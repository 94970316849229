import { getEnumValues } from '@hofy/global';

export enum BillingEntityPaymentTerms {
    TermsPrepaid = 'prepaid',
    Terms14days = '14_days',
    Terms30days = '30_days',
    Terms60days = '60_days',
}

export const allBillingEntityPaymentTerms =
    getEnumValues<BillingEntityPaymentTerms>(BillingEntityPaymentTerms);
