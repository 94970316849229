import { VisualType } from '@hofy/global';

export enum TrackingStatus {
    OrderReceived = 'order_received',
    PreparingShipment = 'preparing_shipment',
    DeliveryEstimated = 'delivery_estimated',
    Delivered = 'delivered',
    CollectionPending = 'collection_pending',
    Collected = 'collected',
}

export const trackingStatusType: Record<TrackingStatus, VisualType> = {
    [TrackingStatus.OrderReceived]: 'informative',
    [TrackingStatus.PreparingShipment]: 'informative',
    [TrackingStatus.DeliveryEstimated]: 'informative',
    [TrackingStatus.Delivered]: 'positive',
    [TrackingStatus.CollectionPending]: 'informative',
    [TrackingStatus.Collected]: 'positive',
};
