import {
    AddressPayload,
    AddressType,
    EmailLoginPairPayload,
    emptyAddressPayload,
    Role,
} from '@hofy/api-shared';
import { DateString, UUID, zeroUuid } from '@hofy/global';

export interface CreateUserPayload extends UpdateUserPayload {
    organizationId: UUID;
}

export interface UpdateUserPayload extends EmailLoginPairPayload {
    itPolicyId: UUID;
    firstName: string;
    lastName: string;
    startsOn: DateString | null;
    address: AddressPayload;
    roles: Role[];
}

export const emptyUserPayload: UpdateUserPayload = {
    itPolicyId: zeroUuid,
    firstName: '',
    lastName: '',
    workEmail: null,
    workEmailLogin: false,
    personalEmail: null,
    personalEmailLogin: false,
    startsOn: null,
    address: emptyAddressPayload(AddressType.Partial),
    roles: [],
};
