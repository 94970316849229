import React, { FC } from 'react';

import { getPurchaseOrderStatusDate, PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { allPurchaseOrderStatuses } from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';

import { useTrPurchaseOrderStatus } from '../../../../../store/purchaseOrders/useTrPurchaseOrderStatus';

interface PurchaseOrderStatusHistoryProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderStatusHistory: FC<PurchaseOrderStatusHistoryProps> = ({ purchaseOrder }) => {
    const trPurchaseOrderStatus = useTrPurchaseOrderStatus();
    return (
        <StatusHistory
            allStatuses={allPurchaseOrderStatuses}
            dateGetter={s => getPurchaseOrderStatusDate(purchaseOrder, s)}
            labelFormatter={trPurchaseOrderStatus}
        />
    );
};
