import React from 'react';

import { allAssignmentPageTabs, AssignmentsPageTab } from '@hofy/api-admin';
import { Tab, Tabs } from '@hofy/ui';

import { useTrAssignmentPageTab } from '../../store/assignments/useTrAssignmentPageTab';

interface AssignmentsPageTabsProps {
    tab: AssignmentsPageTab;
    onChange(tab: AssignmentsPageTab): void;
}

export const AssignmentsPageTabs = ({ tab, onChange }: AssignmentsPageTabsProps) => {
    const trAssignmentPageTab = useTrAssignmentPageTab();
    return (
        <Tabs active={tab} onChange={onChange}>
            {allAssignmentPageTabs.map(tab => (
                <Tab key={tab} id={tab} label={trAssignmentPageTab(tab)} />
            ))}
        </Tabs>
    );
};
