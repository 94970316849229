import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { invoiceEntryService } from '../service/invoiceEntryService';
import { invoiceEntriesQueryKey } from './invoiceEntriesQueryKey';

export const useContractInvoiceEntriesQuery = (contractId: UUID, includeDeleted: boolean) => {
    const { isLoading, data } = useQuery({
        queryKey: [invoiceEntriesQueryKey, contractId, includeDeleted],

        queryFn: () => invoiceEntryService.getInvoiceEntriesForContract(contractId, includeDeleted),
    });

    return {
        data: data || [],
        isLoading,
    };
};
