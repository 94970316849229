export const adminShipmentSlideoutCollectionSurveyStatusI18n = {
    'collection-survey-status.created': 'Created',
    'collection-survey-status.not-sent': 'Not sent',
    'collection-survey-status.pending-response': 'Pending response',
    'collection-survey-status.no-response': 'No response',
    'collection-survey-status.email-sent': 'Email sent',
    'collection-survey-status.viewed': 'Survey viewed',
    'collection-survey-status.text-message-sent': 'Text message sent',
    'collection-survey-status.text-message-delivered': 'Text message delivered',
    'collection-survey-status.escalated': 'Escalated',
    'collection-survey-status.de-escalated': 'De-escalated',
    'collection-survey-status.deferred': 'Deferred',
    'collection-survey-status.de-deferred': 'De-deferred',
    'collection-survey-status.completed': 'Completed',
    'collection-survey-status.requires-action': 'Requires action',
};
