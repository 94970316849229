import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface StatusFlagProps {
    active: boolean;
    label: string;
}

export const StatusFlag: FC<StatusFlagProps> = ({ label, active }) => {
    return (
        <Box row alignItems='center'>
            <Icon
                svg={active ? SvgIcon.CheckCircle : SvgIcon.CrossCircle}
                color={active ? Color.AccentGreen : Color.Neutral300}
                size={14}
                marginRight={10}
            />
            <Paragraph3>{label}</Paragraph3>
        </Box>
    );
};
