import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { emptyInvoiceEntriesFilters, useInvoiceEntries } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../../../../store/invoicing/types/AdminInvoicingTab';
import { InvoiceEntriesTable } from '../../invoiceEntries/components/InvoiceEntriesTable';

interface InvoiceEntriesTabProps {
    invoiceId: UUID;
}

export const InvoiceEntriesTab: FC<InvoiceEntriesTabProps> = ({ invoiceId }) => {
    const { invoiceEntries, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInvoiceEntries({
        ...emptyInvoiceEntriesFilters,
        invoice: invoiceId,
    });

    const invoiceEntriesNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.InvoiceEntries}`;
    const history = useHistory();
    const handleOpenInvoiceEntry = (id: UUID) => {
        history.push(`${invoiceEntriesNavLink}/${id}`);
    };

    return (
        <InvoiceEntriesTable
            entries={invoiceEntries}
            onRowClick={entry => handleOpenInvoiceEntry(entry.id)}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
        />
    );
};
