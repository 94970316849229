export const regionI18n = {
    'region.europe': 'Europe',
    'region.eu': 'EEA + EFTA',
    'region.united-kingdom': 'United Kingdom',
    'region.united-states': 'United States',
    'region.non-core': 'Non-Core Geographies',
    'region.core': 'Core Geographies',

    'region.north-america': 'North America',
    'region.south-america': 'South America',
    'region.central-america': 'Central America',
    'region.africa': 'Africa',
    'region.asia': 'Asia',
    'region.oceania': 'Oceania',
    'region.caribbean': 'Caribbean',
    'region.store-and-reuse-zone': 'Store and reuse zone',
};
