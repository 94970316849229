import { useQuery } from '@tanstack/react-query';

import { betaFeaturesCacheKey } from '../store/cacheKey';
import { betaFeatureService } from './betaFeatureService';
import { BetaFeatureDto } from './types/BetaFeatureDto';

const emptyData: BetaFeatureDto[] = [];

export const useBetaFeaturesQuery = () => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [betaFeaturesCacheKey],
        queryFn: () => betaFeatureService.getFeatures(),
    });

    return {
        isLoading,
        isError,
        data: data || emptyData,
    };
};
