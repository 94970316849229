import { useMutation } from '@tanstack/react-query';

import { revenueRecognitionService } from '@hofy/api-admin';

export const useRevenueRecognitionReport = () => {
    const downloadMutation = useMutation({
        mutationFn: revenueRecognitionService.downloadContractReport,
    });

    return {
        downloadContractReport: downloadMutation.mutate,
    };
};
