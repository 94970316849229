import React, { FC } from 'react';

import { ShipmentCourier, useTrShipmentCourier } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3 } from '@hofy/ui';

interface ShipmentCourierCellProps extends BoxProps {
    courier: ShipmentCourier | null;
}

export const ShipmentCourierCell: FC<ShipmentCourierCellProps> = ({ courier, ...boxProps }) => {
    const trShipmentCourier = useTrShipmentCourier();
    return (
        <Box column gap={4} {...boxProps}>
            <Paragraph3 color={Color.Neutral300}>Courier</Paragraph3>
            <Paragraph3>{courier ? trShipmentCourier(courier) : '--'}</Paragraph3>
        </Box>
    );
};
