import React, { ElementRef, forwardRef } from 'react';
import { useDebounce } from 'react-use';

import { useStateEffect } from '@hofy/hooks';

import { Input, InputNullableStringProps, InputOnlyStringProps } from '../Input';

interface BaseDebounceInputProps {
    delay?: number;
}

export type DebounceInputOnlyStringProps = InputOnlyStringProps & BaseDebounceInputProps;
export type DebounceInputNullableStringProps = InputNullableStringProps & BaseDebounceInputProps;

type DebounceInputProps = DebounceInputOnlyStringProps | DebounceInputNullableStringProps;

export const DebouncedInput = forwardRef<ElementRef<'input'>, DebounceInputProps>(
    ({ value, onChange, placeholder, delay = 400, ...rest }, ref) => {
        const [local, setLocal] = useStateEffect(value, [value]);

        useDebounce(
            () => {
                onChange(local!);
            },
            local ? delay : 0,
            [local],
        );

        return <Input ref={ref} value={local!} onChange={setLocal} placeholder={placeholder} {...rest} />;
    },
);
