import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { colorDyes } from '@hofy/theme';
import { Badge, BoxProps } from '@hofy/ui';

import { Hoverable } from '../../utils/Hoverable';

interface ShipmentPurchaseOrderCellProps extends BoxProps {
    purchaseOrderReference: string;
    linkToPurchaseOrder: string;
}

export const ShipmentPurchaseOrderCell: FC<ShipmentPurchaseOrderCellProps> = ({
    purchaseOrderReference,
    linkToPurchaseOrder,
    ...boxProps
}) => {
    return (
        <Link to={linkToPurchaseOrder}>
            <Hoverable color={colorDyes.grey.onMuted} {...boxProps}>
                <Badge
                    label={`PO #${purchaseOrderReference}`}
                    color='grey'
                    data-test-key='shipment-purchase-order'
                />
            </Hoverable>
        </Link>
    );
};
