/*
 * This file was generated automatically by the script.
 * Do not edit it manually!
 */

export const Palette = {
    aquamarine: {
        '0': 'rgb(9, 124, 105)',
        '+8': 'rgb(251, 254, 254)',
        '+7': 'rgb(240, 250, 249)',
        '+6': 'rgb(226, 248, 245)',
        '+5': 'rgb(218, 246, 243)',
        '+4': 'rgb(197, 242, 236)',
        '+3': 'rgb(190, 233, 228)',
        '+2': 'rgb(110, 201, 191)',
        '+1': 'rgb(61, 164, 151)',
        '-1': 'rgb(26, 112, 97)',
        '-2': 'rgb(21, 101, 90)',
        '-3': 'rgb(13, 74, 66)',
        '-4': 'rgb(1, 45, 38)',
    },
    blue: {
        '0': 'rgb(48, 98, 212)',
        '-4': 'rgb(5, 32, 94)',
        '-3': 'rgb(17, 57, 151)',
        '-2': 'rgb(30, 80, 192)',
        '-1': 'rgb(39, 89, 205)',
        '+1': 'rgb(103, 146, 244)',
        '+2': 'rgb(141, 176, 251)',
        '+5': 'rgb(229, 238, 255)',
        '+6': 'rgb(237, 242, 255)',
        '+8': 'rgb(250, 251, 255)',
        '+3': 'rgb(205, 221, 255)',
        '+7': 'rgb(245, 248, 255)',
        '+4': 'rgb(215, 228, 255)',
    },
    cyan: {
        '0': 'rgb(8, 112, 186)',
        '+8': 'rgb(250, 253, 255)',
        '+7': 'rgb(240, 249, 255)',
        '+6': 'rgb(235, 245, 255)',
        '+5': 'rgb(224, 241, 255)',
        '+4': 'rgb(204, 231, 255)',
        '+3': 'rgb(194, 227, 255)',
        '+2': 'rgb(121, 188, 246)',
        '+1': 'rgb(86, 153, 220)',
        '-1': 'rgb(27, 103, 157)',
        '-2': 'rgb(22, 91, 141)',
        '-3': 'rgb(12, 69, 110)',
        '-4': 'rgb(0, 39, 66)',
    },
    emerald: {
        '0': 'rgb(52, 116, 52)',
        '+8': 'rgb(251, 254, 251)',
        '+7': 'rgb(244, 251, 244)',
        '+6': 'rgb(230, 249, 230)',
        '+5': 'rgb(223, 246, 223)',
        '+4': 'rgb(207, 242, 207)',
        '+3': 'rgb(198, 236, 198)',
        '+2': 'rgb(135, 201, 135)',
        '+1': 'rgb(85, 165, 85)',
        '-1': 'rgb(52, 106, 52)',
        '-2': 'rgb(30, 103, 32)',
        '-3': 'rgb(16, 81, 18)',
        '-4': 'rgb(10, 46, 11)',
    },
    grape: {
        '0': 'rgb(128, 64, 191)',
        '+8': 'rgb(252, 250, 255)',
        '+7': 'rgb(250, 245, 255)',
        '+6': 'rgb(246, 240, 255)',
        '+5': 'rgb(242, 229, 255)',
        '+4': 'rgb(237, 219, 255)',
        '+3': 'rgb(229, 208, 251)',
        '+2': 'rgb(196, 148, 244)',
        '+1': 'rgb(173, 113, 234)',
        '-1': 'rgb(115, 57, 172)',
        '-2': 'rgb(102, 47, 157)',
        '-3': 'rgb(76, 34, 119)',
        '-4': 'rgb(46, 14, 78)',
    },
    green: {
        '0': 'rgb(29, 124, 77)',
        '-4': 'rgb(6, 45, 27)',
        '-3': 'rgb(14, 78, 48)',
        '-2': 'rgb(25, 103, 66)',
        '-1': 'rgb(30, 113, 74)',
        '+1': 'rgb(74, 165, 120)',
        '+2': 'rgb(117, 204, 158)',
        '+5': 'rgb(216, 248, 231)',
        '+6': 'rgb(230, 249, 239)',
        '+8': 'rgb(251, 254, 252)',
        '+3': 'rgb(194, 235, 213)',
        '+7': 'rgb(244, 251, 247)',
        '+4': 'rgb(198, 241, 218)',
    },
    grey: {
        '0': 'rgb(85, 95, 109)',
        '-4': 'rgb(27, 36, 44)',
        '-3': 'rgb(39, 46, 53)',
        '-2': 'rgb(58, 66, 74)',
        '-1': 'rgb(74, 84, 94)',
        '+1': 'rgb(126, 139, 153)',
        '+2': 'rgb(158, 168, 179)',
        '+5': 'rgb(233, 236, 239)',
        '+6': 'rgb(240, 243, 245)',
        '+8': 'rgb(251, 252, 253)',
        '+3': 'rgb(207, 214, 221)',
        '+7': 'rgb(245, 247, 249)',
        '+4': 'rgb(222, 227, 231)',
    },
    greyAlpha: {
        '0': 'rgba(24, 39, 57, 0.74)',
        '-4': 'rgba(13, 20, 28, 0.93)',
        '-3': 'rgba(11, 20, 30, 0.88)',
        '-2': 'rgba(26, 36, 45, 0.86)',
        '-1': 'rgba(27, 41, 54, 0.80)',
        '+1': 'rgba(26, 48, 71, 0.56)',
        '+2': 'rgba(17, 38, 59, 0.40)',
        '+3': 'rgba(22, 48, 75, 0.20)',
        '+4': 'rgba(14, 41, 62, 0.13)',
        '+5': 'rgba(16, 29, 70, 0.09)',
        '+6': 'rgba(2, 41, 80, 0.06)',
        '+7': 'rgba(0, 43, 92, 0.04)',
        '+8': 'rgba(5, 66, 128, 0.02)',
    },
    magenta: {
        '0': 'rgb(177, 47, 134)',
        '+8': 'rgb(255, 250, 253)',
        '+7': 'rgb(254, 245, 251)',
        '+6': 'rgb(253, 237, 248)',
        '+5': 'rgb(253, 227, 244)',
        '+4': 'rgb(251, 213, 238)',
        '+3': 'rgb(247, 202, 232)',
        '+2': 'rgb(236, 137, 203)',
        '+1': 'rgb(222, 94, 179)',
        '-1': 'rgb(149, 50, 116)',
        '-2': 'rgb(137, 41, 105)',
        '-3': 'rgb(103, 30, 78)',
        '-4': 'rgb(65, 11, 47)',
    },
    orange: {
        '0': 'rgb(245, 150, 56)',
        '-4': 'rgb(72, 41, 9)',
        '-3': 'rgb(122, 69, 16)',
        '-2': 'rgb(160, 92, 28)',
        '-1': 'rgb(204, 110, 16)',
        '+1': 'rgb(246, 163, 81)',
        '+2': 'rgb(254, 184, 114)',
        '+3': 'rgb(255, 212, 168)',
        '+4': 'rgb(252, 222, 192)',
        '+5': 'rgb(255, 232, 209)',
        '+6': 'rgb(255, 240, 224)',
        '+7': 'rgb(255, 245, 235)',
        '+8': 'rgb(255, 252, 250)',
    },
    purple: {
        '0': 'rgb(153, 57, 172)',
        '+8': 'rgb(254, 250, 255)',
        '+7': 'rgb(253, 245, 255)',
        '+6': 'rgb(250, 237, 253)',
        '+5': 'rgb(248, 227, 253)',
        '+4': 'rgb(245, 213, 251)',
        '+3': 'rgb(244, 202, 247)',
        '+2': 'rgb(217, 141, 236)',
        '+1': 'rgb(196, 102, 219)',
        '-1': 'rgb(133, 50, 149)',
        '-2': 'rgb(121, 41, 137)',
        '-3': 'rgb(91, 30, 103)',
        '-4': 'rgb(56, 11, 65)',
    },
    raspberry: {
        '0': 'rgb(192, 48, 96)',
        '+8': 'rgb(255, 250, 252)',
        '+7': 'rgb(254, 245, 248)',
        '+6': 'rgb(253, 237, 242)',
        '+5': 'rgb(253, 227, 235)',
        '+4': 'rgb(251, 213, 226)',
        '+3': 'rgb(248, 206, 220)',
        '+2': 'rgb(236, 141, 173)',
        '+1': 'rgb(228, 98, 141)',
        '-1': 'rgb(157, 52, 87)',
        '-2': 'rgb(145, 43, 77)',
        '-3': 'rgb(103, 30, 54)',
        '-4': 'rgb(69, 12, 31)',
    },
    red: {
        '0': 'rgb(197, 52, 52)',
        '-4': 'rgb(74, 13, 13)',
        '-3': 'rgb(111, 32, 32)',
        '-2': 'rgb(149, 45, 45)',
        '-1': 'rgb(161, 54, 54)',
        '+1': 'rgb(242, 100, 100)',
        '+2': 'rgb(244, 144, 144)',
        '+5': 'rgb(254, 230, 230)',
        '+6': 'rgb(255, 235, 235)',
        '+8': 'rgb(255, 250, 250)',
        '+3': 'rgb(252, 207, 207)',
        '+7': 'rgb(254, 245, 245)',
        '+4': 'rgb(252, 217, 217)',
    },
    teal: {
        '0': 'rgb(7, 117, 151)',
        '+8': 'rgb(250, 254, 255)',
        '+7': 'rgb(240, 251, 255)',
        '+6': 'rgb(232, 247, 252)',
        '+5': 'rgb(224, 243, 250)',
        '+4': 'rgb(197, 236, 247)',
        '+3': 'rgb(184, 232, 245)',
        '+2': 'rgb(122, 192, 220)',
        '+1': 'rgb(79, 158, 186)',
        '-1': 'rgb(25, 108, 133)',
        '-2': 'rgb(25, 96, 118)',
        '-3': 'rgb(10, 73, 92)',
        '-4': 'rgb(1, 42, 55)',
    },
    violet: {
        '0': 'rgb(99, 78, 202)',
        '+8': 'rgb(251, 250, 255)',
        '+7': 'rgb(247, 245, 255)',
        '+6': 'rgb(243, 240, 255)',
        '+5': 'rgb(238, 235, 255)',
        '+4': 'rgb(227, 224, 255)',
        '+3': 'rgb(220, 214, 255)',
        '+2': 'rgb(171, 162, 251)',
        '+1': 'rgb(145, 126, 241)',
        '-1': 'rgb(87, 63, 207)',
        '-2': 'rgb(78, 56, 188)',
        '-3': 'rgb(57, 40, 138)',
        '-4': 'rgb(31, 18, 100)',
    },
    violetAlpha: {
        '0': 'rgba(32, 2, 179, 0.70)',
        '-4': 'rgba(14, 0, 88, 0.93)',
        '-3': 'rgba(22, 2, 117, 0.85)',
        '-2': 'rgba(31, 3, 170, 0.79)',
        '-1': 'rgba(34, 2, 192, 0.76)',
        '+1': 'rgba(39, 2, 228, 0.51)',
        '+2': 'rgba(28, 4, 244, 0.37)',
        '+3': 'rgba(49, 14, 255, 0.17)',
        '+4': 'rgba(40, 17, 255, 0.13)',
        '+5': 'rgba(43, 5, 255, 0.08)',
        '+6': 'rgba(55, 5, 255, 0.06)',
        '+7': 'rgba(55, 5, 255, 0.04)',
        '+8': 'rgba(55, 5, 255, 0.02)',
    },
    white: {
        '0': 'rgb(255, 255, 255)',
        'transparent': 'rgba(255, 255, 255, 0.00)',
        '-1': 'rgba(255, 255, 255, 0.82)',
        '-2': 'rgba(255, 255, 255, 0.72)',
        '-3': 'rgba(255, 255, 255, 0.68)',
        '-4': 'rgba(255, 255, 255, 0.64)',
        '-5': 'rgba(255, 255, 255, 0.40)',
        '-6': 'rgba(255, 255, 255, 0.24)',
        '-7': 'rgba(255, 255, 255, 0.12)',
        '-8': 'rgba(255, 255, 255, 0.10)',
        '-9': 'rgba(255, 255, 255, 0.08)',
        '-10': 'rgba(255, 255, 255, 0.05)',
        '-11': 'rgba(255, 255, 255, 0.03)',
        '-12': 'rgba(255, 255, 255, 0.02)',
    },
    yellow: {
        '0': 'rgb(250, 204, 21)',
        '+8': 'rgb(255, 254, 243)',
        '+7': 'rgb(254, 252, 232)',
        '+6': 'rgb(254, 249, 195)',
        '+5': 'rgb(254, 240, 138)',
        '+4': 'rgb(255, 229, 126)',
        '+3': 'rgb(255, 222, 88)',
        '+2': 'rgb(255, 218, 71)',
        '+1': 'rgb(255, 212, 39)',
        '-1': 'rgb(161, 98, 7)',
        '-2': 'rgb(133, 77, 14)',
        '-3': 'rgb(113, 63, 18)',
        '-4': 'rgb(66, 32, 6)',
    },
} as const;
