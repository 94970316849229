import styled, { keyframes } from 'styled-components';

import { Box } from '@hofy/ui';

const showIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Overlay = styled(Box)`
    position: absolute;
    display: flex;
    min-height: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    animation: ${showIn} 0.2s ease both;
`;
