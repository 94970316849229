import React, { FC, useState } from 'react';

import {
    allInvoicingEntityFilters,
    allPaymentStatusFilters,
    JobContext,
    PaymentStatusFilter,
    useBillingEntitiesQuery,
    useInvoices,
    useJobsSummary,
} from '@hofy/api-admin';
import {
    allInvoiceStatuses,
    allNetsuiteSyncStatuses,
    BillingEntityStatus,
    InvoiceStatus,
    NetsuiteSyncStatus,
    useTrInvoiceStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Box, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumBlockFilter } from '../../../components/design/blockFilters/EnumBlockFilter';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { BillingEntityBlockFilter } from '../../../components/domain/filters/BillingEntityBlockFilter';
import { OrganizationBlockFilter } from '../../../components/domain/filters/OrganizationBlockFilter';
import { JobsSummaryIndicator } from '../../../components/domain/job/JobsSummaryIndicator';
import { useInvoicesFilters } from '../../../store/invoices/useInvoicesFilters';
import { useTrInvoicingEntityFilter } from '../../../store/invoices/useTrInvoicingEntityFilter';
import { useTrPaymentStatusFilter } from '../../../store/invoices/useTrPaymentStatusFilter';
import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';
import { InvoicesTable } from '../../organizationsPage/invoicesTab/InvoicesTable';
import { InvoicingTabs } from '../InvoicingTabs';
import { InvoicingTabRouterProps } from '../types/InvoicingTabRouterProps';
import { InvoicesDateBlockFilter } from './components/filters/InvoicesDateBlockFilter';
import { InvoicesPageActiveFilterChips } from './components/filters/InvoicesPageActiveFilterChips';
import { InvoicesPageMenu } from './components/InvoicesPageMenu';

interface InvoicesPageProps extends InvoicingTabRouterProps {
    onOpenInvoice(id: UUID): void;
    onOpenJobs(): void;
}

export const InvoicesPage: FC<InvoicesPageProps> = ({
    tab,
    tabs,
    onChangeTab,
    onOpenInvoice,
    onOpenJobs,
}) => {
    const trNetsuiteSyncStatus = useTrNetsuiteSyncStatus();
    const trInvoiceStatus = useTrInvoiceStatus(false);
    const trPaymentStatusFilter = useTrPaymentStatusFilter();
    const trInvoicingEntitiesFilter = useTrInvoicingEntityFilter();

    const {
        filters,
        filterCount,
        setSearch,
        setOrganization,
        setBillingEntity,
        setNetsuiteStatus,
        setInvoiceStatus,
        setPaymentStatus,
        setInvoicingEntity,
        setInvoiceDate,
    } = useInvoicesFilters();

    const { isLoading, invoices, isFetchingNextPage, hasNextPage, fetchNextPage } = useInvoices(filters);
    const [orgSearchQuery, setOrgSearchQuery] = useState('');
    const [billingEntitySearch, setBillingEntitySearch] = useState('');

    const { data: organizations } = useOrganizationsRefsQuery(filters.organization, orgSearchQuery);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const { billingEntities } = useBillingEntitiesQuery({
        status: [BillingEntityStatus.Active, BillingEntityStatus.Archived],
        organizationIds: filters.organization,
        search: billingEntitySearch,
        includeIds: filters.billingEntity,
    });
    const { data: jobsSummary } = useJobsSummary([JobContext.Invoices]);
    return (
        <Box column flex='auto'>
            <PageHeader
                title='Invoices'
                titleSlot={<JobsSummaryIndicator onClick={onOpenJobs} jobsSummary={jobsSummary} />}
                rightSlot={
                    <>
                        <SearchInput
                            value={filters.search || ''}
                            onChange={setSearch}
                            placeholder='Search customer, order'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <InvoicesPageMenu />
                    </>
                }
                tabsSlot={<InvoicingTabs tabs={tabs} tab={tab} onChangeTab={onChangeTab} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filters.organization}
                    onChange={setOrganization}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <BillingEntityBlockFilter
                    selected={filters.billingEntity}
                    onChange={setBillingEntity}
                    billingEntities={billingEntities}
                    search={billingEntitySearch}
                    onSearchChange={setBillingEntitySearch}
                />
                <EnumMultiBlockFilter<NetsuiteSyncStatus>
                    title='Netsuite status'
                    icon={SvgIcon.Link}
                    selected={filters.netsuiteStatus}
                    onChange={setNetsuiteStatus}
                    items={allNetsuiteSyncStatuses}
                    renderItem={trNetsuiteSyncStatus}
                />
                <EnumMultiBlockFilter<InvoiceStatus>
                    title='Invoice status'
                    icon={SvgIcon.Star}
                    selected={filters.invoiceStatus}
                    onChange={setInvoiceStatus}
                    items={allInvoiceStatuses}
                    renderItem={trInvoiceStatus}
                />
                <EnumMultiBlockFilter<PaymentStatusFilter>
                    title='Payment status'
                    icon={SvgIcon.CurrencyDollar}
                    selected={filters.paymentStatus}
                    onChange={setPaymentStatus}
                    items={allPaymentStatusFilters}
                    renderItem={trPaymentStatusFilter}
                />
                <EnumBlockFilter
                    title='Invoicing entity'
                    selected={filters.invoicingEntity}
                    icon={SvgIcon.FileCheck}
                    onChange={setInvoicingEntity}
                    items={allInvoicingEntityFilters}
                    renderItem={trInvoicingEntitiesFilter}
                />
                <InvoicesDateBlockFilter
                    invoiceTime={filters.invoiceDate}
                    onChangeInvoiceTime={setInvoiceDate}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <InvoicesPageActiveFilterChips
                    filters={filters}
                    organizations={organizations}
                    billingEntities={billingEntities}
                    onOrganizationChange={setOrganization}
                    onBillingEntityChange={setBillingEntity}
                    onNetsuiteStatusChange={setNetsuiteStatus}
                    onInvoiceStatusChange={setInvoiceStatus}
                    onPaymentStatusChange={setPaymentStatus}
                    onInvoicingEntityFilterChange={setInvoicingEntity}
                    onInvoiceDateChange={setInvoiceDate}
                />
            </BlockFilterChipContainer>
            <InvoicesTable
                invoices={invoices}
                onEntryClick={onOpenInvoice}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Box>
    );
};
