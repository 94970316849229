import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { billingEntitiesService } from '../service/billingEntityService';
import { AdminBillingEntityFilters } from '../service/types/AdminBillingEntityFilters';
import { BillingEntityDto } from '../service/types/BillingEntityDto';
import { billingEntitiesCacheKey } from './cacheKey';

export const useBillingEntitiesListQuery = (filters: AdminBillingEntityFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [billingEntitiesCacheKey, filters],
        queryFn: param =>
            billingEntitiesService.listBillingEntities(filters, {
                page: param.pageParam,
                pageSize: 30,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const billingEntities: BillingEntityDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        billingEntities,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
