import React, { FC } from 'react';

import { ShipmentStatus, shipmentStatusColors, useTrShipmentStatus } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Box, Dot, Paragraph3 } from '@hofy/ui';

import { useShipmentDate } from '../../../store/shipments/useShipmentDate';

interface ShipmentStatusInfoProps {
    status: ShipmentStatus;

    createdOn: DateString | null;
    bookedOn: DateString | null;
    shippedOn: DateString | null;
    completedOn: DateString | null;
    backorderOn: DateString | null;
    cancelledOn: DateString | null;
}

export const ShipmentStatusWithDate: FC<ShipmentStatusInfoProps> = ({ status, ...dates }) => {
    const trShipmentStatus = useTrShipmentStatus();
    const shipmentDate = useShipmentDate({
        status,
        ...dates,
    });
    const formattedDate = formatDate(shipmentDate);

    return (
        <Box row gap={8}>
            <Dot color={shipmentStatusColors[status]} size={8} />
            <Paragraph3>{[trShipmentStatus(status), formattedDate].join(' - ')}</Paragraph3>
        </Box>
    );
};
