import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { UserAssignmentsByShipmentDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Chevron, ExpandHeight, LabeledText, Paragraph3, SectionTitle2 } from '@hofy/ui';

import { NewJoinerBadge } from '../../../../components/domain/user/NewJoinerBadge';
import { OffboardedBadge } from '../../../../components/domain/user/OffboardedBadge';
import { OrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { UserLink } from '../../../peoplePage/users/UserLink';

interface UserSummaryProps {
    row: UserAssignmentsByShipmentDto;
}

export const UserSummary: FC<UserSummaryProps> = ({ row }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Box>
            <Box row alignItems='center' gap={8} overflow='hidden'>
                <Box row gap={8} alignItems='flex-end'>
                    <SectionTitle2 color={Color.ContentPrimary}>
                        <UserLink id={row.id}>{`${row.publicId} ${formatUserName(row)}`}</UserLink>
                    </SectionTitle2>
                    <Paragraph3 color={Color.ContentPrimary}>
                        <OrganizationLink id={row.organization.id}>{row.organization.name}</OrganizationLink>
                    </Paragraph3>
                </Box>
                {row.isNewJoiner && row.startsOn && <NewJoinerBadge startDate={row.startsOn} />}
                <OffboardedBadge offboardedOn={row.offboardedOn} lastWorkingDayOn={row.lastWorkingDayOn} />
                <Box flex={1} />
                <ExpandButton onClick={() => setIsExpanded(current => !current)}>
                    <Chevron isOpen={isExpanded} />
                </ExpandButton>
            </Box>
            <ExpandHeight>
                {isExpanded && (
                    <Box
                        overflow='hidden'
                        row
                        gap={20}
                        alignItems='flex-start'
                        marginTop={4}
                        paddingHorizontal={4}
                    >
                        <LabeledText label='Work email' content={row.workEmail} />
                        <LabeledText label='Personal email' content={row.personalEmail} />
                        <LabeledText
                            label='Phone number'
                            content={row.userAddress.phoneNumber}
                            minWidth={200}
                        />
                        <LabeledText
                            label='Delivery instructions'
                            content={row.userAddress.deliveryInstructions}
                        />
                    </Box>
                )}
            </ExpandHeight>
        </Box>
    );
};

const ExpandButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid ${Color.AccentGreyPastel};
    color: ${Color.Neutral500};
    border-radius: 8px;
    cursor: pointer;

    :hover {
        background: ${Color.Highlight};
        z-index: 1;
    }
`;
