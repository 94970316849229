import {
    arrow,
    autoUpdate,
    flip,
    offset,
    Placement,
    shift,
    size,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from '@floating-ui/react';
import { useRef, useState } from 'react';

export interface PopoverOptions {
    placement?: Placement;
}

export const usePopover = ({ placement = 'bottom' }: PopoverOptions = {}) => {
    const [open, setOpen] = useState(false);

    const arrowRef = useRef(null);

    const data = useFloating({
        placement,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(16),
            flip(),
            shift({ padding: 4 }),
            arrow({
                element: arrowRef,
                padding: 8,
            }),
            size({
                apply({ availableWidth, elements }) {
                    Object.assign(elements.floating.style, {
                        maxWidth: `${availableWidth}px`,
                    });
                },
            }),
        ],
    });

    const context = data.context;

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context);

    const interactions = useInteractions([click, dismiss, role]);

    return {
        open,
        setOpen,
        interactions,
        data,
        arrowRef,
    };
};

export type PopoverState = ReturnType<typeof usePopover>;
