import React, { FC } from 'react';

import { ShipmentCourierPayload } from '@hofy/api-admin';
import { allShipmentCouriers, useTrShipmentCourier } from '@hofy/api-shared';
import { FormDropdown, FormInput } from '@hofy/common';
import { UseForm } from '@hofy/ui';

import { ShipmentClassDropdown } from './ShipmentClassDropdown';

interface ShipmentCourierFormProps {
    form: UseForm<ShipmentCourierPayload>;
}

export const ShipmentCourierForm: FC<ShipmentCourierFormProps> = ({ form }) => {
    const trShippingCourier = useTrShipmentCourier();
    const { values, errors, setValues } = form;

    return (
        <>
            <FormDropdown
                items={allShipmentCouriers}
                labelFormatter={trShippingCourier}
                onChange={courier => setValues({ courier })}
                label='Courier'
                isError={errors.courier}
                value={values.courier}
            />
            <ShipmentClassDropdown
                label='Shipment type'
                value={values.shipmentClass}
                onChange={shipmentClass => setValues({ shipmentClass })}
            />
            <FormInput
                label='Courier reference number'
                onChangeText={courierReference => setValues({ courierReference })}
                value={values.courierReference}
                isError={errors.courierReference}
                nullable
            />
            <FormInput
                label='Tracking link'
                onChangeText={trackingLink => setValues({ trackingLink })}
                value={values.trackingLink}
                isError={errors.trackingLink}
                nullable
            />
        </>
    );
};
