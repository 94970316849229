import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { ShipmentStatus } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, FormContainer, FormDateInput, FormSection } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useCancelShipment } from '../../../store/shipments/useCancelShipment';
import { CourierDetails } from '../components/CourierDetails';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';

interface CancelShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const CancelShipmentSlideout: FC<CancelShipmentSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading } = useCancelShipment(shipment, onClose);
    const { courierReference, courier, trackingLink, shipmentClass } = shipment;
    const showCourierBlock = courierReference || trackingLink;

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Cancel shipment' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.Canceled}
                />
                <FormContainer marginBottom={30}>
                    {showCourierBlock && (
                        <FormSection label='Courier details' marginBottom={20}>
                            <CourierDetails
                                courierReference={courierReference}
                                courier={courier}
                                shipmentClass={shipmentClass}
                                trackingLink={trackingLink}
                            />
                        </FormSection>
                    )}
                    <FormDateInput label='Canceled date' api={form.fields.canceledOn} />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Cancel shipment'
                    action='destructive'
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
