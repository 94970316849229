import { ColorDye, getEnumValues } from '@hofy/global';

export enum AssignmentCollectionReason {
    OffBoarding = 'off-boarding',
    OrderedByMistake = 'ordered_by_mistake',
    DamagedInTransit = 'damaged_in_transit',
    WrongItemSent = 'wrong_item_send',
    Defective = 'defective',
    CancellationRequest = 'cancellation_request',
    Disposal = 'disposal',
    EndOfContract = 'end_of_contract',
    CollectAndReuse = 'collect_and_reuse',
    DebtCollection = 'debt_collection',
    Other = 'other',
}

export const allAssignmentCollectionReasons =
    getEnumValues<AssignmentCollectionReason>(AssignmentCollectionReason);

export const allCollectionReasonsUnrelatedToShipment = [
    AssignmentCollectionReason.OffBoarding,
    AssignmentCollectionReason.OrderedByMistake,
    AssignmentCollectionReason.Defective,
    AssignmentCollectionReason.CancellationRequest,
    AssignmentCollectionReason.CollectAndReuse,
    AssignmentCollectionReason.DebtCollection,
    AssignmentCollectionReason.Other,
];

export const assignmentCollectionReasonColor: Record<AssignmentCollectionReason, ColorDye> = {
    [AssignmentCollectionReason.OffBoarding]: 'grey',
    [AssignmentCollectionReason.OrderedByMistake]: 'grey',
    [AssignmentCollectionReason.DamagedInTransit]: 'red',
    [AssignmentCollectionReason.WrongItemSent]: 'red',
    [AssignmentCollectionReason.Defective]: 'red',
    [AssignmentCollectionReason.Disposal]: 'grey',
    [AssignmentCollectionReason.EndOfContract]: 'grey',
    [AssignmentCollectionReason.CancellationRequest]: 'grey',
    [AssignmentCollectionReason.CollectAndReuse]: 'grape',
    [AssignmentCollectionReason.DebtCollection]: 'grey',
    [AssignmentCollectionReason.Other]: 'grey',
};

export const canSendReplacement = (collectionReason: AssignmentCollectionReason | null) => {
    if (!collectionReason) {
        return false;
    }
    return (
        collectionReason === AssignmentCollectionReason.Defective ||
        collectionReason === AssignmentCollectionReason.Other
    );
};
