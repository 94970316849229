import React, { FC } from 'react';

import { OrganizationStatus, organizationStatusColor, useTrOrganizationStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface OrganizationStatusChipProps {
    status: OrganizationStatus;
}

export const OrganizationStatusChip: FC<OrganizationStatusChipProps> = ({ status }) => {
    const tr = useTrOrganizationStatus();
    return <Badge label={tr(status)} color={organizationStatusColor[status]} />;
};
