import React, { FC } from 'react';

import { JobsSummaryDto } from '@hofy/api-admin';
import { Button, SvgIcon } from '@hofy/ui';

interface JobSummaryIndicatorProps {
    onClick(): void;
    jobsSummary: JobsSummaryDto | null;
}

export const JobsSummaryIndicator: FC<JobSummaryIndicatorProps> = ({ onClick, jobsSummary }) => (
    <Button
        type='ghost'
        onClick={onClick}
        label={`${jobsSummary?.total || 0}`}
        leftIcon={SvgIcon.Server}
        testKey='tasks'
        disabled={!jobsSummary}
    />
);
