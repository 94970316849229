import { useQuery } from '@tanstack/react-query';

import { itemsService } from '@hofy/api-admin';
import { isNotFoundError } from '@hofy/rest';

export const useItemByItemCode = (itemCode: string | null) => {
    return useQuery({
        queryKey: ['admin/item/by-code', itemCode],

        queryFn: () =>
            itemsService
                .getItemByCode(itemCode!)
                .catch(error => (isNotFoundError(error) ? undefined : Promise.reject(error))),

        enabled: !!itemCode,
        retry: false,
    });
};
