import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractsCacheKey, contractService, invoiceEntriesQueryKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useRevertContractEnd = (contractId: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: () => contractService.revertEndContract(contractId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey, contractId] });
            showToast({
                type: 'positive',
                message: 'Successfully reverted end of contract',
            });
        },
    });

    return {
        revertContractEnd: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
