import { xor } from 'lodash';
import React, { FC, useState } from 'react';

import { ItemEventDto, useItemEvents } from '@hofy/api-admin';
import { allItemEventStatuses, ItemEventStatus, itemEventStatusColor } from '@hofy/api-shared';
import { formatDateRange } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    Box,
    FilterChip,
    FilterChipList,
    LabeledDateRangeInput,
    Modals,
    PageHeader,
    SearchInput,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { useItemEventsFilters } from '../../../store/itemEvents/useItemEventsFilters';
import { useTrItemEventStatus } from '../../../store/itemEvents/useTrItemEventStatus';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { ManualFixDetailsModal } from './components/ManualFixDetailsModal';
import { ItemEventsTable } from './ItemEventsTable';

export const ItemEventsPage: FC = () => {
    const { search, setSearch, filters, filterCount, setStatuses, setEventDate } = useItemEventsFilters();
    const [selectedItemEvent, setSelectedItemEvent] = useState<ItemEventDto | null>(null);

    const { itemEvents, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useItemEvents(filters);

    const itemEventsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    const trStatus = useTrItemEventStatus();

    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <>
            <Box column flex='auto'>
                <PageHeader
                    title='Item events'
                    rightSlot={
                        <>
                            <SearchInput
                                value={search}
                                onChange={setSearch}
                                placeholder='Item id, public id or serial…'
                                autoFocus
                            />
                            <BlockFilterButton
                                onClick={toggleShowFilters}
                                isOpened={showFilters}
                                count={filterCount}
                            />
                        </>
                    }
                    tabsSlot={<AccountingTabs tab={AdminAccountingTab.ItemEvents} />}
                />
                <BlockFilterContainer ref={filterElRef} show={showFilters}>
                    <EnumMultiBlockFilter<ItemEventStatus>
                        title='Status'
                        selected={filters.statuses}
                        onChange={setStatuses}
                        items={allItemEventStatuses}
                        renderItem={status => trStatus(status)}
                    />
                    <BlockFilter title='Date' icon={SvgIcon.Calendar}>
                        <LabeledDateRangeInput
                            label='Event date'
                            value={filters.eventDate}
                            onChange={setEventDate}
                            nullable
                            marginLeft={12}
                            marginTop={8}
                        />
                    </BlockFilter>
                </BlockFilterContainer>
                <BlockFilterChipContainer show={filterCount > 0}>
                    <FilterChipList
                        toKey={status => status}
                        selected={filters.statuses}
                        toLabel={trStatus}
                        onClear={value => setStatuses(xor(filters.statuses, [value]))}
                        color={p => itemEventStatusColor[p]}
                    />
                    {filters.eventDate && (
                        <FilterChip
                            label={formatDateRange(filters.eventDate)}
                            onClear={() => setEventDate(null)}
                            color='teal'
                        />
                    )}
                </BlockFilterChipContainer>
                <ItemEventsTable
                    itemEvents={itemEvents}
                    infinityScroll={itemEventsScrollDetails}
                    onShowManualFixNotes={setSelectedItemEvent}
                />
            </Box>
            <Modals>
                {selectedItemEvent && (
                    <ManualFixDetailsModal
                        itemEvent={selectedItemEvent}
                        onClose={() => setSelectedItemEvent(null)}
                    />
                )}
            </Modals>
        </>
    );
};
