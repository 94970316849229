import React, { forwardRef, ReactNode } from 'react';

import { ColorDye } from '@hofy/global';
import { Color, colorDyes, VisualVariant } from '@hofy/theme';

import { Box, MarginBoxProps, Paragraph3, Paragraph4 } from '../base';
import { Icon, SvgIcon } from '../icon';
import { Dot } from './Dot';

export interface BadgeProps extends MarginBoxProps {
    label?: string;
    size?: BadgeSize;
    color?: ColorDye;
    dot?: boolean;
    icon?: Svg;
    variant?: VisualVariant;
    leftSlot?: ReactNode;
    onClick?(): void;
    close?: boolean;
}

const sizes = {
    normal: Paragraph4,
    large: Paragraph3,
};

type BadgeSize = keyof typeof sizes;

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
    (
        {
            label,
            size = 'normal',
            color = 'grey',
            dot = false,
            icon,
            variant = 'normal',
            leftSlot,
            onClick,
            close,
            ...rest
        },
        ref,
    ) => {
        const Paragraph = sizes[size];

        const isVivid = variant === 'vivid';
        const { main, onMuted, subtle, surface } = colorDyes[color];

        let iconColor: Color = isVivid ? surface : main;
        let textColor: Color = isVivid ? surface : onMuted;
        let bgColor: Color = isVivid ? main : subtle;

        return (
            <Box
                ref={ref}
                onClick={onClick}
                pointer={!!onClick}
                row
                inline
                gap={8}
                paddingHorizontal={12}
                paddingVertical={2}
                bg={bgColor}
                rounded={16}
                maxWidth='100%'
                {...rest}
            >
                {dot && <Dot size={6} color={iconColor} />}
                {leftSlot}
                {icon && <Icon svg={icon} color={iconColor} size={16} />}
                {label && (
                    <Paragraph color={textColor} overflow='hidden' ellipsis textNoWrap>
                        {label}
                    </Paragraph>
                )}
                {close && <Icon svg={SvgIcon.Cross} color={iconColor} />}
            </Box>
        );
    },
);
