import React, { FC, MouseEvent, useRef, useState } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, IconButton, MarginBoxProps, Paragraph3, SvgIcon } from '@hofy/ui';

import { FlatInput } from './base/FlatInput';

interface PasswordInputProps extends MarginBoxProps {
    isError?: boolean | string;
    isRequired?: boolean;
    isReadOnly?: boolean;
    label: string;
    placeholder?: string;
    value: string;
    name?: string;
    bordered?: boolean;
    autoFocus?: boolean;
    onChangeText?(v: string): void;
}

/** @deprecated to be implemented in `@hofy/ui` */
export const PasswordInput: FC<PasswordInputProps> = ({
    isError,
    value = '',
    onChangeText = () => {},
    label,
    name,
    isRequired,
    placeholder,
    isReadOnly = false,
    bordered = true,
    autoFocus = false,
    ...margins
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef<HTMLInputElement>(null);

    const actualIsError = typeof isError === 'string' || isError === true;
    const errorReason = typeof isError === 'string' ? isError : undefined;

    const onClickWrapper = () => {
        if (ref.current) {
            ref.current.focus();
        }
    };

    const toggleVisibility = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setIsVisible(!isVisible);
    };

    return (
        <Box data-test-key='labeled-root' {...margins}>
            <label data-test-key='labeled-label'>
                {label && (
                    <Paragraph3 data-test-key='label' marginBottom={5}>
                        {label}
                        {isRequired && '*'}
                    </Paragraph3>
                )}
                <Box data-test-key='labeled-content' row onClick={onClickWrapper} relative>
                    <FlatInput
                        isError={actualIsError}
                        ref={ref}
                        type={isVisible ? 'text' : 'password'}
                        paddingLeft={10}
                        paddingRight={40}
                        value={value ?? ''}
                        placeholder={placeholder ?? ''}
                        name={name}
                        onChange={e => onChangeText(e.target.value)}
                        bordered={bordered}
                        readOnly={isReadOnly}
                        autoFocus={autoFocus}
                    />
                    <IconWrapper>
                        <IconButton
                            size={16}
                            color={Color.ContentPrimary}
                            onClick={toggleVisibility}
                            icon={isVisible ? SvgIcon.Eye : SvgIcon.EyeOff}
                        />
                    </IconWrapper>
                </Box>
                {actualIsError && errorReason && (
                    <Paragraph3 data-test-key='labeled-error' color={Color.ContentNegative} marginTop={5}>
                        {errorReason}
                    </Paragraph3>
                )}
            </label>
        </Box>
    );
};

const IconWrapper = styled(Box)`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
`;
