import { useAddonsQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useAddonFilterDefinition = (): FilterDefinitionMulti<UUID> => {
    const { data: addons } = useAddonsQuery();

    const userUuidLabel = (uuid: UUID) => {
        const addon = addons.find(user => user.id === uuid);
        return addon?.name ?? uuid;
    };

    const allAddonsUuids = addons.map(addon => addon.id);
    return {
        type: 'multi',
        name: 'Addons',
        query: queryStringArrayParser(),
        allValues: allAddonsUuids,
        toLabel: userUuidLabel,
    };
};
