import { chunk } from 'lodash';
import React, { CSSProperties, Fragment, useContext } from 'react';

import { AnyEnum, EnumValue } from '@hofy/global';

import { I18nContext } from '../context/I18nContext';
import {
    exists,
    existsParts,
    translate,
    translateEnum,
    translateParts,
    TranslatePayload,
} from '../translate';

export const useI18nGeneric = <T extends string>() => {
    const config = useContext(I18nContext);

    return {
        exists: (key: T) => exists(config, key),
        existsParts: (...items: (string | number)[]) => existsParts(config, ...items),
        tr: (key: T, payload?: TranslatePayload) => translate(config, key, payload),
        trParts: (...items: (string | number)[]) => translateParts(config, ...items),
        trEnum:
            <E extends EnumValue, T extends AnyEnum = AnyEnum>(
                enumEntity: T,
                prefix: string,
                fallbackEnumValue?: E,
            ) =>
            (enumValue: E, payload?: TranslatePayload) =>
                translateEnum(config, enumEntity, prefix, fallbackEnumValue, enumValue, payload),
        trPlural: (key: string, value: number | null | undefined, payload?: Record<string, any>) => {
            if (!value) {
                return translate(config, `${key}.empty` as T, {
                    value,
                    ...payload,
                });
            } else if (value === 1) {
                return translate(config, `${key}.single` as T, {
                    value,
                    ...payload,
                });
            }
            return translate(config, `${key}.plural` as T, {
                value,
                ...payload,
            });
        },
        trStyled: (key: T, style: CSSProperties, payload?: Record<string, any>) => {
            const value = translate(config, key, payload);
            const chunks = chunk(value.split('*'), 2);
            return (
                <>
                    {chunks.map((c, index) => {
                        return (
                            <Fragment key={index}>
                                {c[0]}
                                {c[1] && <span style={style}>{c[1]}</span>}
                            </Fragment>
                        );
                    })}
                </>
            );
        },
    };
};
