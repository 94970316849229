import { useMutation, useQueryClient } from '@tanstack/react-query';

import { JobDto, jobsCacheKey, jobService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useCancelJob = () => {
    const queryClient = useQueryClient();
    const { showToast, showErrorToast } = useToast();

    const mutation = useMutation({
        mutationFn: (job: JobDto) => jobService.cancelJob(job.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [jobsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Job cancelled',
            });
        },
        onError: () => {
            showErrorToast('Failed to cancel job');
        },
    });

    return {
        cancelJob: mutation.mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
