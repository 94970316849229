import { entries } from 'lodash';
import { useMemo } from 'react';

import { FilterApiRecord } from '../useFilters';

export const useActiveFilters = (filters: FilterApiRecord<any>) => {
    const activeFilters = useMemo(() => {
        return entries(filters)
            .filter(([, filter]) => filter.showInActive && filter.hasValue())
            .map(([key, filter]) => ({ key, filter }));
    }, [filters]);

    const hasChangedFilters = useMemo(() => {
        return activeFilters.some(({ filter }) => filter.isChanged());
    }, [activeFilters]);

    return {
        activeFilters,
        hasChangedFilters,
    };
};
