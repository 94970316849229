import React, { FC } from 'react';

import { allSupplierContactRoles, SupplierContactPayload } from '@hofy/api-admin';
import { FormRow } from '@hofy/common';
import { Color } from '@hofy/theme';
import { FormContainer, FormInput, FormPhoneInput, FormSelect, Paragraph3, UseForm } from '@hofy/ui';

import { useTrSupplierContactRole } from '../../../../store/suppliers/useTrSupplierContactRole';

interface ContactFormProps {
    form: UseForm<SupplierContactPayload>;
}

export const SupplierContactForm: FC<ContactFormProps> = ({ form }) => {
    const trRole = useTrSupplierContactRole();

    return (
        <FormContainer>
            <FormSelect
                label='Role'
                options={allSupplierContactRoles}
                api={form.fields.role}
                placeholder='Select a role'
                toText={trRole}
                isRequired
            />
            <FormRow>
                <FormInput label='First name' api={form.fields.name} isRequired nullable />
                <FormInput label='Last name' api={form.fields.lastname} nullable />
            </FormRow>
            <FormRow>
                <FormPhoneInput label='Phone number' api={form.fields.phone} nullable />
                <FormInput label='Email' api={form.fields.email} nullable />
            </FormRow>
            <Paragraph3 color={Color.ContentTertiary}>* At least one of the above is required</Paragraph3>
        </FormContainer>
    );
};
