import React, { FC } from 'react';

import { BYODOrderStatus, byodOrderStatusColors } from '@hofy/api-shared';
import { DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, OuterBoxProps, Tooltip } from '@hofy/ui';

import { useTrBYODOrderStatus } from '../../../store/byodOrders/useTrBYODOrderStatus';

interface BYODOrderStatusChipProps extends OuterBoxProps {
    status: BYODOrderStatus;
    dateTime?: DateTimeString;
}

export const BYODOrderStatusChip: FC<BYODOrderStatusChipProps> = ({
    status,
    dateTime: date,
    ...boxProps
}) => {
    const trBYODOrderStatus = useTrBYODOrderStatus();

    if (!status) {
        return null;
    }

    const color = byodOrderStatusColors[status];

    return (
        <Tooltip body={date && formatDate(date)}>
            <Badge color={color} label={trBYODOrderStatus(status)} {...boxProps} />
        </Tooltip>
    );
};
