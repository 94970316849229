import { FloatingOverlay } from '@floating-ui/react';
import { motion } from 'framer-motion';
import { rgba } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Box } from '../../base';

interface BaseModalProps extends TestKeyAware {
    children?: ReactNode;
    zIndexShift?: number;
}

const transition = { duration: 0.2 };

const closedOverlay = { backgroundColor: rgba(Color.BackgroundDefaultDimmer, 0) };
const openedOverlay = { backgroundColor: Color.BackgroundDefaultDimmer };

const closedContent = { scale: 0.9, opacity: 0 };
const openedContent = { scale: 1, opacity: 1 };

export const BaseModal: FC<BaseModalProps> = ({ children, zIndexShift = 0, testKey }) => {
    return (
        <FloatingOverlay lockScroll style={{ zIndex: ZIndex.Modal + zIndexShift }}>
            <OverlayBox
                centered
                as={motion.div}
                initial={closedOverlay}
                animate={openedOverlay}
                exit={closedOverlay}
                transition={transition}
            >
                <Box
                    as={motion.div}
                    initial={closedContent}
                    animate={openedContent}
                    exit={closedContent}
                    transition={transition}
                    maxHeight='90%'
                    column
                    data-test-key={testKey}
                >
                    {children}
                </Box>
            </OverlayBox>
        </FloatingOverlay>
    );
};

const OverlayBox = styled(Box)`
    position: fixed;
    inset: 0;
`;
