import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useClearTrackingExceptionRequest = (shipmentId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => shipmentService.clearTrackingExceptionRequest(shipmentId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Tracking exception has been cleared.',
            });
        },
    });

    return {
        mutate: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
