import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, StolenItemPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useAssignmentStolen = (assignmentId: UUID, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: StolenItemPayload) => assignmentService.stolenItem(assignmentId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Item marked as stolen',
                message: 'Item and assignments updated accordingly',
            });
            onSuccess();
        },
    });

    const stolen = (createReplacement: boolean, deliveryAddressId: UUID | null) => {
        mutation.mutate({ createReplacement, deliveryAddressId });
    };

    return {
        stolen,
    };
};
