import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useIncludeInDunning = (invoiceId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => invoicesService.includeInDunning(invoiceId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey, invoiceId] });
            showToast({
                type: 'positive',
                message: 'Invoice included in reminders successfully.',
            });
        },
    });

    const enableDunning = () => {
        mutation.mutate();
    };

    return {
        enableDunning,
        isLoading: mutation.isPending,
    };
};
