import React, { FC } from 'react';

import { readFirstFile } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { InvisibleFileInput } from './InvisibleFileInput';

interface UploadFileProps {
    label: string;
    accept: string;
    note: string;
    value: FileRef | null;
    onChange(v: FileRef | null): void;
    errorMessage?: string;
    disabled: boolean;
    isLoading: boolean;
}

export interface FileRef {
    name: string;
    content: string;
}

export const UploadFile: FC<UploadFileProps> = ({
    onChange,
    errorMessage,
    label,
    accept,
    note,
    value,
    disabled,
    isLoading,
}) => {
    const handleSelectFile = (files: FileList) => {
        if (files?.length > 0) {
            const file = readFirstFile(files, content => {
                onChange({ name: file.name, content });
            });
        }
    };

    if (value) {
        return (
            <Box row justify='space-between'>
                <Box flex='auto' row marginRight={20}>
                    <Icon svg={SvgIcon.File} color={Color.Neutral500} marginRight={12} />
                    <Paragraph3 wordBreak>{value.name}</Paragraph3>
                </Box>
                <Button
                    type='secondary'
                    label='Delete'
                    onClick={() => onChange(null)}
                    disabled={disabled || isLoading}
                />
            </Box>
        );
    }

    return (
        <Box>
            <AsyncButton
                type='secondary'
                label={label}
                rightIcon={SvgIcon.ChevronRight}
                isLoading={isLoading}
                disabled={disabled}
            >
                <InvisibleFileInput accept={accept} onChange={handleSelectFile} />
            </AsyncButton>
            <Paragraph3 marginTop={5} color={errorMessage ? Color.FoundationNegative : Color.Neutral300}>
                {errorMessage || note}
            </Paragraph3>
        </Box>
    );
};
