import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, Link, MarginBoxProps, Paragraph3 } from '@hofy/ui';

import { useAuthI18n } from '../../../i18n/useAuthI18n';
import { AuthNavLink } from '../../AuthNavLink';

interface AuthPageSubHeaderProps extends MarginBoxProps {
    signInPage: boolean;
}

export const AuthPageSubHeader: FC<AuthPageSubHeaderProps> = ({ signInPage, ...marginProps }) => {
    const { tr } = useAuthI18n();

    const description = signInPage
        ? tr('auth-page.dont-have-an-account')
        : tr('auth-page.already-have-an-account');
    const link = signInPage ? AuthNavLink.SignUp : AuthNavLink.SignIn;
    const linkText = signInPage ? tr('auth.sign-up-now') : tr('auth-page.footer.sign-in');

    return (
        <Box
            row
            border
            rounded={8}
            padding={16}
            borderColor={Color.NonContextualVioletSurfaceBorder}
            bg={Color.NonContextualVioletSurface}
            justify='space-between'
            {...marginProps}
        >
            <Paragraph3>{description}</Paragraph3>
            <Link to={link} testKey='auth-page-subheader-button'>
                {linkText}
            </Link>
        </Box>
    );
};
