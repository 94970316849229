import styled, { CSSObject } from 'styled-components';

import { Theme } from '@hofy/theme';

import { spacingValuesFromTheme } from './boxHelpers';
import { OuterBox, OuterBoxProps } from './OuterBox';
import { PaddingBoxProps } from './types';

export interface InnerBoxProps extends PaddingBoxProps, OuterBoxProps {
    relative?: boolean;
    zIndex?: number;
    overflow?: 'hidden' | 'auto' | 'scroll';
}

const paddingStyleGenerator = (
    {
        padding,
        paddingHorizontal,
        paddingVertical,
        paddingLeft = paddingHorizontal,
        paddingRight = paddingHorizontal,
        paddingTop = paddingVertical,
        paddingBottom = paddingVertical,
    }: PaddingBoxProps,
    theme: Theme,
) => spacingValuesFromTheme({ padding, paddingLeft, paddingRight, paddingTop, paddingBottom }, theme);

export const InnerBox = styled(OuterBox)<InnerBoxProps>(({ theme, relative, zIndex, overflow, ...rest }) => {
    const styles: CSSObject = {};

    if (relative) {
        styles.position = 'relative';
    }

    if (zIndex) {
        styles.zIndex = zIndex;
    }

    if (overflow) {
        styles.overflow = overflow;
    }

    const paddings = paddingStyleGenerator(rest, theme);

    return { ...styles, ...paddings };
});
