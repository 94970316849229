import {
    AddressPayload,
    AddressType,
    BillingEntityPaymentTerms,
    emptyAddressPayload,
} from '@hofy/api-shared';
import { Country, Currency, DateTimeString, Phone, Price, UUID, zeroUuid } from '@hofy/global';

export interface BillingEntityPayload extends BillingEntityOrganizationPayload {
    id: UUID | null;
    currency: Currency;
    paymentTerms: BillingEntityPaymentTerms;
    name: string;
    registeredCompanyNumber: string | null;
    contacts: ContactPayload[];
    vatNumber: string | null;
    billingAddress: AddressPayload;
    deelSync: DeelPayload | null;
}

interface DeelPayload {
    uuid: UUID | null;
    lastUpdatedAt: DateTimeString | null;
}

export interface BillingEntityOrganizationPayload {
    organizationId: UUID | null;
    isDefault: boolean;
    countries: Country[];
    twoApiEnabled: boolean;
    twoSpreadsheetEnabled: boolean;
    twoRecourseLimit: Price | null;
    isContractSigned: boolean;
}

export const emptyContactPayload: ContactPayload = {
    id: zeroUuid,
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    isDefault: false,
    isInvoiceEmail: true,
};

export const emptyBillingEntityPayload: BillingEntityPayload = {
    id: null,
    organizationId: null,
    isDefault: false,
    currency: Currency.USD,
    paymentTerms: BillingEntityPaymentTerms.Terms14days,
    countries: [],
    twoApiEnabled: false,
    twoSpreadsheetEnabled: false,
    isContractSigned: false,
    contacts: [],
    name: '',
    vatNumber: null,
    twoRecourseLimit: null,
    billingAddress: emptyAddressPayload(AddressType.Entity),
    registeredCompanyNumber: null,
    deelSync: null,
};

export interface ContactPayload {
    id: UUID;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phone: Phone | null;
    isDefault: boolean;
    isInvoiceEmail: boolean;
}
