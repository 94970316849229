import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, SubstituteProductPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useSubstituteProduct = (id: UUID, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: SubstituteProductPayload) => assignmentService.substituteProduct(id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Order product replaced',
            });
        },
    });

    return {
        substitute: mutation.mutate,
        isPending: mutation.isPending,
        isError: mutation.isError,
    };
};
