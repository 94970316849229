import { useMemo } from 'react';

import { isMainHofyWarehouse, ShipmentDetailsDto } from '@hofy/api-admin';
import { AssignmentCollectionReason, isShipmentToHofyWarehouse } from '@hofy/api-shared';

import { useCompleteDeliveryShipment } from './useCompleteDeliveryShipment';

export const useCompleteCollectionShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const { form, isLoading } = useCompleteDeliveryShipment(shipment, onSuccess);

    const assignments = useMemo(() => shipment.assignments.filter(f => !f.hasDropshipShipment), [shipment]);

    const someAssignedItemsNotChecked = useMemo(() => {
        return (
            isShipmentToHofyWarehouse(shipment) &&
            isMainHofyWarehouse(shipment.toWarehouse) &&
            assignments.some(
                order =>
                    order.item &&
                    !order.item.warehouseBin &&
                    order.collectionReason !== AssignmentCollectionReason.Disposal,
            )
        );
    }, [assignments, shipment]);

    return {
        form,
        isLoading,
        disabledCompletion: someAssignedItemsNotChecked,
    };
};
