import React, { FC } from 'react';

import { FulfilmentCountrySettingDto } from '@hofy/api-admin';
import { BackButton, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { useTrCountry } from '@hofy/i18n';
import { AsyncButton, Badge, CountryFlag, FormContainer, SvgIcon } from '@hofy/ui';

import { FormHofyWarehouseSelect } from '../../../components/domain/warehouses/FormHofyWarehouseSelect';
import { useUpdateFulfilmentCountrySetting } from '../../../store/fulfilmentCountrySettings/useUpdateFulfilmentCountrySetting';

interface UpdateFulfilmentCountrySettingSlideoutProps {
    onClose(): void;
    setting: FulfilmentCountrySettingDto;
}

export const UpdateFulfilmentCountrySettingSlideout: FC<UpdateFulfilmentCountrySettingSlideoutProps> = ({
    onClose,
    setting,
}) => {
    const { form, isLoading: isLoadingMutation } = useUpdateFulfilmentCountrySetting(setting);
    const trCountry = useTrCountry();

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Country settings' justify='space-between'>
                <Badge
                    leftSlot={<CountryFlag size={14} country={setting.country} />}
                    label={trCountry(setting.country)}
                    size='large'
                />
            </SlideoutHeader>
            <SlideoutContent>
                <FormContainer marginTop={40} marginBottom={40}>
                    <FormHofyWarehouseSelect
                        label='Default delivery'
                        api={form.fields.defaultDeliverFromWarehouseId}
                        isRequired
                    />
                    <FormHofyWarehouseSelect
                        label='Default collection'
                        api={form.fields.defaultCollectToWarehouseId}
                        isRequired
                    />
                    <FormHofyWarehouseSelect
                        label='Store and reuse local hub'
                        api={form.fields.storeAndReuseCollectToLocalWarehouseId}
                        isRequired={false}
                        nullable
                    />
                    <FormHofyWarehouseSelect
                        label='Store and reuse main hub'
                        api={form.fields.storeAndReuseCollectToHubWarehouseId}
                        isRequired={false}
                        nullable
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton isLoading={isLoadingMutation} label='Update' onClick={form.submit} />
            </SlideoutFooter>
        </Slideout>
    );
};
