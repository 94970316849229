import React, { FC } from 'react';

import { NetsuiteStatusDto } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { BooleanBadge, Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../../../components/domain/netsuite/NetsuiteStatusChip';

interface InvoiceAccountingSectionProps {
    netsuite: NetsuiteStatusDto;
    revenueRecognitionGenerated: boolean;
}

export const InvoiceAccountingSection: FC<InvoiceAccountingSectionProps> = ({
    netsuite,
    revenueRecognitionGenerated,
}) => {
    return (
        <FormSection label='Accounting'>
            <Box row>
                <Box column flex={4} rowGap={24}>
                    <FormGridRow columns={3}>
                        <Labeled
                            label='Revenue generated'
                            content={<BooleanBadge value={revenueRecognitionGenerated} offColor='grey' />}
                        />
                        <Labeled label='Netsuite' content={<NetsuiteStatusChip status={netsuite} />} />
                        {netsuite.successAt && (
                            <LabeledText label='Success at' content={formatDateTime(netsuite.successAt)} />
                        )}
                        {netsuite.errorAt && (
                            <LabeledText label='Error at' content={formatDateTime(netsuite.errorAt)} />
                        )}
                        {netsuite.errorReason && (
                            <LabeledText label='Error reason' content={netsuite.errorReason} />
                        )}
                    </FormGridRow>
                </Box>
                <Box row flex={1} minWidth='200px' />
            </Box>
        </FormSection>
    );
};
