import { ItemLocation } from './ItemLocation';
import { Ownership } from './Ownership';
import { UserRefDto } from './UserRefDto';
import { WarehouseLocationDto } from './WarehouseLocationDto';

export interface LocationDto {
    location: ItemLocation;
    user: UserRefDto | null;
    warehouse: WarehouseLocationDto | null;
}

export const isInOrganizationWarehouse = (location: LocationDto) => {
    return (
        location.location === ItemLocation.AtWarehouse &&
        location.warehouse?.ownership === Ownership.Organization
    );
};
