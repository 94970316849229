import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

export const FormCard = styled(Box)`
    background-color: ${Color.BackgroundDefault};
    border-radius: 8px;
    border: 1px solid ${Color.NonContextualBorderDivider};
    ${p =>
        !!p.onClick &&
        css`
            :hover {
                background-color: ${Color.InteractionDefaultHover};
            }
            cursor: pointer;
        `}
`;
