import React, { FC } from 'react';

import { Badge } from '@hofy/ui';

interface InternalProductChipProps {
    value: boolean;
}

export const InternalProductChip: FC<InternalProductChipProps> = ({ value }) => {
    if (!value) {
        return null;
    }
    return <Badge color='teal' label='Internal' />;
};
