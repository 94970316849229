import React, { FC } from 'react';

import { useTrActivityLogSource } from '@hofy/api-admin';
import { ActivityLogSource, activityLogSourceColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ActivityLogSourceChipProps {
    activityLogSource: ActivityLogSource;
}

export const ActivityLogSourceChip: FC<ActivityLogSourceChipProps> = ({ activityLogSource }) => {
    const tr = useTrActivityLogSource();
    return <Badge label={tr(activityLogSource)} color={activityLogSourceColor[activityLogSource]} />;
};
