import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useGoBack } from '@hofy/hooks';
import { EnumRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { allInspectionsTabs, InspectionsTab } from '../../store/inspections/inspectionsTab';
import { AssignmentSlideoutRouter } from '../assignmentSlideout/AssignmentSlideoutRouter';
import { ItemSlideoutRouter } from '../itemsPage/ItemSlideoutRouter';
import { ShipmentSlideoutRouter } from '../shipmentSlideout/ShipmentSlideoutRouter';
import { InspectionSlideoutRouter } from './inpsectionSlideout/InspectionSlideoutRouter';
import { InspectionsPage } from './page/InspectionsPage';

export const InspectionsRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();

    return (
        <Switch>
            <InspectionsRoute path={`${AdminNavLink.Inspections}/:tab(${allInspectionsTabs.join('|')})`}>
                {({ tab }) => {
                    const base = `${AdminNavLink.Inspections}/${tab}`;
                    const handleSlideoutClose = () => goBack(`${base}${history.location.search}`);

                    return (
                        <>
                            <InspectionsPage
                                tab={tab}
                                onTabChange={t =>
                                    history.push(`${AdminNavLink.Inspections}/${t}${history.location.search}`)
                                }
                            />
                            <InspectionSlideoutRouter
                                base={`${base}/inspection`}
                                itemsBase={`${base}/item`}
                                onClose={handleSlideoutClose}
                            />
                            <ShipmentSlideoutRouter
                                base={`${base}/shipment`}
                                assignmentsBase={`${base}/assignment`}
                                onCloseBase={`${base}${history.location.search}`}
                            />
                            <AssignmentSlideoutRouter
                                base={`${base}/assignment`}
                                shipmentsBase={`${base}/shipment`}
                                onCloseBase={`${base}${history.location.search}`}
                            />
                            <ItemSlideoutRouter base={`${base}/item`} />
                        </>
                    );
                }}
            </InspectionsRoute>
            <Redirect
                to={`${AdminNavLink.Inspections}/${InspectionsTab.Inspections}${history.location.search}`}
                from={`${AdminNavLink.Inspections}`}
            />
        </Switch>
    );
};

const InspectionsRoute = EnumRoute<InspectionsTab>('tab', InspectionsTab, Route);
