import { stringifyUrl } from 'query-string';

import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AddonDetailsDto } from './types/AddonDetailsDto';
import { AddonDto } from './types/AddonDto';
import { AddonPayload } from './types/AddonPayload';
import { AddonQuoteDto } from './types/AddonQuoteDto';
import { AddonQuotePayload } from './types/AddonQuotePayload';

interface AddonsResponse {
    addons: AddonDto[];
}

class AddonsService {
    public listAddons = async (): Promise<AddonDto[]> => {
        const response = await restClient.getJson<AddonsResponse>('/api/admin/addons');
        return response.addons;
    };

    public getAddon = async (id: UUID): Promise<AddonDetailsDto> => {
        return await restClient.getJson(`/api/admin/addons/${id}`);
    };

    public getAddonQuote = async (id: UUID, payload: AddonQuotePayload): Promise<AddonQuoteDto> => {
        return await restClient.getJson<AddonQuoteDto>(
            stringifyUrl({
                url: `/api/admin/addons/${id}/quote`,
                query: { payload: encodeURIComponent(JSON.stringify(payload)) },
            }),
        );
    };

    public createAddon = async (payload: AddonPayload): Promise<void> => {
        return restClient.post('/api/admin/addons/', payload);
    };

    public updateAddon = async (id: UUID, payload: AddonPayload): Promise<void> => {
        return restClient.put(`/api/admin/addons/${id}`, payload);
    };
}

export const addonsService = new AddonsService();
