import React, { FC, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { formatVariant, ProductDto, PublicRefDto, VariantDetailsDto } from '@hofy/api-shared';
import { Checkbox } from '@hofy/common';
import { useSelection } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph2, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ProductItem } from '../../../components/domain/products/ProductItem';

interface Assignment extends PublicRefDto {
    product: ProductDto;
    variant: VariantDetailsDto;
}

interface AddAssignmentsModalProps {
    onClose?(): void;
    onAdd(assignments: Assignment[]): void;
    allAssignments: Assignment[];
    assignments: Assignment[];
}

export const AddAssignmentsModal: FC<AddAssignmentsModalProps> = ({
    onClose = () => {},
    onAdd,
    allAssignments,
    assignments,
}) => {
    const assignmentsSelection = useSelection<Assignment>();

    const handleOnAdd = () => {
        onAdd(assignmentsSelection.getSelectionArray());
        onClose();
    };

    const filteredAssignments = useMemo(
        () => allAssignments.filter(order => !assignments.find(({ id }) => id === order.id)),
        [allAssignments, assignments],
    );

    const showNoContent = !filteredAssignments.length;

    return (
        <Modal onClose={onClose} width={600}>
            <ModalHeader
                title='Add assignments to shipment'
                subtitle='Select order to add to shipment'
                borderBottom
            />
            <ModalContent>
                {showNoContent && <Paragraph2 color={Color.Neutral700}>Nothing to select</Paragraph2>}
                {filteredAssignments.map(assignment => (
                    <RowItem
                        assignment={assignment}
                        key={assignment.id}
                        checked={assignmentsSelection.hasSelected(assignment.id)}
                        onChange={checked => assignmentsSelection.onSelect(assignment, checked)}
                    >
                        <ProductItem
                            image={assignment.variant.image?.url}
                            label={assignment.product.name}
                            subLabel={formatVariant(assignment.variant)}
                        />
                    </RowItem>
                ))}
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton label='Cancel' onClick={onClose} />
                <Button
                    disabled={!assignmentsSelection.hasSelected()}
                    label='Add assignments'
                    onClick={handleOnAdd}
                />
            </ModalFooter>
        </Modal>
    );
};

interface RowItemProps {
    assignment: PublicRefDto;
    onChange(checked: boolean): void;
    checked: boolean;
    children?: ReactNode;
}

const RowItem: FC<RowItemProps> = ({ children, assignment, onChange, checked }) => (
    <RowItemWrapper borderBottom row justify='space-between' paddingVertical={15}>
        <Checkbox checked={checked} onChange={onChange} />
        <Paragraph3 marginLeft={15} width={95} color={Color.Neutral700}>
            {assignment.publicId}
        </Paragraph3>
        <Box flex={1}>{children}</Box>
    </RowItemWrapper>
);

const RowItemWrapper = styled(Box)`
    &:last-child {
        border-bottom: none;
    }
`;
