import React, { FC, useState } from 'react';

import { AddonDetailsDto, AuditableTable, useAddonQuery } from '@hofy/api-admin';
import { AddonTarget, PaymentSchema, ProductCategory } from '@hofy/api-shared';
import { MoreMenu, Slideout, SlideoutTabbedHeader } from '@hofy/common';
import { Currency, getEnumValues, UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, Heading3, Skeleton, Tab, Tabs, useFormArrayField } from '@hofy/ui';

import { useAddonQuoteForm } from '../../../../store/addons/useAddonQuoteForm';
import { useUpdateAddon } from '../../../../store/addons/useUpdateAddon';
import { useAuditLogMenuOption } from '../../../../store/auditLogs/useAuditMenuOption';
import { AddonConfiguration } from './AddonConfiguration';
import { AddonQuote } from './AddonQuote';

interface AddonUpdateSlideoutProps {
    addonId: UUID;
    onClose(): void;
}

export const AddonUpdateSlideout: FC<AddonUpdateSlideoutProps> = ({ addonId, onClose }) => {
    const { data: addon, isLoading, isError } = useAddonQuery(addonId);

    const [activeTab, setActiveTab] = useState(UpdateAddonSlideoutTab.Configuration);

    const [auditMenuItem] = useAuditLogMenuOption(AuditableTable.Addons, addon?.id);
    const menuItems = [auditMenuItem];

    return (
        <Slideout width={1200} onClose={onClose}>
            <SlideoutTabbedHeader
                title={
                    <Box row justify='space-between' flex='auto'>
                        <Box row gap={20}>
                            <Heading3>{addon?.name ?? 'Loading addon...'}</Heading3>
                            <MoreMenu items={menuItems} />
                        </Box>
                    </Box>
                }
                tabsSlot={
                    <Tabs active={activeTab} onChange={t => setActiveTab(t)}>
                        {allUpdateAddonSlideoutTabs.map(tab => (
                            <Tab key={tab} id={tab} label={updateAddonSlideoutLabels[tab]} />
                        ))}
                    </Tabs>
                }
            />
            {(() => {
                if (isLoading) {
                    return <Skeleton height='100%' />;
                }

                if (isError || !addon) {
                    return <ErrorStatePlaceholder />;
                }

                return (
                    <>
                        {/*
                            We don't conditionally render the tabs to avoid unmounting their components and losing form data.
                        */}
                        <AddonConfigurationTab
                            addon={addon}
                            isActive={activeTab === UpdateAddonSlideoutTab.Configuration}
                            onClose={onClose}
                        />
                        <AddonQuoteTab
                            addon={addon}
                            isActive={activeTab === UpdateAddonSlideoutTab.Quote}
                            onClose={onClose}
                        />
                    </>
                );
            })()}
        </Slideout>
    );
};

interface AddonConfigurationTabProps {
    addon: AddonDetailsDto;
    isActive: boolean;
    onClose(): void;
}

const AddonConfigurationTab: FC<AddonConfigurationTabProps> = ({ addon, isActive, onClose }) => {
    const { form, isLoading, isError } = useUpdateAddon(addon);

    if (!isActive) {
        return null;
    }

    return (
        <AddonConfiguration
            form={form}
            isLoading={isLoading}
            isError={isError}
            onClose={onClose}
            addon={addon}
        />
    );
};

interface AddonQuoteTabProps {
    addon: AddonDetailsDto;
    isActive: boolean;
    onClose(): void;
}

const AddonQuoteTab: FC<AddonQuoteTabProps> = ({ addon, isActive, onClose }) => {
    const form = useAddonQuoteForm();

    const enrolments = useFormArrayField(form.fields.enrolments, {
        enrolmentCount: addon.target === AddonTarget.Asset ? 100 : 1,
        assetValue: { amount: '2000', currency: Currency.USD },
        duration: 36,
        paymentSchema: PaymentSchema.Upfront,
        productCategory: ProductCategory.Laptop,
    });

    if (!isActive) {
        return null;
    }

    return <AddonQuote form={form} enrolments={enrolments} addon={addon} onClose={onClose} />;
};

enum UpdateAddonSlideoutTab {
    Configuration = 'configuration',
    Quote = 'quote',
}

const allUpdateAddonSlideoutTabs = getEnumValues<UpdateAddonSlideoutTab>(UpdateAddonSlideoutTab);

const updateAddonSlideoutLabels = {
    [UpdateAddonSlideoutTab.Configuration]: 'Configuration',
    [UpdateAddonSlideoutTab.Quote]: 'Quote',
};
