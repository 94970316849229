import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Permission, RepaymentStatus } from '@hofy/api-shared';
import { ComponentLoader, MoreMenu, Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Badge, Box, ConfirmModal, Heading3, PageHeader, Tab, Tabs } from '@hofy/ui';

import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { AdminInvoicingTab } from '../../../../store/invoicing/types/AdminInvoicingTab';
import {
    allRepaymentPlanDetailsTabs,
    RepaymentPlanDetailsTab,
} from '../../../../store/repaymentPlan/types/RepaymentPlanDetailsTab';
import { useCancelRepaymentPlan } from '../../../../store/repaymentPlan/useCancelRepaymentPlan';
import { useDeleteRejectedRepaymentPlan } from '../../../../store/repaymentPlan/useDeleteRejectedRepaymentPlan';
import { useForceRecreateRepaymentPlan } from '../../../../store/repaymentPlan/useForceRecreateRepaymentPlan';
import { useRecheckRepaymentPlan } from '../../../../store/repaymentPlan/useRecheckRepaymentPlan';
import { useRepaymentPlan } from '../../../../store/repaymentPlan/useRepaymentPlan';
import { useTrRepaymentPlanDetailsTab } from '../../../../store/repaymentPlan/useTrRepaymentPlanDetailsTab';
import { RepaymentDetailsTab } from './RepaymentDetailsTab';
import { RepaymentInvoiceEntriesTab } from './RepaymentInvoiceEntriesTab';
import { RepaymentPlanHistory } from './RepaymentPlanHistory';

interface RepaymentPlanDetailsOverlayProps {
    planId: UUID;
    planTab: RepaymentPlanDetailsTab;
    onChangeTab(v: RepaymentPlanDetailsTab): void;
}

export const RepaymentPlanDetailsOverlay: FC<RepaymentPlanDetailsOverlayProps> = ({
    planId,
    onChangeTab,
    planTab,
}) => {
    const trTab = useTrRepaymentPlanDetailsTab();
    const history = useHistory();
    const { hasPermission } = usePermission();

    const { recheck } = useRecheckRepaymentPlan([planId]);
    const { mutate: deleteRejectedPlan } = useDeleteRejectedRepaymentPlan(planId, () => {
        history.goBack();
    });
    const { mutate: cancelPlan } = useCancelRepaymentPlan(planId);
    const { mutate: forceRecreate } = useForceRecreateRepaymentPlan(planId);
    const { plan, isLoading } = useRepaymentPlan(planId);
    const invoiceEntriesNavLink = `${AdminNavLink.Invoicing}/${AdminInvoicingTab.InvoiceEntries}`;
    const [deleteRejectedRepaymentPlanModal, setDeleteRejectedRepaymentPlanModal] = useState(false);
    const [forceRecreatePlanModal, setForceRecreatePlanModal] = useState(false);
    const [cancelRecreatePlanModal, setCancelRecreatePlanModal] = useState(false);

    const handleOpenInvoiceEntry = (id: UUID) => {
        history.push(`${invoiceEntriesNavLink}/${id}`);
    };
    const renderContent = () => {
        if (isLoading || !plan) {
            return <ComponentLoader />;
        }
        switch (planTab) {
            case RepaymentPlanDetailsTab.Details:
                return <RepaymentDetailsTab plan={plan} />;

            case RepaymentPlanDetailsTab.InvoiceEntries:
                return (
                    <RepaymentInvoiceEntriesTab
                        planId={planId}
                        onRowClick={v => handleOpenInvoiceEntry(v.id)}
                    />
                );

            case RepaymentPlanDetailsTab.History:
                return <RepaymentPlanHistory plan={plan} />;
        }
    };
    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: recheck,
                    label: 'Recheck funding status',
                    visible: hasPermission(Permission.AdminRepaymentPlanManage),
                },

                {
                    action: () => setDeleteRejectedRepaymentPlanModal(true),
                    label: 'Delete rejected plan',
                    visible:
                        hasPermission(Permission.AdminRepaymentPlanManage) &&
                        plan?.status === RepaymentStatus.FundingRejected,
                },
                {
                    action: () => setForceRecreatePlanModal(true),
                    label: 'Force recreate plan',
                    visible: hasPermission(Permission.AdminRepaymentPlanManage),
                },
                {
                    action: () => setCancelRecreatePlanModal(true),
                    label: 'Cancel plan',
                    visible: hasPermission(Permission.AdminRepaymentPlanManage),
                },
            ]}
        />
    );

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={
                    <>
                        <Heading3>{`Repayment plan ${plan?.publicId || ''}`}</Heading3>
                        {plan?.isDeleted && <Badge color='red' label='Deleted' />}
                    </>
                }
                rightSlot={moreMenu}
                tabsSlot={
                    <Tabs active={planTab} onChange={onChangeTab}>
                        {allRepaymentPlanDetailsTabs.map(item => (
                            <Tab key={item} id={item} label={trTab(item)} />
                        ))}
                    </Tabs>
                }
            />
            <Box flex='auto' relative>
                {renderContent()}
            </Box>
            {deleteRejectedRepaymentPlanModal && (
                <ConfirmModal
                    keyPrefix='delete-rejected-plan'
                    onClose={() => setDeleteRejectedRepaymentPlanModal(false)}
                    onConfirm={deleteRejectedPlan}
                />
            )}
            {forceRecreatePlanModal && (
                <ConfirmModal
                    keyPrefix='force-recreate-plan'
                    onClose={() => setForceRecreatePlanModal(false)}
                    onConfirm={forceRecreate}
                />
            )}
            {cancelRecreatePlanModal && (
                <ConfirmModal
                    keyPrefix='cancel-plan'
                    onClose={() => setCancelRecreatePlanModal(false)}
                    onConfirm={cancelPlan}
                />
            )}
        </Overlay>
    );
};
