import React, { FC } from 'react';

import { Alert, Box, Form, FormNumberInput, SubmitButton } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { useSeedOrders } from '../../../../store/settings/useSeedOrders';

export const SeedRandomOrders: FC = () => {
    const { form, isLoading } = useSeedOrders();
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert type='informative' description='Creates orders for users in requested state' />
                <FormOrganizationSelect
                    label='Organization'
                    api={form.fields.organizationId}
                    placeholder='Choose organization'
                />
                <FormNumberInput
                    label='Number of assets'
                    type='number'
                    api={form.fields.count}
                    placeholder='Enter number of orders'
                    isRequired
                />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
