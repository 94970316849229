import React, { FC } from 'react';

import { Alert, Box, Form, FormInput, FormNumberInput, SubmitButton } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { useSeedUsers } from '../../../../store/settings/useSeedUsers';

export const SeedRandomUsers: FC = () => {
    const { form, isLoading } = useSeedUsers();
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Creates number of users in random location with email format base@hofy.com => base+{firstName}-{last-name}@hofy.com'
                />
                <FormOrganizationSelect
                    label='Organization'
                    api={form.fields.organizationId}
                    placeholder='Choose organization'
                />
                <FormNumberInput label='User count' api={form.fields.count} />
                <FormInput label='Base email' api={form.fields.baseEmail} />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
