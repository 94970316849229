export const rentalTermFullI18n = {
    'rental-term-full.rental-6-months': '6 months rental',
    'rental-term-full.rental-12-months': '12 months rental',
    'rental-term-full.rental-24-months': '24 months rental',
    'rental-term-full.rental-36-months': '36 months rental',
};

export const rentalTermI18n = {
    'rental-term.rental-6-months': '6 months',
    'rental-term.rental-12-months': '12 months',
    'rental-term.rental-24-months': '24 months',
    'rental-term.rental-36-months': '36 months',
};

export const rentalTermShortI18n = {
    'rental-term-short.rental-6-months': '6M',
    'rental-term-short.rental-12-months': '12M',
    'rental-term-short.rental-24-months': '24M',
    'rental-term-short.rental-36-months': '36M',
};
