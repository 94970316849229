import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ParentProductCategory, ProductCategory } from '../types/ProductCategory';

export const useTrParentProductCategory = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ParentProductCategory>(ParentProductCategory, 'parent-product-category');
};

export const useTrProductCategory = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ProductCategory>(ProductCategory, 'product-category');
};
