import React, { FC } from 'react';

import { CollectionSurveyStatus, collectionSurveyStatusFilters } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';
import { useTrCollectionSurveyStatus } from '../../../../../store/collectionRequests/useTrCollectionSurveyStatus';

interface CollectionSurveyStatusBlockFilterProps {
    selected: CollectionSurveyStatus[];
    onChange(status: CollectionSurveyStatus[]): void;
}

export const CollectionSurveyStatusBlockFilter: FC<CollectionSurveyStatusBlockFilterProps> = ({
    selected,
    onChange,
}) => {
    const trCollectionSurveyStatus = useTrCollectionSurveyStatus();

    return (
        <ListMultiBlockFilter
            title='Collection survey'
            icon={SvgIcon.Truck}
            selected={selected}
            onChange={onChange}
            items={collectionSurveyStatusFilters}
            renderItem={type => trCollectionSurveyStatus(type)}
        />
    );
};
