import React from 'react';

import { SortDirectionType } from '@hofy/global';
import { Color, NumberValues } from '@hofy/theme';

import { Box } from '../../base';
import { ContainerBox } from '../BaseTableHeader';
import { Cell } from '../cells/Cell';
import {
    FilterableTable,
    SortableTable,
    SortFilterDataTableColumnConfig,
} from '../types/SortFilterDataTableTypes';
import { SortFilterDataTableHeaderCell } from './SortFilterDataTableHeaderCell';

interface SortFilterDataTableHeaderProps<T, S extends string> extends SortableTable<S>, FilterableTable {
    columns: SortFilterDataTableColumnConfig<T>[];
    minWidth?: string | number;
    horizontalPadding?: NumberValues;
}

export const SortFilterDataTableHeader = <T extends any, S extends string>({
    columns,
    sort,
    handleSort,
    filterState,
    setFilter,
    minWidth,
    horizontalPadding = 40,
}: SortFilterDataTableHeaderProps<T, S>) => {
    if (!columns.some(column => column.header)) {
        return null;
    }

    const renderHeaderCell = (column: SortFilterDataTableColumnConfig<T>) => {
        const { id: key, comparator, comparatorBy, filter: filterConfig } = column;
        const sortableColumn = !!(comparator || comparatorBy);
        const sortDirection = sort?.sortBy === key ? sort?.sortDirection : undefined;
        const handleColumnSort = (sortDirection: SortDirectionType) => {
            handleSort({
                sortBy: key as S,
                sortDirection,
            });
        };

        return (
            <Cell width={column.width} flex={column.flexGrow} key={key}>
                <SortFilterDataTableHeaderCell
                    column={column}
                    sortDirection={sortDirection}
                    handleSort={sortableColumn ? handleColumnSort : undefined}
                    filterConfig={filterConfig}
                    filterValue={filterState[key]}
                    setFilter={v => setFilter(key, v)}
                />
            </Cell>
        );
    };

    return (
        <ContainerBox borderBottom minWidth={minWidth} bg={Color.BackgroundDefault}>
            <Box row paddingLeft={horizontalPadding} paddingRight={horizontalPadding}>
                {columns.map(renderHeaderCell)}
            </Box>
        </ContainerBox>
    );
};
