import { useQuery } from '@tanstack/react-query';

import { addonsService } from '../service/addonsService';
import { AddonDto } from '../service/types/AddonDto';
import { addonsCacheKey } from './cacheKey';

const emptyAdminAddons: AddonDto[] = [];
export const useAddonsQuery = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [addonsCacheKey],
        queryFn: addonsService.listAddons,
    });
    return {
        data: data || emptyAdminAddons,
        isLoading,
        isError,
    };
};
