import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { journalsService } from '../service/journalsService';
import { AdminJournalsFilters } from '../service/types/AdminJournalsFilters';
import { JournalDto } from '../service/types/JournalDto';
import { journalsCacheKey } from './journalsCacheKey';

export const useJournals = (filters: AdminJournalsFilters, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [journalsCacheKey, filters],
        queryFn: param =>
            journalsService.listJournals(filters, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const journals: JournalDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        journals,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
