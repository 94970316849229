import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { UserDto } from '@hofy/api-admin';
import { EmailDto, useTrEmailType, useTrRole } from '@hofy/api-shared';
import { CountryLabel, ItPolicyItem } from '@hofy/core';
import { isPassportAndDateOfBirthNeeded, isTaxIdCountry } from '@hofy/global';
import { formatDate, formatDateTime } from '@hofy/helpers';
import {
    Badge,
    BooleanBadge,
    Box,
    FormGridRow,
    FormSection,
    Labeled,
    LabeledText,
    Paragraph3,
} from '@hofy/ui';
import { UserStatusChip } from '@hofy/ui-domain';

import { LinkType, ZendeskSection } from '../../../../components/design/zendesk/ZendeskSection';
import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { clientLink, LabeledDeelLink, workersLink } from '../../../../components/routing/LabeledDeelLink';
import { LabeledOrganizationLink } from '../../../organizationsPage/OrganizationDetailsLink';
import { UserLink } from '../UserLink';

interface DetailsTabProps {
    user: UserDto;
}

export const DetailsTab: FC<DetailsTabProps> = ({ user }) => {
    const trRole = useTrRole();
    const trEmailType = useTrEmailType();
    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20} column gap={30}>
                <FormSection label='Account'>
                    <LabeledText label='Id' content={user.id} />
                    <FormGridRow columns={2}>
                        <LabeledOrganizationLink organization={user.organization} />
                        <Labeled label='Team' content={<ItPolicyItem itPolicy={user.itPolicy} />} />
                        <Labeled label='Status' content={<UserStatusChip status={user.status} />} />
                        <LabeledText label='First login' content={formatDateTime(user.firstLogin)} />
                        <Labeled
                            label='Slack user matched'
                            content={<BooleanBadge value={!!user.slackId} offColor='grey' />}
                        />
                    </FormGridRow>
                    <Labeled
                        label='Roles'
                        content={
                            <Box row wrap marginBottom={-10}>
                                {user.roles.map(role => (
                                    <Badge
                                        key={role}
                                        color='grape'
                                        label={trRole(role)}
                                        marginRight={10}
                                        marginBottom={10}
                                    />
                                ))}
                            </Box>
                        }
                    />
                </FormSection>
                <AuditSection model={user} />
                <FormSection label='Localization'>
                    <FormGridRow columns={2}>
                        <Labeled label='Country' content={<CountryLabel country={user.address.country} />} />
                        <LabeledText label='Address' content={<PostAddressView address={user.address} />} />
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        {isTaxIdCountry(user.address.country) && (
                            <LabeledText flex={1} label='Tax number' content={user.address.taxIdNumber} />
                        )}
                        {isPassportAndDateOfBirthNeeded(user.address.country) && (
                            <>
                                <LabeledText
                                    marginBottom={20}
                                    label='Passport number'
                                    content={user.address.passportNumber}
                                />
                                <LabeledText
                                    label='Date of birth'
                                    content={formatDate(user.address.dateOfBirth)}
                                />
                            </>
                        )}
                        <LabeledText
                            marginBottom={20}
                            label='Phone number'
                            content={user.address.phoneNumber || '---'}
                        />
                    </FormGridRow>
                </FormSection>
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                {user.emails.map((email, i) => (
                    <FormSection key={i} label={trEmailType(email.type)}>
                        <EmailSection key={email.address} email={email} />
                    </FormSection>
                ))}
                {user.identities.length > 1 && (
                    <FormSection label='Identity'>
                        <Labeled
                            label='Shared identity'
                            content={
                                <Box row gap={8}>
                                    {user.identities.map((identity, i) => (
                                        <Paragraph3 key={i}>
                                            <UserLink id={identity.user.id}>
                                                {identity.organization.name}
                                            </UserLink>
                                        </Paragraph3>
                                    ))}
                                </Box>
                            }
                        />
                    </FormSection>
                )}
                <FormSection label='Deel'>
                    <FormGridRow columns={2}>
                        <LabeledDeelLink
                            label='HRIS Profile Id'
                            id={user.deel.hrisProfileId}
                            link={workersLink}
                        />
                        <LabeledDeelLink label='Profile Id' id={user.deel.profileId} link={clientLink} />
                        <LabeledText label='Last synced at' content={user.deel.lastUpdatedAt} />
                        <Labeled
                            label='Is shielded'
                            content={<BooleanBadge value={user.deel.isShielded} offColor='grey' />}
                        />
                    </FormGridRow>
                </FormSection>
                <ZendeskSection linkType={LinkType.UserPage} zendesk={user.zendesk} />
            </Box>
        </Box>
    );
};

interface EmailSectionProps {
    email: EmailDto;
}

const EmailSection: FC<EmailSectionProps> = ({ email }) => {
    return (
        <FormGridRow columns={2}>
            <LabeledText label='Email' content={email.address} />
            <LabeledText label='Sign-in provider' content={email.signInProvider} />
            <LabeledText label='Last login' content={formatDateTime(email.lastLoginAt)} />
            <LabeledText label='Invited at' content={formatDateTime(email.invitedAt)} />
            <LabeledText label='Merge sent at' content={formatDateTime(email.mergeEmailSendAt)} />
            <LabeledText
                label='Verification sent at'
                content={formatDateTime(email.verificationEmailSentAt)}
            />
            <Labeled flex={1} label='Is login' content={<BooleanBadge value={email.loginEnabled} />} />
        </FormGridRow>
    );
};
