import React, { FC } from 'react';

import { Badge, Box } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface DropPointResultProps {
    canUseDropPoint: boolean;
}

export const DropPointResult: FC<DropPointResultProps> = ({ canUseDropPoint }) => {
    const label = canUseDropPoint ? 'Yes' : 'No';
    return (
        <SurveyResultsSectionCardChip
            title='Drop-off point'
            status={SurveyResultsSectionCardChipStatus.Neutral}
        >
            <Box row>
                <Badge label={label} color='violet' />
            </Box>
        </SurveyResultsSectionCardChip>
    );
};
