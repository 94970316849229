import React, { FC } from 'react';

import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, MarginBoxProps, Paragraph3, SectionTitle2, TestKeyAware } from '@hofy/ui';

interface TotalPurchaseOrderValueProps extends MarginBoxProps, TestKeyAware {
    label: string;
    subLabel?: string;
    value?: Price;
    width?: number | string;
}

export const PurchaseOrderSummaryLine: FC<TotalPurchaseOrderValueProps> = ({
    label,
    subLabel,
    value,
    width = 'auto',
    ...margins
}) => {
    const { formatPrice } = usePrice();

    return (
        <Box
            paddingVertical={10}
            paddingHorizontal={10}
            width={width}
            flex='auto'
            direction='row'
            bg={Color.Highlight}
            rounded={4}
            {...margins}
        >
            <Box column paddingHorizontal={15}>
                <SectionTitle2 color={Color.Neutral500}>{label}</SectionTitle2>
                {subLabel && <Paragraph3 color={Color.Neutral300}>{subLabel}</Paragraph3>}
            </Box>
            <Box flex='auto' />
            <Paragraph3 marginRight={20}>{formatPrice(value)}</Paragraph3>
        </Box>
    );
};
