import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { Sort } from '@hofy/global';
import { useDebounceValue } from '@hofy/hooks';

import { assignmentService } from '../service/assignmentService';
import { AdminAssignmentFilters } from '../service/types/AdminAssignmentFilters';
import { AssignmentsPageTab } from '../service/types/AssignmentsPageTab';
import { assignmentsCacheKey } from './cacheKey';

export const useAssignmentsByShipmentQuery = <S extends string>(
    tab: AssignmentsPageTab,
    filters: AdminAssignmentFilters,
    sort: Sort<S>,
) => {
    const {
        data,
        isLoading,
        hasNextPage: userAssignmentsHasNextPage,
        isFetchingNextPage: userAssignmentsIsFetchingNextPage,
        fetchNextPage,
    } = useInfiniteQuery({
        queryKey: [assignmentsCacheKey, tab, filters, sort],
        queryFn: param =>
            assignmentService.listAssignmentsByShipment(tab, filters, sort, {
                page: param.pageParam,
                pageSize: 10,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const userAssignments = useMemo(() => data?.pages.flatMap(v => v.content) || [], [data]);
    const userAssignmentsIsLoading = useDebounceValue(isLoading, 500);

    return {
        userAssignments,
        userAssignmentsHasNextPage,
        userAssignmentsIsLoading: userAssignmentsIsLoading || isLoading,
        userAssignmentsIsFetchingNextPage,
        fetchNextPage,
        filters,
    };
};
