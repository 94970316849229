import { ItemGrade } from '@hofy/api-shared';
import { DateString, UUID, zeroUuid } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';

export interface ReceivePurchaseOrderItemFormData {
    purchaseOrderItemId: UUID;
    scansLeft: number;
    notes: string;
    receivedItems: ReceivePurchaseOrderItemDetailFormData[];
}

export interface ReceivePurchaseOrderItemDetailFormData {
    itemCode: string;
    serialNumber: string | null;
    receivedOn: DateString;
    itemGrade: ItemGrade;
    focused?: boolean;
}

export const emptyReceivePurchaseOrderItemDetail: ReceivePurchaseOrderItemDetailFormData = {
    itemCode: '',
    serialNumber: null,
    receivedOn: nowISODate(),
    itemGrade: ItemGrade.New,
};
export const emptyReceivePurchaseOrderItem: ReceivePurchaseOrderItemFormData = {
    purchaseOrderItemId: zeroUuid,
    scansLeft: 1,
    receivedItems: [emptyReceivePurchaseOrderItemDetail],
    notes: '',
};
