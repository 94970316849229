import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { pathUuid } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { AssignmentSlideoutRouter } from '../../assignmentSlideout/AssignmentSlideoutRouter';
import { ShipmentSlideoutRouter } from '../../shipmentSlideout/ShipmentSlideoutRouter';

interface UserSlideoutRouterProps {
    ordersBasePath: string;
}

export const UserSlideoutRouter: FC<UserSlideoutRouterProps> = ({ ordersBasePath }) => {
    return (
        <Switch>
            <SlideoutRoute path={`${ordersBasePath}/:assignmentId(${pathUuid})/shipment`}>
                {({ assignmentId }) => (
                    <ShipmentSlideoutRouter
                        base={`${ordersBasePath}/${assignmentId}/shipment`}
                        assignmentsBase={ordersBasePath}
                        onCloseBase={ordersBasePath}
                    />
                )}
            </SlideoutRoute>
            <SlideoutRoute path={`${ordersBasePath}/:assignmentId(${pathUuid})`}>
                {({ assignmentId }) => (
                    <AssignmentSlideoutRouter
                        base={ordersBasePath}
                        shipmentsBase={`${ordersBasePath}/${assignmentId}/shipment`}
                        onCloseBase={ordersBasePath}
                    />
                )}
            </SlideoutRoute>
        </Switch>
    );
};

const SlideoutRoute = UUIDRoute('assignmentId', Route);
