import React, { FC } from 'react';

import { Slideout, SlideoutHeader } from '@hofy/common';

import { useCreateAddon } from '../../../../store/addons/useCreateAddon';
import { AddonConfiguration } from './AddonConfiguration';

interface AddonCreateSlideoutProps {
    onClose(): void;
}

export const AddonCreateSlideout: FC<AddonCreateSlideoutProps> = ({ onClose }) => {
    const { form, isLoading, isError } = useCreateAddon(onClose);

    return (
        <Slideout width={1200} onClose={onClose}>
            <SlideoutHeader title='Create Addon' />
            <AddonConfiguration form={form} isLoading={isLoading} isError={isError} onClose={onClose} />
        </Slideout>
    );
};
