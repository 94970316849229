import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import {
    Box,
    DropdownList,
    Icon,
    MarginBoxProps,
    Paragraph3,
    Placement,
    ReactNodeFunction,
    TestKeyAware,
} from '@hofy/ui';

export interface MoreMenuItem extends TestKeyAware, MarginBoxProps {
    icon?: Svg;
    label: ReactNode;
    action(): void;
    color?: Color;
    visible?: boolean;
}

interface MoreMenuProps extends TestKeyAware {
    trigger: ReactNodeFunction<[isOpen: boolean]>;
    items: MoreMenuItem[];
    placement?: Placement;
    asChild?: boolean;
}

export const BaseMoreMenu: FC<MoreMenuProps> = ({
    items,
    trigger,
    placement = 'bottom-start',
    asChild,
    testKey,
    ...rest
}) => {
    return (
        <Box
            // This menu is sometimes used in a *clickable* table row, so we need to stop propagation
            onClick={e => e.stopPropagation()}
            {...rest}
        >
            <DropdownList<MoreMenuItem>
                trigger={trigger}
                options={items}
                value={null}
                onChange={item => item?.action()}
                toKey={item =>
                    item.testKey ||
                    (item.label === 'string' ? item.label.toString() : items.indexOf(item).toString())
                }
                toText={item => (typeof item.label === 'string' ? item.label : '')}
                toLabel={({ color = Color.ContentPrimary, icon, label }) => (
                    <Paragraph3 color={color || Color.ContentPrimary} row gap={8} textNoWrap>
                        {icon && <Icon svg={icon} color={color} size={16} />}
                        {label}
                    </Paragraph3>
                )}
                placement={placement}
                nullable
                asChild={asChild}
                testKey={testKey}
            />
        </Box>
    );
};
