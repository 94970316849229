import { AssignmentConfigurationStatus } from '@hofy/api-shared';

import { useAdminI18n } from '../../i18n/useAdminI18n';

export const useTrAssignmentConfigurationStatus = () => {
    const { trEnum } = useAdminI18n();
    return trEnum<AssignmentConfigurationStatus>(
        AssignmentConfigurationStatus,
        'assignment-configuration-status',
    );
};
