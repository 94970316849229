import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ChangeOwnershipPayload, itemCacheKey, ItemDto, itemsService } from '@hofy/api-admin';
import { Ownership } from '@hofy/api-shared';
import { isRequired, isRequiredIf, useForm, validator } from '@hofy/ui';

export const useChangeOwnership = (item: ItemDto, onEnd?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (v: ChangeOwnershipPayload) => itemsService.changeOwnership(item.id, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onEnd?.();
        },
    });

    const form = useForm<ChangeOwnershipPayload>({
        initial: {
            ownership: item.ownership.type,
            billingEntityId: item.billingEntity?.id ?? null,
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: validator<ChangeOwnershipPayload>({
            ownership: isRequired('Ownership is required'),
            billingEntityId: isRequiredIf(
                v => v.ownership === Ownership.Organization,
                'Billing entity is required',
            ),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
