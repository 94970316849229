import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import {
    emptySendOneOffPayload,
    eventCacheKey,
    notificationService,
    SendOneOffPayload,
} from '@hofy/api-admin';
import { errorMap } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

export const useSendOneOff = (onSend: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: notificationService.sendOneOff,
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: [eventCacheKey] });
        },
    });

    const form = useForm<SendOneOffPayload>({
        initial: emptySendOneOffPayload,
        onSubmit(formState) {
            mutation.mutate(formState);
            onSend();
        },
        validate: formState => ({
            message: errorMap([isEmpty(formState?.message), 'Message is required']),
            title: errorMap([isEmpty(formState?.title), 'Title is required']),
            roles: errorMap([formState?.roles.length === 0, 'At least one role is required']),
        }),
    });

    return {
        sendOneOff: mutation.mutate,
        isLoading: mutation.isPending,
        form,
    };
};
