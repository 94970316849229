import React, { FC } from 'react';

import { allRepairStatuses, getRepairStatusLabel, RepairStatus } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';

interface RepairStatusBlockFilterProps {
    selected: RepairStatus[];
    onChange(repairStatuses: RepairStatus[]): void;
}

export const RepairStatusBlockFilter: FC<RepairStatusBlockFilterProps> = ({ selected, onChange }) => {
    return (
        <ListMultiBlockFilter
            title='Repair status'
            icon={SvgIcon.Tool}
            selected={selected}
            onChange={onChange}
            items={allRepairStatuses}
            renderItem={getRepairStatusLabel}
            width={300}
        />
    );
};
