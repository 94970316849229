import React, { FC } from 'react';

import { PurchaseOrderRefDto } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';
import { Box, DropdownListSearch, FormContainer, Paragraph3 } from '@hofy/ui';

import { usePurchaseOrdersForReceipt } from '../../../../store/purchaseOrders/usePurchaseOrdersForReceipt';

interface PurchaseOrderSelectProps {
    selected: PurchaseOrderRefDto | null;
    onChange(purchaseOrder: PurchaseOrderRefDto): void;
}

export const PurchaseOrderSelect: FC<PurchaseOrderSelectProps> = ({ selected, onChange }) => {
    const { purchaseOrders, purchaseOrdersIsLoading } = usePurchaseOrdersForReceipt();

    if (purchaseOrdersIsLoading) {
        return <ComponentLoader />;
    }

    return (
        <Box marginVertical={20}>
            <FormContainer fullWidth>
                <Paragraph3>Which purchase order would you like to scan?</Paragraph3>
                <DropdownListSearch
                    options={purchaseOrders}
                    value={selected}
                    onChange={onChange}
                    toText={purchaseOrder => purchaseOrder.purchaseOrderReference}
                    triggerPlaceholder='Filter purchase orders'
                    contentWidth={300}
                />
            </FormContainer>
        </Box>
    );
};
