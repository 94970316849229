import React, { FC } from 'react';

import { getShipmentStatusDate, ShipmentDetailsDto } from '@hofy/api-admin';
import { allShipmentStatuses, useTrShipmentStatus } from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';

interface ShipmentStatusHistoryProps {
    shipment: ShipmentDetailsDto;
}

export const ShipmentStatusHistory: FC<ShipmentStatusHistoryProps> = ({ shipment }) => {
    const trShipmentStatus = useTrShipmentStatus();
    return (
        <StatusHistory
            allStatuses={allShipmentStatuses}
            dateGetter={s => getShipmentStatusDate(shipment, s)}
            labelFormatter={trShipmentStatus}
        />
    );
};
