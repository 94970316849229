import { useQuery } from '@tanstack/react-query';

import { authService } from '../service/authService';

export const sessionCacheKey = 'auth/session';

export const useSessionQuery = () => {
    const { data, isLoading } = useQuery({
        queryKey: [sessionCacheKey],
        queryFn: authService.getSession,
        refetchOnWindowFocus: false,
        retry: false,
    });

    return {
        session: data,
        isInitialized: !isLoading,
    };
};
