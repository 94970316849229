import { getEnumValues } from '@hofy/global';

export enum AccountKey {
    AccruedPurchases = 'accrued_purchases',
    AccountsPayable = 'accounts_payable',
    InventoryStockHeldForSale = 'inventory_stock_held_for_sale',
    InventoryInTransit = 'inventory_in_transit',
    InventoryAdjustment = 'inventory_adjustment',
    RentalAssetsAdditions = 'rental_assets_additions',
    RentalAssetsDepreciationCharge = 'rental_assets_depreciation_charge',
    CogsRentalAssetsDepreciation = 'cogs_rental_assets_depreciation',
    CogsRentalAssetsWriteOff = 'cogs_rental_assets_write_off',
    CogsRentalAssetsWriteDown = 'cogs_rental_assets_write_down',
    CogsInterCompanySale = 'cogs_inter_company_sale',
    CogsLogistics = 'cogs_logistics',
    HofyAssetsAdditions = 'hofy_assets_additions',
    HofyAssetsDepreciationCharge = 'hofy_assets_depreciation_charge',
    ExpenseHofyAssetsDepreciation = 'expense_hofy_assets_depreciation',
    ExpenseHofyAssetsWriteOff = 'expense_hofy_assets_write_off',
    ExpenseHofyAssetsWriteDown = 'expense_hofy_assets_write_down',
    CogsEquipmentClearance = 'cogs_equipment_clearance',
    CogsEquipmentSale = 'cogs_equipment_sale',
    CogsEndOfContractEquipmentSale = 'cogs_end_of_contract_equipment_sale',
    AccountsReceivable = 'accounts_receivable',
    InventoryWriteOff = 'inventory_write_off',
    DeferredIncome = 'deferred_income',
    RevenueSaas = 'revenue_saas',
    RevenueMdm = 'revenue_mdm',
    RevenueRental = 'revenue_rental',
    RevenueServiceBundle = 'revenue_service_bundle',
    RevenueExtendedWarranty = 'revenue_extended_warranty',
    RevenueStorage = 'revenue_storage',
    RevenueClearance = 'revenue_clearance',
    BankGbpHofyLtd = 'bank_gbp_hofy_ltd',
    BankEurHofyLtd = 'bank_eur_hofy_ltd',
    BankUsdHofyLtd = 'bank_usd_hofy_ltd',
    BankEurHofyBv = 'bank_eur_hofy_bv',
    BankGbpHofyBv = 'bank_gbp_hofy_bv',
    BankUsdHofyBv = 'bank_usd_hofy_bv',
    BankUsdHofyInc = 'bank_usd_hofy_inc',
    BankEurHofyInc = 'bank_eur_hofy_inc',
    BankGbpHofyInc = 'bank_gbp_hofy_inc',
    StripeHofyLtd = 'stripe_hofy_ltd',
    StripeHofyBv = 'stripe_hofy_bv',
    StripeHofyInc = 'stripe_hofy_inc',
    BankFeesStripe = 'bank_fees_stripe',
    TwoRecourseRepayment = 'two_recourse_repayment',
    TwoNonRecourseRepayment = 'two_non_recourse_repayment',
    TwoApiBankFees = 'two_api_bank_fees',
    TwoSpreadsheetBankFees = 'two_spreadsheet_bank_fees',
    TwoAdvanceGross = 'two_advance_gross',
    TwoAdvanceFees = 'two_advance_fees',
}

export const allAccountKeys = getEnumValues<AccountKey>(AccountKey);
