import React, { FC } from 'react';

import { ProductCategory, useTrProductCategory } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, SectionTitle3, Span } from '@hofy/ui';

import { RefurbishedBadge } from '../assignments/badge/RefurbishedBadge';
import { InactiveBadge } from './InactiveBadge';

interface Image {
    id: UUID;
    url: string;
    isActive: boolean;
}

interface ProductOverviewProps {
    imageSize?: number;
    product: {
        brand: string;
        name: string;
        isRefurbished: boolean;
        category: ProductCategory;
        variants?: { id: UUID }[];
    };
    images?: Image[] | Image;
    showCategory?: boolean;
    showVariants?: boolean;
    verticalLayout?: boolean;
    inactive?: boolean;
}

export const ProductOverview: FC<ProductOverviewProps> = ({
    product,
    images,
    imageSize = 80,
    showCategory = false,
    showVariants = false,
    verticalLayout = false,
    inactive = false,
}) => {
    const trCategory = useTrProductCategory();

    return (
        <Box row={!verticalLayout}>
            <ProductImage image={images} size={imageSize} marginRight={verticalLayout ? 0 : 30} />
            <Box marginTop={verticalLayout ? 20 : 0} column gap={4}>
                <Box row gap={8}>
                    {inactive && <InactiveBadge />}
                    <SectionTitle3>{product.name}</SectionTitle3>
                </Box>
                {showCategory && (
                    <Paragraph3 color={Color.ContentTertiary}>{trCategory(product.category)}</Paragraph3>
                )}
                <Box row gap={8}>
                    <Paragraph3>
                        <Span color={Color.ContentTertiary}>By</Span> {product.brand}
                    </Paragraph3>
                    {product.isRefurbished && <RefurbishedBadge />}
                </Box>

                {showVariants && (
                    <Paragraph3 color={Color.ContentTertiary}>
                        {product.variants?.length || 0} variants
                    </Paragraph3>
                )}
            </Box>
        </Box>
    );
};
