import { ColorDye, getEnumValues } from '@hofy/global';

import { OrderType } from './OrderType';

export enum AssignmentStatus {
    DeliveryPending = 'delivery_pending',
    DeliveryWithCourier = 'delivery_with_courier',
    WithUser = 'with_user',
    CollectionPending = 'collection_pending',
    CollectionWithCourier = 'collection_with_courier',
    Collected = 'collected',
    Cancelled = 'cancelled',
}

export const allAssignmentStatuses = getEnumValues<AssignmentStatus>(AssignmentStatus);

export const activeAssignmentStatuses = [AssignmentStatus.WithUser, AssignmentStatus.CollectionPending];
export const pendingAssignmentStatuses = [
    AssignmentStatus.DeliveryPending,
    AssignmentStatus.DeliveryWithCourier,
    AssignmentStatus.CollectionPending,
    AssignmentStatus.CollectionWithCourier,
];

export const activeOrPendingAssignmentStatuses = [
    AssignmentStatus.DeliveryPending,
    AssignmentStatus.DeliveryWithCourier,
    AssignmentStatus.WithUser,
    AssignmentStatus.CollectionPending,
    AssignmentStatus.CollectionWithCourier,
];

export const deliveryAssignmentStatuses = [
    AssignmentStatus.DeliveryPending,
    AssignmentStatus.DeliveryWithCourier,
    AssignmentStatus.WithUser,
];

export const collectionPendingAssignmentStatuses = [AssignmentStatus.CollectionPending];

export const allFilterableAssignmentStatuses = [
    AssignmentStatus.DeliveryPending,
    AssignmentStatus.WithUser,
    AssignmentStatus.CollectionPending,
    AssignmentStatus.Collected,
    AssignmentStatus.Cancelled,
];

export const assignmentStatusToFilterableStatus = (status: AssignmentStatus): AssignmentStatus => {
    switch (status) {
        case AssignmentStatus.DeliveryPending:
        case AssignmentStatus.DeliveryWithCourier:
            return AssignmentStatus.DeliveryPending;
        case AssignmentStatus.WithUser:
            return AssignmentStatus.WithUser;
        case AssignmentStatus.CollectionPending:
        case AssignmentStatus.CollectionWithCourier:
            return AssignmentStatus.CollectionPending;
        case AssignmentStatus.Cancelled:
            return AssignmentStatus.Cancelled;
        case AssignmentStatus.Collected:
            return AssignmentStatus.Collected;
    }
};

export const assignmentTypeFromStatus = (status: AssignmentStatus) =>
    deliveryAssignmentStatuses.includes(status) ? OrderType.Delivery : OrderType.Collection;

export const assignmentStatusColor: Record<AssignmentStatus, ColorDye> = {
    [AssignmentStatus.DeliveryPending]: 'orange',
    [AssignmentStatus.DeliveryWithCourier]: 'blue',
    [AssignmentStatus.WithUser]: 'green',
    [AssignmentStatus.CollectionPending]: 'grape',
    [AssignmentStatus.CollectionWithCourier]: 'blue',
    [AssignmentStatus.Collected]: 'grey',
    [AssignmentStatus.Cancelled]: 'red',
};

export const isDeliveryPending = (status: AssignmentStatus) =>
    [AssignmentStatus.DeliveryPending, AssignmentStatus.DeliveryWithCourier].includes(status);

export const isCollectionPending = (status: AssignmentStatus) =>
    [AssignmentStatus.CollectionPending, AssignmentStatus.CollectionWithCourier].includes(status);

export const isWithUserOrCollectionPending = (status: AssignmentStatus) =>
    status === AssignmentStatus.WithUser || status === AssignmentStatus.CollectionPending;
