import React, { FC, useState } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { coreCurrencies } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import {
    AsyncButton,
    FormContainer,
    LabeledSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useUpdateBillingEntityCurrency } from '../../../../store/organizations/useUpdateBillingEntityCurrency';

interface UpdateCurrencyModalProps {
    billingEntity: BillingEntityDto;

    onClose(): void;
}

export const UpdateCurrencyModal: FC<UpdateCurrencyModalProps> = ({ billingEntity, onClose }) => {
    const { updateCurrency, isLoading, isError } = useUpdateBillingEntityCurrency(billingEntity.id, onClose);
    const onSubmit = () => updateCurrency(currency);

    const [currency, setCurrency] = useState(billingEntity.currency);

    const trCurrency = useTrCurrency();
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={tr('billing-entity-page.currency-update-modal.header')} />
            <ModalContent>
                <FormContainer>
                    <LabeledSelect
                        options={coreCurrencies}
                        label={tr('billing-entity-page.currency-update-modal.currency-field')}
                        toText={trCurrency}
                        value={currency}
                        onChange={setCurrency}
                        isRequired
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label={tr('billing-entity-page.currency-update-modal.confirm')}
                    disabled={currency === billingEntity.currency}
                    disableCheck
                    onClick={onSubmit}
                    isLoading={isLoading}
                    isError={isError}
                />
            </ModalFooter>
        </Modal>
    );
};
