import { identity } from 'lodash';
import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import {
    allItemGrades,
    getProductCategoryParts,
    isCategoryConsumable,
    isDeviceCategory,
    ItemPart,
    Permission,
    useTrItemPart,
} from '@hofy/api-shared';
import { FormRow, RadioGroup } from '@hofy/common';
import { Currency } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import {
    CheckboxGroup,
    FormContainer,
    FormInput,
    FormModal,
    FormPriceInput,
    FormSection,
    FormSegment,
    FormSelect,
    FormSwitch,
    FormTextarea,
    Labeled,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useTrItemGrade } from '../../../store/items/useTrItemGrade';
import { useUpdateItem } from '../../../store/items/useUpdateItem';
import { UpdateLocationForm } from './UpdateLocationForm';

interface UpdateItemModalProps {
    item: ItemDto;
    onClose(): void;
}

export const UpdateItemModal: FC<UpdateItemModalProps> = ({ item, onClose }) => {
    const { form, isLoading, isError, warehouses, isLocationEditable } = useUpdateItem(item, onClose);
    const trItemGrade = useTrItemGrade();
    const trItemPart = useTrItemPart();
    const { hasPermission } = usePermission();

    return (
        <FormModal
            width={600}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='Update item' />
            <ModalContent>
                <FormContainer>
                    <FormSection label='Details'>
                        <FormRow>
                            <FormInput
                                label='Serial number'
                                api={form.fields.serialNumber}
                                disabled={isCategoryConsumable(item.product.category)}
                                nullable
                            />
                        </FormRow>
                        <FormRow>
                            <FormSwitch label='Usable as loaner' api={form.fields.usableAsLoaner} />
                            <FormSwitch
                                label='Usable in store and reuse'
                                api={form.fields.usableInStoreAndReuse}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection label='Condition'>
                        <FormSelect
                            label='Grade'
                            api={form.fields.grade}
                            toText={trItemGrade}
                            options={allItemGrades}
                        />
                        <FormTextarea
                            label='Damage description (if any)'
                            api={form.fields.damageNote ?? ''}
                            nullable
                        />
                        <Labeled
                            label='Damaged parts/accessories'
                            content={
                                <CheckboxGroup
                                    options={getProductCategoryParts(item.product.category)}
                                    onChange={damagedParts =>
                                        form.setValues({
                                            damagedParts,
                                        })
                                    }
                                    value={form.values.damagedParts}
                                    toKey={identity}
                                    toLabel={trItemPart}
                                />
                            }
                        />
                        {form.values.damagedParts.includes(ItemPart.Other) && (
                            <FormTextarea
                                label='Damaged part/accessory description'
                                api={form.fields.damagedPartsNote ?? ''}
                                nullable
                                isRequired
                            />
                        )}
                        <Labeled
                            label='Missing parts/accessories'
                            content={
                                <CheckboxGroup
                                    options={getProductCategoryParts(item.product.category)}
                                    onChange={missingParts =>
                                        form.setValues({
                                            missingParts,
                                        })
                                    }
                                    value={form.values.missingParts}
                                    toKey={v => v}
                                    toLabel={trItemPart}
                                />
                            }
                        />
                        {form.values.missingParts.includes(ItemPart.Other) && (
                            <FormTextarea
                                label='Missing part/accessory description'
                                api={form.fields.missingPartsNote ?? ''}
                                nullable
                                isRequired
                            />
                        )}
                        <Labeled
                            label='Is missing original packaging'
                            content={
                                <RadioGroup
                                    orientation='horizontal'
                                    gap={10}
                                    items={binaryOptions}
                                    onChange={v =>
                                        form.setValues({
                                            isMissingOriginalPackaging: v === 'Yes',
                                        })
                                    }
                                    value={form.values.isMissingOriginalPackaging ? 'Yes' : 'No'}
                                    labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                                />
                            }
                        />
                        {isDeviceCategory(item.product.category) && (
                            <Labeled
                                label='Is device locked'
                                content={
                                    <RadioGroup
                                        orientation='horizontal'
                                        gap={10}
                                        items={binaryOptions}
                                        onChange={v =>
                                            form.setValues({
                                                isDeviceLocked: v === 'Yes',
                                            })
                                        }
                                        value={form.values.isDeviceLocked ? 'Yes' : 'No'}
                                        labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                                    />
                                }
                            />
                        )}
                    </FormSection>

                    <FormSection label='Location'>
                        <FormSegment disabled={!isLocationEditable} gap={20} column>
                            <UpdateLocationForm
                                canAddWarehouseBin={hasPermission(Permission.AdminWarehouseManage)}
                                warehouses={warehouses}
                                form={form}
                            />
                        </FormSegment>
                    </FormSection>
                    <FormSection label='Clearance'>
                        <FormRow>
                            <FormPriceInput
                                label='Quote'
                                api={form.fields.clearanceQuote}
                                defaultCurrency={item.currentValue?.currency || Currency.GBP}
                                nullable
                                clearable
                            />
                            <FormPriceInput
                                label='Value'
                                api={form.fields.clearanceValue}
                                defaultCurrency={item.currentValue?.currency || Currency.GBP}
                                nullable
                                clearable
                            />
                        </FormRow>
                    </FormSection>
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Update' />
            </ModalFooter>
        </FormModal>
    );
};

const binaryOptions = ['No' as const, 'Yes' as const];
