import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import { useAdminsRefsQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { LabeledSelectSearch, TestKeyAware } from '@hofy/ui';

interface AdminListDropdownProps extends TestKeyAware {
    value?: UUID;
    label: string;
    onChange(o: UUID | undefined): void;
    emptyContent?: string;
    isRequired?: boolean;
}

export const AdminListDropdown: FC<AdminListDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent,
    isRequired,
}) => {
    const { data: admins } = useAdminsRefsQuery();
    const nameMap = useMemo(() => keyBy(admins, v => v.id), [admins]);
    const toLabel = (v: UUID) => {
        const admin = nameMap[v];
        return admin ? `${admin.firstName} ${admin.lastName}` : '';
    };
    return (
        <LabeledSelectSearch
            options={admins.map(v => v.id)}
            toText={toLabel}
            onChange={i => onChange(i)}
            label={label}
            value={value}
            placeholder={emptyContent}
            isRequired={isRequired}
        />
    );
};
