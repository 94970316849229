import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { LabeledLink } from '@hofy/ui';

import { Link } from '../../components/routing/Link';

interface ProductDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const ProductDetailsLink: FC<ProductDetailsLinkProps> = ({ id, children }) => {
    return <Link to={`/products/${id}/detail`}>{children}</Link>;
};

interface LabeledProductLinkProps {
    product: PublicRefDto | null;
    label?: string;
}

export const LabeledProductLink: FC<LabeledProductLinkProps> = ({ label = 'Product', product }) => {
    return (
        <LabeledLink
            label={label}
            content={
                product ? <ProductDetailsLink id={product.id}>{product.publicId}</ProductDetailsLink> : '--'
            }
        />
    );
};
