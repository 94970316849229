import { FloatingArrow } from '@floating-ui/react';
import React, { ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, Paragraph4, SectionTitle3 } from '../base';
import { TooltipOptions, useTooltip } from './hooks/useTooltip';
import { TooltipContent } from './TooltipContent';
import { TooltipTrigger } from './TooltipTrigger';

export interface TooltipProps extends TooltipOptions {
    title?: string;
    body?: ReactNode;
    bodySlot?: ReactNode;

    children?: ReactNode;
    enabled?: boolean;
    /** You can interact with the tooltip content, normally has pointer-events: none */
    interactive?: boolean;
    maxWidth?: number | 'auto';
    /**
     * Allows pass any element as the anchor without the wrapper
     * WARNING: element must accept `ref` (native element like `div` or component with `forwardRef`)
     * */
    asChild?: boolean;
}

export const Tooltip = ({
    title,
    body,
    bodySlot,
    children,
    placement,
    enabled = true,
    interactive = false,
    maxWidth = 220,
    asChild,
}: TooltipProps) => {
    const state = useTooltip({ placement, interactive });

    if (!enabled || (!title && !body && !bodySlot)) {
        return <>{children}</>;
    }

    return (
        <>
            <TooltipTrigger state={state} asChild={asChild}>
                {children}
            </TooltipTrigger>
            {state.open && (
                <TooltipContent interactive={interactive} state={state}>
                    <Box
                        bg={Color.InteractionBackgroundInvertedModeless}
                        paddingHorizontal={8}
                        paddingVertical={4}
                        rounded={4}
                        maxWidth={maxWidth}
                    >
                        {title && (
                            <SectionTitle3 color={Color.ContentInvertedPrimary} marginBottom={2}>
                                {title}
                            </SectionTitle3>
                        )}
                        {body && (
                            <Paragraph4 color={Color.ContentInvertedPrimary} whiteSpace='pre-line'>
                                {body}
                            </Paragraph4>
                        )}
                        {bodySlot}
                    </Box>
                    <FloatingArrow
                        ref={state.arrowRef}
                        context={state.data.context}
                        fill={Color.InteractionBackgroundInvertedModeless}
                        width={12}
                        height={6}
                        tipRadius={3}
                    />
                </TooltipContent>
            )}
        </>
    );
};
