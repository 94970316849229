import { ColorDye, getEnumValues } from '@hofy/global';

export enum TwoCreditDecision {
    Approved = 'approved',
    Declined = 'declined',
}

export const twoCreditDecisionColors: Record<TwoCreditDecision, ColorDye> = {
    [TwoCreditDecision.Approved]: 'green',
    [TwoCreditDecision.Declined]: 'red',
};

export const allTwoCreditDecisions = getEnumValues<TwoCreditDecision>(TwoCreditDecision);
