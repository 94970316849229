import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { repaymentPlanService } from '../service/repaymentPlanService';
import { RepaymentPlanDto } from '../service/types/RepaymentPlanDto';
import { RepaymentPlansFilters } from '../service/types/RepaymentPlansFilters';
import { repaymentPlansCacheKey } from './cacheKey';

export const useRepaymentPlansQuery = (filters: RepaymentPlansFilters) => {
    const response = useInfiniteQuery({
        queryKey: [repaymentPlansCacheKey, filters],
        queryFn: param =>
            repaymentPlanService.listRepaymentPlans(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });
    const repaymentPlans: RepaymentPlanDto[] = useMemo(() => {
        if (response.data === undefined) {
            return [];
        }

        return response.data.pages.flatMap(v => v.content);
    }, [response.data]);

    return {
        response,
        repaymentPlans,
    };
};
