import React, { FC } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { hofySubsidiaryCount, twoStatusColors, TwoSyncStatus } from '@hofy/api-shared';
import { Badge, Span, Tooltip } from '@hofy/ui';

import { useTrTwoSyncStatus } from '../../../../store/two/useTrTwoSyncStatus';

interface TwoStatusChipProps {
    billingEntity: BillingEntityDto;
}

export const TwoStatusChip: FC<TwoStatusChipProps> = ({ billingEntity }) => {
    const trStatus = useTrTwoSyncStatus();
    if (!billingEntity.twoApiEnabled) {
        return null;
    }
    const errors = billingEntity.twoStatuses.filter(i => i.status === TwoSyncStatus.Error);
    if (errors.length > 0) {
        return (
            <Tooltip body={<Span wordBreak>{errors[0].errorReason}</Span>}>
                <Badge color={twoStatusColors[TwoSyncStatus.Error]} label={trStatus(TwoSyncStatus.Error)} />
            </Tooltip>
        );
    }
    if (billingEntity.twoStatuses.length === 0) {
        return (
            <Badge color={twoStatusColors[TwoSyncStatus.Pending]} label={trStatus(TwoSyncStatus.Pending)} />
        );
    }
    if (billingEntity.twoStatuses.length < hofySubsidiaryCount) {
        return (
            <Badge
                color={twoStatusColors[TwoSyncStatus.PartiallySynced]}
                label={trStatus(TwoSyncStatus.PartiallySynced)}
            />
        );
    }
    const status = billingEntity.twoStatuses[0].status || TwoSyncStatus.Pending;
    return <Badge color={twoStatusColors[status]} label={trStatus(status)} />;
};
