import React, { FC } from 'react';

import { InvoiceEntryDto } from '@hofy/api-admin';
import { formatDate, formatTwoDates } from '@hofy/helpers';
import { useDecimal, usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    BooleanBadge,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
    Tooltip,
    TwoLineSmallCell,
} from '@hofy/ui';

import { SubsidiaryCell } from '../../../../components/domain/contracts/SubsidiaryCell';
import { ContractLinkLabel } from '../../../contractsPage/ContractLink';
import { RepaymentStatusLabel } from '../../repaymentPlans/components/RepaymentStatusLabel';
import { RepaymentPlanLink } from '../../repaymentPlans/RepaymentPlanLink';
import { TaxCell, TaxStatusCell } from '../invoiceEntryDetailsOverlay/TaxCell';
import { InvoiceEntryTypeChip } from './InvoiceEntryTypeChip';
import { InvoicingChip } from './InvoicingStatusChip';

interface InvoiceEntriesTableProps {
    entries: InvoiceEntryDto[];
    infinityScroll: InfiniteScrollConfig;
    onRowClick?(entry: InvoiceEntryDto): void;
}

export const InvoiceEntriesTable: FC<InvoiceEntriesTableProps> = ({
    entries,
    infinityScroll,
    onRowClick,
}) => {
    const { formatPrice } = usePrice();
    const { formatPercent } = useDecimal();

    return (
        <InfinityScrollTable
            data={entries}
            toKey={entry => entry.id}
            infinityScroll={infinityScroll}
            onRowClick={onRowClick}
            emptyContent={
                <Placeholder illustration={SvgIllustration.FinanceSearch} title='No invoice entries' />
            }
            minWidth={1200}
            rowStyle={t =>
                t.isDeleted
                    ? {
                          backgroundColor: Color.BackgroundSubtleNegative,
                      }
                    : {}
            }
            columns={[
                {
                    id: 'ref',
                    header: 'Ref',
                    width: 120,
                    flexGrow: 0,
                    renderer: entry => `#${entry.ref}`,
                },
                {
                    id: 'contract',
                    header: 'Contract',
                    flexGrow: 0,
                    width: 140,
                    renderer: entry =>
                        entry.contract ? (
                            <TwoLineSmallCell
                                line1={<ContractLinkLabel id={entry.contract} />}
                                line2={`${
                                    entry.contract.rentalContract?.publicId ||
                                    entry.contract.purchaseContract?.publicId ||
                                    entry.contract.managementContract?.publicId
                                }`}
                            />
                        ) : (
                            '--'
                        ),
                },
                {
                    id: 'type',
                    header: 'Type',
                    flexGrow: 0,
                    flexShrink: 0,
                    width: 160,
                    renderer: entry => <InvoiceEntryTypeChip status={entry.type} />,
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 0,
                    width: 180,
                    renderer: entry => <SubsidiaryCell entry={entry} />,
                },
                {
                    id: 'invoice_time',
                    header: 'Invoice date',
                    flexGrow: 0,
                    width: 160,
                    renderer: entry => (
                        <TwoLineSmallCell
                            line1={formatDate(entry.invoiceTime)}
                            line2={formatTwoDates(entry.invoicePeriodFrom, entry.invoicePeriodTo)}
                        />
                    ),
                },
                {
                    id: 'description',
                    header: 'Description',
                    flexGrow: 1,
                    renderer: entry => (
                        <Tooltip body={entry.description} interactive asChild>
                            <Paragraph3 color={Color.ContentPrimary} textClomp={2}>
                                {entry.description}
                            </Paragraph3>
                        </Tooltip>
                    ),
                },
                {
                    id: 'unit_price',
                    header: 'Unit price',
                    flexGrow: 0,
                    width: 100,
                    renderer: entry => (
                        <TwoLineSmallCell
                            line1={formatPrice(entry.unitPrice)}
                            line2={formatPercent(entry.discount)}
                        />
                    ),
                },
                {
                    id: 'price',
                    header: 'Price',
                    flexGrow: 0,
                    width: 140,
                    renderer: entry => `${entry.quantity} x ${formatPrice(entry.price)}`,
                },
                {
                    id: 'total price',
                    header: 'Total price',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry => (
                        <TwoLineSmallCell
                            line1={formatPrice(entry.total)}
                            line2={formatPrice(entry.totalGross)}
                        />
                    ),
                },
                {
                    id: 'tax',
                    header: 'Tax',
                    flexGrow: 0,
                    width: 80,
                    renderer: entry => <TaxCell tax={entry.tax} />,
                },
                {
                    id: 'taxStatus',
                    header: 'Tax status',
                    flexGrow: 0,
                    width: 120,
                    renderer: entry => <TaxStatusCell tax={entry.tax} />,
                },
                {
                    id: 'invoicing',
                    header: 'Invoicing',
                    flexGrow: 0,
                    width: 160,
                    renderer: entry => <InvoicingChip invoiceEntry={entry} />,
                },
                {
                    id: 'quote',
                    header: 'Quote',
                    flexGrow: 0,
                    width: 80,
                    renderer: entry => <BooleanBadge value={entry.isQuote} offColor='grey' />,
                },
                {
                    id: 'two',
                    header: 'Two repayment',
                    flexGrow: 0,
                    width: 160,
                    renderer: entry => <TwoCell entry={entry} />,
                },
            ]}
        />
    );
};

interface TwoCellProps {
    entry: InvoiceEntryDto;
}

const TwoCell: FC<TwoCellProps> = ({ entry }) => {
    if (!entry.repaymentPlan) {
        return null;
    }

    if (!entry.repaymentPlan?.id) {
        return <RepaymentStatusLabel repayment={entry.repaymentPlan} />;
    }
    return (
        <RepaymentPlanLink id={entry.repaymentPlan?.id!}>
            <RepaymentStatusLabel repayment={entry.repaymentPlan} />
        </RepaymentPlanLink>
    );
};
