import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrganizationDetailsDto, organizationService, UpdateOrganizationDeelPayload } from '@hofy/api-admin';
import { zeroUuid } from '@hofy/global';
import { isRequiredUuid, isValidUuid, useForm, useToast, validator } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationDeelData = (
    organization: OrganizationDetailsDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationDeelPayload) =>
            organizationService.updateOrganizationDeelData(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                type: 'positive',
                message: `Organization Deel data successfully updated`,
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateOrganizationDeelPayload>({
        initial: {
            deelId: organization.deel.id ?? zeroUuid,
            itReady: organization.deel.itReady,
        },
        validate: validator<UpdateOrganizationDeelPayload>({
            deelId: [isRequiredUuid('Deel ID is required'), isValidUuid('Deel ID must be a valid UUID')],
        }),
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
