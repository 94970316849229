import { ColorDye, getEnumValues } from '@hofy/global';

export enum AssignmentException {
    LostInTransit = 'lost_in_transit',
    DamagedInTransit = 'damaged_in_transit',
    WrongSent = 'wrong_item_send',
    LeftWithUser = 'left_with_user',
    Stolen = 'stolen',
    Other = 'other',
}

export const allAssignmentExceptions = getEnumValues<AssignmentException>(AssignmentException);

export const assignmentExceptionColor: Record<AssignmentException, ColorDye> = {
    [AssignmentException.LostInTransit]: 'red',
    [AssignmentException.DamagedInTransit]: 'red',
    [AssignmentException.WrongSent]: 'red',
    [AssignmentException.Stolen]: 'red',
    [AssignmentException.LeftWithUser]: 'grey',
    [AssignmentException.Other]: 'grey',
};
