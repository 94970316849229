import { useQuery } from '@tanstack/react-query';

import { hofySubsidiariesService } from '../service/hofySubsidiariesService';
import { hofySubsidiaryCacheKey } from './hofySubsidiaryCacheKey';

export const useHofySubsidiaries = () => {
    const { data, isLoading } = useQuery({
        queryKey: [hofySubsidiaryCacheKey],
        queryFn: hofySubsidiariesService.getHofySubsidiaries,
    });

    return {
        hofySubsidiaries: data || [],
        isLoading,
    };
};
