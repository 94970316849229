import { getEnumValues } from '@hofy/global';

export enum Ownership {
    Hofy = 'hofy',
    Organization = 'organization',
}

export const belongsToHofy = (ownership?: Ownership | null) => ownership === Ownership.Hofy;

export const belongsToOrganization = (ownership?: Ownership | null) => ownership === Ownership.Organization;

export const allOwnerships = getEnumValues<Ownership>(Ownership);
