import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { SvgIcon } from '@hofy/ui';

interface SubscriptionActionsProps {
    subscriptionId: UUID;
    onDelete(id: UUID): void;
}

export const SubscriptionActions: FC<SubscriptionActionsProps> = ({ subscriptionId, onDelete }) => {
    const { hasPermission } = usePermission();
    return (
        <MoreMenu
            marginLeft={16}
            items={[
                {
                    label: 'Delete',
                    action: () => onDelete(subscriptionId),
                    visible: hasPermission(Permission.AdminOrganizationUpdate),
                    icon: SvgIcon.Trash,
                },
            ]}
        />
    );
};
