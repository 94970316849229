import { ColorDye } from '@hofy/global';

export enum AssignmentConfigurationStatus {
    Pending = 'pending',
    Configured = 'configured',
    Completed = 'completed',
}

export const assignmentConfigurationStatusColor: Record<AssignmentConfigurationStatus, ColorDye> = {
    [AssignmentConfigurationStatus.Pending]: 'red',
    [AssignmentConfigurationStatus.Configured]: 'orange',
    [AssignmentConfigurationStatus.Completed]: 'green',
};
