import React, { ElementRef, forwardRef, useEffect, useState } from 'react';

import { allCountriesSorted, Country, countryDialCodes, parsePhoneNumber, Phone } from '@hofy/global';
import { useTrCountry, useTrCountrySearch } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { asNullable } from '../../../../helpers/String';
import { Box, Paragraph3 } from '../../../base';
import { DropdownListSearch } from '../../../dropdown/DropdownListSearch';
import { CountryFlag } from '../../../flag/CountryFlag';
import { BaseInputProps, Input } from '../Input';

interface BasePhoneInputProps {
    id?: string;
    initialCountry?: Country;
}

export interface PhoneInputOnlyStringProps extends BaseInputProps, BasePhoneInputProps {
    nullable?: false;
    value: Phone;
    onChange(value: Phone): void;
}

export interface PhoneInputNullableStringProps extends BaseInputProps, BasePhoneInputProps {
    nullable: true;
    value: Phone | null;
    onChange(v: Phone | null): void;
}

type PhoneInputProps = PhoneInputOnlyStringProps | PhoneInputNullableStringProps;

export const PhoneInput = forwardRef<ElementRef<'input'>, PhoneInputProps>(
    ({ value, id, onChange, nullable, initialCountry, ...rest }, ref) => {
        const trCountry = useTrCountry();
        const trSearchCountry = useTrCountrySearch();

        const [input, setInput] = useState<string | null>(() => {
            const { formattedPhoneNumber } = parsePhoneNumber(value, initialCountry);
            return formattedPhoneNumber;
        });

        const { country, nationalNumber, phoneNumber, formattedPhoneNumber } = parsePhoneNumber(input);

        useEffect(() => {
            if (value && phoneNumber !== value) {
                const { formattedPhoneNumber } = parsePhoneNumber(value);
                setInput(formattedPhoneNumber);
            }
        }, [value]);

        useEffect(() => {
            if (!value) {
                const { formattedPhoneNumber } = parsePhoneNumber(value, initialCountry);
                setInput(formattedPhoneNumber);
            }
        }, [initialCountry]);

        const handleCountryChange = (country: Country) => {
            const countryDialCode = countryDialCodes[country];
            onChange((countryDialCode + nationalNumber) as Phone);
        };

        const handleChange = (inputValue: string) => {
            const { phoneNumber } = parsePhoneNumber(inputValue);

            setInput(inputValue);
            if (nullable) {
                onChange(asNullable(phoneNumber));
            } else {
                onChange(phoneNumber!);
            }
        };

        const handleBlur = () => {
            setInput(formattedPhoneNumber);
        };

        const countryLabelFormatter = (country: Country) => (
            <>
                <CountryFlag size={16} country={country} />
                <Paragraph3>{trCountry(country)}</Paragraph3>
                <Paragraph3 color={Color.ContentTertiary}>{countryDialCodes[country]}</Paragraph3>
            </>
        );

        return (
            <Input
                ref={ref}
                type='tel'
                id={id}
                value={input!}
                onChange={handleChange}
                onBlur={handleBlur}
                nullable={nullable}
                paddingLeft={0}
                leftSlotInteractive
                leftSlot={
                    <Box paddingRight={8} paddingVertical={2} paddingLeft={12} borderRight>
                        <DropdownListSearch
                            options={allCountriesSorted}
                            value={country}
                            onChange={handleCountryChange}
                            toText={trSearchCountry}
                            toLabel={countryLabelFormatter}
                            toSelectedLabel={country => <CountryFlag size={16} country={country} />}
                            contentMaxHeight={435}
                            asChild
                        />
                    </Box>
                }
                {...rest}
            />
        );
    },
);
