import React, { FC, useMemo } from 'react';

import {
    AnyProductCategory,
    ParentProductCategory,
    ProductCategory,
    useTrParentProductCategory,
    useTrProductCategory,
} from '@hofy/api-shared';
import { SelectFilter } from '@hofy/common';

interface ProductCategoryFilterProps {
    parentCategory: ParentProductCategory;
    categories: ProductCategory[];
    greyOutCategories?: ProductCategory[];
    selected: AnyProductCategory | null;
    onChange(selected: AnyProductCategory): void;
    testPrefix?: string;
}

export const ProductCategoryFilter: FC<ProductCategoryFilterProps> = ({
    parentCategory,
    selected,
    onChange,
    categories,
    testPrefix,
    greyOutCategories = [],
}) => {
    const trParentProductCategory = useTrParentProductCategory();
    const trProductCategory = useTrProductCategory();
    const options = useMemo(
        () =>
            categories.map(c => ({
                value: c,
                display: trProductCategory(c),
                isGreyOut: greyOutCategories.includes(c),
            })),
        [categories, greyOutCategories],
    );
    const title = trParentProductCategory(parentCategory);

    return (
        <SelectFilter
            testPrefix={testPrefix}
            title={title}
            categoryValue={parentCategory}
            options={options}
            selected={selected}
            onChange={onChange}
        />
    );
};
