import { ReactNode, RefObject } from 'react';

import { Writeable } from './Utils';

export type ReactNodeFunction<T extends any[] = []> = (...args: T) => ReactNode;

export type WriteableRefObject<T> = Writeable<RefObject<T>, 'current'>;

export const renderChildren = <T extends any[] = []>(
    children: ReactNodeFunction<T> | ReactNode,
    ...args: T
): ReactNode => {
    if (typeof children === 'function') {
        return children(...args);
    }
    return children;
};
