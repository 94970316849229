import React, { FC } from 'react';

import { Counter, Tab, Tabs } from '@hofy/ui';

import {
    allPurchaseOrderFormTabs,
    PurchaseOrderFormTab,
} from '../../../../../store/purchaseOrders/PurchaseOrderFormTab';
import { useTrPurchaseOrderFormTabs } from '../../../../../store/purchaseOrders/useTrPurchaseOrderFormTabs';

interface PurchaseOrderFormTabsProps {
    tab: PurchaseOrderFormTab;
    stepErrorCounts: Record<PurchaseOrderFormTab, number>;
    onChange(v: PurchaseOrderFormTab): void;
}

export const PurchaseOrderFormTabs: FC<PurchaseOrderFormTabsProps> = ({ tab, stepErrorCounts, onChange }) => {
    const trTab = useTrPurchaseOrderFormTabs();
    return (
        <Tabs active={tab} onChange={current => onChange(current)}>
            {allPurchaseOrderFormTabs.map(t => (
                <Tab
                    key={t}
                    id={t}
                    label={trTab(t)}
                    rightSlot={stepErrorCounts[t] > 0 && <Counter count={stepErrorCounts[t]} color='red' />}
                />
            ))}
        </Tabs>
    );
};
