export const organizationFinancialSettingsUpdateI18n = {
    'organization-page.financial-settings-update.title': 'Update financial settings',
    'organization-page.financial-settings-update.subtitle':
        'This operation will update the organization financial settings.',
    'organization-page.financial-settings-update.modal.organization-model-section': 'Organization model',
    'organization-page.financial-settings-update.modal.storefront-fee': 'Store fee (default 25%)',
    'organization-page.financial-settings-update.modal.platform-tier-section': 'Platform tier config',
    'organization-page.financial-settings-update.modal.platform-tier-select': 'Platform tier',
    'organization-page.financial-settings-update.modal.platform-tier-price': 'Platform tier price',
    'organization-page.financial-settings-update.modal.subsidiary-limit': 'Subsidiary limit',
    'organization-page.financial-settings-update.modal.team-limit': 'Team limit',
    'organization-page.financial-settings-update.modal.device-limit': 'Device tracked limit',
    'organization-page.financial-settings-update.modal.laptop-limit': 'Equip laptop limit',
    'organization-page.financial-settings-update.modal.payment-schema-warning':
        'Payment schema in use as a default',
    'organization-page.financial-settings-update.modal.pricing-section': 'Pricing',
    'organization-page.financial-settings-update.modal.config-fee': 'Config fee',
    'organization-page.financial-settings-update.modal.mdm-fee': 'MDM fee',
    'organization-page.financial-settings-update.modal.credit-limit': 'Credit limit (Monthly)',
    'organization-page.financial-settings-update.modal.credit-limit.tooltip': 'Credit limit (Monthly)',
    'organization-page.financial-settings-update.modal.payment-type.tooltip.title': 'Payment type',
    'organization-page.financial-settings-update.modal.payment-type.tooltip':
        'Allowed payment type for organization.',
    'organization-page.financial-settings-update.modal.billing-frequency-section':
        'Allowed Billing Frequency',
    'organization-page.financial-settings-update.modal.billing-frequency-section.tooltip':
        'Only applies to active organizations.',
    'organization-page.financial-settings-update.modal.allowed-rentals-section': 'Allowed rental terms',
    'organization-page.financial-settings-update.modal.allowed-rentals-section.tooltip':
        'Only applies to monthly & annual billing frequency.',
    'organization-page.financial-settings-update.modal.confirm': 'Save',
    'organization-page.financial-settings-update.modal.cancel': 'Cancel',
};
