import React, { FC } from 'react';

import { APP_CONFIG } from '@hofy/config';
import { Link, LinkSize, MarginBoxProps } from '@hofy/ui';

export enum Document {
    StoreAndReuseExplainer = 'store_reuse_explainer_20240129',
    StoreAndReuseTerms = 'redeployment_v1_terms_20230517',
    StoreAndReuseZone1Countries = 'store_reuse_zone1_countries_20230823',
    DataErasureExplainer = 'certified_data_erasure_20240213',
    HofyAgent = 'hofy_agent_20240501',
}

interface LinkToDocumentProps extends MarginBoxProps {
    document: Document;
    linkLabel: string;
    inline?: boolean;
    size?: LinkSize;
}

export const DocumentLink: FC<LinkToDocumentProps> = ({ document, linkLabel, inline = false, ...rest }) => {
    const hofyDocumentsUrl = APP_CONFIG.hofyDocumentsUrl;

    return (
        <Link to={`${hofyDocumentsUrl}/${document.valueOf()}`} inline={inline} {...rest}>
            {linkLabel}
        </Link>
    );
};
