import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import { HofyWarehouseSelect, LabeledHofyWarehouseSelectProps } from './HofyWarehouseSelect';

type FormHofyWarehouseSelectOptionalProps = Optional<LabeledHofyWarehouseSelectProps, 'onChange'>;
type FormHofyWarehouseSelectOmittedProps = Omit<
    FormHofyWarehouseSelectOptionalProps,
    'errorMessage' | 'value'
>;

interface FormNormalInputProps extends FormHofyWarehouseSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID>;
    nullable?: false;
    isRequired?: boolean;
}

interface FormNullableInputProps extends FormHofyWarehouseSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID | null>;
    nullable: true;
    isRequired: boolean;
}

type FormWarehouseSelectProps = FormNormalInputProps | FormNullableInputProps;

export const FormHofyWarehouseSelect: FC<FormWarehouseSelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <HofyWarehouseSelect
            {...inputProps}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
