import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    fulfilmentCountrySettingCacheKey,
    FulfilmentCountrySettingDto,
    FulfilmentCountrySettingPayload,
    fulfilmentCountrySettingService,
} from '@hofy/api-admin';
import { useGoBack } from '@hofy/hooks';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useUpdateFulfilmentCountrySetting = (setting: FulfilmentCountrySettingDto) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const { goBack } = useGoBack();

    const mutation = useMutation({
        mutationFn: (payload: FulfilmentCountrySettingPayload) =>
            fulfilmentCountrySettingService.updateFulfilmentCountrySetting(payload, setting.country),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [fulfilmentCountrySettingCacheKey] });
            showToast({
                type: 'positive',
                message: 'Fulfilment country settings successfully saved',
            });
            goBack();
        },
    });

    const form = useForm<FulfilmentCountrySettingPayload>({
        initialDeps: [setting],
        initial: {
            defaultCollectToWarehouseId: setting.defaultCollectToWarehouse.id,
            defaultDeliverFromWarehouseId: setting.defaultDeliverFromWarehouse.id,
            storeAndReuseCollectToHubWarehouseId: setting.storeAndReuseCollectToHubWarehouse?.id ?? null,
            storeAndReuseCollectToLocalWarehouseId: setting.storeAndReuseCollectToLocalWarehouse?.id ?? null,
        },
        onSubmit: mutation.mutate,
        validate: validator<FulfilmentCountrySettingPayload>({
            defaultCollectToWarehouseId: isRequired('Default collect to warehouse is required'),
            defaultDeliverFromWarehouseId: isRequired('Default deliver to warehouse is required'),
        }),
    });

    return {
        form: form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
