import React, { FC } from 'react';

import { ExpandHeight, UseForm } from '@hofy/ui';

import { CustomerOwnedStoreAndReuseLocationField } from '../../../components/domain/contracts/CustomerOwnedStoreAndReuseLocationField';
import { StoreAndReuseLocation } from '../../../components/domain/contracts/StoreAndReuseLocation';
import {
    EnableStoreAndReuseCollectionFormData,
    EnableStoreAndReuseCollectionFormErrorData,
} from '../../../store/assignments/useEnableStoreAndReuseCollection';

interface StoreAndReuseSelectionProps {
    form: UseForm<EnableStoreAndReuseCollectionFormData, EnableStoreAndReuseCollectionFormErrorData>;
    isStoreAndReuseCollection: boolean;
    isActiveRental: boolean;
    collectToHub: boolean;
    collectToLocalPartner: boolean;
}

export const StoreAndReuseSelection: FC<StoreAndReuseSelectionProps> = ({
    form,
    isStoreAndReuseCollection,
    isActiveRental,
    collectToHub,
    collectToLocalPartner,
}) => {
    const rentalStoreAndReuse = form.values.rentalStoreAndReuse;
    const customerOwnedStoreAndReuse = form.values.customerOwnedStoreAndReuse;
    const displayStoreAndReuseRental =
        isStoreAndReuseCollection && isActiveRental && rentalStoreAndReuse?.activationFee;
    const displayStoreAndReuseCustomerOwned =
        isStoreAndReuseCollection && !isActiveRental && customerOwnedStoreAndReuse?.activationFee;
    return (
        <ExpandHeight>
            {displayStoreAndReuseRental && (
                <StoreAndReuseLocation
                    collectToHub={collectToHub}
                    collectToLocalPartner={collectToLocalPartner}
                    location={rentalStoreAndReuse.collectToLocation}
                    activationFee={rentalStoreAndReuse.activationFee!}
                    errorMessage={form.errors.rentalStoreAndReuse}
                    onLocationChange={collectToLocation =>
                        form.setValues({
                            rentalStoreAndReuse: {
                                ...rentalStoreAndReuse,
                                collectToLocation,
                            },
                        })
                    }
                    onActivationFeeChange={activationFee =>
                        form.setValues({
                            rentalStoreAndReuse: {
                                ...rentalStoreAndReuse,
                                activationFee,
                            },
                        })
                    }
                />
            )}
            {displayStoreAndReuseCustomerOwned && (
                <CustomerOwnedStoreAndReuseLocationField
                    collectToHub={collectToHub}
                    collectToLocalPartner={collectToLocalPartner}
                    location={customerOwnedStoreAndReuse.collectToLocation}
                    activationFee={customerOwnedStoreAndReuse.activationFee!}
                    monthlyFee={customerOwnedStoreAndReuse.storageMonthlyFee!}
                    errorMessage={form.errors.customerOwnedStoreAndReuse}
                    onCustomerOwnedStoreAndReuseLocationChange={collectToLocation =>
                        form.setValues({
                            customerOwnedStoreAndReuse: {
                                ...customerOwnedStoreAndReuse,
                                collectToLocation,
                            },
                        })
                    }
                    onActivationFeeChange={activationFee =>
                        form.setValues({
                            customerOwnedStoreAndReuse: {
                                ...customerOwnedStoreAndReuse,
                                activationFee,
                            },
                        })
                    }
                    onStorageMonthlyFeeChange={storageMonthlyFee =>
                        form.setValues({
                            customerOwnedStoreAndReuse: {
                                ...customerOwnedStoreAndReuse,
                                storageMonthlyFee,
                            },
                        })
                    }
                />
            )}
        </ExpandHeight>
    );
};
