import { ColorDye } from '@hofy/global';

export enum MessageStatus {
    Sending = 'sending',
    Sent = 'sent',
    Delivered = 'delivered',
    Failed = 'failed',
}

export const messageStatusColor: Record<MessageStatus, ColorDye> = {
    [MessageStatus.Sending]: 'aquamarine',
    [MessageStatus.Sent]: 'cyan',
    [MessageStatus.Delivered]: 'green',
    [MessageStatus.Failed]: 'magenta',
};
