export const zendeskErrorI18n = {
    'api-error.organization-not-sync-with-zendesk.title': 'Organization is not synced with zendesk',
    'api-error.organization-not-sync-with-zendesk.message': 'Cannot perform this operation',
    'api-error.create-organization-on-zendesk-client.title': 'Error when creating organization on zendesk',
    'api-error.create-organization-on-zendesk-client.message': 'Cannot perform this operation',
    'api-error.create-ticket-on-zendesk-client.title': 'Error when creating ticket on zendesk',
    'api-error.create-ticket-on-zendesk-client.message': 'Cannot perform this operation',
    'api-error.create-user-on-zendesk-client.title': 'Error when creating user on zendesk',
    'api-error.create-user-on-zendesk-client.message': 'Cannot perform this operation',
    'api-error.update-user-on-zendesk-client.title': 'Error when updating user on zendesk',
    'api-error.update-user-on-zendesk-client.message': 'Cannot perform this operation',
    'api-error.user-not-sync-with-zendesk.title': 'User is not synced with zendesk',
    'api-error.user-not-sync-with-zendesk.message': 'Cannot perform this operation',
};
