import { ColorDye, getEnumValues } from '@hofy/global';

export enum ItemEventStatus {
    Initial = 'initial',
    Pending = 'pending',
    Processed = 'processed',
    Skipped = 'skipped',
    Error = 'error',
    ManualFix = 'manual_fix',
}

export const itemEventStatusColor: Record<ItemEventStatus, ColorDye> = {
    [ItemEventStatus.Initial]: 'orange',
    [ItemEventStatus.Pending]: 'orange',
    [ItemEventStatus.Processed]: 'green',
    [ItemEventStatus.Skipped]: 'green',
    [ItemEventStatus.Error]: 'red',
    [ItemEventStatus.ManualFix]: 'grey',
};
export const allItemEventStatuses = getEnumValues<ItemEventStatus>(ItemEventStatus);
