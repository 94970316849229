import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractsCacheKey, contractService, invoiceEntriesQueryKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useCreditContract = (contractId: UUID) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: contractService.creditContract,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey, contractId] });
        },
    });

    return {
        isLoading: mutation.isPending,
        creditContract: mutation.mutate,
    };
};
