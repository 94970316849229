import React, { FC } from 'react';

import { AddressField } from '@hofy/address';
import { Box, FormContainer, useFormObjectField, useFormObjectOptionalField } from '@hofy/ui';

import { InvoiceEntryTaxAddressFormState } from '../../../../store/invoiceEntries/useInvoiceEntryTaxAddressForm';

interface InvoiceEntryTaxAddressFormProps {
    form: InvoiceEntryTaxAddressFormState;
}

export const InvoiceEntryTaxAddressForm: FC<InvoiceEntryTaxAddressFormProps> = ({ form }) => {
    const toAddress = useFormObjectField(form.fields.toAddress);
    const fromAddress = useFormObjectOptionalField(form.fields.fromAddress);
    return (
        <Box marginTop={20}>
            <FormContainer>
                {fromAddress && <AddressField label='From address' api={fromAddress} />}
                <AddressField label='To address' api={toAddress} />
            </FormContainer>
        </Box>
    );
};
