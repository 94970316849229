import React, { FC } from 'react';

import { ShipmentCollectionRequestPackagingDto } from '@hofy/api-admin';
import { collectionRequestPackagingStatusLabels, formatUserName } from '@hofy/api-shared';
import { Timeline } from '@hofy/common';
import { formatDateTime } from '@hofy/helpers';
import { Box, Labeled, LabeledText, Link, Paragraph3, Span } from '@hofy/ui';

interface PackagingDetailsSectionProps {
    packagingDetails: ShipmentCollectionRequestPackagingDto;
}

export const PackagingDetailsSection: FC<PackagingDetailsSectionProps> = ({
    packagingDetails: { statusUpdates, trackingLink, trackingReference },
}) => {
    return (
        <Box row>
            <Timeline
                items={statusUpdates.map(({ status, updatedAt, updatedBy }) => (
                    <Box key={status + updatedAt} column gap={4}>
                        <Paragraph3 bold>{collectionRequestPackagingStatusLabels[status]}</Paragraph3>
                        <Box row gap={4}>
                            {updatedAt && <Paragraph3>{formatDateTime(updatedAt)}</Paragraph3>}
                            {updatedAt && updatedBy && (
                                <Paragraph3>
                                    by <Span bold>{formatUserName(updatedBy)}</Span>
                                </Paragraph3>
                            )}
                        </Box>
                    </Box>
                ))}
                currentIndex={statusUpdates.length - 1}
                flex={1}
                alignSelf='stretch'
            />
            <Box column gap={16} flex={1} alignSelf='stretch'>
                <LabeledText label='Tracking reference' content={trackingReference} />
                <Labeled label='Tracking link' content={trackingLink && <Link to={trackingLink} />} />
            </Box>
        </Box>
    );
};
