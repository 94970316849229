import { useUserQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useUser = (id: UUID | null) => {
    const { data: user, isLoading } = useUserQuery(id);

    return {
        user,
        isLoading,
    };
};
