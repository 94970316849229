export const readFirstFile = (files: FileList, result: (file: string) => void, readAsText = false) => {
    return readFile(files[0], result, readAsText);
};

export const readFile = (file: File, result: (file: string) => void, readAsText = false) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => result(reader.result as string));
    readAsText ? reader.readAsText(file) : reader.readAsDataURL(file);
    return file;
};
