import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { useIsDisabled } from '@hofy/ui';

interface MailtoProps {
    email: string;
    subject?: string;
    body?: string;
    underlined?: boolean;
    children?: ReactNode;
    disabled?: boolean;
}

export const Mailto: FC<MailtoProps> = ({
    email,
    subject = '',
    body = '',
    underlined,
    children,
    disabled: mailToDisabled,
}) => {
    const disabled = useIsDisabled(mailToDisabled);

    const href = !disabled
        ? `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
        : undefined;

    return (
        <Link href={href} underlined={underlined}>
            {children}
        </Link>
    );
};

const Link = styled.a<{ underlined?: boolean }>`
    ${p => p.underlined && `text-decoration: underline;`}
`;
