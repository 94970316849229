import React, { FC } from 'react';

import { useSubscriptions } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useStructMemo } from '@hofy/hooks';

import { SubscriptionsTable } from './components/SubscriptionsTable';

interface SubscriptionsTabProps {
    organizationId: UUID;
    onClick(id: UUID): void;
}

export const SubscriptionsTab: FC<SubscriptionsTabProps> = ({ organizationId, onClick }) => {
    const { subscriptions, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useSubscriptions(organizationId);
    const subscriptionsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });

    return (
        <SubscriptionsTable
            organizationId={organizationId}
            subscriptions={subscriptions}
            infinityScroll={subscriptionsScrollDetails}
            onClick={onClick}
        />
    );
};
