import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { isShipmentToHofyWarehouse, ProductDto, VariantDetailsDto, WarehouseDto } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton, Box, FormContainer } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';
import { useItemCheckIn3rdPartyWarehouse } from '../../../store/shipments/useItemCheckIn';
import { ProductVariantDetails } from '../components/ProductVariantDetails';

interface CollectionItemCheckConditionSlideoutProps {
    shipment: ShipmentDetailsDto;
    assignmentId: UUID;
    product: ProductDto;
    variant: VariantDetailsDto;
    item: {
        warehouse: WarehouseDto | null;
        id: UUID;
    };
    onClose(): void;
}

export const CollectionItemCheckConditionSlideout: FC<CollectionItemCheckConditionSlideoutProps> = ({
    shipment,
    onClose,
    product,
    variant,
    assignmentId,
    item,
}) => {
    const { form, isLoading } = useItemCheckIn3rdPartyWarehouse(item.id, assignmentId, onClose);

    if (!isShipmentToHofyWarehouse(shipment)) {
        return null;
    }

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Check in item' />
            <SlideoutContent>
                <Box borderBottom paddingVertical={40}>
                    <ProductVariantDetails product={product} variant={variant} />
                </Box>
                <FormContainer>
                    {shipment.toWarehouse && (
                        <WarehouseBinSelector
                            api={form.fields.warehouseBinIdentifier}
                            warehouse={{
                                id: shipment.toWarehouse.id,
                                name: shipment.toWarehouse.name,
                            }}
                            isAddBinPromptEnabled
                        />
                    )}
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} disableCheck onClick={form.submit} label='Check in item' />
            </SlideoutFooter>
        </Slideout>
    );
};
