import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, Heading2, Paragraph3 } from '../../base/index';
import { Illustration, SvgIllustration } from '../../illustration';
import { Logo } from '../../logo/index';

interface ErrorPageProps {
    illustration: SvgIllustration;
    title: string;
    subtitle: string;
    children?: ReactNode;
}

export const ErrorPage: FC<ErrorPageProps> = ({ illustration, subtitle, title, children }) => {
    return (
        <Box fullSize bg={Color.BackgroundDefault} column centered>
            <Box marginBottom={25}>
                <Logo color />
            </Box>
            <Illustration svg={illustration} />
            <Heading2 marginTop={40}>{title}</Heading2>
            <Paragraph3 marginTop={10}>{subtitle}</Paragraph3>
            {children}
        </Box>
    );
};
