import { ColorDye } from '@hofy/global';

export enum RepaymentPlanType {
    Recourse = 'recourse',
    NonRecourse = 'non_recourse',
}

export const repaymentTypeColors: Record<RepaymentPlanType, ColorDye> = {
    [RepaymentPlanType.Recourse]: 'orange',
    [RepaymentPlanType.NonRecourse]: 'blue',
};
