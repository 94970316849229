import React, { FC } from 'react';

import { PaymentSummaryDto } from '@hofy/api-admin';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Card, FeaturedIcon, Paragraph3, SectionTitle1, SvgIcon, Tooltip } from '@hofy/ui';

interface InvoicePaymentDetailsCardProps {
    summary: PaymentSummaryDto;
    isCreditNote: boolean;
}

export const InvoicePaymentDetailsCard: FC<InvoicePaymentDetailsCardProps> = ({ summary, isCreditNote }) => {
    const { formatPrice } = usePrice();
    return (
        <Card flex='auto' column columnGap={32} rowGap={8} bg={Color.BackgroundSubtleBrand}>
            <Box row justify='space-between'>
                <SectionTitle1>Summary</SectionTitle1>
                {summary.paymentErrorReason && (
                    <Tooltip
                        title='Failed payment attempt'
                        body='Check Payments tab for details'
                        placement='top'
                    >
                        <FeaturedIcon icon={SvgIcon.AlertCircle} type='warning' variant='vivid' />
                    </Tooltip>
                )}
            </Box>
            <Box row justify='space-between'>
                <Paragraph3 color={Color.ContentPrimary}>Total</Paragraph3>
                <Paragraph3 bold>{formatPrice(summary.grossPrice)}</Paragraph3>
            </Box>
            <Box row justify='space-between'>
                <Paragraph3 color={Color.ContentPrimary}>
                    Amount {isCreditNote ? 'applied' : 'paid'}
                </Paragraph3>
                <Paragraph3 bold>{formatPrice(summary.amountPaid)}</Paragraph3>
            </Box>
            {!isCreditNote && (
                <Box row justify='space-between'>
                    <Paragraph3 color={Color.ContentPrimary}>Amount credited</Paragraph3>
                    <Paragraph3 bold>{formatPrice(summary.amountCredited)}</Paragraph3>
                </Box>
            )}
            <Box borderBottom />
            <Box row justify='space-between'>
                <Paragraph3 color={Color.ContentPrimary}>Balance</Paragraph3>
                <Paragraph3 bold>{formatPrice(summary.balance)}</Paragraph3>
            </Box>
        </Card>
    );
};
