import { Country, Currency, Price } from '@hofy/global';

import { VariantDto } from './ProductDto';

export enum PurchaseOrderType {
    Warehouse = 'warehouse',
    Dropship = 'dropship',
}

export interface VariantWithProductAndPriceQuery {
    country: Country;
    currency: Currency;
    purchaseOrderType: PurchaseOrderType;
}

export interface VariantWithProductDto extends VariantDto {
    productName: string;
    targetPurchasePrice: Price | null;
}
