import { rgba } from 'polished';
import styled, { keyframes } from 'styled-components';

import { Color, Palette } from '@hofy/theme';

import { Box } from '../base';

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

const bg = Palette.greyAlpha['+7'];
const bgTransparent = rgba(Color.BackgroundDefault, 0);

export const Skeleton = styled(Box)<{ radius?: number }>`
    background: ${bg};
    border-radius: ${p => p.radius || 4}px;
    overflow: hidden;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            ${bgTransparent} 0,
            ${bgTransparent} 0,
            ${bg} 20%,
            ${bg} 60%,
            ${bgTransparent}
        );
        animation: ${shimmer} 2s infinite;
    }
`;

export const GradientSkeleton = styled(Skeleton)`
    background: linear-gradient(180deg, ${bg} 0, ${bgTransparent} 100%);
`;

export const TextSkeleton = styled(Skeleton).attrs({ radius: 2 })``;
