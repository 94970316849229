export const arrayMove = <T extends any>(arr: T[], oldIndex: number, newIndex: number): T[] => {
    const copy = [...arr];
    copy.splice(newIndex, 0, copy.splice(oldIndex, 1)[0]);
    return copy;
};

export const arrayRemove = <T extends any>(arr: T[], index: number): T[] => {
    const copy = [...arr];
    copy.splice(index, 1);
    return copy;
};

export const arrayUpdateAt = <T extends any>(arr: T[], index: number, mapper: (v: T) => T): T[] => {
    return arr.map((t, i) => (i === index ? mapper(t) : t));
};

export const arrayUpdateBy = <T extends any>(
    arr: T[],
    predicate: (t: T) => boolean,
    mapper: (v: T) => T,
): T[] => {
    return arr.map(t => (predicate(t) ? mapper(t) : t));
};

export const asArray = <T>(value: T | T[]): T[] => {
    return Array.isArray(value) ? value : [value];
};
