import React, { FC } from 'react';

import { ShipmentClass } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface ExpressShippingIndicatorProps {
    shipmentClass: ShipmentClass | null;
}

export const ExpressShippingIndicator: FC<ExpressShippingIndicatorProps> = ({ shipmentClass }) => {
    const color =
        !shipmentClass || shipmentClass === ShipmentClass.Standard
            ? Color.ContentWarning
            : Color.ContentPositive;

    return (
        <Indicator color={color}>
            <TooltipIcon
                icon={SvgIcon.Truck}
                iconColor={color}
                body='Express - customer request'
                iconSize={16}
            />
        </Indicator>
    );
};
