import { useMutation } from '@tanstack/react-query';

import {
    dataSeedService,
    emptySeedDemoOrganizationPayload,
    SeedDemoOrganizationPayload,
} from '@hofy/api-admin';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useSeedDemoOrganization = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedDemoOrganization,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Demo organization created',
            });
        },
    });

    const form = useForm<SeedDemoOrganizationPayload>({
        initial: emptySeedDemoOrganizationPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedDemoOrganizationPayload>({
            organizationName: isRequired('Organization name is required'),
        }),
    });
    return { form, isLoading: mutation.isPending };
};
