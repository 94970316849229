import { getEnumValues } from '@hofy/global';

export enum TermAndCondition {
    V1 = 'v1',
    V2 = 'v2',
    V25 = 'v2.5',
    V3 = 'v3',
    V4 = 'v4',
    V45 = 'v4.5',
}

const allTermAndConditions = getEnumValues<TermAndCondition>(TermAndCondition);

export const isTermAndConditionVersionOrNewer = (
    current: TermAndCondition,
    required: TermAndCondition,
): boolean => {
    const currentIndex = allTermAndConditions.indexOf(current);
    const requiredIndex = allTermAndConditions.indexOf(required);

    return currentIndex >= requiredIndex;
};
