import React, { FC, useRef } from 'react';
import { useKey } from 'react-use';

import { useBaseI18n } from '@hofy/i18n';

import { Icon, SvgIcon } from '../../../icon';
import { DebouncedInput, DebounceInputOnlyStringProps } from '../debounced/DebouncedInput';

/**
 * Search input component with debounced onChange,
 * it will trigger onChange after some delay unless the value is empty.
 *
 * It is also possible to focus the input by pressing `/` key.
 */
export const SearchInput: FC<DebounceInputOnlyStringProps> = ({
    value,
    onChange,
    placeholder,
    width = 220,
    testKey = 'search',
    ...rest
}) => {
    const { tr } = useBaseI18n();

    const ref = useRef<HTMLInputElement>(null);
    useKey('/', event => {
        // Check if we are not in input, textarea or other focusable element
        if (event.target === document.body || event.target === document.documentElement) {
            event.preventDefault();
            ref.current?.focus();
        }
    });

    return (
        <DebouncedInput
            ref={ref}
            value={value}
            onChange={onChange}
            type='search'
            leftSlot={<Icon svg={SvgIcon.Search} />}
            placeholder={placeholder || tr('ui.form.search.placeholder')}
            width={width}
            clearable
            testKey={testKey}
            {...rest}
        />
    );
};
