import React, { FC } from 'react';

import { Price } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { Optional } from '../../../../types';
import { LabeledPriceInput, LabeledPriceInputProps } from './LabeledPriceInput';

type FormPriceInputOptionalProps = Optional<LabeledPriceInputProps, 'onChange'>;
type FormPriceInputOmittedProps = Omit<FormPriceInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalPriceInputProps extends FormPriceInputOmittedProps {
    api: FormFieldApi<Price>;
    nullable?: false;
}

interface FormNullablePriceInputProps extends FormPriceInputOmittedProps {
    api: FormFieldApi<Price | null>;
    nullable: true;
}

type FormPriceInputProps = FormNormalPriceInputProps | FormNullablePriceInputProps;

export const FormPriceInput: FC<FormPriceInputProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledPriceInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
