import React, { FC } from 'react';

import { useMetaEnter, useStructMemo } from '@hofy/hooks';

import { DisableContextProvider } from '../../../contexts';
import { Box, BoxProps } from '../../base';
import { FormContextProvider, FormContextValue } from './formContext';

export interface FormProps extends BoxProps, Partial<FormContextValue> {
    onSubmit(): void;
    disabled?: boolean;
}

export const Form: FC<FormProps> = ({
    onSubmit,
    isLoading = false,
    isError = false,
    disabled = false,
    submitOnEnter = false,
    children,
    ...props
}) => {
    const settings = useStructMemo<FormContextValue>({
        onSubmit,
        isLoading,
        isError,
        submitOnEnter,
    });

    // Listen for CMD/Ctrl+Enter to submit form
    const ref = useMetaEnter({
        onActivate: () => {
            if (!disabled) {
                onSubmit();
            }
        },
        requireMetaKey: !settings.submitOnEnter,
    });

    return (
        <FormContextProvider value={settings}>
            <DisableContextProvider disabled={isLoading || disabled}>
                <Box ref={ref} {...props}>
                    {children}
                </Box>
            </DisableContextProvider>
        </FormContextProvider>
    );
};
