import React, { FC, ReactElement } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';
import { FallbackImage } from './FallbackImage';

interface FramedImageProps extends MarginBoxProps {
    src?: string;
    size?: number | string;
    fallback: string | ReactElement;
    selected?: boolean;
    isError?: boolean;
}

export const FramedImage: FC<FramedImageProps> = ({
    src,
    size,
    selected = false,
    isError,
    fallback,
    ...margins
}) => {
    return (
        <ImageBox width={size} height={size} selected={selected} isError={isError} {...margins}>
            <FallbackImage src={src} fallback={fallback} />
        </ImageBox>
    );
};

export const ImageBox = styled(Box)<{ selected: boolean; isError?: boolean }>`
    border-radius: 5px;
    border: 1px solid
        ${p => {
            if (p.isError) {
                return Color.FoundationNegative;
            }
            if (p.selected) {
                return Color.Purple;
            }

            return Color.Neutral200;
        }};
    transition: border 300ms ease-out;
    overflow: hidden;
    flex-shrink: 0;
`;
