import { noop } from 'lodash';
import { useMemo } from 'react';

import { usePurchaseOrderQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useMultipartForm } from '@hofy/ui';

import {
    emptyPurchaseOrderHeaderFormData,
    usePurchaseOrderHeaderForm,
    ValidatedPurchaseOrderHeaderData,
} from './usePurchaseOrderHeaderForm';
import {
    emptyPurchaseOrderItemsFormData,
    usePurchaseOrderItemsForm,
    ValidatedPurchaseOrderItemsData,
} from './usePurchaseOrderItemsForm';
import { useUpdatePurchaseOrder } from './useUpdatePurchaseOrder';

export const useUpdateMultipartPurchaseOrderForm = (purchaseOrderId: UUID, onSuccess = noop) => {
    const { updatePurchaseOrder, isLoading, isError } = useUpdatePurchaseOrder(purchaseOrderId, onSuccess);
    const { data: purchaseOrder, isLoading: purchaseOrderIsLoading } = usePurchaseOrderQuery(purchaseOrderId);

    const { headerState, itemsState } = useMemo(() => {
        if (!purchaseOrder) {
            return {
                headerState: emptyPurchaseOrderHeaderFormData,
                itemsState: emptyPurchaseOrderItemsFormData,
            };
        }

        return {
            headerState: {
                ...emptyPurchaseOrderHeaderFormData,
                shipmentId: purchaseOrder.purchaseOrderShipments[0]?.id,
                supplierId: purchaseOrder.supplier?.id ?? null,
                currency: purchaseOrder.currency,
                isDropship: purchaseOrder.isDropship,
                estimatedReceiveOn: purchaseOrder.estimatedReceiveOn,
                toWarehouseId: purchaseOrder.toWarehouse?.id || null,
                orderedOn: purchaseOrder.orderedOn,
                notes: purchaseOrder.notes,
                purchaseOrderReference: purchaseOrder.purchaseOrderReference,
                purchaseOrderSupplierReference: purchaseOrder.purchaseOrderSupplierReference,
                toUserId: purchaseOrder.toUser?.id || null,
            },
            itemsState: {
                totalHandlingCost: purchaseOrder.handlingCost,
                items: purchaseOrder.purchaseOrderItems.map(item => {
                    return {
                        variantId: item.variantId,
                        name: item.variant.product.name,
                        supplierCode: item.supplierCode,
                        quantity: item.quantity,
                        unitNetPrice: item.unitNetPrice,
                        taxRate: item.taxRate,
                        warranty: item.warranty,
                        estimatedReceiveOn: item.estimatedReceiveOn,
                        notes: item.notes,
                    };
                }),
            },
        };
    }, [purchaseOrder]);

    const header = usePurchaseOrderHeaderForm(headerState);
    const items = usePurchaseOrderItemsForm(itemsState);

    const form = useMultipartForm(
        {
            header,
            items,
            review: true,
        },
        {
            onSubmit: values => {
                const validatedHeaderData = values.header as ValidatedPurchaseOrderHeaderData;
                const validatedItemsData = values.items as ValidatedPurchaseOrderItemsData;

                updatePurchaseOrder({
                    ...validatedHeaderData,
                    ...validatedItemsData,
                    isDraft: false,
                });
            },
            allowSkipSteps: true,
        },
    );

    return {
        form,
        isLoading: isLoading,
        isPreparing: purchaseOrderIsLoading,
        isError,
    };
};
