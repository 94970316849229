import React, { FC, useMemo } from 'react';

import { SubscriptionPayload, useTrSubscriptionTerm } from '@hofy/api-admin';
import {
    allPaymentSchemasWithoutDeposit,
    allSubscriptionTerms,
    monthsInSubscriptionTerm,
    PaymentSchema,
    useTrPaymentSchema,
} from '@hofy/api-shared';
import { numberToPrice, priceToNumber } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { FormDateInput, FormGridRow, FormPriceInput, FormSelect, LabeledText, UseForm } from '@hofy/ui';

interface SubscriptionFormProps {
    form: UseForm<SubscriptionPayload>;
    organizationBased: boolean;
    readOnly?: boolean;
}

export const SubscriptionFormFields: FC<SubscriptionFormProps> = ({ form, organizationBased, readOnly }) => {
    const trBillingFrequency = useTrPaymentSchema();
    const trTerm = useTrSubscriptionTerm();
    const allowedFrequencies = organizationBased ? allPaymentSchemasWithoutDeposit : [PaymentSchema.Monthly];
    const { formatPrice } = usePrice();

    const totalSubscriptionCharge = useMemo(() => {
        return {
            monthly: form.values.price,
            annual: numberToPrice(priceToNumber(form.values.price) * 12, form.values.price.currency),
            upfront: numberToPrice(
                priceToNumber(form.values.price) * monthsInSubscriptionTerm(form.values.term),
                form.values.price.currency,
            ),
        };
    }, [form.values.term, form.values.price]);

    return (
        <>
            <FormDateInput label='Starts on' api={form.fields.startOn} disabled={readOnly} />
            <FormSelect
                label='Subscription term'
                api={form.fields.term}
                options={allSubscriptionTerms}
                toText={trTerm}
                isRequired
                disabled={readOnly}
            />
            <FormSelect
                label='Billing frequency'
                api={form.fields.billingFrequency}
                options={allowedFrequencies}
                toText={trBillingFrequency}
                isRequired
                disabled={readOnly}
            />
            <FormPriceInput label='Monthly price' api={form.fields.price} isRequired disabled={readOnly} />

            <FormGridRow marginTop={10} columns={2}>
                <LabeledText label='Monthly price' content={formatPrice(totalSubscriptionCharge.monthly)} />
                <LabeledText label='Annual price' content={formatPrice(totalSubscriptionCharge.annual)} />
                <LabeledText label='Upfront price' content={formatPrice(totalSubscriptionCharge.upfront)} />
                <LabeledText label='Billed' content={trBillingFrequency(form.values.billingFrequency)} />
            </FormGridRow>
        </>
    );
};
