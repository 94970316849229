import { AddonDetailsDto, addonsService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { getInitialAddonConstraints } from './types/AddonConstraint';
import { getInitialAddonDataDependencies } from './types/AddonDataDependency';
import { useAddonForm } from './useAddonForm';

export const useUpdateAddon = (addon: AddonDetailsDto, onSuccess?: () => void) => {
    const { showToast } = useToast();

    return useAddonForm({
        initial: {
            name: addon.name,
            services: addon.services,
            type: addon.type,
            target: addon.target,
            isPublic: addon.isPublic,
            isPremium: addon.isPremium,
            constraintDependencies: getInitialAddonConstraints(addon.target, addon.constraints),
            pricingDependencies: getInitialAddonDataDependencies(addon.target, addon.pricings),
            discountDependencies: getInitialAddonDataDependencies(addon.target, addon.discounts),
            constraints: addon.constraints,
            pricings: addon.pricings,
            discounts: addon.discounts,
            pools: addon.pools,
        },
        onSubmit: payload => addonsService.updateAddon(addon.id, payload),
        onSuccess: () => {
            onSuccess?.();
            showToast({
                type: 'positive',
                message: 'Addon successfully updated',
            });
        },
    });
};
