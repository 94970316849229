import React from 'react';

import { SortDirectionType } from '@hofy/global';

import { Pressable } from '../../base';
import { DropdownCheckboxListSearch } from '../../dropdown';
import { DropdownCheckboxList } from '../../dropdown/DropdownCheckboxList';
import { HeaderCellLabel } from '../header/HeaderCellLabel';
import { ColumnFilter, SortFilterDataTableColumnConfig } from '../types/SortFilterDataTableTypes';

interface SortFilterDataTableHeaderCellProps<T> {
    column: SortFilterDataTableColumnConfig<T>;
    sortDirection?: SortDirectionType;
    handleSort?(s: SortDirectionType): void;
    filterConfig?: ColumnFilter<T>;
    filterValue?: any[];
    setFilter(v: any[]): void;
}

export const SortFilterDataTableHeaderCell = <T extends any>({
    column,
    sortDirection,
    handleSort,
    filterConfig,
    filterValue = [],
    setFilter,
}: SortFilterDataTableHeaderCellProps<T>) => {
    const handleColumnSort = () => {
        handleSort?.(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    };

    const anySelected = !!filterValue?.length;

    const trigger = (
        <HeaderCellLabel
            label={column.header}
            hasSort={!!handleSort}
            sort={sortDirection}
            hasFilter={filterConfig ? anySelected : undefined}
        />
    );

    const renderContent = () => {
        if (!filterConfig) {
            return trigger;
        }
        const DropdownList = filterConfig.searchable ? DropdownCheckboxListSearch : DropdownCheckboxList;

        return (
            <DropdownList
                toLabel={filterConfig.formatter}
                toText={value => filterConfig.toSearchLabel(value).toString()}
                options={filterConfig.entries}
                onChange={setFilter}
                values={filterValue}
                trigger={trigger}
            />
        );
    };

    if (!handleSort && !filterConfig) {
        return renderContent();
    }

    return <Pressable onClick={handleColumnSort}>{renderContent()}</Pressable>;
};
