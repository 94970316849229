import React, { ReactNode } from 'react';

import { DropdownCheckboxList, DropdownCheckboxListSearch } from '../../dropdown';
import { HeaderCellLabel } from './HeaderCellLabel';

interface HeaderFilterCellProps<T> {
    options: T[];
    values: T[];
    label: string;
    onChange(v: T[]): void;
    disabled?: boolean;
    toLabel?(v: T): ReactNode;
    toText(v: T): string;
    contentWidth?: number;
    active?: boolean;
    searchable?: boolean;
}

export const HeaderFilterCell = <T,>({
    options,
    values,
    label,
    onChange,
    toText,
    toLabel = toText,
    disabled,
    contentWidth,
    active,
    searchable,
}: HeaderFilterCellProps<T>) => {
    const DropdownComponent = searchable ? DropdownCheckboxListSearch : DropdownCheckboxList;

    return (
        <DropdownComponent
            options={options as NonNullable<T>[]}
            toLabel={toLabel}
            toText={toText}
            onChange={onChange}
            values={values}
            contentMaxHeight={500}
            contentWidth={contentWidth}
            disabled={disabled}
            trigger={<HeaderCellLabel label={label} hasFilter={active ?? values.length > 0} />}
        />
    );
};
