import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Icon, SvgIcon } from '../../icon/index';
import { Rotated } from '../../utils/index';
import { NavigationColors } from '../NavigationColors';

interface ProfileChevronProps {
    isOpen: boolean;
    compact: boolean;
    collapsed: boolean;
    angles?: [number, number];
}

export const ProfileChevron: FC<ProfileChevronProps> = ({
    isOpen,
    angles = [180, 0],
    compact,
    collapsed,
}) => {
    return (
        <Rotated angle={isOpen ? angles[0] : angles[1]}>
            <Icon
                svg={SvgIcon.ChevronUp}
                color={collapsed ? NavigationColors.SubtleIcon : Color.InteractionInvertedNormal}
                size={compact ? 12 : undefined}
                marginLeft={compact ? -8 : undefined}
            />
        </Rotated>
    );
};
