import { sum } from 'lodash';

export const countObjectValues = <T extends object>(obj: T): number => {
    return sum(
        Object.values(obj).map(filter => {
            if (Array.isArray(filter)) {
                return filter.length;
            }
            return filter === undefined || filter === null ? 0 : 1;
        }),
    );
};
