import React, { FC } from 'react';

import { useAuth } from '@hofy/api-admin';
import { PublicSSO } from '@hofy/api-auth';
import { AuthPageWrapper, Or, PublicSSOButton, useAuthI18n } from '@hofy/auth';
import { Box, RWDProvider } from '@hofy/ui';

export const SignInPage: FC = () => {
    const { signIn } = useAuth();

    const { tr } = useAuthI18n();
    return (
        <RWDProvider enabled>
            <AuthPageWrapper
                pageId='sign-in'
                title={tr('sign-in-page.title')}
                showSubHeader={false}
                signInPage
            >
                <Box column gap={24} marginTop={24}>
                    <Or text={tr('sign-in-page.divider.sso')} />
                    <Box column gap={24}>
                        <PublicSSOButton onClick={signIn} mode='sign-in' type={PublicSSO.Google} />
                    </Box>
                </Box>
            </AuthPageWrapper>
        </RWDProvider>
    );
};
