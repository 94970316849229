import { Meta, StoryFn } from '@storybook/react';
import React, { FC, ReactNode, useEffect } from 'react';

import { AddressPayload, AddressType, allAddressTypes, emptyAddressPayload } from '@hofy/api-shared';
import { Form, PrintValueSidebar, SubmitButton, useForm, useFormObjectField, validator } from '@hofy/ui';

import { useAddressValidator } from '../store/useAddressValidator';
import { AddressField } from './AddressField';

interface AddressFieldStoryProps {
    addressType: AddressType;
    label: ReactNode;
    showAlerts?: boolean;
}

const AddressFieldStory: FC<AddressFieldStoryProps> = ({ addressType, ...props }) => {
    const validateAddress = useAddressValidator();
    const form = useForm<NestedAddressPayload>({
        initial: {
            address: emptyAddressPayload(AddressType.Partial),
        },
        validate: validator({
            address: validateAddress,
        }),
    });
    const address = useFormObjectField(form.fields.address);
    useEffect(() => {
        address.type.setValue(addressType);
    }, [addressType]);

    return (
        <PrintValueSidebar value={form.values.address}>
            <Form onSubmit={form.submit}>
                <AddressField {...props} api={address} />
                <SubmitButton label='Save' marginTop={30} />
            </Form>
        </PrintValueSidebar>
    );
};

export default {
    title: 'Packages/Address/AddressField',
    component: AddressFieldStory,
    args: {
        label: 'Address',
        addressType: AddressType.Partial,
    },
    argTypes: {
        addressType: {
            options: allAddressTypes,
            control: { type: 'radio' },
        },
    },
} satisfies Meta<typeof AddressFieldStory>;

interface NestedAddressPayload {
    address: AddressPayload;
}

export const Default: StoryFn<typeof AddressFieldStory> = args => {
    return <AddressFieldStory {...args} />;
};
