import { createContext, useContext } from 'react';

import { emptySessionState } from './SessionState';

export const SessionContext = createContext(emptySessionState);

export const useSession = () => {
    const { session, isInitialized } = useContext(SessionContext);
    return {
        session: session!,
        isInitialized,
    };
};
