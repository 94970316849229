import { useMergeRefs } from '@floating-ui/react';
import React, { cloneElement, forwardRef, HTMLProps, isValidElement } from 'react';

import { Box } from '../base';
import { TooltipState } from './hooks/useTooltip';

interface TooltipTriggerProps extends HTMLProps<HTMLElement> {
    state: TooltipState;
    asChild?: boolean;
}

export const TooltipTrigger = forwardRef<HTMLElement, TooltipTriggerProps>(
    ({ state, children, asChild = false, ...props }, propRef) => {
        const childrenRef = (children as any)?.ref;
        const ref = useMergeRefs([state.data.refs.setReference, propRef, childrenRef]);

        if (asChild && isValidElement(children)) {
            return cloneElement(
                children,
                state.interactions.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                }),
            );
        }

        return (
            <Box ref={ref} cursor='default' {...state.interactions.getReferenceProps(props)}>
                {children}
            </Box>
        );
    },
);
