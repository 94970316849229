import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import {
    AcquisitionType,
    CategoryContractSettingsConfigDto,
    getAvailableRentalTerms,
    PaymentSchema,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import {
    AsyncButton,
    FormContainer,
    FormInput,
    FormSection,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useUpdateOrganizationFinancialSettings } from '../../../../store/organizations/useUpdateOrganizationFinancialSettings';
import { OrganizationModelDropdown } from '../../createOrganizationSlideout/OrganizationModelDropdown';
import { PlatformTierFormSection } from './PlatformTierFormSection';
import { PricingFormSection } from './PricingFormSection';

interface FinancialSettingsUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

const getPaymentSchemas = (contracts: CategoryContractSettingsConfigDto[]): PaymentSchema[] => {
    return contracts
        .filter(v => v.acquisitionType === AcquisitionType.Rental)
        .map(value => value.paymentSchema!);
};
export const FinancialSettingsUpdateModal: FC<FinancialSettingsUpdateModalProps> = ({
    organization,
    onClose,
}) => {
    const { tr } = useAdminI18n();
    const { form, isLoadingMutation } = useUpdateOrganizationFinancialSettings(organization, onClose);

    const isPaymentSchemaUpfront = (schema: PaymentSchema) =>
        schema === PaymentSchema.Upfront && organization.allowedBillingFrequencies.includes(schema);

    const showSchemaWarning = (schema: PaymentSchema) =>
        getPaymentSchemas(organization.defaultContractSettings).includes(schema) &&
        !form.values.allowedBillingFrequencies.includes(schema) &&
        !isPaymentSchemaUpfront(schema);

    return (
        <Modal onClose={onClose}>
            <ModalHeader
                title={tr('organization-page.financial-settings-update.title')}
                subtitle={tr('organization-page.financial-settings-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormSection
                        label={tr(
                            'organization-page.financial-settings-update.modal.organization-model-section',
                        )}
                    >
                        <FormRow>
                            <OrganizationModelDropdown
                                unbundlingEnabled={form.values.unbundlingEnabled}
                                isError={form.errors.unbundlingEnabled}
                                onChange={form.setValues}
                            />
                            {form.values.unbundlingEnabled && (
                                <FormInput
                                    label={tr(
                                        'organization-page.financial-settings-update.modal.storefront-fee',
                                    )}
                                    api={form.fields.storefrontFee}
                                    isRequired
                                    nullable
                                    rightSlot='%'
                                />
                            )}
                        </FormRow>
                    </FormSection>
                    <PlatformTierFormSection
                        form={form}
                        platformTierConfigFeatures={organization.platformTierConfigFeatures}
                    />
                    <PricingFormSection
                        form={form}
                        isPaymentSchemaUpfront={isPaymentSchemaUpfront}
                        showSchemaWarning={showSchemaWarning}
                        currency={organization.currency}
                        availableRentalTerms={getAvailableRentalTerms(form.values.unbundlingEnabled)}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton
                    label={tr('organization-page.financial-settings-update.modal.cancel')}
                    onClick={onClose}
                />
                <AsyncButton
                    label={tr('organization-page.financial-settings-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoadingMutation}
                />
            </ModalFooter>
        </Modal>
    );
};
