import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { useI18nGeneric } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import {
    Box,
    FeaturedIcon,
    Heading2,
    Heading3,
    Logo,
    Paragraph2,
    SectionTitle1,
    SectionTitle2,
    SvgIcon,
    useRWD,
} from '@hofy/ui';

import pageImg1x from './assets/page_image_1x.webp';
import pageImg2x from './assets/page_image_2x.webp';
import pageImg3x from './assets/page_image_3x.webp';
import { AuthPageSubHeader } from './AuthPageSubHeader';

interface SignInWrapperProps {
    title?: string;
    subtitle?: string;
    children?: ReactNode;
    showSubHeader?: boolean;
    signInPage?: boolean;
    formWidth?: number;
    isSignup?: boolean;
    pageId: string;
}

export const AuthPageWrapper: FC<SignInWrapperProps> = ({
    title,
    signInPage = false,
    subtitle,
    children,
    showSubHeader,
    formWidth = 320,
    isSignup = false,
    pageId,
}) => {
    const { isLarge } = useRWD();
    const { tr } = useI18nGeneric();

    return (
        <Box data-test-key={`page:${pageId}`} row alignItems='stretch' flex='auto' fullHeight>
            <Box overflow='auto' flex='auto' column>
                <Box
                    flex={1}
                    paddingHorizontal='mainMarginHorizontal'
                    paddingVertical='mainMarginVertical'
                    centered
                >
                    <Box fullWidth maxWidth={formWidth}>
                        <AuthTitles title={title} subtitle={subtitle} />
                        {showSubHeader && <AuthPageSubHeader signInPage={signInPage} marginTop={24} />}
                        {children}
                    </Box>
                </Box>
            </Box>
            {isLarge && (
                <ImageContainer flex='auto' column padding={16}>
                    <Box maxWidth={600} margin='auto' column>
                        <img src={pageImg1x} srcSet={`${pageImg2x} 2x, ${pageImg3x} 3x`} />
                        <Box flex='auto' justify='center' marginLeft={40} gap={16} column>
                            <Box>
                                {isSignup && (
                                    <Heading2 color={Color.ContentInvertedPrimary} marginBottom={16}>
                                        {tr('sign-up-page.content-title')}
                                    </Heading2>
                                )}
                                <Paragraph2 color={Color.ContentInvertedPrimary}>
                                    {tr('sign-up.page.content-description1')}
                                </Paragraph2>
                                <Paragraph2 color={Color.ContentInvertedPrimary}>
                                    {tr('sign-up.page.content-description2')}
                                </Paragraph2>
                            </Box>
                            {isSignup && (
                                <SectionTitle1 color={Color.ContentInvertedPrimary}>
                                    {tr('sign-up-page.content-subtitle')}
                                </SectionTitle1>
                            )}
                            <Box column gap={16} marginLeft={24}>
                                <Box row gap={16}>
                                    <FeaturedIcon type='positive' icon={SvgIcon.Check} />
                                    <Paragraph2 color={Color.ContentInvertedPrimary}>
                                        {tr('sign-up-page.content-bullet1')}
                                    </Paragraph2>
                                </Box>
                                <Box row gap={16}>
                                    <FeaturedIcon type='positive' icon={SvgIcon.Check} />
                                    <Paragraph2 color={Color.ContentInvertedPrimary}>
                                        {tr('sign-up-page.content-bullet2')}
                                    </Paragraph2>
                                </Box>
                                <Box row gap={16}>
                                    <FeaturedIcon type='positive' icon={SvgIcon.Check} />
                                    <Paragraph2 color={Color.ContentInvertedPrimary}>
                                        {tr('sign-up-page.content-bullet3')}
                                    </Paragraph2>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ImageContainer>
            )}
        </Box>
    );
};

const AuthTitles: FC<{ title?: string; subtitle?: string }> = ({ title, subtitle }) => (
    <Box>
        <Box marginBottom={25}>
            <Logo color />
        </Box>
        {title && <Heading3>{title}</Heading3>}
        {subtitle && <SectionTitle2 marginTop={4}>{subtitle}</SectionTitle2>}
    </Box>
);

const ImageContainer = styled(Box)`
    background: linear-gradient(0deg, #4e38bc 12.3%, #241a56 79.55%);
`;
