import React, { FC } from 'react';

import { DataErasureStatus } from '@hofy/api-shared';
import { ColorDye } from '@hofy/global';
import { Badge } from '@hofy/ui';

import { useTrDataErasureStatus } from '../../../../store/dataErasures/useTrDataErasureStatus';

interface DataErasureStatusBadgeProps {
    status: DataErasureStatus;
}

export const DataErasureStatusBadge: FC<DataErasureStatusBadgeProps> = ({ status }) => {
    const trAssignmentDataErasureStatus = useTrDataErasureStatus();
    return (
        <Badge label={trAssignmentDataErasureStatus(status)} color={assignmentDataErasureChipColor[status]} />
    );
};

const assignmentDataErasureChipColor: Record<DataErasureStatus, ColorDye> = {
    [DataErasureStatus.Pending]: 'orange',
    [DataErasureStatus.Completed]: 'green',
    [DataErasureStatus.Cancelled]: 'red',
};
