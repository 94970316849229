import React, { FC } from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { allRepairsPageTabs, RepairsPageTab } from '../../store/repairs/repairsPageTab';
import { useTrRepairsPageTab } from '../../store/repairs/useTrRepairsPageTab';

interface RepairsPageTabsProps {
    tab: RepairsPageTab;
    onChange(tab: RepairsPageTab): void;
}

export const RepairsPageTabs: FC<RepairsPageTabsProps> = ({ tab, onChange }) => {
    const trTab = useTrRepairsPageTab();
    return (
        <Tabs active={tab} onChange={onChange}>
            {allRepairsPageTabs.map(tab => (
                <Tab key={tab} id={tab} label={trTab(tab)} />
            ))}
        </Tabs>
    );
};
