import React, { FC } from 'react';

import { AdminPayload } from '@hofy/api-admin';
import { allAdminRoles, useTrRole } from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import {
    CheckboxGroup,
    FormCheckbox,
    FormContainer,
    FormEmailInput,
    FormInput,
    FormSection,
    UseForm,
} from '@hofy/ui';

interface AdminFormProps {
    form: UseForm<AdminPayload>;
}

export const AdminForm: FC<AdminFormProps> = ({ form }) => {
    const trRole = useTrRole();
    return (
        <FormContainer marginVertical={20}>
            <FormSection label='Details'>
                <FormRow>
                    <FormInput label='First name' api={form.fields.firstName} isRequired />
                    <FormInput label='Last name' api={form.fields.lastName} isRequired />
                </FormRow>
                <FormEmailInput label='Email' api={form.fields.email} isRequired nullable />
                <FormCheckbox label='Is offboarded' api={form.fields.isOffboarded} />
            </FormSection>
            <FormSection label='Roles'>
                <CheckboxGroup
                    options={allAdminRoles}
                    toKey={role => role}
                    toLabel={trRole}
                    onChange={roles =>
                        form.setValues({
                            ...form.values,
                            roles,
                        })
                    }
                    value={form.values.roles}
                />
            </FormSection>
        </FormContainer>
    );
};
