import { omit } from 'lodash';
import { useState } from 'react';

import {
    ContractFiltersUi,
    ContractFilterType,
    ContractTabFilter,
    StoreAndReuseStatusFilter,
} from '@hofy/api-admin';
import { AnyProductCategory, ContractStatusFilter } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useBooleanQueryParam, useStringQueryParam } from '@hofy/router';

export const useContractsFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [status, setStatus] = useArrayQueryParam<ContractStatusFilter>('status', []);
    const [contractType, setContractTypes] = useArrayQueryParam<ContractFilterType>('contractType', []);
    const [storeAndReuseStatus, setStoreAndReuseStatus] = useArrayQueryParam<StoreAndReuseStatusFilter>(
        'store-and-reuse-status',
        [],
    );
    const [redistributable, setRedistributable] = useBooleanQueryParam('redistributable', undefined);
    const [productCategory, setProductCategory] = useStringQueryParam<AnyProductCategory>('productCategory');
    const [tab, setTab] = useState<ContractTabFilter>(ContractTabFilter.All);
    const [organizations, setOrganizations] = useArrayQueryParam<UUID>('organizations');

    const filters = useStructMemo<ContractFiltersUi>({
        search,
        organizations,
        contractType,
        storeAndReuseStatus,
        storeAndReuseDeliveryCountry: null,
        redistributable,
        productCategory,
        status,
        tab,
        productId: null,
    });

    const filtersToCount = omit(filters, 'search', 'productCategory', 'tab');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setOrganizations,
        setStatus,
        setTab,
        setContractTypes,
        setStoreAndReuseStatus,
        setRedistributable,
        setProductCategory,
    };
};
