import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { UserAssignmentDto, UserAssignmentsByShipmentDto, UserShipmentDto } from '@hofy/api-admin';
import {
    isShipmentFromOrganizationWarehouse,
    isShipmentFromUser,
    isShipmentFromUserToWarehouse,
    isShipmentToOrganizationWarehouse,
    NoteSource,
    ShipmentStatus,
    ShipmentType,
} from '@hofy/api-shared';
import { Box, Divider, Paragraph3 } from '@hofy/ui';

import { ShipmentClassChip } from '../../../../components/domain/shipments/ShipmentClassChip';
import { ShipmentMenu } from '../../../../components/domain/shipments/ShipmentMenu';
import { ShipmentStatusChip } from '../../../../components/domain/shipments/ShipmentStatusChip';
import { ShipmentStatusWithDate } from '../../../../components/domain/shipments/ShipmentStatusWithDate';
import { getPurchaseOrderLink } from '../../../../components/routing/adminLinks';
import { ShipmentAction } from '../../../shipmentSlideout/ShipmentAction';
import { useAssignmentsLinks } from '../../useAssignmentsLinks';
import { NotesCell } from '../shared/cells/NotesCell';
import { ShipmentActionButton } from './cells/ShipmentAction';
import { ShipmentAssigneeCell } from './cells/ShipmentAssigneeCell';
import { ShipmentCourierCell } from './cells/ShipmentCourierCell';
import { ShipmentDateCell, ShipmentDateRangeCell } from './cells/ShipmentDateCell';
import { ShipmentOrganizationWarehouseCell } from './cells/ShipmentOrganizationWarehouseCell';
import { ShipmentPointsCell } from './cells/ShipmentPointsCell';
import { ShipmentPurchaseOrderCell } from './cells/ShipmentPurchaseOrderCell';
import { ShipmentTrackingCell } from './cells/ShipmentTrackingCell';
import { ShipmentTypeCell } from './cells/ShipmentTypeCell';
import { CollectionRequestRow } from './CollectionRequestRow';

interface ShipmentGroupProps {
    user: UserAssignmentsByShipmentDto;
    shipment: UserShipmentDto;
    assignments: UserAssignmentDto[];
    selectedAssignments: UserAssignmentDto[];
    onCreateShipment(t: ShipmentType): void;
    onInPersonTransfer(): void;
    children?: ReactNode;
}

export const ShipmentGroup: FC<ShipmentGroupProps> = ({
    user,
    shipment,
    assignments,
    selectedAssignments,
    onCreateShipment,
    onInPersonTransfer,
    children,
}) => {
    const history = useHistory();

    const {
        getOpenShipmentLink,
        getOpenShipmentNotesLink,
        getShipmentActionLink,
        getCreateNewPurchaseOrderLink,
    } = useAssignmentsLinks();

    const collectionRequest = shipment.collectionRequest;
    const filteredSelectedAssignments = assignments.filter(
        a => !a.isHistorical && selectedAssignments.includes(a),
    );

    const shipmentOTD = shipment.latestDeliveryOn;

    const includeShipmentStatuses = [ShipmentStatus.Backorder, ShipmentStatus.Booked].includes(
        shipment.status,
    );

    const displayShipByStatuses = includeShipmentStatuses || shipment.status === ShipmentStatus.Created;
    const displayEstDeliveryStatuses =
        includeShipmentStatuses || shipment.status === ShipmentStatus.WithCourier;

    const filteredNotes = shipment.notes.filter(note => note.source === NoteSource.Shipments);

    return (
        <>
            <Box row gap={10}>
                <ShipmentMenu
                    shipment={shipment}
                    isCollectionSurveyCreated={collectionRequest !== null}
                    onAction={action => history.push(getShipmentActionLink(shipment.id, action))}
                />
                <Paragraph3 bold>Shipment</Paragraph3>
                <ShipmentStatusChip status={shipment.status} />
                <ShipmentTypeCell
                    publicId={shipment.publicId}
                    type={shipment.adminType}
                    linkToOpenShipment={getOpenShipmentLink(shipment.id)}
                />
                {isShipmentFromOrganizationWarehouse(shipment) && (
                    <ShipmentOrganizationWarehouseCell locationType='origin' />
                )}
                {isShipmentToOrganizationWarehouse(shipment) && (
                    <ShipmentOrganizationWarehouseCell locationType='destination' />
                )}
                {shipment.purchaseOrder && (
                    <ShipmentPurchaseOrderCell
                        purchaseOrderReference={shipment.purchaseOrder.purchaseOrderReference}
                        linkToPurchaseOrder={getPurchaseOrderLink(shipment.purchaseOrder.id)}
                    />
                )}
                {shipment.shipmentClass && <ShipmentClassChip shipmentClass={shipment.shipmentClass} />}
                <Divider marginHorizontal={6} />
                <ShipmentPointsCell user={user} shipment={shipment} />
            </Box>

            {children}

            <Box row gap={65}>
                <ShipmentAssigneeCell
                    user={shipment.assignedUser}
                    shipmentId={shipment.id}
                    alignSelf='flex-start'
                    flex={1}
                />
                <NotesCell
                    notes={filteredNotes}
                    linkToNotes={getOpenShipmentNotesLink(shipment.id)}
                    label='Shipment notes'
                    alignSelf='flex-start'
                    flex={1}
                    gap={4}
                />

                {displayShipByStatuses && !isShipmentFromUserToWarehouse(shipment) && (
                    <ShipmentDateCell
                        title='Ship by'
                        date={shipment.latestWithCourierOn}
                        alignSelf='flex-start'
                        flex={1}
                    />
                )}

                {displayEstDeliveryStatuses && (
                    <ShipmentDateRangeCell
                        title='Est. Delivery'
                        dateRange={shipment.estimateOn}
                        alignSelf='flex-start'
                        flex={1}
                    />
                )}

                {shipment.status === ShipmentStatus.Completed && !isShipmentFromUserToWarehouse(shipment) && (
                    <ShipmentDateCell
                        title='Delivered on'
                        date={shipment.completedOn}
                        alignSelf='flex-start'
                        flex={1}
                    />
                )}

                {!isShipmentFromUserToWarehouse(shipment) && (
                    <ShipmentDateCell title='OTD' date={shipmentOTD} alignSelf='flex-start' flex={1} />
                )}

                {shipment.status !== ShipmentStatus.Created && (
                    <>
                        <ShipmentCourierCell courier={shipment.courier} alignSelf='flex-start' flex={1} />
                        <ShipmentTrackingCell
                            trackingLink={shipment.trackingLink}
                            alignSelf='flex-start'
                            flex={1}
                        />
                    </>
                )}

                <Box column gap={4} alignItems='flex-end'>
                    <Box paddingRight={8}>
                        <ShipmentStatusWithDate {...shipment} />
                    </Box>
                    <ShipmentActionButton
                        assignments={assignments}
                        selectedAssignments={filteredSelectedAssignments}
                        onCreateShipment={onCreateShipment}
                        onInPersonTransfer={onInPersonTransfer}
                        shipmentInfo={{
                            shipment,
                            linkToCreatePurchaseOrder: getCreateNewPurchaseOrderLink(shipment.id),
                            linkToProcessShipment: getShipmentActionLink(shipment.id, ShipmentAction.Book),
                            linkToBackorderShipment: getShipmentActionLink(
                                shipment.id,
                                ShipmentAction.Backorder,
                            ),
                            linkToShipShipment: getShipmentActionLink(shipment.id, ShipmentAction.Ship),
                            linkToCompleteShipment: getShipmentActionLink(
                                shipment.id,
                                ShipmentAction.Complete,
                            ),
                        }}
                    />
                </Box>
            </Box>
            {collectionRequest &&
                ((isShipmentFromUser(shipment) && shipment.fromUser?.id === user.id) ||
                    isShipmentFromOrganizationWarehouse(shipment)) && (
                    <CollectionRequestRow
                        collectionRequest={collectionRequest}
                        shipment={shipment}
                        user={user}
                    />
                )}
        </>
    );
};
