import { globalI18n } from '@hofy/global';

import { dateRangeNameI18n } from './dateRangeNameI18n';
import { dateRangeTypeI18n } from './dateRangeTypeI18n';
import { durationI18n } from './durationl18n';
import { commonI18n } from './generalI18n';
import { uiI18n } from './uiI18n';

export const baseI18n = {
    ...commonI18n,
    ...durationI18n,
    ...uiI18n,
    ...dateRangeNameI18n,
    ...dateRangeTypeI18n,
    ...globalI18n,
};

export type BaseI18n = keyof typeof baseI18n;
