import { times } from 'lodash';
import React, { FC } from 'react';

import { OffsetValue } from '@hofy/theme';

import { Box, OuterBoxProps } from '../base';
import { Skeleton } from './Skeleton';

interface SkeletonListProps extends OuterBoxProps {
    items: number;
    height: number;
    gap: OffsetValue;
}

export const SkeletonList: FC<SkeletonListProps> = ({ items, height, gap, ...rest }) => {
    return (
        <Box column gap={gap} alignContent='stretch' {...rest}>
            {times(items, i => (
                <Skeleton key={i} height={height} />
            ))}
        </Box>
    );
};
