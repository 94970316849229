import { isEmpty } from 'lodash';
import React, { FC, ReactNode } from 'react';

import {
    AddressDto,
    allShipmentIssues,
    AssignmentShipmentIssue,
    ItemStatus,
    useTrAssignmentShipmentIssue,
} from '@hofy/api-shared';
import { FormDropdown, FormTextarea, Switch } from '@hofy/common';
import { UUID } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, ConfirmModal, Icon, Paragraph3, SvgIcon, UseForm, useForm } from '@hofy/ui';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { useReportShipmentIssue } from '../../../store/assignments/useReportShipmentIssue';
import { SelectAddress } from './SelectAddress';

interface OrderShipmentIssueModalProps {
    assignmentId: UUID;
    userAddress: AddressDto;
    deliveryAddress: AddressDto;
    onSuccess(): void;
}

interface AssignmentShipmentIssueFormData {
    shipmentIssue: AssignmentShipmentIssue | null;
    collectAssignment: boolean;
    createReplacement: boolean;
    createLoaner: boolean;
    issueNotes: string;
    deliveryAddressId?: UUID;
}

interface ValidatedAssignmentShipmentIssueFormData extends AssignmentShipmentIssueFormData {
    shipmentIssue: AssignmentShipmentIssue;
}

export const AssignmentShipmentIssueModal: FC<OrderShipmentIssueModalProps> = ({
    assignmentId,
    userAddress,
    deliveryAddress,
    onSuccess,
}) => {
    const trShipmentIssue = useTrAssignmentShipmentIssue();
    const { reportShipmentIssue } = useReportShipmentIssue(assignmentId, onSuccess);

    const form = useForm<AssignmentShipmentIssueFormData, ValidatedAssignmentShipmentIssueFormData>({
        initial: {
            shipmentIssue: null,
            collectAssignment: true,
            createReplacement: true,
            createLoaner: false,
            deliveryAddressId: deliveryAddress.id,
            issueNotes: '',
        },
        onSubmit: ({
            shipmentIssue,
            collectAssignment,
            createReplacement,
            createLoaner,
            issueNotes,
            deliveryAddressId,
        }) => {
            reportShipmentIssue({
                issueType: shipmentIssue,
                createCollection: collectAssignment,
                createReplacement,
                createLoaner,
                issueNotes,
                deliveryAddressId: deliveryAddressId ?? null,
            });
        },
        validate: ({ shipmentIssue, issueNotes, createLoaner, createReplacement, deliveryAddressId }) => ({
            shipmentIssue: errorMap([isEmpty(shipmentIssue), 'Shipment reason is required']),
            issueNotes: errorMap(
                [
                    shipmentIssue === AssignmentShipmentIssue.Other && isEmpty(issueNotes),
                    'Reason is required',
                ],
                [issueNotes.length > 500, 'Reason notes exceed max 500 chars'],
            ),
            deliveryAddressId: errorMap([
                (createLoaner || createReplacement) && !deliveryAddressId,
                'Delivery Address is required',
            ]),
        }),
    });

    const enableCollectionSwitch =
        form.values.shipmentIssue && form.values.shipmentIssue !== AssignmentShipmentIssue.LostInTransit;

    const createLoanerOrReplacement = form.values?.createLoaner || form.values?.createReplacement;

    return (
        <ConfirmModal
            keyPrefix='item-assignment-shipment-issue-modal'
            onClose={onSuccess}
            closeOnConfirm={false}
            onConfirm={form.submit}
            width={createLoanerOrReplacement && userAddress.id === deliveryAddress.id ? 450 : 800}
        >
            <FormDropdown
                items={allShipmentIssues}
                label='Shipment Issue'
                labelFormatter={trShipmentIssue}
                value={form.values.shipmentIssue}
                onChange={shipmentIssue =>
                    form.setValues({
                        shipmentIssue,
                    })
                }
                isError={form.errors.shipmentIssue}
                isRequired
                marginTop={20}
                marginBottom={10}
            />
            {form.values.shipmentIssue === AssignmentShipmentIssue.Other && (
                <FormTextarea
                    marginBottom={10}
                    label='Reason:'
                    value={form.values.issueNotes}
                    onChangeText={issueNotes =>
                        form.setValues({
                            issueNotes,
                        })
                    }
                    note='Max 500 characters'
                    rows={4}
                    isError={form.errors.issueNotes}
                />
            )}
            <CreateReplacementSwitch form={form} />
            <CollectAssignmentSwitch enableCollectionSwitch={!!enableCollectionSwitch} form={form} />
            <CreateLoanerSwitch form={form} />
            {form.values?.shipmentIssue === AssignmentShipmentIssue.LostInTransit && (
                <Box marginTop={20} row>
                    <Paragraph3>Item will be marked as &nbsp;</Paragraph3>
                    <ItemStatusChip status={ItemStatus.LostInTransit} />
                </Box>
            )}
            {form.values?.shipmentIssue === AssignmentShipmentIssue.DamagedInTransit && (
                <Box marginTop={20} row>
                    <Paragraph3>Item will be marked as &nbsp;</Paragraph3>
                    <ItemStatusChip status={ItemStatus.DamagedInTransit} />
                </Box>
            )}
            {createLoanerOrReplacement && (
                <Box marginTop={16}>
                    <SelectAddress
                        userAddress={userAddress}
                        deliveryAddress={deliveryAddress}
                        onSelect={address => {
                            form.setValues({
                                deliveryAddressId: address.id,
                            });
                        }}
                    />
                </Box>
            )}
        </ConfirmModal>
    );
};
const CreateReplacementSwitch: FC<{
    form: UseForm<AssignmentShipmentIssueFormData>;
}> = ({ form }) => {
    return (
        <>
            <Switch
                label='Create replacement assignment'
                disabled={!form.values.shipmentIssue}
                checked={form.values.createReplacement}
                onChange={createReplacement =>
                    form.setValues({
                        createReplacement,
                    })
                }
            />
            {form.values.shipmentIssue && form.values.createReplacement && (
                <Box row>
                    <Icon
                        svg={SvgIcon.AlertTriangle}
                        size={20}
                        marginRight={12}
                        color={Color.FoundationWarning}
                    />
                    <Paragraph3 color={Color.FoundationWarning} marginTop={2}>
                        A replacement order will be created
                    </Paragraph3>
                </Box>
            )}
        </>
    );
};

const CollectAssignmentSwitch: FC<{
    form: UseForm<AssignmentShipmentIssueFormData>;
    enableCollectionSwitch: boolean;
}> = ({ form, enableCollectionSwitch }) => {
    return (
        <>
            <Switch
                marginTop={10}
                label='Collect Item'
                disabled={!enableCollectionSwitch}
                checked={form.values.collectAssignment}
                onChange={sendCollection =>
                    form.setValues({
                        collectAssignment: sendCollection,
                    })
                }
            />
            {enableCollectionSwitch && form.values.collectAssignment && (
                <Warning> A collection order will be created</Warning>
            )}
        </>
    );
};
const CreateLoanerSwitch: FC<{
    form: UseForm<AssignmentShipmentIssueFormData>;
}> = ({ form }) => {
    return (
        <>
            <Switch
                marginTop={10}
                label='Send loaner'
                disabled={!form.values.shipmentIssue}
                checked={form.values.createLoaner}
                onChange={createLoaner =>
                    form.setValues({
                        createLoaner,
                    })
                }
            />
            {form.values.shipmentIssue && form.values.createLoaner && (
                <Warning> A loaner order will be created</Warning>
            )}
        </>
    );
};

interface WarningProps {
    children: ReactNode;
}

const Warning: FC<WarningProps> = ({ children }) => {
    return (
        <Box row marginTop={4}>
            <Icon svg={SvgIcon.AlertTriangle} size={20} marginRight={12} color={Color.FoundationWarning} />
            <Paragraph3 color={Color.FoundationWarning} marginTop={2}>
                {children}
            </Paragraph3>
        </Box>
    );
};
