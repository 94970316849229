import React, { FC, useState } from 'react';

import { useInvoiceEntry } from '@hofy/api-admin';
import { InvoiceEntryType, Permission, TaxStatus } from '@hofy/api-shared';
import { ComponentLoader, MoreMenu, Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Badge, Box, Button, ConfirmModal, Heading3, Modals, PageHeader } from '@hofy/ui';

import { useComputeTaxForInvoiceEntry } from '../../../../store/invoiceEntries/useComputeTaxForInvoiceEntry';
import { useCreditInvoiceEntry } from '../../../../store/invoiceEntries/useCreditInvoiceEntry';
import { useDeleteInvoiceEntry } from '../../../../store/invoiceEntries/useDeleteInvoiceEntry';
import { useRecreateInvoiceEntry } from '../../../../store/invoiceEntries/useRecreateInvoiceEntry';
import { useReportTaxForInvoiceEntry } from '../../../../store/invoiceEntries/useReportTaxForInvoiceEntry';
import { InvoiceEntryDetails } from './InvoiceEntryDetails';

interface InvoiceEntryDetailsOverlayProps {
    entryId: UUID;
    onEntryEdit(): void;
    onUpdateTaxAddress(): void;
    onNewEntry(newId: UUID): void;
}

enum Modal {
    ConfirmDelete,
    ConfirmCredit,
    ConfirmRecreate,
}

export const InvoiceEntryDetailsOverlay: FC<InvoiceEntryDetailsOverlayProps> = ({
    entryId,
    onEntryEdit,
    onUpdateTaxAddress,
    onNewEntry,
}) => {
    const { invoiceEntry, isLoading } = useInvoiceEntry(entryId);
    const { deleteEntry } = useDeleteInvoiceEntry(entryId);
    const { creditEntry } = useCreditInvoiceEntry(entryId, onNewEntry);
    const { computeTax } = useComputeTaxForInvoiceEntry(entryId);
    const { reportTax } = useReportTaxForInvoiceEntry(entryId);
    const { recreateEntry } = useRecreateInvoiceEntry(entryId, onNewEntry);
    const { hasPermission } = usePermission();
    const [showModal, setShowModal] = useState<Modal | null>(null);
    const isDeleted = invoiceEntry?.deletedAt;
    const canBeCredited =
        hasPermission(Permission.AdminInvoicesEntriesCreate) &&
        invoiceEntry?.type !== InvoiceEntryType.CreditNote;
    const canRecreate =
        hasPermission(Permission.AdminInvoicesEntriesCreate) &&
        invoiceEntry?.type !== InvoiceEntryType.CreditNote &&
        invoiceEntry?.isInvoiced;

    const canComputeTax =
        hasPermission(Permission.AdminInvoicesEntriesUpdate) &&
        (!invoiceEntry?.isReadonly ||
            [TaxStatus.Prediction, TaxStatus.Error].includes(invoiceEntry?.tax.status!));

    const moreMenu = (
        <MoreMenu
            items={[
                {
                    action: () => computeTax(),
                    label: 'Compute tax',
                    visible: canComputeTax,
                },
                {
                    action: () => reportTax(),
                    label: 'Report tax',
                    visible:
                        [TaxStatus.Computed, TaxStatus.ReportError].includes(invoiceEntry?.tax.status!) &&
                        hasPermission(Permission.AdminInvoicesEntriesUpdate),
                },
                {
                    action: onUpdateTaxAddress,
                    label: 'Update tax address',
                    visible:
                        hasPermission(Permission.AdminInvoicesEntriesUpdate) &&
                        invoiceEntry?.tax.status !== TaxStatus.Reported,
                },
                {
                    action: () => setShowModal(Modal.ConfirmCredit),
                    label: 'Credit',
                    visible: canBeCredited,
                },
                {
                    action: () => setShowModal(Modal.ConfirmRecreate),
                    label: 'Credit and re-create',
                    visible: canRecreate,
                },
                {
                    action: () => setShowModal(Modal.ConfirmDelete),
                    color: Color.FoundationNegative,
                    label: 'Delete invoice entry',
                    visible:
                        !invoiceEntry?.isReadonly &&
                        hasPermission(Permission.AdminInvoicesEntriesDelete) &&
                        !invoiceEntry?.isPartner,
                },
            ]}
        />
    );

    const canEdit =
        hasPermission(Permission.AdminInvoicesEntriesUpdate) &&
        !isDeleted &&
        !invoiceEntry?.isReadonly &&
        !invoiceEntry?.isPartner;

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={
                    <>
                        <Heading3>{`Invoice entry #${invoiceEntry?.ref || ''}`}</Heading3>
                        {isDeleted && <Badge color='red' label='Deleted' />}
                    </>
                }
                rightSlot={
                    <>
                        {canEdit && <Button label='Edit' onClick={onEntryEdit} />}
                        {moreMenu}
                    </>
                }
            />
            <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40} relative>
                {isLoading || !invoiceEntry ? (
                    <ComponentLoader />
                ) : (
                    <InvoiceEntryDetails entry={invoiceEntry} />
                )}
            </Box>

            <Modals>
                {showModal === Modal.ConfirmDelete && (
                    <ConfirmModal
                        onClose={() => setShowModal(null)}
                        onConfirm={deleteEntry}
                        keyPrefix='delete-invoice-entry-modal'
                    />
                )}
                {showModal === Modal.ConfirmCredit && (
                    <ConfirmModal
                        onClose={() => setShowModal(null)}
                        onConfirm={creditEntry}
                        keyPrefix='credit-invoice-entry-modal'
                    />
                )}
                {showModal === Modal.ConfirmRecreate && (
                    <ConfirmModal
                        onClose={() => setShowModal(null)}
                        onConfirm={recreateEntry}
                        keyPrefix='recreate-invoice-entry-modal'
                    />
                )}
            </Modals>
        </Overlay>
    );
};
