import { useMutation, useQueryClient } from '@tanstack/react-query';

import { journalsCacheKey, journalsService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useSyncJournalToNetsuite = (journalId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => journalsService.syncToNetsuite(journalId),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [journalsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Journal scheduled to sync to accounting system',
            });
        },
    });

    return {
        syncToNetsuite: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
