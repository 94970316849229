import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import { LabeledOrganizationProps, LabeledOrganizationSelect } from './LabeledOrganizationSelect';

type FormOrganizationSelectOptionalProps = Optional<LabeledOrganizationProps, 'onChange'>;
type FormOrganizationSelectOmittedProps = Omit<FormOrganizationSelectOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalOrganizationSelectProps extends FormOrganizationSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID>;
    nullable?: false;
}

interface FormNullableOrganizationSelectProps extends FormOrganizationSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID | null>;
    nullable: true;
}

type FormOrganizationSelectProps = FormNormalOrganizationSelectProps | FormNullableOrganizationSelectProps;

export const FormOrganizationSelect: FC<FormOrganizationSelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledOrganizationSelect
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={(value: UUID | null) => {
                api.setValue(value!);
                onChange?.(value!);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
