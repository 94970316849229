import { useRef, useState } from 'react';

export enum CopyToClipboardStatus {
    Idle,
    Copying,
    Copied,
    Error,
}

export const useCopyToClipboard = (text: string, resetToIdleTimeout = 3000) => {
    const [copyStatus, setCopyStatus] = useState(CopyToClipboardStatus.Idle);
    const timeout = useRef<NodeJS.Timeout>();

    const copyToClipboard = () => {
        clearTimeout(timeout.current);
        setCopyStatus(CopyToClipboardStatus.Copying);
        window.navigator.clipboard
            .writeText(text)
            .then(() => setCopyStatus(CopyToClipboardStatus.Copied))
            .catch(() => setCopyStatus(CopyToClipboardStatus.Error))
            .finally(
                () =>
                    (timeout.current = setTimeout(
                        () => setCopyStatus(CopyToClipboardStatus.Idle),
                        resetToIdleTimeout,
                    )),
            );
    };

    return { copyStatus, copyToClipboard };
};
