/* eslint-disable import/no-cycle */
import { createEvent, STORAGE_LOCAL_EVENT } from '@hofy/helpers';

import { AuthError, isAuthError } from './types/AuthError';
import {
    isValidToken,
    isValidTokenExpires,
    TokenPair,
    TokenPairDto,
    toTokenPair,
} from './types/TokenPairDto';

export const AUTH_ERROR_PARAM = 'auth-error';

interface InitPayload {
    historyReplaceHandler(args: { pathName: string; search: string }): void;
}

export abstract class BaseAuthProvider {
    public STORAGE_KEY = `auth:tokens`;

    protected refreshPromise: Promise<string | null> | null = null;

    protected getTokenFromStorage = (): TokenPair | null => {
        const item = window.localStorage.getItem(this.STORAGE_KEY);
        return item ? JSON.parse(item) : null;
    };

    protected setValueInStorage = (value: TokenPair) => {
        window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(value));
        window.dispatchEvent(createEvent(STORAGE_LOCAL_EVENT));
    };

    protected clearToken = () => {
        window.localStorage.removeItem(this.STORAGE_KEY);
        window.dispatchEvent(createEvent(STORAGE_LOCAL_EVENT));
    };

    public init = async ({ historyReplaceHandler }: InitPayload): Promise<AuthError | boolean> => {
        const token = await this.getInitialValidToken();

        if (!token) {
            const ssoToken = this.getSSORedirectResult({ historyReplaceHandler });
            if (isAuthError(ssoToken)) {
                return ssoToken;
            }
            if (ssoToken) {
                const newTokenPair = await this.exchangeTemporary({ token: ssoToken! });
                this.consumeTokenPair(newTokenPair);
                if (isAuthError(newTokenPair)) {
                    return newTokenPair;
                } else {
                    return true;
                }
            }
            return false;
        }

        return true;
    };

    public getInitialValidToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token) {
            return null;
        }

        if (!token.refresh_token && isValidTokenExpires(token.expires_at)) {
            return token.access_token;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        if (isValidToken(token.refresh_token)) {
            const promise = this.refresh({ refresh_token: token.refresh_token }).then(this.consumeTokenPair);
            this.refreshPromise = promise;
            return promise;
        }
        this.clearToken();
        return null;
    };

    public forceRefreshToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token || !isValidToken(token.refresh_token)) {
            this.clearToken();
            return null;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        const promise = this.refresh({ refresh_token: token.refresh_token }).then(this.consumeTokenPair);
        this.refreshPromise = promise;
        return promise;
    };

    public getValidToken = async (): Promise<string | null> => {
        const token = this.getTokenFromStorage();

        if (!token) {
            return null;
        }

        if (isValidTokenExpires(token.expires_at)) {
            return token.access_token;
        }

        if (this.refreshPromise) {
            return this.refreshPromise;
        }

        if (isValidToken(token.refresh_token)) {
            const promise = this.refresh({ refresh_token: token.refresh_token }).then(this.consumeTokenPair);
            this.refreshPromise = promise;
            return promise;
        }
        this.clearToken();
        return null;
    };

    protected abstract refresh(args: { refresh_token: string }): Promise<TokenPair | AuthError>;
    protected abstract exchangeTemporary(args: { token: string }): Promise<TokenPair | AuthError>;

    public signOut = () => {
        this.clearToken();
    };

    public setTokens = async (token: TokenPairDto) => {
        this.setValueInStorage(toTokenPair(token));
    };

    protected getSSORedirectResult = ({ historyReplaceHandler }: InitPayload) => {
        const params = new URLSearchParams(window.location.search);
        const token = params.get('temporary-token');
        const authError = params.get(AUTH_ERROR_PARAM);
        if (authError) {
            return authError as AuthError;
        }

        if (token) {
            params.delete('temporary-token');
            historyReplaceHandler({
                pathName: window.location.pathname,
                search: params.toString(),
            });
        }
        return token;
    };

    protected consumeTokenPair = (newTokenPair: TokenPair | AuthError) => {
        this.refreshPromise = null;
        if (isAuthError(newTokenPair)) {
            // eslint-disable-next-line no-console
            console.error(newTokenPair);
            this.clearToken();
            return null;
        } else {
            this.setValueInStorage(newTokenPair);
            return newTokenPair.access_token;
        }
    };
}
