import React, { FC } from 'react';

import { Box } from '@hofy/ui';

import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { NetsuitePage } from './NetsuitePage';
import { NetsuitePageMenu } from './NetsuitePageMenu';

interface NetsuiteRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const NetsuiteRouter: FC<NetsuiteRouterProps> = ({ tab, tabs, onChangeTab }) => {
    return (
        <Box column flex='auto'>
            <SettingsHeader
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                titleContent={<NetsuitePageMenu />}
            />
            <NetsuitePage />
        </Box>
    );
};
