import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { orderService } from '../service/orderService';
import { OrderDto } from '../service/types/OrderDto';
import { OrderFilters } from '../service/types/OrderFilters';
import { ordersQueryKey } from './requestsQueryKey';

export const useOrdersQuery = (filters: OrderFilters) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [ordersQueryKey, filters],
        queryFn: param =>
            orderService.listOrders(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const requests = useMemo<OrderDto[]>(() => (data ? data.pages.flatMap(v => v.content) : []), [data]);

    return {
        data: requests,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
