import { ColorDye, getEnumValues } from '@hofy/global';

export enum PaymentType {
    Deferred = 'deferred',
    Prepaid = 'pre_paid',
}

export const allPaymentTypes = getEnumValues<PaymentType>(PaymentType);

export const paymentTypeColors: Record<PaymentType, ColorDye> = {
    [PaymentType.Deferred]: 'orange',
    [PaymentType.Prepaid]: 'blue',
};
