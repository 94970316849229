import { omit } from 'lodash';

import { RepaymentPlansFilters } from '@hofy/api-admin';
import { RepaymentStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useBooleanQueryParam, useStringQueryParam } from '@hofy/router';

export const useRepaymentPlansFilters = () => {
    const [organizations, setOrganizations] = useArrayQueryParam<UUID>('organizations');
    const [billingEntities, setBillingEntities] = useArrayQueryParam<UUID>('billingEntities');
    const [status, setStatus] = useArrayQueryParam<RepaymentStatus>('repaymentStatus');
    const [search, setSearch] = useStringQueryParam('search', '');
    const [isInvoiced, setIsInvoiced] = useBooleanQueryParam('isInvoiced');
    const filters = useStructMemo<RepaymentPlansFilters>({
        organizations,
        billingEntities,
        search,
        status,
        isInvoiced,
        contractId: undefined,
    });

    const filtersToCount = omit(filters, 'search', 'contractId');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setStatus,
        setOrganizations,
        setBillingEntities,
        setSearch,
        setIsInvoiced,
    };
};
