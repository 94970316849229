import { darken } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { PublicSSO } from '@hofy/api-auth';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph2, RawIcon } from '@hofy/ui';

import { AuthI18nKey } from '../../../i18n/authI18n';
import { useAuthI18n } from '../../../i18n/useAuthI18n';
import GoogleImg from './assets/google.svg';
import MicrosoftImg from './assets/microsoft.svg';

interface GoogleButtonProps extends BoxProps {
    onClick(): void;
    type: PublicSSO;
    mode: 'sign-in' | 'sign-up';
}

interface Config {
    signInKey: AuthI18nKey;
    signUpKey: AuthI18nKey;
    logo: Svg;
}

export const PublicSSOButton: FC<GoogleButtonProps> = ({ onClick, mode, type, ...boxProps }) => {
    const { tr } = useAuthI18n();
    const config: Record<PublicSSO, Config> = {
        [PublicSSO.Google]: {
            signInKey: 'auth.sign-in-with-google',
            signUpKey: 'auth.sign-up-with-google',
            logo: GoogleImg,
        },
        [PublicSSO.Microsoft]: {
            signInKey: 'auth.sign-in-with-microsoft',
            signUpKey: 'auth.sign-up-with-microsoft',
            logo: MicrosoftImg,
        },
    };
    return (
        <Wrapper
            fullWidth
            cursor='pointer'
            centered
            onClick={() => onClick()}
            as='button'
            color={Color.InteractionNeutralNormal}
            bold
            {...boxProps}
        >
            <Box row justify='center'>
                <RawIcon marginVertical={12} marginRight={10} size={24} svg={config[type].logo} />
                {mode === 'sign-in' ? tr(config[type].signInKey) : tr(config[type].signUpKey)}
            </Box>
        </Wrapper>
    );
};

const Wrapper = styled(Paragraph2)`
    border-radius: 8px;
    border: 1px solid ${Color.InteractionBorderNeutralNormal};

    &:hover {
        background: ${darken(0.05, Color.BackgroundDefault)};
    }
`;
