import { useMutation } from '@tanstack/react-query';

import { contractService } from '../service/contractService';

export const useDownloadContractsReport = () => {
    const downloadMutation = useMutation({
        mutationFn: contractService.downloadContractsReport,
    });
    return {
        downloadContractReport: downloadMutation.mutate,
    };
};
