import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { FeaturedIcon, SvgIcon } from '@hofy/ui';

import { LocationCard } from './LocationCard';

interface OrgWarehouseLocationCardProps {
    label?: string;
    name: string;
    country?: Country;
}

export const OrgWarehouseLocationCard: FC<OrgWarehouseLocationCardProps> = ({ label, name, country }) => {
    return (
        <LocationCard
            icon={
                <FeaturedIcon
                    icon={SvgIcon.Warehouse}
                    size={32}
                    shape='circle'
                    variant='vivid'
                    type='positive'
                />
            }
            label={label}
            name={name}
            country={country}
        />
    );
};
