import React, { FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SectionTitle3, SvgIcon, useIsDisabled } from '@hofy/ui';

interface QuantitySelectorProps extends BoxProps {
    value: number;
    disabled?: boolean;
    minValue?: number;
    maxValue?: number;
    onChange(v: number): void;
}

/** @deprecated not yet implemented in `@hofy/ui` */
export const QuantitySelector: FC<QuantitySelectorProps> = ({
    value,
    disabled: disabledSelector,
    minValue = 1,
    maxValue,
    onChange,
    ...boxProps
}) => {
    const leftDisabled = value <= minValue;
    const rightDisabled = !!maxValue && value >= maxValue;
    const disabled = useIsDisabled(disabledSelector);

    return (
        <Box {...boxProps} row>
            <LeftButton disabled={leftDisabled || disabled} onClick={() => onChange(value - 1)}>
                <Icon
                    size={16}
                    svg={SvgIcon.Minus}
                    color={leftDisabled ? Color.Neutral300 : Color.Neutral700}
                />
            </LeftButton>
            <ValueWrapper>
                <SectionTitle3>{value}</SectionTitle3>
            </ValueWrapper>
            <RightButton disabled={rightDisabled || disabled} onClick={() => onChange(value + 1)}>
                <Icon
                    size={16}
                    svg={SvgIcon.Add}
                    color={rightDisabled ? Color.Neutral300 : Color.Neutral700}
                />
            </RightButton>
        </Box>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 36px;
    height: 36px;
    background-color: ${Color.BackgroundDefault};
    border-color: ${Color.Neutral300};
    border-style: solid;
    border-width: 1px;
    border-radius: 0;
`;

const ValueWrapper = styled(Wrapper)`
    border-width: 1px 0px;
`;
const ButtonWrapper = styled(Wrapper)<{ disabled: boolean }>`
    user-select: none;
    &:hover {
        background-color: ${p => (p.disabled ? Color.BackgroundDefault : Color.BackgroundSubtleNeutral)};
    }
    cursor: ${p => (p.disabled ? 'initial' : 'pointer')};
    pointer-events: ${p => (p.disabled ? 'none' : 'initial')};
`;
const LeftButton = styled(ButtonWrapper)`
    border-radius: 8px 0px 0px 8px;
`;
const RightButton = styled(ButtonWrapper)`
    border-radius: 0px 8px 8px 0px;
`;
