import { isEqual } from 'lodash';
import React, { FC } from 'react';

import { AddressDto } from '@hofy/api-shared';
import { IconItemWithTooltip } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Span, SvgIcon } from '@hofy/ui';

import { AddressTooltipOverlay } from '../../../../../components/domain/address/AddressTooltipOverlay';

interface CollectionConfirmedAddressCellProps {
    confirmedAddress: AddressDto | null;
    shipmentAddress: AddressDto;
}

export const CollectionConfirmedAddressCell: FC<CollectionConfirmedAddressCellProps> = ({
    confirmedAddress,
    shipmentAddress,
}) => {
    if (!confirmedAddress) {
        return null;
    }

    const sameAddress = isEqual(confirmedAddress, shipmentAddress);
    const sameCountry = isEqual(confirmedAddress.country, shipmentAddress.country);

    return (
        <IconItemWithTooltip
            tooltip={!sameAddress && <AddressTooltipOverlay marginLeft={5} address={confirmedAddress} />}
            icon={SvgIcon.Home}
            iconColor={sameAddress ? Color.FoundationPositive : Color.AccentRed}
        >
            {`${sameAddress ? 'Confirmed' : 'Updated'} address`}
            {!sameCountry && <Span color={Color.ContentNegative}>&nbsp;(different country!)</Span>}
        </IconItemWithTooltip>
    );
};
