import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useRWD } from '../../../contexts';
import { Box, Pressable } from '../../base';
import { Icon, SvgIcon } from '../../icon';
import { Logo } from '../../logo';
import { NavigationColors } from '../NavigationColors';
import { useNavigationContext } from '../NavigationContext';
import { opacityTransition, opacityVariants } from './fadeVariants';
import { NavigationEnv } from './NavigationEnv';

interface NavigationHeaderProps {
    collapsible?: boolean;
    rightSlot?: ReactNode;
    logoLink?: string;
    env?: string;
    envSlot?: ReactNode;
    identitySlot?: ReactNode;
}

export const NavigationHeader: FC<NavigationHeaderProps> = ({
    collapsible,
    rightSlot,
    logoLink,
    env,
    envSlot,
    identitySlot,
}) => {
    const { collapsed, toggleCollapsed } = useNavigationContext();
    const { isMobile } = useRWD();

    const logo = <Logo small={collapsed} />;

    const LogoWrapper = collapsible ? Pressable : Box;

    return (
        <Box
            direction={isMobile ? 'row' : 'column'}
            gap={isMobile ? 0 : 24}
            as={motion.div}
            initial={false}
            variants={opacityVariants}
            transition={opacityTransition}
        >
            <Box row justify={collapsed ? 'center' : 'space-between'} paddingHorizontal={16}>
                <LogoWrapper
                    row
                    paddingLeft={collapsible ? 8 : undefined}
                    onClick={collapsible ? toggleCollapsed : undefined}
                    as={collapsible ? 'button' : undefined}
                >
                    {logoLink ? <Link to={logoLink}>{logo}</Link> : logo}
                    {collapsible && (
                        <Icon
                            svg={collapsed ? SvgIcon.ChevronRight : SvgIcon.ChevronLeft}
                            color={NavigationColors.SubtleIcon}
                            size={12}
                            marginRight={-8}
                        />
                    )}
                </LogoWrapper>
                {!collapsed && rightSlot}
            </Box>
            <Box direction={isMobile ? 'row' : 'column'} gap={isMobile ? 16 : 24}>
                {identitySlot}
                {env && <NavigationEnv env={env} rightSlot={envSlot} />}
            </Box>
        </Box>
    );
};
