import { getEnumValues } from '@hofy/global';

export enum SupplierContactRole {
    Primary = 'primary',
    Commercial = 'commercial',
    Finance = 'finance',
    CustomerService = 'customer_service',
}

export const allSupplierContactRoles = getEnumValues<SupplierContactRole>(SupplierContactRole);
