import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    AssignInspectionHofyWarehouseBinPayload,
    inspectionService,
    inspectionsQueryKey,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useAssignInspectionHofyWarehouseBin = (uuid: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, AssignInspectionHofyWarehouseBinPayload>({
        mutationFn: payload => inspectionService.assignInspectionHofyWarehouseBin(uuid, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey] });
            onSuccess?.();
        },
    });

    return {
        assignBin: (hofyWarehouseBinIdentifier: string | null) => {
            hofyWarehouseBinIdentifier && mutation.mutate({ hofyWarehouseBinIdentifier });
        },
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
