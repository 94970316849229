import { useQuery } from '@tanstack/react-query';

import { issueService } from '../service/issueService';
import { IssueFilter } from '../service/types/IssueFilter';
import { issuesCacheKey } from './cacheKey';

export const useIssuesQuery = (tab: IssueFilter) => {
    const { isLoading, data, isError } = useQuery({
        queryKey: [issuesCacheKey, tab],

        queryFn: () => issueService.listIssues(tab),
    });

    return {
        isLoading,
        isError,
        data,
    };
};
