import React, { FC } from 'react';

import { BYODOrderDto } from '@hofy/api-admin';

import { ShipmentTrackingLink } from '../../../shipmentsPage/components/table/ShipmentTrackingLink';

interface BYODTrackingLinkProps {
    byodOrder: BYODOrderDto;
}

export const BYODTrackingLink: FC<BYODTrackingLinkProps> = ({ byodOrder }) => {
    return (
        <ShipmentTrackingLink
            link={byodOrder.trackingLink}
            trackingId={byodOrder.courierReference || 'link'}
        />
    );
};
