import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { inspectionService } from '../service/inspectionsService';
import { inspectionsQueryKey } from './inspectionsQueryKey';

export const useInspectionQuery = (uuid: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [inspectionsQueryKey, uuid],
        queryFn: () => inspectionService.getInspectionDetails(uuid),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
