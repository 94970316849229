import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box } from './Box';

export const Table: FC<{ children?: ReactNode }> = ({ children }) => {
    return (
        <Box overflow='auto'>
            <StyledTable>{children}</StyledTable>
        </Box>
    );
};

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid ${Color.NonContextualBorderDivider};
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.4;
    color: ${Color.ContentPrimary};

    th,
    td {
        padding: 8px 16px;
        border: 1px solid ${Color.NonContextualBorderDivider};
        text-align: left;
    }
    th {
        background-color: ${Color.BackgroundSurfaceNeutral};
        font-weight: bold;
    }
`;
