export const warehouseErrorI18n = {
    'api-error.duplicate-warehouse-name.title': 'Warehouse name already exists',
    'api-error.duplicate-warehouse-name.message': 'Cannot perform this operation',
    'api-error.invalid-archiving-of-warehouse-with-active-items.title':
        'Cannot archive a warehouse with active items',
    'api-error.invalid-archiving-of-warehouse-with-active-items.message': 'Cannot perform this operation',
    'api-error.invalid-organization-for-warehouse-manager.title':
        'Warehouse manager does not belong to organization',
    'api-error.invalid-organization-for-warehouse-manager.message': 'Cannot perform this operation',
    'api-error.invalid-update-of-archived-warehouse.title': 'Cannot update an archived warehouse',
    'api-error.invalid-update-of-archived-warehouse.message': 'Cannot perform this operation',
    'api-error.invalid-update-of-warehouse-country.title': 'Cannot update warehouse country',
    'api-error.invalid-update-of-warehouse-country.message': 'Cannot perform this operation',
    'api-error.warehouse-bin-already-exists.title': 'Warehouse bin already exists',
    'api-error.warehouse-bin-already-exists.message': 'Cannot perform this operation',
};
