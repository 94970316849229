import React, { FC, ReactNode } from 'react';

import { Box } from '../../base';

interface ModalFooterProps {
    children?: ReactNode;
    borderTop?: boolean;
    centered?: boolean;
}

export const ModalFooter: FC<ModalFooterProps> = ({ children, borderTop, centered }) => {
    return (
        <Box
            paddingBottom={24}
            paddingHorizontal={24}
            paddingTop={borderTop ? 24 : 0}
            row
            justify={centered ? 'center' : 'flex-end'}
            gap={16}
            borderTop={borderTop}
            data-test-key='modal-footer'
        >
            {children}
        </Box>
    );
};
