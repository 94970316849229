import jwtDecode from 'jwt-decode';

import { Role } from '@hofy/api-shared';
import { now } from '@hofy/helpers';

export interface TokenPairDto {
    access_token: string;
    refresh_token: string;
    token_type: 'bearer';
}

export interface TokenPair extends TokenPairDto {
    expires_at: number;
    user_id: number;
    org_id: number;
    roles: Role[];
    provider: string;
}

interface JwtPayload {
    email: string;
    user_id?: number;
    org_id?: number;
    exp: number;
    roles?: Role[];
    provider?: string;
    type?: string;
}

export interface SignupTokenPayload extends JwtPayload {
    first_name?: string;
    last_name?: string;
}

const clockSkewInSeconds = 15;

export const isValidToken = (token: string) => {
    return isValidTokenExpires(decodeToken(token).exp);
};

export const isValidTokenExpires = (expires: number) => {
    return expires - clockSkewInSeconds > now().toSeconds();
};

export const decodeToken = <T = JwtPayload>(token: string): T => {
    return jwtDecode<T>(token);
};

export const toTokenPair = (dto: TokenPairDto): TokenPair => {
    const payload = decodeToken(dto.access_token);
    return {
        ...dto,
        expires_at: payload.exp,
        user_id: payload.user_id!,
        org_id: payload.org_id!,
        roles: payload.roles || [],
        provider: payload.provider || 'password',
    };
};
