import React, { FC } from 'react';

import { ShipmentClass } from '@hofy/api-shared';
import { Badge, SvgIcon } from '@hofy/ui';

import { useTrAdminShipmentClass } from '../../../store/shipments/useTrAdminShipmentClass';

interface ShipmentClassChipProps {
    shipmentClass: ShipmentClass;
}

export const ShipmentClassChip: FC<ShipmentClassChipProps> = ({ shipmentClass }) => {
    const trShipmentClass = useTrAdminShipmentClass();
    return <Badge color='grey' icon={classIconMap[shipmentClass]} label={trShipmentClass(shipmentClass)} />;
};

const classIconMap: Record<ShipmentClass, Svg> = {
    [ShipmentClass.Standard]: SvgIcon.Truck,
    [ShipmentClass.Express]: SvgIcon.Truck,
    [ShipmentClass.CourierExpress]: SvgIcon.Zap,
};
