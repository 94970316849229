import React from 'react';

import { allContractTabFilters, ContractTabFilter } from '@hofy/api-admin';
import { Tab, Tabs } from '@hofy/ui';

import { useTrAdminContractFilter } from '../../store/contracts/useTrAdminContractFilter';

interface ContractPageTabsProps {
    tab: ContractTabFilter;
    onChange(tab: ContractTabFilter): void;
}

export const ContractPageTabs = ({ tab, onChange }: ContractPageTabsProps) => {
    const trTab = useTrAdminContractFilter();

    return (
        <Tabs active={tab} onChange={onChange}>
            {allContractTabFilters.map(item => (
                <Tab key={item} id={item} label={trTab(item)} />
            ))}
        </Tabs>
    );
};
