export interface NameAware {
    firstName: string;
    lastName: string;
}

export const formatUserName = <T extends NameAware>(v: T) => {
    return `${v.firstName} ${v.lastName}`;
};

export const formatOptionalUserName = <T extends NameAware>(v?: T | null) => {
    if (!v) {
        return '--';
    }
    return formatUserName(v);
};
