import { partition } from 'lodash';
import React, { FC, MouseEvent, useMemo, useState } from 'react';
import {
    SortableContainerProps as ContainerProps,
    SortableContainer,
    SortableElement,
    SortableElementProps,
    SortEndHandler,
} from 'react-sortable-hoc';
import styled, { createGlobalStyle } from 'styled-components';

import { UploadFileTileButton } from '@hofy/common';
import { uuid } from '@hofy/global';
import { arrayMove, arrayUpdateAt, readFirstFile } from '@hofy/helpers';
import { FallbackCategoryImage } from '@hofy/product';
import { Color, NumberValues } from '@hofy/theme';
import { Box, FramedImage, IconButton, Modals, SvgIcon, useIsDisabled } from '@hofy/ui';

import { ImageCropModal } from './ImageCropModal';
import { ImagePreviewModal } from './ImagePreviewModal';
import { Image } from './types/Image';

interface ImageEditorProps {
    images: Image[];
    showDeleted: boolean;
    rows?: number;
    width?: number;
    spacing?: number;
    onChange(v: Image[]): void;
}

export const ImageEditor: FC<ImageEditorProps> = ({
    images,
    onChange,
    showDeleted,
    rows = 4,
    spacing = 16,
    width = 500,
}) => {
    const disabled = useIsDisabled();
    const [activeImages, inactiveImages] = useMemo(() => {
        if (showDeleted) {
            return [images, []];
        }
        return partition(images, v => v.isActive);
    }, [images, showDeleted]);
    const [previewIndex, setPreviewIndex] = useState(-1);

    const handleChange = (v: Image[]) => {
        onChange([...v, ...inactiveImages]);
    };

    const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
        handleChange(arrayMove(activeImages, oldIndex, newIndex));
    };
    const onButtonClick = (e: MouseEvent, index: number) => {
        e.stopPropagation();
        handleChange(
            arrayUpdateAt(activeImages, index, v => ({
                ...v,
                isActive: !v.isActive,
            })),
        );
    };
    const itemWidth = (width - (rows - 1) * spacing) / rows;
    const [img, setImg] = useState<string>();
    const onSelectFile = (files: FileList) => {
        if (files && files.length > 0) {
            readFirstFile(files, img => setImg(img));
        }
    };
    const onCreate = (url: string) => {
        handleChange([
            ...activeImages,
            {
                url,
                isActive: true,
                id: uuid(),
            },
        ]);
        setImg(undefined);
    };

    return (
        <>
            <SortableList
                helperClass='sortableHelper'
                axis='xy'
                images={activeImages}
                itemWidth={itemWidth}
                onSortEnd={onSortEnd}
                spacing={spacing}
                onButtonClick={onButtonClick}
                onSelectFile={onSelectFile}
                onPreviewOpen={setPreviewIndex}
                distance={1}
                disabled={disabled}
            />
            <Modals>
                {img && <ImageCropModal onClose={() => setImg(undefined)} onCreate={onCreate} img={img} />}
                {previewIndex > -1 && (
                    <ImagePreviewModal
                        images={activeImages}
                        index={previewIndex}
                        setIndex={setPreviewIndex}
                    />
                )}
            </Modals>
        </>
    );
};

interface SortableContainerProps {
    images: Image[];
    itemWidth: number;
    spacing: number;
    disabled?: boolean;
    onButtonClick(e: MouseEvent, index: number): void;
    onPreviewOpen(index: number): void;
    onSelectFile(f: FileList): void;
}

const SortableList: React.ComponentClass<SortableContainerProps & ContainerProps> = SortableContainer(
    ({
        images,
        itemWidth,
        spacing,
        disabled,
        onButtonClick,
        onSelectFile,
        onPreviewOpen,
        ...restProps
    }: SortableContainerProps) => {
        return (
            <Box row wrap overflow='auto' margin={(-spacing / 2) as NumberValues}>
                {images.map((image, index) => (
                    <SortableItem
                        onButtonClick={e => onButtonClick(e, index)}
                        key={`item-${index}`}
                        index={index}
                        itemWidth={itemWidth}
                        spacing={spacing}
                        onClick={() => onPreviewOpen(index)}
                        image={image}
                        deleted={!image.isActive}
                        {...restProps}
                    />
                ))}
                {!disabled && (
                    <UploadFileTileButton
                        margin={(spacing / 2) as NumberValues}
                        width={itemWidth}
                        onChange={onSelectFile}
                    />
                )}
                <Global />
            </Box>
        );
    },
);

interface SortableItemProps {
    image: Image;
    deleted: boolean;
    itemWidth: number;
    spacing: number;
    onButtonClick(e: MouseEvent): void;
    onClick(): void;
}

const SortableItem: React.ComponentClass<SortableItemProps & SortableElementProps> = SortableElement(
    ({ image, spacing, itemWidth, onButtonClick, onClick, deleted }: SortableItemProps) => (
        <SortableItemWrapper
            relative
            margin={(spacing / 2) as NumberValues}
            onClick={onClick}
            deleted={deleted}
        >
            <FramedImage size={itemWidth} src={image.url} fallback={<FallbackCategoryImage />} />
            <RemoveButton
                color={Color.ContentSecondary}
                icon={deleted ? SvgIcon.Add : SvgIcon.Cross}
                onClick={onButtonClick}
            />
        </SortableItemWrapper>
    ),
);

const RemoveButton = styled(IconButton)`
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
    border-radius: 50%;
    background-color: ${Color.BackgroundDefault};
    border: 1px solid ${Color.Neutral200};
    padding: 2px;
`;
const SortableItemWrapper = styled(Box)<{
    deleted: boolean;
}>`
    position: relative;

    ${RemoveButton} {
        display: none;
    }

    :hover ${RemoveButton} {
        display: block;
    }

    ::after {
        border-radius: 8px;
        border: 1px solid ${p => (p.deleted ? Color.FoundationNegative : Color.Neutral200)};
        position: absolute;
        z-index: 1;
        display: ${p => (p.deleted ? 'block' : 'none')};
        content: '';
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: ${Color.Shadow};
    }
`;

const Global = createGlobalStyle`
  .sortableHelper{
    z-index: 1000;
  }
`;
