export const asNullable = <T extends string>(value: T | null): T | null => {
    if (value === null) {
        return null;
    }
    if (!value.trim()) {
        return null;
    }
    return value;
};

export const isExternalLink = (to: string): boolean =>
    to.startsWith('https://') || to.startsWith('http://') || to.startsWith('mailto:');
