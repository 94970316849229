import React, { FC } from 'react';

import { Box } from '@hofy/ui';

import { PurchaseOrderFormTab } from '../../../../../store/purchaseOrders/PurchaseOrderFormTab';
import { UsePurchaseOrderForm } from '../../../../../store/purchaseOrders/useCreateMultipartPurchaseOrderForm';
import { defaultPurchaseOrderCurrency } from '../../../../../store/purchaseOrders/usePurchaseOrderHeaderForm';
import { PurchaseOrderHeaderForm } from './PurchaseOrderHeaderForm';
import { PurchaseOrderItemsForm } from './PurchaseOrderItemsForm';
import { PurchaseOrderReviewForm } from './PurchaseOrderReviewForm';

interface PurchaseOrderFormProps {
    form: UsePurchaseOrderForm;
    tab: PurchaseOrderFormTab;
}

export const PurchaseOrderForm: FC<PurchaseOrderFormProps> = ({ form: formWrapper, tab }) => {
    const { form } = formWrapper;

    const content = () => {
        switch (tab) {
            case PurchaseOrderFormTab.Header:
                return <PurchaseOrderHeaderForm headerForm={form.forms.header} />;
            case PurchaseOrderFormTab.Items:
                return (
                    <PurchaseOrderItemsForm
                        itemsForm={form.forms.items}
                        currency={form.forms.header.values.currency || defaultPurchaseOrderCurrency}
                        supplierId={form.forms.header.values.supplierId}
                        isDropship={form.forms.header.values.isDropship}
                    />
                );
            case PurchaseOrderFormTab.Review:
                return (
                    <PurchaseOrderReviewForm headerForm={form.forms.header} itemsForm={form.forms.items} />
                );
        }
    };

    return (
        <Box column marginBottom={24}>
            {content()}
        </Box>
    );
};
