import React, { FC } from 'react';

import { StoreAndReuseStatus, useTrStoreAndReuseStatus } from '@hofy/api-shared';
import { ColorDye, DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Badge, Box, Paragraph3, Span } from '@hofy/ui';

const storeAndReuseStatusColor: Record<StoreAndReuseStatus, ColorDye> = {
    [StoreAndReuseStatus.Enabled]: 'green',
    [StoreAndReuseStatus.Ready]: 'orange',
    [StoreAndReuseStatus.Reused]: 'blue',
};

interface StoreAndReuseStatusChipProps {
    status: StoreAndReuseStatus | null;
    rolloverOn?: DateString | null;
}

export const StoreAndReuseStatusChip: FC<StoreAndReuseStatusChipProps> = ({ status, rolloverOn }) => {
    const trStatus = useTrStoreAndReuseStatus();

    if (status === null) {
        return <Badge color='grey' label='Disabled' />;
    }

    const color = storeAndReuseStatusColor[status];
    return (
        <Box gap={4} column flex='auto' alignItems='flex-start'>
            <Badge color={color} label={trStatus(status)} />
            {rolloverOn && (
                <Paragraph3 color={Color.Neutral700}>
                    At: <Span bold>{formatDate(rolloverOn)}</Span>
                </Paragraph3>
            )}
        </Box>
    );
};
