import React, { FC, ReactNode } from 'react';

import { AssignmentProductDetailsDto } from '@hofy/api-admin';
import { useTrOperatingSystem, useTrProductCategory } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, FormGridRow, LabeledText, Paragraph3, SectionTitle2, Span } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { LabeledProductLink } from '../../productsPage/ProductDetailsLink';

interface ProductDetailsProps {
    product: AssignmentProductDetailsDto;
    children?: ReactNode;
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product, children }) => {
    const trProductCategory = useTrProductCategory();
    const trOperatingSystem = useTrOperatingSystem();

    return (
        <Box>
            <Box row>
                <ProductItem
                    row
                    image={product.image?.url}
                    imageSize={100}
                    flex={1}
                    label={
                        <SectionTitle2 marginBottom={5} color={Color.ContentPrimary}>
                            {product.name}
                        </SectionTitle2>
                    }
                    subLabel={
                        <>
                            <Paragraph3 color={Color.ContentPrimary} marginBottom={5}>
                                <Span color={Color.ContentTertiary}>By</Span> {product.brand}
                            </Paragraph3>
                            <Paragraph3 color={Color.ContentTertiary}>
                                {trProductCategory(product.category)}
                            </Paragraph3>
                        </>
                    }
                />
                {children}
            </Box>
            <FormGridRow columns={4} marginTop={20}>
                <LabeledText label='Style' content={product.style} />
                <LabeledText label='Size' content={product.size} />
                {product.os && <LabeledText label='OS' content={trOperatingSystem(product.os)} />}
            </FormGridRow>
            <FormGridRow columns={4} marginTop={20}>
                <LabeledText label='SKU' content={product.sku} />
                <LabeledText label='MPN' content={product.manufacturerPartCode} />
                <LabeledProductLink product={product} />
            </FormGridRow>
        </Box>
    );
};
