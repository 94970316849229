import { getEnumValues } from '@hofy/global';

export enum ShipmentSlideoutTab {
    Details = 'details',
    Assignments = 'assignments',
    Notes = 'notes',
    CollectionSurvey = 'collection-survey',
    Tracking = 'tracking',
}

export const allShipmentSlideoutTabs = getEnumValues<ShipmentSlideoutTab>(ShipmentSlideoutTab);
