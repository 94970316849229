import { AnyEnum, getEnumValues } from '@hofy/global';
import { queryStringParser } from '@hofy/router';
import { FilterDefinitionSingle } from '@hofy/ui';

export interface EnumFilterDefinitionOptions<T, E extends AnyEnum> {
    statusEnum: E;
    name?: string;
    showInActive?: boolean;

    toLabel?(value?: T): string;
}

export const useSingleEnumFilterDefinition = <T extends string, E extends AnyEnum>({
    statusEnum,
    name,
    showInActive,
    toLabel,
}: EnumFilterDefinitionOptions<T, E>): FilterDefinitionSingle<T> => {
    return {
        type: 'single',
        name: name ?? 'Status',
        query: queryStringParser<T>(),
        allValues: getEnumValues<T>(statusEnum),
        toLabel,
        showInActive,
    };
};
