import { Country } from '@hofy/global';

import { useSharedApiI18n } from '../i18n/useSharedApiI18n';

export const useTrTaxIdLabel = () => {
    const { existsParts, trParts } = useSharedApiI18n();
    return (country: Country) => {
        if (existsParts('tax-id-label', country)) {
            return trParts('tax-id-label', country);
        } else {
            return trParts('tax-id-label', 'none');
        }
    };
};
