import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { FontProps, Paragraph3 } from '../../base';

export const TextCell: FC<FontProps & { style?: CSSProperties }> = props => {
    return (
        <CellInnerFont
            ellipsis
            wordBreak
            overflow='hidden'
            paddingRight={12}
            data-test-key='table-cell'
            {...props}
        />
    );
};

/** @deprecated We also have `HeaderCell` for header, consider using that instead */
export const HeaderTextCell: FC<FontProps> = ({ children, ...rest }) => {
    return (
        <TextCell color={Color.ContentSecondary} bold {...rest}>
            {children}
        </TextCell>
    );
};

const CellInnerFont = styled(Paragraph3)`
    &:last-child {
        padding-right: 0;
    }
`;
