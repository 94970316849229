import React, { FC } from 'react';

import { ManagementContractUnionDetailsDto } from '@hofy/api-admin';
import { StorageAssignmentStatus } from '@hofy/api-shared';
import { Labeled } from '@hofy/ui';

import { StorageAssignmentStatusChip } from '../../../components/domain/contracts/StorageAssignmentStatusChip';

export const ManagementContractStatusLabel: FC<{ managementContract: ManagementContractUnionDetailsDto }> = ({
    managementContract,
}) => {
    const latestStatus = (() =>
        managementContract.storageAssignments.length === 0
            ? StorageAssignmentStatus.CollectionPending
            : managementContract.storageAssignments[0].status)();
    return <Labeled label='Status' content={<StorageAssignmentStatusChip status={latestStatus} />} />;
};
