import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

import { InspectionTab } from '../../../store/inspections/InspectionTab';

interface InspectionSlideoutContext {
    tab: InspectionTab;
    base: string;
    itemsBase: string;
}

const InspectionSlideoutCtx = createContext<InspectionSlideoutContext | null>(null);

export const InspectionSlideoutContextProvider: FC<PropsWithChildren<InspectionSlideoutContext>> = ({
    tab,
    base,
    itemsBase,
    children,
}) => {
    const value = useMemo(() => ({ tab, base, itemsBase }), [tab, base, itemsBase]);

    return <InspectionSlideoutCtx.Provider value={value}>{children}</InspectionSlideoutCtx.Provider>;
};

const useInspectionSlideoutContext = (): InspectionSlideoutContext => {
    const ctx = useContext(InspectionSlideoutCtx);

    if (ctx === null) {
        throw new Error(
            'useInspectionSlideoutContext should be called within InspectionSlideoutContextProvider',
        );
    }

    return ctx;
};

export const useInspectionSlideoutLinks = () => {
    const { location } = useHistory();

    const { itemsBase } = useInspectionSlideoutContext();

    const link = useCallback((path: string) => `${path}${location.search}`, [location.search]);
    const getItemLink = useCallback((id: UUID) => link(`${itemsBase}/${id}`), [link, itemsBase]);

    return {
        getItemLink,
    };
};
