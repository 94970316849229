import { NoteDto, NoteSource } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { NotePayload } from './types/NotePayload';

class NotesService {
    public getNotes = async (source: NoteSource, entityUUID: UUID): Promise<NoteDto[]> => {
        return restClient.getJson<NoteDto[]>(`/api/admin/notes/${source}/${entityUUID}`);
    };

    public createNote = async (payload: NotePayload): Promise<void> => {
        return restClient.post(`/api/admin/notes`, payload);
    };

    public deleteNote = async (noteUUID: UUID): Promise<void> => {
        return restClient.delete(`/api/admin/notes/${noteUUID}`);
    };
}

export const notesService = new NotesService();
