import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';

import { Link } from '../../../components/routing/Link';
import { RepaymentPlanDetailsTab } from '../../../store/repaymentPlan/types/RepaymentPlanDetailsTab';

interface RepaymentPlanLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const RepaymentPlanLink: FC<RepaymentPlanLinkProps> = ({ id, children }) => {
    return (
        <Link underline={false} to={`/invoicing/repayment-plans/${id}/${RepaymentPlanDetailsTab.Details}`}>
            {children}
        </Link>
    );
};
