import React, { FC } from 'react';

import { BoxProps } from '@hofy/ui';

import { SlideoutContentContainer } from './SlideoutContentContainer';

export const SlideoutFooter: FC<BoxProps> = ({ children, ...boxProps }) => {
    return (
        <SlideoutContentContainer row shrink={0} height={80} justify='space-between' {...boxProps}>
            {children}
        </SlideoutContentContainer>
    );
};
