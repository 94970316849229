import React, { CSSProperties, FC, SVGAttributes } from 'react';

export interface SvgImageProps {
    svg: Svg;
    style?: CSSProperties;
    attributes?: SVGAttributes<HTMLDivElement>;
    className?: string;
}

export const SvgImage: FC<SvgImageProps> = ({ svg: SvgItem, attributes = {}, className, style }) => {
    if (typeof SvgItem !== 'function') {
        throw new Error('[SvgImage] Svg prop must be a React component');
    }
    return <SvgItem className={className} style={style} {...attributes} />;
};
