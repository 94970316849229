import React, { FC, useMemo } from 'react';

import { BYODOrderItemDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, Icon, InnerCard, Paragraph2, Paragraph3, SvgIcon } from '@hofy/ui';

import { BYODOrderProductInfo } from './BYODOrderProductBox';

interface BYODOrderItemBoxProps {
    item: BYODOrderItemDto;
    setByodOrderItem(byodOrderItem: BYODOrderItemDto): void;
}

export const BYODOrderItemBox: FC<BYODOrderItemBoxProps> = ({ item, setByodOrderItem }) => {
    const isLineAllowedToReceive = useMemo(() => {
        return item && item.receivedQuantity !== item.expectedQuantity;
    }, [item]);

    return (
        <InnerCard pointer={isLineAllowedToReceive}>
            <Box
                fullWidth
                row
                justify='space-between'
                onClick={isLineAllowedToReceive ? () => setByodOrderItem(item) : () => {}}
            >
                <Box row gap={12}>
                    <BYODOrderProductInfo item={item} />
                </Box>
                <Box row gap={24}>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Expected quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>{item.expectedQuantity}</Paragraph2>
                    </Box>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Received quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>
                            {!isLineAllowedToReceive ? (
                                <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />
                            ) : (
                                item.receivedQuantity || '--'
                            )}
                        </Paragraph2>
                    </Box>
                    <Icon svg={SvgIcon.ChevronRight} />
                </Box>
            </Box>
        </InnerCard>
    );
};
