export const currencyOptionI18n = {
    'currency-option.eur': 'EUR',
    'currency-option.aed': 'AED',
    'currency-option.afn': 'AFN',
    'currency-option.xcd': 'XCD',
    'currency-option.all': 'ALL',
    'currency-option.amd': 'AMD',
    'currency-option.aoa': 'AOA',
    'currency-option.ars': 'ARS',
    'currency-option.usd': 'USD',
    'currency-option.aud': 'AUD',
    'currency-option.awg': 'AWG',
    'currency-option.azn': 'AZN',
    'currency-option.bam': 'BAM',
    'currency-option.bdt': 'BDT',
    'currency-option.xof': 'XOF',
    'currency-option.bgn': 'BGN',
    'currency-option.bhd': 'BHD',
    'currency-option.bif': 'BIF',
    'currency-option.bmd': 'BMD',
    'currency-option.sgd': 'SGD',
    'currency-option.bob': 'BOB',
    'currency-option.brl': 'BRL',
    'currency-option.bsd': 'BSD',
    'currency-option.btn': 'BTN',
    'currency-option.bwp': 'BWP',
    'currency-option.byn': 'BYN',
    'currency-option.bzd': 'BZD',
    'currency-option.cad': 'CAD',
    'currency-option.cdf': 'CDF',
    'currency-option.xaf': 'XAF',
    'currency-option.chf': 'CHF',
    'currency-option.nzd': 'NZD',
    'currency-option.clp': 'CLP',
    'currency-option.cny': 'CNY',
    'currency-option.cop': 'COP',
    'currency-option.crc': 'CRC',
    'currency-option.cuc': 'CUC',
    'currency-option.cve': 'CVE',
    'currency-option.ang': 'ANG',
    'currency-option.czk': 'CZK',
    'currency-option.djf': 'DJF',
    'currency-option.dop': 'DOP',
    'currency-option.dzd': 'DZD',
    'currency-option.egp': 'EGP',
    'currency-option.mad': 'MAD',
    'currency-option.ern': 'ERN',
    'currency-option.etb': 'ETB',
    'currency-option.fjd': 'FJD',
    'currency-option.fkp': 'FKP',
    'currency-option.dkk': 'DKK',
    'currency-option.gbp': 'GBP',
    'currency-option.gel': 'GEL',
    'currency-option.ghs': 'GHS',
    'currency-option.gip': 'GIP',
    'currency-option.gmd': 'GMD',
    'currency-option.gnf': 'GNF',
    'currency-option.gtq': 'GTQ',
    'currency-option.gyd': 'GYD',
    'currency-option.hkd': 'HKD',
    'currency-option.hnl': 'HNL',
    'currency-option.hrk': 'HRK',
    'currency-option.htg': 'HTG',
    'currency-option.huf': 'HUF',
    'currency-option.idr': 'IDR',
    'currency-option.ils': 'ILS',
    'currency-option.inr': 'INR',
    'currency-option.iqd': 'IQD',
    'currency-option.irr': 'IRR',
    'currency-option.isk': 'ISK',
    'currency-option.jmd': 'JMD',
    'currency-option.jod': 'JOD',
    'currency-option.jpy': 'JPY',
    'currency-option.kes': 'KES',
    'currency-option.kgs': 'KGS',
    'currency-option.khr': 'KHR',
    'currency-option.kmf': 'KMF',
    'currency-option.kpw': 'KPW',
    'currency-option.krw': 'KRW',
    'currency-option.wst': 'WST',
    'currency-option.kwd': 'KWD',
    'currency-option.kyd': 'KYD',
    'currency-option.kzt': 'KZT',
    'currency-option.lak': 'LAK',
    'currency-option.lbp': 'LBP',
    'currency-option.lkr': 'LKR',
    'currency-option.lrd': 'LRD',
    'currency-option.lsl': 'LSL',
    'currency-option.ltl': 'LTL',
    'currency-option.lyd': 'LYD',
    'currency-option.mdl': 'MDL',
    'currency-option.mga': 'MGA',
    'currency-option.mkd': 'MKD',
    'currency-option.mmk': 'MMK',
    'currency-option.mnt': 'MNT',
    'currency-option.mru': 'MRU',
    'currency-option.mur': 'MUR',
    'currency-option.mvr': 'MVR',
    'currency-option.mwk': 'MWK',
    'currency-option.mxn': 'MXN',
    'currency-option.myr': 'MYR',
    'currency-option.mzn': 'MZN',
    'currency-option.nad': 'NAD',
    'currency-option.xpf': 'XPF',
    'currency-option.ngn': 'NGN',
    'currency-option.nio': 'NIO',
    'currency-option.nok': 'NOK',
    'currency-option.npr': 'NPR',
    'currency-option.omr': 'OMR',
    'currency-option.pab': 'PAB',
    'currency-option.pen': 'PEN',
    'currency-option.pgk': 'PGK',
    'currency-option.php': 'PHP',
    'currency-option.pkr': 'PKR',
    'currency-option.pln': 'PLN',
    'currency-option.pyg': 'PYG',
    'currency-option.qar': 'QAR',
    'currency-option.ron': 'RON',
    'currency-option.rsd': 'RSD',
    'currency-option.rub': 'RUB',
    'currency-option.rwf': 'RWF',
    'currency-option.sar': 'SAR',
    'currency-option.sbd': 'SBD',
    'currency-option.scr': 'SCR',
    'currency-option.sdg': 'SDG',
    'currency-option.sek': 'SEK',
    'currency-option.shp': 'SHP',
    'currency-option.sll': 'SLL',
    'currency-option.sos': 'SOS',
    'currency-option.srd': 'SRD',
    'currency-option.ssp': 'SSP',
    'currency-option.std': 'STD',
    'currency-option.syp': 'SYP',
    'currency-option.szl': 'SZL',
    'currency-option.thb': 'THB',
    'currency-option.tjs': 'TJS',
    'currency-option.tmt': 'TMT',
    'currency-option.tnd': 'TND',
    'currency-option.top': 'TOP',
    'currency-option.try': 'TRY',
    'currency-option.ttd': 'TTD',
    'currency-option.twd': 'TWD',
    'currency-option.tzs': 'TZS',
    'currency-option.uah': 'UAH',
    'currency-option.ugx': 'UGX',
    'currency-option.uyu': 'UYU',
    'currency-option.uzs': 'UZS',
    'currency-option.vef': 'VEF',
    'currency-option.vnd': 'VND',
    'currency-option.vuv': 'VUV',
    'currency-option.yer': 'YER',
    'currency-option.zar': 'ZAR',
    'currency-option.zmw': 'ZMW',
    'currency-option.zwd': 'ZWD',
};
