import { useQuery } from '@tanstack/react-query';

import { AdminRefDto, Role } from '@hofy/api-shared';

import { adminService } from '../../admin/service/adminService';
import { adminsCacheKey } from '../../admin/store/cacheKey';

const emptyAdmins: AdminRefDto[] = [];

export const useAdminsRefsQuery = (role = Role.FulfillmentAdmin, getOffBoardedUsers = false) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [adminsCacheKey, 'refs', role, getOffBoardedUsers],

        queryFn: () => adminService.getAdminRefs(role, getOffBoardedUsers),
    });

    return {
        isLoading,
        isError,
        data: data || emptyAdmins,
    };
};
