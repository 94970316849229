export const auditTableI18n = {
    'audit-table.organization': 'organization',
    'audit-table.item': 'item',
    'audit-table.user': 'user',
    'audit-table.product': 'product',
    'audit-table.variant': 'variant',
    'audit-table.item-assignment': 'item assignment',
    'audit-table.shipment': 'shipment',
    'audit-table.contract': 'contract',
};
