import React, { FC } from 'react';

import { DataErasureDetailsDto } from '@hofy/api-admin';
import { Box, FormSection, Paragraph3 } from '@hofy/ui';

interface DataErasureSlideoutCancellationProps {
    dataErasure: DataErasureDetailsDto;
}

export const DataErasureSlideoutCancellation: FC<DataErasureSlideoutCancellationProps> = ({
    dataErasure,
}) => {
    return (
        <Box>
            <FormSection paddingBottom={20} label='Cancellation comment'>
                <Paragraph3>{dataErasure.cancellation?.comment}</Paragraph3>
            </FormSection>
        </Box>
    );
};
