import React, { FC } from 'react';

import { SubRegion } from '@hofy/global';
import { Flag, FlagImages, MarginBoxProps } from '@hofy/ui';

interface RegionFlagProps extends MarginBoxProps {
    region?: SubRegion;
    size?: number;
}

export const RegionFlag: FC<RegionFlagProps> = ({ region, size, ...boxProps }) => {
    const src = flagByRegion(region);
    return <Flag size={size} src={src} {...boxProps} />;
};

const flagByRegion = (region?: SubRegion): string => {
    switch (region) {
        case SubRegion.Eu:
            return FlagImages.EU;
        case SubRegion.UnitedKingdom:
            return FlagImages.GB;
        default:
            return FlagImages.None;
    }
};
