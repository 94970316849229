import { getEnumValues } from '@hofy/global';

// keep in mind this order is relevant for the UI:
// tabs will be displayed in the order defined here
export enum AssignmentsPageTab {
    Delivery = 'delivery',
    Collection = 'collection',
    ShipToday = 'ship_today',
    AtRisk = 'at_risk',
    Escalated = 'escalated',
    Deferred = 'deferred',
    All = 'all',
}

export const allAssignmentPageTabs = getEnumValues<AssignmentsPageTab>(AssignmentsPageTab);
