import React, { FC } from 'react';

import {
    getAllowedRentalTermsForPaymentSchema,
    getAvailableRentalTerms,
    PaymentSchema,
    RentalTerm,
} from '@hofy/api-shared';

import { useCoreI18n } from '../../../i18n/useCoreI18n';
import { PaymentSchemaFormDropdown } from '../organizatition/PaymentSchemaFormDropdown';
import { RentalTermFormDropdown } from '../organizatition/RentalTermFormDropdown';

interface ContractSettingsDropdownProps {
    rentalTerm: RentalTerm;
    paymentSchema: PaymentSchema;
    onChange(rentalTerm: RentalTerm, paymentSchema: PaymentSchema): void;
    allowedBillingFrequencies: PaymentSchema[];
    allowedRentalTerms: RentalTerm[];
    disabled?: boolean;
    unbundlingEnabled: boolean;
    addonsEnabled?: boolean;
}

export const ContractSettingsDropdown: FC<ContractSettingsDropdownProps> = ({
    rentalTerm,
    paymentSchema,
    onChange,
    allowedBillingFrequencies,
    allowedRentalTerms,
    disabled = false,
    addonsEnabled = false,
    unbundlingEnabled,
}) => {
    const { tr } = useCoreI18n();

    const availableRentalTerms = getAvailableRentalTerms(unbundlingEnabled);

    const allowedRentalTermsForPaymentSchema = getAllowedRentalTermsForPaymentSchema(
        unbundlingEnabled,
        paymentSchema,
        allowedRentalTerms,
    );

    const handleChange = (rentalTerm: RentalTerm, paymentSchema: PaymentSchema) => {
        const forceUpfront =
            (rentalTerm === RentalTerm.Rental6Months || rentalTerm === RentalTerm.Rental12Months) &&
            paymentSchema === PaymentSchema.Annual;

        return onChange(rentalTerm, forceUpfront ? PaymentSchema.Upfront : paymentSchema);
    };

    return (
        <>
            <RentalTermFormDropdown
                testKey='edit-request-contract-option-modal-dropdown'
                onChange={rentalTerm => handleChange(rentalTerm, paymentSchema)}
                value={rentalTerm}
                label={tr('contract-settings.dropdown.rental-duration')}
                disabled={disabled}
                allowedRentalTerms={allowedRentalTermsForPaymentSchema}
                availableRentalTerms={availableRentalTerms}
                width='100%'
            />
            <PaymentSchemaFormDropdown
                value={paymentSchema}
                onChange={paymentSchema => handleChange(rentalTerm, paymentSchema)}
                rentalTerm={rentalTerm}
                allowedBillingFrequencies={allowedBillingFrequencies}
                disabled={disabled}
                addonsEnabled={addonsEnabled}
                width='100%'
            />
        </>
    );
};
