import React, { FC } from 'react';

import { InvoiceEntryDto, useContractInvoiceEntriesQuery } from '@hofy/api-admin';
import { InvoiceEntryType, Permission } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';

import { InvoiceEntriesTable } from '../../invoicingPage/invoiceEntries/components/InvoiceEntriesTable';

interface RentalAgreementScheduleTabProps {
    contractId: UUID;
    includeDeleted: boolean;
    onRowClick?(entry: InvoiceEntryDto): void;
}

export const ContractInvoiceEntriesTab: FC<RentalAgreementScheduleTabProps> = ({
    contractId,
    includeDeleted,
    onRowClick,
}) => {
    const { data, isLoading } = useContractInvoiceEntriesQuery(contractId, includeDeleted);
    const { hasPermission } = usePermission();
    const hasPermissionsToEditEntry = (e: InvoiceEntryDto) => {
        if (hasPermission(Permission.AdminInvoicesEntriesUpdate)) {
            return true;
        }
        return (
            hasPermission(Permission.AdminInvoicesEntriesUpdateEnded) &&
            (e.type === InvoiceEntryType.EndOfContractPurchase || e.type === InvoiceEntryType.Cancellation)
        );
    };
    return (
        <InvoiceEntriesTable
            entries={data}
            onRowClick={e => (hasPermissionsToEditEntry(e) && onRowClick ? onRowClick(e) : undefined)}
            infinityScroll={{
                hasMore: false,
                isLoading: isLoading,
                isLoadingMore: false,
                loadMore: () => {},
            }}
        />
    );
};
