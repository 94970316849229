import React from 'react';

import { Country } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks/form/formTypes';
import { Optional } from '../../../../types/Utils';
import {
    LabeledCountrySelect,
    LabeledCountrySelectNotNullableProps,
    LabeledCountrySelectNullableProps,
    LabeledCountrySelectProps,
} from './LabeledCountrySelect';

type OmitAndOptional<T extends LabeledCountrySelectProps> = Omit<
    Optional<T, 'onChange'>,
    'errorMessage' | 'value'
>;

interface FormNormalCountrySelectProps extends OmitAndOptional<LabeledCountrySelectNotNullableProps> {
    api: FormFieldApi<Country>;
    nullable?: false;
}

interface FormNullableCountrySelectProps extends OmitAndOptional<LabeledCountrySelectNullableProps> {
    api: FormFieldApi<Country | null>;
    nullable: true;
}

type FormCountrySelectProps = FormNormalCountrySelectProps | FormNullableCountrySelectProps;

export const FormCountrySelect = ({
    api,
    onChange,
    onSearchChange,
    isLoadingSearch,
    loadingSearchPlaceHolder,
    onBlur,
    ...rest
}: FormCountrySelectProps) => {
    return (
        <LabeledCountrySelect
            {...rest}
            value={api.value!}
            onChange={(value: Country) => {
                api.setValue(value);
                onChange?.(value);
            }}
            onSearchChange={onSearchChange}
            isLoadingSearch={isLoadingSearch}
            loadingSearchPlaceHolder={loadingSearchPlaceHolder}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
