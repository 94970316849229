import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { itemEventService } from '../service/itemEventService';
import { AdminItemEventsFilters } from '../service/types/AdminItemEventsFilters';
import { ItemEventDto } from '../service/types/ItemEventDto';
import { itemEventsCacheKey } from './cacheKey';

export const useItemEvents = (filters: AdminItemEventsFilters, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [itemEventsCacheKey, filters],
        queryFn: param =>
            itemEventService.listItemEvents(filters, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const itemEvents: ItemEventDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        itemEvents,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
