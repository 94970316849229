import React, { ElementRef, forwardRef, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box } from '../../base';

interface ModalContainerProps {
    children?: ReactNode;
    width?: number | string;
    maxWidth?: number | string;
    floatingProps?: any;
}

export const ModalContainer = forwardRef<ElementRef<'div'>, ModalContainerProps>(
    ({ children, width, maxWidth, floatingProps }, ref) => {
        return (
            <Box
                ref={ref}
                rounded={12}
                bg={Color.BackgroundDefault}
                elevation='+4'
                flex='auto'
                column
                width={width}
                maxWidth={maxWidth}
                {...floatingProps}
                data-test-key='modal-container'
            >
                {children}
            </Box>
        );
    },
);
