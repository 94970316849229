import React, { FC } from 'react';

import { BillingEntityStatus, billingEntityStatusColor, useTrBillingEntityStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface BillingEntityStatusChipProps {
    status: BillingEntityStatus;
}

export const BillingEntityStatusChip: FC<BillingEntityStatusChipProps> = ({ status }) => {
    const tr = useTrBillingEntityStatus();
    return <Badge label={tr(status)} color={billingEntityStatusColor[status]} />;
};
