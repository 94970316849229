import React, { FC } from 'react';

import { SupplierContactRole } from '@hofy/api-admin';
import { Phone } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Box,
    FormGridItem,
    FormGridRow,
    IconButton,
    InnerCard,
    Labeled,
    LabeledText,
    Paragraph3,
    SectionTitle2,
    SvgIcon,
    Tooltip,
} from '@hofy/ui';

import { useTrSupplierContactRole } from '../../../../store/suppliers/useTrSupplierContactRole';

interface SupplierContactCardProps {
    contact: SupplierContact;
    onEdit(): void;
    onDelete(): void;
    summarize?: boolean;
}

export interface SupplierContact {
    role: SupplierContactRole;
    name: string;
    lastname: string;
    email: string | null;
    phone: Phone | null;
}

export const SupplierContactCard: FC<SupplierContactCardProps> = ({
    contact,
    onEdit,
    onDelete,
    summarize,
}) => {
    const trRole = useTrSupplierContactRole();

    const getFullName = (contact: SupplierContact): string => {
        if (contact.lastname) {
            return `${contact.name} ${contact.lastname}`;
        }

        return contact.name ?? '';
    };

    const renderLongText = (content: string | null) => (
        <Paragraph3 textClomp={1}>{content ?? '--'}</Paragraph3>
    );

    const renderWithTooltip = (content: string | null) => {
        if (content) {
            return <Tooltip body={content}>{renderLongText(content)}</Tooltip>;
        }

        return renderLongText(null);
    };

    return (
        <InnerCard>
            <Box row>
                <Box flex={1} justify='flex-start'>
                    <SectionTitle2>{getFullName(contact)}</SectionTitle2>
                    {!!summarize && (
                        <Paragraph3 color={Color.ContentTertiary}>{trRole(contact.role)}</Paragraph3>
                    )}
                </Box>
                <Box justify='flex-end'>
                    <Box row>
                        <IconButton icon={SvgIcon.Edit} onClick={onEdit} marginLeft={10} />
                        <IconButton icon={SvgIcon.Trash} onClick={onDelete} marginLeft={10} />
                    </Box>
                </Box>
            </Box>
            {!summarize && (
                <FormGridRow columns={2} marginTop={30}>
                    <FormGridItem columns={2}>
                        <LabeledText label='Role' content={trRole(contact.role)} />
                    </FormGridItem>
                    <Labeled label='Phone' content={renderLongText(contact.phone)} />
                    <Labeled label='Email' content={renderWithTooltip(contact.email)} />
                </FormGridRow>
            )}
        </InnerCard>
    );
};
