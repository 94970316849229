import { sharedApiI18n } from '@hofy/api-shared';
import { baseI18n } from '@hofy/i18n';

import { contractSettingsI18n } from './contractSettingsI18n';

/** @deprecated */
export const coreI18n = {
    ...baseI18n,
    ...sharedApiI18n,
    ...contractSettingsI18n,
};

export type CoreI18nKey = keyof typeof coreI18n;
