import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { ReactNodeFunction, renderChildren } from '../../types/React';
import { Box, Paragraph2 } from '../base';
import { PopoverOptions, usePopover } from './hooks/usePopover';
import { PopoverContent } from './PopoverContent';
import { PopoverTrigger } from './PopoverTrigger';

type ClosePopover = () => void;

export interface PopoverProps extends PopoverOptions {
    trigger: ReactNode | ReactNodeFunction<[boolean]>;
    children: ReactNode | ReactNodeFunction<[ClosePopover]>;
    width: number;
    maxHeight?: number;
    /**
     * Allows pass any element as the anchor without the wrapper
     * WARNING: element must accept `ref` (native element like `div` or component with `forwardRef`)
     * */
    asChild?: boolean;
}

export const Popover = ({ trigger, children, asChild, width, maxHeight, placement }: PopoverProps) => {
    const state = usePopover({ placement });
    const closePopover: ClosePopover = () => state.setOpen(false);

    return (
        <>
            <PopoverTrigger state={state} asChild={asChild}>
                {renderChildren(trigger, state.open)}
            </PopoverTrigger>
            <AnimatePresence>
                {state.open && (
                    <FloatingPortal>
                        <PopoverContent
                            state={state}
                            style={state.data.floatingStyles}
                            floatingProps={state.interactions.getFloatingProps()}
                            placement={state.data.placement}
                        >
                            <Box
                                maxHeight={maxHeight}
                                width={width}
                                maxWidth='100%'
                                column
                                bg={Color.InteractionBackgroundModal}
                                rounded={16}
                                elevation='center'
                            >
                                {renderChildren(children, closePopover)}
                            </Box>
                        </PopoverContent>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};

interface PopoverHeaderProps {
    title: string;
    children?: ReactNode;
}

export const PopoverHeader: FC<PopoverHeaderProps> = ({ title, children }) => (
    <Box padding={24} row gap={24} justify='space-between' borderBottom>
        <Paragraph2 bold>{title}</Paragraph2>
        {children}
    </Box>
);

interface PopoverBodyProps {
    children: ReactNode;
    testKey?: string;
}

export const PopoverBody: FC<PopoverBodyProps> = ({ children, testKey }) => (
    <Box data-test-key={testKey} overflow='auto' flex='auto' padding={24}>
        {children}
    </Box>
);

interface PopoverFooterProps {
    children: ReactNode;
}

export const PopoverFooter: FC<PopoverFooterProps> = ({ children }) => (
    <Box padding={24} borderTop>
        {children}
    </Box>
);
