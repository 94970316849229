export enum HelpCenterLink {
    CancelTeamMemberOrder = 'https://help.hofy.com/hc/en-us/articles/28142810503825-Collection-requests-as-Hofy-managers',
    PurchaseTeamMemberOrder = 'https://help.hofy.com/hc/en-us/articles/28142778490641-Purchasing-equipment-from-Hofy',
    ManagerPermissions = 'https://help.hofy.com/hc/en-us/articles/28142810910353-Hofy-Manager-permission-types',
    CountryMultipliers = 'https://help.hofy.com/hc/en-us/articles/28142762586513-What-do-country-multipliers-mean',
    HowInvoicingWorks = 'https://help.hofy.com/hc/en-us/articles/28142825898769-How-Hofy-s-invoicing-works',
    HowPaymentWorks = 'https://help.hofy.com/hc/en-us/articles/28142756409361-How-to-pay-your-Hofy-invoices',
    OrganisationCreditLimit = 'https://help.hofy.com/hc/en-us/articles/28142825342737-Your-organisation-s-credit-limit',
    SecureLogIn = 'https://help.hofy.com/hc/en-us/articles/28142770303505-Security-Log-in-securely-into-Hofy',
    EquipmentCategoriesAndSubCategories = 'https://help.hofy.com/hc/en-us/articles/28142778765201-Managing-teams-Allowance-Approvals',
    DseAssessment = 'https://help.hofy.com/hc/en-us/articles/28142780101009-DSE-assessment',
    CountryIdentifiers = 'https://help.hofy.com/hc/en-us/articles/28142762131473-Country-identifiers-Do-I-need-to-provide-an-ID-number',
    UsersUpload = 'https://www.notion.so/hofy/User-Bulk-Upload-638cff8520044996bd29ddcce691fb51', // used on the Admin panel
    Main = 'https://help.hofy.com/hc/en-us',
    TermsAndConditions = 'https://www.hofy.com/terms-of-service',
    SerialNumberLocation = 'https://help.hofy.com/hc/en-us/articles/28142794343057-How-to-locate-your-equipment-s-serial-number-or-service-tags',
}
