import React, { FC, ReactNode } from 'react';

import { MultiSelectDropdown } from '@hofy/common';
import { allCountriesSorted, Country } from '@hofy/global';
import { useTrCountrySearch } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { BoxProps, TestKeyAware } from '@hofy/ui';

import { CountryLabel } from './CountryLabel';

interface CountryMultiFormDropdownProps extends BoxProps, TestKeyAware {
    value: Country[];
    label: string;
    onChange(o: Country[]): void;
    emptyContent?: ReactNode;
    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
}

export const CountryMultiFormDropdown: FC<CountryMultiFormDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired = false,
    disabled = false,
    testKey,
    ...boxProps
}) => {
    const getCountryNames = useTrCountrySearch();

    return (
        <MultiSelectDropdown
            value={value}
            items={allCountriesSorted}
            label={label}
            labelFormatter={c => (
                <CountryLabel iconSize={16} color={Color.Neutral500} country={c} textNoWrap marginRight={4} />
            )}
            onChange={onChange}
            isError={isError}
            isRequired={isRequired}
            emptyContent={emptyContent}
            toSearchLabel={country => getCountryNames(country)}
            clearable
            disabled={disabled}
            testKey={testKey}
            {...boxProps}
        />
    );
};
