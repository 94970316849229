import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { inspectionService } from '../service/inspectionsService';
import { ListInspectionsFilter } from '../service/types/ListInspectionsFilter';
import { inspectionsQueryKey } from './inspectionsQueryKey';

export const useInspectionsQuery = (filters: ListInspectionsFilter) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [inspectionsQueryKey, filters],
        queryFn: param =>
            inspectionService.listInspections(filters, {
                page: param.pageParam,
                pageSize: 10,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const inspections = useMemo(() => data?.pages.flatMap(v => v.content) || [], [data]);

    return {
        inspections,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
