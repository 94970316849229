import { ProductCategory } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

export const categoryIcons: Record<ProductCategory, Svg> = {
    [ProductCategory.Chair]: SvgIcon.Chair,
    [ProductCategory.Desk]: SvgIcon.Desk,
    [ProductCategory.DeskRiser]: SvgIcon.DeskRiser,
    [ProductCategory.DockingStation]: SvgIcon.DockingStation,
    [ProductCategory.Footrest]: SvgIcon.StandingMat,
    [ProductCategory.Headset]: SvgIcon.Headset,
    [ProductCategory.Keyboard]: SvgIcon.Keyboard,
    [ProductCategory.LaptopStand]: SvgIcon.LaptopStand,
    [ProductCategory.Monitor]: SvgIcon.Monitor,
    [ProductCategory.MobileDevice]: SvgIcon.Phone,
    [ProductCategory.MonitorArm]: SvgIcon.MonitorArm,
    [ProductCategory.MiceTrackpad]: SvgIcon.Mouse,
    [ProductCategory.Printer]: SvgIcon.Printer,
    [ProductCategory.Shredder]: SvgIcon.Shredder,
    [ProductCategory.StandingMat]: SvgIcon.StandingMat,
    [ProductCategory.TaskLight]: SvgIcon.TaskLight,
    [ProductCategory.Webcam]: SvgIcon.Webcam,
    [ProductCategory.WifiRangeExtender]: SvgIcon.Router,
    [ProductCategory.Laptop]: SvgIcon.Laptop,
    [ProductCategory.Dongle]: SvgIcon.Dongle,
    [ProductCategory.Cable]: SvgIcon.Cable,
    [ProductCategory.Adapter]: SvgIcon.Adapter,
    [ProductCategory.DeskTidy]: SvgIcon.DeskTidy,
    [ProductCategory.Desktop]: SvgIcon.Desktop,
    [ProductCategory.Tablet]: SvgIcon.Tablet,
    [ProductCategory.Charger]: SvgIcon.Charger,
    [ProductCategory.Other]: SvgIcon.Other,
    [ProductCategory.WelcomePack]: SvgIcon.Other,
};
