import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface BlockFilterItemProps {
    name: ReactNode;
    onClick(): void;
    selected: boolean;
}

export const BlockFilterItem: FC<BlockFilterItemProps> = ({ name, selected, onClick }) => {
    const itemContent =
        typeof name === 'string' ? (
            <Paragraph3 textNoWrap overflow='hidden' ellipsis>
                {name}
            </Paragraph3>
        ) : (
            name
        );
    return (
        <Box
            onClick={onClick}
            bg={selected ? Color.HighlightActive : undefined}
            paddingHorizontal={12}
            paddingVertical={6}
            rounded
            pointer
            row
        >
            <Box flex='auto'>{itemContent}</Box>
            <Icon svg={SvgIcon.Cross} size={16} color={selected ? Color.ContentSecondary : 'transparent'} />
        </Box>
    );
};
