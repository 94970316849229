import React, { FC } from 'react';

import { isDeel, UserDto } from '@hofy/api-admin';
import { formatUserName, hasAnyManagerRole } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    Avatar,
    BooleanBadge,
    Box,
    CountryLabel,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    PublicIdColumn,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';
import { UserStatusChip } from '@hofy/ui-domain';

interface UsersTableProps {
    users: UserDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenUser(id: UUID): void;
    search: string | undefined;
}

export const UsersTable: FC<UsersTableProps> = ({ users, infinityScroll, onOpenUser, search }) => {
    return (
        <InfinityScrollTable
            data={users}
            toKey={user => user.id}
            infinityScroll={infinityScroll}
            onRowClick={user => onOpenUser(user.id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title={search ? 'No users were found for the current search' : 'No users'}
                />
            }
            minWidth={1200}
            flex='auto'
            columns={[
                PublicIdColumn<UserDto>(),
                {
                    id: 'deel',
                    header: 'Deel',
                    flexGrow: 0,
                    width: 100,
                    renderer: user => <BooleanBadge value={isDeel(user)} offColor='grey' />,
                },
                {
                    id: 'name',
                    header: 'Name',
                    renderer: user => (
                        <Box row gap={8}>
                            <Avatar
                                shrink={0}
                                userId={user.id}
                                name={user.firstName}
                                pictureUrl={user.pictureUrl}
                            />
                            <TextCell>{formatUserName(user)}</TextCell>
                        </Box>
                    ),
                },

                {
                    id: 'email',
                    header: 'Email',
                    renderer: user => (
                        <Box column>
                            {user.emails.map((email, i) => (
                                <Paragraph3 wordBreak key={i}>
                                    {email.address}
                                </Paragraph3>
                            ))}
                        </Box>
                    ),
                },
                {
                    id: 'phone-number',
                    header: 'Phone number',
                    renderer: user => (
                        <Box column>
                            <Paragraph3>{user.address.phoneNumber}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    id: 'country',
                    header: 'Country',
                    renderer: user => <CountryLabel country={user.address.country} />,
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    renderer: user => user.organization.name,
                },
                {
                    id: 'is_manager',
                    header: 'Is Manager',
                    renderer: user => <BooleanBadge offColor='grey' value={hasAnyManagerRole(user.roles)} />,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: user => <UserStatusChip status={user.status} />,
                },
            ]}
        />
    );
};
