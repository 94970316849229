import { useMutation, useQueryClient } from '@tanstack/react-query';

import { suppliersCacheKey, suppliersService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncSuppliersToNetsuite = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: suppliersService.syncSuppliersToNetsuite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Suppliers synced to Netsuite',
            });
        },
    });

    return {
        isLoadingMutation: mutation.isPending,
        syncToNetsuite: mutation.mutate,
    };
};
