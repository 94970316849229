import React, { FC } from 'react';

import { CountryFormDropdown } from '@hofy/core';
import { Country } from '@hofy/global';
import { FormCheckbox, FormInput, UseForm } from '@hofy/ui';

import { CreateOrganizationFormData } from '../../../store/organizations/useCreateOrganization';

interface ManagerFormProps {
    form: UseForm<CreateOrganizationFormData>;
}

export const ManagerForm: FC<ManagerFormProps> = ({ form }) => {
    return (
        <>
            <FormInput label='Manager first name' api={form.fields.managerFirstName} isRequired />
            <FormInput label='Manager last name' api={form.fields.managerLastName} isRequired />
            <FormInput label='Manager email' api={form.fields.managerEmail} isRequired />
            <CountryFormDropdown
                label='Manager country'
                value={form.values.managerCountry}
                onChange={(managerCountry: Country) =>
                    form.setValues({
                        managerCountry,
                    })
                }
                isError={form.errors.managerCountry}
                isRequired
            />
            <FormCheckbox label='Send manager invite' api={form.fields.managerSendInvite} />
        </>
    );
};
