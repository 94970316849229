import React, { FC, Fragment, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { FormSeparator } from './FormSeparator';

interface FormPromptProps extends BoxProps {
    items: {
        label: ReactNode;
        icon?: Svg;
        iconColor?: Color;
    }[];
}

/** @deprecated use `Alert` from `@hofy/ui` instead */
export const FormPrompt: FC<FormPromptProps> = ({ items, ...boxProps }) => {
    return (
        <InfoWrapper padding={16} bg={Color.BackgroundSubtleNeutral} {...boxProps}>
            {items.map((item, index) => (
                <Fragment key={index}>
                    <Box row>
                        <Icon
                            svg={item.icon || SvgIcon.CheckCircle}
                            size={16}
                            color={item.iconColor || Color.FoundationPositive}
                            marginRight={20}
                        />
                        <Paragraph3>{item.label}</Paragraph3>
                    </Box>
                    {index !== items.length - 1 && <FormSeparator spacing={20} />}
                </Fragment>
            ))}
        </InfoWrapper>
    );
};

const InfoWrapper = styled(Box)`
    border-radius: 12px;
`;
