import { createContext, useContext } from 'react';

export interface ModalContextValue {
    onClose(): void;
    dismissible?: boolean;
    headerClose?: boolean;
}

const ModalContext = createContext<ModalContextValue>({
    onClose: () => {},
});

export const useModalContext = () => {
    return useContext(ModalContext);
};

export const ModalContextProvider = ModalContext.Provider;
