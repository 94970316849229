import { DateTime } from 'luxon-business-days';
import React, { FC } from 'react';

import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Paragraph4, Tooltip } from '@hofy/ui';

interface NewJoinerLabelProps {
    startDate: DateString;
}

export const NewJoinerLabel: FC<NewJoinerLabelProps> = ({ startDate }) => {
    const { startDateFormatted, targetDateFormatted } = newJoinerDeliveryDates(startDate);
    return (
        <Tooltip body={`Delivery target date: ${targetDateFormatted}`}>
            <Paragraph4 color={Color.ContentTertiary}>New joiner {startDateFormatted}</Paragraph4>
        </Tooltip>
    );
};

export const newJoinerDeliveryDates = (startDate: DateString) => {
    const date = DateTime.fromISO(startDate, {
        zone: 'UTC',
    }).toUTC();
    const targetDate = date.minusBusiness({
        days: 8,
    });
    const startDateFormatted = formatDate(startDate);
    const targetDateFormatted = formatDate(targetDate);
    return {
        startDateFormatted,
        targetDateFormatted,
    };
};
