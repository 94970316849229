import {
    FloatingContext,
    ReferenceType,
    useClick,
    useDismiss,
    useInteractions,
    useListNavigation,
    useRole,
    useTypeahead,
} from '@floating-ui/react';
import { useRef, useState } from 'react';

interface Options<T, RT extends ReferenceType> {
    context: FloatingContext<RT>;
    stringList: string[];
    disabled?: boolean;
    onChange(value: T): void;
}

/**
 * Hook for handling list interactions for native like dropdowns:
 * - Typeahead search (you can type to navigate the list)
 * - Keyboard navigation (up, down, home, end)
 * - Dismissing (escape, clicking outside)
 * - Clicking (mouse down)
 */
export const useA11yListInteractions = <T, RT extends ReferenceType = ReferenceType>({
    context,
    stringList,
    disabled,
    onChange,
}: Options<T, RT>) => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const listRef = useRef<(HTMLElement | null)[]>([]);
    const isTypingRef = useRef(false);

    const click = useClick(context, { event: 'mousedown' });
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'listbox' });

    const listNav = useListNavigation(context, {
        listRef,
        activeIndex,
        onNavigate: setActiveIndex,
        loop: true,
    });

    const isTyping = (key: string) => {
        return key === 'Enter' || (key === ' ' && !isTypingRef.current);
    };

    const listContentRef = useRef(stringList);
    const typeahead = useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        onMatch: setActiveIndex,
        onTypingChange(isTyping) {
            isTypingRef.current = isTyping;
        },
    });

    const interactions = useInteractions([dismiss, role, listNav, typeahead, click]);

    const referenceProps = disabled ? () => undefined : interactions.getReferenceProps;
    const floatingProps = interactions.getFloatingProps;
    const itemProps = (item: T) =>
        interactions.getItemProps({
            onClick: () => onChange(item),
            onKeyDown: event => {
                if (isTyping(event.key)) {
                    event.preventDefault();
                    onChange(item);
                }
            },
        });

    return {
        referenceProps,
        floatingProps,
        itemProps,
        activeIndex,
        listRef,
    };
};
