import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceEntriesQueryKey, invoiceEntryService, InvoiceEntryTaxAddressPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateInvoiceEntryTaxAddress = (id: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (p: InvoiceEntryTaxAddressPayload) =>
            invoiceEntryService.updateInvoiceEntryTaxAddress(id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry tax address updated',
            });
            onSuccess();
        },
    });

    return {
        updateAddress: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
