import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Color, ColorKeywords } from '@hofy/theme';

import { lerp } from '../../helpers/Math';
import SpinnerSvg from './assets/spinner.svg';

export type SpinnerSize = 16 | 24 | 48 | 96;
const defaultSize: SpinnerSize = 24;
const defaultColor = Color.NonContextualBorderBrand;

export interface SpinnerProps {
    color?: Color | ColorKeywords;
    size?: SpinnerSize;
}

export const Spinner: FC<SpinnerProps> = props => {
    return <SpinnerStyledSvg {...props} />;
};

const rotation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const SpinnerStyledSvg = styled(SpinnerSvg)<SpinnerProps>`
    display: block;
    ${({ size = defaultSize, color = defaultColor }) => css`
        width: ${size}px;
        height: ${size}px;
        fill: ${color};
        animation: ${rotation} ${lerp(0.5, 1.25, size / 96)}s linear infinite;
    `}
`;
