import { getEnumValues } from '@hofy/global';

export enum AdminSettingsTab {
    TermsAndConditions = 'terms-and-conditions',
    CountryMultipliers = 'country-multipliers',
    ApiKeys = 'api-keys',
    ExchangeRates = 'exchange-rates',
    Warehouses = 'warehouses',
    FulfilmentCountrySettings = 'fulfilment-country-settings',
    BetaFeatures = 'beta-features',
    Data = 'data',
    Subsidiaries = 'subsidiaries',
    Addons = 'addons',
    Netsuite = 'netsuite',
}

export const allSettingsTabs = getEnumValues<AdminSettingsTab>(AdminSettingsTab);
