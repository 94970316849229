import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, BoxProps, SectionTitle2 } from '../../base';

export interface CardProps extends BoxProps {
    children?: ReactNode;
}

export const Card: FC<CardProps> = ({ children, ...rest }) => {
    return (
        <Box padding={24} border rounded={8} bg={Color.BackgroundDefault} data-test-key='card' {...rest}>
            {children}
        </Box>
    );
};

interface CardHeaderProps extends BoxProps {
    title?: string;
    children?: ReactNode;
}

export const CardHeader: FC<CardHeaderProps> = ({ title, children, ...rest }) => {
    return (
        <Box
            marginTop={-24}
            marginHorizontal={-24}
            marginBottom={24}
            padding={24}
            borderBottom
            data-test-key='card-header'
            {...rest}
        >
            {title && <SectionTitle2>{title}</SectionTitle2>}
            {children}
        </Box>
    );
};

export const CardFooter: FC<CardProps> = ({ children, ...rest }) => {
    return (
        <Box
            marginBottom={-24}
            marginHorizontal={-24}
            marginTop={24}
            padding={24}
            borderTop
            data-test-key='card-footer'
            {...rest}
        >
            {children}
        </Box>
    );
};

export const CardDivider: FC = () => {
    return <Box marginTop={-24} marginHorizontal={-24} marginBottom={24} padding={24} borderBottom />;
};
