import { RepairListSorting } from '@hofy/api-admin';
import { useStructMemo } from '@hofy/hooks';

import { RepairsPageTab } from './repairsPageTab';

export const useListRepairsSorting = (tab: RepairsPageTab) => {
    const values = useStructMemo<RepairListSorting>({
        sortField: tab === RepairsPageTab.Resolved ? 'updatedAt' : 'createdAt',
        sortDirection: 'desc',
    });

    return {
        values,
    };
};
