import React, { FC } from 'react';

import { ActivityLogDto } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { SvgIcon } from '@hofy/ui';

interface ActivityLogActionsProps {
    activityLog: ActivityLogDto;

    handleHideActivityLog(activityLogId: UUID): void;
}

export const ActivityLogActions: FC<ActivityLogActionsProps> = ({ activityLog, handleHideActivityLog }) => {
    const { hasPermission } = usePermission();

    return (
        <MoreMenu
            marginLeft={16}
            items={[
                {
                    label: 'Hide activity log',
                    action: () => handleHideActivityLog(activityLog.id),
                    visible: hasPermission(Permission.AdminActivityLogsUpdate) && !activityLog.hidden,
                    icon: SvgIcon.Edit,
                },
            ]}
        />
    );
};
