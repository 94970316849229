import React, { FC } from 'react';

import { ItemEventStatus, itemEventStatusColor } from '@hofy/api-shared';
import { Badge, Tooltip } from '@hofy/ui';

import { useTrItemEventStatus } from '../../../../store/itemEvents/useTrItemEventStatus';

interface ItemEventStatusChipProps {
    status: ItemEventStatus;
    errorReason: string | null;
}

export const ItemEventStatusChip: FC<ItemEventStatusChipProps> = ({ status, errorReason }) => {
    const tr = useTrItemEventStatus();
    return (
        <Tooltip body={errorReason ?? 'No error'} maxWidth='auto'>
            <Badge label={tr(status)} color={itemEventStatusColor[status]} />
        </Tooltip>
    );
};
