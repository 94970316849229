import React, { FC } from 'react';

import { BillingScheduleDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';

interface ContractScheduleTabProps {
    schedule: BillingScheduleDto[];
}

export const ContractScheduleTab: FC<ContractScheduleTabProps> = ({ schedule }) => {
    const { formatPrice, multiply } = usePrice();

    return (
        <BaseTable
            data={schedule}
            toKey={(_, index) => index}
            emptyContent={
                <Placeholder illustration={SvgIllustration.ContractSearch} title='No billing schedule' />
            }
            flex='auto'
            columns={[
                {
                    id: 'range',
                    header: 'Invoice period',
                    flexGrow: 1,
                    renderer: schedule =>
                        `${formatDate(schedule.rangeFrom)} - ${formatDate(schedule.rangeTo)}`,
                },
                {
                    id: 'invoice-data',
                    header: 'Invoice data',
                    flexGrow: 1,
                    renderer: schedule => formatDate(schedule.invoiceTime),
                },
                {
                    id: 'invoice-price',
                    header: 'Invoice months',
                    flexGrow: 1,
                    renderer: schedule => schedule.invoiceMonths,
                },
                {
                    id: 'invoice-months',
                    header: 'Unit Price',
                    flexGrow: 1,
                    renderer: schedule => formatPrice(schedule.price),
                },
                {
                    id: 'invoice-price',
                    header: 'Invoice price',
                    flexGrow: 1,
                    renderer: schedule => formatPrice(multiply(schedule.price, schedule.invoiceMonths)),
                },
            ]}
        />
    );
};
