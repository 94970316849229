import React, { FC, useState } from 'react';

import { arePostAddressesEqual } from '@hofy/address';
import {
    originalCollectionAddressSentInSurvey,
    ShipmentCollectionRequestDto,
    ShipmentDetailsDto,
} from '@hofy/api-admin';
import { Alert, AsyncButton, Box, ConfirmModal, Modals } from '@hofy/ui';

import { useUpdateShipmentAddressToCollectionUserAddress } from '../../../../../store/shipments/useUpdateShipmentAddressToCollectionUserAddress';
import { AddressResult } from './AddressResult';
import { CollectionDatesResult } from './CollectionDatesResult';
import { CountryResult } from './CountryResult';
import { DeviceResetResult } from './DeviceResetResult';
import { DropPointResult } from './DropPointResult';
import { NotesResult } from './NotesResult';
import { ParcelsResult } from './ParcelsResult';
import { PhoneNumberResult } from './PhoneNumberResult';
import { ShippingLabelsResult } from './ShippingLabelsResult';
import { SurveyResultsSectionCard } from './SurveyResultsSectionCard';

interface SurveyResultsSectionProps {
    shipment: ShipmentDetailsDto;
    collectionRequest: ShipmentCollectionRequestDto;
}

export const SurveyResultsSection: FC<SurveyResultsSectionProps> = ({ shipment, collectionRequest }) => {
    const { updateShipmentAddressToCollectionUserAddress, isLoadingMutation } =
        useUpdateShipmentAddressToCollectionUserAddress(shipment.id);

    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);

    if (!collectionRequest.formSubmittedByUser) {
        return <Alert description='Pending response' type='warning' />;
    }

    const originalCollectionAddress = originalCollectionAddressSentInSurvey(shipment);

    const showUpdateButton =
        collectionRequest.userConfirmedAddress &&
        originalCollectionAddress &&
        shipment.fromAddress &&
        (!arePostAddressesEqual(shipment.fromAddress, originalCollectionAddress) ||
            shipment.fromAddress.phoneNumber !== originalCollectionAddress.phoneNumber);

    return (
        <Box column gap={24}>
            <Alert
                description='Shows user’s response to the survey and does not represent current shipment details'
                type='neutral'
            />
            <Box row gap={24}>
                <SurveyResultsSectionCard column fullWidth border alignSelf='stretch'>
                    <AddressResult
                        collectionAddress={collectionRequest.userConfirmedAddress}
                        placeOfWorkAddress={originalCollectionAddress}
                    />
                    <CountryResult
                        collectionCountry={collectionRequest.userConfirmedAddress?.country ?? null}
                        placeOfWorkCountry={originalCollectionAddress?.country ?? null}
                    />
                    <PhoneNumberResult
                        collectionPhoneNumber={collectionRequest.userConfirmedAddress?.phoneNumber ?? null}
                        userPhoneNumber={originalCollectionAddress?.phoneNumber ?? null}
                    />
                    <NotesResult
                        title='Collection notes'
                        notes={collectionRequest.userConfirmedAddress?.deliveryInstructions}
                    />
                    {showUpdateButton && (
                        <>
                            <AsyncButton
                                label='Update to place of work address'
                                size='small'
                                type='secondary'
                                onClick={() => setIsUpdateModalOpen(true)}
                                isLoading={isLoadingMutation}
                            />
                            <Modals>
                                {isUpdateModalOpen && (
                                    <ConfirmModal
                                        width={600}
                                        keyPrefix='update-shipment-address-modal'
                                        onClose={() => setIsUpdateModalOpen(false)}
                                        onConfirm={updateShipmentAddressToCollectionUserAddress}
                                    />
                                )}
                            </Modals>
                        </>
                    )}
                </SurveyResultsSectionCard>
                <SurveyResultsSectionCard column fullWidth border alignSelf='stretch'>
                    <CollectionDatesResult userAvailability={collectionRequest.userAvailability} />
                    <ShippingLabelsResult labelStatus={collectionRequest.shipmentLabelsStatus} />
                    <ParcelsResult numberOfParcels={collectionRequest.numberOfParcelsToCollect} />
                    <NotesResult title='Customer notes' notes={collectionRequest.additionalNotes} />
                    <DropPointResult canUseDropPoint={collectionRequest.canUseDropPoint} />
                    <DeviceResetResult isDeviceResetConfirmed={collectionRequest.isDeviceResetConfirmed} />
                </SurveyResultsSectionCard>
            </Box>
        </Box>
    );
};
