import { entries, first, isArray, isObject, last, mapValues, values } from 'lodash';
import { useEffect, useState } from 'react';

import { UseForm } from './formTypes';
import { useFormDiscardConfirmation } from './useFormDiscardConfirmation';

export interface UseMultipartFormForms {
    /** `useForm` or use boolean: true = keep this step, false = skip this step */
    [key: string]: UseForm<any, any> | boolean;
}

interface UseMultipartFormOptions<T> {
    onSubmit(values: T): void;
    onDiscard?(): void;
    /** If true, you can skip steps even if they are not valid */
    allowSkipSteps?: boolean;
}

/** Get values types of all forms  */
export type UseMultipartFormValues<T> = {
    [K in keyof T]: T[K] extends UseForm<infer U, any>
        ? U
        : T[K] extends UseForm<infer U, any> | boolean
        ? U | boolean
        : never;
};

type FormStepName<T extends UseMultipartFormForms> = keyof T;

export interface UseMultipartFormState<T extends UseMultipartFormForms, U extends keyof T = keyof T> {
    values: UseMultipartFormValues<T>;
    isValid: boolean;
    forms: T;
    currentStep: T[U];
    currentIndex: number;
    step: U;
    isFirstStep: boolean;
    isLastStep: boolean;
    stepList: U[];
    stepErrorCounts: Record<U, number>;
    goToStep(step: FormStepName<T>): void;
    previous(): void;
    next(): void;
    submit(): boolean;
    reset(): void;
    discard(): void;
}

/**
 * Combines multiple useForm into one
 *
 * @example ```ts
    const multiform = useMultipartForm(
        {
            first: useForm({
                initial: { name: 'John Doe' },
            }),
            second: useForm({
                initial: { age: 18 },
            }),
        },
        {
            onSubmit: values => {
                values.first.name;
            },
            onDiscard: () => { … }
        },
    );
    ```
 */
export const useMultipartForm = <T extends UseMultipartFormForms, V = T>(
    steps: T,
    options: UseMultipartFormOptions<UseMultipartFormValues<V>>,
): UseMultipartFormState<T> => {
    const stepEntries = entries(steps);

    // Filter out boolean steps, only keep UseForm
    const formEntries = stepEntries.filter(([, step]) => isObject(step)) as [
        FormStepName<T>,
        UseForm<any, any>,
    ][];
    const formList = formEntries.map(([, step]) => step);

    // Filter out skipped `false` steps
    const stepList = stepEntries.filter(([, step]) => step !== false).map(([key]) => key);
    const stepErrorCounts: Record<keyof T, number> = mapValues(steps, step => {
        if (isObject(step)) {
            const stepErrors = values(step.errors).flatMap(stepError => {
                if (isArray(stepError)) {
                    return stepError.flatMap(s => values(s));
                }
                return stepError;
            });

            return stepErrors.length;
        }
        return 0;
    });

    if (stepList.length === 0) {
        throw new Error('[useMultipartForm] `forms` must have at least one form');
    }

    const firstStepName = first(stepList)!;
    const lastStepName = last(stepList)!;

    const [step, setStep] = useState<FormStepName<T>>(firstStepName);
    const isFirstStep = step === firstStepName;
    const isLastStep = step === lastStepName;

    // This state will be used to change step after all changes are applied
    // +1 = next, -1 = previous or step name
    const [transitionToStep, setTransitionToStep] = useState<FormStepName<T> | number | null>(null);

    useEffect(() => {
        // We are using additional useEffect to wait for other changes inside the nested forms to be applied
        // In this way we can immediately do some changes in forms and then change step
        // Without this if changes will affect stepList it will not work correctly
        if (transitionToStep === null) {
            return;
        }
        if (typeof transitionToStep === 'number') {
            const nextIndex = currentIndex + transitionToStep;
            const nextStepName = stepList[nextIndex] || lastStepName;
            setStep(nextStepName);
        } else {
            setStep(transitionToStep);
        }
        setTransitionToStep(null);
    }, [transitionToStep]);

    if (!step) {
        throw new Error('[useMultipartForm] `forms` must have at least one form');
    }

    if (!(step in steps)) {
        throw new Error(`[useMultipartForm] Form with key "${step.toString()}" does not exist`);
    }

    const formValues = Object.fromEntries(
        formEntries.map(([key, form]) => [key, form?.values]),
    ) as UseMultipartFormValues<T>;

    const isValid = formList.every(form => form.isValid);
    const currentIndex = stepList.indexOf(step.toString());

    const nextStep = (nextIndex = +1) => {
        setTransitionToStep(nextIndex);
    };

    const currentStep = steps[step];

    const nextOrSubmit = () => {
        if (isLastStep) {
            submit();
        } else {
            nextStep();
        }
    };

    const next = () => {
        // Skip if step is not a form
        if (typeof currentStep === 'boolean') {
            nextOrSubmit();
            return;
        }

        if (currentStep.isValid || options.allowSkipSteps) {
            nextOrSubmit();
        } else {
            currentStep.showErrors();
        }
    };

    const previous = () => {
        const previousIndex = currentIndex - 1;
        if (previousIndex >= 0) {
            setStep(stepList[previousIndex]);
        }
    };

    const findNotReadyFormEntry = () => {
        return formEntries.find(([, form]) => !form.isValid);
    };

    const submit = () => {
        const notReadyForm = findNotReadyFormEntry();
        if (notReadyForm) {
            const [key, form] = notReadyForm;
            if (key !== step) {
                setTransitionToStep(key);
            }
            form.showErrors();
            // Focus needs dom to be ready
            setTimeout(() => form.focusIncorrectField(), 0);
            return false;
        }

        options.onSubmit(formValues as UseMultipartFormValues<V>);
        return true;
    };

    const goToStep = (targetStep: FormStepName<T>) => {
        // Skip if step is not a form
        if (currentStep === undefined || typeof currentStep === 'boolean') {
            setTransitionToStep(targetStep);
            return;
        }

        const isMovingBackwards = stepList.indexOf(targetStep.toString()) < currentIndex;

        if (currentStep.isValid || options.allowSkipSteps || isMovingBackwards) {
            setTransitionToStep(targetStep);
        } else {
            currentStep?.showErrors();
        }
    };

    const reset = () => {
        formList.forEach(form => form.reset());
        setStep(firstStepName);
    };

    const discard = () => {
        options.onDiscard?.();
    };

    const confirmDiscard = useFormDiscardConfirmation(discard);

    const hasChanges = formList.some(form => form.hasChanges);

    const confirmDiscardIfChanged = () => {
        if (hasChanges) {
            confirmDiscard();
        } else {
            discard();
        }
    };

    return {
        values: formValues,
        isValid,
        forms: steps,
        currentStep,
        currentIndex,
        step,
        isFirstStep,
        isLastStep,
        stepList,
        stepErrorCounts,
        goToStep,
        previous,
        next,
        submit,
        reset,
        discard: confirmDiscardIfChanged,
    };
};
