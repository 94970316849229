import React, { FC } from 'react';

import { formatUserName, ItemLocation, LocationDto, Ownership, WarehouseLocationDto } from '@hofy/api-shared';
import { Avatar, FeaturedIcon, SvgIcon } from '@hofy/ui';

import { ItemLocationCard } from './ItemLocationCard';

interface ItemLocationCellProps {
    location: LocationDto;
}

export const ItemLocationCell: FC<ItemLocationCellProps> = ({ location }) => {
    switch (location.location) {
        case ItemLocation.WithUser:
            return (
                <ItemLocationCard
                    icon={
                        <Avatar
                            userId={location.user!.id}
                            name={location.user!.firstName}
                            pictureUrl={location.user!.pictureUrl}
                        />
                    }
                    label='Team member'
                    name={formatUserName(location.user!)}
                />
            );
        case ItemLocation.AtWarehouse:
            return <WarehouseLocationCard warehouse={location.warehouse!} />;
        case ItemLocation.WithCourier:
            return (
                <ItemLocationCard
                    icon={<FeaturedIcon icon={SvgIcon.Truck} shape='circle' size={32} type='informative' />}
                    label='In Transit'
                    name='With courier'
                />
            );
        case ItemLocation.WriteOff:
            return null;
    }
};

interface WarehouseLocationCardProps {
    warehouse: WarehouseLocationDto;
}
export const WarehouseLocationCard: FC<WarehouseLocationCardProps> = ({ warehouse }) => {
    switch (warehouse.ownership) {
        case Ownership.Hofy:
            return (
                <ItemLocationCard
                    icon={<FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant='vivid' />}
                    label='Hofy warehouse'
                    name={warehouse.name}
                />
            );
        case Ownership.Organization:
            return (
                <ItemLocationCard
                    icon={<FeaturedIcon icon={SvgIcon.Warehouse} shape='circle' size={32} />}
                    label='Organization warehouse'
                    name={warehouse.name}
                />
            );
    }
};
