import { getEnumValues } from '@hofy/global';

export enum ContractExtendOption {
    Default = 'default',
    Not = 'not',
    Months24 = '24m',
    Months36 = '36m',
    Months48 = '48m',
}

export const contractExtendOptionLength: Record<ContractExtendOption, number> = {
    [ContractExtendOption.Default]: 0,
    [ContractExtendOption.Not]: 0,
    [ContractExtendOption.Months24]: 24,
    [ContractExtendOption.Months36]: 36,
    [ContractExtendOption.Months48]: 48,
};

export const allContractExtendOptions = getEnumValues<ContractExtendOption>(ContractExtendOption);
export const allOrgDevicesContractExtendOptions: ContractExtendOption[] = [
    ContractExtendOption.Months24,
    ContractExtendOption.Months36,
    ContractExtendOption.Months48,
];
export const allDevicesContractExtendOptions: ContractExtendOption[] = [
    ContractExtendOption.Not,
    ContractExtendOption.Months24,
    ContractExtendOption.Months36,
    ContractExtendOption.Months48,
];
