import { useQuery } from '@tanstack/react-query';

import { adminService } from '../../admin/service/adminService';
import { adminsCacheKey } from '../../admin/store/cacheKey';
import { AdminDto } from '../service/types/AdminDto';

const emptyAdmins: AdminDto[] = [];

export const useAdminsQuery = (search: string | null) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [adminsCacheKey, { search }],
        queryFn: () => adminService.getAdmins({ search }),
    });

    return {
        isLoading,
        isError,
        data: data || emptyAdmins,
    };
};
