import React, { FC } from 'react';

import { allCountries, Country } from '@hofy/global';
import { useTrCountry, useTrCountryShortname } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { CountryFlag, FontProps, OuterBoxProps } from '@hofy/ui';

import { FlagLabelView, FlagLabelWrapper } from './FlagLabel';

interface CountryLabelProps extends FontProps {
    country?: Country | null;
    iconSize?: number;
    textNoWrap?: boolean;
    color?: Color;
    displayShortName?: boolean;
}

/** @deprecated use `CountryLabel` from `@hofy/ui` instead */
export const CountryLabel: FC<CountryLabelProps> = ({
    country,
    iconSize,
    textNoWrap,
    displayShortName = false,
    ...wrapperProps
}) => {
    return (
        <FlagLabelWrapper {...wrapperProps} flex='auto'>
            <CountryLabelView
                country={country}
                iconSize={iconSize}
                displayShortName={displayShortName}
                textNoWrap={textNoWrap}
            />
        </FlagLabelWrapper>
    );
};

interface CountryLabelViewProps extends OuterBoxProps {
    country?: Country | null;
    iconSize?: number;
    textNoWrap?: boolean;
    displayShortName?: boolean;
}

export const CountryLabelView = ({
    country,
    iconSize = 20,
    textNoWrap,
    displayShortName = false,
    ...boxProps
}: CountryLabelViewProps) => {
    const trCountry = useTrCountry();
    const isCountryValid = !!country && allCountries.includes(country);
    const trCountryShort = useTrCountryShortname();
    const countryOrShortName =
        isCountryValid && displayShortName ? trCountryShort(country) : trCountry(country);

    return (
        <FlagLabelView label={countryOrShortName} textNoWrap={textNoWrap} testKey='country' {...boxProps}>
            <CountryFlag size={iconSize} country={isCountryValid ? country : null} />
        </FlagLabelView>
    );
};
