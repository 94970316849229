import { useHistory, useLocation } from 'react-router-dom';

const goBackFromDefault = 'go-back-from-default';

export const useGoBack = () => {
    const history = useHistory();
    const location = useLocation();

    const goBack = (defaultLocation?: string) => {
        // @ts-ignore
        if (location.key && location.state?.from !== goBackFromDefault) {
            history.goBack();
        } else if (defaultLocation) {
            history.replace(defaultLocation, { from: goBackFromDefault });
        } else if (location) {
            const pageUrl = getRouterPage(location.pathname);
            if (pageUrl) {
                history.replace(window.location.pathname);
            } else {
                history.replace('');
            }
        }
    };

    return { goBack };
};

const getRouterPage = (currentPath: string) => {
    const httpIndex = currentPath.indexOf('//');
    const siteIndex = currentPath.indexOf('/', httpIndex > 0 ? httpIndex + 2 : 0);
    if (siteIndex > 0) {
        const routerIndex = currentPath.indexOf('/', siteIndex + 1);
        const routerPageIndex = currentPath.indexOf('/', routerIndex + 1);
        return currentPath.substring(0, routerPageIndex);
    }
    return undefined;
};
