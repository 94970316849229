import { ColorDye } from '@hofy/global';

export enum OrderType {
    Delivery = 'delivery',
    Collection = 'collection',
}

export const orderTypeColors: Record<OrderType, ColorDye> = {
    [OrderType.Delivery]: 'blue',
    [OrderType.Collection]: 'grape',
};
