import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useConvertLoanerToReplacement = () => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: assignmentService.convertLoanerToReplacement,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Successfully converted to replacement',
            });
        },
    });

    const convertLoanerToReplacement = (id: UUID) => {
        mutation.mutate(id);
    };

    return {
        convertLoanerToReplacement,
    };
};
