import { getEnumValues } from '@hofy/global';

import { PaymentType } from './PaymentType';

export enum PaymentSchema {
    Monthly = 'monthly',
    Annual = 'annual',
    Upfront = 'upfront',
    Deposit6Months = 'deposit_6_months',
    Deposit12Months = 'deposit_12_months',
}

export const allPaymentSchemas = getEnumValues<PaymentSchema>(PaymentSchema);
export const allPaymentSchemasWithoutDeposit = getEnumValues<PaymentSchema>(PaymentSchema).filter(
    v => v !== PaymentSchema.Deposit6Months && v !== PaymentSchema.Deposit12Months,
);

export const allowedPaymentSchemasForPaymentType = new Map<PaymentType, PaymentSchema[]>([
    [PaymentType.Prepaid, [PaymentSchema.Upfront]],
    [PaymentType.Deferred, allPaymentSchemas],
]);
