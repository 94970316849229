import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { childrenProps } from '../../../helpers/React';
import { Box } from '../../base';
import { Tooltip } from '../../tooltip';
import { Button } from '../button/Button';
import { ButtonSize } from '../button/buttonConfig';

export interface SegmentedControlProps {
    size?: ButtonSize;
    children: ReactNode;
    disabled?: boolean;
}

export const SegmentedControl: React.FC<SegmentedControlProps> = ({
    size = 'medium',
    children,
    disabled: segmentDisabled,
}) => {
    const disabled = useIsDisabled(segmentDisabled);
    const controls = childrenProps(children, SegmentedControlItem);

    return (
        <Box rounded={10} border borderColor={Color.InteractionDefaultNormal} row inline>
            {controls.map((control, index) => (
                <SegmentedControlItem
                    key={index}
                    label={control.label}
                    onClick={control.onClick}
                    active={control.active}
                    disabled={disabled || control.disabled}
                    size={size}
                    tooltip={control.tooltip}
                />
            ))}
        </Box>
    );
};

interface SegmentedControlItemProps {
    label: string;
    onClick(): void;
    active?: boolean;
    tooltip?: string | null;
    disabled?: boolean;
    size?: ButtonSize;
}

export const SegmentedControlItem: React.FC<SegmentedControlItemProps> = ({
    label,
    onClick,
    active = false,
    tooltip,
    disabled = false,
    // eslint-disable-next-line react/no-unused-prop-types
    size,
}) => {
    return (
        <Tooltip placement='bottom' body={tooltip} enabled={!!tooltip}>
            <SegmentedButton
                label={label}
                type={active ? 'primary' : 'ghost'}
                onClick={onClick}
                size={size}
                disabled={disabled}
            />
        </Tooltip>
    );
};

const SegmentedButton = styled(Button)<{ disabled: boolean }>`
    ${p =>
        p.disabled &&
        css`
            pointer-events: none;
        `}
`;
