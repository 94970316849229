import React, { FC, ReactNode, useState } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { DateString } from '@hofy/global';
import {
    DateType,
    jsDateToLuxonDate,
    luxonToJsDate,
    optionalDateStringToJsDate,
    parseDateTime,
    toISODate,
} from '@hofy/helpers';

import { DatePickerContent } from './base/DatePickerContent';
import { DatepickerHeader } from './base/DatepickerHeader';
import { numberOfWeeksInMonth } from './lib/numberOfWeeksInMonth';

export interface InlineDatepickerProps {
    type?: DateType;
    date: DateString | null;
    onChange(v: DateString | null): void;

    minDate?: DateString;
    maxDate?: DateString;
    highlightDates?: DateString[];
    disabled?: boolean;

    filterDate?(date: DateString): boolean;
    renderCustomHeader?(v: ReactDatePickerCustomHeaderProps): ReactNode;
}

export const InlineDatepicker: FC<InlineDatepickerProps> = ({
    date,
    onChange,
    type = 'day',
    minDate,
    maxDate,
    highlightDates,
    disabled,
    filterDate,
    renderCustomHeader,
}) => {
    const onSelect = (selectedDate: Date) => {
        if (toISODate(jsDateToLuxonDate(selectedDate)) === date) {
            onChange(null);
        }
    };

    const selectedJsDate = optionalDateStringToJsDate(date);

    const shouldPeekNextMonth = (jsDate: Date | null) => {
        const weeks = numberOfWeeksInMonth(jsDateToLuxonDate(jsDate ?? new Date()));
        return weeks < 6;
    };

    const [peekNextMonth, setPeekNextMonth] = useState(() => shouldPeekNextMonth(selectedJsDate));

    return (
        <DatePickerContent
            selected={selectedJsDate}
            minDate={optionalDateStringToJsDate(minDate)}
            maxDate={optionalDateStringToJsDate(maxDate)}
            highlightDates={highlightDates?.map(date => luxonToJsDate(parseDateTime(date)))}
            onSelect={onSelect}
            onChange={jsDate => onChange(jsDate ? toISODate(jsDateToLuxonDate(jsDate)) : null)}
            onMonthChange={jsDate => {
                setPeekNextMonth(shouldPeekNextMonth(jsDate));
            }}
            filterDate={filterDate ? d => filterDate(toISODate(jsDateToLuxonDate(d))) : undefined}
            peekNextMonth={peekNextMonth} // show next month first week to avoid jumping when changing months
            renderCustomHeader={props =>
                renderCustomHeader ? (
                    renderCustomHeader(props)
                ) : (
                    <DatepickerHeader {...props} showMonths={type !== 'month'} />
                )
            }
            disabled={disabled}
            showMonthYearPicker={type === 'month'}
            inline
        />
    );
};
