/* eslint-disable */
// @ts-nocheck
import { APP_CONFIG } from '@hofy/config';

export const analytics = window.analytics as SegmentAnalytics.AnalyticsJS | undefined;

const registerAnalytics = () => {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize) {
        if (analytics.invoked) {
            window.console && console.error && console.error('Segment snippet included twice.');
        } else {
            analytics.invoked = !0;
            analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
            ];
            analytics.factory = function (e) {
                return function () {
                    const t = Array.prototype.slice.call(arguments);
                    t.unshift(e);
                    analytics.push(t);
                    return analytics;
                };
            };
            for (const key of analytics.methods) {
                analytics[key] = analytics.factory(key);
            }
            analytics.load = function (key, e) {
                const t = document.createElement('script');
                t.type = 'text/javascript';
                t.async = !0;
                t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js';
                const n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(t, n);
                analytics._loadOptions = e;
            };
            analytics.SNIPPET_VERSION = '4.15.3';
        }
    }

    return analytics;
};

export const initAnalytics = () => {
    if (APP_CONFIG.segmentWriteKey) {
        const analytics = registerAnalytics();
        analytics._writeKey = APP_CONFIG.segmentWriteKey;
        analytics.load(APP_CONFIG.segmentWriteKey);
    } else {
        // by default all events will be recorded to window.analytics when no key provided
        console.log('Fake adapter initialized!');
    }
};
