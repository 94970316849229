import React, { FC } from 'react';

import { ItPolicyDto, OrderAction, teamColorMapping } from '@hofy/api-shared';
import { ComponentLoader } from '@hofy/common';
import { usePrice, useSearch } from '@hofy/hooks';
import { getColorFromColorDye } from '@hofy/theme';
import { BaseTable, Box, Paragraph3, Placeholder, SvgIllustration } from '@hofy/ui';

import { ColorBox } from '../components/ColorBox';

interface ItPoliciesTabProps {
    isItPoliciesLoading: boolean;
    itPolicies: ItPolicyDto[];
    search: string;
}

export const ItPoliciesTab: FC<ItPoliciesTabProps> = ({ itPolicies, isItPoliciesLoading, search }) => {
    const { formatPrice } = usePrice();
    const filteredItPolicies = useSearch(itPolicies, t => [t.name], search);

    if (isItPoliciesLoading) {
        return <ComponentLoader />;
    }

    return (
        <BaseTable
            data={filteredItPolicies}
            toKey={itPolicy => itPolicy.id}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title={search ? 'No teams were found for the current search' : 'No teams'}
                />
            }
            columns={[
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 1,
                    renderer: itPolicy => (
                        <Box row>
                            <ColorBox bg={getColorFromColorDye(teamColorMapping[itPolicy.color], 'main')} />
                            <Paragraph3>{itPolicy.name}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    width: 200,
                    flexGrow: 0,
                    id: 'allowance',
                    header: 'Allowance',
                    renderer: itPolicy => formatPrice(itPolicy.hardwarePolicy.allowance),
                },
                {
                    id: 'approval',
                    flexGrow: 0,
                    header: 'Approval',
                    renderer: itPolicy =>
                        itPolicy.hardwarePolicy.withinBudgetAction === OrderAction.Approve
                            ? 'Auto'
                            : 'Manual',
                },
            ]}
        />
    );
};
