import React, { FC } from 'react';

import { JobStatus, jobStatusColor, useTrJobStatus } from '@hofy/api-admin';
import { Badge } from '@hofy/ui';

interface JobStatusChipProps {
    status: JobStatus;
}

export const JobStatusChip: FC<JobStatusChipProps> = ({ status }) => {
    const tr = useTrJobStatus();
    return <Badge label={tr(status)} color={jobStatusColor[status]} />;
};
