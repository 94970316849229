import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    subscriptionCacheKey,
    SubscriptionDto,
    SubscriptionPayload,
    subscriptionService,
} from '@hofy/api-admin';
import { nowISODate } from '@hofy/helpers';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from '../organizations/organizationsCacheKey';
import { useSubscriptionForm } from './useSubscriptionForm';

export const useReplaceSubscription = (
    organization: OrganizationDetailsDto,
    subscription: SubscriptionDto,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: SubscriptionPayload) =>
            subscriptionService.replaceSubscription(organization.id, subscription.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [subscriptionCacheKey, organization.id],
            });
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                type: 'positive',
                message: 'Subscription replaced',
            });
            onSuccess();
        },
    });

    const initialValuesForNewSubscription: SubscriptionDto = { ...subscription, startOn: nowISODate() };
    const form = useSubscriptionForm(mutation.mutate, initialValuesForNewSubscription);

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
