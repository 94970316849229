import React, { FC, MouseEvent } from 'react';

import { Span, useToast } from '@hofy/ui';

interface CopiableTextProps {
    text: string | null;
    onCopy(): void;
}

export const CopiableText: FC<CopiableTextProps> = ({ text, onCopy }) => {
    const { showErrorToast } = useToast();

    const textClickedHandler = (event: MouseEvent<HTMLSpanElement>) => {
        if (event.type === 'click') {
            window.navigator.clipboard
                .writeText(text !== null ? text : '')
                .then(() => onCopy())
                .catch(() => showErrorToast());
        }
    };

    return <Span onClick={textClickedHandler}>{text !== null ? text : '--'}</Span>;
};
