import { Country, DateString, Phone, UUID } from '@hofy/global';

import { AddressType } from './AddressType';

export interface PostAddress {
    line1: string | null;
    line2: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: Country;
}

export interface AddressDto {
    id: UUID;
    type: AddressType;
    line1: string | null;
    line2: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: Country;
    taxIdNumber: string | null;
    passportNumber: string | null;
    dateOfBirth: DateString | null;
    phoneNumber: Phone | null;
    deliveryInstructions: string | null;
    isValid: boolean;
}

export type AddressViewDto = Omit<AddressDto, 'id' | 'isValid'>;

const dtoToPayload = (dto: AddressDto, type: AddressType): AddressPayload => {
    return {
        type: type,
        line1: dto.line1,
        line2: dto.line2,
        city: dto.city,
        state: dto.state,
        postCode: dto.postCode,
        country: dto.country,
        taxIdNumber: dto.taxIdNumber,
        passportNumber: dto.passportNumber,
        dateOfBirth: dto.dateOfBirth,
        phoneNumber: dto.phoneNumber,
        deliveryInstructions: dto.deliveryInstructions,
    };
};
export const addressToPayloadWithDefault = (dto: AddressDto | null, type: AddressType) => {
    if (!dto) {
        return emptyAddressPayload(type);
    }
    return dtoToPayload(dto, type);
};
export const addressToOptionalPayload = (dto: AddressDto | null, type: AddressType) => {
    if (!dto) {
        return null;
    }
    return dtoToPayload(dto, type);
};

/* eslint-disable hofy-lint/dto */
export interface AddressPayload {
    type: AddressType;
    line1: string | null;
    line2: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: Country;

    taxIdNumber: string | null;
    passportNumber: string | null;
    dateOfBirth: DateString | null;
    phoneNumber: Phone | null;
    deliveryInstructions: string | null;
}

export const emptyAddressPayload = (type: AddressType): AddressPayload => ({
    type,
    country: Country.UnitedKingdom,
    state: null,
    line1: null,
    line2: null,
    city: null,
    postCode: null,
    taxIdNumber: null,
    passportNumber: null,
    dateOfBirth: null,
    phoneNumber: null,
    deliveryInstructions: null,
});
