import { compact, xor } from 'lodash';
import React, { FC } from 'react';

import { OrganizationRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { FilterChip } from '@hofy/ui';

interface OrganizationFilterChipProps {
    organizations: OrganizationRefDto[];
    value: UUID[];
    onChange(ids: UUID[]): void;
}

export const OrganizationFilterChip: FC<OrganizationFilterChipProps> = ({
    value,
    organizations,
    onChange,
}) => {
    const removeOrganization = (id: UUID) => {
        onChange(xor(value, [id]));
    };

    const organizationRef = compact(value.map(id => organizations.find(org => org.id === id)) || []);
    return (
        <>
            {organizationRef?.map(org => (
                <FilterChip
                    key={org.id}
                    label={org.name}
                    onClear={() => removeOrganization(org.id)}
                    color='blue'
                />
            ))}
        </>
    );
};
