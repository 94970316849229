import { noop } from 'lodash';
import React, { FC } from 'react';
import { useDebounce } from 'react-use';

import {
    AddonDetailsDto,
    AddonQuoteDto,
    AddonQuoteEnrolmentPayload,
    AddonQuotePayload,
    useAddonQuoteQuery,
} from '@hofy/api-admin';
import {
    AddonTarget,
    PaymentSchema,
    ProductCategory,
    useTrPaymentSchema,
    useTrProductCategory,
} from '@hofy/api-shared';
import { SlideoutContent, SlideoutFooter } from '@hofy/common';
import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    ArrayField,
    ArrayFieldRecord,
    BaseTable,
    Box,
    Button,
    ErrorStatePlaceholder,
    Form,
    FormDecimalInput,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    IconButton,
    Paragraph3,
    Placeholder,
    SkeletonList,
    SvgIcon,
    SvgIllustration,
    UseForm,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useTrPlatformTier } from '../../../../store/organizations/useTrPlatformTier';

interface AddonQuoteProps {
    form: UseForm<AddonQuotePayload>;
    enrolments: ArrayField<AddonQuoteEnrolmentPayload>;
    addon: AddonDetailsDto;
    onClose(): void;
}

export const AddonQuote: FC<AddonQuoteProps> = ({ form, enrolments, addon, onClose }) => {
    const trPlatformTier = useTrPlatformTier();

    const { quote, isPending, isError, refetch } = useAddonQuoteQuery(addon.id, form.values, false);
    useDebounce(() => form.isValid && refetch(), 500, [refetch, form.isValid, form.values]);

    return (
        <Form isError={isError} onSubmit={noop} column flex='auto'>
            <SlideoutContent column gap={30} paddingVertical={20} flex='auto'>
                <FormSection label='Organization details'>
                    <Box row gap={10}>
                        <FormSelect
                            label='Platform tier'
                            toText={trPlatformTier}
                            api={form.fields.platformTier}
                            options={addon.applicablePlatformTiers}
                            width={260}
                        />
                        <FormDecimalInput
                            label='Organization discount'
                            api={form.fields.customDiscount}
                            rightSlot='%'
                            precision={4}
                            width={200}
                        />
                    </Box>
                    <Box column gap={10} alignItems='flex-start'>
                        {enrolments.fields.map(row => (
                            <EnrolmentsForm
                                key={row.key}
                                arrayField={row}
                                onDuplicate={() => enrolments.duplicate(row.key)}
                                onRemove={() => enrolments.remove(row.key)}
                                addonTarget={addon.target}
                                productCategories={addon.applicableProductCategories}
                                paymentSchemas={addon.applicablePaymentSchemas}
                            />
                        ))}
                        <Box row gap={10}>
                            <Button
                                label='Add enrolments'
                                type='secondary'
                                onClick={() => enrolments.add()}
                            />
                            {enrolments.fields.length > 0 && (
                                <Button
                                    label='Clear all'
                                    type='secondary'
                                    action='destructive'
                                    onClick={() => enrolments.clear()}
                                />
                            )}
                        </Box>
                    </Box>
                </FormSection>

                {enrolments.fields.length === 0 ? (
                    <Placeholder illustration={SvgIllustration.List} title='' />
                ) : (
                    <>
                        {isPending && <SkeletonList items={2} height={200} gap={20} />}
                        {isError && <ErrorStatePlaceholder />}
                        {quote && <AddonQuotePreview quote={quote} addonTarget={addon.target} />}
                    </>
                )}
            </SlideoutContent>

            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Form>
    );
};

interface EnrolmentsFormProps {
    arrayField: ArrayFieldRecord<AddonQuoteEnrolmentPayload>;
    onDuplicate?(): void;
    onRemove?(): void;
    addonTarget: AddonTarget;
    productCategories: ProductCategory[];
    paymentSchemas: PaymentSchema[];
}

const EnrolmentsForm: FC<EnrolmentsFormProps> = ({
    arrayField,
    onDuplicate,
    onRemove,
    addonTarget,
    productCategories,
    paymentSchemas,
}) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trProductCategory = useTrProductCategory();

    return (
        <Box
            row
            gap={10}
            alignItems='flex-start'
            justify='space-between'
            borderTop
            borderColor={Color.InteractionDefaultSubtleBorder}
            paddingVertical={12}
            fullWidth
        >
            <Box row gap={10} wrap alignItems='flex-start'>
                <FormNumberInput label='Enrolment count' api={arrayField.api.enrolmentCount} width={140} />
                {addonTarget === AddonTarget.Asset && (
                    <FormSelect
                        label='Product category'
                        toText={trProductCategory}
                        api={arrayField.api.productCategory}
                        options={productCategories}
                        width={200}
                    />
                )}
                {addonTarget === AddonTarget.Asset && (
                    <FormPriceInput label='Asset value' api={arrayField.api.assetValue} width={140} />
                )}
                <FormNumberInput
                    label='Enrolment duration (months)'
                    api={arrayField.api.duration}
                    width={200}
                />
                <FormSelect
                    label='Payment schema'
                    toText={trPaymentSchema}
                    api={arrayField.api.paymentSchema}
                    options={paymentSchemas}
                    width={200}
                />
            </Box>
            <Box row gap={10} marginTop={40} minWidth={60}>
                <IconButton icon={SvgIcon.Copy} onClick={onDuplicate} color={Color.ContentSecondary} />
                <IconButton icon={SvgIcon.Trash} onClick={onRemove} color={Color.ContentNegative} />
            </Box>
        </Box>
    );
};

interface AddonQuotePreviewProps {
    quote: AddonQuoteDto;
    addonTarget: AddonTarget;
}

const AddonQuotePreview: FC<AddonQuotePreviewProps> = ({ quote, addonTarget }) => {
    const trProductCategory = useTrProductCategory();

    const { formatPrice } = usePrice();

    return (
        <>
            <FormSection label='Pricing'>
                <BaseTable
                    data={quote.pricing}
                    toKey={(_, index) => index}
                    columns={[
                        {
                            id: 'enrolments',
                            header: 'Enrolments',
                            flexGrow: 2,
                            renderer: ({
                                enrolmentCount,
                                assetValue,
                                productCategory,
                                enrolmentDuration,
                            }) => (
                                <Box column>
                                    <Paragraph3>
                                        {addonTarget === AddonTarget.Asset
                                            ? `${enrolmentCount} x ${formatPrice(
                                                  assetValue,
                                              )} ${trProductCategory(productCategory)}`
                                            : enrolmentCount}
                                    </Paragraph3>
                                    <Paragraph3>{`${enrolmentDuration} months`}</Paragraph3>
                                </Box>
                            ),
                        },
                        {
                            id: 'total-discount',
                            header: 'Total discount',
                            flexGrow: 1,
                            renderer: ({ totalDiscount }) => `${totalDiscount}%`,
                        },
                        {
                            id: 'price-per-enrolment-per-month',
                            header: 'Price/enrolment/month',
                            flexGrow: 1,
                            renderer: ({ pricingBeforeDiscount, pricingAfterDiscount }) => (
                                <PriceRenderer
                                    before={pricingBeforeDiscount.pricePerMonthPerEnrolment}
                                    after={pricingAfterDiscount.pricePerMonthPerEnrolment}
                                />
                            ),
                        },
                        {
                            id: 'price-per-enrolment',
                            header: 'Price/enrolment',
                            flexGrow: 1,
                            renderer: ({ pricingBeforeDiscount, pricingAfterDiscount }) => (
                                <PriceRenderer
                                    before={pricingBeforeDiscount.pricePerEnrolment}
                                    after={pricingAfterDiscount.pricePerEnrolment}
                                />
                            ),
                        },
                        {
                            id: 'price-per-month',
                            header: 'Price/month',
                            flexGrow: 1,
                            renderer: ({ pricingBeforeDiscount, pricingAfterDiscount }) => (
                                <PriceRenderer
                                    before={pricingBeforeDiscount.pricePerMonth}
                                    after={pricingAfterDiscount.pricePerMonth}
                                />
                            ),
                        },
                        {
                            id: 'price',
                            header: 'Total price',
                            flexGrow: 1,
                            renderer: ({ pricingBeforeDiscount, pricingAfterDiscount }) => (
                                <PriceRenderer
                                    before={pricingBeforeDiscount.price}
                                    after={pricingAfterDiscount.price}
                                />
                            ),
                        },
                    ]}
                />
            </FormSection>
            {quote.tokenPools.length > 0 && (
                <FormSection label='Pools'>
                    <BaseTable
                        data={quote.tokenPools}
                        toKey={(_, index) => index}
                        columns={[
                            {
                                id: 'pool-name',
                                header: 'Pool',
                                flexGrow: 1,
                                renderer: ({ poolName }) => poolName,
                            },
                            {
                                id: 'tokens',
                                header: 'Available tokens',
                                flexGrow: 1,
                                renderer: ({ generatedTokens }) => generatedTokens,
                            },
                        ]}
                    />
                </FormSection>
            )}
        </>
    );
};

interface PriceRendererProps {
    before: Price;
    after: Price;
}

const PriceRenderer: FC<PriceRendererProps> = ({ before, after }) => {
    const { formatPrice } = usePrice();
    return (
        <Box column alignItems='flex-end'>
            <Paragraph3>{formatPrice(after)}</Paragraph3>
            <Paragraph3 color={Color.ContentDisabled}>{formatPrice(before)}</Paragraph3>
        </Box>
    );
};
