export enum PaymentError {
    PaymentProviderNotEnabled = 'payment_provider_not_enabled',
    InvalidFileMimeType = 'invalid_file_mime_type',
    InvalidFileHeader = 'invalid_file_header',
    InvalidFileSheetCount = 'invalid_file_sheet_count',
    InvalidFilename = 'invalid_filename',
    InvalidRowCurrency = 'invalid_row_currency',
    InvalidRowCompanyAlias = 'invalid_row_company_alias',
    InvalidGroupNegativeAmount = 'invalid_group_negative_amount',
}
