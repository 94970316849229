import React, { FC } from 'react';

import { Portal } from '../../components';
import { Toast } from '../../components/toast/Toast';
import { ToastStack } from '../../components/toast/ToastStack';
import { useToastsContext } from './ToastsContext';

interface ToastListProps {
    onPause?(): void;
    onResume?(): void;
    onDismiss?(id: string): void;
}

export const ToastList: FC<ToastListProps> = ({ onPause, onResume, onDismiss }) => {
    const context = useToastsContext();

    if (!context) {
        return null;
    }

    const { toasts, hideToast } = context;

    return (
        <Portal>
            <ToastStack onPause={onPause} onResume={onResume} onDismiss={onDismiss}>
                {toasts.map(toast => (
                    <Toast
                        key={toast.key || toast.id}
                        tmpKey={toast.key}
                        id={toast.id}
                        title={toast.title}
                        message={toast.message}
                        type={toast.type}
                        onClose={() => hideToast(toast.id)}
                    />
                ))}
            </ToastStack>
        </Portal>
    );
};
