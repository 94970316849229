import React, { FC } from 'react';

import { AdminDto, useAdminQuery } from '@hofy/api-admin';
import { formatAdminName, Permission, useTrRole } from '@hofy/api-shared';
import { ComponentLoader, Overlay } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import {
    Badge,
    BooleanBadge,
    Box,
    Button,
    FormSection,
    Labeled,
    LabeledText,
    PageHeader,
    SvgIcon,
} from '@hofy/ui';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';

interface AdminDetailsPageProps {
    adminId: UUID;
    onEdit(id: UUID): void;
}

export const AdminDetailsPage: FC<AdminDetailsPageProps> = ({ adminId, onEdit }) => {
    const { data, isLoading } = useAdminQuery(adminId);
    const { hasPermission } = usePermission();

    const content = () => {
        if (isLoading || !data) {
            return <ComponentLoader />;
        }
        return <Details admin={data} />;
    };

    return (
        <Overlay column flex='auto' bg={Color.BackgroundDefault}>
            <PageHeader
                title={data ? formatAdminName(data) : ''}
                rightSlot={
                    <Button
                        label='Edit'
                        leftIcon={SvgIcon.Settings}
                        onClick={() => onEdit(adminId)}
                        disabled={!hasPermission(Permission.AdminAdminsUpdate)}
                    />
                }
            />
            {content()}
        </Overlay>
    );
};

interface DetailsProps {
    admin: AdminDto;
}

export const Details: FC<DetailsProps> = ({ admin }) => {
    const trRole = useTrRole();
    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20} column gap={30}>
                <FormSection label='Details'>
                    <LabeledText label='Id' content={admin.id} />
                    <LabeledText label='Public Id' content={admin.publicId} />
                    <LabeledText label='Name' content={formatAdminName(admin)} />
                </FormSection>
                <FormSection label='Account'>
                    <LabeledText label='Email' content={admin.email} />
                    <Labeled
                        label='Is offboarded'
                        content={<BooleanBadge onColor='red' offColor='green' value={admin.isOffboarded} />}
                    />
                    <LabeledText label='Last login' content={formatDateTime(admin.lastLoginAt)} />
                    <LabeledText label='First login' content={formatDateTime(admin.firstLoginAt)} />
                    <Labeled
                        label='Roles'
                        content={
                            <Box row wrap marginBottom={-10}>
                                {admin.roles.map(role => (
                                    <Badge
                                        key={role}
                                        color='grape'
                                        label={trRole(role)}
                                        marginRight={10}
                                        marginBottom={10}
                                    />
                                ))}
                            </Box>
                        }
                    />
                </FormSection>
                <AuditSection model={admin} />
            </Box>
            <Box flex={1} />
        </Box>
    );
};
