import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    AppendAssignmentsToShipmentPayload,
    assignmentsCacheKey,
    shipmentsCacheKey,
    shipmentService,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useAppendAssignmentsToShipment = (shipmentId: UUID | null) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: AppendAssignmentsToShipmentPayload) =>
            shipmentService.appendAssignmentsToShipment(shipmentId!, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [shipmentsCacheKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Assignments added to shipment',
            });
        },
    });

    const appendAssignmentToShipment = (assignmentIDs: UUID[]) => {
        if (!shipmentId) {
            return;
        }
        mutation.mutate({ assignmentIDs });
    };

    return {
        appendAssignmentToShipment,
    };
};
