import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { EnumRoute, UUIDRoute } from '@hofy/router';

import { allInspectionTabs, InspectionTab } from '../../../store/inspections/InspectionTab';
import { InspectionSlideout } from './InspectionSlideout';
import { InspectionSlideoutContextProvider } from './InspectionSlideoutContext';

interface InspectionSlideoutRouterProps {
    base: string;
    itemsBase: string;
    onClose(): void;
}

export const InspectionSlideoutRouter: FC<InspectionSlideoutRouterProps> = ({ base, itemsBase, onClose }) => {
    const history = useHistory();

    return (
        <InspectionUUIDRoute path={`${base}/:uuid`}>
            {({ uuid }) => (
                <Switch>
                    <InspectionDetailsTabRoute path={`${base}/:uuid/:tab(${allInspectionTabs.join('|')})`}>
                        {({ tab }) => (
                            <InspectionSlideoutContextProvider tab={tab} base={base} itemsBase={itemsBase}>
                                <InspectionSlideout
                                    uuid={uuid}
                                    tab={tab}
                                    onTabChange={newTab =>
                                        history.replace(`${base}/${uuid}/${newTab}${history.location.search}`)
                                    }
                                    onClose={onClose}
                                />
                            </InspectionSlideoutContextProvider>
                        )}
                    </InspectionDetailsTabRoute>
                    <Redirect
                        to={`${base}/${uuid}/${InspectionTab.Inspection}${history.location.search}`}
                        from={`${base}/${uuid}`}
                    />
                </Switch>
            )}
        </InspectionUUIDRoute>
    );
};

const InspectionUUIDRoute = UUIDRoute('uuid', Route);
const InspectionDetailsTabRoute = EnumRoute<InspectionTab>('tab', InspectionTab, Route);
