import { useMutation, useQueryClient } from '@tanstack/react-query';
import { countBy } from 'lodash';
import { MouseEvent } from 'react';

import {
    OrganizationPaymentDiscountDto,
    OrganizationPaymentDiscountPayload,
    OrganizationPaymentDiscountsPayload,
    organizationService,
} from '@hofy/api-admin';
import { PaymentSchema, RentalTerm } from '@hofy/api-shared';
import { isValidFraction, Percent, UUID, zeroUuid } from '@hofy/global';
import { arrayRemove, errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

interface OrganizationPaymentsValidation {
    paymentDiscounts: {
        precedence?: string;
        discountPercent?: Percent;
        rentalTerm?: string;
        paymentSchema?: string;
        termSchemaInvalid?: string;
    }[];
}

export const useUpdateOrganizationPaymentDiscounts = (
    organizationId: UUID,
    paymentDiscounts: OrganizationPaymentDiscountDto[],
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: OrganizationPaymentDiscountsPayload) =>
            organizationService.updateOrganizationPaymentDiscounts(organizationId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: `Payment discount successfully updated`,
            });
            onSuccess?.();
        },
    });

    const form = useForm<
        OrganizationPaymentDiscountsPayload,
        OrganizationPaymentDiscountsPayload,
        OrganizationPaymentsValidation
    >({
        initial: {
            paymentDiscounts: paymentDiscounts.map(p => ({
                id: p.id,
                precedence: p.precedence,
                discountPercent: p.discountPercent,
                rentalTerm: p.rentalTerm,
                paymentSchema: p.paymentSchema,
                validStartOn: p.validStartOn,
                validEndOn: p.validEndOn,
            })),
        },
        onSubmit: mutation.mutate,
        validate: ({ paymentDiscounts }) => {
            const precedenceCountMap = countBy(paymentDiscounts, i => i.precedence);
            return {
                paymentDiscounts: paymentDiscounts.map(p => ({
                    precedence: errorMap([
                        precedenceCountMap[p.precedence] > 1,
                        'Precedence should be unique',
                    ]),
                    discountPercent: errorMap([
                        !isValidFraction(p.discountPercent),
                        'Value should be between -100 and 100',
                    ]),
                    rentalTerm: errorMap([isEmpty(p.rentalTerm), 'RentalTerm is required']),
                    paymentSchema: errorMap([isEmpty(p.paymentSchema), 'PaymentSchema is required']),
                    termSchemaInvalid: errorMap([
                        p.paymentSchema === PaymentSchema.Annual &&
                            (p.rentalTerm === RentalTerm.Rental6Months ||
                                p.rentalTerm === RentalTerm.Rental12Months),
                        'Term + Schema not valid',
                    ]),
                })),
            };
        },
    });

    const resetForm = () => {
        form.setValues({
            paymentDiscounts: paymentDiscounts.map(p => ({
                id: p.id,
                precedence: p.precedence,
                discountPercent: p.discountPercent,
                rentalTerm: p.rentalTerm,
                paymentSchema: p.paymentSchema,
                validStartOn: p.validStartOn,
                validEndOn: p.validEndOn,
            })),
        });
    };

    const setStateAtIndex = (index: number, v: Partial<OrganizationPaymentDiscountPayload>) => {
        const newData = [...form.values.paymentDiscounts];
        newData[index] = {
            ...newData[index],
            ...v,
        };
        form.setValues({
            paymentDiscounts: newData,
        });
    };

    const deleteAtIndex = (e: MouseEvent<HTMLDivElement>, index: number) => {
        e.stopPropagation();
        const newData = [...form.values.paymentDiscounts];
        form.setValues({
            paymentDiscounts: arrayRemove(newData, index),
        });
    };

    const addEntry = () => {
        form.setValues({
            paymentDiscounts: [
                ...form.values.paymentDiscounts,
                {
                    id: zeroUuid,
                    precedence: 0,
                    discountPercent: '0.00',
                    rentalTerm: RentalTerm.Rental12Months,
                    paymentSchema: PaymentSchema.Annual,
                    validStartOn: null,
                    validEndOn: null,
                },
            ],
        });
    };
    return {
        form,
        resetForm,
        setStateAtIndex,
        deleteAtIndex,
        addEntry,
        isLoadingMutation: mutation.isPending,
        isMutationSuccess: mutation.isSuccess,
    };
};
