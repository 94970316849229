import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import { Currency } from '@hofy/global';
import {
    AsyncButton,
    FormContainer,
    FormPriceInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateOrganizationBYOD } from '../../../store/organizations/useUpdateOrganizationBYOD';

interface BYODUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const BYODUpdateModal: FC<BYODUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationBYOD(organization, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.byod-update.title')}
                subtitle={tr('organization-page.byod-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormPriceInput
                        label={tr('organization-page.byod-update.modal.activation-fee')}
                        clearable
                        api={form.fields.activationFee}
                        defaultCurrency={Currency.USD}
                        nullable
                    />
                    <FormPriceInput
                        label={tr('organization-page.byod-update.modal.storage-fee')}
                        clearable
                        api={form.fields.storageFee}
                        defaultCurrency={Currency.USD}
                        nullable
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.byod-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.byod-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
