import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Box, Paragraph4 } from '../../base';

interface NavigationItemBadgeProps extends TestKeyAware {
    count: number;
}

export const NavigationItemBadge: FC<NavigationItemBadgeProps> = ({ count, testKey }) => {
    return (
        <Box
            row
            justify='center'
            height={20}
            minWidth={20}
            paddingHorizontal={4}
            rounded={8}
            bg={Color.NonContextualVioletSubtle}
            data-test-key={`${testKey}-notifications`}
        >
            <Paragraph4 color={Color.NonContextualVioletOnMuted}>{count}</Paragraph4>
        </Box>
    );
};
