import { getEnumValues } from '@hofy/global';

export enum EmailTarget {
    Both = 'both',
    Work = 'work',
    Personal = 'personal',
}

export const isEmailTargetPersonal = (p: EmailTarget | null | undefined): boolean => {
    return p === EmailTarget.Personal || p === EmailTarget.Both;
};

export const isEmailTargetWork = (p: EmailTarget | null | undefined): boolean => {
    return p === EmailTarget.Work || p === EmailTarget.Both;
};

export const allEmailTargets = getEnumValues<EmailTarget>(EmailTarget);
