import React, { FC } from 'react';

import { useNetsuiteConfiguration } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';
import {
    BooleanBadge,
    Box,
    FormContainer,
    FormGridRow,
    FormSection,
    Labeled,
    LabeledText,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

export const NetsuitePage: FC = () => {
    const { netsuiteConfiguration, isLoading, isError } = useNetsuiteConfiguration();
    if (isLoading) {
        return (
            <Box fullHeight relative>
                <ComponentLoader />
            </Box>
        );
    }
    if (isError || !netsuiteConfiguration) {
        return (
            <Placeholder
                illustration={SvgIllustration.Error}
                title='Something went wrong'
                message='Please try again later'
            />
        );
    }
    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <FormContainer flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Configuration'>
                    <FormGridRow columns={2}>
                        <Labeled
                            label='Sync enabled'
                            content={<BooleanBadge value={netsuiteConfiguration.isEnabled} />}
                        />
                        <LabeledText label='Host' content={netsuiteConfiguration.host} />
                    </FormGridRow>
                </FormSection>
            </FormContainer>
        </Box>
    );
};
