import { ColorDye } from '@hofy/global';

export enum ZendeskStatus {
    Success = 'success',
    Failed = 'failed',
    Pending = 'pending',
}

export interface ZendeskStatusDto {
    syncStatus: ZendeskStatus;
    id: number | null;
    error: string | null;
}

export const zendeskStatusColors: Record<ZendeskStatus, ColorDye> = {
    [ZendeskStatus.Success]: 'green',
    [ZendeskStatus.Failed]: 'red',
    [ZendeskStatus.Pending]: 'grey',
};

export const zendeskStatusLabel: Record<ZendeskStatus, string> = {
    [ZendeskStatus.Success]: 'Synced',
    [ZendeskStatus.Failed]: 'Sync error',
    [ZendeskStatus.Pending]: 'Sync pending',
};
