import { SessionDto } from '../service/types/SessionDto';

export interface SessionState {
    session?: SessionDto;
    isInitialized: boolean;
}

export const emptySessionState: SessionState = {
    isInitialized: false,
};
