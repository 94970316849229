import React, { FC } from 'react';

import { TicketStatus, zendeskTicketStatusColors, zendeskTicketStatusLabel } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ZendeskTicketStatusChipProps {
    status: TicketStatus | null;
}

export const ZendeskTicketStatusChip: FC<ZendeskTicketStatusChipProps> = ({ status }) => {
    const title = status ? zendeskTicketStatusLabel[status] : 'Not synced';
    const color = status ? zendeskTicketStatusColors[status] : 'grey';

    return <Badge color={color} label={title} />;
};
