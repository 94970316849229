import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inspectionService, inspectionsQueryKey, UpdateInspectionDeviceCheckPayload } from '@hofy/api-admin';
import { InspectionDeviceCheckStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { InspectionDeviceCheckType } from './InspectionDeviceCheckType';

const inspectionDeviceCheckTypeActions: Record<
    InspectionDeviceCheckType,
    (uuid: UUID, payload: UpdateInspectionDeviceCheckPayload) => Promise<void>
> = {
    [InspectionDeviceCheckType.ActivationLockRemoval]:
        inspectionService.updateInspectionDeviceActivationLockRemovalStatus,
    [InspectionDeviceCheckType.UserAccountRemoval]:
        inspectionService.updateInspectionDeviceUserAccountRemovalStatus,
    [InspectionDeviceCheckType.OrgMDMRemoval]: inspectionService.updateInspectionDeviceOrgMDMRemovalStatus,
    [InspectionDeviceCheckType.PinLockRemoval]: inspectionService.updateInspectionDevicePinLockRemovalStatus,
};

export const useUpdateInspectionDeviceCheck = (
    uuid: UUID,
    type: InspectionDeviceCheckType,
    onSuccess?: () => void,
) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, UpdateInspectionDeviceCheckPayload>({
        mutationFn: payload => inspectionDeviceCheckTypeActions[type](uuid, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey] });
            onSuccess?.();
        },
    });

    return {
        updateInspectionDeviceCheck: (status: InspectionDeviceCheckStatus) => mutation.mutate({ status }),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
