export enum PurchaseOrderError {
    BillQuantityNotValid = 'bill_quantity_is_not_valid',
    InvalidPurchaseOrderStatus = 'invalid_purchase_order_status',
    PurchaseOrderNotUpdatable = 'purchase_order_not_updatable',
    InvalidPurchaseOrderPayload = 'invalid_purchase_order_payload',
    DropshipNotSupported = 'drop_shipped_purchase_orders_should_be_updated_by_updating_the_shipment',
    QuantityReceivedHigherThanExpected = 'received_quantity_is_higher_than_expected_on_po_item',
    QuantityReceivedLowerThanExpected = 'received_quantity_is_lower_than_expected_on_po_item',
    NoItemCode = 'item_code_must_be_provided_for_warehouse_deliveries',
    PurchaseOrderNotApproved = 'purchase_order_not_approved',
    InvalidPurchaseOrderCurrencyPayload = 'invalid_purchase_order_currency_payload',
}
