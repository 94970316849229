import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';

import { organizationService } from '@hofy/api-admin';
import { useNumberQueryParam } from '@hofy/router';

import { useOrganizationFilters } from './useOrganizationFilters';
import { useOrganizationsQuery } from './useOrganizationsQuery';

export const useOrganizations = () => {
    const filters = useOrganizationFilters();

    const [creditUtilisationFrom, setCreditUtilisationFrom] = useNumberQueryParam('creditUtilisationFrom');
    const [creditUtilisationTo, setCreditUtilisationTo] = useNumberQueryParam('creditUtilisationTo');

    const { organizations, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useOrganizationsQuery(filters.filterValues);

    const downloadReportMutation = useMutation({
        mutationFn: organizationService.downloadListOrganizationsReport,
    });

    const filteredOrganizations = useMemo(() => {
        return organizations.filter(
            ({ creditUtilisationPercentage }) =>
                (creditUtilisationFrom === null || creditUtilisationPercentage >= creditUtilisationFrom) &&
                (creditUtilisationTo === null || creditUtilisationPercentage <= creditUtilisationTo),
        );
    }, [creditUtilisationFrom, creditUtilisationTo, organizations]);

    return {
        organizations: filteredOrganizations,
        filters,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        downloadContractReport: downloadReportMutation.mutate,
        creditUtilisationFrom,
        setCreditUtilisationFrom,
        creditUtilisationTo,
        setCreditUtilisationTo,
    };
};
