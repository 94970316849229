import { ColorDye, getEnumValues } from '@hofy/global';

export enum ItemGrade {
    New = 'new',
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
    F = 'f',
}

export const allItemGrades = getEnumValues<ItemGrade>(ItemGrade);

export const itemGradeColor: Record<ItemGrade, ColorDye> = {
    [ItemGrade.New]: 'blue',
    [ItemGrade.A]: 'green',
    [ItemGrade.B]: 'green',
    [ItemGrade.C]: 'teal',
    [ItemGrade.D]: 'orange',
    [ItemGrade.F]: 'magenta',
};

export const goodConditionGrades = [ItemGrade.New, ItemGrade.A, ItemGrade.B, ItemGrade.C];
const badCondition = [ItemGrade.D, ItemGrade.F];

export const isInBadCondition = (grade: ItemGrade) => {
    return badCondition.includes(grade);
};
