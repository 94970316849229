import React, { FC } from 'react';

import { useDefaultSubscription } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { Skeleton } from '@hofy/ui';

import { useOrganizationDetailsQuery } from '../../../store/organizations/useOrganizationDetailsQuery';
import { CreateSubscriptionSlideout } from './CreateSubscriptionSlideout';

interface CrateSubscriptionSlideoutContainerProps {
    organizationId: UUID;
    onClose(): void;
}

export const CreateSubscriptionSlideoutContainer: FC<CrateSubscriptionSlideoutContainerProps> = ({
    organizationId,
    onClose,
}) => {
    const { data: organization, isLoading: organizationIsLoading } =
        useOrganizationDetailsQuery(organizationId);
    const { data: subscription, isLoading: subscriptionIsLoading } = useDefaultSubscription(organizationId);

    if (organizationIsLoading || !organization || subscriptionIsLoading || !subscription) {
        return <Skeleton />;
    }

    return (
        <CreateSubscriptionSlideout
            organization={organization}
            defaultSubscription={subscription}
            onClose={onClose}
        />
    );
};
