import { useQuery } from '@tanstack/react-query';

import { exchangeRatesService } from '../service/exchangeRatesService';
import { exchangeRatesCacheKey } from './cacheKey';

export const useExchangeRatesQuery = () => {
    const { isLoading, data } = useQuery({
        queryKey: [exchangeRatesCacheKey],

        queryFn: () => exchangeRatesService.getExchangeRates(),
    });

    return {
        data,
        isLoading,
    };
};
