import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { invoiceMonths } from '@hofy/api-admin';
import { nowDate } from '@hofy/helpers';

import { useSyncFileFromTwo } from '../../../../store/invoices/useSyncFileFromTwo';
import { MonthSelectorModal } from './MonthSelectorModal';

interface SyncInvoiceFilesFromTwoModalProps {
    onClose(): void;
}

export const SyncInvoiceFilesFromTwoModal: FC<SyncInvoiceFilesFromTwoModalProps> = ({ onClose }) => {
    const { syncFilesFromTwo, isLoading, isError } = useSyncFileFromTwo(onClose);

    const onSubmit = (month: DateTime) => {
        syncFilesFromTwo({
            month: month.toISODate(),
            organizationId: null,
            invoiceId: null,
        });
    };

    return (
        <MonthSelectorModal
            title='Sync files from Two'
            selectorLabel='Invoicing month'
            selectorOptions={invoiceMonths}
            selectorInitialValue={nowDate().endOf('month')}
            submitLabel='Sync from Two'
            isLoading={isLoading}
            isError={isError}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
