import React, { FC } from 'react';

import { ImageDto } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, SectionTitle3 } from '@hofy/ui';

interface VariantDisplayProps {
    name?: string;
    variant?: { sku: string | null; image: ImageDto };
    imageSize?: number;
    verticalLayout?: boolean;
    flex?: number | 'auto';
}

export const VariantDisplay: FC<VariantDisplayProps> = ({
    name,
    variant,
    imageSize = 80,
    verticalLayout = false,
    flex,
}) => {
    return (
        <Box flex={flex}>
            <Box row={!verticalLayout} padding={10}>
                <ProductImage image={variant?.image} size={imageSize} marginRight={verticalLayout ? 0 : 30} />
                <Box marginTop={verticalLayout ? 20 : 0}>
                    <SectionTitle3>{name ?? 'Select variant'}</SectionTitle3>
                    <Paragraph3 color={Color.Neutral300} paddingTop={4}>
                        {variant?.sku ?? '---'}
                    </Paragraph3>
                </Box>
            </Box>
        </Box>
    );
};
