import React, { FC } from 'react';

import { AdminRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { AdminUserSelector } from '../../../components/domain/user/AdminUserSelector';
import { useUpdateRepairAssignee } from '../../../store/repairs/useUpdateRepairAssignee';

interface RepairAdminSelectorProps {
    repairId: UUID;
    user: AdminRefDto | null;
}

export const RepairAdminSelector: FC<RepairAdminSelectorProps> = ({ repairId, user }) => {
    const { assignRepair } = useUpdateRepairAssignee(repairId);
    return <AdminUserSelector user={user} onUserChange={assignRepair} />;
};
