import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { payoutService } from '../service/payoutService';
import { AdminPayoutsFilters } from '../service/types/AdminPayoutsFilters';
import { PayoutDto } from '../service/types/PayoutDto';
import { payoutsCacheKey } from './cacheKey';

export const usePayouts = (filters: AdminPayoutsFilters, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [payoutsCacheKey, filters],
        queryFn: param =>
            payoutService.listPayouts(filters, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const payouts: PayoutDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        payouts,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
