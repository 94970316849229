import React, { ElementRef, forwardRef } from 'react';

import { Labeled } from '../../../labeled';
import {
    DebouncedInput,
    DebounceInputNullableStringProps,
    DebounceInputOnlyStringProps,
} from './DebouncedInput';

interface BaseLabeledDebouncedInputProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledDebouncedInputOnlyStringProps = Omit<DebounceInputOnlyStringProps, 'isError'> &
    BaseLabeledDebouncedInputProps;
export type LabeledDebouncedInputNullableStringProps = Omit<DebounceInputNullableStringProps, 'isError'> &
    BaseLabeledDebouncedInputProps;

export type LabeledDebouncedInputProps =
    | LabeledDebouncedInputOnlyStringProps
    | LabeledDebouncedInputNullableStringProps;

export const LabeledDebouncedInput = forwardRef<ElementRef<'input'>, LabeledDebouncedInputProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,

            value,
            onChange,
            onBlur,
            onFocus,
            type,
            nullable,
            disabled,
            clearable,
            testKey,
            autoFocus,
            placeholder,
            leftSlot,
            rightSlot,

            ...rest
        },
        ref,
    ) => {
        return (
            <Labeled
                as='label'
                label={label}
                isRequired={isRequired}
                content={
                    <DebouncedInput
                        ref={ref}
                        value={value!}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        type={type}
                        nullable={nullable}
                        placeholder={placeholder}
                        disabled={disabled}
                        clearable={clearable}
                        testKey={testKey}
                        autoFocus={autoFocus}
                        leftSlot={leftSlot}
                        rightSlot={rightSlot}
                        isError={!!errorMessage}
                    />
                }
                errorMessage={errorMessage}
                helperText={helperText}
                {...rest}
            />
        );
    },
);
