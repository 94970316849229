import { useQuery } from '@tanstack/react-query';

import { addonsCacheKey, addonsService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { AddonQuotePayload } from '../service/types/AddonQuotePayload';

export const useAddonQuoteQuery = (addonId: UUID, payload: AddonQuotePayload, enabled: boolean) => {
    const {
        data: quote,
        isPending,
        isError,
        refetch,
    } = useQuery({
        queryKey: [addonsCacheKey, 'quote', payload],
        queryFn: () => addonsService.getAddonQuote(addonId, payload),
        enabled,
    });

    return {
        quote,
        isPending,
        isError,
        refetch,
    };
};
