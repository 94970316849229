import { AssignmentsPageTab } from '@hofy/api-admin';
import { ProductCategory, StoreAndReuseStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { InspectionsTab } from '../../store/inspections/inspectionsTab';
import { InventoryTab } from '../../store/inventory/types/InventoryTab';
import { SalesOrderTab } from '../../store/invoicing/salesOrders/types/SalesOrderTab';
import { AdminInvoicingTab } from '../../store/invoicing/types/AdminInvoicingTab';
import { PurchaseOrderTab } from '../../store/purchaseOrders/types/PurchaseOrderTab';
import { RepairsPageTab } from '../../store/repairs/repairsPageTab';
import { AdminSettingsTab } from '../../store/settings/types/AdminSettingsTab';
import { SupplierTab } from '../../store/suppliers/types/SupplierTab';
import { AdminAssignmentSlideoutTabs } from '../../views/assignmentSlideout/AdminAssignmentSlideoutTabs';
import { PeopleTab } from '../../views/peoplePage/PeopleTab';
import { AdminNavLink } from './AdminNavLink';

// Assignments
export const getAssignmentsTabLink = (tab: AssignmentsPageTab) => `${AdminNavLink.Assignments}/${tab}`;
export const getAssignmentLink = (assignmentId: UUID) =>
    `${AdminNavLink.Assignments}/${AssignmentsPageTab.All}/assignment/${assignmentId}/${AdminAssignmentSlideoutTabs.Details}`;

// Users
export const getUserLink = (userId: UUID) => `${AdminNavLink.People}/${PeopleTab.Users}/${userId}/details`;
export const getAdminLink = (userId: UUID) => `${AdminNavLink.People}/${PeopleTab.Admins}/${userId}`;

// Organizations
export const getPartnerDetailsLink = (partnerId: UUID) =>
    `${AdminNavLink.Organizations}/${partnerId}/details`;

// Inspections
export const getInspectionsLink = (tab = InspectionsTab.Inspections) => `/inspections/${tab}`;

// Repairs
export const getRepairsLink = (tab = RepairsPageTab.Pending) => `${AdminNavLink.Repairs}/${tab}`;
export const getRepairsDetailsLink = (id: UUID) => `${AdminNavLink.Repairs}/pending/repair/${id}/details`;

//Contracts
export const getStoreAndReuseContractLink = (organizationId: UUID, category: ProductCategory) =>
    `${AdminNavLink.Contracts}/?storeAndReuseStatus=${StoreAndReuseStatus.Enabled}&organizations=${organizationId}&productCategory=${category}`;

// Purchase orders
export const getPurchaseOrderLink = (purchaseOrderId: UUID) =>
    `${AdminNavLink.Inventory}/${InventoryTab.PurchaseOrders}/${purchaseOrderId}/${PurchaseOrderTab.Details}`;

// Suppliers
export const getSupplierLink = (supplierId: UUID) =>
    `${AdminNavLink.Inventory}/${InventoryTab.Suppliers}/${supplierId}/${SupplierTab.Details}`;

// BYOD orders
export const getBYODOrderLink = (publicId: string) =>
    `${AdminNavLink.Inventory}/${InventoryTab.BYODOrders}?search=${publicId}`;

// Sales orders
export const getSalesOrderLink = (id: UUID | null) => {
    return `${AdminNavLink.Invoicing}/${AdminInvoicingTab.SalesOrders}/${id}/${SalesOrderTab.Details}`;
};

// Addons
export const getAddonLink = (addonId: UUID) =>
    `${AdminNavLink.Settings}/${AdminSettingsTab.Addons}/${addonId}/edit`;
