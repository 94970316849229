/* eslint-disable import/no-unused-modules */

/**
 * Parse a string to a number
 * '1' → 1
 * '1.20' → 1.2
 * '1,29' → 1.29
 * '1,200.45' → 1200.45
 * '1.200,45' → 1200.45
 * '€1.234.567,89 EUR' → 1234567.89
 */
export const parseNumber = (value: string): number | null => {
    const val = value
        .trim()
        .replace(/[^\d.,-]+/g, '') // Remove non-numeric characters except dots and commas
        .replace(/,/g, '.') // Replace commas with dots
        .replace(/\.(?=.*\.)/g, ''); // Remove dots except the last one
    const num = parseFloat(val);
    return isNaN(num) ? null : num;
};

export const numberPrecision = (value: number, precision?: number): number => {
    if (precision === undefined) {
        return value;
    }
    const factor = Math.pow(10, precision);
    return Math.round(value * factor) / factor;
};

/**
 * Format a number to a string using punctuation space (U+00A0) between thousands
 * 1 → '1'
 * 1.2 → '1.2'
 * 1000 → '1 000'
 * 1000000.45 → '1 000 000.45'
 */
export const formatNumber = (value: number, precision?: number): string => {
    const num = numberPrecision(value, precision);
    const [integer, decimal] = num.toFixed(precision).split('.');
    const parts = [];

    let i = integer.length;
    while (i > 0) {
        parts.unshift(integer.substring(Math.max(0, i - 3), i));
        i -= 3;
    }
    return parts.join('\u00A0') + (decimal ? '.' + decimal : '');
};

export const formatNumberOptional = (price: number | null, precision?: number): string => {
    if (price === null) {
        return '';
    }
    return formatNumber(price, precision);
};
