import { useMutation, useQueryClient } from '@tanstack/react-query';

import { purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncPurchaseOrdersToNetsuite = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: purchaseOrderService.syncPurchaseOrdersToNetsuite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Purchase orders synced to Netsuite',
            });
        },
    });

    return {
        isLoadingMutation: mutation.isPending,
        syncToNetsuite: mutation.mutate,
    };
};
