import React, { FC } from 'react';

import { AssignmentException, assignmentExceptionColor, useTrAssignmentException } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface AssignmentCompletionReasonProps {
    reason: AssignmentException;
}

export const AssignmentCompletionReasonBadge: FC<AssignmentCompletionReasonProps> = ({ reason }) => {
    const trItemCompletionReason = useTrAssignmentException();
    return <Badge color={assignmentExceptionColor[reason]} label={trItemCompletionReason(reason)} />;
};
