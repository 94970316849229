import { getEnumValues } from '@hofy/global';

export enum OrganizationType {
    Standard = 'standard',
    Demo = 'demo',
    Partner = 'partner',
}

export const prodOrganizationType = [OrganizationType.Standard, OrganizationType.Partner];
export const allOrganizationTypes = getEnumValues<OrganizationType>(OrganizationType);
