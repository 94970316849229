import { ColorDye, getEnumValues } from '@hofy/global';

import { ManagementContractStatus } from './ManagementContractStatus';

export enum PurchaseContractStatus {
    Pending = 'pending',
    Purchased = 'purchased',
    Cancelled = 'cancelled',
}

export const purchaseContractStatusColors: Record<PurchaseContractStatus, ColorDye> = {
    [PurchaseContractStatus.Pending]: 'orange',
    [PurchaseContractStatus.Purchased]: 'green',
    [PurchaseContractStatus.Cancelled]: 'red',
};

export const allPurchaseContractStatuses = getEnumValues<PurchaseContractStatus>(PurchaseContractStatus);

export const purchaseContractStatusToManagementContractStatus: Record<
    PurchaseContractStatus,
    ManagementContractStatus
> = {
    [PurchaseContractStatus.Pending]: ManagementContractStatus.Pending,
    [PurchaseContractStatus.Purchased]: ManagementContractStatus.Active,
    [PurchaseContractStatus.Cancelled]: ManagementContractStatus.Cancelled,
};

export const isActivePurchaseStatus = (contractStatus: PurchaseContractStatus): boolean =>
    contractStatus === PurchaseContractStatus.Purchased;
