import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { paymentService } from '../service/paymentService';
import { AdminPaymentsFilters } from '../service/types/AdminPaymentsFilters';
import { PaymentDto } from '../service/types/PaymentDto';
import { paymentsCacheKey } from './cacheKey';

export const usePayments = (filters: AdminPaymentsFilters, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [paymentsCacheKey, filters],
        queryFn: param =>
            paymentService.listPayments(filters, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const payments: PaymentDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        payments,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
