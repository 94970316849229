import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { OrganizationRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useOrganizationFilterDefintion = (
    filterName: string,
    organizations: OrganizationRefDto[],
): FilterDefinitionMulti<UUID> => {
    const nameMap = useMemo(() => keyBy(organizations, v => v.id), [organizations]);
    const toLabel = (id: UUID) => {
        return nameMap[id] ? nameMap[id].name : '';
    };
    const ids = organizations.map(a => a.id);

    return {
        type: 'multi',
        name: filterName,
        query: queryStringArrayParser<UUID>(),
        allValues: ids,
        toLabel: toLabel,
    };
};
