import React, { FC, memo } from 'react';

import {
    AnyProductCategory,
    ParentProductCategory,
    productCategoriesHierarchy,
    ProductCategory,
    visibleParentProductCategories,
} from '@hofy/api-shared';
import { SeparatedContainer } from '@hofy/common';

import { ProductCategoryFilter } from './ProductCategoryFilter';

interface ProductCategoryTreeProps {
    category: AnyProductCategory | null;
    onChange(v: AnyProductCategory | null): void;
    hierarchy?: Record<ParentProductCategory, ProductCategory[]>;
    testPrefix?: string;
    parentProductCategories?: ParentProductCategory[];
    greyedOutCategories?: ProductCategory[];
}

const ProductCategoryTreeComponent: FC<ProductCategoryTreeProps> = ({
    category,
    onChange,
    testPrefix,
    hierarchy = productCategoriesHierarchy,
    parentProductCategories = visibleParentProductCategories,
    greyedOutCategories = [],
}) => {
    return (
        <SeparatedContainer spacing={16}>
            {parentProductCategories.map(c => (
                <ProductCategoryFilter
                    testPrefix={testPrefix}
                    key={c}
                    parentCategory={c}
                    selected={category}
                    onChange={onChange}
                    categories={hierarchy[c]}
                    greyOutCategories={greyedOutCategories}
                />
            ))}
        </SeparatedContainer>
    );
};

export const ProductCategoryTree = memo(ProductCategoryTreeComponent);
