import React, { FC } from 'react';

import {
    allAssignmentStatuses,
    AssignmentDto,
    dateForAssignmentStatus,
    useTrAssignmentStatus,
} from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

interface OrderDetailsSectionProps {
    assignment: AssignmentDto;
}

export const AssignmentDetailsSection: FC<OrderDetailsSectionProps> = ({ assignment }) => {
    const trAssignmentStatus = useTrAssignmentStatus();

    return (
        <FormSection label='Assignment details'>
            <FormGridRow columns={4}>
                <LabeledText label='Assignment number' content={assignment.publicId} />
                <LabeledText label='Status' content={trAssignmentStatus(assignment.status)} />
                <LabeledText
                    label='Collection not before'
                    content={formatDate(assignment.collectionNotBefore)}
                />
            </FormGridRow>
            <Labeled
                label='History'
                content={
                    <StatusHistory
                        allStatuses={allAssignmentStatuses}
                        dateGetter={s => dateForAssignmentStatus(assignment, s)}
                        labelFormatter={trAssignmentStatus}
                    />
                }
            />
        </FormSection>
    );
};
