import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceEntriesQueryKey, invoiceEntryService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useCreditInvoiceEntry = (invoiceEntryId: UUID, onSuccess: (id: UUID) => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: invoiceEntryService.creditInvoiceEntry,
        onSuccess: (p: { id: UUID }) => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry credited',
            });
            onSuccess(p.id);
        },
    });

    const creditEntry = () => {
        mutation.mutate(invoiceEntryId);
    };

    return {
        creditEntry,
    };
};
