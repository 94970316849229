import React, { FC, ReactNode } from 'react';

import { PageHeader, Tab, Tabs } from '@hofy/ui';

import { AdminSettingsTab } from '../../store/settings/types/AdminSettingsTab';
import { useTrAdminSettingsTab } from '../../store/settings/useTrAdminSettingsTab';

interface SettingsHeaderProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
    titleContent?: ReactNode;
}

export const SettingsHeader: FC<SettingsHeaderProps> = ({ tab, tabs, onChangeTab, titleContent }) => {
    const trAdminSettingsTab = useTrAdminSettingsTab();

    return (
        <PageHeader
            title='Hofy settings'
            rightSlot={titleContent}
            tabsSlot={
                <Tabs active={tab} onChange={onChangeTab} animationId='settings-tabs'>
                    {tabs.map(tab => (
                        <Tab key={tab} id={tab} label={trAdminSettingsTab(tab)} />
                    ))}
                </Tabs>
            }
        />
    );
};
