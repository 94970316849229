import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { LabeledLink } from '@hofy/ui';

import { getAssignmentLink } from '../../components/routing/adminLinks';
import { Link } from '../../components/routing/Link';

interface AssignmentLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const AssignmentLink: FC<AssignmentLinkProps> = ({ id, children }) => {
    return <Link to={getAssignmentLink(id)}>{children}</Link>;
};

interface AssignmentDetailsLinkProps {
    assignment: PublicRefDto;
}

export const AssignmentDetailsLink: FC<AssignmentDetailsLinkProps> = ({ assignment }) => {
    return <AssignmentLink id={assignment.id}>{assignment.publicId}</AssignmentLink>;
};

interface LabeledAssignmentLinkProps {
    assignment: PublicRefDto | null;
    label?: string;
}

export const LabeledAssignmentLink: FC<LabeledAssignmentLinkProps> = ({ label = 'Item', assignment }) => {
    return (
        <LabeledLink
            label={label}
            content={assignment ? <AssignmentDetailsLink assignment={assignment} /> : '--'}
        />
    );
};
