import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface ItemIndicatorProps {
    isAssigned: boolean;
    isScanRequired: boolean;
}

export const ItemIndicator: FC<ItemIndicatorProps> = ({ isAssigned, isScanRequired }) => {
    const color = iconColor(isAssigned, isScanRequired);
    return (
        <Indicator color={color}>
            <TooltipIcon
                icon={SvgIcon.Chair}
                iconColor={color}
                body={isScanRequired || !isAssigned ? 'Not Scanned' : 'Scanned'}
                iconSize={16}
            />
        </Indicator>
    );
};

const iconColor = (isAssigned: boolean, isScanRequired: boolean) => {
    if (isScanRequired) {
        return Color.AccentYellowPastel;
    }

    if (isAssigned) {
        return Color.AccentBlue;
    }

    return Color.AccentGreyPastel;
};
