import { useMutation, useQueryClient } from '@tanstack/react-query';

import { subscriptionCacheKey, subscriptionService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from '../organizations/organizationsCacheKey';

export const useDeleteSubscription = (organizationId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (subscriptionId: UUID) =>
            subscriptionService.deleteSubscription(organizationId, subscriptionId),
        onSuccess() {
            queryClient.invalidateQueries({ queryKey: [subscriptionCacheKey, organizationId] });
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: 'Subscription removed.',
            });
            onSuccess?.();
        },
    });

    return {
        deleteSubscription: (subscriptionId: UUID) => mutation.mutate(subscriptionId),
        isLoading: mutation.isPending,
    };
};
