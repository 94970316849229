import { useQuery } from '@tanstack/react-query';

import { countryService } from '../service/countryService';
import { countryMultipliersQueryKey } from './countryMultipliersQueryKey';

export const useCountryMultipliersQuery = () => {
    const { isLoading, data } = useQuery({
        queryKey: [countryMultipliersQueryKey],

        queryFn: () => countryService.getMultipliers(),
    });

    return {
        data,
        isLoading,
    };
};
