import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, Icon, MarginBoxProps, Paragraph3, SectionTitle2, TestKeyAware } from '@hofy/ui';

interface TileButtonProps extends MarginBoxProps, TestKeyAware {
    label?: ReactNode;
    subtitle?: ReactNode;
    icon: Svg;
    type?: 'submit' | 'reset' | 'button';
    bg?: Color;
    color?: Color;
    width?: number | string;
    onClick?(): void;
    testKey?: string;
    disabled?: boolean;
}

export const TileButton: FC<TileButtonProps> = ({
    label,
    subtitle,
    width,
    icon,
    color = Color.ContentSecondary,
    testKey,
    disabled,
    ...margins
}) => {
    return (
        <TileButtonWrapper
            as='button'
            paddingVertical={25}
            {...margins}
            width={width}
            data-test-key={testKey}
            disabled={disabled}
        >
            <Icon size={20} svg={icon} color={color} />
            {label && (
                <SectionTitle2 paddingTop={12} paddingHorizontal={30} color={color}>
                    {label}
                </SectionTitle2>
            )}
            {subtitle && (
                <Paragraph3 paddingTop={16} paddingHorizontal={8} color={Color.Neutral300}>
                    {subtitle}
                </Paragraph3>
            )}
        </TileButtonWrapper>
    );
};

export const TileButtonWrapper = styled(Box)<{ disabled?: boolean }>`
    min-height: ${p => p.width}px;
    text-align: center;

    :hover {
        background-color: ${p => (p.disabled ? p.color : Color.Highlight)};
    }

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23B7B6BB' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
`;
