import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, BoxProps } from '@hofy/ui';

export const SurveyResultsSectionCard: FC<BoxProps> = ({
    bg = Color.BackgroundDefault,
    gap = 16,
    padding = 16,
    borderColor = Color.NonContextualBorderDivider,
    children,
    border = false,
    ...boxProps
}) => {
    return (
        <Box
            bg={bg}
            gap={gap}
            padding={padding}
            border={border}
            borderColor={borderColor}
            rounded={8}
            {...boxProps}
        >
            {children}
        </Box>
    );
};
