import React, { FC, ReactNode } from 'react';

import { Country } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, CountryFlag, Paragraph3, Paragraph4 } from '@hofy/ui';

interface ItemLocationCardProps {
    icon: ReactNode;
    label?: string;
    name: string;
    country?: Country;
}

export const ItemLocationCard: FC<ItemLocationCardProps> = ({ icon, label, name, country }) => {
    return (
        <Box row gap={12} overflow='hidden' data-test-key='item-location-card'>
            {icon}
            <Box column flex='auto'>
                {label && <Paragraph4 data-test-key='label'>{label}</Paragraph4>}
                <Box row gap={8}>
                    {country && <CountryFlag country={country} size={12} />}
                    <Paragraph3
                        textNoWrap
                        overflow='hidden'
                        ellipsis
                        color={Color.ContentPrimary}
                        data-test-key='name'
                    >
                        {name}
                    </Paragraph3>
                </Box>
            </Box>
        </Box>
    );
};
