import React, { FC } from 'react';

import { ContractDetailsWithManagement } from '@hofy/api-admin';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { LabeledOrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { MainItemSection } from './components/MainItemSection';
import { ContractProductDetails } from './ContractDetailsTab';

interface ContractDetailsTabProps {
    contract: ContractDetailsWithManagement;
}

export const ManagementContractDetailsTab: FC<ContractDetailsTabProps> = ({ contract }) => {
    const { id, publicId, organization, product, variant } = contract;

    return (
        <Box
            overflow='auto'
            fullHeight
            flex='auto'
            row
            alignItems='flex-start'
            gap={40}
            paddingHorizontal={40}
        >
            <Box flex={1} marginVertical={30} gap={30} column>
                <FormSection label='Details'>
                    <FormGridRow columns={4}>
                        <LabeledText label='Management id' content={id} />
                        <LabeledText label='Management public id' content={publicId} />
                        <LabeledOrganizationLink organization={organization} />
                    </FormGridRow>
                    <ContractProductDetails product={product} variant={variant} />
                </FormSection>
                <AuditSection model={contract} />
            </Box>
            <Box flex={1} marginVertical={30} gap={30} column>
                <MainItemSection item={contract.mainItem} />
            </Box>
        </Box>
    );
};
