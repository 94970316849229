export const itemFilterTypeI18n = {
    'item-filter-type.redistributable': 'Redistributable',
    'item-filter-type.not-redistributable': 'Not Redistributable',
    'item-filter-type.is-loaner': 'Loaner Item',
    'item-filter-type.need-repairs': 'Needs Repairs',
    'item-filter-type.no-warehouse': 'Not in warehouse',
    'item-filter-type.available-for-store-and-reuse': 'Available for reuse',
    'item-filter-type.with-damaged-parts': 'With damaged parts',
    'item-filter-type.with-missing-parts': 'With missing parts',
    'item-filter-type.is-missing-original-packaging': 'Missing original packaging',
    'item-filter-type.is-device-locked': 'Device locked',
};
