export enum CollectionRequestDisplayStatus {
    Active = 'active',
    EmailSent = 'email_sent',
    TextMessageSent = 'text_message_sent',
    TextMessageDelivered = 'text_message_delivered',
    Viewed = 'viewed',
    Escalated = 'escalated',
    DeEscalated = 'de_escalated',
    Deferred = 'deferred',
    DeDeferred = 'de_deferred',
    Completed = 'completed',
    Cancelled = 'cancelled',
}
