import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentConfigurationCacheKey,
    AssignmentConfigurationDto,
    assignmentsCacheKey,
    assignmentService,
    defaultAssignmentConfigurationPayload,
    UpdateAssignmentConfigurationPayload,
    useAssignmentConfigurationQuery,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { UseForm, useForm, useToast } from '@hofy/ui';

export interface UpdateAssignmentConfigurationState {
    form: UseForm<UpdateAssignmentConfigurationPayload>;
    isLoadingMutation: boolean;
    assignmentId: UUID;
    assignmentConfiguration?: AssignmentConfigurationDto;
    assignmentConfigurationIsLoading: boolean;
}

export const useUpdateAssignmentConfiguration: (
    id: UUID,
    basePath: string,
) => UpdateAssignmentConfigurationState = (assignmentId: UUID, basePath: string) => {
    const { showToast } = useToast();
    const { goBack } = useGoBack();

    const { data: assignmentConfiguration, isLoading: assignmentConfigurationIsLoading } =
        useAssignmentConfigurationQuery(assignmentId);

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (v: UpdateAssignmentConfigurationPayload) =>
            assignmentService.updateAssignmentConfiguration(assignmentId, v),
        onSuccess: () => {
            goBack(`${basePath}/${assignmentId}`);
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [assignmentConfigurationCacheKey] });
            showToast({
                type: 'positive',
                message: 'Configuration modified',
            });
        },
    });

    const form = useForm<UpdateAssignmentConfigurationPayload>({
        initial: { ...defaultAssignmentConfigurationPayload, ...assignmentConfiguration },
        onSubmit: mutation.mutate,
        initialDeps: [assignmentConfiguration],
    });

    return {
        form,
        assignmentId,
        assignmentConfiguration,
        assignmentConfigurationIsLoading,
        isLoadingMutation: mutation.isPending,
    };
};
