import { Country, Decimal } from '@hofy/global';

export interface NewCountryMultiplierFormData {
    country?: Country;
    multiplier: Decimal;
}

export const emptyNewCountryMultiplierFormData: NewCountryMultiplierFormData = {
    multiplier: '2.0',
    country: undefined,
};
