import { useQuery } from '@tanstack/react-query';

import { notesService } from '@hofy/api-admin';
import { NoteSource } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { notesQueryKey } from './notesQueryKey';

export const useNotesQuery = (source: NoteSource, entityUUID: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [notesQueryKey, source, entityUUID],
        queryFn: () => notesService.getNotes(source, entityUUID),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
