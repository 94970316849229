import React, { FC } from 'react';

import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, OuterBoxProps, Tooltip } from '@hofy/ui';

interface CollectionNotBeforeChipProps extends OuterBoxProps {
    date: DateString;
}

export const CollectionNotBeforeChip: FC<CollectionNotBeforeChipProps> = ({ date, ...rest }) => {
    return (
        <Tooltip body={date && formatDate(date)}>
            <Badge color='magenta' label='Not before' {...rest} />
        </Tooltip>
    );
};
