import React, { FC } from 'react';

import { formatVariant, ProductDto, useTrProductCategory, VariantDto } from '@hofy/api-shared';
import { useTrCountry } from '@hofy/i18n';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { ProductDetailsLink } from '../../productsPage/ProductDetailsLink';

interface ProductDetailsSectionProps {
    product: ProductDto;
    variant: VariantDto;
}

export const ProductDetailsSection: FC<ProductDetailsSectionProps> = ({ product, variant }) => {
    const trProductCategory = useTrProductCategory();
    const trCountry = useTrCountry();

    return (
        <FormSection label='Product details'>
            <ProductImage image={variant.image?.url} size={250} category={product.category} />
            <FormGridRow columns={4}>
                <Labeled
                    label='Product'
                    content={
                        <ProductItem
                            image={variant.image}
                            label={
                                <ProductDetailsLink id={product.id}>
                                    <Paragraph3 color={Color.ContentPrimary}>{product.name}</Paragraph3>
                                </ProductDetailsLink>
                            }
                            subLabel={formatVariant(variant)}
                        />
                    }
                />
                <LabeledText label='Name' content={product.name} />
                <LabeledText label='Brand' content={product.brand} />
                <LabeledText label='Category' content={trProductCategory(product.category)} />
            </FormGridRow>
            <LabeledText
                label='Available in countries'
                content={variant.availability.map(c => trCountry(c)).join(', ')}
                fullWidth
            />
        </FormSection>
    );
};
