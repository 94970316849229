import React, { FC, useCallback, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { allAssignmentPageTabs, AssignmentsPageTab } from '@hofy/api-admin';
import { ShipmentType } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { EnumRoute } from '@hofy/router';

import { getAssignmentsTabLink } from '../../components/routing/adminLinks';
import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { useAssignmentsSelection } from '../../store/assignments/useAssignmentsSelection';
import { AssignmentSlideoutRouter } from '../assignmentSlideout/AssignmentSlideoutRouter';
import { CreatePurchaseOrderForShipmentSlideoutRouter } from '../inventoryPage/purchaseOrders/purchaseOrderCreateEditSlideout/CreatePurchaseOrderForShipmentSlideoutRouter';
import { ItemSlideoutRouter } from '../itemsPage/ItemSlideoutRouter';
import { CreateRepairForItemSlideoutRouter } from '../repairsPage/slideouts/createRepair/CreateRepairForItemSlideoutRouter';
import { RepairSlideoutRouter } from '../repairsPage/slideouts/viewRepair/RepairSlideoutRouter';
import { ShipmentSlideoutRouter } from '../shipmentSlideout/ShipmentSlideoutRouter';
import { AssignmentsPage } from './AssignmentsPage';
import { AssignmentsPageTabContextProvider } from './AssignmentsTabContext';
import { InPersonTransferSlideout } from './components/InPersonTransferForm';
import { CreateShipmentSlideout } from './createShipmentSlideout/CreateShipmentSlideout';
import { useAssignmentsLinks } from './useAssignmentsLinks';

export const AssignmentsRouter: FC = () => {
    const { location } = useHistory();

    return (
        <Switch>
            <AssignmentsRoute path={`${AdminNavLink.Assignments}/:tab(${allAssignmentPageTabs.join('|')})`}>
                {({ tab }) => (
                    <AssignmentsPageTabContextProvider tab={tab}>
                        <AssignmentsTabRouter tab={tab} />
                    </AssignmentsPageTabContextProvider>
                )}
            </AssignmentsRoute>
            <Redirect
                to={`${AdminNavLink.Assignments}/delivery${location.search}`}
                from={`${AdminNavLink.Assignments}`}
            />
        </Switch>
    );
};

interface AssignmentsTabRouterProps {
    tab: AssignmentsPageTab;
}

const AssignmentsTabRouter: FC<AssignmentsTabRouterProps> = ({ tab }) => {
    const history = useHistory();
    const { location } = history;
    const { goBack } = useGoBack();

    const [userContext, setUserContext] = useState<{
        userId: UUID;
        organizationId: UUID;
        country: Country;
    }>();

    const { selected, toggleSelected, userSelection, clearSelection } = useAssignmentsSelection();

    const { getCreateNewShipmentLink, getInPersonTransferLink, getOpenShipmentLink } = useAssignmentsLinks();

    const handleCreateShipment = useCallback(
        (userId: UUID, organizationId: UUID, country: Country, shipmentType: ShipmentType): void => {
            setUserContext({
                userId,
                organizationId,
                country,
            });
            history.push(getCreateNewShipmentLink(shipmentType));
        },
        [getCreateNewShipmentLink],
    );

    const handleInPersonTransfer = useCallback(
        (userId: UUID, organizationId: UUID, country: Country): void => {
            setUserContext({
                userId,
                organizationId,
                country: country,
            });
            history.push(getInPersonTransferLink());
        },
        [getInPersonTransferLink],
    );

    const handleCloseSlideout = useCallback((): void => {
        history.push(`${AdminNavLink.Assignments}/${tab}`);
    }, [tab]);

    return (
        <>
            <AssignmentsPage
                activeTab={tab}
                onTabChanged={newTab => {
                    clearSelection();
                    setUserContext(undefined);
                    history.push(`${getAssignmentsTabLink(newTab)}${history.location.search}`);
                }}
                selectedAssignments={selected}
                onToggleSelectedAssignments={toggleSelected}
                onCreateShipment={handleCreateShipment}
                onInPersonTransfer={handleInPersonTransfer}
            />
            <NewShipmentRoute path={`${AdminNavLink.Assignments}/:type/new-shipment/:shipmentType`} exact>
                {({ shipmentType }) =>
                    userContext && userSelection(userContext.userId).length ? (
                        <CreateShipmentSlideout
                            userId={userContext.userId}
                            organizationId={userContext.organizationId}
                            assignments={userSelection(userContext.userId)}
                            onSuccess={id => {
                                clearSelection(userContext.userId);
                                setUserContext(undefined);
                                history.replace(getOpenShipmentLink(id));
                            }}
                            onClose={() => goBack(`${AdminNavLink.Assignments}/${tab}`)}
                            shipmentType={shipmentType}
                        />
                    ) : (
                        <Redirect to={`${AdminNavLink.Assignments}/${tab}${location.search}`} />
                    )
                }
            </NewShipmentRoute>
            <Route path={`${AdminNavLink.Assignments}/${tab}/in-person-transfer`} exact>
                {userContext ? (
                    <InPersonTransferSlideout
                        userId={userContext.userId}
                        organizationId={userContext.organizationId}
                        assignments={userSelection(userContext.userId)}
                        onSuccess={() => {
                            clearSelection(userContext.userId);
                            setUserContext(undefined);
                            goBack(`${AdminNavLink.Assignments}/${tab}`);
                        }}
                        onClose={() => goBack(`${AdminNavLink.Assignments}/${tab}`)}
                    />
                ) : (
                    <Redirect to={`${AdminNavLink.Assignments}/${tab}${location.search}`} />
                )}
            </Route>
            <CreatePurchaseOrderForShipmentSlideoutRouter
                base={`${AdminNavLink.Assignments}/${tab}`}
                onClose={() => goBack()}
            />
            <AssignmentSlideoutRouter
                base={`${AdminNavLink.Assignments}/${tab}/assignment`}
                shipmentsBase={`${AdminNavLink.Assignments}/${tab}/shipment`}
                onCloseBase={`${AdminNavLink.Assignments}/${tab}`}
            />
            <ShipmentSlideoutRouter
                base={`${AdminNavLink.Assignments}/${tab}/shipment`}
                assignmentsBase={`${AdminNavLink.Assignments}/${tab}/assignment`}
                onCloseBase={`${AdminNavLink.Assignments}/${tab}`}
            />
            <CreateRepairForItemSlideoutRouter
                base={`${AdminNavLink.Assignments}/${tab}/create-repair`}
                onClose={handleCloseSlideout}
            />
            <RepairSlideoutRouter
                base={`${AdminNavLink.Assignments}/${tab}/repair`}
                onClose={handleCloseSlideout}
            />
            <ItemSlideoutRouter base={`${AdminNavLink.Assignments}/${tab}/item`} />
        </>
    );
};

const AssignmentsRoute = EnumRoute<AssignmentsPageTab>('tab', AssignmentsPageTab, Route);
const NewShipmentRoute = EnumRoute<ShipmentType>('shipmentType', ShipmentType, Route);
