import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    BackorderShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { useForm } from '@hofy/ui';

export const useBackorderShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, BackorderShipmentPayload>({
        mutationFn: payload => shipmentService.backorderShipment(shipment.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });

    const form = useForm<BackorderShipmentPayload>({
        initial: {
            estimatedAvailability: shipment.estimatedAvailability,
            sendNotification: false,
            deliveryNotes: shipment.deliveryNotes,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
