import React, { Children, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color, NumberValues } from '@hofy/theme';
import { Box, BoxProps } from '@hofy/ui';

interface SelectableListProps extends BoxProps {
    itemSpacing?: NumberValues;
    itemLeftSpacing?: NumberValues;
    itemRightSpacing?: NumberValues;
    children?: ReactNode;
    selectable?: boolean;
}

export const SelectableList = forwardRef<HTMLDivElement, SelectableListProps>(
    (
        {
            children,
            itemSpacing,
            itemLeftSpacing = itemSpacing,
            itemRightSpacing = itemSpacing,
            selectable = true,
            ...props
        },
        ref,
    ) => {
        const childArray = Children.toArray(children);
        return (
            <Box ref={ref} {...props}>
                {childArray.map((t: Exclude<ReactNode, boolean | null | undefined>, index: number) => (
                    <RowWrapper
                        pointer={selectable}
                        paddingLeft={itemLeftSpacing}
                        paddingRight={itemRightSpacing}
                        key={index}
                        selectable={selectable}
                    >
                        <RowContent>{t}</RowContent>
                    </RowWrapper>
                ))}
            </Box>
        );
    },
);

const RowContent = styled(Box)``;

export const RowWrapper = styled(Box)<{ selectable?: boolean }>`
    ${p =>
        p.selectable &&
        css`
            :hover {
                background: ${Color.Highlight};
                z-index: 1;
                ${RowContent} {
                    border-bottom-color: transparent;
                }
            }
        `}
    :not(:last-child) {
        ${RowContent} {
            border-bottom: 1px solid ${Color.Neutral200};
        }
    }
`;
