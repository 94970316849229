import React, { FC } from 'react';

import { ProductDetailsTab } from '../../../store/products/types/ProductDetailsTab';
import { useCreateProduct } from '../../../store/products/useCreateProduct';
import { ProductDetails } from '../components/ProductDetails';

interface NewProductOverlayProps {
    tab: ProductDetailsTab;
    onChangeTab(v: ProductDetailsTab): void;
}

export const NewProductOverlay: FC<NewProductOverlayProps> = ({ tab, onChangeTab }) => {
    const { form, isLoadingMutation, lockVariantPrices, setLockVariantPrices } = useCreateProduct();

    return (
        <ProductDetails
            tab={tab}
            onTabChange={onChangeTab}
            form={form}
            product={null}
            title={form.values.name || 'New product'}
            isLoading={isLoadingMutation}
            lockVariantPrices={lockVariantPrices}
            onLockVariantPricesChange={setLockVariantPrices}
        />
    );
};
