import React, { FC, memo } from 'react';

import { FixedAssetValuationFilters, useFixedAssetValuationListQuery } from '@hofy/api-admin';
import { useTrProductCategory } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { InfinityScrollTable, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { useTrFixedAssetType } from '../../../../store/fixedAssets/useTrFixedAssetType';
import { ItemDetailsLink } from '../../../itemsPage/ItemDetailsLink';
import { UserDetailsLink } from '../../../peoplePage/users/UserLink';

interface FixedAssetValuationTableProps {
    filters: FixedAssetValuationFilters;
}

const FixedAssetValuationTableComponent: FC<FixedAssetValuationTableProps> = ({ filters }) => {
    const { fixedAssetValuations, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } =
        useFixedAssetValuationListQuery(filters);
    const { formatPrice } = usePrice();
    const trProductCategory = useTrProductCategory();
    const trFixedAssetType = useTrFixedAssetType();

    return (
        <InfinityScrollTable
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No fixed asset valuations'
                    message='No fixed asset valuations for selected criteria'
                />
            }
            flex='auto'
            data={fixedAssetValuations}
            toKey={fixedAssetValuation => fixedAssetValuation.itemDepreciationId}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
            columns={[
                {
                    id: 'name',
                    header: 'Name',
                    width: 220,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => fixedAssetValuation.productName,
                },
                {
                    id: 'category',
                    header: 'Category',
                    width: 80,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => trProductCategory(fixedAssetValuation.productCategory),
                },
                {
                    id: 'fixedAssetType',
                    header: 'Fixed asset type',
                    width: 80,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => trFixedAssetType(fixedAssetValuation.fixedAssetType),
                },
                {
                    id: 'item',
                    header: 'Item',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => (
                        <TextCell>
                            <ItemDetailsLink item={fixedAssetValuation.item} />
                        </TextCell>
                    ),
                },
                {
                    id: 'assetLifetimeMonths',
                    header: 'Lifetime (months)',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => fixedAssetValuation.assetLifetimeMonths,
                },
                {
                    id: 'assetRemainingLifetimeMonths',
                    header: 'Remaining lifetime (months)',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => fixedAssetValuation.assetRemainingLifetimeMonths,
                },
                {
                    id: 'assetValueAtActivation',
                    header: 'Value at activation',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => formatPrice(fixedAssetValuation.assetValueAtActivation),
                },
                {
                    id: 'depreciationStartOn',
                    header: 'First depreciation on',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => formatDate(fixedAssetValuation.depreciationStartOn),
                },
                {
                    id: 'lastDepreciatedOn',
                    header: 'Last depreciation on',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => formatDate(fixedAssetValuation.lastDepreciatedOn),
                },
                {
                    id: 'assignedUser',
                    header: 'Assigned user',
                    width: 100,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => (
                        <TextCell>
                            <UserDetailsLink user={fixedAssetValuation.user} />
                        </TextCell>
                    ),
                },
                {
                    id: 'hofySubsidiary',
                    header: 'Hofy subsidiary',
                    flexGrow: 1,
                    width: 80,
                    renderer: fixedAssetValuation => fixedAssetValuation.hofySubsidiary.name,
                },
                {
                    id: 'itemCost',
                    header: 'Cost',
                    width: 80,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => formatPrice(fixedAssetValuation.itemCost),
                },
                {
                    id: 'netBookValue',
                    header: 'Net book value',
                    width: 80,
                    flexGrow: 1,
                    renderer: fixedAssetValuation => formatPrice(fixedAssetValuation.netBookValue),
                },
            ]}
        />
    );
};

export const FixedAssetValuationTable = memo(FixedAssetValuationTableComponent);
