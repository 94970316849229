import { AnimatePresence } from 'framer-motion';
import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useBoolean } from 'react-use';

import { useRWD } from '../../contexts/rwd';
import { Box } from '../base';
import { HiddenScroll } from '../scroll';
import { NavigationMobileBar } from './components/mobile/NavigationMobileBar';
import { NavigationMobileContainer } from './components/mobile/NavigationMobileContainer';
import { NavigationContainer } from './components/NavigationContainer';
import { NavigationHeader } from './components/NavigationHeader';
import { NavigationContextProvider } from './NavigationContext';

export interface NavigationProps {
    children?: ReactNode;
    isCollapsed?: boolean;
    collapsible?: boolean;
    logoLink?: string;
    topRightSlot?: ReactNode;
    bottomSlot?: ReactNode;
    env?: string;
    envSlot?: ReactNode;
    identitySlot?: ReactNode;
}

export const Navigation: FC<NavigationProps> = ({
    children,
    collapsible = false,
    isCollapsed = false,
    logoLink,
    topRightSlot,
    bottomSlot,
    identitySlot,
    env,
    envSlot,
}) => {
    const [isMobileMenuOpen, toggleMobileMenu] = useBoolean(false);
    const { key } = useLocation();
    const { isMobile } = useRWD();

    useEffect(() => {
        toggleMobileMenu(false);
    }, [key]);

    const header = (
        <NavigationHeader
            collapsible={collapsible}
            logoLink={logoLink}
            rightSlot={topRightSlot}
            env={env}
            envSlot={envSlot}
            identitySlot={identitySlot}
        />
    );

    const items = (
        <HiddenScroll flex='auto' paddingRight={16} marginRight={-16}>
            <Box column gap={8} paddingTop={16}>
                {children}
            </Box>
        </HiddenScroll>
    );

    if (isMobile) {
        return (
            <Box>
                <NavigationContextProvider startAsCollapsed={false}>
                    <NavigationMobileBar onToggle={toggleMobileMenu}>{header}</NavigationMobileBar>
                    <AnimatePresence>
                        {isMobileMenuOpen && (
                            <NavigationMobileContainer>
                                {items}
                                {bottomSlot}
                            </NavigationMobileContainer>
                        )}
                    </AnimatePresence>
                </NavigationContextProvider>
            </Box>
        );
    }

    return (
        <NavigationContextProvider startAsCollapsed={isCollapsed}>
            <NavigationContainer>
                {header}
                {items}
                {bottomSlot}
            </NavigationContainer>
        </NavigationContextProvider>
    );
};
