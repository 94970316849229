import React, { FC } from 'react';

import { hofySubsidiariesService } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';

interface RowMenuProps {
    id: UUID;
}

export const RowMenu: FC<RowMenuProps> = ({ id }) => {
    const { hasPermission } = usePermission();

    const openSvixUrl = () => {
        hofySubsidiariesService.getTwoSvixUrl(id).then(v => window.open(v.url, '_blank'));
    };

    return (
        <MoreMenu
            items={[
                {
                    action: openSvixUrl,
                    label: 'Open Two Svix portal',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
            ]}
        />
    );
};
