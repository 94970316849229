import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ColorDye } from '@hofy/global';
import { Color, Palette } from '@hofy/theme';

import { activeScaleCss, innerFocusOutlineCss } from '../../../helpers/Css';
import { TestKeyAware } from '../../../types';
import { Dot } from '../../badge';
import { Box, Font, Paragraph3, Pressable } from '../../base';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { NavigationColors } from '../NavigationColors';
import { useNavigationContext } from '../NavigationContext';
import { opacityTransition, opacityVariants } from './fadeVariants';
import { NavigationItemBadge } from './NavigationItemBadge';

export interface NavigationItemProps extends TestKeyAware {
    label: string;
    icon?: Svg;
    dotColor?: Color | ColorDye;
    link?: string;
    onClick?(): void;
    notifications?: number;
    isActive?: boolean;
    isVisible?: boolean;
    beta?: boolean;
    isNew?: boolean;
}

export const NavigationItem: FC<NavigationItemProps> = ({
    label,
    icon,
    dotColor,
    link,
    onClick,
    notifications,
    isActive,
    isVisible = true,
    beta,
    testKey,
    isNew,
}) => {
    const { collapsed } = useNavigationContext();

    const { pathname } = useLocation();
    const isActivePath = (v: string) => pathname.startsWith(v);

    const isLinkActive = link ? isActivePath(link) : false;
    const isActiveItem = isActive ?? isLinkActive;
    const showTooltip = collapsed && !!label;

    if (!isVisible) {
        return null;
    }

    const inner = (
        <ContainerBox
            rounded={8}
            paddingHorizontal={16}
            paddingVertical={8}
            isActive={isActiveItem}
            height={38}
            column
            alignContent='stretch'
            justify='center'
            pointer
            as={motion.div}
            initial={false}
            variants={opacityVariants}
            transition={opacityTransition}
            data-test-key={testKey}
        >
            <Box row gap={8} justify='center' relative>
                {icon && (
                    <Icon color={Color.InteractionInvertedNormal} size={collapsed ? 18 : 16} svg={icon} />
                )}
                {dotColor && (
                    <Box rect={16} centered>
                        <Dot color={dotColor} size={10} inverted />
                    </Box>
                )}
                {!collapsed && (
                    <Paragraph3
                        flex={1}
                        color={Color.InteractionInvertedNormal}
                        bold
                        textNoWrap
                        ellipsis
                        overflow='hidden'
                    >
                        {label}
                    </Paragraph3>
                )}
                {(isNew || beta) && !collapsed && (
                    <Beta
                        color={Color.ContentInvertedPrimary}
                        size={8}
                        marginLeft={8}
                        paddingVertical={2}
                        paddingHorizontal={6}
                    >
                        {isNew ? 'New' : 'Beta'}
                    </Beta>
                )}
                {!!notifications &&
                    (collapsed ? (
                        <Dot color={Color.InteractionInvertedNormal} topRight />
                    ) : (
                        <NavigationItemBadge count={notifications} testKey={testKey} />
                    ))}
            </Box>
        </ContainerBox>
    );

    const content = link ? (
        <NavLink to={link}>{inner}</NavLink>
    ) : (
        <Pressable onClick={onClick} innerFocus activeEffect>
            {inner}
        </Pressable>
    );

    if (showTooltip) {
        return (
            <Tooltip placement='right' body={label} asChild>
                {content}
            </Tooltip>
        );
    }

    return content;
};

const ContainerBox = styled(Box)<{ isActive: boolean }>`
    background-color: ${p => (p.isActive ? `${NavigationColors.ActiveItem} !important` : 'transparent')};
    &:hover {
        background-color: ${NavigationColors.Hover};
    }
`;

export const Beta = styled(Font)`
    border: 1px solid ${Palette.violet['-1']};
    border-radius: 4px;
    text-transform: uppercase;
`;

const NavLink = styled(Link)`
    ${innerFocusOutlineCss}
    ${activeScaleCss}
`;
