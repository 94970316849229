import { AddressDto } from './AddressDto';
import { belongsToHofy, belongsToOrganization, Ownership } from './Ownership';
import { PublicRefDto } from './PublicRefDto';

export interface WarehouseDto extends PublicRefDto {
    ownership: Ownership;
    name: string;
    address: AddressDto | null;
}

export const isHofyWarehouse = <T extends WarehouseDto>(warehouse?: T | null): warehouse is T => {
    return belongsToHofy(warehouse?.ownership);
};

export const isOrganizationWarehouse = <T extends WarehouseDto>(warehouse?: T | null): warehouse is T => {
    return belongsToOrganization(warehouse?.ownership);
};
