import { Children, Fragment, isValidElement, JSXElementConstructor, ReactElement, ReactNode } from 'react';

export const childrenProps = <T>(children: ReactNode, type: JSXElementConstructor<T>): T[] => {
    let array = Children.toArray(children);
    // check if children are wrapped in a fragment
    if (array.length === 1 && isValidElement(array[0]) && array[0].type === Fragment) {
        array = Children.toArray(array[0].props.children);
    }
    return array
        .filter(child => isValidElement(child) && child.type === type)
        .map(child => (child as ReactElement<T>).props);
};

export const renderTextNode = <T extends ReactNode>(
    text: T,
    decorator: (text: string) => ReactNode,
): ReactNode => {
    if (typeof text === 'string' || typeof text === 'number') {
        return decorator(text.toString());
    }
    return text;
};
