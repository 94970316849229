import React, { FC } from 'react';

import { CountryLabelView } from '@hofy/core';
import { Country } from '@hofy/global';
import { FilterApiMulti, FilterHeaderCell, Paragraph3 } from '@hofy/ui';

interface FilterCountryHeaderCellProps {
    label: string;
    filter: FilterApiMulti<Country>;
    disabled?: boolean;
}

export const FilterCountryHeaderCell: FC<FilterCountryHeaderCellProps> = ({ label, filter, disabled }) => {
    return (
        <FilterHeaderCell
            label={label}
            filter={filter}
            toLabel={country => (
                <Paragraph3>
                    <CountryLabelView country={country} textNoWrap />
                </Paragraph3>
            )}
            contentWidth={300}
            disabled={disabled}
            searchable
        />
    );
};
