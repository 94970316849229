import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Spinner } from '@hofy/ui';

import { useSyncJournalToNetsuite } from '../../../store/journals/useSyncJournalToAccounting';

interface JournalRowMenuProps {
    journalId: UUID;
}

export const JournalRowMenu: FC<JournalRowMenuProps> = ({ journalId }) => {
    const { hasPermission } = usePermission();

    const { syncToNetsuite, isLoading } = useSyncJournalToNetsuite(journalId);

    if (isLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: syncToNetsuite,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingTransactionalSync),
                },
            ]}
        />
    );
};
