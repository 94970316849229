export const hrisErrorI18n = {
    'api-error.hris-auto-import-not-enabled.title': 'Organization has no auto import enabled',
    'api-error.hris-auto-import-not-enabled.message': 'Cannot perform this operation',
    'api-error.no-hris-key.title': 'Organization has no hris key',
    'api-error.no-hris-key.message': 'Cannot perform this operation',
    'api-error.email-required.title': 'User requires one of work or personal email to be created',
    'api-error.email-required.message': 'Cannot perform this operation',
    'api-error.invalid-i-ds.title': 'Invalid ids requested',
    'api-error.invalid-i-ds.message': 'Cannot perform this operation',
    'api-error.invalid-it-policy-id.title': 'Invalid IT policy ID requested',
    'api-error.invalid-it-policy-id.message': 'Cannot perform this operation',
};
