import { ColorDye, getEnumValues } from '@hofy/global';

export enum SupplierStatus {
    Active = 'active',
    Suspended = 'suspended',
}

export const supplierStatusColor: Record<SupplierStatus, ColorDye> = {
    [SupplierStatus.Active]: 'green',
    [SupplierStatus.Suspended]: 'grey',
};
export const allSupplierStatuses = getEnumValues<SupplierStatus>(SupplierStatus);
