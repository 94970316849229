import React, { FC } from 'react';

import { PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { Box, FormGridRow, LabeledText } from '@hofy/ui';

interface PurchaseOrderDetailsProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderDetails: FC<PurchaseOrderDetailsProps> = ({ purchaseOrder }) => {
    return (
        <Box>
            <FormGridRow columns={4} marginVertical={20}>
                {purchaseOrder.toWarehouse && (
                    <LabeledText label='Warehouse' content={purchaseOrder.toWarehouse.name} />
                )}
                <LabeledText
                    label='Supplier reference'
                    content={purchaseOrder.purchaseOrderSupplierReference}
                />
                <LabeledText
                    label='Est. receipt date'
                    content={formatDate(purchaseOrder.estimatedReceiveOn)}
                />
                <LabeledText label='Notes' content={purchaseOrder.notes} />
            </FormGridRow>
        </Box>
    );
};
