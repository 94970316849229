import React, { FC, ReactNode } from 'react';

import { VisualType } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, FeaturedIcon, Paragraph3, SvgIcon } from '@hofy/ui';

export enum SurveyResultsSectionCardChipStatus {
    Neutral,
    Warning,
    Negative,
}

interface SurveyResultsSectionCardChipProps {
    title: string;
    status: SurveyResultsSectionCardChipStatus;
    children?: ReactNode;
}

export const SurveyResultsSectionCardChip: FC<SurveyResultsSectionCardChipProps> = ({
    title,
    status,
    children,
}) => {
    return (
        <Box column gap={8} data-test-key='survey-result-chip'>
            <CardChipTitle text={title} status={status} />
            {children}
        </Box>
    );
};

interface CardChipTitleProps {
    text: string;
    status: SurveyResultsSectionCardChipStatus;
}

const CardChipTitle: FC<CardChipTitleProps> = ({ text, status }) => {
    const titleColorMap: Record<SurveyResultsSectionCardChipStatus, Color> = {
        [SurveyResultsSectionCardChipStatus.Neutral]: Color.ContentSecondary,
        [SurveyResultsSectionCardChipStatus.Warning]: Color.ContentWarning,
        [SurveyResultsSectionCardChipStatus.Negative]: Color.ContentNegative,
    };

    const iconAppearanceMap: Record<SurveyResultsSectionCardChipStatus, VisualType> = {
        [SurveyResultsSectionCardChipStatus.Neutral]: 'informative',
        [SurveyResultsSectionCardChipStatus.Warning]: 'warning',
        [SurveyResultsSectionCardChipStatus.Negative]: 'negative',
    };

    const getIcon = () => {
        if (status === SurveyResultsSectionCardChipStatus.Neutral) {
            return null;
        }

        return <FeaturedIcon type={iconAppearanceMap[status]} size={24} icon={SvgIcon.AlertCircle} />;
    };

    return (
        <Box row justify='space-between' gap={8}>
            <Paragraph3
                color={titleColorMap[status]}
                data-test-key='label'
                data-type={iconAppearanceMap[status]}
            >
                {text}
            </Paragraph3>
            {getIcon()}
        </Box>
    );
};
