import React, { FC, useMemo } from 'react';

import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';

interface RepairFilterBlockFilterProps {
    hasUnassignedUser: boolean;
    onHasUnassignedUserChange(): void;
}

export const RepairFilterBlockFilter: FC<RepairFilterBlockFilterProps> = ({
    hasUnassignedUser,
    onHasUnassignedUserChange,
}) => {
    const blockFilterItems = useMemo(
        () => [
            {
                selected: hasUnassignedUser,
                action: onHasUnassignedUserChange,
                label: 'Unassigned',
            },
        ],
        [hasUnassignedUser, onHasUnassignedUserChange],
    );

    return (
        <ListMultiBlockFilter
            title='Filter'
            icon={SvgIcon.Filter}
            items={blockFilterItems}
            renderItem={item => item.label}
            selected={blockFilterItems.filter(item => item.selected)}
            onChange={(_, changedItem) => changedItem.action()}
        />
    );
};
