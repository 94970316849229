import React, { FC } from 'react';

import { JobDto } from '@hofy/api-admin';
import { RangeBar, RangeBarBackground } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Paragraph3 } from '@hofy/ui';

interface JobProgressCellProps {
    job: JobDto;
}

export const JobProgressCell: FC<JobProgressCellProps> = ({ job }) => {
    const calculatePercentage = (job: JobDto): string => {
        const percentage = (job.tasksProcessed / job.tasksTotal) * 100;
        return `${Number(percentage.toFixed(0))}%`;
    };

    return (
        <Box column fullWidth paddingRight={24}>
            <Paragraph3 color={Color.Neutral700} bold>
                {job.tasksProcessed}/{job.tasksTotal} tasks
            </Paragraph3>
            <RangeBarBackground row fullWidth marginTop={4} bg={Color.Neutral200}>
                <RangeBar
                    height={5}
                    fullWidth
                    minWidth={5}
                    width={calculatePercentage(job)}
                    bg={Color.AccentGreen}
                />
            </RangeBarBackground>
        </Box>
    );
};
