import { ColorDye, getEnumValues } from '@hofy/global';

export enum ShipmentPublicType {
    Delivery = 'delivery',
    Collection = 'collection',
    Transfer = 'transfer',
}

export const shipmentPublicTypeColors: Record<ShipmentPublicType, ColorDye> = {
    [ShipmentPublicType.Delivery]: 'green',
    [ShipmentPublicType.Collection]: 'magenta',
    [ShipmentPublicType.Transfer]: 'blue',
};

export const allShipmentPublicTypes = getEnumValues<ShipmentPublicType>(ShipmentPublicType);
