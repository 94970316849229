import React, { FC } from 'react';

import { EmailStatus, useTrEmailStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface EmailStatusChipProps {
    status: EmailStatus;
}

export const EmailStatusChip: FC<EmailStatusChipProps> = ({ status }) => {
    const tr = useTrEmailStatus();

    return <Badge label={tr(status)} color='grape' />;
};
