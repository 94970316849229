import { Response } from '@hofy/global';

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const localDelayResponseInterceptor = async (response: Response): Promise<Response> => {
    if ((window as any)?.APP_CONFIG?.env === 'local') {
        await delay(600);
    }
    return response;
};
