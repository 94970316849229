import { allCountries, Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useCountryFilterDefinition = (): FilterDefinitionMulti<Country> => {
    const trCountry = useTrCountry();

    return {
        type: 'multi',
        name: 'Countries',
        query: queryStringArrayParser(),
        allValues: allCountries,
        toLabel: trCountry,
    };
};
