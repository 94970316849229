import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { SlideoutContent } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { PurchaseOrderStatusChip } from '../../components/domain/purchaseOrders/PurchaseOrderStatusChip';
import { PurchaseOrderLink } from './components/PurchaseOrderLink';
import { SupplierLink } from './components/SupplierLink';

interface ItemBillingBlockProps {
    item: ItemDto;
}

export const ItemBillingTab: FC<ItemBillingBlockProps> = ({ item }) => {
    const { formatPriceWithISOCode: formatPrice } = usePrice();

    return (
        <SlideoutContent gap={20} column paddingVertical={30}>
            <FormSection label='Purchase details'>
                <FormGridRow columns={2}>
                    <PurchaseOrderLink
                        purchaseOrderId={item.purchaseOrder?.purchaseOrderId}
                        purchaseOrderReference={item.purchaseOrder?.purchaseOrderReference}
                    />
                    <Labeled
                        label='Purchase order status'
                        content={
                            item.purchaseOrder ? (
                                <PurchaseOrderStatusChip status={item.purchaseOrder?.purchaseOrderStatus} />
                            ) : (
                                <Paragraph3>--</Paragraph3>
                            )
                        }
                    />
                    <SupplierLink
                        supplierId={item.purchaseOrder?.supplier.id}
                        supplierName={item.purchaseOrder?.supplier.name}
                    />
                    <LabeledText
                        content={item.purchaseOrder?.purchaseOrderSupplierReference}
                        label='Supplier reference'
                    />
                    <LabeledText
                        content={formatPrice(item.purchaseOrder?.purchasePrice)}
                        label='Purchase price'
                    />
                    <LabeledText content={item.purchaseOrder?.supplierCode} label='Item supplier code' />
                </FormGridRow>
            </FormSection>
            <FormSection label='Billing details'>
                <FormGridRow columns={2}>
                    <LabeledText label='Supplier bill' content={item.supplierBill?.supplierBillReference} />
                    <LabeledText
                        label='Billed on'
                        content={formatDate(item.supplierBill?.billedOn || null)}
                    />
                    <LabeledText label='Bill price' content={formatPrice(item.supplierBill?.billPrice)} />
                </FormGridRow>
            </FormSection>
            <FormSection label='Clearance'>
                <FormGridRow columns={2}>
                    <LabeledText
                        label='Quote'
                        content={item.clearanceQuote ? formatPrice(item.clearanceQuote) : null}
                    />
                    <LabeledText
                        label='Value'
                        content={item.clearanceQuote ? formatPrice(item.clearanceValue) : null}
                    />
                </FormGridRow>
            </FormSection>
        </SlideoutContent>
    );
};
