import React, { FC } from 'react';

import { ItemValuationFilters, useItemValuationTotalsQuery } from '@hofy/api-admin';
import { SummaryLayout, SummarySkeleton } from '@hofy/ui';

import { ValuationTotalCard } from '../../components/ValuationTotalCard';

interface ItemValuationTotalsProps {
    filters: ItemValuationFilters;
}

export const ItemValuationTotals: FC<ItemValuationTotalsProps> = ({ filters }) => {
    const { data: totals = [], isLoading } = useItemValuationTotalsQuery(filters);

    return (
        <SummaryLayout marginHorizontal='mainMarginHorizontal' marginVertical={10}>
            {isLoading ? (
                <SummarySkeleton count={3} height={98} />
            ) : (
                totals.map(({ totals, hofySubsidiary }) => (
                    <ValuationTotalCard
                        key={hofySubsidiary.id}
                        totals={totals}
                        hofySubsidiary={hofySubsidiary}
                    />
                ))
            )}
        </SummaryLayout>
    );
};
