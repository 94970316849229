import { useQuery } from '@tanstack/react-query';

import { itemEventService } from '../service/itemEventService';
import { ItemValuationFilters } from '../service/types/ItemValuationFilters';
import { itemValuationTotalCacheKey } from './cacheKey';

export const useItemValuationTotalsQuery = (filters: ItemValuationFilters) => {
    const { data, isLoading } = useQuery({
        queryKey: [itemValuationTotalCacheKey, filters],

        queryFn: () => itemEventService.getTotalItemValuation(filters),
    });

    return {
        data,
        isLoading,
    };
};
