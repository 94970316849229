import { useMutation } from '@tanstack/react-query';

import { DisconnectOrganizationHRISPayload, organizationService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useForm, useToast } from '@hofy/ui';

export const useDisconnectOrganizationHRIS = (organizationId: UUID) => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: DisconnectOrganizationHRISPayload) =>
            organizationService.disconnectOrganizationHRIS(organizationId, payload),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: `HRIS system disconnected`,
            });
        },
    });

    const form = useForm<DisconnectOrganizationHRISPayload>({
        initial: { deleteNonImportedUsers: false },
        onSubmit: mutation.mutate,
    });

    return {
        form,
    };
};
