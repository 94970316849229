import { ColorDye, getEnumValues } from '@hofy/global';

export enum ShipmentPublicStatus {
    Initiated = 'initiated',
    CourierBooked = 'courier_booked',
    WithCourier = 'with_courier',
    Delivered = 'delivered',
}

export const shipmentPublicStatusColors: Record<ShipmentPublicStatus, ColorDye> = {
    [ShipmentPublicStatus.Initiated]: 'teal',
    [ShipmentPublicStatus.CourierBooked]: 'blue',
    [ShipmentPublicStatus.WithCourier]: 'purple',
    [ShipmentPublicStatus.Delivered]: 'green',
};

export const allShipmentPublicStatuses = getEnumValues<ShipmentPublicStatus>(ShipmentPublicStatus);
