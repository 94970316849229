import { omit } from 'lodash';

import { RevenueRecognitionFilters } from '@hofy/api-admin';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useDateRangeQueryParam, useStringQueryParam } from '@hofy/router';

export const useAdminRevenueRecognitionFilters = () => {
    const [dateRange, setDateRange] = useDateRangeQueryParam('dateRange');
    const [search, setSearch] = useStringQueryParam('search', '');

    const filters = useStructMemo<RevenueRecognitionFilters>({
        search,
        dateRange,
    });

    const filtersToCount = omit(filters, 'search');
    const filterCount = countObjectValues(filtersToCount);

    return {
        filters,
        filterCount,
        setSearch,
        setDateRange,
    };
};
