import { xor } from 'lodash';
import React, { MouseEvent, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Chevron, Icon, IconButton, Paragraph3, SvgIcon, TestKeyAware } from '@hofy/ui';

import { ContentProps } from './DropdownList';
import { DropdownInner, FormDropdown } from './FormDropdown';

interface MultiSelectDropdownProps<T> extends BoxProps, TestKeyAware {
    value: T[];
    items: T[];
    label: string;
    onChange(v: T[]): void;
    labelFormatter(v: T): ReactNode;
    chipLabelFormatter?(v: T): ReactNode;
    emptyContent?: ReactNode;
    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
    toSearchLabel?(p: T): string | string[];
    clearable?: boolean;
}

export const MultiSelectDropdown = <T extends any>({
    items,
    value,
    label,
    onChange,
    emptyContent,
    isError,
    isRequired,
    testKey,
    disabled,
    clearable = false,
    labelFormatter,
    chipLabelFormatter = labelFormatter,
    toSearchLabel = labelFormatter as (v: T) => string,
    ...boxProps
}: MultiSelectDropdownProps<T>) => {
    const handleRemoveSelected = (item: T, e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onChange(xor(value, [item]));
    };

    const handleClear = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onChange([]);
    };

    const filteredItems = useMemo(() => {
        return items.filter(item => !value.includes(item));
    }, [items, value]);

    const actualIsError = typeof isError === 'string' || isError === true;
    const emptyContentLabel =
        typeof emptyContent === 'string' ? (
            <Paragraph3 color={Color.Neutral300}>{emptyContent}</Paragraph3>
        ) : (
            emptyContent
        );

    const iconColor = disabled ? Color.Neutral300 : Color.Neutral700;
    const fontColor = disabled ? Color.Neutral500 : Color.Neutral700;
    const getChipLabel = (value: ReactNode) => {
        if (typeof value !== 'string') {
            return value;
        }
        return (
            <Paragraph3 textNoWrap overflow='hidden' ellipsis color={fontColor}>
                {value}
            </Paragraph3>
        );
    };

    const topContent = ({ isOpen, toggleOpen }: ContentProps) => {
        return (
            <DropdownInner
                as={Box}
                onClick={() => !disabled && toggleOpen()}
                isOpen={isOpen}
                row
                cursor={disabled ? 'default' : 'pointer'}
                bg={Color.BackgroundDefault}
                color={Color.ContentPrimary}
                width='100%'
                height='auto'
                isError={actualIsError}
                rounded={8}
                border
                paddingHorizontal={10}
                minHeight={40}
                disabled={disabled}
            >
                <Box row justify='space-between' fullWidth>
                    <Box row wrap paddingVertical={2} maxWidth='80%'>
                        {value.length > 0
                            ? value.map((v, i) => (
                                  <ChipWrapper
                                      key={i}
                                      row
                                      onClick={e => !disabled && handleRemoveSelected(v, e)}
                                  >
                                      {getChipLabel(chipLabelFormatter(v))}
                                      <Icon marginLeft={2} svg={SvgIcon.Cross} color={iconColor} size={12} />
                                  </ChipWrapper>
                              ))
                            : emptyContentLabel}
                    </Box>
                    <Box row>
                        {clearable && value.length > 0 && (
                            <IconButton
                                disabled={disabled}
                                onClick={handleClear}
                                icon={SvgIcon.Cross}
                                color={iconColor}
                            />
                        )}
                        <Box marginLeft={8}>
                            <Chevron isOpen={isOpen} color={iconColor} />
                        </Box>
                    </Box>
                </Box>
            </DropdownInner>
        );
    };

    return (
        <FormDropdown
            disabled={disabled}
            minWidth={150}
            testKey={testKey}
            items={filteredItems}
            labelFormatter={labelFormatter}
            topContent={topContent}
            onChange={v => v && onChange(xor(value, [v]))}
            label={label}
            toSearchLabel={toSearchLabel}
            searchable
            emptyContent={emptyContent}
            isError={isError}
            isRequired={isRequired}
            value={null}
            {...boxProps}
        />
    );
};

const ChipWrapper = styled(Box)`
    border: 1px solid ${Color.NonContextualBorderDivider};
    border-radius: 16px;
    padding: 2px 8px;
    margin: 2px 4px 2px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
