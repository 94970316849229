import { useQuery } from '@tanstack/react-query';

import { hofySubsidiariesService } from '../service/hofySubsidiariesService';
import { hofySubsidiaryCacheKey } from './hofySubsidiaryCacheKey';

export const useHofySubsidiaryRefs = () => {
    const { data, isLoading } = useQuery({
        queryKey: [hofySubsidiaryCacheKey, 'refs'],
        queryFn: hofySubsidiariesService.getHofySubsidiaryRefs,
    });

    return {
        hofySubsidiaries: data || [],
        isLoading,
    };
};
