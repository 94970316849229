import React, { FC, useState } from 'react';

import { PurchaseOrderItemDto } from '@hofy/api-admin';
import { Overlay } from '@hofy/common';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Modals,
    Paragraph4,
    Placeholder,
    Strong,
    SvgIllustration,
    TextCell,
    TwoLineSmallCell,
} from '@hofy/ui';

import { ItemDeliveryDateUpdateModal } from './ItemDeliveryDateUpdateModal';
import { ItemQuantityUpdateModal } from './ItemQuantityUpdateModal';
import { PurchaseOrderItemActions } from './PurchaseOrderItemActions';
import { PurchaseOrderQuantityCell } from './PurchaseOrderQuantityCell';

interface PurchaseOrderItemsTableProps {
    entries: PurchaseOrderItemDto[];
    infinityScroll: InfiniteScrollConfig;
}

enum PurchaseOrderItemActionModals {
    UpdateEstimatedDeliveryModal = 'update_estimated_delivery_modal',
    UpdateQuantityModal = 'update_quantity_modal',
}

export const PurchaseOrderItemsTable: FC<PurchaseOrderItemsTableProps> = ({ entries, infinityScroll }) => {
    const { formatPrice } = usePrice();

    const [showModal, setShowModal] = useState<PurchaseOrderItemActionModals | null>(null);
    const [purchaseOrderItem, setPurchaseOrderItem] = useState<PurchaseOrderItemDto | undefined>();

    const handlePurchaseOrderItemDeliveryUpdate = (purchaseOrderItem: PurchaseOrderItemDto) => {
        setPurchaseOrderItem(purchaseOrderItem);
        setShowModal(PurchaseOrderItemActionModals.UpdateEstimatedDeliveryModal);
    };

    const handlePurchaseOrderItemQuantityUpdate = (purchaseOrderItem: PurchaseOrderItemDto) => {
        setPurchaseOrderItem(purchaseOrderItem);
        setShowModal(PurchaseOrderItemActionModals.UpdateQuantityModal);
    };

    return (
        <Overlay>
            <InfinityScrollTable
                data={entries}
                toKey={entry => entry.id}
                infinityScroll={infinityScroll}
                emptyContent={
                    <Placeholder
                        illustration={SvgIllustration.PackageSearch}
                        title='No items on this purchase order'
                    />
                }
                minWidth={1200}
                columns={[
                    {
                        id: 'image',
                        header: 'Item',
                        width: 80,
                        flexGrow: 0,
                        renderer: entry => <ProductImage image={entry.variant.image} size={54} />,
                    },
                    {
                        id: 'name',
                        header: 'Name',
                        flexGrow: 1,
                        width: 200,
                        renderer: ({ variant }) => (
                            <TwoLineSmallCell
                                line1={<Strong>{variant.product.name}</Strong>}
                                line2={variant.sku}
                            />
                        ),
                    },
                    {
                        id: 'supplierCode',
                        header: 'Supplier code',
                        flexGrow: 1,
                        renderer: item => item.supplierCode || '--',
                    },
                    {
                        id: 'estimatedReceiveAt',
                        header: 'Delivery',
                        width: 150,
                        flexGrow: 0,
                        renderer: entry =>
                            entry.estimatedReceiveOn ? formatDate(entry.estimatedReceiveOn) : null,
                    },
                    {
                        id: 'quantity',
                        header: 'Quantity',
                        width: 160,
                        flexGrow: 0,
                        renderer: entry => (
                            <PurchaseOrderQuantityCell
                                orderedQuantity={entry.quantity}
                                receivedQuantity={entry.receivedQuantity}
                                billedQuantity={entry.billedQuantity}
                                approvedQuantity={entry.approvedQuantity}
                            />
                        ),
                    },
                    {
                        id: 'price',
                        header: 'Price',
                        width: 200,
                        flexGrow: 0,
                        renderer: entry => (
                            <Box overflow='hidden' fullWidth>
                                <TextCell bold color={Color.ContentPrimary}>
                                    {formatPrice(entry.price)}
                                </TextCell>
                                <Box row justify='space-between' marginTop={2} color={Color.ContentSecondary}>
                                    <Paragraph4 textAlign='left'>Net price:</Paragraph4>
                                    <Paragraph4 textAlign='right'>{`${formatPrice(
                                        entry.unitNetPrice,
                                    )}/unit`}</Paragraph4>
                                </Box>

                                {entry.unitTax && (
                                    <Box
                                        row
                                        justify='space-between'
                                        marginTop={2}
                                        color={Color.ContentSecondary}
                                    >
                                        <Paragraph4 textAlign='left'>Tax:</Paragraph4>
                                        <Paragraph4 textAlign='right'>{`${formatPrice(
                                            entry.unitTax,
                                        )}/unit`}</Paragraph4>
                                    </Box>
                                )}
                                {entry.unitHandlingFee && (
                                    <Box
                                        row
                                        justify='space-between'
                                        marginTop={2}
                                        color={Color.ContentSecondary}
                                    >
                                        <Paragraph4 textAlign='left'>Handling fee:</Paragraph4>
                                        <Paragraph4 textAlign='right'>{`${formatPrice(
                                            entry.unitHandlingFee,
                                        )}/unit`}</Paragraph4>
                                    </Box>
                                )}
                            </Box>
                        ),
                    },
                    {
                        id: 'taxRate',
                        header: 'Tax rate',
                        width: 100,
                        flexGrow: 0,
                        renderer: entry => (parseInt(entry.taxRate) ? `${entry.taxRate}%` : '--'),
                    },
                    {
                        id: 'notes',
                        header: 'Notes',
                        flexGrow: 1,
                        renderer: entry => entry.notes ?? '',
                    },
                    {
                        id: 'actions',
                        header: 'Actions',
                        flexGrow: 0,
                        renderer: entry => (
                            <PurchaseOrderItemActions
                                handlePurchaseOrderItemDeliveryUpdate={handlePurchaseOrderItemDeliveryUpdate}
                                handlePurchaseOrderItemQuantityUpdate={handlePurchaseOrderItemQuantityUpdate}
                                purchaseOrderItem={entry}
                            />
                        ),
                    },
                ]}
            />
            <Modals>
                {showModal === PurchaseOrderItemActionModals.UpdateEstimatedDeliveryModal && (
                    <ItemDeliveryDateUpdateModal
                        purchaseOrderItem={purchaseOrderItem}
                        onClose={() => setShowModal(null)}
                    />
                )}

                {showModal === PurchaseOrderItemActionModals.UpdateQuantityModal && (
                    <ItemQuantityUpdateModal
                        purchaseOrderItem={purchaseOrderItem!}
                        onClose={() => setShowModal(null)}
                    />
                )}
            </Modals>
        </Overlay>
    );
};
