import React, { FC } from 'react';
import { useToggle } from 'react-use';

import { DateString } from '@hofy/global';
import { formatMonth } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Chevron, Dropdown, Icon, InlineDatepicker, OuterBoxProps, Paragraph3, SvgIcon } from '@hofy/ui';

import { DropdownInner } from '../dropdown/FormDropdown';

interface MonthDropdownProps extends OuterBoxProps {
    date: DateString;
    onChange(range: DateString): void;
    disabled?: boolean;
    minDate?: DateString;
    maxDate?: DateString;
}

/** @deprecated Not yet implemented in `@hofy/ui` and used only few times */
export const MonthDropdown: FC<MonthDropdownProps> = ({
    date,
    onChange,
    disabled,
    minDate,
    maxDate,
    ...boxProps
}) => {
    const [isOpen, toggleOpen] = useToggle(false);

    const text = formatMonth(date);
    const fontColor = disabled ? Color.ContentTertiary : Color.ContentPrimary;

    const handleDateRangeChange = (date: DateString) => {
        onChange(date);
        toggleOpen(false);
    };

    const datePickerDropdown = (
        <Dropdown
            open={isOpen}
            onDismiss={() => toggleOpen(false)}
            contentWidth='auto'
            placement='bottom-start'
            trigger={
                <DropdownInner
                    onClick={() => {
                        if (!disabled) {
                            toggleOpen();
                        }
                    }}
                    isOpen={isOpen}
                    inactive={disabled}
                    row
                    bg={Color.BackgroundDefault}
                    color={fontColor}
                    border
                    relative
                    rounded={8}
                    paddingHorizontal={10}
                    height={40}
                >
                    <Box row gap={8} justify='space-between' fullWidth>
                        <Icon svg={SvgIcon.Calendar} color={fontColor} />
                        <Paragraph3 color={Color.ContentPrimary} flex='auto'>
                            {text}
                        </Paragraph3>
                        <Chevron isOpen={isOpen} color={fontColor} />
                    </Box>
                </DropdownInner>
            }
            asChild
            padding={16}
        >
            <InlineDatepicker
                type='month'
                onChange={handleDateRangeChange}
                date={date}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
            />
        </Dropdown>
    );

    return <Box {...boxProps}>{datePickerDropdown}</Box>;
};
