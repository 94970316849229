import React, { FC, ReactNode } from 'react';

import { NumberValues } from '@hofy/theme';

import { Box, BoxProps } from '../../base';

interface FormContainerProps extends BoxProps {
    spacing?: NumberValues;
    children?: ReactNode;
}

export const FormContainer: FC<FormContainerProps> = ({ children, spacing = 20, ...margins }) => {
    return (
        <Box column gap={spacing} {...margins}>
            {children}
        </Box>
    );
};
