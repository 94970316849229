import { groupBy } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';

import { UserAssignmentDto, UserDto } from '@hofy/api-admin';
import { AddressDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Alert, Box, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { AddressDetails } from '../../../components/domain/address/AddressDetails';

interface CreateShipmentAddressModalPros {
    assignments: UserAssignmentDto[];
    user?: UserDto;
    onClose(): void;
    onSelect(address: AddressDto): void;
}

export const CreateShipmentAddressModal: FC<CreateShipmentAddressModalPros> = ({
    assignments,
    user,
    onClose,
    onSelect,
}) => {
    const groups = groupBy(assignments, a => a.deliveryAddress.id);
    const assignmentAddressUuids = Object.keys(groups);
    const userAddressNotInAssignments = user && !assignmentAddressUuids.includes(user.address.id);

    return (
        <Modal onClose={onClose} width={800}>
            <ModalHeader title='Select Address' borderBottom />
            <ModalContent>
                <Box column gap={10}>
                    <Alert
                        type='warning'
                        description='Multiple addresses found - please select an address to use'
                    />
                    <Box row alignItems='stretch' overflow='auto' gap={10} maxWidth={800}>
                        {assignmentAddressUuids.map(addressId => (
                            <Box key={addressId} column flex={0} minWidth={350}>
                                <BoxHoverableContainer
                                    padding={20}
                                    onClick={() => onSelect(groups[addressId][0].deliveryAddress)}
                                    pointer
                                    flex={1}
                                >
                                    <AddressDetails address={groups[addressId][0].deliveryAddress} />
                                </BoxHoverableContainer>
                                <Box height={100}>
                                    <Paragraph3>Assignments IDs:</Paragraph3>
                                    <Paragraph3>
                                        {groups[addressId].map(g => `#${g.id}`).join(', ')}
                                    </Paragraph3>
                                </Box>
                            </Box>
                        ))}
                        {userAddressNotInAssignments && (
                            <Box key={user.address.id} column flex={0} minWidth={350}>
                                <BoxHoverableContainer
                                    padding={20}
                                    onClick={() => onSelect(user.address)}
                                    pointer
                                    flex={1}
                                >
                                    <AddressDetails address={user.address} />
                                </BoxHoverableContainer>
                                <Box height={100}>
                                    <Paragraph3>Users default address</Paragraph3>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={onClose} />
            </ModalFooter>
        </Modal>
    );
};

const BoxHoverableContainer = styled(Box)`
    border: 1px solid ${Color.NonContextualBorderDivider};
    border-radius: 5px;

    :hover {
        background-color: ${Color.HighlightHover};
    }
`;
