import React, { ElementRef, forwardRef, useState } from 'react';

import { Country } from '@hofy/global';

import { useIsDisabled } from '../../../../contexts/disable/useIsDisabled';
import { Box } from '../../../base/Box';
import { Labeled } from '../../../labeled/Labeled';
import { Modals } from '../../../modal/Modals';
import { CountryRegionButton } from './CountryRegionButton';
import { CountryRegionModal } from './CountryRegionModal';

export interface LabeledCountryRegionProps {
    label: string;
    isRequired?: boolean;
    disabled?: boolean;
    errorMessage?: string;
    placeholder?: string;
    helperText?: string;
    value: Country[];
    onBlur?(): void;
    onChange(country: Country[]): void;
}

export const LabeledCountryRegion = forwardRef<ElementRef<'div'>, LabeledCountryRegionProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,

            value,
            placeholder,
            disabled: inputDisabled,
            onChange,
            onBlur,
            ...rest
        },
        ref,
    ) => {
        const [isOpen, setIsOpen] = useState(false);
        const disabled = useIsDisabled(inputDisabled);

        return (
            <>
                <Labeled
                    as='label'
                    label={label}
                    isRequired={isRequired}
                    content={
                        <Box ref={ref}>
                            <CountryRegionButton
                                placeholder={placeholder}
                                isReadonly={disabled}
                                countries={value}
                                onOpen={() => setIsOpen(true)}
                            />
                        </Box>
                    }
                    errorMessage={errorMessage}
                    helperText={helperText}
                    {...rest}
                />
                <Modals>
                    {isOpen && (
                        <CountryRegionModal
                            title={label}
                            onCancel={() => {
                                setIsOpen(false);
                                onBlur?.();
                            }}
                            onSave={onChange}
                            initialCountries={value}
                        />
                    )}
                </Modals>
            </>
        );
    },
);
