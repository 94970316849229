import { useQuery } from '@tanstack/react-query';

import { OrganizationAddonConfigDto, organizationAddonConfigService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { organizationAddonConfigCacheKey } from './organizationAddonConfigCacheKey';

const emptyOrganizationAddonsWithConfig: OrganizationAddonConfigDto[] = [];

export const useOrganizationAddonConfigsQuery = (orgId: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [organizationAddonConfigCacheKey, orgId],
        queryFn: () => organizationAddonConfigService.listOrganizationAddonConfigurations(orgId),
    });

    return {
        isLoading,
        isError,
        data: data || emptyOrganizationAddonsWithConfig,
    };
};
