import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { AdminInvoiceFilters, invoicesService } from '../service/invoiceService';
import { InvoiceDto } from '../service/types/InvoiceDto';
import { invoiceCacheKey } from './invoiceCacheKey';

export const useInvoices = (filters: AdminInvoiceFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [invoiceCacheKey, filters],
        queryFn: param =>
            invoicesService.listInvoices(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const invoices: InvoiceDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        invoices,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        filters,
    };
};
