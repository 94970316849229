import React from 'react';

import { Box, Button, ButtonProps, Counter, SvgIcon } from '@hofy/ui';

interface BlockFilterButtonProps extends Omit<ButtonProps, 'label'> {
    isOpened: boolean;
    count: number;
}

export const BlockFilterButton = ({ isOpened, count, onClick, ...props }: BlockFilterButtonProps) => {
    // id used in useBlockFilterHook
    return (
        <Button
            id='filter-button'
            type='secondary'
            label='Filters'
            leftIcon={isOpened ? SvgIcon.Cross : SvgIcon.Filter}
            onClick={onClick}
            {...props}
        >
            {count > 0 && (
                <Box marginLeft={8}>
                    <Counter color='violet' count={count} />
                </Box>
            )}
        </Button>
    );
};
