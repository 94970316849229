import React, { FC } from 'react';

import { FormFieldApi } from '../../../../hooks';
import { Optional } from '../../../../types';
import { LabeledNumberInput, LabeledNumberInputProps } from './LabeledNumberInput';

type FormNumberInputOptionalProps = Optional<LabeledNumberInputProps, 'onChange'>;
type FormNumberInputOmittedProps = Omit<FormNumberInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalNumberInputProps extends FormNumberInputOmittedProps {
    api: FormFieldApi<number>;
    nullable?: false;
}

interface FormNullableNumberInputProps extends FormNumberInputOmittedProps {
    api: FormFieldApi<number | null>;
    nullable: true;
}

type FormNumberInputProps = FormNormalNumberInputProps | FormNullableNumberInputProps;

export const FormNumberInput: FC<FormNumberInputProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledNumberInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
