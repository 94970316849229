import React, { ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Paragraph3 } from '@hofy/ui';

import { RadioGroup } from '../form/RadioGroup';
import { FilterContainer } from './FilterContainer';

interface RadioFilterProps<T> {
    title: string;
    borderBottom?: boolean;
    options: T[];
    selected: T;
    onChange(selected: T): void;
    optionToText?(option: T): string; // by default just use toString
}

export const RadioFilter = <T extends ReactNode>({
    options,
    selected,
    onChange,
    optionToText,
    borderBottom,
    title,
}: RadioFilterProps<T>) => {
    return (
        <FilterContainer borderBottom={borderBottom} title={title}>
            <RadioGroup
                onChange={onChange}
                value={selected}
                items={options}
                labelFormatter={(option: T) => (
                    <Paragraph3 color={Color.Neutral700}>
                        {optionToText ? optionToText(option) : option}
                    </Paragraph3>
                )}
            />
        </FilterContainer>
    );
};
