import { ColorDye } from '@hofy/global';

export enum BillingEntityStatus {
    Active = 'active',
    Trial = 'trial',
    Archived = 'archived',
    ArchivedTrial = 'archived_trial',
}

export const billingEntityStatusColor: Record<BillingEntityStatus, ColorDye> = {
    [BillingEntityStatus.Active]: 'green',
    [BillingEntityStatus.Trial]: 'purple',
    [BillingEntityStatus.Archived]: 'blue',
    [BillingEntityStatus.ArchivedTrial]: 'blue',
};
