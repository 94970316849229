import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { warehouseService } from '../service/warehouseService';
import { warehouseCacheKey } from './warehousesCacheKey';

export const useHofyWarehouseQuery = (id: UUID | null) => {
    const { isLoading, data } = useQuery({
        queryKey: [warehouseCacheKey, id],
        queryFn: () => warehouseService.getHofyWarehouseById(id!),
        enabled: !!id,
    });

    return {
        isLoading,
        data,
    };
};
