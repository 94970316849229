import { ColorDye, getEnumValues } from '@hofy/global';

export enum PaymentDataStatus {
    Pending = 'pending',
    Success = 'success',
    Failed = 'failed',
}

export const paymentDataStatusColor: Record<PaymentDataStatus, ColorDye> = {
    [PaymentDataStatus.Pending]: 'orange',
    [PaymentDataStatus.Success]: 'green',
    [PaymentDataStatus.Failed]: 'red',
};

export const allPaymentDataStatuses = getEnumValues<PaymentDataStatus>(PaymentDataStatus);
