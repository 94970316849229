import React, { FC } from 'react';

import { ContractAddonDto } from '@hofy/api-admin';
import {
    addonTypeColors,
    ContractType,
    contractTypeColors,
    ManagementContractStatus,
    PublicRefDto,
    useTrAddonItem,
    useTrAddonType,
    useTrContractType,
    useTrPaymentSchema,
} from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Badge, BaseTable, Box, Paragraph3, PublicIdColumn } from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

export interface ContractAddonsWithSubContractReference extends ContractAddonDto {
    contractType: ContractType;
    contract: PublicRefDto;
    status: ManagementContractStatus;
}
interface AddonsTableProps {
    addons: ContractAddonsWithSubContractReference[];
}

export const AddonsTable: FC<AddonsTableProps> = ({ addons }) => {
    const trAddonType = useTrAddonType();
    const trAddonItemType = useTrAddonItem();
    const trPaymentSchema = useTrPaymentSchema();
    const { formatPrice } = usePrice();
    const trContractType = useTrContractType();

    return (
        <BaseTable
            data={addons}
            toKey={addon => addon.id}
            columns={[
                PublicIdColumn<ContractAddonsWithSubContractReference>(),
                {
                    id: 'name',
                    header: 'Name / Status',
                    flexGrow: 0,
                    width: 150,
                    renderer: addon => (
                        <Box column alignItems='flex-start' gap={5}>
                            <Paragraph3 bold>{addon.addon.name}</Paragraph3>
                            <ContractStatusChip status={addon.status} />
                        </Box>
                    ),
                },

                {
                    id: 'type',
                    header: 'Addon type / Agreement id',
                    width: 200,
                    flexGrow: 0,
                    renderer: addon => (
                        <Box column alignItems='flex-start' gap={5}>
                            <Badge
                                color={addonTypeColors[addon.addonType]}
                                label={trAddonType(addon.addonType)}
                            />
                            <Badge
                                color={contractTypeColors[addon.contractType]}
                                label={`${trContractType(addon.contractType)} ${addon.contract.publicId}`}
                            />
                        </Box>
                    ),
                },
                {
                    id: 'payment_schema',
                    header: 'Payment schema',
                    flexGrow: 0,
                    width: 140,
                    renderer: addon => trPaymentSchema(addon.paymentSchema),
                },
                {
                    id: 'pricing',
                    header: 'Pricing',
                    flexGrow: 0,
                    width: 160,
                    renderer: addon => (
                        <Box row gap={5}>
                            <Box column gap={5}>
                                <Paragraph3 bold>Monthly:</Paragraph3>
                                <Paragraph3 bold>Total:</Paragraph3>
                                <Paragraph3 bold>Resell:</Paragraph3>
                            </Box>
                            <Box column gap={5}>
                                <Paragraph3>{formatPrice(addon.price)}</Paragraph3>
                                <Paragraph3>{formatPrice(addon.totalPrice)}</Paragraph3>
                                <Paragraph3>{formatPrice(addon.resellPrice)}</Paragraph3>
                            </Box>
                        </Box>
                    ),
                },
                {
                    id: 'valid',
                    header: 'Valid',
                    flexGrow: 0,
                    width: 160,
                    renderer: addon => (
                        <Box row gap={5}>
                            <Box column gap={5}>
                                <Paragraph3 bold>From:</Paragraph3>
                                <Paragraph3 bold>To:</Paragraph3>
                            </Box>
                            <Box column gap={5}>
                                <Paragraph3>{formatDate(addon.activeOn)}</Paragraph3>
                                <Paragraph3>{formatDate(addon.endOn)}</Paragraph3>
                            </Box>
                        </Box>
                    ),
                },
                {
                    id: 'type',
                    header: 'Addon items',
                    flexGrow: 1,
                    renderer: addon => (
                        <Box row wrap gap={20}>
                            {addon.addonItems.map(item => (
                                <Badge key={item} color='grey' label={trAddonItemType(item)} />
                            ))}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
