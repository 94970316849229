import React, { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

import { RepairsPageTab } from '../../store/repairs/repairsPageTab';

interface RepairsTabContext {
    tab: RepairsPageTab;
}

const RepairsTabCtx = createContext<RepairsTabContext | null>(null);

export const RepairsPageTabContextProvider: FC<PropsWithChildren<RepairsTabContext>> = ({
    tab,
    children,
}) => {
    const value = useMemo(() => ({ tab }), [tab]);

    return <RepairsTabCtx.Provider value={value}>{children}</RepairsTabCtx.Provider>;
};

export const useRepairsPageTab = (): RepairsPageTab => {
    const ctx = useContext(RepairsTabCtx);

    if (ctx === null) {
        throw new Error('useRepairsPageTab should be called within RepairsPageTabContextProvider');
    }

    return ctx.tab;
};
