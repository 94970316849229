import React, { FC } from 'react';

import { RentalStoreAndReuseLocation } from '@hofy/api-shared';
import { ColorDye } from '@hofy/global';
import { Badge, Paragraph3 } from '@hofy/ui';

const optionLabel = (storeAndReuseLocation: RentalStoreAndReuseLocation): string => {
    if (storeAndReuseLocation === RentalStoreAndReuseLocation.Local) {
        return 'Local';
    }
    return 'Zone 1';
};

const optionColor = (storeAndReuseLocation: RentalStoreAndReuseLocation): ColorDye => {
    if (storeAndReuseLocation === RentalStoreAndReuseLocation.Local) {
        return 'blue';
    }
    return 'green';
};

interface StoreAndReuseLocationChipProps {
    location: RentalStoreAndReuseLocation | null;
}

export const StoreAndReuseLocationChip: FC<StoreAndReuseLocationChipProps> = ({ location }) => {
    if (location === null) {
        return <Paragraph3>--</Paragraph3>;
    }
    return <Badge color={optionColor(location)} label={optionLabel(location)} />;
};
