import React, { FC, useMemo } from 'react';
import styled, { keyframes } from 'styled-components';

import { BYODOrderItemDto } from '@hofy/api-admin';
import { WarehouseRefDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { ArrayField, Box, FormFieldRecord, Paragraph2, Paragraph3 } from '@hofy/ui';

import { ReceiveBYODOrderItemDetailFormData } from '../../../../../store/byodOrders/types/ReceiveBYODOrderItemFormData';
import { BYODOrderProductInfo } from '../BYODOrderProductBox';
import { BYODOrderItemDetails } from './BYODOrderItemDetails';

interface BYODOrderItemsScanFormProps {
    items: ArrayField<ReceiveBYODOrderItemDetailFormData>;
    byodOrderItem: BYODOrderItemDto;
    warehouse: WarehouseRefDto;
    warehouseBinRequired: boolean;
    isItemEmpty(item: FormFieldRecord<ReceiveBYODOrderItemDetailFormData>): boolean;
}

export const BYODOrderItemsScanForm: FC<BYODOrderItemsScanFormProps> = ({
    items,
    byodOrderItem,
    warehouse,
    warehouseBinRequired,
    isItemEmpty,
}) => {
    const scannedQuantity = useMemo(() => {
        return items.fields.reduce((acc, val) => (isItemEmpty(val.api) ? acc : acc + 1), 0);
    }, [items]);

    return (
        <Box paddingTop={20} column fullHeight gap={30} minWidth={800}>
            <Box padding={20} row fullWidth gap={30}>
                <BYODOrderProductInfo item={byodOrderItem} />
                <Box>
                    <Paragraph3 paddingVertical={4}>Expected quantity</Paragraph3>
                    <Paragraph2 color={Color.ContentPrimary}>{byodOrderItem.expectedQuantity}</Paragraph2>
                </Box>
            </Box>
            <Box column gap={20} flex={1}>
                {items.fields.map((item, index) => (
                    <BYODOrderItemDetails
                        key={index}
                        itemForm={item.api}
                        index={index}
                        category={byodOrderItem.product.category}
                        warehouse={warehouse}
                        warehouseBinRequired={warehouseBinRequired}
                        deleteItem={() => items.remove(item.key)}
                    />
                ))}
            </Box>
            <Box padding={20} row fullWidth justify='space-between' alignSelf='flex-end'>
                <Box>
                    <Paragraph3>Receive progress</Paragraph3>
                </Box>
                <ProgressWrapper>
                    <Paragraph3>
                        {scannedQuantity + byodOrderItem.receivedQuantity}/{byodOrderItem.expectedQuantity}{' '}
                        items
                    </Paragraph3>
                    <Box row fullWidth height={8} border rounded={4} marginTop={4}>
                        <RangeBar
                            rounded={4}
                            height={8}
                            minWidth={5}
                            width={`${
                                ((scannedQuantity + byodOrderItem.receivedQuantity) /
                                    byodOrderItem.expectedQuantity) *
                                100
                            }%`}
                            bg={Color.ContentPositive}
                        />
                    </Box>
                </ProgressWrapper>
            </Box>
        </Box>
    );
};

const ProgressWrapper = styled(Box)`
    min-width: 120px;
    text-align: right;
`;

const progressBar = (width: string) => keyframes`
    0% { width: 0; }
    100% { width: ${width}; }
`;

const RangeBar = styled(Box)<{ width: string }>`
    animation: ${props => progressBar(props.width)} 1.5s ease;
`;
