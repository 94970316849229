import { useQuery } from '@tanstack/react-query';

import { apiKeyService } from '../service/apiKeyService';
import { apiKeysCacheKey } from './cacheKey';

export const useApiKeys = () => {
    const { isLoading: isKeysLoading, data: apiKeys = [] } = useQuery({
        queryKey: [apiKeysCacheKey],

        queryFn: () => apiKeyService.getApiKeys(),
    });

    return {
        apiKeys,
        isKeysLoading,
    };
};
