export const dateRangeNameI18n = {
    'date-range-name.today': 'Today',
    'date-range-name.this-week': 'This week',
    'date-range-name.this-month': 'This month',
    'date-range-name.this-quarter': 'This quarter',
    'date-range-name.this-year': 'This year',

    'date-range-name.next-week': 'Next week',
    'date-range-name.next-month': 'Next month',
    'date-range-name.next-quarter': 'Next quarter',
    'date-range-name.next-3-months': 'Next 3 months',
    'date-range-name.next-year': 'Next year',
};
