import React, { FC } from 'react';

import { CopyToClipboardStatus, useCopyToClipboard } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SvgIcon } from '@hofy/ui';

interface CopyToClipboardButtonProps extends BoxProps {
    value: string;
    color?: Color;
}

export const CopyToClipboardButton: FC<CopyToClipboardButtonProps> = ({
    value,
    color = Color.InteractionNeutralNormal,
    border = true,
    ...rest
}) => {
    const { copyStatus, copyToClipboard } = useCopyToClipboard(value);
    return (
        <Box border={border} rounded={8} padding={8} centered pointer onClick={copyToClipboard} {...rest}>
            <Icon
                svg={copyStatus !== CopyToClipboardStatus.Idle ? SvgIcon.Check : SvgIcon.Copy}
                color={color}
                size={16}
            />
        </Box>
    );
};
