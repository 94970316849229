import React, { FC } from 'react';

import { WarehouseRefDto } from '@hofy/api-shared';
import {
    AsyncButton,
    Box,
    FormInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
} from '@hofy/ui';

import { useCreateHofyWarehouseBinForm } from '../../../store/warehouses/useCreateHofyWarehouseBinForm';
import { CancelButton } from '../../design/button/CancelButton';
import { WarehouseChip } from './WarehouseChip';

interface CreateWarehouseBinModalProps {
    warehouse: WarehouseRefDto;
    onCancel(): void;
    onSuccess(warehouseBinIdentifier: string): void;
}

export const CreateWarehouseBinModal: FC<CreateWarehouseBinModalProps> = ({
    warehouse,
    onCancel,
    onSuccess,
}) => {
    const { form, isLoading } = useCreateHofyWarehouseBinForm(warehouse.id, (_, { identifier }) => {
        onSuccess(identifier);
    });

    const isSubmitDisabled = !!(form.errors.identifier || !form.values.identifier || isLoading);

    return (
        <Modal onClose={onCancel} width={500}>
            <ModalHeader title='Add a new bin' />
            <ModalContent>
                <Box column gap={16}>
                    <Box column gap={8}>
                        <Paragraph3>Warehouse</Paragraph3>
                        <Box>
                            <WarehouseChip name={warehouse.name} />
                        </Box>
                    </Box>
                    <FormInput
                        autoFocus
                        label='Bin identifier'
                        api={form.fields.identifier}
                        placeholder='Enter bin identifier'
                        isRequired
                        nullable
                    />
                </Box>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onCancel} label='Cancel' />
                <AsyncButton
                    label='Add bin'
                    onClick={form.submit}
                    disabled={isSubmitDisabled}
                    isLoading={isLoading}
                    disableCheck
                />
            </ModalFooter>
        </Modal>
    );
};
