import { isEqual, xorWith } from 'lodash';
import React, { useMemo } from 'react';

import { Checkbox } from '../../../form';
import { SortFilterDataTableColumnConfig } from '../../types/SortFilterDataTableTypes';

export const useTableSelection = <T extends any>(
    columns: SortFilterDataTableColumnConfig<T>[],
    data: T[],
    selected: T[],
    onSelect?: (v: T[]) => void,
) => {
    const finalColumns = useMemo(() => {
        const anySelected = selected.length > 0;
        const allSelected = data.length === selected.length;
        const partialSelected = selected.length > 0 && !allSelected;
        const handleHeaderSelection = () => onSelect?.(allSelected ? [] : data);

        if (onSelect) {
            return [
                {
                    id: '_selection',
                    width: 40,
                    flexGrow: 0,
                    renderer: (v: T) => (
                        <Checkbox checked={selected.includes(v)} testKey='table-row-selection-checkbox' />
                    ),
                    header: columns.some(column => column.header) && (
                        <Checkbox
                            checked={anySelected ? (partialSelected ? 'mixed' : allSelected) : false}
                            onChange={handleHeaderSelection}
                            testKey='table-selection-checkbox'
                        />
                    ),
                },
                ...columns,
            ];
        }
        return columns;
    }, [columns, data, selected]);

    const toggleRow = (v: T) => {
        onSelect?.(xorWith(selected, [v], isEqual));
    };

    return { columns: finalColumns, toggleRow };
};
