export const organizationCurrencyUpdateI18n = {
    'organization-page.currency-update.non-trial-not-allowed-modal.title': 'Cannot update currency',
    'organization-page.currency-update.non-trial-not-allowed-modal.subtitle':
        'Only organizations in trial mode without contracts/requests are permitted to change currency',
    'organization-page.currency-update.non-trial-not-allowed-modal.confirm': 'Ok',

    'organization-page.currency-update.allowed-modal.title': 'Update organization currency',
    'organization-page.currency-update.allowed-modal.subtitle':
        'This operation will update the organization currency',
    'organization-page.currency-update.allowed-modal.description-trial-org':
        'Currency for the default billing entity will be updated as well',
    'organization-page.currency-update.allowed-modal.description-non-trial-org':
        'Currency for the default billing entity will not be updated',
    'organization-page.currency-update.allowed-modal.confirm': 'Save',
    'organization-page.currency-update.allowed-modal.cancel': 'Cancel',
};
