import { countryI18n } from './countryI18n';
import { countryRegionModal } from './countryRegionModal';
import { currencyOptionI18n } from './currencyOptionI18n';
import { dateI18n, monthI18n } from './dateI18n';
import { regionI18n } from './regionI18n';
import { stateI18n } from './stateI18n';

export const globalI18n = {
    ...countryI18n,
    ...countryRegionModal,
    ...dateI18n,
    ...monthI18n,
    ...currencyOptionI18n,
    ...regionI18n,
    ...stateI18n,
};
