import { useAddressValidator } from '@hofy/address';
import { InvoiceEntryTaxAddressPayload, useInvoiceEntry } from '@hofy/api-admin';
import {
    addressToOptionalPayload,
    addressToPayloadWithDefault,
    AddressType,
    emptyAddressPayload,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { UseForm, useForm, validator } from '@hofy/ui';

import { useUpdateInvoiceEntryTaxAddress } from './useUpdateInvoiceEntryTaxAddress';

export const useInvoiceEntryTaxAddressForm = (invoiceEntryId: UUID, onClose: () => void) => {
    const { invoiceEntry, isLoading: isLoadingEntry, isError } = useInvoiceEntry(invoiceEntryId);
    const { updateAddress, isLoading: isLoadingMutation } = useUpdateInvoiceEntryTaxAddress(
        invoiceEntryId,
        onClose,
    );
    const onSubmit = (payload: InvoiceEntryTaxAddressPayload) => {
        updateAddress({
            fromAddress: payload.fromAddress,
            toAddress: payload.toAddress,
        });
    };

    const validateAddress = useAddressValidator();

    const form = useForm<InvoiceEntryTaxAddressPayload>({
        initial: invoiceEntry
            ? {
                  fromAddress: addressToOptionalPayload(
                      invoiceEntry.avalaraTransaction?.fromAddress || null,
                      AddressType.Billing,
                  ),
                  toAddress: addressToPayloadWithDefault(
                      invoiceEntry.avalaraTransaction?.toAddress || null,
                      AddressType.Billing,
                  ),
              }
            : {
                  fromAddress: null,
                  toAddress: emptyAddressPayload(AddressType.Billing),
              },
        initialDeps: [invoiceEntry],
        onSubmit: onSubmit,
        validate: validator<InvoiceEntryTaxAddressPayload>({
            toAddress: validateAddress,
            fromAddress: validateAddress,
        }),
    });

    return { form, isLoadingEntry, isLoadingMutation, isError };
};

export type InvoiceEntryTaxAddressFormState = UseForm<InvoiceEntryTaxAddressPayload>;
