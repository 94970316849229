import React, { FC } from 'react';

import { ShipmentCollectionRequestItemDto } from '@hofy/api-admin';
import {
    CollectionPackagingStatus,
    isLaptop,
    isSerialNumberRequired,
    ProductCategory,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Badge, Box, FormGridRow, Paragraph3, Span } from '@hofy/ui';

import { CollectionRequestItemPropertyStatus } from '../../../../../store/collectionRequests/types/CollectionRequestItemPropertyStatus';
import {
    allCollectionRequestItemPropertyTypes,
    CollectionRequestItemPropertyType,
} from '../../../../../store/collectionRequests/types/CollectionRequestItemPropertyType';
import { Hoverable } from '../../../../assignmentsPage/components/utils/Hoverable';
import { SurveyResultsSectionCard } from '../surveyResultsSection/SurveyResultsSectionCard';
import { ItemPropertyStatusChip } from './ItemPropertyStatusChip';

interface AssignmentDetailsSectionProps {
    itemsToCollect: ShipmentCollectionRequestItemDto[];
    onAssignmentClick(assignmentId: UUID): void;
}

export const AssignmentDetailsSection: FC<AssignmentDetailsSectionProps> = ({
    itemsToCollect,
    onAssignmentClick,
}) => {
    if (itemsToCollect.length === 0) {
        return null;
    }

    const items = itemsToCollect.map(item => {
        const getClickableId = () => {
            const isItemConfirmedOrDisabled = !item.missingItem || item.disabled;
            const color = isItemConfirmedOrDisabled ? Color.ContentPrimary : Color.ContentNegative;
            return (
                <Hoverable color={color} onClick={() => onAssignmentClick(item.assignmentId)}>
                    <Span color={color}>#{item.assignmentId}</Span>
                </Hoverable>
            );
        };

        return (
            <SurveyResultsSectionCard key={item.assignmentId} column padding={0} border>
                <Box row justify='space-between' marginHorizontal={16} marginTop={16}>
                    <Box row gap={4}>
                        <Paragraph3 color={Color.ContentSecondary}>Assignment ID</Paragraph3>
                        <Paragraph3>{getClickableId()}</Paragraph3>
                    </Box>
                    {item.disabled && <Badge label='Removed' color='grey' />}
                </Box>
                <Box column>
                    <Box row gap={12} marginHorizontal={16} marginBottom={16}>
                        <ProductImage image={item.product.image.url} size={40} />
                        <Box column gap={4}>
                            <Paragraph3 color={Color.ContentPrimary} bold>
                                {item.product.name}
                            </Paragraph3>
                            <Box row gap={4}>
                                <Paragraph3 size={12} color={Color.ContentSecondary}>
                                    SKU:
                                </Paragraph3>
                                <Paragraph3 size={12} color={Color.ContentPrimary}>
                                    {item.product.sku ?? '--'}
                                </Paragraph3>
                            </Box>
                        </Box>
                    </Box>
                    <Box column borderTop padding={16}>
                        <FormGridRow columns={3}>
                            {allCollectionRequestItemPropertyTypes.map(
                                propertyType =>
                                    shouldShowItemProperty(item.product.category, propertyType) && (
                                        <ItemPropertyStatusChip
                                            key={propertyType}
                                            item={item}
                                            propertyType={propertyType}
                                            propertyStatus={getItemPropertyStatus(item, propertyType)}
                                            isDisabled={item.disabled}
                                        />
                                    ),
                            )}
                        </FormGridRow>
                    </Box>
                </Box>
            </SurveyResultsSectionCard>
        );
    });

    return (
        <Box column gap={24}>
            {items}
        </Box>
    );
};

const shouldShowItemProperty = (
    itemCategory: ProductCategory,
    propertyType: CollectionRequestItemPropertyType,
) => {
    if (propertyType === CollectionRequestItemPropertyType.ProvidedSerialNumber) {
        return isSerialNumberRequired(itemCategory);
    }
    if (propertyType === CollectionRequestItemPropertyType.ChargingBlock) {
        return isLaptop(itemCategory);
    }
    if (propertyType === CollectionRequestItemPropertyType.ChargingCable) {
        return isLaptop(itemCategory);
    }
    return true;
};

const getItemPropertyStatus = (
    item: ShipmentCollectionRequestItemDto,
    propertyType: CollectionRequestItemPropertyType,
) => {
    const getBoxTypeStatus = (packagingStatus: CollectionPackagingStatus | null) => {
        switch (packagingStatus) {
            case null:
            case CollectionPackagingStatus.Required:
            case CollectionPackagingStatus.AssistanceRequested:
                return CollectionRequestItemPropertyStatus.Missing;
            case CollectionPackagingStatus.PackagingSentToUser:
                return CollectionRequestItemPropertyStatus.Sent;
            case CollectionPackagingStatus.UserReceivedPackaging:
                return CollectionRequestItemPropertyStatus.Resolved;
            case CollectionPackagingStatus.UserHasPackaging:
                return CollectionRequestItemPropertyStatus.Confirmed;
        }
    };

    const getItemTypeStatus = (isItemConfirmed: boolean, hasMissingItemNote: boolean) => {
        if (isItemConfirmed && hasMissingItemNote) {
            return CollectionRequestItemPropertyStatus.Resolved;
        }
        if (isItemConfirmed) {
            return CollectionRequestItemPropertyStatus.Confirmed;
        }
        return CollectionRequestItemPropertyStatus.Missing;
    };

    const getChargingAccessoryStatus = (hasChargingAccessory: boolean | null) => {
        if (hasChargingAccessory === null) {
            return CollectionRequestItemPropertyStatus.ResponseNotReceived;
        }
        if (hasChargingAccessory) {
            return CollectionRequestItemPropertyStatus.Confirmed;
        }
        return CollectionRequestItemPropertyStatus.Missing;
    };

    const getProvidedSerialNumberStatus = (hasProvidedSerialNumber: boolean | null) => {
        if (hasProvidedSerialNumber) {
            return CollectionRequestItemPropertyStatus.Confirmed;
        }
        return CollectionRequestItemPropertyStatus.Missing;
    };

    switch (propertyType) {
        case CollectionRequestItemPropertyType.Item:
            return getItemTypeStatus(!item.missingItem, !item.missingItemNote);
        case CollectionRequestItemPropertyType.ManufacturerBox:
            return getBoxTypeStatus(item.manufacturerPackagingStatus);
        case CollectionRequestItemPropertyType.ShippingBox:
            return getBoxTypeStatus(item.cardboardBoxPackagingStatus);
        case CollectionRequestItemPropertyType.ChargingBlock:
            return getChargingAccessoryStatus(item.hasChargingBlock);
        case CollectionRequestItemPropertyType.ChargingCable:
            return getChargingAccessoryStatus(item.hasChargingCable);
        case CollectionRequestItemPropertyType.ProvidedSerialNumber:
            return getProvidedSerialNumberStatus(!!item.providedSerialNumber);
    }
};
