import { getEnumValues } from '@hofy/global';

export enum UserTab {
    Details = 'details',
    Orders = 'orders',
    Assignments = 'assignments',
    Emails = 'emails',
    Messages = 'messages',
}

export const allUserTabs = getEnumValues<UserTab>(UserTab);
