export enum ZIndex {
    Loader = 100,
    MapControls = 200,
    Navigation = 250,
    Popup = 300,
    PopupOverlay = 400,
    Header = 450,
    MobileMenu = 460,
    Modal = 500,
    Dropdown = 550,
    Tooltip = 600,
    Popover = 700,
    GlobalLoader = 900,
    Overlap = 999,
}
