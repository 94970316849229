import React, { FC } from 'react';

import { FilterGroups } from '../../components';
import { FilterGroupMulti } from './groups/FilterGroupMulti';
import { FilterGroupSinge } from './groups/FilterGroupSinge';
import { useActiveFilters } from './hooks/useActiveFilters';
import { FilterApiRecord } from './useFilters';

interface ActiveFiltersProps {
    filters: FilterApiRecord<any>;
    onClearAll(): void;
}

export const ActiveFilters: FC<ActiveFiltersProps> = ({ filters, onClearAll }) => {
    const { activeFilters, hasChangedFilters } = useActiveFilters(filters);

    return (
        <FilterGroups onClearAll={hasChangedFilters ? onClearAll : undefined}>
            {activeFilters.map(({ key, filter }) => {
                switch (filter.type) {
                    case 'single':
                        return <FilterGroupSinge key={key} filter={filter} />;
                    case 'multi':
                        return <FilterGroupMulti key={key} filter={filter} />;
                }
            })}
        </FilterGroups>
    );
};
