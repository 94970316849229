import { getEnumValues } from '@hofy/global';

export enum OrderStage {
    PendingRequest = 'pending_request',
    PendingDelivery = 'pending_delivery',
    ReadyToShip = 'ready_to_ship',
    Shipped = 'shipped',
    Completed = 'completed',
    Declined = 'declined',
    Cancelled = 'cancelled',
}

export const allOrderStages = getEnumValues<OrderStage>(OrderStage);
