import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { ColorDye } from '@hofy/global';
import { Color, colorDyes, isColorDye, Palette } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';

export interface DotProps extends MarginBoxProps {
    size?: number;
    topRight?: boolean;
    color: Color | ColorDye;
    inverted?: boolean;
}

export const Dot: FC<DotProps> = ({ size = 6, topRight, color, inverted, ...boxProps }) => {
    const bg = getColor(color);
    return <DotBox rect={size} bg={bg} inverted={inverted} topRight={topRight} {...boxProps} />;
};

const DotBox = styled(Box)<{ inverted?: boolean; topRight?: boolean }>`
    flex-shrink: 0;
    border-radius: 50%;
    ${p =>
        p.inverted &&
        css`
            box-shadow: inset 0 0 0 1px ${Palette.white['-5']};
        `}
    ${p =>
        p.topRight &&
        css`
            position: absolute;
            top: 0;
            right: 0;
        `}
`;

const getColor = (color: Color | ColorDye): Color => {
    if (isColorDye(color)) {
        return colorDyes[color as ColorDye].main;
    }
    return color as Color;
};
