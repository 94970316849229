import React, { FC, useMemo } from 'react';

import { allPermissions, Permission, permissionToName } from '@hofy/api-shared';
import { CheckboxGroup, Labeled } from '@hofy/ui';

interface LabeledPermissionsProps {
    value: Permission[];
    onChange(v: Permission[]): void;
    errorMessage?: string;
}

export const LabeledPermissions: FC<LabeledPermissionsProps> = ({ errorMessage, value, onChange }) => {
    const permissions = useMemo<Permission[]>(() => [...allPermissions].sort(), []);

    return (
        <Labeled
            label='Permissions'
            errorMessage={errorMessage}
            content={
                <CheckboxGroup
                    marginTop={4}
                    value={value}
                    toKey={v => v}
                    toLabel={permissionToName}
                    options={permissions}
                    onChange={onChange}
                />
            }
        />
    );
};
