import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    UploadUsersFormInitialData,
    UploadUsersFormResult,
    usersCacheKey,
    userService,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isBlank, useForm, useToast } from '@hofy/ui';

import { UploadUsersErrorCode } from './types/UploadUsersErrorCode';
import { useTrUploadUsersError } from './useTrUploadUsersError';

export const useUploadUsers = (organizationId: UUID, onSuccess: () => void) => {
    const { showErrorToast, showToast } = useToast();
    const trUploadUsersError = useTrUploadUsersError();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: userService.uploadUsers,
        onSuccess: res => {
            if (!res.success) {
                if (res.errorCode) {
                    const message = trUploadUsersError(res.errorCode as UploadUsersErrorCode, {
                        row: res.errorRow,
                    });
                    showToast({ type: 'negative', message: message });
                    return;
                }
                return showErrorToast();
            }
            queryClient.invalidateQueries({ queryKey: [usersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Users uploaded successfully',
            });
            form.reset();
            onSuccess();
        },
    });

    const form = useForm<UploadUsersFormInitialData, UploadUsersFormResult>({
        initial: {
            file: null,
        },
        onSubmit: formResult =>
            mutation.mutate({
                file: formResult.file,
                organizationId,
            }),
        validate: formInitialData => ({
            file: isBlank(formInitialData.file) ? 'Users file is required' : undefined,
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
