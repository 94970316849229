import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    contractsCacheKey,
    contractService,
    invoiceEntriesQueryKey,
    RolloverContractPayload,
} from '@hofy/api-admin';
import { DateString, Price, UUID, zeroDollars } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const useRolloverContract = (
    contract: {
        id: UUID;
        rolloverPrice: Price | null;
        estimatedEndOfContract: DateString | null;
    },
    onEnd: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (p: RolloverContractPayload) => contractService.rolloverContract(contract.id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [contractsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract.id],
            });
            showToast({
                type: 'positive',
                message: 'Contract rolled over',
            });
            onEnd();
        },
    });

    const form = useForm<RolloverContractPayload>({
        initial: {
            rolloverPrice: contract.rolloverPrice || zeroDollars,
            rolloverDate: contract.estimatedEndOfContract || nowISODate(),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
    });
    return {
        rollover: mutation.mutate,
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
