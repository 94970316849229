import React, { FC } from 'react';

import { FormFieldApi } from '../../../hooks';
import { Optional } from '../../../types/Utils';
import { LabeledCheckbox, LabeledCheckboxProps } from './LabeledCheckbox';

type FormCheckboxOptionalProps = Optional<LabeledCheckboxProps, 'onChange'>;
type FormCheckboxOmittedProps = Omit<FormCheckboxOptionalProps, 'errorMessage' | 'checked'>;

interface FormCheckboxProps extends FormCheckboxOmittedProps {
    api: FormFieldApi<boolean>;
}

export const FormCheckbox: FC<FormCheckboxProps> = ({ api, onChange, onBlur, ...checkboxProps }) => {
    return (
        <LabeledCheckbox
            {...checkboxProps}
            ref={api.ref}
            checked={api.value}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
            errorMessage={api.errorMessage}
        />
    );
};
