export interface TermAndConditionPayload {
    name: string;
    url: string | null;
    isDefault: boolean;
}

export const emptyTermAndConditionPayload: TermAndConditionPayload = {
    name: '',
    url: null,
    isDefault: false,
};
