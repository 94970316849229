import React, { FC } from 'react';

import { ContactPayload, emptyContactPayload } from '@hofy/api-admin';
import { SeparatedContainer } from '@hofy/common';
import { Alert, ArrayField, Button, FormSection, LabelError, SvgIcon } from '@hofy/ui';

import { ContactForm } from './ContactForm';

interface ContactsSectionProps {
    contacts: ArrayField<ContactPayload>;
    twoApiEnabled: boolean;
    error?: string;
}

export const ContactsSection: FC<ContactsSectionProps> = ({ contacts, twoApiEnabled, error }) => {
    return (
        <FormSection label='Contact' column>
            {twoApiEnabled && (
                <Alert
                    type='informative'
                    description='Two integration requires contact information for the company'
                />
            )}
            {!!contacts.fields.length && (
                <SeparatedContainer lined orientation='vertical' spacing={20}>
                    {contacts.fields.map((field, index) => (
                        <ContactForm
                            key={field.key}
                            contact={field.api}
                            onDeleteItem={() => contacts.remove(field.key)}
                            canBeDeleted={contacts.fields.length > 1}
                            isRequired={index === 0 && twoApiEnabled}
                        />
                    ))}
                </SeparatedContainer>
            )}
            {error && <LabelError message={error} />}
            <Button
                label='Add new item'
                type='secondary'
                leftIcon={SvgIcon.Add}
                onClick={() => contacts.add(emptyContactPayload)}
            />
        </FormSection>
    );
};
