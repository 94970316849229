import { useMutation } from '@tanstack/react-query';

import { invoicesService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useDownloadInvoice = () => {
    const mutation = useMutation({
        mutationFn: invoicesService.downloadInvoiceFile,
    });

    const download = (fileId: UUID) => {
        mutation.mutate(fileId);
    };

    return {
        download,
        isDownloadInvoiceLoading: mutation.isPending,
    };
};
