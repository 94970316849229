import React, { FC } from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import { InspectionStatus } from '@hofy/api-shared';

import { InspectionProgressSection } from './InspectionProgressSection';
import { InspectionProgressStartPromptSection } from './InspectionProgressStartPromptSection';

interface InspectionProgressProps {
    inspection: InspectionDetailsUnionDto;
}

export const InspectionProgressTab: FC<InspectionProgressProps> = ({ inspection }) => {
    return (
        <>
            {inspection.status === InspectionStatus.Pending && (
                <InspectionProgressStartPromptSection inspection={inspection} />
            )}

            {[InspectionStatus.InProgress, InspectionStatus.Completed].includes(inspection.status) && (
                <InspectionProgressSection inspection={inspection} />
            )}
        </>
    );
};
