import React, { FC, useState } from 'react';

import { emptySupplierContactPayload, SupplierContactPayload } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader, WizardSimpleFooter } from '@hofy/common';
import { arrayRemove, arrayUpdateAt } from '@hofy/helpers';
import { Box, Button, FormSection, SvgIcon, useFormObjectField } from '@hofy/ui';

import { useCreateSupplier } from '../../../../store/suppliers/useCreateSupplier';
import { SupplierContactFormSlideout } from '../components/SupplierContactFormSlideout';
import { SupplierContactsTable } from '../components/SupplierContactsTable';
import { SupplierAddressForm } from './components/SupplierAddressForm';
import { SupplierBasicDataForm } from './components/SupplierBasicDataForm';

interface NewSupplierSlideoutProps {
    onClose(): void;
}

export const CreateSupplierSlideout: FC<NewSupplierSlideoutProps> = ({ onClose }) => {
    const { form, isLoading } = useCreateSupplier(onClose);

    const [contactFormEditIndex, setContactFormEditIndex] = useState<number | null>(null);
    const [contactFormAdd, setContactFormAdd] = useState<boolean>(false);

    const billingAddressForm = useFormObjectField(form.forms.step2.fields.billingAddress);
    const shippingAddressForm = useFormObjectField(form.forms.step3.fields.shippingAddress);
    const onSubmit = () => {
        form.submit();
    };
    const handleAddContact = (p: SupplierContactPayload) => {
        setContactFormAdd(false);
        form.forms.step4.fields.contacts.setValue([...form.forms.step4.values.contacts, p]);
    };
    const handleUpdateContact = (index: number, p: SupplierContactPayload) => {
        setContactFormEditIndex(null);
        form.forms.step4.fields.contacts.setValue(
            arrayUpdateAt(form.forms.step4.values.contacts, index, () => p),
        );
    };
    const handleDeleteContact = (index: number) => {
        setContactFormEditIndex(null);
        form.forms.step4.fields.contacts.setValue(arrayRemove(form.forms.step4.values.contacts, index));
    };
    const copyBillingAddress = () => {
        form.forms.step3.setValues({ shippingAddress: form.forms.step2.values.billingAddress });
    };

    const content = () => {
        switch (form.step) {
            case 'step1': // Basic Supplier data
                return <SupplierBasicDataForm form={form.forms.step1} creating />;
            case 'step2':
                return (
                    <SupplierAddressForm
                        form={billingAddressForm}
                        label='Billing address'
                        locatedIn={form.forms.step1.values.locatedIn!}
                    />
                );
            case 'step3':
                return (
                    <SupplierAddressForm
                        form={shippingAddressForm}
                        label='Shipping address'
                        locatedIn={form.forms.step1.values.locatedIn!}
                    />
                );

            case 'step4': // Supplier contacts
                return (
                    <FormSection label='Contacts' paddingTop={16} overflow='hidden'>
                        <SupplierContactsTable
                            contacts={form.forms.step4.values.contacts}
                            columns={2}
                            onEditContact={index => setContactFormEditIndex(index)}
                            onDeleteContact={handleDeleteContact}
                            summarize
                        />
                    </FormSection>
                );
        }
    };

    return (
        <>
            <Slideout width={800} onClose={onClose} slideoutId='supplier'>
                <SlideoutHeader title='New Supplier'>
                    <Box row flex={1} justify='flex-end' gap={24}>
                        {form.step === 'step4' && (
                            <Button
                                leftIcon={SvgIcon.Add}
                                label='Add Contact'
                                onClick={() => setContactFormAdd(true)}
                            />
                        )}
                        {form.step === 'step3' && (
                            <Button label='Copy billing address' onClick={copyBillingAddress} />
                        )}
                    </Box>
                </SlideoutHeader>
                <SlideoutContent>
                    <Box fullWidth paddingVertical={20}>
                        {content()}
                    </Box>
                </SlideoutContent>
                <SlideoutFooter>
                    <WizardSimpleFooter
                        step={form.step}
                        steps={form.stepList}
                        onCancel={onClose}
                        nextLabel='Next'
                        cancelLabel='Cancel'
                        prevLabel='Previous'
                        onSubmit={onSubmit}
                        isSubmitLoading={isLoading}
                        onChangeStep={form.goToStep}
                        submitLabel='Save'
                    />
                </SlideoutFooter>
            </Slideout>
            {contactFormAdd && (
                <SupplierContactFormSlideout
                    payload={emptySupplierContactPayload}
                    onSave={handleAddContact}
                    onClose={() => setContactFormAdd(false)}
                />
            )}
            {contactFormEditIndex !== null && (
                <SupplierContactFormSlideout
                    payload={form.forms.step4.values.contacts[contactFormEditIndex]}
                    onSave={p => handleUpdateContact(contactFormEditIndex, p)}
                    onClose={() => setContactFormEditIndex(null)}
                />
            )}
        </>
    );
};
