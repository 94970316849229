import { ActivityLogEventKind } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

const ActivityLogIcons: Record<ActivityLogEventKind, Svg> = {
    [ActivityLogEventKind.AssetCreatedWithUser]: SvgIcon.User,
    [ActivityLogEventKind.AssetCreatedAtWarehouse]: SvgIcon.Warehouse,
    [ActivityLogEventKind.AssetPendingDelivery]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetPendingCollection]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetPendingTransfer]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetCollectedByCourier]: SvgIcon.Truck,
    [ActivityLogEventKind.AssetTransferredBetweenUsers]: SvgIcon.Send,
    [ActivityLogEventKind.AssetArchived]: SvgIcon.Box,
    [ActivityLogEventKind.AssetCollectedToWarehouse]: SvgIcon.Warehouse,
    [ActivityLogEventKind.AssetDeliveredFromWarehouse]: SvgIcon.User,
    [ActivityLogEventKind.AssetPendingRepair]: SvgIcon.Tool,
    [ActivityLogEventKind.AssetRepairResolved]: SvgIcon.Tool,
    [ActivityLogEventKind.AssetInspectionCompleted]: SvgIcon.Eye,
    [ActivityLogEventKind.CollectionRequestCreated]: SvgIcon.PackageSearch,
    [ActivityLogEventKind.CollectionRequestEscalated]: SvgIcon.PackageSearch,
    [ActivityLogEventKind.CollectionRequestCompleted]: SvgIcon.PackageSearch,
    [ActivityLogEventKind.CollectionRequestCancelled]: SvgIcon.PackageSearch,
    [ActivityLogEventKind.CollectionRequestEmailSent]: SvgIcon.MessageAlert,
    [ActivityLogEventKind.CollectionRequestEmailReminderSent]: SvgIcon.MessageAlert,
    [ActivityLogEventKind.CollectionRequestSmsReminderSent]: SvgIcon.MessageAlert,
    [ActivityLogEventKind.CollectionBooked]: SvgIcon.Truck,
    [ActivityLogEventKind.CollectionCompleted]: SvgIcon.Warehouse,
};

export const getActivityLogIcon = (kind: ActivityLogEventKind): Svg => {
    return ActivityLogIcons[kind] ?? SvgIcon.Calendar;
};
