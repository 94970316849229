import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inspectionService, inspectionsQueryKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useStartInspection = (uuid: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => inspectionService.startInspection(uuid),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey] });
            onSuccess?.();
        },
    });

    return {
        startInspection: () => mutation.mutate(),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
