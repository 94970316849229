import { useMutation } from '@tanstack/react-query';

import { exportImportService } from '@hofy/api-admin';

export const useExport = () => {
    const exportMutation = useMutation({
        mutationFn: exportImportService.export,
    });
    return {
        exportData: exportMutation.mutate,
        isExportInProgress: exportMutation.isPending,
    };
};
