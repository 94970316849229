import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { Labeled, TwoLineSmallCell } from '@hofy/ui';

import { getPurchaseOrderLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface PurchaseOrderLinkProps {
    purchaseOrderId?: UUID;
    purchaseOrderReference?: string;
}

export const PurchaseOrderLink: FC<PurchaseOrderLinkProps> = ({
    purchaseOrderId,
    purchaseOrderReference,
}) => {
    const reference = purchaseOrderReference ?? '--';
    return (
        <Labeled
            label='Purchase order'
            content={
                purchaseOrderId ? (
                    <Link to={getPurchaseOrderLink(purchaseOrderId)} underline={false}>
                        <TwoLineSmallCell line1={reference} line2={`#${purchaseOrderId}`} />
                    </Link>
                ) : (
                    <TwoLineSmallCell line1={reference} line2='' />
                )
            }
        />
    );
};
