import React, { FC } from 'react';

import { ActivityLogDto, useTrActivityLogType } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import {
    BooleanBadge,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';

import { ShipmentLinkLabel } from '../../../components/domain/shipments/ShipmentLink';
import { useHideActivityLog } from '../../../store/activityLogs/useHideActivityLog';
import { ItemDetailsLink } from '../../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { UserDetailsLink } from '../../peoplePage/users/UserLink';
import { OrderDetailsLink } from '../../requestsPage/components/OrderDetailsLink';
import { ActivityLogActions } from './ActivityLogActions';
import { ActivityLogSourceChip } from './ActivityLogSourceChip';

interface ActivityLogTableProps {
    activityLogs: ActivityLogDto[];
    infinityScroll: InfiniteScrollConfig;
}

export const ActivityLogTable: FC<ActivityLogTableProps> = ({ activityLogs, infinityScroll }) => {
    const trActivityLogType = useTrActivityLogType();
    const { hideActivityLog } = useHideActivityLog();
    return (
        <InfinityScrollTable
            data={activityLogs}
            toKey={activityLog => activityLog.id}
            infinityScroll={infinityScroll}
            minWidth={1400}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.List}
                    title='No activity logs'
                    message='No activity logs for selected criteria'
                />
            }
            columns={[
                {
                    id: 'activityAt',
                    header: 'Activity at',
                    flexGrow: 1,
                    width: 200,
                    renderer: activityLog => formatDateTime(activityLog.activityAt),
                },
                {
                    id: 'source',
                    header: 'Source',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => <ActivityLogSourceChip activityLogSource={activityLog.source} />,
                },
                {
                    id: 'type',
                    header: 'Log type',
                    flexGrow: 1,
                    width: 200,
                    renderer: activityLog => <TextCell>{trActivityLogType(activityLog.logType)}</TextCell>,
                },
                {
                    id: 'organization',
                    header: 'Organization',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => (
                        <TextCell>
                            <OrganizationLink id={activityLog.organization.id}>
                                {activityLog.organization.name}
                            </OrganizationLink>
                        </TextCell>
                    ),
                },
                {
                    id: 'item',
                    header: 'Item',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => (
                        <TextCell>
                            {activityLog.item ? <ItemDetailsLink item={activityLog.item} /> : '--'}
                        </TextCell>
                    ),
                },
                {
                    id: 'order',
                    header: 'Order',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => (
                        <TextCell>
                            {activityLog.order ? <OrderDetailsLink order={activityLog.order} /> : '--'}
                        </TextCell>
                    ),
                },
                {
                    id: 'user',
                    header: 'User',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => (
                        <TextCell>
                            {activityLog.user ? <UserDetailsLink user={activityLog.user} /> : '--'}
                        </TextCell>
                    ),
                },
                {
                    id: 'shipment',
                    header: 'Shipment',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => (
                        <TextCell>
                            {activityLog.shipment ? (
                                <ShipmentLinkLabel shipment={activityLog.shipment} />
                            ) : (
                                '--'
                            )}
                        </TextCell>
                    ),
                },
                {
                    id: 'hidden',
                    header: 'Hidden for customers',
                    flexGrow: 1,
                    width: 150,
                    renderer: activityLog => <BooleanBadge value={activityLog.hidden} />,
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    flexGrow: 0,
                    renderer: activityLog => (
                        <ActivityLogActions
                            handleHideActivityLog={hideActivityLog}
                            activityLog={activityLog}
                        />
                    ),
                },
            ]}
        />
    );
};
