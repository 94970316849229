import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { twoFactorService } from '@hofy/api-auth';
import { downloadFile } from '@hofy/helpers';

import { sessionCacheKey } from '../session/useSessionQuery';

export enum TwoFactorState {
    QR,
    Verified,
    CodesDownloaded,
}

export const useTwoFactor = (onSet?: () => void) => {
    const [twoFactorState, setTwoFactorState] = useState<TwoFactorState>(TwoFactorState.QR);
    const [verificationError, setVerificationError] = useState(false);
    const generateMutation = useQuery({
        queryKey: ['auth/2fa/config'],
        queryFn: twoFactorService.generateConfig,
        refetchOnWindowFocus: false,
    });
    const queryClient = useQueryClient();
    const enableMutation = useMutation({
        mutationFn: twoFactorService.enableTwoFactor,
        onSuccess: () => {
            onSet?.();
            queryClient.invalidateQueries({ queryKey: [sessionCacheKey] });
        },
    });
    const enableTwoFactor = () => {
        if (!generateMutation.data) {
            return;
        }
        enableMutation.mutate({
            secret: generateMutation.data.secret,
            recoveryCodes: generateMutation.data.recoveryCodes,
        });
    };
    const validateMutation = useMutation({
        mutationFn: twoFactorService.validateTwoFA,
        onSuccess: valid => {
            if (valid) {
                setTwoFactorState(TwoFactorState.Verified);
            } else {
                setVerificationError(true);
            }
        },
    });
    const downloadCodes = () => {
        const data = generateMutation.data!.recoveryCodes.join('\n');
        const blob = new Blob([data], { type: 'text/plain' });
        downloadFile(blob, 'hofy-2fa-codes.txt');
        setTwoFactorState(TwoFactorState.CodesDownloaded);
    };
    const validate = (code: string, secret: string) => {
        setVerificationError(false);
        validateMutation.mutate({
            code,
            secret,
        });
    };

    return {
        isLoadingGenerateConfig: generateMutation.isPending,
        isLoadingEnableTwoFactor: enableMutation.isPending,
        isLoadingValidate: validateMutation.isPending,
        twoFactorConfig: generateMutation.data,
        twoFactorState,
        verificationError,
        enableTwoFactor,
        validate,
        downloadCodes,
    };
};
