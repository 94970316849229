import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { LabeledLink } from '@hofy/ui';

import { Link } from '../../components/routing/Link';

interface ItemLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const ItemLink: FC<ItemLinkProps> = ({ id, children }) => {
    return <Link to={`/items/item/${id}`}>{children}</Link>;
};

interface ItemDetailsLinkProps {
    item: PublicRefDto;
}

export const ItemDetailsLink: FC<ItemDetailsLinkProps> = ({ item }) => {
    return <ItemLink id={item.id}>{item.publicId}</ItemLink>;
};

interface LabeledItemLinkProps {
    item: PublicRefDto | null;
    label?: string;
}

export const LabeledItemLink: FC<LabeledItemLinkProps> = ({ label = 'Item', item }) => {
    return <LabeledLink label={label} content={item ? <ItemDetailsLink item={item} /> : '--'} />;
};
