import { ProductCategory, useTrProductCategory } from '@hofy/api-shared';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useCategoryFilterDefinition = (
    allValues: ProductCategory[],
): FilterDefinitionMulti<ProductCategory> => {
    const trProductCategory = useTrProductCategory();

    return {
        type: 'multi',
        name: 'Category',
        query: queryStringArrayParser(),
        allValues,
        toLabel: trProductCategory,
    };
};
