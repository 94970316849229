import React, { FC } from 'react';

import { UploadFile } from '@hofy/common';
import { Modal, ModalContent, ModalHeader } from '@hofy/ui';

import { useUploadMinAvailabilityLevels } from '../../../../store/stockLevels/useUploadMinAvailabilityLevels';

interface MinAvailabilityModalProps {
    onClose(): void;
}

export const MinAvailabilityModal: FC<MinAvailabilityModalProps> = ({ onClose = () => {} }) => {
    const { file, upload, isLoading } = useUploadMinAvailabilityLevels(onClose);

    return (
        <Modal onClose={onClose} width={400}>
            <ModalHeader title='Upload minimum availability' />
            <ModalContent>
                <UploadFile
                    label='Select file'
                    value={file}
                    accept='text/xlsx'
                    note='Accepted files: .xlsx'
                    onChange={upload}
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </ModalContent>
        </Modal>
    );
};
