import { useQuery } from '@tanstack/react-query';

import { termAndConditionService } from '../service/termAndConditionService';
import { TermAndConditionDto } from '../service/types/TermAndConditionDto';
import { termAndConditionsCacheKey } from './cacheKey';

const emptyTermsAndConditions: TermAndConditionDto[] = [];

export const useTermsAndConditionsQuery = () => {
    const { isLoading, data } = useQuery({
        queryKey: [termAndConditionsCacheKey],

        queryFn: () => termAndConditionService.getTermsAndConditions(),
    });

    return {
        isLoading,
        data: data || emptyTermsAndConditions,
    };
};
