import React, { FC } from 'react';

import { emptyAdminPayload } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Box, Button, Form, SubmitButton, SvgIcon } from '@hofy/ui';

import { useAdminForm } from '../../../../store/admin/useAdminForm';
import { useCreateAdmin } from '../../../../store/admin/useCreateAdmin';
import { AdminForm } from '../../admins/components/AdminForm';

interface CreateAdminSlideoutProps {
    onClose(): void;
}

export const CreateAdminSlideout: FC<CreateAdminSlideoutProps> = ({ onClose }) => {
    const { createAdmin, isCreateAdminLoading } = useCreateAdmin(onClose);
    const form = useAdminForm(emptyAdminPayload, createAdmin, onClose);

    return (
        <Slideout width={600} onClose={form.discard}>
            <Form onSubmit={form.submit} isLoading={isCreateAdminLoading} flex='auto' column>
                <SlideoutHeader title='Create admin' />
                <SlideoutContent relative>
                    <AdminForm form={form} />
                </SlideoutContent>
                <SlideoutFooter>
                    <Button
                        type='ghost'
                        negativeMargin
                        onClick={form.discard}
                        label='Cancel'
                        leftIcon={SvgIcon.Cross}
                    />
                    <Box row>
                        <SubmitButton label='Create' />
                    </Box>
                </SlideoutFooter>
            </Form>
        </Slideout>
    );
};
