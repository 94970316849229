import React, { FC } from 'react';

import { allRegions, AnyRegion } from '@hofy/global';
import { useTrRegion } from '@hofy/i18n';
import { Icon, SectionTitle3, SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';

interface RegionBlockFilterProps {
    selected: AnyRegion[];
    onChange(region: AnyRegion[]): void;
    title?: string;
}

export const RegionBlockFilter: FC<RegionBlockFilterProps> = ({ selected, onChange, title = 'Regions' }) => {
    const trRegion = useTrRegion();

    return (
        <ListMultiBlockFilter
            title={
                <>
                    <Icon svg={SvgIcon.Globe} size={16} marginRight={10} />
                    <SectionTitle3 textNoWrap ellipsis overflow='hidden'>
                        {title}
                    </SectionTitle3>
                </>
            }
            icon={SvgIcon.Globe}
            selected={selected}
            onChange={onChange}
            items={allRegions}
            renderItem={type => trRegion(type)}
        />
    );
};
