import { AnyProductCategory, StockCondition } from '@hofy/api-shared';
import { Country } from '@hofy/global';

import { Availability, availabilityAll } from './Availability';
import { ProductStatus } from './ProductStatus';

export interface ProductFilters {
    category: AnyProductCategory | null;
    country: Country | null;
    productStatus: ProductStatus;
    availability: Availability;
    stockCondition: StockCondition;
    search: string;
}

export const emptyProductFilters: ProductFilters = {
    category: null,
    country: null,
    productStatus: ProductStatus.Active,
    availability: availabilityAll,
    search: '',
    stockCondition: StockCondition.Any,
};
