import React, { FC } from 'react';

import {
    AssignmentCollectionReason,
    assignmentCollectionReasonColor,
    useTrAssignmentCollectionReason,
} from '@hofy/api-shared';
import { Badge, Box, BoxProps } from '@hofy/ui';

interface AssignmentCollectionReasonProps extends BoxProps {
    reason: AssignmentCollectionReason;
}

export const AssignmentCollectionReasonCell: FC<AssignmentCollectionReasonProps> = ({
    reason,
    ...boxProps
}) => {
    const trItemCollectionReason = useTrAssignmentCollectionReason();
    return (
        <Box row {...boxProps}>
            <Badge color={assignmentCollectionReasonColor[reason]} label={trItemCollectionReason(reason)} />
        </Box>
    );
};
