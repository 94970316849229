import { passportPaneI18n } from './passportPaneI18n';
import { taxIdLabelI18n } from './taxIdLabelI18n';
import { taxIdPaneI18n } from './taxIdPane';

export const addressI18n = {
    'address.country.label': 'Country',
    'address.country.error.required': 'Country is required',

    'address.line1.label': 'Address line 1',
    'address.line1.error.required': 'Address line 1 is required',

    'address.line2.label': 'Address line 2',
    'address.line2.error.required': 'Address line 2 is required',

    'address.state.label': 'State',
    'address.state.placeholder': 'Select state',
    'address.state.error.required': 'State is required',
    'address.state.error.usa': 'State is invalid',
    'address.state-province-region.label': 'State/Province/Region',

    'address.city.label': 'City',
    'address.city.error.required': 'City is required',

    'address.postcode.label': 'Postal Code/ZIP',
    'address.postcode.error.required': 'Postal Code/ZIP is required',
    'address.postcode.error.max': 'Postal Code/ZIP cannot be longer than 11 characters',

    'address.phone-number.label': 'Phone number',
    'address.phone-number.error.required': 'Phone number is required',
    'address.phone-number.error.min': 'Phone number cannot be less than 7 digits',
    'address.phone-number.error.max': 'Phone number cannot be greater than 15 digits',
    'address.phone-number.error.country-code': 'Phone number must start with valid country code',

    'address.delivery-instructions.label': 'Courier notes',
    'address.delivery-instructions.note': 'Max 300 characters',
    'address.delivery-instructions.error.max': 'Courier notes cannot be longer than 300 characters',

    'address.date-of-birth.label': 'Date of birth',
    'address.date-of-birth.error.required': 'Date of birth should not be empty',

    'address.passport-number.label': 'Passport number',
    'address.passport-number.error.required': 'Passport number should not be empty',

    'address.tax-id.error.required': '{value} is required',

    'address.address.title': 'Address',
    'address.additional-fields.title': 'Additional country information',
    'address.additional-fields.tooltip.text':
        'This information is required. Read more about the requirements for this country ',
    'address.additional-fields.tooltip.link': 'here',

    ...taxIdLabelI18n,
    ...taxIdPaneI18n,
    ...passportPaneI18n,

    'address.address-hidden': 'Address hidden',
    'address.address-invalid': 'Address is not valid',
    'address.address-hidden.tooltip':
        'Address is hidden because you do not have permission to view personally identifiable information (PII) of the team member',
    'address.address-invalid.tooltip':
        'Address provided by the team member is not valid. You cannot provide it yourself because access to personal identifiable information is not permitted',
};

export type AddressI18nKey = keyof typeof addressI18n;
