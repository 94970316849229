import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { ApiKeyDto } from './types/ApiKeyDto';
import { CreateApiKeyPayload } from './types/CreateApiKeyPayload';

export interface CreateApiKeyResponse {
    value: string;
}

class ApiKeyService {
    public getApiKeys = async (): Promise<ApiKeyDto[]> => {
        return restClient.getJson<ApiKeyDto[]>(`/api/auth/api-keys`);
    };

    public createApiKey = async (payload: CreateApiKeyPayload): Promise<CreateApiKeyResponse> => {
        return restClient.postJson<CreateApiKeyResponse>(`/api/auth/api-keys`, payload);
    };

    public revokeApiKey = async (id: UUID): Promise<void> => {
        return restClient.post(`/api/auth/api-keys/${id}/revoke`);
    };
}

export const apiKeyService = new ApiKeyService();
