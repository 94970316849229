import React from 'react';

import { invoiceMonths } from '@hofy/api-admin';
import { DateString } from '@hofy/global';
import { parseDateTime } from '@hofy/helpers';
import { MonthLabel } from '@hofy/ui';

import { ListBlockFilter } from '../../../../components/design/blockFilters/ListBlockFilter';

interface InvoiceDateBlockFilterProps {
    selected: DateString | null;
    onChange(v: DateString | null): void;
}

export const InvoiceDateBlockFilter = ({ selected, onChange }: InvoiceDateBlockFilterProps) => {
    return (
        <ListBlockFilter
            title='Date'
            items={invoiceMonths}
            selected={selected ? parseDateTime(selected) : undefined}
            onChange={v => onChange(v ? (v.toISODate()! as DateString) : null)}
            renderItem={date => <MonthLabel date={date} />}
            compareIteratee={date => date.toISO()}
        />
    );
};
