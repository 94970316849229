import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { itemDepreciationService } from '../service/itemDepreciationService';
import { FixedAssetValuationFilters } from '../service/types/FixedAssetValuationFilters';
import { fixedAssetValuationListCacheKey } from './cacheKey';

export const useFixedAssetValuationListQuery = (filters: FixedAssetValuationFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [fixedAssetValuationListCacheKey, filters],
        queryFn: param =>
            itemDepreciationService.listFixedAssetValuation(filters, {
                page: param.pageParam,
                pageSize: 30,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const fixedAssetValuations = useMemo(() => {
        if (data === undefined) {
            return [];
        }
        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        fixedAssetValuations,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
