import React, { FC } from 'react';

import { FormFieldApi } from '../../../hooks';
import { TestKeyAware } from '../../../types';
import { Optional } from '../../../types/Utils';
import { LabeledInput, LabeledInputProps } from './LabeledInput';

type FormInputOptionalProps = Optional<LabeledInputProps, 'onChange'>;
type FormInputOmittedProps = Omit<FormInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalInputProps extends FormInputOmittedProps, TestKeyAware {
    api: FormFieldApi<string>;
    nullable?: false;
}

export interface FormNullableInputProps extends FormInputOmittedProps, TestKeyAware {
    api: FormFieldApi<string | null>;
    nullable: true;
}

export type FormInputProps = FormNormalInputProps | FormNullableInputProps;

export const FormInput: FC<FormInputProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
