export enum AuditableTable {
    Organization = 'organizations',
    Item = 'items',
    Repair = 'repairs',
    Shipment = 'shipments',
    Assignment = 'assignments',
    Contracts = 'contracts',

    RentalContracts = 'rental_contracts',
    Product = 'products',
    User = 'users',
    Variant = 'variants',
    ApiKeys = 'api_keys',
    Supplier = 'suppliers',
    PurchaseOrder = 'purchase_orders',
    BYODOrder = 'byod_orders',
    AddonDiscounts = 'addon_discounts',
    Addons = 'addons',
}
