import { createContext, useContext } from 'react';

interface FormDisableContextType {
    disabled: boolean;
}

export const DisableContext = createContext<FormDisableContextType>({
    disabled: false,
});

export const useDisableContext = () => useContext(DisableContext);
