import React, { FC, useEffect, useState } from 'react';

import { useUsersQuery } from '@hofy/api-admin';
import { allManagerRoles, Role, useTrRole } from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { ActiveFilters, ActiveFiltersLayout, Box, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { BooleanBlockFilter } from '../../../components/design/blockFilters/BooleanBlockFilter';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { OrganizationBlockFilter } from '../../../components/domain/filters/OrganizationBlockFilter';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';
import { useUserFilters } from '../../../store/users/useUsersFilters';
import { PeopleTab } from '../PeopleTab';
import { PeopleTabs } from '../PeopleTabs';
import { UsersTable } from './UsersTable';

interface UsersPageProps {
    onOpenUser(id: UUID): void;
}

export const UsersPage: FC<UsersPageProps> = ({ onOpenUser }) => {
    const trRole = useTrRole();
    const [organizationValues, setOrganizationValues] = React.useState<UUID[]>([]);
    const [orgSearchQuery, setOrgSearchQuery] = useState('');
    const { data: organizations } = useOrganizationsRefsQuery(organizationValues, orgSearchQuery);
    const { filters, filterValues, clearFilters, filterCount } = useUserFilters({
        organizations,
    });
    // TODO this is pretty ugly, but there is no better way to do that for now
    useEffect(() => {
        setOrganizationValues(filterValues.organizations);
    }, [filterValues.organizations]);

    const { users, hasNextPage, isFetchingNextPage, usersIsLoading, fetchNextPage } =
        useUsersQuery(filterValues);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page>
            <PageHeader
                title='Users'
                rightSlot={
                    <>
                        <SearchInput
                            value={filters.search.value}
                            onChange={filters.search.set}
                            placeholder='User name or email…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<PeopleTabs active={PeopleTab.Users} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <OrganizationBlockFilter
                    selected={filterValues.organizations}
                    onChange={filters.organizations.set}
                    organizations={organizations}
                    searchQuery={orgSearchQuery}
                    setSearchQuery={setOrgSearchQuery}
                />
                <EnumMultiBlockFilter<Role>
                    title='Role'
                    selected={filters.roles.value}
                    onChange={filters.roles.set}
                    items={allManagerRoles}
                    renderItem={trRole}
                />
                <BooleanBlockFilter
                    filter={filters.isDeel}
                    title='Deel'
                    labelForTrue='Yes'
                    labelForFalse='No'
                />
            </BlockFilterContainer>
            <Box>
                <ActiveFiltersLayout show={filterCount > 0}>
                    <ActiveFilters filters={filters} onClearAll={clearFilters} />
                </ActiveFiltersLayout>
            </Box>
            <UsersTable
                users={users}
                onOpenUser={onOpenUser}
                search={filters.search.value}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: usersIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Page>
    );
};
