import { Percent } from '@hofy/global';

export const useDecimal = () => {
    const formatDecimal = (num: string, precision: number = 2): string => {
        const value = parseFloat(num);
        return value.toFixed(precision);
    };
    const formatRate = (num: string, precision: number = 6): string => {
        const value = parseFloat(num);
        return value.toFixed(precision);
    };

    const formatPercent = (num: Percent, precision: number = 2): string => {
        const value = parseFloat(num);
        return `${value.toFixed(precision)}%`;
    };

    return {
        formatRate,
        formatDecimal,
        formatPercent,
    };
};
