import { Palette } from '@hofy/theme';

export const NavigationColors = {
    Background: 'linear-gradient(180deg, #4e38bc 0%, #39288a 54.1%)',

    ActiveItem: Palette.white['-6'],
    Hover: Palette.white['-9'],
    SubtleIcon: Palette.white['-5'],

    ProfileBackground: Palette.white['-8'],
    ProfileBackgroundOverlay: Palette.violet['0'],
    ProfileItemHover: Palette.white['-8'],
};
