import React, { Key, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Box, Paragraph3, Pressable } from '../base';
import { Icon } from '../icon';

export interface PillTabProps<T extends Key> extends TestKeyAware {
    id: T;
    icon?: Svg;
    label: string;
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    disabled?: boolean;
    // Props from parent, you should not pass them manually
    active?: boolean;
    onClick?(id: T): void;
}

export const PillTab = <T extends Key>({
    id,
    icon,
    label,
    leftSlot,
    rightSlot,
    disabled,
    active,
    onClick,
    testKey,
}: PillTabProps<T>) => {
    return (
        <Box relative flex={1}>
            <PillTabPressable
                onClick={() => onClick?.(id)}
                active={active}
                inactive={disabled}
                activeEffect
                relative
                zIndex={2}
                fullWidth
                row
                justify='center'
                gap={8}
                paddingHorizontal={16}
                paddingVertical={8}
                rounded={8}
                color={active ? Color.ContentPrimary : Color.ContentSecondary}
                data-test-key={testKey}
            >
                {icon && <Icon svg={icon} size={16} color='currentColor' />}
                {leftSlot}
                <Paragraph3 color='currentColor' bold lineHeight='large'>
                    {label}
                </Paragraph3>
                {rightSlot}
            </PillTabPressable>
            {active && (
                <Active
                    border
                    borderColor={Color.NonContextualBorderDivider}
                    bg={Color.BackgroundDefault}
                    rounded={8}
                />
            )}
        </Box>
    );
};

const PillTabPressable = styled(Pressable)<{ active?: boolean }>`
    ${p =>
        !(p.active || p.inactive) &&
        css`
            &:hover {
                color: ${Color.ContentPrimary};
            }
        `}
`;

const Active = styled(Box)`
    position: absolute;
    inset: 0;
    z-index: 1;
`;
