import React, { FC } from 'react';

import { allCountries, Country } from '@hofy/global';
import { useTrCountryShortname } from '@hofy/i18n';

import { Optional } from '../../../../types/Utils';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
    LabeledSelectSearchProps,
} from '../LabeledSelectSearch';
import { CountryLabel } from './CountryLabel';

type OmitAndOptional<T extends LabeledSelectSearchProps<any>> = Omit<
    Optional<T, 'options'>,
    'toLabel' | 'toText'
>;

export type LabeledCountrySelectNotNullableProps = OmitAndOptional<
    LabeledSelectSearchOnlyStringProps<Country>
>;
export type LabeledCountrySelectNullableProps = OmitAndOptional<
    LabeledSelectSearchNullableStringProps<Country | null>
>;

export type LabeledCountrySelectProps =
    | LabeledCountrySelectNotNullableProps
    | LabeledCountrySelectNullableProps;

export const LabeledCountrySelect: FC<LabeledCountrySelectProps> = ({
    label,
    errorMessage,
    isRequired,
    onChange,
    options = allCountries,
    ...rest
}) => {
    const trCountry = useTrCountryShortname();
    return (
        <LabeledSelectSearch
            label={label}
            toText={trCountry}
            toLabel={country => <CountryLabel country={country} />}
            onChange={onChange}
            options={options}
            isRequired={isRequired}
            errorMessage={errorMessage}
            {...rest}
        />
    );
};
