import { useQuery } from '@tanstack/react-query';

import { productCacheKey, productsService } from '@hofy/api-admin';
import { VariantRefDto } from '@hofy/api-admin';

const emptyVariants: VariantRefDto[] = [];
export const useVariantRefsQuery = (search: string) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [productCacheKey, search],
        queryFn: () => productsService.getVariantRefs(search),
    });
    return {
        isLoading,
        isError,
        data: data || emptyVariants,
    };
};
