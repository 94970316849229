import React, { FC } from 'react';

import {
    ContractStatusFilter,
    ManagementContractStatus,
    PurchaseContractStatus,
    purchaseContractStatusColors,
    RentalContractStatus,
    rentalContractStatusColors,
    useTrPurchaseContractStatus,
    useTrRentalContractStatus,
} from '@hofy/api-shared';
import { isValidEnumEntry } from '@hofy/global';
import { Badge } from '@hofy/ui';

interface ContractStatusChipProps {
    status: RentalContractStatus | PurchaseContractStatus | ManagementContractStatus | ContractStatusFilter;
}

export const ContractStatusChip: FC<ContractStatusChipProps> = ({ status }) => {
    if (isValidEnumEntry(RentalContractStatus)(status)) {
        return <RentalContractStatusChip status={status as RentalContractStatus} />;
    }
    return <PurchaseContractStatusChip status={status as PurchaseContractStatus} />;
};

interface PurchaseContractStatusChipProps {
    status: PurchaseContractStatus;
}

const PurchaseContractStatusChip: FC<PurchaseContractStatusChipProps> = ({ status }) => {
    const trStatus = useTrPurchaseContractStatus();
    const color = purchaseContractStatusColors[status];
    return <Badge color={color} label={trStatus(status)} />;
};

interface RentalContractStatusChipProps {
    status: RentalContractStatus;
}

const RentalContractStatusChip: FC<RentalContractStatusChipProps> = ({ status }) => {
    const trStatus = useTrRentalContractStatus();
    const color = rentalContractStatusColors[status];
    return <Badge color={color} label={trStatus(status)} />;
};
