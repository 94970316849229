import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { BillingEntityStatus } from '../types/BillingEntityStatus';
import { OrganizationStatus } from '../types/OrganizationStatus';

export const useTrOrganizationStatus = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<OrganizationStatus>(OrganizationStatus, 'organization-status');
};
export const useTrBillingEntityStatus = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<BillingEntityStatus>(BillingEntityStatus, 'organization-status');
};
