import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

export const useNavigateSubscription = (rootPath: string) => {
    const history = useHistory();

    const handleOpenCreateSubscription = () => {
        history.push(`${rootPath}/create`);
    };

    const handleOpenEditSubscription = (id: UUID) => {
        history.push(`${rootPath}/${id}/edit`);
    };

    const goBack = () => {
        history.push(rootPath);
    };

    return {
        handleOpenCreateSubscription,
        handleOpenEditSubscription,
        goBack,
    };
};
