import { RequestInterceptorParam } from '../types/RestClientImpl';

interface AuthProvider {
    getValidToken(): Promise<string | null>;
}

const addAuthHeader = async (as: AuthProvider, requestInit?: RequestInit): Promise<RequestInit> => {
    let currentRequestInit = requestInit || {};
    if (!currentRequestInit.headers) {
        currentRequestInit.headers = new Headers();
    }
    const token = await as.getValidToken();
    if (token) {
        (currentRequestInit.headers as Headers).append('Authorization', `Bearer ${token}`);
    }

    return currentRequestInit;
};

export const registerAuthRequestInterceptor =
    (as: AuthProvider) =>
    async ([url, init]: RequestInterceptorParam): Promise<RequestInterceptorParam> => {
        const newInit = await addAuthHeader(as, init);
        return [url, newInit];
    };
