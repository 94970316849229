import React, { FC } from 'react';

import { AdminDto, useAdminsQuery } from '@hofy/api-admin';
import { formatUserName, Role } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    Avatar,
    BaseTable,
    BooleanBadge,
    Box,
    Placeholder,
    PublicIdColumn,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';

interface AdminsTableProps {
    onOpenAdmin(id: UUID): void;
    search: string | null;
}

export const AdminsTable: FC<AdminsTableProps> = ({ onOpenAdmin, search }) => {
    const { data, isLoading } = useAdminsQuery(search);
    return (
        <BaseTable
            data={data}
            toKey={user => user.id}
            onRowClick={user => onOpenAdmin(user.id)}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title={search ? 'No admins were found for the current search' : 'No admins'}
                />
            }
            isLoading={isLoading}
            minWidth={1200}
            flex='auto'
            columns={[
                PublicIdColumn<AdminDto>(),
                {
                    id: 'name',
                    header: 'Name',
                    renderer: user => (
                        <Box row gap={8}>
                            <Avatar
                                shrink={0}
                                userId={user.id}
                                name={user.firstName}
                                pictureUrl={user.pictureUrl}
                            />
                            <TextCell>{formatUserName(user)}</TextCell>
                        </Box>
                    ),
                },
                {
                    id: 'email',
                    header: 'Email',
                    renderer: user => user.email,
                },
                {
                    id: 'is_offboarded',
                    header: 'Is Offboarded',
                    renderer: user => (
                        <BooleanBadge onColor='red' offColor='green' value={user.isOffboarded} />
                    ),
                },
                {
                    id: 'is_super',
                    header: 'Super Admin',
                    renderer: user => <BooleanBadge value={user.roles.includes(Role.SuperAdmin)} />,
                },
            ]}
        />
    );
};
