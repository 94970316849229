import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    emptyPurchaseOrderItemDeliveryPayload,
    PurchaseOrderItemDeliveryPayload,
    purchaseOrdersCacheKey,
    purchaseOrderService,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const usePurchaseOrderItemUpdateDeliveryForm = (id: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: PurchaseOrderItemDeliveryPayload) =>
            purchaseOrderService.updatePurchaseOrderItemDelivery(id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });

            showToast({
                type: 'positive',
                message: 'Purchase order line has been successfully updated.',
            });
        },
    });

    const form = useForm<PurchaseOrderItemDeliveryPayload>({
        initial: emptyPurchaseOrderItemDeliveryPayload,
        onSubmit: mutation.mutate,
        validate: ({ estimatedReceiveOn }) => ({
            estimatedReceiveOn: errorMap([isEmpty(estimatedReceiveOn), "Can't be empty"]),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};
