import { createContext, useContext } from 'react';

import { getUnifiedDetails } from '@hofy/api-auth';
import { allManagerRoles, hasRole, Permission, Role } from '@hofy/api-shared';

import { emptySessionState } from './types/SessionState';

export const SessionContext = createContext(emptySessionState);

export const hasAdminPermission = (permissions: Permission[]): boolean => {
    return permissions.includes(Permission.AdminBasic);
};

export const isGoogleLogin = (provider: string): boolean => {
    return provider === 'google';
};

export const isPasswordLogin = (provider: string): boolean => {
    return provider === 'password';
};

export const isManager = (roles: Role[]): boolean => {
    return allManagerRoles.some(role => hasRole(roles, role));
};

export const useSession = () => {
    const { session, provider, isInitialized, hasPii } = useContext(SessionContext);
    return {
        session: session!,
        details: getUnifiedDetails(session!),
        provider: provider,
        hasPii: hasPii,
        isInitialized,
    };
};
