import { useQuery } from '@tanstack/react-query';

import { purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';

export const useGeneratePurchaseOrderReference = () => {
    const query = useQuery({
        queryKey: [purchaseOrdersCacheKey, 'generate-reference'],
        queryFn: purchaseOrderService.generatePurchaseOrderReference,
        refetchOnWindowFocus: false,
    });

    return { purchaseOrderReference: query.data, isLoading: query.isLoading || query.isRefetching };
};
