import React, { FC } from 'react';

import { AssignmentDetailsDto } from '@hofy/api-admin';
import { Box, FormSection } from '@hofy/ui';

import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { AssignmentDetails } from './AssignmentDetails';
import { ContractDetails } from './ContractDetails';
import { ItemDetails } from './ItemDetails';
import { ManagementContractSection } from './ManagementContractSection';
import { ProductDetails } from './ProductDetails';

interface AssignmentSlideoutDetailsProps {
    assignment: AssignmentDetailsDto;
}

export const AssignmentSlideoutDetails: FC<AssignmentSlideoutDetailsProps> = ({ assignment }) => {
    const requestedProductLabel =
        assignment.customProduct || assignment.requestedProduct
            ? 'Requested product details'
            : 'Product details';

    return (
        <Box paddingVertical={30} gap={30} column>
            {assignment.product && (
                <FormSection label={requestedProductLabel} data-test-key='requested-product'>
                    <ProductDetails product={assignment.requestedProduct || assignment.product} />
                </FormSection>
            )}
            {assignment.customProduct && (
                <FormSection label='Assigned product' data-test-key='assigned-product'>
                    <ProductDetails product={assignment.customProduct} />
                </FormSection>
            )}

            {!assignment.customProduct && assignment.requestedProduct && (
                <FormSection label='Assigned product' data-test-key='assigned-product'>
                    <ProductDetails product={assignment.product} />
                </FormSection>
            )}

            <FormSection label='Assignment details'>
                <AssignmentDetails assignment={assignment} />
            </FormSection>
            {assignment.item && (
                <FormSection label='Item details'>
                    <ItemDetails
                        item={assignment.item}
                        deliveredGrade={assignment.itemGradeOnDelivery}
                        collectedGrade={assignment.itemGradeOnCollection}
                    />
                </FormSection>
            )}
            {assignment.contract && (
                <FormSection label='Contract'>
                    <ContractDetails contract={assignment.contract} />
                </FormSection>
            )}
            {assignment.contract?.managementContract && (
                <FormSection label='Management'>
                    <ManagementContractSection contract={assignment.contract} />
                </FormSection>
            )}
            <AuditSection model={assignment} />
        </Box>
    );
};
