import React, { FC } from 'react';
import { useCopyToClipboard } from 'react-use';

import { EmailType, useTrEmailType } from '@hofy/api-shared';
import { BlockLoader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, Icon, Modal, ModalContent, ModalHeader, Paragraph3, SvgIcon, useToast } from '@hofy/ui';

import { useGenerateSignupLinkQuery } from '../../../../store/users/useGenerateSignupLinkQuery';

interface GenerateSignupLinkModalProps {
    onClose(): void;
    invitedEmailType: EmailType;
    userId: UUID;
}

export const GenerateSignupLinkModal: FC<GenerateSignupLinkModalProps> = ({
    onClose,
    invitedEmailType,
    userId,
}) => {
    const trEmailType = useTrEmailType();
    const { data, isLoading } = useGenerateSignupLinkQuery(invitedEmailType, userId);
    const [, copy] = useCopyToClipboard();
    const { showToast } = useToast();

    const onCopyURL: () => void = () => {
        copy(data?.signupLink || '');
        showToast({
            id: 'signup-link-copied',
            type: 'positive',
            message: 'Signup URL copied successfully',
        });
    };

    if (!isLoading && data === undefined) {
        return null;
    }

    return (
        <Modal onClose={onClose} width={550}>
            <ModalHeader title={`Signup link for ${trEmailType(invitedEmailType)} email`} />
            <ModalContent>
                {isLoading ? (
                    <BlockLoader />
                ) : (
                    <Box row gap={8} onClick={onCopyURL} cursor='pointer'>
                        <Icon size={16} color={Color.ContentSecondary} svg={SvgIcon.Clipboard} />
                        <Paragraph3 ellipsis whiteSpace='nowrap' overflow='hidden' title='Click to copy'>
                            {data?.signupLink || ''}
                        </Paragraph3>
                    </Box>
                )}
            </ModalContent>
        </Modal>
    );
};
