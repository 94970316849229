import React, { FC, useEffect, useState } from 'react';

import { UserAssignmentDto } from '@hofy/api-admin';
import { isOrganizationWarehouse, ShipmentType } from '@hofy/api-shared';
import { Slideout } from '@hofy/common';
import { UUID } from '@hofy/global';

import { useUser } from '../../../store/users/useUser';
import { CreateCollectionShipmentForm } from './CreateCollectionShipmentForm';
import { CreateDeliveryShipmentForm } from './CreateDeliveryShipmentForm';
import { CreateDropshippingShipmentForm } from './CreateDropshippingShipmentForm';
import { CreateShipmentAddressModal } from './CreateShipmentAddressModal';
import { CreateTransferShipmentForm } from './CreateTransferShipmentForm';

interface CreateShipmentSlideoutOverlayProps {
    userId: UUID;
    organizationId: UUID;
    assignments: UserAssignmentDto[];
    shipmentType: ShipmentType;
    onSuccess(id: UUID): void;
    onClose(): void;
}

export const CreateShipmentSlideout: FC<CreateShipmentSlideoutOverlayProps> = ({
    userId,
    organizationId,
    assignments,
    shipmentType,
    onClose,
    onSuccess,
}) => {
    const { user } = useUser(userId);
    const [address, setAddress] = useState(() => {
        const assignment = assignments[0];
        if (assignments.every(a => a.deliveryAddress.id === assignment.deliveryAddress.id)) {
            return assignment.deliveryAddress;
        }
        return null;
    });

    useEffect(() => {
        if (user && address && user.address.id !== address.id) {
            setAddress(null);
        }
    }, [user]);

    if (!address) {
        return (
            <CreateShipmentAddressModal
                user={user}
                onClose={onClose}
                assignments={assignments}
                onSelect={setAddress}
            />
        );
    }
    const content = () => {
        switch (shipmentType) {
            case ShipmentType.Delivery:
                return (
                    <CreateDeliveryShipmentForm
                        userId={userId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                        selected={assignments}
                        address={address}
                    />
                );
            case ShipmentType.Collection:
                return (
                    <CreateCollectionShipmentForm
                        userId={userId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                        selected={assignments}
                        country={address.country}
                    />
                );
            case ShipmentType.Transfer:
                if (assignments[0].collectionToOrganizationWarehouse) {
                    return (
                        <CreateCollectionShipmentForm
                            userId={userId}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            selected={assignments}
                            country={address.country}
                        />
                    );
                }
                if (isOrganizationWarehouse(assignments[0].item?.warehouse)) {
                    return (
                        <CreateDeliveryShipmentForm
                            userId={userId}
                            onClose={onClose}
                            onSuccess={onSuccess}
                            selected={assignments}
                            address={address}
                        />
                    );
                }
                return (
                    <CreateTransferShipmentForm
                        userId={userId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                        organizationId={organizationId}
                        selected={assignments}
                    />
                );
            case ShipmentType.Dropshipping:
                return (
                    <CreateDropshippingShipmentForm
                        userId={userId}
                        onClose={onClose}
                        onSuccess={onSuccess}
                        selected={assignments}
                        address={address}
                    />
                );
        }
    };
    return (
        <Slideout width={600} onClose={onClose} slideoutId='create-shipment'>
            {content()}
        </Slideout>
    );
};
