import React, { FC } from 'react';

import { ContractEndOption, useTrContractEndOptionShort } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Badge, Paragraph4, Span } from '@hofy/ui';

interface ContractEndOptionBadgeProps {
    contractEndOption: ContractEndOption;
    organizationEndOption: ContractEndOption;
}

export const ContractEndOptionBadge: FC<ContractEndOptionBadgeProps> = ({
    contractEndOption,
    organizationEndOption,
}) => {
    const trEndOptionShort = useTrContractEndOptionShort();

    const getLabel = () => {
        if (contractEndOption === ContractEndOption.Default) {
            return (
                <Paragraph4>
                    {trEndOptionShort(contractEndOption)}:{' '}
                    <Span color={Color.ContentPrimary}>{trEndOptionShort(organizationEndOption)}</Span>
                </Paragraph4>
            );
        }

        return <Paragraph4 color={Color.ContentPrimary}>{trEndOptionShort(contractEndOption)}</Paragraph4>;
    };

    return <Badge leftSlot={getLabel()} />;
};
