import { useId } from 'react';

import { useBaseI18n } from '@hofy/i18n';

import { useConfirmation } from '../../modules/confirmation/useConfirmation';

export const useFormDiscardConfirmation = (onDiscard: () => void) => {
    const id = useId();
    const { tr } = useBaseI18n();

    return useConfirmation(onDiscard, {
        id,
        title: tr('ui.form.leave-confirmation.title'),
        message: tr('ui.form.leave-confirmation.message'),
        confirmLabel: tr('ui.form.leave-confirmation.confirm'),
        cancelLabel: tr('ui.form.leave-confirmation.cancel'),
    });
};
