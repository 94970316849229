import { useQuery } from '@tanstack/react-query';

import { contractService, EnrollItemValidationDetailsDto } from '@hofy/api-admin';
import { PaymentSchema } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';

interface EnrollDevicesValidation {
    itemIds: UUID[];
    activeOn: DateString | null;
    addonIds: UUID[];
    paymentSchema: PaymentSchema | null;
    duration: number | null;
    relaxValidation: boolean;
}
export const useValidateEnrollDevicesIntoServicePackageQuery = (validationData: EnrollDevicesValidation) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [
            'admin/contract/validate-enrollment',
            validationData.itemIds,
            validationData.activeOn,
            validationData.addonIds,
            validationData.duration,
            validationData.paymentSchema,
            validationData.relaxValidation,
        ],

        queryFn: () =>
            contractService.validateEnrollItemsIntoServicePackage({
                itemIds: validationData.itemIds,
                activeOn: validationData.activeOn!,
                addonIds: validationData.addonIds,
                duration: validationData.duration!,
                paymentSchema: validationData.paymentSchema!,
                relaxValidation: validationData.relaxValidation,
            }),
        enabled: !!validationData.activeOn && !!validationData.paymentSchema && !!validationData.duration,
    });

    return {
        data: data || [],
        isLoading,
        isError,
    };
};

export const enrollmentIsValid = (item: EnrollItemValidationDetailsDto) => {
    return !item.itemFailReason && !item.addonsValidation?.some(a => a.addonFailReason);
};
