import { useMemo } from 'react';

import { AuditLogsFilters } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useNumberQueryParam, useStringQueryParam } from '@hofy/router';

export const useAuditLogsFilters = () => {
    const [auditTableName, setAuditTableName] = useStringQueryParam('auditTableName');
    const [auditTableId, setAuditTableId] = useNumberQueryParam('auditTableId');
    const [auditTableUuid, setAuditTableUuid] = useStringQueryParam<UUID>('auditTableUuid');
    const [transactionId, setTransactionId] = useStringQueryParam<UUID>('transactionId');

    const filters: AuditLogsFilters = useMemo(() => {
        return {
            auditTableName,
            auditTableId,
            auditTableUuid,
            transactionId,
        };
    }, [auditTableName, auditTableId, transactionId, auditTableUuid]);

    return {
        filters,
        auditTableId,
        auditTableName,
        auditTableUuid,
        transactionId,
        setAuditTableId,
        setAuditTableName,
        setAuditTableUuid,
        setTransactionId,
    };
};
