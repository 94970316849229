import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { VisualVariant } from '@hofy/theme';
import { FeaturedIcon, SvgIcon } from '@hofy/ui';

import { LocationCard } from './LocationCard';

interface HofyWarehouseLocationCardProps {
    iconVariant?: VisualVariant;
    label?: string;
    name: string;
    country?: Country;
}

export const HofyWarehouseLocationCard: FC<HofyWarehouseLocationCardProps> = ({
    iconVariant = 'vivid',
    label,
    name = 'Hofy',
    country,
}) => (
    <LocationCard
        icon={<FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant={iconVariant} />}
        label={label}
        name={name}
        country={country}
    />
);
