import { groupBy, values } from 'lodash';
import React, { FC, memo, useMemo } from 'react';
import styled from 'styled-components';

import { UserAssignmentDto, UserAssignmentsByShipmentDto } from '@hofy/api-admin';
import { ShipmentType } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

import { UserAssignmentSelectionRecord } from '../../../../store/assignments/useAssignmentsSelection';
import { AssignmentRow } from '../assignments/AssignmentRow';
import { NoShipmentGroup } from '../shipments/NoShipmentGroup';
import { ShipmentGroup } from '../shipments/ShipmentGroup';
import { UserSummary } from './UserSummary';

interface UserShipmentsProps {
    user: UserAssignmentsByShipmentDto;
    selected: UserAssignmentSelectionRecord;
    onToggleSelected(v: UserAssignmentDto[]): void;
    onCreateShipment(userId: UUID, organizationId: UUID, country: Country, shipmentType: ShipmentType): void;
    onInPersonTransfer(userId: UUID, organizationId: UUID, country: Country): void;
}

export const UserShipments: FC<UserShipmentsProps> = memo(
    ({ user, selected, onToggleSelected, onCreateShipment, onInPersonTransfer }) => {
        const selectedAssignments = useMemo(
            () =>
                Object.values(selected)
                    .filter(({ selected }) => selected)
                    .map(({ assignment }) => assignment),
            [selected],
        );

        const assignmentsWithoutShipmentByItemWarehouse = useMemo(
            () =>
                values(
                    groupBy(
                        user.noShipmentAssignments,
                        ({ item, collectionToOrganizationWarehouse }) =>
                            item?.warehouse?.id || collectionToOrganizationWarehouse?.id,
                    ),
                ).filter(assignments => assignments.length !== 0),
            [user.noShipmentAssignments],
        );

        const selectBulk = (selectAll: boolean) => {
            assignmentsWithoutShipmentByItemWarehouse.forEach(assignments => {
                assignments.forEach(assignment => {
                    if (selectAll !== selected[assignment.id]?.selected) {
                        onToggleSelected([assignment]);
                    }
                });
            });
        };
        return (
            <UserShipmentsPanel
                column
                gap={12}
                padding={12}
                minWidth={1200}
                data-test-key='user-shipments-panel'
            >
                <UserSummary row={user} />
                {assignmentsWithoutShipmentByItemWarehouse.map((assignments, index) => (
                    <ShipmentGroupWrapper
                        data-test-key='no-shipments-group'
                        key={index}
                        column
                        gap={12}
                        padding={12}
                    >
                        <NoShipmentGroup
                            userAddress={user.userAddress}
                            assignments={assignments}
                            selectedAssignments={selectedAssignments}
                            setSelectAll={selectBulk}
                            onCreateShipment={shipmentType => {
                                onCreateShipment(
                                    user.id,
                                    user.organization.id,
                                    user.userAddress.country,
                                    shipmentType,
                                );
                            }}
                            onInPersonTransfer={() =>
                                onInPersonTransfer(user.id, user.organization.id, user.userAddress.country)
                            }
                        >
                            <Box column gap={4}>
                                {assignments.map(assignment => (
                                    <AssignmentRow
                                        key={assignment.id}
                                        user={user}
                                        assignment={assignment}
                                        isAssignmentSelected={!!selected[assignment.id]?.selected}
                                        onSelectAssignment={() => onToggleSelected([assignment])}
                                    />
                                ))}
                            </Box>
                        </NoShipmentGroup>
                    </ShipmentGroupWrapper>
                ))}
                {user.shipments.map(shipment => (
                    <ShipmentGroupWrapper
                        data-test-key='shipments-group'
                        key={shipment.id}
                        column
                        gap={12}
                        padding={12}
                    >
                        <ShipmentGroup
                            user={user}
                            shipment={shipment}
                            assignments={shipment.assignments}
                            selectedAssignments={selectedAssignments}
                            onCreateShipment={shipmentType => {
                                onCreateShipment(
                                    user.id,
                                    user.organization.id,
                                    user.userAddress.country,
                                    shipmentType,
                                );
                            }}
                            onInPersonTransfer={() =>
                                onInPersonTransfer(user.id, user.organization.id, user.userAddress.country)
                            }
                        >
                            <Box column gap={4}>
                                {shipment.assignments.map(assignment => (
                                    <AssignmentRow
                                        key={assignment.id}
                                        assignment={assignment}
                                        user={user}
                                        shipment={shipment}
                                        isAssignmentSelected={!!selected[assignment.id]?.selected}
                                        onSelectAssignment={() => onToggleSelected([assignment])}
                                    />
                                ))}
                            </Box>
                        </ShipmentGroup>
                    </ShipmentGroupWrapper>
                ))}
            </UserShipmentsPanel>
        );
    },
);

const UserShipmentsPanel = styled(Box)`
    background-color: ${Color.BackgroundSubtleNeutral};
    border-radius: 8px;
`;

const ShipmentGroupWrapper = styled(Box)`
    background-color: ${Color.BackgroundDefault};
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
`;
