import { HofySubsidiaryRef } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { HofySubsidiaryDto } from './types/HofySubsidiaryDto';
import { SvixUrlDto } from './types/SvixUrlDto';

class HofySubsidiariesService {
    public getHofySubsidiaryRefs = async (): Promise<HofySubsidiaryRef[]> => {
        return restClient.getJson<HofySubsidiaryRef[]>(`/api/admin/hofy-subsidiaries/refs`);
    };
    public getHofySubsidiaries = async (): Promise<HofySubsidiaryDto[]> => {
        return restClient.getJson<HofySubsidiaryDto[]>(`/api/admin/hofy-subsidiaries`);
    };

    public getHofySubsidiaryForCountry = async (country: Country): Promise<HofySubsidiaryRef> => {
        return restClient.getJson<HofySubsidiaryRef>(`/api/admin/hofy-subsidiaries/countries/${country}`);
    };

    public getTwoSvixUrl = async (id: UUID): Promise<SvixUrlDto> => {
        return restClient.getJson<SvixUrlDto>(`/api/admin/hofy-subsidiaries/${id}/two-svix`);
    };

    public getDefaultSubsidiary = async (): Promise<HofySubsidiaryRef> => {
        return restClient.getJson<HofySubsidiaryRef>('/api/admin/hofy-subsidiaries/default');
    };
}

export const hofySubsidiariesService = new HofySubsidiariesService();
