import { compact } from 'lodash';

import { Country } from '@hofy/global';

import { useBaseI18n } from '../useBaseI18n';

export const useTrCountry = () => {
    const { trParts } = useBaseI18n();
    return (v?: Country | null) => trParts('country', v || 'none');
};

export const useTrCountryShortname = () => {
    const trCountry = useTrCountry();
    const { trParts, existsParts } = useBaseI18n();
    return (country: Country) => {
        const label = ['country.shortname', country];
        if (existsParts(...label)) {
            return trParts(...label);
        }
        return trCountry(country);
    };
};

export const useTrCountryAbbreviation = () => {
    const { trParts, existsParts } = useBaseI18n();
    return (country: Country) => {
        const label = ['country.abbreviation', country];
        if (existsParts(...label)) {
            return trParts(...label);
        }
        return null;
    };
};

export const useTrCountrySearch = () => {
    const trCountry = useTrCountry();
    const trCountryShortname = useTrCountryShortname();
    const trCountryAbbreviation = useTrCountryAbbreviation();
    return (country: Country) => {
        return compact([
            country,
            trCountryAbbreviation(country),
            trCountry(country),
            trCountryShortname(country),
        ]).join(' ');
    };
};
