import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';

export const useShipmentOrderItemUnAssign = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: assignmentService.unAssignItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });

    return {
        unAssign: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
