import { OrganizationStatus, PlatformTier } from '@hofy/api-shared';
import { Currency, UUID } from '@hofy/global';

export interface AdminOrganizationsFilters {
    search: string;
    statuses: OrganizationStatus[];
    currencies: Currency[];
    platformTiers: PlatformTier[];
    salesReps: UUID[];
    accountManagers: UUID[];
    isDeel: boolean | null;
}

export const emptyAdminOrganizationsFilters: AdminOrganizationsFilters = {
    statuses: [],
    currencies: [],
    platformTiers: [],
    salesReps: [],
    accountManagers: [],
    isDeel: null,
    search: '',
};
