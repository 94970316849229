export enum ContractError {
    InvalidCountryToCollectForStoreAndReuse = 'invalid_country_to_collect_for_store_and_reuse',
    InvalidStoreReuseCantBeNil = 'store_and_reuse_cannot_be_nil',
    InvalidStoreReuseCollectionOption = 'invalid_store_reuse_collection_option',
    RentalContractIsNotReadyToBeReused = 'rental_contract_is_not_ready_to_be_reused',
    CountryNotAvailableForStoreAndReuse = 'country_is_not_available_for_store_and_reuse',
    UndefinedStoreAndReuseDeliveryZone = 'undefined_store_and_reuse_delivery_zone',
    FailToCreateManagementContract = 'failed_to_create_management_contract',
    InvalidAddonContract = 'invalid_addon_contract',
    EnrolAssetFailItemInactive = 'enrol_asset_fail_item_inactive',
    EnrolAssetFailItemOwnedByHofy = 'enrol_asset_fail_item_owned_by_hofy',
    EnrolAssetFailHofyObtainedFromHofy = 'enrol_asset_fail_item_obtained_from_hofy',
    EnrolAssetFailAssetAlreadyEnrolled = 'enrol_asset_fail_asset_already_enrolled',
    EnrolAssetFailItemWithCourier = 'enrol_asset_fail_item_with_courier',
    EnrolAssetFailAssetValueUndefined = 'enrol_asset_fail_asset_value_undefined',
    EnrolAssetFailAssetAddonNotAvailableToOrg = 'enrol_asset_fail_not_available_to_organization',
    AddonContratTypeConstraintNotMet = 'addon_contract_type_constraint_not_met',
    AddonRentalTermConstraintNotMet = 'addon_rental_term_constraint_not_met',
    AddonDurationConstraintNotMet = 'addon_duration_constraint_not_met',
    AddonPaymentSchemaConstraintNotMet = 'addon_payment_schema_constraint_not_met',
    AddonProductParentCategoryConstraintNotMet = 'addon_product_parent_category_constraint_not_met',
    AddonProductCategoryConstraintNotMet = 'addon_product_category_constraint_not_met',
    AddonProductBrandConstraintNotMet = 'addon_product_brand_constraint_not_met',
    AddonAssetAgeConstraintNotMet = 'addon_asset_age_constraint_not_met',
    AddonPlatformTierConstraintNotMet = 'addon_platform_tier_constraint_not_met',
    InvalidContractStatus = 'invalid_contract_status',
    InvalidContactType = 'invalid_contract_type',
    InvalidContactTermsAndConditions = 'invalid_contract_terms_and_conditions',
    NothingToInvoice = 'nothing_to_invoice',
    UpdatePartnerContract = 'can_not_update_partner_contracts',
    CannotRevertNotEndedContract = 'cannot_revert_not_ended_contract',
    CannotRevertContractEndedPreviousMonths = 'cannot_revert_contract_ended_in_a_month_previous_to_current',
    ItemBelongsToCurrentContract = 'item_belongs_to_current_contract',
    ItemAttachedToActiveAssignment = 'item_still_attached_to_active_assignment',
    ItemIsNotHofyOwned = 'item_is_not_hofy_owned',
    ItemHasNoAssignedContract = 'item_has_no_assigned_contract',
    ContractNotActive = 'contract_must_be_active',
    ContractBeingStoredForReuse = 'contract_being_stored_for_reuse',
    InvalidContractTypeForStoreAndReuse = 'invalid_contract_type_for_store_and_reuse',
    InvalidCountryForStoreAndReuse = 'contract_country_invalid_for_store_and_reuse',
    ProductCategoryNotForStoreAndReuseAllowed = 'contract_device_category_invalid_for_store_and_reuse',
    ContractInUse = 'contract_already_in_use',
    ContractHasNotFinalAssignments = 'contract_has_not_final_assignments',
    CannotEndContractInThePast = 'cannot_end_contract_in_the_past',
    InvalidContractPaymentTypeForPurchaseUpdate = 'invalid_contract_payment_type_for_purchase_update',
    InvalidPurchaseContractStatus = 'invalid_purchase_contract_status',
    ContractHasInvoicedEntries = 'contract_has_invoiced_entries',
    ContractHasInvoiceEntriesSoldToTwo = 'contract_has_invoice_entries_sold_to_two',
}
