import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useCancelPurchaseOrder } from '../../../../../store/purchaseOrders/useCancelPurchaseOrder';

interface CancelPurchaseOrderModalProps {
    purchaseOrderId: UUID;
    purchaseOrderReference: string;
    onClose(): void;
}

export const CancelPurchaseOrderModal: FC<CancelPurchaseOrderModalProps> = ({
    purchaseOrderId,
    purchaseOrderReference,
    onClose,
}) => {
    const { mutate } = useCancelPurchaseOrder(purchaseOrderId);
    const submit = () => {
        mutate();
        onClose();
    };

    return (
        <Modal width={600} onClose={onClose}>
            <ModalHeader title='Cancel purchase order' />
            <ModalContent>
                <Paragraph3>{`Are you sure you want to cancel purchase order #${purchaseOrderReference}?`}</Paragraph3>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <Button label='Confirm' onClick={submit} />
            </ModalFooter>
        </Modal>
    );
};
