import React, { FC } from 'react';

import { Decimal, decimalToNumber } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { Paragraph3 } from '../../../base/index';
import { LabeledNumberInput, LabeledNumberInputProps } from './LabeledNumberInput';

interface BaseLabeledDecimalInputProps extends Omit<LabeledNumberInputProps, 'onChange' | 'value'> {
    onBlur?(): void;
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
    unit?: string;
}

interface FormNormalDecimalInputProps extends BaseLabeledDecimalInputProps {
    onChange?(value: Decimal): void;
    api: FormFieldApi<Decimal>;
    nullable?: false;
}

interface FormNullableDecimalInputProps extends BaseLabeledDecimalInputProps {
    api: FormFieldApi<Decimal | null>;
    onChange?(value: Decimal | null): void;
    nullable: true;
}

type FormDecimalInputProps = FormNormalDecimalInputProps | FormNullableDecimalInputProps;

export const FormDecimalInput: FC<FormDecimalInputProps> = ({
    api,
    unit,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledNumberInput
            {...inputProps}
            ref={api.ref}
            value={api.value === null ? null! : decimalToNumber(api.value)}
            onChange={value => {
                if (value === null) {
                    api.setValue(null!);
                    onChange?.(null!);
                    return;
                }
                api.setValue(value.toFixed(inputProps.precision || 2));
                onChange?.(value.toFixed(inputProps.precision || 2));
            }}
            leftSlot={unit ? <Paragraph3>{unit}</Paragraph3> : undefined}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
