import React, { ReactNode, useState } from 'react';
import { useToggle } from 'react-use';

import { Color } from '@hofy/theme';
import { Box, Chevron, Dropdown, MarginBoxProps, Paragraph3, Placement, Pressable, Skeleton } from '@hofy/ui';

import { useSearch } from '../../../../hooks/useSearch';
import { DropdownContentList } from './DropdownContentList';
import { DropdownSearchInput } from './DropdownSearchInput';

interface DropdownListProps<T> extends MarginBoxProps {
    items: T[];
    fixedItems?: T[];
    value?: T;
    placement?: Placement;
    placeholder?: ReactNode;
    onChange(v: T): void;
    labelFormatter(v: T): ReactNode;
    disabled?: boolean;
    searchable?: boolean;
    searchPlaceholder?: string;
    toSearchLabel?(p: T): string | string[];
    onSearchChange?(v: string): void;
    loadingSearchResults?: boolean;
    topContent?: DropdownContent;
    contentWidth?: string | number | 'auto';
    testKey?: string;
    testKeyFormatter?(o: T): string;
    topPosition?: number;
    width?: string | number | 'auto';
    minWidth?: string | number;
}

export type DropdownContent = ReactNode | ((p: ContentProps) => ReactNode);

export interface ContentProps {
    toggleOpen(v?: boolean): void;
    isOpen: boolean;
}

/** @deprecated use `DropdownList` from `@hofy/ui` instead */
export const DropdownList = <T extends any>({
    items,
    fixedItems = [],
    value,
    placement,
    labelFormatter,
    onChange,
    placeholder,
    disabled,
    searchable = false,
    searchPlaceholder,
    toSearchLabel = () => [],
    onSearchChange,
    loadingSearchResults = false,
    topContent,
    contentWidth = 'auto',
    testKey,
    testKeyFormatter,
    width,
    minWidth,
    ...rest
}: DropdownListProps<T>) => {
    const [isOpen, toggleOpen] = useToggle(false);
    const [searchQuery, setSearchQuery] = useState('');
    const { results: searchResult } = useSearch(items, toSearchLabel, onSearchChange ? '' : searchQuery);
    const fontColor = disabled ? Color.ContentTertiary : Color.ContentPrimary;

    const formatLabel = (value: T) => {
        const label = labelFormatter(value);
        if (typeof label !== 'string') {
            return label;
        }
        return (
            <Paragraph3 textNoWrap color={fontColor}>
                {label}
            </Paragraph3>
        );
    };

    const handleOpen = () => {
        if (!disabled) {
            toggleOpen();
        }
    };

    const placeholderLabel =
        typeof placeholder !== 'string' ? (
            placeholder
        ) : (
            <Paragraph3 color={fontColor}>{placeholder}</Paragraph3>
        );

    const getTopContent = () => {
        if (typeof topContent === 'function') {
            return topContent({ toggleOpen, isOpen });
        }

        if (topContent) {
            return (
                <Pressable inactive={disabled} onClick={handleOpen}>
                    {topContent}
                </Pressable>
            );
        }

        return (
            <Pressable row inactive={disabled} onClick={handleOpen}>
                {value ? formatLabel(value) : placeholderLabel}
                <Box marginLeft={8}>
                    <Chevron isOpen={isOpen} color={disabled ? Color.ContentTertiary : fontColor} />
                </Box>
            </Pressable>
        );
    };

    const handleClick = (value: T) => {
        setSearchQuery('');
        onChange(value);
        toggleOpen();
    };

    return (
        <Dropdown
            testKey={testKey}
            open={isOpen}
            onDismiss={() => toggleOpen(false)}
            trigger={getTopContent()}
            asChild
            placement={placement}
            width={width}
            {...rest}
        >
            <Box minWidth={minWidth} width={contentWidth}>
                {searchable && (
                    <DropdownSearchInput
                        autoFocus
                        search={searchQuery}
                        setSearch={q => {
                            setSearchQuery(q);
                            onSearchChange?.(q);
                        }}
                        placeholder={searchPlaceholder}
                    />
                )}
                {fixedItems.length > 0 && (
                    <Box borderBottom padding={6}>
                        <DropdownContentList
                            value={value}
                            items={fixedItems}
                            onClick={handleClick}
                            labelFormatter={formatLabel}
                            testKey={testKey}
                        />
                    </Box>
                )}
                {loadingSearchResults ? (
                    <Skeleton height={80} fullWidth />
                ) : (
                    <Box maxHeight={296} overflow='auto' padding={6}>
                        <DropdownContentList
                            value={value}
                            items={searchResult}
                            onClick={handleClick}
                            labelFormatter={formatLabel}
                            testKey={testKey}
                            testKeyFormatter={testKeyFormatter}
                        />
                    </Box>
                )}
            </Box>
        </Dropdown>
    );
};
