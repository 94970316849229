import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Paragraph4 } from '../components';

export const Code = styled(Paragraph4)`
    white-space: pre-wrap;
    word-break: break-all;
    font-family: monospace;
    margin: 0;
    padding: 0;
    color: ${Color.ContentInvertedSecondary};
`;
