import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { renderTextNode } from '../../../helpers';
import { Box, Paragraph3 } from '../../base';
import { TableFilterIcon } from './TableFilterIcon';
import { TableSortIcon } from './TableSortIcon';

interface HeaderCellLabelProps {
    label: ReactNode;
    hasFilter?: boolean;
    hasSort?: boolean;
    sort?: 'ASC' | 'DESC';
    sortActive?: boolean;
}

export const HeaderCellLabel: FC<HeaderCellLabelProps> = ({
    label,
    hasFilter,
    hasSort,
    sort,
    sortActive,
}) => {
    return (
        <Box row gap={4}>
            {hasFilter !== undefined && <TableFilterIcon active={hasFilter} />}
            {renderTextNode(label, text => (
                <Paragraph3
                    color={Color.ContentSecondary}
                    bold
                    textNoWrap
                    overflow='hidden'
                    ellipsis
                    minWidth={0}
                >
                    {text}
                </Paragraph3>
            ))}
            {hasSort && <TableSortIcon sortDirection={sort} active={sortActive} />}
        </Box>
    );
};
