import React, { FC, memo } from 'react';

import { JournalDto, JournalLineDto, JournalLineType } from '@hofy/api-admin';
import { Currency } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    Box,
    Icon,
    IdColumn,
    InfiniteScrollConfig,
    InfinityScrollTable,
    MultiCell,
    Paragraph3,
    Placeholder,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../components/domain/netsuite/NetsuiteStatusChip';
import { useTrAccountKey } from '../../../store/accounting/useTrAccountKey';
import { JournalRowMenu } from './JournalRowMenu';

interface JournalsTableProps {
    journals: JournalDto[];
    infinityScroll: InfiniteScrollConfig;
}

const JournalsTableComponent: FC<JournalsTableProps> = ({ journals, infinityScroll }) => {
    const { formatPrice } = usePrice();
    const trAccountKey = useTrAccountKey();

    const renderLineAmount = (type: JournalLineType, line: JournalLineDto, currency: Currency) => {
        return line.type === type
            ? formatPrice({
                  amount: `${line.amount}`,
                  currency: currency,
              })
            : '\u00A0';
    };
    return (
        <InfinityScrollTable
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No journals'
                    message='No journals for selected criteria'
                />
            }
            flex='auto'
            data={journals}
            toKey={journal => journal.id}
            infinityScroll={infinityScroll}
            columns={[
                IdColumn<JournalDto>(),
                {
                    id: 'transactionDate',
                    header: 'Date',
                    flexGrow: 1,
                    renderer: journal => formatDate(journal.transactionOn),
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 1,
                    renderer: journal => journal.hofySubsidiary.name,
                },
                {
                    id: 'description',
                    header: 'Description',
                    flexGrow: 2,
                    renderer: journal => (
                        <Box row>
                            {journal.isInterCompany && (
                                <Icon marginRight={8} size={12} svg={SvgIcon.Building3} />
                            )}
                            <Paragraph3>{journal.memo}</Paragraph3>
                        </Box>
                    ),
                },
                {
                    id: 'lineDescription',
                    header: 'Line description',
                    flexGrow: 2,
                    CellRenderer: MultiCell,
                    renderer: journal => (
                        <>
                            {journal.lines.map(line => {
                                return <Paragraph3 key={line.id}>{line.memo ?? '\u00A0'}</Paragraph3>;
                            })}
                        </>
                    ),
                },
                {
                    id: 'account',
                    header: 'Account',
                    flexGrow: 1,
                    width: 130,
                    CellRenderer: MultiCell,
                    renderer: journal => (
                        <>
                            {journal.lines.map(line => {
                                return <Paragraph3 key={line.id}>{trAccountKey(line.accountKey)}</Paragraph3>;
                            })}
                        </>
                    ),
                },
                {
                    id: 'debit',
                    header: 'Debit amount',
                    flexGrow: 1,
                    width: 130,
                    CellRenderer: MultiCell,
                    renderer: journal => (
                        <>
                            {journal.lines.map(line => {
                                return (
                                    <Paragraph3 key={line.id}>
                                        {renderLineAmount(JournalLineType.Debit, line, journal.currency)}
                                    </Paragraph3>
                                );
                            })}
                        </>
                    ),
                },
                {
                    id: 'credit',
                    header: 'Credit amount',
                    flexGrow: 1,
                    width: 130,
                    CellRenderer: MultiCell,
                    renderer: journal => (
                        <>
                            {journal.lines.map(line => {
                                return (
                                    <Paragraph3 marginRight={12} key={line.id}>
                                        {renderLineAmount(JournalLineType.Credit, line, journal.currency)}
                                    </Paragraph3>
                                );
                            })}
                        </>
                    ),
                },
                {
                    id: 'netsuiteStatus',
                    header: 'Netsuite',
                    renderer: journal => <NetsuiteStatusChip status={journal.netsuite} />,
                },
                {
                    id: 'syncToAccounting',
                    flexGrow: 0,
                    width: 50,
                    renderer: journal => {
                        return <JournalRowMenu journalId={journal.id} />;
                    },
                },
            ]}
        />
    );
};

export const JournalsTable = memo(JournalsTableComponent);
