import { v4, validate } from 'uuid';

// UUID is a type that represents a UUID string
export type UUID = `${string}-${string}-${string}-${string}-${string}`;

export const pathUuid = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

export const uuid = () => v4() as UUID;

export const zeroUuid = '00000000-0000-0000-0000-000000000000' as UUID;

export const validateUuid = (value: string): boolean => validate(value);
