import { useQuery } from '@tanstack/react-query';

import { productsService } from '../service/productService';
import { productCacheKey } from './productCacheKey';

export const useProductsQuery = () => {
    const { data, isLoading } = useQuery({
        queryKey: [productCacheKey],
        queryFn: productsService.getProducts,
    });

    return {
        data: data || [],
        isLoading,
    };
};
