import React, { FC, useEffect, useState } from 'react';

import { invoiceMonths, useInvoiceEntriesWithTaxErrorsCount } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { formatMonth, nowDate } from '@hofy/helpers';
import {
    Alert,
    AsyncButton,
    LabeledSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    MonthLabel,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useGenerateInvoices } from '../../../../store/invoices/useGenerateInvoices';

interface GenerateInvoicesModalProps {
    onClose(): void;

    billingEntityId?: UUID;
    organizationId?: UUID;
}

export const GenerateInvoicesModal: FC<GenerateInvoicesModalProps> = ({
    onClose,
    billingEntityId,
    organizationId,
}) => {
    const [invoiceDate, setInvoiceDate] = useState(
        nowDate()
            .startOf('month')
            .minus({
                month: 1,
            })
            .endOf('month'),
    );
    const { count, isLoading: invoiceEntriesCountLoading } = useInvoiceEntriesWithTaxErrorsCount({
        month: invoiceDate.toISODate()!,
        billingEntityId,
        organizationId,
    });
    const [warningMessage, setWarningMessage] = useState<string>('');
    useEffect(() => {
        if (invoiceEntriesCountLoading) {
            return;
        }
        if (count !== 0) {
            setWarningMessage(`Found ${count} invoice entries with tax calculation errors`);
        } else {
            setWarningMessage('');
        }
    }, [count, invoiceEntriesCountLoading]);

    const { generateInvoices, isLoading } = useGenerateInvoices(onClose);
    const handleSubmit = () => {
        generateInvoices({
            month: invoiceDate.toISODate()!,
            billingEntityId: billingEntityId,
            organizationId: organizationId,
        });
    };

    return (
        <Modal onClose={onClose} width={500}>
            <ModalHeader title='Generate invoices' />
            <ModalContent>
                <LabeledSelect
                    options={invoiceMonths}
                    label='Invoicing month'
                    toText={formatMonth}
                    toLabel={date => <MonthLabel date={date} />}
                    value={invoiceDate}
                    onChange={date => setInvoiceDate(date!)}
                />
                {warningMessage && <Alert marginVertical={12} description={warningMessage} type='warning' />}
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Generate'
                    disableCheck
                    onClick={() => handleSubmit()}
                    isLoading={isLoading || invoiceEntriesCountLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
