import React, { FC } from 'react';

import { AuthError } from '@hofy/api-auth';
import { Button, ErrorPage, SvgIllustration } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';

interface ForbiddenPageProps {
    reason: AuthError;
    onSignOut(): void;
}

const reasonToIllustration: Record<AuthError, SvgIllustration> = {
    [AuthError.Archived]: SvgIllustration.AssetSecurity,
    [AuthError.BadCredentials]: SvgIllustration.Error,
    [AuthError.GoogleSsoNotAllowed]: SvgIllustration.Error,
    [AuthError.IncorrectSsoConfig]: SvgIllustration.Error,
    [AuthError.IncorrectTwoFactorCode]: SvgIllustration.Error,
    [AuthError.InvalidApiKey]: SvgIllustration.Error,
    [AuthError.InvalidToken]: SvgIllustration.Error,
    [AuthError.MicrosoftSsoNotAllowed]: SvgIllustration.Error,
    [AuthError.MissingEmail]: SvgIllustration.Error,
    [AuthError.NoRoles]: SvgIllustration.Error,
    [AuthError.NotFound]: SvgIllustration.NotFound,
    [AuthError.NotInvited]: SvgIllustration.AssetSecurity,
    [AuthError.NotVerified]: SvgIllustration.Email,
    [AuthError.Offboarded]: SvgIllustration.ParcelOk,
    [AuthError.RequiredSsoLogin]: SvgIllustration.Error,
    [AuthError.TeamMemberInManagerOnlyOrg]: SvgIllustration.Error,
    [AuthError.TwoFactorAlreadySet]: SvgIllustration.Error,
    [AuthError.TwoFactorRequired]: SvgIllustration.Error,
    [AuthError.Unauthorized]: SvgIllustration.Error,
    [AuthError.NonSupportedEmail]: SvgIllustration.Error,
    [AuthError.Unknown]: SvgIllustration.Error,
    [AuthError.DuplicateApiKey]: SvgIllustration.Error,
    [AuthError.InvalidPassword]: SvgIllustration.Error,
    [AuthError.SSONotAllowed]: SvgIllustration.Error,
    [AuthError.MissingSSORedirectBinding]: SvgIllustration.Error,
};

export const ForbiddenPage: FC<ForbiddenPageProps> = ({ reason, onSignOut }) => {
    const { trParts, tr } = useAuthI18n();
    const trSuffix = (v: string) => trParts('forbidden-page', reason, v);
    return (
        <ErrorPage
            title={trSuffix('title')}
            subtitle={trSuffix('subtitle')}
            illustration={reasonToIllustration[reason]}
        >
            <Button
                onClick={onSignOut}
                marginTop={40}
                width={300}
                label={tr('forbidden-page.sign-in-with-other')}
            />
            {reason !== AuthError.NotVerified && (
                <Button
                    type='secondary'
                    label={tr('forbidden-page.goto-hofy')}
                    onClick={() => (window.location.href = 'https://hofy.com')}
                    marginTop={24}
                    width={300}
                />
            )}
        </ErrorPage>
    );
};
