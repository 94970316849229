import React, { FC, useEffect } from 'react';

import { AddressField } from '@hofy/address';
import { AddressPayload } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import { FormFieldRecord } from '@hofy/ui';

interface SupplierAddressFormProps {
    form: FormFieldRecord<AddressPayload>;
    label: string;
    locatedIn: Country | null;
}

export const SupplierAddressForm: FC<SupplierAddressFormProps> = ({ form, label, locatedIn }) => {
    useEffect(() => {
        if (locatedIn) {
            form.country.setValue(locatedIn);
        }
    }, [locatedIn]);

    return <AddressField api={form} label={label} />;
};
