import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useSinglePlanMutation = (
    planId: UUID,
    fn: (planId: UUID) => Promise<void>,
    toastMessage: string,
    onSuccess?: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => fn(planId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: toastMessage,
            });
            onSuccess?.();
        },
    });

    const mutate = () => {
        mutation.mutate();
    };

    return {
        mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
