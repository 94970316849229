import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    organizationService,
    subscriptionCacheKey,
    UpdateOrganizationStatusPayload,
} from '@hofy/api-admin';
import { isShorterThan, useForm, useToast, validator } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationStatus = (organization: OrganizationDetailsDto, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationStatusPayload) =>
            organizationService.updateOrganizationStatus(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            queryClient.invalidateQueries({ queryKey: [subscriptionCacheKey, organization.id] });

            showToast({
                message: `Organization ${organization.name} status successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationStatusPayload>({
        initial: {
            status: organization.status,
            trialEndOn: organization.trialEndOn,
            bannerMessage: organization.bannerMessage || '',
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationStatusPayload>({
            bannerMessage: [isShorterThan(250, 'Message cannot be longer than 250 characters')],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
