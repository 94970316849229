import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Paragraph3 } from '../../base';
import { NavigationColors } from '../NavigationColors';
import { useNavigationContext } from '../NavigationContext';
import { NavigationItemContainer } from './NavigationItemContainer';

interface NavigationEnvProps {
    env: string;
    rightSlot?: ReactNode;
}

export const NavigationEnv: FC<NavigationEnvProps> = ({ env, rightSlot }) => {
    const { collapsed } = useNavigationContext();
    const envLabel = envLabels[env];

    if (!envLabel) {
        return null;
    }

    return (
        <NavigationItemContainer collapsed={collapsed} bg={NavigationColors.ProfileBackground}>
            <Paragraph3 color={Color.ContentInvertedPrimary}>{envLabel.icon}</Paragraph3>
            {!collapsed && (
                <>
                    <Paragraph3 bold color={Color.ContentInvertedPrimary}>
                        {envLabel.label}
                    </Paragraph3>
                    {rightSlot}
                </>
            )}
        </NavigationItemContainer>
    );
};

interface NavigationEnvLabel {
    label: string;
    icon: string;
}

const envLabels: Record<string, NavigationEnvLabel | undefined> = {
    prod: undefined,
    demo: {
        label: 'Demo',
        icon: '✈️',
    },
    staging: {
        label: 'Staging',
        icon: '☕️',
    },
    dev: {
        label: 'Development',
        icon: '🚧',
    },
    local: {
        label: 'Local',
        icon: '💻',
    },
};
