import { css } from 'styled-components';

import { Palette } from '@hofy/theme';

const scrollbarWidth = 12;

export const transparentScrollbarStyles = css`
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track {
        background-color: transparent;
        width: ${scrollbarWidth}px;
        height: ${scrollbarWidth}px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border: 3px solid transparent;
        background: transparent;
        box-shadow: inset 0 0 10px 10px ${Palette.grey['+1']};
    }

    scrollbar-color: ${Palette.grey['+1']} ${Palette.grey['+7']};
    scrollbar-width: thin;
`;
