import styled, { keyframes } from 'styled-components';

import { Box } from '@hofy/ui';

const progressBar = (width: string) => keyframes`
    0% { width: 0; }
    100% { width: ${width}; }
`;

/** @deprecated use `ProgressBar` from `@hofy/ui` instead */
export const RangeBar = styled(Box)<{ width: string }>`
    border-radius: 5px;
    animation: ${props => progressBar(props.width)} 1.5s ease;
`;

/** @deprecated use `ProgressBar` from `@hofy/ui` instead */
export const RangeBarBackground = styled(Box)`
    border-radius: 5px;
`;
