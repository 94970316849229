import React, { MouseEvent, ReactNode, useLayoutEffect, useRef } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, Pressable, Spinner, TestKeyAware } from '@hofy/ui';

interface DropdownContentListProps<T> {
    value?: T;
    items: T[];
    onClick(v: T): void;
    labelFormatter(v: T): ReactNode;
    testKey?: string;
    testKeyFormatter?(o: T): string;
    isLoading?: boolean;
}

/** @deprecated  */
export const DropdownContentList = <T extends any>({
    value,
    items,
    labelFormatter,
    onClick,
    testKey,
    testKeyFormatter,
    isLoading,
}: DropdownContentListProps<T>) => {
    const itemTestKey = (v: T) => {
        if (testKeyFormatter) {
            return `${testKey}-${testKeyFormatter(v)}`;
        }
        if (!testKey) {
            return undefined;
        }
        if (typeof v === 'string') {
            return `${testKey}-${v}`;
        }
        if (typeof v === 'object' && (v as TestKeyAware).testKey) {
            return `${testKey}-${(v as TestKeyAware).testKey}`;
        }
    };

    const handleClick = (e: MouseEvent, v: T) => {
        e.stopPropagation();
        onClick(v);
    };

    return (
        <Box column gap={4} data-test-key='content'>
            {items.map((item, index) => (
                <DropdownItem
                    key={index}
                    value={item}
                    active={item === value}
                    onClick={e => handleClick(e, item)}
                    itemTestKey={itemTestKey}
                >
                    {labelFormatter(item)}
                </DropdownItem>
            ))}
            {isLoading && (
                <Box padding={8} centered>
                    <Spinner size={16} />
                </Box>
            )}
        </Box>
    );
};

interface DropdownItemProps<T> {
    value: T;
    active: boolean;
    onClick(event: MouseEvent, value: T): void;
    itemTestKey?(v: T): void;
    children: ReactNode;
}

const DropdownItem = <T extends any>({
    value,
    onClick,
    itemTestKey,
    children,
    active,
}: DropdownItemProps<T>) => {
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (active) {
            ref.current?.scrollIntoView({
                block: 'center',
            });
        }
    }, [active]);

    return (
        <ItemPressable
            ref={ref}
            onClick={e => onClick(e, value)}
            bg={active ? Color.InteractionGhostActive : undefined}
            rounded={8}
            paddingVertical={8}
            paddingHorizontal={12}
            data-test-key={itemTestKey?.(value)}
        >
            {children}
        </ItemPressable>
    );
};

const ItemPressable = styled(Pressable)`
    &:hover {
        background-color: ${Color.InteractionGhostHover};
    }
`;
