import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ZIndex } from '@hofy/theme';

import { easeInOutTransition } from '../../../../config/animations';
import { Box } from '../../../base';

interface NavigationMobileContainerProps {
    children?: ReactNode;
}

export const NavigationMobileContainer = ({ children }: NavigationMobileContainerProps) => {
    return (
        <ContainerBox
            as={motion.div}
            paddingBottom={24}
            paddingHorizontal={16}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={easeInOutTransition}
        >
            <Box
                as={motion.div}
                column
                gap={8}
                initial={{ y: -30 }}
                animate={{ y: 0 }}
                transition={easeInOutTransition}
                fullHeight
            >
                {children}
            </Box>
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)`
    overflow: hidden;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${ZIndex.MobileMenu};

    background: linear-gradient(180deg, #4e38bc 0%, #39288a 54.1%);
    will-change: width;
`;
