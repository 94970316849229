import { useEffect, useState } from 'react';

import { numberPrecision, parseNumber } from '../../../../../helpers/Number';
import { NumberInputProps } from '../types/NumberInputProps';

export const useNumberInput = ({
    value,
    onChange,
    onBlur,
    nullable,
    precision = 0,
    fixedDecimal,
    formatter,
}: NumberInputProps) => {
    const toString = (val: number | null) => {
        if (val === null) {
            return '';
        }
        const num = numberPrecision(val, precision);

        if (formatter) {
            return formatter(num);
        }
        if (fixedDecimal) {
            return num.toFixed(precision);
        }
        return num.toString();
    };

    const [stringValue, setStringValue] = useState(() => toString(value));

    useEffect(() => {
        if (parseNumber(stringValue) !== value) {
            setStringValue(toString(value));
        }
    }, [value]);

    const change = (newValue: string) => {
        setStringValue(newValue);

        const num = parseNumber(newValue);

        if (num === null && nullable) {
            onChange(null);
        } else if (num !== null) {
            onChange(numberPrecision(num, precision));
        }
    };

    const blur = () => {
        const num = parseNumber(stringValue);

        if (num === null && nullable) {
            setStringValue('');
            onChange(null);
        } else if (num !== null) {
            setStringValue(toString(num));
            onChange(numberPrecision(num, precision));
        } else {
            setStringValue(toString(value));
        }

        onBlur?.();
    };

    return {
        stringValue,
        setStringValue,
        change,
        blur,
    };
};
