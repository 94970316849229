import { capitalize } from 'lodash';
import React, { FC } from 'react';

import { AddressField } from '@hofy/address';
import {
    allPaymentSchemasWithoutDeposit,
    allRentalTerms,
    useTrPaymentSchema,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Box,
    FormContainer,
    FormInput,
    FormSection,
    FormSelect,
    LabeledInput,
    LabeledNumberInput,
    LabeledPriceInput,
    LabeledSelect,
    LabeledSwitch,
    Paragraph3,
    useFormObjectField,
} from '@hofy/ui';

import { FormBillingEntitySelect } from '../../../components/domain/billingEntity/FormBillingEntitySelect';
import { FormHofySubsidiarySelect } from '../../../components/domain/hofySubsidiaries/FormHofySubsidiarySelect';
import { UseContractForm } from '../../../store/contracts/useManualContractForm';

interface ManualContractFormProps {
    formState: UseContractForm;
    organizationId: UUID;
}

export const ManualRentalContractForm: FC<ManualContractFormProps> = ({ formState, organizationId }) => {
    const {
        form,
        setPrice,
        setBasePrice,
        setMultiplier,
        setUnitPrice,
        setPaymentDiscount,
        setCustomDiscount,
        setAddon,
        lockBasePriceUpdate,
        setLockBasePriceUpdate,
        changeBillingEntity,
    } = formState;
    const trRentalTerm = useTrRentalTerm();
    const trPaymentSchema = useTrPaymentSchema();
    const address = useFormObjectField(form.fields.billingToAddress);
    return (
        <Box marginTop={10}>
            <FormContainer>
                <FormSection label='Base details'>
                    <FormSelect
                        label='Rental term'
                        api={form.fields.rentalTerm}
                        toText={trRentalTerm}
                        options={allRentalTerms}
                        isRequired
                    />
                    <LabeledSelect
                        label='Payment schema'
                        onChange={paymentSchema => form.setValues({ paymentSchema })}
                        value={form.values.paymentSchema}
                        toText={trPaymentSchema}
                        options={allPaymentSchemasWithoutDeposit}
                        isRequired
                    />
                </FormSection>
                <FormSection label='Price'>
                    <FormRow>
                        <LabeledSwitch
                            label={<Paragraph3 color={Color.Neutral700}>Lock base price update</Paragraph3>}
                            checked={lockBasePriceUpdate}
                            onChange={setLockBasePriceUpdate}
                        />
                    </FormRow>
                    <FormRow>
                        <LabeledPriceInput
                            label='Base price'
                            value={form.values.basePrice}
                            onChange={basePrice => setBasePrice(basePrice)}
                            errorMessage={form.errors.basePrice as string}
                        />
                        <LabeledNumberInput
                            label='Multiplier'
                            precision={2}
                            value={parseFloat(form.values.multiplier)}
                            onChange={multiplier => setMultiplier(multiplier.toFixed(2))}
                            errorMessage={form.errors.multiplier as string}
                        />
                        <LabeledPriceInput
                            label='Unit price'
                            value={form.values.unitPrice}
                            onChange={unitPrice => setUnitPrice(unitPrice)}
                            errorMessage={form.errors.unitPrice as string}
                        />
                    </FormRow>

                    <FormRow>
                        <LabeledNumberInput
                            label='Payment discount'
                            precision={2}
                            rightSlot='%'
                            value={parseFloat(form.values.paymentDiscount)}
                            onChange={discount => setPaymentDiscount(discount.toFixed(2))}
                            errorMessage={form.errors.paymentDiscount as string}
                        />
                        <LabeledNumberInput
                            label='Custom discount'
                            precision={2}
                            rightSlot='%'
                            value={parseFloat(form.values.customDiscount)}
                            onChange={discount => setCustomDiscount(discount.toFixed(2))}
                            errorMessage={form.errors.customDiscount as string}
                        />
                        <LabeledPriceInput
                            label='Price'
                            value={form.values.price}
                            onChange={price => setPrice(price!)}
                            errorMessage={form.errors.price as string}
                        />
                    </FormRow>
                    <FormRow>
                        <FormInput label='Discount reason' api={form.fields.discountReason} nullable />
                    </FormRow>
                    {form.values.rolloverPrice && (
                        <LabeledPriceInput
                            label='Rollover price'
                            value={form.values.rolloverPrice}
                            onChange={rolloverPrice => form.setValues({ rolloverPrice })}
                        />
                    )}
                </FormSection>
                <FormSection label='Subsidiaries'>
                    <FormRow>
                        <FormHofySubsidiarySelect
                            api={form.fields.hofySubsidiaryId}
                            label='Hofy subsidiary'
                        />
                        <FormBillingEntitySelect
                            organizationId={organizationId}
                            onChange={(_, dto) => changeBillingEntity(dto)}
                            label='Billing entity'
                            api={form.fields.billingEntityId}
                        />
                    </FormRow>
                </FormSection>
                {form.values.addons.map((addon, index) => {
                    return (
                        <FormSection key={addon.id} label={`${capitalize(addon.addonType)}`}>
                            <FormRow>
                                <LabeledPriceInput
                                    label='Unit price'
                                    value={addon.unitPrice}
                                    onChange={unitPrice => setAddon(index, { ...addon, unitPrice })}
                                    errorMessage={form.errors.addons as string}
                                />
                                <LabeledInput
                                    label='Discount percent'
                                    rightSlot='%'
                                    value={addon.discount}
                                    onChange={discount => setAddon(index, { ...addon, discount })}
                                />
                                <LabeledPriceInput
                                    label='Monthly price'
                                    value={addon.price}
                                    onChange={price => setAddon(index, { ...addon, price })}
                                    errorMessage={form.errors.addons as string}
                                />
                            </FormRow>
                        </FormSection>
                    );
                })}
                <AddressField api={address} label='Billing address' />
            </FormContainer>
        </Box>
    );
};
