import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ContractEndOption } from '../types/ContractEndOption';

export const useTrContractEndOption = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractEndOption>(ContractEndOption, 'contract-end-option');
};

export const useTrContractEndOptionShort = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractEndOption>(ContractEndOption, 'contract-end-option-short');
};
