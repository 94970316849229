import { useMutation, useQueryClient } from '@tanstack/react-query';

import { organizationService } from '@hofy/api-admin';
import { ContractExpirySettingsPayload } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationContractExpirySettings = (organizationId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: ContractExpirySettingsPayload) =>
            organizationService.updateContractExpirySettings(organizationId, payload),
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Contract defaults successfully updated',
            });
            onSuccess?.();
        },
    });

    return {
        updateContractExpirySetting: mutation.mutate,
        updateIsPending: mutation.isPending,
    };
};
