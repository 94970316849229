import React, { FC, useState } from 'react';

import { ContractDetailsDtoWithRental } from '@hofy/api-admin';
import {
    allOrgDevicesContractExtendOptions,
    ContractEndOption,
    contractEndOptionsForCategoryGroupMap,
    ContractExtendOption,
    contractExtendOptionLength,
    ParentProductCategoryGroup,
    Permission,
    productCategoryToParentProductCategoryGroup,
    rentalLength,
    useTrContractEndOption,
    useTrContractExtendOption,
    useTrContractExtendOptionDuration,
    useTrPaymentSchema,
} from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    ExpandHeight,
    FeaturedIcon,
    LabeledRadio,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph2,
    Paragraph3,
    Select,
    Strong,
    SvgIcon,
    Switch,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { useUpdateContractExpirySettings } from '../../../../store/contracts/useUpdateContractExpirySettings';

interface UpdateContractExpirySettingsModalProps {
    contract: ContractDetailsDtoWithRental;
    onClose(): void;
}

export const UpdateContractExpirySettingsModal: FC<UpdateContractExpirySettingsModalProps> = ({
    contract,
    onClose,
}) => {
    const trContractEndOption = useTrContractEndOption();
    const trContractExtendOptionDuration = useTrContractExtendOptionDuration();
    const trContractExtendOption = useTrContractExtendOption();
    const trPaymentSchema = useTrPaymentSchema();

    const {
        id,
        product: { category },
        rentalContract: { contractExpirySettings, organizationExpirySettings },
        rentalTerm,
    } = contract;
    const { onSubmit, isLoading } = useUpdateContractExpirySettings(id, onClose);

    const [extendOptionSelected, setExtendOptionSelected] = useState<ContractExtendOption>(
        contractExpirySettings.contractExtendOption,
    );
    const [endOptionSelected, setEndOptionSelected] = useState<ContractEndOption>(
        contractExpirySettings.contractEndOption,
    );

    const isOverrideEndOption = endOptionSelected !== ContractEndOption.Default;
    const isOverrideExtendOption = extendOptionSelected !== ContractExtendOption.Default;

    const parentProductCategoryGroup = productCategoryToParentProductCategoryGroup[category];
    const isDevices = parentProductCategoryGroup === ParentProductCategoryGroup.Devices;

    const handleChangeExtendOption = (isChecked: boolean) => {
        setExtendOptionSelected(isChecked ? ContractExtendOption.Months48 : ContractExtendOption.Default);
    };

    const handleChangeEndOption = (isChecked: boolean) => {
        const initialEndOption = isDevices ? ContractEndOption.Return : ContractEndOption.Purchase;

        return setEndOptionSelected(isChecked ? initialEndOption : ContractEndOption.Default);
    };

    const handleUpdateContractExpirySettings = () => {
        onSubmit({
            extendOption: extendOptionSelected,
            endOption: endOptionSelected,
        });
    };

    const filteredExtendOptions = allOrgDevicesContractExtendOptions.filter(
        option => rentalTerm && contractExtendOptionLength[option] > rentalLength[rentalTerm],
    );
    const extendOptions = [ContractExtendOption.Not, ...filteredExtendOptions];

    return (
        <Modal onClose={onClose} width={600}>
            <ModalHeader borderBottom title='Contract lifecycle defaults' />
            <ModalContent column gap={24}>
                <Box
                    padding={16}
                    border
                    rounded={8}
                    borderColor={Color.NonContextualVioletSurfaceBorder}
                    bg={Color.BackgroundSurfaceBrand}
                    column
                    gap={8}
                >
                    <Paragraph3 color={Color.ContentPrimary}>Organisation defaults</Paragraph3>
                    {isDevices && (
                        <Paragraph3>
                            Continue renting:{' '}
                            <Strong>
                                {trContractExtendOptionDuration(
                                    organizationExpirySettings.contractExtendOption,
                                )}
                            </Strong>
                        </Paragraph3>
                    )}
                    <Paragraph3>
                        End of rental decision:{' '}
                        <Strong>{trContractEndOption(organizationExpirySettings.contractEndOption)}</Strong>
                    </Paragraph3>
                </Box>
                {isDevices && (
                    <Box row gap={8} alignItems='baseline' borderBottom>
                        <FeaturedIcon icon={SvgIcon.Calendar} size={32} />
                        <Box flex={1}>
                            <Box row justify='space-between' marginBottom={24}>
                                <Paragraph2 bold color={Color.ContentPrimary}>
                                    Override rental
                                </Paragraph2>
                                <Switch
                                    checked={isOverrideExtendOption}
                                    onChange={handleChangeExtendOption}
                                />
                            </Box>
                            <ExpandHeight>
                                {isOverrideExtendOption && (
                                    <Box column marginBottom={24}>
                                        <Paragraph3 color={Color.ContentPrimary} marginBottom={8}>
                                            End contract when it reaches
                                        </Paragraph3>
                                        <Select
                                            options={extendOptions}
                                            toText={trContractExtendOption}
                                            value={extendOptionSelected}
                                            onChange={setExtendOptionSelected}
                                        />
                                        {organizationExpirySettings.paymentSchema && (
                                            <Paragraph3 marginTop={16}>
                                                Billing frequency:{' '}
                                                {trPaymentSchema(organizationExpirySettings.paymentSchema)}
                                            </Paragraph3>
                                        )}
                                    </Box>
                                )}
                            </ExpandHeight>
                        </Box>
                    </Box>
                )}
                <Box row gap={8} alignItems='baseline'>
                    <FeaturedIcon icon={SvgIcon.File02} size={32} />
                    <Box flex={1}>
                        <Box row justify='space-between' marginBottom={24}>
                            <Paragraph2 bold color={Color.ContentPrimary}>
                                Override end of rental decision
                            </Paragraph2>
                            <Switch checked={isOverrideEndOption} onChange={handleChangeEndOption} />
                        </Box>
                        <ExpandHeight>
                            {isOverrideEndOption && (
                                <Box column gap={16}>
                                    {contractEndOptionsForCategoryGroupMap[parentProductCategoryGroup].map(
                                        option => (
                                            <LabeledRadio
                                                label={trContractEndOption(option)}
                                                checked={endOptionSelected === option}
                                                onChange={() => setEndOptionSelected(option)}
                                                key={option}
                                            />
                                        ),
                                    )}
                                </Box>
                            )}
                        </ExpandHeight>
                    </Box>
                </Box>
            </ModalContent>
            <ModalFooter borderTop>
                <Button type='secondary' label='Cancel' onClick={onClose} />
                <PermissionWrapper permission={Permission.AdminContractsUpdate} tooltip>
                    <AsyncButton
                        label='Save'
                        onClick={handleUpdateContractExpirySettings}
                        isLoading={isLoading}
                        disableCheck
                    />
                </PermissionWrapper>
            </ModalFooter>
        </Modal>
    );
};
