import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    emptyInvoiceGroupPayload,
    InvoiceGroupPayload,
    InvoiceGroupsPayload,
    OrganizationDetailsDto,
    organizationService,
} from '@hofy/api-admin';
import { SplitterType } from '@hofy/api-shared';
import { arrayRemove, arrayUpdateAt } from '@hofy/helpers';
import { UseForm, useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateInvoiceGroups = (organization: OrganizationDetailsDto, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const mutation = useMutation({
        mutationFn: (payload: InvoiceGroupsPayload) =>
            organizationService.updateInvoiceGroups(organization.id, payload),
        onSuccess() {
            onSuccess();
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Invoice groups updated',
            });
        },
    });
    useEffect(
        () =>
            form.setValues({
                invoiceGroups: organization.invoiceGroups,
            }),
        [organization],
    );

    const form = useForm<InvoiceGroupsPayload>({
        initial: {
            invoiceGroups: organization.invoiceGroups,
        },
        onSubmit: mutation.mutate,
    });

    const deleteItem = (v: number) => {
        form.setValues({
            invoiceGroups: arrayRemove(form.values.invoiceGroups, v),
        });
    };

    const addItem = () => {
        form.setValues({
            invoiceGroups: [
                ...form.values.invoiceGroups,
                emptyInvoiceGroupPayload(SplitterType.ByInvoiceEntryType),
            ],
        });
    };

    const setItemAt = (index: number, p: Partial<InvoiceGroupPayload>) => {
        form.setValues({
            invoiceGroups: arrayUpdateAt(form.values.invoiceGroups, index, v => ({ ...v, ...p })),
        });
    };

    return {
        form,
        deleteItem,
        addItem,
        setItemAt,
    };
};

export type UpdateInvoiceGroupForm = UseForm<InvoiceGroupsPayload>;
