import React, { FC } from 'react';
import styled from 'styled-components';

import { VariantDto } from '@hofy/api-admin';
import { formatVariant } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, SectionTitle3 } from '@hofy/ui';

interface VariantsListProps {
    variants: VariantDto[];
    onVariantClick(v: VariantDto): void;
}

export const VariantsList: FC<VariantsListProps> = ({ onVariantClick, variants }) => {
    return (
        <>
            {variants.map(v => (
                <VariantRowWrapper key={v.id} row borderBottom onClick={() => onVariantClick(v)} pointer>
                    <ProductImage image={v.image?.url} size={50} />
                    <Box marginLeft={20}>
                        <SectionTitle3 color={Color.Neutral900}>{formatVariant(v)}</SectionTitle3>
                        <Paragraph3 color={Color.Neutral700} marginTop={5}>
                            {v.sku}
                        </Paragraph3>
                    </Box>
                </VariantRowWrapper>
            ))}
        </>
    );
};

const VariantRowWrapper = styled(Box)`
    padding: 10px 20px;
    :hover {
        background-color: ${Color.Highlight};
    }
`;
