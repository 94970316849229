import { ColorDye } from '@hofy/global';

export enum InitialCostSource {
    PurchaseOrder = 'purchase-order',
    Organization = 'organization',
    Estimated = 'estimated',
    Unknown = 'unknown',
}

export const initialCostSourceColor: Record<InitialCostSource, ColorDye> = {
    [InitialCostSource.PurchaseOrder]: 'teal',
    [InitialCostSource.Organization]: 'blue',
    [InitialCostSource.Estimated]: 'purple',
    [InitialCostSource.Unknown]: 'grey',
};
