import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { isShipmentFromUserToWarehouse } from '@hofy/api-shared';

import { CollectionCompleteSlideout } from './CollectionCompleteSlideout';
import { DeliveryCompleteSlideout } from './DeliveryCompleteSlideout';

interface CompleteShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const CompleteShipmentSlideout: FC<CompleteShipmentSlideoutProps> = ({ shipment, onClose }) => {
    return isShipmentFromUserToWarehouse(shipment) ? (
        <CollectionCompleteSlideout shipment={shipment} onClose={onClose} />
    ) : (
        <DeliveryCompleteSlideout shipment={shipment} onClose={onClose} />
    );
};
