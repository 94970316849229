import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    assignmentsCacheKey,
    FulfilmentCountrySettingDto,
    shipmentService,
    useFulfilmentCountrySettingQuery,
    UserAssignmentDto,
} from '@hofy/api-admin';
import {
    belongsToOrganization,
    getAssignmentStoreAndReuse,
    isParentContainsCategory,
    ParentProductCategory,
    RentalStoreAndReuseLocation,
} from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import { useAssignmentSelection } from './useAssignmentSelection';

interface CreateDeliveryShipmentFormData extends SendCollectionSurveyFormData {
    assignedUserId?: UUID;
    warehouseId?: UUID;
    country: Country;
}

export const useCreateDeliveryShipment = (
    selectedAssignments: UserAssignmentDto[],
    country: Country,
    addressId: UUID,
    defaultWarehouseId: UUID | undefined,
    onSuccess: (id: UUID) => void,
) => {
    const queryClient = useQueryClient();
    const selection = useAssignmentSelection(selectedAssignments);

    const { data: countrySetting } = useFulfilmentCountrySettingQuery(country);
    defaultWarehouseId = defaultWarehouseId || countrySetting?.defaultDeliverFromWarehouse.id;
    const mutation = useMutation({
        mutationFn: shipmentService.createDeliveryShipment,
        onSuccess: (id: UUID) => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess(id);
        },
    });

    const form = useForm<CreateDeliveryShipmentFormData>({
        initial: {
            warehouseId: defaultWarehouseId,
            country: country,
            ...initialCollectionSurveyFormData,
        },
        onSubmit: data => {
            mutation.mutate({
                assignmentIds: selection.assignments.map(({ id }) => id),
                assignedUserId: data.assignedUserId ?? null,
                warehouseId: data.warehouseId!,
                addressId,
                collectionRequest: getCollectionRequestPayloadFromSurveyFormData(data),
            });
        },
        validate: ({ warehouseId }) => ({
            warehouseId: errorMap([isEmpty(warehouseId), 'Warehouse is required']),
        }),
    });

    useEffect(() => {
        if (!defaultWarehouseId) {
            return;
        }
        if (!form.values.warehouseId) {
            form.setValues({
                warehouseId: defaultWarehouseId,
            });
        }
    }, [form.values.warehouseId, defaultWarehouseId]);

    return {
        form,
        selection,
        isLoadingMutation: mutation.isPending,
    };
};

export interface SendCollectionSurveyFormData {
    collectionRequest: boolean;
    requestLabelsPrintingInfo: boolean;
    requestPackagingInfo: boolean;
    requestConfigurationStatusInfo: boolean;
}

export const initialCollectionSurveyFormData: SendCollectionSurveyFormData = {
    collectionRequest: false,
    requestLabelsPrintingInfo: true,
    requestPackagingInfo: true,
    requestConfigurationStatusInfo: true,
};

const getCollectionRequestPayloadFromSurveyFormData = (v: SendCollectionSurveyFormData) =>
    v.collectionRequest
        ? {
              requestPackagingInfo: v.requestPackagingInfo,
              requestLabelsPrintingInfo: v.requestLabelsPrintingInfo,
              requestConfigurationStatusInfo: v.requestConfigurationStatusInfo,
          }
        : null;

interface CreateCollectionShipmentFormData extends SendCollectionSurveyFormData {
    assignedUserId?: UUID;
    warehouseId?: UUID;
    country: Country;
}

const getDefaultWarehouse = (
    countrySetting?: FulfilmentCountrySettingDto,
    storeAndReuseLocation?: RentalStoreAndReuseLocation | null,
) => {
    if (!storeAndReuseLocation) {
        return countrySetting?.defaultCollectToWarehouse;
    }

    // Both rental and management (customer owned) contracts can have a store
    // and reuse location. There's currently a workaround that maps the
    // management contract's store-and-reuse location values to the rental
    // contract's values (see HOF-8052), and so we use the
    // RentalStoreAndReuseLocation enum to cover both cases.
    switch (storeAndReuseLocation) {
        case RentalStoreAndReuseLocation.Local:
            return countrySetting?.storeAndReuseCollectToLocalWarehouse;
        case RentalStoreAndReuseLocation.Zone1:
            return countrySetting?.storeAndReuseCollectToHubWarehouse;
    }
};

export const useCreateCollectionShipment = (
    selectedAssignments: UserAssignmentDto[],
    onSuccess: (id: UUID) => void,
    country: Country,
) => {
    const queryClient = useQueryClient();

    const selection = useAssignmentSelection(selectedAssignments);
    const firstAssignment = selectedAssignments[0];
    const contract = firstAssignment.contract;

    const { data: countrySetting } = useFulfilmentCountrySettingQuery(country);
    const defaultWarehouse = getDefaultWarehouse(
        countrySetting,
        getAssignmentStoreAndReuse(contract)?.collectToLocation,
    );

    const mutation = useMutation({
        mutationFn: shipmentService.createCollectionShipment,
        onSuccess: (id: UUID) => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess(id);
        },
    });

    const form = useForm<CreateCollectionShipmentFormData>({
        initial: {
            warehouseId: defaultWarehouse?.id,
            country: country,
            ...initialCollectionSurveyFormData,
        },
        onSubmit: data => {
            mutation.mutate({
                assignmentIds: selection.assignments.map(({ id }) => id),
                assignedUserId: data.assignedUserId ?? null,
                warehouseId: data.warehouseId!,
                collectionRequest: getCollectionRequestPayloadFromSurveyFormData(data),
            });
        },
        validate: ({ warehouseId }) => ({
            warehouseId: errorMap([isEmpty(warehouseId), 'Warehouse is required']),
        }),
    });

    useEffect(() => {
        if (!defaultWarehouse) {
            return;
        }
        if (!form.values.warehouseId) {
            form.setValues({
                warehouseId: defaultWarehouse.id,
            });
        }
    }, [form.values.warehouseId, defaultWarehouse]);

    return {
        form,
        selection,
        isLoadingMutation: mutation.isPending,
    };
};

interface CreateDropshippingShipmentFormData {
    assignedUserId?: UUID;
}

export const useCreateDropshippingShipment = (
    selectedAssignments: UserAssignmentDto[],
    addressId: UUID,
    onSuccess: (id: UUID) => void,
) => {
    const queryClient = useQueryClient();

    const selection = useAssignmentSelection(selectedAssignments);

    const mutation = useMutation({
        mutationFn: shipmentService.createDropshippingShipment,
        onSuccess: (id: UUID) => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess(id);
        },
    });

    const form = useForm<CreateDropshippingShipmentFormData>({
        initial: {},
        onSubmit: ({ assignedUserId }) => {
            mutation.mutate({
                assignmentIds: selection.assignments.map(({ id }) => id),
                assignedUserId: assignedUserId ?? null,
                addressId,
            });
        },
    });

    return {
        form,
        selection,
        isLoadingMutation: mutation.isPending,
    };
};

interface CreateTransferShipmentFormData extends SendCollectionSurveyFormData {
    assignedUserId?: UUID;
    toUserId: UUID | null;
    disposeFurniture: boolean;
    arrangeCollectionForDisposal: boolean;
    omConsentGiven: boolean;
    allowTransfersBetweenTaxZones: boolean;
    generateTransferFees: boolean;
}

interface CreateTransferShipmentPayloadValidation {
    toUserId?: string;
    omConsentGiven?: string;
}

export const useCreateTransferShipment = (
    selectedAssignments: UserAssignmentDto[],
    onSuccess: (id: UUID) => void,
) => {
    const queryClient = useQueryClient();
    const selection = useAssignmentSelection(selectedAssignments);

    const mutation = useMutation({
        mutationFn: shipmentService.createTransferShipment,
        onSuccess: (id: UUID) => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess(id);
        },
    });

    const selectionContainsFurniture = selection.assignments.some(
        i => i.item && isParentContainsCategory(ParentProductCategory.Furniture, i.product.category),
    );
    const selectionContainsCustomerOwnedFurniture = selection.assignments.some(
        i =>
            i.item &&
            belongsToOrganization(i.item.ownership.type) &&
            isParentContainsCategory(ParentProductCategory.Furniture, i.product.category),
    );

    const form = useForm<
        CreateTransferShipmentFormData,
        CreateTransferShipmentFormData,
        CreateTransferShipmentPayloadValidation
    >({
        initial: {
            toUserId: null,
            disposeFurniture: selectionContainsFurniture && !selectionContainsCustomerOwnedFurniture,
            arrangeCollectionForDisposal: false,
            omConsentGiven: false,
            generateTransferFees: true,
            allowTransfersBetweenTaxZones: false,
            ...initialCollectionSurveyFormData,
        },
        validate(formState) {
            return {
                toUserId: errorMap([isEmpty(formState.toUserId), 'Transfer to user cannot be empty']),
                omConsentGiven: errorMap([
                    !formState.omConsentGiven,
                    'If consent is not available, neither are transfers.',
                ]),
            };
        },
        onSubmit: v => {
            mutation.mutate({
                assignmentIds: selection.assignments.map(i => i.id),
                assignedUserId: v.assignedUserId ?? null,
                toUserId: v.toUserId!,
                disposeFurniture: v.disposeFurniture,
                userKeepsFurniture: !v.arrangeCollectionForDisposal,
                collectionNotBefore: null,
                collectionRequest: getCollectionRequestPayloadFromSurveyFormData(v),
                allowTransfersBetweenTaxZones: v.allowTransfersBetweenTaxZones,
                generateTransferFees: v.generateTransferFees,
            });
        },
    });

    return {
        form,
        selection,
        isLoadingMutation: mutation.isPending,
        selectionContainsFurniture,
        selectionContainsCustomerOwnedFurniture,
    };
};
