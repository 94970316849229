import { queryBooleanParser } from '@hofy/router';
import { FilterDefinitionSingle } from '@hofy/ui';

interface BooleanFilterDefinitionOptions {
    name?: string;
    showInActive?: boolean;
    toLabel?(value: boolean): string;
}

export const useBooleanFilterDefinition = ({
    name,
    toLabel,
    showInActive,
}: BooleanFilterDefinitionOptions): FilterDefinitionSingle<boolean> => {
    return {
        type: 'single',
        name: name,
        query: queryBooleanParser(),
        toLabel: toLabel,
        showInActive,
    };
};
