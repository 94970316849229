import { useMemo } from 'react';

import { getCountryBillingEntity, useBillingEntitiesQuery, useHofyWarehouseQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useBillingEntityForWarehouseCountry = (
    organizationId: UUID | null,
    warehouseId: UUID | null,
) => {
    const { isLoading, billingEntities } = useBillingEntitiesQuery({
        organizationIds: organizationId ? [organizationId] : [],
        search: '',
    });
    const { data: hofyWarehouse } = useHofyWarehouseQuery(warehouseId);
    return useMemo(() => {
        if (
            isLoading ||
            !organizationId ||
            !hofyWarehouse ||
            !billingEntities ||
            !hofyWarehouse.address?.country
        ) {
            return undefined;
        }
        return getCountryBillingEntity(billingEntities, hofyWarehouse.address.country);
    }, [billingEntities, hofyWarehouse]);
};
