import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { FormContainer, FormModal, ModalContent, ModalFooter, ModalHeader, SubmitButton } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useChangeOwnership } from '../../../store/items/useChangeItemOwnership';
import { UpdateOwnershipForm } from '../updateItemModal/UpdateOwnershipForm';

interface ChangeItemStatusModalProps {
    item: ItemDto;
    onCancel(): void;
}

export const ChangeItemOwnershipModal: FC<ChangeItemStatusModalProps> = ({ item, onCancel }) => {
    const { form, isLoading } = useChangeOwnership(item, onCancel);
    return (
        <FormModal onClose={form.discard} onSubmit={form.submit} isLoading={isLoading} width={600}>
            <ModalHeader title='Change ownership' />
            <ModalContent>
                <FormContainer>
                    <UpdateOwnershipForm form={form} />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onCancel} />
                <SubmitButton label='Change ownership' action='destructive' />
            </ModalFooter>
        </FormModal>
    );
};
