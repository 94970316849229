import React, { Key, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { childrenProps } from '../../helpers/React';
import { TestKeyAware } from '../../types';
import { Box, OuterBoxProps } from '../base';
import { PillTab, PillTabProps } from './PillTab';

export interface PillTabsProps<T extends Key> extends OuterBoxProps, TestKeyAware {
    active: T;
    onChange(v: T): void;
    children: ReactNode;
}

export const PillTabs = <T extends Key>({
    active,
    onChange,
    children,
    testKey = 'pill-tabs',
    ...props
}: PillTabsProps<T>) => {
    const tabs = childrenProps<PillTabProps<T>>(children, PillTab);

    if (!tabs.length) {
        return null;
    }

    return (
        <Box
            inline
            row
            wrap
            columnGap={8}
            padding={2}
            bg={Color.NonContextualGreySubtle}
            rounded={8}
            data-test-key={testKey}
            {...props}
        >
            {tabs.map(item => (
                <PillTab
                    key={item.id}
                    {...item}
                    onClick={onChange}
                    active={active === item.id}
                    testKey={item.id.toString()}
                />
            ))}
        </Box>
    );
};
