import React, { FC, ReactNode, useMemo } from 'react';

import { DisableContext, useDisableContext } from './DisableContext';

interface DisableContextProps {
    disabled?: boolean;
    children?: ReactNode;
}

/**
 * This provider is for every wrapper that applies the `disabled` prop to the `DisableContext`.
 * The rule is that the parent `disabled=true` wrappers will override the child `disabled=false` wrappers.
 */
export const DisableContextProvider: FC<DisableContextProps> = ({
    children,
    disabled: newDisabledValue = false,
}) => {
    const context = useDisableContext();
    const disabled = context.disabled === true ? true : newDisabledValue;

    const value = useMemo(() => ({ disabled }), [disabled]);

    return <DisableContext.Provider value={value}>{children}</DisableContext.Provider>;
};
