import { ColorDye, getEnumValues } from '@hofy/global';

export enum ContractStatusFilter {
    Pending = 'pending',
    Active = 'active',
    Ended = 'ended',
    Cancelled = 'cancelled',
}

export const contractStatusColors: Record<ContractStatusFilter, ColorDye> = {
    [ContractStatusFilter.Pending]: 'orange',
    [ContractStatusFilter.Active]: 'green',
    [ContractStatusFilter.Ended]: 'blue',
    [ContractStatusFilter.Cancelled]: 'red',
};

export const allContractStatusFilterStatuses = getEnumValues<ContractStatusFilter>(ContractStatusFilter);
