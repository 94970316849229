import React, { FC } from 'react';

import { DateString } from '@hofy/global';
import { Badge, Tooltip } from '@hofy/ui';

import { newJoinerDeliveryDates } from './NewJoinerLabel';

interface NewJoinerBadgeProps {
    startDate: DateString;
}

export const NewJoinerBadge: FC<NewJoinerBadgeProps> = ({ startDate }) => {
    const { startDateFormatted, targetDateFormatted } = newJoinerDeliveryDates(startDate);

    return (
        <Tooltip body={`Delivery target date: ${targetDateFormatted}`}>
            <Badge color='violet' label={`NEW JOINER - ${startDateFormatted}`} dot />
        </Tooltip>
    );
};
