import React, { FC } from 'react';

import { AddressDto, AssignmentStatus, isCollectionPending, isDeliveryPending } from '@hofy/api-shared';
import { Badge, Tooltip } from '@hofy/ui';

import { AddressTooltipOverlay } from '../../address/AddressTooltipOverlay';

interface OrganizationWarehouseBadgeProps {
    assignmentStatus: AssignmentStatus;
    isItemAtWarehouse: boolean;
    atWarehouseAddress: AddressDto | null;
    isItemCollectionToWarehouse: boolean;
    toWarehouseAddress: AddressDto | null;
}

export const OrganizationWarehouseBadge: FC<OrganizationWarehouseBadgeProps> = ({
    assignmentStatus,
    isItemAtWarehouse,
    atWarehouseAddress,
    isItemCollectionToWarehouse,
    toWarehouseAddress,
}) => {
    if (!(isDeliveryPending(assignmentStatus) || isCollectionPending(assignmentStatus))) {
        return null;
    }

    const isDeliveryFromWarehouseLabel =
        isItemAtWarehouse && isDeliveryPending(assignmentStatus) ? 'From storage location' : null;
    const isCollectionToWarehouseLabel =
        isItemCollectionToWarehouse && isCollectionPending(assignmentStatus) ? 'To storage location' : null;

    const label = isDeliveryFromWarehouseLabel ?? isCollectionToWarehouseLabel;
    if (!label) {
        return null;
    }

    const address = isItemAtWarehouse ? atWarehouseAddress : toWarehouseAddress;
    return (
        <Tooltip
            placement='top'
            bodySlot={address && <AddressTooltipOverlay address={address} />}
            maxWidth='auto'
            interactive
        >
            <Badge label={label} color='aquamarine' />
        </Tooltip>
    );
};
