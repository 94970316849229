import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import {
    AddressDto,
    formatVariant,
    ProductRefDto,
    TermAndConditionRefDto,
    VariantDto,
} from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { PricePair } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Labeled, LabeledText, Paragraph3, Paragraph4 } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';
import { ProductDetailsLink } from '../../productsPage/ProductDetailsLink';

export const ContractBillingCountryLabel: FC<{
    billingToAddress: AddressDto;
}> = ({ billingToAddress }) => (
    <Labeled label='Billing country' content={<CountryLabel country={billingToAddress.country} />} />
);
export const ContractBillingAddressLabeledText: FC<{
    billingToAddress: AddressDto;
}> = ({ billingToAddress }) => (
    <LabeledText label='Billing address' content={<PostAddressView address={billingToAddress} />} />
);
export const ContractTermsAndConditionsLabeledText: FC<{
    termAndCondition: TermAndConditionRefDto;
}> = ({ termAndCondition }) => <LabeledText label='Term and condition' content={termAndCondition.name} />;
export const ContractBudgetLabeledText: FC<{
    budgetConsumption: PricePair;
}> = ({ budgetConsumption }) => {
    const { formatPricePair } = usePrice();
    return (
        <LabeledText
            label='Budget consumption'
            content={formatPricePair(budgetConsumption, v => `(${v} in org)`)}
        />
    );
};
export const ContractProductDetails: FC<{
    variant: VariantDto;
    product: ProductRefDto;
}> = ({ variant, product }) => (
    <Labeled
        label='Product'
        content={
            <ProductItem
                image={variant.image}
                label={<ProductItemLabel variant={variant} product={product} />}
                subLabel={formatVariant(variant)}
            />
        }
    />
);
const ProductItemLabel: FC<{
    variant: VariantDto;
    product: ProductRefDto;
}> = ({ variant, product }) => {
    if (variant.isControlledByOrganization) {
        return (
            <Box column>
                <Paragraph3 color={Color.ContentPrimary}>{product.name}</Paragraph3>
                <Paragraph4 color={Color.ContentPrimary}>Organization product</Paragraph4>
            </Box>
        );
    }
    return (
        <ProductDetailsLink id={product.id}>
            <Paragraph3 color={Color.ContentPrimary}>{product.name}</Paragraph3>
        </ProductDetailsLink>
    );
};
