import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import {
    AsyncButton,
    FormContainer,
    FormInput,
    FormSwitch,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateOrganizationDeelData } from '../../../store/organizations/useUpdateOrganizationDeelData';

interface DeelUpdateModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const DeelUpdateModal: FC<DeelUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationDeelData(organization, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={tr('organization-page.deel-update.modal.title')} />
            <ModalContent>
                <FormContainer>
                    <FormInput
                        api={form.fields.deelId}
                        label={tr('organization-page.deel-update.modal.form.deel-id')}
                    />
                    <FormSwitch
                        api={form.fields.itReady}
                        label={tr('organization-page.deel-update.modal.form.it-ready')}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.deel-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.deel-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                    disabled={!form.isValid}
                />
            </ModalFooter>
        </Modal>
    );
};
