import { isEqual, xorWith } from 'lodash';
import { useState } from 'react';

import { UUID } from '@hofy/global';

export const useShipmentsSelected = () => {
    const [shipmentSelectedIds, setShipmentSelectedIds] = useState<UUID[]>([]);

    const handlerAddShipmentId = (shipmentId: UUID) => {
        setShipmentSelectedIds(xorWith([...shipmentSelectedIds], [shipmentId], isEqual));
    };

    return {
        shipmentSelectedIds,
        handlerAddShipmentId,
        setShipmentSelectedIds,
    };
};
