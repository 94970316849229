import React, { FC } from 'react';

import { allInvoiceEntryTypes } from '@hofy/api-shared';
import {
    BackButton,
    BlockLoader,
    CenteredBox,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Heading3, Placeholder, SvgIcon, SvgIllustration } from '@hofy/ui';

import { useUpdateInvoiceEntry } from '../../../../store/invoiceEntries/useUpdateInvoiceEntry';
import { InvoiceEntryForm } from '../components/InvoiceEntryForm';
import { InvoicingStatusChip } from '../components/InvoicingStatusChip';

interface UpdateInvoiceEntrySlideoutProps {
    entryId: UUID;
    basePath: string;
    onClose(): void;
}

export const UpdateInvoiceEntrySlideout: FC<UpdateInvoiceEntrySlideoutProps> = ({
    entryId,
    basePath,
    onClose,
}) => {
    const { form, isLoading, isLoadingMutation, isError, invoiceEntry } = useUpdateInvoiceEntry(
        entryId,
        onClose,
    );

    if (isLoading) {
        return <BlockLoader fullHeight flex={1} />;
    }

    if (isError) {
        return (
            <CenteredBox>
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No such invoice entry'
                    message='We could not find an invoice entry with provided identifier'
                />
            </CenteredBox>
        );
    }
    const isReadonly = invoiceEntry?.isReadonly || invoiceEntry?.isPartner;

    return (
        <Slideout width={900} onClose={onClose}>
            <SlideoutHeader
                title={
                    <Box row>
                        <Heading3 color={Color.Neutral900} marginRight={10}>
                            {invoiceEntry?.isInvoiced && 'View invoice entry'}
                            {!invoiceEntry?.isInvoiced && 'Edit invoice entry'}
                        </Heading3>
                        {invoiceEntry && <InvoicingStatusChip invoiceEntry={invoiceEntry} />}
                    </Box>
                }
            />

            <SlideoutContent borderBottom paddingBottom={30}>
                <InvoiceEntryForm
                    formState={form}
                    invoiceEntry={invoiceEntry}
                    organizationId={invoiceEntry?.organization?.id ?? null}
                    entryTypes={allInvoiceEntryTypes}
                    editable={!isReadonly}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} defaultNavigation={basePath} />
                <Box row>
                    <AsyncButton
                        isLoading={isLoadingMutation}
                        label='Save changes'
                        onClick={form.form.submit}
                        disableCheck
                        disabled={isReadonly}
                    />
                </Box>
            </SlideoutFooter>
        </Slideout>
    );
};
