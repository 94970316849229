import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps } from '../base';
import { SummaryInnerContent, SummaryInnerContentProps } from './SummaryInnerContent';

export interface SummaryProps extends SummaryInnerContentProps, OuterBoxProps {}

export const Summary: FC<SummaryProps> = ({ title, tooltip, children, ...rest }) => {
    return (
        <Box
            flex={1}
            padding={16}
            bg={Color.BackgroundSubtleNeutral}
            border
            borderColor={Color.NonContextualGreySurfaceBorder}
            rounded={8}
            {...rest}
        >
            <SummaryInnerContent title={title} tooltip={tooltip}>
                {children}
            </SummaryInnerContent>
        </Box>
    );
};
