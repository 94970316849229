import React, { ElementRef, forwardRef, ReactNode } from 'react';

import { Labeled } from '../../../labeled';
import { PriceInput, PriceInputNullableStringProps, PriceInputOnlyStringProps } from './PriceInput';

interface BaseLabeledPriceInputProps {
    label: ReactNode;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

type LabeledFormNormalPriceInputProps = Omit<PriceInputOnlyStringProps, 'isError'> &
    BaseLabeledPriceInputProps;
type LabeledFormNullablePriceInputProps = Omit<PriceInputNullableStringProps, 'isError'> &
    BaseLabeledPriceInputProps;

export type LabeledPriceInputProps = LabeledFormNormalPriceInputProps | LabeledFormNullablePriceInputProps;

export const LabeledPriceInput = forwardRef<ElementRef<'input'>, LabeledPriceInputProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,

            value,
            onChange,
            onBlur,
            onFocus,
            type,
            nullable,
            placeholder,
            disabled,
            clearable,
            withoutDecimals,
            defaultCurrency,
            testKey,
            autoFocus,
            rightSlot,

            ...rest
        },
        ref,
    ) => {
        return (
            <Labeled
                as='label'
                label={label}
                isRequired={isRequired}
                content={
                    <PriceInput
                        ref={ref}
                        value={value!}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        type={type}
                        nullable={nullable}
                        placeholder={placeholder}
                        disabled={disabled}
                        clearable={clearable}
                        withoutDecimals={withoutDecimals}
                        defaultCurrency={defaultCurrency}
                        testKey={testKey}
                        autoFocus={autoFocus}
                        rightSlot={rightSlot}
                        isError={!!errorMessage}
                    />
                }
                errorMessage={errorMessage}
                helperText={helperText}
                {...rest}
            />
        );
    },
);
