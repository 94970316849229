import React, { FC } from 'react';

import { formatVariant, ProductDto, VariantDetailsDto } from '@hofy/api-shared';
import { Box, LabeledText, SectionTitle2 } from '@hofy/ui';

import { ProductItem } from '../../../components/domain/products/ProductItem';

interface ProductVariantDetailsProps {
    product: ProductDto;
    variant: VariantDetailsDto;
}

export const ProductVariantDetails: FC<ProductVariantDetailsProps> = ({ product, variant }) => (
    <Box column>
        <ProductItem
            image={variant.image?.url}
            label={<SectionTitle2>{product.name}</SectionTitle2>}
            subLabel={formatVariant(variant)}
        />
        <Box row marginTop={20}>
            <LabeledText
                flex={1}
                label='Manufacture part number'
                content={variant.manufacturerPartCode || '--'}
            />
            <LabeledText flex={1} label='SKU' content={variant.sku} />
        </Box>
    </Box>
);
