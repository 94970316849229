import { isNil } from 'lodash';
import styled, { CSSObject } from 'styled-components';

import { Theme } from '@hofy/theme';

import { BaseBox, BaseBoxProps } from './BaseBox';
import { sizeToValue, spacingValuesFromTheme } from './boxHelpers';
import { AlignItems, MarginBoxProps } from './types';

export interface OuterBoxProps extends MarginBoxProps, BaseBoxProps {
    flex?: number | 'auto';
    shrink?: number;
    alignSelf?: AlignItems;

    fullWidth?: boolean;
    fullHeight?: boolean;
    fullSize?: boolean;
    maxFullWidth?: boolean;
    maxFullHeight?: boolean;
    width?: string | number;
    minWidth?: string | number;
    maxWidth?: string | number;
    height?: string | number;
    maxHeight?: string | number;
    minHeight?: string | number;
    rect?: number;
}

export const marginStyleGenerator = (
    {
        margin,
        marginHorizontal,
        marginVertical,
        marginLeft = marginHorizontal,
        marginRight = marginHorizontal,
        marginTop = marginVertical,
        marginBottom = marginVertical,
    }: MarginBoxProps,
    theme: Theme,
) => spacingValuesFromTheme({ margin, marginLeft, marginRight, marginTop, marginBottom }, theme);

export const OuterBox = styled(BaseBox)<OuterBoxProps>(
    ({
        theme,

        fullWidth = false,
        fullHeight = false,
        fullSize = false,
        maxFullWidth = false,
        maxFullHeight = false,
        width,
        maxWidth,
        minWidth,
        height,
        maxHeight,
        minHeight,
        rect,
        ...rest
    }) => {
        const styles: CSSObject = {};

        if (fullWidth) {
            styles.width = '100%';
        }

        if (fullHeight) {
            styles.height = '100%';
        }

        if (fullSize) {
            styles.width = '100%';
            styles.height = '100%';
        }

        if (maxFullWidth) {
            styles.maxWidth = '100%';
        }

        if (maxFullHeight) {
            styles.maxHeight = '100%';
        }

        if (!isNil(rect)) {
            styles.width = `${rect}px`;
            styles.height = `${rect}px`;
        }

        if (!isNil(width)) {
            styles.width = sizeToValue(width);
        }

        if (!isNil(maxWidth)) {
            styles.maxWidth = sizeToValue(maxWidth);
        }

        if (!isNil(minWidth)) {
            styles.minWidth = sizeToValue(minWidth);
        }

        if (!isNil(height)) {
            styles.height = sizeToValue(height);
        }

        if (!isNil(maxHeight)) {
            styles.maxHeight = sizeToValue(maxHeight);
        }

        if (!isNil(minHeight)) {
            styles.minHeight = sizeToValue(minHeight);
        }

        const margins = marginStyleGenerator(rest, theme);

        return { ...styles, ...margins };
    },
);
