import React, { FC } from 'react';

import { OperatingSystem } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, Tooltip } from '@hofy/ui';

import { PairsTableTooltipOverlay } from '../../../../../components/design/tooltips/PairsTableTooltipOverlay';
import { useProductTooltipItems } from '../../../../../store/products/useProductTooltipItems';

interface ProductInfoCellProps extends BoxProps {
    productName: string;
    imageUrl: string;
    imageSize?: number;
    size: string | null;
    style: string | null;
    os: OperatingSystem | null;
    serial: string | null;
    mpc: string | null;
    sku: string | null;
    isCustomerOwned: boolean;
}

export const ProductInfoCell: FC<ProductInfoCellProps> = ({
    productName,
    imageUrl,
    imageSize = 40,
    size,
    style,
    os,
    serial,
    mpc,
    sku,
    isCustomerOwned,
    ...boxProps
}) => {
    const tooltipItems = useProductTooltipItems({ size, style, os, serial, mpc, sku });

    return (
        <Box row gap={12} {...boxProps}>
            <Tooltip
                bodySlot={<PairsTableTooltipOverlay items={tooltipItems} marginLeft={12} />}
                maxWidth='auto'
                interactive
            >
                <ProductImage image={imageUrl} size={imageSize} />
            </Tooltip>

            <Box>
                <Paragraph3 color={Color.ContentPrimary}>{productName}</Paragraph3>
                <ProductInfoSubHeading sku={sku} isCustomerOwned={isCustomerOwned} serial={serial} />
            </Box>
        </Box>
    );
};

interface ProductInfoSubHeadingProps {
    sku: string | null;
    isCustomerOwned: boolean;
    serial: string | null;
}

const ProductInfoSubHeading: FC<ProductInfoSubHeadingProps> = ({ isCustomerOwned, sku, serial }) => {
    if (!isCustomerOwned) {
        return <Paragraph3 color={Color.ContentTertiary}>{sku || '--'}</Paragraph3>;
    }

    return <Paragraph3 color={Color.ContentTertiary}>Customer owned | SN: {serial || '--'}</Paragraph3>;
};
