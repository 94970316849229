import { omit } from 'lodash';
import { useMemo } from 'react';

import { ItemCondition, ItemValuationFilters } from '@hofy/api-admin';
import { ItemLocation } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';
import { countObjectValues, endOfPreviousMonth, toISODate } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam, useStringQueryParam } from '@hofy/router';

export const useItemValuationFilters = () => {
    const initialDate = useMemo(() => toISODate(endOfPreviousMonth()), []);

    const [search, setSearch] = useStringQueryParam<string>('search', '');
    const [hofySubsidiaries, setHofySubsidiaries] = useArrayQueryParam<UUID>('hofySubsidiaries', []);
    const [warehouses, setWarehouses] = useArrayQueryParam<UUID>('warehouses', []);
    const [location, setLocation] = useStringQueryParam<ItemLocation>('location');
    const [upToMonth, setUpToMonth] = useStringQueryParam<DateString>('upToMonth', initialDate);
    const [condition, setCondition] = useStringQueryParam<ItemCondition>('condition');

    const filters = useStructMemo<ItemValuationFilters>({
        search,
        hofySubsidiaries,
        warehouses,
        upToMonth,
        location,
        condition,
    });

    const filtersToCount = omit(filters, 'search', 'upToMonth');
    const filterCount = countObjectValues(filtersToCount);

    return {
        search,
        filters,
        filterCount,
        setSearch,
        setHofySubsidiaries,
        setWarehouses,
        setUpToMonth,
        setLocation,
        setCondition,
    };
};
