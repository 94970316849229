import React, { FC } from 'react';

import { IssueUserDto } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';
import { UserStatusChip } from '@hofy/ui-domain';

interface UserDetailsSectionProps {
    user: IssueUserDto;
}

export const UserDetailsSection: FC<UserDetailsSectionProps> = ({ user }) => {
    const { id, email, address, status } = user;
    return (
        <FormSection label='User details' paddingTop={30}>
            <FormGridRow columns={4}>
                <LabeledText label='User id' content={id} />
                <LabeledText label='Name' content={formatUserName(user)} />
                <LabeledText label='Email' content={email} />
                <Labeled label='Country' content={<CountryLabel country={address.country} />} />
                <LabeledText label='Delivery instructions' content={address.deliveryInstructions} />
                <Labeled label='Account status' content={<UserStatusChip status={status} />} />
            </FormGridRow>
        </FormSection>
    );
};
