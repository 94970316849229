import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IssueDetailsDto, issuesCacheKey, issueService, UpdateIssuePayload } from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

export const useUpdateIssue = (issue: IssueDetailsDto) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: UpdateIssuePayload) => issueService.updateIssue(issue.id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [issuesCacheKey] });
            showToast({
                type: 'positive',
                message: 'Issue successfully updated',
            });
        },
    });

    const form = useForm<UpdateIssuePayload>({
        initial: {
            resolvedOn: issue.resolvedOn,
        },
        onSubmit: mutation.mutate,
    });

    return {
        updateIssueIsLoading: mutation.isPending,
        form,
    };
};
