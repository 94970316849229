import { autoUpdate, Placement, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';

import { ContentWidth } from '../types/InteractiveListTypes';
import { dropdownMiddleware } from './dropdownMiddleware';

interface UseDropdownOptions {
    open?: boolean;
    onChange?(open: boolean): void;
    placement?: Placement;
    contentWidth?: ContentWidth;
    flipPadding?: number;
}

export const useDropdown = ({
    open,
    onChange,
    placement = 'bottom',
    contentWidth,
    flipPadding,
}: UseDropdownOptions = {}) => {
    const data = useFloating({
        placement,
        open,
        onOpenChange: onChange,
        whileElementsMounted: autoUpdate,
        middleware: dropdownMiddleware({ contentWidth, flipPadding }),
    });

    const dismiss = useDismiss(data.context);
    const role = useRole(data.context);

    const interactions = useInteractions([dismiss, role]);

    return {
        interactions,
        data,
    };
};
