import React, { FC, ReactNode } from 'react';

import { Box } from '../../base';
import { GradientSkeleton, Skeleton } from '../../loader/Skeleton';
import { PageCardLayout } from './PageCardLayout';
import { ColumnTypes, PageColumnsLayout } from './PageColumnsLayout';
import { PageContent } from './PageContent';
import { PageHeader } from './PageHeader';

interface PageSkeletonProps {
    withTabs?: boolean;
    withRightSlot?: boolean;
    children?: ReactNode;
    columns?: ColumnTypes;
}

export const PageSkeleton: FC<PageSkeletonProps> = ({ withTabs, withRightSlot, children, columns }) => {
    const contentSkeleton = columns && (
        <PageColumnsLayout columns={columns}>
            <PageCardLayout>
                <GradientSkeleton height={500} />
            </PageCardLayout>
            <PageContent>
                <GradientSkeleton height={500} />
            </PageContent>
        </PageColumnsLayout>
    );

    return (
        <>
            <PageHeader
                title={<Skeleton width={200} height={32} />}
                tabsSlot={withTabs ? <Skeleton width={400} height={40} marginBottom={8} /> : null}
                rightSlot={withRightSlot ? <Skeleton width={200} height={40} /> : null}
            />
            {children ? (
                children
            ) : contentSkeleton ? (
                contentSkeleton
            ) : (
                <Box
                    flex='auto'
                    overflow='hidden'
                    paddingVertical='mainMarginVertical'
                    paddingHorizontal='mainMarginHorizontal'
                >
                    <GradientSkeleton height={500} />
                </Box>
            )}
        </>
    );
};
