import { noop } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthError, isAuthError, TokenPair } from '@hofy/api-auth';
import { useLocalStorage } from '@hofy/hooks';

import { authProvider } from '../service/authProvider';

export interface AuthWrapperContextProps {
    signOut(): void;
    signIn(): void;
    isAuthenticated: boolean;

    authError?: AuthError;
    storageToken: TokenPair | null;
}

const emptyAuthWrapperContext: AuthWrapperContextProps = {
    signOut: noop,
    signIn: noop,
    isAuthenticated: false,
    storageToken: null,
};

export const AuthWrapperContext = createContext(emptyAuthWrapperContext);

export const useAuthWrapper = () => {
    const history = useHistory<{ redirect: string }>();
    const [isInitialized, setIsInitialized] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authError, setAuthError] = useState<AuthError>();
    const [storageToken] = useLocalStorage<TokenPair | null>(authProvider.STORAGE_KEY, null);

    const clearErrors = () => {
        setAuthError(undefined);
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isInitialized) {
            setIsAuthenticated(!!storageToken);
        }
    }, [storageToken, isInitialized]);

    const init = async () => {
        try {
            const result = await authProvider.init({ historyReplaceHandler: history.replace });
            if (isAuthError(result)) {
                setAuthError(result);
                setIsAuthenticated(false);
            } else {
                setIsAuthenticated(result);
                clearErrors();
            }
        } finally {
            setIsInitialized(true);
        }
    };

    const signIn = async () => {
        await authProvider.signIn({
            redirect: history.location.state?.redirect,
        });
    };

    const signOut = async () => {
        clearErrors();
        setIsAuthenticated(false);
        authProvider.signOut();
    };

    return {
        signIn,
        signOut,
        isInitialized,
        isAuthenticated,
        authError,
        storageToken,
    };
};
