import { useTrRegion } from '@hofy/i18n';

import { Availability } from './types/Availability';

export const useTrAvailability = () => {
    const tr = useTrRegion();

    return (v: Availability) => {
        if (v === 'ALL') {
            return 'All';
        }
        return tr(v);
    };
};
