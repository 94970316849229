import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { UUIDRoute } from '@hofy/router';

import { InspectionSlideoutRouter } from '../inspectionsPage/inpsectionSlideout/InspectionSlideoutRouter';
import { CreateRepairForItemSlideoutRouter } from '../repairsPage/slideouts/createRepair/CreateRepairForItemSlideoutRouter';
import { RepairSlideoutRouter } from '../repairsPage/slideouts/viewRepair/RepairSlideoutRouter';
import { ItemDetailsSlideout } from './ItemDetailsSlideout';

interface ItemSlideoutRouterProps {
    base: string;
}

export const ItemSlideoutRouter: FC<ItemSlideoutRouterProps> = ({ base }) => {
    const history = useHistory();
    const { goBack } = useGoBack();
    const handleCreateRepairSlideout = (id: UUID) => history.push(`${base}/create-repair/${id}`);
    const handleOpenInspectionSlideout = (uuid: UUID) => history.push(`${base}/inspection/${uuid}`);
    const handleSlideoutClose = () => goBack(`${base}${history.location.search}`);

    return (
        <>
            <ItemIdRoute path={`${base}/:itemId`} exact>
                {({ itemId }) => (
                    <ItemDetailsSlideout
                        itemId={itemId}
                        onCreateRepairForItem={handleCreateRepairSlideout}
                        onOpenInspectionDetails={handleOpenInspectionSlideout}
                        onClose={handleSlideoutClose}
                    />
                )}
            </ItemIdRoute>
            <CreateRepairForItemSlideoutRouter base={`${base}/create-repair`} onClose={handleSlideoutClose} />
            <RepairSlideoutRouter base={`${base}/repair`} onClose={handleSlideoutClose} />
            <InspectionSlideoutRouter
                base={`${base}/inspection`}
                itemsBase={base}
                onClose={handleSlideoutClose}
            />
        </>
    );
};

const ItemIdRoute = UUIDRoute('itemId', Route);
