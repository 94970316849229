import { Role } from '@hofy/api-shared';
import { Country, UUID } from '@hofy/global';

export interface AdminUserFilters {
    search: string;
    organizations: UUID[];
    roles: Role[];
    redistributableIn: Country | null;
    includeOffboarded: boolean;
    isDeel: boolean | null;
}

export const emptyAdminUsersFilter: AdminUserFilters = {
    search: '',
    organizations: [],
    roles: [],
    redistributableIn: null,
    includeOffboarded: true,
    isDeel: null,
};
