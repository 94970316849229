import { AnyEnum } from '@hofy/global';

import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { BillingEntityPaymentTerms } from '../types/BillingEntityPaymentTerms';
import { SupplierPaymentTerms } from '../types/SupplierPaymentTerms';

export const useTrPaymentTerms = <
    E extends BillingEntityPaymentTerms | SupplierPaymentTerms,
    T extends AnyEnum = AnyEnum,
>(
    enumEntity: T,
) => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<E>(enumEntity, 'payment-terms');
};
