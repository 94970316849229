import { useQuery } from '@tanstack/react-query';

import { itemDepreciationService } from '../service/itemDepreciationService';
import { FixedAssetValuationFilters } from '../service/types/FixedAssetValuationFilters';
import { fixedAssetValuationTotalCacheKey } from './cacheKey';

export const useFixedAssetValuationTotalsQuery = (filters: FixedAssetValuationFilters) => {
    const { data, isLoading } = useQuery({
        queryKey: [fixedAssetValuationTotalCacheKey, filters],

        queryFn: () => itemDepreciationService.getTotalFixedAssetValuation(filters),
    });

    return {
        data,
        isLoading,
    };
};
