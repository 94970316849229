import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { BoxProps, FeaturedIcon, SvgIcon } from '@hofy/ui';
import { LocationCard } from '@hofy/ui-domain';

interface SupplierLocationCardProps extends BoxProps {
    name: string;
    label?: string;
    country?: Country;
}

export const SupplierLocationCard: FC<SupplierLocationCardProps> = ({ name, label, country }) => (
    <LocationCard
        icon={<FeaturedIcon icon={SvgIcon.Box} shape='circle' size={32} variant='vivid' type='warning' />}
        label={label}
        name={name}
        country={country}
    />
);
