import React, { FC } from 'react';

import { renderChildren } from '../../types';
import { Form } from '../form';
import { Modal, ModalProps } from './Modal';

export interface FormModalProps extends ModalProps {
    isLoading?: boolean;
    isError?: boolean;
    submitOnEnter?: boolean;

    onSubmit(): void;
}

export const FormModal: FC<FormModalProps> = ({
    children,
    isLoading,
    isError,
    dismissible,
    headerClose = true,
    submitOnEnter = false,
    onSubmit,
    ...rest
}) => {
    return (
        <Modal dismissible={dismissible ?? !isLoading} headerClose={headerClose} {...rest}>
            <Form
                onSubmit={onSubmit}
                isLoading={isLoading}
                isError={isError}
                submitOnEnter={submitOnEnter}
                column
                flex='auto'
            >
                {renderChildren(children)}
            </Form>
        </Modal>
    );
};
