import { Country, PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { FulfilmentCountrySettingDto } from './types/FulfilmentCountrySettingDto';
import { FulfilmentCountrySettingPayload } from './types/FulfilmentCountrySettingPayload';
import { FulfilmentCountrySettingsFilter } from './types/FulfilmentCountrySettingsFilter';
import { FulfilmentCountrySettingStoreAndReuseEnabledDto } from './types/FulfilmentCountrySettingStoreAndReuseEnabledDto';

class FulfilmentCountrySettingService {
    public getCountrySettingByCountry = async (country: Country): Promise<FulfilmentCountrySettingDto> => {
        return await restClient.getJson<FulfilmentCountrySettingDto>(
            `/api/admin/fulfilment-country-settings/${country}`,
        );
    };

    public getCountrySettingsStoreAndReuseEnabled = async (): Promise<
        FulfilmentCountrySettingStoreAndReuseEnabledDto[]
    > => {
        return await restClient.getJson<FulfilmentCountrySettingStoreAndReuseEnabledDto[]>(
            `/api/admin/fulfilment-country-settings/with-store-and-reuse-enabled`,
        );
    };

    public listCountrySettings = async (
        filters: FulfilmentCountrySettingsFilter,
        page: PageRequest,
    ): Promise<PageResponse<FulfilmentCountrySettingDto>> => {
        return await restClient.getJson<PageResponse<FulfilmentCountrySettingDto>>(
            stringifyUrl({
                url: '/api/admin/fulfilment-country-settings',
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public updateFulfilmentCountrySetting = async (
        payload: FulfilmentCountrySettingPayload,
        country: Country,
    ): Promise<void> => {
        return restClient.put(`/api/admin/fulfilment-country-settings/${country}`, payload);
    };
}

export const fulfilmentCountrySettingService = new FulfilmentCountrySettingService();
