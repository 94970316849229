import React, { FC } from 'react';

import { AdminRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { useUpdateShipmentAssignee } from '../../../store/shipments/useUpdateShipmentAssignee';
import { AdminUserSelector } from '../user/AdminUserSelector';

interface ShipmentAdminSelectorProps {
    shipmentId: UUID;
    user: AdminRefDto | null;
}

export const ShipmentAdminSelector: FC<ShipmentAdminSelectorProps> = ({ shipmentId, user }) => {
    const { assignShipment } = useUpdateShipmentAssignee(shipmentId);
    return <AdminUserSelector user={user} onUserChange={assignShipment} />;
};
