import React, { FC, ReactNode } from 'react';

import { BoxProps, Heading3, renderTextNode } from '@hofy/ui';

import { SlideoutContentContainer } from './SlideoutContentContainer';

interface SlideoutHeaderProps extends BoxProps {
    title?: ReactNode;
}

export const SlideoutHeader: FC<SlideoutHeaderProps> = ({ title, children, ...boxProps }) => {
    return (
        <SlideoutContentContainer borderBottom row shrink={0} height={80} {...boxProps}>
            {renderTextNode(title, text => (
                <Heading3>{text}</Heading3>
            ))}
            {children}
        </SlideoutContentContainer>
    );
};
