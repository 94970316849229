import styled from 'styled-components';

import { Box } from '@hofy/ui';

export const ColorBox = styled(Box)`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
`;
