import React, { FC } from 'react';

import { allUSStates, USState } from '@hofy/global';
import { useTrState } from '@hofy/i18n';
import { FormFieldApi, FormSelectSearch } from '@hofy/ui';

import { useAddressI18n } from '../store/useAddressI18n';

interface USStateFormSelectProps {
    api: FormFieldApi<USState | null>;
    isRequired?: boolean;
    disabled?: boolean;
}

export const USStateFormSelect: FC<USStateFormSelectProps> = ({ isRequired = false, disabled, api }) => {
    const { tr } = useAddressI18n();
    const trState = useTrState();
    return (
        <FormSelectSearch
            options={allUSStates}
            api={api}
            toText={(state: USState) => trState(state)}
            label={tr('address.state.label')}
            placeholder={tr('address.state.placeholder')}
            isRequired={isRequired}
            disabled={disabled}
            nullable
            data-test-key='us-state-select'
        />
    );
};
