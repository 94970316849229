import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { Alert, Box, Paragraph3 } from '@hofy/ui';

interface DifferentCountryWarningProps {
    collectionCountry?: Country;
    countries: (Country | null)[];
}

export const DifferentCountryWarning: FC<DifferentCountryWarningProps> = ({
    collectionCountry,
    countries,
}) => {
    const trCountry = useTrCountry();

    const isDifferentCountry = collectionCountry && !countries.includes(collectionCountry);
    if (!isDifferentCountry) {
        return null;
    }

    const createDifferentCountryDescription = () => {
        const countriesKey = countries.length > 1 ? 'Contract countries' : 'Contract country';
        const countriesValue = countries.filter(Boolean).map(trCountry).join(', ');
        return (
            <Box column gap={2}>
                <Paragraph3>Requested collection country: {trCountry(collectionCountry)}</Paragraph3>
                <Paragraph3>
                    {countriesKey}: {countriesValue}
                </Paragraph3>
            </Box>
        );
    };

    return (
        <Alert
            title='Collection from a different country'
            description={createDifferentCountryDescription()}
            type='warning'
        />
    );
};
