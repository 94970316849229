import styled from 'styled-components';

import { Paragraph3 } from './Typography';

export const Ul = styled.ul`
    margin-left: 16px;
`;

export const Ol = styled.ol`
    margin-left: 16px;
`;

export const Li = styled(Paragraph3).attrs({ as: 'li' })`
    ${Ul} & {
        list-style: disc;
    }
    ${Ol} & {
        list-style: decimal;
    }
    & + & {
        margin-top: 4px;
    }
`;
