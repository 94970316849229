import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { NewNotificationSlideout } from './newNotificationSlideout/NewNotificationSlideout';
import { NotificationsPage } from './NotificationsPage';

export const NotificationsRouter: FC = () => {
    const history = useHistory();
    const onClose = () => {
        history.replace(AdminNavLink.Notifications);
    };

    const onSend = () => {
        history.push(`${AdminNavLink.Notifications}/send`);
    };
    return (
        <>
            <NotificationsPage onSend={onSend} />
            <Route path={`${AdminNavLink.Notifications}/send`}>
                <NewNotificationSlideout onClose={onClose} />
            </Route>
        </>
    );
};
