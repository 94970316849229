import { intersection } from 'lodash';

import { useAddressValidator } from '@hofy/address';
import { UpdateUserPayload } from '@hofy/api-admin';
import { allManagerRoles, onboardedUserStatuses, Role, UserStatus } from '@hofy/api-shared';
import {
    is,
    isDifferentThanField,
    isRequired,
    isRequiredId,
    isRequiredIf,
    isRequiredIfFieldIsEmpty,
    useForm,
    validator,
} from '@hofy/ui';

export const useUserForm = (
    initial: UpdateUserPayload,
    status: UserStatus | null,
    onSubmit: (payload: UpdateUserPayload) => void,
    onDiscard?: () => void,
) => {
    const validateAddress = useAddressValidator();
    return useForm<UpdateUserPayload>({
        initial,
        onSubmit(p: UpdateUserPayload) {
            onSubmit(p);
        },
        onDiscard,
        validate: validator<UpdateUserPayload>({
            itPolicyId: isRequiredId('Team is required'),
            firstName: isRequired('First name should not be empty'),
            lastName: isRequired('Last name should not be empty'),
            workEmail: [
                isDifferentThanField('personalEmail', 'Personal email & work email should not be the same'),
                isRequiredIfFieldIsEmpty('personalEmail', 'Work email should not be empty'),
            ],
            personalEmail: [
                isDifferentThanField('workEmail', 'Personal email & work email should not be the same'),
                isRequiredIfFieldIsEmpty('workEmail', 'Personal email should not be empty'),
            ],
            roles: [
                isRequiredIf(
                    () => status === null || onboardedUserStatuses.includes(status),
                    'At least one role should be selected',
                ),
                is(
                    roles =>
                        roles.includes(Role.ReadOnlyManager) ||
                        intersection(allManagerRoles, roles).length === 0,
                    '"Read only manager" role is required to be a manager',
                ),
            ],
            address: validateAddress,
        }),
    });
};
