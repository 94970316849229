import React, { FC } from 'react';

import { FormDropdown } from '@hofy/common';
import { numberToPercent, Percent, zeroPercent } from '@hofy/global';

interface OrganizationModelDropdownProps {
    unbundlingEnabled: boolean;
    disabled?: boolean;
    isError?: boolean | string;
    onChange(values: { unbundlingEnabled: boolean; storefrontFee?: Percent | null }): void;
}

const DEFAULT_STORE_FEE = 25;

interface OrganisationModelDropdownItemsProps {
    unbundled: boolean;
    storefrontFee: Percent | null;
    label: string;
}

export const OrganizationModelDropdown: FC<OrganizationModelDropdownProps> = ({
    unbundlingEnabled,
    disabled = false,
    isError,
    onChange,
}) => {
    const unbundledOption: OrganisationModelDropdownItemsProps = {
        unbundled: true,
        storefrontFee: numberToPercent(DEFAULT_STORE_FEE),
        label: 'Unbundled',
    };
    const bundledOption: OrganisationModelDropdownItemsProps = {
        unbundled: false,
        label: 'Bundled Rental',
        storefrontFee: zeroPercent,
    };

    return (
        <FormDropdown
            label='Model'
            items={[unbundledOption, bundledOption]}
            flex={1}
            isRequired
            value={unbundlingEnabled ? unbundledOption : bundledOption}
            onChange={(item: OrganisationModelDropdownItemsProps) =>
                onChange({
                    storefrontFee: item.storefrontFee,
                    unbundlingEnabled: item.unbundled,
                })
            }
            labelFormatter={({ label }) => label}
            disabled={disabled}
            isError={isError}
        />
    );
};
