import React, { FC } from 'react';

import {
    OrganizationStatus,
    organizationStatusTransitionMap,
    trialOrganizationStatuses,
    useTrOrganizationStatus,
} from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';

export enum OrganizationStatusPermission {
    All,
    TrialOnly,
    None,
}

interface OrganizationStatusDropdownProps {
    onChange(status: OrganizationStatus): void;
    status: OrganizationStatus;
    statusPermission: OrganizationStatusPermission;
}

const organizationStatusItems = (
    status: OrganizationStatus,
    permission: OrganizationStatusPermission,
): OrganizationStatus[] => {
    switch (permission) {
        case OrganizationStatusPermission.All:
            return organizationStatusTransitionMap[status];
        case OrganizationStatusPermission.TrialOnly:
            if (trialOrganizationStatuses.includes(status)) {
                return trialOrganizationStatuses;
            }
            // Don't allow status switching when not in trial status
            return [status];
        default:
            return [status];
    }
};

export const OrganizationStatusDropdown: FC<OrganizationStatusDropdownProps> = ({
    onChange,
    status,
    statusPermission,
}) => {
    const trOrganizationStatus = useTrOrganizationStatus();
    return (
        <FormDropdown
            label='Status'
            items={organizationStatusItems(status, statusPermission)}
            flex={1}
            isRequired
            value={status}
            onChange={onChange}
            labelFormatter={trOrganizationStatus}
            disabled={
                statusPermission === OrganizationStatusPermission.None ||
                (statusPermission === OrganizationStatusPermission.TrialOnly &&
                    !trialOrganizationStatuses.includes(status))
            }
        />
    );
};
