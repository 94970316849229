export const signUpPageI18n = {
    'sign-up-page.title': 'Sign up to Hofy',
    'sign-up-page.form.sign-up': 'Sign up',
    'sign-up-page.form.error': 'Cannot create an account for this email',
    'sign-up-page.form.email': 'Email',
    'sign-up-page.divider.sso': 'Sign up with',
    'sign-up-page.divider.email': 'Sign up with email',
    'sign-up-page.form.terms-and-conditions': 'I agree to Hofy’s',
    'sign-up-page.form.terms-and-conditions.link': 'Terms & Conditions',
    'sign-up-page.form.terms-and-conditions.and': 'and',
    'sign-up-page.form.privacy-policy.link': 'Customer Privacy Policy',
    'sign-up-page.form.terms-and-conditions.required': 'Terms and Conditions required',

    'sign-up-page.content-title': 'Why Hofy?',
    'sign-up.page.content-description1': 'Automate your global IT operations from one platform.',
    'sign-up.page.content-description2':
        'Perform real-world actions on your company equipment with a few clicks.',

    'sign-up-page.content-subtitle': 'Get started for free, no credit card needed.',
    'sign-up-page.content-bullet1': 'Track & manage your equipment with an automated asset tracker.',
    'sign-up-page.content-bullet2': 'Order, ship & deliver in 120+ countries.',
    'sign-up-page.content-bullet3': 'Recover your company devices globally.',
};
