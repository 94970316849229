import { createContext, useContext } from 'react';

export interface FormContextValue {
    onSubmit(): void;
    isLoading: boolean;
    isError: boolean;
    submitOnEnter: boolean;
}

const FormContext = createContext<FormContextValue>({
    onSubmit: () => {
        throw new Error('[formContext] onSubmit not provided (missing <Form/> component?)');
    },
    isLoading: false,
    isError: false,
    submitOnEnter: false,
});

export const FormContextProvider = FormContext.Provider;

export const useFormContext = () => useContext(FormContext);
