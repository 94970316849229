export const commonI18n = {
    'general.text.empty': '-',
    'general.text.dot': '.',
    'general.required-note': '*Required fields',
    'general.clear-selection': 'Clear selection',
    'general.asap': 'As soon as possible',
    'general.placeholder-search-products': 'Search products',
    'general.placeholder-loading-search-results': 'Loading search results',

    'general.contract-option.mo': '/mo',

    'general.text.per-month': 'per month',
    'general.text.ex-taxes': '(excl. taxes)',
    'general.text.inclusive-taxes': '(inc. taxes)',

    'general.step': 'Step {from}/{to}',
    'general.xlsx': 'XLSX',

    'general.yes': 'Yes',
    'general.no': 'No',

    'general.from': 'From',
    'general.to': 'To',

    'general.edit': 'Edit',
    'general.save': 'Save',

    'general.cancel': 'Cancel',
    'general.back': 'Back',
    'general.next': 'Next',

    'general.coming-soon': 'Coming soon',
    'general.none': 'None',
    'general.not-available': 'Not available',
    'general.help': 'Help',
    'general.help-email': 'Request support',
    'general.help-email.email-subject': 'General help',
    'general.help-email.email-body': 'Hi Hofy,',
};
