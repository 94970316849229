import React from 'react';

import { DateRangeStrings } from '@hofy/global';
import { DateRangeInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../../components/design/blockFilters/BlockFilter';

interface InvoiceTimeRangeFilterProps {
    date: DateRangeStrings | null;
    onChange(v: DateRangeStrings): void;
}

export const RevenueTimeRangeFilter = ({ date, onChange }: InvoiceTimeRangeFilterProps) => {
    return (
        <BlockFilter title='Revenue period range' icon={SvgIcon.Calendar}>
            <DateRangeInput value={date} onChange={onChange} nullable clearable />
        </BlockFilter>
    );
};
