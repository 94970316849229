import React, { FC, useState } from 'react';

import {
    EmailsAware,
    EmailType,
    emptyEmailTypes,
    invitationEmailType,
    LoginProfileAware,
} from '@hofy/api-shared';
import {
    AsyncButton,
    Button,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { InviteEmailTypePicker } from './InviteEmailTypePicker';

interface SendInviteModalProps {
    selectedUser: EmailsAware & LoginProfileAware;
    onClose(): void;
    onSubmit(emailType: EmailType[]): void;
    isLoading: boolean;
}

export const SendInviteModal: FC<SendInviteModalProps> = ({ onClose, selectedUser, onSubmit, isLoading }) => {
    const { tr } = useAuthI18n();
    const disabled = emptyEmailTypes(selectedUser);
    const initialEmailType = invitationEmailType(selectedUser);
    const [emailTypes, setEmailTypes] = useState(initialEmailType);

    return (
        <Modal onClose={onClose} width={550} testKey='send-invite-modal'>
            <ModalHeader title={tr('send-invite-modal.header')} />
            <ModalContent>
                <Paragraph3 paddingBottom={10}>{tr('send-invite-modal.form.email-invite')}</Paragraph3>
                <InviteEmailTypePicker
                    disabledEmailTypes={disabled}
                    emailTypes={emailTypes}
                    selectedUser={selectedUser}
                    onChange={setEmailTypes}
                />
            </ModalContent>
            <ModalFooter>
                <Button
                    type='ghost'
                    label={tr('send-invite-modal.header.cancel-button')}
                    onClick={onClose}
                    leftIcon={SvgIcon.Cross}
                    testKey='cancel-button'
                />
                <AsyncButton
                    disabled={emailTypes.length === 0}
                    label={tr('send-invite-modal.header.submit-button')}
                    onClick={() => onSubmit(emailTypes)}
                    testKey='submit-button'
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
