import { isNil } from 'lodash';
import React, { FC, ReactNode, useEffect, useState } from 'react';

import { MarginBoxProps, TestKeyAware } from '@hofy/ui';

import { FormInput } from './FormInput';

interface BaseFormNumberInputProps extends MarginBoxProps, TestKeyAware {
    isError?: boolean | string;
    errorMessage?: ReactNode;
    isRequired?: boolean;
    label?: string;
    name?: string;
    unit?: string;
    placeholder?: string;
    disabled?: boolean;
}

interface FormInputOnlyNumberProps extends BaseFormNumberInputProps {
    nullable?: false;
    value: number;
    onChange(v: number): void;
}

interface FormInputNullableNumberProps extends BaseFormNumberInputProps {
    nullable: true;
    value: number | null;
    onChange(v: number | null): void;
}

type FormNumberInputProps = FormInputOnlyNumberProps | FormInputNullableNumberProps;

/** @deprecated use `LabeledNumberInput` or `FormNumberInput` from `@hofy/ui */
export const FormNumberInput: FC<FormNumberInputProps> = ({ value, onChange, nullable, ...rest }) => {
    const [innerValue, setInnerValue] = useState('');

    useEffect(() => {
        setInnerValue(isNil(value) ? '' : value.toString());
    }, [value]);
    const handleBlur = () => {
        const number = parseInt(innerValue, 10);
        if (value === number) {
            return;
        }
        if (Number.isNaN(number)) {
            onChange(nullable ? (null as any) : 0);
        } else {
            onChange(number);
        }
    };

    return <FormInput value={innerValue} onChangeText={setInnerValue} onBlur={handleBlur} {...rest} />;
};
