import { DateTime } from 'luxon';

export const numberOfWeeksInMonth = (date: DateTime): number => {
    const start = date.startOf('month');
    const end = date.endOf('month');

    // Adjust to Monday as the first day of the week
    const firstWeekStart = start.startOf('week');
    const lastWeekEnd = end.endOf('week');

    // Count the number of Mondays in this range
    let weeks = 0;
    let current = firstWeekStart;
    while (current <= lastWeekEnd) {
        if (current.weekday === 1) {
            // 1 represents Monday in Luxon
            weeks++;
        }
        current = current.plus({ days: 1 });
    }

    return weeks;
};
