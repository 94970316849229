import React, { FC } from 'react';

import { FormFieldApi } from '../../../hooks';
import { Optional } from '../../../types/Utils';
import { LabeledSwitch, LabeledSwitchProps } from './LabeledSwitch';

type FormSwitchOptionalProps = Optional<LabeledSwitchProps, 'onChange'>;
type FormSwitchOmittedProps = Omit<FormSwitchOptionalProps, 'errorMessage' | 'checked'>;

interface FormSwitchProps extends FormSwitchOmittedProps {
    api: FormFieldApi<boolean>;
}

export const FormSwitch: FC<FormSwitchProps> = ({ api, onChange, onBlur, ...switchProps }) => {
    return (
        <LabeledSwitch
            {...switchProps}
            ref={api.ref}
            checked={api.value}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
            errorMessage={api.errorMessage}
        />
    );
};
