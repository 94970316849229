import { Role } from '@hofy/api-shared';

export interface AdminPayload {
    firstName: string;
    lastName: string;
    email: string;
    roles: Role[];
    isOffboarded: boolean;
}

export const emptyAdminPayload: AdminPayload = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    isOffboarded: false,
};
