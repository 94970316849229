import React, { FC } from 'react';

import { arePostAddressesEqual, PostAddressView } from '@hofy/address';
import { AddressDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, LabeledText, Paragraph3 } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface AddressResultProps {
    collectionAddress: AddressDto | null;
    placeOfWorkAddress: AddressDto | null;
}

export const AddressResult: FC<AddressResultProps> = ({ collectionAddress, placeOfWorkAddress }) => {
    if (
        collectionAddress &&
        placeOfWorkAddress &&
        arePostAddressesEqual(collectionAddress, placeOfWorkAddress)
    ) {
        return (
            <SurveyResultsSectionCardChip title='Address' status={SurveyResultsSectionCardChipStatus.Neutral}>
                <Paragraph3 color={Color.ContentPrimary}>
                    <AddressWrapper address={collectionAddress} />
                </Paragraph3>
            </SurveyResultsSectionCardChip>
        );
    }

    return (
        <SurveyResultsSectionCardChip
            title='Different address'
            status={SurveyResultsSectionCardChipStatus.Warning}
        >
            <LabeledText
                label='Collection address'
                content={<AddressWrapper address={collectionAddress} />}
            />
            <LabeledText
                label='Place of work address'
                content={<AddressWrapper address={placeOfWorkAddress} />}
            />
        </SurveyResultsSectionCardChip>
    );
};

interface AddressWrapperProps {
    address: AddressDto | null;
}

const AddressWrapper: FC<AddressWrapperProps> = ({ address }) => {
    return (
        <Box column gap={4}>
            <PostAddressView address={address} />
        </Box>
    );
};
