import { getEnumValues } from '@hofy/global';

export enum InvoiceEntryGroup {
    Equipment = 'equipment',
    Addons = 'addon',
    ServiceRendered = 'service_rendered',
    Saas = 'saas',
}

export const allInvoiceEntryGroups = getEnumValues<InvoiceEntryGroup>(InvoiceEntryGroup);
