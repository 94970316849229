import React, { FC } from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { AdminInvoicingTab } from '../../store/invoicing/types/AdminInvoicingTab';
import { useTrAdminInvoicingTab } from '../../store/invoicing/useTrInvoicingSettingsTab';

interface InvoicingTabsProps {
    tab: AdminInvoicingTab;
    tabs: AdminInvoicingTab[];
    onChangeTab(tab: AdminInvoicingTab): void;
}

export const InvoicingTabs: FC<InvoicingTabsProps> = ({ tab, tabs, onChangeTab }) => {
    const trTab = useTrAdminInvoicingTab();

    return (
        <Tabs active={tab} onChange={onChangeTab} animationId='invoicing'>
            {tabs.map(item => (
                <Tab key={item} id={item} label={trTab(item)} />
            ))}
        </Tabs>
    );
};
