import React, { FC } from 'react';

import { Counter, Tab, Tabs } from '@hofy/ui';

import { allProductDetailsTabs, ProductDetailsTab } from '../../../store/products/types/ProductDetailsTab';
import { useTrProductDetailsTab } from '../../../store/products/useTrProductDetailsTab';

interface ProductDetailsTabsProps {
    tab: ProductDetailsTab;
    detailsErrorCount: number;
    onChange(tab: ProductDetailsTab): void;
}

export const ProductDetailsTabs: FC<ProductDetailsTabsProps> = ({ tab, detailsErrorCount, onChange }) => {
    const trProductDetailsTab = useTrProductDetailsTab();

    return (
        <Tabs active={tab} onChange={onChange}>
            {allProductDetailsTabs.map(tab => (
                <Tab
                    key={tab}
                    id={tab}
                    label={trProductDetailsTab(tab)}
                    rightSlot={
                        !!detailsErrorCount &&
                        tab === ProductDetailsTab.Details && <Counter count={detailsErrorCount} color='red' />
                    }
                />
            ))}
        </Tabs>
    );
};
