import React, { FC } from 'react';

import { BackButton, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton } from '@hofy/ui';

import { useSendOneOff } from '../../../store/notifications/useOneOffEvents';
import { NotificationsEditor } from './NotificationsEditor';

interface NewNotificationSlideoutProps {
    onClose(): void;
}

export const NewNotificationSlideout: FC<NewNotificationSlideoutProps> = ({ onClose }) => {
    const { form, isLoading } = useSendOneOff(onClose);

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Send one-off notification' />
            <SlideoutContent paddingVertical={30}>
                <NotificationsEditor form={form} />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' />
                <AsyncButton isLoading={isLoading} label='Send' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
