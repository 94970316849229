import React, { FC } from 'react';

import { APP_CONFIG } from '@hofy/config';
import { UUID } from '@hofy/global';
import { Labeled, LabeledText, Link } from '@hofy/ui';

export const organizationLink = (id: UUID) =>
    `${APP_CONFIG.deelAdminUrl}/lookup/organizations?searchTerm=${id}`;
export const workersLink = (id: UUID) => `${APP_CONFIG.deelAdminUrl}/lookup/workers?searchTerm=${id}`;
export const clientLink = (id: UUID) => `${APP_CONFIG.deelAdminUrl}/lookup/clients?searchTerm=${id}`;
export const entityLink = (id: UUID) => `${APP_CONFIG.deelAdminUrl}/lookup/entities?searchTerm=${id}`;

interface LabeledDeelLinkProps {
    id: UUID | null;
    label: string;
    link(id: UUID): string;
}

export const LabeledDeelLink: FC<LabeledDeelLinkProps> = ({ id, link, label }) => {
    if (!id || !APP_CONFIG.deelAdminUrl) {
        return <LabeledText label={label} content={id} />;
    }
    return <Labeled flex={1} label={label} content={<Link to={link(id)}>{`#${id}`}</Link>} />;
};
