import React from 'react';

import { FormFieldApi } from '../../../hooks';
import { Optional } from '../../../types/Utils';
import { LabeledSelect, LabeledSelectProps } from './LabeledSelect';

type FormSelectOptionalProps<T> = Optional<LabeledSelectProps<T>, 'onChange'>;
type FormSelectOmittedProps<T> = Omit<FormSelectOptionalProps<T>, 'errorMessage' | 'value'>;

interface FormNormalSelectProps<T> extends FormSelectOmittedProps<T> {
    api: FormFieldApi<T>;
    nullable?: false;
}

interface FormNullableSelectProps<T> extends FormSelectOmittedProps<T> {
    api: FormFieldApi<T | null>;
    nullable: true;
}

type FormSelectProps<T> = FormNormalSelectProps<T> | FormNullableSelectProps<T>;

export const FormSelect = <T,>({ api, onChange, onBlur, ...rest }: FormSelectProps<T>) => {
    return (
        <LabeledSelect
            {...rest}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
