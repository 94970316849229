import React, { FC } from 'react';

import { Button, Modals, Paragraph3 } from '../../components';
import { ModalContent } from '../../components/modal/base/ModalContent';
import { ModalFooter } from '../../components/modal/base/ModalFooter';
import { ModalHeader } from '../../components/modal/base/ModalHeader';
import { Modal } from '../../components/modal/Modal';
import { useConfirmationContext } from './ConfirmationContext';

export const ConfirmationModals: FC = () => {
    const context = useConfirmationContext();

    if (!context) {
        return null;
    }

    const { modals, opened, fnRef, closeModal } = context;

    return (
        <Modals>
            {modals
                .filter(modal => opened === modal.id)
                .map(modal => (
                    <Modal key={modal.id} onClose={closeModal} width={500} zIndexShift={10}>
                        <ModalHeader title={modal.title} />
                        <ModalContent>
                            <Paragraph3>{modal.message}</Paragraph3>
                        </ModalContent>
                        <ModalFooter>
                            <Button label={modal.cancelLabel} type='secondary' onClick={closeModal} />
                            <Button
                                label={modal.confirmLabel}
                                action='destructive'
                                onClick={() => {
                                    fnRef.current?.();
                                    closeModal();
                                }}
                            />
                        </ModalFooter>
                    </Modal>
                ))}
        </Modals>
    );
};
