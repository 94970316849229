import React, { FC } from 'react';

import { AssignmentException, AssignmentStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface InPersonTransferBadgeProps {
    assignmentStatus: AssignmentStatus;
    exception: AssignmentException | null;
    hasCollection: boolean;
    hasDelivery: boolean;
}

export const InPersonTransferBadge: FC<InPersonTransferBadgeProps> = ({
    assignmentStatus,
    exception,
    hasCollection,
    hasDelivery,
}) => {
    const isValidCompletionReason = !exception;
    const isDeliveredWithoutShipment = assignmentStatus === AssignmentStatus.WithUser && !hasDelivery;
    const isCollectedWithoutShipment = assignmentStatus === AssignmentStatus.Collected && !hasCollection;

    if (isDeliveredWithoutShipment) {
        return <Badge label='In-person delivery' color='green' />;
    }

    if (isValidCompletionReason && isCollectedWithoutShipment) {
        return <Badge label='In-person collection' color='aquamarine' />;
    }

    return null;
};
