import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { organizationService, TermAndConditionDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { errorMap, nowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { AdminAddOrganizationTermAndConditionFormData } from './types/AdminAddOrganizationTermAndConditionFormData';
import { organizationTermAndConditionsCacheKey } from './useOrganizationTermAndConditionsQuery';

export const useAddOrganizationTermAndCondition = (
    organizationId: UUID,
    defaultTermAndCondition: TermAndConditionDto,
    onEnd: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (f: AdminAddOrganizationTermAndConditionFormData) =>
            organizationService.addOrganizationTermAndCondition(f.organizationId, f.termAndCondition.id, {
                startOn: f.startOn,
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [organizationTermAndConditionsCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Term and Condition has been added',
            });
            onEnd();
        },
    });

    const form = useForm<AdminAddOrganizationTermAndConditionFormData>({
        initial: {
            organizationId,
            termAndCondition: defaultTermAndCondition,
            startOn: nowISODate(),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: ({ startOn }) => ({
            startOn: errorMap([isEmpty(startOn), 'Term and Condition Start date is required']),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
