import React, { FC } from 'react';

import { PurchaseOrderStatus, purchaseOrderStatusColors } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, OuterBoxProps, Tooltip } from '@hofy/ui';

import { useTrPurchaseOrderStatus } from '../../../store/purchaseOrders/useTrPurchaseOrderStatus';

interface PurchaseOrderStatusChipProps extends OuterBoxProps {
    status: PurchaseOrderStatus;
    date?: DateString;
}

export const PurchaseOrderStatusChip: FC<PurchaseOrderStatusChipProps> = ({ status, date, ...boxProps }) => {
    const trPurchaseOrderStatus = useTrPurchaseOrderStatus();

    if (!status) {
        return null;
    }

    const color = purchaseOrderStatusColors[status];

    return (
        <Tooltip body={date && formatDate(date)}>
            <Badge color={color} label={trPurchaseOrderStatus(status)} {...boxProps} />
        </Tooltip>
    );
};
