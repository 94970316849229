import React, { FC } from 'react';

import { RepaymentPlanDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { BaseTable, Placeholder, SvgIllustration } from '@hofy/ui';

import { RepaymentStatusBadge } from '../components/RepaymentStatusBadge';

interface RepaymentPlanHistoryProps {
    plan: RepaymentPlanDto;
}

export const RepaymentPlanHistory: FC<RepaymentPlanHistoryProps> = ({ plan }) => {
    return (
        <BaseTable
            data={plan.statusUpdates}
            toKey={entry => entry.id}
            emptyContent={<Placeholder illustration={SvgIllustration.FinanceSearch} title='No history' />}
            columns={[
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 0,
                    width: 180,
                    renderer: ({ status }) => <RepaymentStatusBadge status={status} />,
                },
                {
                    id: 'changedOn',
                    header: 'Status changed at',
                    flexGrow: 0,
                    width: 180,
                    renderer: ({ updatedAt }) => formatDateTime(updatedAt),
                },
                {
                    id: 'reason',
                    header: 'Reason',
                    flexGrow: 1,
                    width: 180,
                    renderer: ({ reason }) => reason,
                },
            ]}
        />
    );
};
