import { InvoiceEntryType, SplitterType } from '@hofy/api-shared';

import { SplitterConfig } from './InvoiceGroupDto';

export interface InvoiceGroupsPayload {
    invoiceGroups: InvoiceGroupPayload[];
}

export interface InvoiceGroupPayload {
    precedence: number;
    config: SplitterConfig;
}

export const emptyInvoiceGroupPayload = (splitterType: SplitterType): InvoiceGroupPayload => {
    if (splitterType === SplitterType.ByInvoiceEntryType) {
        return {
            precedence: 0,
            config: {
                type: SplitterType.ByInvoiceEntryType,
                byInvoiceEntryType: {
                    types: [],
                },
            },
        };
    }
    return {
        precedence: 0,
        config: {
            type: splitterType,
        },
    };
};

export const defaultInvoiceGroups: InvoiceGroupPayload[] = [
    emptyInvoiceGroupPayload(SplitterType.Currency),
    emptyInvoiceGroupPayload(SplitterType.DomesticVat),
    {
        precedence: 0,
        config: {
            type: SplitterType.ByInvoiceEntryType,
            byInvoiceEntryType: {
                types: [InvoiceEntryType.CreditNote],
            },
        },
    },
];
