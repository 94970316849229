import { UUID } from '@hofy/global';

export interface HofySubsidiaryRef {
    id: UUID;
    name: string;
}

export const findHofySubsidiaryById = (
    subsidiaries: HofySubsidiaryRef[],
    id: UUID | null,
): HofySubsidiaryRef | null => {
    return subsidiaries.find(item => item.id === id) || null;
};

export const hofySubsidiaryCount = 3;
