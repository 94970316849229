import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { FormFieldApi, Optional } from '@hofy/ui';

import { LabeledVariantSelect, LabeledVariantSelectProps } from './LabeledVariantSelect';

type FormVariantSelectOptionalProps = Optional<LabeledVariantSelectProps, 'onChange'>;
type FormVariantSelectOmittedProps = Omit<FormVariantSelectOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalVariantSelectProps extends FormVariantSelectOmittedProps {
    api: FormFieldApi<UUID>;
    nullable?: false;
}

interface FormNullableVariantSelectProps extends FormVariantSelectOmittedProps {
    api: FormFieldApi<UUID | null>;
    nullable: true;
}

type FormVariantSelectProps = FormNormalVariantSelectProps | FormNullableVariantSelectProps;

export const FormVariantSelect: FC<FormVariantSelectProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledVariantSelect
            {...inputProps}
            ref={api.ref}
            value={api.value}
            onChange={(value: UUID) => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
