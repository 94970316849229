import React, { FC, useState } from 'react';

import { PostAddressView } from '@hofy/address';
import { ManagementContractUnionDetailsDto } from '@hofy/api-admin';
import { managementContractTypeColors, useTrManagementContractType } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import {
    Badge,
    BaseTable,
    Box,
    CountryLabel,
    Paragraph2,
    Paragraph3,
    Paragraph4,
    PublicIdColumn,
} from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

import { SubsidiaryCell } from '../../../components/domain/contracts/SubsidiaryCell';
import { ManagementSection } from './ManagementSection';

interface ManagementContractsDetailsProps {
    contracts: ManagementContractUnionDetailsDto[];
}

export const ManagementContractsDetails: FC<ManagementContractsDetailsProps> = ({ contracts }) => {
    const [contract, setContract] = useState<ManagementContractUnionDetailsDto>();

    return (
        <>
            <ManagementContractsDetailsTable
                contracts={contracts}
                selectedContract={contract}
                setSelectedContract={setContract}
            />
            <SelectedManagementContractsDetails
                selectedContract={contract}
                setSelectedContract={setContract}
            />
        </>
    );
};

interface SelectedManagementContractsDetailsProps {
    selectedContract: ManagementContractUnionDetailsDto | undefined;
    setSelectedContract(contract: ManagementContractUnionDetailsDto | undefined): void;
}

const SelectedManagementContractsDetails: FC<SelectedManagementContractsDetailsProps> = ({
    selectedContract,
    setSelectedContract,
}) => {
    if (!selectedContract) {
        return null;
    }

    return (
        <Box marginVertical={20} minWidth={450}>
            <Paragraph2 bold color={Color.ContentPrimary}>
                {`Service package #${selectedContract.id}`}
            </Paragraph2>
            <ManagementSection
                managementContract={selectedContract}
                onClose={() => setSelectedContract(undefined)}
            />
        </Box>
    );
};

interface ManagementContractsTableProps {
    contracts: ManagementContractUnionDetailsDto[];
    selectedContract: ManagementContractUnionDetailsDto | undefined;
    setSelectedContract(contract: ManagementContractUnionDetailsDto | undefined): void;
}

const ManagementContractsDetailsTable: FC<ManagementContractsTableProps> = ({
    contracts,
    selectedContract,
    setSelectedContract,
}) => {
    const trManagementContractType = useTrManagementContractType();

    return (
        <BaseTable
            data={contracts}
            toKey={contract => contract.id}
            onRowClick={contract =>
                setSelectedContract(contract.id === selectedContract?.id ? undefined : contract)
            }
            columns={[
                PublicIdColumn<ManagementContractUnionDetailsDto>(),
                {
                    id: 'type',
                    header: 'Type',
                    flexGrow: 0,
                    width: 200,
                    renderer: contract => (
                        <Badge
                            color={managementContractTypeColors[contract.type]}
                            label={trManagementContractType(contract.type)}
                        />
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 0,
                    width: 200,
                    renderer: contract => (
                        <Box column>
                            <Box>
                                <ContractStatusChip status={contract.status} />
                            </Box>
                            {contract.activeOn && (
                                <Box row gap={5}>
                                    <Paragraph4 bold>Started on:</Paragraph4>
                                    <Paragraph4>{formatDate(contract.activeOn)}</Paragraph4>
                                </Box>
                            )}
                            {contract.endedOn && (
                                <Box row gap={5}>
                                    <Paragraph4 bold>Ended on:</Paragraph4>
                                    <Paragraph4>{formatDate(contract.endedOn)}</Paragraph4>
                                </Box>
                            )}
                        </Box>
                    ),
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 0,
                    width: 350,
                    renderer: contract => {
                        return (
                            <SubsidiaryCell
                                entry={{
                                    hofySubsidiary: {
                                        id: contract.hofySubsidiary.id,
                                        name: contract.hofySubsidiary.name,
                                    },
                                    billingEntity: {
                                        id: contract.billingEntity.id,
                                        name: contract.billingEntity.name,
                                    },
                                }}
                            />
                        );
                    },
                },
                {
                    id: 'address',
                    header: 'Billing address',
                    width: 250,
                    flexGrow: 1,
                    renderer: contract => (
                        <Box column gap={4}>
                            <Paragraph3>
                                <PostAddressView address={contract.billingToAddress} />
                            </Paragraph3>
                            <CountryLabel country={contract.billingToAddress.country} />
                        </Box>
                    ),
                },
            ]}
        />
    );
};
