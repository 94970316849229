import React, { FC } from 'react';

import { useHofySubsidiaries } from '@hofy/api-admin';
import { Box } from '@hofy/ui';

import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { SubsidiariesTable } from './SubsidiariesTable';

interface SubsidiariesTabProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const SubsidiariesTab: FC<SubsidiariesTabProps> = ({ tab, tabs, onChangeTab }) => {
    const { hofySubsidiaries, isLoading } = useHofySubsidiaries();

    return (
        <Box column flex='auto'>
            <SettingsHeader tab={tab} tabs={tabs} onChangeTab={onChangeTab} />
            <SubsidiariesTable subsidiaries={hofySubsidiaries} isLoading={isLoading} />
        </Box>
    );
};
