import React, { FC, useState } from 'react';

import { BackButton, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, FormInput, Modals, SvgIcon } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../components/domain/organizations/FormOrganizationSelect';
import { useCreateApiKey } from '../../../store/apiKeys/useCreateApiKey';
import { ApiKeysResultModal } from './components/ApiKeysResultModal';
import { LabeledPermissions } from './components/LabeledPermissions';

interface ApiKeysSlideoutProps {
    keyId: number;
    onClose?(): void;
}

export const CreateApiKeySlideout: FC<ApiKeysSlideoutProps> = ({ onClose, keyId }) => {
    const keyType = keyId ? 'Edit' : 'New';
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [keyValue, setKeyValue] = useState<string>('');

    const onSuccess = (value: string) => {
        setKeyValue(value);
        setShowSuccessModal(true);
    };
    const { form, isLoading } = useCreateApiKey(onSuccess);

    const onCloseResultModal = () => {
        onClose?.();
    };

    return (
        <Slideout width={800} onClose={onCloseResultModal}>
            <Modals>
                {showSuccessModal && (
                    <ApiKeysResultModal
                        keyName={form.values.name}
                        keyValue={keyValue}
                        onClose={onCloseResultModal}
                    />
                )}
            </Modals>
            <SlideoutHeader title={`${keyType} API key`} />
            <SlideoutContent paddingVertical={20} column gap={20}>
                <FormInput label='Name' api={form.fields.name} isRequired />
                <FormOrganizationSelect
                    label='Organization'
                    api={form.fields.organizationId}
                    nullable
                    placeholder='Choose organization'
                />
                <FormInput label='Existing API key' clearable api={form.fields.existingApiKey} nullable />
                <LabeledPermissions
                    value={form.values.permissions}
                    errorMessage={form.errors.permissions as string}
                    onChange={permissions => form.setValues({ permissions })}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton isLoading={isLoading} label='Create' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
