export type I18nKey = string;

export interface I18nConfig {
    showKeys: boolean;
    keys: Record<string, string>;
}

export const emptyI18nConfig: I18nConfig = {
    showKeys: false,
    keys: {},
};
