import { AdminRefDto, Role } from '@hofy/api-shared';
import { stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminPayload } from './AdminPayload';
import { AdminDto } from './types/AdminDto';

class AdminService {
    public getAdminRefs = async (
        role = Role.FulfillmentAdmin,
        getOffBoarded = false,
    ): Promise<AdminRefDto[]> => {
        return restClient.getJson<AdminRefDto[]>(
            stringifyUrl({
                url: `/api/admin/admins/refs`,
                query: {
                    role,
                    getOffBoarded,
                },
            }),
        );
    };
    public getAdmins = async ({ search }: { search: string | null }): Promise<AdminDto[]> => {
        return restClient.getJson<AdminDto[]>(
            stringifyUrl({
                url: `/api/admin/admins`,
                query: {
                    search,
                },
            }),
        );
    };
    public getAdmin = async (id: UUID): Promise<AdminDto> => {
        return restClient.getJson<AdminDto>(`/api/admin/admins/${id}`);
    };
    public createAdmin = async (payload: AdminPayload): Promise<{ id: UUID }> => {
        return restClient.postJson<{ id: UUID }>(`/api/admin/admins`, payload);
    };
    public updateAdmin = async (id: UUID, payload: AdminPayload): Promise<void> => {
        return restClient.put(`/api/admin/admins/${id}`, payload).then();
    };
}

export const adminService = new AdminService();
