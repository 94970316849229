/* eslint-disable import/no-cycle */
import { incorrectStatusesResponseInterceptor } from './interceptors/incorrectStatusesResponseInterceptor';
import { localDelayResponseInterceptor } from './interceptors/localDelayResponseInterceptor';
import { stringifyBodyInterceptor } from './interceptors/stringifyBodyRequestInterceptor';
import { RestClientImpl } from './types/RestClientImpl';

export const restClient = new RestClientImpl(
    '',
    [stringifyBodyInterceptor],
    [incorrectStatusesResponseInterceptor, localDelayResponseInterceptor],
);

export const publicRestClient = new RestClientImpl(
    '',
    [stringifyBodyInterceptor],
    [incorrectStatusesResponseInterceptor, localDelayResponseInterceptor],
);
