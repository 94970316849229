const isLocal = (): boolean => {
    return window.location.hostname.includes('localhost');
};

const isDev = (): boolean => {
    return window.location.hostname.includes('dev');
};

export const isDevOrLocal = (): boolean => {
    return isDev() || isLocal();
};
