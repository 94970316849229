import React, { FC } from 'react';

import { useBaseI18n } from '@hofy/i18n';

import { DropdownListItem } from './DropdownListItem';

interface UnselectAllListItemProps {
    onClick(): void;
    disabled?: boolean;
}

export const UnselectAllListItem: FC<UnselectAllListItemProps> = ({ onClick, disabled }) => {
    const { tr } = useBaseI18n();
    return <DropdownListItem label={tr('ui.dropdown.unselect-all')} onClick={onClick} disabled={disabled} />;
};
