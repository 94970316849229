import React, { FC } from 'react';

import { InspectionWithDeviceCheckDetailsDto } from '@hofy/api-admin';

import { DeviceCheckProgressSection } from './DeviceCheckProgressSection';

interface DeviceCheckProgressTabProps {
    inspection: InspectionWithDeviceCheckDetailsDto;
}

export const DeviceCheckProgressTab: FC<DeviceCheckProgressTabProps> = ({ inspection }) => {
    return <DeviceCheckProgressSection inspection={inspection} />;
};
