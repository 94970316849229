import { capitalize } from 'lodash';
import React, { FC } from 'react';

import { EnrollItemValidationDetailsDto, OrganizationAddonConfigDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { BaseTable, Box, Paragraph3, Paragraph4, PublicIdColumn } from '@hofy/ui';
import { ProductCell } from '@hofy/ui-domain';

import { enrollmentIsValid } from '../../../store/contracts/useValidateEnrollDevicesIntoServicePackageQuery';

interface DeviceEnrollmentValidationTableProps {
    addonsConfigs: OrganizationAddonConfigDto[];
    validationDetails: EnrollItemValidationDetailsDto[];
    isLoading: boolean;
}

export const DeviceEnrollmentValidationTable: FC<DeviceEnrollmentValidationTableProps> = ({
    addonsConfigs,
    validationDetails,
    isLoading,
}) => {
    return (
        <BaseTable
            data={validationDetails}
            isLoading={isLoading}
            toKey={item => item.id}
            columns={[
                PublicIdColumn<EnrollItemValidationDetailsDto>(),
                {
                    id: 'product',
                    flexGrow: 3,
                    header: 'Product',
                    renderer: item => <ProductCell product={item.product} />,
                },
                {
                    id: 'validations',
                    flexGrow: 3,
                    header: 'Validations',
                    renderer: item => (
                        <DeviceEnrollmentValidationTableRow
                            addonConfigs={addonsConfigs}
                            item={item}
                            isValid={enrollmentIsValid(item)}
                        />
                    ),
                },
            ]}
        />
    );
};

interface DeviceEnrollmentValidationTableRowProps {
    addonConfigs: OrganizationAddonConfigDto[];
    item: EnrollItemValidationDetailsDto;
    isValid: boolean;
}

const DeviceEnrollmentValidationTableRow: FC<DeviceEnrollmentValidationTableRowProps> = ({
    addonConfigs,
    item,
    isValid,
}) => {
    if (isValid) {
        return (
            <Box column gap={5}>
                <Paragraph3 color={Color.ContentPositive} bold>
                    Validation passed
                </Paragraph3>
                <Box column marginLeft={5}>
                    {item.addonsValidation?.map(addon => (
                        <Box row key={addon.addonId} gap={2}>
                            <Paragraph4 bold>{getAddonName(addonConfigs, addon.addonId)}:</Paragraph4>
                            {addon.enrollmentDuration && (
                                <Paragraph4 color={Color.ContentTertiary}>
                                    {addon.enrollmentDuration} months
                                </Paragraph4>
                            )}
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    }
    return (
        <Box column gap={5}>
            <Box row gap={5}>
                <Paragraph3 color={Color.ContentWarning} bold>
                    Validation failed
                </Paragraph3>
                <Paragraph4 color={Color.ContentTertiary} italic>
                    (device will be skipped)
                </Paragraph4>
            </Box>

            <Box column marginLeft={5}>
                <Box row gap={2}>
                    <Paragraph4 bold>Item: </Paragraph4>
                    {item.itemFailReason ? (
                        <Paragraph4 color={Color.ContentTertiary}>
                            {capitalizeEnum(item.itemFailReason)}
                        </Paragraph4>
                    ) : (
                        <Paragraph4 color={Color.ContentTertiary} italic>
                            Valid
                        </Paragraph4>
                    )}
                </Box>

                {item.addonsValidation?.map(addon => (
                    <Box row key={addon.addonId} gap={2}>
                        <Paragraph4 bold>{getAddonName(addonConfigs, addon.addonId)}:</Paragraph4>
                        {addon.addonFailReason && (
                            <Paragraph4 color={Color.ContentTertiary} italic>
                                {capitalizeEnum(addon.addonFailReason)}
                            </Paragraph4>
                        )}
                        {!addon.addonFailReason && (
                            <Paragraph4 italic color={Color.ContentTertiary}>
                                Valid
                            </Paragraph4>
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

const capitalizeEnum = (enumValue: string) => {
    return enumValue
        .split('.')
        .map(i => capitalize(i.replace(/-/g, ' ')))
        .join('/');
};

const getAddonName = (addonConfigs: OrganizationAddonConfigDto[], id: UUID) => {
    return addonConfigs.find(a => a.id === id)?.name ?? id.toString();
};
