import { useAdminI18n } from '../../i18n/useAdminI18n';

export const useTrPaymentMethod = () => {
    const { existsParts, trParts } = useAdminI18n();

    const capitalize = (source: string) =>
        source
            .replace(/^[-_]*(.)/, (_, letter) => letter.toUpperCase())
            .replace(/[-_](.)/g, (_, letter) => ` ${letter.toUpperCase()}`);

    const trPaymentMethod = (source: string) => {
        const i18nKey = source.replace('_', '-');
        if (existsParts('payment-method', i18nKey)) {
            return trParts('payment-method', i18nKey);
        }

        // Fallback for unhandled payment method types
        return capitalize(source);
    };

    return { trPaymentMethod };
};
