import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { stockLevelsService } from '../service/stockLevelsService';
import { StockLevelsFilters } from '../service/types/StockLevelsFilters';
import { stockLevelCacheKey } from './cacheKey';

export const useStockLevels = (filters: StockLevelsFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [stockLevelCacheKey, filters],
        queryFn: param =>
            stockLevelsService.getStockLevels(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });
    const stockLevels = useMemo(() => (data ? data.pages.flatMap(v => v.content) : []), [data]);

    return {
        stockLevels: stockLevels,
        hasNextPage,
        isLoading: isLoading,
        isFetchingNextPage,
        fetchNextPage,
    };
};
