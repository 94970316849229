import React, { FC, ReactNode } from 'react';

import { Box, LabeledText, OuterBoxProps } from '@hofy/ui';

interface ItemCodesProps extends OuterBoxProps {
    label: ReactNode;
    itemCodes: {
        code: string;
    }[];
}

export const LabeledItemCodes: FC<ItemCodesProps> = ({ label, itemCodes, ...rest }) => {
    return (
        <LabeledText
            label={label}
            content={itemCodes.length ? itemCodes.map(({ code }) => <Box key={code}>{code}</Box>) : '--'}
            {...rest}
        />
    );
};
