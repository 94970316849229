import React, { FC } from 'react';

import { OrderItemStatus, orderItemStatusColors, useTrOrderItemStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface OrderItemStatusChipProps {
    status: OrderItemStatus;
}

export const OrderItemStatusChip: FC<OrderItemStatusChipProps> = ({ status }) => {
    const trRequestItemStatus = useTrOrderItemStatus();
    return <Badge label={trRequestItemStatus(status)} color={orderItemStatusColors[status]} />;
};
