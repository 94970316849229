import { RequestInterceptorParam } from '../types/RestClientImpl';

const stringifyBody = (requestInit?: RequestInit): RequestInit => {
    let currentRequestInit = requestInit || {};
    if (currentRequestInit.body) {
        currentRequestInit = Object.assign(currentRequestInit, {
            body: JSON.stringify(currentRequestInit.body),
        });
    }
    return currentRequestInit;
};

export const stringifyBodyInterceptor = ([url, init]: RequestInterceptorParam): RequestInterceptorParam => [
    url,
    stringifyBody(init),
];
