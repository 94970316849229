import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { ActivityLogsFilter } from '../store/ActivityLogsFilter';
import { ActivityLogDto } from './types/ActivityLogDto';

class ActivityLogService {
    public list = (filters: ActivityLogsFilter, page: PageRequest): Promise<PageResponse<ActivityLogDto>> => {
        return restClient.getJson<PageResponse<ActivityLogDto>>(
            stringifyUrl({
                url: `/api/admin/activity-logs`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public hideActivityLog = async (id: UUID): Promise<void> => {
        return restClient.put(`/api/admin/activity-logs/${id}/hide`);
    };
}

export const activityLogService = new ActivityLogService();
