import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

import { TestKeyAware } from '../../types';

export interface ExpandHeightProps extends TestKeyAware {
    children?: ReactNode;
    duration?: number;
}

/**
 * ExpandHeight component is a wrapper for any component whose render we want to animate.
 * Add or remove children to see the animation.
 */
const variants = {
    closed: {
        height: 0,
        opacity: 0,
        overflow: 'hidden',
    },
    open: {
        height: 'auto',
        opacity: 1,
        transitionEnd: {
            overflow: 'unset',
        },
    },
};
export const ExpandHeight: FC<ExpandHeightProps> = ({ children, testKey, duration = 0.4 }) => {
    return (
        <AnimatePresence>
            {children && (
                <motion.div
                    data-test-key={testKey}
                    initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
                    animate='open'
                    exit='closed'
                    transition={{ duration }}
                    variants={variants}
                >
                    {children}
                </motion.div>
            )}
        </AnimatePresence>
    );
};
