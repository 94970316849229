import { isEmpty, isEqual } from 'lodash';
import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import {
    allPaymentSchemasWithoutDeposit,
    formatAdminName,
    getAvailableRentalTerms,
    ParentProductCategoryGroup,
    useTrAcquisitionType,
    useTrContractEndOption,
    useTrContractExtendOption,
    useTrOperatingSystem,
    useTrOrganizationSize,
    useTrPaymentSchema,
    useTrPaymentType,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { StatusFlag } from '@hofy/common';
import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import {
    BooleanBadge,
    Box,
    FormGridItem,
    FormGridRow,
    FormSection,
    InfoTooltipIcon,
    Labeled,
    LabeledText,
    SvgIcon,
    TooltipIcon,
} from '@hofy/ui';

import { LinkType, ZendeskSection } from '../../../components/design/zendesk/ZendeskSection';
import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { OrganizationStatusChip } from '../../../components/domain/organizations/OrganizationStatusChip';
import { LabeledDeelLink, organizationLink } from '../../../components/routing/LabeledDeelLink';
import { useTrOrganizationType } from '../../../store/organizations/useTrOrganizationType';
import { PlatformTierSection } from './PlatformTierSection';

interface DetailsTabProps {
    organization: OrganizationDetailsDto;
}

export const DetailsTab: FC<DetailsTabProps> = ({ organization }) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trRentalTerm = useTrRentalTerm();
    const trOperatingSystem = useTrOperatingSystem();
    const trAcquisitionType = useTrAcquisitionType();
    const trOrganizationType = useTrOrganizationType();
    const trPaymentType = useTrPaymentType();
    const trOrganizationSize = useTrOrganizationSize();
    const trContractExtendOption = useTrContractExtendOption();
    const trContractEndOption = useTrContractEndOption();

    const { formatPrice } = usePrice();

    const nonDefaultValueTooltip = (value: Price, defaultValue: Price, name: string) => {
        if (!isEqual(value, defaultValue)) {
            return (
                <TooltipIcon
                    placement='top-start'
                    icon={SvgIcon.Asterisk}
                    iconSize={12}
                    body={`This is a custom ${name}. Default is ${formatPrice(defaultValue)}`}
                />
            );
        } else {
            return undefined;
        }
    };

    const deviceConfigurationLabelSuffix = !isEmpty(organization.deviceConfigRequired)
        ? ` (${organization.deviceConfigRequired.map(os => trOperatingSystem(os)).join(', ')})`
        : '';

    const successAdminUserName = organization.successAdminUser
        ? formatAdminName(organization.successAdminUser)
        : '';
    const salesAdminUserName = organization.salesAdminUser
        ? formatAdminName(organization.salesAdminUser)
        : '';

    const allRentalTerms = getAvailableRentalTerms(organization.unbundlingEnabled);

    const contractDefaultsSectionTitle = (
        <Box row inline gap={4}>
            Contract defaults
            <InfoTooltipIcon body='Settings can be overwritten on individual contracts' />
        </Box>
    );

    const devicesContractExpirySetting =
        organization.contractExpirySettings[ParentProductCategoryGroup.Devices];
    const accessoriesContractExpirySetting =
        organization.contractExpirySettings[ParentProductCategoryGroup.Accessories];

    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Organization'>
                    <FormGridRow columns={2}>
                        <LabeledText label='Name' content={organization.name} />
                        <LabeledText label='Id' content={organization.id} />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Public Id' content={organization.publicId} />
                        <LabeledText label='Currency' content={organization.currency} />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Sales representative' content={salesAdminUserName} />
                        <Labeled
                            label='Status'
                            content={<OrganizationStatusChip status={organization.status} />}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Success representative' content={successAdminUserName} />
                        <LabeledText
                            label='Model'
                            content={organization.unbundlingEnabled ? 'Unbundled' : 'Bundled'}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Allowed Acquisition Types'
                            content={organization.allowedAcquisitionTypes
                                .map(type => trAcquisitionType(type))
                                .join(', ')}
                        />
                        <LabeledText label='Type' content={trOrganizationType(organization.type)} />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Parent partner organization'
                            content={organization.partnerName || '--'}
                        />
                        <LabeledText
                            label='Organization size'
                            content={organization.size && trOrganizationSize(organization.size)}
                        />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText
                            label='Default payment type'
                            content={trPaymentType(organization.defaultPaymentType)}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection marginTop={30} label='Allowed billing frequency'>
                    <FormGridRow columns={4}>
                        {allPaymentSchemasWithoutDeposit.map(paymentSchema => (
                            <StatusFlag
                                key={paymentSchema}
                                label={trPaymentSchema(paymentSchema)}
                                active={organization.allowedBillingFrequencies.includes(paymentSchema)}
                            />
                        ))}
                    </FormGridRow>
                </FormSection>
                <FormSection marginTop={30} label='Allowed rental terms'>
                    <FormGridRow columns={4}>
                        {allRentalTerms.map(term => (
                            <StatusFlag
                                key={term}
                                label={trRentalTerm(term)}
                                active={organization.allowedRentalTerms.includes(term)}
                            />
                        ))}
                    </FormGridRow>
                </FormSection>
                {devicesContractExpirySetting && accessoriesContractExpirySetting && (
                    <FormSection marginTop={30} label={contractDefaultsSectionTitle}>
                        <FormGridRow columns={4}>
                            <LabeledText
                                label='Devices contract extend option'
                                content={trContractExtendOption(
                                    devicesContractExpirySetting.contractExtendOption,
                                )}
                            />
                            <LabeledText
                                label='Devices contract end option'
                                content={trContractEndOption(devicesContractExpirySetting.contractEndOption)}
                            />
                            <LabeledText
                                label='Accessories contract end option'
                                content={trContractEndOption(
                                    accessoriesContractExpirySetting.contractEndOption,
                                )}
                            />
                        </FormGridRow>
                    </FormSection>
                )}
                <FormSection marginTop={30} label='Features'>
                    <StatusFlag label='Welcome packs enabled' active={organization.welcomePacksEnabled} />
                    <StatusFlag
                        label='Skip device wiping prompt in collection survey'
                        active={organization.collectionSurveySkipDeviceReset}
                    />
                    <StatusFlag label='Service desk' active={organization.serviceDeskEnabled} />
                    <StatusFlag label='MDM' active={organization.mdmEnabled} />
                    <StatusFlag label='Data erasure' active={organization.dataErasureEnabled} />
                    <StatusFlag
                        label={`Device configuration${deviceConfigurationLabelSuffix}`}
                        active={!isEmpty(organization.deviceConfigRequired)}
                    />
                    <StatusFlag
                        label='Store and reuse managed by hofy'
                        active={organization.storeAndReuseManagedByHofy}
                    />
                    <StatusFlag label='DSE' active={organization.dseEnabled} />
                </FormSection>
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                <FormSection label='Pricing'>
                    <FormGridRow columns={4}>
                        <LabeledText
                            label='MDM fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.mdmFee,
                                organization.defaultPricing.mdmFee,
                                'MDM fee',
                            )}
                            content={formatPrice(organization.pricing.mdmFee)}
                        />
                        <LabeledText
                            label='Config fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.laptopConfigFee,
                                organization.defaultPricing.laptopConfigFee,
                                'Config fee',
                            )}
                            content={formatPrice(organization.pricing.laptopConfigFee)}
                        />
                        <LabeledText
                            label='Data erasure fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.dataErasureFee,
                                organization.defaultPricing.dataErasureFee,
                                'Data erasure fee',
                            )}
                            content={formatPrice(organization.pricing.dataErasureFee)}
                        />
                        {!!organization.mdmCount && (
                            <Box row>
                                <LabeledText label='Mdm device count' content={organization.mdmCount} />
                            </Box>
                        )}
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        <LabeledText
                            label='BYOD activation fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.byodActivationFee,
                                organization.defaultPricing.byodActivationFee,
                                'BYOD activation fee',
                            )}
                            content={formatPrice(organization.pricing.byodActivationFee)}
                        />
                        <LabeledText
                            label='BYOD storage fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.byodStorageFee,
                                organization.defaultPricing.byodStorageFee,
                                'BYOD storage fee',
                            )}
                            content={formatPrice(organization.pricing.byodStorageFee)}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Crediting'>
                    <FormGridRow columns={4}>
                        <LabeledText
                            label='Credit limit (Monthly)'
                            content={formatPrice(organization.crediting.creditLimit)}
                        />
                        <LabeledText
                            label='Credit used'
                            content={formatPrice(organization.crediting.creditUsed)}
                        />
                    </FormGridRow>
                </FormSection>
                <FormSection label='Deel'>
                    <FormGridRow columns={4}>
                        <FormGridItem columns={2}>
                            <LabeledDeelLink link={organizationLink} label='Id' id={organization.deel.id} />
                        </FormGridItem>
                        <LabeledText label='Last synced at' content={organization.deel.lastUpdatedAt} />
                        <Labeled
                            label='Deel IT ready'
                            content={<BooleanBadge value={organization.deel.itReady} />}
                        />
                    </FormGridRow>
                </FormSection>
                <ZendeskSection linkType={LinkType.OrganizationPage} zendesk={organization.zendesk} />
                <AuditSection model={organization} />
                <PlatformTierSection organization={organization} />
            </Box>
        </Box>
    );
};
