import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inspectionService, inspectionsQueryKey, UpdateInspectionPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useCompleteInspection = (uuid: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, UpdateInspectionPayload>({
        mutationFn: payload => inspectionService.completeInspection(uuid, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey] });
            onSuccess?.();
        },
    });

    return {
        completeInspection: (payload: UpdateInspectionPayload) => mutation.mutate(payload),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
