import React, { FC } from 'react';

import { PurchaseOrderDto } from '@hofy/api-admin';
import { IconItem } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';

import { NetsuiteStatusChip } from '../../../../../components/domain/netsuite/NetsuiteStatusChip';
import { PurchaseOrderStatusChip } from '../../../../../components/domain/purchaseOrders/PurchaseOrderStatusChip';
import { PurchaseOrderTypeChip } from '../../../../../components/domain/purchaseOrders/PurchaseOrderTypeChip';
import { AssignedSupplierCell } from '../../../../../components/domain/supplier/AssignedSupplierCell';
import { UserCard } from '../../../../../components/domain/user/UserCard';
import { PurchaseOrderApprovalCell } from './PurchaseOrderApprovalCell';

interface PurchaseOrderTableProps {
    purchaseOrders: PurchaseOrderDto[];
    onOpenPurchaseOrder(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
}

export const PurchaseOrderTable: FC<PurchaseOrderTableProps> = ({
    purchaseOrders,
    onOpenPurchaseOrder,
    infinityScroll,
}) => {
    const { formatPrice } = usePrice();

    return (
        <InfinityScrollTable
            data={purchaseOrders}
            toKey={purchaseOrder => purchaseOrder.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Requests}
                    title='No purchase orders'
                    message='No purchase orders for selected criteria'
                />
            }
            onRowClick={purchaseOrder => onOpenPurchaseOrder(purchaseOrder.id)}
            columns={[
                {
                    id: 'purchase-order',
                    header: 'Purchase order',
                    flexGrow: 1,
                    renderer: purchaseOrder => purchaseOrder.purchaseOrderReference,
                },
                {
                    id: 'supplierReference',
                    header: 'Supplier ref.',
                    flexGrow: 1,
                    renderer: purchaseOrder => (
                        <TextCell>{purchaseOrder.purchaseOrderSupplierReference ?? '--'}</TextCell>
                    ),
                },
                {
                    id: 'notes',
                    header: 'Notes',
                    flexGrow: 1,
                    renderer: purchaseOrder => purchaseOrder.notes,
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    renderer: purchaseOrder => (
                        <Box column gap={12}>
                            <PurchaseOrderTypeChip isDropship={purchaseOrder.isDropship} />
                            <PurchaseOrderStatusChip
                                status={purchaseOrder.status}
                                date={purchaseOrder.orderedOn}
                            />
                        </Box>
                    ),
                },
                {
                    id: 'approveProgress',
                    header: 'Approval',
                    flexGrow: 1,
                    renderer: purchaseOrder => (
                        <PurchaseOrderApprovalCell
                            requiredApprovals={purchaseOrder.requiredApprovals}
                            approvals={purchaseOrder.approvalsCount}
                        />
                    ),
                },
                {
                    id: 'estimatedReceiptAt',
                    header: 'Est. receive at',
                    flexGrow: 1,
                    renderer: purchaseOrder => (
                        <IconItem icon={SvgIcon.Truck}>
                            {formatDate(purchaseOrder.estimatedReceiveOn)}
                        </IconItem>
                    ),
                },
                {
                    id: 'value',
                    header: 'Total value',
                    flexGrow: 1,
                    renderer: purchaseOrder => formatPrice(purchaseOrder.totalPrice),
                },
                {
                    id: 'supplier',
                    header: 'Supplier',
                    flexGrow: 1,
                    renderer: purchaseOrder => <AssignedSupplierCell supplier={purchaseOrder.supplier} />,
                },
                {
                    id: 'createdByUser',
                    header: 'Created by',
                    flexGrow: 1,
                    renderer: purchaseOrder => <UserCard user={purchaseOrder.createdByUser || undefined} />,
                },
                {
                    id: 'netsuiteSync',
                    header: 'Netsuite',
                    renderer: purchaseOrder => <NetsuiteStatusChip status={purchaseOrder.netsuite} />,
                },
            ]}
        />
    );
};
