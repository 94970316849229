import React, { FC } from 'react';

import { useBaseI18n } from '@hofy/i18n';

import { Input, InputProps } from '../../form/input/Input';

export const DropdownSearchInput: FC<InputProps> = ({ placeholder, ...props }) => {
    const { tr } = useBaseI18n();
    return (
        <Input
            placeholder={placeholder || tr('ui.dropdown.search-placeholder')}
            fullWidth
            clearable
            testKey='search-input'
            {...props}
        />
    );
};
