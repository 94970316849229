import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiKeysCacheKey, apiKeyService, UpdateApiKeyPayload, useApiKeys } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isRequired, minLength, useForm, useToast, validator } from '@hofy/ui';

export const useUpdateApiKey = (keyId: UUID, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const { apiKeys } = useApiKeys();
    const apiKey = apiKeys.find(i => i.id === keyId);

    const revokeMutation = useMutation({
        mutationFn: apiKeyService.revokeApiKey,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [apiKeysCacheKey] });
            showToast({
                type: 'positive',
                message: `The api key has been revoked!`,
            });
            onSuccess?.();
        },
    });

    const updateMutation = useMutation({
        mutationFn: (p: UpdateApiKeyPayload) => apiKeyService.updateApiKey(keyId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [apiKeysCacheKey] });
            showToast({
                type: 'positive',
                message: `The api key has been updated!`,
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateApiKeyPayload>({
        initial: {
            organizationId: apiKey?.organizationId || null,
            name: apiKey?.name ?? '',
            permissions: apiKey?.permissions ?? [],
        },
        initialDeps: [apiKey],
        onSubmit: updateMutation.mutate,
        validate: validator<UpdateApiKeyPayload>({
            name: isRequired('Name is required'),
            permissions: minLength('Select at least one permission', 1),
        }),
    });

    const revokeKey = (id: UUID) => {
        revokeMutation.mutate(id);
    };

    return {
        isLoading: updateMutation.isPending || revokeMutation.isPending,
        form,
        revokeKey,
    };
};
