import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';

import { Box } from '../../../base';
import { IconButton, SvgIcon } from '../../../icon';

interface NavigationContainerProps {
    children?: ReactNode;
    onToggle(): void;
}

export const NavigationMobileBar = ({ children, onToggle }: NavigationContainerProps) => {
    return (
        <ContainerBox row justify='space-between' padding={16}>
            {children}

            <IconButton
                icon={SvgIcon.Menu}
                color={Color.InteractionInvertedNormal}
                onClick={() => onToggle()}
            />
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)`
    position: relative;
    z-index: ${ZIndex.MobileMenu - 1};
    box-shadow: ${Color.Shadow} 0 0 10px 0;
    background: #4e38bc;
    height: 50px;
`;
