import { CollectionRequestDisplayStatus } from '@hofy/api-admin';

import { useAdminI18n } from '../../i18n/useAdminI18n';

export const useTrCollectionRequestDisplayStatus = () => {
    const { trEnum } = useAdminI18n();
    return trEnum<CollectionRequestDisplayStatus>(
        CollectionRequestDisplayStatus,
        'collection-request-display-status',
    );
};
