import React, { FC } from 'react';
import styled from 'styled-components';

import { Box } from '@hofy/ui';

import deel from './assets/deel.webp';

interface DeelBatchProps {
    size?: number;
}

export const DeelBatch: FC<DeelBatchProps> = ({ size = 32 }) => {
    return (
        <Box fullHeight column justify='center' alignItems='center'>
            <Img src={deel} size={size} />
        </Box>
    );
};

const Img = styled.img<{ size: number }>`
    width: ${p => p.size}px;
    height: ${p => p.size}px;
    border-radius: ${p => p.size / 4}px;
`;
