import React, { FC, useEffect, useState } from 'react';

import { PurchaseOrderItemDto, PurchaseOrderRefDto } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, Paragraph2, Paragraph3 } from '@hofy/ui';

import { emptyReceivePurchaseOrderItemDetail } from '../../../../store/purchaseOrders/types/ReceivePurchaseOrderItemFormData';
import { useReceivePurchaseOrderItems } from '../../../../store/purchaseOrders/useReceivePurchaseOrderItems';
import { ItemsScanForm } from './itemScanForm/ItemsScanForm';
import { ItemsScanSelect } from './ItemsScanSelect';
import { PurchaseOrderSelect } from './PurchaseOrderSelect';

interface ReceiveItemSlideoutProps {
    onClose(): void;
}

export const ReceiveItemSlideout: FC<ReceiveItemSlideoutProps> = ({ onClose }) => {
    const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderRefDto | null>(null);
    const [itemToScan, setItemToScan] = useState<PurchaseOrderItemDto | null>(null);
    const {
        form,
        updateUnit,
        deleteUnit,
        isLoadingMutation: poItemIsLoading,
    } = useReceivePurchaseOrderItems(() => setItemToScan(null));

    useEffect(() => {
        if (itemToScan) {
            form.setValues({
                purchaseOrderItemId: itemToScan.id,
                scansLeft: itemToScan.quantity - itemToScan.receivedQuantity,
                notes: itemToScan.notes ?? '',
            });
        }
    }, [itemToScan]);

    const getSlideoutTitle = () => {
        if (!purchaseOrder) {
            return 'Select PO to scan';
        }

        if (itemToScan) {
            return `Scan items for PO #${purchaseOrder.purchaseOrderReference}`;
        }

        return (
            <Box>
                <Paragraph2 color={Color.Neutral900} marginBottom={4}>
                    {`Receive items at the warehouse for PO #${purchaseOrder.purchaseOrderReference}`}
                </Paragraph2>
                <Paragraph3>Select a product to start scanning.</Paragraph3>
            </Box>
        );
    };

    const handleSave = () => {
        if (itemToScan) {
            form.submit();
            return;
        }
        onClose();
    };

    const handleCancel = () => {
        if (itemToScan) {
            form.setValues({
                receivedItems: [emptyReceivePurchaseOrderItemDetail],
            });
            setItemToScan(null);
            return;
        }
        if (purchaseOrder) {
            setPurchaseOrder(null);
            return;
        }
        onClose();
    };

    const renderContent = () => {
        if (!purchaseOrder) {
            return <PurchaseOrderSelect selected={purchaseOrder} onChange={setPurchaseOrder} />;
        }

        if (!itemToScan) {
            return <ItemsScanSelect purchaseOrderId={purchaseOrder.id} onItemToScanSelect={setItemToScan} />;
        }

        return (
            <ItemsScanForm item={itemToScan} form={form} updateUnit={updateUnit} deleteUnit={deleteUnit} />
        );
    };

    return (
        <Slideout width={!purchaseOrder ? 600 : 1200} onClose={onClose}>
            <SlideoutHeader title={getSlideoutTitle()} />
            <SlideoutContent>{renderContent()}</SlideoutContent>

            <SlideoutFooter fullWidth justify='flex-end'>
                {(!purchaseOrder || itemToScan) && (
                    <Button type='secondary' label='Cancel' onClick={handleCancel} />
                )}
                <Box flex={1} />
                <AsyncButton
                    isLoading={poItemIsLoading}
                    disabled={!purchaseOrder && !itemToScan}
                    onClick={handleSave}
                    label={!itemToScan ? 'Close' : 'Save'}
                    marginLeft={20}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
