import React, { FC, MouseEvent, ReactNode } from 'react';

import { NumberValues } from '@hofy/theme';

import { Box, BoxProps } from '../../base';
import { TextCell } from './TextCell';

export interface CellProps extends BoxProps {
    rowVerticalPadding?: NumberValues;
    children?: ReactNode;
    onClick?(e: MouseEvent): void;
}

export interface BaseCellProps {
    rowVerticalPadding?: NumberValues;
    children?: ReactNode;
    onClick?(e: MouseEvent): void;
    flex?: number | 'auto';
    shrink?: number;
    width?: string | number;
}

export const Cell: FC<CellProps> = ({
    children,
    flex = 1,
    rowVerticalPadding = 12,
    shrink = 1,
    width = 100,
    onClick,
    ...rest
}) => {
    const flexStyle = `${flex} ${shrink} ${width}px`;

    if (typeof children === 'string' || typeof children === 'number') {
        return (
            <TextCell
                row
                paddingVertical={rowVerticalPadding}
                onClick={onClick}
                style={{ flex: flexStyle }}
                {...rest}
            >
                {children}
            </TextCell>
        );
    }

    return (
        <Box
            row
            paddingRight={12}
            paddingVertical={rowVerticalPadding}
            overflow='hidden'
            onClick={onClick}
            style={{ flex: flexStyle }}
            data-test-key='table-cell'
            {...rest}
        >
            {children}
        </Box>
    );
};
