import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box } from '../../base';

interface HeaderIconProps {
    active?: boolean;
    testKey?: string;
    children: ReactNode;
}

export const HeaderIcon: FC<HeaderIconProps> = ({ active, testKey, children }: HeaderIconProps) => (
    <Bg padding={4} rounded active={active} data-test-key={testKey}>
        {children}
    </Bg>
);

const Bg = styled(Box)<{ active?: boolean }>`
    background-color: ${p => (p.active ? Color.InteractionDefaultNormal : undefined)};
    color: ${p => (p.active ? Color.InteractionInvertedNormal : undefined)};
    :hover {
        background-color: ${Color.InteractionDefaultHover};
        color: ${Color.InteractionInvertedHover};
    }
    :active {
        background-color: ${Color.InteractionDefaultActive};
        color: ${Color.InteractionInvertedActive};
    }
`;
