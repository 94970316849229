import React, { FC, ReactNode } from 'react';

import { AssignmentsPageTab } from '@hofy/api-admin';
import { stringifyUrl } from '@hofy/global';

import { getAssignmentsTabLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface AssignmentPendingLinkProps {
    search: string;
    children?: ReactNode;
}

export const AssignmentPendingLink: FC<AssignmentPendingLinkProps> = ({ search, children }) => {
    const url = stringifyUrl({
        url: getAssignmentsTabLink(AssignmentsPageTab.Delivery),
        query: {
            search: search,
        },
    });
    return <Link to={url}>{children}</Link>;
};
