import React, { FC } from 'react';

import { TermAndConditionDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Badge, Box, FormFieldApi, FormSelect, Paragraph3 } from '@hofy/ui';

interface TermAndConditionFormSelectProps {
    termsAndConditions: TermAndConditionDto[];
    api: FormFieldApi<TermAndConditionDto>;
}

export const TermAndConditionFormSelect: FC<TermAndConditionFormSelectProps> = ({
    termsAndConditions,
    api,
}) => {
    return (
        <FormSelect
            options={termsAndConditions}
            label='Terms and conditions'
            api={api}
            toText={term => term.name}
            toLabel={term => (
                <Box row flex={1} justify='space-between' alignItems='center' gap={4}>
                    <Paragraph3 color={Color.ContentPrimary}>{term.name}</Paragraph3>
                    {term.isDefault && <Badge color='purple' label='Default' />}
                </Box>
            )}
        />
    );
};
