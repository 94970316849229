import { ReferenceType, useMergeRefs } from '@floating-ui/react';
import React, { cloneElement, FC, HTMLProps, isValidElement, ReactNode } from 'react';

import { TestKeyAware } from '../../../types';
import { Pressable } from '../../base';

interface DropdownTriggerProps extends TestKeyAware, HTMLProps<HTMLElement> {
    children: ReactNode;
    setReference(node: ReferenceType | null): void;
    referenceProps(userProps?: React.HTMLProps<Element> | undefined): Record<string, unknown> | undefined;
    /**
     * Allows pass any element as the trigger without the wrapper
     * WARNING: element must accept `ref` (native element like `div` or component with `forwardRef`)
     * */
    asChild?: boolean;
}

export const DropdownTrigger: FC<DropdownTriggerProps> = ({
    children,
    asChild = false,
    setReference,
    referenceProps,
    testKey,
    ...props
}) => {
    const childrenRef = (children as any)?.ref;
    const ref = useMergeRefs([setReference, childrenRef]);

    if (asChild) {
        if (!isValidElement(children)) {
            throw new Error('[DropdownTrigger] Trigger with `asChild` must be a valid singe React element');
        }

        return cloneElement(
            children,
            referenceProps({
                ref,
                ...props,
                ...children.props,
                'data-test-key': testKey,
                testKey,
            }),
        );
    }

    return (
        <Pressable ref={ref} cursor='default' data-test-key={testKey} {...referenceProps(props)}>
            {children}
        </Pressable>
    );
};
