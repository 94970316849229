import React, { FC } from 'react';

import { EmailsAware, EmailType, LoginProfileAware } from '@hofy/api-shared';
import { Box } from '@hofy/ui';

import { EmailCheckBox } from './EmailCheckBox';

interface InviteEmailTypePickerProps {
    disabledEmailTypes: EmailType[];
    emailTypes: EmailType[];
    selectedUser: EmailsAware & LoginProfileAware;
    onChange(v: EmailType[]): void;
}

export const InviteEmailTypePicker: FC<InviteEmailTypePickerProps> = ({
    disabledEmailTypes,
    emailTypes,
    selectedUser,
    onChange,
}) => {
    return (
        <Box column gap={16}>
            {selectedUser.workEmail && (
                <EmailCheckBox
                    emailType={EmailType.Work}
                    disabledEmailTypes={disabledEmailTypes}
                    emailTypes={emailTypes}
                    onChange={onChange}
                    email={selectedUser.workEmail}
                    testKey='work-email'
                />
            )}
            {selectedUser.personalEmail && (
                <EmailCheckBox
                    emailType={EmailType.Personal}
                    disabledEmailTypes={disabledEmailTypes}
                    emailTypes={emailTypes}
                    onChange={onChange}
                    email={selectedUser.personalEmail}
                    testKey='personal-email'
                />
            )}
        </Box>
    );
};
