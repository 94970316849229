import React, { FC } from 'react';

import { WarehouseDto } from '@hofy/api-shared';
import { DateRangeStrings, DateString } from '@hofy/global';
import { formatDate, formatDateRange } from '@hofy/helpers';
import { FormGridRow, LabeledText } from '@hofy/ui';

interface ShipmentDetailsProps {
    label: string;
    publicId: string;
    estimateOn?: DateRangeStrings | null;
    scheduledOn?: DateString | null;
    latestWithCourier: DateString | null;
    warehouse?: WarehouseDto;
    numberOfBoxes: number;
}

export const ShipmentDetails: FC<ShipmentDetailsProps> = ({
    estimateOn,
    scheduledOn,
    latestWithCourier,
    label,
    publicId,
    warehouse,
    numberOfBoxes,
}) => (
    <FormGridRow columns={2}>
        {estimateOn && <LabeledText label={label} content={formatDateRange(estimateOn)} />}
        {scheduledOn && <LabeledText label={label} content={formatDate(scheduledOn)} />}
        <LabeledText label='Ship By' content={formatDate(latestWithCourier)} />
        <LabeledText label='Public id' content={publicId} flex={1} />
        <LabeledText label='Warehouse' content={warehouse?.name} flex={1} />
        <LabeledText label='Number of Boxes' content={numberOfBoxes} />
    </FormGridRow>
);
