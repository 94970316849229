import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey, repaymentPlanService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useRecheckRepaymentPlan = (id: UUID[]) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => repaymentPlanService.recheckRepaymentPlans(id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: 'Two status check re-triggered',
            });
        },
    });

    const recheck = () => {
        mutation.mutate();
    };

    return {
        recheck,
        isLoading: mutation.isPending,
    };
};
