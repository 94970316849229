import React, { FC } from 'react';

import { UploadFile } from '@hofy/common';
import {
    AsyncButton,
    Button,
    FormContainer,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useUploadTwoPayouts } from '../../../../store/payouts/useUploadTwoPayouts';

interface UploadTwoPayoutsModalProps {
    onClose(): void;
}

export const UploadTwoPayoutsModal: FC<UploadTwoPayoutsModalProps> = ({ onClose = () => {} }) => {
    const { tr } = useAdminI18n();
    const { file, setFile, submit, isLoading } = useUploadTwoPayouts(onClose);

    return (
        <Modal onClose={onClose} width={420}>
            <ModalHeader title={tr('upload-two-payouts-modal.title')} />
            <ModalContent>
                <FormContainer>
                    <Paragraph3>{tr('upload-two-payouts-modal.description')}</Paragraph3>
                    <UploadFile
                        label={tr('upload-two-payouts-modal.upload-label')}
                        value={file}
                        accept='text/xlsx'
                        note={tr('upload-two-payouts-modal.upload-note')}
                        onChange={setFile}
                        disabled={isLoading}
                        isLoading={isLoading}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <Button
                    type='secondary'
                    label={tr('upload-two-payouts-modal.cancel')}
                    onClick={onClose}
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    label={tr('upload-two-payouts-modal.submit')}
                    isLoading={isLoading}
                    disabled={!file}
                    disableCheck
                    onClick={submit}
                />
            </ModalFooter>
        </Modal>
    );
};
