import { ColorDye } from '@hofy/global';

export enum DataErasureStatus {
    Pending = 'pending',
    Completed = 'completed',
    Cancelled = 'cancelled',
}

export const dataErasureStatusColor: Record<DataErasureStatus, ColorDye> = {
    [DataErasureStatus.Pending]: 'red',
    [DataErasureStatus.Completed]: 'green',
    [DataErasureStatus.Cancelled]: 'grey',
};
