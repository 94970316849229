export const assignmentStatusI18n = {
    'assignment-status.delivery-pending': 'Delivery pending',
    'assignment-status.delivery-with-courier': 'Delivery with courier',
    'assignment-status.with-user': 'With user',
    'assignment-status.collection-pending': 'Collection pending',
    'assignment-status.collection-with-courier': 'Collection with courier',
    'assignment-status.collection-at-warehouse': 'Collection at warehouse',
    'assignment-status.collected': 'Collected',
    'assignment-status.cancelled': 'Cancelled',
};
