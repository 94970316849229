import { easeInOutTransition } from '../../../config/animations';

export const opacityVariants = {
    large: { opacity: [0, 1] },
    small: { opacity: [0, 1] },
};
export const sizeVariants = {
    large: { width: 255 },
    small: { width: 100 },
};

export const opacityTransition = {
    ...easeInOutTransition,
    duration: easeInOutTransition.duration * 3,
};
