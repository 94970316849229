import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Paragraph3 } from '@hofy/ui';

import { EmptyResult } from './EmptyResult';
import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface NotesResultProps {
    title: string;
    notes?: string | null;
}

export const NotesResult: FC<NotesResultProps> = ({ title, notes }) => {
    const getNotes = () => {
        if (!notes) {
            return <EmptyResult />;
        }

        return <Paragraph3 color={Color.ContentPrimary}>{notes}</Paragraph3>;
    };

    return (
        <SurveyResultsSectionCardChip title={title} status={SurveyResultsSectionCardChipStatus.Neutral}>
            {getNotes()}
        </SurveyResultsSectionCardChip>
    );
};
