import React, { FC } from 'react';

import { Ownership, OwnershipDto } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ItemOwnershipBadgeProps {
    ownership: OwnershipDto;
}

export const ItemOwnershipBadge: FC<ItemOwnershipBadgeProps> = ({ ownership }) => {
    return (
        <Badge dot color={ownership.type === Ownership.Hofy ? 'violet' : 'orange'} label={ownership.name} />
    );
};
