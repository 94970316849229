import React, { FC, useState } from 'react';

import { SupplierContactPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { Box } from '@hofy/ui';

import { useDeleteSupplierContact } from '../../../../../store/suppliers/useDeleteSupplierContact';
import { useSupplierContactsQuery } from '../../../../../store/suppliers/useSupplierContactsQuery';
import { useUpdateSupplierContact } from '../../../../../store/suppliers/useUpdateSupplierContact';
import { SupplierContactFormSlideout } from '../../components/SupplierContactFormSlideout';
import { SupplierContactsTable } from '../../components/SupplierContactsTable';

interface SupplierContactsTabProps {
    supplierId: UUID;
    search?: string;
}

export const SupplierContactsTab: FC<SupplierContactsTabProps> = ({ supplierId, search }) => {
    const { data: contacts } = useSupplierContactsQuery(supplierId, search);
    const { updateContact } = useUpdateSupplierContact(supplierId);
    const { deleteContact } = useDeleteSupplierContact(supplierId);
    const [editIndex, setEditIndex] = useState<number | null>(null);

    const onEditContact = (index: number, contact: SupplierContactPayload) => {
        updateContact(contacts[index].id, contact);
        setEditIndex(null);
    };

    return (
        <Box fullWidth paddingHorizontal={40} paddingVertical={30}>
            <SupplierContactsTable
                contacts={contacts}
                columns='auto-fit'
                onEditContact={v => setEditIndex(v)}
                onDeleteContact={i => deleteContact(contacts[i].id)}
            />
            {editIndex !== null && (
                <SupplierContactFormSlideout
                    payload={contacts[editIndex]}
                    onSave={p => onEditContact(editIndex, p)}
                    onClose={() => setEditIndex(null)}
                />
            )}
        </Box>
    );
};
