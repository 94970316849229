import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    BookShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { isShipmentFromUserToUser, ShipmentCourier } from '@hofy/api-shared';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import { validateScheduledOn, validateShipmentCourierForm } from './useUpdateShipment';

const validateBookShipmentForm = (shipment: ShipmentDetailsDto) => (formState: BookShipmentPayload) => {
    const { bookedOn, estimateOn, scheduledOn } = formState;
    return {
        bookedOn: errorMap([isEmpty(bookedOn), 'Processed date is required']),
        scheduledOn: !isShipmentFromUserToUser(shipment) && validateScheduledOn(scheduledOn),
        estimateOn: errorMap([isEmpty(estimateOn), 'Select date']),
        ...validateShipmentCourierForm(formState),
    };
};

export const useBookShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, BookShipmentPayload>({
        mutationFn: payload => shipmentService.bookShipment(shipment.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<BookShipmentPayload>({
        initial: mapShipmentToPayload(shipment),
        onSubmit: mutation.mutate,
        validate: validateBookShipmentForm(shipment),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};

const mapShipmentToPayload = (shipment: ShipmentDetailsDto): BookShipmentPayload => {
    return {
        courier: shipment.courier || ShipmentCourier.UPS,
        trackingLink: shipment.trackingLink,
        courierReference: shipment.courierReference,
        shipmentClass: shipment.shipmentClass,
        bookedOn: shipment.bookedOn || nowISODate(),
        estimateOn: shipment.estimateOn,
        scheduledOn: shipment.scheduledOn,
        sendNotification: true,
        deliveryNotes: shipment.deliveryNotes,
        numberOfBoxes: shipment.numberOfBoxes || 1,
    };
};
