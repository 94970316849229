export const purchaseOrderStatusI18n = {
    'purchase-order-status.draft': 'Draft',
    'purchase-order-status.pending-approval': 'Pending approval',
    'purchase-order-status.declined': 'Declined',
    'purchase-order-status.cancelled': 'Cancelled',
    'purchase-order-status.approved': 'Approved',
    'purchase-order-status.pending-receipt': 'Pending receipt',
    'purchase-order-status.partially-received': 'Partially received',
    'purchase-order-status.pending-billing': 'Pending billing',
    'purchase-order-status.partially-billed': 'Partially billed',
    'purchase-order-status.partially-billed-and-received': 'Partially billed and received',
    'purchase-order-status.billed': 'Billed',
    'purchase-order-status.closed': 'Closed',
};
