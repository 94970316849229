import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';
import { Icon, SvgIcon } from '../icon';
import { Tooltip, TooltipProps } from './Tooltip';

interface TooltipIconProps extends Omit<TooltipProps, 'asChild'>, MarginBoxProps {
    icon: Svg;
    iconColor?: Color;
    iconSize?: number;
}

export const TooltipIcon: FC<TooltipIconProps> = ({
    icon,
    iconColor = Color.ContentTertiary,
    iconSize,
    title,
    body,
    bodySlot,
    placement,
    maxWidth,
    interactive,
    ...rest
}) => {
    return (
        <Tooltip
            title={title}
            body={body}
            bodySlot={bodySlot}
            placement={placement}
            maxWidth={maxWidth}
            interactive={interactive}
            asChild
        >
            <Box centered {...rest}>
                <Icon svg={icon} color={iconColor} size={iconSize} />
            </Box>
        </Tooltip>
    );
};

export const AlertTooltipIcon: FC<Omit<TooltipIconProps, 'icon'>> = props => {
    return <TooltipIcon icon={SvgIcon.AlertCircle} iconColor={Color.ContentNegative} {...props} />;
};

export const WarnTooltipIcon: FC<Omit<TooltipIconProps, 'icon'>> = props => {
    return <TooltipIcon icon={SvgIcon.AlertCircle} iconColor={Color.ContentWarning} {...props} />;
};

export const InfoTooltipIcon: FC<Omit<TooltipIconProps, 'icon'>> = props => {
    return <TooltipIcon icon={SvgIcon.InfoCircle} iconColor={Color.ContentTertiary} {...props} />;
};

export const HelpTooltipIcon: FC<Omit<TooltipIconProps, 'icon'>> = props => {
    return <TooltipIcon icon={SvgIcon.Help} iconColor={Color.ContentSecondary} {...props} />;
};
