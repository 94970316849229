import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    CancelDataErasurePayload,
    CompleteDataErasurePayload,
    dataErasureService,
    defaultCancelDataErasureDto,
    defaultCompleteDataErasurePayload,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { UseForm, useForm, useToast } from '@hofy/ui';

interface UpdateDataErasureState {
    form: UseForm<UpdateDataErasureFormProps>;
    assignmentId: UUID;
    isLoadingMutation: boolean;
}

export interface UpdateDataErasureFormProps extends CompleteDataErasurePayload, CancelDataErasurePayload {
    cannotComplete: boolean;
}

const defaultUpdateDataErasureFormProps: UpdateDataErasureFormProps = {
    ...defaultCompleteDataErasurePayload,
    ...defaultCancelDataErasureDto,
    cannotComplete: false,
};

export const useUpdateDataErasure: (assignmentId: UUID, onSuccess: () => void) => UpdateDataErasureState = (
    assignmentId: UUID,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();

    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (props: UpdateDataErasureFormProps) => {
            if (props.cannotComplete) {
                return dataErasureService.cancelAssignmentDataErasure(assignmentId, props);
            }
            return dataErasureService.completeAssignmentDataErasure(assignmentId, props);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Data erasure updated',
            });
        },
    });

    const form = useForm<UpdateDataErasureFormProps>({
        initial: defaultUpdateDataErasureFormProps,
        onSubmit: mutation.mutate,
    });

    return {
        form,
        assignmentId,
        isLoadingMutation: mutation.isPending,
        isError: mutation.isError,
    };
};
