import React, { FC, ReactNode, useCallback, useRef, useState } from 'react';
import { useList } from 'react-use';

import { useStructMemo } from '@hofy/hooks';

import { ConfirmationContext, ConfirmationContextType, ConfirmationModalItem } from './ConfirmationContext';
import { ConfirmationModals } from './ConfirmationModals';

interface ConfirmationContextProps {
    children?: ReactNode;
}

export const ConfirmationContextProvider: FC<ConfirmationContextProps> = ({ children }) => {
    const [modals, { push, removeAt }] = useList<ConfirmationModalItem>();
    const [opened, setOpened] = useState<string>();
    const fnRef = useRef<() => void>();

    const addModal = useCallback((modal: ConfirmationModalItem) => {
        push(modal);
    }, []);

    const removeModal = useCallback((id: string) => {
        removeAt(modals.findIndex(modal => modal.id === id));
    }, []);

    const openModal = useCallback((id: string, callback?: () => void) => {
        setOpened(id);
        fnRef.current = callback;
    }, []);

    const closeModal = useCallback(() => {
        setOpened(undefined);
    }, []);

    const value = useStructMemo<ConfirmationContextType>({
        modals,
        opened,
        addModal,
        removeModal,
        openModal,
        closeModal,
        fnRef,
    });

    return (
        <ConfirmationContext.Provider value={value}>
            <ConfirmationModals />
            {children}
        </ConfirmationContext.Provider>
    );
};
