import React, { FC } from 'react';

import { getTaxRate } from '@hofy/api-admin';
import { TaxStatus, validTaxStatuses } from '@hofy/api-shared';
import { Percent } from '@hofy/global';
import { useDecimal } from '@hofy/hooks';
import { Paragraph3, TwoLineSmallCell } from '@hofy/ui';

import { TaxStatusChip } from './TaxStatusChip';

interface TaxCellProps {
    tax: {
        status: TaxStatus;
        rate: Percent;
        predictionRate: Percent | null;
        code: string;
    };
}

export const TaxCell: FC<TaxCellProps> = ({ tax }) => {
    const { formatPercent } = useDecimal();
    if (!validTaxStatuses.includes(tax.status)) {
        return <Paragraph3>--</Paragraph3>;
    }
    return <TwoLineSmallCell line1={formatPercent(getTaxRate(tax))} line2={tax.code} />;
};

export const TaxStatusCell: FC<TaxCellProps> = ({ tax }) => {
    return <TaxStatusChip status={tax.status} />;
};
