import React, { FC, ReactNode, useMemo } from 'react';

import { useI18nConfig } from '../hooks/useI18nConfig';
import { I18nConfig } from '../types';
import { I18nContext } from './I18nContext';

interface I18nProviderProps {
    children?: ReactNode;
    withMagicCombination: boolean;
    translations: Record<string, string>;
}

export const I18nProvider: FC<I18nProviderProps> = ({ children, translations, withMagicCombination }) => {
    const { showKeys } = useI18nConfig(withMagicCombination);

    const value = useMemo<I18nConfig>(
        () => ({
            showKeys,
            keys: translations,
        }),
        [translations, showKeys],
    );

    return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};
