import { ColorDye, getEnumValues, VisualType } from '@hofy/global';

export enum InspectionStatus {
    Pending = 'pending',
    InProgress = 'in_progress',
    Completed = 'completed',
    Cancelled = 'cancelled',
}

export const allInspectionStatuses = getEnumValues<InspectionStatus>(InspectionStatus);

export const allInitialInspectionStatuses: InspectionStatus[] = [
    InspectionStatus.Pending,
    InspectionStatus.InProgress,
    InspectionStatus.Completed,
];

export const getInspectionStatusIndex = (status: InspectionStatus): number => {
    return allInspectionStatuses.indexOf(status);
};

const inspectionStatusColorDyes: Record<InspectionStatus, ColorDye> = {
    [InspectionStatus.Pending]: 'magenta',
    [InspectionStatus.InProgress]: 'orange',
    [InspectionStatus.Completed]: 'green',
    [InspectionStatus.Cancelled]: 'red',
};

export const getInspectionStatusColorDye = (status: InspectionStatus): ColorDye => {
    return inspectionStatusColorDyes[status];
};

const inspectionStatusVisualTypes: Record<InspectionStatus, VisualType> = {
    [InspectionStatus.Pending]: 'informative',
    [InspectionStatus.InProgress]: 'informative',
    [InspectionStatus.Completed]: 'positive',
    [InspectionStatus.Cancelled]: 'negative',
};

export const getInspectionStatusVisualType = (status: InspectionStatus): VisualType => {
    return inspectionStatusVisualTypes[status];
};
