import React from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { useTrAdminAccounting } from '../../store/accounting/useTrAdminAccounting';
import { useAdminNavigateAccounting } from '../../store/finance/useAdminNavigateAccounting';
import { AdminAccountingTab } from './AdminAccountingTab';

interface AccountingTabsProps {
    tab: AdminAccountingTab;
}

export const AccountingTabs = ({ tab }: AccountingTabsProps) => {
    const trAdminAccounting = useTrAdminAccounting();
    const { adminAccountingTabs, navigateAccounting } = useAdminNavigateAccounting();

    return (
        <Tabs active={tab} onChange={navigateAccounting} animationId='accounting-tabs'>
            {adminAccountingTabs.map(tab => (
                <Tab key={tab} id={tab} label={trAdminAccounting(tab)} />
            ))}
        </Tabs>
    );
};
