import React, { FC } from 'react';

import { SupplierContactPayload } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Button } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useSupplierContactForm } from '../../../../store/suppliers/useSupplierForm';
import { SupplierContactForm } from './SupplierContactForm';

interface SupplierContactFormSlideoutProps {
    payload: SupplierContactPayload;
    onClose(): void;
    onSave(p: SupplierContactPayload): void;
}

export const SupplierContactFormSlideout: FC<SupplierContactFormSlideoutProps> = ({
    payload,
    onSave,
    onClose,
}) => {
    const form = useSupplierContactForm(payload, onSave);
    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Supplier Contact' />
            <SlideoutContent fullWidth paddingVertical={10}>
                <SupplierContactForm form={form} />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                <Button label='Save' onClick={form.submit} />
            </SlideoutFooter>
        </Slideout>
    );
};
