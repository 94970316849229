import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { Spinner } from '@hofy/ui';

import { useSyncSuppliersToNetsuite } from '../../../store/suppliers/useSyncSuppliersToNetsuite';

export const SuppliersPageMenu: FC = () => {
    const { hasPermission } = usePermission();

    const { syncToNetsuite, isLoadingMutation } = useSyncSuppliersToNetsuite();

    if (isLoadingMutation) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: syncToNetsuite,
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
            ]}
        />
    );
};
