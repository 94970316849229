import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    ActivateContractPayload,
    ContractDetailsUnionDto,
    contractsCacheKey,
    contractService,
    invoiceEntriesQueryKey,
} from '@hofy/api-admin';
import { nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

export const useActivateContract = (contract: ContractDetailsUnionDto, onEnd: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: ActivateContractPayload) =>
            contractService.activateContract(contract.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [contractsCacheKey],
            });
            queryClient.invalidateQueries({
                queryKey: [invoiceEntriesQueryKey, contract.id],
            });
            onEnd();
        },
    });

    const form = useForm<ActivateContractPayload>({
        initial: {
            activeOn: nowISODate(),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
