import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { purchaseOrderService } from '../service/purchaseOrderService';
import { AdminPurchaseOrdersFilter } from './AdminPurchaseOrdersFilter';
import { purchaseOrdersCacheKey } from './cacheKey';

export const usePurchaseOrders = (filters: AdminPurchaseOrdersFilter) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [purchaseOrdersCacheKey, filters],
        queryFn: param =>
            purchaseOrderService.listPurchaseOrders(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const purchaseOrders = useMemo(() => {
        if (data) {
            return data.pages.flatMap(v => v.content);
        }
        return [];
    }, [data]);

    return {
        purchaseOrders,
        purchaseOrdersIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
