export interface UpdateAssignmentConfigurationPayload {
    userName: string | null;
    password: string | null;
    recoveryKey: string | null;
    checklistCompleted: boolean | null;
}

export const defaultAssignmentConfigurationPayload: UpdateAssignmentConfigurationPayload = {
    userName: null,
    password: null,
    recoveryKey: null,
    checklistCompleted: null,
};
