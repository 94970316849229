export interface SsoConfigDto {
    ssoType: SsoType;
    enabled: boolean;
    metadataUrl: string | null;
}

export enum SsoType {
    Saml = 'saml',
    Google = 'google',
    Microsoft = 'microsoft',
}
