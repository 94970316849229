import { getEnumValues } from '@hofy/global';

export enum OperatingSystem {
    Linux = 'linux',
    Macos = 'macos',
    Windows = 'windows',
    IosPhone = 'ios_phone',
    AndroidPhone = 'android_phone',
    IosTablet = 'ios_tablet',
    AndroidTablet = 'android_tablet',
}

export const allOperatingSystems = getEnumValues<OperatingSystem>(OperatingSystem);
