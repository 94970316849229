import { AuditableTable } from '@hofy/api-admin';
import { stringifyUrl, UUID } from '@hofy/global';

import { AdminNavLink } from '../../components/routing/AdminNavLink';

export const useAuditLink = () => {
    return (query: AuditLinkProps) => {
        return stringifyUrl({
            url: AdminNavLink.AuditLogs,
            query: {
                ...query,
            },
        });
    };
};

interface AuditLinkProps {
    auditTableId?: number | null;
    auditTableUuid?: UUID | null;
    auditTableName?: AuditableTable;
    transactionId?: UUID;
}
