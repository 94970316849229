import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentService, CreateAssignmentForItemPayload, itemCacheKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useCreateAssignment = (userId: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: CreateAssignmentForItemPayload) =>
            assignmentService.createAssignment(userId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onSuccess();
        },
    });

    return {
        createAssignment: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
