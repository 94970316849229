import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Icon, LabeledLink, SvgIcon } from '@hofy/ui';

import { Link } from '../../components/routing/Link';
import { OrganizationTab } from '../../store/organizations/types/OrganizationTab';

interface OrganizationLinkProps {
    id: UUID;
    children?: ReactNode;
    tab?: OrganizationTab;
}

export const OrganizationLink: FC<OrganizationLinkProps> = ({
    id,
    children,
    tab = OrganizationTab.Details,
}) => {
    return (
        <Link flex to={`/organizations/${id}/${tab}`}>
            {children}
        </Link>
    );
};

export const OrganizationLinkOptional: FC<Partial<OrganizationLinkProps>> = ({ id, children }) => {
    return id ? <OrganizationLink id={id}>{children}</OrganizationLink> : <>--</>;
};

interface LabeledOrganizationLinkProps {
    organization: {
        id: UUID;
        name: string;
    };
}

export const LabeledOrganizationLink: FC<LabeledOrganizationLinkProps> = ({ organization }) => {
    return (
        <LabeledLink
            label='Organization'
            content={
                <OrganizationLink id={organization.id}>
                    <Icon size={14} svg={SvgIcon.Building} marginRight={4} />
                    {organization.name}
                </OrganizationLink>
            }
        />
    );
};
