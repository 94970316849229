import { ColorDye, getEnumValues } from '@hofy/global';

export enum JobStatus {
    Pending = 'pending',
    Processing = 'processing',
    Finished = 'finished',
    Cancelled = 'cancelled',
    Failed = 'failed',
}

export const jobStatusColor: Record<JobStatus, ColorDye> = {
    [JobStatus.Pending]: 'grey',
    [JobStatus.Processing]: 'blue',
    [JobStatus.Finished]: 'green',
    [JobStatus.Cancelled]: 'red',
    [JobStatus.Failed]: 'red',
};
export const allJobStatuses = getEnumValues<JobStatus>(JobStatus);
