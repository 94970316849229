export const contractSettingsI18n = {
    'contract-settings.dropdown.rental-duration': 'Rental duration',

    'contract-settings.dropdown.billing-frequency': 'Billing frequency',
    'contract-settings.dropdown.device-billing-frequency': 'Device billing frequency',

    'contract-settings.dropdown.billing-frequency.monthly': 'Monthly',
    'contract-settings.dropdown.billing-frequency.annual': 'Annual',
    'contract-settings.dropdown.billing-frequency.upfront': 'Upfront',

    'contract-settings.devices.subtitle': 'Includes: laptops, desktops, tablets & mobile phones.',
    'contract-settings.it_peripherals.subtitle':
        'Includes: monitors, docking stations, headsets, keyboards, mice & trackpads, printers, shredders, webcams and wifi range extenders.',
    'contract-settings.it_accessories.subtitle': 'Includes: dongles, cables, adaptors and chargers.',
    'contract-settings.furniture.subtitle': 'Includes: desks, chairs and desk risers.',
    'contract-settings.furniture_accessories.subtitle':
        'Includes: standing mats, Laptop stands, Foot rests, Task lights, Desk tidies and Monitor arms.',

    'contract-settings.toast.success': 'Updated contract settings',
};
