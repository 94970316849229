import React, { FC } from 'react';

import { emptyItemsFilters, useItemQuery } from '@hofy/api-admin';
import { formatVariant, validStatusesToRepair } from '@hofy/api-shared';
import { FormTextarea, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, Form, FormContainer, FormDateInput, Heading3, SubmitButton, SvgIcon } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { ItemListDropdown } from '../../../../components/domain/items/ItemListDropdown';
import { ProductItem } from '../../../../components/domain/products/ProductItem';
import { AdminListDropdown } from '../../../../components/domain/user/AdminListDropdown';
import { useCreateRepair } from '../../../../store/repairs/useCreateRepair';
import { ItemLocationCell } from '../../../itemsPage/components/ItemLocationCell';

interface CreateRepairSlideoutProps {
    itemId?: UUID;
    onClose(): void;
}

export const CreateRepairSlideout: FC<CreateRepairSlideoutProps> = ({ itemId, onClose }) => {
    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title={<Heading3>Create repair</Heading3>} />
            <SlideoutContent column paddingVertical={20} gap={20}>
                <CreateRepairForm itemId={itemId} onClose={onClose} />
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </Slideout>
    );
};

const CreateRepairForm: FC<CreateRepairSlideoutProps> = ({ itemId, onClose }) => {
    const {
        form,
        isLoading: isFormLoading,
        isError: isFormError,
    } = useCreateRepair({
        itemId,
        onSuccess: onClose,
    });
    const { data: item, isLoading: isItemLoading } = useItemQuery(form.values.itemId);

    return (
        <Form onSubmit={form.submit} isLoading={isFormLoading} isError={isFormError}>
            <FormContainer>
                {itemId === undefined && (
                    <ItemListDropdown
                        label='Defective item'
                        value={form.values.itemId}
                        onChange={itemId =>
                            form.setValues({
                                itemId,
                            })
                        }
                        filters={{
                            ...emptyItemsFilters,
                            status: validStatusesToRepair,
                        }}
                        isRequired
                        isError={form.errors.itemId}
                        disabled={isItemLoading}
                    />
                )}
                {item && (
                    <Box row gap={10}>
                        <Box flex={2}>
                            <ProductItem
                                image={item.variant.image}
                                label={item.product.name}
                                subLabel={formatVariant(item.variant)}
                            />
                        </Box>
                        <Box flex={1}>
                            <ItemLocationCell location={item.location} />
                        </Box>
                    </Box>
                )}
                <FormDateInput label='Reported on' api={form.fields.createdOn} isRequired />
                <FormTextarea
                    label='Note'
                    placeholder='Describe the issue with the item'
                    value={form.values.noteText}
                    onChangeText={noteText =>
                        form.setValues({
                            noteText,
                        })
                    }
                    isRequired
                    isError={form.errors.noteText}
                    height={60}
                />
                <AdminListDropdown
                    label='Assigned admin'
                    emptyContent='Select an admin user'
                    value={form.values.assignedUserId || undefined}
                    onChange={assignedUserId =>
                        form.setValues({
                            assignedUserId,
                        })
                    }
                />
                <SubmitButton label='Create' leftIcon={SvgIcon.Tool} marginTop={10} />
            </FormContainer>
        </Form>
    );
};
