import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { OrderDetailsDto } from './types/OrderDetailsDto';
import { OrderDto } from './types/OrderDto';
import { OrderFilters } from './types/OrderFilters';

class OrderService {
    public listOrders = async (filters: OrderFilters, page: PageRequest): Promise<PageResponse<OrderDto>> => {
        return await restClient.getJson<PageResponse<OrderDto>>(
            stringifyUrl({
                url: '/api/admin/orders',
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getOrderDetails = async (id: UUID): Promise<OrderDetailsDto> => {
        return restClient.getJson<OrderDetailsDto>(
            stringifyUrl({
                url: `/api/admin/orders/${id}`,
            }),
        );
    };
}

export const orderService = new OrderService();
