import React, { FC, ReactNode } from 'react';

import { RepaymentPlanRefDto, RepaymentStatus } from '@hofy/api-shared';
import { FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { RepaymentPlanLink } from '../RepaymentPlanLink';
import { RepaymentStatusBadge } from './RepaymentStatusBadge';

interface TwoRefDetailsProps {
    plan: RepaymentPlanRefDto | null;
    children?: ReactNode;
}

export const TwoRefDetails: FC<TwoRefDetailsProps> = ({ plan, children }) => {
    if (!plan?.id) {
        return (
            <FormGridRow columns={4}>
                <Labeled label='Status' content={<RepaymentStatusBadge status={RepaymentStatus.Pending} />} />
                {children}
            </FormGridRow>
        );
    }

    return (
        <FormGridRow columns={4}>
            <Labeled label='Status' content={<RepaymentStatusBadge status={plan.status} />} />
            <LabeledText
                label='Plan id'
                content={<RepaymentPlanLink id={plan.id}>{plan.id}</RepaymentPlanLink>}
            />
            {children}
        </FormGridRow>
    );
};
