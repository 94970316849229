import React from 'react';

import { TeamColor, teamColorMapping } from '@hofy/api-shared';
import { Dot, MarginBoxProps } from '@hofy/ui';

interface TeamDotProps extends MarginBoxProps {
    color: TeamColor;
}

export const TeamDot = ({ color, ...rest }: TeamDotProps) => {
    return <Dot color={teamColorMapping[color]} size={8} {...rest} />;
};
