import { useQuery } from '@tanstack/react-query';

import { fulfilmentCountrySettingService } from '../service/FulfilmentCountrySettingService';
import { fulfilmentCountrySettingCacheKey } from './fulfilmentCountrySettingCacheKey';

export const useFulfilmentCountrySettingsStoreAndReuseEnabledQuery = () => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [fulfilmentCountrySettingCacheKey],
        queryFn: () => fulfilmentCountrySettingService.getCountrySettingsStoreAndReuseEnabled(),
    });

    return {
        isLoading,
        isError,
        data: data ?? [],
    };
};
