import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SvgIcon } from '@hofy/ui';

interface TimelineProps extends BoxProps {
    items: ReactNode[];
    currentIndex: number;
}

export const Timeline: FC<TimelineProps> = ({ items, currentIndex, ...boxProps }) => {
    const lastIndex = items.length - 1;
    return (
        <Box column gap={10} {...boxProps}>
            {items.map((item, i) => (
                <Box row alignItems='stretch' key={i}>
                    <Box column alignItems='center'>
                        {i < currentIndex || currentIndex === lastIndex ? (
                            <TimelineItemChecked />
                        ) : (
                            <TimelineItemDot isCurrent={currentIndex === i} />
                        )}
                        {i !== lastIndex && (
                            <Box
                                width={1}
                                bg={Color.NonContextualBorderDivider}
                                minHeight={20}
                                marginTop={10}
                                flex={1}
                            />
                        )}
                    </Box>
                    <Box paddingLeft={16} fullWidth>
                        {item}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

const TimelineItemChecked = () => {
    return (
        <Box bg={Color.NonContextualGreenSubtle} rect={20} centered rounded={16}>
            <Icon svg={SvgIcon.Check} size={12} color={Color.NonContextualGreenDefault} />
        </Box>
    );
};

export const TimelineItemDot = styled.div<{ isCurrent?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    border: 1px solid
        ${props =>
            props.isCurrent ? Color.NonContextualVioletDefault : Color.InteractionDisabledSubtleNormal};

    ::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${props =>
            props.isCurrent ? Color.NonContextualVioletDefault : Color.InteractionGhostNormal};
        border-radius: 50%;
    }
`;
