import React, { FC } from 'react';

import { netsuiteStatusColors, NetsuiteStatusDto, NetsuiteSyncStatus } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Badge, Paragraph3, Tooltip } from '@hofy/ui';

import { useTrNetsuiteSyncStatus } from '../../../store/netsuite/useTrNetsuiteSyncStatus';

interface NetsuiteStatusChipProps {
    status: NetsuiteStatusDto;
}

export const NetsuiteStatusChip: FC<NetsuiteStatusChipProps> = ({ status }) => {
    const trStatus = useTrNetsuiteSyncStatus();
    if (!status.status) {
        return <Badge color='orange' label='Pending' />;
    }
    if (status.status === NetsuiteSyncStatus.Error) {
        return (
            <Tooltip
                bodySlot={
                    <Paragraph3 color={Color.ContentInvertedPrimary} wordBreak>
                        {status.errorReason}
                    </Paragraph3>
                }
            >
                <Badge color={netsuiteStatusColors[status.status]} label={trStatus(status.status)} />
            </Tooltip>
        );
    }
    return <Badge color={netsuiteStatusColors[status.status]} label={trStatus(status.status)} />;
};
