import { LayoutGroup } from 'framer-motion';
import React, { Fragment, Key, ReactNode, useId } from 'react';

import { childrenProps } from '../../helpers/React';
import { TestKeyAware } from '../../types';
import { Box, OuterBoxProps } from '../base';
import { Tab, TabProps } from './Tab';

export interface TabsProps<T extends Key> extends OuterBoxProps, TestKeyAware {
    active: T;
    onChange(v: T): void;
    children: ReactNode;
    /** Use this if you want to preserve animations after re-mounting a component */
    animationId?: string;
}

export const Tabs = <T extends Key>({
    active,
    onChange,
    children,
    animationId,
    testKey = 'tabs',
    ...props
}: TabsProps<T>) => {
    const uniqId = useId();
    const id = animationId || uniqId;
    const tabs = childrenProps<TabProps<T>>(children, Tab);

    if (!tabs.length) {
        return null;
    }

    return (
        <Box row wrap columnGap={8} marginHorizontal={-8} data-test-key={testKey} {...props}>
            <LayoutGroup id={id}>
                {tabs.map(item => (
                    <Fragment key={item.id}>
                        {item.divider === true && <Box marginBottom={4} height={20} borderLeft />}
                        <Tab
                            {...item}
                            onClick={onChange}
                            active={active === item.id}
                            testKey={item.id.toString()}
                        />
                    </Fragment>
                ))}
            </LayoutGroup>
        </Box>
    );
};
