import React, { FC } from 'react';

import { AssignmentStatus } from '@hofy/api-shared';
import { Box, BoxProps } from '@hofy/ui';

import { AssignmentStatusBadge } from '../../../../../components/domain/assignments/badge/AssignmentStatusBadge';

interface AssignmentStatusCellProps extends BoxProps {
    status: AssignmentStatus;
}

export const AssignmentStatusCell: FC<AssignmentStatusCellProps> = ({ status, ...boxProps }) => {
    return (
        <Box row {...boxProps}>
            <AssignmentStatusBadge status={status} />
        </Box>
    );
};
