import React, { FC } from 'react';

import { PaymentDataStatus, paymentDataStatusColor } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Badge, Paragraph3, Tooltip } from '@hofy/ui';

import { useTrPaymentDataStatus } from '../../../../../store/payments/useTrPaymentDataStatus';

interface PaymentDataStatusChipProps {
    status: PaymentDataStatus;
    errorReason: string | null;
}

export const PaymentDataStatusChip: FC<PaymentDataStatusChipProps> = ({ status, errorReason }) => {
    const trStatus = useTrPaymentDataStatus();

    if (errorReason) {
        return (
            <Tooltip
                bodySlot={
                    <Paragraph3 color={Color.ContentInvertedPrimary} wordBreak>
                        {errorReason[0].toUpperCase() + errorReason.slice(1)}
                    </Paragraph3>
                }
            >
                <Badge color={paymentDataStatusColor[status]} label={trStatus(status)} />
            </Tooltip>
        );
    }

    return <Badge label={trStatus(status)} color={paymentDataStatusColor[status]} />;
};
