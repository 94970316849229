import React, { FC } from 'react';

import { ItemDto } from '@hofy/api-admin';
import { ItemGrade, ProductCategory } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import {
    Box,
    Form,
    FormContainer,
    Icon,
    LabeledDebouncedInput,
    LabeledInput,
    LabeledText,
    Paragraph3,
    Paragraph4,
    Spinner,
    SvgIcon,
} from '@hofy/ui';

interface ShipmentOrderItemAssignFormProps {
    item: ItemDto | null;
    serialNumber: string | null;
    onSerialNumberChange(serialNumber: string | null): void;
    serialNumberError: string | undefined;
    itemCode: string | null;
    onItemCodeChange(itemCode: string | null): void;
    itemError: string | undefined;
    shouldUseRefurbishedStock: boolean;
    isFetchingItem: boolean;
    onSuccess(): void;
}

export const ShipmentOrderItemAssignForm: FC<ShipmentOrderItemAssignFormProps> = ({
    item,
    serialNumber,
    onSerialNumberChange,
    serialNumberError,
    itemCode,
    onItemCodeChange,
    itemError,
    shouldUseRefurbishedStock,
    isFetchingItem,
    onSuccess,
}) => {
    return (
        <Form submitOnEnter onSubmit={onSuccess}>
            <FormContainer>
                <Box column gap={6}>
                    <LabeledDebouncedInput
                        autoFocus
                        isRequired
                        label='Item code or #Id'
                        placeholder='Enter or scan a item code'
                        onChange={onItemCodeChange}
                        value={itemCode}
                        rightSlot={isFetchingItem && <Spinner />}
                        nullable
                    />
                    {itemError && itemCode && (
                        <Box row gap={10}>
                            <Icon svg={SvgIcon.AlertCircle} color={Color.ContentNegative} />
                            <Paragraph3 color={Color.ContentNegative}>{itemError}</Paragraph3>
                        </Box>
                    )}
                    {shouldUseRefurbishedStock && item?.grade === ItemGrade.New && (
                        <Box row gap={10}>
                            <Icon svg={SvgIcon.AlertTriangle} color={Color.ContentWarning} />
                            <Paragraph4 color={Color.ContentWarning}>
                                Loaners and refurbish orders should be filled with used stock
                            </Paragraph4>
                        </Box>
                    )}
                </Box>
                {item && !itemError && (
                    <SerialNumberField
                        item={item}
                        serialNumber={serialNumber}
                        error={serialNumberError}
                        onChange={onSerialNumberChange}
                    />
                )}
            </FormContainer>
        </Form>
    );
};

interface SerialNumberFieldProps {
    item: ItemDto;
    serialNumber: string | null;
    error: string | undefined;
    onChange(serialNumber: string | null): void;
}

const SerialNumberField: FC<SerialNumberFieldProps> = ({ item, serialNumber, error, onChange }) => {
    if (item.serialNumber) {
        return <LabeledText label='Serial number' content={item.serialNumber} />;
    }

    return (
        <LabeledInput
            autoFocus
            isRequired={item?.product.category === ProductCategory.Laptop}
            label='Serial number'
            placeholder='Enter or scan serial number'
            onChange={onChange}
            value={serialNumber}
            errorMessage={error}
            nullable
        />
    );
};
