import {
    currencySymbols,
    formatDecimalWithISOCode,
    formatPriceWithCurrency,
    isPriceGreaterThan,
    Price,
    PricePair,
    priceToNumber,
} from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';

const defaultLocale = 'en-GB';

export const usePrice = () => {
    const trCurrency = useTrCurrency();

    const formatMonthlyPrice = (price: Price | null | undefined, precision: number = 2): string => {
        if (price === null || price === undefined) {
            return '--';
        }
        return formatPriceWithCurrency(price, precision) + '/mo';
    };

    const formatPrice = (price: Price | null | undefined, precision: number = 2): string => {
        if (price === null || price === undefined) {
            return '--';
        }
        return formatPriceWithCurrency(price, precision);
    };
    const formatPricePair = (
        price: PricePair | null,
        formatter: (v: string) => string = v => `(${v})`,
        precision: number = 2,
    ): string => {
        if (price === null || price === undefined) {
            return '--';
        }
        return `${formatPriceWithCurrency(price.price, precision)} ${formatter(
            formatPriceWithCurrency(price.originalPrice, precision),
        )}`;
    };

    const formatPriceWithISOCode = (price: Price | null | undefined, precision: number = 2) => {
        if (price === null || price === undefined) {
            return '--';
        }
        return formatDecimalWithISOCode(price.amount, trCurrency(price.currency), precision);
    };

    const multiply = (price: Price, times: number): Price => {
        return {
            currency: price.currency,
            amount: (priceToNumber(price) * times).toFixed(2),
        };
    };

    const add = (price: Price, secondPrice: Price): Price => {
        if (price.currency !== secondPrice.currency) {
            throw new Error('price currency mismatch');
        }
        return {
            currency: price.currency,
            amount: (priceToNumber(price) + priceToNumber(secondPrice)).toFixed(2),
        };
    };
    const sub = (price: Price, secondPrice: Price): Price => {
        if (price.currency !== secondPrice.currency) {
            throw new Error('price currency mismatch');
        }
        return {
            currency: price.currency,
            amount: (priceToNumber(price) - priceToNumber(secondPrice)).toFixed(2),
        };
    };

    const getPaidPercentage = (price: Price, secondPrice: Price) => {
        if (price.currency !== secondPrice.currency) {
            throw new Error('price currency mismatch');
        }
        if (isPriceGreaterThan(price, secondPrice) || secondPrice.amount === '0') {
            throw new Error('price should not exceed secondPrice');
        }
        return parseFloat((priceToNumber(price) / priceToNumber(secondPrice)).toFixed(2));
    };

    const localeFormatPrice = (price: Price | null | undefined, precision: number = 2): string => {
        if (price === null || price === undefined) {
            return '--';
        }

        const currencySymbol = currencySymbols[price.currency];
        const priceValue = parseFloat(price.amount);
        const options: Intl.NumberFormatOptions = {
            style: 'decimal',
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
        };

        if (priceValue < 0) {
            return ['-', currencySymbol, (-1 * priceValue).toLocaleString(defaultLocale, options)].join('');
        }
        return [currencySymbol, priceValue.toLocaleString(defaultLocale, options)].join('');
    };

    return {
        formatMonthlyPrice,
        formatPrice,
        formatPriceWithISOCode,
        localeFormatPrice,
        formatPricePair,
        multiply,
        add,
        sub,
        getPaidPercentage,
    };
};
