import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { fulfilmentCountrySettingService } from '../service/FulfilmentCountrySettingService';
import { FulfilmentCountrySettingDto } from '../service/types/FulfilmentCountrySettingDto';
import { FulfilmentCountrySettingsFilter } from '../service/types/FulfilmentCountrySettingsFilter';
import { fulfilmentCountrySettingCacheKey } from './fulfilmentCountrySettingCacheKey';

export const useFulfilmentCountrySettingsQuery = (filters: FulfilmentCountrySettingsFilter) => {
    const {
        data: pagedData,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useInfiniteQuery({
        queryKey: [fulfilmentCountrySettingCacheKey, filters],
        queryFn: param =>
            fulfilmentCountrySettingService.listCountrySettings(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const data = useMemo<FulfilmentCountrySettingDto[]>(
        () => (pagedData ? pagedData.pages.flatMap(v => v.content) : []),
        [pagedData],
    );

    return {
        isLoading,
        isError,
        data,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
