import { sortBy } from 'lodash';
import React, { FC } from 'react';

import { HofyWarehouseDetailsDto } from '@hofy/api-admin';
import {
    AdminRefDto,
    formatAdminName,
    getInspectionStatusColorDye,
    getInspectionStatusIndex,
    useTrInspectionStatus,
} from '@hofy/api-shared';
import { FilterChip } from '@hofy/ui';

import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { InspectionsTab } from '../../../store/inspections/inspectionsTab';
import { useInspectionsFilters } from '../../../store/inspections/useInspectionsFilters';

interface InspectionsFilterChipsProps {
    tab: InspectionsTab;
    filters: ReturnType<typeof useInspectionsFilters>;
    adminUsers: AdminRefDto[];
    hofyWarehouses: HofyWarehouseDetailsDto[];
}

export const InspectionsFilterChips: FC<InspectionsFilterChipsProps> = ({
    tab,
    adminUsers,
    hofyWarehouses,
    filters,
}) => {
    const trInspectionStatus = useTrInspectionStatus();
    return (
        <BlockFilterChipContainer show={filters.count > 0}>
            {filters.values.viewType === 'rows' && tab === InspectionsTab.Inspections && (
                <>
                    {sortBy(filters.values.inspectionStatuses, getInspectionStatusIndex).map(status => (
                        <FilterChip
                            key={status}
                            label={trInspectionStatus(status)}
                            onClear={() => filters.controls.toggleInspectionStatus(status)}
                            color={getInspectionStatusColorDye(status)}
                        />
                    ))}
                </>
            )}
            {filters.values.viewType === 'rows' && tab === InspectionsTab.DeviceChecks && (
                <>
                    {sortBy(filters.values.deviceCheckStatuses, getInspectionStatusIndex).map(status => (
                        <FilterChip
                            key={status}
                            label={trInspectionStatus(status)}
                            onClear={() => filters.controls.toggleDeviceCheckStatus(status)}
                            color={getInspectionStatusColorDye(status)}
                        />
                    ))}
                </>
            )}
            {adminUsers
                .filter(user => filters.values.assignedToIds.includes(user.id))
                .map(admin => (
                    <FilterChip
                        key={admin.id}
                        label={formatAdminName(admin)}
                        onClear={() => filters.controls.toggleAssignedToId(admin.id)}
                        color='blue'
                    />
                ))}

            {hofyWarehouses
                .filter(warehouse => filters.values.warehouseIds.includes(warehouse.id))
                .map(warehouse => (
                    <FilterChip
                        key={warehouse.id}
                        label={warehouse.name}
                        onClear={() => filters.controls.toggleWarehouseId(warehouse.id)}
                        color='grape'
                    />
                ))}
        </BlockFilterChipContainer>
    );
};
