import React, { FC } from 'react';

import { VisualType } from '@hofy/global';
import { Color, toColorDye, VisualVariant } from '@hofy/theme';

import { Box, MarginBoxProps } from '../base';
import { Icon } from './Icon';

export type FeaturedIconShape = 'circle' | 'square';
export type FeaturedIconSize = 24 | 32 | 40;

export interface FeaturedIconProps extends MarginBoxProps {
    type?: VisualType;
    variant?: VisualVariant;
    shape?: FeaturedIconShape;
    size?: FeaturedIconSize;
    icon: Svg;
    bgColour?: Color;
}

export const FeaturedIcon: FC<FeaturedIconProps> = ({
    variant = 'normal',
    type = 'informative',
    shape = 'circle',
    size = 24,
    icon,
    bgColour,
    ...props
}) => {
    const iconSize = iconSizeMap[size];
    const colorDye = toColorDye(type);

    const defaultBgColour = variant === 'vivid' ? colorDye.main : colorDye.subtle;
    const iconColor = variant === 'vivid' ? Color.ContentInvertedPrimary : colorDye.onMuted;

    return (
        <Box
            alignItems='center'
            justify='center'
            bg={bgColour ?? defaultBgColour}
            rounded={shape === 'circle' ? 32 : 8}
            rect={size}
            shrink={0}
            color={iconColor}
            {...props}
        >
            <Icon svg={icon} size={iconSize} color='currentColor' />
        </Box>
    );
};

const iconSizeMap: Record<FeaturedIconSize, number> = {
    24: 14,
    32: 14,
    40: 16,
};
