import { ActivityLogsFilter, emptyActivityLogsFilter } from '@hofy/api-admin';
import { useFilters } from '@hofy/ui';

import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';

export const useActivityLogsFilters = () => {
    const search = useSearchFilterDefinition();

    return useFilters<ActivityLogsFilter>(
        {
            search,
        },
        emptyActivityLogsFilter,
    );
};
