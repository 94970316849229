export const adminSettingsTabI18n = {
    'admin-settings-tab.terms-and-conditions': 'Terms and conditions',
    'admin-settings-tab.api-keys': 'API keys',
    'admin-settings-tab.country-multipliers': 'Country multipliers',
    'admin-settings-tab.exchange-rates': 'Exchange rates',
    'admin-settings-tab.warehouses': 'Warehouses',
    'admin-settings-tab.fulfilment-country-settings': 'Fulfilment countries',
    'admin-settings-tab.beta-features': 'Beta features',
    'admin-settings-tab.subsidiaries': 'Subsidiaries',
    'admin-settings-tab.data': 'Data',
    'admin-settings-tab.addons': 'Addons',
    'admin-settings-tab.netsuite': 'Netsuite',
};
