import { restClient } from '@hofy/rest';

import { NetsuiteConfigurationDto } from './types/NetsuiteConfigurationDto';

const baseUrl = '/api/admin/accounting';

class AccountingService {
    public getNetsuiteConfiguration = (): Promise<NetsuiteConfigurationDto> => {
        return restClient.getJson(`${baseUrl}/netsuite-configuration`);
    };

    public syncCurrenciesToNetsuite = (): Promise<void> => {
        return restClient.post(`${baseUrl}/currencies/sync-to-netsuite`);
    };

    public syncNetsuiteConfig = (): Promise<void> => {
        return restClient.post(`${baseUrl}/config/sync-to-netsuite`);
    };
}

export const accountingService = new AccountingService();
