import React from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import { InspectionStatus, useTrInspectionStatus } from '@hofy/api-shared';
import { addWorkingDays, formatDate, parseDateTime } from '@hofy/helpers';
import { Alert, AsyncButton, Box, FormSection, Paragraph3, Span } from '@hofy/ui';

import { useStartInspection } from '../../../store/inspections/useStartInspection';
import { InspectionTimeline } from '../components/InspectionTimeline';

interface InspectionProgressStartPromptSectionProps {
    inspection: InspectionDetailsUnionDto;
}

export const InspectionProgressStartPromptSection: React.FC<InspectionProgressStartPromptSectionProps> = ({
    inspection,
}) => {
    const trInspectionStatus = useTrInspectionStatus();

    const { startInspection, isLoading, isError } = useStartInspection(inspection.id);

    return (
        <FormSection label='Inspection progress'>
            <Alert
                type='informative'
                description={
                    <Box row gap={10}>
                        <Paragraph3>
                            This item arrived at the warehouse on {formatDate(inspection.statusUpdatedAt)} and
                            is due for inspection by{' '}
                            <Span bold>
                                {formatDate(addWorkingDays(parseDateTime(inspection.statusUpdatedAt), 2))}
                            </Span>
                            .
                        </Paragraph3>
                    </Box>
                }
            >
                <Box row alignItems='flex-start' fullWidth>
                    <InspectionTimeline
                        statusUpdates={inspection.statusUpdates}
                        statusSequence={[
                            InspectionStatus.Pending,
                            InspectionStatus.InProgress,
                            InspectionStatus.Completed,
                        ]}
                        toStatus={trInspectionStatus}
                    />
                    <Box>
                        <AsyncButton
                            label='Start inspection'
                            onClick={startInspection}
                            isLoading={isLoading}
                            isError={isError}
                        />
                    </Box>
                </Box>
            </Alert>
        </FormSection>
    );
};
