import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Box } from '../../base/index';

interface NavigationItemContainerProps {
    children: React.ReactNode;
    collapsed: boolean;
    bg?: Color;
    onClick?(): void;
}

export const NavigationItemContainer: FC<NavigationItemContainerProps> = ({
    children,
    onClick,
    collapsed,
    ...rest
}) => {
    return (
        <Box
            rounded={8}
            row
            gap={8}
            paddingHorizontal={16}
            paddingVertical={8}
            height={36}
            onClick={onClick}
            justify={collapsed ? 'center' : 'flex-start'}
            cursor={onClick ? 'pointer' : 'default'}
            {...rest}
        >
            {children}
        </Box>
    );
};
