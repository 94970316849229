import React, { FC } from 'react';
import { Switch } from 'react-router-dom';

import { SessionContext, useAuth, useSessionState } from '@hofy/api-admin';
import { AuthError } from '@hofy/api-auth';
import { ForbiddenPage, hasAdminPermission, isGoogleLogin, OutdatedVersionModal } from '@hofy/auth';
import { APP_CONFIG } from '@hofy/config';
import { PermissionContext } from '@hofy/permission';
import { Box, Modals } from '@hofy/ui';

import { AdminNavLink } from '../components/routing/AdminNavLink';
import { AdminRoute } from '../components/routing/AdminRoute';
import { DefaultRoute } from '../components/routing/DefaultRoute';
import { AccountingRouter } from '../views/accountingPage/AccountingRouter';
import { ActivityLogsRouter } from '../views/activityLogsPage/ActivityLogsRouter';
import { AssignmentsRouter } from '../views/assignmentsPage/AssignmentsRouter';
import { AuditLogsRouter } from '../views/auditLogsPage/AuditLogsRouter';
import { ContractsRouter } from '../views/contractsPage/ContractsRouter';
import { InspectionsRouter } from '../views/inspectionsPage/InspectionsRouter';
import { InventoryRouter } from '../views/inventoryPage/InventoryRouter';
import { InvoicingRouter } from '../views/invoicingPage/InvoicingRouter';
import { IssuesRouter } from '../views/issuesPage/IssuesRouter';
import { ItemsRouter } from '../views/itemsPage/ItemsRouter';
import { JobsRouter } from '../views/jobsPage/JobsRouter';
import { NotificationsRouter } from '../views/notificationsPage/NotificationsRouter';
import { OrganizationsRouter } from '../views/organizationsPage/OrganizationsRouter';
import { PeopleRouter } from '../views/peoplePage/PeopleRouter';
import { ProductsRouter } from '../views/productsPage/ProductsRouter';
import { RepairsRouter } from '../views/repairsPage/RepairsRouter';
import { OrdersRouter } from '../views/requestsPage/OrdersRouter';
import { SettingsRouter } from '../views/settingsPage/SettingsRouter';
import { ShipmentsRouter } from '../views/shipmentsPage/ShipmentsRouter';
import { AdminNavigation } from './navigation/AdminNavigation';

export const AdminRouter: FC = () => {
    const { signOut } = useAuth();
    const sessionState = useSessionState();

    if (!sessionState.isInitialized || !sessionState.session) {
        return null;
    }

    if (!hasAdminPermission(sessionState.permissions.permissions)) {
        return <ForbiddenPage reason={AuthError.NoRoles} onSignOut={signOut} />;
    }

    if (APP_CONFIG.env !== 'e2e' && !isGoogleLogin(sessionState.session.provider)) {
        return <ForbiddenPage reason={AuthError.RequiredSsoLogin} onSignOut={signOut} />;
    }

    const handleBackToHofy = () => {
        window.location.href = APP_CONFIG.hofyUrl!;
    };

    return (
        <>
            <DefaultRoute session={sessionState.permissions} />
            <PermissionContext.Provider value={sessionState.permissions}>
                <SessionContext.Provider value={sessionState.session}>
                    <AdminNavigation onSignOut={signOut} onBackToHofy={handleBackToHofy} />
                    <Box flex='auto' column relative fullHeight>
                        <Switch>
                            <AdminRoute path={AdminNavLink.Assignments} component={AssignmentsRouter} />
                            <AdminRoute path={AdminNavLink.Products} component={ProductsRouter} />
                            <AdminRoute path={AdminNavLink.Organizations} component={OrganizationsRouter} />
                            <AdminRoute path={AdminNavLink.Shipments} component={ShipmentsRouter} />
                            <AdminRoute path={AdminNavLink.Inspections} component={InspectionsRouter} />
                            <AdminRoute path={AdminNavLink.Issues} component={IssuesRouter} />
                            <AdminRoute path={AdminNavLink.Inventory} component={InventoryRouter} />
                            <AdminRoute path={AdminNavLink.Items} component={ItemsRouter} />
                            <AdminRoute path={AdminNavLink.Repairs} component={RepairsRouter} />
                            <AdminRoute path={AdminNavLink.People} component={PeopleRouter} />
                            <AdminRoute path={AdminNavLink.Orders} component={OrdersRouter} />
                            <AdminRoute path={AdminNavLink.Contracts} component={ContractsRouter} />
                            <AdminRoute path={AdminNavLink.AuditLogs} component={AuditLogsRouter} />
                            <AdminRoute path={AdminNavLink.ActivityLogs} component={ActivityLogsRouter} />
                            <AdminRoute path={AdminNavLink.Invoicing} component={InvoicingRouter} />
                            <AdminRoute path={AdminNavLink.Accounting} component={AccountingRouter} />
                            <AdminRoute path={AdminNavLink.Settings} component={SettingsRouter} />
                            <AdminRoute path={AdminNavLink.Notifications} component={NotificationsRouter} />
                            <AdminRoute path={AdminNavLink.Jobs} component={JobsRouter} />
                        </Switch>
                    </Box>
                </SessionContext.Provider>
            </PermissionContext.Provider>
            <Modals>
                <OutdatedVersionModal />
            </Modals>
        </>
    );
};
