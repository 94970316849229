import React, { FC } from 'react';

import { StockLevelCountsDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Divider, Icon, LabeledText, Link, Paragraph2, Paragraph3, SvgIcon } from '@hofy/ui';

import { getPurchaseOrderLink } from '../../../../components/routing/adminLinks';
import { AssignmentPendingLink } from '../../../assignmentsPage/components/AssignmentPendingLink';

interface StockLevelTooltipProps {
    stockLevel: StockLevelCountsDto;
    sku: string;
}

export const StockLevelTooltip: FC<StockLevelTooltipProps> = ({ sku, stockLevel }) => {
    return (
        <Box width={500}>
            <Paragraph2 bold color={Color.ContentInvertedPrimary}>
                {stockLevel.warehouse.name}
            </Paragraph2>
            <Paragraph3 marginTop={16} color={Color.ContentInvertedPrimary}>
                Summary
            </Paragraph3>
            <Divider marginVertical={8} />
            <Box row gap={16}>
                <LabeledText label='In warehouse' content={stockLevel.inWarehouse} invertedColor />
                <LabeledText
                    label='Open orders'
                    content={
                        stockLevel.openAssignments.total > 0 ? (
                            <AssignmentPendingLink search={sku}>
                                {stockLevel.openAssignments.total}
                            </AssignmentPendingLink>
                        ) : (
                            stockLevel.openAssignments.total
                        )
                    }
                    invertedColor
                />
                <LabeledText label='Available' content={stockLevel.available} invertedColor />
                <LabeledText label='Minimum' content={stockLevel.minQuantity} invertedColor />
            </Box>
            <Paragraph3 marginTop={16} color={Color.ContentInvertedPrimary}>
                Open orders
            </Paragraph3>
            <Divider marginVertical={8} />
            <Box row gap={16}>
                <LabeledText
                    label='Ship today'
                    content={stockLevel.openAssignments.scheduledToday.total}
                    invertedColor
                />
                <LabeledText
                    label='Ship after today'
                    content={stockLevel.openAssignments.scheduledInFuture.total}
                    invertedColor
                />
                <LabeledText label='On hold' content={stockLevel.openAssignments.onHold} invertedColor />
                <LabeledText label='Backorder' content={stockLevel.openAssignments.backorder} invertedColor />
                <LabeledText
                    label='No shipment created'
                    content={stockLevel.openAssignments.noShipment}
                    invertedColor
                />
            </Box>
            <Paragraph3 marginTop={16} color={Color.ContentInvertedPrimary}>
                Stock availability
            </Paragraph3>
            <Divider marginVertical={8} />
            <Box row gap={16}>
                <LabeledText
                    label='After shipped today'
                    content={stockLevel.openAssignments.scheduledToday.availableStock}
                    invertedColor
                />
                <LabeledText
                    label='After all scheduled shipments'
                    content={stockLevel.openAssignments.scheduledInFuture.availableStock}
                    invertedColor
                />
            </Box>
            {stockLevel && !!stockLevel.purchaseOrders.length && (
                <>
                    <Paragraph3 marginTop={16} color={Color.ContentInvertedPrimary}>
                        Incoming purchase orders
                    </Paragraph3>
                    <Divider marginVertical={8} />
                    <Box column gap={6}>
                        <Paragraph3 row color={Color.ContentInvertedSecondary}>
                            <Box width={120}>Delivery date</Box>
                            <Box width={80}>Quantity</Box>
                            <Box width={200}>Supplier</Box>
                            <Box width={100} />
                        </Paragraph3>
                        {stockLevel.purchaseOrders.map(order => (
                            <Box row key={order.id}>
                                <Paragraph3 row color={Color.ContentInvertedSecondary}>
                                    <Box width={120}>{formatDate(order.deliveryOn)}</Box>
                                    <Box width={80}>{order.quantity}</Box>
                                    <Box width={200}>{order.supplier}</Box>
                                    <Box width={100} justify='flex-end'>
                                        <Link to={getPurchaseOrderLink(order.id)} inline>
                                            <Box row gap={4}>
                                                <Icon
                                                    svg={SvgIcon.Link}
                                                    size={14}
                                                    color={Color.ContentInvertedSecondary}
                                                />
                                                PO details
                                            </Box>
                                        </Link>
                                    </Box>
                                </Paragraph3>
                            </Box>
                        ))}
                    </Box>
                </>
            )}
        </Box>
    );
};
