export const userErrorI18n = {
    'api-error.email-exists.title': 'Another user with this email already exists',
    'api-error.email-exists.message': 'Cannot perform this operation',
    'api-error.user-not-offboarded.title': 'User is not offboarded',
    'api-error.user-not-offboarded.message': 'Cannot perform this operation',
    'api-error.user-bound-to-active-assignment.title': 'User is still bound to an active assignment',
    'api-error.user-bound-to-active-assignment.message': 'Cannot perform this operation',
    'api-error.invalid-file-for-users-upload.title': 'Invalid file for users upload',
    'api-error.invalid-file-for-users-upload.message': 'Cannot perform this operation',
    'api-error.invalid-sheets-count-for-users-upload.title': 'Invalid sheets count for users upload',
    'api-error.invalid-sheets-count-for-users-upload.message': 'Cannot perform this operation',
    'api-error.invalid-header-for-users-upload.title': 'Invalid headers for users upload',
    'api-error.invalid-header-for-users-upload.message': 'Cannot perform this operation',
    'api-error.missing-team.title': 'Missing team',
    'api-error.missing-team.message': 'Cannot perform this operation',
    'api-error.missing-first-name.title': 'Missing first name',
    'api-error.missing-first-name.message': 'Cannot perform this operation',
    'api-error.missing-last-name.title': 'Missing last name',
    'api-error.missing-last-name.message': 'Cannot perform this operation',
    'api-error.missing-emails.title': 'Missing emails',
    'api-error.missing-emails.message': 'Cannot perform this operation',
    'api-error.missing-country.title': 'Missing country',
    'api-error.missing-country.message': 'Cannot perform this operation',
    'api-error.invalid-team.title': 'Invalid team',
    'api-error.invalid-team.message': 'Cannot perform this operation',
    'api-error.invalid-login-email-type.title': 'Invalid login email type',
    'api-error.invalid-login-email-type.message': 'Cannot perform this operation',
    'api-error.invalid-country.title': 'Invalid country',
    'api-error.invalid-country.message': 'Cannot perform this operation',
    'api-error.invalid-new-joiner-start-date.title': 'Invalid new joiner start date',
    'api-error.invalid-new-joiner-start-date.message': 'Cannot perform this operation',
    'api-error.email-exist.title': 'Email exists',
    'api-error.email-exist.message': 'Cannot perform this operation',
    'api-error.invalid-merge-request.title': 'Invalid merge request',
    'api-error.invalid-merge-request.message': 'Cannot perform this operation',
    'api-error.invalid-start-date.title': 'Invalid start date',
    'api-error.invalid-start-date.message': 'Cannot perform this operation',
    'api-error.duplicate-user.title': 'Duplicate user',
    'api-error.duplicate-user.message': 'Cannot perform this operation',
};
