import {
    BillingEntityRef,
    ContractRefDto,
    HofySubsidiaryRef,
    InvoiceEntryType,
    InvoiceRefDto,
    RepaymentPlanRefDto,
    TaxStatus,
} from '@hofy/api-shared';
import { DateString, DateTimeString, Percent, Price, UUID } from '@hofy/global';

export interface InvoiceEntryDto {
    id: UUID;
    ref: number;
    type: InvoiceEntryType;
    contract: ContractRefDto | null;
    shipmentId: UUID | null;
    invoiceTime: DateString;
    invoicePeriodFrom: DateString | null;
    invoicePeriodTo: DateString | null;
    description: string;
    quantity: number;
    isInvoiced: boolean;
    isQuote: boolean;
    isDeleted: boolean;
    invoice: InvoiceRefDto | null;
    unitPrice: Price;
    price: Price;
    total: Price;
    totalGross: Price;
    discount: Percent;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityRef;
    repaymentPlan: RepaymentPlanRefDto | null;
    instalment: {
        reference: string | null;
        commissionRate: string | null;
        commission: Price | null;
    };
    tax: TaxDto;
}

export interface TaxDto {
    rate: Percent;
    amount: Price;
    predictionRate: Percent | null;
    predictionAmount: Price | null;
    code: string;
    status: TaxStatus;
    computedAt: DateTimeString | null;
    errorAt: DateTimeString | null;
    predictionAt: DateTimeString | null;
    errorReason: string | null;
    reportedAt: DateTimeString | null;
    reportErrorAt: DateTimeString | null;
}

export const getTaxRate = (tax: {
    status: TaxStatus;
    rate: Percent;
    predictionRate: Percent | null;
}): Percent => {
    return tax.status === TaxStatus.Prediction ? tax.predictionRate! : tax.rate;
};
