import React, { FC, useMemo } from 'react';

import { allSubRegions, countriesToFullRegions, Country, removeRegionsFromCountries } from '@hofy/global';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { Paragraph3 } from '../../../base/Typography';
import { Icon } from '../../../icon/Icon';
import { SvgIcon } from '../../../icon/SvgIcon';
import { FieldContainer } from '../../shared/FieldContainer';

interface CountryRegionButtonProps {
    isReadonly: boolean;
    onOpen(): void;
    placeholder?: string;
    countries: Country[];
}

export const CountryRegionButton: FC<CountryRegionButtonProps> = ({
    countries,
    onOpen,
    placeholder,
    isReadonly,
}) => {
    if (isReadonly) {
        return (
            <Paragraph3 flex='auto' marginRight={8} textNoWrap ellipsis overflow='hidden'>
                <CountryRegionLabel placeholder={placeholder} countries={countries} />
            </Paragraph3>
        );
    }

    return (
        <FieldContainer pointer onClick={onOpen} row paddingHorizontal={16}>
            <Paragraph3 flex='auto' marginRight={8} textNoWrap ellipsis overflow='hidden'>
                <CountryRegionLabel countries={countries} />
            </Paragraph3>
            <Icon svg={SvgIcon.Edit} color={Color.ContentSecondary} />
        </FieldContainer>
    );
};

interface CountryRegionLabelProps {
    countries: Country[];
    placeholder?: string;
}

export const CountryRegionLabel: FC<CountryRegionLabelProps> = ({ countries, placeholder }) => {
    const trRegion = useTrRegion();
    const trCountry = useTrCountry();

    const label = useMemo(() => {
        const fullRegions = countriesToFullRegions(countries);
        const singleLeftovers = removeRegionsFromCountries(countries, fullRegions);
        if (fullRegions.length === allSubRegions.length) {
            return 'Globally';
        }
        if (fullRegions.length > 1) {
            return `${trRegion(fullRegions[0])} +${fullRegions.length - 1}`;
        }
        if (fullRegions.length === 1 && singleLeftovers.length === 0) {
            return `${trRegion(fullRegions[0])}`;
        }
        if (fullRegions.length === 1) {
            return `${trRegion(fullRegions[0])} +${singleLeftovers.length}`;
        }
        if (countries.length > 1) {
            return `${trCountry(countries[0])} +${countries.length - 1}`;
        }
        if (countries.length === 1) {
            return `${trCountry(countries[0])}`;
        }
        return placeholder;
    }, [countries]);

    return <>{label}</>;
};
