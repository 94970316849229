import { getEnumValues } from '@hofy/global';

export enum UpdateOtdReason {
    CustomerRequest = 'customer_request',
    BulkOrder = 'bulk_order',
    Supplier = 'supplier',
    Inventory = 'inventory',
    CustomToOrder = 'custom_to_order',
}

export const allUpdateOtdReasons = getEnumValues<UpdateOtdReason>(UpdateOtdReason);
