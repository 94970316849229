import React, { FC } from 'react';

import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, Box } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface CollectionDatesResultProps {
    userAvailability: DateString[];
}

export const CollectionDatesResult: FC<CollectionDatesResultProps> = ({ userAvailability }) => {
    return (
        <SurveyResultsSectionCardChip
            title='Collection dates'
            status={SurveyResultsSectionCardChipStatus.Neutral}
        >
            <Box row gap={8} wrap>
                {userAvailability.map(date => (
                    <Badge key={date} color='violet' label={formatDate(date)} />
                ))}
            </Box>
        </SurveyResultsSectionCardChip>
    );
};
