import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { JobContext } from '@hofy/api-admin';
import { pathUuid, UUID } from '@hofy/global';
import { getEnumParam, getStringParam, UUIDRoute } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { PurchaseOrderTab } from '../../../store/purchaseOrders/types/PurchaseOrderTab';
import { CreatePurchaseOrderSlideout } from './purchaseOrderCreateEditSlideout/CreatePurchaseOrderSlideout';
import { UpdatePurchaseOrderSlideout } from './purchaseOrderCreateEditSlideout/UpdatePurchaseOrderSlideout';
import { PurchaseOrderDetailsOverlay } from './purchaseOrderDetailsOverlay/PurchaseOrderDetailsOverlay';
import { PurchaseOrdersPage } from './PurchaseOrdersPage';

interface PurchaseOrderDetailsRouteProps {
    path?: string;
    children(v: { purchaseOrderTab: PurchaseOrderTab }): ReactNode;
}

const PurchaseOrderDetailsRoute: FC<PurchaseOrderDetailsRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const purchaseOrderId = getStringParam(p.match?.params || {}, 'purchaseOrderId') as UUID;
        const purchaseOrderTab = getEnumParam<PurchaseOrderTab>(
            p.match?.params || {},
            'purchaseOrderTab',
            PurchaseOrderTab,
        );

        if (purchaseOrderId && purchaseOrderTab) {
            return children({
                purchaseOrderTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};

export const PurchaseOrdersRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();

    const handleOpenNewPurchaseOrder = () => {
        history.push(`${path}/new`);
    };

    const handleOpenPurchaseOrder = (id: UUID) => {
        history.push(`${path}/${id}/${PurchaseOrderTab.Details}`);
    };

    const handleOpenEditPurchaseOrder = (id: UUID) => {
        history.push(`${path}/${id}/${PurchaseOrderTab.Details}/edit`);
    };

    const handleChangeDetailsTab = (id: UUID, tab: PurchaseOrderTab) => {
        history.push(`${path}/${id}/${tab}`);
    };

    const handleOpenJobs = () => {
        history.push(`${AdminNavLink.Jobs}?context=${JobContext.PurchaseOrders}`);
    };

    const handleListPurchaseOrders = () => {
        history.push(`${path}`);
    };

    return (
        <Switch>
            <PurchaseOrderRoute path={`${path}/:purchaseOrderId(${pathUuid})`}>
                {({ purchaseOrderId }) => (
                    <>
                        <PurchaseOrderDetailsRoute
                            path={`${path}/:purchaseOrderId(${pathUuid})/:purchaseOrderTab`}
                        >
                            {({ purchaseOrderTab }) => (
                                <PurchaseOrderDetailsOverlay
                                    purchaseOrderId={purchaseOrderId}
                                    purchaseOrderTab={purchaseOrderTab}
                                    onChangeDetailsTab={tab => handleChangeDetailsTab(purchaseOrderId, tab)}
                                    handleOpenEditPurchaseOrder={() =>
                                        handleOpenEditPurchaseOrder(purchaseOrderId)
                                    }
                                    onDeleted={handleListPurchaseOrders}
                                />
                            )}
                        </PurchaseOrderDetailsRoute>
                        <Route
                            path={`${path}/:purchaseOrderId(${pathUuid})/${PurchaseOrderTab.Details}/edit`}
                        >
                            <UpdatePurchaseOrderSlideout
                                purchaseOrderId={purchaseOrderId}
                                onClose={() => handleOpenPurchaseOrder(purchaseOrderId)}
                            />
                        </Route>
                    </>
                )}
            </PurchaseOrderRoute>
            <Route exact path={`${path}/new`}>
                <PurchaseOrdersPage
                    onOpenPurchaseOrder={handleOpenPurchaseOrder}
                    onNewPurchaseOrder={handleOpenNewPurchaseOrder}
                    onOpenJobs={handleOpenJobs}
                />
                <CreatePurchaseOrderSlideout
                    onClose={handleListPurchaseOrders}
                    onDraftSuccess={handleOpenEditPurchaseOrder}
                />
            </Route>
            <Route path={path}>
                <PurchaseOrdersPage
                    onOpenPurchaseOrder={handleOpenPurchaseOrder}
                    onNewPurchaseOrder={handleOpenNewPurchaseOrder}
                    onOpenJobs={handleOpenJobs}
                />
            </Route>
        </Switch>
    );
};

const PurchaseOrderRoute = UUIDRoute('purchaseOrderId');
