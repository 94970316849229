import { Optional } from '@tanstack/react-query';
import React, { FC } from 'react';

import { Country } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks/form/formTypes';
import { LabeledCountryRegion, LabeledCountryRegionProps } from './LabeledCountryRegion';

type FormAvailabilityOptionalProps = Optional<LabeledCountryRegionProps, 'onChange'>;
type FormAvailabilityOmittedProps = Omit<FormAvailabilityOptionalProps, 'errorMessage' | 'value'>;

interface FormCountryRegionSelectProps extends FormAvailabilityOmittedProps {
    api: FormFieldApi<Country[]>;
}

export const FormCountryRegionSelect: FC<FormCountryRegionSelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledCountryRegion
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
