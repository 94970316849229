import { isEqual } from 'lodash';
import React, { ReactNode } from 'react';

import { NumberValues } from '@hofy/theme';
import { BoxProps, TestKeyAware } from '@hofy/ui';

import { SeparatedContainer } from '../layout/SeparatedContainer';
import { Radio } from './Radio';

interface RadioGroupProps<T> extends BoxProps, TestKeyAware {
    spacing?: NumberValues;
    items: T[];
    value: T | null | undefined;
    orientation?: 'horizontal' | 'vertical';
    labelFormatter(v: T): ReactNode;
    onChange(v: T): void;
    disabled?: boolean;
}

/** @deprecated not yet implemented in `@hofy/ui` */
export const RadioGroup = <T extends any>({
    spacing = 8,
    value,
    labelFormatter,
    items,
    onChange,
    orientation,
    disabled,
    testKey,
    ...props
}: RadioGroupProps<T>) => {
    return (
        <SeparatedContainer spacing={spacing} orientation={orientation} {...props}>
            {items.map((v, index) => (
                <Radio
                    disabled={disabled}
                    checked={isEqual(v, value)}
                    key={index}
                    onChange={() => onChange(v)}
                    testKey={testKey}
                >
                    {labelFormatter(v)}
                </Radio>
            ))}
        </SeparatedContainer>
    );
};
