import React, { FC } from 'react';

import { DataErasureStatus, dataErasureStatusColor } from '@hofy/api-shared';
import { getColorFromColorDye } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { useTrAssignmentDataErasureStatus } from '../../../../store/assignments/useTrAssignmentDataErasureStatus';
import { Indicator } from '../../../design/indicator/Indicator';

interface DataErasureIndicatorProps {
    dataErasureStatus: DataErasureStatus;
}

export const DataErasureIndicator: FC<DataErasureIndicatorProps> = ({ dataErasureStatus }) => {
    const color = getColorFromColorDye(dataErasureStatusColor[dataErasureStatus], 'main');
    const trStatus = useTrAssignmentDataErasureStatus();
    return (
        <Indicator color={color}>
            <TooltipIcon
                icon={SvgIcon.Server}
                iconColor={color}
                body={trStatus(dataErasureStatus)}
                iconSize={16}
            />
        </Indicator>
    );
};
