import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import { Color, NumberValues } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Box } from '../base';
import { Skeleton } from '../loader';
import { Cell } from './cells/Cell';
import { BaseTableColumnConfig } from './types/BaseTableColumnConfig';

interface BaseTableRowSkeletonProps<T> {
    columns: BaseTableColumnConfig<T>[];
    isFirst: boolean;
    rowVerticalPadding: NumberValues;
    rowHorizontalPadding: NumberValues;
}

export const BaseTableRowSkeleton = <T extends any>({
    columns,
    isFirst,
    rowVerticalPadding,
    rowHorizontalPadding,
}: BaseTableRowSkeletonProps<T>) => {
    return (
        <RowBoxWrapper isFirst={isFirst} hoverable={false} paddingHorizontal={rowHorizontalPadding}>
            <RowInner row>
                {columns.map((column, index) => (
                    <Cell
                        key={index}
                        width={column.width}
                        flex={column.flexGrow}
                        rowVerticalPadding={rowVerticalPadding}
                        shrink={column.flexShrink}
                    >
                        <Skeleton width='70%' height={20} />
                    </Cell>
                ))}
            </RowInner>
        </RowBoxWrapper>
    );
};

interface BaseTableRowProps<T extends any> extends TestKeyAware {
    columns: BaseTableColumnConfig<T>[];
    item: T;
    rowIndex: number;
    hoverable: boolean;
    rowVerticalPadding: NumberValues;
    rowHorizontalPadding: NumberValues;
    onRowClick?(v: T): void;
    styles?: CSSProperties;
}

export const BaseTableRow = <T extends any>({
    item,
    rowIndex,
    columns,
    onRowClick,
    testKey,
    hoverable,
    rowVerticalPadding,
    rowHorizontalPadding,
    styles = {},
}: BaseTableRowProps<T>) => {
    return (
        <RowBoxWrapper
            hoverable={hoverable}
            onClick={() => onRowClick?.(item)}
            pointer={hoverable}
            isFirst={rowIndex === 0}
            data-test-key={testKey}
            paddingHorizontal={rowHorizontalPadding}
            style={styles}
        >
            <RowInner row data-test-key='table-row'>
                {columns.map((column, index) => {
                    const CellRenderer = column.CellRenderer || Cell;
                    return (
                        <CellRenderer
                            key={index}
                            width={column.width}
                            flex={column.flexGrow}
                            shrink={column.flexShrink}
                            rowVerticalPadding={rowVerticalPadding}
                        >
                            {column.renderer(item, rowIndex)}
                        </CellRenderer>
                    );
                })}
            </RowInner>
        </RowBoxWrapper>
    );
};

const RowInner = styled(Box)``;

export const RowAutoHide = styled.div``;

const RowBoxWrapper = styled(Box)<{
    isLoading?: boolean;
    isFirst: boolean;
    hoverable: boolean;
    paddingHorizontal?: number;
}>`
    border-radius: 10px;
    ${RowInner} {
        border-top: 1px solid ${p => (p.isFirst ? Color.Transparent : Color.NonContextualBorderDivider)};
    }
    ${p =>
        p.hoverable &&
        `:hover {
            background-color: ${Color.BackgroundSubtleNeutral};
            ${RowInner} {
                border-top-color: ${Color.Transparent};
            }
        }
        :hover + :nth-child(n) ${RowInner} {
            border-top-color: ${Color.Transparent};
        }`}

    ${RowAutoHide} {
        visibility: hidden;
    }

    :hover ${RowAutoHide} {
        visibility: visible;
    }
`;
