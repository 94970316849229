import React, { FC } from 'react';

import { useCancelSalesOrder, useSalesOrderQuery } from '@hofy/api-admin';
import { Permission, SalesOrderStatus } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Spinner, useToast } from '@hofy/ui';

interface SalesOrderDetailsPageMenuProps {
    salesOrderId: UUID;
}

export const SalesOrderDetailsPageMenu: FC<SalesOrderDetailsPageMenuProps> = ({ salesOrderId }) => {
    const { hasPermission } = usePermission();

    const { showToast } = useToast();
    const { data: salesOrder, isLoading } = useSalesOrderQuery(salesOrderId);
    const { cancelOrder, isLoading: isMutationLoading } = useCancelSalesOrder(salesOrderId, () =>
        showToast({
            type: 'positive',
            message: 'Sales order cancelled successfully',
        }),
    );

    if (isMutationLoading || isLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: cancelOrder,
                    label: 'Cancel order',
                    visible:
                        hasPermission(Permission.AdminSalesOrdersCancel) &&
                        salesOrder?.status === SalesOrderStatus.Created,
                },
            ]}
        />
    );
};
