import React, { FC } from 'react';

import { UpdateOrganizationFinancialSettingsPayload } from '@hofy/api-admin';
import {
    bundledPlatformTiers,
    PlatformTier,
    PlatformTierConfigDto,
    PlatformTierConfigFeaturesMap,
    unbundledPlatformTiers,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import {
    FormGridRow,
    FormSection,
    LabeledCheckbox,
    LabeledNumberInput,
    LabeledSelect,
    Paragraph3,
    UseForm,
} from '@hofy/ui';

import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { useTrPlatformTier } from '../../../../store/organizations/useTrPlatformTier';

interface PlatformTierConfigFormSectionProps {
    form: UseForm<UpdateOrganizationFinancialSettingsPayload>;
    platformTierConfigFeatures: PlatformTierConfigFeaturesMap;
}

export const PlatformTierFormSection: FC<PlatformTierConfigFormSectionProps> = ({
    form,
    platformTierConfigFeatures,
}) => {
    const { tr } = useAdminI18n();
    const trPlatformTier = useTrPlatformTier();
    const platformTierConfig = form.values.platformTierConfig;
    const handleStateChange = (value: Partial<PlatformTierConfigDto>) => {
        form.setValues({
            platformTierConfig: {
                ...platformTierConfig,
                ...value,
            },
        });
    };

    const handlePlatformTierChange = (platformTier: PlatformTier) => {
        handleStateChange({
            platformTier,
            ...platformTierConfigFeatures[platformTier],
        });
    };

    const isUnbundlingEnabled = form.values.unbundlingEnabled;
    const platformTierOptions = isUnbundlingEnabled ? unbundledPlatformTiers : bundledPlatformTiers;

    return (
        <FormSection label={tr('organization-page.financial-settings-update.modal.platform-tier-section')}>
            <FormRow>
                <LabeledSelect
                    label={tr('organization-page.financial-settings-update.modal.platform-tier-select')}
                    options={platformTierOptions}
                    onChange={handlePlatformTierChange}
                    value={platformTierConfig.platformTier}
                    toText={trPlatformTier}
                />
            </FormRow>

            <FormGridRow columns={4}>
                <LabeledNumberInput
                    label={tr('organization-page.financial-settings-update.modal.subsidiary-limit')}
                    nullable
                    onChange={value =>
                        handleStateChange({
                            billingEntityLimit: value || null,
                        })
                    }
                    value={platformTierConfig.billingEntityLimit || null}
                />
                <LabeledNumberInput
                    label={tr('organization-page.financial-settings-update.modal.team-limit')}
                    nullable
                    onChange={value =>
                        handleStateChange({
                            teamLimit: value || null,
                        })
                    }
                    value={platformTierConfig.teamLimit || null}
                />
                {isUnbundlingEnabled && (
                    <LabeledNumberInput
                        label={tr('organization-page.financial-settings-update.modal.device-limit')}
                        nullable
                        onChange={value =>
                            handleStateChange({
                                deviceTrackedLimit: value || null,
                            })
                        }
                        value={platformTierConfig.deviceTrackedLimit || null}
                    />
                )}

                {platformTierConfig.platformTier === PlatformTier.OrgBasedFree && (
                    <LabeledNumberInput
                        label={tr('organization-page.financial-settings-update.modal.laptop-limit')}
                        nullable
                        onChange={value =>
                            handleStateChange({
                                teamMemberEquipLimit: value || null,
                            })
                        }
                        value={platformTierConfig.teamMemberEquipLimit || null}
                    />
                )}
            </FormGridRow>

            <FormGridRow columns={4}>
                <LabeledCheckbox
                    label={<Paragraph3>SAML</Paragraph3>}
                    checked={platformTierConfig.samlEnabled}
                    onChange={value =>
                        handleStateChange({
                            samlEnabled: value,
                        })
                    }
                />
                <LabeledCheckbox
                    label={<Paragraph3>Auto approval</Paragraph3>}
                    checked={platformTierConfig.autoApprovalEnabled}
                    onChange={value =>
                        handleStateChange({
                            autoApprovalEnabled: value,
                        })
                    }
                />
                <LabeledCheckbox
                    label={<Paragraph3>HRIS Import Rules</Paragraph3>}
                    checked={platformTierConfig.hrisAutoImportEnabled}
                    onChange={value =>
                        handleStateChange({
                            hrisAutoImportEnabled: value,
                        })
                    }
                />
                <LabeledCheckbox
                    label={<Paragraph3>Manager only organisation</Paragraph3>}
                    checked={platformTierConfig.managerOnlyOrganizationEnabled}
                    onChange={managerOnlyOrganizationEnabled =>
                        handleStateChange({
                            managerOnlyOrganizationEnabled,
                        })
                    }
                />
                {!isUnbundlingEnabled && (
                    <LabeledCheckbox
                        label={<Paragraph3>5 Free Licenses</Paragraph3>}
                        checked={platformTierConfig.freeLicensesLimit > 0}
                        onChange={value =>
                            handleStateChange({
                                freeLicensesLimit: value ? 5 : 0,
                            })
                        }
                    />
                )}
            </FormGridRow>
        </FormSection>
    );
};
