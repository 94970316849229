import { stringifyUrl } from 'query-string';

import { PageRequest, PageResponse, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AssignInspectionAdminPayload } from './types/AssignInspectionAdminPayload';
import { AssignInspectionHofyWarehouseBinPayload } from './types/AssignInspectionHofyWarehouseBinPayload';
import { InspectionDetailsUnionDto } from './types/InspectionDetailsDto';
import { InspectionUnionDto } from './types/InspectionDto';
import { ListInspectionsFilter } from './types/ListInspectionsFilter';
import { UpdateInspectionDeviceCheckPayload } from './types/UpdateInspectionDeviceCheckPayload';
import { UpdateInspectionPayload } from './types/UpdateInspectionPayload';

class InspectionsService {
    public listInspections = (
        filters: ListInspectionsFilter,
        page: PageRequest,
    ): Promise<PageResponse<InspectionUnionDto>> => {
        return restClient.getJson<PageResponse<InspectionUnionDto>>(
            stringifyUrl({
                url: `/api/admin/inspections`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public getInspectionDetails = (uuid: UUID): Promise<InspectionDetailsUnionDto> => {
        return restClient.getJson<InspectionDetailsUnionDto>(`/api/admin/inspections/${uuid}`);
    };

    public assignInspectionAdmin = async (
        uuid: UUID,
        payload: AssignInspectionAdminPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/assign-admin`, payload);
    };

    public assignInspectionHofyWarehouseBin = async (
        uuid: UUID,
        payload: AssignInspectionHofyWarehouseBinPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/assign-bin`, payload);
    };

    public startInspection = async (uuid: UUID): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/start`);
    };

    public updateInspection = async (uuid: UUID, payload: UpdateInspectionPayload): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}`, payload);
    };

    public completeInspection = async (uuid: UUID, payload: UpdateInspectionPayload): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/complete`, payload);
    };

    public updateInspectionDeviceActivationLockRemovalStatus = async (
        uuid: UUID,
        payload: UpdateInspectionDeviceCheckPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/device-check/activation-lock`, payload).then();
    };

    public updateInspectionDeviceUserAccountRemovalStatus = async (
        uuid: UUID,
        payload: UpdateInspectionDeviceCheckPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/device-check/user-account`, payload);
    };

    public updateInspectionDeviceOrgMDMRemovalStatus = async (
        uuid: UUID,
        payload: UpdateInspectionDeviceCheckPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/device-check/org-mdm`, payload);
    };

    public updateInspectionDevicePinLockRemovalStatus = async (
        uuid: UUID,
        payload: UpdateInspectionDeviceCheckPayload,
    ): Promise<void> => {
        return restClient.put(`/api/admin/inspections/${uuid}/device-check/pin-lock`, payload);
    };
}

export const inspectionService = new InspectionsService();
