import { AdminPayload } from '@hofy/api-admin';
import { isRequired, useForm, validator } from '@hofy/ui';

export const useAdminForm = (
    initial: AdminPayload,
    onSubmit: (payload: AdminPayload) => void,
    onDiscard?: () => void,
) => {
    return useForm<AdminPayload>({
        initial,
        onSubmit(p: AdminPayload) {
            onSubmit(p);
        },
        onDiscard,
        validate: validator<AdminPayload>({
            firstName: isRequired('First name should not be empty'),
            lastName: isRequired('Last name should not be empty'),
            email: isRequired('Email should not be empty'),
        }),
    });
};
