import { getEnumValues } from '@hofy/global';

export enum ItemSlideoutTab {
    Details = 'details',
    Assignments = 'assignments',
    Inspections = 'inspections',
    Repairs = 'repairs',
    Billing = 'billing',
    FixedAssets = 'fixed-assets',
    StatusHistory = 'status-history',
}

export const allItemSlideoutTabs = getEnumValues<ItemSlideoutTab>(ItemSlideoutTab);
