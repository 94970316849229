import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react';

import { useScrollEnd } from '@hofy/hooks';
import { Box, BoxProps } from '@hofy/ui';

import { BlockLoader } from '../loader/Loader';

interface InfiniteScrollProps extends BoxProps {
    loadMore(): void;
    isLoadingMore: boolean;
    // undefined means first page
    hasMore?: boolean;
    children?: ReactNode;
}

export const InfiniteScroll = forwardRef<HTMLDivElement, InfiniteScrollProps>(
    ({ loadMore, isLoadingMore, hasMore, children, ...props }, forwardedRef) => {
        const callback = useCallback(() => {
            if (!isLoadingMore && hasMore !== false) {
                loadMore();
            }
        }, [isLoadingMore, hasMore, loadMore]);

        const ref = useRef<HTMLDivElement>(null);
        useImperativeHandle(forwardedRef, () => ref.current!);
        useScrollEnd(ref, callback);

        return (
            <Box ref={ref} {...props}>
                {children}
                {isLoadingMore && <BlockLoader marginVertical={30} />}
            </Box>
        );
    },
);
