import React, { FC } from 'react';

import { IconItemWithTooltip } from '@hofy/common';
import { Color } from '@hofy/theme';
import { SvgIcon } from '@hofy/ui';

export const CollectionUserCanUseDropOffCell: FC = () => {
    return (
        <IconItemWithTooltip tooltip={false} icon={SvgIcon.Asterisk} iconColor={Color.AccentYellow}>
            Can use dropoff point
        </IconItemWithTooltip>
    );
};
