import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { BoxProps, Icon, SvgIcon } from '@hofy/ui';

import { InvisibleFileInput } from '../form/InvisibleFileInput';
import { TileButtonWrapper } from './TileButton';

interface UploadFileTileButtonProps extends BoxProps {
    onChange(v: FileList): void;
    width: number;
}

export const UploadFileTileButton: FC<UploadFileTileButtonProps> = ({ width, onChange, ...margins }) => {
    return (
        <TileButtonWrapper
            relative
            as='button'
            overflow='hidden'
            paddingVertical={25}
            {...margins}
            width={width}
        >
            <Icon size={20} svg={SvgIcon.Add} color={Color.Neutral500} />
            <InvisibleFileInput accept='image/*' onChange={onChange} />
        </TileButtonWrapper>
    );
};
