import React, { FC, useEffect, useState } from 'react';

import { BYODOrderItemDto, useBYODOrderQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, ErrorStatePlaceholder } from '@hofy/ui';

import { useReceiveBYODOrderItems } from '../../../../store/byodOrders/useReceiveBYODOrderItems';
import { useWarehouseBinRequired } from '../hooks/useWarehouseBinRequired';
import { BYODOrderItemsScanSelect } from './BYODOrderItemsScanSelect';
import { BYODOrderItemsScanForm } from './itemScanForm/BYODOrderItemsScanForm';

interface ReceiveBYODOrderSlideoutProps {
    id: UUID;
    onClose(): void;
}

export const ReceiveBYODOrderSlideout: FC<ReceiveBYODOrderSlideoutProps> = ({ id, onClose }) => {
    const [byodOrderItem, setByodOrderItem] = useState<BYODOrderItemDto | null>(null);

    const { data: byodOrder, isLoading, isError } = useBYODOrderQuery(id);
    const { isWarehouseBinRequired, isLoading: isLoadingWarehouse } = useWarehouseBinRequired(
        byodOrder?.toWarehouse.id,
    );

    const {
        form,
        items,
        setScansLeft,
        isItemEmpty,
        isLoadingMutation: receiveBYODItemSubmitting,
        isErrorMutation: receiveBYODItemError,
    } = useReceiveBYODOrderItems({
        onSuccess: () => setByodOrderItem(null),
        byodOrderItem,
        isWarehouseBinRequired,
    });

    useEffect(() => {
        if (byodOrderItem) {
            form.setValues({
                byodOrderItemId: byodOrderItem.id,
                category: byodOrderItem.product.category,
            });
            setScansLeft(byodOrderItem.expectedQuantity - byodOrderItem.receivedQuantity);
        }
    }, [byodOrderItem]);

    const handleSave = () => {
        if (byodOrderItem) {
            form.submit();
            return;
        }
        onClose();
    };
    const handleCancel = () => {
        if (byodOrderItem) {
            form.reset();
            setByodOrderItem(null);
            return;
        }
        onClose();
    };

    if (isLoading || isLoadingWarehouse) {
        return <ComponentLoader />;
    }
    if (isError || !byodOrder || isWarehouseBinRequired === undefined) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Slideout width={1200} onClose={onClose}>
            <SlideoutHeader
                title={`Receive items at the warehouse for #${byodOrder.publicId}`}
                color={Color.ContentPrimary}
            />
            <SlideoutContent>
                {byodOrderItem ? (
                    <BYODOrderItemsScanForm
                        byodOrderItem={byodOrderItem}
                        items={items}
                        isItemEmpty={isItemEmpty}
                        warehouse={byodOrder.toWarehouse}
                        warehouseBinRequired={isWarehouseBinRequired}
                    />
                ) : (
                    <BYODOrderItemsScanSelect
                        byodOrderId={byodOrder.id}
                        setByodOrderItem={setByodOrderItem}
                    />
                )}
            </SlideoutContent>

            <SlideoutFooter fullWidth justify='space-between'>
                {byodOrderItem && <Button type='secondary' label='Cancel' onClick={handleCancel} />}
                <Box flex={1} />
                <AsyncButton
                    isLoading={receiveBYODItemSubmitting}
                    isError={receiveBYODItemError}
                    onClick={handleSave}
                    label={!byodOrderItem ? 'Close' : 'Save'}
                    marginLeft={20}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
