import { isValidEnumEntry } from '@hofy/global';

import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ContractType } from '../types/ContractType';
import { RentalContractType } from '../types/RentalContractType';
import { useTrRentalContractType } from './useTrRentalContractType';

export const useTrAnyContractType = () => {
    const trRental = useTrRentalContractType();
    const trType = useTrContractType();

    return (v: RentalContractType | ContractType) => {
        if (isValidEnumEntry(RentalContractType)(v)) {
            return trRental(v as RentalContractType);
        }
        return trType(v as ContractType);
    };
};

export const useTrContractType = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractType>(ContractType, 'contract-type');
};
