import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { AddonItem } from '../types/AddonItem';

export const useTrAddonItem = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<AddonItem>(AddonItem, 'addon-item');
};

export const useTrAddonItemDescription = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<AddonItem>(AddonItem, 'addon-item.description');
};
