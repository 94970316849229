import React from 'react';

import { CountryMultiplierDto } from '@hofy/api-admin';
import { CountryLabel } from '@hofy/core';
import { Color } from '@hofy/theme';
import { BaseTable, Box, Button, Form, FormInput, Paragraph3, SubmitButton } from '@hofy/ui';

import { useUpdateCountryMultipliers } from '../../../store/countryMultipliers/useUpdateCountryMultipliers';
import { ChangedBy } from './components/ChangedBy';

interface CountryMultipliersTableProps {
    countryMultipliers: CountryMultiplierDto[];
    canEdit: boolean;
    isLoading: boolean;
}

export const CountryMultipliersTable = ({
    countryMultipliers,
    canEdit,
    isLoading,
}: CountryMultipliersTableProps) => {
    const {
        form: { setValues, values, submit, reset, fields },
        isMutationLoading,
    } = useUpdateCountryMultipliers();

    const edit = (item: CountryMultiplierDto) => {
        setValues({
            country: item.country,
            multiplier: item.multiplier,
        });
    };

    return (
        <BaseTable
            data={countryMultipliers}
            toKey={data => data.country}
            hoverable={canEdit}
            onRowClick={item => {
                if (canEdit && item.country !== values.country) {
                    edit(item);
                }
            }}
            emptyContent={<Paragraph3>No country multipliers found</Paragraph3>}
            isLoading={isLoading}
            flex='auto'
            columns={[
                {
                    id: 'country',
                    header: 'Country',
                    flexGrow: 1,
                    renderer: data => <CountryLabel iconSize={25} country={data.country} textNoWrap />,
                },
                {
                    id: 'multiplier',
                    header: 'Multiplier',
                    flexGrow: 1.8,
                    renderer: data => {
                        if (data.country === values?.country) {
                            return (
                                <Form
                                    onSubmit={submit}
                                    isLoading={isMutationLoading}
                                    submitOnEnter
                                    row
                                    alignItems='flex-start'
                                    gap={10}
                                >
                                    <FormInput label='' api={fields.multiplier} autoFocus />
                                    <SubmitButton label='Save' />
                                    <Button type='secondary' label='Cancel' onClick={reset} />
                                </Form>
                            );
                        }
                        return (
                            <Box rounded padding={4} bg={Color.BackgroundSubtleNeutral}>
                                <Paragraph3>{data.multiplier}x</Paragraph3>
                            </Box>
                        );
                    },
                },
                {
                    id: 'changedBy',
                    header: 'Changed',
                    flexGrow: 0.8,
                    renderer: data => (
                        <ChangedBy
                            when={data.updatedAt || data.createdAt}
                            who={data.updatedBy || data.createdBy}
                        />
                    ),
                },
            ]}
        />
    );
};
