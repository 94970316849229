import { xor } from 'lodash';
import React, { FC } from 'react';

import { EmailType, useTrEmailType } from '@hofy/api-shared';
import { Box, LabeledCheckbox, Paragraph3, TestKeyAware } from '@hofy/ui';

interface EmailCheckBoxProps extends TestKeyAware {
    disabledEmailTypes: EmailType[];
    emailTypes: EmailType[];
    emailType: EmailType;
    onChange(v: EmailType[]): void;
    email: string;
}

export const EmailCheckBox: FC<EmailCheckBoxProps> = ({
    disabledEmailTypes,
    emailTypes,
    emailType,
    email,
    onChange,
    testKey,
}) => {
    const trEmailType = useTrEmailType();
    return (
        <Box column gap={4}>
            <Paragraph3>{trEmailType(emailType)}</Paragraph3>
            <LabeledCheckbox
                label={email}
                disabled={disabledEmailTypes.includes(emailType)}
                checked={emailTypes.includes(emailType)}
                onChange={() => onChange(xor([emailType], emailTypes))}
                testKey={testKey}
            />
        </Box>
    );
};
