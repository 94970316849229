import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { assignmentsCacheKey } from '../../assignment/store/cacheKey';
import { shipmentService } from '../service/shipmentService';

export const useShipmentQuery = (id: UUID | null) => {
    const { data, isPending, isLoading } = useQuery({
        queryKey: [assignmentsCacheKey, 'shipment', id],
        queryFn: () => shipmentService.getShipment(id!),
        enabled: !!id,
    });

    return {
        data,
        isPending,
        isLoading,
    };
};
