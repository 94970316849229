import { useRepaymentPlanQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useRepaymentPlan = (planId: UUID) => {
    const { data, isError, isLoading } = useRepaymentPlanQuery(planId);

    return {
        plan: data,
        isLoading,
        isError,
    };
};
