import { AdminUserFilters, emptyAdminUsersFilter } from '@hofy/api-admin';
import { OrganizationRefDto, Role, useTrRole } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti, useFilters } from '@hofy/ui';

import { useBooleanFilterDefinition } from '../filters/useBooleanFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useOrganizationFilterDefintion } from '../organizations/filters/useOrganizationFilterDefintion';

interface UseUserFiltersProps {
    defaultValues?: Partial<AdminUserFilters>;
    organizations: OrganizationRefDto[];
}
export const useUserFilters = (options: UseUserFiltersProps) => {
    const search = useSearchFilterDefinition();
    const isDeel = useBooleanFilterDefinition({
        name: 'Deel',
        toLabel: value => (value ? 'Yes' : 'No'),
    });
    const includeOffboarded = useBooleanFilterDefinition({
        name: 'Include offboarded',
        toLabel: value => (value ? 'Yes' : 'No'),
        showInActive: false,
    });
    const roles: FilterDefinitionMulti<Role> = {
        type: 'multi',
        name: 'Roles',
        query: queryStringArrayParser(),
        toLabel: useTrRole(),
    };
    const redistributableIn: FilterDefinitionMulti<Country> = {
        type: 'multi',
        name: 'Redistributable in',
        query: queryStringArrayParser(),
        toLabel: useTrCountry(),
    };
    const organizations = useOrganizationFilterDefintion('Organizations', options.organizations);
    return useFilters<AdminUserFilters>(
        {
            search,
            isDeel,
            roles,
            includeOffboarded,
            redistributableIn,
            organizations,
        },
        {
            ...emptyAdminUsersFilter,
            ...options?.defaultValues,
        },
    );
};
