import { capitalize } from 'lodash';
import React, { FC, useEffect } from 'react';

import { emptyAdminOrganizationsFilters } from '@hofy/api-admin';
import { allItemGrades, OrganizationStatus, Ownership } from '@hofy/api-shared';
import { Currency, zeroPrice } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Alert,
    Box,
    BoxProps,
    ExpandHeight,
    FormContainer,
    FormDateInput,
    FormPriceInput,
    LabeledInput,
    LabeledSelect,
    LabeledText,
    Paragraph3,
    UseForm,
} from '@hofy/ui';

import { FormOrganizationSelect } from '../../../../components/domain/organizations/FormOrganizationSelect';
import { WarehouseDropdown } from '../../../../components/domain/warehouses/WarehouseDropdown';
import { useBillingEntityForWarehouseCountry } from '../../../../store/invoicing/billingEntities/useBillingEntities';
import { CreateItemFormData, CreateItemFormDataErrors } from '../../../../store/items/useCreateItem';
import { useTrItemGrade } from '../../../../store/items/useTrItemGrade';

interface AddItemFormProps extends BoxProps {
    form: UseForm<CreateItemFormData, CreateItemFormDataErrors>;
}

export const AddItemForm: FC<AddItemFormProps> = ({ form, ...boxProps }) => {
    const trItemGrade = useTrItemGrade();

    const organizationBillingEntity = useBillingEntityForWarehouseCountry(
        form.values.organization,
        form.values.warehouseId ?? null,
    );
    useEffect(() => {
        if (!organizationBillingEntity) {
            return;
        }

        form.setValues({
            activationFee: zeroPrice(organizationBillingEntity.currency),
            monthlyFee: zeroPrice(organizationBillingEntity.currency),
        });
    }, [organizationBillingEntity]);

    return (
        <Box {...boxProps}>
            <FormContainer spacing={10} marginBottom={20}>
                <LabeledText label='Variant' content={form.values.variant?.style} />
                <LabeledText
                    label='Manufacture part number'
                    content={form.values.variant?.manufacturerPartCode}
                />
                <LabeledText label='SKU' content={form.values.variant?.sku} />
            </FormContainer>
            <FormContainer>
                <WarehouseDropdown
                    label='Warehouse'
                    onChange={warehouseId =>
                        form.setValues({
                            warehouseId,
                        })
                    }
                    value={form.values.warehouseId}
                    isError={form.errors.warehouseId}
                    isRequired
                />
                <LabeledSelect
                    label='Ownership'
                    options={[Ownership.Hofy, Ownership.Organization]}
                    value={form.values.ownership}
                    onChange={ownership =>
                        form.setValues({
                            ownership,
                        })
                    }
                    toText={capitalize}
                    isRequired
                />
                <ExpandHeight>
                    {form.values.ownership === Ownership.Organization && (
                        <Box
                            column
                            gap={10}
                            paddingHorizontal={20}
                            paddingVertical={20}
                            rounded={8}
                            bg={Color.InteractionNeutralSubtleNormal}
                        >
                            <Alert
                                type='warning'
                                description={
                                    <Paragraph3 bold>
                                        This asset will be added to the customer store and reuse program
                                    </Paragraph3>
                                }
                            />
                            <FormOrganizationSelect
                                label='Organization'
                                placeholder='Choose organization'
                                api={form.fields.organization}
                                filters={{
                                    ...emptyAdminOrganizationsFilters,
                                    statuses: [OrganizationStatus.Active],
                                }}
                                nullable
                                isRequired
                            />
                            <FormPriceInput
                                label='Monthly storage fee'
                                api={form.fields.monthlyFee}
                                defaultCurrency={Currency.GBP}
                                disabled={!form.values.monthlyFee}
                                isRequired
                                nullable
                            />
                        </Box>
                    )}
                </ExpandHeight>
                <LabeledInput
                    label='Item code'
                    placeholder='Enter or scan item code'
                    onChange={itemCode =>
                        form.setValues({
                            itemCode,
                        })
                    }
                    value={form.values.itemCode}
                    errorMessage={form.errors.itemCode}
                    isRequired
                />
                <LabeledInput
                    label='Serial number'
                    placeholder='Enter or scan serial number (optional)'
                    onChange={serialNumber =>
                        form.setValues({
                            serialNumber,
                        })
                    }
                    value={form.values.serialNumber}
                    errorMessage={form.errors.serialNumber}
                    nullable
                />
                <LabeledSelect
                    label='Grade'
                    options={allItemGrades}
                    onChange={grade =>
                        form.setValues({
                            grade,
                        })
                    }
                    value={form.values.grade}
                    errorMessage={form.errors.grade}
                    toText={trItemGrade}
                    isRequired
                />
                <FormDateInput label='Received date' api={form.fields.receivedOn} isRequired />
                <FormPriceInput label='Estimated value' api={form.fields.estimatedValue} isRequired />
            </FormContainer>
        </Box>
    );
};
