import React, { FC } from 'react';

import { AssignmentDetailsDto } from '@hofy/api-admin';
import { Box, FormSection } from '@hofy/ui';

import { LabeledAssignmentLink } from '../../assignmentsPage/AssignmentLink';
import { LabeledItemLink } from '../../itemsPage/ItemDetailsLink';
import { LabeledOrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';

interface DataErasureSlideoutDetailsProps {
    assignment: AssignmentDetailsDto;
}

export const DataErasureSlideoutDetails: FC<DataErasureSlideoutDetailsProps> = ({ assignment }) => {
    return (
        <Box>
            <FormSection paddingBottom={20} label='Details'>
                <Box row>
                    <LabeledAssignmentLink assignment={assignment} />
                    <LabeledItemLink item={assignment.item} />
                </Box>
                <Box row>
                    <LabeledOrganizationLink organization={assignment.organization} />
                </Box>
            </FormSection>
        </Box>
    );
};
