import React, { FC } from 'react';

import { AdminDto, useAdminQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, Button, Form, SubmitButton, SvgIcon } from '@hofy/ui';

import { useAdminForm } from '../../../../store/admin/useAdminForm';
import { useUpdateAdmin } from '../../../../store/admin/useUpdateAdmin';
import { AdminForm } from '../../admins/components/AdminForm';

interface UpdateAdminSlideoutProps {
    adminId: UUID;
    onClose(): void;
}

export const UpdateAdminSlideout: FC<UpdateAdminSlideoutProps> = ({ adminId, onClose }) => {
    const { data: admin, isLoading } = useAdminQuery(adminId);

    if (isLoading || !admin) {
        return (
            <Slideout width={600} onClose={onClose}>
                <SlideoutHeader title='Update admin details' />
                <SlideoutContent relative>
                    <ComponentLoader />
                </SlideoutContent>
                <SlideoutFooter>
                    <Button type='ghost' negativeMargin label='Cancel' disabled leftIcon={SvgIcon.Cross} />
                    <Box row>
                        <SubmitButton label='Save' disabled />
                    </Box>
                </SlideoutFooter>
            </Slideout>
        );
    }
    return <UpdateAdminSlideoutContent admin={admin} onClose={onClose} />;
};

interface UpdateAdminSlideoutContentProps {
    admin: AdminDto;
    onClose(): void;
}

export const UpdateAdminSlideoutContent: FC<UpdateAdminSlideoutContentProps> = ({ admin, onClose }) => {
    const { updateAdmin, isUpdateAdminLoading } = useUpdateAdmin(admin.id, onClose);
    const form = useAdminForm(
        {
            email: admin.email,
            firstName: admin.firstName,
            lastName: admin.lastName,
            isOffboarded: admin.isOffboarded,
            roles: admin.roles,
        },
        updateAdmin,
        onClose,
    );

    return (
        <Slideout width={600} onClose={form.discard}>
            <Form onSubmit={form.submit} isLoading={isUpdateAdminLoading} flex='auto' column>
                <SlideoutHeader title='Update admin details' />
                <SlideoutContent relative>
                    <AdminForm form={form} />
                </SlideoutContent>
                <SlideoutFooter>
                    <Button
                        type='ghost'
                        negativeMargin
                        onClick={form.discard}
                        label='Cancel'
                        leftIcon={SvgIcon.Cross}
                    />
                    <Box row>
                        <SubmitButton label='Save' />
                    </Box>
                </SlideoutFooter>
            </Form>
        </Slideout>
    );
};
