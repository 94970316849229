import React, { FC } from 'react';

import {
    AssignmentCollectionReason,
    AssignmentException,
    AssignmentStatus,
    assignmentStatusColor,
    isCollectionPending,
    useTrAssignmentCollectionReason,
    useTrAssignmentException,
    useTrAssignmentStatus,
} from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, OuterBoxProps, Tooltip } from '@hofy/ui';

interface AssignmentTypeChipProps extends OuterBoxProps {
    status: AssignmentStatus;
    exception?: AssignmentException | null;
    collectionReason?: AssignmentCollectionReason | null;
    date?: DateString | null;
}

export const AssignmentTypeChip: FC<AssignmentTypeChipProps> = ({
    status,
    exception,
    collectionReason,
    date,
    ...boxProps
}) => {
    const trAssgnmentStatus = useTrAssignmentStatus();
    const trException = useTrAssignmentException();
    const trCollectionReason = useTrAssignmentCollectionReason();

    const color = assignmentStatusColor[status];

    const description = () => {
        if (isCollectionPending(status) && collectionReason) {
            return trCollectionReason(collectionReason);
        }
        if (exception) {
            return trException(exception);
        }
        return null;
    };

    return (
        <Tooltip title={date ? formatDate(date) : undefined} body={description()}>
            <Badge label={trAssgnmentStatus(status)} color={color} {...boxProps} />
        </Tooltip>
    );
};
