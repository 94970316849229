export const createEvent = (v: string) => {
    if (typeof Event === 'function') {
        return new Event(v);
    } else {
        const event = document.createEvent('Event');
        event.initEvent(v, true, true);
        return event;
    }
};

export const STORAGE_LOCAL_EVENT = 'local-storage';
