import React, { FC, forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, BoxProps, Paragraph3 } from '../../base';
import { IconButton, SvgIcon } from '../../icon';
import { Chevron } from '../../shared/Chevron';

interface FieldContainerProps extends BoxProps {
    as?: any;
    isError?: boolean;
    disabled?: boolean;
}

export const FieldContainer = forwardRef<FieldContainerProps, any>(
    ({ children, as, isError, disabled, ...rest }, ref) => {
        const borderColor = isError ? Color.InteractionBorderAlert : Color.InteractionBorderNeutralNormal;

        return (
            <FieldContainerBox
                ref={ref}
                as={as}
                row
                gap={8}
                rounded={8}
                height={40}
                border
                borderColor={borderColor}
                inactive={disabled}
                relative
                data-test-key='field-container'
                {...rest}
            >
                {children}
            </FieldContainerBox>
        );
    },
);

interface SelectFieldContainerProps extends FieldContainerProps {
    isOpen?: boolean;
    setReference?(ref: HTMLDivElement): void;
    innerBoxRef?: any;
    innerBoxProps?: any;
    labelledBy?: string;
    selectedItemLabel?: ReactNode;
    placeholder?: string;
    nullable?: boolean;
    hasValue?: boolean;
    onClear?(): void;
    referenceProps?: any;
}

export const SelectFieldContainer: FC<SelectFieldContainerProps> = ({
    as,
    disabled,
    isOpen,
    setReference,
    innerBoxRef,
    innerBoxProps,
    labelledBy,
    selectedItemLabel,
    placeholder,
    nullable,
    hasValue,
    onClear,
    referenceProps,
    ...rest
}) => {
    return (
        <FieldContainer
            ref={setReference as any}
            as={as}
            alignItems='stretch'
            disabled={disabled}
            cursor={disabled ? undefined : 'pointer'}
            test-key='select-field-container'
            {...rest}
        >
            <Box
                ref={innerBoxRef}
                aria-disabled={disabled}
                tabIndex={disabled ? -1 : 0}
                aria-labelledby={labelledBy}
                aria-autocomplete='none'
                flex={1}
                row
                gap={8}
                paddingLeft={16}
                {...innerBoxProps}
            >
                {selectedItemLabel || (
                    <Paragraph3 textNoWrap overflow='hidden' ellipsis color={Color.ContentTertiary}>
                        {placeholder}
                    </Paragraph3>
                )}
            </Box>
            {nullable && hasValue && !disabled && (
                <Box centered>
                    <IconButton icon={SvgIcon.Cross} onClick={onClear} />
                </Box>
            )}
            {isOpen !== undefined && (
                <Box centered paddingRight={16} {...referenceProps}>
                    <Chevron isOpen={isOpen} />
                </Box>
            )}
        </FieldContainer>
    );
};

const fieldFocus = css`
    &:focus-within {
        outline: none;
        border-color: ${Color.InteractionFocusDefault};
        box-shadow: 0 0 0 3px ${Color.InteractionFocusDefault};
    }
`;

const fieldHover = css`
    &:hover {
        border-color: ${Color.InteractionBorderHover};
    }
`;

export const FieldContainerBox = styled(Box)`
    background: ${Color.InteractionBackgroundFormField};

    ${props => (props.inactive ? undefined : fieldHover)}
    ${props => (props.inactive ? undefined : fieldFocus)}

    transition: border-color 150ms ease-in-out, box-shadow 300ms ease-out;
`;
