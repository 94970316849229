import React, { FC } from 'react';

import { EmailLogDto } from '@hofy/api-admin';
import { useTrEmailTopic } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import {
    ErrorStatePlaceholder,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIllustration,
} from '@hofy/ui';

import { EmailStatusChip } from '../../../views/peoplePage/users/components/EmailStatusChip';

interface EmailsTableProps {
    emails: EmailLogDto[];
    onOpenEmail(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
    isError?: boolean;
}

export const EmailsTable: FC<EmailsTableProps> = ({ emails, onOpenEmail, infinityScroll, isError }) => {
    const trEmailTopic = useTrEmailTopic();

    return (
        <InfinityScrollTable
            data={emails}
            toKey={email => email.id}
            infinityScroll={infinityScroll}
            onRowClick={email => onOpenEmail(email.id)}
            emptyContent={
                isError ? (
                    <ErrorStatePlaceholder />
                ) : (
                    <Placeholder
                        illustration={SvgIllustration.List}
                        title='No emails'
                        message='No emails for selected criteria'
                    />
                )
            }
            flex='auto'
            columns={[
                {
                    id: 'to',
                    header: 'To',
                    flexGrow: 2,
                    renderer: email => email.messageTo,
                },
                {
                    id: 'created-at',
                    header: 'Created at',
                    renderer: email => formatDateTime(email.createdAt),
                },
                {
                    id: 'updated-at',
                    header: 'Updated at',
                    renderer: email => formatDateTime(email.updatedAt),
                },
                {
                    id: 'topic',
                    header: 'Topic',
                    renderer: email => trEmailTopic(email.topic),
                },
                {
                    id: 'subject',
                    header: 'Subject',
                    renderer: email => email.messageSubject,
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: email => <EmailStatusChip status={email.status} />,
                },
            ]}
        />
    );
};
