import React, { FC } from 'react';

import { TermAndConditionDto, useTermsAndConditionsQuery } from '@hofy/api-admin';
import { Permission, Role } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import {
    AsyncButton,
    BaseTable,
    BooleanBadge,
    Box,
    Button,
    FormCheckbox,
    FormInput,
    IconButton,
    Link,
    SvgIcon,
} from '@hofy/ui';

import { Footer } from '../../../components/design/layout/Footer';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { useUpdateTermAndCondition } from '../../../store/termAndCondition/useUpdateTermAndCondition';
import { SettingsHeader } from '../SettingsHeader';

interface TermsAndConditionsTabProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const TermsAndConditionsTab: FC<TermsAndConditionsTabProps> = ({ tab, tabs, onChangeTab }) => {
    const { data: termsAndConditions, isLoading } = useTermsAndConditionsQuery();
    const { hasPermission, hasRole } = usePermission();
    const canEdit = hasPermission(Permission.AdminTermAndConditionUpdate) && hasRole(Role.SuperAdmin);

    const { form, currentRow, setCurrentRow, isLoadingMutation } = useUpdateTermAndCondition();

    const onRowClick = (tac: TermAndConditionDto) => {
        if (canEdit) {
            setCurrentRow(tac);
            form.setValues({
                name: tac.name,
                url: tac.url,
                isDefault: tac.isDefault,
            });
        }
    };

    const cancelHandler = () => {
        setCurrentRow(null);
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader tab={tab} tabs={tabs} onChangeTab={onChangeTab} />
            <Box column flex='auto'>
                <BaseTable
                    isLoading={isLoading}
                    data={termsAndConditions}
                    toKey={tac => tac.id || 'form'}
                    flex='auto'
                    columns={[
                        {
                            id: 'createdAt',
                            header: 'Created at',
                            width: 200,
                            flexGrow: 0,
                            renderer: (tac: TermAndConditionDto) => formatDate(tac.createdAt),
                        },
                        {
                            id: 'name',
                            header: 'Name',
                            flexGrow: 1,
                            renderer: tac => tac.name,
                        },
                        {
                            id: 'url',
                            header: 'Url',
                            flexGrow: 2,
                            renderer: tac => {
                                if (tac.id === currentRow?.id) {
                                    return (
                                        <FormInput marginLeft={4} label='' api={form.fields.url} nullable />
                                    );
                                } else {
                                    return tac.url ? <Link to={tac.url}>{tac.url}</Link> : '--';
                                }
                            },
                        },
                        {
                            id: 'isDefault',
                            header: 'New organization default',
                            flexGrow: 1,
                            renderer: tac => {
                                if (tac.id === currentRow?.id) {
                                    return (
                                        <FormCheckbox
                                            api={form.fields.isDefault}
                                            label=''
                                            disabled={tac.isDefault}
                                        />
                                    );
                                } else {
                                    return <BooleanBadge value={tac.isDefault} />;
                                }
                            },
                        },
                        {
                            id: 'edit',
                            flexGrow: 0,
                            width: 50,
                            renderer: (tac: TermAndConditionDto) => {
                                if (canEdit) {
                                    return <IconButton icon={SvgIcon.Edit} onClick={() => onRowClick(tac)} />;
                                }
                                return null;
                            },
                        },
                    ]}
                />
                {canEdit && (
                    <Footer justify='flex-end'>
                        {!!currentRow && (
                            <>
                                <Button
                                    type='secondary'
                                    marginRight={20}
                                    label='Cancel'
                                    onClick={cancelHandler}
                                />
                                <AsyncButton
                                    label='Save'
                                    disabled={!canEdit}
                                    isLoading={isLoadingMutation}
                                    onClick={form.submit}
                                />
                            </>
                        )}
                    </Footer>
                )}
            </Box>
        </Box>
    );
};
