import { SvgIcon } from '@hofy/ui';

export enum InspectionDeviceCheckType {
    ActivationLockRemoval = 'activationLockRemoval',
    UserAccountRemoval = 'userAccountRemoval',
    OrgMDMRemoval = 'orgMDMRemoval',
    PinLockRemoval = 'pinLockRemoval',
}

const inspectionDeviceCheckTypeLabels: Record<InspectionDeviceCheckType, string> = {
    [InspectionDeviceCheckType.ActivationLockRemoval]: 'Activation lock removal',
    [InspectionDeviceCheckType.UserAccountRemoval]: 'User account removal',
    [InspectionDeviceCheckType.OrgMDMRemoval]: 'ABM/Autopilot removal',
    [InspectionDeviceCheckType.PinLockRemoval]: 'Pin lock removal',
};

export const getInspectionDeviceCheckTypeLabel = (type: InspectionDeviceCheckType): string => {
    return inspectionDeviceCheckTypeLabels[type];
};

const inspectionDeviceCheckTypeIcons: Record<InspectionDeviceCheckType, Svg> = {
    [InspectionDeviceCheckType.ActivationLockRemoval]: SvgIcon.Lock,
    [InspectionDeviceCheckType.UserAccountRemoval]: SvgIcon.User,
    [InspectionDeviceCheckType.OrgMDMRemoval]: SvgIcon.Building,
    [InspectionDeviceCheckType.PinLockRemoval]: SvgIcon.Key,
};

export const getInspectionDeviceCheckTypeIcon = (type: InspectionDeviceCheckType): Svg => {
    return inspectionDeviceCheckTypeIcons[type];
};
