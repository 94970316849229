import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps, Switch, useHistory, useRouteMatch } from 'react-router-dom';

import { JobContext } from '@hofy/api-admin';
import { pathUuid, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { getEnumParam, getStringParam, UUIDRoute } from '@hofy/router';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { InventoryTab } from '../../../store/inventory/types/InventoryTab';
import { PurchaseOrderTab } from '../../../store/purchaseOrders/types/PurchaseOrderTab';
import { SupplierTab } from '../../../store/suppliers/types/SupplierTab';
import { CreatePurchaseOrderSlideout } from '../purchaseOrders/purchaseOrderCreateEditSlideout/CreatePurchaseOrderSlideout';
import { SupplierDetailsOverlay } from './supplierDetailsPage/SupplierDetailsOverlay';
import { CreateSupplierSlideout } from './supplierFormSlideout/CreateSupplierSlideout';
import { UpdateSupplierSlideout } from './supplierFormSlideout/UpdateSupplierSlideout';
import { SuppliersPage } from './SuppliersPage';

const SupplierRoute = UUIDRoute('supplierId', Route);

export const SuppliersRouter: FC = () => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { goBack } = useGoBack();

    const handleOpenSupplier = (id: UUID) => {
        history.push(`${path}/${id}/${SupplierTab.Details}`);
    };

    const handleOpenNewSupplier = () => {
        history.push(`${path}/new`);
    };

    const handleOpenEditSupplier = (id: UUID) => {
        history.push(`${path}/${id}/${SupplierTab.Details}/edit`);
    };

    const handleOpenJobs = () => {
        history.push(`${AdminNavLink.Jobs}?context=${JobContext.Suppliers}`);
    };

    const handleChangeTab = (id: UUID, tab: SupplierTab) => {
        history.push(`${path}/${id}/${tab}`);
    };

    const handleOpenEditPurchaseOrder = (id: UUID) => {
        history.push(
            `${AdminNavLink.Inventory}/${InventoryTab.PurchaseOrders}/${id}/${PurchaseOrderTab.Details}/edit`,
        );
    };

    return (
        <Switch>
            <SupplierRoute path={`${path}/:supplierId(${pathUuid})`}>
                {({ supplierId }) => (
                    <>
                        <OverlayRoute path={`${path}/:supplierId(${pathUuid})/:supplierTab`}>
                            {({ supplierTab }) => (
                                <SupplierDetailsOverlay
                                    supplierId={supplierId}
                                    supplierTab={supplierTab}
                                    onChangeTab={supplierTab => handleChangeTab(supplierId, supplierTab)}
                                    onOpenEditSupplier={handleOpenEditSupplier}
                                />
                            )}
                        </OverlayRoute>
                        <Switch>
                            <Route path={`${path}/:supplierId(${pathUuid})/${SupplierTab.Details}/edit`}>
                                <UpdateSupplierSlideout
                                    onClose={() => goBack(`${path}/${supplierId}/${SupplierTab.Details}`)}
                                    supplierId={supplierId}
                                />
                            </Route>
                            <Route path={`${path}/:supplierId(${pathUuid})/${SupplierTab.Orders}/new`}>
                                <CreatePurchaseOrderSlideout
                                    onClose={() => goBack(`${path}/${supplierId}/${SupplierTab.Orders}`)}
                                    onDraftSuccess={handleOpenEditPurchaseOrder}
                                />
                            </Route>
                        </Switch>
                    </>
                )}
            </SupplierRoute>
            <Route path={`${path}`}>
                <>
                    <SuppliersPage
                        onNewSupplier={handleOpenNewSupplier}
                        onOpenSupplier={handleOpenSupplier}
                        onOpenJobs={handleOpenJobs}
                    />
                    <Route exact path={`${path}/new`}>
                        <CreateSupplierSlideout onClose={() => goBack(path)} />
                    </Route>
                    <Route exact path={`${path}/purchase-order/new`}>
                        <CreatePurchaseOrderSlideout onClose={() => goBack(path)} />
                    </Route>
                </>
            </Route>
        </Switch>
    );
};

interface OverlayRouteProps {
    path?: string;
    children(v: { supplierTab: SupplierTab }): ReactNode;
}

const OverlayRoute: FC<OverlayRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const supplierId = getStringParam(p.match?.params || {}, 'supplierId');
        const supplierTab = getEnumParam<SupplierTab>(p.match?.params || {}, 'supplierTab', SupplierTab);

        if (supplierId && supplierTab) {
            return children({
                supplierTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
