import { Permission } from '@hofy/api-shared';
import { getEnumValues } from '@hofy/global';

export enum AdminAccountingTab {
    Journals = 'journals',
    ItemEvents = 'item-events',
    RevenueRecognition = 'revenue-recognition',
    ItemValuation = 'item-valuation',
    FixedAssetValuation = 'fixed-asset-valuation',
    Payments = 'payments',
    Payouts = 'payouts',
}

export const AdminAccountingTabLink = {
    [AdminAccountingTab.Journals]: 'journals',
    [AdminAccountingTab.ItemEvents]: 'item-events',
    [AdminAccountingTab.RevenueRecognition]: 'revenue-recognition',
    [AdminAccountingTab.ItemValuation]: 'item-valuation',
    [AdminAccountingTab.FixedAssetValuation]: 'fixed-asset-valuation',
    [AdminAccountingTab.Payments]: 'payments',
    [AdminAccountingTab.Payouts]: 'payouts',
};

export const adminAccountingTabPermissions: Record<AdminAccountingTab, Permission> = {
    [AdminAccountingTab.Journals]: Permission.AdminJournalsView,
    [AdminAccountingTab.ItemEvents]: Permission.AdminItemEventsView,
    [AdminAccountingTab.RevenueRecognition]: Permission.AdminRevenueRecognitionView,
    [AdminAccountingTab.ItemValuation]: Permission.AdminItemValuationView,
    [AdminAccountingTab.FixedAssetValuation]: Permission.AdminFixedAssetValuationView,
    [AdminAccountingTab.Payments]: Permission.AdminAccountingView,
    [AdminAccountingTab.Payouts]: Permission.AdminAccountingView,
};

export const allAdminAccountingTabs = getEnumValues<AdminAccountingTab>(AdminAccountingTab);
