import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { productsService } from '../service/productService';
import {
    VariantWithProductAndPriceQuery,
    VariantWithProductDto,
} from '../service/types/VariantWithProductDto';
import { variantsWithProductAndPriceCacheKey } from './variantsWithProductAndPriceCacheKey';

export type VariantWithProductAndPriceMap = Record<UUID, VariantWithProductDto>;

export const useVariantsWithProductAndPrice = (
    query: VariantWithProductAndPriceQuery,
    enabled: boolean = true,
) => {
    const { data, isLoading, isPending } = useQuery({
        queryKey: variantsWithProductAndPriceCacheKey(query),
        queryFn: () => productsService.getVariantsWithProductAndPrice(query),
        enabled,
    });

    const variantsById =
        data?.reduce(
            (obj: VariantWithProductAndPriceMap, variant) => Object.assign(obj, { [variant.id]: variant }),
            {},
        ) ?? {};
    return { variantsById, isLoading, isPending };
};
