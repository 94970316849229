import { ProductCategory } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

export const productCategoryIcons: Record<ProductCategory, Svg> = {
    [ProductCategory.Chair]: SvgIcon.Chair,
    [ProductCategory.Desk]: SvgIcon.Desk,
    [ProductCategory.DeskRiser]: SvgIcon.Box,
    [ProductCategory.DockingStation]: SvgIcon.Box,
    [ProductCategory.Footrest]: SvgIcon.Box,
    [ProductCategory.Headset]: SvgIcon.Headset,
    [ProductCategory.Keyboard]: SvgIcon.Box,
    [ProductCategory.LaptopStand]: SvgIcon.Box,
    [ProductCategory.Monitor]: SvgIcon.Monitor,
    [ProductCategory.MobileDevice]: SvgIcon.Box,
    [ProductCategory.MonitorArm]: SvgIcon.Box,
    [ProductCategory.MiceTrackpad]: SvgIcon.Box,
    [ProductCategory.Printer]: SvgIcon.Printer,
    [ProductCategory.Shredder]: SvgIcon.Box,
    [ProductCategory.StandingMat]: SvgIcon.Box,
    [ProductCategory.TaskLight]: SvgIcon.Box,
    [ProductCategory.Webcam]: SvgIcon.Box,
    [ProductCategory.WifiRangeExtender]: SvgIcon.Box,
    [ProductCategory.Laptop]: SvgIcon.Laptop,
    [ProductCategory.Dongle]: SvgIcon.Box,
    [ProductCategory.Cable]: SvgIcon.Box,
    [ProductCategory.Adapter]: SvgIcon.Box,
    [ProductCategory.DeskTidy]: SvgIcon.Box,
    [ProductCategory.Desktop]: SvgIcon.Desktop,
    [ProductCategory.Tablet]: SvgIcon.Box,
    [ProductCategory.Charger]: SvgIcon.Box,
    [ProductCategory.Other]: SvgIcon.Box,
    [ProductCategory.WelcomePack]: SvgIcon.Box,
};
