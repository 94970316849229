import { useHofyWarehousesQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useWarehouseBinRequired = (id: UUID | undefined) => {
    const { data: hofyWarehouses, isLoading } = useHofyWarehousesQuery();
    const warehouse = hofyWarehouses.find(warehouse => warehouse.id === id);
    return {
        isWarehouseBinRequired: warehouse?.hofyWarehouse.isWarehouseBinRequired,
        isLoading,
    };
};
