import { getEnumValues } from '@hofy/global';

export enum ContractFilterType {
    NormalRental = 'normal_rental',
    RollingRental = 'rolling_rental',
    Purchase = 'purchase',
    Management = 'management',
}

export const allContractFilterTypes = getEnumValues<ContractFilterType>(ContractFilterType);
