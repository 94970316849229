import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Box, OuterBoxProps } from '../../base';

export interface CardLayoutProps extends OuterBoxProps, TestKeyAware {
    children?: ReactNode;
    header?: ReactNode;
    footer?: ReactNode;
    direction?: 'column' | 'row';
}

export const cardLayoutGap = 24;

export const PageCardLayout: FC<CardLayoutProps> = ({
    children,
    direction = 'column',
    header,
    footer,
    testKey,
    ...rest
}) => {
    return (
        <Box column flex='auto' bg={Color.BackgroundSurfaceNeutral} data-test-key={testKey}>
            {header && (
                <Box paddingHorizontal='mainMarginHorizontal' paddingVertical={16} borderBottom>
                    {header}
                </Box>
            )}
            <Box
                direction={direction}
                alignItems='stretch'
                gap={cardLayoutGap}
                paddingHorizontal='mainMarginHorizontal'
                paddingVertical='mainMarginVertical'
                flex='auto'
                overflow='auto'
                {...rest}
            >
                {children}
            </Box>
            {footer && (
                <Box paddingHorizontal='mainMarginHorizontal' paddingVertical={16} borderTop>
                    {footer}
                </Box>
            )}
        </Box>
    );
};
