import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';
import { IconButton, MarginBoxProps, SvgIcon } from '@hofy/ui';

import { BaseMoreMenu, MoreMenuItem } from './BaseMoreMenu';

interface MoreMenuProps extends MarginBoxProps {
    icon?: Svg;
    items: MoreMenuItem[];
    iconSize?: number;
}

export const MoreMenu: FC<MoreMenuProps> = ({ items, iconSize, icon = SvgIcon.DotsVertical, ...rest }) => {
    const finalItems = useMemo(() => {
        return items.filter(i => i.visible !== false);
    }, [items]);

    if (finalItems.length === 0) {
        return null;
    }

    return (
        <BaseMoreMenu
            data-test-key='more-menu'
            items={finalItems}
            trigger={isOpen => (
                <MoreMenuButton isOpen={isOpen} size={iconSize} color={Color.ContentPrimary} icon={icon} />
            )}
            asChild
            {...rest}
        />
    );
};

const MoreMenuButton = styled(IconButton)<{ isOpen: boolean }>`
    ${p =>
        p.isOpen &&
        css`
            visibility: visible !important;
        `}
`;
