import React, { FC } from 'react';

import { CopyToClipboardStatus, useCopyToClipboard } from '@hofy/hooks';
import { Button, SvgIcon } from '@hofy/ui';

interface CollectionRequestStatusProps {
    formLink: string;
}

export const CollectionFormLinkToClipboard: FC<CollectionRequestStatusProps> = ({ formLink }) => {
    const { copyStatus, copyToClipboard } = useCopyToClipboard(formLink);
    return (
        <Button
            type='secondary'
            leftIcon={copyStatus === CopyToClipboardStatus.Idle ? SvgIcon.Copy : SvgIcon.Check}
            onClick={copyToClipboard}
            label={copyStatusLabel[copyStatus]}
            disabled={copyStatus === CopyToClipboardStatus.Copying}
        />
    );
};

const copyStatusLabel: Record<CopyToClipboardStatus, string> = {
    [CopyToClipboardStatus.Idle]: 'Copy survey link',
    [CopyToClipboardStatus.Copying]: 'Copying...',
    [CopyToClipboardStatus.Copied]: 'Copied!',
    [CopyToClipboardStatus.Error]: 'Try again!',
};
