import React, { FC } from 'react';

import { AssignmentConfigurationStatus, assignmentConfigurationStatusColor } from '@hofy/api-shared';
import { getColorFromColorDye } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { useTrAssignmentConfigurationStatus } from '../../../../store/assignments/useTrAssignmentConfigurationStatus';
import { Indicator } from '../../../design/indicator/Indicator';

interface DeviceConfigurationIndicatorProps {
    configurationStatus: AssignmentConfigurationStatus;
}

export const DeviceConfigurationIndicator: FC<DeviceConfigurationIndicatorProps> = ({
    configurationStatus,
}) => {
    const color = getColorFromColorDye(assignmentConfigurationStatusColor[configurationStatus], 'main');
    const trStatus = useTrAssignmentConfigurationStatus();

    return (
        <Indicator color={color}>
            <TooltipIcon
                icon={SvgIcon.Laptop}
                iconColor={color}
                body={trStatus(configurationStatus)}
                iconSize={16}
            />
        </Indicator>
    );
};
