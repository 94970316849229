import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';
import {
    Box,
    BoxProps,
    Chevron,
    IconButton,
    LabelError,
    Paragraph3,
    Pressable,
    SvgIcon,
    TestKeyAware,
    useIsDisabled,
} from '@hofy/ui';

import { ContentProps, DropdownContent, DropdownList } from './DropdownList';

export interface FormDropdownProps<T> extends BoxProps, TestKeyAware {
    items: T[];
    fixedItems?: T[];
    value: T | null | undefined;
    label?: ReactNode;
    bg?: Color;
    width?: string | number | 'auto';
    onChange(o: T | undefined): void;
    labelFormatter(o: T): ReactNode;
    labelListFormatter?(o: T): ReactNode;
    topContent?: DropdownContent;
    disabled?: boolean;
    listMinWidth?: number | string;

    searchable?: boolean;
    searchPlaceholder?: string;
    toSearchLabel?(p: T): string | string[];
    onSearchChange?(v: string): void;
    loadingSearchResults?: boolean;
    emptyContent?: ReactNode;
    clearable?: boolean;

    testKeyFormatter?(o: T): string;

    isError?: boolean | string;
    isRequired?: boolean;
    border?: boolean;
}

/** @deprecated use `FormSelect` or `LabeledSelect` from `@hofy/ui` instead */
export const FormDropdown = <T extends any>({
    value,
    onChange,
    label,
    bg,
    labelFormatter,
    items,
    fixedItems,
    width = '100%',
    labelListFormatter = labelFormatter,
    disabled: formDropdownDisabled,
    searchable = false,
    searchPlaceholder,
    toSearchLabel = () => [],
    onSearchChange,
    loadingSearchResults = false,
    emptyContent,
    testKey,
    isRequired,
    isError,
    testKeyFormatter,
    listMinWidth = 150,
    clearable = true,
    topContent,
    border = true,
    ...boxProps
}: FormDropdownProps<T>) => {
    const disabled = useIsDisabled(formDropdownDisabled);
    const fontColor = disabled ? Color.ContentTertiary : Color.ContentPrimary;

    const getLabel = (value: ReactNode) => {
        if (typeof value !== 'string') {
            return value;
        }
        return (
            <Paragraph3 textNoWrap overflow='hidden' ellipsis color={fontColor}>
                {value}
            </Paragraph3>
        );
    };

    const emptyContentLabel =
        typeof emptyContent === 'string' ? (
            <Paragraph3 color={Color.ContentTertiary}>{emptyContent}</Paragraph3>
        ) : (
            emptyContent
        );

    const handleClearValue = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        onChange(null as T);
    };

    const getTopContent = () => {
        if (topContent) {
            return topContent;
        }
        return ({ isOpen, toggleOpen }: ContentProps) => (
            <DropdownInner
                onClick={() => {
                    if (!disabled) {
                        toggleOpen();
                    }
                }}
                isOpen={isOpen}
                inactive={disabled}
                row
                width={width}
                bg={bg || Color.BackgroundDefault}
                isError={actualIsError}
                color={fontColor}
                border={border}
                relative
                rounded={8}
                paddingHorizontal={10}
                height={40}
            >
                <Box row justify='space-between' fullWidth>
                    <Box flex='auto'>{value ? getLabel(labelFormatter(value)) : emptyContentLabel}</Box>
                    {emptyContentLabel && clearable && !!value && (
                        <IconButton
                            disabled={disabled}
                            icon={SvgIcon.Cross}
                            onClick={handleClearValue}
                            color={fontColor}
                        />
                    )}
                    <Box marginLeft={8}>
                        <Chevron
                            isOpen={isOpen}
                            color={actualIsError && !disabled ? Color.FoundationNegative : fontColor}
                        />
                    </Box>
                </Box>
            </DropdownInner>
        );
    };

    const actualIsError = typeof isError === 'string' || isError === true;
    const errorReason = typeof isError === 'string' ? isError : undefined;

    return (
        <Box data-test-key='dropdown-root' column width={width} gap={8} {...boxProps}>
            {label && (
                <Paragraph3 role='label' data-test-key='dropdown-label'>
                    {label}
                    {isRequired && '*'}
                </Paragraph3>
            )}
            <DropdownList
                value={value}
                items={items}
                fixedItems={fixedItems}
                topContent={getTopContent()}
                labelFormatter={(v: T) => getLabel(labelListFormatter(v))}
                searchable={searchable}
                searchPlaceholder={searchPlaceholder}
                toSearchLabel={toSearchLabel}
                onSearchChange={onSearchChange}
                loadingSearchResults={loadingSearchResults}
                onChange={onChange}
                placement='bottom'
                width={width}
                minWidth={listMinWidth}
                disabled={disabled}
                testKey={testKey}
                testKeyFormatter={testKeyFormatter}
            />
            {!!errorReason && <LabelError message={errorReason} />}
        </Box>
    );
};

export const DropdownInner = styled(Pressable)<{
    isError?: boolean;
    isOpen: boolean;
}>`
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;

    border-color: ${p =>
        p.isError
            ? Color.FoundationNegative
            : p.isOpen
            ? Color.FoundationLightPurple
            : Color.InteractionBorderNeutralNormal};

    ${p =>
        !p.inactive &&
        !p.isError &&
        !p.isOpen &&
        css`
            :hover {
                border-color: ${Color.InteractionBorderHover};
            }
        `};
`;
