import { useMergeRefs } from '@floating-ui/react';
import React, { ElementRef, forwardRef, useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';

import { AnyDateString, DateString } from '@hofy/global';
import {
    DateType,
    formatDate,
    formatMonth,
    parseDateTime,
    parseOptionalDateTime,
    parseUserDate,
    toISODate,
} from '@hofy/helpers';

import { TestKeyAware } from '../../../../types';
import { OuterBoxProps } from '../../../base';
import { InlineDatepicker } from '../../../date/InlineDatepicker';
import { Dropdown } from '../../../dropdown/Dropdown';
import { Icon, IconButton, SvgIcon } from '../../../icon';
import { Chevron } from '../../../shared';
import { Input } from '../Input';

interface DatePickerBaseProps extends TestKeyAware, OuterBoxProps {
    type?: DateType;
    onChange(date: DateString | null): void;
    onBlur?(): void;

    isRequired?: boolean;
    placeholder?: string;
    isError?: boolean;

    filterDate?(date: DateString): boolean;

    minDate?: DateString;
    maxDate?: DateString;

    disabled?: boolean;
    clearable?: boolean;
}

interface NormalDateInputProps extends DatePickerBaseProps {
    nullable?: false;
    value: DateString;
    onChange(value: DateString): void;
}

interface NullableDateInputProps extends DatePickerBaseProps {
    nullable: true;
    value: DateString | null;
    onChange(value: DateString | null): void;
}

export type DateInputProps = NormalDateInputProps | NullableDateInputProps;

export const DateInput = forwardRef<ElementRef<'input'>, DateInputProps>(
    (
        {
            type = 'day',
            value,
            onChange,
            onBlur,
            testKey,
            minDate,
            maxDate,
            filterDate,
            nullable,
            disabled,
            clearable,

            ...rest
        },
        ref,
    ) => {
        const inputRef = useRef<HTMLInputElement>(null);
        const allRefs = useMergeRefs([ref, inputRef]);

        const formatInput = (date: DateString | null) => {
            if (!date) {
                return '';
            }
            switch (type) {
                case 'day':
                    return formatDate(date);
                case 'month':
                    return formatMonth(date);
                default:
                    return '';
            }
        };

        const [isOpen, toggleOpen] = useToggle(false);
        const [input, setInput] = useState(() => formatInput(value));

        const min = parseOptionalDateTime(minDate || null);
        const max = parseOptionalDateTime(maxDate || null);

        useEffect(() => {
            // Update input value when value changes but only if the input is not focused
            if (!isOpen) {
                setInput(formatInput(value));
            }
        }, [value, input, isOpen]);

        useEffect(() => {
            setInput(formatInput(value));
        }, [type]);

        const empty = () => {
            if (nullable) {
                onChange(null);
            } else {
                onChange('' as any);
            }
        };

        const parseAndCheckString = (parsedDateString: string) => {
            const dateString = parseUserDate(parsedDateString as AnyDateString);

            if (!dateString) {
                return undefined;
            }

            const date = parseDateTime(dateString);

            if (
                date.isValid &&
                (!min || date >= min) &&
                (!max || date <= max) &&
                (!filterDate || filterDate(parsedDateString as DateString))
            ) {
                return toISODate(date);
            }
            return undefined;
        };

        const handleDatePickerChange = (date: DateString | null): void => {
            if (!date) {
                return empty();
            }

            setInput(formatInput(date));
            toggleOpen(false);
            onChange(date);
        };

        const handleInputChange = (value: string) => {
            setInput(value);
            if (!value) {
                return empty();
            }
            const date = parseAndCheckString(value);
            if (date) {
                onChange(date);
            }
        };

        const handleBlur = () => {
            setInput(formatInput(value));
            onBlur?.();
        };

        const handleKeyDown = (event: React.KeyboardEvent<Element>): void => {
            // Close dropdown when tabbing out from the input, because we open it on focus
            // We cannot close it on blur because the datepicker is inside the dropdown and in Portal
            if (event.key === 'Tab') {
                toggleOpen(false);
            }

            // Close and blur on enter
            if (event.key === 'Enter') {
                toggleOpen(false);
                inputRef.current?.blur();
            }
        };

        const clearButton = clearable && value && !disabled && (
            <IconButton icon={SvgIcon.Cross} onClick={empty} />
        );

        return (
            <Dropdown
                open={isOpen}
                onDismiss={() => toggleOpen(false)}
                contentWidth='auto'
                placement='bottom-start'
                trigger={
                    <Input
                        ref={allRefs}
                        value={input}
                        onChange={handleInputChange}
                        onFocus={() => toggleOpen(true)}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        testKey={testKey}
                        nullable={nullable}
                        leftSlot={<Icon svg={SvgIcon.Calendar} />}
                        rightSlot={
                            <>
                                {clearButton}
                                <Chevron isOpen={isOpen} />
                            </>
                        }
                        disabled={disabled}
                        {...rest}
                    />
                }
                asChild
                padding={24}
            >
                <InlineDatepicker
                    key={value}
                    type={type}
                    date={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={handleDatePickerChange}
                    filterDate={filterDate ? date => filterDate(date) : undefined}
                    disabled={disabled}
                />
            </Dropdown>
        );
    },
);
