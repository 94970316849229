import React, { FC, memo } from 'react';

import { OrganizationDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { BooleanBadge, Box, InfinityScrollTable, Paragraph3, Paragraph4, PublicIdColumn } from '@hofy/ui';

import { OrganizationStatusChip } from '../../components/domain/organizations/OrganizationStatusChip';
import { useAdminI18n } from '../../i18n/useAdminI18n';

interface OrganizationsTableProps {
    isLoading: boolean;
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage(): void;
    organizations: OrganizationDto[];
    onOpenOrganization(id: UUID): void;
}

const OrganizationsTableComponent: FC<OrganizationsTableProps> = ({
    organizations,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    onOpenOrganization,
}) => {
    const { trPlural } = useAdminI18n();

    const getCreditUtilisation = (credits: number | null) => {
        if (credits !== null) {
            return (
                <Paragraph3
                    color={credits >= 80 ? Color.NonContextualYellowDefault : Color.NonContextualGreenDefault}
                >
                    {credits}%
                </Paragraph3>
            );
        }
        return <Paragraph3 color={Color.ContentSecondary}>--</Paragraph3>;
    };

    return (
        <InfinityScrollTable
            data={organizations}
            toKey={org => org.id}
            onRowClick={org => onOpenOrganization(org.id)}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoadingMore: isFetchingNextPage,
                isLoading: isLoading,
                loadMore: () => fetchNextPage(),
            }}
            flex='auto'
            columns={[
                PublicIdColumn<OrganizationDto>(),
                {
                    id: 'deel',
                    header: 'Deel',
                    flexGrow: 0,
                    width: 100,
                    renderer: org => <BooleanBadge value={org.isDeel} offColor='grey' />,
                },
                {
                    id: 'name',
                    header: 'Name',
                    flexGrow: 2,
                    renderer: org => {
                        return (
                            <Box>
                                <Paragraph3>{org.name}</Paragraph3>
                                {org.partnerName !== null && <Paragraph4>{org.partnerName}</Paragraph4>}
                            </Box>
                        );
                    },
                },
                {
                    id: 'currency',
                    header: 'Currency',
                    flexGrow: 0,
                    width: 150,
                    renderer: org => org.currency,
                },
                {
                    id: 'user-count',
                    header: 'Users',
                    flexGrow: 0,
                    width: 150,
                    renderer: org => trPlural('users.title', org.userCount),
                },
                {
                    id: 'utilisation',
                    header: 'Credit utilisation',
                    flexGrow: 0,
                    width: 150,
                    renderer: org => getCreditUtilisation(org.creditUtilisationPercentage),
                },

                {
                    id: 'status-organization',
                    header: 'Status',
                    flexGrow: 0,
                    width: 150,
                    renderer: org => <OrganizationStatusChip status={org.status} />,
                },
            ]}
        />
    );
};

export const OrganizationsTable = memo(OrganizationsTableComponent);
