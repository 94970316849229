import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutContentContainer, SlideoutFooter } from '@hofy/common';
import { AsyncButton, Box, Button, Form, Heading3, Skeleton, SubmitButton } from '@hofy/ui';

import { PurchaseOrderFormTab } from '../../../../../store/purchaseOrders/PurchaseOrderFormTab';
import { UsePurchaseOrderForm } from '../../../../../store/purchaseOrders/useCreateMultipartPurchaseOrderForm';
import { PurchaseOrderForm } from './PurchaseOrderForm';
import { PurchaseOrderFormTabs } from './PurchaseOrderFormTabs';

interface PurchaseOrderFormSlideoutProps {
    onClose(): void;
    formWrapper: UsePurchaseOrderForm;
    isPreparing: boolean;
    title: string;
    saveAsDraft(): void;
    isLoadingSaveAsDraft: boolean;
}

export const PurchaseOrderFormSlideout: FC<PurchaseOrderFormSlideoutProps> = ({
    onClose,
    formWrapper,
    isPreparing,
    title,
    saveAsDraft,
    isLoadingSaveAsDraft,
}) => {
    const { form } = formWrapper;
    const tab = form.step as PurchaseOrderFormTab;
    const setTab = form.goToStep;

    const slideoutContent = () => {
        if (isPreparing) {
            return <Skeleton height={400} />;
        }

        return <PurchaseOrderForm tab={tab} form={formWrapper} />;
    };

    return (
        <Form onSubmit={form.submit} isLoading={formWrapper.isLoading} isError={formWrapper.isError}>
            <Slideout width={1300} onClose={onClose} slideoutId='purchase-order'>
                <SlideoutContentContainer borderBottom shrink={0}>
                    <Heading3 paddingTop={20} paddingBottom={16}>
                        {title}
                    </Heading3>
                    <PurchaseOrderFormTabs
                        tab={tab}
                        stepErrorCounts={form.stepErrorCounts}
                        onChange={v => setTab(v)}
                    />
                </SlideoutContentContainer>
                <SlideoutContent paddingTop={24}>{slideoutContent()}</SlideoutContent>
                <SlideoutFooter>
                    <Box>
                        <Button
                            label='Cancel'
                            onClick={onClose}
                            type='ghost'
                            negativeMargin
                            testKey='cancel'
                            marginRight={10}
                        />
                        {!form.forms.header.values.isDropship && (
                            <AsyncButton
                                type='ghost'
                                label='Save as draft'
                                onClick={saveAsDraft}
                                testKey='save-as-draft'
                                isLoading={isLoadingSaveAsDraft}
                            />
                        )}
                    </Box>

                    {form.step !== PurchaseOrderFormTab.Review && (
                        <Button label='Next' onClick={form.next} data-test-key='submit' />
                    )}

                    {form.step === PurchaseOrderFormTab.Review && (
                        <SubmitButton label='Submit' data-test-key='submit' />
                    )}
                </SlideoutFooter>
            </Slideout>
        </Form>
    );
};
