import { Placement } from '@floating-ui/react';
import { MotionStyle } from 'framer-motion';
import { css } from 'styled-components';

import { Color } from '@hofy/theme';

export const focusOutlineCss = css`
    &:focus-visible {
        outline: 2px solid ${Color.InteractionFocusDefault};
        outline-offset: 1px;
    }
`;

export const innerFocusOutlineCss = css`
    &:focus-visible {
        outline: 2px solid ${Color.InteractionFocusDefault};
        outline-offset: -2px;
    }
`;

export const activeScaleCss = css`
    will-change: transform;
    transition: transform 0.2s cubic-bezier(0, 0.3, 0.6, 1);
    &:active {
        transform: scale(0.97);
    }
`;

export const placementToOrigin = (placement?: Placement): MotionStyle => {
    let originX = 0;
    let originY = 0;

    if (!placement) {
        return { originX: 0.5, originY: 0.5 };
    }

    if (placement.startsWith('top')) {
        originY = 1;
    }

    if (placement.endsWith('end')) {
        originX = 1;
    }

    if (placement === 'bottom' || placement === 'top') {
        originX = 0.5;
    }

    return { originX, originY };
};
