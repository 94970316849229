import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SupplierContactPayload, suppliersCacheKey, suppliersService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateSupplierContact = (supplierId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: ([id, payload]: [UUID, SupplierContactPayload]) =>
            suppliersService.updateContact(supplierId, id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Supplier contact edited',
            });
        },
    });

    const updateContact = (id: UUID, p: SupplierContactPayload) => mutation.mutate([id, p]);
    return { updateContact };
};
