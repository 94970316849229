import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    AssignmentWithProductDto,
    CompleteShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { isSerialNumberRequired, ProductCategory } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';
import { isRequired, isRequiredIf, useForm, validateArrayField, validator } from '@hofy/ui';

export interface CompleteDeliveryShipmentFormData {
    completedOn: DateString;
    items: CompleteDeliveryShipmentItemFormData[];
    sendNotification: boolean;
    deliveryNotes: string | null;
}

export interface CompleteDeliveryShipmentItemFormData {
    assignmentId: UUID;
    category: ProductCategory;
    serialNumber: string | null;
    unknownSerialNumber: boolean;
}

export const initialCompleteDeliveryShipmentFormData = (
    shipment: ShipmentDetailsDto,
): CompleteDeliveryShipmentFormData => {
    return {
        completedOn: nowISODate(),
        sendNotification: true,
        deliveryNotes: shipment.deliveryNotes,
        items: shipment.assignments.map(initialCompleteDeliveryShipmentItemFormData),
    };
};

export const initialCompleteDeliveryShipmentItemFormData = (
    assignment: AssignmentWithProductDto,
): CompleteDeliveryShipmentItemFormData => {
    return {
        serialNumber: null,
        assignmentId: assignment.id,
        category: assignment.product.category,
        unknownSerialNumber: false,
    };
};

export const useCompleteDropshipDeliveryShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, CompleteShipmentPayload>({
        mutationFn: payload => shipmentService.completeShipment(shipment.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<CompleteDeliveryShipmentFormData>({
        initial: initialCompleteDeliveryShipmentFormData(shipment),
        onSubmit: mutation.mutate,
        validate: validator<CompleteDeliveryShipmentFormData>({
            items: validateArrayField<CompleteDeliveryShipmentFormData, 'items'>({
                fieldsValidator: validator<CompleteDeliveryShipmentItemFormData>({
                    serialNumber: isRequiredIf(
                        ({ category, unknownSerialNumber }) =>
                            !unknownSerialNumber && isSerialNumberRequired(category),
                        'Serial number is required',
                    ),
                }),
            }),
            completedOn: isRequired('Completed date must be set if status is Completed'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
