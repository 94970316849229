import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { byodOrderService } from '../service/byodOrderService';
import { BYODOrdersFilter } from './BYODOrdersFilter';
import { byodOrdersCacheKey } from './cacheKey';

export const useBYODOrders = (filters: BYODOrdersFilter) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [byodOrdersCacheKey, filters],
        queryFn: param =>
            byodOrderService.listBYODOrders(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const byodOrders = useMemo(() => {
        if (data) {
            return data.pages.flatMap(v => v.content);
        }
        return [];
    }, [data]);

    return {
        byodOrders: byodOrders,
        byodOrdersIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
