import React, { FC } from 'react';

import { ListProductFilters, ProductDto, useListProducts } from '@hofy/api-admin';
import { InfiniteScroll } from '@hofy/common';
import { SkeletonList } from '@hofy/ui';

import { ProductRow } from './ProductRow';

interface ProductPickerProps {
    listProductsOptions: Partial<ListProductFilters>;
    selected?: ProductDto;
    onPick(v: ProductDto): void;
}

export const ProductPicker: FC<ProductPickerProps> = ({ listProductsOptions, onPick, selected }) => {
    const { products, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useListProducts(listProductsOptions);

    if (isLoading) {
        return <SkeletonList items={5} height={60} gap={16} marginHorizontal={40} />;
    }

    return (
        <InfiniteScroll
            flex='auto'
            overflow='auto'
            hasMore={hasNextPage}
            isLoadingMore={isFetchingNextPage}
            loadMore={fetchNextPage}
        >
            {products.map(p => (
                <ProductRow
                    key={p.id}
                    product={p}
                    paddingLeft={20}
                    onClick={() => onPick(p)}
                    selected={selected?.id === p.id}
                />
            ))}
        </InfiniteScroll>
    );
};
