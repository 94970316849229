import { AddressDto } from '@hofy/api-shared';
import { useTrCountry } from '@hofy/i18n';

export const useAddressTooltipItems = ({
    line1,
    line2,
    city,
    postCode,
    state,
    country,
    phoneNumber,
}: AddressDto): [string, string][] => {
    const trCountry = useTrCountry();

    return [
        ['Line 1', line1],
        ['Line 2', line2],
        ['City', city],
        ['Postcode', postCode],
        ['State', state],
        ['Country', trCountry(country)],
        ['Phone number', phoneNumber],
    ].filter((item): item is [string, string] => !!item[1]);
};
