import React, { FC } from 'react';

import { FormCheckbox } from '@hofy/common';
import { FormSection, LabeledSwitch, Paragraph3, UseForm } from '@hofy/ui';

import { SendCollectionSurveyFormData } from '../../../store/shipments/useCreateShipment';

interface SendCollectionSurveyFormProps {
    showSendOption?: boolean;
    form: UseForm<SendCollectionSurveyFormData, unknown>;
}

export const SendCollectionSurveyForm: FC<SendCollectionSurveyFormProps> = ({
    showSendOption = false,
    form,
}) => {
    const formQuestions = (
        <>
            <Paragraph3>Include questions about:</Paragraph3>
            <FormCheckbox
                checked={form.values.requestPackagingInfo}
                onChange={requestPackagingInfo =>
                    form.setValues({
                        requestPackagingInfo,
                    })
                }
                disabled={!form.values.collectionRequest}
            >
                Items packaging
            </FormCheckbox>
            <FormCheckbox
                checked={form.values.requestLabelsPrintingInfo}
                onChange={requestLabelsPrintingInfo =>
                    form.setValues({
                        requestLabelsPrintingInfo,
                    })
                }
                disabled={!form.values.collectionRequest}
            >
                Printing shipment labels
            </FormCheckbox>
        </>
    );

    if (showSendOption) {
        return (
            <FormSection label='Collection survey' marginBottom={20}>
                <LabeledSwitch
                    checked={form.values.collectionRequest}
                    onChange={collectionRequest =>
                        form.setValues({
                            collectionRequest,
                        })
                    }
                    label='Send collection survey'
                />
                {form.values.collectionRequest && formQuestions}
            </FormSection>
        );
    }

    return formQuestions;
};
