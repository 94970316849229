import { AnyEnum, getEnumValues } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

import { EnumFilterDefinitionOptions } from './useSingleEnumFilterDefinition';

export const useMultiEnumFilterDefinition = <T extends string, E extends AnyEnum>({
    statusEnum,
    name,
    showInActive,
    toLabel,
}: EnumFilterDefinitionOptions<T, E>): FilterDefinitionMulti<T> => {
    return {
        type: 'multi',
        name: name ?? 'Status',
        query: queryStringArrayParser<T>(),
        allValues: getEnumValues<T>(statusEnum),
        toLabel,
        showInActive,
    };
};
