import React, { FC, memo } from 'react';

import { ItemEventDto } from '@hofy/api-admin';
import { ItemEventStatus } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    Button,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    TextCell,
    Tooltip,
    TwoLineSmallCell,
} from '@hofy/ui';

import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { ItemLocationCell } from '../../itemsPage/components/ItemLocationCell';
import { JournalLinkLabel } from '../journals/JournalLink';
import { ItemEventOwnershipCell } from './components/ItemEventOwnershipCell';
import { ItemEventStatusChip } from './components/ItemEventStatusChip';

interface ItemEventsTableProps {
    itemEvents: ItemEventDto[];
    infinityScroll: InfiniteScrollConfig;
    onShowManualFixNotes(itemEvent: ItemEventDto): void;
}

const ItemEventsTableComponent: FC<ItemEventsTableProps> = ({
    itemEvents,
    infinityScroll,
    onShowManualFixNotes,
}) => {
    const { formatPrice } = usePrice();

    return (
        <InfinityScrollTable
            data={itemEvents}
            toKey={itemEvent => itemEvent.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.List}
                    title='No item events'
                    message='No item events for selected criteria'
                />
            }
            flex='auto'
            columns={[
                {
                    id: 'id',
                    header: 'Id',
                    flexGrow: 0,
                    width: 100,
                    renderer: itemEvent => (
                        <TwoLineSmallCell
                            line1={`#${itemEvent.id}`}
                            line2={itemEvent.previousEventId ? `#${itemEvent.previousEventId}` : ''}
                        />
                    ),
                },
                {
                    id: 'createdAt',
                    header: 'Date',
                    flexGrow: 2,
                    renderer: itemEvent => formatDateTime(itemEvent.createdAt),
                },
                {
                    id: 'itemId',
                    header: 'Item',
                    flexGrow: 2,
                    renderer: itemEvent => itemEvent.item.publicId,
                },
                {
                    id: 'value',
                    header: 'Item value',
                    width: 130,
                    flexGrow: 0,
                    renderer: itemEvent => formatPrice(itemEvent.currentValue),
                },
                {
                    id: 'itemStats',
                    header: 'Item status',
                    flexGrow: 1,
                    width: 130,
                    renderer: itemEvent => <ItemStatusChip status={itemEvent.itemStatus} />,
                },
                {
                    id: 'location',
                    header: 'Item location',
                    width: 150,
                    flexGrow: 1,
                    renderer: itemEvent => <ItemLocationCell location={itemEvent.location} />,
                },
                {
                    id: 'hofySubsidiary',
                    header: 'Item ownership',
                    width: 130,
                    flexGrow: 0,
                    renderer: itemEvent => (
                        <ItemEventOwnershipCell
                            entry={{
                                ...itemEvent,
                                ownership: itemEvent.ownership.type,
                                billingEntity: null,
                            }}
                        />
                    ),
                },
                {
                    id: 'rule',
                    header: 'Rule name',
                    renderer: itemEvent => itemEvent.appliedRule ?? '--',
                },
                {
                    id: 'status',
                    header: 'Status',
                    renderer: itemEvent => (
                        <ItemEventStatusChip status={itemEvent.status} errorReason={itemEvent.errorReason} />
                    ),
                },
                {
                    id: 'journalId',
                    header: 'Journal',
                    flexGrow: 0,
                    width: 120,
                    renderer: itemEvent => (
                        <TextCell>
                            <JournalLinkLabel id={itemEvent.journalId} />
                        </TextCell>
                    ),
                },
                {
                    id: 'manual-fix-data',
                    header: 'Fix data',
                    renderer: itemEvent => (
                        <Tooltip body='See manual fix details'>
                            <Button
                                type='plain'
                                onClick={() => onShowManualFixNotes(itemEvent)}
                                leftIcon={SvgIcon.InfoCircle}
                                disabled={itemEvent.status !== ItemEventStatus.ManualFix}
                            />
                        </Tooltip>
                    ),
                },
            ]}
        />
    );
};

export const ItemEventsTable = memo(ItemEventsTableComponent);
