export const assetsGradeI18n = {
    'asset-grade.new': 'New',
    'asset-grade.a': 'Grade A',
    'asset-grade.b': 'Grade B',
    'asset-grade.c': 'Grade C',
    'asset-grade.d': 'Grade D',
    'asset-grade.f': 'Grade F',

    'asset-grade-label.new': 'New',
    'asset-grade-label.a': 'Excellent condition',
    'asset-grade-label.b': 'Good condition',
    'asset-grade-label.d': 'Significant damage',
    'asset-grade-label.f': 'Faulty',
};
