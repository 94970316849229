import React, { FC } from 'react';

import {
    ItemContractWithPurchaseDetails,
    ItemContractWithRentalDetails,
    ItemRentalContractDetails,
} from '@hofy/api-admin';
import { ContractType, RentalContractStatus } from '@hofy/api-shared';
import { RangeBar, RangeBarBackground } from '@hofy/common';
import { DateString, formatDecimalWithCurrency, Price, priceToNumber } from '@hofy/global';
import { parseDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, Paragraph4, Tooltip } from '@hofy/ui';

import { PairsTableTooltipOverlay } from '../../../components/design/tooltips/PairsTableTooltipOverlay';

interface ItemContractStatusCellProps {
    contractDetails: ItemContractWithRentalDetails | ItemContractWithPurchaseDetails;
}

export const ItemContractCell: FC<ItemContractStatusCellProps> = ({ contractDetails }) => {
    const tooltip = [
        ['Contract', `#${contractDetails.id}`],
        ['Organization', `${contractDetails.organization?.name}`],
    ] as [string, string][];

    return (
        <Tooltip bodySlot={<PairsTableTooltipOverlay items={tooltip} />} maxWidth='auto' interactive asChild>
            <Box column fullWidth>
                {contractDetails.type === ContractType.Purchase && (
                    <Paragraph3 color={Color.ContentPrimary} bold>
                        Purchase
                    </Paragraph3>
                )}
                {contractDetails.type === ContractType.Rental && (
                    <RentalCell contractDetails={contractDetails} />
                )}
                <Paragraph4>{contractDetails.organization?.name}</Paragraph4>
            </Box>
        </Tooltip>
    );
};

interface RentalCellProps {
    contractDetails: ItemContractWithRentalDetails;
}

const RentalCell: FC<RentalCellProps> = ({ contractDetails }) => {
    const { sub } = usePrice();
    const rentalDetails = contractDetails.rentalContract;
    const getPaymentDetails = (paidAmount: Price, total: Price): string => {
        return formatDecimalWithCurrency(paidAmount.amount, paidAmount.currency) + '/' + total.amount;
    };
    const remaining = rentalDetails.remainingMonths;
    const activeStatus = contractDetails.rentalContract.status === RentalContractStatus.Active;
    const contractDescription = activeStatus
        ? getPaymentDetails(
              sub(rentalDetails.totalAmount!, rentalDetails.remainingAmount!),
              rentalDetails.totalAmount!,
          )
        : 'Inactive';

    const calculatePercentage = (contractDetails: ItemRentalContractDetails): string => {
        if (!activeStatus) {
            return '100%';
        }

        if (contractDetails.totalAmount !== null && contractDetails.remainingAmount !== null) {
            const paidAmount =
                priceToNumber(contractDetails.totalAmount) - priceToNumber(contractDetails.remainingAmount);

            const percentage = (paidAmount / priceToNumber(contractDetails.totalAmount)) * 100;

            return `${Number(percentage)}%`;
        }

        return '0%';
    };

    const label = () => {
        if (!activeStatus) {
            return 'Ended';
        }
        return `${parseDateTime(rentalDetails.endDate.toLocaleString() as DateString).toFormat(
            'LLL yyyy',
        )} (in ${remaining} ${remaining === 1 ? 'month' : 'months'})`;
    };
    return (
        <>
            <Paragraph3 color={Color.ContentPrimary} bold>
                {contractDescription}
            </Paragraph3>
            <RangeBarBackground row fullWidth marginTop={4} bg={Color.NonContextualGreySubtle}>
                <RangeBar
                    height={5}
                    fullWidth
                    minWidth={5}
                    width={calculatePercentage({
                        ...rentalDetails,
                        type: ContractType.Rental,
                    })}
                    bg={Color.AccentGreen}
                />
            </RangeBarBackground>
            <Paragraph4 color={Color.ContentPrimary} marginTop={2}>
                {label()}
            </Paragraph4>
        </>
    );
};
