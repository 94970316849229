import { restClient } from '@hofy/rest';

import { TwoFactorConfigDto } from './types/twoFactor/TwoFactorConfigDto';
import { TwoFactorEnablePayload } from './types/twoFactor/TwoFactorEnablePayload';
import { TwoFactorVerifyPayload } from './types/twoFactor/TwoFactorVerifyPayload';

class TwoFactorService {
    public generateConfig = (): Promise<TwoFactorConfigDto> => {
        return restClient.postJson<TwoFactorConfigDto>('/api/auth/2fa/generate-config');
    };

    public validateTwoFA = (p: TwoFactorVerifyPayload): Promise<boolean> => {
        return restClient.postJson<{ valid: boolean }>('/api/auth/2fa/verify', p).then(v => v.valid);
    };

    public enableTwoFactor = (p: TwoFactorEnablePayload): Promise<void> => {
        return restClient.post('/api/auth/2fa/enable', p);
    };

    public disableTwoFactor = (): Promise<void> => {
        return restClient.post('/api/auth/2fa/disable');
    };
}

export const twoFactorService = new TwoFactorService();
