import { ColorDye, getEnumValues } from '@hofy/global';

export enum PaymentStatusFilter {
    Processing = 'processing',
    Succeeded = 'succeeded',
    Failed = 'failed',
    None = 'none',
}

export const paymentStatusFilterColours: Record<PaymentStatusFilter, ColorDye> = {
    [PaymentStatusFilter.Processing]: 'orange',
    [PaymentStatusFilter.Succeeded]: 'green',
    [PaymentStatusFilter.Failed]: 'red',
    [PaymentStatusFilter.None]: 'grey',
};

export const allPaymentStatusFilters = getEnumValues<PaymentStatusFilter>(PaymentStatusFilter);
