import { useQuery } from '@tanstack/react-query';

import { jobService } from '../service/jobService';
import { JobContext } from '../service/types/JobDto';
import { jobsCacheKey } from './jobsCacheKey';

export const useJobsSummary = (context: JobContext[]) => {
    const { data, isLoading } = useQuery({
        queryKey: [jobsCacheKey, ...context],
        queryFn: () => jobService.getJobsSummary(context),
    });
    return {
        isLoading,
        data: data || null,
    };
};
