import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { Spinner } from '@hofy/ui';

import { useAdminSyncProductsToNetsuite } from '../../../store/products/useAdminSyncProductsToNetsuite';

export const ProductsPageMenu: FC = () => {
    const { hasPermission } = usePermission();

    const { syncToNetsuite, isLoading } = useAdminSyncProductsToNetsuite();

    if (isLoading) {
        return <Spinner size={16} />;
    }

    return (
        <MoreMenu
            items={[
                {
                    action: () => syncToNetsuite(),
                    label: 'Sync to Netsuite',
                    visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
                },
            ]}
        />
    );
};
