import { useMutation, useQueryClient } from '@tanstack/react-query';

import { journalsCacheKey, journalsService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncJournalsToNetsuite = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: journalsService.syncAllToNetsuite,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [journalsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Journals scheduled to sync to Netsuite',
            });
        },
    });

    return {
        syncToNetsuite: mutation.mutate,
        syncToNetsuiteIsLoading: mutation.isPending,
    };
};
