import React, { FC } from 'react';

import { UserStatus, userStatusColor, useTrUserStatus } from '@hofy/api-shared';
import { Badge, TestKeyAware } from '@hofy/ui';

interface UserStatusChipProps extends TestKeyAware {
    status: UserStatus;
}

export const UserStatusChip: FC<UserStatusChipProps> = ({ status, testKey }) => {
    const tr = useTrUserStatus();
    return <Badge data-test-key={testKey} label={tr(status)} color={userStatusColor[status]} />;
};
