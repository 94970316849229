import { UUID } from '@hofy/global';

import { UsePurchaseOrderForm } from './useCreateMultipartPurchaseOrderForm';
import { PurchaseOrderHeaderFormData } from './usePurchaseOrderHeaderForm';
import { PurchaseOrderItemsFormData } from './usePurchaseOrderItemsForm';
import { useUpdatePurchaseOrder } from './useUpdatePurchaseOrder';

export const useUpdateDraftPurchaseOrder = (formWrapper: UsePurchaseOrderForm, purchaseOrderId: UUID) => {
    const { updatePurchaseOrder, isLoading } = useUpdatePurchaseOrder(purchaseOrderId);

    const saveAsDraft = () => {
        const headerData = formWrapper.form.forms.header.values as PurchaseOrderHeaderFormData;
        const itemData = formWrapper.form.forms.items.values as PurchaseOrderItemsFormData;

        updatePurchaseOrder({
            ...headerData,
            ...itemData,
            isDraft: true,
        });
    };

    return {
        isLoading,
        saveAsDraft,
    };
};
