export const accountKeyI18n = {
    'account-key.accrued-purchases': 'Accrued purchases',
    'account-key.accounts-payable': 'Accounts payable',
    'account-key.inventory-stock-held-for-sale': 'Inventory stock held for sale',
    'account-key.inventory-in-transit': 'Inventory in transit',
    'account-key.inventory-adjustment': 'Inventory adjustment',
    'account-key.rental-assets-additions': 'Rentals assets additions',
    'account-key.rental-assets-depreciation-charge': 'Rentals assets depreciation charge',
    'account-key.cogs-rental-assets-depreciation': 'COGS rentals assets depreciation',
    'account-key.cogs-rental-assets-write-off': 'COGS rental assets write-off',
    'account-key.cogs-rental-assets-write-down': 'COGS rental assets write-down',
    'account-key.cogs-inter-company-sale': 'COGS inter-company sale',
    'account-key.cogs-logistics': 'COGS logistics',
    'account-key.hofy-assets-additions': 'Hofy assets additions',
    'account-key.hofy-assets-depreciation-charge': 'Hofy assets depreciation charge',
    'account-key.expense-hofy-assets-depreciation': 'Expense Hofy assets depreciation',
    'account-key.expense-hofy-assets-write-off': 'Expense Hofy assets write-off',
    'account-key.expense-hofy-assets-write-down': 'Expense Hofy assets write-down',
    'account-key.cogs-equipment-clearance': 'COGS equipment clearance',
    'account-key.cogs-equipment-sale': 'COGS equipment sale  (Purchase)',
    'account-key.cogs-end-of-contract-equipment-sale': 'COGS equipment sale (End of contract)',
    'account-key.accounts-receivable': 'Accounts receivable',
    'account-key.inventory-write-off': 'Inventory write-off',
    'account-key.deferred-income': 'Deferred income',
    'account-key.revenue-saas': 'Revenue SaaS',
    'account-key.revenue-mdm': 'Revenue MDM',
    'account-key.revenue-rental': 'Revenue rental',
    'account-key.revenue-service-bundle': 'Revenue service bundle',
    'account-key.revenue-extended-warranty': 'Revenue extended warranty',
    'account-key.revenue-storage': 'Revenue storage',
    'account-key.revenue-clearance': 'Revenue clearance',
    'account-key.bank-gbp-hofy-ltd': 'Bank GBP Hofy Ltd',
    'account-key.bank-eur-hofy-ltd': 'Bank EUR Hofy Ltd',
    'account-key.bank-usd-hofy-ltd': 'Bank USD Hofy Ltd',
    'account-key.bank-eur-hofy-bv': 'Bank EUR Hofy BV',
    'account-key.bank-usd-hofy-bv': 'Bank USD Hofy BV',
    'account-key.bank-gbp-hofy-bv': 'Bank GBP Hofy BV',
    'account-key.bank-usd-hofy-inc': 'Bank USD Hofy Inc',
    'account-key.bank-eur-hofy-inc': 'Bank EUR Hofy Inc',
    'account-key.bank-gbp-hofy-inc': 'Bank GBP Hofy Inc',
    'account-key.stripe-hofy-ltd': 'Stripe Hofy Ltd',
    'account-key.stripe-hofy-bv': 'Stripe Hofy BV',
    'account-key.stripe-hofy-inc': 'Stripe Hofy Inc',
    'account-key.bank-fees-stripe': 'Bank fees Stripe',
    'account-key.two-recourse-repayment': 'Two recourse repayment',
    'account-key.two-non-recourse-repayment': 'Two non-recourse repayment',
    'account-key.two-api-bank-fees': 'Two api bank fees',
    'account-key.two-spreadsheet-bank-fees': 'Two spreadsheet bank fees',
    'account-key.two-advance-gross': 'Two advance gross',
    'account-key.two-advance-fees': 'Two advance fees',
};
