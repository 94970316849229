import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, shipmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateShipmentAddressToCollectionUserAddress = (shipmentId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: shipmentService.updateShipmentAddressToCollectionUserAddress,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Collection address updated successfully',
            });
            onSuccess?.();
        },
    });

    return {
        updateShipmentAddressToCollectionUserAddress: () => mutation.mutate(shipmentId),
        isLoadingMutation: mutation.isPending,
    };
};
