import React, { FC } from 'react';

import { Country } from '@hofy/global';

import { MarginBoxProps } from '../base';
import { Flag } from './Flag';
import { FlagImages } from './FlagImages';

interface CountryFlagProps extends MarginBoxProps {
    country?: Country | null;
    size?: number;
}

export const CountryFlag: FC<CountryFlagProps> = ({ country, size, ...boxProps }) => {
    const src = country ? FlagImages[country] : FlagImages.None;
    return <Flag size={size} src={src} {...boxProps} />;
};
