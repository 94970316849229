import {
    AddressPayload,
    AddressType,
    emptyAddressPayload,
    PaymentSchema,
    RentalTerm,
} from '@hofy/api-shared';
import { Multiplier, Percent, Price, UUID, zeroDollars, zeroUuid } from '@hofy/global';

import { ContractAddonDto } from './ContractDetailsDto';

export interface UpdateContractPayload {
    billingToAddress: AddressPayload;
    rentalTerm: RentalTerm | null;
    basePrice: Price;
    unitPrice: Price;
    rolloverPrice: Price | null;
    price: Price;
    multiplier: Multiplier;
    paymentSchema: PaymentSchema;
    customDiscount: Percent;
    paymentDiscount: Percent;
    discountReason: string | null;
    hofySubsidiaryId: UUID;
    billingEntityId: UUID;
    addons: ContractAddonDto[];
}

export const emptyContractPayload: UpdateContractPayload = {
    billingToAddress: emptyAddressPayload(AddressType.Billing),
    rentalTerm: RentalTerm.Rental12Months,
    basePrice: zeroDollars,
    unitPrice: zeroDollars,
    price: zeroDollars,
    rolloverPrice: null,
    multiplier: '1.00',
    paymentSchema: PaymentSchema.Annual,
    customDiscount: '0.00',
    paymentDiscount: '0.00',
    discountReason: null,
    hofySubsidiaryId: zeroUuid,
    billingEntityId: zeroUuid,
    addons: [],
};

export interface AutoUpdateContractPayload {
    billingToAddress: AddressPayload;
    rentalTerm: RentalTerm;
    paymentSchema: PaymentSchema;
}

export const emptyAutoContractPayload: AutoUpdateContractPayload = {
    billingToAddress: emptyAddressPayload(AddressType.Billing),
    rentalTerm: RentalTerm.Rental12Months,
    paymentSchema: PaymentSchema.Annual,
};

export interface UpdatePurchaseContractPayload {
    basePrice: Price;
    unitPrice: Price;
    price: Price;
    storefrontFee: Percent;
    countryMultiplier: Multiplier;
}
