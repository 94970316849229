import { getEnumValues } from '@hofy/global';

export enum AdminInvoicingTab {
    Invoices = 'invoices',
    InvoiceEntries = 'invoice-entries',
    RepaymentPlans = 'repayment-plans',
    BillingEntities = 'billing-entities',
    SalesOrders = 'sales-orders',
}

export const allInvoicingTabs = getEnumValues<AdminInvoicingTab>(AdminInvoicingTab);
