import { capitalize } from 'lodash';
import React, { FC } from 'react';

import { MessageStatus, messageStatusColor } from '@hofy/api-admin';
import { Badge } from '@hofy/ui';

interface MessageStatusChipProps {
    status: MessageStatus;
}

export const MessageStatusChip: FC<MessageStatusChipProps> = ({ status }) => {
    return <Badge label={capitalize(status)} color={messageStatusColor[status]} />;
};
