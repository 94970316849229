import React, { FC } from 'react';

import { HofySubsidiaryRef, Ownership } from '@hofy/api-shared';
import { Paragraph3 } from '@hofy/ui';

interface ItemEventSubsidiaryCellProps {
    entry: {
        hofySubsidiary: HofySubsidiaryRef | null;
        billingEntity: {
            id: number;
            name?: string | null;
        } | null;
        ownership: Ownership;
    };
}

export const ItemEventOwnershipCell: FC<ItemEventSubsidiaryCellProps> = ({
    entry: { ownership, billingEntity, hofySubsidiary },
}) => {
    if (ownership === Ownership.Hofy) {
        return <Paragraph3>{hofySubsidiary?.name || 'Hofy'}</Paragraph3>;
    }

    return <Paragraph3>{billingEntity?.name || 'Organization'}</Paragraph3>;
};
