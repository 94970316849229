import React, { FC, ReactNode } from 'react';

import { renderTextNode } from '../../../helpers';
import { TestKeyAware } from '../../../types';
import { Box, Heading3, Paragraph3 } from '../../base';
import { Illustration, SvgIllustration } from '../../illustration';

export interface PlaceholderProps extends TestKeyAware {
    illustration: SvgIllustration;
    title: ReactNode;
    message?: ReactNode;
    children?: ReactNode;
}

export const Placeholder: FC<PlaceholderProps> = ({ illustration, title, message, children, testKey }) => {
    return (
        <Box flex={1} centered>
            <Box column alignItems='center' maxWidth={500} data-test-key={testKey}>
                <Illustration svg={illustration} />
                <Heading3 textAlign='center' marginTop={10}>
                    {title}
                </Heading3>
                {renderTextNode(message, text => {
                    return (
                        <Paragraph3 marginTop={12} textAlign='center'>
                            {text}
                        </Paragraph3>
                    );
                })}
                {children && (
                    <Box marginTop={30} column alignItems='center'>
                        {children}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
