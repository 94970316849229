import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { TestKeyAware } from '@hofy/ui';

import { CheckableContent } from './checkbox/CheckableContent';

export interface RadioProps extends TestKeyAware {
    checked: boolean;
    onChange?(checked: boolean): void;
    disabled?: boolean;
    children?: ReactNode;
}

/** @deprecated use `Radio` from `@hofy/ui` instead */
export const Radio: FC<RadioProps> = ({ children, checked, onChange, disabled = false, testKey }) => {
    return (
        <RadioContainer
            disabled={disabled}
            cursor={disabled ? 'not-allowed' : 'pointer'}
            data-test-key={testKey}
        >
            <CheckableContent type='radio' checked={checked} onChange={onChange} disabled={disabled}>
                {children}
            </CheckableContent>
        </RadioContainer>
    );
};

const RadioContainer = styled.div<{ disabled: boolean; cursor: 'not-allowed' | 'pointer' }>`
    > input {
        display: none;
    }

    > label {
        cursor: ${p => p.cursor};
        display: flex;
        flex-direction: row;
        position: relative;
        padding-left: 30px;
        line-height: 24px;

        &::before,
        &::after {
            cursor: ${p => p.cursor};
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 50% 50%;
            content: '';
        }

        &::before {
            cursor: ${p => p.cursor};
            width: 15px;
            height: 15px;
            border-radius: 8px;
            border: 1px solid ${p => (p.disabled ? Color.Neutral200 : Color.Radio)};
            background: ${Color.BackgroundDefault};
            transform: translate(0, -50%);
        }

        &::after {
            cursor: ${p => p.cursor};
            transition: opacity 200ms ease;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: ${Color.Radio};
            transform: translate(0, -50%);
            left: 5px;
            opacity: 0;
        }
    }

    > input:checked + label::before {
        cursor: ${p => p.cursor};
        border-color: ${p => (p.disabled ? Color.Neutral200 : Color.Purple)};
    }

    > input:checked + label::after {
        cursor: ${p => p.cursor};
        background: ${p => (p.disabled ? Color.Neutral200 : Color.Purple)};
        opacity: 1;
    }
`;
