import React, { FC, ReactNode } from 'react';

import { Tooltip } from '@hofy/ui';

import { IconItem, IconItemProps } from './IconItem';

interface IconItemWithTooltipProps extends IconItemProps {
    tooltip: ReactNode;
}

export const IconItemWithTooltip: FC<IconItemWithTooltipProps> = ({
    tooltip,
    children,
    ...iconItemProps
}) => {
    const isString = typeof tooltip === 'string';
    return (
        <Tooltip
            placement='top-start'
            title={isString ? tooltip : undefined}
            bodySlot={!isString && tooltip}
            maxWidth='auto'
            enabled={!!tooltip}
        >
            <IconItem {...iconItemProps}>{children}</IconItem>
        </Tooltip>
    );
};
