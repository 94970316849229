import { useQuery } from '@tanstack/react-query';

import { organizationAddonConfigService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { organizationAddonConfigCacheKey } from './organizationAddonConfigCacheKey';

export const useOrganizationAddonConfigQuery = (orgId: UUID, addonId: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [organizationAddonConfigCacheKey, orgId, addonId],
        queryFn: () => organizationAddonConfigService.getOrganizationAddonConfiguration(orgId, addonId),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
