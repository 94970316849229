import React, { FC } from 'react';

import { Box, BoxProps, Checkbox } from '@hofy/ui';

interface AssignmentSelectorCellProps extends BoxProps {
    isAssignmentSelected: boolean;
    hidden?: boolean;
}

export const AssignmentSelectorCell: FC<AssignmentSelectorCellProps> = ({
    isAssignmentSelected,
    hidden = false,
    ...boxProps
}) => {
    return (
        <Box alignItems='center' height={20} width={20} {...boxProps} data-test-key='checkbox'>
            {!hidden && <Checkbox checked={isAssignmentSelected} />}
        </Box>
    );
};
