import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Country } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, CountryFlag, Paragraph3, Paragraph4 } from '@hofy/ui';

interface LocationCardProps {
    icon: ReactNode;
    label?: string;
    name: string;
    country?: Country;
    link?: string;
    children?: ReactNode;
}

export const LocationCard: FC<LocationCardProps> = ({ icon, label, name, country, link, children }) => {
    const content = (
        <Box row gap={12} overflow='hidden' data-test-key='location-card'>
            {icon}
            <Box column flex='auto'>
                {label && <Paragraph4 data-test-key='label'>{label}</Paragraph4>}
                <Box row gap={8}>
                    {country && <CountryFlag country={country} size={12} />}
                    <Paragraph3
                        textNoWrap
                        overflow='hidden'
                        ellipsis
                        color={Color.ContentPrimary}
                        underline={!!link}
                        data-test-key='name'
                    >
                        {name}
                    </Paragraph3>
                </Box>
                {children}
            </Box>
        </Box>
    );

    return link ? <Link to={link}>{content}</Link> : content;
};
