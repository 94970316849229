import styled, { css } from 'styled-components';

export const CenteredBox = styled.div<{ absolute?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    ${p =>
        p.absolute &&
        css`
            position: absolute;
            top: 0;
            left: 0;
        `}
`;
