import { restClient } from '@hofy/rest';

import { OneOffEventDto } from './types/OneOffEventDto';
import { SendOneOffPayload } from './types/SendOneOffPayload';

class NotificationService {
    public getOneOffs = (): Promise<OneOffEventDto[]> => {
        return restClient.getJson<OneOffEventDto[]>('/api/admin/events/one-offs');
    };

    public sendOneOff = (p: SendOneOffPayload): Promise<void> => {
        return restClient.postJson('/api/admin/events/one-offs', p).then();
    };
}

export const notificationService = new NotificationService();
