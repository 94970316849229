import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Button, PageHeader, SearchInput, SvgIcon } from '@hofy/ui';

import { PeopleTab } from '../PeopleTab';
import { PeopleTabs } from '../PeopleTabs';
import { AdminsTable } from './AdminsTable';

interface AdminsPageProps {
    onOpenAdmin(id: UUID): void;
    onCreate(): void;
}

export const AdminsPage: FC<AdminsPageProps> = ({ onOpenAdmin, onCreate }) => {
    const [search, setSearch] = useState<string>('');
    const { hasPermission } = usePermission();
    return (
        <Page>
            <PageHeader
                title='Admins'
                rightSlot={
                    <>
                        <SearchInput
                            value={search}
                            onChange={setSearch}
                            placeholder='Admin name or email…'
                            autoFocus
                        />
                        <Button
                            label='Create'
                            leftIcon={SvgIcon.Add}
                            onClick={() => onCreate()}
                            disabled={!hasPermission(Permission.AdminAdminsUpdate)}
                        />
                    </>
                }
                tabsSlot={<PeopleTabs active={PeopleTab.Admins} />}
            />
            <AdminsTable onOpenAdmin={onOpenAdmin} search={search} />
        </Page>
    );
};
