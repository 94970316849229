import React, { FC } from 'react';

import { UUID } from '@hofy/global';

import { Tooltip } from '../../tooltip/index';
import { BaseTableColumnConfig } from '../types/BaseTableColumnConfig';
import { TextCell } from './TextCell';

interface IdCellProps {
    id: UUID;
    publicId?: string;
}

export const IdCell: FC<IdCellProps> = ({ id, publicId }) => {
    return (
        <Tooltip body={id}>
            <TextCell>{publicId || id.substring(0, 8).toUpperCase()}</TextCell>
        </Tooltip>
    );
};

interface IdAware {
    id: UUID;
    publicId?: string;
}

export const IdColumn = <T extends IdAware>(): BaseTableColumnConfig<T> => ({
    id: 'id',
    header: 'Id',
    flexGrow: 0,
    width: 120,
    renderer: i => <IdCell id={i.id} publicId={i.publicId} />,
});

interface PublicIdAware {
    id: UUID;
    publicId: string;
}

export const PublicIdColumn = <T extends PublicIdAware>(): BaseTableColumnConfig<T> => ({
    id: 'id',
    header: 'Id',
    flexGrow: 0,
    width: 140,
    renderer: i => i.publicId,
});
