import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { getGradesForCondition } from '../../item/service/types/ItemCondition';
import { AdminItemEventsFilters } from './types/AdminItemEventsFilters';
import { ItemEventDto } from './types/ItemEventDto';
import { ItemValuationDto, TotalSubsidiaryValuationDto } from './types/ItemValuationDto';
import { ItemValuationFilters } from './types/ItemValuationFilters';

class ItemEventService {
    public listItemValuation = ({ condition, ...filters }: ItemValuationFilters, page: PageRequest) => {
        return restClient.getJson<PageResponse<ItemValuationDto>>(
            stringifyUrl({
                url: '/api/admin/item-events/item-valuation',
                query: {
                    ...filters,
                    ...page,
                    grades: getGradesForCondition(condition),
                },
            }),
        );
    };

    public getTotalItemValuation = async ({ condition, ...filters }: ItemValuationFilters) => {
        return restClient.getJson<TotalSubsidiaryValuationDto[]>(
            stringifyUrl({
                url: '/api/admin/item-events/total-item-valuation',
                query: {
                    ...filters,
                    grades: getGradesForCondition(condition),
                },
            }),
        );
    };

    public listItemEvents = async (
        filters: AdminItemEventsFilters,
        page: PageRequest,
    ): Promise<PageResponse<ItemEventDto>> => {
        return restClient.getJson<PageResponse<ItemEventDto>>(
            stringifyUrl({
                url: '/api/admin/item-events',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };
}

export const itemEventService = new ItemEventService();
