export const paymentErrorI18n = {
    'api-error.invalid-file-mime-type.title': 'Invalid file mime type',
    'api-error.invalid-file-mime-type.message': 'Cannot perform this operation',
    'api-error.invalid-file-header.title': 'Invalid file header',
    'api-error.invalid-file-header.message': 'Cannot perform this operation',
    'api-error.invalid-file-sheet-count.title': 'Invalid file sheet count',
    'api-error.invalid-file-sheet-count.message': 'Cannot perform this operation',
    'api-error.invalid-filename.title': 'Invalid filename',
    'api-error.invalid-filename.message': 'Cannot perform this operation',
    'api-error.invalid-row-currency.title': 'Invalid row currency',
    'api-error.invalid-row-currency.message': 'Cannot perform this operation',
    'api-error.invalid-row-company-alias.title': 'Invalid row company alias',
    'api-error.invalid-row-company-alias.message': 'Cannot perform this operation',
    'api-error.invalid-group-negative-amount.title': 'Invalid group negative amount',
    'api-error.invalid-group-negative-amount.message': 'Cannot perform this operation',
    'api-error.payment-provider-not-enabled.title': 'Payment provider not enabled',
    'api-error.payment-provider-not-enabled.message': 'Cannot perform this operation',
};
