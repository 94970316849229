import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    organizationService,
    UpdateOrganizationCurrencyPayload,
} from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationCurrency = (
    organization: OrganizationDetailsDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationCurrencyPayload) =>
            organizationService.updateOrganizationCurrency(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                type: 'positive',
                message: `Organization currency successfully updated`,
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateOrganizationCurrencyPayload>({
        initial: {
            currency: organization.currency,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
