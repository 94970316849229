import { ColorDye, DateTimeString } from '@hofy/global';

export enum TwoSyncStatus {
    Success = 'success',
    Error = 'error',

    Pending = 'pending',
    PartiallySynced = 'partially_synced',
}

export const twoStatusColors: Record<TwoSyncStatus, ColorDye> = {
    [TwoSyncStatus.Success]: 'green',
    [TwoSyncStatus.Error]: 'red',
    [TwoSyncStatus.Pending]: 'orange',
    [TwoSyncStatus.PartiallySynced]: 'orange',
};

export interface TwoStatusDto {
    id: string; // uuid
    status: TwoSyncStatus | null;
    successAt: DateTimeString | null;
    errorAt: DateTimeString | null;
    errorReason: string | null;
}
