import { publicRestClient } from '@hofy/rest';

interface Version {
    version: string;
}

class VersionService {
    public getCurrentVersion = async (): Promise<string> => {
        const response = await publicRestClient.getJson<Version>('/version');
        return response.version;
    };
}

export const versionService = new VersionService();
