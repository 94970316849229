import React, { FC } from 'react';

import { VisualType } from '@hofy/global';
import { Color } from '@hofy/theme';

import { visualTypeIcon } from '../../config/visualTypeIcon';
import { Box, Paragraph3, Paragraph4, SectionTitle3 } from '../base';
import { FeaturedIcon, IconButton, SvgIcon } from '../icon';

export interface ToastProps {
    /** Use same id to avoid multiple toasts with the same message */
    id: string;
    type: VisualType;
    title?: string;
    message: string;
    onClose(): void;
    /** Only used internally for animation purposes */
    tmpKey?: string;
}

export const Toast: FC<ToastProps> = ({ id, tmpKey, title, message, type, onClose }) => {
    const icon = visualTypeIcon[type];

    return (
        <Box
            key={tmpKey || id}
            row
            gap={16}
            padding={8}
            bg={Color.BackgroundDefault}
            rounded={8}
            width={400}
            elevation='+2'
            border
            data-test-key='toast-item'
            data-test-value={type}
        >
            <FeaturedIcon icon={icon} type={type} variant='vivid' shape='square' size={40} />
            <Box column gap={2} flex={1}>
                {title ? (
                    <>
                        <SectionTitle3>{title}</SectionTitle3>
                        <Paragraph4 color={Color.ContentPrimary}>{message}</Paragraph4>
                    </>
                ) : (
                    <Paragraph3 color={Color.ContentPrimary}>{message}</Paragraph3>
                )}
            </Box>
            <IconButton icon={SvgIcon.Cross} color={Color.ContentPrimary} onClick={onClose} marginRight={8} />
        </Box>
    );
};
