import React, { FC } from 'react';

import { PurchaseOrderApprovalDto, PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { useTrRole } from '@hofy/api-shared';
import { ColorDye } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

interface PurchaseOrderApprovalsProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

enum POApprovalStatus {
    AutoApproved = 'Auto-approved',
    Approved = 'Approved',
    Declined = 'Declined',
    Waiting = 'Waiting',
}

interface ApprovalStatus {
    approval: POApprovalStatus;
    role: string;
    onDate: string;
    by?: string;
}

const approvalStatusColors: Record<POApprovalStatus, ColorDye> = {
    [POApprovalStatus.Declined]: 'red',
    [POApprovalStatus.Approved]: 'green',
    [POApprovalStatus.AutoApproved]: 'green',
    [POApprovalStatus.Waiting]: 'orange',
};

export const PurchaseOrderApprovals: FC<PurchaseOrderApprovalsProps> = ({ purchaseOrder }) => {
    const trRole = useTrRole();

    const getStatus = (approval: PurchaseOrderApprovalDto): ApprovalStatus => {
        if (approval.approvedAt) {
            return {
                approval: POApprovalStatus.Approved,
                role: trRole(approval.requiredRole[0]),
                by: `${approval.approvedBy?.firstName} ${approval.approvedBy?.lastName}`,
                onDate: formatDate(approval.approvedAt),
            };
        } else if (approval.declinedAt) {
            return {
                approval: POApprovalStatus.Declined,
                role: trRole(approval.requiredRole[0]),
                by: `${approval.declinedBy?.firstName} ${approval.declinedBy?.lastName}`,
                onDate: formatDate(approval.declinedAt),
            };
        }
        return {
            approval: POApprovalStatus.Waiting,
            role: trRole(approval.requiredRole[0]),
            onDate: formatDate(purchaseOrder.orderedOn),
        };
    };

    const renderStatus = (status: POApprovalStatus) => {
        const color = approvalStatusColors[status];
        return <Badge color={color} label={status} />;
    };

    const renderApprovalStatuses = purchaseOrder.purchaseOrderApprovals.map((approval, index) => {
        const status = getStatus(approval);
        const showLabel = index === 0;
        return (
            <FormGridRow key={approval.id} columns={4}>
                <Labeled content={renderStatus(status.approval)} label={showLabel && 'Status'} />
                <LabeledText content={status.role} label={showLabel && 'Role'} />
                <LabeledText content={status.by} label={showLabel && 'By'} />
                <LabeledText content={status.onDate} label={showLabel && 'Date'} />
            </FormGridRow>
        );
    });

    if (purchaseOrder.purchaseOrderApprovals.length === 0) {
        return <Box width={200}>{renderStatus(POApprovalStatus.AutoApproved)}</Box>;
    }

    return <>{renderApprovalStatuses}</>;
};
