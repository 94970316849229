import React, { FC } from 'react';

import { useSalesOrderQuery } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, FormGridRow, FormSection } from '@hofy/ui';

import { SalesOrderDetailsInfo } from '../SalesOrderDetailsInfo';
import { SalesOrderPaymentSessionInfo } from '../SalesOrderPaymentSessionInfo';
import { SalesOrderRequestInfo } from '../SalesOrderRequestInfo';

interface SalesOrderDetailsTabContentProps {
    salesOrderId: UUID;
}

export const SalesOrderDetailsTabContent: FC<SalesOrderDetailsTabContentProps> = ({ salesOrderId }) => {
    const { data: salesOrder, isLoading, isError } = useSalesOrderQuery(salesOrderId);

    if (isLoading) {
        return (
            <Box relative flex={1}>
                <ComponentLoader />
            </Box>
        );
    }

    if (isError || !salesOrder) {
        return <ErrorStatePlaceholder />;
    }

    const { request, paymentSession } = salesOrder;

    return (
        <Box column gap={24} paddingVertical='mainMarginVertical' paddingHorizontal='mainMarginHorizontal'>
            <FormSection label='Details'>
                <FormGridRow columns={3}>
                    <SalesOrderDetailsInfo salesOrder={salesOrder} />
                </FormGridRow>
            </FormSection>
            {paymentSession && (
                <FormSection label='Payment session'>
                    <FormGridRow columns={3}>
                        <SalesOrderPaymentSessionInfo paymentSession={paymentSession} />
                    </FormGridRow>
                </FormSection>
            )}
            {request && (
                <FormSection label='Request'>
                    <FormGridRow columns={5}>
                        <SalesOrderRequestInfo request={request} />
                    </FormGridRow>
                </FormSection>
            )}
        </Box>
    );
};
