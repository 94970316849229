import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    emptyAdminUploadInvoicePayload,
    invoiceCacheKey,
    invoicesService,
    UploadInvoicePayload,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useUploadInvoice = (invoiceId: UUID, onEnd: () => void) => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (p: UploadInvoicePayload) => invoicesService.uploadInvoice(invoiceId, p),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Invoice successfully sent',
            });
            onEnd();
        },
    });

    const form = useForm<UploadInvoicePayload>({
        initial: { ...emptyAdminUploadInvoicePayload },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: validator<UploadInvoicePayload>({
            file: isRequired('Invoice file is required'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};

export const useDeleteFile = () => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const deleteMutation = useMutation({
        mutationFn: invoicesService.deleteInvoiceFile,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey] });
            showToast({
                type: 'positive',
                message: 'Invoice deleted',
            });
        },
    });

    return {
        deleteFile: deleteMutation.mutate,
    };
};
