import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { forwardRef, ReactElement, Ref, useMemo } from 'react';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3 } from '../../base';
import { useInteractiveList } from '../../dropdown/hooks/useInteractiveList';
import { DropdownContentScrollable } from '../../dropdown/shared/DropdownContentScrollable';
import { DropdownInteractiveListItem } from '../../dropdown/shared/DropdownInteractiveListItem';
import {
    InteractiveListBase,
    NormalInteractiveList,
    NullableInteractiveList,
} from '../../dropdown/types/InteractiveListTypes';
import { SelectFieldContainer } from '../shared/FieldContainer';

interface SelectPropsBaseProps<T> extends InteractiveListBase<T>, OuterBoxProps {
    fixedOptions?: NonNullable<T>[];
    onFocus?(): void;
    onBlur?(): void;
    placeholder?: string;
    isError?: boolean;
    id?: string;
}

export interface NormalSelectProps<T> extends SelectPropsBaseProps<T>, NormalInteractiveList<T> {}
export interface NullableSelectProps<T> extends SelectPropsBaseProps<T>, NullableInteractiveList<T> {}

export type SelectProps<T> = NormalSelectProps<T> | NullableSelectProps<T>;

const SelectComponent = <T,>(
    {
        value,
        options,
        fixedOptions,
        onChange,
        onFocus,
        onBlur,
        toText,
        toKey,
        toLabel,
        disabled: selectDisabled,
        placeholder,
        nullable,
        isError,
        id,
        ...rest
    }: SelectProps<T>,
    ref: Ref<HTMLDivElement>,
) => {
    const allOptions = useMemo(() => {
        if (fixedOptions) {
            return [...fixedOptions, ...options];
        }
        return options;
    }, [fixedOptions, options]);

    const {
        refs,
        context,
        isOpen,
        activeIndex,
        selectedIndex,
        clear,
        getLabel,
        getKey,
        referenceProps,
        floatingProps,
        itemProps,
        listRef,
        floatingStyles,
        disabled,
        resultantPlacement,
    } = useInteractiveList({
        options: allOptions,
        toText,
        toKey,
        toLabel,
        value,
        onChange,
        disabled: selectDisabled,
        nullable,
        placement: 'bottom',
    });

    const renderLabel = (value: T) => {
        const label = getLabel(value);
        if (typeof label === 'string') {
            return (
                <Paragraph3 textNoWrap overflow='hidden' ellipsis color={Color.ContentPrimary}>
                    {label}
                </Paragraph3>
            );
        }
        return label;
    };

    const renderItem = (item: NonNullable<T>, index: number) => (
        <DropdownInteractiveListItem
            key={getKey(item)}
            ref={node => {
                listRef.current[index] = node;
            }}
            label={getLabel(item)}
            isActive={index === activeIndex}
            isSelected={index === selectedIndex}
            {...itemProps(item)}
        />
    );

    const selectedItemLabel = value && renderLabel(value);
    const indexOffset = fixedOptions?.length ?? 0;

    return (
        <>
            <SelectFieldContainer
                labelledBy={id}
                isOpen={isOpen}
                isError={isError}
                hasValue={!!value}
                nullable={nullable}
                disabled={disabled}
                innerBoxProps={referenceProps({ onFocus, onBlur })}
                innerBoxRef={ref}
                onClear={clear}
                setReference={refs.setReference}
                selectedItemLabel={selectedItemLabel}
                placeholder={placeholder}
                referenceProps={referenceProps()}
                {...rest}
            />

            <AnimatePresence>
                {isOpen && (
                    <FloatingPortal>
                        <FloatingFocusManager context={context} modal={false}>
                            <DropdownContentScrollable
                                ref={refs.setFloating}
                                style={floatingStyles}
                                floatingProps={floatingProps()}
                                placement={resultantPlacement}
                                topSlot={
                                    fixedOptions && (
                                        <Box borderBottom column gap={4} padding={4}>
                                            {fixedOptions.map(renderItem)}
                                        </Box>
                                    )
                                }
                            >
                                {options.map((item, index) => renderItem(item, index + indexOffset))}
                            </DropdownContentScrollable>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};

export const Select = forwardRef(SelectComponent) as <T>(
    props: SelectProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
