import { queryStringParser } from '@hofy/router';
import { FilterDefinitionSingle } from '@hofy/ui';

export const useSearchFilterDefinition = (): FilterDefinitionSingle<string> => {
    return {
        type: 'single',
        name: 'Search',
        query: queryStringParser(),
        showInActive: false,
    };
};
