import React, { FC, useEffect, useState } from 'react';

import { useAppVersion } from '@hofy/api-auth';
import { GIT_HASH, isE2E, isLocal } from '@hofy/config';
import { ConfirmModal } from '@hofy/ui';

export const OutdatedVersionModal: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);
    const version = useAppVersion(isCancelled);

    useEffect(() => {
        if (!version || isLocal() || isE2E()) {
            return;
        }
        if (version !== GIT_HASH) {
            setIsCancelled(true);
            setIsOpen(true);
        }
    }, [version]);

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <ConfirmModal
            onClose={handleClose}
            keyPrefix='outdated-app-modal'
            onConfirm={() => window.location.reload()}
        />
    );
};
