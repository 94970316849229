import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { useGoBack } from '@hofy/hooks';
import { EnumRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { allRepairsPageTabs, RepairsPageTab } from '../../store/repairs/repairsPageTab';
import { ItemSlideoutRouter } from '../itemsPage/ItemSlideoutRouter';
import { RepairsPage } from './RepairsPage';
import { RepairsPageTabContextProvider } from './RepairsTabContext';
import { CreateRepairForItemSlideoutRouter } from './slideouts/createRepair/CreateRepairForItemSlideoutRouter';
import { CreateRepairSlideoutRouter } from './slideouts/createRepair/CreateRepairSlideoutRouter';
import { RepairSlideoutRouter } from './slideouts/viewRepair/RepairSlideoutRouter';

export const RepairsRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();

    return (
        <Switch>
            <RepairsRoute path={`${AdminNavLink.Repairs}/:tab(${allRepairsPageTabs.join('|')})`}>
                {({ tab }) => {
                    const base = `${AdminNavLink.Repairs}/${tab}`;
                    const goBackToBase = () => goBack(`${base}${history.location.search}`);

                    return (
                        <RepairsPageTabContextProvider tab={tab}>
                            <RepairsPage
                                activeTab={tab}
                                onTabChange={newTab =>
                                    history.push(
                                        `${AdminNavLink.Repairs}/${newTab}${history.location.search}`,
                                    )
                                }
                            />
                            <CreateRepairSlideoutRouter base={`${base}/create`} onClose={goBackToBase} />
                            <CreateRepairForItemSlideoutRouter
                                base={`${base}/create-for-item`}
                                onClose={goBackToBase}
                            />
                            <RepairSlideoutRouter base={`${base}/repair`} onClose={goBackToBase} />
                            <ItemSlideoutRouter base={`${base}/item`} />
                        </RepairsPageTabContextProvider>
                    );
                }}
            </RepairsRoute>
            <Redirect
                to={`${AdminNavLink.Repairs}/${RepairsPageTab.Pending}${history.location.search}`}
                from={`${AdminNavLink.Repairs}`}
            />
        </Switch>
    );
};

const RepairsRoute = EnumRoute<RepairsPageTab>('tab', RepairsPageTab, Route);
