import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { itPolicyService } from '../service/itPolicyService';
import { itPoliciesCacheKey } from './itPoliciesCacheKey';

export const useItPoliciesQuery = (organizationId: UUID) => {
    const { isLoading: isItPoliciesLoading, data: itPolicies } = useQuery({
        queryKey: [itPoliciesCacheKey, organizationId],

        queryFn: () => itPolicyService.getItPolicies(organizationId),
    });

    return {
        itPolicies: itPolicies || [],
        isItPoliciesLoading,
    };
};
