import { findKey } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';

import { SvgIllustration } from './SvgIllustration';

export interface IllustrationProps {
    svg: SvgIllustration;
}

/**
 * Illustration component that displays an SVG illustration based on the type prop
 *
 * It comes in two sizes: small and large, depending on the type prefix.
 * If the name starts with 'Small', the illustration will be 150x150 pixels, otherwise, it will be 400x400 pixels.
 */
export const Illustration: FC<IllustrationProps> = ({ svg }) => {
    const name = findKey(SvgIllustration, value => value === svg);

    if (!name) {
        throw new Error(`Illustration '${svg}' not found`);
    }

    const size = name.startsWith('Small') ? 150 : 300;

    return <Img src={svg} alt='' maxWidth={size} maxHeight={size} />;
};

const Img = styled.img<{
    maxWidth: number;
    maxHeight: number;
}>`
    display: block;
    max-width: ${props => props.maxWidth}px;
    max-height: ${props => props.maxHeight}px;
    width: 100%;
`;
