import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiKeysCacheKey, apiKeyService, CreateApiKeyPayload } from '@hofy/api-admin';
import { isAtLeast128bit, isEmpty } from '@hofy/helpers';
import { isRequired, minLength, useForm, useToast, validator } from '@hofy/ui';

export const useCreateApiKey = (onSuccess?: (value: string) => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: apiKeyService.createApiKey,
        onSuccess: response => {
            queryClient.invalidateQueries({ queryKey: [apiKeysCacheKey] });
            showToast({
                type: 'positive',
                message: `The api key has been created!`,
            });
            onSuccess?.(response.value);
        },
    });

    const form = useForm<CreateApiKeyPayload>({
        initial: {
            organizationId: null,
            name: '',
            existingApiKey: null,
            permissions: [],
        },
        onSubmit: mutation.mutate,
        validate: validator<CreateApiKeyPayload>({
            name: isRequired('Name is required'),
            permissions: minLength('Select at least one permission', 1),
            existingApiKey: existingApiKey =>
                !isEmpty(existingApiKey) && !isAtLeast128bit(existingApiKey!)
                    ? 'Enter at least 128 bit key'
                    : undefined,
        }),
    });

    return {
        isLoading: mutation.isPending,
        form,
    };
};
