import React, { Component, ErrorInfo, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, Button, Paragraph4, Placeholder, SvgIllustration } from '@hofy/ui';

interface ErrorBoundaryProps {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    errorMessage?: string;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false,
    };

    public static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        return { hasError: true, errorMessage: error?.message };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // eslint-disable-next-line no-console
        console.error('[ErrorBoundary]', error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <Box
                    column
                    alignItems='center'
                    justify='center'
                    gap={16}
                    padding={20}
                    overflow='auto'
                    minHeight='100%'
                    flex={1}
                >
                    <Placeholder
                        illustration={SvgIllustration.Error}
                        title='Something went wrong'
                        message='Please try again later'
                    />
                    <Button onClick={() => window.location.reload()}>Reload</Button>
                    {this.state.errorMessage && (
                        <Paragraph4 marginTop={16} color={Color.ContentTertiary}>
                            Message: {this.state.errorMessage}
                        </Paragraph4>
                    )}
                </Box>
            );
        }

        return this.props.children;
    }
}
