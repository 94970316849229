import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey, repaymentPlanService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useDeleteTwoRejectedRepaymentPlans = (billingEntityId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => repaymentPlanService.deleteRejectedPlans(billingEntityId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: 'Rejected repayment plans deleted',
            });
            onSuccess?.();
        },
    });

    const deleteRejectedPlans = () => {
        mutation.mutate();
    };

    return {
        deleteRejectedPlans,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
