import React, { FC } from 'react';

import { ContractDetailsDtoWithPurchase } from '@hofy/api-admin';
import { BackButton, FormRow, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import {
    AsyncButton,
    Box,
    FormContainer,
    FormInput,
    FormPriceInput,
    FormSection,
    Paragraph3,
    SvgIcon,
} from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { useUpdatePurchaseContract } from '../../../store/contracts/useUpdatePurchaseContract';

interface UpdatePurchaseContractSlideoutProps {
    contract: ContractDetailsDtoWithPurchase;
    onClose(): void;
}

export const UpdatePurchaseContractSlideout: FC<UpdatePurchaseContractSlideoutProps> = ({
    onClose,
    contract,
}) => {
    const { form, isLoading, submit } = useUpdatePurchaseContract(contract, onClose);
    const content = () => {
        return (
            <Box marginTop={10}>
                <FormContainer>
                    <FormSection label='Price'>
                        <FormRow>
                            <FormPriceInput label='Base price' api={form.fields.basePrice} isRequired />
                            <FormPriceInput label='Unit price' api={form.fields.unitPrice} isRequired />
                            <FormPriceInput label='Price' api={form.fields.price} isRequired />
                        </FormRow>
                        <FormRow>
                            <FormInput label='Multiplier' api={form.fields.countryMultiplier} isRequired />
                            <FormInput
                                label='Storefront fee'
                                rightSlot={<Paragraph3>%</Paragraph3>}
                                api={form.fields.storefrontFee}
                                isRequired
                            />
                        </FormRow>
                    </FormSection>
                </FormContainer>
            </Box>
        );
    };

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Update purchase contract' />
            <SlideoutContent borderBottom paddingBottom={30}>
                {content()}
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton
                    defaultNavigation={`${AdminNavLink.Contracts}/${contract.id}/details`}
                    label='Close'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton
                    label='Save'
                    disableCheck
                    onClick={submit}
                    isLoading={isLoading}
                    disabled={!form.hasChanges}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
