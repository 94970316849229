import React, { forwardRef, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { renderTextNode } from '../../../helpers/React';
import { TestKeyAware } from '../../../types';
import { Box, Paragraph3 } from '../../base';
import { Icon } from '../../icon';

interface DropdownListItemProps extends TestKeyAware {
    icon?: Svg;
    label: ReactNode;
    onClick(): void;
    disabled?: boolean;
}

export const DropdownListItem = forwardRef<HTMLDivElement, DropdownListItemProps>(
    ({ label, icon, onClick, disabled, testKey, ...rest }, ref) => {
        return (
            <ContainerBox
                ref={ref}
                role='button'
                onClick={onClick}
                row
                gap={8}
                paddingHorizontal={16}
                paddingVertical={8}
                rounded={8}
                pointer
                inactive={disabled}
                data-test-key={testKey}
                {...rest}
            >
                {icon && <Icon svg={icon} color={Color.ContentPrimary} />}
                {renderTextNode(label, label => (
                    <Paragraph3 textNoWrap overflow='hidden' ellipsis color={Color.ContentPrimary}>
                        {label}
                    </Paragraph3>
                ))}
            </ContainerBox>
        );
    },
);

const ContainerBox = styled(Box)(p => {
    if (!p.inactive) {
        return css`
            &:hover {
                background-color: ${Color.InteractionGhostHover};
            }
            &:active {
                background-color: ${Color.InteractionGhostActive};
            }
        `;
    }
});
