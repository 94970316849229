import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, AssignUserToShipmentPayload, shipmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useUpdateShipmentAssignee = (shipmentId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, AssignUserToShipmentPayload>({
        mutationFn: payload => shipmentService.assignUserToShipment(shipmentId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });

    return {
        assignShipment: (userId: UUID | null) => mutation.mutate({ userId }),
        isLoading: mutation.isPending,
    };
};
