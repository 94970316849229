export const byodOrderErrorI18n = {
    'api-error.invalid-byod-order-status.title': 'Invalid BYOD order status',
    'api-error.invalid-byod-order-status.message': 'Please see order status',
    'api-error.received-quantity-higher-than-expected.title': 'Received quantity higher than expected',
    'api-error.received-quantity-higher-than-expected.message':
        'Check the expected quantity for this product',
    'api-error.duplicate-serial-numbers.title': 'Duplicate serial numbers',
    'api-error.duplicate-serial-numbers.message': 'Cannot perform this operation',
    'api-error.warehouse-bin-required.title': 'Warehouse bin required',
    'api-error.warehouse-bin-required.message': 'Cannot perform this operation',
};
