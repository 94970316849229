import React, { FC, ReactNode, useRef } from 'react';

import { BaseI18n, useBaseI18n } from '@hofy/i18n';

import { TestKeyAware } from '../../types';
import { Paragraph3 } from '../base';
import { AsyncButton, Button } from '../form';
import { ButtonAction } from '../form/button/buttonConfig';
import { SvgIcon } from '../icon';
import { ModalContent } from './base/ModalContent';
import { ModalFooter } from './base/ModalFooter';
import { ModalHeader } from './base/ModalHeader';
import { Modal } from './Modal';

interface ConfirmModalProps extends TestKeyAware {
    keyPrefix: string;
    trPayload?: Record<string, any>;
    action?: ButtonAction;
    width?: number;

    onClose(): void;
    onConfirm(): void;

    isLoading?: boolean;
    disabled?: boolean;
    closeOnConfirm?: boolean;

    /** @deprecated Create a custom modal instead */
    subTitle?: ReactNode;
    children?: ReactNode;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
    keyPrefix,
    trPayload,
    width = 450,
    action,

    onClose,
    onConfirm,

    isLoading,
    disabled,
    closeOnConfirm = true,

    subTitle,
    children,

    testKey,
}) => {
    const confirmRef = useRef(null);
    const { tr } = useBaseI18n();

    const trPart = (part: string) => {
        return tr(`${keyPrefix}.${part}` as BaseI18n, trPayload);
    };

    const handleConfirm = () => {
        onConfirm();
        if (closeOnConfirm) {
            onClose();
        }
    };

    return (
        <Modal onClose={onClose} width={width} initialFocus={confirmRef} testKey={testKey || 'confirm-modal'}>
            <ModalHeader title={trPart('title')} />
            <ModalContent>
                {subTitle ?? <Paragraph3 data-test-key='subtitle'>{trPart('subtitle')}</Paragraph3>}
                {children}
            </ModalContent>
            <ModalFooter>
                <Button
                    type='secondary'
                    label={trPart('cancel')}
                    onClick={onClose}
                    leftIcon={SvgIcon.Cross}
                    data-test-key='cancel-button'
                />
                <AsyncButton
                    ref={confirmRef}
                    label={trPart('confirm')}
                    onClick={handleConfirm}
                    isLoading={isLoading}
                    disabled={disabled}
                    action={action}
                    data-test-key='confirm-button'
                />
            </ModalFooter>
        </Modal>
    );
};
