import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';

import { AdminOrganizationsFilters } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';

interface BaseProps {
    filters?: AdminOrganizationsFilters;
}

type LabeledOrganizationNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;
type LabeledOrganizationNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options'
> &
    BaseProps;

export type LabeledOrganizationProps = LabeledOrganizationNormalProps | LabeledOrganizationNullableProps;

export const LabeledOrganizationSelect = forwardRef(
    ({ value, onChange, ...rest }: LabeledOrganizationProps, ref: Ref<HTMLDivElement>) => {
        const [search, setSearch] = useState('');

        const { data, isLoading } = useOrganizationsRefsQuery(value ? [value] : [], search);
        const nameMap = useMemo(() => keyBy(data, v => v.id), [data]);

        return (
            <LabeledSelectSearch
                toText={org => nameMap[org]?.name}
                options={data.map(({ id }) => id)}
                onSearchChange={setSearch}
                isLoadingSearch={isLoading}
                ref={ref}
                value={value}
                onChange={(newId: UUID) => {
                    setSearch('');
                    onChange(newId);
                }}
                {...rest}
            />
        );
    },
) as (props: LabeledOrganizationProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
