import { every, some } from 'lodash';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { UserAssignmentDto, UserAssignmentsByShipmentDto, UserShipmentDto } from '@hofy/api-admin';
import {
    AssignmentStatus,
    deliveryAssignmentStatuses,
    getAssignmentStoreAndReuse,
    isCategoryConsumable,
    isShipmentFromHofyWarehouse,
    isShipmentFromSupplierToUser,
    isShipmentFromWarehouseToUser,
    NoteSource,
    Ownership,
    ShipmentStatus,
} from '@hofy/api-shared';
import { DateTimeString } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, BoxProps } from '@hofy/ui';

import { AssignmentMenu } from '../../../../components/domain/assignments/AssignmentMenu';
import {
    getPartnerDetailsLink,
    getRepairsLink,
    getStoreAndReuseContractLink,
} from '../../../../components/routing/adminLinks';
import { useAssignmentsLinks } from '../../useAssignmentsLinks';
import { NotesCell } from '../shared/cells/NotesCell';
import { AssignmentDateCell } from './cells/AssignmentDateCell';
import { AssignmentIndicatorsCell } from './cells/AssignmentIndicatorsCell';
import { AssignmentInfoCell } from './cells/AssignmentInfoCell';
import { AssignmentSelectorCell } from './cells/AssignmentSelectorCell';
import { AssignmentStatusCell } from './cells/AssignmentStatusCell';
import { ItemAvailabilityCell } from './cells/ItemAvailabilityCell';
import { ProductInfoCell } from './cells/ProductInfoCell';

interface AssignmentRowProps extends BoxProps {
    assignment: UserAssignmentDto;
    user: UserAssignmentsByShipmentDto;
    shipment?: UserShipmentDto;
    isAssignmentSelected: boolean;
    onSelectAssignment(): void;
}

export const AssignmentRow: FC<AssignmentRowProps> = ({
    assignment,
    user,
    shipment,
    isAssignmentSelected,
    onSelectAssignment,
}) => {
    const history = useHistory();

    const {
        getOpenAssignmentLink,
        getAssignmentNotesLink,
        getConfigureDeviceLink,
        getDataErasureLink,
        getOpenItemLink,
        getSubstituteProductLink,
        getPickCustomProductLink,
        getCreateConsumableLink,
        getShipmentAssignmentsLink,
        getOpenAssignmentDetailLink,
        getOpenRepairLink,
        getCreateRepairLink,
    } = useAssignmentsLinks();

    const {
        missingItem,
        missingItemNote,
        manufacturerPackagingStatus,
        cardboardBoxPackagingStatus,
        hasChargingBlock,
        hasChargingCable,
    } = assignment.collectionRequestItem ?? {};
    const displayingOldDelivery = (): boolean =>
        assignment.isHistorical &&
        !!shipment &&
        (isShipmentFromWarehouseToUser(shipment) || isShipmentFromSupplierToUser(shipment));

    const missingLaptopAccessories = () => {
        if (every([hasChargingBlock, hasChargingCable], value => value === null || value === true)) {
            return false;
        }
        return some([hasChargingBlock, hasChargingCable], value => value === false);
    };

    const isDelivery = deliveryAssignmentStatuses.includes(assignment.status) || displayingOldDelivery();
    const managedStatus =
        isDelivery && assignment.isHistorical ? AssignmentStatus.WithUser : assignment.status;

    const notSelectable = assignment.isHistorical || assignment.isOnHold;

    const filteredNotes = assignment.notes.filter(note => note.source === NoteSource.Assignments);

    return (
        <Wrapper
            bg={Color.BackgroundSurfaceNeutral}
            row
            historical={isDelivery && assignment.isHistorical}
            isCustomerOwned={assignment.item?.ownership.type === Ownership.Organization}
            gap={10}
            paddingVertical={12}
            paddingHorizontal={16}
            borderVertical
            borderRight
            border
            onClick={notSelectable ? undefined : onSelectAssignment}
            data-test-key='assignment-row'
        >
            <AssignmentSelectorCell hidden={notSelectable} isAssignmentSelected={isAssignmentSelected} />
            <AssignmentInfoCell
                id={assignment.id}
                isDelivery={isDelivery}
                publicId={assignment.publicId}
                status={assignment.status}
                type={assignment.type}
                collectionReason={assignment.collectionReason}
                exception={assignment.exception}
                isRefurbishedProduct={assignment.product.isRefurbished}
                isOnHold={assignment.isOnHold}
                isConsumable={assignment.isConsumable}
                isStoreAndReuseDelivery={assignment.isStoreAndReuseDelivery}
                isStoreAndReuseCollection={assignment.isStoreAndReuseCollection}
                storeAndReuse={getAssignmentStoreAndReuse(assignment.contract)}
                itemWarehouse={assignment.item?.warehouse ?? null}
                collectionToOrganizationWarehouse={assignment.collectionToOrganizationWarehouse ?? null}
                country={assignment.contract?.country}
                linkToAssignment={getOpenAssignmentLink(assignment.id)}
                collectionShipmentId={assignment.collectionShipmentId}
                deliveryShipmentId={assignment.deliveryShipmentId}
                collectionNotBefore={assignment.collectionNotBefore}
                width={156}
            />
            <AssignmentIndicatorsCell
                isDelivery={isDelivery}
                partnerName={user.organization.partnerName}
                configurationStatus={assignment.configurationStatus}
                dataErasureStatus={assignment.dataErasure?.status ?? null}
                isSubstituted={!!assignment.requestedVariantId}
                isAssigned={!!assignment.item}
                assignedItemNeedsScanning={assignment.assignedItemNeedsScanning}
                storeAndReuseManagedByHofy={user.organization.storeAndReuseManagedByHofy}
                isProductStoreAndReuseAvailable={assignment.storeAndReuseProductAvailable}
                isCategoryStoreAndReuseAvailable={assignment.storeAndReuseCategoryAvailable}
                collectionMissingItem={missingItem ?? null}
                collectionMissingItemNote={missingItemNote ?? null}
                collectionMissingLaptopAccessories={missingLaptopAccessories()}
                collectionManufacturerPackagingStatus={manufacturerPackagingStatus ?? null}
                collectionCardboardBoxPackagingStatus={cardboardBoxPackagingStatus ?? null}
                linkToPartnerDetails={
                    user.organization.partnerId ? getPartnerDetailsLink(user.organization.partnerId) : null
                }
                linkToOpenShipmentOrders={shipment?.id ? getShipmentAssignmentsLink(shipment.id) : null}
                linkToConfigureDevice={getConfigureDeviceLink(assignment.id)}
                linkToDataErasure={getDataErasureLink(assignment.id)}
                linkToStoreAndReuseContracts={getStoreAndReuseContractLink(
                    user.organization.id,
                    assignment.product.category,
                )}
                linkToItemDetails={assignment.item?.id ? getOpenItemLink(assignment.item.id) : null}
                linkToRepair={assignment.item?.repairId ? getOpenRepairLink(assignment.item.repairId) : null}
                isExpressShipping={assignment.expressDeliveryRequested}
                shipmentClass={shipment?.shipmentClass ?? null}
                wrap
                width={156}
                justify='flex-start'
            />
            <ProductInfoCell
                productName={assignment.product.name}
                imageUrl={assignment.variant.image.url}
                size={assignment.variant.size}
                style={assignment.variant.style}
                os={assignment.variant.os}
                serial={assignment.item?.serialNumber ?? null}
                mpc={assignment.variant.manufacturerPartCode}
                sku={assignment.variant.sku}
                isCustomerOwned={assignment.item?.ownership.type === Ownership.Organization}
                flex={3}
                maxWidth={600}
            />
            <Box row gap={10} justify='flex-end' flex={3}>
                <ItemAvailabilityCell
                    status={assignment.status}
                    assignmentType={assignment.type}
                    isRefurbished={assignment.product.isRefurbished}
                    sku={assignment.variant.sku}
                    itemId={assignment.item?.id ?? null}
                    stockLevelCounts={assignment.stockLevels}
                    assignedBin={assignment.item?.warehouseBin ?? null}
                    itemLocation={assignment.item?.location ?? null}
                    warehouse={
                        isShipmentFromHofyWarehouse(shipment)
                            ? shipment.fromWarehouse
                            : assignment.item?.warehouse ?? null
                    }
                />
                <NotesCell
                    notes={filteredNotes}
                    linkToNotes={getAssignmentNotesLink(assignment.id)}
                    label='Order notes'
                    flex={2}
                />
                <AssignmentStatusCell status={managedStatus} justify='flex-end' width={156} />
                <AssignmentDateCell
                    status={managedStatus}
                    onTimeDeliveryDate={assignment.latestDeliveryOn}
                    collectionPendingAt={
                        assignment.statusDates[AssignmentStatus.CollectionPending]! as DateTimeString
                    }
                    withUserOn={assignment.statusDates[AssignmentStatus.WithUser]!}
                    width={90}
                    marginLeft={20}
                />
                <AssignmentMenu
                    assignmentId={assignment.id}
                    assignmentStatus={assignment.status}
                    assignmentType={assignment.type}
                    isStoreAndReuseDelivery={assignment.isStoreAndReuseDelivery}
                    isStoreAndReuseCollection={assignment.isStoreAndReuseCollection}
                    isConsumable={
                        assignment.isConsumable || isCategoryConsumable(assignment.product.category)
                    }
                    hasCustomProduct={assignment.customProduct !== null}
                    isOnHold={assignment.isOnHold}
                    configurationStatus={assignment.configurationStatus}
                    dataErasureStatus={assignment.dataErasure?.status ?? null}
                    collectionReason={assignment.collectionReason}
                    productId={assignment.product.id}
                    productCategory={assignment.product.category}
                    requestedVariantId={assignment.requestedVariantId}
                    itemId={assignment.item?.id ?? null}
                    itemStatus={assignment.item?.status ?? null}
                    itemOwnership={assignment.item?.ownership ?? null}
                    itemManagedByAssignmentContract={
                        assignment.item?.assignedContract?.id !== undefined &&
                        assignment.item?.assignedContract?.id === assignment.contract?.id
                    }
                    contract={assignment.contract}
                    isPendingShipment={!shipment || shipment.status !== ShipmentStatus.WithCourier}
                    organizationId={user.organization.id}
                    collectionCountry={assignment.contract?.country ?? user.userAddress.country}
                    deliveryCountry={assignment.deliveryAddress.country}
                    activeShipmentId={shipment?.isActive ? shipment.id : null}
                    assignmentUserId={assignment.userId}
                    salesOrderId={assignment.salesOrderId}
                    deliveryAddress={assignment.deliveryAddress}
                    userAddress={user.userAddress}
                    activeShipment={shipment ?? null}
                    otd={assignment.latestDeliveryOn}
                    onEraseDevice={() => {
                        history.push(getOpenAssignmentDetailLink(assignment.id));
                        history.push(getDataErasureLink(assignment.id));
                    }}
                    onConfigureDevice={() => {
                        history.push(getOpenAssignmentDetailLink(assignment.id));
                        history.push(getConfigureDeviceLink(assignment.id));
                    }}
                    onRepairItem={() => {
                        if (!assignment.item) {
                            return;
                        }
                        history.push(getRepairsLink());
                        history.push(getCreateRepairLink(assignment.item.id));
                    }}
                    onSubstituteProduct={() => {
                        history.push(getOpenAssignmentDetailLink(assignment.id));
                        history.push(getSubstituteProductLink(assignment.id));
                    }}
                    onPickCustomProduct={() => {
                        history.push(getOpenAssignmentDetailLink(assignment.id));
                        history.push(getPickCustomProductLink(assignment.id));
                    }}
                    onCreateConsumable={() => {
                        history.push(getOpenAssignmentDetailLink(assignment.id));
                        history.push(getCreateConsumableLink(assignment.id));
                    }}
                />
            </Box>
        </Wrapper>
    );
};
const Wrapper = styled(Box)<{
    historical: boolean;
    isCustomerOwned: boolean;
}>`
    border-left: 4px solid ${p => (p.isCustomerOwned ? Color.ContentWarning : Color.ContentBrand)};
    border-radius: 4px;

    filter: ${p => (p.historical ? 'grayscale(1) opacity(0.7) ' : undefined)};

    :hover {
        background: ${Color.BackgroundSubtleNeutral};
        z-index: 1;
    }
`;
