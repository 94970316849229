import { isEmpty } from 'lodash';

import { InspectionDetailsUnionDto, UpdateInspectionPayload } from '@hofy/api-admin';
import {
    InspectionStatus,
    isDeviceCategory,
    isSerialNumberRequired,
    ItemGrade,
    ItemPart,
    requiresAccessoryResetOnInspection,
} from '@hofy/api-shared';
import { FormErrors, isRequired, isRequiredIf, useForm, useToast, validator } from '@hofy/ui';

import { useCompleteInspection } from './useCompleteInspection';
import { useUpdateInspection } from './useUpdateInspection';

interface UpdateInspectionForm {
    isDamaged: boolean;
    damageNote: string | null;

    grade: ItemGrade | null;

    isWithDamagedParts: boolean;
    damagedParts: ItemPart[];
    damagedPartsNote: string | null;

    isWithMissingParts: boolean;
    missingParts: ItemPart[];
    missingPartsNote: string | null;

    isMissingOriginalPackaging: boolean;

    isCleaned: boolean;

    isAccessoryReset: boolean;

    hofyWarehouseBinIdentifier: string | null;

    serialNumber: string | null;
    cantGetSerialNumber: boolean;

    additionalNotes: string | null;

    hasAdminConfirmedCompletion: boolean;
}

interface ValidateUpdateInspectionForm extends UpdateInspectionForm {
    hofyWarehouseBinIdentifier: string;
}

type UpdateInspectionFormValidation = FormErrors<UpdateInspectionForm>;

export const useUpdateInspectionForm = (inspection: InspectionDetailsUnionDto, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const {
        updateInspection,
        isLoading: isUpdating,
        isError: isUpdateError,
    } = useUpdateInspection(inspection.id, onSuccess);
    const {
        completeInspection,
        isLoading: isCompleting,
        isError: isCompleteError,
    } = useCompleteInspection(inspection.id, () => {
        if (isDeviceCategory(inspection.product.category)) {
            showToast({ type: 'informative', message: 'Item is now ready for device check' });
        }
    });

    const form = useForm<UpdateInspectionForm, ValidateUpdateInspectionForm, UpdateInspectionFormValidation>({
        initial: getInitialFormValuesFromDto(inspection),
        initialDeps: [inspection],
        validate: getUpdateInspectionFormValidator(inspection),
        onSubmit: values => {
            return values.hasAdminConfirmedCompletion
                ? completeInspection(getPayloadFromFormValues(values))
                : updateInspection(getPayloadFromFormValues(values));
        },
    });

    return {
        form,
        isLoading: isUpdating || isCompleting,
        isError: isUpdateError || isCompleteError,
    };
};

const getInitialFormValuesFromDto = (inspection: InspectionDetailsUnionDto): UpdateInspectionForm => ({
    isDamaged: inspection.damageNote !== null,
    damageNote: inspection.damageNote,

    grade: inspection.grade,

    isWithDamagedParts: !isEmpty(inspection.damagedParts),
    damagedParts: inspection.damagedParts || [],
    damagedPartsNote: inspection.damagedPartsNote,

    isWithMissingParts: !isEmpty(inspection.missingParts),
    missingParts: inspection.missingParts || [],
    missingPartsNote: inspection.missingPartsNote,

    isMissingOriginalPackaging: inspection.isMissingOriginalPackaging,

    isCleaned: inspection.isCleaned,

    isAccessoryReset: inspection.isAccessoryReset,

    hofyWarehouseBinIdentifier: inspection.hofyWarehouseBin?.identifier || null,

    serialNumber: inspection.serialNumber,
    cantGetSerialNumber: inspection.status === InspectionStatus.Completed && inspection.serialNumber === null,

    additionalNotes: inspection.additionalNotes,

    hasAdminConfirmedCompletion: false,
});

const getPayloadFromFormValues = (form: ValidateUpdateInspectionForm): UpdateInspectionPayload => ({
    damageNote: form.isDamaged ? form.damageNote : null,

    grade: form.grade,

    damagedParts: form.isWithDamagedParts ? form.damagedParts : [],
    damagedPartsNote:
        form.isWithDamagedParts && form.damagedParts.includes(ItemPart.Other) ? form.damagedPartsNote : null,

    missingParts: form.isWithMissingParts ? form.missingParts : [],
    missingPartsNote:
        form.isWithMissingParts && form.missingParts.includes(ItemPart.Other) ? form.missingPartsNote : null,

    isMissingOriginalPackaging: form.isMissingOriginalPackaging,

    isCleaned: form.isCleaned,
    isAccessoryReset: form.isAccessoryReset,
    hofyWarehouseBinIdentifier: form.hofyWarehouseBinIdentifier,
    serialNumber: form.serialNumber,
    additionalNotes: form.additionalNotes,
});

const getUpdateInspectionFormValidator = (inspection: InspectionDetailsUnionDto) =>
    validator<UpdateInspectionForm>({
        damageNote: isRequiredIf(({ isDamaged }) => isDamaged, 'Damage description is required'),
        damagedParts: isRequiredIf(
            ({ isWithDamagedParts }) => isWithDamagedParts,
            'Please specify the damaged part(s)',
        ),
        damagedPartsNote: isRequiredIf(
            ({ isWithDamagedParts, damagedParts }) =>
                isWithDamagedParts && damagedParts?.includes(ItemPart.Other),
            'Please describe the damaged part(s)',
        ),
        missingParts: isRequiredIf(
            ({ isWithMissingParts }) => isWithMissingParts,
            'Please specify the missing part(s)',
        ),
        missingPartsNote: isRequiredIf(
            ({ isWithMissingParts, missingParts }) =>
                isWithMissingParts && missingParts?.includes(ItemPart.Other),
            'Please describe the missing part(s)',
        ),
        grade: isRequiredIf(
            ({ hasAdminConfirmedCompletion }) => hasAdminConfirmedCompletion,
            'Item grade is required',
        ),
        isCleaned: isRequiredIf(
            ({ hasAdminConfirmedCompletion }) => hasAdminConfirmedCompletion,
            'Item has to be cleaned',
        ),
        isAccessoryReset: isRequiredIf(
            ({ hasAdminConfirmedCompletion }) =>
                hasAdminConfirmedCompletion &&
                requiresAccessoryResetOnInspection(inspection.product.category, inspection.product.brand),
            'Accessory reset is required',
        ),
        hofyWarehouseBinIdentifier: isRequired('Item location is required'),
        serialNumber: isRequiredIf(
            ({ hasAdminConfirmedCompletion, cantGetSerialNumber }) =>
                isSerialNumberRequired(inspection.product.category) &&
                hasAdminConfirmedCompletion &&
                !cantGetSerialNumber &&
                !inspection.item.serialNumber,
            'Serial number is required',
        ),
    });
