import React, { forwardRef, MouseEvent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Color, FontSize, ThemeFontSize } from '@hofy/theme';

import { isExternalLink } from '../../../helpers/String';
import { TestKeyAware } from '../../../types';
import { Box, BoxProps, Font } from '../../base';
import { Pressable, PressableProps } from '../../base/Pressable';
import { ButtonStateColors } from './buttonConfig';

interface BareButtonProps extends BoxProps, TestKeyAware {
    onClick?(e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void;
    to?: string;
    label?: string;
    labelSize: FontSize | ThemeFontSize;
    labelColors: ButtonStateColors;
    bgColors?: ButtonStateColors;
    borderColors?: ButtonStateColors;
    disabled?: boolean;
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
    overlaySlot?: ReactNode;
    hideLabel?: boolean;
}

export const BareButton = forwardRef<any, BareButtonProps>(
    (
        {
            onClick,
            to,
            label,
            labelSize,
            labelColors,
            bgColors,
            borderColors,
            disabled,
            leftSlot,
            rightSlot,
            overlaySlot,
            hideLabel,
            testKey,
            children,
            ...props
        },
        ref,
    ) => {
        const getLabel = () => {
            return (
                <LabelBox row justify='center' gap={8} hidden={hideLabel} flex={1} minWidth={0}>
                    {leftSlot}
                    {label && (
                        <Font
                            size={labelSize}
                            bold
                            minWidth={0}
                            overflow='hidden'
                            ellipsis
                            textNoWrap
                            color={
                                // We don't want color in this case, but it's required by the Font component
                                undefined as any
                            }
                        >
                            {label}
                        </Font>
                    )}
                    {rightSlot}
                    {children}
                </LabelBox>
            );
        };

        const baseProps: ContainerBoxProps = {
            'textColors': labelColors,
            'bgColors': bgColors,
            'borderColors': borderColors,
            'data-test-key': testKey,

            'activeEffect': true,
            'relative': true,
            'row': true,
        };

        if (to && !disabled) {
            if (isExternalLink(to)) {
                return (
                    <ContainerBox
                        ref={ref}
                        as='a'
                        target='_blank'
                        rel='noopener noreferrer'
                        href={to}
                        {...baseProps}
                        {...props}
                    >
                        {getLabel()}
                        {overlaySlot}
                    </ContainerBox>
                );
            }

            return (
                <ContainerBox
                    as={Link}
                    ref={ref}
                    to={to}
                    onClick={event => {
                        onClick?.(event);
                    }}
                    {...baseProps}
                    {...props}
                >
                    {getLabel()}
                    {overlaySlot}
                </ContainerBox>
            );
        }

        return (
            <ContainerBox
                ref={ref}
                as='button'
                type='button'
                onClick={event => {
                    if (!disabled) {
                        onClick?.(event);
                    }
                }}
                disabled={disabled}
                {...baseProps}
                {...props}
            >
                {getLabel()}
                {overlaySlot}
            </ContainerBox>
        );
    },
);

interface ContainerBoxProps extends PressableProps {
    'textColors': ButtonStateColors;
    'bgColors'?: ButtonStateColors;
    'borderColors'?: ButtonStateColors;
    'disabled'?: boolean;
    'data-test-key'?: string;
}

const ContainerBox = styled(Pressable)<ContainerBoxProps>`
    display: inline-flex;

    &:focus-visible {
        outline: 2px solid ${Color.InteractionFocusDefault};
        outline-offset: 1px;
    }

    color: ${p => p.textColors.normal} ${p => p.disabled && '!important'};
    &:hover {
        color: ${p => p.textColors.hover};
    }
    &:disabled {
        opacity: 0.5;
    }

    ${p =>
        p.bgColors &&
        css`
            background-color: ${p.bgColors.normal} ${p.disabled && '!important'};
            &:hover {
                background-color ${p.bgColors.hover};
            }
        `}

    ${p =>
        p.borderColors &&
        css`
            box-shadow: 0 0 0 1px inset ${p.borderColors.normal} ${p.disabled && '!important'};
            &:hover {
                box-shadow: 0 0 0 1px inset ${p.borderColors.hover};
            }
        `}
`;

const LabelBox = styled(Box)<{ hidden?: boolean }>`
    color: currentColor;
    ${p =>
        p.hidden &&
        css`
            visibility: hidden;
        `}
`;
