import { useMergeRefs } from '@floating-ui/react';
import React, { cloneElement, forwardRef, HTMLProps, isValidElement } from 'react';

import { Pressable } from '../base';
import { PopoverState } from './hooks/usePopover';

interface PopoverTriggerProps extends HTMLProps<HTMLElement> {
    state: PopoverState;
    /**
     * Allows pass any element as the anchor without the wrapper
     * WARNING: element must accept `ref` (native element like `div` or component with `forwardRef`)
     * */
    asChild?: boolean;
}

export const PopoverTrigger = forwardRef<HTMLElement, PopoverTriggerProps>(
    ({ state, children, asChild = false, ...props }, propRef) => {
        const childrenRef = (children as any)?.ref;
        const ref = useMergeRefs([state.data.refs.setReference, propRef, childrenRef]);

        if (asChild && isValidElement(children)) {
            return cloneElement(
                children,
                state.interactions.getReferenceProps({
                    ref,
                    ...props,
                    ...children.props,
                }),
            );
        }

        return (
            <Pressable ref={ref} {...state.interactions.getReferenceProps(props)}>
                {children}
            </Pressable>
        );
    },
);
