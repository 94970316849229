import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { AddressDto, useTrTaxIdLabel } from '@hofy/api-shared';
import { CountryLabel } from '@hofy/core';
import { isPassportAndDateOfBirthNeeded, isTaxIdCountry } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Box, Labeled, LabeledText } from '@hofy/ui';

interface AddressDetailsProps {
    address: AddressDto | null;
}

export const AddressDetails: FC<AddressDetailsProps> = ({ address }) => {
    const trTaxIdLabel = useTrTaxIdLabel();
    if (!address) {
        return null;
    }

    if (!address.country) {
        return <>--</>;
    }
    const { country, taxIdNumber, passportNumber, dateOfBirth } = address;

    return (
        <Box column>
            <Box flex={1}>
                <LabeledText marginBottom={20} label='Phone number' content={address.phoneNumber} />
            </Box>
            <Box row alignItems='flex-start'>
                <Box flex={1}>
                    <Labeled marginBottom={20} label='Country' content={<CountryLabel country={country} />} />
                    <LabeledText
                        marginBottom={20}
                        label='Address'
                        content={<PostAddressView address={address} />}
                    />
                </Box>
                <Box flex={1}>
                    {isTaxIdCountry(country) && (
                        <LabeledText marginBottom={20} label={trTaxIdLabel(country)} content={taxIdNumber} />
                    )}
                    {isPassportAndDateOfBirthNeeded(country) && (
                        <>
                            <LabeledText marginBottom={20} label='Passport No' content={passportNumber} />
                            {dateOfBirth && (
                                <LabeledText
                                    marginBottom={20}
                                    label='Date of Birth'
                                    content={formatDate(dateOfBirth)}
                                />
                            )}
                        </>
                    )}
                </Box>
            </Box>
            <Box flex={1}>
                <LabeledText label='Delivery instructions' content={address.deliveryInstructions} />
            </Box>
        </Box>
    );
};
