import React, { FC } from 'react';

import { AssignmentType } from '@hofy/api-shared';

import { LoanerBadge } from './LoanerBadge';
import { RedistributionBadge } from './RedistributionBadge';
import { ReplacementBadge } from './ReplacementBadge';

interface AssignmentTypeBadgeProps {
    type: AssignmentType;
}

export const AssignmentTypeBadge: FC<AssignmentTypeBadgeProps> = ({ type }) => {
    switch (type) {
        case AssignmentType.Loaner:
            return <LoanerBadge />;
        case AssignmentType.Replacement:
            return <ReplacementBadge />;
        case AssignmentType.Redistribution:
            return <RedistributionBadge />;
        default:
            return null;
    }
};
