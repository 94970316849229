import { useQuery } from '@tanstack/react-query';

import { purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';

export const usePurchaseOrdersForReceipt = () => {
    const { data, isLoading } = useQuery({
        queryKey: [purchaseOrdersCacheKey, 'receipt'],

        queryFn: () => purchaseOrderService.listPurchaseOrdersForReceipt(),
    });

    return {
        purchaseOrders: data ?? [],
        purchaseOrdersIsLoading: isLoading,
    };
};
