import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Box, Paragraph4 } from '../base/index';
import { Icon, SvgIcon } from '../icon/index';

interface LabelErrorProps {
    message: string;
}

export const LabelError: FC<LabelErrorProps> = ({ message }) => {
    return (
        <Box row gap={4}>
            <Icon svg={SvgIcon.AlertCircleFill} size={16} color={Color.ContentNegative} />
            <Paragraph4 data-test-key='labeled-error' color={Color.ContentNegative}>
                {message}
            </Paragraph4>
        </Box>
    );
};
