import React, { FC } from 'react';

import { asArray } from '@hofy/helpers';

import { FilterChip, FilterGroup } from '../../../components';
import { FilterApiMulti } from '../useFilters';

interface FilterPropsMulti {
    filter: FilterApiMulti<any>;
}

export const FilterGroupMulti: FC<FilterPropsMulti> = ({ filter }) => {
    const { value, limit, toLabel, isChanged, toggle } = filter;

    const clearFromIndex = (index: number) => {
        const trimmedValues = asArray(filter.value).slice(0, index);
        filter.set(trimmedValues);
    };

    return (
        <FilterGroup title={filter.name} limit={limit} onClearAfter={clearFromIndex}>
            {value.map((value: any, index: number) => (
                <FilterChip
                    key={index}
                    label={toLabel(value)}
                    onClear={isChanged() ? () => toggle(value) : undefined}
                />
            ))}
        </FilterGroup>
    );
};
