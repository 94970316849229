import { noop } from 'lodash';
import React, { FC, ReactNode, useId } from 'react';
import styled from 'styled-components';

import { TestKeyAware, useIsDisabled } from '@hofy/ui';

interface CheckableContentProps extends TestKeyAware {
    checked: boolean;
    type: string;
    disabled?: boolean;
    onChange?(checked: boolean): void;
    children?: ReactNode;
}

export const CheckableContent: FC<CheckableContentProps> = ({
    children,
    disabled: checkableContentDisabled,
    type,
    checked,
    onChange,
    testKey,
}) => {
    const disabled = useIsDisabled(checkableContentDisabled);
    const id = useId();

    return (
        <>
            <input
                id={id}
                onChange={v => (onChange ?? noop)(v.target.checked)}
                checked={checked}
                type={type}
                disabled={disabled}
                data-test-key={testKey}
            />
            <Label disabled={disabled} clickable={!!onChange} htmlFor={id}>
                {children}
            </Label>
        </>
    );
};

const Label = styled.label<{ disabled?: boolean; clickable?: boolean }>`
    cursor: ${p => (p.disabled ? 'initial' : 'pointer')};
    pointer-events: ${p => (p.clickable ? 'initial' : 'none')};
`;
