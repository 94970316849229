import React, { FC } from 'react';

import { IconItemWithTooltip } from '@hofy/common';
import { Color } from '@hofy/theme';
import { SvgIcon } from '@hofy/ui';

interface CollectionUserNotesCellProps {
    userNotes: string;
}

export const CollectionUserNotesCell: FC<CollectionUserNotesCellProps> = ({ userNotes }) => {
    return (
        <IconItemWithTooltip tooltip={userNotes} icon={SvgIcon.Asterisk} iconColor={Color.AccentYellow}>
            User notes
        </IconItemWithTooltip>
    );
};
