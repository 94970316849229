import { useMutation } from '@tanstack/react-query';

import { invoiceEntryService } from '../service/invoiceEntryService';

export const useInvoiceEntryDownloadReport = () => {
    const downloadMutation = useMutation({
        mutationFn: invoiceEntryService.downloadInvoiceReport,
    });

    return {
        downloadContractReport: downloadMutation.mutate,
    };
};
