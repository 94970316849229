import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { AssignmentCollectionReason } from '../types/AssignmentCollectionReason';

export const useTrAssignmentCollectionReason = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<AssignmentCollectionReason>(
        AssignmentCollectionReason,
        'item-assignment-collection-reason',
    );
};
