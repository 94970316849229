import { sharedApiI18n } from '@hofy/api-shared';

import { addLoginEmailPageI18n } from './addLoginEmailPageI18n';
import { authErrorI18n } from './authErrorI18n';
import { changePasswordSlideoutI18n } from './changePasswordSlideoutI18n';
import { forbiddenPageI18n } from './forbiddenPageI18n';
import { forgotPasswordPageI18n } from './forgotPasswordPageI18n';
import { genericI18n } from './genericI18n';
import { mergeIdentityPageI18n } from './mergeIdentityPageI18n';
import { organizationSignUpPageI18n } from './organizationSignUpPageI18n';
import { outdatedAppModalI18n } from './outdatedAppModalI18n';
import { pageNotFoundPageI18n } from './pageNotFoundPageI18n';
import { passwordFormI18n } from './passwordFormI18n';
import { sendInviteModalI18n } from './sendInviteModalI18n';
import { setPasswordPageI18n } from './setPasswordPageI18n';
import { setPasswordSlideoutI18n } from './setPasswordSlideoutI18n';
import { signInPageI18n } from './signInPageI18n';
import { signUpPageI18n } from './signUpPageI18n';
import { twoFactortI18n } from './twoFactorI18n';

export const authI18n = {
    ...authErrorI18n,
    ...changePasswordSlideoutI18n,
    ...forbiddenPageI18n,
    ...forgotPasswordPageI18n,
    ...genericI18n,
    ...organizationSignUpPageI18n,
    ...outdatedAppModalI18n,
    ...pageNotFoundPageI18n,
    ...passwordFormI18n,
    ...sendInviteModalI18n,
    ...setPasswordPageI18n,
    ...setPasswordSlideoutI18n,
    ...sharedApiI18n,
    ...signInPageI18n,
    ...signUpPageI18n,
    ...twoFactortI18n,
    ...mergeIdentityPageI18n,
    ...addLoginEmailPageI18n,
};

export type AuthI18nKey = keyof typeof authI18n;
