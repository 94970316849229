import React, { ElementRef, forwardRef } from 'react';

import { Labeled } from '../../labeled';
import { Textarea, TextareaProps } from './Textarea';

interface BaseLabeledTextareaProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledTextareaProps = BaseLabeledTextareaProps & TextareaProps;

export const LabeledTextarea = forwardRef<ElementRef<'textarea'>, LabeledTextareaProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,
            placeholder,
            rows,

            value,
            onChange,
            onBlur,
            onFocus,
            nullable,
            disabled,
            testKey,

            ...rest
        },
        ref,
    ) => {
        return (
            <Labeled
                as='label'
                label={label}
                isRequired={isRequired}
                content={
                    <Textarea
                        ref={ref}
                        value={value!}
                        placeholder={placeholder}
                        rows={rows}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        nullable={nullable}
                        disabled={disabled}
                        isError={!!errorMessage}
                        data-test-key={testKey}
                    />
                }
                errorMessage={errorMessage}
                helperText={helperText}
                {...rest}
            />
        );
    },
);
