import { CollectionSurveyStatus, OrderType, Ownership, ShipmentStatus, ShipmentType } from '@hofy/api-shared';
import { AnyRegion, Country, DateRangeStrings, DateString, UUID } from '@hofy/global';

export interface AdminAssignmentFilters {
    allShipments: boolean | null;
    assignedUsers: UUID[];
    collectionSurveyStatus: CollectionSurveyStatus[];
    configRequired: boolean | null;
    containingLaptopOnly: boolean | null;
    countries: Country[];
    deliveryDate: DateString | null;
    futureScheduled: boolean | null;
    isConsumable: boolean | null;
    isDisposal: boolean | null;
    isLoaner: boolean | null;
    isOnHold: boolean | null;
    isPartner: boolean | null;
    isPending: boolean | null;
    isStoreAndReuse: boolean | null;
    isStorageLocation: boolean | null;
    newJoiners: boolean | null;
    orderType: OrderType | null;
    otdDate: DateString | null;
    ownership: Ownership | null;
    region: AnyRegion[];
    scanned: boolean | null;
    scheduledOnly: boolean | null;
    search: string;
    shipDateRange: DateRangeStrings | null;
    shipmentStatus: ShipmentStatus[];
    shipmentType: ShipmentType | null;
    unscanned: boolean | null;
    warehouses: UUID[];
}

export const emptyAdminAssignmentFilters: AdminAssignmentFilters = {
    allShipments: null,
    assignedUsers: [],
    collectionSurveyStatus: [],
    configRequired: null,
    containingLaptopOnly: null,
    countries: [],
    deliveryDate: null,
    futureScheduled: null,
    isConsumable: null,
    isDisposal: null,
    isLoaner: null,
    isOnHold: null,
    isPartner: null,
    isPending: null,
    isStoreAndReuse: null,
    isStorageLocation: null,
    newJoiners: null,
    orderType: null,
    otdDate: null,
    ownership: null,
    region: [],
    scanned: null,
    scheduledOnly: null,
    search: '',
    shipDateRange: null,
    shipmentStatus: [],
    shipmentType: null,
    unscanned: null,
    warehouses: [],
};
