import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AdminPayload, adminsCacheKey, adminService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useCreateAdmin = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: AdminPayload) => adminService.createAdmin(p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [adminsCacheKey] });
            onSuccess();
            showToast({
                type: 'positive',
                message: 'Admin created successfully',
            });
        },
    });

    return { createAdmin: mutation.mutate, isCreateAdminLoading: mutation.isPending };
};
