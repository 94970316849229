import React, { FC } from 'react';

import { AssignmentDetailsDto, dataErasureService } from '@hofy/api-admin';
import { DataErasureStatus } from '@hofy/api-shared';
import { UploadFile } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, FeaturedIcon, FormSection, Paragraph3, SvgIcon, UseForm } from '@hofy/ui';

import { UpdateDataErasureFormProps } from '../../../store/dataErasures/useUpdateDataErasure';

interface DataErasureSlideoutCertificateProps {
    assignment: AssignmentDetailsDto;
    form: UseForm<UpdateDataErasureFormProps>;
    isLoadingMutation: boolean;
}

export const DataErasureSlideoutCertificate: FC<DataErasureSlideoutCertificateProps> = ({
    assignment,
    form,
    isLoadingMutation,
}) => {
    if (assignment.dataErasure?.status === DataErasureStatus.Cancelled) {
        return <Box />;
    }

    const fileElement = assignment.dataErasure?.certificateFileName ? (
        <Box row gap={8}>
            <FeaturedIcon icon={SvgIcon.File02} size={32} />
            <Paragraph3
                bold
                color={Color.ContentPrimary}
                flex='auto'
                onClick={() => dataErasureService.getAssignmentDataErasureCertificate(assignment.id)}
                pointer
                underline
                textNoWrap
                ellipsis
                overflow='hidden'
            >
                {assignment.dataErasure.certificateFileName}
            </Paragraph3>
        </Box>
    ) : (
        <UploadFile
            label='Upload file'
            accept='application/pdf'
            note='Accepted files: pdf'
            value={form.values.certificateFile}
            onChange={certificateFile =>
                form.setValues({
                    certificateFile,
                })
            }
            disabled={isLoadingMutation}
            isLoading={isLoadingMutation}
        />
    );

    return (
        <FormSection label='Upload certificate' paddingBottom={40}>
            {fileElement}
        </FormSection>
    );
};
