import React, { FC } from 'react';

import { PaymentType, paymentTypeColors, useTrPaymentType } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ContractPaymentTypeChipProps {
    type: PaymentType;
}

export const ContractPaymentTypeChip: FC<ContractPaymentTypeChipProps> = ({ type }) => {
    const trType = useTrPaymentType();
    const color = paymentTypeColors[type];
    return <Badge color={color} label={trType(type)} />;
};
