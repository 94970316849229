import React, { FC } from 'react';

import { RadioGroup } from '@hofy/common';
import { UUID } from '@hofy/global';
import {
    Box,
    Button,
    FormModal,
    LabelError,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { useResolveCollectionRequestLabelsAndPackaging } from '../../../../../store/collectionRequests/useResolveCollectionRequestLabelsAndPackaging';

interface ResolvePackagingModalProps {
    shipmentId: UUID;
    collectionRequestId: UUID;
    onClose(): void;
}

export const ResolvePackagingModal: FC<ResolvePackagingModalProps> = ({
    collectionRequestId,
    shipmentId,
    onClose,
}) => {
    const { form, isPending, isError } = useResolveCollectionRequestLabelsAndPackaging(
        shipmentId,
        collectionRequestId,
        onClose,
    );

    return (
        <FormModal
            onSubmit={form.submit}
            onClose={onClose}
            isLoading={isPending}
            isError={isError}
            width={600}
        >
            <ModalHeader title='Resolve labels and packaging' />
            <ModalContent>
                <Box column gap={16}>
                    <Paragraph3>
                        Did the user receive packaging from Hofy to complete the collection?
                    </Paragraph3>
                    <Box column gap={8}>
                        <RadioGroup
                            orientation='vertical'
                            items={['Yes', 'No']}
                            onChange={v => form.setValues({ isPackagingReceivedByUser: v === 'Yes' })}
                            value={getIsPackagingReceivedByUserRadioValue(
                                form.values.isPackagingReceivedByUser,
                            )}
                            labelFormatter={option => <Paragraph3>{option}</Paragraph3>}
                        />
                        {form.errors.isPackagingReceivedByUser && (
                            <LabelError message={form.errors.isPackagingReceivedByUser.toString()} />
                        )}
                    </Box>
                </Box>
            </ModalContent>
            <ModalFooter>
                <Button label='Close' type='secondary' onClick={onClose} />
                <SubmitButton label='Mark as resolved' />
            </ModalFooter>
        </FormModal>
    );
};

const getIsPackagingReceivedByUserRadioValue = (isPackagingReceivedByUser: boolean | null) => {
    if (isPackagingReceivedByUser === null) {
        return null;
    }
    return isPackagingReceivedByUser ? 'Yes' : 'No';
};
