import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { productsService } from '../service/productService';
import { productCacheKey } from './productCacheKey';

export const useProductQuery = (id: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [productCacheKey, id],
        queryFn: () => productsService.getProduct(id),
        refetchOnWindowFocus: false,
    });

    return {
        data,
        isLoading,
    };
};
