import { useQuery } from '@tanstack/react-query';

import { notificationService } from '../service/eventService';
import { eventCacheKey } from './cacheKey';

export const useOneOffEvents = () => {
    const { data: events, isLoading } = useQuery({
        queryKey: [eventCacheKey],
        queryFn: notificationService.getOneOffs,
    });

    return {
        events: events,
        isLoading,
    };
};
