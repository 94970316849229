import { useMutation, useQueryClient } from '@tanstack/react-query';

import { authService, InvitePayload } from '@hofy/api-auth';
import { useToast } from '@hofy/ui';

export const useSendInvite = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: authService.sendInvite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['admin/user/emails'] });
            showToast({
                type: 'positive',
                message: 'Invitation sent successfully',
            });
            onSuccess();
        },
    });

    const sendInvite = (payload: InvitePayload) => {
        mutation.mutate(payload);
    };

    return { sendInvite, isInviteMutationLoading: mutation.isPending };
};
