import React, { FC } from 'react';

import { SendOneOffPayload } from '@hofy/api-admin';
import { FormInput, FormTextarea } from '@hofy/common';
import { BoxProps, FormContainer, UseForm } from '@hofy/ui';

import { FormOrganizationSelect } from '../../../components/domain/organizations/FormOrganizationSelect';
import { RoleMultiFormDropdown } from './RoleMultiFormDropdown';

interface NotificationsEditorProps extends BoxProps {
    form: UseForm<SendOneOffPayload>;
}

export const NotificationsEditor: FC<NotificationsEditorProps> = ({ form, ...boxProps }) => {
    return (
        <FormContainer {...boxProps}>
            <FormOrganizationSelect
                label='Only to this organization'
                api={form.fields.organizationId}
                nullable
                placeholder='Choose organization'
            />
            <RoleMultiFormDropdown
                label='Send to users having role'
                value={form.values.roles}
                onChange={roles =>
                    form.setValues({
                        roles,
                    })
                }
                isError={form.errors.roles}
            />
            <FormInput
                label='Title'
                value={form.values.title}
                onChangeText={title =>
                    form.setValues({
                        title,
                    })
                }
                isError={form.errors.title}
            />
            <FormTextarea
                label='Message'
                rows={10}
                value={form.values.message}
                onChangeText={message =>
                    form.setValues({
                        message,
                    })
                }
                isError={form.errors.message}
            />
        </FormContainer>
    );
};
