import { DateRangeStrings } from '@hofy/global';
import { queryDateRangeParser } from '@hofy/router';
import { FilterDefinitionSingle, useFormatDateRange } from '@hofy/ui';

interface DateRangeFilterDefinitionOptions {
    name?: string;
    showInActive?: boolean;
}

export const useDateRangeFilterDefinition = ({
    name,
    showInActive,
}: DateRangeFilterDefinitionOptions): FilterDefinitionSingle<DateRangeStrings | null> => {
    return {
        type: 'single',
        name: name,
        query: queryDateRangeParser(),
        toLabel: useFormatDateRange(),
        showInActive,
    };
};
