import { getEnumValues } from '@hofy/global';

export enum AddonConfigurationSlideoutTab {
    Configuration = 'configuration',
    TokenPoolUsage = 'tokenPoolUsage',
    ConsumedTokens = 'consumedTokens',
}

export const allAddonConfigurationSlideoutTabs = getEnumValues<AddonConfigurationSlideoutTab>(
    AddonConfigurationSlideoutTab,
);

export const addonConfigurationSlideoutLabels = {
    [AddonConfigurationSlideoutTab.Configuration]: 'Configuration',
    [AddonConfigurationSlideoutTab.TokenPoolUsage]: 'Token pool usage',
    [AddonConfigurationSlideoutTab.ConsumedTokens]: 'Consumed tokens',
};
