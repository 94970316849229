import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools as TanstackReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { Color, Palette } from '@hofy/theme';
import { Box, Icon, Paragraph4, Portal, Pressable, SvgIcon, Tooltip } from '@hofy/ui';

interface ReactQueryDevtoolsProps {
    warningThreshold?: number;
}

export const ReactQueryDevtools: FC<ReactQueryDevtoolsProps> = ({ warningThreshold = 15 }) => {
    const [openDevtools, toggleDevtools] = useToggle(false);
    const [observers, setObservers] = useState(0);
    const client = useQueryClient();

    useEffect(() => {
        return client.getQueryCache().subscribe(event => {
            event.type === 'observerAdded' && setObservers(prev => prev + 1);
            event.type === 'observerRemoved' && setObservers(prev => prev - 1);
        });
    }, []);

    return (
        <Box marginLeft='auto'>
            <Tooltip body='Active queries (open devtools)' asChild>
                <Pressable
                    onClick={toggleDevtools}
                    row
                    gap={4}
                    paddingVertical={2}
                    paddingHorizontal={4}
                    bg={getObserverColor(observers, warningThreshold)}
                    rounded={4}
                >
                    <Paragraph4 color={Color.InteractionInvertedNormal}>{observers}</Paragraph4>
                    <Icon svg={SvgIcon.Server} color={Color.InteractionInvertedNormal} />
                </Pressable>
            </Tooltip>
            <Portal>{openDevtools && <TanstackReactQueryDevtools initialIsOpen />}</Portal>
        </Box>
    );
};

const getObserverColor = (observers: number, threshold: number) => {
    if (observers > threshold * 2) {
        return Palette.red['0'];
    }
    if (observers > threshold) {
        return Palette.orange['0'];
    }
    return undefined;
};
