import React, { FC } from 'react';

import {
    allOrganizationStatuses,
    allPlatformTiers,
    OrganizationStatus,
    Permission,
    PlatformTier,
    useTrOrganizationStatus,
} from '@hofy/api-shared';
import { ExportButton, Page } from '@hofy/common';
import { coreCurrencies, Currency, UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    ActiveFilters,
    ActiveFiltersLayout,
    Box,
    Button,
    NumberInput,
    PageHeader,
    Paragraph3,
    SearchInput,
    SvgIcon,
} from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { BooleanBlockFilter } from '../../components/design/blockFilters/BooleanBlockFilter';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { AdminsBlockFilter } from '../../components/domain/admin/AdminsBlockFilter';
import { useOrganizations } from '../../store/organizations/useOrganizations';
import { useTrPlatformTier } from '../../store/organizations/useTrPlatformTier';
import { OrganizationsTable } from './OrganizationsTable';

interface OrganizationsPageProps {
    onOpenOrganization(id: UUID): void;
    onNewOrganization(): void;
}

export const OrganizationsPage: FC<OrganizationsPageProps> = ({ onOpenOrganization, onNewOrganization }) => {
    const {
        organizations,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
        filters: { filters, filterCount, clearFilters, filterContext },
        downloadContractReport,
        creditUtilisationFrom,
        setCreditUtilisationFrom,
        creditUtilisationTo,
        setCreditUtilisationTo,
    } = useOrganizations();

    const trStatus = useTrOrganizationStatus();
    const trPlatformTier = useTrPlatformTier();
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page pageId='admin-organizations'>
            <PageHeader
                title='Organizations'
                rightSlot={
                    <>
                        <ExportButton onClick={downloadContractReport} />
                        <Box row gap={8}>
                            <Paragraph3 color={Color.ContentPrimary}>Credit utilisation:</Paragraph3>
                            <Box row gap={8} width={150}>
                                <NumberInput
                                    placeholder='From'
                                    nullable
                                    value={creditUtilisationFrom || null}
                                    onChange={v => setCreditUtilisationFrom(v)}
                                />
                                <NumberInput
                                    placeholder='To'
                                    nullable
                                    value={creditUtilisationTo || null}
                                    onChange={v => setCreditUtilisationTo(v)}
                                />
                            </Box>
                        </Box>
                        <SearchInput
                            value={filters.search.value}
                            onChange={filters.search.set}
                            placeholder='Search organisations…'
                            autoFocus
                        />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <PermissionWrapper permission={Permission.AdminOrganizationCreate} tooltip>
                            <Button
                                leftIcon={SvgIcon.Add}
                                label='Add organisation'
                                onClick={onNewOrganization}
                            />
                        </PermissionWrapper>
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <EnumMultiBlockFilter<PlatformTier>
                    title='Platform Tiers'
                    selected={filters.platformTiers.value}
                    onChange={filters.platformTiers.set}
                    items={allPlatformTiers}
                    renderItem={trPlatformTier}
                />
                <AdminsBlockFilter
                    title='Account Managers'
                    selected={filters.accountManagers.value}
                    onChange={filters.accountManagers.set}
                    users={filterContext.accountManagerAdmins}
                />
                <AdminsBlockFilter
                    title='Sales Rep'
                    selected={filters.salesReps.value}
                    onChange={filters.salesReps.set}
                    users={filterContext.salesAdmins}
                />
                <EnumMultiBlockFilter<OrganizationStatus>
                    title='Status'
                    selected={filters.statuses.value}
                    onChange={filters.statuses.set}
                    items={allOrganizationStatuses}
                    renderItem={trStatus}
                />
                <EnumMultiBlockFilter<Currency>
                    title='Currency'
                    selected={filters.currencies.value}
                    onChange={filters.currencies.set}
                    items={coreCurrencies}
                    renderItem={v => v}
                />
                <BooleanBlockFilter
                    filter={filters.isDeel}
                    title='Deel'
                    labelForTrue='Yes'
                    labelForFalse='No'
                />
            </BlockFilterContainer>
            <Box>
                <ActiveFiltersLayout show={filterCount > 0}>
                    <ActiveFilters filters={filters} onClearAll={clearFilters} />
                </ActiveFiltersLayout>
            </Box>
            <OrganizationsTable
                isLoading={isLoading}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                organizations={organizations || []}
                onOpenOrganization={onOpenOrganization}
            />
        </Page>
    );
};
