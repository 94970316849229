import React, { FC } from 'react';

import { Color, ColorKeywords } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Icon, SvgIcon } from '../icon';
import { Rotated } from '../utils';

interface ChevronProps extends TestKeyAware {
    isOpen: boolean;
    size?: number;
    color?: Color | ColorKeywords;
    angleOpen?: number;
    angleClosed?: number;
}

export const Chevron: FC<ChevronProps> = ({
    isOpen,
    size = 16,
    color = Color.ContentPrimary,
    angleOpen = 180,
    angleClosed = 0,
    testKey,
}) => {
    return (
        <Rotated angle={isOpen ? angleOpen : angleClosed} data-test-key={testKey}>
            <Icon color={color} svg={SvgIcon.ChevronDown} block size={size} />
        </Rotated>
    );
};
