import { BYODOrderPayload } from '@hofy/api-admin';
import { Currency, DateString, Price, UUID, zeroPrice } from '@hofy/global';
import { UseForm } from '@hofy/ui';

export interface BYODOrderFormData {
    toWarehouseId: UUID | null;
    organizationId: UUID | null;
    estimatedReceiveOn: DateString | null;
    items: BYODOrderFormItemData[];
    courierReference: string | null;
    trackingLink: string | null;
}
export interface BYODOrderFormItemData {
    variantId: UUID | null;
    expectedQuantity: number;
    unitPurchasePrice: Price;
}

export type BYODOnlyOrderFormData = Omit<BYODOrderFormData, 'items'>;
export type BYODOnlyItemsFormData = Pick<BYODOrderFormData, 'items'>;

export type BYODOnlyOrderFormValidData = Omit<BYODOrderPayload, 'items'>;
export type BYODOnlyItemsFormValidData = Pick<BYODOrderPayload, 'items'>;

export enum WizardSteps {
    Order = 'order',
    Items = 'items',
}

export type BYODMultipartForm = {
    [key in WizardSteps]: UseForm<any>;
};

export interface BYODMultipartValidForm extends BYODMultipartForm {
    [WizardSteps.Order]: UseForm<BYODOnlyOrderFormValidData>;
    [WizardSteps.Items]: UseForm<BYODOnlyItemsFormValidData>;
}

const defaultCurrency = Currency.USD;
export const emptyBYODOrderItemFormData = (currency?: Currency) => ({
    variantId: null,
    expectedQuantity: 1,
    unitPurchasePrice: zeroPrice(currency ?? defaultCurrency),
});
export const emptyBYODOrderFormData: Omit<BYODOrderFormData, 'items'> = {
    organizationId: null,
    toWarehouseId: null,
    estimatedReceiveOn: null,
    courierReference: null,
    trackingLink: null,
};
