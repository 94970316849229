export interface CompleteDataErasurePayload {
    certificateFile: CompleteDataErasureCertificateFileDto | null;
}

export interface CompleteDataErasureCertificateFileDto {
    name: string;
    content: string;
}

export const defaultCompleteDataErasurePayload: CompleteDataErasurePayload = {
    certificateFile: null,
};
