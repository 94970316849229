import React, { FC } from 'react';
import styled from 'styled-components';

import { initialCostSourceColor, ItemDto } from '@hofy/api-admin';
import { isDefectiveStatus, isDeviceCategory, Ownership, useTrItemPart } from '@hofy/api-shared';
import { SlideoutContent } from '@hofy/common';
import { formatDate, formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import {
    Alert,
    Badge,
    BooleanBadge,
    Box,
    FormGridRow,
    FormSection,
    InfoTooltipIcon,
    Labeled,
    LabeledText,
    Paragraph3,
} from '@hofy/ui';

import { ItemGradeChip } from '../../components/domain/items/ItemGradeChip';
import { ItemStatusChip } from '../../components/domain/items/ItemStatusChip';
import { ProductDetails } from '../../components/domain/products/ProductDetails';
import { useTrInitialCostSource } from '../../store/items/useTrInitialCostSource';
import { ContractLinkLabel } from '../contractsPage/ContractLink';
import { LabeledOrganizationLink } from '../organizationsPage/OrganizationDetailsLink';
import { ItemLocationCell } from './components/ItemLocationCell';
import { LabeledItemCodes } from './LabeledItemCodes';

interface ItemDetailsBlockProps {
    item: ItemDto;
}

export const ItemDetailsTab: FC<ItemDetailsBlockProps> = ({ item }) => {
    const { formatPriceWithISOCode: formatPrice } = usePrice();
    const trInitialCostSource = useTrInitialCostSource();
    const trItemPart = useTrItemPart();

    return (
        <SlideoutContent paddingBottom={30}>
            <FormSection label='Product details' marginTop={20}>
                <ProductDetails product={item.product} variant={item.variant} />
            </FormSection>
            <FormSection label='Item details' marginTop={20} marginBottom={!item.initialCost ? 20 : 0}>
                {isDefectiveStatus(item.status) && (
                    <Alert type='warning' description='Item marked as damaged or broken' />
                )}
                <FormGridRow columns={2}>
                    <LabeledText label='Id' content={`${item.id}`} />
                    <LabeledText label='Public Id' content={item.publicId} />
                    <LabeledItemCodes label='Item codes' itemCodes={item.itemCodes} />
                    {item.hofySubsidiary && (
                        <LabeledText label='Hofy subsidiary' content={item.hofySubsidiary?.name} />
                    )}
                    <LabeledText
                        label='Ownership'
                        content={
                            item.ownership.type === Ownership.Hofy
                                ? item.hofySubsidiary?.name
                                : item.billingEntity?.name
                        }
                    />
                    <Labeled label='Status' content={<ItemStatusChip status={item.status} />} />
                    <Labeled
                        label={
                            <Box row gap={4}>
                                Redistributable
                                <InfoTooltipIcon
                                    bodySlot={<RedistributableTooltipContent />}
                                    maxWidth={375}
                                    interactive
                                />
                            </Box>
                        }
                        content={<BooleanBadge value={!!item.redistributableInContractId} />}
                    />
                    <Labeled
                        label='Usable in store and reuse'
                        content={<BooleanBadge value={item.usableInStoreAndReuse} />}
                    />
                    <Labeled
                        label='Useable as loaner'
                        flex={1}
                        content={<BooleanBadge value={item.usableAsLoaner} />}
                    />
                    <LabeledText label='Serial number' content={item.serialNumber} />
                    <Labeled label='Location' content={<ItemLocationCell location={item.location} />} />
                    {item.purchasedAt && (
                        <LabeledText label='Purchased at' content={formatDateTime(item.purchasedAt)} />
                    )}
                </FormGridRow>
            </FormSection>
            <FormSection label='Item condition' marginTop={20}>
                <FormGridRow columns={2}>
                    <Labeled label='Grade' content={<ItemGradeChip grade={item.grade} />} />
                    <LabeledText label='Damage' content={item.damageNote ?? '--'} />
                    <Labeled
                        label='Damaged parts'
                        content={
                            item.damagedParts.length === 0 ? (
                                <Paragraph3>--</Paragraph3>
                            ) : (
                                <Box row gap={10}>
                                    {item.damagedParts.map(p => (
                                        <Badge key={p} label={trItemPart(p)} />
                                    ))}
                                </Box>
                            )
                        }
                    />
                    <LabeledText label='Damaged parts note' content={item.damagedPartsNote ?? '--'} />
                    <Labeled
                        label='Missing parts'
                        content={
                            item.missingParts.length === 0 ? (
                                <Paragraph3>--</Paragraph3>
                            ) : (
                                <Box row gap={10}>
                                    {item.missingParts.map(p => (
                                        <Badge key={p} label={trItemPart(p)} />
                                    ))}
                                </Box>
                            )
                        }
                    />
                    <LabeledText label='Missing parts note' content={item.missingPartsNote ?? '--'} />
                    <Labeled
                        label='With original packaging'
                        content={<BooleanBadge value={!item.isMissingOriginalPackaging} />}
                    />
                    {isDeviceCategory(item.product.category) && (
                        <Labeled
                            label='Is device unlocked'
                            content={<BooleanBadge value={!item.isDeviceLocked} />}
                        />
                    )}
                </FormGridRow>
            </FormSection>
            {item.contractDetails && (
                <FormSection label='Contract' marginTop={20}>
                    <FormGridRow columns={4}>
                        <LabeledOrganizationLink organization={item.contractDetails.organization} />
                        <LabeledText
                            label='Contract Id'
                            content={<ContractLinkLabel id={item.contractDetails} />}
                        />
                    </FormGridRow>
                </FormSection>
            )}
            <FormSection label='Valuation' marginTop={20} marginBottom={20}>
                <FormGridRow columns={3}>
                    <LabeledText content={formatPrice(item.initialCost)} label='Initial cost' />
                    <Labeled
                        label='Initial cost source'
                        content={
                            <Badge
                                color={initialCostSourceColor[item.initialCostSource]}
                                label={trInitialCostSource(item.initialCostSource)}
                            />
                        }
                    />
                    <LabeledText content={formatPrice(item.currentValue)} label='Current value' />
                    {item.ownership.type === Ownership.Organization && (
                        <LabeledText
                            content={formatPrice(item.customerValuation)}
                            label='Customer valuation'
                        />
                    )}
                </FormGridRow>
            </FormSection>
            <FormSection label='Warranty details' marginTop={20}>
                <FormGridRow columns={1}>
                    <LabeledText label='End date' content={formatDate(item.warrantyEndsOn)} />
                </FormGridRow>
            </FormSection>
        </SlideoutContent>
    );
};

const RedistributableTooltipContent = () => {
    const list = ["It's part of a contract with an organization", "It's in a Hofy warehouse"];
    return (
        <Box>
            <Paragraph3 color={Color.ContentInvertedPrimary}>An item is redistributable if BOTH:</Paragraph3>
            <Box marginLeft={15}>
                <ul>
                    {list.map(point => (
                        <LiCircled as='li' key={point} color={Color.ContentInvertedPrimary}>
                            {point}
                        </LiCircled>
                    ))}
                </ul>
            </Box>
            <Paragraph3 color={Color.ContentInvertedSecondary}>
                Note: rental store and reuse contracts detach the original item, so those items won&apos;t be
                redistributable.
            </Paragraph3>
        </Box>
    );
};

const LiCircled = styled(Paragraph3)`
    list-style-type: circle;
`;
