import { range } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, forwardRef } from 'react';
import styled from 'styled-components';

import { now } from '@hofy/helpers';
import { Color } from '@hofy/theme';

import { Box, Paragraph3, Pressable, PressableProps } from '../../base';
import { DropdownList } from '../../dropdown/DropdownList';
import { Chevron } from '../../shared';

interface MonthYearDropdownsProps {
    onYearChange(year: number): void;
    onMonthChange(month: number): void;
    date: DateTime;
    showMonths?: boolean;
}

const { year } = now();
const years = range(year + 2, year - 120);
const months = range(1, 13);

export const MonthYearDropdowns: FC<MonthYearDropdownsProps> = ({
    onYearChange,
    onMonthChange,
    date,
    showMonths = true,
}) => {
    return (
        <Box row gap={8}>
            {showMonths && (
                <DropdownList
                    value={date.month}
                    options={months}
                    toLabel={monthNumberToName}
                    toText={monthNumberToName}
                    onChange={month => onMonthChange(month - 1)}
                    contentMaxHeight={400}
                    trigger={isOpen => <DropdownTrigger label={date.toFormat('LLLL')} isOpen={isOpen} />}
                    asChild
                />
            )}
            <DropdownList
                value={date.year}
                options={years}
                toLabel={year => year.toString()}
                toText={year => year.toString()}
                onChange={onYearChange}
                contentMaxHeight={400}
                trigger={isOpen => <DropdownTrigger label={date.toFormat('yyyy')} isOpen={isOpen} />}
                asChild
            />
        </Box>
    );
};

const monthNumberToName = (month: number) => {
    return DateTime.fromFormat(`${month}`, 'M').toFormat('LLLL');
};

interface DropdownTriggerProps extends PressableProps {
    label: string;
    isOpen: boolean;
}

export const DropdownTrigger = forwardRef<HTMLDivElement, DropdownTriggerProps>(
    ({ label, isOpen, ...rest }, ref) => {
        return (
            <TriggerContainer
                ref={ref}
                row
                gap={2}
                rounded
                paddingVertical={4}
                paddingLeft={8}
                paddingRight={4}
                {...rest}
            >
                <Paragraph3 bold color={Color.ContentPrimary}>
                    {label}
                </Paragraph3>
                <Chevron isOpen={isOpen} size={12} color={Color.ContentSecondary} />
            </TriggerContainer>
        );
    },
);

const TriggerContainer = styled(Pressable)`
    &:hover {
        background-color: ${Color.InteractionNeutralSubtleHover};
    }
`;
