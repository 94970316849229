import { UUID } from '@hofy/global';

import { UsePurchaseOrderForm } from './useCreateMultipartPurchaseOrderForm';
import { useCreatePurchaseOrder } from './useCreatePurchaseOrder';
import { PurchaseOrderHeaderFormData } from './usePurchaseOrderHeaderForm';
import { PurchaseOrderItemsFormData } from './usePurchaseOrderItemsForm';

export const useCreateDraftPurchaseOrder = (
    formWrapper: UsePurchaseOrderForm,
    onSuccess?: (id: UUID) => void,
) => {
    const { createPurchaseOrder, isLoading } = useCreatePurchaseOrder(onSuccess);

    const saveAsDraft = () => {
        const headerData = formWrapper.form.forms.header.values as PurchaseOrderHeaderFormData;
        const itemData = formWrapper.form.forms.items.values as PurchaseOrderItemsFormData;

        createPurchaseOrder({
            ...headerData,
            ...itemData,
            isDraft: true,
        });
    };

    return {
        isLoading,
        saveAsDraft,
    };
};
