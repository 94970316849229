import { allItemLocations, ItemLocation, useTrItemLocation } from '@hofy/api-shared';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useLocationFilterDefinition = (): FilterDefinitionMulti<ItemLocation> => {
    const trItemLocation = useTrItemLocation();

    return {
        type: 'multi',
        name: 'Location',
        query: queryStringArrayParser(),
        allValues: allItemLocations,
        toLabel: trItemLocation,
    };
};
