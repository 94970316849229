import { ColorDye, getEnumValues, VisualType } from '@hofy/global';

export enum ShipmentStatus {
    Created = 'created',
    Backorder = 'backorder',
    Booked = 'booked',
    WithCourier = 'with_courier',
    Completed = 'completed',
    Canceled = 'cancelled',
}

export const allShipmentStatuses = getEnumValues<ShipmentStatus>(ShipmentStatus);

export const pendingShipmentStatuses = [
    ShipmentStatus.Backorder,
    ShipmentStatus.Booked,
    ShipmentStatus.WithCourier,
    ShipmentStatus.Created,
];

export const shipmentStatusColors: Record<ShipmentStatus, ColorDye> = {
    [ShipmentStatus.Created]: 'orange',
    [ShipmentStatus.Backorder]: 'teal',
    [ShipmentStatus.Booked]: 'aquamarine',
    [ShipmentStatus.WithCourier]: 'blue',
    [ShipmentStatus.Completed]: 'green',
    [ShipmentStatus.Canceled]: 'red',
};

const shipmentStatusTransitionsMap: Record<ShipmentStatus, ShipmentStatus[]> = {
    [ShipmentStatus.Created]: [ShipmentStatus.Booked, ShipmentStatus.Canceled, ShipmentStatus.Backorder],
    [ShipmentStatus.Backorder]: [ShipmentStatus.Booked, ShipmentStatus.Canceled],
    [ShipmentStatus.Booked]: [ShipmentStatus.Booked, ShipmentStatus.WithCourier, ShipmentStatus.Canceled],
    [ShipmentStatus.WithCourier]: [ShipmentStatus.Completed, ShipmentStatus.Canceled],
    [ShipmentStatus.Completed]: [],
    [ShipmentStatus.Canceled]: [],
};

export const shipmentStatusNotificationType: Record<ShipmentStatus, VisualType> = {
    [ShipmentStatus.Created]: 'informative',
    [ShipmentStatus.Backorder]: 'warning',
    [ShipmentStatus.Booked]: 'warning',
    [ShipmentStatus.WithCourier]: 'warning',
    [ShipmentStatus.Completed]: 'positive',
    [ShipmentStatus.Canceled]: 'negative',
};

export const isPossibleStatusTransition = (from: ShipmentStatus, to: ShipmentStatus) =>
    shipmentStatusTransitionsMap[from].includes(to);

export const isWithCourierOrCompletedOrCanceled = (status: ShipmentStatus): boolean =>
    [ShipmentStatus.Completed, ShipmentStatus.Canceled, ShipmentStatus.WithCourier].includes(status);
