import React from 'react';

import { InspectionWithDeviceCheckDetailsDto } from '@hofy/api-admin';
import { FormSection, Labeled } from '@hofy/ui';

import { InspectionStatusChip } from '../components/InspectionStatusChip';

interface DeviceCheckDetailsSectionProps {
    inspection: InspectionWithDeviceCheckDetailsDto;
}

export const DeviceCheckDetailsSection: React.FC<DeviceCheckDetailsSectionProps> = ({
    inspection: {
        deviceCheck: { deviceCheckStatus },
    },
}) => {
    return (
        <FormSection label='Device check details'>
            <Labeled label='Status' content={<InspectionStatusChip status={deviceCheckStatus} />} />
        </FormSection>
    );
};
