import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { RepaymentPlanDto } from './types/RepaymentPlanDto';
import { RepaymentPlansFilters } from './types/RepaymentPlansFilters';
import { TriggerInvoicingPayload } from './types/TriggerInvoicingPayload';

class RepaymentPlanService {
    public listRepaymentPlans = async (
        filters: RepaymentPlansFilters,
        page: PageRequest,
    ): Promise<PageResponse<RepaymentPlanDto>> => {
        return restClient.getJson<PageResponse<RepaymentPlanDto>>(
            stringifyUrl({
                url: `/api/admin/repayment-plans`,
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getRepaymentPlan = (id: UUID): Promise<RepaymentPlanDto> => {
        return restClient.getJson<RepaymentPlanDto>(`/api/admin/repayment-plans/${id}`);
    };

    public recheckRepaymentPlans = (ids: UUID[]): Promise<void> => {
        return restClient
            .post(
                stringifyUrl({
                    url: `/api/admin/repayment-plans/recheck`,
                    query: {
                        planId: ids,
                    },
                }),
            )
            .then();
    };

    public triggerInvoicing = (billingEntityId: UUID, payload: TriggerInvoicingPayload): Promise<void> => {
        return restClient
            .post(`/api/admin/repayment-plans/trigger-invoicing/${billingEntityId}`, payload)
            .then();
    };
    public deleteRejectedPlans = (billingEntityId: UUID): Promise<void> => {
        return restClient.post(`/api/admin/repayment-plans/delete-rejected/${billingEntityId}`);
    };
    public deleteRejectedPlan = (planId: UUID): Promise<void> => {
        return restClient.post(`/api/admin/repayment-plans/${planId}/delete-rejected`);
    };
    public forceRecreatePlan = (planId: UUID): Promise<void> => {
        return restClient.post(`/api/admin/repayment-plans/${planId}/force-recreate`);
    };
    public cancel = (planId: UUID): Promise<void> => {
        return restClient.post(`/api/admin/repayment-plans/${planId}/cancel`);
    };
    public syncRepaymentPlans = (): Promise<void> => {
        return restClient.post(`/api/admin/repayment-plans/sync`);
    };
}

export const repaymentPlanService = new RepaymentPlanService();
