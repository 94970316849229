import React, { ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Paragraph2, Paragraph3 } from '@hofy/ui';

export const PurchaseOrderItemHeader = ({ text }: { text: ReactNode }) => (
    <Paragraph2 color={Color.Neutral900} marginBottom={4}>
        {text}
    </Paragraph2>
);

export const PurchaseOrderItemDescription = ({ text }: { text: ReactNode }) => (
    <Paragraph3>{text}</Paragraph3>
);
