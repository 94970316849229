import React, { FC, useState } from 'react';

import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { ConfirmModal, Modals, Spinner } from '@hofy/ui';

import { useRecheckRepaymentPlan } from '../../../store/repaymentPlan/useRecheckRepaymentPlan';
import { useSyncRepaymentPlans } from '../../../store/repaymentPlan/useSyncRepaymentPlans';

export const RepaymentPlansMenu: FC = () => {
    const { hasPermission } = usePermission();

    const [syncPlansModal, setSyncPlansModal] = useState(false);
    const [recheckRepaymentPlansModal, setRecheckRepaymentPlansModal] = useState(false);
    const { syncPlans, isLoading } = useSyncRepaymentPlans();
    const { recheck } = useRecheckRepaymentPlan([]);

    if (isLoading) {
        return <Spinner size={16} />;
    }

    return (
        <>
            <MoreMenu
                items={[
                    {
                        action: () => setSyncPlansModal(true),
                        label: 'Sync repayment plans',
                        visible: hasPermission(Permission.AdminRepaymentPlanManage),
                    },
                    {
                        action: () => setRecheckRepaymentPlansModal(true),
                        label: 'Recheck rejected statuses',
                        visible: hasPermission(Permission.AdminRepaymentPlanManage),
                    },
                ]}
            />
            <Modals>
                {syncPlansModal && (
                    <ConfirmModal
                        keyPrefix='sync-two-modal'
                        onConfirm={syncPlans}
                        onClose={() => setSyncPlansModal(false)}
                    />
                )}{' '}
                {recheckRepaymentPlansModal && (
                    <ConfirmModal
                        keyPrefix='recheck-plans-modal'
                        onConfirm={recheck}
                        onClose={() => setRecheckRepaymentPlansModal(false)}
                    />
                )}
            </Modals>
        </>
    );
};
