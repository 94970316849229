import { DialogButton, DirectiveDescriptor, directivesPluginHooks } from '@mdxeditor/editor';
import React from 'react';
import styled from 'styled-components';

import { Box } from '@hofy/ui';

import { YouTubeIcon } from './YouTubeIcon';

export const YoutubeDirectiveDescriptor: DirectiveDescriptor = {
    name: 'callout',
    testNode(node) {
        return node.name === 'youtube';
    },
    attributes: [],
    hasChildren: true,
    Editor: params => {
        const { id } = params.mdastNode.attributes || {};

        return (
            <Box rounded={8} overflow='hidden' relative>
                <YouTubeImage src={`https://i.ytimg.com/vi/${id}/maxresdefault.jpg`} />
                <YouTubeCenterWrapper centered>
                    <YouTubeIcon size={96} color />
                </YouTubeCenterWrapper>
            </Box>
        );
    },
};

export const YouTubeButton = () => {
    const insertDirective = directivesPluginHooks.usePublisher('insertDirective');

    return (
        <DialogButton
            tooltipTitle='Insert Youtube video'
            submitButtonTitle='Insert video'
            dialogInputPlaceholder='Paste the youtube video URL'
            buttonContent={<YouTubeIcon size={24} />}
            onSubmit={url => {
                const videoId = new URL(url).searchParams.get('v');
                if (videoId) {
                    insertDirective({
                        name: 'youtube',
                        type: 'leafDirective',
                        attributes: { id: videoId },
                        children: [],
                    } as any);
                } else {
                    alert('Invalid YouTube URL');
                }
            }}
        />
    );
};

const YouTubeImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 16 / 9;
`;

const YouTubeCenterWrapper = styled(Box)`
    position: absolute;
    inset: 0;
`;
