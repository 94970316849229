import { PostAddress } from '@hofy/api-shared';
import {
    Country,
    DateString,
    isInvalidUSAState,
    isPassportAndDateOfBirthNeeded,
    isStateRequired,
    isTaxIdCountry,
    isUSA,
    Phone,
} from '@hofy/global';
import { isInvalidPhoneNumber } from '@hofy/helpers';
import { isBlank } from '@hofy/ui';

export const isAddressEmpty = (address?: {
    line1: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: Country;

    taxIdNumber: string | null;
    passportNumber: string | null;
    dateOfBirth: DateString | null;
    phoneNumber: Phone | null;
}) => {
    if (!address) {
        return true;
    }
    return (
        isBlank(address.line1) ||
        isBlank(address.postCode) ||
        isBlank(address.city) ||
        isInvalidOptionalState(address.country, address.state) ||
        (isTaxIdCountry(address.country) && isBlank(address.taxIdNumber)) ||
        (isPassportAndDateOfBirthNeeded(address.country) &&
            (isBlank(address.passportNumber) || !address.dateOfBirth)) ||
        isInvalidPhoneNumber(address.phoneNumber)
    );
};

export const isInvalidOptionalState = (country: Country, state: string | null) => {
    const needToCheckState = isStateRequired(country);

    if (!needToCheckState) {
        return false;
    }

    if (isBlank(state)) {
        return true;
    }

    if (isUSA(country)) {
        return isInvalidUSAState(state);
    }

    return false;
};

export const arePostAddressesEqual = (a1?: PostAddress | null, a2?: PostAddress | null) => {
    if (!a1 || !a2) {
        return a1 === a2;
    }
    return (
        a1.line1 === a2.line1 &&
        a1.line2 === a2.line2 &&
        a1.city === a2.city &&
        a1.state === a2.state &&
        a1.postCode === a2.postCode &&
        a1.country === a2.country
    );
};
