export enum FulfilmentError {
    CannotBillCustomerOwnedWithoutLocation = 'cannot_bill_customer_owned_without_location',
    CannotBillHofyOwnedWithoutContract = 'cannot_bill_hofy_owned_without_contract',
    ContractMustBeRentalForBill = 'contract_must_be_rental_for_bill',
    MissingAssetValueForToken = 'missing_asset_value_for_token',
    MissingContractForBill = 'missing_contract_for_bill',
    MissingFeeForBill = 'missing_fee_for_bill',
    MissingFulfillmentEntityForBill = 'missing_fulfillment_entity',
    MissingInvoiceAndTokenForBill = 'missing_invoice_or_token_for_bill',
    FeeCannotBeNegative = 'fee_cannot_be_negative',
    FeeCannotBeZero = 'fee_cannot_be_zero',
    RentalContractNotReadyForStoreAndReuse = 'rental_contract_not_ready_for_store_and_reuse',
    ServiceNotEligible = 'service_not_eligible',
    AssignmentIsNotConsumable = 'assignment_is_not_consumable',
    InvalidContractType = 'invalid_contract_type',
}
