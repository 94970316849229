import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { BillingEntityDto, HofySubsidiaryRef } from '@hofy/api-shared';
import { FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { BillingEntityLink } from '../../../invoicingPage/billingEntities/BillingEntityLink';

interface BillingSectionProps {
    label: string;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityDto;
}

export const BillingSection: FC<BillingSectionProps> = ({ label, billingEntity, hofySubsidiary }) => {
    return (
        <FormSection label={label}>
            <FormGridRow columns={2}>
                <LabeledText label='Invoice from' content={hofySubsidiary.name} />
                <LabeledText
                    label='Invoice to'
                    content={<BillingEntityLink billingEntity={billingEntity} />}
                />
            </FormGridRow>
            <FormGridRow columns={2}>
                <LabeledText label='Customer VAT number' content={billingEntity.vatNumber} />
                <LabeledText
                    label='Customer invoice address'
                    content={<PostAddressView address={billingEntity?.billingAddress} />}
                />
            </FormGridRow>
        </FormSection>
    );
};
