import React, { FC } from 'react';

import { VariantDto } from '@hofy/api-admin';
import { formatVariant } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, Paragraph4, SvgIcon } from '@hofy/ui';

import { InactiveBadge } from '../InactiveBadge';

interface VariantRowProps {
    variant: VariantDto;
    selected?: boolean;
    inactive?: boolean;
    onClick(): void;
}

export const VariantRow: FC<VariantRowProps> = ({ variant, selected, onClick, inactive }) => {
    return (
        <Box row paddingVertical={10} onClick={onClick} pointer>
            <ProductImage image={variant.image?.url} size={50} />
            <Box paddingLeft={30} flex={1} row gap={8}>
                {inactive && <InactiveBadge />}
                <Box column gap={4}>
                    <Paragraph3>{formatVariant(variant)}</Paragraph3>
                    <Paragraph4>{variant.sku}</Paragraph4>
                </Box>
            </Box>
            {selected && (
                <Icon svg={SvgIcon.Check} size={24} color={Color.ContentPositive} marginRight={20} />
            )}
        </Box>
    );
};
