import React, { FC } from 'react';

import { getInvoiceStatusDate, InvoiceStatusHistoryDto } from '@hofy/api-admin';
import { allInvoiceStatuses, useTrInvoiceStatus } from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';
import { FormSection } from '@hofy/ui';

interface InvoiceStatusHistorySectionProps {
    statusHistory: InvoiceStatusHistoryDto;
    isCreditNote: boolean;
}

export const InvoiceStatusHistorySection: FC<InvoiceStatusHistorySectionProps> = ({
    statusHistory,
    isCreditNote,
}) => {
    const trStatus = useTrInvoiceStatus(isCreditNote);

    return (
        <FormSection label='Status history'>
            <StatusHistory
                allStatuses={allInvoiceStatuses}
                dateGetter={s => getInvoiceStatusDate(statusHistory, s)}
                labelFormatter={trStatus}
            />
        </FormSection>
    );
};
