import { maxBy, minBy } from 'lodash';

import { Currency, currencySymbols } from './Currency';
import { Decimal, decimalToNumber } from './Decimal';

export interface Price {
    amount: Decimal;
    currency: Currency;
}

export interface PricePair {
    price: Price;
    originalPrice: Price;
}

export const priceToNumber = (p1: Price) => {
    return parseFloat(p1.amount);
};

export const numberToPrice = (amount: number, currency: Currency): Price => {
    return { amount: amount.toFixed(2), currency };
};

export const decimalToPrice = (amount: Decimal | null, currency: Currency): Price | null => {
    if (amount === null || isNaN(decimalToNumber(amount))) {
        return null;
    }
    return { amount, currency };
};

export const toCurrency = (p1: Price, currency: Currency) => {
    return {
        currency,
        amount: p1.amount,
    };
};

export const sumPrice = (p1: Price, p2: Price) => {
    if (p1.currency !== p2.currency) {
        throw new Error(
            'Cannot sum prices with different currencies: ' + p1.currency + ' and ' + p2.currency,
        );
    }
    return {
        currency: p2.currency,
        amount: (priceToNumber(p1) + priceToNumber(p2)).toFixed(2),
    };
};

export const sumPrices = (prices: Price[], currency: Currency) => {
    if (prices.some(p => p.currency !== currency)) {
        throw new Error('Cannot sum prices with different currencies');
    }
    return {
        currency,
        amount: prices.reduce((acc, p) => acc + priceToNumber(p), 0).toFixed(2),
    };
};

export const minusPrice = (p1: Price, p2: Price) => {
    return {
        currency: p2.currency,
        amount: (priceToNumber(p1) - priceToNumber(p2)).toFixed(2),
    };
};

export const isNegative = (p1: Price) => {
    return priceToNumber(p1) < 0;
};

export const isPositive = (p1: Price) => {
    return priceToNumber(p1) > 0;
};

export const isZero = (p1: Price) => {
    return priceToNumber(p1) === 0;
};

export const minPrice = (p: Price[]): Price => {
    return minBy(p, priceToNumber)!;
};
export const maxPrice = (p: Price[]): Price => {
    return maxBy(p, priceToNumber)!;
};

export const zeroDollars: Price = {
    amount: '0.00',
    currency: Currency.USD,
};

export const zeroPrice = (currency: Currency): Price => {
    return { amount: '0.00', currency };
};

export const isPriceGreaterThan = (p1: Price, p2: Price) => priceToNumber(p1) > priceToNumber(p2);

export const isPriceEqual = (p1: Price, p2: Price) => priceToNumber(p1) === priceToNumber(p2);

export const formatPriceWithCurrency = (price: Price, precision: number = 2): string => {
    return formatDecimalWithCurrency(price.amount, price.currency, precision);
};

export const formatDecimalWithCurrency = (
    decimal: Decimal,
    currency: Currency,
    precision: number = 2,
): string => {
    const currencySymbol = currencySymbols[currency];
    const priceValue = parseFloat(decimal);
    if (priceValue < 0) {
        return ['-', currencySymbol, (-1 * priceValue).toFixed(precision)].join('');
    } else {
        return [currencySymbol, priceValue.toFixed(precision)].join('');
    }
};

export const formatDecimalWithISOCode = (
    decimal: Decimal,
    isoCode: string,
    precision: number = 2,
): string => {
    const priceValue = parseFloat(decimal);
    return [priceValue.toFixed(precision), isoCode].join(' ');
};
