export const productBrandI18n = {
    'product-brand.apple': 'Apple',
    'product-brand.dell': 'Dell',
    'product-brand.lenovo': 'Lenovo',
    'product-brand.hp': 'HP',
    'product-brand.acer': 'Acer',
    'product-brand.asus': 'Asus',
    'product-brand.microsoft': 'Microsoft',
    'product-brand.samsung': 'Samsung',
    'product-brand.other': 'Other',
};
