import { useMemo } from 'react';

import { UserAssignmentsByShipmentDto, UserShipmentDto } from '@hofy/api-admin';
import {
    isShipmentFromHofyWarehouse,
    isShipmentFromOrganizationWarehouse,
    isShipmentFromSupplierToUser,
    isShipmentFromUser,
    isShipmentToHofyWarehouse,
    isShipmentToOrganizationWarehouse,
    isShipmentToUser,
} from '@hofy/api-shared';

import { ShipmentPoint, ShipmentPointType } from './types/ShipmentPoint';

export const useShipmentPoints = (user: UserAssignmentsByShipmentDto, shipment: UserShipmentDto) => {
    const shipmentFromPoint: ShipmentPoint = useMemo(() => {
        if (isShipmentFromUser(shipment)) {
            return { type: ShipmentPointType.Address, address: shipment.fromAddress || user.userAddress };
        }
        if (isShipmentFromOrganizationWarehouse(shipment)) {
            return {
                type: ShipmentPointType.Address,
                address: shipment.fromAddress || shipment.fromWarehouse.address!,
            };
        }
        if (isShipmentFromHofyWarehouse(shipment)) {
            return { type: ShipmentPointType.HofyWarehouse, warehouse: shipment.fromWarehouse };
        }
        if (isShipmentFromSupplierToUser(shipment)) {
            return { type: ShipmentPointType.Supplier, supplier: shipment.fromSupplier };
        }
        throw new Error('Invalid shipment from point');
    }, [shipment, user.userAddress]);

    const shipmentToPoint: ShipmentPoint = useMemo(() => {
        if (isShipmentToUser(shipment)) {
            return {
                type: ShipmentPointType.Address,
                address: shipment.toAddress || user.userAddress,
            };
        }
        if (isShipmentToOrganizationWarehouse(shipment)) {
            return {
                type: ShipmentPointType.Address,
                address: shipment.toAddress || shipment.toWarehouse.address!,
            };
        }
        if (isShipmentToHofyWarehouse(shipment)) {
            return { type: ShipmentPointType.HofyWarehouse, warehouse: shipment.toWarehouse };
        }
        throw new Error('Invalid shipment to point');
    }, [shipment, user.userAddress]);

    return { shipmentFromPoint, shipmentToPoint };
};
