import {
    BillingEntityRef,
    HofySubsidiaryRef,
    InvoiceFileDto,
    InvoiceStatus,
    NetsuiteStatusDto,
    OrganizationRefDto,
} from '@hofy/api-shared';
import { DateString, DateTimeString, Price, UUID } from '@hofy/global';

import { ExcludedFromDunningDto } from './InvoiceDto';
import { InvoicePaymentStatus } from './InvoicePaymentStatus';

export interface InvoiceDetailsDto {
    id: UUID;
    reference: string;
    isCreditNote: boolean;
    status: InvoiceStatus;

    invoiceTime: DateString;
    dueDate: DateString;
    hofySubsidiary: HofySubsidiaryRef;
    billingEntity: BillingEntityRef;
    organization: OrganizationRefDto | null;
    twoApi: boolean;
    twoSpreadsheet: boolean;
    revenueRecognitionGenerated: boolean;

    paymentSummary: PaymentSummaryDto;
    statusHistory: InvoiceStatusHistoryDto;
    notification: InvoiceNotificationDto;
    excludedFromDunning: ExcludedFromDunningDto;
    netsuite: NetsuiteStatusDto;

    appliedInvoices: AppliedTransactionDto[];
    appliedCreditNotes: AppliedTransactionDto[];
}

export interface InvoiceStatusHistoryDto {
    draftAt: DateTimeString;
    openAt: DateTimeString | null;
    processingPaymentAt: DateTimeString | null;
    paidAt: DateTimeString | null;
    voidedAt: DateTimeString | null;
    overdueAt: DateTimeString | null;
}

export interface PaymentSummaryDto {
    grossPrice: Price;
    amountPaid: Price;
    amountCredited: Price;
    balance: Price;
    paymentStatus: InvoicePaymentStatus | null;
    paymentErrorReason: string | null;
}

export interface InvoiceNotificationDto {
    firstSentAt: DateString | null;
    lastResentAt: DateString | null;
    lastReminderAt: DateString | null;
    files: InvoiceFileDto[];
}

export interface AppliedTransactionDto {
    id: UUID;
    reference: string;
    status: InvoiceStatus;
    applied: Price;
    date: DateString;
}

export const getInvoiceStatusDate = (
    r: InvoiceStatusHistoryDto,
    status: InvoiceStatus,
): DateTimeString | null => {
    switch (status) {
        case InvoiceStatus.Draft:
            return r.draftAt;
        case InvoiceStatus.Open:
            return r.openAt;
        case InvoiceStatus.ProcessingPayment:
            return r.processingPaymentAt;
        case InvoiceStatus.Paid:
            return r.paidAt;
        case InvoiceStatus.Voided:
            return r.voidedAt;
        case InvoiceStatus.Overdue:
            return r.overdueAt;
    }
};
