import React from 'react';

import { FormFieldApi } from '../../../hooks/index';
import { Optional } from '../../../types/index';
import { Labeled } from '../../labeled/index';
import { CheckboxGroup, CheckboxGroupProps } from './CheckboxGroup';

type FormCheckboxGroupBaseProps<T> = Omit<
    Optional<CheckboxGroupProps<T>, 'onChange'>,
    'errorMessage' | 'value'
>;

interface FormCheckboxGroupProps<T> extends FormCheckboxGroupBaseProps<T> {
    label: string;
    helperText?: string;
    isRequired?: boolean;
    api: FormFieldApi<T[]>;
}

export const FormCheckboxGroup = <T extends any>({
    label,
    isRequired,
    api,
    helperText,
    onChange,
    ...rest
}: FormCheckboxGroupProps<T>) => {
    return (
        <Labeled
            label={label}
            isRequired={isRequired}
            content={
                <CheckboxGroup
                    value={api.value}
                    onChange={value => {
                        api.setValue(value);
                        onChange?.(value);
                    }}
                    {...rest}
                />
            }
            errorMessage={api.errorMessage}
            helperText={helperText}
            {...rest}
        />
    );
};
