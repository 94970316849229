import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { payoutsCacheKey, payoutService } from '@hofy/api-admin';
import { FileRef } from '@hofy/common';
import { useToast } from '@hofy/ui';

export const useUploadTwoPayouts = (onSuccess: () => void) => {
    const [file, setFile] = useState<FileRef | null>(null);
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: payoutService.uploadTwoPayouts,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [payoutsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Two payouts created',
            });
            setFile(null);
            onSuccess();
        },
    });

    const submit = () => {
        if (file) {
            mutation.mutate({ file });
        }
    };

    return { file, setFile, isLoading: mutation.isPending, submit };
};
