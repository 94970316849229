import React, { FC } from 'react';
import styled from 'styled-components';

import { Box, Paragraph3, TextCell } from '@hofy/ui';

interface PurchaseOrderApprovalCellProps {
    requiredApprovals: number;
    approvals: number;
}

export const PurchaseOrderApprovalCell: FC<PurchaseOrderApprovalCellProps> = ({
    requiredApprovals,
    approvals,
}) => {
    return (
        <Box overflow='hidden'>
            <Box row>
                <QuantityLabel marginRight={4}>Required:</QuantityLabel>
                <QuantityCell>{requiredApprovals}</QuantityCell>
            </Box>
            <Box row>
                <QuantityLabel marginRight={4}>Approved:</QuantityLabel>
                <QuantityCell>{approvals}</QuantityCell>
            </Box>
        </Box>
    );
};

const QuantityCell = styled(TextCell)`
    font-weight: bold;
`;

const QuantityLabel = styled(Paragraph3)`
    min-width: 80px;
    text-align: right;
`;
