export const sendInviteModalI18n = {
    'send-invite-modal.header': 'Send email invite',
    'send-invite-modal.form.work-email': 'Work email address',
    'send-invite-modal.form.personal-email': 'Personal email address',
    'send-invite-modal.form.email-invite': 'Send email invite to:',
    'send-invite-modal.form.login-email-tooltip':
        'Team members log in to Hofy using their primary email address. The other email can be used to send notification emails to, but not to log in.',
    'send-invite-modal.header.cancel-button': 'Cancel',
    'send-invite-modal.header.submit-button': 'Send invite',
};
