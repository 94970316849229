import { useMutation, useQueryClient } from '@tanstack/react-query';

import { activityLogsCacheKey, activityLogService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useHideActivityLog = () => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: activityLogService.hideActivityLog,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [activityLogsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Activity log hidden',
            });
        },
    });

    return {
        hideActivityLog: mutation.mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
