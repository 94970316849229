import React, { FC } from 'react';

import { warehouseService } from '@hofy/api-admin';
import { WarehouseBinDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    AlertTooltipIcon,
    FormFieldApi,
    LabeledDebouncedInput,
    Spinner,
    SvgIcon,
    TooltipIcon,
    useAsyncInputFormData,
} from '@hofy/ui';

interface WarehouseBinInputProps {
    api: FormFieldApi<string | null>;
    warehouseId: UUID;
    isRequired?: boolean;
    width?: number;
}

export const WarehouseBinInput: FC<WarehouseBinInputProps> = ({
    warehouseId,
    api,
    isRequired = false,
    width,
}) => {
    const {
        inputValue,
        setInputValue,
        data: fetchedBin,
        isLoading,
    } = useAsyncInputFormData({
        api,
        dataProvider: inputValue => warehouseService.getWarehouseBinByIdentifier(warehouseId, inputValue),
    });

    return (
        <LabeledDebouncedInput
            ref={api.ref}
            label='Warehouse bin identifier'
            value={inputValue}
            onChange={setInputValue}
            rightSlot={isLoading ? <Spinner size={24} /> : getInputIcon(fetchedBin, isRequired)}
            errorMessage={api.errorMessage}
            onBlur={() => api.setTouched(true)}
            isRequired={isRequired}
            width={width}
        />
    );
};

const getInputIcon = (fetchedBin: WarehouseBinDto | null, isRequired: boolean) => {
    if (fetchedBin) {
        return (
            <TooltipIcon body='Warehouse bin found' icon={SvgIcon.Check} iconColor={Color.ContentPositive} />
        );
    }

    return isRequired ? null : (
        <AlertTooltipIcon body='Warehouse bin not found. No data will be submitted.' />
    );
};
