import React, { FC } from 'react';

import { UUID } from '@hofy/global';

import { useCreateDraftPurchaseOrder } from '../../../../store/purchaseOrders/useCreateDraftPurchaseOrder';
import { useCreateMultipartPurchaseOrderForm } from '../../../../store/purchaseOrders/useCreateMultipartPurchaseOrderForm';
import { PurchaseOrderFormSlideout } from './components/PurchaseOrderFormSlideout';

interface CreatePurchaseOrderSlideoutProps {
    onClose(): void;
    onDraftSuccess?(id: UUID): void;
}

export const CreatePurchaseOrderSlideout: FC<CreatePurchaseOrderSlideoutProps> = ({
    onClose,
    onDraftSuccess,
}) => {
    const formState = useCreateMultipartPurchaseOrderForm(onClose);

    const { saveAsDraft, isLoading: isLoadingSaveAsDraft } = useCreateDraftPurchaseOrder(
        formState,
        onDraftSuccess,
    );

    const slideoutTitle = formState.form.forms.header.values.isDropship
        ? 'Create new dropship purchase order'
        : 'Create new purchase order';

    return (
        <PurchaseOrderFormSlideout
            title={slideoutTitle}
            isPreparing={formState.isPreparing}
            onClose={onClose}
            formWrapper={formState}
            saveAsDraft={saveAsDraft}
            isLoadingSaveAsDraft={isLoadingSaveAsDraft}
        />
    );
};
