import { useMutation, useQueryClient } from '@tanstack/react-query';

import { organizationService, UpdateRentalSettingPayload } from '@hofy/api-admin';
import { CategoryContractSettingsConfigDto, rentalLength } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateContractSettings = (
    organizationId: UUID,
    contractSettings: CategoryContractSettingsConfigDto,
    onSuccess: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const updateContractSettingsMutation = useMutation({
        mutationFn: (payload: UpdateRentalSettingPayload) =>
            organizationService.updateOrganizationContractSettings(organizationId, contractSettings.id, {
                ...contractSettings,
                serviceDuration: payload.rentalTerm ? rentalLength[payload.rentalTerm] : null,
                rentalTerm: payload.rentalTerm,
                paymentSchema: payload.paymentSchema,
            }),

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organizationId] });
            showToast({
                type: 'positive',
                message: `Organization rental term successfully saved`,
            });
            onSuccess?.();
        },
    });

    const mutate = (data: UpdateRentalSettingPayload) => {
        updateContractSettingsMutation.mutate(data);
    };

    const form = useForm<UpdateRentalSettingPayload>({
        initial: {
            rentalTerm: contractSettings.rentalTerm,
            paymentSchema: contractSettings.paymentSchema,
        },
        onSubmit: mutate,
    });

    return {
        form,
        isLoadingMutation: updateContractSettingsMutation.isPending,
    };
};
