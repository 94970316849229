export const repaymentStatusI18n = {
    'repayment-status.pending': 'Pending',
    'repayment-status.created': 'Created',
    'repayment-status.creation-error': 'Creation error',
    'repayment-status.funding-approved': 'Funding approved',
    'repayment-status.funding-rejected': 'Funding rejected',
    'repayment-status.funding-check-error': 'Funding check error',
    'repayment-status.fulfilled': 'Fulfilled',
    'repayment-status.fulfill-error': 'Fulfill error',
    'repayment-status.approved': 'Approved',
    'repayment-status.instalment-mismatch': 'Instalment mismatch',
    'repayment-status.approval-error': 'Approval error',
    'repayment-status.cancelled': 'Cancelled',
    'repayment-status.cancellation-error': 'Cancellation error',
    'repayment-status.cancellation-mismatch': 'Cancellation mismatch',
    'repayment-status.fully-invoiced': 'Fully invoiced',
    'repayment-status.cancellation-pending': 'Cancellation pending',
};
