import React, { FC } from 'react';

import { ContractType, RentalContractType, useTrRentalContractType } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Paragraph3 } from '@hofy/ui';

import { useAdminI18n } from '../../../i18n/useAdminI18n';

interface ContractTypeLabelProps {
    contract: {
        type: ContractType;
        rentalContract: {
            rentalType: RentalContractType;
            rentalLength: number;
        } | null;
    };
}

export const ContractTypeLabel: FC<ContractTypeLabelProps> = ({ contract }) => {
    const { trPlural } = useAdminI18n();
    const trContractType = useTrRentalContractType();
    switch (contract.type) {
        case ContractType.Management:
            return <Paragraph3>Management</Paragraph3>;
        case ContractType.Purchase:
            return <Paragraph3>Purchase</Paragraph3>;
        case ContractType.Rental:
            return (
                <Box>
                    <Paragraph3>{trContractType(contract.rentalContract?.rentalType!)}</Paragraph3>
                    <Paragraph3 color={Color.Neutral300}>
                        {trPlural('month', contract.rentalContract?.rentalLength)}
                    </Paragraph3>
                </Box>
            );
    }
};
