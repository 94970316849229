import { Currency, DateString, Percent, Price, UUID, zeroPercent, zeroPrice } from '@hofy/global';
import {
    isGreaterThan,
    isLessThan,
    isPricePositive,
    isRequired,
    isValidPercent,
    useForm,
    validateArrayField,
    validator,
} from '@hofy/ui';

import { defaultPurchaseOrderCurrency } from './usePurchaseOrderHeaderForm';

export interface PurchaseOrderFormItemData {
    variantId: UUID | null;
    name: string | null;
    supplierCode: string | null;
    quantity: number;
    unitNetPrice: Price;
    taxRate: Percent;
    warranty: number | null;
    estimatedReceiveOn: DateString | null;
    notes: string | null;
}

export interface PurchaseOrderItemsFormData {
    totalHandlingCost: Price;
    items: PurchaseOrderFormItemData[];
}

interface ValidatedPurchaseOrderItemData extends PurchaseOrderFormItemData {
    variantId: UUID;
    name: string;
    notes: string;
}

export interface ValidatedPurchaseOrderItemsData extends PurchaseOrderItemsFormData {
    items: ValidatedPurchaseOrderItemData[];
}

export const emptyPurchaseOrderItemData = (currency: Currency): PurchaseOrderFormItemData => {
    return {
        variantId: null,
        name: null,
        supplierCode: null,
        quantity: 1,
        unitNetPrice: zeroPrice(currency),
        taxRate: zeroPercent,
        warranty: null,
        estimatedReceiveOn: null,
        notes: null,
    };
};

export const emptyPurchaseOrderItemsFormData: PurchaseOrderItemsFormData = {
    totalHandlingCost: zeroPrice(defaultPurchaseOrderCurrency),
    items: [emptyPurchaseOrderItemData(defaultPurchaseOrderCurrency)],
};

export const usePurchaseOrderItemsForm = (initialState: PurchaseOrderItemsFormData) => {
    return useForm<PurchaseOrderItemsFormData>({
        initial: initialState,
        validate: validator<PurchaseOrderItemsFormData>({
            totalHandlingCost: isRequired('Total handling cost is required'),
            items: validateArrayField<PurchaseOrderItemsFormData, 'items'>({
                selfRules: isRequired('At least one item is required'),
                fieldsValidator: validator<PurchaseOrderFormItemData>({
                    variantId: isRequired('Variant is required'),
                    quantity: isGreaterThan(0, 'Quantity must be greater than 0'),
                    unitNetPrice: isPricePositive('Unit price must be greater than 0'),
                    taxRate: isValidPercent('Tax rate is not valid'),
                    warranty: isLessThan(10, 'Warranty must be less than 10 years'),
                }),
            }),
        }),
        initialDeps: [initialState],
    });
};
