import React, { FC } from 'react';

import { ColorDye } from '@hofy/global';
import { Badge, Box } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

type DeviceResetResultLabel = 'Yes' | 'No' | 'Unknown';

const deviceResetResultLabelColors: Record<DeviceResetResultLabel, ColorDye> = {
    Yes: 'green',
    No: 'red',
    Unknown: 'violet',
};

interface DeviceResetResultProps {
    isDeviceResetConfirmed: boolean | null;
}

export const DeviceResetResult: FC<DeviceResetResultProps> = ({ isDeviceResetConfirmed }) => {
    const label = getDeviceResetResultLabel(isDeviceResetConfirmed);

    return (
        <SurveyResultsSectionCardChip
            title='Device reset confirmed'
            status={SurveyResultsSectionCardChipStatus.Neutral}
        >
            <Box row>
                <Badge label={label} color={deviceResetResultLabelColors[label]} />
            </Box>
        </SurveyResultsSectionCardChip>
    );
};

const getDeviceResetResultLabel = (isDeviceResetConfirmed: boolean | null): DeviceResetResultLabel => {
    if (isDeviceResetConfirmed === null) {
        return 'Unknown';
    }
    return isDeviceResetConfirmed ? 'Yes' : 'No';
};
