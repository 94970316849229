import { Permission, useTrRole } from '@hofy/api-shared';
import { BaseI18n, useBaseI18n } from '@hofy/i18n';
import { usePermission } from '@hofy/permission';

export const usePermissionMessage = (i18nKey?: BaseI18n) => {
    const { roleRequiredForPermission } = usePermission();
    const { trStyled, tr } = useBaseI18n();
    const trRole = useTrRole();

    return (v: Permission | Permission[]) => {
        const requiredRoles = roleRequiredForPermission(v);
        if (requiredRoles.length === 0) {
            return tr('ui-domain.permissions.no-permissions');
        }
        return trStyled(
            i18nKey || 'ui-domain.permissions.no-permissions-role',
            { fontWeight: 'bold' },
            {
                role: requiredRoles.map(v => `*${trRole(v)}*`).join(' or '),
            },
        );
    };
};
