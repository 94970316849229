import { ColorDye, getEnumValues, VisualType } from '@hofy/global';

export enum InspectionDeviceCheckStatus {
    Pending = 'pending',
    InProgress = 'in_progress',
    Escalated = 'escalated',
    Completed = 'completed',
    Verified = 'verified',
    Unsuccessful = 'unsuccessful',
    Cancelled = 'cancelled',
}

export const allInspectionDeviceCheckStatuses =
    getEnumValues<InspectionDeviceCheckStatus>(InspectionDeviceCheckStatus);

const inspectionDeviceCheckStatusColorDyes: Record<InspectionDeviceCheckStatus, ColorDye> = {
    [InspectionDeviceCheckStatus.Pending]: 'magenta',
    [InspectionDeviceCheckStatus.InProgress]: 'orange',
    [InspectionDeviceCheckStatus.Escalated]: 'red',
    [InspectionDeviceCheckStatus.Completed]: 'teal',
    [InspectionDeviceCheckStatus.Verified]: 'green',
    [InspectionDeviceCheckStatus.Unsuccessful]: 'red',
    [InspectionDeviceCheckStatus.Cancelled]: 'red',
};

export const getInspectionDeviceCheckStatusColorDye = (status: InspectionDeviceCheckStatus): ColorDye => {
    return inspectionDeviceCheckStatusColorDyes[status];
};

const inspectionDeviceCheckStatusVisualTypes: Record<InspectionDeviceCheckStatus, VisualType> = {
    [InspectionDeviceCheckStatus.Pending]: 'informative',
    [InspectionDeviceCheckStatus.InProgress]: 'informative',
    [InspectionDeviceCheckStatus.Escalated]: 'informative',
    [InspectionDeviceCheckStatus.Completed]: 'informative',
    [InspectionDeviceCheckStatus.Verified]: 'positive',
    [InspectionDeviceCheckStatus.Unsuccessful]: 'negative',
    [InspectionDeviceCheckStatus.Cancelled]: 'negative',
};

export const getInspectionDeviceCheckStatusVisualType = (status: InspectionDeviceCheckStatus): VisualType => {
    return inspectionDeviceCheckStatusVisualTypes[status];
};
