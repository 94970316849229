import React, { FC } from 'react';

import { Badge, SvgIcon, Tooltip } from '@hofy/ui';

export const RefurbishedBadge: FC = () => {
    return (
        <Tooltip body='This assignment should be fulfilled with used stock' interactive asChild>
            <Badge icon={SvgIcon.Recycle} label='Refurbished' color='purple' />
        </Tooltip>
    );
};
