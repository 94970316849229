import React, { FC } from 'react';

import { HofySubsidiaryRef, Ownership, OwnershipDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Box, Paragraph3 } from '@hofy/ui';

interface SubsidiaryCellProps {
    entry: {
        hofySubsidiary: HofySubsidiaryRef | null;
        billingEntity: {
            id: UUID;
            name?: string | null;
        } | null;
        ownership: OwnershipDto;
    };
}

export const OwnershipCell: FC<SubsidiaryCellProps> = ({
    entry: { ownership, billingEntity, hofySubsidiary },
}) => {
    const hofySubsidiaryNode = <Paragraph3>{hofySubsidiary?.name || '--'}</Paragraph3>;
    const billingEntityNode = <Paragraph3>{billingEntity?.name || '--'}</Paragraph3>;

    switch (ownership.type) {
        case Ownership.Hofy:
            return hofySubsidiaryNode;
        case Ownership.Organization:
            if (billingEntity && hofySubsidiary) {
                return (
                    <Box column gap={2}>
                        {billingEntityNode}
                        {hofySubsidiaryNode}
                    </Box>
                );
            }
            return billingEntityNode;
        default:
            return <Paragraph3>--</Paragraph3>;
    }
};
