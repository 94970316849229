import React, { FC } from 'react';

import { useContractDetailsQuery } from '@hofy/api-admin';
import { ContractType } from '@hofy/api-shared';
import { ComponentLoader } from '@hofy/common';
import { UUID } from '@hofy/global';

import { UpdatePurchaseContractSlideout } from './UpdatePurchaseContractSlideout';
import { UpdateRentalContractSlideout } from './UpdateRentalContractSlideout';

interface UpdateContractSlideoutOverlayProps {
    contractId: UUID;
    onClose(): void;
}

export const UpdateContractSlideoutOverlay: FC<UpdateContractSlideoutOverlayProps> = ({
    onClose,
    contractId,
}) => {
    const { contract, isLoading } = useContractDetailsQuery(contractId);

    if (isLoading || !contract) {
        return <ComponentLoader />;
    }

    switch (contract.type) {
        case ContractType.Purchase:
            return <UpdatePurchaseContractSlideout contract={contract} onClose={onClose} />;
        case ContractType.Rental:
            return <UpdateRentalContractSlideout contract={contract} onClose={onClose} />;
    }
    return null;
};
