import React, { FC, ReactNode, useId } from 'react';

import { useIsDisabled } from '../../../contexts';
import { ToggleLabel } from '../shared/ToggleLabel';
import { Radio, RadioProps } from './Radio';

export interface LabeledRadioProps extends RadioProps {
    label: ReactNode;
    errorMessage?: string;
    helperText?: string;
}

export const LabeledRadio: FC<LabeledRadioProps> = ({
    label,
    errorMessage,
    helperText,

    checked,
    onChange,
    disabled: radioDisabled,
    testKey,

    ...rest
}) => {
    const id = useId();
    const disabled = useIsDisabled(radioDisabled);

    return (
        <ToggleLabel
            label={label}
            errorMessage={errorMessage}
            helperText={helperText}
            disabled={disabled}
            onClick={() => onChange?.(!checked)}
            id={id}
            {...rest}
            data-test-key='labeled-label'
        >
            <Radio
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                isError={!!errorMessage}
                id={id}
            />
        </ToggleLabel>
    );
};
