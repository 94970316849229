import React, { FC, useEffect } from 'react';

import {
    allStoreAndReuseLocations,
    RentalStoreAndReuseLocation,
    storeAndReuseLocationLabels,
} from '@hofy/api-shared';
import { Price } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Box, LabeledPriceInput, LabeledSelect } from '@hofy/ui';

interface StoreAndReuseLocationProps {
    collectToHub: boolean;
    collectToLocalPartner: boolean;

    location: RentalStoreAndReuseLocation | null;
    activationFee: Price;

    onLocationChange(location: RentalStoreAndReuseLocation): void;
    onActivationFeeChange(activationFee: Price): void;

    errorMessage?: string;
}

export const StoreAndReuseLocation: FC<StoreAndReuseLocationProps> = ({
    collectToHub,
    collectToLocalPartner,
    location,
    activationFee,
    errorMessage,
    onLocationChange,
    onActivationFeeChange,
}) => {
    useEffect(() => {
        if (collectToLocalPartner && collectToHub) {
            // Present choice
            return;
        }

        if (collectToHub) {
            onLocationChange(RentalStoreAndReuseLocation.Zone1);
        } else if (collectToLocalPartner) {
            onLocationChange(RentalStoreAndReuseLocation.Local);
        }
    }, [collectToLocalPartner, collectToHub]);

    return (
        <Box rounded marginVertical={10} padding={10} gap={10} column bg={Color.BackgroundSubtleNeutral}>
            <LabeledSelect
                label='Store and reuse location'
                toText={value => storeAndReuseLocationLabels[value]}
                value={location}
                onChange={onLocationChange}
                isRequired
                nullable
                errorMessage={errorMessage}
                options={allStoreAndReuseLocations}
            />
            <LabeledPriceInput
                label='Store and reuse fee'
                value={activationFee}
                onChange={activationFee => onActivationFeeChange(activationFee)}
            />
        </Box>
    );
};
