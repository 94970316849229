import React, { FC } from 'react';

import { Badge, Icon, SvgIcon } from '@hofy/ui';

interface ShipmentOrganizationWarehouseCellProps {
    locationType: 'origin' | 'destination';
}

export const ShipmentOrganizationWarehouseCell: FC<ShipmentOrganizationWarehouseCellProps> = ({
    locationType,
}) => {
    const prefix = locationType === 'origin' ? 'From' : 'To';
    return (
        <Badge
            leftSlot={<Icon svg={SvgIcon.Warehouse} />}
            label={`${prefix} storage location`}
            color='purple'
        />
    );
};
