import { ShipmentStatus } from '@hofy/api-shared';
import { DateString, DateTimeString } from '@hofy/global';

import { AssignmentShipmentDto } from '../../../assignment/service/types/AssignmentShipmentDto';
import { ShipmentDetailsDto } from './ShipmentDetailsDto';

export const getShipmentStatusDate = <T extends ShipmentDetailsDto | AssignmentShipmentDto>(
    shipment: T,
    status: ShipmentStatus = shipment.status,
): DateTimeString | DateString | null => {
    switch (status) {
        case ShipmentStatus.Created:
            return shipment.createdAt;
        case ShipmentStatus.Booked:
            return shipment.bookedOn;
        case ShipmentStatus.WithCourier:
            return shipment.withCourierOn;
        case ShipmentStatus.Completed:
            return shipment.completedOn;
        case ShipmentStatus.Canceled:
            return shipment.canceledOn;
        case ShipmentStatus.Backorder:
            return shipment.backorderOn;
        default:
            return null;
    }
};
