import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

interface IndicatorProps {
    color: Color;
    children?: ReactNode;
}

export const Indicator: FC<IndicatorProps> = ({ color, children }) => {
    return (
        <Box
            column
            alignItems='center'
            justify='center'
            border
            borderColor={color}
            rounded={8}
            height={32}
            width={32}
            paddingHorizontal={2}
            paddingVertical={12}
        >
            {children}
        </Box>
    );
};
