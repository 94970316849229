import React, { FC, memo } from 'react';

import { ItemDto } from '@hofy/api-admin';
import {
    formatVariant,
    goodConditionGrades,
    isDefectiveStatus,
    ItemLocation,
    ItemStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    BooleanBadge,
    Box,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    PublicIdColumn,
    SvgIllustration,
} from '@hofy/ui';

import { PairsTableTooltipOverlay } from '../../components/design/tooltips/PairsTableTooltipOverlay';
import { ItemGradeChip } from '../../components/domain/items/ItemGradeChip';
import { ItemStatusChip } from '../../components/domain/items/ItemStatusChip';
import { ProductItem } from '../../components/domain/products/ProductItem';
import { ItemContractCell } from './components/ItemContractCell';
import { ItemLocationCell } from './components/ItemLocationCell';
import { OwnershipCell } from './components/OwnershipCell';

interface ItemsTableProps {
    items: ItemDto[];
    infinityScroll: InfiniteScrollConfig;
    onOpenItem(id: UUID): void;
}

const ItemsTableComponent: FC<ItemsTableProps> = ({ items, infinityScroll, onOpenItem }) => {
    const isUsableInStoreAndReuse = (item: ItemDto) => {
        return (
            item.usableInStoreAndReuse &&
            item.contractDetails === null &&
            item.location.location === ItemLocation.AtWarehouse &&
            item.status === ItemStatus.Active &&
            goodConditionGrades.includes(item.grade)
        );
    };

    const productTooltip = (i: ItemDto) => {
        return [
            ['SKU', i.variant.sku],
            ['MPC', i.variant.manufacturerPartCode],
        ].filter((item): item is [string, string] => !!item[1]);
    };

    return (
        <InfinityScrollTable
            data={items}
            toKey={item => item.id}
            onRowClick={item => onOpenItem(item.id)}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.AssetsSearch}
                    title='No items'
                    message='No items for selected criteria'
                />
            }
            minWidth={1300}
            columns={[
                PublicIdColumn<ItemDto>(),
                {
                    id: 'product',
                    header: 'Product',
                    width: 250,
                    flexGrow: 3,
                    renderer: item => (
                        <ProductItem
                            imageTooltip={
                                <PairsTableTooltipOverlay items={productTooltip(item)} marginLeft={12} />
                            }
                            usableAsLoaner={item.usableAsLoaner}
                            needRepairs={isDefectiveStatus(item.status)}
                            image={item.variant.image}
                            label={item.product.name}
                            subLabel={formatVariant(item.variant)}
                        />
                    ),
                },
                {
                    id: 'grade',
                    header: 'Grade',
                    flexGrow: 1,
                    width: 100,
                    renderer: item => <ItemGradeChip grade={item.grade} />,
                },
                {
                    id: 'grade',
                    header: 'Status',
                    flexGrow: 1,
                    width: 150,
                    renderer: item => <ItemStatusChip status={item.status} />,
                },
                {
                    id: 'codes',
                    header: 'Codes',
                    width: 140,
                    flexGrow: 0,
                    renderer: entry => {
                        if (!entry.itemCodes.length) {
                            return '--';
                        }
                        return (
                            <Box>
                                {entry.itemCodes.map(c => (
                                    <Paragraph3 color={Color.ContentPrimary} key={c.code}>
                                        {c.code}
                                    </Paragraph3>
                                ))}
                            </Box>
                        );
                    },
                },
                {
                    id: 'location',
                    header: 'Location',
                    width: 150,
                    flexGrow: 1,
                    renderer: item => <ItemLocationCell location={item.location} />,
                },
                {
                    id: 'hofySubsidiary',
                    header: 'Ownership',
                    width: 130,
                    flexGrow: 0,
                    renderer: item => <OwnershipCell entry={item} />,
                },
                {
                    id: 'redistributable',
                    header: 'Redistr.',
                    width: 80,
                    flexGrow: 0,
                    renderer: item => (
                        <BooleanBadge value={!!item.redistributableInContractId} offColor='grey' />
                    ),
                },
                {
                    id: 'storeAndReuse',
                    header: 'Reusable',
                    width: 80,
                    flexGrow: 0,
                    renderer: item => (
                        <BooleanBadge
                            value={isUsableInStoreAndReuse(item)}
                            onColor='purple'
                            offColor='grey'
                        />
                    ),
                },
                {
                    id: 'status',
                    header: 'Contract',
                    width: 160,
                    flexGrow: 0,
                    renderer: item =>
                        item.contractDetails && <ItemContractCell contractDetails={item.contractDetails} />,
                },
            ]}
        />
    );
};

export const ItemsTable = memo(ItemsTableComponent);
