import React, { FC, ReactNode } from 'react';
import { Route, RouteChildrenProps, useHistory } from 'react-router-dom';

import { Permission } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { EnumRoute, getEnumParam, getStringParam } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { ProductDetailsTab } from '../../store/products/types/ProductDetailsTab';
import { NewProductOverlay } from './newProductOverlay/NewProductOverlay';
import { ProductsPage } from './ProductsPage';
import { UpdateProductOverlay } from './updateProductOverlay/UpdateProductOverlay';

export const ProductsRouter: FC = () => {
    const history = useHistory();
    const { hasPermission } = usePermission();
    const handleProductOpen = (id: UUID) => {
        history.push(`${AdminNavLink.Products}/${id}/${ProductDetailsTab.Details}`);
    };

    const handleChangeProductTab = (id: UUID | 'new', tab: ProductDetailsTab) => {
        history.replace(`${AdminNavLink.Products}/${id}/${tab}`);
    };

    const handleAddProduct = () => {
        history.push(`${AdminNavLink.Products}/new/${ProductDetailsTab.Details}`);
    };

    return (
        <Route path={AdminNavLink.Products}>
            <Route path={AdminNavLink.Products} exact>
                <ProductsPage onOpenProduct={handleProductOpen} onAddProduct={handleAddProduct} />
            </Route>
            <ProductDetailsRoute path={`${AdminNavLink.Products}/:productId/:productTab`} exact>
                {({ productId, productTab }) => (
                    <UpdateProductOverlay
                        productId={productId}
                        tab={productTab}
                        onChangeTab={tab => handleChangeProductTab(productId, tab)}
                    />
                )}
            </ProductDetailsRoute>
            {hasPermission(Permission.AdminProductsCreate) && (
                <NewProductRoute path={`${AdminNavLink.Products}/new/:productTab`} exact>
                    {({ productTab }) => (
                        <NewProductOverlay
                            tab={productTab}
                            onChangeTab={tab => handleChangeProductTab('new', tab)}
                        />
                    )}
                </NewProductRoute>
            )}
        </Route>
    );
};

const NewProductRoute = EnumRoute<ProductDetailsTab>('productTab', ProductDetailsTab, Route);

interface ProductDetailsRouteProps {
    path?: string;
    exact?: boolean;
    children(v: { productTab: ProductDetailsTab; productId: UUID }): ReactNode;
}

const ProductDetailsRoute: FC<ProductDetailsRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const productId = getStringParam(p.match?.params || {}, 'productId') as UUID;
        const productTab = getEnumParam<ProductDetailsTab>(
            p.match?.params || {},
            'productTab',
            ProductDetailsTab,
        );

        if (productId && productTab) {
            return children({
                productId,
                productTab,
            });
        }
        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};
