import React, { FC } from 'react';

import { PurchaseOrderDetailsDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { Button, FormGridRow, LabeledText, SvgIcon } from '@hofy/ui';

import { useDownloadPurchaseOrder } from '../../../../../store/purchaseOrders/useDownloadPurchaseOrder';

interface PurchaseOrderFilesProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderFiles: FC<PurchaseOrderFilesProps> = ({ purchaseOrder }) => {
    const { isDownloadPurchaseOrderLoading, download } = useDownloadPurchaseOrder();

    const renderFiles = purchaseOrder.purchaseOrderFiles.map(f => {
        return (
            <FormGridRow key={f.id} columns={4}>
                <LabeledText content={formatDateTime(f.createdAt)} label='Date' />
                <LabeledText content={f.filename} label='Filename' />
                <LabeledText
                    label=''
                    content={
                        <Button
                            type='secondary'
                            leftIcon={SvgIcon.Download}
                            label='Download'
                            onClick={() => !isDownloadPurchaseOrderLoading && download(f.id)}
                        />
                    }
                />
            </FormGridRow>
        );
    });

    return <>{renderFiles}</>;
};
