import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { AUTH_ERROR_PARAM } from '@hofy/api-auth';

export const useRedirectLocation = () => {
    const { pathname, search } = useLocation();
    const url = useMemo(() => {
        const params = new URLSearchParams(search);
        params.delete(AUTH_ERROR_PARAM);
        const output = params.toString();
        return output ? `?${output}` : '';
    }, [pathname, search]);

    return {
        pathname: pathname,
        redirectLocation: `${pathname}${url}`,
    };
};
