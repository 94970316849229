import {
    Country,
    getEnumValues,
    isPassportAndDateOfBirthNeeded,
    isStateRequired,
    isTaxIdCountry,
} from '@hofy/global';

import { AddressPayload } from './AddressDto';

export enum AddressType {
    Delivery = 'delivery',
    Partial = 'partial',
    Collection = 'collection',
    Billing = 'billing',
    Entity = 'entity',
    Supplier = 'supplier',
    PII = 'pii',
}

export const allAddressTypes = getEnumValues<AddressType>(AddressType);

export type AddressPayloadField = keyof AddressPayload;

interface AddressConfig {
    required: AddressPayloadField[];
    visible: AddressPayloadField[];
}

export const getAddressConfig = (type: AddressType, country?: Country): AddressConfig => {
    const state: AddressPayloadField[] = isStateRequired(country) ? ['state'] : [];
    const passport: AddressPayloadField[] = isPassportAndDateOfBirthNeeded(country)
        ? ['passportNumber', 'dateOfBirth']
        : [];
    const taxIdNumber: AddressPayloadField[] = isTaxIdCountry(country) ? ['taxIdNumber'] : [];

    const additionalFields: AddressPayloadField[] = [
        'deliveryInstructions',
        'phoneNumber',
        ...passport,
        ...taxIdNumber,
    ];

    const simpleModeFields: AddressPayloadField[] = [
        'country',
        'line1',
        'line2',
        'state',
        'city',
        'postCode',
    ];
    switch (type) {
        case AddressType.Delivery:
            return {
                required: [
                    'country',
                    'line1',
                    'city',
                    'postCode',
                    'phoneNumber',
                    ...state,
                    ...passport,
                    ...taxIdNumber,
                ],
                visible: [...simpleModeFields, ...additionalFields],
            };
        case AddressType.Partial:
            return {
                required: ['country'],
                visible: [...simpleModeFields, ...additionalFields],
            };
        case AddressType.Billing:
            return {
                required: ['country', 'line1', 'city', 'postCode', ...state],
                visible: simpleModeFields,
            };
        case AddressType.Entity:
            return {
                required: ['country', 'line1', 'city', 'postCode', 'phoneNumber', ...state],
                visible: [...simpleModeFields, 'phoneNumber'],
            };
        case AddressType.Collection:
        case AddressType.Supplier:
            return {
                required: ['country', 'line1', 'city', 'postCode', ...state],
                visible: [...simpleModeFields, 'phoneNumber', 'deliveryInstructions'],
            };
        case AddressType.PII:
            return {
                required: ['country'],
                visible: ['country'],
            };
    }
};
