import { filter, includes, map, xor } from 'lodash';
import React, { FC } from 'react';

import { allPayoutProviders, PayoutProvider, useHofySubsidiaryRefs, usePayouts } from '@hofy/api-admin';
import { HofySubsidiaryRef } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDateRange } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import {
    Box,
    FilterChip,
    FilterChipList,
    LabeledDateRangeInput,
    PageHeader,
    SearchInput,
    SvgIcon,
} from '@hofy/ui';

import { BlockFilter } from '../../../components/design/blockFilters/BlockFilter';
import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../../components/design/blockFilters/ListMultiBlockFilter';
import { usePayoutsFilters } from '../../../store/payouts/usePayoutsFilters';
import { useTrPayoutProvider } from '../../../store/payouts/useTrPayoutProvider';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { PayoutsPageMenu } from './PayoutsPageMenu';
import { PayoutsTable } from './PayoutsTable';

export const PayoutsPage: FC = () => {
    const { search, setSearch, filters, filterCount, setDateRange, setHofySubsidiaryIds, setProviders } =
        usePayoutsFilters();

    const { payouts, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = usePayouts(filters);

    const payoutsScrollDetails = useStructMemo({
        hasMore: hasNextPage,
        isLoading: isLoading,
        isLoadingMore: isFetchingNextPage,
        loadMore: fetchNextPage,
    });
    const { hofySubsidiaries } = useHofySubsidiaryRefs();
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    const trPayoutProvider = useTrPayoutProvider();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Payouts'
                rightSlot={
                    <>
                        <SearchInput value={search} onChange={setSearch} placeholder='Payout ID' autoFocus />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <PayoutsPageMenu />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.Payouts} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <ListMultiBlockFilter
                    title='Subsidiary'
                    selected={filter(hofySubsidiaries, s => includes(filters.hofySubsidiaryIds, s.id))}
                    items={hofySubsidiaries}
                    onChange={filter => setHofySubsidiaryIds(map<HofySubsidiaryRef, UUID>(filter, s => s.id))}
                    renderItem={s => s?.name || ''}
                />
                <EnumMultiBlockFilter<PayoutProvider>
                    title='Provider'
                    selected={filters.providers}
                    onChange={setProviders}
                    items={allPayoutProviders}
                    renderItem={provider => trPayoutProvider(provider)}
                />
                <BlockFilter title='Date' icon={SvgIcon.Calendar}>
                    <LabeledDateRangeInput
                        label='Date'
                        value={filters.dateRange}
                        onChange={setDateRange}
                        nullable
                        marginLeft={12}
                        marginTop={8}
                    />
                </BlockFilter>
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={value => value}
                    selected={filters.hofySubsidiaryIds}
                    toLabel={id => hofySubsidiaries.find(s => s.id === id)?.name || ''}
                    onClear={value => setHofySubsidiaryIds(xor(filters.hofySubsidiaryIds, [value]))}
                    color='blue'
                />
                <FilterChipList
                    toKey={value => value}
                    selected={filters.providers}
                    toLabel={trPayoutProvider}
                    onClear={value => setProviders(xor(filters.providers, [value]))}
                    color='purple'
                />
                {filters.dateRange && (
                    <FilterChip
                        label={formatDateRange(filters.dateRange)}
                        onClear={() => setDateRange(null)}
                        color='teal'
                    />
                )}
            </BlockFilterChipContainer>
            <PayoutsTable payouts={payouts} infinityScroll={payoutsScrollDetails} />
        </Box>
    );
};
