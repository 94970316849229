import { noop } from 'lodash';
import { useMemo } from 'react';

import { useShipmentQuery } from '@hofy/api-admin';
import { isShipmentFromSupplierToUser } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { queryStringParser, useQueryParams } from '@hofy/router';
import { useMultipartForm } from '@hofy/ui';

import { useCreatePurchaseOrder } from './useCreatePurchaseOrder';
import { useGeneratePurchaseOrderReference } from './useGeneratePurchaseOrderReference';
import {
    defaultPurchaseOrderCurrency,
    emptyPurchaseOrderHeaderFormData,
    usePurchaseOrderHeaderForm,
    ValidatedPurchaseOrderHeaderData,
} from './usePurchaseOrderHeaderForm';
import {
    emptyPurchaseOrderItemData,
    emptyPurchaseOrderItemsFormData,
    usePurchaseOrderItemsForm,
    ValidatedPurchaseOrderItemsData,
} from './usePurchaseOrderItemsForm';

export const useCreateMultipartPurchaseOrderForm = (onSuccess = noop) => {
    const { createPurchaseOrder, isLoading, isError } = useCreatePurchaseOrder(onSuccess);

    const queryParams = useQueryParams<{ shipmentId: UUID | null }>(
        {
            shipmentId: queryStringParser<UUID>(),
        },
        { shipmentId: null },
    );
    const { data: shipment, isLoading: isLoadingShipment } = useShipmentQuery(queryParams.query.shipmentId);
    const { purchaseOrderReference, isLoading: isLoadingReference } = useGeneratePurchaseOrderReference();

    const { headerState, itemState } = useMemo(() => {
        if (!shipment || !isShipmentFromSupplierToUser(shipment)) {
            return {
                headerState: {
                    ...emptyPurchaseOrderHeaderFormData,
                    purchaseOrderReference: purchaseOrderReference || null,
                },
                itemState: emptyPurchaseOrderItemsFormData,
            };
        }

        return {
            headerState: {
                ...emptyPurchaseOrderHeaderFormData,
                purchaseOrderReference: purchaseOrderReference || null,
                shipmentId: queryParams.query.shipmentId,
                isDropship: true,
                toUserId: shipment.toUser.id,
            },
            itemState: {
                ...emptyPurchaseOrderItemsFormData,
                items: shipment.assignments.map(assignment => {
                    return {
                        ...emptyPurchaseOrderItemData(defaultPurchaseOrderCurrency),
                        variantId: assignment.variant.id,
                        supplierCode: assignment.variant.manufacturerPartCode,
                        quantity: 1,
                        name: assignment.product.name,
                        estimatedReceiveOn: shipment.estimatedAvailability?.from ?? null,
                    };
                }),
            },
        };
    }, [shipment, purchaseOrderReference]);

    const header = usePurchaseOrderHeaderForm(headerState);
    const items = usePurchaseOrderItemsForm(itemState);

    const form = useMultipartForm(
        {
            header,
            items,
            review: true,
        },
        {
            onSubmit: values => {
                const validatedHeaderData = values.header as ValidatedPurchaseOrderHeaderData;
                const validatedItemsData = values.items as ValidatedPurchaseOrderItemsData;

                createPurchaseOrder({
                    ...validatedHeaderData,
                    ...validatedItemsData,
                    isDraft: false,
                });
            },
            allowSkipSteps: true,
        },
    );

    return {
        form,
        isLoading: isLoading,
        isPreparing: isLoadingReference || isLoadingShipment,
        isError,
    };
};

export type UsePurchaseOrderForm = ReturnType<typeof useCreateMultipartPurchaseOrderForm>;
