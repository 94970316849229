import React, { FC } from 'react';

import { OrganizationDetailsDto, SubscriptionDto } from '@hofy/api-admin';
import { orgBasedPlatformTiers, Permission } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { AsyncButton, Button, FormContainer, SvgIcon } from '@hofy/ui';

import { useUpdateSubscription } from '../../../store/subscription/useUpdateSubscription';
import { SubscriptionFormFields } from '../components/SubscriptionFormFields';

interface UpdateSubscriptionSlideoutPorps {
    organization: OrganizationDetailsDto;
    subscription: SubscriptionDto;
    onClose(): void;
}

export const UpdateSubscriptionSlideout: FC<UpdateSubscriptionSlideoutPorps> = ({
    organization,
    subscription,
    onClose,
}) => {
    const { form, isLoading, isError } = useUpdateSubscription(organization, subscription, onClose);
    const { hasPermission } = usePermission();
    const readOnly = !hasPermission(Permission.AdminOrganizationUpdateFinancialSettings);
    const organizationBased = orgBasedPlatformTiers.includes(organization.platformTierConfig.platformTier);
    return (
        <Slideout width={600} onClose={onClose} slideoutId='edit-subscription'>
            <SlideoutHeader title='Edit subscription' />
            <SlideoutContent>
                <FormContainer marginVertical={40}>
                    <SubscriptionFormFields
                        form={form}
                        organizationBased={organizationBased}
                        readOnly={readOnly}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    leftIcon={SvgIcon.Cross}
                    label='Cancel'
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                />
                <AsyncButton
                    label='Save'
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                    disabled={readOnly}
                    disableCheck
                />
            </SlideoutFooter>
        </Slideout>
    );
};
