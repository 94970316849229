import React, { ElementRef, forwardRef } from 'react';

import { Labeled } from '../../labeled';
import { Input, InputNullableStringProps, InputOnlyStringProps } from './Input';

interface BaseLabeledInputProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledInputOnlyStringProps = Omit<InputOnlyStringProps, 'isError'> & BaseLabeledInputProps;
export type LabeledInputNullableStringProps = Omit<InputNullableStringProps, 'isError'> &
    BaseLabeledInputProps;

export type LabeledInputProps = LabeledInputOnlyStringProps | LabeledInputNullableStringProps;

export const LabeledInput = forwardRef<ElementRef<'input'>, LabeledInputProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,

            value,
            onChange,
            onBlur,
            onFocus,
            refine,
            type,
            id,
            trim,
            nullable,
            disabled,
            clearable,
            testKey,
            autoFocus,
            placeholder,
            leftSlot,
            leftSlotInteractive,
            rightSlot,
            rightSlotInteractive,

            ...rest
        },
        ref,
    ) => {
        return (
            <Labeled
                as='label'
                label={label}
                isRequired={isRequired}
                content={
                    <Input
                        testKey={testKey}
                        ref={ref}
                        value={value!}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        refine={refine}
                        id={id}
                        type={type}
                        nullable={nullable}
                        placeholder={placeholder}
                        disabled={disabled}
                        clearable={clearable}
                        trim={trim}
                        autoFocus={autoFocus}
                        leftSlot={leftSlot}
                        leftSlotInteractive={leftSlotInteractive}
                        rightSlot={rightSlot}
                        rightSlotInteractive={rightSlotInteractive}
                        isError={!!errorMessage}
                    />
                }
                errorMessage={errorMessage}
                helperText={helperText}
                {...rest}
            />
        );
    },
);
