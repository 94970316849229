import { forcedNetsuiteSyncQuery } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminPaymentsFilters } from './types/AdminPaymentsFilters';
import { CollectPaymentsPayload } from './types/CollectPaymentsPayload';
import { PaymentDataDto } from './types/PaymentDataDto';
import { PaymentDto } from './types/PaymentDto';

class PaymentService {
    public listPayments = (
        filters: AdminPaymentsFilters,
        page: PageRequest,
    ): Promise<PageResponse<PaymentDto>> => {
        return restClient.getJson(
            stringifyUrl({
                url: '/api/admin/payments',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public syncAllToNetsuite = (): Promise<void> => {
        return restClient
            .post(
                stringifyUrl({
                    url: '/api/admin/payments/sync-to-netsuite',
                    query: {
                        ...forcedNetsuiteSyncQuery,
                    },
                }),
            )
            .then();
    };

    public listForInvoice = (invoiceId: UUID): Promise<PaymentDataDto[]> => {
        return restClient.getJson(`/api/admin/payments/invoices/${invoiceId}`);
    };

    public collectPayments = async (billingEntityId: UUID, payload: CollectPaymentsPayload) => {
        return restClient.post(`/api/admin/payments/billing-entity/${billingEntityId}/collect`, payload);
    };

    public retryMandateVerification = async (billingEntityId: UUID) => {
        return restClient.post(
            `/api/admin/payments/billing-entity/${billingEntityId}/retry-mandate-verification`,
        );
    };
}

export const paymentService = new PaymentService();
