import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractsCacheKey, contractService, invoiceEntriesQueryKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateContractBillingEntity = (contractId: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (billingEntityId: UUID) =>
            contractService.updateBillingEntity(contractId, billingEntityId),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Contract billing entity updated',
            });
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey, contractId] });
            onSuccess();
        },
    });

    return {
        updateBillingEntity: mutation.mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
