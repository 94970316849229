import { productDropdownsI18n } from './productDropdownsI18n';
import { productFormDropdownsI18n } from './productFormDropdownsI18n';
import { productStyleAndSizeFormI18n } from './productStyleAndSizeFormI18n';
import { productStyleAndSizeSelectionAlertI18n } from './productStyleAndSizeSelectionAlertI18n';

export const productI18n = {
    'product.title.empty': 'products',
    'product.title.single': 'product',
    'product.title.plural': 'products',
    'product.by': 'By *{value}*',
    'product.style.single': '{value} style available',
    'product.style.plural': '{value} styles available',

    'product.size.label': 'Size',
    'product.style.label': 'Style',
    'product.specifications': 'Specifications',

    ...productDropdownsI18n,
    ...productFormDropdownsI18n,
    ...productStyleAndSizeFormI18n,
    ...productStyleAndSizeSelectionAlertI18n,
};

export type ProductI18nKey = keyof typeof productI18n;
