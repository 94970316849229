import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, UpdateOnHoldAssignmentPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateAssignmentOnHold = (assignmentId: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (v: UpdateOnHoldAssignmentPayload) => assignmentService.updateOnHold(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Assignment modified',
            });
        },
    });

    const updateOnHold = (onHold: boolean) => {
        mutation.mutate({ onHold });
    };

    return {
        updateOnHold,
    };
};
