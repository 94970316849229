import React, { Children, FC, ReactNode } from 'react';

import { Box, OuterBoxProps } from '../base';

export interface SummaryLayoutProps extends OuterBoxProps {
    children?: ReactNode;
}

/**
 * A layout component for displaying Summary and SummaryButton cards in a row.
 * It will render nothing if there are no children.
 */
export const SummaryLayout: FC<SummaryLayoutProps> = ({ children, ...rest }) => {
    if (Children.count(children) === 0) {
        return null;
    }

    return (
        <Box row gap={16} alignItems='stretch' {...rest}>
            {children}
        </Box>
    );
};
