import { useMutation } from '@tanstack/react-query';

import { accountingService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncNetsuiteConfig = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: accountingService.syncNetsuiteConfig,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Config synced to Netsuite',
            });
        },
    });

    return {
        syncConfig: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
