import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

import { ColorDye } from '@hofy/global';
import { Color, colorDyes } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3 } from '../base';

export interface ProgressBarProps extends OuterBoxProps {
    progress: number | boolean;
    value?: string | number;
    color?: ColorDye;
    showKnob?: boolean;
}

const valueSwapThreshold = 0.7;
const duration = 0.6;

export const ProgressBar = ({
    progress,
    value,
    color = 'violet',
    showKnob = true,
    ...boxProps
}: ProgressBarProps) => {
    const progressColor = colorDyes[color].main;
    const isPercent = typeof progress === 'number';

    const progressWidth = isPercent ? `${progress * 100}%` : progress === true ? '100%' : '0';
    const emptyWidth = isPercent ? `${Math.abs(progress * 100 - 100)}%` : 0;

    const swapValue = isPercent && progress < valueSwapThreshold;
    const trackCorrection = isPercent && progress < 0.5 ? 6 : 0;

    return (
        <Box height={28} row relative {...boxProps}>
            <Box flex={1} height={6} bg={Color.InteractionNeutralSubtleNormal} rounded>
                <Track
                    as={motion.div}
                    fullHeight
                    bg={progressColor}
                    rounded
                    initial={{ width: 0 }}
                    animate={{ width: progressWidth }}
                    transition={{ duration }}
                    paddingLeft={trackCorrection}
                />
            </Box>
            <InnerContainer>
                {showKnob && isPercent && (
                    <Head
                        color={progressColor}
                        initial={{ left: 0 }}
                        animate={{ left: progressWidth }}
                        transition={{ duration }}
                    />
                )}
                {isPercent && value !== undefined && (
                    <Value
                        as={motion.div}
                        layout
                        fullHeight
                        centered
                        color={Color.ContentPrimary}
                        bg={Color.BackgroundDefault}
                        elevation='+2'
                        paddingHorizontal={8}
                        marginHorizontal={12}
                        rounded
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 0, 1] }}
                        transition={{ duration }}
                        style={{
                            left: !swapValue ? 'auto' : progressWidth,
                            right: swapValue ? 'auto' : emptyWidth,
                        }}
                    >
                        {value}
                    </Value>
                )}
            </InnerContainer>
        </Box>
    );
};

const Track = styled(Box)`
    box-sizing: content-box;
`;

const InnerContainer = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 6px;
    right: 6px;
`;

const Head = styled(motion.div)<{ color: Color }>`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: 2px solid ${props => props.color};
    background-color: ${Color.BackgroundDefault};
    box-shadow: 0 0 0 2px ${Color.ContentInvertedTertiary};
`;

const Value = styled(Paragraph3)`
    position: absolute;
    top: 0;
`;
