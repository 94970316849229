import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { contractService } from '../service/contractService';
import { ContractUnionDto } from '../service/types/ContractDto';
import { ContractFiltersUi } from '../service/types/ContractFilters';
import { contractsCacheKey } from './cacheKey';

export const useContractsQuery = (filters: ContractFiltersUi) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [contractsCacheKey, filters],
        queryFn: param =>
            contractService.listContracts(filters, {
                page: param.pageParam,
                pageSize: 30,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const contracts: ContractUnionDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        contracts,
        contractsIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
