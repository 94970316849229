import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    ShipmentDetailsDto,
    shipmentService,
    ShipShipmentPayload,
} from '@hofy/api-admin';
import { ShipmentCourier } from '@hofy/api-shared';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

import { validateShipmentCourierForm } from './useUpdateShipment';

export const useShipShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, ShipShipmentPayload>({
        mutationFn: payload => shipmentService.shipShipment(shipment.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<ShipShipmentPayload>({
        initial: {
            withCourierOn: shipment.withCourierOn || nowISODate(),
            courier: shipment.courier || ShipmentCourier.UPS,
            trackingLink: shipment.trackingLink,
            courierReference: shipment.courierReference,
            shipmentClass: shipment.shipmentClass,
            sendNotification: true,
            deliveryNotes: shipment.deliveryNotes,
        },
        onSubmit: mutation.mutate,
        validate: formValues => ({
            withCourierOn: errorMap([isEmpty(formValues.withCourierOn), 'Courier pick up date is required']),
            ...validateShipmentCourierForm(formValues),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
