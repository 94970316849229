import React, { FC } from 'react';

import { Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { Alert } from '@hofy/ui';

import { useAddressI18n } from '../store/useAddressI18n';

interface PassportAndDateOfBirthInfoPaneProps {
    country: Country;
}

export const PassportInfoPane: FC<PassportAndDateOfBirthInfoPaneProps> = ({ country }) => {
    const { tr } = useAddressI18n();
    const trCountry = useTrCountry();

    return (
        <Alert
            type='warning'
            title={tr('passport-pane.title')}
            description={tr('passport-pane.description', {
                country: trCountry(country),
            })}
        />
    );
};
