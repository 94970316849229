import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { contractService } from '../service/contractService';
import { contractsCacheKey } from './cacheKey';

export const useContractDetailsQuery = (id: UUID | null) => {
    const { data: contract, isLoading } = useQuery({
        queryKey: [contractsCacheKey, id],
        queryFn: () => contractService.getContract(id!),
        enabled: !!id,
    });

    return {
        contract,
        isLoading,
    };
};
