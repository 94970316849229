import { allRootRegions, RootRegion, SubRegion } from '@hofy/global';

import { useBaseI18n } from '../useBaseI18n';

export const useTrRegion = () => {
    const { trEnum } = useBaseI18n();
    const trSubRegion = trEnum<SubRegion>(SubRegion, 'region');
    const trRootRegion = trEnum<RootRegion>(RootRegion, 'region');
    return (v: SubRegion | RootRegion) => {
        if (allRootRegions.includes(v as RootRegion)) {
            return trRootRegion(v as RootRegion);
        }
        return trSubRegion(v as SubRegion);
    };
};
