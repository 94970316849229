import React, { FC } from 'react';

import { ZendeskStatus, zendeskStatusColors, zendeskStatusLabel } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ZendeskStatusChipProps {
    status: ZendeskStatus | null;
}

export const ZendeskStatusChip: FC<ZendeskStatusChipProps> = ({ status }) => {
    const title = status ? zendeskStatusLabel[status] : 'Not synced';
    const color = status ? zendeskStatusColors[status] : 'grey';

    return <Badge color={color} label={title} />;
};
