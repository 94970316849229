import { Property } from 'csstype';
import { ComponentType, MouseEvent } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
    Color,
    ColorKeywords,
    fontFamily,
    FontSize,
    fontSpacing,
    FontWeight,
    getFontSizeValue,
    LineHeight,
    ThemeFontSize,
    ThemeProp,
} from '@hofy/theme';

import { styledAttrProps } from '../../helpers/StyledComponents';
import { Box, BoxProps } from './Box';

export interface FontProps extends BoxProps, FontStyleProps {
    as?: string | ComponentType;
    title?: string;
    onClick?(e: MouseEvent<HTMLDivElement>): void;
}

export interface FontStyleProps {
    size?: FontSize | ThemeFontSize;
    /** @deprecated use `bold` instead */
    weight?: FontWeight;
    bold?: boolean;
    italic?: boolean;
    color?: Color | ColorKeywords;
    spacing?: number;
    lineHeight?: LineHeight;
    textAlign?: Property.TextAlign;
    verticalAlign?: 'top' | 'bottom' | 'middle';
    textNoWrap?: boolean;
    textWrap?: boolean;
    upper?: boolean;
    underline?: boolean;
    lineThrough?: boolean;
    selectable?: boolean;
    ellipsis?: boolean;
    wordBreak?: boolean;
    whiteSpace?: Property.WhiteSpace;
    textClomp?: number;
    textTransform?: Property.TextTransform;
}

const lineHeightToNumber: Record<LineHeight, number> = {
    small: 1.2,
    standard: 1.4,
    large: 1.7,
};

const textStyleBuilder = ({
    lineHeight = 'standard',
    theme,
    size,
    weight,
    bold,
    italic,
    color,
    textAlign,
    textNoWrap,
    textWrap,
    verticalAlign,
    upper,
    underline,
    lineThrough,
    selectable,
    ellipsis,
    wordBreak,
    textClomp,
    whiteSpace,
    textTransform,
    spacing,
}: FontStyleProps & ThemeProp) => {
    const style: CSSObject = {
        fontFamily,
        lineHeight: lineHeightToNumber[lineHeight],
    };

    if (size) {
        style.fontSize = getFontSizeValue(theme, size);
    }
    if (color) {
        style.color = color;
    }
    if (weight || bold) {
        style.fontWeight = bold ? 'bold' : weight;
    }
    if (italic) {
        style.fontStyle = 'italic';
    }
    if (textAlign) {
        style.textAlign = textAlign;
    }
    if (textNoWrap) {
        style.whiteSpace = 'nowrap';
    }
    if (textWrap) {
        style.whiteSpace = 'pre-wrap';
        style.wordWrap = 'normal';
    }
    if (whiteSpace) {
        style.whiteSpace = whiteSpace;
    }
    if (verticalAlign) {
        style.verticalAlign = verticalAlign;
    }
    if (upper) {
        style.textTransform = 'uppercase';
    }
    if (textTransform) {
        style.textTransform = textTransform;
    }
    if (underline) {
        style.textDecoration = 'underline';
    }
    if (lineThrough) {
        style.textDecoration = 'line-through';
    }
    if (selectable) {
        style.userSelect = 'text';
    } else {
        style.userSelect = 'none';
    }
    if (ellipsis) {
        style.textOverflow = 'ellipsis';
    }
    if (wordBreak) {
        style.wordBreak = 'break-word';
    }
    if (spacing) {
        style.letterSpacing = spacing;
    }
    if (textClomp && lineHeight) {
        const lh = lineHeightToNumber[lineHeight];
        style.wordBreak = 'break-word';
        style.lineHeight = lh;
        style.maxHeight = `${lh * textClomp}em`;
        style.textOverflow = 'ellipsis';
        style.overflow = 'hidden';
        style.display = '-webkit-box';
        style['-webkit-line-clamp'] = `${textClomp}`;
        style['-webkit-box-orient'] = 'vertical';
    }
    return style;
};

const defaultValues: FontProps = {
    className: 'Font',
    weight: 'normal',
    lineHeight: 'standard',
    selectable: true,
    spacing: fontSpacing,
};

const defaultProps =
    <T,>(props?: Partial<FontProps>) =>
    (userProps: T) => ({ ...defaultValues, ...props, ...userProps });

export const BareFont = styled(Box)<FontProps>(textStyleBuilder);

export const Font = styled(BareFont).attrs(styledAttrProps(defaultValues))``;

export const Span = styled(BareFont).attrs(styledAttrProps({ as: 'span' }))``;

export const Strong = styled(BareFont).attrs(styledAttrProps({ as: 'strong', weight: 'bold' }))``;
export const Em = styled(BareFont).attrs(styledAttrProps({ as: 'em', fontStyle: 'italic' }))``;

export const Heading1 = styled(BareFont).attrs(
    defaultProps({ as: 'h1', size: 'heading1', weight: 'bold', color: Color.ContentPrimary }),
)``;
export const Heading2 = styled(BareFont).attrs(
    defaultProps({ as: 'h2', size: 'heading2', weight: 'bold', color: Color.ContentPrimary }),
)``;
export const Heading3 = styled(BareFont).attrs(
    defaultProps({ as: 'h3', size: 'heading3', weight: 'bold', color: Color.ContentPrimary }),
)``;

export const SectionTitle1 = styled(BareFont).attrs(
    defaultProps({ as: 'h4', size: 'sectionTitle1', weight: 'bold', color: Color.ContentPrimary }),
)``;
export const SectionTitle2 = styled(BareFont).attrs(
    defaultProps({ as: 'h5', size: 'sectionTitle2', weight: 'bold', color: Color.ContentPrimary }),
)``;
export const SectionTitle3 = styled(BareFont).attrs(
    defaultProps({ as: 'h6', size: 'sectionTitle3', weight: 'bold', color: Color.ContentPrimary }),
)``;

export const Paragraph1 = styled(BareFont).attrs(
    defaultProps({ size: 'paragraph1', weight: 'normal', color: Color.ContentSecondary }),
)``;
export const Paragraph2 = styled(BareFont).attrs(
    defaultProps({ size: 'paragraph2', weight: 'normal', color: Color.ContentSecondary }),
)``;
export const Paragraph3 = styled(BareFont).attrs(
    defaultProps({ size: 'paragraph3', weight: 'normal', color: Color.ContentSecondary }),
)``;
export const Paragraph4 = styled(BareFont).attrs(
    defaultProps({ size: 'paragraph4', weight: 'normal', color: Color.ContentSecondary }),
)``;

export const Overline1 = styled(BareFont).attrs(
    defaultProps({
        as: 'h6',
        size: 12,
        weight: 'bold',
        color: Color.ContentPrimary,
        upper: true,
        spacing: 2,
    }),
)``;
export const Overline2 = styled(BareFont).attrs(
    defaultProps({
        as: 'h6',
        size: 10,
        weight: 'bold',
        color: Color.ContentPrimary,
        upper: true,
        spacing: 2,
    }),
)``;
