import React, { FC } from 'react';

import { ItemGrade, itemGradeColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrItemGrade } from '../../../store/items/useTrItemGrade';

interface ItemGradeChipProps {
    grade: ItemGrade;
}

export const ItemGradeChip: FC<ItemGradeChipProps> = ({ grade }) => {
    const trItemGrade = useTrItemGrade();
    const color = itemGradeColor[grade];

    return <Badge color={color} label={trItemGrade(grade)} />;
};
