import React, { FC } from 'react';

import { IconItemWithTooltip } from '@hofy/common';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Paragraph3, SvgIcon } from '@hofy/ui';

interface CollectionUserAvailabilityCellProps {
    userAvailability: DateString[];
}

export const CollectionUserAvailabilityCell: FC<CollectionUserAvailabilityCellProps> = ({
    userAvailability,
}) => {
    return (
        <IconItemWithTooltip
            tooltip={
                <>
                    {userAvailability.map(date => (
                        <Paragraph3 key={date} textNoWrap color={Color.ContentInvertedPrimary}>
                            {formatDate(date)}
                        </Paragraph3>
                    ))}
                </>
            }
            icon={SvgIcon.Calendar}
            iconColor={Color.AccentYellow}
        >
            {`User availability (${userAvailability.length + ' dates'})`}
        </IconItemWithTooltip>
    );
};
