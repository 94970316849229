import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ShipmentTrackingExceptionStatus } from '../types/ShipmentTrackingExceptionStatus';

export const useTrShipmentTrackingExceptionStatus = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ShipmentTrackingExceptionStatus>(
        ShipmentTrackingExceptionStatus,
        'shipment-tracking-exception-status',
    );
};
