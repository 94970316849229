import React, { FC } from 'react';

import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Button, SvgIcon } from '@hofy/ui';

import { useCreateOrganization } from '../../../store/organizations/useCreateOrganization';
import { CreateOrganizationForm } from './CreateOrganizationForm';

interface CreateOrganizationSlideoutProps {
    onClose(): void;
}

export const CreateOrganizationSlideout: FC<CreateOrganizationSlideoutProps> = ({ onClose }) => {
    const { isLoadingMutation, form } = useCreateOrganization(onClose);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='add-organization'>
            <SlideoutHeader title='Add organisation' />
            <SlideoutContent>
                <CreateOrganizationForm form={form} />
            </SlideoutContent>
            <SlideoutFooter>
                <Button
                    type='ghost'
                    negativeMargin
                    onClick={onClose}
                    label='Cancel'
                    leftIcon={SvgIcon.Cross}
                />
                <AsyncButton isLoading={isLoadingMutation} label='Save' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
