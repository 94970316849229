import { ItemStatus, useTrItemStatus } from '@hofy/api-shared';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useItemStatusFilterDefinition = (allValues: ItemStatus[]): FilterDefinitionMulti<ItemStatus> => {
    const trItemStatus = useTrItemStatus();

    return {
        type: 'multi',
        name: 'Status',
        query: queryStringArrayParser(),
        toLabel: trItemStatus,
        allValues,
    };
};
