import React, { FC } from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';

import { DeviceCheckDetailsSection } from './DeviceCheckDetailsSection';
import { InspectionDetailsSection } from './InspectionDetailsSection';
import { ItemDetailsSection } from './ItemDetailsSection';
import { ItemLocationSection } from './ItemLocationSection';

interface InspectionDetailsTabProps {
    inspection: InspectionDetailsUnionDto;
}

export const InspectionDetailsTab: FC<InspectionDetailsTabProps> = ({ inspection }) => {
    return (
        <>
            <ItemDetailsSection inspection={inspection} />
            <ItemLocationSection inspection={inspection} />
            <InspectionDetailsSection inspection={inspection} />
            {inspection.deviceCheck && <DeviceCheckDetailsSection inspection={inspection} />}
        </>
    );
};
