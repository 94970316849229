import React, { FC } from 'react';

import { RegionFlag } from '@hofy/core';
import { Country, countryToStoreAndReuseRegion } from '@hofy/global';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { Badge, CountryFlag, Icon, SvgIcon, Tooltip } from '@hofy/ui';

type StoreAndReuseType = 'delivery' | 'collection';

interface AssignmentStoreAndReuseChipProps {
    type: StoreAndReuseType;
    country: Country | null;
    isLocalRegion: boolean | null;
}

export const StoreAndReuseBadge: FC<AssignmentStoreAndReuseChipProps> = ({
    type,
    country,
    isLocalRegion,
}) => {
    const tooltip = useStoreAndReuseTooltip(type, country, isLocalRegion);
    return (
        <Tooltip body={tooltip} asChild>
            <Badge
                leftSlot={
                    isLocalRegion ? (
                        <CountryFlagWithRegion country={country} size={16} />
                    ) : (
                        <Icon color={Color.FoundationDarkPurple} svg={SvgIcon.Hofy} size={16} />
                    )
                }
                color={type === 'collection' ? 'grape' : 'blue'}
                label='Store and reuse'
            />
        </Tooltip>
    );
};

interface CountryFlagWithRegionProps {
    country: Country | null;
    size?: number;
}

export const CountryFlagWithRegion = ({ country, size }: CountryFlagWithRegionProps) => {
    const region = country && countryToStoreAndReuseRegion(country);
    if (region) {
        return <RegionFlag region={region} size={size} />;
    }

    return <CountryFlag country={country} size={size} />;
};

const useStoreAndReuseTooltip = (
    type: StoreAndReuseType,
    country: Country | null,
    toOrFromOriginRegion?: boolean | null,
) => {
    const trCountry = useTrCountry();
    const trRegion = useTrRegion();
    if (toOrFromOriginRegion === null) {
        return `Store and reuse ${type}`;
    }

    const typeLabel = type === 'delivery' ? 'delivery from' : 'collection to';
    if (toOrFromOriginRegion) {
        const region = country && countryToStoreAndReuseRegion(country);
        const location = region ? trRegion(region) : trCountry(country);
        return `Store and reuse ${typeLabel} (${location})`;
    }
    return `Store and reuse ${typeLabel} Zone 1`;
};
