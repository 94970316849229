/* eslint-disable react/jsx-max-depth */
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MotionConfig } from 'framer-motion';
import React, { FC, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import { AuthWrapperContext, useAuthWrapper } from '@hofy/api-admin';
import { ErrorBoundary, Main } from '@hofy/common';
import { isDev, isE2E } from '@hofy/config';
import { I18nProvider } from '@hofy/i18n';
import { GlobalStyle, HofyUiModuleContexts, RWDProvider } from '@hofy/ui';

import { adminI18n } from '../i18n/adminI18n';
import { useErrorHandler } from '../store/useErrorHandler';
import { AdminRouter } from './AdminRouter';
import { AuthGate } from './AuthGate';

const root = createRoot(document.getElementById('app')!);

const App: FC = () => {
    const auth = useAuthWrapper();

    if (!auth.isInitialized) {
        return null;
    }

    return (
        <ErrorBoundary>
            <AuthWrapperContext.Provider value={auth}>
                <Main as='main'>
                    <AuthGate>
                        <AdminRouter />
                    </AuthGate>
                </Main>
            </AuthWrapperContext.Provider>
        </ErrorBoundary>
    );
};

const Root = () => {
    const onError = useErrorHandler();
    const queryClient = useRef(
        new QueryClient({
            queryCache: new QueryCache(),
            mutationCache: new MutationCache({ onError }),
            defaultOptions: { mutations: { retry: false } },
        }),
    );
    return (
        <QueryClientProvider client={queryClient.current}>
            <GlobalStyle lockBody />
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </QueryClientProvider>
    );
};

export const startApp = () =>
    root.render(
        <StyleSheetManager disableVendorPrefixes>
            <RWDProvider enabled={false}>
                <MotionConfig reducedMotion={isE2E() ? 'always' : 'user'}>
                    <HofyUiModuleContexts>
                        <I18nProvider translations={adminI18n} withMagicCombination={isDev()}>
                            <Root />
                        </I18nProvider>
                    </HofyUiModuleContexts>
                </MotionConfig>
            </RWDProvider>
        </StyleSheetManager>,
    );
