import { UUID, zeroUuid } from '@hofy/global';

export interface SeedRandomOrdersPayload {
    organizationId: UUID;
    count: number;
}

export const emptySeedRandomOrdersPayload: SeedRandomOrdersPayload = {
    organizationId: zeroUuid,
    count: 10,
};
