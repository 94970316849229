import { keyBy } from 'lodash';
import React, { FC, useMemo } from 'react';

import { RepaymentPlansFilters } from '@hofy/api-admin';
import {
    OrganizationRefDto,
    RepaymentStatus,
    repaymentStatusColors,
    useTrRepaymentStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { FilterChip } from '@hofy/ui';

interface RepaymentPlansActiveFilterChipsProps {
    filters: RepaymentPlansFilters;
    organizations: OrganizationRefDto[];
    onClearStatus(status: RepaymentStatus): void;
    onClearOrganization(id: UUID): void;
    onClearIsInvoiced(): void;
}

export const RepaymentPlansActiveFilterChips: FC<RepaymentPlansActiveFilterChipsProps> = ({
    filters,
    organizations,
    onClearStatus,
    onClearOrganization,
    onClearIsInvoiced,
}) => {
    const trRepaymentStatus = useTrRepaymentStatus();
    const organizationIdMap = useMemo(() => keyBy(organizations, v => v.id), [organizations]);
    return (
        <>
            {!!filters.organizations &&
                filters.organizations.map(s => (
                    <FilterChip
                        key={s}
                        label={organizationIdMap[s]?.name}
                        onClear={() => onClearOrganization(s)}
                        color='blue'
                    />
                ))}
            {!!filters.status &&
                filters.status.map(s => (
                    <FilterChip
                        key={s}
                        label={trRepaymentStatus(s)}
                        onClear={() => onClearStatus(s)}
                        color={repaymentStatusColors[s]}
                    />
                ))}
            {filters.isInvoiced !== null && (
                <FilterChip
                    label={filters.isInvoiced ? 'Invoiced' : 'Not invoiced'}
                    onClear={() => onClearIsInvoiced()}
                    color={filters.isInvoiced ? 'green' : 'red'}
                />
            )}
        </>
    );
};
