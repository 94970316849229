import React, { FC } from 'react';

import { FormDropdown } from '@hofy/common';
import { coreCurrencies, Currency } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { BoxProps, TestKeyPrefixAware } from '@hofy/ui';

interface CurrencyFormDropdownProps extends BoxProps, TestKeyPrefixAware {
    value?: Currency;
    label?: string;
    bg?: Color;
    onChange(o: Currency): void;
    isError?: boolean | string;
}

export const CurrencyFormDropdown: FC<CurrencyFormDropdownProps> = ({
    value,
    onChange,
    label,
    bg,
    testKeyPrefix,
    isError,
    ...boxProps
}) => {
    const tr = useTrCurrency();

    return (
        <FormDropdown
            items={coreCurrencies}
            labelFormatter={tr}
            onChange={onChange}
            label={label}
            bg={bg}
            value={value}
            testKey={testKeyPrefix}
            isError={isError}
            {...boxProps}
        />
    );
};
