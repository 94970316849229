import React, { FC } from 'react';

import { RevenueRecognitionDto } from '@hofy/api-admin';
import { usePrice } from '@hofy/hooks';
import { NumberValues } from '@hofy/theme';
import { BaseTable, FormSection, Paragraph3, Placeholder, SvgIllustration } from '@hofy/ui';

interface RevenueRecognitionProps {
    entries: RevenueRecognitionDto[];
    needsRevenueRecognition: boolean;
}

export const RevenueRecognitionSection: FC<RevenueRecognitionProps> = ({
    entries,
    needsRevenueRecognition,
}) => {
    return (
        <FormSection label='Revenue recognition schedule'>
            {!needsRevenueRecognition && <Paragraph3>No schedule for this invoice entry type.</Paragraph3>}

            {needsRevenueRecognition && <RevenueRecognitionTable entries={entries} />}
        </FormSection>
    );
};
const RevenueRecognitionTable: FC<{
    entries: RevenueRecognitionDto[];
}> = ({ entries }) => {
    const { formatPrice } = usePrice();

    if (entries.length === 0) {
        return <Paragraph3>Schedule will be calculated on invoice approval.</Paragraph3>;
    }

    return (
        <BaseTable
            data={entries}
            toKey={(_, index) => index}
            emptyContent={
                <Placeholder illustration={SvgIllustration.List} title='No revenue recognition schedule' />
            }
            height='auto'
            marginHorizontal={-40 as NumberValues}
            columns={[
                {
                    id: 'monthStartOn',
                    header: 'Month start on',
                    renderer: item => item.monthStartOn,
                },
                {
                    id: 'monthEndOn',
                    header: 'Month end on',
                    renderer: item => item.monthEndOn,
                },
                {
                    id: 'startOn',
                    header: 'Revenue start on',
                    renderer: item => item.startOn,
                },
                {
                    id: 'endOn',
                    header: 'Revenue end on',
                    renderer: item => item.endOn,
                },
                {
                    id: 'daysRecognized',
                    header: 'Number of days',
                    renderer: item => item.daysRecognized,
                },
                {
                    id: 'revenueValue',
                    header: 'Value recognized',
                    renderer: item => formatPrice(item.revenueValue),
                },
            ]}
        />
    );
};
