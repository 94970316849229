import React, { FC } from 'react';

import { ShipmentCollectionRequestItemDto } from '@hofy/api-admin';
import { Color, toContentColor } from '@hofy/theme';
import { Box, FeaturedIcon, Paragraph3 } from '@hofy/ui';

import { CollectionRequestItemPropertyStatus } from '../../../../../store/collectionRequests/types/CollectionRequestItemPropertyStatus';
import {
    CollectionRequestItemPropertyType,
    getItemPropertyTextAndAppearance,
} from '../../../../../store/collectionRequests/types/CollectionRequestItemPropertyType';

interface ItemPropertyStatusChipProps {
    item: ShipmentCollectionRequestItemDto;
    propertyType: CollectionRequestItemPropertyType;
    propertyStatus: CollectionRequestItemPropertyStatus;
    isDisabled: boolean;
}

export const ItemPropertyStatusChip: FC<ItemPropertyStatusChipProps> = ({
    item,
    propertyType,
    propertyStatus,
    isDisabled,
}) => {
    const [text, visualType, icon] = getItemPropertyTextAndAppearance(propertyType, propertyStatus);
    const type = isDisabled ? 'informative' : visualType;

    return (
        <Box row gap={12}>
            <FeaturedIcon type={type} icon={icon} />
            <ItemPropertyStatusChipText
                text={text}
                color={toContentColor(type)}
                item={item}
                propertyType={propertyType}
            />
        </Box>
    );
};

interface ItemPropertyStatusChipTextProps {
    text: string;
    color: Color;
    item: ShipmentCollectionRequestItemDto;
    propertyType: CollectionRequestItemPropertyType;
}

const ItemPropertyStatusChipText: FC<ItemPropertyStatusChipTextProps> = ({
    text,
    color,
    item,
    propertyType,
}) => {
    const textNode = <Paragraph3 color={color}>{text}</Paragraph3>;

    if (
        propertyType === CollectionRequestItemPropertyType.ProvidedSerialNumber &&
        item.providedSerialNumber
    ) {
        return (
            <Box column>
                {textNode}
                <Paragraph3>{item.providedSerialNumber}</Paragraph3>
            </Box>
        );
    }

    return textNode;
};
