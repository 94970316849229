import React, { FC } from 'react';

import { Badge } from '@hofy/ui';

interface ActiveProductChipProps {
    value: boolean;
}

export const ActiveProductChip: FC<ActiveProductChipProps> = ({ value }) => {
    const color = value ? 'green' : 'grey';
    const title = value ? 'Active' : 'Inactive';
    return <Badge color={color} label={title} />;
};
