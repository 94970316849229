import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, collectionRequestService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

export const useRevertEscalateCollectionRequest = (shipmentId: UUID, collectionRequestId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () =>
            collectionRequestService.revertEscalateCollectionRequest(shipmentId, collectionRequestId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Shipment has been successfully de-escalated.',
            });
        },
    });

    return {
        mutate: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
