export const paymentSchemaI18n = {
    'payment-schema.monthly': 'Monthly',
    'payment-schema.upfront': 'Upfront',
    'payment-schema.annual': 'Annual',
    'payment-schema.deposit-6-months': 'Deposit 6 months',
    'payment-schema.deposit-12-months': 'Deposit 12 months',
};

export const paidWithSchemaI18n = {
    'paid-schema.monthly': 'Monthly payment',
    'paid-schema.annual': 'Annual payment',
    'paid-schema.upfront': 'Upfront payment',
    'paid-schema.deposit_6_months': 'Deposit 6 months',
    'paid-schema.deposit_12_months': 'Deposit 12 months',
};

export const paymentSchemaWithPaymentsI18n = {
    'payment-schema-payments.monthly': 'Monthly payments',
    'payment-schema-payments.upfront': 'Upfront payment',
    'payment-schema-payments.annual': 'Annual payments',
    'payment-schema-payments.deposit-6-months': 'Deposit 6 months',
    'payment-schema-payments.deposit-12-months': 'Deposit 12 months',
};
