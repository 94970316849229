import React, { FC } from 'react';

import { ShipmentSchedulePayload } from '@hofy/api-admin';
import { Switch } from '@hofy/common';
import { DateString } from '@hofy/global';
import { now } from '@hofy/helpers';
import { FormDateInput, UseForm } from '@hofy/ui';

interface ShipmentScheduleFormProps {
    form: UseForm<ShipmentSchedulePayload>;
}

export const ShipmentScheduleForm: FC<ShipmentScheduleFormProps> = ({ form }) => {
    return (
        <>
            <Switch
                label='Schedule shipment'
                checked={!!form.values.scheduledOn}
                onChange={value => {
                    if (!value) {
                        return form.setValues({
                            scheduledOn: undefined,
                        });
                    }
                    return form.setValues({
                        scheduledOn: now()
                            .plus({
                                days: 1,
                            })
                            .toISO() as DateString,
                    });
                }}
            />
            {form.values.scheduledOn && (
                <FormDateInput label='Schedule date' api={form.fields.scheduledOn} nullable />
            )}
        </>
    );
};
