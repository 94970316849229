import React from 'react';

import { formatUserName, InspectionDeviceCheckStatus, StatusUpdateDto } from '@hofy/api-shared';
import { Timeline } from '@hofy/common';
import { formatDateTime } from '@hofy/helpers';
import { Box, Paragraph3, Span } from '@hofy/ui';

interface InspectionTimelineProps<T extends string> {
    statusUpdates: StatusUpdateDto<T>[];
    statusSequence: T[];
    toStatus(s: T): string;
}

export const InspectionTimeline = <T extends string>({
    statusUpdates,
    statusSequence,
    toStatus,
}: InspectionTimelineProps<T>) => {
    const isDeviceCheckEscalated = statusUpdates.some(
        update => update.status === InspectionDeviceCheckStatus.Escalated,
    );
    const statusUpdatesWithoutEscalated = statusUpdates.filter(
        update => update.status !== InspectionDeviceCheckStatus.Escalated,
    );
    return (
        <Timeline
            items={getInspectionTimeline(statusUpdatesWithoutEscalated, statusSequence).map(update => (
                <Box key={update.status} column gap={4} paddingHorizontal={10}>
                    <Paragraph3 bold>{toStatus(update.status)}</Paragraph3>
                    <Box row gap={4}>
                        {update.updatedAt && <Paragraph3>{formatDateTime(update.updatedAt)}</Paragraph3>}
                        {update.updatedAt && update.updatedBy && (
                            <Paragraph3>
                                by <Span bold>{formatUserName(update.updatedBy)}</Span>
                            </Paragraph3>
                        )}
                    </Box>
                    {update.status === InspectionDeviceCheckStatus.Completed && isDeviceCheckEscalated && (
                        <Paragraph3>
                            Hofy had to reach out to a team member or organisation to complete this task
                        </Paragraph3>
                    )}
                </Box>
            ))}
            currentIndex={statusUpdatesWithoutEscalated.length - 1}
            fullWidth
            padding={10}
        />
    );
};

const getInspectionTimeline = <T extends string>(
    statusUpdates: StatusUpdateDto<T>[],
    statusSequence: T[],
): (
    | StatusUpdateDto<T>
    | {
          status: T;
          updatedAt: null;
      }
)[] => {
    return statusSequence.map((status, index) => {
        return (
            statusUpdates[index] ?? {
                status,
                updatedAt: null,
            }
        );
    });
};
