import React from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

export const CollectionMissingLaptopAccessories = () => {
    const color = Color.ContentNegative;
    const chipBody = 'Missing laptop accessories';
    return (
        <Indicator color={color}>
            <TooltipIcon icon={SvgIcon.Dongle} iconColor={color} body={chipBody} iconSize={16} />
        </Indicator>
    );
};
