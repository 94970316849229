import { useMutation, useQueryClient } from '@tanstack/react-query';

import { suppliersCacheKey, suppliersService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { useSupplierForm } from './useSupplierForm';

export const useCreateSupplier = (onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: suppliersService.createSupplier,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [suppliersCacheKey] });
            showToast({
                type: 'positive',
                message: 'Supplier created',
            });
            onSuccess();
        },
    });

    const form = useSupplierForm(mutation.mutate);
    return { form, isLoading: mutation.isPending };
};
