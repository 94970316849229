import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import {
    LabeledBillingEntityNormalProps,
    LabeledBillingEntityNullableProps,
    LabeledBillingEntitySelect,
} from './LabeledBillingEntitySelect';

// Normal
type FormBillingEntityNormalOptionalProps = Optional<LabeledBillingEntityNormalProps, 'onChange'>;
type FormBillingEntityNormalOmittedProps = Omit<
    FormBillingEntityNormalOptionalProps,
    'errorMessage' | 'value'
>;

interface FormNormalBillingEntitySelectProps extends FormBillingEntityNormalOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID>;
    nullable?: false;
}

// Nullable
type FormBillingEntityNullableOptionalProps = Optional<LabeledBillingEntityNullableProps, 'onChange'>;
type FormBillingEntityNullabletOmittedProps = Omit<
    FormBillingEntityNullableOptionalProps,
    'errorMessage' | 'value'
>;

interface FormNullableBillingEntitySelectProps extends FormBillingEntityNullabletOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID | null>;
    nullable: true;
}

type FormBillingEntitySelectProps = FormNormalBillingEntitySelectProps | FormNullableBillingEntitySelectProps;

export const FormBillingEntitySelect: FC<FormBillingEntitySelectProps> = ({
    api,
    onChange,
    onBlur,
    ...inputProps
}) => {
    return (
        <LabeledBillingEntitySelect
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={(value, dto) => {
                api.setValue(value);
                onChange?.(value, dto);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
