import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useDebounceValue } from '@hofy/hooks';

import { auditLogService } from '../service/auditLogService';
import { AuditLogDto } from '../service/types/AuditLogDto';
import { AuditLogsFilters } from '../service/types/AuditLogsFilters';
import { cacheKey } from './cacheKey';

export const useAuditLogs = (filters: AuditLogsFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [cacheKey, filters],
        queryFn: param =>
            auditLogService.auditLogsItems(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const isLoadingValue = useDebounceValue(isLoading, 500);

    const items: AuditLogDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        items,
        isLoading: isLoadingValue,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
