import React, { FC } from 'react';

import { getRepairStatusColor, getRepairStatusLabel, RepairStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface RepairStatusChipProps {
    status: RepairStatus;
    isCancelled: boolean;
}

export const RepairStatusChip: FC<RepairStatusChipProps> = ({ status, isCancelled }) => {
    if (isCancelled) {
        return <Badge label='Cancelled' color='red' />;
    }

    return <Badge label={getRepairStatusLabel(status)} color={getRepairStatusColor(status)} />;
};
