import { useQuery } from '@tanstack/react-query';

import { auditLogService } from '../service/auditLogService';
import { auditTablesCacheKey } from './cacheKey';

const initialItems: string[] = [];
export const useAuditTablesQuery = () => {
    const { isLoading, data } = useQuery({
        queryKey: [auditTablesCacheKey],
        queryFn: auditLogService.getAuditableTables,
    });
    return {
        isLoading,
        data: data || initialItems,
    };
};
