export const stateI18n = {
    'state.AL': 'Alabama',
    'state.AK': 'Alaska',
    'state.AZ': 'Arizona',
    'state.AR': 'Arkansas',
    'state.CA': 'California',
    'state.CO': 'Colorado',
    'state.CT': 'Connecticut',
    'state.DE': 'Delaware',
    'state.DC': 'District of Columbia',
    'state.FL': 'Florida',
    'state.GA': 'Georgia',
    'state.HI': 'Hawaii',
    'state.ID': 'Idaho',
    'state.IL': 'Illinois',
    'state.IN': 'Indiana',
    'state.IA': 'Iowa',
    'state.KS': 'Kansas',
    'state.KY': 'Kentucky',
    'state.LA': 'Louisiana',
    'state.ME': 'Maine',
    'state.MD': 'Maryland',
    'state.MA': 'Massachusetts',
    'state.MI': 'Michigan',
    'state.MN': 'Minnesota',
    'state.MS': 'Mississippi',
    'state.MO': 'Missouri',
    'state.MT': 'Montana',
    'state.NE': 'Nebraska',
    'state.NV': 'Nevada',
    'state.NH': 'New Hampshire',
    'state.NJ': 'New Jersey',
    'state.NM': 'New Mexico',
    'state.NY': 'New York',
    'state.NC': 'North Carolina',
    'state.ND': 'North Dakota',
    'state.OH': 'Ohio',
    'state.OK': 'Oklahoma',
    'state.OR': 'Oregon',
    'state.PA': 'Pennsylvania',
    'state.RI': 'Rhode Island',
    'state.SC': 'South Carolina',
    'state.SD': 'South Dakota',
    'state.TN': 'Tennessee',
    'state.TX': 'Texas',
    'state.UT': 'Utah',
    'state.VT': 'Vermont',
    'state.VA': 'Virginia',
    'state.WA': 'Washington',
    'state.WV': 'West Virginia',
    'state.WI': 'Wisconsin',
    'state.WY': 'Wyoming',
};
