import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Overline2 } from '../../base';

interface NavigationTitleProps {
    title: string;
}

export const NavigationTitle = ({ title }: NavigationTitleProps) => {
    return (
        <TitleFont paddingTop={16} marginBottom={8} paddingHorizontal={16}>
            {title}
        </TitleFont>
    );
};

export const TitleFont = styled(Overline2)`
    color: ${rgba(Color.ContentInvertedPrimary, 0.5)};
`;
