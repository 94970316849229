import React, { FC, FocusEvent, KeyboardEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, MarginBoxProps, Paragraph3, Paragraph4, TestKeyAware } from '@hofy/ui';

import { FlatTextarea } from './base/FlatInput';

interface FormTextareaProps extends MarginBoxProps, TestKeyAware, BoxProps {
    label?: ReactNode;

    value: string | null | undefined;
    placeholder?: string;
    name?: string;

    rows?: number;

    isRequired?: boolean;
    isError?: boolean | string;
    disabled?: boolean;
    errorMessage?: ReactNode;

    note?: ReactNode;

    onChangeText(v: string): void;
    onBlur?(e: FocusEvent): void;
    onFocus?(e: FocusEvent): void;
    onKeyDown?(e: KeyboardEvent): void;
}

/** @deprecated use `LabeledTextarea` or `FormTextarea` from `@hofy/ui */
export const FormTextarea: FC<FormTextareaProps> = ({
    label,
    isRequired,
    isError,
    disabled,
    errorMessage,
    value = '',
    onChangeText = () => {},
    placeholder,
    name,
    rows,
    testKey,
    onBlur,
    onFocus,
    onKeyDown,
    note,
    height,
    ...margins
}) => {
    const finalErrorMessage = errorMessage || (typeof isError === 'string' ? isError : undefined);
    const showErrorMessage = isError && finalErrorMessage;
    return (
        <Box {...margins}>
            {label && (
                <Paragraph3 marginBottom={8}>
                    {label} {isRequired && '*'}
                </Paragraph3>
            )}
            <FormInputWrapper isError={!!isError} row disabled={disabled}>
                <FlatTextarea
                    rows={rows}
                    height={height}
                    paddingLeft={10}
                    paddingTop={8}
                    paddingBottom={8}
                    value={value ?? ''}
                    name={name}
                    onBlur={onBlur}
                    data-test-key={testKey}
                    onChange={e => onChangeText(e.target.value)}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    disabled={disabled}
                />
            </FormInputWrapper>
            {note && !showErrorMessage && (
                <Paragraph4 marginTop={8} color={Color.Neutral300}>
                    {note}
                </Paragraph4>
            )}
            {showErrorMessage && (
                <Paragraph3 marginTop={8} color={Color.FoundationNegative}>
                    {finalErrorMessage}
                </Paragraph3>
            )}
        </Box>
    );
};

const FormInputWrapper = styled(Box)<{ isError?: boolean; disabled?: boolean }>`
    transition: all 0.3s ease-in-out;
    border: 1px solid ${p => (p.isError ? Color.FoundationNegative : Color.InteractionBorderNeutralNormal)};
    border-radius: 5px;
    position: relative;

    :hover {
        border-color: ${Color.InteractionBorderHover};
    }

    :focus-within {
        outline: none;
        border-color: ${Color.InteractionFocusDefault};
        box-shadow: 0 0 0 3px ${Color.InteractionFocusDefault};
    }

    ${p =>
        p.disabled &&
        css`
            border: none;
        `};
`;
