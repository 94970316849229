import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';

import { BillingEntityDto, useBillingEntitiesQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import {
    LabeledSelectSearch,
    LabeledSelectSearchNullableStringProps,
    LabeledSelectSearchOnlyStringProps,
} from '@hofy/ui';

interface BaseProps {
    placeholder?: string;
    organizationId?: UUID;
    nonOrgEntitiesOnly?: boolean;
}

export type LabeledBillingEntityNormalProps = Omit<
    LabeledSelectSearchOnlyStringProps<UUID>,
    'isError' | 'toText' | 'options' | 'onChange'
> & { onChange(id: UUID, dto: BillingEntityDto): void } & BaseProps;

export type LabeledBillingEntityNullableProps = Omit<
    LabeledSelectSearchNullableStringProps<UUID>,
    'isError' | 'toText' | 'options' | 'onChange'
> & { onChange(id: UUID | null, dto: BillingEntityDto | null): void } & BaseProps;

export type LabeledBillingEntityProps = LabeledBillingEntityNormalProps | LabeledBillingEntityNullableProps;

export const LabeledBillingEntitySelect = forwardRef(
    (
        { organizationId, onChange, nonOrgEntitiesOnly = false, value, ...rest }: LabeledBillingEntityProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const [billingEntitySearch, setBillingEntitySearch] = useState('');
        const { billingEntities, isFetching } = useBillingEntitiesQuery({
            organizationIds: organizationId ? [organizationId] : [],
            search: billingEntitySearch,
            includeIds: value ? [value] : undefined,
            nonOrgEntitiesOnly,
        });
        const nameMap = useMemo(() => keyBy(billingEntities, v => v.id), [billingEntities]);

        return (
            <LabeledSelectSearch
                toText={org => nameMap[org]?.name}
                options={billingEntities.map(v => v.id)}
                ref={ref}
                onSearchChange={setBillingEntitySearch}
                isLoadingSearch={isFetching}
                value={value}
                onChange={(newId: UUID) => {
                    setBillingEntitySearch('');
                    onChange(newId, nameMap[newId] ?? null);
                }}
                {...rest}
            />
        );
    },
) as (props: LabeledBillingEntityProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
