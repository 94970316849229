import React, { FC } from 'react';

import { BillingEntityRef } from '@hofy/api-shared';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { Link } from '../../../components/routing/Link';

interface BillingEntityLinkProps {
    billingEntity: BillingEntityRef;
}

export const BillingEntityLink: FC<BillingEntityLinkProps> = ({ billingEntity }) => {
    return (
        <Link to={`${AdminNavLink.Invoicing}/billing-entities/${billingEntity.id}/details`}>
            {billingEntity.name}
        </Link>
    );
};
