import React, { FC, useState } from 'react';

import { DateRangeStrings, FromToDateStrings, UUID } from '@hofy/global';
import { DateRangeType, nowDate, toISODate } from '@hofy/helpers';
import {
    Alert,
    AsyncButton,
    LabeledDateRangeInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { useCollectBillingEntityPayments } from '../../../../store/payments/useCollectBillingEntityPayments';

interface CollectBillingEntityPaymentsModalProps {
    billingEntityId: UUID;
    onClose(): void;
}

export const CollectBillingEntityPaymentsModal: FC<CollectBillingEntityPaymentsModalProps> = ({
    billingEntityId,
    onClose,
}) => {
    const { collectPayments, isLoading } = useCollectBillingEntityPayments(billingEntityId, onClose);
    const fromDate = toISODate(
        nowDate().startOf('month').minus({
            month: 1,
        }),
    );
    const toDate = toISODate(
        nowDate().endOf('month').minus({
            month: 1,
        }),
    );
    const [dateRange, setDateRange] = useState<DateRangeStrings>({
        from: fromDate,
        to: toDate,
    });

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Collect payments' />
            <ModalContent>
                <Alert
                    type='informative'
                    description='Payments are only collected for invoices that are due, and if there are active Direct Debit mandates for this billing entity.'
                />
                <LabeledDateRangeInput
                    label='Invoice date between'
                    value={dateRange}
                    onChange={setDateRange}
                    allowedRangeTypes={[DateRangeType.FromToDate]}
                    marginLeft={12}
                    marginTop={8}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Collect'
                    disableCheck
                    onClick={() => collectPayments(dateRange as FromToDateStrings)}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
