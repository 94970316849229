import React, { FC, useEffect } from 'react';

import { emptyPurchaseOrderItemDeliveryPayload, PurchaseOrderItemDto } from '@hofy/api-admin';
import { Button, FormDateInput, Modal, ModalContent, ModalFooter, ModalHeader } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { usePurchaseOrderItemUpdateDeliveryForm } from '../../../../../store/purchaseOrders/usePurchaseOrderItemUpdateDeliveryForm';

interface ItemDeliveryDateUpdateModalProps {
    purchaseOrderItem?: PurchaseOrderItemDto;
    onClose(): void;
}

export const ItemDeliveryDateUpdateModal: FC<ItemDeliveryDateUpdateModalProps> = ({
    purchaseOrderItem,
    onClose,
}) => {
    const purchaseOrderItemDeliveryForm = usePurchaseOrderItemUpdateDeliveryForm(purchaseOrderItem?.id!);

    useEffect(() => {
        if (!purchaseOrderItem) {
            purchaseOrderItemDeliveryForm.form.setValues(emptyPurchaseOrderItemDeliveryPayload);
            return;
        }
        purchaseOrderItemDeliveryForm.form.setValues({
            estimatedReceiveOn: purchaseOrderItem.estimatedReceiveOn,
        });
    }, [purchaseOrderItem]);

    const submit = () => {
        purchaseOrderItemDeliveryForm.form.submit();
        onClose();
    };

    return (
        <Modal width={600} onClose={onClose}>
            <ModalHeader
                title='Update item delivery date'
                subtitle={`For purchase order item #${purchaseOrderItem?.id}`}
            />
            <ModalContent>
                <FormDateInput
                    label='Estimated receive on'
                    api={purchaseOrderItemDeliveryForm.form.fields.estimatedReceiveOn}
                    nullable
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <Button label='Confirm' onClick={submit} />
            </ModalFooter>
        </Modal>
    );
};
