import React, { ElementRef, forwardRef, ReactNode } from 'react';

import { Box } from '../../base';
import { DropdownContent, DropdownContentProps } from './DropdownContent';

interface DropdownContentScrollableProps extends DropdownContentProps {
    topSlot?: ReactNode;
    bottomSlot?: ReactNode;
}

export const DropdownContentScrollable = forwardRef<
    ElementRef<typeof DropdownContent>,
    DropdownContentScrollableProps
>(({ topSlot, bottomSlot, children, ...rest }, ref) => {
    return (
        <DropdownContent ref={ref} column flex='auto' {...rest}>
            {topSlot}
            <Box flex='auto' overflow='auto' column gap={4} padding={4}>
                {children}
            </Box>
            {bottomSlot}
        </DropdownContent>
    );
});
