import React, { FC } from 'react';

import { InvoiceDetailsDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { BooleanBadge, Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { LabeledOrganizationLink } from '../../../../organizationsPage/OrganizationDetailsLink';
import { BillingEntityLink } from '../../../billingEntities/BillingEntityLink';
import { InvoicePaymentDetailsCard } from './InvoicePaymentDetailsCard';

interface InvoiceDetailsSectionProps {
    invoice: InvoiceDetailsDto;
}

export const InvoiceDetailsSection: FC<InvoiceDetailsSectionProps> = ({ invoice }) => {
    return (
        <FormSection label='Details'>
            <Box row>
                <Box column flex={4} rowGap={24}>
                    <FormGridRow columns={3}>
                        <LabeledText label='Id' content={invoice.id} />
                        <LabeledText label='Invoice date' content={formatDate(invoice.invoiceTime)} />
                        <LabeledText label='Due date' content={formatDate(invoice.dueDate)} />
                        <LabeledText label='Invoice from' content={invoice.hofySubsidiary.name} />
                        <LabeledText
                            label='Invoice to'
                            content={<BillingEntityLink billingEntity={invoice.billingEntity} />}
                        />
                        {invoice.organization && (
                            <LabeledOrganizationLink organization={invoice.organization} />
                        )}
                    </FormGridRow>
                    <FormGridRow columns={3}>
                        <Labeled
                            label='Two API'
                            content={<BooleanBadge value={invoice.twoApi} offColor='grey' />}
                        />
                        <Labeled
                            label='Two Spreadsheet'
                            content={<BooleanBadge value={invoice.twoSpreadsheet} offColor='grey' />}
                        />
                    </FormGridRow>
                </Box>
                <Box row flex={1} minWidth='240px'>
                    <InvoicePaymentDetailsCard
                        summary={invoice.paymentSummary}
                        isCreditNote={invoice.isCreditNote}
                    />
                </Box>
            </Box>
        </FormSection>
    );
};
