import { xor } from 'lodash';
import React, { FC } from 'react';

import { UpdateOrganizationFinancialSettingsPayload } from '@hofy/api-admin';
import { PaymentSchema, useTrPaymentSchema } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Icon, LabeledCheckbox, Paragraph3, SvgIcon, UseForm } from '@hofy/ui';

import { useAdminI18n } from '../../../../i18n/useAdminI18n';

interface PaymentSchemaSectionProps {
    schema: PaymentSchema;
    form: UseForm<UpdateOrganizationFinancialSettingsPayload>;
    isPaymentSchemaUpfront(schema: PaymentSchema): boolean;
    showSchemaWarning(schema: PaymentSchema): boolean;
    disabled?: boolean;
}

export const PaymentSchemaSection: FC<PaymentSchemaSectionProps> = ({
    schema,
    form,
    isPaymentSchemaUpfront,
    showSchemaWarning,
    disabled = false,
}) => {
    const { tr } = useAdminI18n();
    const trPaymentSchema = useTrPaymentSchema();

    return (
        <Box key={schema} marginVertical={0}>
            <Box>
                <LabeledCheckbox
                    key={schema}
                    checked={form.values.allowedBillingFrequencies.includes(schema)}
                    onChange={() =>
                        form.setValues({
                            allowedBillingFrequencies: xor(form.values.allowedBillingFrequencies, [schema]),
                        })
                    }
                    disabled={disabled || isPaymentSchemaUpfront(schema)}
                    label={
                        <Paragraph3
                            color={isPaymentSchemaUpfront(schema) ? Color.Neutral300 : Color.Neutral500}
                        >
                            {trPaymentSchema(schema)}
                        </Paragraph3>
                    }
                />
            </Box>
            {showSchemaWarning(schema) && (
                <Box row marginTop={5}>
                    <Icon svg={SvgIcon.InfoCircle} size={18} marginRight={5} color={Color.AccentOrange} />
                    <Paragraph3 color={isPaymentSchemaUpfront(schema) ? Color.Neutral300 : Color.Neutral500}>
                        {tr('organization-page.financial-settings-update.modal.payment-schema-warning')}
                    </Paragraph3>
                </Box>
            )}
        </Box>
    );
};
