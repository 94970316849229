import React, { FC } from 'react';

import { CountryLabel } from '@hofy/core';
import { Country } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Labeled } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface CountryResultProps {
    collectionCountry: Country | null;
    placeOfWorkCountry: Country | null;
}

export const CountryResult: FC<CountryResultProps> = ({ collectionCountry, placeOfWorkCountry }) => {
    if (collectionCountry === placeOfWorkCountry) {
        return (
            <SurveyResultsSectionCardChip title='Country' status={SurveyResultsSectionCardChipStatus.Neutral}>
                <CountryLabel color={Color.ContentPrimary} country={collectionCountry} />
            </SurveyResultsSectionCardChip>
        );
    }

    return (
        <SurveyResultsSectionCardChip
            title='Different country'
            status={SurveyResultsSectionCardChipStatus.Warning}
        >
            <Labeled label='Collection country' content={<CountryLabel country={collectionCountry} />} />
            <Labeled label='Place of work country' content={<CountryLabel country={placeOfWorkCountry} />} />
        </SurveyResultsSectionCardChip>
    );
};
