import React, { Key } from 'react';

import { ColorDye } from '@hofy/global';

import { FilterChip } from './FilterChip';

interface FilterChipListProps<T> {
    selected: T[];
    color: ColorDye | ((t: T) => ColorDye);
    toLabel(value: T): string;
    toKey(value: T): Key;
    onClear(value: T): void;
}

export const FilterChipList = <T extends any>({
    selected,
    color,
    toKey,
    toLabel,
    onClear,
}: FilterChipListProps<T>) => {
    if (!selected.length) {
        return null;
    }

    return (
        <>
            {selected.map(v => (
                <FilterChip
                    key={toKey(v)}
                    label={toLabel(v)}
                    onClear={() => onClear(v)}
                    color={typeof color === 'function' ? color(v) : color}
                />
            ))}
        </>
    );
};
