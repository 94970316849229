import { ColorDye } from '@hofy/global';

export enum ManagementContractType {
    Addon = 'addon',
    StoreAndReuse = 'store_and_reuse',
}

export const managementContractTypeColors: Record<ManagementContractType, ColorDye> = {
    [ManagementContractType.StoreAndReuse]: 'blue',
    [ManagementContractType.Addon]: 'green',
};
