import React, { FC, ReactNode } from 'react';

import { Box, ExpandHeight } from '@hofy/ui';

interface BlockFilterChipContainerProps {
    show?: boolean;
    children: ReactNode;
}

export const BlockFilterChipContainer: FC<BlockFilterChipContainerProps> = ({ children, show }) => {
    return (
        <Box>
            <ExpandHeight>
                {!!show && (
                    <Box
                        wrap
                        row
                        gap={10}
                        justify='flex-end'
                        paddingVertical={12}
                        paddingHorizontal={40}
                        borderBottom
                    >
                        {children}
                    </Box>
                )}
            </ExpandHeight>
        </Box>
    );
};
