import React from 'react';

import {
    AdminItemFilter,
    contractRemainingMonths,
    ContractUnionDto,
    emptyItemsFilters,
    useItems,
} from '@hofy/api-admin';
import {
    formatVariant,
    RentalStoreAndReuseLocation,
    useTrAnyContractType,
    WarehouseRefDto,
} from '@hofy/api-shared';
import { BlockLoader, SeparatedContainer } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import {
    Box,
    Button,
    LabelDivider,
    Paragraph3,
    Paragraph4,
    Radio,
    Spinner,
    SvgIcon,
    TooltipIcon,
} from '@hofy/ui';

import { StoreAndReuseBadge } from '../../../components/domain/assignments/badge/StoreAndReuseBadge';
import { ProductItem } from '../../../components/domain/products/ProductItem';
import { ContractLinkLabel } from '../../contractsPage/ContractLink';

interface ContractListProps {
    title: string;
    contracts: ContractUnionDto[];
    selectedId: UUID | null;
    isLoading: boolean;
    hasNextPage?: boolean;
    warehouse?: WarehouseRefDto;
    onSelect(contractId: UUID): void;
    onFetchNextPage(): void;
}

export const ContractRadioList = ({
    title,
    contracts,
    selectedId,
    isLoading,
    hasNextPage,
    warehouse,
    onSelect,
    onFetchNextPage,
}: ContractListProps) => {
    return (
        <>
            <LabelDivider marginTop={40} marginBottom={20}>
                {title}
            </LabelDivider>
            <SeparatedContainer orientation='vertical' lined spacing={16} alignItems='stretch'>
                {contracts.map(contract => (
                    <ContractRadioTile
                        key={contract.id}
                        contract={contract}
                        checked={contract.id === selectedId}
                        onChange={onSelect}
                        warehouse={warehouse}
                    />
                ))}
                {contracts.length === 0 && !isLoading && (
                    <Box padding={20} centered>
                        <Paragraph3 bold color={Color.BackgroundNeutral}>
                            No contracts found
                        </Paragraph3>
                    </Box>
                )}
                {isLoading ? (
                    <BlockLoader marginTop={20} />
                ) : (
                    hasNextPage && (
                        <Box marginTop={20} centered>
                            <Button type='secondary' label='Load more' onClick={onFetchNextPage} />
                        </Box>
                    )
                )}
            </SeparatedContainer>
        </>
    );
};

interface ContractTileProps {
    contract: ContractUnionDto;
    checked: boolean;
    warehouse?: WarehouseRefDto;
    onChange(id: UUID): void;
}

const ContractRadioTile = ({ contract, checked, warehouse, onChange }: ContractTileProps) => {
    const trContractType = useTrAnyContractType();

    const { items, isLoading } = useItems({
        ...emptyItemsFilters,
        filter: AdminItemFilter.AvailableForStoreAndReuse,
        variant: [contract.variant.id],
        warehouseId: warehouse?.id ?? null,
    });

    const months = contractRemainingMonths(contract);

    const itemsColor = isLoading || items.length > 0 ? Color.InteractionDefaultActive : Color.ContentNegative;

    return (
        <Box gap={10} row as='label' pointer>
            <Box width={20}>
                <Radio checked={checked} onChange={() => onChange(contract.id)} />
            </Box>
            <Box width={150}>
                <Paragraph3 color={Color.BackgroundInverted}>
                    <ContractLinkLabel id={contract} />
                </Paragraph3>
                <Paragraph4 marginBottom={10}>{contract.publicId}</Paragraph4>
                <StoreAndReuseBadge
                    type='collection'
                    isLocalRegion={
                        contract.rentalContract?.storeAndReuse?.collectToLocation ===
                        RentalStoreAndReuseLocation.Local
                    }
                    country={contract.rentalContract?.storeAndReuse?.country ?? null}
                />
            </Box>
            <ProductItem
                flex={4}
                image={contract.variant.image}
                label={contract.product.name}
                subLabel={formatVariant(contract.variant)}
            />

            <Box flex={1.5}>
                <Paragraph3 color={Color.ContentPrimary}>{trContractType(contract.type)}</Paragraph3>
                <Paragraph3 color={Color.ContentTertiary}>
                    {months ?? '--'} {months === 1 ? ' mo.' : ' mos.'} remaining
                </Paragraph3>
            </Box>
            <Box width={40} marginRight={5} row>
                <TooltipIcon
                    icon={SvgIcon.Home}
                    iconColor={itemsColor}
                    marginRight={5}
                    body={`Items at warehouse ${warehouse?.name ?? ''}`}
                    placement='bottom'
                />
                {isLoading ? (
                    <Spinner size={16} />
                ) : (
                    <Paragraph3 color={itemsColor}>{items.length}</Paragraph3>
                )}
            </Box>
        </Box>
    );
};
