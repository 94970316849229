import { Country } from '@hofy/global';

import { ContractUnionDto } from './ContractDto';
import { ContractType } from './ContractType';
import { RentalContractStatus } from './RentalContractStatus';

interface ContractAware {
    contract: ContractUnionDto | null;
}

export const getFirstActiveRentalContractCountry = (contractAware: ContractAware[]): Country | null => {
    return (
        contractAware.find(
            v =>
                v.contract?.type === ContractType.Rental &&
                v.contract.rentalContract.status === RentalContractStatus.Active,
        )?.contract?.country ?? null
    );
};
