import FlexSearch, { CreateOptions } from 'flexsearch';
import { useEffect, useRef, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

const DEFAULT_DELAY = 100;

interface ResultsOptions {
    delay?: number;
    limit?: number;
}

const defaultIndexOptions: CreateOptions = {
    encode: 'balance',
    tokenize: 'full',
    depth: 2,
    async: false,
};

/** @deprecated use `useSearch` from `@hofy/hooks` instead */
export const useSearch = <L extends any>(
    list: L[],
    extractText: (item: L) => string | string[],
    query: string,
    resultsOptions: ResultsOptions = {},
    indexOptions: CreateOptions = {},
) => {
    const { limit, delay = DEFAULT_DELAY } = resultsOptions;
    const [results, setResults] = useState(list);
    const searchRef = useRef(FlexSearch.create<number>());
    const search = searchRef.current;
    const flattenText = (v: string | string[]) => (Array.isArray(v) ? v.join(' ') : v);

    const runSearch = () => {
        if (query.trim()) {
            Promise.resolve(search.search({ query, limit })).then(indexes => {
                setResults(indexes.map((index: number) => list[index]));
            });
        } else if (list !== results) {
            setResults(list);
        }
    };

    useEffect(() => {
        search.init({
            ...defaultIndexOptions,
            ...indexOptions,
        });
        list.forEach((value, id) => {
            search.add(id, flattenText(extractText(value)));
        });
        runSearch();
        return () => {
            search.destroy();
        };
    }, [list]);

    useDebounce(runSearch, delay, [query]);

    return { results: query ? results : list };
};
