/*
 * This file was generated automatically by the script.
 * Do not edit it manually!
 */
import Adapter from './assets/Adapter.svg';
import Add from './assets/Add.svg';
import AddCircle from './assets/AddCircle.svg';
import AlarmClock from './assets/AlarmClock.svg';
import AlertCircle from './assets/AlertCircle.svg';
import AlertCircleFill from './assets/AlertCircleFill.svg';
import AlertTriangle from './assets/AlertTriangle.svg';
import Annotation from './assets/Annotation.svg';
import AnnotationAlert from './assets/AnnotationAlert.svg';
import Announcement from './assets/Announcement.svg';
import Archive from './assets/Archive.svg';
import ArrowDown from './assets/ArrowDown.svg';
import ArrowLeft from './assets/ArrowLeft.svg';
import ArrowRight from './assets/ArrowRight.svg';
import ArrowUp from './assets/ArrowUp.svg';
import Asterisk from './assets/Asterisk.svg';
import Attachment from './assets/Attachment.svg';
import BarChart02 from './assets/BarChart02.svg';
import BarChart from './assets/BarChart.svg';
import BarChartSquare from './assets/BarChartSquare.svg';
import Bell from './assets/Bell.svg';
import BellOff from './assets/BellOff.svg';
import BookClosed from './assets/BookClosed.svg';
import BookOpen from './assets/BookOpen.svg';
import Box from './assets/Box.svg';
import Browser from './assets/Browser.svg';
import Building2 from './assets/Building2.svg';
import Building3 from './assets/Building3.svg';
import Building from './assets/Building.svg';
import Cable from './assets/Cable.svg';
import Calendar from './assets/Calendar.svg';
import Chair from './assets/Chair.svg';
import Charger from './assets/Charger.svg';
import Check from './assets/Check.svg';
import CheckCircle from './assets/CheckCircle.svg';
import CheckCircleFill from './assets/CheckCircleFill.svg';
import CheckVerified from './assets/CheckVerified.svg';
import ChevronDown from './assets/ChevronDown.svg';
import ChevronLeft from './assets/ChevronLeft.svg';
import ChevronRight from './assets/ChevronRight.svg';
import ChevronSelectorHorizontal from './assets/ChevronSelectorHorizontal.svg';
import ChevronSelectorVertical from './assets/ChevronSelectorVertical.svg';
import ChevronUp from './assets/ChevronUp.svg';
import Circle from './assets/Circle.svg';
import Clipboard from './assets/Clipboard.svg';
import Clock from './assets/Clock.svg';
import Copy from './assets/Copy.svg';
import CreditCard from './assets/CreditCard.svg';
import Cross from './assets/Cross.svg';
import CrossCircle from './assets/CrossCircle.svg';
import CrossCircleFill from './assets/CrossCircleFill.svg';
import CurrencyDollar from './assets/CurrencyDollar.svg';
import Desk from './assets/Desk.svg';
import DeskRiser from './assets/DeskRiser.svg';
import DeskTidy from './assets/DeskTidy.svg';
import Desktop from './assets/Desktop.svg';
import DockingStation from './assets/DockingStation.svg';
import DollarSign from './assets/DollarSign.svg';
import Dongle from './assets/Dongle.svg';
import DotsGrid from './assets/DotsGrid.svg';
import DotsHorizontal from './assets/DotsHorizontal.svg';
import DotsVertical from './assets/DotsVertical.svg';
import Download from './assets/Download.svg';
import DownloadCircle from './assets/DownloadCircle.svg';
import Edit02 from './assets/Edit02.svg';
import Edit from './assets/Edit.svg';
import Expand from './assets/Expand.svg';
import Eye from './assets/Eye.svg';
import EyeOff from './assets/EyeOff.svg';
import File02 from './assets/File02.svg';
import File03 from './assets/File03.svg';
import File from './assets/File.svg';
import FileAttachment from './assets/FileAttachment.svg';
import FileCheck from './assets/FileCheck.svg';
import FileDownload from './assets/FileDownload.svg';
import Filter from './assets/Filter.svg';
import FilterLines from './assets/FilterLines.svg';
import Flag from './assets/Flag.svg';
import Folder from './assets/Folder.svg';
import FootRest from './assets/FootRest.svg';
import Gift from './assets/Gift.svg';
import Globe from './assets/Globe.svg';
import Headset from './assets/Headset.svg';
import Heart from './assets/Heart.svg';
import Help from './assets/Help.svg';
import Hofy from './assets/Hofy.svg';
import HofyToken from './assets/HofyToken.svg';
import Home from './assets/Home.svg';
import HorizontalBarChart from './assets/HorizontalBarChart.svg';
import Image from './assets/Image.svg';
import Inbox from './assets/Inbox.svg';
import InfoCircle from './assets/InfoCircle.svg';
import Key from './assets/Key.svg';
import Keyboard from './assets/Keyboard.svg';
import Laptop from './assets/Laptop.svg';
import LaptopStand from './assets/LaptopStand.svg';
import LayoutGrid from './assets/LayoutGrid.svg';
import LifeBuoy from './assets/LifeBuoy.svg';
import Lightbulb from './assets/Lightbulb.svg';
import LineChartUp from './assets/LineChartUp.svg';
import Link from './assets/Link.svg';
import LinkExternal from './assets/LinkExternal.svg';
import List from './assets/List.svg';
import Loading from './assets/Loading.svg';
import Lock from './assets/Lock.svg';
import LockUnlocked from './assets/LockUnlocked.svg';
import LogIn from './assets/LogIn.svg';
import LogOut from './assets/LogOut.svg';
import Mail from './assets/Mail.svg';
import MarkerPin from './assets/MarkerPin.svg';
import Maximize from './assets/Maximize.svg';
import Menu02 from './assets/Menu02.svg';
import Menu03 from './assets/Menu03.svg';
import Menu from './assets/Menu.svg';
import Message from './assets/Message.svg';
import MessageAlert from './assets/MessageAlert.svg';
import MessageChat from './assets/MessageChat.svg';
import MessageCheck from './assets/MessageCheck.svg';
import MessageDots from './assets/MessageDots.svg';
import Minimize from './assets/Minimize.svg';
import Minus from './assets/Minus.svg';
import Mobile from './assets/Mobile.svg';
import Monitor from './assets/Monitor.svg';
import MonitorArm from './assets/MonitorArm.svg';
import Mouse from './assets/Mouse.svg';
import Other from './assets/Other.svg';
import Package from './assets/Package.svg';
import PackageAdd from './assets/PackageAdd.svg';
import PackageCheck from './assets/PackageCheck.svg';
import PackageMinus from './assets/PackageMinus.svg';
import PackageRemove from './assets/PackageRemove.svg';
import PackageSearch from './assets/PackageSearch.svg';
import Percent from './assets/Percent.svg';
import Phone from './assets/Phone.svg';
import PieChart from './assets/PieChart.svg';
import Printer from './assets/Printer.svg';
import Recycle from './assets/Recycle.svg';
import Refresh from './assets/Refresh.svg';
import Replace from './assets/Replace.svg';
import Router from './assets/Router.svg';
import Rows from './assets/Rows.svg';
import Save from './assets/Save.svg';
import Scan from './assets/Scan.svg';
import Search from './assets/Search.svg';
import Send from './assets/Send.svg';
import Server from './assets/Server.svg';
import Settings2 from './assets/Settings2.svg';
import Settings from './assets/Settings.svg';
import Share02 from './assets/Share02.svg';
import Share from './assets/Share.svg';
import Shield from './assets/Shield.svg';
import ShoppingBag from './assets/ShoppingBag.svg';
import ShoppingCart from './assets/ShoppingCart.svg';
import Shredder from './assets/Shredder.svg';
import SlashDivider from './assets/SlashDivider.svg';
import StandingMat from './assets/StandingMat.svg';
import Star from './assets/Star.svg';
import Stars from './assets/Stars.svg';
import SwitchHorizontal from './assets/SwitchHorizontal.svg';
import SwitchVertical from './assets/SwitchVertical.svg';
import Table from './assets/Table.svg';
import Tablet from './assets/Tablet.svg';
import Tag from './assets/Tag.svg';
import TaskLight from './assets/TaskLight.svg';
import ThumbDown from './assets/ThumbDown.svg';
import ThumbUp from './assets/ThumbUp.svg';
import Tool from './assets/Tool.svg';
import Trash from './assets/Trash.svg';
import TrendDown from './assets/TrendDown.svg';
import TrendUp from './assets/TrendUp.svg';
import Trophy from './assets/Trophy.svg';
import Truck from './assets/Truck.svg';
import Undo from './assets/Undo.svg';
import Upload from './assets/Upload.svg';
import UploadCircle from './assets/UploadCircle.svg';
import User from './assets/User.svg';
import UserAdd from './assets/UserAdd.svg';
import UserCheck from './assets/UserCheck.svg';
import UserDown from './assets/UserDown.svg';
import UserEdit from './assets/UserEdit.svg';
import UserMinus from './assets/UserMinus.svg';
import UserRemove from './assets/UserRemove.svg';
import Users from './assets/Users.svg';
import Wallet from './assets/Wallet.svg';
import Warehouse from './assets/Warehouse.svg';
import Webcam from './assets/Webcam.svg';
import X from './assets/X.svg';
import Youtube from './assets/Youtube.svg';
import Zap from './assets/Zap.svg';

export const SvgIcon = {
    Adapter,
    Add,
    AddCircle,
    AlarmClock,
    AlertCircle,
    AlertCircleFill,
    AlertTriangle,
    Annotation,
    AnnotationAlert,
    Announcement,
    Archive,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowUp,
    Asterisk,
    Attachment,
    BarChart,
    BarChart02,
    BarChartSquare,
    Bell,
    BellOff,
    BookClosed,
    BookOpen,
    Box,
    Browser,
    Building,
    Building2,
    Building3,
    Cable,
    Calendar,
    Chair,
    Charger,
    Check,
    CheckCircle,
    CheckCircleFill,
    CheckVerified,
    ChevronDown,
    ChevronLeft,
    ChevronRight,
    ChevronSelectorHorizontal,
    ChevronSelectorVertical,
    ChevronUp,
    Circle,
    Clipboard,
    Clock,
    Copy,
    CreditCard,
    Cross,
    CrossCircle,
    CrossCircleFill,
    CurrencyDollar,
    Desk,
    DeskRiser,
    DeskTidy,
    Desktop,
    DockingStation,
    DollarSign,
    Dongle,
    DotsGrid,
    DotsHorizontal,
    DotsVertical,
    Download,
    DownloadCircle,
    Edit,
    Edit02,
    Expand,
    Eye,
    EyeOff,
    File,
    File02,
    File03,
    FileAttachment,
    FileCheck,
    FileDownload,
    Filter,
    FilterLines,
    Flag,
    Folder,
    FootRest,
    Gift,
    Globe,
    Headset,
    Heart,
    Help,
    Hofy,
    HofyToken,
    Home,
    HorizontalBarChart,
    Image,
    Inbox,
    InfoCircle,
    Key,
    Keyboard,
    Laptop,
    LaptopStand,
    LayoutGrid,
    LifeBuoy,
    Lightbulb,
    LineChartUp,
    Link,
    LinkExternal,
    List,
    Loading,
    Lock,
    LockUnlocked,
    LogIn,
    LogOut,
    Mail,
    MarkerPin,
    Maximize,
    Menu,
    Menu02,
    Menu03,
    Message,
    MessageAlert,
    MessageChat,
    MessageCheck,
    MessageDots,
    Minimize,
    Minus,
    Mobile,
    Monitor,
    MonitorArm,
    Mouse,
    Other,
    Package,
    PackageAdd,
    PackageCheck,
    PackageMinus,
    PackageRemove,
    PackageSearch,
    Percent,
    Phone,
    PieChart,
    Printer,
    Recycle,
    Refresh,
    Replace,
    Router,
    Rows,
    Save,
    Scan,
    Search,
    Send,
    Server,
    Settings,
    Settings2,
    Share,
    Share02,
    Shield,
    ShoppingBag,
    ShoppingCart,
    Shredder,
    SlashDivider,
    StandingMat,
    Star,
    Stars,
    SwitchHorizontal,
    SwitchVertical,
    Table,
    Tablet,
    Tag,
    TaskLight,
    ThumbDown,
    ThumbUp,
    Tool,
    Trash,
    TrendDown,
    TrendUp,
    Trophy,
    Truck,
    Undo,
    Upload,
    UploadCircle,
    User,
    UserAdd,
    UserCheck,
    UserDown,
    UserEdit,
    UserMinus,
    UserRemove,
    Users,
    Wallet,
    Warehouse,
    Webcam,
    X,
    Youtube,
    Zap,
} as const;

export type SvgIconType = keyof typeof SvgIcon;
