import { useAddressValidator } from '@hofy/address';
import { SupplierContactPayload, SupplierDetailDto, SupplierPayload } from '@hofy/api-admin';
import {
    AddressPayload,
    addressToPayloadWithDefault,
    AddressType,
    noCreditSupplierPaymentTerms,
    SupplierPaymentTerms,
    SupplierStatus,
    SupplierType,
} from '@hofy/api-shared';
import { allCountries, Country, Currency, Price, UUID, zeroUuid } from '@hofy/global';
import {
    isBlank,
    isRequired,
    isRequiredIf,
    useForm,
    useMultipartForm,
    validateArrayField,
    validator,
} from '@hofy/ui';

export interface Step1FormData {
    name: string;
    legalName: string | null;
    supplierType: SupplierType | null;
    supplierStatus: SupplierStatus;
    companyRegistrationNumber: string | null;
    taxRegistrationNumber: string | null;
    hofySubsidiary: UUID | null;
    notes: string | null;
    primaryCurrency: Currency | null;
    unlimitedCredit: boolean;
    creditLimit: Price | null;
    paymentTerm: SupplierPaymentTerms | null;
    countries: Country[];
    locatedIn: Country | null;
}

export interface Step2FormData {
    billingAddress: AddressPayload;
}

export interface Step3FormData {
    shippingAddress: AddressPayload;
}

export interface Step4FormData {
    contacts: SupplierContactPayload[];
}

const supplierContactValidator = validator<SupplierContactPayload>({
    name: isRequired('Name of contact is required'),
    lastname: isRequired('Last name of contact is required'),
    email: isRequiredIf(v => isBlank(v.phone), 'Email or phone is required'),
    phone: isRequiredIf(v => isBlank(v.email), 'Email or phone is required'),
});

export const useSupplierForm = (onSubmit: (v: SupplierPayload) => void, supplier?: SupplierDetailDto) => {
    const validateAddress = useAddressValidator();

    const creditLimitRequired = (v: Step1FormData) =>
        !v.unlimitedCredit && !!v.paymentTerm && !noCreditSupplierPaymentTerms.includes(v.paymentTerm);

    const step1 = useForm<Step1FormData>({
        initial: supplier
            ? {
                  name: supplier.name,
                  legalName: supplier.legalName,
                  supplierType: supplier.supplierType,
                  supplierStatus: supplier.supplierStatus,
                  companyRegistrationNumber: supplier.companyRegistrationNumber,
                  taxRegistrationNumber: supplier.taxRegistrationNumber,
                  hofySubsidiary: supplier.hofySubsidiary.id,
                  notes: supplier.notes,
                  primaryCurrency: supplier.currency,
                  unlimitedCredit: supplier.unlimitedCredit,
                  creditLimit: supplier.creditLimit,
                  paymentTerm: supplier.paymentTerm,
                  countries: supplier.countries,
                  locatedIn: supplier.billingAddress.country,
              }
            : {
                  name: '',
                  legalName: null,
                  supplierType: SupplierType.CoreGeography,
                  supplierStatus: SupplierStatus.Active,
                  companyRegistrationNumber: null,
                  taxRegistrationNumber: null,
                  hofySubsidiary: zeroUuid,
                  notes: null,
                  locatedIn: null,
                  primaryCurrency: Currency.GBP,
                  unlimitedCredit: false,
                  creditLimit: null,
                  paymentTerm: null,
                  countries: allCountries,
              },
        initialDeps: [supplier],
        validate: validator<Step1FormData>({
            name: isRequired('Supplier name is required'),
            legalName: isRequired('Legal name is required'),
            supplierType: isRequired('Supplier type is required'),
            hofySubsidiary: isRequired('Hofy subsidiary is required'),
            primaryCurrency: isRequired('Currency is required'),
            paymentTerm: isRequired('Payment term is required'),
            locatedIn: isRequired('Located in is required'),
            creditLimit: isRequiredIf(creditLimitRequired, 'Credit limit is required'),
        }),
    });
    const step2 = useForm<Step2FormData>({
        initial: {
            billingAddress: addressToPayloadWithDefault(
                supplier?.billingAddress || null,
                AddressType.Supplier,
            ),
        },
        initialDeps: [supplier],
        validate: validator<Step2FormData>({
            billingAddress: validateAddress,
        }),
    });
    const step3 = useForm<Step3FormData>({
        initial: {
            shippingAddress: addressToPayloadWithDefault(
                supplier?.shippingAddress || null,
                AddressType.Supplier,
            ),
        },
        initialDeps: [supplier],
        validate: validator<Step3FormData>({
            shippingAddress: validateAddress,
        }),
    });
    const step4 = useForm<Step4FormData>({
        initial: {
            contacts: supplier
                ? supplier.contacts.map(c => ({
                      email: c.email,
                      phone: c.phone,
                      role: c.role,
                      name: c.name,
                      lastname: c.lastname,
                  }))
                : [],
        },
        initialDeps: [supplier],
        validate: validator<Step4FormData>({
            contacts: validateArrayField<Step4FormData, 'contacts'>({
                selfRules: [],
                fieldsValidator: supplierContactValidator,
            }),
        }),
    });

    return useMultipartForm(
        {
            step1,
            step2,
            step3,
            step4,
        },
        {
            onSubmit: values => {
                const { locatedIn: _, ...step1 } = values.step1;
                onSubmit({
                    ...step1,
                    ...values.step2,
                    ...values.step3,
                    ...values.step4,
                } as SupplierPayload);
            },
        },
    );
};

export const useSupplierContactForm = (
    initial: SupplierContactPayload,
    onSuccess: (formValues: SupplierContactPayload) => void,
) => {
    return useForm<SupplierContactPayload>({
        initial: initial,
        onSubmit: onSuccess,
        validate: supplierContactValidator,
    });
};
