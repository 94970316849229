import React, { FC } from 'react';

import { BoxProps, LabeledSwitch } from '@hofy/ui';

interface SettingsSwitchProps extends BoxProps {
    label?: string;
    checked: boolean;
    isLoading?: boolean;
    onChange(v: boolean): void;
    disabled?: boolean;
}

export const SettingsSwitch: FC<SettingsSwitchProps> = ({
    label,
    checked,
    onChange,
    isLoading = false,
    disabled = false,
    ...boxProps
}) => {
    return (
        <LabeledSwitch
            label={label}
            checked={checked}
            onChange={isLoading ? undefined : onChange}
            disabled={disabled}
            {...boxProps}
        />
    );
};
