import { ColorDye } from '@hofy/global';

export enum ManagementContractStatus {
    Pending = 'pending',
    Active = 'active',
    Ended = 'ended',
    Cancelled = 'cancelled',
}

export const managementContractStatusColors: Record<ManagementContractStatus, ColorDye> = {
    [ManagementContractStatus.Pending]: 'orange',
    [ManagementContractStatus.Active]: 'green',
    [ManagementContractStatus.Ended]: 'blue',
    [ManagementContractStatus.Cancelled]: 'red',
};

export const timeOrderedContractStatus = [
    ManagementContractStatus.Pending,
    ManagementContractStatus.Cancelled,
    ManagementContractStatus.Active,
    ManagementContractStatus.Ended,
];
