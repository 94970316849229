import { createContext, useContext } from 'react';

import { VisualType } from '@hofy/global';

import { Optional } from '../../types';

export interface ToastItem {
    id: string;
    key?: string;
    title?: string;
    message: string;
    type: VisualType;
}

export type ToastItemWithoutId = Optional<ToastItem, 'id'>;

export interface ToastsContextType {
    toasts: ToastItem[];
    showToast(toast: ToastItemWithoutId): void;
    hideToast(id: string): void;
}

export const ToastsContext = createContext<ToastsContextType | null>(null);

export const useToastsContext = () => {
    return useContext(ToastsContext);
};
