import { VisualType } from '@hofy/global';

import { SvgIcon } from '../components/icon';

export const visualTypeIcon: Record<VisualType, Svg> = {
    informative: SvgIcon.InfoCircle,
    positive: SvgIcon.CheckCircle,
    warning: SvgIcon.AlertCircle,
    negative: SvgIcon.AlertCircle,
    neutral: SvgIcon.AlertCircle,
};
