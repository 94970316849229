import React, { FC } from 'react';

import { PurchaseOrderDetailsDto, purchaseOrderNeedsApproval } from '@hofy/api-admin';
import { Box, FormSection, Labeled } from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { NetsuiteStatusChip } from '../../../../components/domain/netsuite/NetsuiteStatusChip';
import { PurchaseOrderApprovals } from './components/PurchaseOrderApprovals';
import { PurchaseOrderDelivery } from './components/PurchaseOrderDelivery';
import { PurchaseOrderDetails } from './components/PurchaseOrderDetails';
import { PurchaseOrderFiles } from './components/PurchaseOrderFiles';
import { PurchaseOrderStatusHistory } from './components/PurchaseOrderStatusHistory';
import { PurchaseOrderStatusNotification } from './components/PurchaseOrderStatusNotification';

interface PurchaseOrderDetailsTabProps {
    purchaseOrder: PurchaseOrderDetailsDto;
}

export const PurchaseOrderDetailsTab: FC<PurchaseOrderDetailsTabProps> = ({ purchaseOrder }) => {
    const needsApproval = purchaseOrderNeedsApproval(purchaseOrder.status);
    const withFiles = purchaseOrder.purchaseOrderFiles.length > 0;

    return (
        <Box overflow='auto' fullHeight paddingHorizontal={40} paddingVertical={30} column gap={30}>
            {needsApproval && (
                <PurchaseOrderStatusNotification purchaseOrder={purchaseOrder} marginBottom={30} />
            )}
            <FormSection label='Details' marginBottom={40}>
                <PurchaseOrderDetails purchaseOrder={purchaseOrder} />
            </FormSection>

            <FormSection label='Delivery'>
                <PurchaseOrderDelivery purchaseOrder={purchaseOrder} />
            </FormSection>

            <FormSection label='Status history'>
                <PurchaseOrderStatusHistory purchaseOrder={purchaseOrder} />
            </FormSection>

            <FormSection label='Approval progress'>
                <PurchaseOrderApprovals purchaseOrder={purchaseOrder} />
            </FormSection>

            <FormSection label='Accounting'>
                <Labeled label='Netsuite' content={<NetsuiteStatusChip status={purchaseOrder.netsuite} />} />
            </FormSection>

            {withFiles && (
                <FormSection label='Files'>
                    <PurchaseOrderFiles purchaseOrder={purchaseOrder} />
                </FormSection>
            )}

            <AuditSection model={purchaseOrder} />
        </Box>
    );
};
