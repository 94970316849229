import { getEnumValues } from '@hofy/global';

export enum WebhookMessageType {
    AssetLocationUpdated = 'asset.location-updated.v1',
    AssetCreated = 'asset.created.v1',
    AssetArchived = 'asset.archived.v1',
    UserCreated = 'user.created.v1',
    UserOffboarded = 'user.offboarded.v1',
    UserUpdated = 'user.updated.v1',
    OrderCreated = 'order.created.v1',
    OrderUpdated = 'order.updated.v1',
}

export const allWebhookMessageTypes = getEnumValues<WebhookMessageType>(WebhookMessageType);
