import { useAddressValidator } from '@hofy/address';
import { ShipmentCourierPayload, ShipmentDetailsDto, UpdateShipmentPayload } from '@hofy/api-admin';
import {
    addressToOptionalPayload,
    AddressType,
    isShipmentFromHofyWarehouse,
    isShipmentFromUser,
    isShipmentFromUserToUser,
    isShipmentToHofyWarehouse,
    isShipmentToUser,
    ShipmentStatus,
} from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { errorMap, isDateInPast, isEmpty, isNotEmpty, isValidHTTPSURL } from '@hofy/helpers';
import { isRequiredIf, isValidHttpsURL, useForm, validator } from '@hofy/ui';

import { useUpdateShipmentMutation } from './useUpdateShipmentMutation';

export const validateScheduledOn = (scheduledOn: DateString | null) => {
    let dateInPast = scheduledOn ? isDateInPast(scheduledOn) : false;

    return errorMap([dateInPast, 'Scheduled date must be at least on day after tomorrow']);
};

export const validateShipmentCourierForm = (formState: ShipmentCourierPayload) => {
    const { courier } = formState;
    return {
        courier: errorMap([isEmpty(courier), 'Courier is required']),
        ...validateCourierDetails(formState),
    };
};

const validateCourierDetails = (formState: ShipmentCourierPayload) => {
    const { courierReference, trackingLink } = formState;
    return {
        courierReference: errorMap([
            isEmpty(courierReference) && isNotEmpty(trackingLink),
            'Courier reference must be set with tracking link',
        ]),
        trackingLink: errorMap(
            [
                isEmpty(trackingLink) && isNotEmpty(courierReference),
                'Tracking link must be set with courier reference',
            ],
            [
                isNotEmpty(trackingLink) && !isValidHTTPSURL(trackingLink),
                'Tracking link must begin with https://',
            ],
        ),
    };
};

export const useUpdateShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const mutation = useUpdateShipmentMutation(shipment.id, onSuccess);
    const validateAddress = useAddressValidator();
    const scheduledOnRequired =
        !isShipmentFromUserToUser(shipment) && shipment.status === ShipmentStatus.Booked;
    const validateScheduledOn = (p: DateString | null) =>
        p && scheduledOnRequired && isDateInPast(p)
            ? 'Scheduled date must be at least on day after tomorrow'
            : undefined;
    const form = useForm<UpdateShipmentPayload>({
        initial: mapShipmentToPayload(shipment),
        onSubmit: mutation.mutate,
        validate: validator<UpdateShipmentPayload>({
            estimateOn: isRequiredIf(() => shipment.status === ShipmentStatus.Booked, 'Select date'),
            warehouseId: isRequiredIf(() => isShipmentFromHofyWarehouse(shipment), 'Warehouse is required'),
            scheduledOn: validateScheduledOn,
            fromAddress: validateAddress,
            toAddress: validateAddress,
            trackingLink: [
                isRequiredIf(p => !!p.courierReference, 'Tracking link is required'),
                isValidHttpsURL('Tracking link must be valid HTTPS Url'),
            ],
            courierReference: isRequiredIf(p => !!p.trackingLink, 'Courier reference is required'),
        }),
    });
    return {
        form,
        isLoading: mutation.isPending,
    };
};

export const mapShipmentToPayload = (shipment: ShipmentDetailsDto): UpdateShipmentPayload => {
    const fromAddress = addressToOptionalPayload(
        shipment.fromAddress,
        isShipmentFromUser(shipment) ? AddressType.Delivery : AddressType.Billing,
    );
    const toAddress = addressToOptionalPayload(
        shipment.toAddress,
        isShipmentToUser(shipment) ? AddressType.Delivery : AddressType.Billing,
    );

    return {
        fromAddress,
        toAddress,
        assignedUserId: shipment.assignedUser?.id,
        courier: shipment.courier || null,
        trackingLink: shipment.trackingLink || null,
        shipmentClass: shipment.shipmentClass || null,
        courierReference: shipment.courierReference || null,
        estimatedAvailability: shipment.estimatedAvailability,
        estimateOn: shipment.estimateOn,
        warehouseId: getHofyWarehouseIdFromShipment(shipment),
        scheduledOn: shipment.scheduledOn,
        latestWithCourierOn: shipment.latestWithCourierOn,
        sendNotification: false,
        purchaseOrderId: shipment.purchaseOrder?.id,
        numberOfBoxes: shipment.numberOfBoxes || 1,
    };
};

const getHofyWarehouseIdFromShipment = (shipment: ShipmentDetailsDto) => {
    if (isShipmentFromHofyWarehouse(shipment)) {
        return shipment.fromWarehouse?.id ?? null;
    }
    if (isShipmentToHofyWarehouse(shipment)) {
        return shipment.toWarehouse?.id ?? null;
    }
    return null;
};
