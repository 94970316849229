import React, { FC } from 'react';

import { allEmailTypes, EmailType, useTrEmailType } from '@hofy/api-shared';
import { CheckboxGroup, OuterBoxProps } from '@hofy/ui';

interface EmailTypePickerProps extends OuterBoxProps {
    value: EmailType[];
    disabled?: EmailType[];
    onChange(v: EmailType[]): void;
}

export const EmailTypePicker: FC<EmailTypePickerProps> = ({ onChange, value, disabled, ...rest }) => {
    const trEmailType = useTrEmailType();
    return (
        <CheckboxGroup
            options={allEmailTypes}
            onChange={onChange}
            value={value}
            toKey={type => type}
            toLabel={trEmailType}
            disabledOptions={disabled}
            horizontal
            {...rest}
        />
    );
};
