import React, { FC } from 'react';

import { HofyWarehouseDetailsDto, StockLevelCountsDto } from '@hofy/api-admin';
import { AssignmentStatus } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Icon, SvgIcon, Tooltip } from '@hofy/ui';

import { StockLevelTooltip } from '../inventoryPage/stockLevels/components/StockLevelTooltip';

interface ItemStockDetailsProps {
    iconSize?: number;
    stockDetailsData: ItemStockDetailData;
}

export interface ItemStockDetailData {
    assignmentStatus?: AssignmentStatus;
    warehouse: HofyWarehouseDetailsDto | null;
    stockLevelCounts: StockLevelCountsDto | null;
    sku: string | null;
    usableAsLoaner: boolean | null;
    isRedistribution: boolean | null;
}

export const ItemStockDetails: FC<ItemStockDetailsProps> = ({ iconSize = 12, stockDetailsData }) => {
    const colorScheme =
        stockDetailsData.stockLevelCounts &&
        stockDetailsData.stockLevelCounts.available < stockDetailsData.stockLevelCounts.minQuantity
            ? stockDetailsData.stockLevelCounts.available > 0
                ? Color.FoundationWarning
                : Color.FoundationNegative
            : Color.FoundationPositive;
    return (
        <Tooltip
            bodySlot={
                stockDetailsData.sku &&
                stockDetailsData.stockLevelCounts && (
                    <StockLevelTooltip
                        sku={stockDetailsData.sku}
                        stockLevel={stockDetailsData.stockLevelCounts}
                    />
                )
            }
            maxWidth='auto'
            asChild
        >
            <Box>
                <Icon size={iconSize} svg={SvgIcon.Box} color={colorScheme} />
            </Box>
        </Tooltip>
    );
};
