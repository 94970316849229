import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { purchaseOrderService } from '../service/purchaseOrderService';
import { purchaseOrdersCacheKey } from './cacheKey';

export const usePurchaseOrderQuery = (id: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [purchaseOrdersCacheKey, 'purchase-order', id],

        queryFn: () => purchaseOrderService.getPurchaseOrder(id),
    });

    return {
        data,
        isLoading,
    };
};
