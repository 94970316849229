export const productStyleAndSizeSelectionAlertI18n = {
    'product-style-and-size-selection-alert.select-style-and-size.price':
        'Select style and size for exact price',
    'product-style-and-size-selection-alert.select-style.price': 'Select style for exact price',
    'product-style-and-size-selection-alert.select-size.price': 'Select size for exact price',

    'product-style-and-size-selection-alert.select-style-and-size.credits':
        'Select style and size for exact credits',
    'product-style-and-size-selection-alert.select-style.credits': 'Select style for exact credits',
    'product-style-and-size-selection-alert.select-size.credits': 'Select size for exact credits',
};
