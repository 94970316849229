export const genericI18n = {
    'auth.no-account': "Don't have an account?",
    'auth-page.already-have-an-account': 'Already have an account?',
    'auth-page.dont-have-an-account': 'Don’t have a Hofy account?',
    'auth.sign-up-now': 'Sign up',
    'auth-page.footer.sign-in': 'Sign in',
    'auth.brokering-form.continue': 'Next',
    'auth.brokering-form.email': 'Email',
    'auth.form.email.errors.not-empty': 'Email is required',
    'auth.form.email.errors.not-valid': 'Email must be valid',
    'auth.sign-in-with-google': 'Sign in with Google',
    'auth.sign-in-with-microsoft': 'Sign in with Microsoft',
    'auth.sign-up-with-google': 'Sign up with Google',
    'auth.sign-up-with-microsoft': 'Sign up with Microsoft',
    'auth.or': 'or',
};
