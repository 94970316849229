import React, { FC } from 'react';

import { AssignmentItemDto } from '@hofy/api-admin';
import { ItemGrade, ItemStatus } from '@hofy/api-shared';
import { Box, FormGridRow, Labeled, LabeledText } from '@hofy/ui';

import { ItemGradeChip } from '../../../components/domain/items/ItemGradeChip';
import { ItemStatusChip } from '../../../components/domain/items/ItemStatusChip';
import { LabeledItemLink } from '../../itemsPage/ItemDetailsLink';
import { LabeledItemCodes } from '../../itemsPage/LabeledItemCodes';

interface ItemDetailsProps {
    item: AssignmentItemDto;
    deliveredGrade: ItemGrade | null;
    collectedGrade: ItemGrade | null;
}

export const ItemDetails: FC<ItemDetailsProps> = ({ item, deliveredGrade, collectedGrade }) => {
    return (
        <FormGridRow columns={4}>
            {item.status === ItemStatus.Active && (
                <Labeled flex={1} label='Current grade' content={<ItemGradeChip grade={item.grade} />} />
            )}
            {item.status !== ItemStatus.Active && (
                <Box flex={1}>
                    <Labeled label='Status' content={<ItemStatusChip status={item.status} />} />
                </Box>
            )}
            <LabeledItemLink item={item} />
            <LabeledText label='Serial number' content={item.serialNumber} />
            <LabeledItemCodes label='Item codes' itemCodes={item.itemCodes} />
            {deliveredGrade && (
                <Labeled
                    flex={1}
                    label='Delivered grade'
                    content={<ItemGradeChip grade={deliveredGrade} />}
                />
            )}
            {collectedGrade && (
                <Labeled
                    flex={1}
                    label='Collection grade'
                    content={<ItemGradeChip grade={collectedGrade} />}
                />
            )}
            <LabeledText label='Ownership' content={item.ownership.name} />
        </FormGridRow>
    );
};
