import React, { FC, ReactNode } from 'react';

import { Box, SectionTitle1 } from '../base';
import { InfoTooltipIcon } from '../tooltip';

export interface SummaryInnerContentProps {
    title: string;
    tooltip?: string;
    children?: ReactNode;
}

export const SummaryInnerContent: FC<SummaryInnerContentProps> = ({ title, tooltip, children }) => {
    return (
        <>
            <Box row gap={8}>
                <SectionTitle1>{title}</SectionTitle1>
                {tooltip && <InfoTooltipIcon body={tooltip} />}
            </Box>
            {children}
        </>
    );
};
