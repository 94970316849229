import React from 'react';

import { DateRangeStrings } from '@hofy/global';
import { LabeledDateRangeInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../../../components/design/blockFilters/BlockFilter';

interface InvoicesDateBlockFilterProps {
    invoiceTime: DateRangeStrings | null;
    onChangeInvoiceTime(range: DateRangeStrings): void;
}

export const InvoicesDateBlockFilter = ({
    invoiceTime = null,
    onChangeInvoiceTime,
}: InvoicesDateBlockFilterProps) => {
    return (
        <BlockFilter title='Date' icon={SvgIcon.Calendar}>
            <LabeledDateRangeInput
                label='Invoice time'
                value={invoiceTime}
                onChange={onChangeInvoiceTime}
                nullable
                marginLeft={12}
                marginTop={8}
            />
        </BlockFilter>
    );
};
