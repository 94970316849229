import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { queryOptionalStringParser } from '@hofy/router';
import { FilterDefinitionSingle } from '@hofy/ui';

interface DateFilterDefinitionOptions {
    name?: string;
    showInActive?: boolean;
}

export const useDateFilterDefinition = ({
    name,
    showInActive,
}: DateFilterDefinitionOptions): FilterDefinitionSingle<DateString | null> => {
    return {
        type: 'single',
        name: name,
        query: queryOptionalStringParser(),
        toLabel: formatDate,
        showInActive,
    };
};
