import { getEnumValues } from '@hofy/global';

export enum InspectionTab {
    Details = 'details',
    Inspection = 'inspection',
    DeviceCheck = 'device-check',
    Notes = 'notes',
}

export const inspectionTabLabels: Record<InspectionTab, string> = {
    [InspectionTab.Details]: 'Details',
    [InspectionTab.Inspection]: 'Inspection',
    [InspectionTab.DeviceCheck]: 'Device check',
    [InspectionTab.Notes]: 'Notes',
};

export const allInspectionTabs = getEnumValues<InspectionTab>(InspectionTab);

export const getInspectionTabs = ({ isDeviceCheckRequired }: { isDeviceCheckRequired: boolean }) => {
    return isDeviceCheckRequired
        ? [InspectionTab.Details, InspectionTab.Inspection, InspectionTab.DeviceCheck, InspectionTab.Notes]
        : [InspectionTab.Details, InspectionTab.Inspection, InspectionTab.Notes];
};
