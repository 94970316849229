import { apiErrorNames, useTrApiError } from '@hofy/api-admin';
import { isAuthError } from '@hofy/api-auth';
import { useTrAuthError } from '@hofy/auth';
import { useToast } from '@hofy/ui';

interface ErrorResponse {
    message: string | null;
    code: string;
}

interface Error {
    name: string;
    response: ErrorResponse | null;
}

const isResponseError = (arg: any): arg is Error => {
    return arg.name === 'ResponseError';
};

export const useErrorHandler = () => {
    const { showErrorToast, showToast } = useToast();
    const { trMessage, trTitle } = useTrApiError();
    const trAuthError = useTrAuthError();

    return (error?: unknown) => {
        if (!isResponseError(error) || !error?.response?.code) {
            showErrorToast();
            return;
        }
        const code = error?.response?.code;

        if (isAuthError(code)) {
            showToast({
                message: trAuthError(code as any),
                type: 'negative',
            });
            return;
        }

        if (apiErrorNames[code]) {
            showToast({
                title: trTitle(code as any),
                message: trMessage(code as any),
                type: 'negative',
            });
            return;
        }
        showErrorToast();
    };
};
