import { getEnumValues } from '@hofy/global';

export enum ContractTab {
    Details = 'details',
    Schedule = 'schedule',
    ServicePackages = 'service-packages',
    InvoiceEntries = 'entries',
    Assignments = 'assignments',
    RentalContracts = 'rental-contracts',
    Addons = 'addons',
    RepaymentPlans = 'repayment-plans',
    StoreAndReuse = 'store-and-reuse',
    StorageAssignments = 'storage-assignments',
}

export const rentalContractTabs = getEnumValues<ContractTab>(ContractTab);
export const purchaseContractTabs = [
    ContractTab.Details,
    ContractTab.ServicePackages,
    ContractTab.InvoiceEntries,
    ContractTab.Assignments,
    ContractTab.Addons,
    ContractTab.RepaymentPlans,
    ContractTab.StoreAndReuse,
    ContractTab.StorageAssignments,
];

export const managementContractTabs = [
    ContractTab.Details,
    ContractTab.ServicePackages,
    ContractTab.InvoiceEntries,
    ContractTab.Assignments,
    ContractTab.Addons,
    ContractTab.RepaymentPlans,
    ContractTab.StoreAndReuse,
    ContractTab.StorageAssignments,
];
