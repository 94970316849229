import React from 'react';

import { useBaseI18n } from '@hofy/i18n';

import { SvgIllustration } from '../../illustration';
import { Placeholder } from './Placeholder';

export const ErrorStatePlaceholder = () => {
    const { tr } = useBaseI18n();
    return (
        <Placeholder
            illustration={SvgIllustration.Error}
            title={tr('ui.error.title')}
            message={tr('ui.error.message')}
        />
    );
};
