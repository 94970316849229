import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { itemEventService } from '../service/itemEventService';
import { ItemValuationFilters } from '../service/types/ItemValuationFilters';
import { itemValuationListCacheKey } from './cacheKey';

export const useItemValuationListQuery = (filters: ItemValuationFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [itemValuationListCacheKey, filters],
        queryFn: param =>
            itemEventService.listItemValuation(filters, {
                page: param.pageParam,
                pageSize: 30,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const itemValuations = useMemo(() => {
        if (data === undefined) {
            return [];
        }
        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        itemValuations,
        itemValuationIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
