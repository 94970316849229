import React, { FC } from 'react';

import { Phone } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { Optional } from '../../../../types/Utils';
import { LabeledPhoneInput, LabeledPhoneInputProps } from './LabeledPhoneInput';

type FormPhoneInputOptionalProps = Optional<LabeledPhoneInputProps, 'onChange'>;
type FormPhoneInputOmittedProps = Omit<FormPhoneInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalPhoneInputProps extends FormPhoneInputOmittedProps {
    api: FormFieldApi<Phone>;
    nullable?: false;
}

interface FormNullablePhoneInputProps extends FormPhoneInputOmittedProps {
    api: FormFieldApi<Phone | null>;
    nullable: true;
}

type FormPhoneInputProps = FormNormalPhoneInputProps | FormNullablePhoneInputProps;

export const FormPhoneInput: FC<FormPhoneInputProps> = ({
    api,
    onChange,
    onBlur,
    initialCountry,
    ...inputProps
}) => {
    return (
        <LabeledPhoneInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            initialCountry={initialCountry}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
