import { useQuery } from '@tanstack/react-query';

import { Country } from '@hofy/global';

import { fulfilmentCountrySettingService } from '../service/FulfilmentCountrySettingService';
import { fulfilmentCountrySettingCacheKey } from './fulfilmentCountrySettingCacheKey';

export const useFulfilmentCountrySettingQuery = (country: Country) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [fulfilmentCountrySettingCacheKey, country],
        queryFn: () => fulfilmentCountrySettingService.getCountrySettingByCountry(country),
    });

    return {
        isLoading,
        isError,
        data,
    };
};
