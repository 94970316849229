import { ColorDye, getEnumValues } from '@hofy/global';

export enum ActivityLogSource {
    Items = 'items',
}

export const allActivityLogSources = getEnumValues<ActivityLogSource>(ActivityLogSource);

export const activityLogSourceColor: Record<ActivityLogSource, ColorDye> = {
    [ActivityLogSource.Items]: 'blue',
};
