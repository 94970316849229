import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { ReactNode, useMemo } from 'react';

import { ReactNodeFunction, renderChildren, TestKeyAware } from '../../types';
import { Box } from '../base';
import { useInteractiveListSearch } from './hooks/useInteractiveListSearch';
import { DropdownContentScrollable } from './shared/DropdownContentScrollable';
import { DropdownInteractiveListItem } from './shared/DropdownInteractiveListItem';
import { DropdownSearchInput } from './shared/DropdownSearchInput';
import { DropdownTrigger } from './shared/DropdownTrigger';
import { DropdownTriggerLabel } from './shared/DropdownTriggerLabel';
import {
    InteractiveListBase,
    NormalInteractiveList,
    NullableInteractiveList,
} from './types/InteractiveListTypes';

interface DropdownListSearchPropsBaseProps<T> extends InteractiveListBase<T>, TestKeyAware {
    trigger?: ReactNode | ReactNodeFunction<[isOpen: boolean]>;
    /** Only used if trigger is not provided */
    triggerPlaceholder?: string;
    /**
     * Allows pass any element as the trigger without the wrapper
     * WARNING: element must accept `ref` (native element like `div` or component with `forwardRef`)
     * */
    asChild?: boolean;

    fixedOptions?: NonNullable<T>[];

    searchPlaceholder?: string;
    contentWidth?: string | number | 'auto';

    onFocus?(): void;
    onBlur?(): void;
}

export interface NormalDropdownListSearchProps<T>
    extends DropdownListSearchPropsBaseProps<T>,
        NormalInteractiveList<T> {}
export interface NullableDropdownListSearchProps<T>
    extends DropdownListSearchPropsBaseProps<T>,
        NullableInteractiveList<T> {}

export type DropdownListSearchProps<T> =
    | NormalDropdownListSearchProps<T>
    | NullableDropdownListSearchProps<T>;

export const DropdownListSearch = <T,>({
    trigger,
    triggerPlaceholder,
    asChild,

    fixedOptions,
    searchPlaceholder,
    contentWidth,
    contentMaxHeight,
    placement,

    value,
    options,
    onChange,
    toText,
    toKey,
    toLabel,
    toSelectedLabel,
    disabled: selectDisabled,
    nullable,
    testKey,
}: DropdownListSearchProps<T>) => {
    const allOptions = useMemo(() => {
        if (fixedOptions) {
            return [...fixedOptions, ...options];
        }
        return options;
    }, [fixedOptions, options]);

    const state = useInteractiveListSearch({
        options: allOptions,
        toText,
        toKey,
        toLabel,
        toSelectedLabel,
        value,
        onChange,
        disabled: selectDisabled,
        nullable,
        contentWidth,
        contentMaxHeight,
        placement,
    });

    const {
        context,
        isOpen,
        activeIndex,
        selectedIndex,
        refs,
        floatingStyles,
        listRef,

        inputProps,
        itemProps,
        floatingProps,
        referenceProps,

        getKey,
        getLabel,
        getSelectedLabel,

        search,
        handleSearch,
        searchResults,
        resultantPlacement,
        disabled,
    } = state;

    const renderItem = (item: NonNullable<T>, index: number) => {
        return (
            <DropdownInteractiveListItem
                key={getKey(item)}
                ref={node => {
                    listRef.current[index] = node;
                }}
                label={getLabel(item)}
                isActive={index === activeIndex}
                isSelected={index === selectedIndex}
                testKey={testKey && `${testKey}--${getKey(item)}`}
                {...itemProps(item)}
            />
        );
    };

    const indexOffset = fixedOptions?.length ?? 0;

    const renderTrigger = () => {
        if (trigger) {
            return renderChildren(trigger, isOpen);
        }
        return (
            <DropdownTriggerLabel
                label={getSelectedLabel(value) || triggerPlaceholder}
                isOpen={isOpen}
                disabled={disabled}
            />
        );
    };

    return (
        <>
            <DropdownTrigger
                referenceProps={referenceProps}
                setReference={refs.setReference}
                asChild={trigger ? asChild : true}
                testKey={testKey}
            >
                {renderTrigger()}
            </DropdownTrigger>

            <AnimatePresence>
                {isOpen && (
                    <FloatingPortal>
                        <FloatingFocusManager context={context} modal={false}>
                            <DropdownContentScrollable
                                ref={refs.setFloating}
                                style={floatingStyles}
                                floatingProps={floatingProps()}
                                placement={resultantPlacement}
                                topSlot={
                                    <Box column>
                                        <Box padding={4} marginBottom={8}>
                                            <DropdownSearchInput
                                                value={search}
                                                onChange={handleSearch}
                                                placeholder={searchPlaceholder}
                                                inputRawProps={inputProps()}
                                            />
                                        </Box>
                                        {!searchResults && fixedOptions && (
                                            <Box borderBottom padding={4} marginBottom={4}>
                                                {fixedOptions.map((item, index) => renderItem(item, index))}
                                            </Box>
                                        )}
                                    </Box>
                                }
                            >
                                {searchResults
                                    ? searchResults.map((item, index) => renderItem(item, index))
                                    : options.map((item, index) => renderItem(item, index + indexOffset))}
                            </DropdownContentScrollable>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};
