import React, { FC } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { APP_CONFIG } from '@hofy/config';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Spinner } from '@hofy/ui';

import { useAuditLogMenuOption } from '../../../store/auditLogs/useAuditMenuOption';
import { useAdminSyncProductToNetsuite } from '../../../store/products/useAdminSyncProductToNetsuite';

interface ProductDetailsMenuProps {
    productId: UUID | undefined;
}

export const ProductDetailsMenu: FC<ProductDetailsMenuProps> = ({ productId }) => {
    const { hasPermission } = usePermission();
    const [auditLink] = useAuditLogMenuOption(AuditableTable.Product, productId);

    const copyProductLink = {
        action: () => {
            const url = `${APP_CONFIG.hofyUrl!}/t/webstore/products/${productId}`;
            window.navigator.clipboard.writeText(url);
        },
        label: `Copy link to webstore`,
        testKey: 'copy-product-view',
        visible: productId !== undefined,
    };
    const { syncToNetsuite, isLoading } = useAdminSyncProductToNetsuite();

    if (isLoading) {
        return <Spinner size={16} />;
    }

    const menuItems = [copyProductLink, auditLink];
    if (productId) {
        menuItems.push({
            action: () => syncToNetsuite(productId),
            label: 'Sync to Netsuite',
            visible: hasPermission(Permission.AdminAccountingNonTransactionalSync),
        });
    }

    return <MoreMenu items={menuItems} />;
};
