import React, { FC } from 'react';

import { ProductCategory } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, BoxProps, FeaturedIcon, Paragraph4, SectionTitle3, Span } from '@hofy/ui';

import { productCategoryIcons } from './productCategoryIcons';

interface ProductCellProps extends BoxProps {
    product: {
        images?: { url: string; isActive: boolean }[];
        name: string;
        brand: string;
        category: ProductCategory;
    };
}

export const ProductCell: FC<ProductCellProps> = ({ product, ...rest }) => {
    return (
        <Box flex={1} row data-test-key='product-cell' {...rest}>
            {!product.images ? (
                <FeaturedIcon
                    icon={productCategoryIcons[product.category]}
                    shape='square'
                    type='informative'
                    size={40}
                />
            ) : (
                <ProductImage image={product.images} size={60} category={product.category} />
            )}
            <Box flex={1} marginLeft={16} paddingRight={24}>
                <SectionTitle3>{product.name}</SectionTitle3>
                <Paragraph4 color={Color.ContentSecondary} paddingTop={4} data-test-key='product-brand'>
                    <Span color={Color.ContentTertiary}>By</Span> {product.brand}
                </Paragraph4>
            </Box>
        </Box>
    );
};
