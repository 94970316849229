import React, { FC } from 'react';

import { ShipmentCollectionRequestUpdateTrackingDataAction } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { ConfirmModal, FormInput } from '@hofy/ui';

import { useUpdatePackagingTrackingDataForCollectionRequest } from '../../../../../store/collectionRequests/useUpdatePackagingTrackingDataForCollectionRequest';

interface PackagingTrackingDataModalProps {
    shipmentId: UUID;
    collectionRequestId: UUID;
    trackingData: ShipmentCollectionRequestUpdateTrackingDataAction;
    onClose(): void;
}

export const PackagingTrackingDataModal: FC<PackagingTrackingDataModalProps> = ({
    shipmentId,
    collectionRequestId,
    trackingData,
    onClose,
}) => {
    const { form, isLoadingMutation: isUpdatePackagingTrackingDataInProgress } =
        useUpdatePackagingTrackingDataForCollectionRequest(
            shipmentId,
            collectionRequestId,
            trackingData,
            onClose,
        );
    return (
        <ConfirmModal
            keyPrefix='update-packaging-tracking-data-modal'
            width={600}
            onClose={onClose}
            closeOnConfirm={false}
            onConfirm={form.submit}
            isLoading={isUpdatePackagingTrackingDataInProgress}
        >
            <FormInput
                marginVertical={10}
                label='Tracking reference:'
                api={form.fields.packagingTrackingReference}
            />
            <FormInput marginVertical={10} label='Tracking link:' api={form.fields.packagingTrackingLink} />
        </ConfirmModal>
    );
};
