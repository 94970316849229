import React, { FC, ReactNode } from 'react';

import { Color, NumberValues } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Box, OuterBoxProps } from '../../base';

interface PageContentProps extends OuterBoxProps, TestKeyAware {
    children?: ReactNode;
    tabsSlot?: ReactNode;
    footerSlot?: ReactNode;
    bottomSafeArea?: boolean;
    withTable?: boolean;
}

const defaultSafeArea = 74 as NumberValues;

export const PageContent: FC<PageContentProps> = ({
    children,
    tabsSlot,
    footerSlot,
    testKey,
    bottomSafeArea,
    withTable = false,
    ...rest
}) => {
    return (
        <Box
            column
            flex='auto'
            bg={Color.BackgroundDefault}
            data-test-key={testKey}
            paddingBottom={bottomSafeArea ? defaultSafeArea : 0}
        >
            {tabsSlot && (
                <Box paddingHorizontal='mainMarginHorizontal' paddingTop={16} borderBottom>
                    {tabsSlot}
                </Box>
            )}
            <Box
                paddingHorizontal={withTable ? 0 : 'mainMarginHorizontal'}
                paddingVertical={withTable ? 0 : 'mainMarginVertical'}
                flex='auto'
                overflow='auto'
                {...rest}
            >
                {children}
            </Box>
            {footerSlot && (
                <Box paddingHorizontal='mainMarginHorizontal' paddingVertical={16} borderTop>
                    {footerSlot}
                </Box>
            )}
        </Box>
    );
};
