import { ShipmentDetailsDto, UpdateShipmentPayload } from '@hofy/api-admin';
import { useForm } from '@hofy/ui';

import { mapShipmentToPayload } from './useUpdateShipment';
import { useUpdateShipmentMutation } from './useUpdateShipmentMutation';

export const useUpdateShipmentPurchaseOrder = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const mutation = useUpdateShipmentMutation(shipment.id, onSuccess);

    const form = useForm<Partial<UpdateShipmentPayload>>({
        initial: {
            purchaseOrderId: shipment.purchaseOrder?.id,
        },
        onSubmit: ({ purchaseOrderId }) => {
            mutation.mutate({ ...mapShipmentToPayload(shipment), purchaseOrderId });
        },
    });
    return {
        form,
        isLoading: mutation.isPending,
    };
};
