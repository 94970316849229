import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { Crop, ReactCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, IconButton, Modal, ModalContent, ModalFooter, SvgIcon } from '@hofy/ui';

interface ImageCropModalProps {
    img: string;
    onClose(): void;
    onCreate(v: string): void;
}

export const ImageCropModal: FC<ImageCropModalProps> = ({ img, onClose, onCreate }) => {
    const [crop, setCrop] = useState<Crop>({ unit: '%', width: 100, height: 100, x: 0, y: 0 });
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const onCreateCrop = () => {
        if (canvasRef.current && imageRef.current && completedCrop) {
            onCreate(getImage(imageRef.current, canvasRef.current, completedCrop as Required<Crop>));
        }
    };

    const onImageLoad = (event: SyntheticEvent) => {
        if (event.currentTarget) {
            (imageRef as any).current = event.currentTarget;
            const min = Math.min((imageRef as any).current.width, (imageRef as any).current.height);
            setCompletedCrop({ unit: 'px', width: min, height: min, x: 0, y: 0 });
            setCrop({ unit: 'px', width: min, height: min, x: 0, y: 0 });
        }
    };

    return (
        <Modal onClose={onClose}>
            <ModalContent>
                <Box bg={Color.BackgroundInverted} padding={16} rounded={8}>
                    <ReactCrop crop={crop} onChange={setCrop} onComplete={setCompletedCrop} aspect={1}>
                        <img src={img} onLoad={onImageLoad} alt='' />
                    </ReactCrop>
                </Box>
                <Canvas ref={canvasRef} />
            </ModalContent>
            <ModalFooter borderTop centered>
                <IconButton size={32} onClick={onClose} icon={SvgIcon.Cross} />
                <IconButton size={32} marginLeft={16} onClick={onCreateCrop} icon={SvgIcon.Check} />
            </ModalFooter>
        </Modal>
    );
};

const Canvas = styled.canvas`
    display: none;
`;

const getImage = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: Required<Crop>) => {
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d')!;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );
    return canvas.toDataURL('image/jpg', 0.8);
};
