import React, { FC, ReactNode, useState } from 'react';

import { BYODOrderDto, BYODOrderItemDto, BYODOrdersFilter } from '@hofy/api-admin';
import { IconItem } from '@hofy/common';
import { UUID } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import {
    Box,
    Chevron,
    defaultRowRenderer,
    ExpandHeight,
    FeaturedIcon,
    FilterApiRecord,
    FilterHeaderCell,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';
import { LocationCard } from '@hofy/ui-domain';

import { BYODOrderStatusChip } from '../../../../components/domain/byodOrders/BYODOrderStatusChip';
import { ActorLabel } from '../../../auditLogsPage/components/ActorLabel';
import { BYODOrderActionMenu } from './BYODOrderActionMenu';
import { BYODOrderItemsTable } from './BYODOrderItemsTable';
import { BYODTrackingLink } from './BYODTrackingLink';

interface BYODOrderTableProps {
    byodOrders: BYODOrderDto[];
    filters: FilterApiRecord<BYODOrdersFilter>;
    onReceiveBYODOrder(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
}

export const BYODOrderTable: FC<BYODOrderTableProps> = ({
    byodOrders,
    filters,
    onReceiveBYODOrder,
    infinityScroll,
}) => {
    const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
    const toggleExpand = (v: number) => {
        if (v === expandedIndex) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(v);
        }
    };

    return (
        <InfinityScrollTable
            data={byodOrders}
            toKey={byodOrder => byodOrder.id}
            infinityScroll={infinityScroll}
            minWidth={1400}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Requests}
                    title='No BYOD orders'
                    message='No BYOD orders for selected criteria'
                />
            }
            rowRenderer={item => (
                <ExpandableBYODEntryRow
                    isExpanded={item.index === expandedIndex}
                    onClick={() => toggleExpand(item.index)}
                    key={item.index}
                    byodOrderItems={item.item.items}
                >
                    {defaultRowRenderer(item)}
                </ExpandableBYODEntryRow>
            )}
            columns={[
                {
                    id: 'items',
                    header: 'Items',
                    width: 20,
                    renderer: (_, index) => <Chevron isOpen={index === expandedIndex} />,
                },
                {
                    id: 'byod-order',
                    header: 'BYOD order #',
                    flexGrow: 1,
                    renderer: byodOrder => <TextCell>{byodOrder.publicId}</TextCell>,
                },
                {
                    id: 'trackingLink',
                    header: 'Tracking link',
                    flexGrow: 1,
                    renderer: byodOrder => <BYODTrackingLink byodOrder={byodOrder} />,
                },
                {
                    id: 'organization',
                    header: 'Organisation',
                    flexGrow: 2,
                    renderer: byodOrder => byodOrder.organization.name,
                },
                {
                    id: 'location',
                    header: <FilterHeaderCell label='Location' filter={filters.warehouses} />,
                    flexGrow: 2,
                    renderer: ({ toWarehouse }) => (
                        <LocationCard
                            icon={
                                <FeaturedIcon icon={SvgIcon.Hofy} shape='circle' size={32} variant='vivid' />
                            }
                            label={toWarehouse.name}
                            name={toWarehouse.name}
                            country={toWarehouse.address?.country}
                        />
                    ),
                },
                {
                    id: 'expectedItems',
                    header: 'Exp. items',
                    renderer: byodOrder =>
                        byodOrder.items.reduce(
                            (previous: number, item: BYODOrderItemDto) => previous + item.expectedQuantity,
                            0,
                        ),
                    width: 50,
                },
                {
                    id: 'estimatedReceiptAt',
                    header: 'Est. receive at',
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <IconItem icon={SvgIcon.Truck}>{formatDate(byodOrder.estimatedReceiveOn)}</IconItem>
                    ),
                },
                {
                    id: 'createdAt',
                    header: 'Created at',
                    flexGrow: 1,
                    renderer: byodOrder => formatDate(byodOrder.createdAt),
                },
                {
                    id: 'createdBy',
                    header: 'Created by',
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <Paragraph3>
                            <ActorLabel actor={byodOrder.createdBy} />
                        </Paragraph3>
                    ),
                },
                {
                    id: 'status',
                    header: <FilterHeaderCell label='Status' filter={filters.statuses} />,
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <Box column gap={12}>
                            <BYODOrderStatusChip status={byodOrder.status} dateTime={byodOrder.createdAt} />
                        </Box>
                    ),
                },
                {
                    id: 'actions',
                    header: 'Actions',
                    flexGrow: 1,
                    renderer: byodOrder => (
                        <BYODOrderActionMenu onReceiveBYODOrder={onReceiveBYODOrder} byodOrder={byodOrder} />
                    ),
                },
            ]}
        />
    );
};

interface ExpandableBYODEntryRowProps {
    byodOrderItems: BYODOrderItemDto[];
    children: ReactNode;
    onClick(): void;
    isExpanded: boolean;
}

const ExpandableBYODEntryRow: FC<ExpandableBYODEntryRowProps> = ({
    children,
    onClick,
    isExpanded,
    byodOrderItems,
}) => {
    return (
        <Box>
            <Box onClick={onClick} pointer>
                {children}
            </Box>
            <ExpandHeight>{isExpanded && <BYODOrderItemsTable byodItems={byodOrderItems} />}</ExpandHeight>
        </Box>
    );
};
