import { useMutation } from '@tanstack/react-query';

import { byodOrdersCacheKey, byodOrderService, BYODOrdersFilter } from '@hofy/api-admin';

export const useBYODOrdersExport = (filters: BYODOrdersFilter) => {
    const { mutate, isPending } = useMutation({
        mutationFn: () => byodOrderService.exportBYODOrders(filters),
        mutationKey: [byodOrdersCacheKey, 'export'],
    });
    return {
        download: mutate,
        isDownloading: isPending,
    };
};
