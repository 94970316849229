import { useMutation } from '@tanstack/react-query';

import {
    dataSeedService,
    emptySeedLocalOrganizationPayload,
    SeedLocalOrganizationPayload,
} from '@hofy/api-admin';
import { isRequired, isValidEmailAddress, useForm, useToast, validator } from '@hofy/ui';

export const useSeedOrganization = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedLocalOrganization,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Organization generated',
            });
        },
    });

    const form = useForm<SeedLocalOrganizationPayload>({
        initial: emptySeedLocalOrganizationPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedLocalOrganizationPayload>({
            organization: isRequired('Organization name is required'),
            firstName: isRequired('First name is required'),
            lastName: isRequired('Last name is required'),
            email: [
                isRequired('Base email is required'),
                isValidEmailAddress('Valid base email is required'),
            ],
        }),
    });
    return { form, isLoading: mutation.isPending };
};
