import { getEnumValues } from '@hofy/global';

export enum SubscriptionTerm {
    Term12Months = '12_months',
    Term24Months = '24_months',
    Term36Months = '36_months',
}

export const allSubscriptionTerms = getEnumValues<SubscriptionTerm>(SubscriptionTerm);

export const monthsInSubscriptionTerm = (term: SubscriptionTerm): number => {
    switch (term) {
        case SubscriptionTerm.Term12Months:
            return 12;
        case SubscriptionTerm.Term24Months:
            return 24;
        case SubscriptionTerm.Term36Months:
            return 36;
    }
};
