import React, { FC } from 'react';

import {
    CustomerOwnedStoreAndReuseLocation,
    customerOwnedStoreAndReuseLocationLabels,
} from '@hofy/api-shared';
import { ColorDye } from '@hofy/global';
import { Badge } from '@hofy/ui';

interface CustomerAssetStorageLocationChipProps {
    storageLocation: CustomerOwnedStoreAndReuseLocation | null;
}

const storageAssignmentsStatusColors: Record<CustomerOwnedStoreAndReuseLocation, ColorDye> = {
    [CustomerOwnedStoreAndReuseLocation.LocalHub]: 'orange',
    [CustomerOwnedStoreAndReuseLocation.HofyHub]: 'purple',
};

export const CustomerAssetStorageLocationChip: FC<CustomerAssetStorageLocationChipProps> = ({
    storageLocation,
}) => {
    if (!storageLocation) {
        return null;
    }
    return (
        <Badge
            label={customerOwnedStoreAndReuseLocationLabels[storageLocation]}
            color={storageAssignmentsStatusColors[storageLocation]}
        />
    );
};
