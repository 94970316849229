import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey, repaymentPlanService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSyncRepaymentPlans = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: repaymentPlanService.syncRepaymentPlans,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: 'Two sync triggered',
            });
        },
    });

    const syncPlans = () => {
        mutation.mutate();
    };

    return {
        syncPlans,
        isLoading: mutation.isPending,
    };
};
