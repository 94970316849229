import { AnyEnum, enumToLabel, EnumValue, isValidEnumEntry } from '@hofy/global';

import { I18nConfig, I18nKey } from './types';

const extractionRegex = /{(.*?)}/g;

export interface TranslatePayload {
    [key: string]: string | number | null | undefined;
}

const handleNotTranslatedKey = (key: string) => {
    const anyWindow = window as any;

    anyWindow.missingTranslations = anyWindow.missingTranslations || [];
    if (!anyWindow.missingTranslations.includes(key)) {
        anyWindow.missingTranslations.push(key);
    }
};

export const translate = (config: I18nConfig, key: I18nKey, payload?: TranslatePayload): string => {
    if (config.showKeys) {
        return `[${key}]`;
    }

    if (config.keys[key] !== undefined) {
        return interpolate(config.keys[key], payload);
    }

    handleNotTranslatedKey(key);
    return key;
};

export const exists = (config: I18nConfig, key: I18nKey): boolean => !!config.keys[key];
export const existsParts = (config: I18nConfig, ...items: (string | number)[]): boolean =>
    exists(config, items.join('.') as I18nKey);

export const translateParts = (config: I18nConfig, ...items: (string | number)[]) =>
    translate(config, items.join('.') as I18nKey);

export const translateEnum = <E extends EnumValue, T extends AnyEnum = AnyEnum>(
    config: I18nConfig,
    enumEntity: T,
    prefix: string,
    fallbackEnumValue: E | undefined,
    enumValue: E,
    payload?: TranslatePayload,
    // eslint-disable-next-line @typescript-eslint/max-params
) => {
    const enumLabel = enumToLabel(enumEntity, prefix);
    if (isValidEnumEntry(enumEntity)(enumValue)) {
        return translate(config, enumLabel(enumValue) as I18nKey, payload);
    }
    return translate(config, enumLabel(fallbackEnumValue!) as I18nKey, payload);
};

const interpolate = (value: string | undefined, payload?: TranslatePayload) => {
    if (!value || !payload) {
        return value as string;
    }
    const extractedPlaceholder = value.match(extractionRegex);

    if (extractedPlaceholder) {
        return extractedPlaceholder.reduce((prev: string, current: string) => {
            const key = current.substr(1, current.length - 2);
            return prev.replace(current, payload[key]?.toString() ?? '');
        }, value);
    }
    return value;
};
