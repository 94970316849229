import AD from 'flag-icons/flags/1x1/ad.svg?external';
import AE from 'flag-icons/flags/1x1/ae.svg?external';
import AF from 'flag-icons/flags/1x1/af.svg?external';
import AG from 'flag-icons/flags/1x1/ag.svg?external';
import AI from 'flag-icons/flags/1x1/ai.svg?external';
import AL from 'flag-icons/flags/1x1/al.svg?external';
import AM from 'flag-icons/flags/1x1/am.svg?external';
import AO from 'flag-icons/flags/1x1/ao.svg?external';
import AQ from 'flag-icons/flags/1x1/aq.svg?external';
import AR from 'flag-icons/flags/1x1/ar.svg?external';
import AS from 'flag-icons/flags/1x1/as.svg?external';
import AT from 'flag-icons/flags/1x1/at.svg?external';
import AU from 'flag-icons/flags/1x1/au.svg?external';
import AW from 'flag-icons/flags/1x1/aw.svg?external';
import AX from 'flag-icons/flags/1x1/ax.svg?external';
import AZ from 'flag-icons/flags/1x1/az.svg?external';
import BA from 'flag-icons/flags/1x1/ba.svg?external';
import BB from 'flag-icons/flags/1x1/bb.svg?external';
import BD from 'flag-icons/flags/1x1/bd.svg?external';
import BE from 'flag-icons/flags/1x1/be.svg?external';
import BF from 'flag-icons/flags/1x1/bf.svg?external';
import BG from 'flag-icons/flags/1x1/bg.svg?external';
import BH from 'flag-icons/flags/1x1/bh.svg?external';
import BI from 'flag-icons/flags/1x1/bi.svg?external';
import BJ from 'flag-icons/flags/1x1/bj.svg?external';
import BL from 'flag-icons/flags/1x1/bl.svg?external';
import BM from 'flag-icons/flags/1x1/bm.svg?external';
import BN from 'flag-icons/flags/1x1/bn.svg?external';
import BO from 'flag-icons/flags/1x1/bo.svg?external';
import BQ from 'flag-icons/flags/1x1/bq.svg?external';
import BR from 'flag-icons/flags/1x1/br.svg?external';
import BS from 'flag-icons/flags/1x1/bs.svg?external';
import BT from 'flag-icons/flags/1x1/bt.svg?external';
import BV from 'flag-icons/flags/1x1/bv.svg?external';
import BW from 'flag-icons/flags/1x1/bw.svg?external';
import BY from 'flag-icons/flags/1x1/by.svg?external';
import BZ from 'flag-icons/flags/1x1/bz.svg?external';
import CA from 'flag-icons/flags/1x1/ca.svg?external';
import CC from 'flag-icons/flags/1x1/cc.svg?external';
import CD from 'flag-icons/flags/1x1/cd.svg?external';
import CF from 'flag-icons/flags/1x1/cf.svg?external';
import CG from 'flag-icons/flags/1x1/cg.svg?external';
import CH from 'flag-icons/flags/1x1/ch.svg?external';
import CI from 'flag-icons/flags/1x1/ci.svg?external';
import CK from 'flag-icons/flags/1x1/ck.svg?external';
import CL from 'flag-icons/flags/1x1/cl.svg?external';
import CM from 'flag-icons/flags/1x1/cm.svg?external';
import CN from 'flag-icons/flags/1x1/cn.svg?external';
import CO from 'flag-icons/flags/1x1/co.svg?external';
import CR from 'flag-icons/flags/1x1/cr.svg?external';
import CU from 'flag-icons/flags/1x1/cu.svg?external';
import CV from 'flag-icons/flags/1x1/cv.svg?external';
import CW from 'flag-icons/flags/1x1/cw.svg?external';
import CX from 'flag-icons/flags/1x1/cx.svg?external';
import CY from 'flag-icons/flags/1x1/cy.svg?external';
import CZ from 'flag-icons/flags/1x1/cz.svg?external';
import DE from 'flag-icons/flags/1x1/de.svg?external';
import DJ from 'flag-icons/flags/1x1/dj.svg?external';
import DK from 'flag-icons/flags/1x1/dk.svg?external';
import DM from 'flag-icons/flags/1x1/dm.svg?external';
import DO from 'flag-icons/flags/1x1/do.svg?external';
import DZ from 'flag-icons/flags/1x1/dz.svg?external';
import EC from 'flag-icons/flags/1x1/ec.svg?external';
import EE from 'flag-icons/flags/1x1/ee.svg?external';
import EG from 'flag-icons/flags/1x1/eg.svg?external';
import EH from 'flag-icons/flags/1x1/eh.svg?external';
import ER from 'flag-icons/flags/1x1/er.svg?external';
import ES from 'flag-icons/flags/1x1/es.svg?external';
import ET from 'flag-icons/flags/1x1/et.svg?external';
import EU from 'flag-icons/flags/1x1/eu.svg?external';
import FI from 'flag-icons/flags/1x1/fi.svg?external';
import FJ from 'flag-icons/flags/1x1/fj.svg?external';
import FK from 'flag-icons/flags/1x1/fk.svg?external';
import FM from 'flag-icons/flags/1x1/fm.svg?external';
import FO from 'flag-icons/flags/1x1/fo.svg?external';
import FR from 'flag-icons/flags/1x1/fr.svg?external';
import GA from 'flag-icons/flags/1x1/ga.svg?external';
import GB from 'flag-icons/flags/1x1/gb.svg?external';
import GD from 'flag-icons/flags/1x1/gd.svg?external';
import GE from 'flag-icons/flags/1x1/ge.svg?external';
import GF from 'flag-icons/flags/1x1/gf.svg?external';
import GG from 'flag-icons/flags/1x1/gg.svg?external';
import GH from 'flag-icons/flags/1x1/gh.svg?external';
import GI from 'flag-icons/flags/1x1/gi.svg?external';
import GL from 'flag-icons/flags/1x1/gl.svg?external';
import GM from 'flag-icons/flags/1x1/gm.svg?external';
import GN from 'flag-icons/flags/1x1/gn.svg?external';
import GP from 'flag-icons/flags/1x1/gp.svg?external';
import GQ from 'flag-icons/flags/1x1/gq.svg?external';
import GR from 'flag-icons/flags/1x1/gr.svg?external';
import GS from 'flag-icons/flags/1x1/gs.svg?external';
import GT from 'flag-icons/flags/1x1/gt.svg?external';
import GU from 'flag-icons/flags/1x1/gu.svg?external';
import GW from 'flag-icons/flags/1x1/gw.svg?external';
import GY from 'flag-icons/flags/1x1/gy.svg?external';
import HK from 'flag-icons/flags/1x1/hk.svg?external';
import HM from 'flag-icons/flags/1x1/hm.svg?external';
import HN from 'flag-icons/flags/1x1/hn.svg?external';
import HR from 'flag-icons/flags/1x1/hr.svg?external';
import HT from 'flag-icons/flags/1x1/ht.svg?external';
import HU from 'flag-icons/flags/1x1/hu.svg?external';
import IC from 'flag-icons/flags/1x1/ic.svg?external';
import ID from 'flag-icons/flags/1x1/id.svg?external';
import IE from 'flag-icons/flags/1x1/ie.svg?external';
import IL from 'flag-icons/flags/1x1/il.svg?external';
import IM from 'flag-icons/flags/1x1/im.svg?external';
import IN from 'flag-icons/flags/1x1/in.svg?external';
import IO from 'flag-icons/flags/1x1/io.svg?external';
import IQ from 'flag-icons/flags/1x1/iq.svg?external';
import IR from 'flag-icons/flags/1x1/ir.svg?external';
import IS from 'flag-icons/flags/1x1/is.svg?external';
import IT from 'flag-icons/flags/1x1/it.svg?external';
import JE from 'flag-icons/flags/1x1/je.svg?external';
import JM from 'flag-icons/flags/1x1/jm.svg?external';
import JO from 'flag-icons/flags/1x1/jo.svg?external';
import JP from 'flag-icons/flags/1x1/jp.svg?external';
import KE from 'flag-icons/flags/1x1/ke.svg?external';
import KG from 'flag-icons/flags/1x1/kg.svg?external';
import KH from 'flag-icons/flags/1x1/kh.svg?external';
import KI from 'flag-icons/flags/1x1/ki.svg?external';
import KM from 'flag-icons/flags/1x1/km.svg?external';
import KN from 'flag-icons/flags/1x1/kn.svg?external';
import KP from 'flag-icons/flags/1x1/kp.svg?external';
import KR from 'flag-icons/flags/1x1/kr.svg?external';
import KW from 'flag-icons/flags/1x1/kw.svg?external';
import KY from 'flag-icons/flags/1x1/ky.svg?external';
import KZ from 'flag-icons/flags/1x1/kz.svg?external';
import LA from 'flag-icons/flags/1x1/la.svg?external';
import LB from 'flag-icons/flags/1x1/lb.svg?external';
import LC from 'flag-icons/flags/1x1/lc.svg?external';
import LI from 'flag-icons/flags/1x1/li.svg?external';
import LK from 'flag-icons/flags/1x1/lk.svg?external';
import LR from 'flag-icons/flags/1x1/lr.svg?external';
import LS from 'flag-icons/flags/1x1/ls.svg?external';
import LT from 'flag-icons/flags/1x1/lt.svg?external';
import LU from 'flag-icons/flags/1x1/lu.svg?external';
import LV from 'flag-icons/flags/1x1/lv.svg?external';
import LY from 'flag-icons/flags/1x1/ly.svg?external';
import MA from 'flag-icons/flags/1x1/ma.svg?external';
import MC from 'flag-icons/flags/1x1/mc.svg?external';
import MD from 'flag-icons/flags/1x1/md.svg?external';
import ME from 'flag-icons/flags/1x1/me.svg?external';
import MF from 'flag-icons/flags/1x1/mf.svg?external';
import MG from 'flag-icons/flags/1x1/mg.svg?external';
import MH from 'flag-icons/flags/1x1/mh.svg?external';
import MK from 'flag-icons/flags/1x1/mk.svg?external';
import ML from 'flag-icons/flags/1x1/ml.svg?external';
import MM from 'flag-icons/flags/1x1/mm.svg?external';
import MN from 'flag-icons/flags/1x1/mn.svg?external';
import MO from 'flag-icons/flags/1x1/mo.svg?external';
import MP from 'flag-icons/flags/1x1/mp.svg?external';
import MQ from 'flag-icons/flags/1x1/mq.svg?external';
import MR from 'flag-icons/flags/1x1/mr.svg?external';
import MS from 'flag-icons/flags/1x1/ms.svg?external';
import MT from 'flag-icons/flags/1x1/mt.svg?external';
import MU from 'flag-icons/flags/1x1/mu.svg?external';
import MV from 'flag-icons/flags/1x1/mv.svg?external';
import MW from 'flag-icons/flags/1x1/mw.svg?external';
import MX from 'flag-icons/flags/1x1/mx.svg?external';
import MY from 'flag-icons/flags/1x1/my.svg?external';
import MZ from 'flag-icons/flags/1x1/mz.svg?external';
import NA from 'flag-icons/flags/1x1/na.svg?external';
import NC from 'flag-icons/flags/1x1/nc.svg?external';
import NE from 'flag-icons/flags/1x1/ne.svg?external';
import NF from 'flag-icons/flags/1x1/nf.svg?external';
import NG from 'flag-icons/flags/1x1/ng.svg?external';
import NI from 'flag-icons/flags/1x1/ni.svg?external';
import NL from 'flag-icons/flags/1x1/nl.svg?external';
import AN from 'flag-icons/flags/1x1/nl.svg?external';
import NO from 'flag-icons/flags/1x1/no.svg?external';
import NP from 'flag-icons/flags/1x1/np.svg?external';
import NR from 'flag-icons/flags/1x1/nr.svg?external';
import NU from 'flag-icons/flags/1x1/nu.svg?external';
import NZ from 'flag-icons/flags/1x1/nz.svg?external';
import OM from 'flag-icons/flags/1x1/om.svg?external';
import PA from 'flag-icons/flags/1x1/pa.svg?external';
import PE from 'flag-icons/flags/1x1/pe.svg?external';
import PF from 'flag-icons/flags/1x1/pf.svg?external';
import PG from 'flag-icons/flags/1x1/pg.svg?external';
import PH from 'flag-icons/flags/1x1/ph.svg?external';
import PK from 'flag-icons/flags/1x1/pk.svg?external';
import PL from 'flag-icons/flags/1x1/pl.svg?external';
import PM from 'flag-icons/flags/1x1/pm.svg?external';
import PN from 'flag-icons/flags/1x1/pn.svg?external';
import PR from 'flag-icons/flags/1x1/pr.svg?external';
import PS from 'flag-icons/flags/1x1/ps.svg?external';
import PT from 'flag-icons/flags/1x1/pt.svg?external';
import PW from 'flag-icons/flags/1x1/pw.svg?external';
import PY from 'flag-icons/flags/1x1/py.svg?external';
import QA from 'flag-icons/flags/1x1/qa.svg?external';
import RE from 'flag-icons/flags/1x1/re.svg?external';
import RO from 'flag-icons/flags/1x1/ro.svg?external';
import RS from 'flag-icons/flags/1x1/rs.svg?external';
import RU from 'flag-icons/flags/1x1/ru.svg?external';
import RW from 'flag-icons/flags/1x1/rw.svg?external';
import SA from 'flag-icons/flags/1x1/sa.svg?external';
import SB from 'flag-icons/flags/1x1/sb.svg?external';
import SC from 'flag-icons/flags/1x1/sc.svg?external';
import SD from 'flag-icons/flags/1x1/sd.svg?external';
import SE from 'flag-icons/flags/1x1/se.svg?external';
import SG from 'flag-icons/flags/1x1/sg.svg?external';
import SH from 'flag-icons/flags/1x1/sh.svg?external';
import SI from 'flag-icons/flags/1x1/si.svg?external';
import SJ from 'flag-icons/flags/1x1/sj.svg?external';
import SK from 'flag-icons/flags/1x1/sk.svg?external';
import SL from 'flag-icons/flags/1x1/sl.svg?external';
import SM from 'flag-icons/flags/1x1/sm.svg?external';
import SN from 'flag-icons/flags/1x1/sn.svg?external';
import SO from 'flag-icons/flags/1x1/so.svg?external';
import SR from 'flag-icons/flags/1x1/sr.svg?external';
import SS from 'flag-icons/flags/1x1/ss.svg?external';
import ST from 'flag-icons/flags/1x1/st.svg?external';
import SV from 'flag-icons/flags/1x1/sv.svg?external';
import SX from 'flag-icons/flags/1x1/sx.svg?external';
import SY from 'flag-icons/flags/1x1/sy.svg?external';
import SZ from 'flag-icons/flags/1x1/sz.svg?external';
import TC from 'flag-icons/flags/1x1/tc.svg?external';
import TD from 'flag-icons/flags/1x1/td.svg?external';
import TF from 'flag-icons/flags/1x1/tf.svg?external';
import TG from 'flag-icons/flags/1x1/tg.svg?external';
import TH from 'flag-icons/flags/1x1/th.svg?external';
import TJ from 'flag-icons/flags/1x1/tj.svg?external';
import TK from 'flag-icons/flags/1x1/tk.svg?external';
import TL from 'flag-icons/flags/1x1/tl.svg?external';
import TM from 'flag-icons/flags/1x1/tm.svg?external';
import TN from 'flag-icons/flags/1x1/tn.svg?external';
import TO from 'flag-icons/flags/1x1/to.svg?external';
import TR from 'flag-icons/flags/1x1/tr.svg?external';
import TT from 'flag-icons/flags/1x1/tt.svg?external';
import TV from 'flag-icons/flags/1x1/tv.svg?external';
import TW from 'flag-icons/flags/1x1/tw.svg?external';
import TZ from 'flag-icons/flags/1x1/tz.svg?external';
import UA from 'flag-icons/flags/1x1/ua.svg?external';
import UG from 'flag-icons/flags/1x1/ug.svg?external';
import UM from 'flag-icons/flags/1x1/um.svg?external';
import US from 'flag-icons/flags/1x1/us.svg?external';
import UY from 'flag-icons/flags/1x1/uy.svg?external';
import UZ from 'flag-icons/flags/1x1/uz.svg?external';
import VA from 'flag-icons/flags/1x1/va.svg?external';
import VC from 'flag-icons/flags/1x1/vc.svg?external';
import VE from 'flag-icons/flags/1x1/ve.svg?external';
import VG from 'flag-icons/flags/1x1/vg.svg?external';
import VI from 'flag-icons/flags/1x1/vi.svg?external';
import VN from 'flag-icons/flags/1x1/vn.svg?external';
import VU from 'flag-icons/flags/1x1/vu.svg?external';
import WF from 'flag-icons/flags/1x1/wf.svg?external';
import WS from 'flag-icons/flags/1x1/ws.svg?external';
import XK from 'flag-icons/flags/1x1/xk.svg?external';
import YE from 'flag-icons/flags/1x1/ye.svg?external';
import YT from 'flag-icons/flags/1x1/yt.svg?external';
import ZA from 'flag-icons/flags/1x1/za.svg?external';
import ZM from 'flag-icons/flags/1x1/zm.svg?external';
import ZW from 'flag-icons/flags/1x1/zw.svg?external';

import None from './assets/none.svg?external';

export const FlagImages = {
    AD,
    AE,
    AF,
    AG,
    AI,
    AL,
    AM,
    AN,
    AO,
    AQ,
    AR,
    AS,
    AT,
    AU,
    AW,
    AX,
    AZ,
    BA,
    BB,
    BD,
    BE,
    BF,
    BG,
    BH,
    BI,
    BJ,
    BL,
    BM,
    BN,
    BO,
    BQ,
    BR,
    BS,
    BT,
    BV,
    BW,
    BY,
    BZ,
    CA,
    CC,
    CD,
    CF,
    CG,
    CH,
    CI,
    CK,
    CL,
    CM,
    CN,
    CO,
    CR,
    CU,
    CV,
    CW,
    CX,
    CY,
    CZ,
    DE,
    DJ,
    DK,
    DM,
    DO,
    DZ,
    EC,
    EE,
    EG,
    EH,
    ER,
    ES,
    ET,
    EU,
    FI,
    FJ,
    FK,
    FM,
    FO,
    FR,
    GA,
    GB,
    GD,
    GE,
    GF,
    GG,
    GH,
    GI,
    GL,
    GM,
    GN,
    GP,
    GQ,
    GR,
    GS,
    GT,
    GU,
    GW,
    GY,
    HK,
    HM,
    HN,
    HR,
    HT,
    HU,
    IC,
    ID,
    IE,
    IL,
    IM,
    IN,
    IO,
    IQ,
    IR,
    IS,
    IT,
    JE,
    JM,
    JO,
    JP,
    KE,
    KG,
    KH,
    KI,
    KM,
    KN,
    KP,
    KR,
    KW,
    KY,
    KZ,
    LA,
    LB,
    LC,
    LI,
    LK,
    LR,
    LS,
    LT,
    LU,
    LV,
    LY,
    MA,
    MC,
    MD,
    ME,
    MF,
    MG,
    MH,
    MK,
    ML,
    MM,
    MN,
    MO,
    MP,
    MQ,
    MR,
    MS,
    MT,
    MU,
    MV,
    MW,
    MX,
    MY,
    MZ,
    NA,
    NC,
    NE,
    NF,
    NG,
    NI,
    NL,
    NO,
    NP,
    NR,
    NU,
    NZ,
    OM,
    PA,
    PE,
    PF,
    PG,
    PH,
    PK,
    PL,
    PM,
    PN,
    PR,
    PS,
    PT,
    PW,
    PY,
    QA,
    RE,
    RO,
    RS,
    RU,
    RW,
    SA,
    SB,
    SC,
    SD,
    SE,
    SG,
    SH,
    SI,
    SJ,
    SK,
    SL,
    SM,
    SN,
    SO,
    SR,
    SS,
    ST,
    SV,
    SX,
    SY,
    SZ,
    TC,
    TD,
    TF,
    TG,
    TH,
    TJ,
    TK,
    TL,
    TM,
    TN,
    TO,
    TR,
    TT,
    TV,
    TW,
    TZ,
    UA,
    UG,
    UM,
    US,
    UY,
    UZ,
    VA,
    VC,
    VE,
    VG,
    VI,
    VN,
    VU,
    WF,
    WS,
    YE,
    YT,
    ZA,
    ZM,
    ZW,
    XK,
    None,
};
