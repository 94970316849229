import { getEnumValues } from '@hofy/global';

export enum ShipmentTab {
    Pending = 'pending',
    All = 'all',
    ShipToday = 'ship-today',
    RealTimeTracking = 'real-time-tracking',
}

export const allShipmentTabs = getEnumValues<ShipmentTab>(ShipmentTab);
