import { isDevOrLocal } from '@hofy/helpers';

import { useLocalStorage } from './useLocalStorage';

/**
 * Only works on dev or local, never on prod
 */
export const createDevFlagHook = (name: string) => () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [flag, setFlag] = useLocalStorage(name, false);
    return [flag && isDevOrLocal(), setFlag] as const;
};
