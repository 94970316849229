import { useMutation, useQueryClient } from '@tanstack/react-query';

import { authService, ResetPasswordPayload } from '@hofy/api-auth';
import { useToast } from '@hofy/ui';

export const useResetPassword = () => {
    const { showToast, showErrorToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: authService.resetPassword,
        onSuccess: () => {
            // todo this should not be here
            queryClient.invalidateQueries({ queryKey: ['admin/user/emails'] });
            showToast({
                type: 'positive',
                message: 'Password reset executed successfully',
            });
        },
        onError: () => showErrorToast(),
    });

    const resetPassword = (payload: ResetPasswordPayload) => {
        mutation.mutate(payload);
    };

    return { resetPassword };
};
