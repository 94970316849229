import React, { FC } from 'react';

import { InvoiceEntryType, invoiceEntryTypeColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrInvoiceEntryType } from '../../../../store/invoiceEntries/useTrInvoiceEntryType';

interface InvoiceEntryTypeChipProps {
    status: InvoiceEntryType;
}

export const InvoiceEntryTypeChip: FC<InvoiceEntryTypeChipProps> = ({ status }) => {
    const tr = useTrInvoiceEntryType();
    return <Badge label={tr(status)} color={invoiceEntryTypeColor[status]} />;
};
