import React from 'react';

import { FormFieldApi } from '../../../hooks';
import { Optional } from '../../../types';
import { LabeledSelectSearch, LabeledSelectSearchProps } from './LabeledSelectSearch';

type FormSelectSearchOptionalProps<T> = Optional<LabeledSelectSearchProps<T>, 'onChange'>;
type FormSelectSearchOmittedProps<T> = Omit<FormSelectSearchOptionalProps<T>, 'errorMessage' | 'value'>;

interface FormNormalSelectSearchProps<T> extends FormSelectSearchOmittedProps<T> {
    api: FormFieldApi<T>;
    nullable?: false;
}

interface FormNullableSelectSearchProps<T> extends FormSelectSearchOmittedProps<T> {
    api: FormFieldApi<T | null>;
    nullable: true;
}

type FormSelectSearchProps<T> = FormNormalSelectSearchProps<T> | FormNullableSelectSearchProps<T>;

export const FormSelectSearch = <T,>({
    api,
    onChange,
    onSearchChange,
    isLoadingSearch,
    loadingSearchPlaceHolder,
    onBlur,
    ...rest
}: FormSelectSearchProps<T>) => {
    return (
        <LabeledSelectSearch
            {...rest}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            onSearchChange={onSearchChange}
            isLoadingSearch={isLoadingSearch}
            loadingSearchPlaceHolder={loadingSearchPlaceHolder}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
