import React, { FC } from 'react';

import { DataErasureDetailsDto } from '@hofy/api-admin';
import { DataErasureStatus } from '@hofy/api-shared';
import { Alert, Box, FormSwitch, FormTextarea, UseForm } from '@hofy/ui';

import { UpdateDataErasureFormProps } from '../../../store/dataErasures/useUpdateDataErasure';

interface DataErasureSlideoutFormProps {
    dataErasure: DataErasureDetailsDto;
    form: UseForm<UpdateDataErasureFormProps>;
}

export const DataErasureSlideoutForm: FC<DataErasureSlideoutFormProps> = ({ dataErasure, form }) => {
    if (dataErasure.status !== DataErasureStatus.Pending) {
        return <Box />;
    }

    const disclaimer = (!form.values.cannotComplete && (
        <Box paddingBottom={40}>
            <Alert
                description='By submitting this certificate, you confirm the data erasure was successfully completed.'
                type='informative'
            />
        </Box>
    )) || (
        <Box paddingBottom={40}>
            <Alert
                description='By submitting, you confirm the data erasure could not be completed.'
                type='warning'
            />
        </Box>
    );

    return (
        <Box>
            {disclaimer}
            <FormSwitch
                label='Cannot be completed'
                api={form.fields.cannotComplete}
                onChange={cannotComplete => cannotComplete}
            />

            {form.values.cannotComplete && (
                <FormTextarea marginTop={24} api={form.fields.comment} label='Provide reason' />
            )}
        </Box>
    );
};
