import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ZIndex } from '@hofy/theme';

import { childrenProps } from '../../helpers/React';
import { Toast } from './Toast';

interface ToastStackProps {
    children?: ReactNode;
    onPause?(): void;
    onResume?(): void;
    onDismiss?(id: string): void;
}

export const ToastStack: FC<ToastStackProps> = ({ children, onPause, onResume, onDismiss }) => {
    const toasts = childrenProps(children, Toast);

    return (
        <Wrapper onPointerEnter={onPause} onPointerLeave={onResume}>
            <AnimatePresence>
                {toasts.map((toast, index) => (
                    <ToastContainer
                        key={toast.tmpKey || toast.id}
                        initial={{ opacity: 0, y: 80 }}
                        animate={{
                            y: 0,
                            zIndex: toasts.length - index,
                            opacity: index === 0 ? 1 : 0.8,
                            scale: 1 - index * 0.05,
                            filter: `blur(${index * 1.2}px)`,
                        }}
                        exit={{ opacity: 0, y: 80 }}
                        transition={{ duration: 0.3 }}
                        drag='y'
                        dragConstraints={{ top: 0, bottom: 0 }}
                        onDragEnd={(_, info) => {
                            if (info.velocity.y > 50) {
                                toast.onClose();
                            }
                            if (info.velocity.y < -50) {
                                onDismiss?.(toast.id);
                            }
                        }}
                    >
                        <Toast {...toast} />
                    </ToastContainer>
                ))}
            </AnimatePresence>
        </Wrapper>
    );
};

const Wrapper = styled(motion.div)`
    position: fixed;
    z-index: ${ZIndex.Popover};
    left: 0;
    bottom: 16px;
    right: 0;
`;

const ToastContainer = styled(motion.div)`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    transform-origin: 50% 250%;

    pointer-events: none;
    > * {
        pointer-events: all;
    }
`;
