import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';

export const useRevertScan = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: assignmentService.revertScanItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            onSuccess?.();
        },
    });

    return {
        revertScan: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
