import React, { FC } from 'react';

import { IconItem } from '@hofy/common';
import { Color } from '@hofy/theme';
import { SvgIcon } from '@hofy/ui';

interface CollectionParcelsToCollectCellProps {
    numberOfParcelsToCollect: number;
}

export const CollectionParcelsToCollectCell: FC<CollectionParcelsToCollectCellProps> = ({
    numberOfParcelsToCollect,
}) => {
    return (
        <IconItem icon={SvgIcon.Box} iconColor={Color.AccentYellow}>
            {`Parcels to collect (${numberOfParcelsToCollect})`}
        </IconItem>
    );
};
