import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Paragraph3, Pressable } from '../../base';
import { Icon } from '../../icon';
import { Beta } from '../components/NavigationItem';
import { NavigationColors } from '../NavigationColors';

interface NavigationProfileItemProps extends TestKeyAware {
    icon?: Svg;
    label: ReactNode;
    isVisible?: boolean;
    onClick(): void;
    beta?: boolean;
}

export const NavigationProfileItem: FC<NavigationProfileItemProps> = ({
    icon,
    label,
    isVisible = true,
    onClick,
    testKey,
    beta,
}) => {
    if (!isVisible) {
        return null;
    }

    return (
        <ContainerBox
            minHeight={36}
            paddingHorizontal={16}
            row
            innerFocus
            gap={12}
            onClick={onClick}
            data-test-key={testKey}
        >
            {icon && <Icon color={Color.InteractionInvertedNormal} size={16} svg={icon} />}
            <Paragraph3 bold flex={1} color={Color.InteractionInvertedNormal} textNoWrap ellipsis>
                {label}
            </Paragraph3>

            {beta && (
                <Beta color={Color.ContentInvertedPrimary} size={8} paddingVertical={2} paddingHorizontal={6}>
                    Beta
                </Beta>
            )}
        </ContainerBox>
    );
};

const ContainerBox = styled(Pressable)`
    &:hover {
        background-color: ${NavigationColors.ProfileItemHover};
    }
`;
