import { createContext, MutableRefObject, useContext } from 'react';

export interface ConfirmationModalItem {
    id: string;
    title: string;
    message: string;
    confirmLabel: string;
    cancelLabel: string;
}

export interface ConfirmationContextType {
    modals: ConfirmationModalItem[];
    opened?: string | null;
    addModal(modal: ConfirmationModalItem): void;
    removeModal(id: string): void;
    openModal(id: string, callback?: () => void): void;
    closeModal(): void;
    fnRef: MutableRefObject<(() => void) | undefined>;
}

export const ConfirmationContext = createContext<ConfirmationContextType | null>(null);

export const useConfirmationContext = () => {
    return useContext(ConfirmationContext);
};
