import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { addonsService } from '../service/addonsService';
import { addonsCacheKey } from './cacheKey';

export const useAddonQuery = (id: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [addonsCacheKey, id],

        queryFn: () => addonsService.getAddon(id),
    });
    return {
        data,
        isLoading,
        isError,
    };
};
