import { useQuery } from '@tanstack/react-query';

import { shipmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const shipmentCollectionRequestQueryKey = 'admin/shipments/collection-request';
export const useShipmentCollectionRequestQuery = (id: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [shipmentCollectionRequestQueryKey, id],

        queryFn: () => shipmentService.getShipmentCollectionRequest(id),
    });

    return {
        data,
        isLoading,
    };
};
