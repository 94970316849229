import React, { FC } from 'react';

import { ContactPayload } from '@hofy/api-admin';
import {
    Box,
    FormCheckbox,
    FormFieldRecord,
    FormGridRow,
    FormInput,
    FormPhoneInput,
    IconButton,
    SvgIcon,
} from '@hofy/ui';

interface ContactFormProps {
    contact: FormFieldRecord<ContactPayload>;
    canBeDeleted: boolean;
    isRequired: boolean;
    onDeleteItem(): void;
}

export const ContactForm: FC<ContactFormProps> = ({ contact, canBeDeleted, onDeleteItem, isRequired }) => {
    return (
        <Box row>
            <FormGridRow columns={4}>
                <FormInput label='First name' api={contact.firstName} isRequired={isRequired} nullable />
                <FormInput label='Last name' api={contact.lastName} isRequired={isRequired} nullable />
                <FormPhoneInput label='Phone' api={contact.phone} isRequired={isRequired} nullable />
                <FormInput label='Email' api={contact.email} nullable />
                <FormCheckbox label='Is invoice email' api={contact.isInvoiceEmail} />
                <FormCheckbox label='Is default' api={contact.isDefault} />
            </FormGridRow>
            <Box marginLeft={20}>
                <IconButton icon={SvgIcon.Trash} onClick={onDeleteItem} disabled={!canBeDeleted} />
            </Box>
        </Box>
    );
};
