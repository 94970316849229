import React, { FC, useMemo } from 'react';

import { ProductCategory } from '@hofy/api-shared';
import { BoxProps, FramedImage } from '@hofy/ui';

import { FallbackCategoryImage } from './FallbackCategoryImage';

export interface ImageRef {
    url?: string;
    isActive: boolean;
}

interface ProductImageProps extends BoxProps {
    image?: string | ImageRef | ImageRef[] | null;
    size?: number | string;
    selected?: boolean;
    category?: ProductCategory;
}

export const ProductImage: FC<ProductImageProps> = ({
    category = ProductCategory.Other,
    image,
    size = '100%',
    ...props
}) => {
    const src = useMemo(() => {
        if (!image || typeof image === 'string') {
            return image;
        }
        if (Array.isArray(image)) {
            return image.find(v => v.isActive)?.url;
        }
        return image?.url;
    }, [image]);

    return (
        <FramedImage
            src={src || undefined}
            size={size}
            fallback={<FallbackCategoryImage category={category} />}
            {...props}
        />
    );
};
