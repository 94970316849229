import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { Color, getSpacingValue, useTheme } from '@hofy/theme';

import { TestKeyAware } from '../../types';
import { Box, OuterBoxProps } from '../base';
import { BorderedBoxProps } from '../base/box/BorderedBox';
import { PaddingBoxProps } from '../base/box/types';
import { useElScrollbarSize } from './hooks/useElScrollbarSize';
import { transparentScrollbarStyles } from './scrollbarTransparentStyles';

export interface HiddenScrollProps extends OuterBoxProps, PaddingBoxProps, BorderedBoxProps, TestKeyAware {
    bg?: Color;
}

export const HiddenScroll: FC<HiddenScrollProps> = ({
    children,
    padding = 0,
    paddingHorizontal = padding,
    paddingRight = paddingHorizontal,
    paddingLeft = paddingHorizontal,
    testKey,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollSize, contentSize } = useElScrollbarSize(ref);

    const theme = useTheme();
    const paddingLeftPx = getSpacingValue(theme, paddingLeft);
    const paddingRightPx = getSpacingValue(theme, paddingRight);

    let maxWidth = contentSize - paddingLeftPx - paddingRightPx;

    // If we have space to fit the scrollbar in right padding area
    // we can increase the max width to keep spacing consistent
    if (paddingRightPx >= scrollSize) {
        maxWidth += scrollSize;
    }

    return (
        <ContainerBox
            ref={ref}
            padding={padding}
            paddingHorizontal={paddingHorizontal}
            paddingLeft={paddingLeft}
            style={{ paddingRight: 0 }}
            column
            data-test-key={testKey}
            {...props}
        >
            <Box flex={1} style={{ width: maxWidth }}>
                {children}
            </Box>
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)`
    ${transparentScrollbarStyles}

    overflow-y: auto;
    overflow-x: hidden;

    @media (hover: hover) {
        overflow-y: hidden;

        &:hover {
            overflow-y: auto;
        }
    }
`;
