import { DependencyList, useLayoutEffect, useRef } from 'react';

export const useScrollToBottom = (deps: DependencyList) => {
    const ref = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        ref.current?.scrollTo(0, Number.MAX_SAFE_INTEGER);
    }, deps);

    return ref;
};
