import React, { FC } from 'react';

import { RepairRefDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { BaseTable, LabeledText, Placeholder, Span, SvgIllustration, TwoLineSmallCell } from '@hofy/ui';

import { getRepairsDetailsLink } from '../../components/routing/adminLinks';
import { RepairStatusChip } from '../repairsPage/components/RepairStatusChip';

interface RepairsTableProps {
    repairs: RepairRefDto[];
    maxWidth?: number | string;
}

export const RepairsTable: FC<RepairsTableProps> = ({ repairs, maxWidth = '100%' }) => {
    return (
        <BaseTable
            data={repairs}
            toKey={repair => repair.id}
            emptyContent={<Placeholder illustration={SvgIllustration.List} title='No repairs' />}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            maxWidth={maxWidth}
            columns={[
                {
                    id: 'repairId',
                    header: 'Repair id',
                    width: 80,
                    renderer: ({ publicId, id }) => {
                        return (
                            <TwoLineSmallCell
                                link={getRepairsDetailsLink(id)}
                                line1={<Span underline>#{publicId}</Span>}
                                line2={null}
                            />
                        );
                    },
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 300,
                    renderer: ({ status, isCancelled }) => (
                        <RepairStatusChip status={status} isCancelled={isCancelled} />
                    ),
                },
                {
                    id: 'changedOn',
                    header: 'Status changed on',
                    width: 180,
                    renderer: ({ statusUpdatedOn }) => (
                        <LabeledText label='Status changed on' content={formatDate(statusUpdatedOn)} />
                    ),
                },
            ]}
        />
    );
};
