import '@mdxeditor/editor/style.css';
import React, { FC } from 'react';

import { ImagePayload, ProductPayload } from '@hofy/api-admin';
import {
    allProductTiers,
    isParentContainsCategory,
    ParentProductCategory,
    useTrProductTier,
} from '@hofy/api-shared';
import { MarkdownEditor } from '@hofy/editor';
import { FormCategorySelect } from '@hofy/product';
import { Box, FormCheckbox, FormInput, FormSection, FormSelect, UseForm } from '@hofy/ui';

import { ImageEditor } from '../../../components/design/imageEditor/ImageEditor';
import { ProductSpecifications } from './ProductSpecifications';

interface ProductFormProps {
    form: UseForm<ProductPayload>;
}

export const ProductForm: FC<ProductFormProps> = ({ form }) => {
    const trProductTier = useTrProductTier();
    return (
        <Box
            flex='auto'
            overflow='auto'
            fullHeight
            row
            alignItems='flex-start'
            paddingHorizontal={40}
            paddingVertical={30}
            gap={40}
        >
            <Box width={500} shrink={0} column gap={30}>
                <FormSection label='Details'>
                    <FormInput label='Product name' api={form.fields.name} isRequired nullable />
                    <FormCategorySelect label='Category' api={form.fields.category} isRequired />
                    <FormInput label='Brand' api={form.fields.brand} isRequired nullable />
                    {form.values.category &&
                        isParentContainsCategory(ParentProductCategory.Devices, form.values.category) && (
                            <FormSelect
                                api={form.fields.tier}
                                toText={trProductTier}
                                label='Tier'
                                nullable
                                options={allProductTiers}
                            />
                        )}
                    <FormCheckbox api={form.fields.isRefurbished} label='Is refurbished' />
                </FormSection>
                <FormSection label='Images'>
                    <ImageEditor
                        images={form.values.images}
                        onChange={images =>
                            form.setValues({
                                images: images.map(
                                    (v, index): ImagePayload => ({
                                        ...v,
                                        position: index,
                                    }),
                                ),
                            })
                        }
                        showDeleted
                    />
                </FormSection>
                <FormSection label='Specifications'>
                    <ProductSpecifications form={form} />
                </FormSection>
            </Box>
            <FormSection label='Description' flex={1}>
                <Box flex={1} paddingTop={24} paddingBottom={60}>
                    <MarkdownEditor
                        value={form.values.description}
                        onChange={description =>
                            form.setValues({
                                description,
                            })
                        }
                        isError={!!form.errors.description}
                    />
                </Box>
            </FormSection>
        </Box>
    );
};
