import { useMutation, useQueryClient } from '@tanstack/react-query';

import { purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const usePurchaseOrderApproval = (purchaseOrderId: UUID) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const options = {
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [purchaseOrdersCacheKey, 'purchase-order', purchaseOrderId],
            });
            showToast({
                type: 'positive',
                message: 'Purchase order approval applied',
            });
        },
    };

    const approveMutation = useMutation({
        mutationFn: () => purchaseOrderService.approvePurchaseOrder(purchaseOrderId),
        ...options,
    });

    const declineMutation = useMutation({
        mutationFn: () => purchaseOrderService.declinePurchaseOrder(purchaseOrderId),
        ...options,
    });

    return {
        approvePurchaseOrder: approveMutation.mutate,
        declinePurchaseOrder: declineMutation.mutate,
    };
};
