import React, { FC, ReactNode, useState } from 'react';
import { Redirect, Route, RouteChildrenProps, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { getEnumParam, getStringParam, UUIDRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { ContractTab } from '../../store/contracts/types/ContractTab';
import { CreateInvoiceEntrySlideout } from '../invoicingPage/invoiceEntries/createInvoiceEntrySlideout/CreateInvoiceEntrySlideout';
import { InvoiceEntryDetailsOverlay } from '../invoicingPage/invoiceEntries/invoiceEntryDetailsOverlay/InvoiceEntryDetailsOverlay';
import { UpdateInvoiceEntryTaxAddressSlideout } from '../invoicingPage/invoiceEntries/updateInvoiceEntryAddressSlideout/UpdateInvoiceEntryTaxAddressSlideout';
import { UpdateInvoiceEntrySlideout } from '../invoicingPage/invoiceEntries/updateInvoiceEntrySlideout/UpdateInvoiceEntrySlideout';
import { ContractDetailsOverlay } from './contractDetailsOverlay/ContractDetailsOverlay';
import { ContractsPage } from './ContractsPage';
import { UpdateContractSlideoutOverlay } from './updateContractSlideout/UpdateContractSlideoutOverlay';

export const ContractsRouter: FC = () => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const [organizationId, setOrganizationId] = useState<UUID>();

    const handleOpenContract = (id: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${id}/${ContractTab.Details}`);
    };

    const handleChangeTab = (id: UUID, tab: ContractTab) => {
        history.push(`${AdminNavLink.Contracts}/${id}/${tab}`);
    };

    const handleClickAddEntry = (contractId: UUID, organizationId: UUID) => {
        setOrganizationId(organizationId);
        history.push(`${AdminNavLink.Contracts}/${contractId}/entries/add`);
    };

    const handleEditEntry = (contractId: UUID, entryId: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${contractId}/entries/${entryId}/edit`);
    };

    const handleUpdateEntryTaxAddress = (contractId: UUID, entryId: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${contractId}/entries/${entryId}/update-tax-address`);
    };

    const handleEntryClick = (contractId: UUID, entryId: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${contractId}/entries/${entryId}`);
    };

    const handleEdit = (tab: ContractTab, contractId: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${contractId}/${tab}/edit`);
    };

    const handleNewInvoiceEntry = (contractId: UUID, entryId: UUID) => {
        history.push(`${AdminNavLink.Contracts}/${contractId}/entries/${entryId}`);
    };

    const invoiceEntrySlideoutOrRedirect = (contractId: UUID, organizationId?: UUID) => {
        if (organizationId) {
            return (
                <CreateInvoiceEntrySlideout
                    basePath={`${AdminNavLink.Contracts}/${contractId}/entries`}
                    contractId={contractId}
                    organizationId={organizationId}
                    onClose={() => goBack(`${AdminNavLink.Contracts}/${contractId}/entries`)}
                />
            );
        } else {
            return <Redirect to={`${AdminNavLink.Contracts}/${contractId}/entries`} />;
        }
    };

    return (
        <Route path={AdminNavLink.Contracts}>
            <Route path={AdminNavLink.Contracts} exact>
                <ContractsPage onOpenContract={handleOpenContract} />
            </Route>
            <DetailsRoute path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/:contractTab`}>
                {({ contractId, contractTab }) => (
                    <>
                        <ContractDetailsOverlay
                            contractId={contractId}
                            tab={contractTab}
                            onChangeTab={t => handleChangeTab(contractId, t)}
                            onEdit={i => handleEdit(contractTab, i)}
                            onOpenAddSlideout={organizationId =>
                                handleClickAddEntry(contractId, organizationId)
                            }
                            onInvoiceEntryClick={entryId => handleEntryClick(contractId, entryId)}
                        />
                        <IdAwareRoute
                            path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/:contractTab/edit`}
                        >
                            {({ contractId }) => (
                                <UpdateContractSlideoutOverlay
                                    contractId={contractId}
                                    onClose={() => goBack(`${AdminNavLink.Contracts}/${contractId}/details`)}
                                />
                            )}
                        </IdAwareRoute>
                    </>
                )}
            </DetailsRoute>
            <IdAwareRoute path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/entries/add`}>
                {({ contractId }) => invoiceEntrySlideoutOrRedirect(contractId, organizationId)}
            </IdAwareRoute>
            <InvoiceEntryRoute
                path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/entries/:entryId(${pathUuid})`}
            >
                {({ contractId, entryId }) => (
                    <InvoiceEntryDetailsOverlay
                        entryId={entryId}
                        onEntryEdit={() => handleEditEntry(contractId, entryId)}
                        onUpdateTaxAddress={() => handleUpdateEntryTaxAddress(contractId, entryId)}
                        onNewEntry={newEntryId => handleNewInvoiceEntry(contractId, newEntryId)}
                    />
                )}
            </InvoiceEntryRoute>
            <InvoiceEntryRoute
                path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/entries/:entryId(${pathUuid})/edit`}
            >
                {({ contractId, entryId }) => (
                    <UpdateInvoiceEntrySlideout
                        entryId={entryId}
                        basePath={`${AdminNavLink.Contracts}/${contractId}/entries`}
                        onClose={() => goBack(`${AdminNavLink.Contracts}/${contractId}/entries`)}
                    />
                )}
            </InvoiceEntryRoute>
            <InvoiceEntryRoute
                path={`${AdminNavLink.Contracts}/:contractId(${pathUuid})/entries/:entryId(${pathUuid})/update-tax-address`}
            >
                {({ contractId, entryId }) => (
                    <UpdateInvoiceEntryTaxAddressSlideout
                        entryId={entryId}
                        basePath={`${AdminNavLink.Contracts}/${contractId}/entries`}
                        onClose={() => goBack(`${AdminNavLink.Contracts}/${contractId}/entries`)}
                    />
                )}
            </InvoiceEntryRoute>
        </Route>
    );
};

interface DetailsRouteProps {
    path?: string;
    children(v: { contractTab: ContractTab; contractId: UUID }): ReactNode;
}

const DetailsRoute: FC<DetailsRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const contractId = getStringParam(p.match?.params || {}, 'contractId') as UUID;
        const contractTab = getEnumParam<ContractTab>(p.match?.params || {}, 'contractTab', ContractTab);

        if (contractId && contractTab) {
            return children({
                contractId,
                contractTab,
            });
        }

        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};

interface InvoiceEntryEditRouteProps {
    children(v: { contractId: UUID; entryId: UUID }): ReactNode;
    path?: string;
}

const InvoiceEntryRoute: FC<InvoiceEntryEditRouteProps> = ({ children, ...props }) => {
    const renderChildren = (p: RouteChildrenProps) => {
        const contractId = getStringParam(p.match?.params || {}, 'contractId') as UUID;
        const entryId = getStringParam(p.match?.params || {}, 'entryId') as UUID;
        if (contractId && entryId) {
            return children({
                contractId,
                entryId,
            });
        }
        return null;
    };
    return <Route {...props}>{renderChildren}</Route>;
};

const IdAwareRoute = UUIDRoute('contractId', Route);
