import React, { FC } from 'react';

import { ProductDetailsDto } from '@hofy/api-admin';
import { RentalTerm } from '@hofy/api-shared';
import { zeroDollars } from '@hofy/global';
import { BaseProductDetails, ProductDropdowns, useProductVariants } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, InnerCard, SectionTitle2 } from '@hofy/ui';

import { RentalTermPriceLabel } from './RentalTermPriceLabel';

interface ProductPreviewDetailsProps {
    product: ProductDetailsDto;
    rentalTerm: RentalTerm;
}

export const ProductPreviewDetails: FC<ProductPreviewDetailsProps> = ({ product, rentalTerm }) => {
    const variantsState = useProductVariants(product.variants);
    const { sizes, style, styles, size, setSize, setStyle, variant } = variantsState;
    const price = variant ? variant.price[rentalTerm] : zeroDollars;

    return (
        <BaseProductDetails
            product={product}
            variant={variant}
            zoomable
            rightContent={
                <Box flex={1}>
                    <InnerCard column gap={20}>
                        <SectionTitle2 color={Color.ContentSecondary}>Rental price</SectionTitle2>
                        {(!!sizes.length || !!styles.length) && (
                            <ProductDropdowns
                                size={size}
                                sizes={sizes}
                                styles={styles}
                                style={style}
                                onChangeSize={setSize}
                                onChangeStyle={setStyle}
                            />
                        )}
                        <RentalTermPriceLabel price={price} rentalTerm={rentalTerm} />
                    </InnerCard>
                </Box>
            }
        />
    );
};
