export const paymentTermsI18n = {
    'payment-terms.terms-prepaid': 'On purchase',
    'payment-terms.terms-on-delivery': 'On delivery',
    'payment-terms.terms-5-days': '5 days',
    'payment-terms.terms-7-days': '7 days',
    'payment-terms.terms-14-days': '14 days',
    'payment-terms.terms-21-days': '21 days',
    'payment-terms.terms-30-days': '30 days',
    'payment-terms.terms-60-days': '60 days',
};
