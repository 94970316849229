import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty, isEqual } from 'lodash';

import {
    collectionRequestService,
    PackagingTrackingPayload,
    ShipmentCollectionRequestUpdateTrackingDataAction,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { errorMap } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

export const useUpdatePackagingTrackingDataForCollectionRequest = (
    shipmentId: UUID,
    collectionRequestId: UUID,
    trackingData: ShipmentCollectionRequestUpdateTrackingDataAction,
    onSuccess?: () => void,
) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: PackagingTrackingPayload) =>
            collectionRequestService.updatePackagingTrackingDataForCollectionRequest(
                shipmentId,
                collectionRequestId,
                payload,
            ),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            showToast({
                type: 'positive',
                message: 'Packaging tracking data updated successfully',
            });
            onSuccess?.();
        },
    });

    const form = useForm<PackagingTrackingPayload>({
        initial: {
            packagingTrackingLink: trackingData.trackingLink || '',
            packagingTrackingReference: trackingData.trackingReference || '',
        },
        onSubmit: mutation.mutate,
        validate: formValues => ({
            packagingTrackingLink: errorMap(
                [isEmpty(formValues.packagingTrackingLink), 'Tracking link is required'],
                [
                    isEqual(formValues.packagingTrackingLink, trackingData.trackingLink),
                    'Tracking link is the same as the current one',
                ],
            ),
            packagingTrackingReference: errorMap(
                [isEmpty(formValues.packagingTrackingReference), 'Tracking reference is required'],
                [
                    isEqual(formValues.packagingTrackingReference, trackingData.trackingReference),
                    'Tracking reference is the same as the current one',
                ],
            ),
        }),
    });

    return {
        form,
        isMutationSuccess: mutation.isSuccess,
        isLoadingMutation: mutation.isPending,
    };
};
