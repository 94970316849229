import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    ChangeItemStatusPayload,
    itemCacheKey,
    ItemDto,
    itemsService,
    toLocationPayload,
} from '@hofy/api-admin';
import { isWriteOff, ItemStatus } from '@hofy/api-shared';
import { isRequired, isRequiredIf, useForm, validator } from '@hofy/ui';

import { useItemWarehouses } from './useUpdateItem';

export const useChangeItemStatus = (item: ItemDto, onEnd?: () => void) => {
    const queryClient = useQueryClient();
    const { warehouses, isWarehouseBinRequired } = useItemWarehouses(item);

    const mutation = useMutation({
        mutationFn: (v: ChangeItemStatusPayload) => itemsService.changeStatus(item.id, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onEnd?.();
        },
    });

    const requireLocation = (status: ItemStatus) => !isWriteOff(status) && isWriteOff(item.status);

    const form = useForm<ChangeItemStatusPayload>({
        initial: {
            status: item.status,
            statusExplanation: null,
            ...toLocationPayload(item.location),
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
        validate: validator<ChangeItemStatusPayload>({
            status: isRequired('Status is required'),
            statusExplanation: isRequired('Explanation is required'),
            warehouseBinIdentifier: isRequiredIf(
                v => isWarehouseBinRequired(v.warehouseId) && requireLocation(v.status),
                'Valid warehouse bin is required for core warehouse',
            ),
        }),
    });

    return {
        form,
        warehouses,
        isLoading: mutation.isPending,
        isError: mutation.isError,
        requireLocation: requireLocation(form.values.status),
    };
};
