import { useMutation, useQueryClient } from '@tanstack/react-query';

import { usersCacheKey, userService } from '@hofy/api-admin';

export const useCreateUser = (onSuccess: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: userService.createUser,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [usersCacheKey] });
            onSuccess();
        },
    });

    return { createUser: mutation.mutate, isCreateUserLoading: mutation.isPending };
};
