import { AddressPayload, SupplierPaymentTerms, SupplierStatus, SupplierType } from '@hofy/api-shared';
import { Country, Currency, Phone, Price, UUID } from '@hofy/global';

import { SupplierContactRole } from './SupplierContactRole';

export interface SupplierContactPayload {
    email: string | null;
    phone: Phone | null;
    role: SupplierContactRole;
    name: string;
    lastname: string;
}

export const emptySupplierContactPayload: SupplierContactPayload = {
    email: null,
    phone: null,
    role: SupplierContactRole.Primary,
    name: '',
    lastname: '',
};

export interface SupplierPayload {
    name: string;
    legalName: string | null;
    supplierType: SupplierType;
    supplierStatus: SupplierStatus;
    companyRegistrationNumber: string | null;
    taxRegistrationNumber: string | null;
    hofySubsidiary: UUID;
    notes: string | null;
    primaryCurrency: Currency;
    unlimitedCredit: boolean;
    creditLimit: Price | null;
    paymentTerm: SupplierPaymentTerms;
    billingAddress: AddressPayload | null;
    shippingAddress: AddressPayload | null;
    countries: Country[];
    contacts: SupplierContactPayload[];
}
