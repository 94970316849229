import React, { FC } from 'react';

import { OrderDetailsDto } from '@hofy/api-admin';
import { RequestDecisionStatusChip } from '@hofy/core';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { BooleanBadge, Box, InfoTooltipIcon, Labeled, LabeledText, Link, Paragraph3 } from '@hofy/ui';

import { AddressTooltipOverlay } from '../../../components/domain/address/AddressTooltipOverlay';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { SalesOrderTab } from '../../../store/invoicing/salesOrders/types/SalesOrderTab';
import { AdminInvoicingTab } from '../../../store/invoicing/types/AdminInvoicingTab';
import { LabeledOrganizationLink } from '../../organizationsPage/OrganizationDetailsLink';
import { LabeledUserLink } from '../../peoplePage/users/UserLink';

interface RequestDetailsContentProps {
    order: OrderDetailsDto;
}

export const OrderDetailsContent: FC<RequestDetailsContentProps> = ({ order }) => {
    const { formatPrice } = usePrice();
    const {
        publicId,
        id,
        note,
        isManagerOrder,
        status,
        createdAt,
        decisionAt,
        decisionBy,
        autoApproved,
        deliveryAddress,
        deliveryFee,
        expressDeliveryFee,
        organization,
        user,
        salesOrderId,
    } = order;

    return (
        <>
            <LabeledText label='Public id' content={publicId} />
            <LabeledText label='Id' content={id} />
            <Labeled label='Status' content={<RequestDecisionStatusChip status={status} />} />
            <Labeled label='Auto-approved' content={<BooleanBadge value={autoApproved} offColor='grey' />} />
            <Labeled
                label='Created by manager'
                content={<BooleanBadge value={isManagerOrder} offColor='grey' />}
            />
            <LabeledOrganizationLink organization={organization} />
            <LabeledUserLink user={user} />
            <Labeled
                label='Delivery address'
                content={
                    <Box row gap={8}>
                        <Paragraph3>Address</Paragraph3>
                        <InfoTooltipIcon
                            placement='top'
                            bodySlot={<AddressTooltipOverlay address={deliveryAddress} />}
                            maxWidth='auto'
                            interactive
                        />
                    </Box>
                }
            />
            <LabeledText label='Note' content={note} />
            <LabeledText label='Created date' content={formatDate(createdAt)} />
            <LabeledText label='Decision date' content={formatDate(decisionAt)} />
            <LabeledText label='Decision by' content={decisionBy?.name} />
            <Labeled
                label='Sales order'
                content={
                    salesOrderId && (
                        <Link
                            to={`${AdminNavLink.Invoicing}/${AdminInvoicingTab.SalesOrders}/${salesOrderId}/${SalesOrderTab.Details}`}
                        >
                            #{salesOrderId}
                        </Link>
                    )
                }
            />
            <LabeledText label='Delivery fee' content={formatPrice(deliveryFee)} />
            <LabeledText label='Express delivery fee' content={formatPrice(expressDeliveryFee)} />
        </>
    );
};
