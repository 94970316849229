export type Percent = string;

export const zeroPercent: Percent = '0.00';
export const percentToNumber = (v: Percent) => parseFloat(v);
export const numberToPercent = (v: number) => v.toFixed(2);

export const isValidFraction = (v: string | null) =>
    v ? percentToNumber(v) >= -100 && percentToNumber(v) <= 100 : true;

export const isNegativePercent = (v: Percent) => {
    return parseFloat(v) < 0;
};

export const isPositivePercent = (v?: Percent | null): v is Percent => {
    return !!v && parseFloat(v) > 0;
};
