import React, { FC } from 'react';
import { useCopyToClipboard } from 'react-use';

import { Alert, Box, Button, Input, Modal, ModalContent, ModalHeader, SvgIcon } from '@hofy/ui';

interface ApiKeysResultModalModalProps {
    keyValue: string;
    keyName: string;
    onClose(): void;
}

export const ApiKeysResultModal: FC<ApiKeysResultModalModalProps> = ({ onClose, keyName, keyValue }) => {
    const [state, setCopied] = useCopyToClipboard();

    const copy = () => {
        window.navigator.clipboard.writeText(keyValue);
        setCopied(keyValue);
    };

    return (
        <Modal width={550} onClose={onClose}>
            <ModalHeader title={`Your API key - ${keyName}`} />
            <ModalContent>
                <Alert
                    type='warning'
                    title='Please copy this key and save it'
                    description='For security reasons, this key is not stored anywhere. It cannot be recovered after leaving this screen'
                    marginBottom={16}
                />
                <Box flex={1} row gap={16}>
                    <Input value={keyValue} onChange={() => {}} disabled flex={1} />
                    <Button
                        label={state.value ? 'Copied' : 'Copy'}
                        leftIcon={state.value ? SvgIcon.Check : SvgIcon.Copy}
                        onClick={copy}
                    />
                </Box>
            </ModalContent>
        </Modal>
    );
};
