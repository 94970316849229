import styled, { CSSObject } from 'styled-components';

import { BorderRadiusValues, Color, ColorKeywords, Elevation, ElevationType } from '@hofy/theme';

import { BorderedBox, BorderedBoxProps } from './box/BorderedBox';

export interface BoxProps extends BorderedBoxProps {
    bg?: Color;
    color?: Color | ColorKeywords;
    cursor?: CSSObject['cursor'];
    pointer?: boolean;
    inactive?: boolean;
    /** By default is 4px (true) */
    rounded?: BorderRadiusValues | boolean;
    elevation?: ElevationType;
}

export const Box = styled(BorderedBox)<BoxProps>(
    ({ bg, color, cursor, pointer, rounded, inactive, elevation }) => {
        const styles: CSSObject = {};

        if (bg) {
            styles.backgroundColor = bg;
        }

        if (color) {
            styles.color = color;
        }

        if (cursor) {
            styles.cursor = cursor;
        }

        if (pointer) {
            styles.cursor = 'pointer';
        }

        if (rounded) {
            styles.borderRadius = rounded === true ? 4 : rounded + 'px';
        }

        if (inactive) {
            styles.opacity = 0.5;
        }

        if (elevation) {
            styles.boxShadow = Elevation[elevation].join(',');
        }

        return styles;
    },
);
