import React, { FC } from 'react';

interface YouTubeIconProps {
    size: number;
    color?: boolean;
}

export const YouTubeIcon: FC<YouTubeIconProps> = ({ size, color }) => {
    const fill = color ? '#CD201F' : 'currentColor';

    return (
        <svg viewBox='0 0 512 512' width={size} height={size}>
            <path
                fill={fill}
                d='M423.6,81.5c-54.9-2-111.7-3.1-170.1-3c-56.7,0-111.8,1.1-165.2,3C42.4,83.2,6,120.9,6,166.9v178.2   c0,46,36.4,83.7,82.4,85.4c54.7,2,111.1,3.1,169.2,3c57,0,112.4-1.1,166.1-3c46-1.7,82.3-39.4,82.3-85.4V166.9   C506,120.9,469.6,83.2,423.6,81.5z'
            />
            <polygon fill='#FFFFFF' points='205.7,181 205.7,332.2 335.8,256.6' />
        </svg>
    );
};
