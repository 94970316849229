import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { TotalSubsidiaryValuationDto } from '../../itemEvent/service/types/ItemValuationDto';
import { FixedAssetValuationDto } from './types/FixedAssetValuationDto';
import { FixedAssetValuationFilters } from './types/FixedAssetValuationFilters';

const itemDepreciationUrl = '/api/admin/item-depreciation';

class ItemDepreciationService {
    public listFixedAssetValuation = (filters: FixedAssetValuationFilters, page: PageRequest) => {
        return restClient.getJson<PageResponse<FixedAssetValuationDto>>(
            stringifyUrl({
                url: `${itemDepreciationUrl}/fixed-asset-valuation`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public getTotalFixedAssetValuation = async (query: FixedAssetValuationFilters) => {
        return restClient.getJson<TotalSubsidiaryValuationDto[]>(
            stringifyUrl({
                url: `${itemDepreciationUrl}/total-fixed-asset-valuation`,
                query: {
                    ...query,
                },
            }),
        );
    };
}

export const itemDepreciationService = new ItemDepreciationService();
