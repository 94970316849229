import React, { FC, useState } from 'react';

import { AssignmentWithProductDto, UserAssignmentDto, useUserAssignmentsQuery } from '@hofy/api-admin';
import { AssignmentStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { Button, FormSection, Modals, SvgIcon } from '@hofy/ui';

import { OrderItemList } from '../../../components/domain/orders/OrderItemList';
import { UseAssignmentSelectionState } from '../../../store/shipments/useAssignmentSelection';
import { AddAssignmentsModal } from './AddAssignmentsModal';

interface AssignmentSelectionProps {
    userId: UUID;
    status: AssignmentStatus[];
    selection: UseAssignmentSelectionState<UserAssignmentDto>;
    filterAdditionalAssignmentsBy?(assignment: AssignmentWithProductDto): boolean;
}

export const AssignmentSelection: FC<AssignmentSelectionProps> = ({
    selection,
    userId,
    status,
    filterAdditionalAssignmentsBy = alwaysTrue,
}) => {
    const { assignments, onRemoveAssignment, onAdd } = selection;
    const { data: allAssignments } = useUserAssignmentsQuery(userId, status);
    const [showModal, setShowModal] = useState(false);
    const showAddButton = assignments.length !== allAssignments.length;

    return (
        <FormSection label='Assignments' gap={0} marginBottom={40}>
            <OrderItemList assignments={assignments} onRemoveAssignment={onRemoveAssignment} />
            {showAddButton && (
                <Button
                    type='secondary'
                    leftIcon={SvgIcon.Add}
                    label='Add assignment to shipment'
                    onClick={() => setShowModal(!showModal)}
                    fullWidth
                />
            )}
            <Modals>
                {showModal && (
                    <AddAssignmentsModal
                        assignments={assignments}
                        allAssignments={allAssignments.filter(filterAdditionalAssignmentsBy)}
                        onClose={() => setShowModal(!showModal)}
                        onAdd={onAdd}
                    />
                )}
            </Modals>
        </FormSection>
    );
};

const alwaysTrue = () => true;
