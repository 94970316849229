import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { invoiceMonths } from '@hofy/api-admin';
import { formatApiMonth, nowDate } from '@hofy/helpers';

import { useSendInvoicesByEmail } from '../../../../store/invoices/useSendInvoicesByEmail';
import { MonthSelectorModal } from './MonthSelectorModal';

interface SendInvoicesModalProps {
    onClose(): void;
}

export const SendInvoicesModal: FC<SendInvoicesModalProps> = ({ onClose }) => {
    const { sendInvoices, isLoading, isError } = useSendInvoicesByEmail(onClose);

    const onSubmit = (month: DateTime) => {
        sendInvoices({
            month: formatApiMonth(month),
        });
    };

    return (
        <MonthSelectorModal
            title='Send invoices by e-mail'
            selectorLabel='Invoicing month'
            selectorOptions={invoiceMonths}
            selectorInitialValue={nowDate()
                .minus({
                    months: 1,
                })
                .endOf('month')}
            submitLabel='Send invoices'
            isLoading={isLoading}
            isError={isError}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    );
};
