import { noop } from 'lodash';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box } from '../../base';
import { BaseCellProps } from './Cell';

/** @deprecated not often used, consider using `Cell` instead */
export const MultiCell: FC<BaseCellProps> = ({
    rowVerticalPadding,
    flex = 1,
    shrink = 1,
    width = 100,
    children,
    onClick,
    ...rest
}) => {
    const flexStyle = `${flex} ${shrink} ${width}px`;
    noop(rowVerticalPadding);
    return (
        <Box onClick={onClick} overflow='hidden' alignSelf='stretch' {...rest} style={{ flex: flexStyle }}>
            <Wrapper rowVerticalPadding={8}>{children}</Wrapper>
        </Box>
    );
};

const Wrapper = styled.div<{ rowVerticalPadding?: number }>`
    display: grid;
    grid-auto-rows: 1fr;
    width: 100%;
    > * {
        padding: ${p => p.rowVerticalPadding}px 12px ${p => p.rowVerticalPadding}px 0;
        border-top: 1px solid ${Color.NonContextualBorderDivider};
        align-items: center;
        display: flex;
        :first-child {
            border-top-color: ${Color.Transparent};
        }
    }
`;
