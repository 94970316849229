import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UUID } from '@hofy/global';

import { messagingService } from '../service/messagingService';
import { MessageDto } from '../service/types/MessageDto';
import { adminUserMessagesQueryKey } from './cacheKey';

export const useMessagesByUserQuery = (userId: UUID) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [adminUserMessagesQueryKey, userId],
        queryFn: param =>
            messagingService.listUserMessages(userId, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const adminMessages: MessageDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        adminMessages,
        adminMessagesIsLoading: isLoading,
        adminMessagesIsError: isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
