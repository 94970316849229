import { FloatingFocusManager, useDismiss, useFloating, useInteractions, useRole } from '@floating-ui/react';
import React, { FC, MutableRefObject, ReactNode } from 'react';

import { useStructMemo } from '@hofy/hooks';

import { TestKeyAware } from '../../types';
import { BaseModal } from './base/BaseModal';
import { ModalContainer } from './base/ModalContainer';
import { ModalContextProvider, ModalContextValue } from './context/ModalContext';

export interface ModalProps extends TestKeyAware {
    onClose(): void;
    children: ReactNode;

    width?: number | string;
    maxWidth?: number | string;
    headerClose?: boolean;
    dismissible?: boolean;
    zIndexShift?: number;

    initialFocus?: MutableRefObject<HTMLElement | null>;
}

export const Modal: FC<ModalProps> = ({
    onClose,
    children,

    width,
    maxWidth,
    dismissible = true,
    headerClose = dismissible,
    zIndexShift,
    testKey,

    initialFocus,
}) => {
    const { refs, context } = useFloating({
        open: true,
        onOpenChange: onClose,
    });

    const role = useRole(context);
    const dismiss = useDismiss(context, {
        outsidePressEvent: 'mousedown',
        enabled: dismissible,
    });

    const { getFloatingProps } = useInteractions([role, dismiss]);

    const modalContext = useStructMemo<ModalContextValue>({
        onClose,
        dismissible,
        headerClose,
    });

    return (
        <ModalContextProvider value={modalContext}>
            <BaseModal zIndexShift={zIndexShift} testKey={testKey}>
                <FloatingFocusManager context={context} initialFocus={initialFocus}>
                    <ModalContainer
                        ref={refs.setFloating}
                        floatingProps={getFloatingProps()}
                        width={width}
                        maxWidth={maxWidth}
                    >
                        {children}
                    </ModalContainer>
                </FloatingFocusManager>
            </BaseModal>
        </ModalContextProvider>
    );
};
