import { Permission, Role } from '@hofy/api-shared';

export interface PermissionState {
    permissions: Permission[];
    roles: Role[];
    roleMapping: Partial<Record<Role, Permission[]>>;
}

export const emptyPermissionState: PermissionState = {
    permissions: [],
    roles: [],
    roleMapping: {},
};
