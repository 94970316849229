import React, { FC } from 'react';

import { FilterApiSingle } from '../../../../modules/filters/useFilters';
import { SearchInput } from './SearchInput';

interface FilterSearchInputProps {
    filter: FilterApiSingle<string>;
    placeholder?: string;
    autoFocus?: boolean;
}

export const FilterSearchInput: FC<FilterSearchInputProps> = ({ filter, placeholder, autoFocus }) => {
    return (
        <SearchInput
            value={filter.value}
            onChange={filter.set}
            placeholder={placeholder}
            autoFocus={autoFocus}
        />
    );
};
