import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
    PurchaseOrderItemDto,
    PurchaseOrderItemQuantityUpdatePayload,
    purchaseOrdersCacheKey,
    purchaseOrderService,
} from '@hofy/api-admin';
import { isGreaterThan, isLessThan, useForm, useToast, validator } from '@hofy/ui';

export const usePurchaseOrderItemUpdateQuantityForm = (
    purchaseOrderItem: PurchaseOrderItemDto,
    onSuccess: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: PurchaseOrderItemQuantityUpdatePayload) =>
            purchaseOrderService.updatePurchaseOrderItemQuantity(purchaseOrderItem.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [purchaseOrdersCacheKey] });

            showToast({
                type: 'positive',
                message: 'Purchase order quantity has been updated.',
            });
            onSuccess();
        },
    });

    const maxQuantity = useMemo(() => purchaseOrderItem.approvedQuantity, [purchaseOrderItem]);
    const minQuantity = useMemo(
        () => Math.max(purchaseOrderItem.receivedQuantity, purchaseOrderItem.billedQuantity),
        [purchaseOrderItem],
    );

    const form = useForm<PurchaseOrderItemQuantityUpdatePayload>({
        initial: {
            quantity: purchaseOrderItem.quantity,
        },
        onSubmit: mutation.mutate,
        validate: validator<PurchaseOrderItemQuantityUpdatePayload>({
            quantity: [
                isGreaterThan(minQuantity - 1, `Min quantity allowed is ${minQuantity}`),
                isLessThan(maxQuantity + 1, `Max quantity allowed is ${maxQuantity}`),
            ],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
    };
};
