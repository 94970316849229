export const setPasswordSlideoutI18n = {
    'set-password-slideout.header': 'Set password',
    'set-password-slideout.cancel-button': 'Cancel',
    'set-password-slideout.submit-button': 'Set password',
    'set-password-slideout.password': 'New password',
    'set-password-slideout.password.tooltip':
        'Ensure your password is unique. Using the same password to log into multiple accounts such as social media profiles increases your security risk',
    'set-password-slideout.password.errors.not-valid': 'Invalid password',
    'set-password-slideout.confirmed-password': 'Confirm password',
    'set-password-slideout.confirmed-password.errors.not-match': 'Password and confirmed password must match',
    'set-password-slideout.password-validation.8-characters': 'At least 8 characters',
    'set-password-slideout.password-validation.capital': 'One uppercase character',
    'set-password-slideout.password-validation.number': 'One number',
    'set-password-slideout.password-validation.symbol': 'One symbol: !@#$%^&*',
    'set-password-slideout.success': 'Password changed',
    'set-password-slideout.failure': 'Cannot set password',
};
