import React, { FC } from 'react';

import { CollectionPackagingStatus, collectionPackagingStatusColors } from '@hofy/api-shared';
import { getColorFromColorDye } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface CollectionPackagingIndicatorProps {
    type: 'manufacturer' | 'cardboardBox';
    packagingStatus: CollectionPackagingStatus;
}

export const CollectionPackagingIndicator: FC<CollectionPackagingIndicatorProps> = ({
    type,
    packagingStatus,
}) => {
    const prefix: Record<CollectionPackagingIndicatorProps['type'], string> = {
        cardboardBox: 'Cardboard box',
        manufacturer: 'Manufacturer box',
    };

    const icon: Record<CollectionPackagingIndicatorProps['type'], Svg> = {
        cardboardBox: SvgIcon.Box,
        manufacturer: SvgIcon.Tag,
    };

    const collectionShipmentLabelsStatusLabels: Record<CollectionPackagingStatus, string> = {
        [CollectionPackagingStatus.Required]: 'pending user response',
        [CollectionPackagingStatus.UserHasPackaging]: 'user has packaging',
        [CollectionPackagingStatus.AssistanceRequested]: "user doesn't have packaging",
        [CollectionPackagingStatus.PackagingSentToUser]: 'sent to user',
        [CollectionPackagingStatus.UserReceivedPackaging]: 'user received packaging',
    };

    const color = getColorFromColorDye(collectionPackagingStatusColors[packagingStatus], 'main');
    const statusLabel = collectionShipmentLabelsStatusLabels[packagingStatus];

    return (
        <Indicator color={color}>
            <TooltipIcon
                icon={icon[type]}
                iconColor={color}
                body={`${prefix[type]}:  ${statusLabel}`}
                iconSize={16}
            />
        </Indicator>
    );
};
