export const productCategoryI18n = {
    'product-category.desk': 'Desks',
    'product-category.chair': 'Chairs',
    'product-category.docking-station': 'Docking stations',
    'product-category.dongle': 'Dongles',
    'product-category.headset': 'Headsets',
    'product-category.keyboard': 'Keyboards',
    'product-category.mice-trackpad': 'Mice & Trackpads',
    'product-category.monitor': 'Monitors',
    'product-category.mobile-device': 'Mobile devices',
    'product-category.printer': 'Printers',
    'product-category.shredder': 'Shredders',
    'product-category.webcam': 'Webcams',
    'product-category.wifi-range-extender': 'WiFi range extenders',
    'product-category.laptop': 'Laptops',
    'product-category.laptop-stand': 'Laptop stands',
    'product-category.desk-riser': 'Desk risers',
    'product-category.footrest': 'Foot rests',
    'product-category.monitor-arm': 'Monitor arms',
    'product-category.standing-mat': 'Standing mats',
    'product-category.task-light': 'Task lights',
    'product-category.desk-tidy': 'Desk tidies',
    'product-category.other': 'Other',
    'product-category.desktop': 'Desktops',
    'product-category.tablet': 'Tablets',
    'product-category.charger': 'Chargers',
    'product-category.cable': 'Cables',
    'product-category.adapter': 'Adapters',
    'product-category.welcome-pack': 'Welcome Packs',

    'product-category.desk.empty': 'desks',
    'product-category.desk.single': 'desk',
    'product-category.desk.plural': 'desks',
    'product-category.chair.empty': 'chairs',
    'product-category.chair.single': 'chair',
    'product-category.chair.plural': 'chairs',
    'product-category.docking_station.empty': 'docking stations',
    'product-category.docking_station.single': 'docking station',
    'product-category.docking_station.plural': 'docking stations',
    'product-category.dongle.empty': 'dongles',
    'product-category.dongle.single': 'dongle',
    'product-category.dongle.plural': 'dongles',
    'product-category.headset.empty': 'headsets',
    'product-category.headset.single': 'headset',
    'product-category.headset.plural': 'headsets',
    'product-category.keyboard.empty': 'keyboards',
    'product-category.keyboard.single': 'keyboard',
    'product-category.keyboard.plural': 'keyboards',
    'product-category.mice_trackpad.empty': 'mice & trackpads',
    'product-category.mice_trackpad.single': 'mouse & trackpad',
    'product-category.mice_trackpad.plural': 'mice & trackpads',
    'product-category.monitor.empty': 'monitors',
    'product-category.monitor.single': 'monitor',
    'product-category.monitor.plural': 'monitors',
    'product-category.mobile_device.empty': 'mobile devices',
    'product-category.mobile_device.single': 'mobile device',
    'product-category.mobile_device.plural': 'mobile devices',
    'product-category.printer.empty': 'printers',
    'product-category.printer.single': 'printer',
    'product-category.printer.plural': 'printers',
    'product-category.shredder.empty': 'shredders',
    'product-category.shredder.single': 'shredder',
    'product-category.shredder.plural': 'shredders',
    'product-category.webcam.empty': 'webcams',
    'product-category.webcam.single': 'webcam',
    'product-category.webcam.plural': 'webcams',
    'product-category.wifi_range_extender.empty': 'WiFi range extenders',
    'product-category.wifi_range_extender.single': 'WiFi range extender',
    'product-category.wifi_range_extender.plural': 'WiFi range extenders',
    'product-category.laptop.empty': 'laptops',
    'product-category.laptop.single': 'laptop',
    'product-category.laptop.plural': 'laptops',
    'product-category.laptop_stand.empty': 'laptop stands',
    'product-category.laptop_stand.single': 'laptop stand',
    'product-category.laptop_stand.plural': 'laptop stands',
    'product-category.desk_riser.empty': 'desk risers',
    'product-category.desk_riser.single': 'desk riser',
    'product-category.desk_riser.plural': 'desk risers',
    'product-category.footrest.empty': 'foot rests',
    'product-category.footrest.single': 'foot rest',
    'product-category.footrest.plural': 'foot rests',
    'product-category.monitor_arm.empty': 'monitor arms',
    'product-category.monitor_arm.single': 'monitor arm',
    'product-category.monitor_arm.plurals': 'monitor arms',
    'product-category.standing_mat.empty': 'standing mats',
    'product-category.standing_mat.single': 'standing mat',
    'product-category.standing_mat.plural': 'standing mats',
    'product-category.task_light.empty': 'task lights',
    'product-category.task_light.single': 'task light',
    'product-category.task_light.plural': 'task lights',
    'product-category.desk_tidy.empty': 'desk tidies',
    'product-category.desk_tidy.single': 'desk tidy',
    'product-category.desk_tidy.plural': 'desk tidies',
    'product-category.other.empty': 'others',
    'product-category.other.single': 'other',
    'product-category.other.plural': 'others',
    'product-category.desktop.empty': 'desktops',
    'product-category.desktop.single': 'desktop',
    'product-category.desktop.plural': 'desktops',
    'product-category.tablet.empty': 'tablets',
    'product-category.tablet.single': 'tablet',
    'product-category.tablet.plural': 'tablets',
    'product-category.charger.empty': 'chargers',
    'product-category.charger.single': 'charger',
    'product-category.charger.plural': 'chargers',
    'product-category.cable.empty': 'cables',
    'product-category.cable.single': 'cable',
    'product-category.cable.plural': 'cables',
    'product-category.adapter.empty': 'adapters',
    'product-category.adapter.single': 'adapter',
    'product-category.adapter.plural': 'adapters',
};
