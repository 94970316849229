import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { salesOrderService } from '../service/salesOrderService';
import { salesOrdersQueryKey } from './salesOrderQueryKey';

export const useSalesOrderQuery = (id: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [salesOrdersQueryKey, id],

        queryFn: () => salesOrderService.getSalesOrderDetails(id),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
