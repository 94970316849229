import React, { FC } from 'react';

import { DateString, UUID } from '@hofy/global';
import { nowISODate } from '@hofy/helpers';
import {
    Alert,
    Box,
    Form,
    InlineDatepickerRange,
    Labeled,
    LabeledInput,
    LabeledSwitch,
    SubmitButton,
} from '@hofy/ui';

import { LabeledOrganizationSelect } from '../../../../components/domain/organizations/LabeledOrganizationSelect';
import { useSeedHofyAssets } from '../../../../store/settings/useSeedHofyAssets';

export const SeedRandomHofyAssets: FC = () => {
    const { form, isLoading } = useSeedHofyAssets();
    const minDate: DateString = '2022-01-01';
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Creates Hofy owned assets, with the corresponding contracts, assignments and invoices'
                />
                <LabeledOrganizationSelect
                    label='Organization'
                    onChange={(organizationId: UUID) =>
                        form.setValues({
                            organizationId,
                        })
                    }
                    value={form.values.organizationId}
                    placeholder='Choose organization'
                    nullable={false}
                    errorMessage={form.currentErrors.organizationId?.toString()}
                    isRequired
                />
                <LabeledInput
                    label='Number of assets'
                    type='number'
                    onChange={numberOfAssets =>
                        form.setValues({
                            numberOfAssets: parseInt(numberOfAssets),
                        })
                    }
                    value={form.values.numberOfAssets.toString()}
                    placeholder='Enter number of contracts'
                    errorMessage={form.currentErrors.numberOfAssets?.toString()}
                    isRequired
                />
                <Labeled
                    label='Active on range'
                    content={
                        <InlineDatepickerRange
                            value={form.values.activeOnRange}
                            onChange={activeOnRange => {
                                form.setValues({
                                    activeOnRange,
                                });
                            }}
                            minDate={minDate}
                            openToDate={nowISODate()}
                        />
                    }
                    errorMessage={form.currentErrors.activeOnRange?.toString()}
                    isRequired
                />
                <LabeledSwitch
                    label='Generate invoices'
                    checked={form.values.generateInvoices}
                    onChange={generateInvoices =>
                        form.setValues({
                            generateInvoices,
                        })
                    }
                />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
