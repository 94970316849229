import { AddonRefDto } from '@hofy/api-shared';
import { Price } from '@hofy/global';

export const BillingMethod = {
    Currency: 'currency' as const,
    Token: 'token' as const,
};

interface TokenUsage {
    addon: AddonRefDto;
    multiplier: string;
}

interface CurrencyServiceCost {
    billingMethod: typeof BillingMethod.Currency;
    price: Price;
}

interface TokenServiceCost {
    billingMethod: typeof BillingMethod.Token;
    tokenUsage: TokenUsage;
}

export type ServiceCostDto = CurrencyServiceCost | TokenServiceCost;
