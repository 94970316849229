import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, UserAssignmentDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

import { useAssignmentSelection } from '../shipments/useAssignmentSelection';

interface InPersonTransferFormData {
    toUserId: UUID | null;
    allowTransfersBetweenTaxZones: boolean;
}

interface ValidatedInPersonTransferFormData {
    toUserId: UUID;
    allowTransfersBetweenTaxZones: boolean;
}

export const useInPersonTransfer = (selectedAssignments: UserAssignmentDto[], onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const assignmentSelection = useAssignmentSelection(selectedAssignments);

    const mutation = useMutation({
        mutationFn: assignmentService.inPersonTransfer,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'In-person transfer successful',
            });
            onSuccess?.();
        },
    });

    const form = useForm<InPersonTransferFormData, ValidatedInPersonTransferFormData>({
        initial: {
            toUserId: null,
            allowTransfersBetweenTaxZones: false,
        },
        validate: validator<InPersonTransferFormData>({
            toUserId: isRequired('Choosing a user is required'),
        }),
        onSubmit: data => {
            mutation.mutate({
                assignmentIds: assignmentSelection.assignments.map(a => a.id),
                toUserId: data.toUserId,
                allowTransfersBetweenTaxZones: data.allowTransfersBetweenTaxZones,
            });
        },
    });

    return {
        form,
        assignmentSelection,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
