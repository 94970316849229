import React, { FC } from 'react';

import { ContractType, contractTypeColors, useTrAnyContractType } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ContractTypeChipProps {
    type: ContractType;
}

export const ContractTypeChip: FC<ContractTypeChipProps> = ({ type }) => {
    const trContractType = useTrAnyContractType();
    return <Badge dot color={contractTypeColors[type]} label={trContractType(type)} />;
};
