/* eslint-disable import/no-cycle */
import { stringifyUrl } from '@hofy/global';
import { isResponseError, publicRestClient, restClient } from '@hofy/rest';

import { AddLoginEmailPayload } from './types/AddLoginEmailPayload';
import { AuthError, isAuthError } from './types/AuthError';
import { ChangePasswordPayload } from './types/ChangePasswordPayload';
import { InvitePayload } from './types/InvitePayload';
import { OrganizationSignupPayload } from './types/OrganizationSignupPayload';
import { RefreshTokenPayload } from './types/RefreshTokenPayload';
import { ResetPasswordPayload } from './types/ResetPasswordPayload';
import { SendEmailVerificationPayload } from './types/SendEmailVerificationPayload';
import { SessionDto } from './types/SessionDto';
import { SetPasswordPayload } from './types/SetPasswordPayload';
import { SignInPayload } from './types/SignInPayload';
import { SignUpPayload } from './types/SignUpPayload';
import { SwitchIdentityPayload } from './types/SwitchIdentityPayload';
import { TokenPair, TokenPairDto, toTokenPair } from './types/TokenPairDto';

interface ConnectionDto {
    signInProvider: string | null;
}

interface TokenDto {
    token: string;
    email: string;
}

class AuthService {
    public getSession = async (): Promise<SessionDto> => {
        return restClient.getJson<SessionDto>('/api/auth/session');
    };

    public acceptTermsAndConditions = async (): Promise<void> => {
        return restClient.post('/api/auth/session/terms-and-conditions');
    };

    public signIn = async (payload: SignInPayload): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/auth/login', {
                email: payload.email,
                password: payload.password,
                code: payload.code || null,
            });
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };
    public switchIdentity = async (payload: SwitchIdentityPayload): Promise<TokenPair | AuthError> => {
        try {
            const token = await restClient.postJson<TokenPairDto>('/api/auth/switch-identity', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };

    public refresh = async (payload: RefreshTokenPayload): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/auth/refresh', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };

    public exchangeTemporary = async (payload: { token: string }): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/auth/exchange', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };

    public resetPassword = (payload: ResetPasswordPayload): Promise<void> => {
        return publicRestClient.post('/api/auth/reset-password', payload);
    };

    public setPassword = async (payload: SetPasswordPayload): Promise<TokenPair | AuthError> => {
        try {
            const token = await publicRestClient.postJson<TokenPairDto>('/api/auth/set-password', payload);
            return toTokenPair(token);
        } catch (r) {
            if (isResponseError(r) && isAuthError(r.response?.code)) {
                return r.response?.code as AuthError;
            }
            return Promise.reject(r);
        }
    };

    public changePassword = (payload: ChangePasswordPayload): Promise<void> => {
        return restClient
            .post('/api/auth/change-password', {
                currentPassword: payload.currentPassword || null,
                newPassword: payload.newPassword,
            })
            .then();
    };

    public getSignInProvider = async (email: string): Promise<string | undefined> => {
        const response = await publicRestClient.getJson<ConnectionDto>(
            stringifyUrl({ url: '/api/auth/sign-in-provider', query: { email } }),
        );
        return response.signInProvider || undefined;
    };

    public getChatToken = (scriptKey: string): Promise<TokenDto> => {
        return restClient.getJson<TokenDto>('/api/auth/zendesk-token/' + scriptKey);
    };

    public signUp = (p: SignUpPayload): Promise<void> =>
        restClient
            .post(`/api/auth/sign-up`, {
                token: p.token,
                password: p.password || null,
            })
            .then();

    public verifyEmail = (p: SendEmailVerificationPayload): Promise<void> =>
        restClient
            .post('/api/auth/self-sign-up/init', {
                email: p.email,
                recaptchaToken: p.recaptchaToken,
            })
            .then();

    public organizationSignUp = async (p: OrganizationSignupPayload): Promise<TokenPair | AuthError> => {
        const token = await restClient.postJson<TokenPairDto>('/api/auth/self-sign-up', p);
        return toTokenPair(token);
    };

    public getOrganizationSignUpFeature = async (): Promise<{ enabled: boolean }> => {
        return await restClient.getJson('/api/auth/organization-signup-feature-flag');
    };

    public addLoginEmail = (p: AddLoginEmailPayload): Promise<void> =>
        restClient
            .post(`/api/auth/add-email`, {
                token: p.token,
            })
            .then();

    public mergeIdentity = (p: AddLoginEmailPayload): Promise<void> =>
        restClient
            .post(`/api/auth/merge-identity`, {
                token: p.token,
            })
            .then();

    public sendInvite = (p: InvitePayload): Promise<void> =>
        restClient
            .post(`/api/auth/invite/${p.userId} `, {
                emailType: p.emailType,
            })
            .then();
}

export const authService = new AuthService();
