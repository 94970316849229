import React, { FC } from 'react';

import { Box, BoxProps, LabeledSelect } from '@hofy/ui';

import { useProductI18n } from '../store/useProductI18n';

export interface ProductDropdownsProps extends BoxProps {
    size?: string;
    style?: string;
    styles: string[];
    sizes: string[];
    isRequired?: boolean;
    isError?: boolean;
    onChangeStyle(v: string): void;
    onChangeSize(v: string): void;
}

export const ProductDropdowns: FC<ProductDropdownsProps> = ({
    size,
    style,
    styles,
    sizes,
    onChangeSize,
    onChangeStyle,
    isRequired = false,
    isError = false,
    ...boxProps
}) => {
    const { tr } = useProductI18n();

    const hasStyles = styles.length > 0;
    const hasSizes = sizes.length > 0;

    if (!hasStyles && !hasSizes) {
        return null;
    }

    const isSizeDisabled = hasStyles && !style;

    return (
        <Box column gap={16} {...boxProps}>
            {hasStyles && (
                <LabeledSelect
                    label={tr('product-dropdowns.style')}
                    onChange={onChangeStyle}
                    options={styles}
                    value={style}
                    toText={s => s}
                    placeholder={tr('product-dropdowns.placeholder.select')}
                    errorMessage={
                        isError && !style ? tr('product-dropdowns.style.error.required') : undefined
                    }
                    isRequired={isRequired}
                />
            )}
            {hasSizes && (
                <LabeledSelect
                    label={tr('product-dropdowns.size')}
                    onChange={onChangeSize}
                    options={sizes}
                    value={size}
                    toText={s => s}
                    placeholder={tr('product-dropdowns.placeholder.select')}
                    disabled={isSizeDisabled}
                    errorMessage={
                        isError && !size && !isSizeDisabled
                            ? tr('product-dropdowns.size.error.required')
                            : undefined
                    }
                    isRequired={isRequired}
                />
            )}
        </Box>
    );
};
