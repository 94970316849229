import { useState } from 'react';
import { useDebounce } from 'react-use';

import { AssignmentWithProductDto } from '@hofy/api-admin';
import { VariantDetailsDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

const SEARCH_DELAY = 400;

interface FastCodeScanCallbacks {
    orderFound(item: AssignmentWithProductDto): void;
    findCode(q: string): Promise<SomeItem | null>;
}

interface SomeItem {
    id: UUID | number;
    variant: VariantDetailsDto;
}

export const useFastCodeScan = (
    assignments: AssignmentWithProductDto[],
    inputQuery: string,
    { orderFound, findCode }: FastCodeScanCallbacks,
) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const query = inputQuery.trim();

    useDebounce(
        async () => {
            if (query === '') {
                return;
            }

            const foundItem = await findCode(query);

            if (!foundItem) {
                setErrorMessage('Unknown code');
                return;
            }

            const foundItems = assignments.filter(({ variant }) => {
                return variant.id === foundItem.variant.id;
            });

            if (foundItems.length === 0) {
                setErrorMessage('Item code belongs to another product');
                return;
            }

            setErrorMessage(undefined);

            if (foundItems.length === 1) {
                orderFound(foundItems[0]);
                return;
            }

            const sameMatchingItem = assignments.filter(({ variant, item }) => {
                return variant.id === foundItem.variant.id && item?.id === foundItem.id;
            });
            if (sameMatchingItem.length > 0) {
                orderFound(sameMatchingItem[0]);
                return;
            }

            const foundItemsWithoutScannedCode = assignments.filter(({ variant, item }) => {
                return variant.id === foundItem.variant.id && !item;
            });

            if (foundItemsWithoutScannedCode.length > 0) {
                orderFound(foundItemsWithoutScannedCode[0]);
            }
        },
        SEARCH_DELAY,
        [query],
    );

    return {
        errorMessage,
    };
};
