import React, { FC } from 'react';

import { OrganizationDetailsDto, organizationIsTrial } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { usePermission } from '@hofy/permission';
import {
    Alert,
    AsyncButton,
    Button,
    FormContainer,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateOrganizationCurrency } from '../../../store/organizations/useUpdateOrganizationCurrency';
import { CurrencyFormDropdown } from './CurrencyFormDropdown';

interface CurrencyUpdateModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const CurrencyUpdateModal: FC<CurrencyUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationCurrency(organization, onClose);
    const { hasPermission } = usePermission();
    const { tr } = useAdminI18n();

    const hasSpecialUpdatePermission = hasPermission(Permission.AdminOrganizationUpdateCurrency);
    const isTrialOrgWithoutContracts = organizationIsTrial(organization) && !organization.hasContracts;
    const currencyUpdateAllowed =
        (hasSpecialUpdatePermission || isTrialOrgWithoutContracts) && !organization.isPartner;

    if (!currencyUpdateAllowed) {
        return (
            <Modal onClose={onClose} width={450}>
                <ModalHeader
                    title={tr('organization-page.currency-update.non-trial-not-allowed-modal.title')}
                />
                <ModalContent>
                    <Paragraph3>
                        {tr('organization-page.currency-update.non-trial-not-allowed-modal.subtitle')}
                    </Paragraph3>
                </ModalContent>
                <ModalFooter>
                    <Button
                        label={tr('organization-page.currency-update.non-trial-not-allowed-modal.confirm')}
                        onClick={onClose}
                    />
                </ModalFooter>
            </Modal>
        );
    }

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={tr('organization-page.currency-update.allowed-modal.title')} />
            <ModalContent>
                <FormContainer>
                    <Alert
                        marginBottom={16}
                        type='warning'
                        title={tr('organization-page.currency-update.allowed-modal.subtitle')}
                        description={tr(
                            isTrialOrgWithoutContracts
                                ? 'organization-page.currency-update.allowed-modal.description-trial-org'
                                : 'organization-page.currency-update.allowed-modal.description-non-trial-org',
                        )}
                    />
                    <CurrencyFormDropdown
                        label='Currency'
                        onChange={currency =>
                            form.setValues({
                                currency,
                            })
                        }
                        value={form.values.currency}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton
                    label={tr('organization-page.currency-update.allowed-modal.cancel')}
                    onClick={onClose}
                />
                <AsyncButton
                    label={tr('organization-page.currency-update.allowed-modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
