import { ColorDye, getEnumValues } from '@hofy/global';

export enum PaymentProvider {
    Stripe = 'stripe',
    TwoRecourse = 'two_recourse',
    TwoNonRecourse = 'two_non_recourse',
}

export const paymentProviderColor: Record<PaymentProvider, ColorDye> = {
    [PaymentProvider.Stripe]: 'purple',
    [PaymentProvider.TwoRecourse]: 'blue',
    [PaymentProvider.TwoNonRecourse]: 'green',
};

export const allPaymentProviders = getEnumValues<PaymentProvider>(PaymentProvider);
