import React from 'react';

import { ChangeOwnershipPayload } from '@hofy/api-admin';
import { allOwnerships, Ownership, Permission, useTrOwnership } from '@hofy/api-shared';
import { FormSelect, UseForm } from '@hofy/ui';
import { PermissionWrapper } from '@hofy/ui-domain';

import { FormBillingEntitySelect } from '../../../components/domain/billingEntity/FormBillingEntitySelect';

interface UpdateOwnershipormProps {
    form: UseForm<ChangeOwnershipPayload>;
}

export const UpdateOwnershipForm = ({ form }: UpdateOwnershipormProps) => {
    const trOwnership = useTrOwnership();
    return (
        <>
            <PermissionWrapper permission={Permission.AdminItemsChangeLocation} tooltip>
                <FormSelect
                    label='Ownership'
                    api={form.fields.ownership}
                    toText={trOwnership}
                    options={allOwnerships}
                />
            </PermissionWrapper>
            {form.fields.ownership.value === Ownership.Organization && (
                <FormBillingEntitySelect label='Billing entity' api={form.fields.billingEntityId} nullable />
            )}
        </>
    );
};
