import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AddLoanerPayload, assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { isRequired, useForm, useToast, validator } from '@hofy/ui';

interface AddLoanerForm {
    deliveryAddressId: UUID | null;
    isBillable: boolean;
}

export const useAddLoaner = (assignmentId: UUID, addressId: UUID, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: AddLoanerPayload) => assignmentService.addLoaner(assignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Loaner created',
            });
            onSuccess?.();
        },
    });

    const form = useForm<AddLoanerForm>({
        initial: {
            deliveryAddressId: addressId,
            isBillable: false,
        },
        initialDeps: [addressId],
        onSubmit: mutation.mutate,
        validate: validator<AddLoanerForm, AddLoanerPayload>({
            deliveryAddressId: isRequired('Delivery address is required'),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
