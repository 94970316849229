import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { invoiceEntryService } from '../service/invoiceEntryService';
import { AdminInvoiceEntriesFilters } from '../service/types/AdminInvoiceEntriesFilters';
import { InvoiceEntryDto } from '../service/types/InvoiceEntryDto';
import { invoiceEntriesQueryKey } from './invoiceEntriesQueryKey';

export const useInvoiceEntries = (filters: AdminInvoiceEntriesFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [invoiceEntriesQueryKey, filters],
        queryFn: param =>
            invoiceEntryService.listInvoiceEntries(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const invoiceEntries: InvoiceEntryDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        invoiceEntries: invoiceEntries,
        isLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
