export enum CollectionRequestStatus {
    Active = 'active',
    Escalated = 'escalated',
    Deferred = 'deferred',
    Completed = 'completed',
    Cancelled = 'cancelled',
}

export interface CollectionRequestStatusAware {
    status: CollectionRequestStatus;
}
