export const activityLogTypeI18n = {
    'activity-log-type.item-created-with-user': 'Item created with user',
    'activity-log-type.item-created-with-user-as-replacement': 'Item created with user as replacement',
    'activity-log-type.item-created-with-user-as-loaner': 'Item created with user as loaner',
    'activity-log-type.item-created-with-user-from-dropshipped-order':
        'Item created with user from dropshipped order',
    'activity-log-type.item-created-at-hofy-warehouse': 'Item created at hofy warehouse',
    'activity-log-type.item-created-at-organization-warehouse': 'Item created at organization warehouse',
    'activity-log-type.item-pending-delivery': 'Item is pending delivery',
    'activity-log-type.item-pending-collection': 'Item is pending collection',
    'activity-log-type.item-pending-transfer': 'Item is pending transfer',
    'activity-log-type.item-collected-from-user': 'Item collected from user',
    'activity-log-type.item-transferred-between-users': 'Item is transferred between users',
    'activity-log-type.item-archived': 'Items archived',
    'activity-log-type.item-collected-to-hofy-warehouse': 'Item collected to Hofy warehouse',
    'activity-log-type.item-collected-to-organization-warehouse': 'Item collected to organization warehouse',
    'activity-log-type.item-delivered-from-hofy-warehouse': 'Item delivered from hofy warehouse',
    'activity-log-type.item-delivered-from-organization-warehouse':
        'Item delivered from organization warehouse by Hofy',
    'activity-log-type.item-self-serve-delivered-from-organization-warehouse':
        'Item delivered from organization warehouse by organization',
    'activity-log-type.item-pending-repair': 'Item pending repair',
    'activity-log-type.item-repaired': 'Item repaired',
    'activity-log-type.item-replaced': 'Item replaced',
    'activity-log-type.item-repair-cancelled': 'Item repair was cancelled',
    'activity-log-type.item-inspected': 'Item inspected',
    'activity-log-type.item-collection-requested': 'Item collection requested',
    'activity-log-type.item-collection-request-email-sent': 'Item collection request email sent',
    'activity-log-type.item-collection-request-email-reminder-sent':
        'Item collection request email reminder sent',
    'activity-log-type.item-collection-request-sms-reminder-sent':
        'Item collection request SMS reminder sent',
    'activity-log-type.item-collection-request-escalated': 'Item collection request escalated',
    'activity-log-type.item-collection-request-cancelled': 'Item collection request cancelled',
    'activity-log-type.item-collection-request-completed': 'Item collection request completed',
    'activity-log-type.item-collection-booked': 'Item collection booked',
};
