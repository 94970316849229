import { useMutation } from '@tanstack/react-query';

import { dataSeedService, emptySeedWarehouseItemsPayload, SeedWarehouseItemsPayload } from '@hofy/api-admin';
import { isGreaterThan, isRequired, useForm, useToast, validator } from '@hofy/ui';

export const useSeedWarehouseItems = () => {
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: dataSeedService.seedWarehouseItems,
        onSuccess: async () => {
            showToast({
                type: 'positive',
                message: 'Items generated',
            });
        },
    });

    const form = useForm<SeedWarehouseItemsPayload>({
        initial: emptySeedWarehouseItemsPayload,
        onSubmit: mutation.mutate,
        validate: validator<SeedWarehouseItemsPayload>({
            indexFrom: [isRequired('Index from is required'), isGreaterThan(0, 'From must be valid')],
            indexTo: [isRequired('Index to is required'), isGreaterThan(0, 'To must be valid')],
        }),
    });
    return { form, isLoading: mutation.isPending };
};
