import { motion } from 'framer-motion';
import React, { ElementRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { Box, OuterBoxProps, Pressable } from '../../base';

export interface SwitchProps extends OuterBoxProps {
    checked: boolean;
    onChange?(v: boolean): void;
    onFocus?(): void;
    onBlur?(): void;
    disabled?: boolean;
    size?: SwitchSize;
    id?: string;
}

export type SwitchSize = 'normal' | 'small';

export const Switch = forwardRef<ElementRef<'div'>, SwitchProps>(
    (
        {
            checked,
            onChange,
            onFocus,
            onBlur,

            disabled: switchDisabled,
            size = 'normal',
            id,
            ...rest
        },
        ref,
    ) => {
        const disabled = useIsDisabled(switchDisabled);

        const { width, height } = switchSizes[size];
        const knobSize = height - knobPadding * 2;
        const x = checked ? width - knobSize - knobPadding : knobPadding;

        return (
            <SwitchToggleBox
                ref={ref}
                onClick={() => {
                    if (!disabled) {
                        onChange?.(!checked);
                    }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                checked={checked}
                inactive={disabled}
                rounded={32}
                width={width}
                height={height}
                row
                role='switch'
                aria-checked={checked}
                aria-labelledby={id}
                {...rest}
            >
                <Box
                    bg={Color.InteractionInvertedNormal}
                    rect={knobSize}
                    rounded={32}
                    as={motion.div}
                    initial={{ x }}
                    animate={{ x }}
                    transition={{ duration: 0.2 }}
                />
            </SwitchToggleBox>
        );
    },
);

const knobPadding = 2;

const switchSizes: Record<SwitchSize, { width: number; height: number }> = {
    normal: { width: 36, height: 20 },
    small: { width: 28, height: 16 },
};

const hover = css`
    &:hover {
        background-color: ${Color.InteractionDisabledHover};
    }
`;

const hoverChecked = css`
    &:hover {
        background-color: ${Color.InteractionDefaultHover};
    }
`;

const active = css`
    &:active {
        background-color: ${Color.InteractionDisabledActive};
    }
`;

const activeChecked = css`
    &:active {
        background-color: ${Color.InteractionDefaultActive};
    }
`;

const SwitchToggleBox = styled(Pressable)<{ checked: boolean }>`
    background-color: ${p => (p.checked ? Color.InteractionDefaultNormal : Color.InteractionDisabledNormal)};
    ${p => (p.inactive ? undefined : p.checked ? hoverChecked : hover)}
    ${p => (p.inactive ? undefined : p.checked ? activeChecked : active)}
    transition: background-color 150ms ease-in-out;
`;
