import React, { FC } from 'react';

import { useTrTaxIdLabel } from '@hofy/api-shared';
import { Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { Alert } from '@hofy/ui';

import { useAddressI18n } from '../store/useAddressI18n';

interface TaxIdInfoPaneProps {
    country: Country;
}

export const TaxIdInfoPane: FC<TaxIdInfoPaneProps> = ({ country }) => {
    const { tr } = useAddressI18n();
    const trCountry = useTrCountry();
    const trTaxIdLabel = useTrTaxIdLabel();
    return (
        <Alert
            type='warning'
            title={tr('tax-id-pane.title', {
                taxIdLabel: trTaxIdLabel(country),
            })}
            description={tr('tax-id-pane.description', {
                country: trCountry(country),
            })}
        />
    );
};
