import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Box, MarginBoxProps } from '@hofy/ui';

interface FormRowProps extends MarginBoxProps {
    stretchItems?: boolean;
    children?: ReactNode;
}

export const FormRow: FC<FormRowProps> = ({ stretchItems = true, children, ...margins }) => {
    return (
        <Wrapper stretchItems={stretchItems} row alignItems='flex-start' {...margins}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled(Box)<{ stretchItems: boolean }>`
    & > *:not(:last-child) {
        margin-right: 24px;
    }
    ${p =>
        p.stretchItems &&
        css`
            & > * {
                flex: 1;
            }
        `}
`;
