import React, { FC, ReactNode } from 'react';

import { Box, Heading3, Paragraph3 } from '../../base';
import { IconButton, SvgIcon } from '../../icon';
import { useModalContext } from '../context/ModalContext';

interface ModalHeaderProps {
    title: ReactNode;
    subtitle?: ReactNode;
    titleSlot?: ReactNode;
    children?: ReactNode;
    borderBottom?: boolean;
}

export const ModalHeader: FC<ModalHeaderProps> = ({ title, subtitle, titleSlot, children, borderBottom }) => {
    const { headerClose, onClose } = useModalContext();

    const titleContent =
        typeof title === 'string' ? (
            <Box>
                <Heading3>{title}</Heading3>
                {subtitle && <Paragraph3 paddingTop={4}>{subtitle}</Paragraph3>}
            </Box>
        ) : (
            title
        );

    return (
        <Box
            paddingTop={24}
            paddingHorizontal={24}
            paddingBottom={borderBottom ? 24 : 0}
            column
            gap={24}
            borderBottom={borderBottom}
            role='heading'
            data-test-key='modal-header'
        >
            <Box gap={16} row justify='space-between'>
                {titleContent}
                {titleSlot}
                {headerClose && (
                    <Box alignSelf='flex-start' data-test-key='modal-close-button-parent'>
                        <IconButton icon={SvgIcon.X} onClick={onClose} />
                    </Box>
                )}
            </Box>
            {children}
        </Box>
    );
};
