export enum ShipmentError {
    AssignmentContainsConsumableItems = 'cannot_transfer_consumable_item_assignment',
    AssignmentContainsItemsNotInvoiceable = 'cannot_transfer_items_that_are_not_invoicable',
    ShipmentContainsAssignmentsCollectedForStoreAndReuse = 'cannot_transfer_assignments_that_are_being_collected_for_store_and_reuse',
    NotMatchingAssignmentRegionWithAssigneeUser = 'cannot_transfer_items_that_have_subcontracts_in_a_different_region_to_the_user',
    NoPurchaseOrder = 'no_purchase_order',
    ItemsNotAssigned = 'no_items_assigned',
    ItemsNotScanned = 'found_unscanned_items',
    InvalidShipmentStatus = 'invalid_shipment_status',
    AddressCountryChanged = 'invalid_country_changed',
    InvalidNumberOfBoxes = 'number_of_boxes',
    NoAssignmentsToShip = 'no_assignments_to_ship',
    InvalidAssignmentType = 'invalid_assignment_type',
    InvalidAssignmentStatus = 'invalid_assignment_status',
    InvalidSelectedAssignments = 'invalid_selected_assignments',
    AssignmentsBelongToDifferentUsers = 'assignments_belong_to_different_users',
    InvalidShipmentPublicAddress = 'invalid_shipment_public_address',
    MissingShipmentHofyWarehouse = 'missing_shipment_hofy_warehouse',
    InvalidShipmentHofyWarehouse = 'invalid_shipment_hofy_warehouse',
    MissingShipmentEstimateStartOn = 'missing_shipment_estimate_start_on',
    AssignmentIsOnHold = 'assignment_is_on_hold',
    AssignmentStatusNotDeliveryPending = 'assignment_should_be_in_delivery_pending_status',
    ItemsInDifferentOrganizationWarehouses = 'items_at_different_organization_warehouses',
    ItemsPartiallyAtWarehouse = 'items_partially_at_warehouse',
    InvalidShipmentState = 'cannot_create_survey_for_shipments_that_are_with_courier_completed_or_canceled',
    InvalidShipmentType = 'can_only_create_survey_for_shipments_of_type_collection_or_transfer',
}
