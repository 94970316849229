export const signInPageI18n = {
    'sign-in-page.title': 'Sign in to Hofy',
    'sign-in-page.2fa.title': 'Authentication',
    'sign-in-page.2fa.subtitle': 'Enter the 6-digit code from your authenticator app',
    'sign-in-page.sign-up.link': 'Sign up',
    'sign-in-page.divider.sso': 'Sign in with',
    'sign-in-page.divider.email': 'or, with email',
    'sign-in-page.email-password-form.sign-up': 'Sign up',
    'sign-in-page.email-password-form.sign-in': 'Sign in',
    'sign-in-page.email-password-form.email': 'Email',
    'sign-in-page.email-password-form.placeholder.email': 'example@hofy.com',
    'sign-in-page.email-password-form.code': 'Authentication code',
    'sign-in-page.email-password-form.password': 'Password',
    'sign-in-page.email-password-form.placeholder.password': 'Minimum 8 characters',
    'sign-in-page.email-password-form.forgot-password': 'Forgot password?',
    'sign-in-page.form.password.errors.not-empty': 'Password is required',
    'sign-in-page.form.code.errors.not-empty': 'Code is required',
    'sign-in-page.email-password-form.form.error.unknown':
        'Cannot authenticate, please contact your administrator',
};
