import React, { FC } from 'react';

import { Chevron } from '../../shared';
import { HeaderIcon } from './HeaderIcon';

interface TableSortIconProps {
    active?: boolean;
    sortDirection?: 'ASC' | 'DESC';
}

export const TableSortIcon: FC<TableSortIconProps> = ({ active, sortDirection }: TableSortIconProps) => (
    <HeaderIcon active={active} testKey='sort-icon'>
        <Chevron color='currentColor' isOpen={sortDirection === 'DESC'} />
    </HeaderIcon>
);
