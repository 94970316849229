import React, { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';

import { useLocalStorage } from '@hofy/hooks';

const NavigationContext = createContext({
    collapsed: false,
    toggleCollapsed: () => {},
});

export const useNavigationContext = () => {
    return useContext(NavigationContext);
};

interface NavigationContextProviderProps {
    children: ReactNode;
    startAsCollapsed?: boolean;
}

export const NavigationContextProvider: FC<NavigationContextProviderProps> = ({
    children,
    startAsCollapsed = false,
}) => {
    const [savedCollapsed, setSavedCollapsed] = useLocalStorage('ui:navigation-collapsed', startAsCollapsed);
    const [collapsed, setCollapsed] = useState(savedCollapsed);

    // Delay the update to avoid animation issues
    // because updating local storage is synchronous
    useDebounce(() => setSavedCollapsed(collapsed), 700, [collapsed]);

    const value = useMemo(
        () => ({
            collapsed,
            toggleCollapsed: () => setCollapsed(v => !v),
        }),
        [collapsed],
    );

    return <NavigationContext.Provider value={value}>{children}</NavigationContext.Provider>;
};
