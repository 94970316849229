import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

export const Hoverable = styled(Box).attrs({ pointer: true })(
    ({ color = Color.Neutral700 }) => `
    :hover {
        text-decoration: underline;
        text-decoration-color: ${color};
    }
`,
);
