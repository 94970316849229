import { useMutation, useQueryClient } from '@tanstack/react-query';

import { inspectionService, inspectionsQueryKey, UpdateInspectionPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useUpdateInspection = (uuid: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, UpdateInspectionPayload>({
        mutationFn: payload => inspectionService.updateInspection(uuid, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey, uuid] });
            onSuccess?.();
        },
    });

    return {
        updateInspection: (payload: UpdateInspectionPayload) => mutation.mutate(payload),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
