import { IssueDto, IssueFilter, useIssuesQuery } from '@hofy/api-admin';
import { formatUserName } from '@hofy/api-shared';
import { useSearch } from '@hofy/hooks';
import { useStringQueryParam } from '@hofy/router';

const emptyIssues: IssueDto[] = [];

export const useIssues = (tab: IssueFilter) => {
    const { data, isLoading, isError } = useIssuesQuery(tab);
    const [search, setSearch] = useStringQueryParam('search', '');

    const results = useSearch(
        data || emptyIssues,
        issue => [issue.id.toString(), formatUserName(issue.user), issue.organization.name],
        search,
    );
    return {
        data: results,
        isLoading,
        isError,
        search,
        setSearch,
        openIssuesLength: data?.length,
    };
};
