import React, { FC } from 'react';

import { CollectionRequestStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { DeferCollectionSurveyButton } from './DeferCollectionSurveyButton';
import { EscalateCollectionSurveyButton } from './EscalateCollectionSurveyButton';

interface CollectionSurveyStatusButtonsProps {
    collectionRequest: {
        status: CollectionRequestStatus;
        id: UUID;
    };
    shipmentId: UUID;
}

export const CollectionSurveyStatusButtons: FC<CollectionSurveyStatusButtonsProps> = ({
    collectionRequest: { id, status },
    shipmentId,
}) => {
    return (
        <>
            {![CollectionRequestStatus.Deferred, CollectionRequestStatus.Cancelled].includes(status) && (
                <EscalateCollectionSurveyButton
                    collectionRequestId={id}
                    escalated={status === CollectionRequestStatus.Escalated}
                    shipmentId={shipmentId}
                />
            )}
            {[CollectionRequestStatus.Deferred, CollectionRequestStatus.Escalated].includes(status) && (
                <DeferCollectionSurveyButton
                    collectionRequestId={id}
                    deferred={status === CollectionRequestStatus.Deferred}
                    shipmentId={shipmentId}
                />
            )}
        </>
    );
};
