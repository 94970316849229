import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { CreateExchangeRatePayload, exchangeRatesCacheKey, exchangeRatesService } from '@hofy/api-admin';
import { errorMap, getTomorrowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const useCreateExchangeRates = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: exchangeRatesService.createExchangeRate,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [exchangeRatesCacheKey],
            });
            showToast({
                type: 'positive',
                message: `The exchange rate has been created!`,
            });
            onSuccess();
        },
    });

    const form = useForm<CreateExchangeRatePayload>({
        initial: {
            applyOn: getTomorrowISODate(),
            rate: null,
            backRate: null,
        },
        onSubmit: mutation.mutate,
        validate: ({ applyOn, fromCurrency, toCurrency, rate, backRate }) => ({
            applyOn: errorMap([isEmpty(applyOn), 'Apply on date is required']),
            fromCurrency: errorMap([isEmpty(fromCurrency), 'Currency is required']),
            toCurrency: errorMap([isEmpty(toCurrency), 'Currency is required']),
            rate: errorMap([isEmpty(rate), 'Rate is required']),
            backRate: errorMap([isEmpty(backRate), 'Back rate is required']),
        }),
    });

    return {
        isLoading: mutation.isPending,
        form,
    };
};
