import React, { FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps, Overline2 } from '../components';

interface SlotPlaceholderProps extends OuterBoxProps {
    name: string;
}

export const SlotPlaceholder: FC<SlotPlaceholderProps> = ({ name, ...rest }) => {
    return (
        <LinedBox
            border
            borderColor={Color.NonContextualBorderDivider}
            rounded={8}
            bg={Color.BackgroundSubtleNeutral}
            overflow='hidden'
            centered
            padding={8}
            {...rest}
        >
            <Overline2 color={Color.ContentTertiary}>{name}</Overline2>
        </LinedBox>
    );
};

const LinedBox = styled(Box)`
    background: repeating-linear-gradient(
        45deg,
        ${Color.BackgroundSubtleNeutral},
        ${Color.BackgroundSubtleNeutral} 10px,
        ${Color.BackgroundDefault} 10px,
        ${Color.BackgroundDefault} 20px
    );
`;
