import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, Font } from '@hofy/ui';

interface FilterContainerProps {
    title: ReactNode;
    borderBottom?: boolean;
    children?: ReactNode;
}

/** @deprecated not used anymore */
export const FilterContainer: FC<FilterContainerProps> = ({ title, borderBottom, children }) => {
    return (
        <Box marginLeft={10} borderBottom={!!borderBottom}>
            <Font size={16} color={Color.Neutral700} marginBottom={16}>
                {title}
            </Font>
            {children}
        </Box>
    );
};
