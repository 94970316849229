import { difference } from 'lodash';

import { getEnumValues } from '@hofy/global';

export enum EmailType {
    Work = 'work',
    Personal = 'personal',
}

export const allEmailTypes = getEnumValues<EmailType>(EmailType);

export interface LoginProfileAware {
    workEmailLogin: boolean;
    personalEmailLogin: boolean;
}

export interface EmailsAware {
    workEmail: string | null;
    personalEmail: string | null;
}

export const notLoginEmailsForProfile = (p: LoginProfileAware): EmailType[] => {
    return difference(allEmailTypes, loginEmailsForProfile(p));
};

export const loginEmailsForProfile = (p: LoginProfileAware | null): EmailType[] => {
    if (!p) {
        return [];
    }
    const emails: EmailType[] = [];
    if (p.workEmailLogin) {
        emails.push(EmailType.Work);
    }
    if (p.personalEmailLogin) {
        emails.push(EmailType.Personal);
    }
    return emails;
};

export const fromEmailTypes = (types: EmailType[]) => {
    return {
        workEmailLogin: types.includes(EmailType.Work),
        personalEmailLogin: types.includes(EmailType.Personal),
    };
};

export const notEmptyEmailTypes = (p: EmailsAware): EmailType[] => {
    return difference(allEmailTypes, emptyEmailTypes(p));
};

export const emptyEmailTypes = (p: EmailsAware): EmailType[] => {
    const emptyEmails: EmailType[] = [];
    if (!p.workEmail) {
        emptyEmails.push(EmailType.Work);
    }
    if (!p.personalEmail) {
        emptyEmails.push(EmailType.Personal);
    }
    return emptyEmails;
};

export const invitationEmailType = (p: EmailsAware & LoginProfileAware): EmailType[] => {
    const invitationEmails: EmailType[] = [];
    if (p.workEmail && !p.workEmailLogin) {
        invitationEmails.push(EmailType.Work);
    }
    if (p.personalEmail && p.personalEmailLogin) {
        invitationEmails.push(EmailType.Personal);
    }
    return invitationEmails;
};
