import React, { FC } from 'react';

import {
    ContractDatesAware,
    getContractStatusDate,
    getRentalContractStatusDate,
    RentalContractDatesAware,
} from '@hofy/api-admin';
import { ContractType, getContractStatus, RentalContractStatus } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { Box, Paragraph3 } from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

interface ContractStatusLabelProps {
    contract: ContractDatesAware;
    simple?: boolean;
}

export const ContractStatusLabel: FC<ContractStatusLabelProps> = ({ contract, simple = true }) => {
    const date = () => {
        switch (contract.type) {
            case ContractType.Management:
                return null;
            case ContractType.Purchase:
                return formatDate(getContractStatusDate(contract));
            case ContractType.Rental:
                // eslint-disable-next-line no-case-declarations
                const contractDate = getContractStatusDate(contract);
                if (simple || contract.rentalContract!.status !== RentalContractStatus.Ended) {
                    return formatDate(contractDate);
                }
                // eslint-disable-next-line no-case-declarations
                const activeContractDate = getRentalContractStatusDate(
                    contract as RentalContractDatesAware,
                    RentalContractStatus.Active,
                );
                if (!activeContractDate) {
                    return null;
                }

                return `${formatDate(activeContractDate)} - ${formatDate(contractDate)}`;
        }
    };

    const status = contract && getContractStatus(contract);

    const dateString = date();
    if (!status) {
        return null;
    }
    return (
        <Box>
            <ContractStatusChip status={status} />
            {dateString && <Paragraph3 marginTop={4}>{date()}</Paragraph3>}
        </Box>
    );
};
