import { PublicRefDto } from './PublicRefDto';

export interface AdminRefDto extends PublicRefDto {
    email: string;
    firstName: string;
    lastName: string;
    isOffboarded: boolean;
    pictureUrl: string | null;
}

export const formatAdminName = (v: AdminRefDto) =>
    `${v.firstName} ${v.lastName}${v.isOffboarded ? ' (Offboarded)' : ''}`;
