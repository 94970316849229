import React, { FC } from 'react';

import { PostAddressView } from '@hofy/address';
import { InvoiceEntryDetailsDto } from '@hofy/api-admin';
import { CountryLabel } from '@hofy/core';
import { formatDate, formatDateTime } from '@hofy/helpers';
import { useDecimal } from '@hofy/hooks';
import { NumberValues } from '@hofy/theme';
import { BaseTable, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { TaxStatusChip } from './TaxStatusChip';

interface AvalaraSectionProps {
    entry: InvoiceEntryDetailsDto;
}

export const AvalaraSection: FC<AvalaraSectionProps> = ({ entry }) => {
    const avalara = entry.avalaraTransaction;
    const { formatPercent, formatRate } = useDecimal();
    return (
        <FormSection label='Tax calculation'>
            <FormGridRow columns={4}>
                <Labeled label='Status' content={<TaxStatusChip status={entry.tax.status} />} />
                {entry?.tax.predictionAt && (
                    <LabeledText label='Prediction at' content={formatDateTime(entry.tax.predictionAt)} />
                )}
                {entry?.tax.computedAt && (
                    <LabeledText label='Computed at' content={formatDateTime(entry.tax.computedAt)} />
                )}
                {entry?.tax.reportedAt && (
                    <LabeledText label='Reported at' content={formatDateTime(entry.tax.reportedAt)} />
                )}
                {!!avalara?.externalId && <LabeledText label='External id' content={avalara.externalId} />}
                {entry?.tax.errorAt && (
                    <LabeledText label='Error at' content={formatDateTime(entry.tax.errorAt)} />
                )}
                {entry?.tax.reportErrorAt && (
                    <LabeledText
                        label='Reporting error at'
                        content={formatDateTime(entry.tax.reportErrorAt)}
                    />
                )}
                {entry.avalaraTransaction?.exchangeRate && (
                    <LabeledText
                        label='Exchange rate'
                        content={formatRate(entry.avalaraTransaction?.exchangeRate)}
                    />
                )}
                {entry.avalaraTransaction?.sendExchangeRate && (
                    <LabeledText
                        label='Send exchange rate'
                        content={formatRate(entry.avalaraTransaction?.sendExchangeRate)}
                    />
                )}
                {entry.avalaraTransaction?.exchangeRateApplyOn && (
                    <LabeledText
                        label='Exchange rate apply on'
                        content={formatDate(entry.avalaraTransaction?.exchangeRateApplyOn)}
                    />
                )}
            </FormGridRow>
            {entry?.tax.errorReason && <LabeledText label='Error reason' content={entry.tax.errorReason} />}
            <FormGridRow columns={4}>
                {avalara?.fromAddress && (
                    <>
                        <Labeled
                            label='From country'
                            content={<CountryLabel country={avalara?.fromAddress.country} />}
                        />
                        <LabeledText
                            label='From address'
                            content={<PostAddressView address={avalara?.fromAddress} />}
                        />
                    </>
                )}
                {avalara?.toAddress && (
                    <>
                        <Labeled
                            label='To country'
                            content={<CountryLabel country={avalara?.toAddress.country} />}
                        />
                        <LabeledText
                            label='To address'
                            content={<PostAddressView address={avalara?.toAddress} />}
                        />
                    </>
                )}
            </FormGridRow>
            {!!avalara?.taxRateSummary.length && (
                <BaseTable
                    data={avalara?.taxRateSummary}
                    toKey={item => item.taxType + item.taxName}
                    height='auto'
                    inlineHeader
                    marginHorizontal={-40 as NumberValues}
                    columns={[
                        {
                            id: 'type',
                            header: 'Tax type',
                            renderer: item => item.taxType,
                        },
                        {
                            id: 'name',
                            header: 'Tax name',
                            renderer: item => item.taxName,
                        },
                        {
                            id: 'vat',
                            header: 'VAT code',
                            renderer: item => item.vatCode ?? '--',
                        },
                        {
                            id: 'rate',
                            header: 'Rate',
                            renderer: item => formatPercent(item.taxRate),
                        },
                        {
                            id: 'tax',
                            header: 'Tax',
                            renderer: item => item.tax,
                        },
                    ]}
                />
            )}
        </FormSection>
    );
};
