import React, { FC } from 'react';

import { useIsDisabled } from '../../../contexts';
import { useFormContext } from '../form/formContext';
import { AsyncButton, AsyncButtonProps } from './AsyncButton';

interface SubmitButtonProps extends Omit<AsyncButtonProps, 'onClick' | 'submit' | 'to' | 'isLoading'> {}

export const SubmitButton: FC<SubmitButtonProps> = props => {
    const disabled = useIsDisabled(props.disabled);
    const context = useFormContext();

    return (
        <AsyncButton
            {...props}
            onClick={() => {
                context.onSubmit();
            }}
            disabled={disabled}
            isLoading={context.isLoading}
            isError={context.isError}
        />
    );
};
