import React, { FC } from 'react';

import { orderDecisionStatusColor, OrderStatus, useTrOrderRequestStatus } from '@hofy/api-shared';
import { Badge, MarginBoxProps } from '@hofy/ui';

interface DecisionStatusProps extends MarginBoxProps {
    status: OrderStatus;
}

export const RequestDecisionStatusChip: FC<DecisionStatusProps> = ({ status, ...props }) => {
    const trRequestStatus = useTrOrderRequestStatus();

    return (
        <Badge
            color={orderDecisionStatusColor[status]}
            label={trRequestStatus(status)}
            data-test-key='decision-chip'
            {...props}
        />
    );
};
