import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { IssueFilter } from '@hofy/api-admin';
import { pathUuid, UUID } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { IssueDetailsOverlay } from './issueDetails/IssueDetailsOverlay';
import { IssuesPage } from './IssuesPage';

export const IssuesRouter: FC = () => {
    const history = useHistory();

    const handleIssueOpen = (id: UUID) => {
        history.push(`${AdminNavLink.Issues}/${id}`);
    };

    const handleOpenTab = (tab: IssueFilter) => {
        history.push(`${AdminNavLink.Issues}/${tab}`);
    };

    return (
        <Switch>
            <Route path={`${AdminNavLink.Issues}/${IssueFilter.Open}`}>
                <IssuesPage
                    filter={IssueFilter.Open}
                    onOpenTab={handleOpenTab}
                    onOpenIssue={handleIssueOpen}
                />
            </Route>
            <Route path={`${AdminNavLink.Issues}/${IssueFilter.All}`}>
                <IssuesPage
                    filter={IssueFilter.All}
                    onOpenTab={handleOpenTab}
                    onOpenIssue={handleIssueOpen}
                />
            </Route>
            <IssueRoute path={`${AdminNavLink.Issues}/:issueId(${pathUuid})`} exact>
                {({ issueId }) => <IssueDetailsOverlay issueId={issueId} />}
            </IssueRoute>
            <Redirect to='/issues/open' />
        </Switch>
    );
};

const IssueRoute = UUIDRoute('issueId', Route);
