import { Response } from '@hofy/global';

import { ResponseError } from '../types/ResponseError';

export const incorrectStatusesResponseInterceptor = async (r: Response): Promise<Response> => {
    if (r.status >= 200 && r.status < 300) {
        return Promise.resolve(r);
    } else {
        const error = await ResponseError.create(r);
        return Promise.reject(error);
    }
};
