import { getEnumValues } from '@hofy/global';

export enum RedistributableFilter {
    Yes = 'yes',
    No = 'no',
}

export const allRedistributableFilters = getEnumValues<RedistributableFilter>(RedistributableFilter);

export const redistributableFilterFromBoolean = (v: boolean | null) =>
    v === null ? null : v ? RedistributableFilter.Yes : RedistributableFilter.No;
export const redistributableFilterToBoolean = (v: RedistributableFilter | null) =>
    v === null ? null : v === RedistributableFilter.Yes;
