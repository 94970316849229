import { Country, Decimal } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { CountryMultiplierDto } from './types/CountryMultiplierDto';

interface CountryMultipliersResponse {
    countryMultipliers: CountryMultiplierDto[];
}

class CountryService {
    public getMultipliers = async (): Promise<CountryMultiplierDto[]> => {
        const response = await restClient.getJson<CountryMultipliersResponse>(
            '/api/admin/country-multipliers',
        );
        return response.countryMultipliers;
    };

    public updateMultiplier = async (country: Country, multiplier: Decimal): Promise<void> => {
        await restClient.put('/api/admin/country-multipliers', {
            country,
            multiplier,
        });
    };
}

export const countryService = new CountryService();
