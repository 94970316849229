export const platformTierI18n = {
    'platform-tier.user-based-lite': 'User Based Lite',
    'platform-tier.user-based-pro': 'User Based Pro',
    'platform-tier.user-based-enterprise': 'User Based Enterprise',
    'platform-tier.org-based-free': 'Organization Based Free',
    'platform-tier.org-based-entry': 'Organization Based Entry',
    'platform-tier.org-based-lite': 'Organization Based Lite',
    'platform-tier.org-based-pro': 'Organization Based Pro',
    'platform-tier.org-based-enterprise': 'Organization Based Enterprise',
};
