import { useEffect, useRef, useState } from 'react';

import { FormFieldApi } from './formTypes';

interface useAsyncInputFormDataOptions<V> {
    api: FormFieldApi<string | null>;
    dataProvider(input: string): Promise<V>;
}

interface useAsyncInputFormDataReturn<V> {
    inputValue: string;
    setInputValue(v: string): void;
    data: V | null;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
}

/**
 * This hook allows fetching data asynchronously based on the typed value in an input form field.
 *
 * It provides the props required for setting/getting a form field value, as well as the fetched data and its status.
 *
 * It accepts a FormFieldApi<string | null> instance and takes care of setting it based on the fetched data status,
 * 
 * It also ensures that stale fetched data is dismissed so that it doesn't show up on the input.
 * 
 * It finally accounts for when the api value is updated from the outside to enable two-way binding.
 * 
 * @example: 
```ts
    const {
        inputValue,
        setInputValue,
        data: fetchedBin,
        isLoading,
    } = useAsyncInputFormData({
        api,
        dataProvider: inputValue => warehouseService.getWarehouseBinByIdentifier(warehouseId, inputValue),
    });

    return (
        <LabeledDebouncedInput
            ref={api.ref}
            label='Warehouse bin identifier'
            value={inputValue}
            onChange={setInputValue}
            rightSlot={isLoading ? <Spinner size={24} /> : getInputIcon(fetchedBin, isRequired)}
            errorMessage={api.errorMessage}
            onBlur={() => api.setTouched(true)}
            isRequired={isRequired}
            width={width}
        />
    );
```
 */
export const useAsyncInputFormData = <V>({
    api,
    dataProvider,
}: useAsyncInputFormDataOptions<V>): useAsyncInputFormDataReturn<V> => {
    const [inputValue, setInputValue] = useState(api.value || '');
    const inputValueVersion = useRef(0);

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const [data, setData] = useState<V | null>(null);

    useEffect(() => {
        inputValueVersion.current++;
        api.setValue(null);
        setIsError(false);
        setError(null);

        if (!inputValue) {
            setIsLoading(false);
            setData(null);
            return;
        }

        setIsLoading(true);
        const dataVersion = inputValueVersion.current;
        const isStale = () => dataVersion !== inputValueVersion.current;

        dataProvider(inputValue)
            .then(data => {
                if (isStale()) {
                    return;
                }
                setData(data);
                api.setValue(inputValue);
            })
            .catch(error => {
                if (isStale()) {
                    return;
                }
                setData(null);
                setIsError(true);
                setError(error);
                api.setValue(null);
            })
            .finally(() => {
                if (isStale()) {
                    return;
                }
                setIsLoading(false);
                api.setTouched(true);
            });
    }, [inputValue]);

    useEffect(() => {
        if (api.value) {
            setInputValue(api.value);
        }
    }, [api.value]);

    return { inputValue, setInputValue, data, isLoading, isError, error };
};
