import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { CreateRepairSlideout } from './CreateRepairSlideout';

interface CreateRepairSlideoutRouterProps {
    base: string;
    onClose(): void;
}

export const CreateRepairSlideoutRouter: FC<CreateRepairSlideoutRouterProps> = ({ base, onClose }) => {
    return (
        <Route path={base}>
            <CreateRepairSlideout onClose={onClose} />
        </Route>
    );
};
