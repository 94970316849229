import { Permission } from '@hofy/api-shared';
import { getEnumValues } from '@hofy/global';

export enum InventoryTab {
    StockLevels = 'stock-levels',
    PurchaseOrders = 'purchase-orders',
    Suppliers = 'suppliers',
    BYODOrders = 'byod-orders',
}

export const InventoryTabLink = {
    [InventoryTab.StockLevels]: 'stock-levels',
    [InventoryTab.PurchaseOrders]: 'purchase-orders',
    [InventoryTab.Suppliers]: 'suppliers',
    [InventoryTab.BYODOrders]: 'byod-orders',
};

export const inventoryTabPermissions: Record<InventoryTab, Permission> = {
    [InventoryTab.StockLevels]: Permission.AdminItemsView,
    [InventoryTab.PurchaseOrders]: Permission.AdminPurchaseOrdersView,
    [InventoryTab.Suppliers]: Permission.AdminSuppliersView,
    [InventoryTab.BYODOrders]: Permission.AdminBYODOrdersView,
};

export const allInventoryTabs = getEnumValues<InventoryTab>(InventoryTab);
