import { ColorDye, getEnumValues } from '@hofy/global';

export enum RepairStatus {
    RepairStatusRequestedFromUser = 'requested_from_user',

    RepairStatusTroubleshootingByIT = 'troubleshooting_by_technical_support',
    RepairStatusResolvedByIT = 'resolved_by_technical_support',

    RepairStatusPendingInternalPartDelivery = 'pending_internal_part_delivery',
    RepairStatusRepairedByInternalPart = 'repaired_by_internal_part',

    RepairStatusPendingCollectionToWarehouse = 'pending_collection_to_warehouse',
    RepairStatusCollectedToWarehouse = 'collected_to_warehouse',

    RepairStatusRequestedFromWarehouse = 'requested_from_warehouse',

    RepairStatusTroubleshootingByManufacturer = 'troubleshooting_by_manufacturer',

    RepairStatusRepairedByManufacturerOnsite = 'repaired_by_manufacturer_onsite',

    RepairStatusPendingRepairByManufacturer = 'pending_repair_by_manufacturer',
    RepairStatusRepairedByManufacturer = 'repaired_by_manufacturer',

    RepairStatusPendingReplacementByManufacturer = 'pending_replacement_by_manufacturer',
    RepairStatusReplacedByManufacturer = 'replaced_by_manufacturer',
}

export const allRepairStatuses = getEnumValues<RepairStatus>(RepairStatus);

const initialRepairStatuses = new Set([
    RepairStatus.RepairStatusRequestedFromUser,
    RepairStatus.RepairStatusRequestedFromWarehouse,
]);
export const isInitialRepairStatus = (status: RepairStatus): boolean => initialRepairStatuses.has(status);

const resolvedRepairStatusWithItemRepaired = new Set([
    RepairStatus.RepairStatusResolvedByIT,
    RepairStatus.RepairStatusRepairedByInternalPart,
    RepairStatus.RepairStatusRepairedByManufacturerOnsite,
    RepairStatus.RepairStatusRepairedByManufacturer,
]);

export const isResolvedRepairStatusWithItemRepaired = (status: RepairStatus): boolean =>
    resolvedRepairStatusWithItemRepaired.has(status);

const resolvedRepairStatusWithItemNotRepaired = new Set([RepairStatus.RepairStatusReplacedByManufacturer]);

const resolvedRepairStatuses = new Set([
    ...resolvedRepairStatusWithItemRepaired,
    ...resolvedRepairStatusWithItemNotRepaired,
]);
export const isResolvedRepairStatus = (status: RepairStatus): boolean => resolvedRepairStatuses.has(status);

const nextValidRepairStatuses: Record<RepairStatus, RepairStatus[]> = {
    [RepairStatus.RepairStatusRequestedFromUser]: [
        RepairStatus.RepairStatusTroubleshootingByIT,
        RepairStatus.RepairStatusTroubleshootingByManufacturer,
        RepairStatus.RepairStatusPendingInternalPartDelivery,
        RepairStatus.RepairStatusPendingCollectionToWarehouse,
    ],
    [RepairStatus.RepairStatusTroubleshootingByIT]: [
        RepairStatus.RepairStatusResolvedByIT,
        RepairStatus.RepairStatusTroubleshootingByManufacturer,
        RepairStatus.RepairStatusPendingInternalPartDelivery,
    ],
    [RepairStatus.RepairStatusResolvedByIT]: [],
    [RepairStatus.RepairStatusPendingInternalPartDelivery]: [RepairStatus.RepairStatusRepairedByInternalPart],
    [RepairStatus.RepairStatusRepairedByInternalPart]: [],
    [RepairStatus.RepairStatusPendingCollectionToWarehouse]: [RepairStatus.RepairStatusCollectedToWarehouse],
    [RepairStatus.RepairStatusCollectedToWarehouse]: [RepairStatus.RepairStatusTroubleshootingByManufacturer],
    [RepairStatus.RepairStatusRequestedFromWarehouse]: [
        RepairStatus.RepairStatusTroubleshootingByManufacturer,
    ],
    [RepairStatus.RepairStatusTroubleshootingByManufacturer]: [
        RepairStatus.RepairStatusRepairedByManufacturerOnsite,
        RepairStatus.RepairStatusRepairedByInternalPart,
        RepairStatus.RepairStatusPendingRepairByManufacturer,
        RepairStatus.RepairStatusPendingReplacementByManufacturer,
    ],
    [RepairStatus.RepairStatusRepairedByManufacturerOnsite]: [],
    [RepairStatus.RepairStatusPendingRepairByManufacturer]: [RepairStatus.RepairStatusRepairedByManufacturer],
    [RepairStatus.RepairStatusRepairedByManufacturer]: [],
    [RepairStatus.RepairStatusPendingReplacementByManufacturer]: [
        RepairStatus.RepairStatusReplacedByManufacturer,
    ],
    [RepairStatus.RepairStatusReplacedByManufacturer]: [],
};
export const getNextValidRepairStatuses = (status: RepairStatus): RepairStatus[] => {
    return nextValidRepairStatuses[status];
};

const repairStatusLabels: Record<RepairStatus, string> = {
    [RepairStatus.RepairStatusRequestedFromUser]: 'Repair requested from user',

    [RepairStatus.RepairStatusTroubleshootingByIT]: 'Troubleshooting by IT',
    [RepairStatus.RepairStatusResolvedByIT]: 'Resolved by IT',

    [RepairStatus.RepairStatusPendingInternalPartDelivery]: 'Pending internal part delivery',
    [RepairStatus.RepairStatusRepairedByInternalPart]: 'Repaired by internal part',

    [RepairStatus.RepairStatusPendingCollectionToWarehouse]: 'Pending collection to warehouse',
    [RepairStatus.RepairStatusCollectedToWarehouse]: 'Collected at warehouse',

    [RepairStatus.RepairStatusRequestedFromWarehouse]: 'Repair requested from warehouse',

    [RepairStatus.RepairStatusTroubleshootingByManufacturer]: 'Troubleshooting by manufacturer',

    [RepairStatus.RepairStatusRepairedByManufacturerOnsite]: 'Repaired by manufacturer on site',

    [RepairStatus.RepairStatusPendingRepairByManufacturer]: 'Pending repair by manufacturer',
    [RepairStatus.RepairStatusRepairedByManufacturer]: 'Repaired by manufacturer',

    [RepairStatus.RepairStatusPendingReplacementByManufacturer]: 'Pending replacement by manufacturer',
    [RepairStatus.RepairStatusReplacedByManufacturer]: 'Replaced by manufacturer',
};
export const getRepairStatusLabel = (status: RepairStatus): string => repairStatusLabels[status];

// repair status colors
export const getRepairStatusColor = (status: RepairStatus): ColorDye => {
    if (initialRepairStatuses.has(status)) {
        return 'magenta';
    }

    if (resolvedRepairStatuses.has(status)) {
        return 'green';
    }

    return 'orange';
};
