import { isString } from 'lodash';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, BoxProps, HiddenScroll, Icon, SectionTitle3, SvgIcon } from '@hofy/ui';

interface BlockFilterProps extends PropsWithChildren<BoxProps> {
    icon?: Svg;
    title: ReactNode;
}

export const BlockFilter: FC<BlockFilterProps> = ({ title, icon, children, ...boxProps }) => {
    return (
        <Box column gap={10} width={220} fullHeight {...boxProps}>
            <Box row paddingHorizontal={12} paddingTop={12}>
                {isString(title) ? (
                    <>
                        <Icon
                            svg={icon || SvgIcon.FilterLines}
                            size={16}
                            marginRight={10}
                            color={Color.ContentPrimary}
                        />
                        <SectionTitle3 textNoWrap ellipsis overflow='hidden'>
                            {title}
                        </SectionTitle3>
                    </>
                ) : (
                    title
                )}
            </Box>
            <Box flex='auto' marginHorizontal={-12}>
                <HiddenScroll fullHeight paddingHorizontal={12} paddingBottom={12}>
                    <Box column gap={2}>
                        {children}
                    </Box>
                </HiddenScroll>
            </Box>
        </Box>
    );
};
