import React, { FC } from 'react';

import { BetaFeatureDto, useBetaFeaturesQuery } from '@hofy/api-admin';
import { useTrBetaFeatureName } from '@hofy/api-shared';
import { BaseTable, BooleanBadge, Box, ErrorStatePlaceholder } from '@hofy/ui';

import { useAdminI18n } from '../../../i18n/useAdminI18n';

interface BetaFeaturesTabProps {
    onEdit(key: BetaFeatureDto): void;
}

export const BetaFeaturesTab: FC<BetaFeaturesTabProps> = ({ onEdit }) => {
    const { data: features, isError, isLoading } = useBetaFeaturesQuery();
    const { trPlural } = useAdminI18n();
    const trBetaFeature = useTrBetaFeatureName();

    if (isError) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Box column flex='auto'>
            <Box column flex='auto'>
                <BaseTable
                    data={features}
                    isLoading={isLoading}
                    toKey={feature => feature.id}
                    onRowClick={onEdit}
                    flex='auto'
                    columns={[
                        {
                            id: 'name',
                            header: 'Name',
                            flexGrow: 1,
                            renderer: feature => trBetaFeature(feature.name),
                        },
                        {
                            id: 'betaFeaturesOrganizations',
                            header: 'Organizations',
                            flexGrow: 1,
                            renderer: feature => trPlural('organizations', feature.organizations.length),
                        },
                        {
                            id: 'globally_enabled',
                            header: 'Globally enabled',
                            flexGrow: 1,
                            renderer: feature => <BooleanBadge value={feature.globallyEnabled} />,
                        },
                        {
                            id: 'active',
                            header: 'Active',
                            flexGrow: 1,
                            renderer: feature => <BooleanBadge value={feature.active} />,
                        },
                    ]}
                />
            </Box>
        </Box>
    );
};
