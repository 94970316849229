import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { ListUserRefsFilter } from '../service/types/ListUserRefsFilter';
import { userService } from '../service/userService';
import { usersCacheKey } from './cacheKey';

export const useUserRefsQuery = (filter: ListUserRefsFilter) => {
    const {
        data = [],
        isLoading,
        isPending,
    } = useQuery({
        queryKey: [usersCacheKey, filter],
        queryFn: () => userService.getUserRefs(filter),
        placeholderData: keepPreviousData,
    });
    return {
        data,
        isLoading,
        isPending,
    };
};
