import React, { ElementRef, forwardRef, useId } from 'react';

import { Labeled } from '../../../labeled';
import { PhoneInput, PhoneInputNullableStringProps, PhoneInputOnlyStringProps } from './PhoneInput';

interface BaseLabeledPhoneInputProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

type LabeledFormNormalPhoneInputProps = Omit<PhoneInputOnlyStringProps, 'isError'> &
    BaseLabeledPhoneInputProps;
type LabeledFormNullablePhoneInputProps = Omit<PhoneInputNullableStringProps, 'isError'> &
    BaseLabeledPhoneInputProps;

export type LabeledPhoneInputProps = LabeledFormNormalPhoneInputProps | LabeledFormNullablePhoneInputProps;

export const LabeledPhoneInput = forwardRef<ElementRef<'input'>, LabeledPhoneInputProps>(
    (
        {
            label,
            isRequired,
            errorMessage,
            helperText,

            initialCountry,

            value,
            onChange,
            onBlur,
            onFocus,
            type,
            nullable,
            placeholder,
            disabled,
            clearable,
            testKey,
            autoFocus,
            rightSlot,

            ...rest
        },
        ref,
    ) => {
        const id = useId();
        return (
            <Labeled
                as='label'
                label={label}
                htmlFor={id}
                isRequired={isRequired}
                content={
                    <PhoneInput
                        id={id}
                        ref={ref}
                        value={value!}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        type={type}
                        nullable={nullable}
                        placeholder={placeholder}
                        disabled={disabled}
                        clearable={clearable}
                        initialCountry={initialCountry}
                        testKey={testKey}
                        autoFocus={autoFocus}
                        rightSlot={rightSlot}
                        isError={!!errorMessage}
                    />
                }
                errorMessage={errorMessage}
                helperText={helperText}
                {...rest}
            />
        );
    },
);
