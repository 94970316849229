import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { ZIndex } from '@hofy/theme';

import { easeInOutTransition } from '../../../config/animations';
import { Box } from '../../base';
import { NavigationColors } from '../NavigationColors';
import { useNavigationContext } from '../NavigationContext';
import { sizeVariants } from './fadeVariants';

interface NavigationContainerProps {
    children?: ReactNode;
}

export const NavigationContainer: FC<NavigationContainerProps> = ({ children }) => {
    const { collapsed } = useNavigationContext();

    return (
        <ContainerBox
            as={motion.div}
            column
            gap={8}
            paddingVertical={24}
            paddingHorizontal={16}
            fullHeight
            initial={false}
            animate={collapsed ? 'small' : 'large'}
            variants={sizeVariants}
            transition={easeInOutTransition}
            data-test-key='menu'
        >
            {children}
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)`
    background: ${NavigationColors.Background};
    will-change: width;
    position: relative;
    z-index: ${ZIndex.Navigation};
`;
