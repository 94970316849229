import React, { FC } from 'react';

import { Button, SvgIcon } from '@hofy/ui';

interface CancelButtonProps {
    onClick(): void;
    label?: string;
}

export const CancelButton: FC<CancelButtonProps> = ({ onClick, label = 'Cancel' }) => (
    <Button type='secondary' onClick={onClick} label={label} leftIcon={SvgIcon.Cross} testKey='cancel' />
);
