import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    OrganizationDetailsDto,
    organizationService,
    UpdateDataErasureSettingsPayload,
} from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateDataErasureSettings = (organization: OrganizationDetailsDto, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (p: UpdateDataErasureSettingsPayload) =>
            organizationService.updateOrganizationDataErasureSettings(organization.id, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                type: 'positive',
                message: `Organization data erasure settings successfully updated`,
            });
            onSuccess();
        },
    });

    const form = useForm<UpdateDataErasureSettingsPayload>({
        initial: {
            enabled: organization.dataErasureEnabled,
            fee: organization.pricing.dataErasureFee,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
