import React, { FC, ReactNode } from 'react';

import { NumberValues } from '@hofy/theme';

import { Box, BoxProps } from '../../base/index';
import { FormContainer } from './FormContainer';
import { LabelDivider } from './LabelDivider';

interface FormSectionProps extends BoxProps {
    label: ReactNode;
    gap?: NumberValues;
    labelGap?: NumberValues;
}

export const FormSection: FC<FormSectionProps> = ({
    label,
    gap = 20,
    labelGap = gap,
    children,
    ...boxProps
}) => {
    return (
        <Box data-test-key='form-section' {...boxProps}>
            <LabelDivider marginBottom={labelGap}>{label}</LabelDivider>
            <FormContainer spacing={gap}>{children}</FormContainer>
        </Box>
    );
};
