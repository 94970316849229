import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { ShipmentStatus } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Box } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useBookShipment } from '../../../store/shipments/useBookShipment';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';
import { ShipmentBookForm } from './ShipmentBookForm';

interface BookShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const BookShipmentSlideout: FC<BookShipmentSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading } = useBookShipment(shipment, onClose);

    return (
        <Slideout width={600} onClose={onClose} slideoutId='shipment-mark-as-processed'>
            <SlideoutHeader title='Mark as processed' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.Booked}
                />
                <Box marginBottom={30}>
                    <ShipmentBookForm form={form} shipment={shipment} />
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} onClick={form.submit} label='Mark as processed' />
            </SlideoutFooter>
        </Slideout>
    );
};
