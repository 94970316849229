export const deleteRejectedPlansModalI18n = {
    'delete-rejected-plans.title': 'Delete rejected plans',
    'delete-rejected-plans.subtitle': 'Are you sure you want to delete rejected plans?',
    'delete-rejected-plans.cancel': 'Cancel',
    'delete-rejected-plans.confirm': 'Delete',

    'delete-rejected-plan.title': 'Delete rejected plan',
    'delete-rejected-plan.subtitle': 'Are you sure you want to delete this plan?',
    'delete-rejected-plan.cancel': 'Cancel',
    'delete-rejected-plan.confirm': 'Delete',
};
