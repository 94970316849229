import React, { ElementRef, forwardRef } from 'react';

import { Input } from '../../input/Input';
import { useNumberInput } from './hooks/useNumberInput';
import { NumberInputProps } from './types/NumberInputProps';

export const NumberInput = forwardRef<ElementRef<'input'>, NumberInputProps>((props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { value, onChange, onBlur, nullable, ...rest } = props;
    const { stringValue, change, blur } = useNumberInput(props);

    return <Input ref={ref} value={stringValue} onChange={change} onBlur={blur} {...rest} />;
});
