import { useMemo } from 'react';

import { useAuth } from './useAuth';
import { useSessionQuery } from './useSessionQuery';

export const useSessionState = () => {
    const sessionQueryState = useSessionQuery();
    const { storageToken } = useAuth();

    const session = useMemo(
        () => ({
            ...sessionQueryState,
            provider: storageToken?.provider || 'password',
            hasPii: true,
        }),
        [sessionQueryState, storageToken],
    );
    const permissions = useMemo(
        () => ({
            roles: storageToken?.roles || [],
            permissions: sessionQueryState.session?.permissions || [],
            roleMapping: sessionQueryState.session?.roleMapping || {},
        }),
        [storageToken, sessionQueryState],
    );

    return {
        session,
        permissions,
        isInitialized: sessionQueryState.isInitialized,
    };
};
