import { ColorDye, getEnumValues } from '@hofy/global';

export enum OrganizationStatus {
    Active = 'active',
    Trial = 'trial',
    Suspended = 'suspended',
    Archived = 'archived',
    ArchivedTrial = 'archived_trial',
}

export const organizationStatusColor: Record<OrganizationStatus, ColorDye> = {
    [OrganizationStatus.Active]: 'green',
    [OrganizationStatus.Trial]: 'violet',
    [OrganizationStatus.Suspended]: 'grey',
    [OrganizationStatus.Archived]: 'blue',
    [OrganizationStatus.ArchivedTrial]: 'blue',
};

export const allOrganizationStatuses = getEnumValues<OrganizationStatus>(OrganizationStatus);
export const trialOrganizationStatuses = [OrganizationStatus.Trial, OrganizationStatus.ArchivedTrial];

export const organizationStatusTransitionMap: Record<OrganizationStatus, OrganizationStatus[]> = {
    [OrganizationStatus.Active]: [
        OrganizationStatus.Active,
        OrganizationStatus.Trial,
        OrganizationStatus.Suspended,
        OrganizationStatus.Archived,
    ],
    [OrganizationStatus.Trial]: [
        OrganizationStatus.Active,
        OrganizationStatus.Trial,
        OrganizationStatus.Archived,
        OrganizationStatus.ArchivedTrial,
    ],
    [OrganizationStatus.Suspended]: [
        OrganizationStatus.Active,
        OrganizationStatus.Suspended,
        OrganizationStatus.Archived,
    ],
    [OrganizationStatus.Archived]: [
        OrganizationStatus.Active,
        OrganizationStatus.Trial,
        OrganizationStatus.Suspended,
        OrganizationStatus.Archived,
    ],
    [OrganizationStatus.ArchivedTrial]: [
        OrganizationStatus.Trial,
        OrganizationStatus.Archived,
        OrganizationStatus.ArchivedTrial,
    ],
};
