import React, { FC } from 'react';

import { PurchaseOrderItemDto } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { MoreMenu } from '@hofy/common';
import { usePermission } from '@hofy/permission';
import { SvgIcon } from '@hofy/ui';

interface PurchaseOrderLineActionProps {
    purchaseOrderItem: PurchaseOrderItemDto;
    handlePurchaseOrderItemDeliveryUpdate(purchaseOrderItem: PurchaseOrderItemDto): void;
    handlePurchaseOrderItemQuantityUpdate(purchaseOrderItem: PurchaseOrderItemDto): void;
}

export const PurchaseOrderItemActions: FC<PurchaseOrderLineActionProps> = ({
    purchaseOrderItem,
    handlePurchaseOrderItemDeliveryUpdate,
    handlePurchaseOrderItemQuantityUpdate,
}) => {
    const { hasPermission } = usePermission();

    return (
        <MoreMenu
            marginLeft={16}
            items={[
                {
                    label: 'Update estimated delivery',
                    action: () => handlePurchaseOrderItemDeliveryUpdate(purchaseOrderItem),
                    visible: hasPermission(Permission.AdminPurchaseOrdersUpdate),
                    icon: SvgIcon.Edit,
                },
                {
                    label: 'Update ordered quantity',
                    action: () => handlePurchaseOrderItemQuantityUpdate(purchaseOrderItem),
                    visible: hasPermission(Permission.AdminPurchaseOrdersUpdate),
                    icon: SvgIcon.Edit,
                },
            ]}
        />
    );
};
