import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    assignmentService,
    emptyEnableStoreAndReuseDeliveryPayload,
    EnableStoreAndReuseDeliveryPayload,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useForm, useToast } from '@hofy/ui';

export const useEnableStoreAndReuseDelivery = (assignmentId: UUID, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (p: EnableStoreAndReuseDeliveryPayload) =>
            assignmentService.enableStoreAndReuseDelivery(assignmentId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Store and reuse',
                message: 'Store and reuse delivery enabled',
            });
            onSuccess();
        },
    });

    const form = useForm<EnableStoreAndReuseDeliveryPayload>({
        initial: emptyEnableStoreAndReuseDeliveryPayload,
        onSubmit: mutation.mutate,
    });

    return {
        form,
    };
};
