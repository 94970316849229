import { useMutation, useQueryClient } from '@tanstack/react-query';

import { productCacheKey, productsService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useAdminSyncProductsToNetsuite = () => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: productsService.syncAllToNetsuite,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [productCacheKey] });
            showToast({
                type: 'positive',
                message: 'Synced to Netsuite',
            });
        },
    });

    return {
        syncToNetsuite: mutation.mutate,
        isLoading: mutation.isPending,
    };
};
