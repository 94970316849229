import React, { FC, ReactNode } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { mobileTheme, theme } from '@hofy/theme';

import { useRWD } from '../contexts/rwd/useRWD';

interface ThemeProviderProps {
    children?: ReactNode;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const { isMobile } = useRWD();

    return <SCThemeProvider theme={isMobile ? mobileTheme : theme}>{children}</SCThemeProvider>;
};
