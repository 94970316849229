import { getEnumValues } from './Enum';

export enum Currency {
    EUR = 'EUR',
    AED = 'AED',
    AFN = 'AFN',
    XCD = 'XCD',
    ALL = 'ALL',
    AMD = 'AMD',
    AOA = 'AOA',
    ARS = 'ARS',
    USD = 'USD',
    AUD = 'AUD',
    AWG = 'AWG',
    AZN = 'AZN',
    BAM = 'BAM',
    BDT = 'BDT',
    XOF = 'XOF',
    BGN = 'BGN',
    BHD = 'BHD',
    BIF = 'BIF',
    BMD = 'BMD',
    SGD = 'SGD',
    BOB = 'BOB',
    BRL = 'BRL',
    BSD = 'BSD',
    BTN = 'BTN',
    BWP = 'BWP',
    BYN = 'BYN',
    BZD = 'BZD',
    CAD = 'CAD',
    CDF = 'CDF',
    XAF = 'XAF',
    CHF = 'CHF',
    NZD = 'NZD',
    CLP = 'CLP',
    CNY = 'CNY',
    COP = 'COP',
    CRC = 'CRC',
    CUC = 'CUC',
    CVE = 'CVE',
    ANG = 'ANG',
    CZK = 'CZK',
    DJF = 'DJF',
    DOP = 'DOP',
    DZD = 'DZD',
    EGP = 'EGP',
    MAD = 'MAD',
    ERN = 'ERN',
    ETB = 'ETB',
    FJD = 'FJD',
    FKP = 'FKP',
    DKK = 'DKK',
    GBP = 'GBP',
    GEL = 'GEL',
    GHS = 'GHS',
    GIP = 'GIP',
    GMD = 'GMD',
    GNF = 'GNF',
    GTQ = 'GTQ',
    GYD = 'GYD',
    HKD = 'HKD',
    HNL = 'HNL',
    HRK = 'HRK',
    HTG = 'HTG',
    HUF = 'HUF',
    IDR = 'IDR',
    ILS = 'ILS',
    INR = 'INR',
    IQD = 'IQD',
    IRR = 'IRR',
    ISK = 'ISK',
    JMD = 'JMD',
    JOD = 'JOD',
    JPY = 'JPY',
    KES = 'KES',
    KGS = 'KGS',
    KHR = 'KHR',
    KMF = 'KMF',
    KPW = 'KPW',
    KRW = 'KRW',
    WST = 'WST',
    KWD = 'KWD',
    KYD = 'KYD',
    KZT = 'KZT',
    LAK = 'LAK',
    LBP = 'LBP',
    LKR = 'LKR',
    LRD = 'LRD',
    LSL = 'LSL',
    LTL = 'LTL',
    LYD = 'LYD',
    MDL = 'MDL',
    MGA = 'MGA',
    MKD = 'MKD',
    MMK = 'MMK',
    MNT = 'MNT',
    MRU = 'MRU',
    MUR = 'MUR',
    MVR = 'MVR',
    MWK = 'MWK',
    MXN = 'MXN',
    MYR = 'MYR',
    MZN = 'MZN',
    NAD = 'NAD',
    XPF = 'XPF',
    NGN = 'NGN',
    NIO = 'NIO',
    NOK = 'NOK',
    NPR = 'NPR',
    OMR = 'OMR',
    PAB = 'PAB',
    PEN = 'PEN',
    PGK = 'PGK',
    PHP = 'PHP',
    PKR = 'PKR',
    PLN = 'PLN',
    PYG = 'PYG',
    QAR = 'QAR',
    RON = 'RON',
    RSD = 'RSD',
    RUB = 'RUB',
    RWF = 'RWF',
    SAR = 'SAR',
    SBD = 'SBD',
    SCR = 'SCR',
    SDG = 'SDG',
    SEK = 'SEK',
    SHP = 'SHP',
    SLL = 'SLL',
    SOS = 'SOS',
    SRD = 'SRD',
    SSP = 'SSP',
    STD = 'STD',
    SYP = 'SYP',
    SZL = 'SZL',
    THB = 'THB',
    TJS = 'TJS',
    TMT = 'TMT',
    TND = 'TND',
    TOP = 'TOP',
    TRY = 'TRY',
    TTD = 'TTD',
    TWD = 'TWD',
    TZS = 'TZS',
    UAH = 'UAH',
    UGX = 'UGX',
    UYU = 'UYU',
    UZS = 'UZS',
    VEF = 'VEF',
    VND = 'VND',
    VUV = 'VUV',
    YER = 'YER',
    ZAR = 'ZAR',
    ZMW = 'ZMW',
    ZWD = 'ZWD',
}

export const allCurrencies = getEnumValues<Currency>(Currency);
export const coreCurrencies = [Currency.GBP, Currency.USD, Currency.EUR];

export const currencySymbols: Record<Currency, string> = {
    [Currency.EUR]: '€',
    [Currency.AED]: 'د.إ',
    [Currency.AFN]: '؋',
    [Currency.XCD]: '$',
    [Currency.ALL]: 'L',
    [Currency.AMD]: '֏',
    [Currency.AOA]: 'Kz',
    [Currency.ARS]: '$',
    [Currency.USD]: '$',
    [Currency.AUD]: '$',
    [Currency.AWG]: 'Afl',
    [Currency.AZN]: '₼',
    [Currency.BAM]: 'KM',
    [Currency.BDT]: '৳',
    [Currency.XOF]: 'CFA',
    [Currency.BGN]: 'лв',
    [Currency.BHD]: 'BD',
    [Currency.BIF]: 'FBu',
    [Currency.BMD]: '$',
    [Currency.SGD]: '$',
    [Currency.BOB]: 'Bs',
    [Currency.BRL]: 'R$',
    [Currency.BSD]: '$',
    [Currency.BTN]: 'Nu.',
    [Currency.BWP]: 'P',
    [Currency.BYN]: 'Br',
    [Currency.BZD]: '$',
    [Currency.CAD]: '$',
    [Currency.CDF]: 'FCFA',
    [Currency.XAF]: 'FCFA',
    [Currency.CHF]: 'Fr.',
    [Currency.NZD]: '$',
    [Currency.CLP]: '$',
    [Currency.CNY]: '¥',
    [Currency.COP]: '$',
    [Currency.CRC]: '₡',
    [Currency.CUC]: '$',
    [Currency.CVE]: '$',
    [Currency.ANG]: 'ƒ',
    [Currency.CZK]: 'Kč',
    [Currency.DJF]: 'Fdj',
    [Currency.DOP]: '$',
    [Currency.DZD]: 'DA',
    [Currency.EGP]: 'E£',
    [Currency.MAD]: 'DH',
    [Currency.ERN]: 'Nkf',
    [Currency.ETB]: 'Br',
    [Currency.FJD]: '$',
    [Currency.FKP]: '£',
    [Currency.DKK]: 'kr',
    [Currency.GBP]: '£',
    [Currency.GEL]: '₾',
    [Currency.GHS]: 'GH₵',
    [Currency.GIP]: '£',
    [Currency.GMD]: 'D',
    [Currency.GNF]: 'FG',
    [Currency.GTQ]: 'Q',
    [Currency.GYD]: '$',
    [Currency.HKD]: '$',
    [Currency.HNL]: 'L',
    [Currency.HRK]: 'kn',
    [Currency.HTG]: 'G',
    [Currency.HUF]: 'Ft',
    [Currency.IDR]: 'Rp',
    [Currency.ILS]: '₪',
    [Currency.INR]: '₹',
    [Currency.IQD]: 'د.ع',
    [Currency.IRR]: '﷼',
    [Currency.ISK]: 'kr',
    [Currency.JMD]: '$',
    [Currency.JOD]: 'د.أ',
    [Currency.JPY]: '¥',
    [Currency.KES]: 'KSh',
    [Currency.KGS]: 'Лв',
    [Currency.KHR]: '៛',
    [Currency.KMF]: 'CF',
    [Currency.KPW]: '₩',
    [Currency.KRW]: '₩',
    [Currency.WST]: 'WS$',
    [Currency.KWD]: 'KD',
    [Currency.KYD]: '$',
    [Currency.KZT]: '₸',
    [Currency.LAK]: '₭',
    [Currency.LBP]: 'LL',
    [Currency.LKR]: 'රු',
    [Currency.LRD]: '$',
    [Currency.LSL]: 'M',
    [Currency.LTL]: 'Lt',
    [Currency.LYD]: 'LD',
    [Currency.MDL]: 'L',
    [Currency.MGA]: 'Ar',
    [Currency.MKD]: 'den',
    [Currency.MMK]: 'K',
    [Currency.MNT]: '₮',
    [Currency.MRU]: 'UM',
    [Currency.MUR]: '₨',
    [Currency.MVR]: 'Rf',
    [Currency.MWK]: 'K',
    [Currency.MXN]: '$',
    [Currency.MYR]: 'RM',
    [Currency.MZN]: 'MT',
    [Currency.NAD]: '$',
    [Currency.XPF]: '₣',
    [Currency.NGN]: '₦',
    [Currency.NIO]: 'C$',
    [Currency.NOK]: 'kr',
    [Currency.NPR]: 'रू',
    [Currency.OMR]: 'ر.ع.',
    [Currency.PAB]: 'B/.',
    [Currency.PEN]: 'S/',
    [Currency.PGK]: 'K',
    [Currency.PHP]: '₱',
    [Currency.PKR]: '₨',
    [Currency.PLN]: 'zł',
    [Currency.PYG]: '₲',
    [Currency.QAR]: 'QR',
    [Currency.RON]: 'RON',
    [Currency.RSD]: 'din',
    [Currency.RUB]: '₽',
    [Currency.RWF]: 'FRw',
    [Currency.SAR]: 'SR',
    [Currency.SBD]: '$',
    [Currency.SCR]: 'SR',
    [Currency.SDG]: 'SD',
    [Currency.SEK]: 'kr',
    [Currency.SHP]: '£',
    [Currency.SLL]: 'Le',
    [Currency.SOS]: 'Sh.So.',
    [Currency.SRD]: '$',
    [Currency.SSP]: 'SD',
    [Currency.STD]: 'Db',
    [Currency.SYP]: 'LS',
    [Currency.SZL]: 'L',
    [Currency.THB]: '฿',
    [Currency.TJS]: 'ЅM',
    [Currency.TMT]: 'T',
    [Currency.TND]: 'DT',
    [Currency.TOP]: 'T$',
    [Currency.TRY]: '₺',
    [Currency.TTD]: '$',
    [Currency.TWD]: 'NT$',
    [Currency.TZS]: 'TSh',
    [Currency.UAH]: '₴',
    [Currency.UGX]: 'USh',
    [Currency.UYU]: '$',
    [Currency.UZS]: 'som',
    [Currency.VEF]: 'Bs.S.',
    [Currency.VND]: '₫',
    [Currency.VUV]: 'VT',
    [Currency.YER]: '﷼',
    [Currency.ZAR]: 'R',
    [Currency.ZMW]: 'K',
    [Currency.ZWD]: '$',
};
