import React, { FC } from 'react';

import { getPurchaseContractStatusDate, getRentalContractStatusDate } from '@hofy/api-admin';
import {
    allPurchaseContractStatuses,
    allRentalContractStatuses,
    ContractDtoWithManagement,
    ContractDtoWithPurchase,
    ContractDtoWithRental,
    ContractType,
    ContractUnionDto,
    isEndingRental,
    useTrContractEndOption,
    useTrPurchaseContractStatus,
    useTrRentalContractStatus,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { StatusHistory } from '@hofy/common';
import { useFormatPriceByContractType } from '@hofy/hooks';
import { Badge, FormGridRow, Labeled, LabeledText } from '@hofy/ui';
import { ContractStatusChip } from '@hofy/ui-domain';

import { ContractPaymentTypeChip } from '../../../components/domain/contracts/ContractPaymentTypeChip';
import { ContractTypeChip } from '../../../components/domain/contracts/ContractTypeChip';
import { StoreAndReuseLocationChip } from '../../../components/domain/contracts/StoreAndReuseOptionChip';
import { ContractLinkLabel } from '../../contractsPage/ContractLink';

interface ContractDetailsProps {
    contract: ContractUnionDto;
}

export const ContractDetails: FC<ContractDetailsProps> = ({ contract }) => {
    switch (contract.type) {
        case ContractType.Rental:
            return <RentalContractDetails contract={contract} />;
        case ContractType.Purchase:
            return <PurchaseContractDetails contract={contract} />;
        case ContractType.Management:
            return <ManagementContractDetails contract={contract} />;
        default:
            return null;
    }
};

interface PurchaseContractDetailsProps {
    contract: ContractDtoWithPurchase;
}

const PurchaseContractDetails: FC<PurchaseContractDetailsProps> = ({ contract }) => {
    const trContractStatus = useTrPurchaseContractStatus();

    const { formatAnyPrice } = useFormatPriceByContractType();

    return (
        <>
            <FormGridRow columns={4}>
                <LabeledText label='Id' content={<ContractLinkLabel id={contract} />} />
                <Labeled label='Contract type' content={<ContractTypeChip type={contract.type} />} />
                <Labeled
                    label='Status'
                    content={<ContractStatusChip status={contract.purchaseContract.status} />}
                />
                <Labeled
                    label='Payment Type'
                    content={<ContractPaymentTypeChip type={contract.paymentType} />}
                />
                <LabeledText
                    flex={1}
                    label='Purchase price'
                    content={formatAnyPrice(contract.purchaseContract.price, contract.type)}
                />
            </FormGridRow>
            <Labeled
                label='History'
                content={
                    <StatusHistory
                        allStatuses={allPurchaseContractStatuses}
                        dateGetter={s => getPurchaseContractStatusDate(contract, s)}
                        labelFormatter={trContractStatus}
                    />
                }
            />
        </>
    );
};

interface RentalContractDetailsProps {
    contract: ContractDtoWithRental;
}

const RentalContractDetails: FC<RentalContractDetailsProps> = ({ contract }) => {
    const trContractStatus = useTrRentalContractStatus();
    const trRentalTerm = useTrRentalTerm();
    const trContractEndOption = useTrContractEndOption();
    const { formatAnyPrice } = useFormatPriceByContractType();
    const { executedContractEndOption } = contract.rentalContract;
    const isEnding = executedContractEndOption && isEndingRental(contract);

    return (
        <>
            <FormGridRow columns={4}>
                <LabeledText label='Id' content={<ContractLinkLabel id={contract} />} />
                <Labeled label='Contract type' content={<ContractTypeChip type={contract.type} />} />
                <Labeled
                    label='Status'
                    content={<ContractStatusChip status={contract.rentalContract.status} />}
                />
                <Labeled
                    label='Payment Type'
                    content={<ContractPaymentTypeChip type={contract.paymentType} />}
                />
                {isEnding && (
                    <Labeled
                        label='End of contract'
                        content={
                            <Badge color='orange' label={trContractEndOption(executedContractEndOption)} />
                        }
                    />
                )}
                {!isEnding && contract.rentalContract.estimatedEndOfContract && (
                    <LabeledText
                        label='Estimated end of contract'
                        content={contract.rentalContract.estimatedEndOfContract}
                    />
                )}
                {contract.rentalContract.rentalTerm && (
                    <LabeledText
                        label='Rental term'
                        content={trRentalTerm(contract.rentalContract.rentalTerm)}
                    />
                )}
                {contract.rentalContract.rentalLength !== null && (
                    <LabeledText label='Rental length' content={contract.rentalContract.rentalLength} />
                )}
                <LabeledText
                    flex={1}
                    label='Rental price'
                    content={formatAnyPrice(contract.rentalContract.price, contract.type)}
                />

                <Labeled
                    label='Store and reuse option'
                    content={
                        <StoreAndReuseLocationChip
                            location={contract.rentalContract.storeAndReuse?.collectToLocation || null}
                        />
                    }
                />
            </FormGridRow>
            <Labeled
                label='History'
                content={
                    <StatusHistory
                        allStatuses={allRentalContractStatuses}
                        dateGetter={s => getRentalContractStatusDate(contract, s)}
                        labelFormatter={trContractStatus}
                    />
                }
            />
        </>
    );
};

interface ManagementContractDetailsProps {
    contract: ContractDtoWithManagement;
}

const ManagementContractDetails: FC<ManagementContractDetailsProps> = ({ contract }) => {
    return (
        <FormGridRow columns={4}>
            <LabeledText label='Id' content={<ContractLinkLabel id={contract} />} />
            <Labeled label='Contract type' content={<ContractTypeChip type={contract.type} />} />
        </FormGridRow>
    );
};
