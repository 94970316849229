import React, { FC } from 'react';

import {
    AssignmentCollectionReason,
    assignmentCollectionReasonColor,
    useTrAssignmentCollectionReason,
} from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface AssignmentCollectionReasonChipProps {
    reason: AssignmentCollectionReason;
}

export const AssignmentCollectionReasonBadge: FC<AssignmentCollectionReasonChipProps> = ({ reason }) => {
    const trCollectionReason = useTrAssignmentCollectionReason();

    if (reason === AssignmentCollectionReason.CollectAndReuse) {
        return null;
    }
    return <Badge label={trCollectionReason(reason)} color={assignmentCollectionReasonColor[reason]} />;
};
