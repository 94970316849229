import { ColorDye } from '@hofy/global';

export enum CollectionShipmentLabelsStatus {
    Required = 'required',
    UserWillPrintLabels = 'user_will_print_labels',
    AssistanceRequested = 'assistance_requested',
    LabelsSentToUser = 'labels_sent_to_user',
    UserReceivedLabels = 'user_received_labels',
}

export const collectionShipmentLabelsStatusColors: Record<CollectionShipmentLabelsStatus, ColorDye> = {
    [CollectionShipmentLabelsStatus.Required]: 'orange',
    [CollectionShipmentLabelsStatus.UserWillPrintLabels]: 'green',
    [CollectionShipmentLabelsStatus.AssistanceRequested]: 'red',
    [CollectionShipmentLabelsStatus.LabelsSentToUser]: 'green',
    [CollectionShipmentLabelsStatus.UserReceivedLabels]: 'green',
};
