import React, { FC } from 'react';
import styled from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';
import { Box, BoxProps, Spinner } from '@hofy/ui';

export const GlobalLoader: FC = () => (
    <ComponentContainer zIndex={ZIndex.GlobalLoader}>
        <Spinner size={96} />
    </ComponentContainer>
);

export const ComponentLoader: FC = () => (
    <ComponentContainer zIndex={ZIndex.Loader}>
        <Spinner size={96} />
    </ComponentContainer>
);

export const BlockLoader: FC<BoxProps> = props => (
    <Box centered {...props}>
        <Spinner size={48} />
    </Box>
);

const ComponentContainer = styled.div<{ zIndex: ZIndex }>`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: ${ZIndex.Loader};
    background: ${Color.BackgroundDefault};
    display: flex;
    align-items: center;
    justify-content: center;
`;
