import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface RepairIndicatorProps {
    linkToRepair: string;
}

export const RepairIndicator: FC<RepairIndicatorProps> = ({ linkToRepair }) => {
    return (
        <Link to={linkToRepair} onClick={e => e.stopPropagation()}>
            <Indicator color={Color.NonContextualMagentaDefault}>
                <TooltipIcon
                    icon={SvgIcon.Tool}
                    iconColor={Color.NonContextualMagentaDefault}
                    body='Being repaired'
                    iconSize={16}
                />
            </Indicator>
        </Link>
    );
};
