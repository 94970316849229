import React, { FC } from 'react';

import { UUID } from '@hofy/global';

import { useUpdateDraftPurchaseOrder } from '../../../../store/purchaseOrders/useUpdateDraftPurchaseOrder';
import { useUpdateMultipartPurchaseOrderForm } from '../../../../store/purchaseOrders/useUpdateMultipartPurchaseOrderForm';
import { PurchaseOrderFormSlideout } from './components/PurchaseOrderFormSlideout';

interface UpdatePurchaseOrderSlideoutProps {
    onClose(): void;
    purchaseOrderId: UUID;
}

export const UpdatePurchaseOrderSlideout: FC<UpdatePurchaseOrderSlideoutProps> = ({
    onClose,
    purchaseOrderId,
}) => {
    const formState = useUpdateMultipartPurchaseOrderForm(purchaseOrderId, onClose);
    const { saveAsDraft, isLoading: isLoadingSaveAsDraft } = useUpdateDraftPurchaseOrder(
        formState,
        purchaseOrderId,
    );

    return (
        <PurchaseOrderFormSlideout
            title='Update purchase order'
            isPreparing={formState.isPreparing}
            onClose={onClose}
            formWrapper={formState}
            saveAsDraft={saveAsDraft}
            isLoadingSaveAsDraft={isLoadingSaveAsDraft}
        />
    );
};
