import { pick } from 'lodash';
import React, { FC, useState } from 'react';

import { emptyExportDto, ExportDto, ExportField } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { InvisibleFileInput } from '@hofy/common';
import { readFirstFile } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import { AsyncButton, Box, Modals, SvgIcon, useToast } from '@hofy/ui';

import { useImport } from '../../../../store/exportImport/useImport';
import { ImportDataModal } from './ImportDataModal';

export const ImportSection: FC = () => {
    const { importData, isImportInProgress } = useImport();
    const { hasPermission } = usePermission();
    const { showToast } = useToast();
    const [importModalOpen, setImportModalOpen] = useState(false);
    const [rawJson, setRawJson] = useState<ExportDto>(emptyExportDto);
    const handleImportSubmit = (files: FileList) => {
        return readFirstFile(
            files,
            (json: string) => {
                try {
                    const res = JSON.parse(json);
                    setRawJson(res);
                    setImportModalOpen(true);
                } catch (err) {
                    showToast({
                        type: 'negative',
                        message: 'Unable to parse JSON',
                    });
                }
            },
            true,
        );
    };

    const onImportSubmit = (selectedFields: ExportField[]) => {
        importData(pick(rawJson, selectedFields));
        setImportModalOpen(false);
    };

    return (
        <Box>
            <AsyncButton
                isLoading={isImportInProgress}
                leftIcon={SvgIcon.Upload}
                label='Import'
                disabled={!hasPermission(Permission.AdminImport)}
            >
                <InvisibleFileInput
                    accept='application/JSON'
                    onChange={handleImportSubmit}
                    disabled={!hasPermission(Permission.AdminImport)}
                />
            </AsyncButton>
            <Modals>
                {importModalOpen && (
                    <ImportDataModal
                        fields={Object.keys(rawJson) as ExportField[]}
                        onClose={() => setImportModalOpen(false)}
                        onSubmit={onImportSubmit}
                    />
                )}
            </Modals>
        </Box>
    );
};
