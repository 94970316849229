import React, { FC } from 'react';

import { AuditableTable } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { AssignmentLink } from '../assignmentsPage/AssignmentLink';
import { ContractLink } from '../contractsPage/ContractLink';
import { ItemLink } from '../itemsPage/ItemDetailsLink';
import { OrganizationLink } from '../organizationsPage/OrganizationDetailsLink';

interface AuditLogsDetailsEntityLinkProps {
    tableName?: string;
    tableId?: number;
    tableUuid?: UUID;
}

export const AuditLogsDetailsEntityLink: FC<AuditLogsDetailsEntityLinkProps> = ({
    tableName,
    tableUuid,
    tableId,
}) => {
    if (!tableName || !tableId || tableUuid) {
        return <>--</>;
    }

    const content = () => {
        return `#${tableId}`;
    };

    switch (tableName) {
        case AuditableTable.Assignment:
            return <AssignmentLink id={tableUuid!}>{content()}</AssignmentLink>;
        case AuditableTable.Organization:
            return <OrganizationLink id={tableUuid!}>{content()}</OrganizationLink>;
        case AuditableTable.Item:
            return <ItemLink id={tableUuid!}> {content()}</ItemLink>;
        case AuditableTable.RentalContracts:
            return <ContractLink id={tableUuid!}>{content()}</ContractLink>;
    }
    return <>--</>;
};
