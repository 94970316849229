import React, { FC } from 'react';

import { Icon, SvgIcon } from '../../icon';
import { HeaderIcon } from './HeaderIcon';

interface TableFilterIconProps {
    active: boolean;
}

export const TableFilterIcon: FC<TableFilterIconProps> = ({ active }: TableFilterIconProps) => (
    <HeaderIcon active={active} testKey='filter-icon'>
        <Icon color='currentColor' block svg={SvgIcon.FilterLines} />
    </HeaderIcon>
);
