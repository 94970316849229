import React, { ElementRef, forwardRef } from 'react';

import { Currency, currencySymbols, Price, priceToNumber } from '@hofy/global';

import { formatNumberOptional } from '../../../../helpers/Number';
import { InputProps } from '../Input';
import { NumberInput } from '../number/NumberInput';

interface BasePriceInputProps extends Omit<InputProps, 'onChange' | 'value' | 'nullable' | 'leftSlot'> {
    defaultCurrency?: Currency;
    withoutDecimals?: boolean;
}

export interface PriceInputOnlyStringProps extends BasePriceInputProps {
    nullable?: false;
    value: Price;
    onChange(value: Price): void;
}

export interface PriceInputNullableStringProps extends BasePriceInputProps {
    nullable: true;
    value: Price | null;
    onChange(v: Price | null): void;
}

type PriceInputProps = PriceInputOnlyStringProps | PriceInputNullableStringProps;

export const PriceInput = forwardRef<ElementRef<'input'>, PriceInputProps>(
    ({ value, onChange, defaultCurrency = Currency.GBP, withoutDecimals, ...props }, ref) => {
        const numberValue = value === null ? null : priceToNumber(value);

        const precision = withoutDecimals ? 0 : 2;

        return (
            <NumberInput
                ref={ref}
                value={numberValue!}
                onChange={number => {
                    if (number === null) {
                        if (!props.nullable) {
                            throw new Error('Cannot set null value on non-nullable PriceInput');
                        }
                        onChange(null!);
                    } else {
                        onChange({
                            amount: number.toFixed(precision),
                            currency: value?.currency || defaultCurrency,
                        });
                    }
                }}
                leftSlot={currencySymbols[value?.currency || defaultCurrency]}
                formatter={number => formatNumberOptional(number, precision)}
                precision={precision}
                fixedDecimal
                {...props}
            />
        );
    },
);
