import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import { kebabCase } from 'lodash';
import React, { ReactNode, useMemo } from 'react';

import { TestKeyAware } from '../../types';
import { ReactNodeFunction, renderChildren } from '../../types/React';
import { Box } from '../base';
import { useInteractiveList } from './hooks/useInteractiveList';
import { DropdownContentScrollable } from './shared/DropdownContentScrollable';
import { DropdownInteractiveListItem } from './shared/DropdownInteractiveListItem';
import { DropdownTrigger } from './shared/DropdownTrigger';
import { DropdownTriggerLabel } from './shared/DropdownTriggerLabel';
import {
    InteractiveListBase,
    NormalInteractiveList,
    NullableInteractiveList,
} from './types/InteractiveListTypes';

interface DropdownListPropsBaseProps<T> extends InteractiveListBase<T>, TestKeyAware {
    trigger?: ReactNode | ReactNodeFunction<[isOpen: boolean]>;
    /** Only used if trigger is not provided */
    triggerPlaceholder?: string;
    asChild?: boolean;

    fixedOptions?: NonNullable<T>[];
    contentWidth?: string | number | 'auto';

    onFocus?(): void;
    onBlur?(): void;
}

export interface NormalDropdownListProps<T> extends DropdownListPropsBaseProps<T>, NormalInteractiveList<T> {}
export interface NullableDropdownListProps<T>
    extends DropdownListPropsBaseProps<T>,
        NullableInteractiveList<T> {}

export type DropdownListProps<T> = NormalDropdownListProps<T> | NullableDropdownListProps<T>;

export const DropdownList = <T,>({
    trigger,
    triggerPlaceholder,
    asChild,

    fixedOptions,
    contentWidth,
    contentMaxHeight,
    placement,

    value,
    options,
    onChange,
    toText,
    toKey,
    toLabel,
    toSelectedLabel,
    disabled: dropdownDisabled,
    nullable,
    testKey,
}: DropdownListProps<T>) => {
    const allOptions = useMemo(() => {
        if (fixedOptions) {
            return [...fixedOptions, ...options];
        }
        return options;
    }, [fixedOptions, options]);

    const state = useInteractiveList({
        options: allOptions,
        toText,
        toKey,
        toLabel,
        toSelectedLabel,
        value,
        onChange,
        disabled: dropdownDisabled,
        nullable,
        contentWidth,
        contentMaxHeight,
        placement,
    });

    const {
        context,
        isOpen,
        activeIndex,
        selectedIndex,
        refs,
        floatingStyles,
        floatingProps,
        referenceProps,
        itemProps,
        listRef,
        getKey,
        getLabel,
        getSelectedLabel,
        resultantPlacement,
        disabled,
    } = state;

    const renderItem = (item: NonNullable<T>, index: number) => {
        const key = getKey(item);
        return (
            <DropdownInteractiveListItem
                key={key}
                ref={node => {
                    listRef.current[index] = node;
                }}
                label={getLabel(item)}
                isActive={index === activeIndex}
                isSelected={index === selectedIndex}
                testKey={testKey && `${testKey}-${kebabCase(key)}`}
                {...itemProps(item)}
            />
        );
    };

    const indexOffset = fixedOptions?.length ?? 0;

    const renderTrigger = () => {
        if (trigger) {
            return renderChildren(trigger, isOpen);
        }
        return (
            <DropdownTriggerLabel
                label={getSelectedLabel(value) || triggerPlaceholder}
                isOpen={isOpen}
                disabled={disabled}
            />
        );
    };

    return (
        <>
            <DropdownTrigger
                referenceProps={referenceProps}
                setReference={refs.setReference}
                asChild={trigger ? asChild : true}
                testKey={testKey}
            >
                {renderTrigger()}
            </DropdownTrigger>

            <AnimatePresence>
                {isOpen && (
                    <FloatingPortal>
                        <FloatingFocusManager context={context} modal={false}>
                            <DropdownContentScrollable
                                ref={refs.setFloating}
                                style={floatingStyles}
                                floatingProps={floatingProps()}
                                placement={resultantPlacement}
                                topSlot={
                                    fixedOptions && (
                                        <Box borderBottom padding={4} marginBottom={4} column gap={4}>
                                            {fixedOptions.map((item, index) => renderItem(item, index))}
                                        </Box>
                                    )
                                }
                            >
                                {options.map((item, index) => renderItem(item, index + indexOffset))}
                            </DropdownContentScrollable>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};
