export const contractEndOptionI18n = {
    'contract-end-option.default': 'Default',
    'contract-end-option.return': 'Return to Hofy',
    'contract-end-option.purchase': 'Purchase item',
    'contract-end-option.refresh': 'Refresh device',
};

export const contractEndOptionShortI18n = {
    'contract-end-option-short.default': 'Default',
    'contract-end-option-short.return': 'Return to Hofy',
    'contract-end-option-short.purchase': 'Purchase',
    'contract-end-option-short.refresh': 'Refresh',
};
