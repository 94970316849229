import { useTheme as useSCTheme } from 'styled-components';

export const fontFamily = 'Denim, Roboto, sans-serif';
export const fontSpacing = 0.4;

export const theme = {
    spacing: {
        mainMarginHorizontal: 40,
        mainMarginVertical: 30,
    },
    fontSize: {
        heading1: 40,
        heading2: 32,
        heading3: 24,
        sectionTitle1: 18,
        sectionTitle2: 16,
        sectionTitle3: 14,
        paragraph1: 18,
        paragraph2: 16,
        paragraph3: 14,
        paragraph4: 12,
    },
};

export type Theme = typeof theme;

export interface ThemeProp {
    theme: Theme;
}

export const mobileTheme: Theme = {
    ...theme,
    spacing: {
        mainMarginHorizontal: 20,
        mainMarginVertical: 20,
    },
    fontSize: {
        heading1: 32,
        heading2: 20,
        heading3: 19,
        sectionTitle1: 16,
        sectionTitle2: 14,
        sectionTitle3: 12,
        paragraph1: 16,
        paragraph2: 15,
        paragraph3: 12,
        paragraph4: 11,
    },
};

export const useTheme = () => {
    return useSCTheme() as Theme;
};

export type ThemeSpacing = keyof Theme['spacing'];

export const isThemeSpacingName = (value: any): value is ThemeSpacing => {
    return typeof value === 'string' && value in theme.spacing;
};

export const getSpacingValue = (theme: Theme, value: ThemeSpacing | number) => {
    return isThemeSpacingName(value) ? theme.spacing[value] : value;
};

export type ThemeFontSize = keyof Theme['fontSize'];

export const isThemeFontSizeName = (value: any): value is ThemeFontSize => {
    return typeof value === 'string' && value in theme.fontSize;
};

export const getFontSizeValue = (theme: Theme, value: ThemeFontSize | number) => {
    return isThemeFontSizeName(value) ? theme.fontSize[value] : value;
};
