import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { activityLogService } from '../service/activityLogService';
import { ActivityLogsFilter } from './ActivityLogsFilter';
import { activityLogsCacheKey } from './cacheKey';

export const useActivityLogs = (filters: ActivityLogsFilter) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [activityLogsCacheKey, filters],
        queryFn: param =>
            activityLogService.list(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const activityLogs = useMemo(() => {
        if (data) {
            return data.pages.flatMap(v => v.content);
        }
        return [];
    }, [data]);

    return {
        activityLogs: activityLogs,
        activityLogsIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
