import { getEnumValues } from '@hofy/global';

export enum ItemPart {
    PowerAdapter = 'power_adapter',
    PowerCable = 'power_cable',
    UsbCable = 'usb_cable',
    Charger = 'charger',
    Battery = 'battery',
    Dongle = 'dongle',
    Other = 'other',
}

export const allItemParts = getEnumValues<ItemPart>(ItemPart);
