import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Icon, LabeledLink, SvgIcon } from '@hofy/ui';

import { getAdminLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface AdminLinkProps {
    id: UUID;
    color?: Color;
    children?: ReactNode;
}

export const AdminLink: FC<AdminLinkProps> = ({ id, color, children }) => {
    return (
        <Link flex color={color} to={getAdminLink(id)}>
            {children}
        </Link>
    );
};

interface AdminDetailsLinkProps {
    admin: {
        id: UUID;
        firstName: string;
        lastName: string;
    };
}

export const AdminDetailsLink: FC<AdminDetailsLinkProps> = ({ admin }) => {
    return (
        <AdminLink id={admin.id}>
            <Icon size={14} svg={SvgIcon.User} marginRight={4} />
            {admin.firstName} {admin.lastName}
        </AdminLink>
    );
};

interface LabeledAdminLinkProps {
    admin: {
        id: UUID;
        firstName: string;
        lastName: string;
    } | null;
    label?: string;
}

export const LabeledAdminLink: FC<LabeledAdminLinkProps> = ({ label = 'Admin', admin }) => {
    return <LabeledLink label={label} content={admin ? <AdminDetailsLink admin={admin} /> : '--'} />;
};
