import React, { FC } from 'react';

import { toLoginEmailAware, UserDto } from '@hofy/api-admin';
import { addressToPayloadWithDefault, AddressType } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Box, Button, Form, SubmitButton, SvgIcon } from '@hofy/ui';

import { UserForm } from '../../../../components/domain/user/UserForm';
import { useUpdateUser } from '../../../../store/users/useUpdateUser';
import { useUserForm } from '../../../../store/users/useUserForm';

interface UpdateUserSlideoutProps {
    user: UserDto;
    onClose(): void;
}

export const UpdateUserSlideout: FC<UpdateUserSlideoutProps> = ({ user, onClose }) => {
    const { updateUser, isUpdateUserLoading } = useUpdateUser(user.id, onClose);
    const form = useUserForm(
        {
            itPolicyId: user.itPolicy.id,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles,
            address: addressToPayloadWithDefault(user.address, AddressType.Partial),
            startsOn: user.startsOn,
            ...toLoginEmailAware(user),
        },
        user.status,
        updateUser,
        onClose,
    );

    return (
        <Slideout width={600} onClose={form.discard}>
            <Form onSubmit={form.submit} isLoading={isUpdateUserLoading} flex='auto' column>
                <SlideoutHeader title='Update user details' />
                <SlideoutContent relative>
                    <UserForm form={form} organizationId={user.organization.id} />
                </SlideoutContent>
                <SlideoutFooter>
                    <Button
                        type='ghost'
                        negativeMargin
                        onClick={form.discard}
                        label='Cancel'
                        leftIcon={SvgIcon.Cross}
                    />
                    <Box row>
                        <SubmitButton label='Save' />
                    </Box>
                </SlideoutFooter>
            </Form>
        </Slideout>
    );
};
