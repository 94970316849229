import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { billingEntitiesService } from '../service/billingEntityService';
import { AdminBillingEntityFilters } from '../service/types/AdminBillingEntityFilters';
import { billingEntitiesCacheKey } from './cacheKey';

export const useBillingEntitiesQuery = (filters: AdminBillingEntityFilters) => {
    const { isFetching, isLoading, data } = useQuery({
        queryKey: [billingEntitiesCacheKey, filters],
        queryFn: () => billingEntitiesService.listBillingEntities(filters, { page: 0, pageSize: 100 }),
        placeholderData: keepPreviousData,
    });

    return {
        isFetching,
        isLoading,
        billingEntities: data?.content || [],
    };
};

export const useBillingEntityQuery = (billingEntityId?: UUID) => {
    const { isLoading, data } = useQuery({
        queryKey: [billingEntitiesCacheKey, billingEntityId],
        queryFn: () => billingEntitiesService.getBillingEntity(billingEntityId!),
        enabled: !!billingEntityId,
    });
    return {
        isLoading,
        billingEntity: data,
    };
};
