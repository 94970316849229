import { useAdminI18n } from '../../i18n/useAdminI18n';
import { AdminAssignmentSlideoutTabs } from '../../views/assignmentSlideout/AdminAssignmentSlideoutTabs';

export const useTrAdminAssignmentSlideoutTabs = () => {
    const { trEnum } = useAdminI18n();
    return trEnum<AdminAssignmentSlideoutTabs>(
        AdminAssignmentSlideoutTabs,
        'admin-item-assignment-slideout-tab',
    );
};
