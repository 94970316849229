import { sortBy, xor } from 'lodash';
import React, { FC, useMemo, useState } from 'react';

import { useSearch } from '@hofy/common';
import { allCountries, Country } from '@hofy/global';
import { useTrCountry } from '@hofy/i18n';
import { SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../../../../components/design/blockFilters/SearchableBlockFilter';

interface CountryBlockFilterProps {
    title?: string;
    selected: Country[];
    items?: Country[];
    onChange(countries: Country[]): void;
}

export const CountryBlockFilter: FC<CountryBlockFilterProps> = ({
    selected,
    items = allCountries,
    onChange,
    title = 'Country',
}) => {
    const trCountry = useTrCountry();
    const [searchQuery, setSearchQuery] = useState('');
    const sortedItems = useMemo(() => sortBy(items, trCountry), [items]);
    const { results } = useSearch(sortedItems, item => `${item} ${trCountry(item)}`, searchQuery);

    return (
        <SearchableBlockFilter
            title={title}
            icon={SvgIcon.Globe}
            items={results}
            isSelected={country => selected.includes(country)}
            onFilterClick={country => onChange(xor(selected, [country]))}
            renderItem={country => trCountry(country)}
            searchPlaceholder='Search country'
            search={searchQuery}
            onSearch={setSearchQuery}
        />
    );
};
