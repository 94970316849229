export const collectionRequestErrorI18n = {
    'api-error.forbidden-access.title': 'Forbidden access to collection request',
    'api-error.forbidden-access.message': 'Cannot perform this operation',
    'api-error.inactive-collection-request.title': 'Inactive collection survey',
    'api-error.inactive-collection-request.message': 'The collection survey is not active.',
    'api-error.invalid-collection-request-status.title': 'Invalid collection request status',
    'api-error.invalid-collection-request-status.message': 'Cannot perform this operation',
    'api-error.unescalated-collection-request.title': 'Unescalated collection request',
    'api-error.unescalated-collection-request.message': 'Cannot perform this operation',
    'api-error.deferred-collection-request.title': 'Deferred collection request',
    'api-error.deferred-collection-request.message': 'Cannot perform this operation',
    'api-error.un-deferred-collection-request.title': 'Undeferred collection request',
    'api-error.un-deferred-collection-request.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-type.title': 'Invalid shipment type',
    'api-error.invalid-shipment-type.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-state.title': 'Invalid shipment state',
    'api-error.invalid-shipment-state.message': 'Cannot perform this operation',
    'api-error.invalid-warehouse-ownership.title': 'Invalid warehouse ownership',
    'api-error.invalid-warehouse-ownership.message': 'Cannot perform this operation',
    'api-error.missing-personal-email.title': 'Missing user personal email',
    'api-error.missing-personal-email.message': 'Cannot perform this operation',
    'api-error.missing-warehouse-primary-manager.title': 'Missing organization warehouse primary manager',
    'api-error.missing-warehouse-primary-manager.message': 'Cannot perform this operation',
    'api-error.invalid-collection-dates-range.title': 'Invalid collection dates range',
    'api-error.invalid-collection-dates-range.message': 'Cannot perform this operation',
    'api-error.invalid-collection-dates-amount.title': 'Invalid collection dates amount',
    'api-error.invalid-collection-dates-amount.message': 'Cannot perform this operation',
    'api-error.collection-dates-without-weekday.title': 'Collection dates without weekday',
    'api-error.collection-dates-without-weekday.message': 'Cannot perform this operation',
    'api-error.shipment-has-collection-request.title': 'Shipment has collection request',
    'api-error.shipment-has-collection-request.message': 'Cannot perform this operation',
    'api-error.reset-device-confirmation-not-provided.title': 'Reset device confirmation not provided',
    'api-error.reset-device-confirmation-not-provided.message': 'Cannot perform this operation',
    'api-error.provided-serial-number-for-non-device.title': 'Provided serial number for non device',
    'api-error.provided-serial-number-for-non-device.message': 'Cannot perform this operation',
    'api-error.packaging-assistance-not-required.title': 'Packaging assistance not required',
    'api-error.packaging-assistance-not-required.message': 'Cannot perform this operation',
    'api-error.packaging-assistance-already-completed.title': 'Packaging assistance already completed',
    'api-error.packaging-assistance-already-completed.message': 'Cannot perform this operation',
};
