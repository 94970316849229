import { ColorDye, getEnumValues } from '@hofy/global';

export enum StorageAssignmentStatus {
    CollectionCancelled = 'collection_cancelled',
    CollectionPending = 'collection_pending',
    CollectionWithCourier = 'collection_with_courier',
    AtWarehouse = 'at_warehouse',
    DeliveryPending = 'delivery_pending',
    DeliveryWithCourier = 'delivery_with_courier',
    Delivered = 'delivered',
}

export const allStorageAssignmentStatuses = getEnumValues<StorageAssignmentStatus>(StorageAssignmentStatus);

export const storageAssignmentsStatusColors: Record<StorageAssignmentStatus, ColorDye> = {
    [StorageAssignmentStatus.CollectionCancelled]: 'red',
    [StorageAssignmentStatus.CollectionPending]: 'orange',
    [StorageAssignmentStatus.CollectionWithCourier]: 'magenta',
    [StorageAssignmentStatus.AtWarehouse]: 'green',
    [StorageAssignmentStatus.DeliveryPending]: 'orange',
    [StorageAssignmentStatus.DeliveryWithCourier]: 'magenta',
    [StorageAssignmentStatus.Delivered]: 'blue',
};
