import { getEnumValues } from '@hofy/global';

export enum InvoiceDetailTabs {
    Details = 'details',
    InvoiceEntries = 'invoice_entries',
    PaymentDetails = 'payment_details',
    AppliedCreditNotes = 'applied_credit_notes',
    AppliedInvoices = 'applied_invoices',
}

export const getInvoiceDetailsTabs = (isCreditNote: boolean) => [
    InvoiceDetailTabs.Details,
    InvoiceDetailTabs.InvoiceEntries,
    InvoiceDetailTabs.PaymentDetails,
    ...(isCreditNote ? [InvoiceDetailTabs.AppliedInvoices] : [InvoiceDetailTabs.AppliedCreditNotes]),
];

export const allInvoiceDetailsTabs = getEnumValues<InvoiceDetailTabs>(InvoiceDetailTabs);
