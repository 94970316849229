import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { childrenProps } from '../../helpers/React';
import { Box, Paragraph3, Span } from '../base';
import { Tooltip } from '../tooltip';

const COMPLETED_STEP_COLOR = Color.NonContextualGreenDefault;
const CURRENT_STEP_COLOR = Color.NonContextualVioletDefault;
const NOT_STARTED_STEP_COLOR = Color.NonContextualGreySubtle;

export interface ProgressStepsProps {
    children: ReactNode;
    renderCounter?(current: number, all: number): ReactNode;
    defaultTitle?: string;
    completedColor?: Color;
    notStartedColor?: Color;
    currentColor?: Color;
    completedText?: string;
}

const counterDefaultRenderer = (current: number, all: number) => {
    return (
        <>
            Step {current}
            <Span color={Color.ContentTertiary}> out of {all}</Span>
        </>
    );
};

export const ProgressSteps: FC<ProgressStepsProps> = ({
    children,
    defaultTitle,
    renderCounter = counterDefaultRenderer,
    completedColor = COMPLETED_STEP_COLOR,
    notStartedColor = NOT_STARTED_STEP_COLOR,
    currentColor = CURRENT_STEP_COLOR,
    completedText = 'Completed',
}) => {
    const steps = childrenProps(children, ProgressStep);
    const totalSteps = steps.length;
    const allStepsCompleted = steps.every(step => step.completed);
    const currentStepTitle = steps.find(step => step.current)?.title;
    const currentStepIndex = steps.findIndex(step => step.current) + 1;

    if (allStepsCompleted) {
        return (
            <Box>
                <Box row marginBottom={12}>
                    <Box flex={1} rounded bg={COMPLETED_STEP_COLOR} height={4} />
                </Box>
                <Paragraph3 textAlign='center' color={COMPLETED_STEP_COLOR}>
                    {completedText}
                </Paragraph3>
            </Box>
        );
    }

    return (
        <Box>
            <Box row gap={4} marginBottom={12}>
                {steps.map((step, index) => (
                    <ProgressStep
                        key={index}
                        completed={step.completed}
                        current={step.current}
                        onClick={step.onClick}
                        tooltip={step.tooltip}
                        completedColor={completedColor}
                        notStartedColor={notStartedColor}
                        currentColor={currentColor}
                    />
                ))}
            </Box>

            <Box row justify='space-between'>
                <Paragraph3 color={Color.ContentPrimary}>{currentStepTitle || defaultTitle}</Paragraph3>
                <Paragraph3 color={Color.ContentPrimary}>
                    {renderCounter(currentStepIndex, totalSteps)}
                </Paragraph3>
            </Box>
        </Box>
    );
};

interface ProgressStepProps {
    completed?: boolean;
    current?: boolean;
    onClick?(): void;
    tooltip?: string;
    // eslint-disable-next-line react/no-unused-prop-types
    title?: string;
    completedColor?: Color;
    notStartedColor?: Color;
    currentColor?: Color;
}

export const ProgressStep: FC<ProgressStepProps> = ({
    completed,
    current,
    onClick,
    tooltip,
    completedColor = COMPLETED_STEP_COLOR,
    notStartedColor = NOT_STARTED_STEP_COLOR,
    currentColor = CURRENT_STEP_COLOR,
}) => {
    const color = current ? currentColor : completed ? completedColor : notStartedColor;

    return (
        <Box flex={1} pointer={!!onClick} onClick={onClick}>
            {tooltip ? (
                <Tooltip placement='bottom' body={tooltip}>
                    <Box rounded bg={color} height={4} />
                </Tooltip>
            ) : (
                <Box rounded bg={color} height={4} />
            )}
        </Box>
    );
};
