import { VALUE_LEFT, VALUE_RIGHT } from 'keycode-js';
import { RefObject, useEffect, useState } from 'react';

const getNextEditablePosition = (bannedPositions: number[], start: number) => {
    for (let i = start + 1; ; i++) {
        if (isEditablePosition(bannedPositions, i)) {
            return i;
        }
    }
};

const getPrevEditablePosition = (bannedPositions: number[], start: number) => {
    for (let i = start - 1; i > -1; i--) {
        if (isEditablePosition(bannedPositions, i)) {
            return i;
        }
    }
    return -1;
};

const isEditablePosition = (bannedPositions: number[], i: number) => {
    return !bannedPositions.includes(i);
};

export const useInputCursor = (v: RefObject<HTMLInputElement>, bannedPositions: number[] = []) => {
    const [position, setPosition] = useState<number>();
    const setCursor = (i: number) => {
        if (v.current) {
            v.current.setSelectionRange(i, i);
        }
    };
    const getCursor = () => {
        if (v.current) {
            return v.current.selectionStart || 0;
        }
        return 0;
    };
    useEffect(() => {
        if (!v.current) {
            return;
        }
        const keyDown = (e: KeyboardEvent) => {
            if (!v.current) {
                return;
            }
            const key = e.key;

            switch (key) {
                case VALUE_LEFT: {
                    e.preventDefault();
                    const prevEditable = getPrevEditablePosition(bannedPositions, getCursor());
                    if (prevEditable > -1) {
                        setCursor(prevEditable);
                    }
                    break;
                }
                case VALUE_RIGHT: {
                    e.preventDefault();
                    const nextEditable = getNextEditablePosition(bannedPositions, getCursor());
                    if (nextEditable <= v.current.value.length) {
                        setCursor(nextEditable);
                    }
                    break;
                }
                default:
                    break;
            }

            setPosition(getCursor());
        };
        const mouse = () => {
            if (!v.current) {
                return;
            }
            v.current.focus();
            const nextEditable = getNextEditablePosition(bannedPositions, getCursor() - 1);
            setCursor(nextEditable);
        };
        v.current.addEventListener('keydown', keyDown);
        v.current.addEventListener('mouseup', mouse);
        return () => {
            v.current?.removeEventListener('keydown', keyDown);
            v.current?.removeEventListener('mouseup', mouse);
        };
    }, [v.current]);
    return {
        position,
        setPosition,
    };
};
