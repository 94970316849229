import React, { FC, useEffect } from 'react';

import { useHofySubsidiaryQuery } from '@hofy/api-admin';
import { SupplierType } from '@hofy/api-shared';
import { Country } from '@hofy/global';

import {
    HofySubsidiaryDropdown,
    HofySubsidiaryDropdownProps,
} from '../../../../../components/domain/hofySubsidiaries/HofySubsidiaryDropdown';

interface SupplierHofySubsidiaryDropdownProps extends HofySubsidiaryDropdownProps {
    country?: Country;
    supplierType?: SupplierType;
}

export const SupplierHofySubsidiaryDropdown: FC<SupplierHofySubsidiaryDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired,
    testKey,
    disabled = false,
    country,
    supplierType,
}) => {
    const { data: subsidiary, isLoading } = useHofySubsidiaryQuery(country, supplierType);

    useEffect(() => {
        onChange(null);
        if (!isLoading && !!subsidiary) {
            onChange(subsidiary.id);
        }
    }, [isLoading, subsidiary]);

    return (
        <HofySubsidiaryDropdown
            label={label}
            value={value}
            emptyContent={emptyContent}
            onChange={onChange}
            disabled={disabled}
            isRequired={isRequired}
            isError={isError}
            testKey={testKey}
        />
    );
};
