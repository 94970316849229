import { Country, DateString, UUID, zeroUuid } from '@hofy/global';

export interface SeedRandomNonHofyAssetsPayload {
    organizationId: UUID;
    numberOfAssets: number;
    activeOnRange: {
        start: DateString;
        end: DateString;
    };
    countries: Country[];
}

export const emptySeedRandomNonHofyAssetPayload: SeedRandomNonHofyAssetsPayload = {
    organizationId: zeroUuid,
    numberOfAssets: 0,
    activeOnRange: {
        start: '2020-01-01',
        end: '2020-01-01',
    },
    countries: [],
};
