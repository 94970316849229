import { getEnumValues } from '@hofy/global';

export enum RentalStoreAndReuseLocation {
    Local = 'local',
    Zone1 = 'zone_1',
}

export const allStoreAndReuseLocations =
    getEnumValues<RentalStoreAndReuseLocation>(RentalStoreAndReuseLocation);

export const storeAndReuseLocationLabels = {
    [RentalStoreAndReuseLocation.Local]: 'Local country',
    [RentalStoreAndReuseLocation.Zone1]: 'Reuse in Zone 1',
};
