import { useMutation, useQueryClient } from '@tanstack/react-query';

import { purchaseOrdersCacheKey, purchaseOrderService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useDeletePurchaseOrder = (purchaseOrderId: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: () => purchaseOrderService.deletePurchaseOrder(purchaseOrderId),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [purchaseOrdersCacheKey, 'purchase-order', purchaseOrderId],
            });
            showToast({
                type: 'positive',
                message: 'Draft purchase order discarded',
            });
            onSuccess();
        },
    });

    return {
        deletePurchaseOrder: mutation.mutate,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
