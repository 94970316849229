import React, { FC } from 'react';

import { isScheduled, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    isShipmentFromHofyWarehouse,
    isShipmentFromSupplierToUser,
    isShipmentToHofyWarehouse,
    NoteSource,
    Permission,
} from '@hofy/api-shared';
import { SlideoutContent, SlideoutFooter, SlideoutTabbedHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import { Badge, Box, Heading3, Paragraph3, SvgIcon, Tab, Tabs } from '@hofy/ui';

import { CancelButton } from '../../components/design/button/CancelButton';
import { NotesEditor } from '../../components/domain/notes/NotesEditor';
import { ShipmentAdminSelector } from '../../components/domain/shipments/ShipmentAdminSelector';
import { ShipmentMenu } from '../../components/domain/shipments/ShipmentMenu';
import { ShipmentTypeChip } from '../../components/domain/shipments/ShipmentTypeChip';
import {
    allShipmentSlideoutTabs,
    ShipmentSlideoutTab,
} from '../../store/shipments/types/ShipmentSlideoutTab';
import { useShipmentCollectionRequestQuery } from '../../store/shipments/useShipmentCollectionRequestQuery';
import { useTrAdminShipmentSlideoutTab } from '../../store/shipments/useTrAdminShipmentSlideoutTab';
import { useUpdateShipmentPurchaseOrder } from '../../store/shipments/useUpdateShipmentPurchaseOrder';
import { ShipmentPurchaseOrderPane } from './components/ShipmentPurchaseOrderPane';
import { ShipmentStatusPane } from './components/ShipmentStatusPane';
import { ShipmentAction } from './ShipmentAction';
import { ShipmentSlideoutCollectionSurvey } from './shipmentSlideoutTabs/shipmentSlideoutCollectionSurvey/ShipmentSlideoutCollectionSurvey';
import { ShipmentSlideoutDetails } from './shipmentSlideoutTabs/shipmentSlideoutDetails/ShipmentSlideoutDetails';
import { ShipmentSlideoutAssignments } from './shipmentSlideoutTabs/shipmentSlideoutOrders/ShipmentSlideoutAssignments';
import { ShipmentTracking } from './shipmentSlideoutTabs/shipmentSlideoutTracking/ShipmentTracking';

interface ShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
    activeTab: ShipmentSlideoutTab;
    onTabChange(tab: ShipmentSlideoutTab): void;
    onAction(action: ShipmentAction): void;
    onAssignmentClick(assignmentId: UUID): void;
    onCreatePurchaseOrder(shipmentId: UUID, tab: ShipmentSlideoutTab): void;
}

export const ShipmentSlideoutContent: FC<ShipmentSlideoutProps> = ({
    shipment,
    activeTab,
    onClose,
    onTabChange,
    onAssignmentClick,
    onCreatePurchaseOrder,
    onAction,
}) => {
    const trTab = useTrAdminShipmentSlideoutTab();
    const { hasPermission } = usePermission();
    const canChangeShipment = hasPermission(Permission.AdminShipmentsUpdate);

    const { form } = useUpdateShipmentPurchaseOrder(shipment);
    const { data: collectionRequest } = useShipmentCollectionRequestQuery(shipment.id);

    const handleDisconnectPurchaseOrder = () => {
        form.setValues({
            purchaseOrderId: undefined,
        });
        form.submit();
    };

    const getShipmentSlideoutTabsToShow = () => {
        if (collectionRequest) {
            return allShipmentSlideoutTabs;
        }
        return allShipmentSlideoutTabs.filter(i => i !== ShipmentSlideoutTab.CollectionSurvey);
    };

    const slideoutContent = () => {
        switch (activeTab) {
            case ShipmentSlideoutTab.Details:
                return (
                    <>
                        <ShipmentStatusPane
                            disabled={!canChangeShipment}
                            marginTop={30}
                            shipment={shipment}
                            onBook={() => onAction(ShipmentAction.Book)}
                            onShip={() => onAction(ShipmentAction.Ship)}
                            onComplete={() => onAction(ShipmentAction.Complete)}
                            onBackorder={() => onAction(ShipmentAction.Backorder)}
                        />
                        {isShipmentFromSupplierToUser(shipment) && (
                            <ShipmentPurchaseOrderPane
                                marginTop={30}
                                onCreatePurchaseOrder={() => onCreatePurchaseOrder(shipment.id, activeTab)}
                                onDisconnectPurchaseOrder={handleDisconnectPurchaseOrder}
                                shipment={shipment}
                            />
                        )}
                        {((isShipmentFromHofyWarehouse(shipment) && !shipment.fromWarehouse) ||
                            (isShipmentToHofyWarehouse(shipment) && !shipment.toWarehouse)) && (
                            <Paragraph3 color={Color.FoundationNegative} marginVertical={20}>
                                Please select warehouse
                            </Paragraph3>
                        )}
                        <ShipmentSlideoutDetails shipment={shipment} />
                    </>
                );
            case ShipmentSlideoutTab.Assignments:
                return (
                    <ShipmentSlideoutAssignments canChangeShipment={canChangeShipment} shipment={shipment} />
                );
            case ShipmentSlideoutTab.Notes:
                return (
                    <Box paddingVertical={20}>
                        <NotesEditor
                            source={NoteSource.Shipments}
                            entityId={shipment.id}
                            canSetCustomerVisibleNote
                        />
                    </Box>
                );
            case ShipmentSlideoutTab.CollectionSurvey:
                return (
                    <ShipmentSlideoutCollectionSurvey
                        shipment={shipment}
                        collectionRequest={collectionRequest}
                        onAssignmentClick={onAssignmentClick}
                    />
                );
            case ShipmentSlideoutTab.Tracking:
                return <ShipmentTracking shipment={shipment} />;
        }
    };

    return (
        <>
            <SlideoutTabbedHeader
                title={
                    <Box row fullWidth justify='space-between'>
                        <Box row gap={12}>
                            <Heading3>Shipment {shipment.publicId}</Heading3>
                            <ShipmentTypeChip marginLeft={12} type={shipment.adminType} />
                            {isScheduled(shipment) && <Badge icon={SvgIcon.Calendar} color='teal' />}
                            <ShipmentMenu
                                shipment={shipment}
                                isCollectionSurveyCreated={collectionRequest !== null}
                                onAction={onAction}
                                marginLeft={12}
                            />
                        </Box>
                        <ShipmentAdminSelector shipmentId={shipment.id} user={shipment.assignedUser} />
                    </Box>
                }
                tabsSlot={
                    <Tabs active={activeTab} onChange={onTabChange}>
                        {getShipmentSlideoutTabsToShow().map(tab => (
                            <Tab key={tab} id={tab} label={trTab(tab)} />
                        ))}
                    </Tabs>
                }
            />
            <SlideoutContent>{slideoutContent()}</SlideoutContent>
            <SlideoutFooter>
                <CancelButton label='Close' onClick={onClose} />
            </SlideoutFooter>
        </>
    );
};
