import { debounce } from 'lodash';
import { RefObject, useEffect, useMemo } from 'react';
import { usePrevious, useScroll } from 'react-use';

export const useScrollEnd = (ref: RefObject<HTMLDivElement>, onEnd: () => void, buffer: number = 20) => {
    const scroll = useScroll(ref);

    const prevScrollY = usePrevious(scroll.y);

    const isEnd = (value: number, margin: number) => {
        return Math.abs(value) < margin;
    };

    const debouncedOnEnd = useMemo(() => debounce(onEnd, 100), [onEnd]);

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const isEndScroll = isEnd(
            ref.current.scrollHeight - ref.current.scrollTop - ref.current.clientHeight,
            buffer,
        );
        if (
            ref.current.scrollHeight > ref.current.clientHeight &&
            isEndScroll &&
            (prevScrollY || 0) < scroll.y
        ) {
            debouncedOnEnd();
        }
    }, [scroll.x, scroll.y, ref.current]);

    return {
        ref,
    };
};
