import React, { FC, useState } from 'react';

import { InspectionDetailsUnionDto } from '@hofy/api-admin';
import { MarginValues } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    FormSection,
    IconButton,
    isRequired,
    LabeledText,
    SvgIcon,
    useForm,
    validator,
} from '@hofy/ui';

import { WarehouseBinSelector } from '../../../components/domain/warehouses/WarehouseBinSelector';
import { useAssignInspectionHofyWarehouseBin } from '../../../store/inspections/useAssignInspectionHofyWarehouseBin';

interface ItemLocationSectionProps {
    inspection: InspectionDetailsUnionDto;
}

export const ItemLocationSection: FC<ItemLocationSectionProps> = ({ inspection }) => {
    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <FormSection label='Item location'>
            <Box row fullWidth alignItems='flex-start' gap={10}>
                <LabeledText flex={1} label='Warehouse' content={`${inspection.warehouse.name}`} />
                <Box flex={3} row gap={10} alignItems='flex-start'>
                    {isEditMode ? (
                        <WarehouseBinEditor inspection={inspection} onCancel={() => setIsEditMode(false)} />
                    ) : (
                        <LabeledText
                            label={
                                <Box row gap={10}>
                                    Warehouse bin identifier{' '}
                                    <IconButton icon={SvgIcon.Edit} onClick={() => setIsEditMode(true)} />
                                </Box>
                            }
                            content={inspection.hofyWarehouseBin?.identifier}
                        />
                    )}
                </Box>
            </Box>
        </FormSection>
    );
};

interface WarehouseBinEditorProps {
    inspection: InspectionDetailsUnionDto;
    onCancel(): void;
}

const WarehouseBinEditor: FC<WarehouseBinEditorProps> = ({ inspection, onCancel }) => {
    const { assignBin, isLoading, isError } = useAssignInspectionHofyWarehouseBin(inspection.id, onCancel);
    const form = useForm<{
        bin: string | null;
    }>({
        initial: {
            bin: inspection.hofyWarehouseBin?.identifier ?? null,
        },
        initialDeps: [inspection],
        onSubmit: ({ bin }) => assignBin(bin),
        validate: validator<{
            bin: string | null;
        }>({
            bin: isRequired('Bin is required'),
        }),
    });

    return (
        <>
            <WarehouseBinSelector
                api={form.fields.bin}
                warehouse={inspection.warehouse}
                isAddBinPromptEnabled
                isRequired
                width={260}
            />
            <Box row gap={10} marginTop={28 as MarginValues}>
                <AsyncButton
                    label='Update'
                    onClick={() => assignBin(form.values.bin)}
                    isLoading={isLoading}
                    isError={isError}
                    disabled={!form.values.bin || inspection.hofyWarehouseBin?.identifier === form.values.bin}
                />
                <Button label='Cancel' type='ghost' onClick={onCancel} />
            </Box>
        </>
    );
};
