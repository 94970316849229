import { camelCase, clone, isNil, isPlainObject, omitBy } from 'lodash';
import { stringifyUrl as queryStringifyUrl, StringifiableRecord, StringifyOptions } from 'query-string';

export type Stringifiable = string | boolean | number | null | undefined;

type QueryObject = Record<
    string,
    | Stringifiable
    // e.g. Countries[]
    | Stringifiable[]
    // e.g. DateRangeStrings
    | Record<string, any>
>;

export interface UrlObject {
    url: string;
    query?: QueryObject;
}

/**
 * Stringifies the provided object into a URL
 * It can handle nested objects and arrays in the query object
 *
 * Example:
 * ```
 * { dateRange: { from: '2021-01-01', to: '2021-01-31' } }
 * ```
 * will be stringified as: `?dateRangeFrom=2021-01-01&dateRangeTo=2021-01-31`
 *
 * ```
 * { countries: ['US', 'CA'] }
 * ```
 * will be stringified as: `?countries=US&countries=CA`
 */
export const stringifyUrl = ({ url, query }: UrlObject, options?: StringifyOptions): string => {
    const clonedQuery = (clone(query) || {}) as QueryObject;

    for (const [key, value] of Object.entries(clonedQuery)) {
        if (!isPlainObject(value) || !value) {
            continue;
        }
        clonedQuery[key] = undefined;
        for (const [subKey, subValue] of Object.entries(value)) {
            const newKey = camelCase(`${key} ${subKey}`);
            clonedQuery[newKey] = subValue;
        }
    }

    const cleanQuery = omitBy(clonedQuery, item => isNil(item) || item === '') as StringifiableRecord;

    return queryStringifyUrl({ url, query: cleanQuery }, options);
};
