import React, { FC } from 'react';
import { Route } from 'react-router-dom';

import { ShipmentAction } from '../../../shipmentSlideout/ShipmentAction';
import { CreatePurchaseOrderSlideout } from './CreatePurchaseOrderSlideout';

interface CreatePurchaseOrderSlideoutRouterProps {
    base: string;
    onClose(): void;
}

export const CreatePurchaseOrderForShipmentSlideoutRouter: FC<CreatePurchaseOrderSlideoutRouterProps> = ({
    base,
    onClose,
}) => {
    return (
        <Route path={`${base}/${ShipmentAction.CreatePurchaseOrder}`} exact>
            <CreatePurchaseOrderSlideout onClose={onClose} />
        </Route>
    );
};
