import React, { FC } from 'react';

import { Checkbox } from '@hofy/common';

interface ShipmentCheckBoxProps {
    onSelect(): void;
    isSelected: boolean;
}

export const ShipmentCheckBox: FC<ShipmentCheckBoxProps> = ({ onSelect, isSelected }) => {
    return (
        <Checkbox checked={isSelected} onChange={onSelect}>
            &nbsp;
        </Checkbox>
    );
};
