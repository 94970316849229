import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { issueService } from '../service/issueService';
import { issuesCacheKey } from './cacheKey';

export const useIssueQuery = (id: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [issuesCacheKey, id],
        queryFn: () => issueService.getIssue(id),
    });

    return {
        data,
        isLoading,
    };
};
