import { useQuery } from '@tanstack/react-query';

import { versionService } from '../service/versionService';

export const useAppVersion = (isCancelled: boolean) => {
    const query = useQuery({
        queryKey: ['version'],
        queryFn: versionService.getCurrentVersion,
        refetchOnWindowFocus: true,
        refetchInterval: isCancelled ? undefined : 10 * 1000 * 60,
        refetchIntervalInBackground: false,
    });
    return query.data;
};
