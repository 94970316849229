import { flip, offset, shift, size } from '@floating-ui/react';

import { ContentWidth } from '../types/InteractiveListTypes';

interface DropdownMiddlewareArgs {
    contentWidth?: ContentWidth;
    contentMaxHeight?: number;
    flipPadding?: number;
}

export const dropdownMiddleware = ({
    contentWidth,
    contentMaxHeight,
    flipPadding,
}: DropdownMiddlewareArgs) => {
    return [
        offset(4),
        flip({ padding: flipPadding ?? 8 }),
        shift({ padding: 8 }),
        size(() => ({
            apply({ rects, elements, availableHeight }) {
                Object.assign(elements.floating.style, {
                    maxHeight: `${
                        contentMaxHeight && contentMaxHeight < availableHeight
                            ? contentMaxHeight
                            : availableHeight
                    }px`,
                    minWidth: contentWidth ? undefined : `${rects.reference.width}px`,
                    width: typeof contentWidth === 'number' ? `${contentWidth}px` : contentWidth,
                });
            },
            padding: 8,
        })),
    ];
};
