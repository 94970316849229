import React, { ReactNode } from 'react';

import { FilterApiMulti } from '../../../modules/filters/useFilters';
import { HeaderFilterCell } from './HeaderFilterCell';

interface FilterHeaderCellProps<T> {
    label: string;
    filter: FilterApiMulti<T>;
    contentWidth?: number;
    searchable?: boolean;
    disabled?: boolean;
    toLabel?(value: T): ReactNode;
}

export const FilterHeaderCell = <T,>({ label, filter, toLabel, ...rest }: FilterHeaderCellProps<T>) => {
    return (
        <HeaderFilterCell
            label={label}
            options={filter.allValues || []}
            values={filter.value}
            onChange={filter.set}
            toText={filter.toLabel}
            toLabel={toLabel}
            active={filter.isChanged()}
            {...rest}
        />
    );
};
