import React, { FC, useRef, useState } from 'react';

import { UUID } from '@hofy/global';
import {
    FormContainer,
    FormModal,
    LabeledInput,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useItemCode } from '../../../store/items/useItemCode';

interface LookupItemModalProps {
    onCancel?(): void;
    onOpenItemDetails(id: UUID): void;
}

export const LookupItemModal: FC<LookupItemModalProps> = ({ onCancel = () => {}, onOpenItemDetails }) => {
    const [code, setCode] = useState('');
    const ref = useRef<HTMLInputElement>(null);
    const { isError, findItem, isLoading } = useItemCode(code, onOpenItemDetails);

    return (
        <FormModal
            onSubmit={findItem}
            onClose={onCancel}
            submitOnEnter
            isLoading={isLoading}
            isError={isError}
            width={500}
        >
            <ModalHeader title='Lookup item' />
            <ModalContent>
                <FormContainer>
                    <Paragraph3>Enter or scan a item code to view details of the item</Paragraph3>
                    <LabeledInput
                        ref={ref}
                        label='Item code'
                        value={code}
                        onChange={setCode}
                        placeholder='Enter or scan item code'
                        errorMessage={isError ? 'Item code not found' : undefined}
                        autoFocus
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onCancel} />
                <SubmitButton label='Lookup item' />
            </ModalFooter>
        </FormModal>
    );
};
