import React, { ReactNode } from 'react';

import { FilterApiSingle } from '../../../modules';
import { Button } from '../../form';
import { HeaderFilterCell } from './HeaderFilterCell';

interface FilterHeaderCellSingleProps<T> {
    label: string;
    filter: FilterApiSingle<T | null>;
    contentWidth?: number;
    searchable?: boolean;
    disabled?: boolean;
    isActive?: boolean;
    nullable?: boolean;
    toLabel?(value: T): ReactNode;
}

export const FilterHeaderCellSingle = <T,>({
    label,
    filter,
    toLabel,
    nullable,
    ...rest
}: FilterHeaderCellSingleProps<T>) => {
    return (
        <>
            <HeaderFilterCell
                label={label}
                options={filter.allValues || []}
                values={filter.value ? [filter.value] : []}
                onChange={(v: T[]) => (v.length >= 1 ? filter.set(v[v.length - 1]) : filter.set(null))}
                toText={filter.toLabel}
                {...rest}
            />
            {nullable && <Button type='secondary' label='Clear' onClick={() => filter.set(null)} />}
        </>
    );
};
