export const passwordFormI18n = {
    'password-form.password': 'Password',
    'password-form.password.tooltip':
        'Ensure your password is unique. Using the same password to log into multiple accounts such as social media profiles increases your security risk',
    'password-form.password.errors.not-valid': 'Invalid password',
    'password-form.confirmed-password': 'Confirm password',
    'password-form.confirmed-password.errors.not-match': 'Password and confirmed password must match',
    'password-form.password-validation.8-characters': 'At least 8 characters',
    'password-form.password-validation.capital': 'One uppercase character',
    'password-form.password-validation.number': 'One number',
    'password-form.password-validation.symbol': 'One symbol: !@#$%^&*',
};
