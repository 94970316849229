import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { salesOrderService } from '../service/salesOrderService';
import { SalesOrderDto } from '../service/types/SalesOrderDto';
import { SalesOrderFilters } from '../service/types/SalesOrderFilters';
import { salesOrdersQueryKey } from './salesOrderQueryKey';

export const useSalesOrdersQuery = (filters: SalesOrderFilters) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [salesOrdersQueryKey, filters],
        queryFn: param =>
            salesOrderService.listSalesOrders(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const salesOrders = useMemo<SalesOrderDto[]>(
        () => (data ? data.pages.flatMap(v => v.content) : []),
        [data],
    );

    return {
        data: salesOrders,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
