import React, { ElementRef, forwardRef, ReactNode, useId } from 'react';

import { useIsDisabled } from '../../../contexts';
import { ToggleLabel } from '../shared/ToggleLabel';
import { Checkbox, CheckboxProps } from './Checkbox';

export interface LabeledCheckboxProps extends CheckboxProps {
    label: ReactNode;
    errorMessage?: string;
    helperText?: string;
    isRequired?: boolean;
}

export const LabeledCheckbox = forwardRef<ElementRef<'div'>, LabeledCheckboxProps>(
    (
        {
            label,
            errorMessage,
            helperText,
            isRequired,

            checked,
            onChange,
            onFocus,
            onBlur,
            disabled: checkboxDisabled,
            testKey,

            ...rest
        },
        ref,
    ) => {
        const id = useId();
        const disabled = useIsDisabled(checkboxDisabled);

        return (
            <ToggleLabel
                label={label}
                errorMessage={errorMessage}
                helperText={helperText}
                isRequired={isRequired}
                disabled={disabled}
                onClick={() => onChange?.(!checked)}
                id={id}
                data-test-key='labeled-label'
                {...rest}
            >
                <Checkbox
                    ref={ref}
                    checked={checked}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    isError={!!errorMessage}
                    id={id}
                />
            </ToggleLabel>
        );
    },
);
