import { getEnumValues } from '@hofy/global';

export enum ShipmentTrackingStatus {
    Created = 'created',
    InTransit = 'in_transit',
    InDistribution = 'in_distribution',
    MissedDelivery = 'missed_delivery',
    Delivered = 'delivered',
    Exception = 'exception',
}

export const allShipmentTrackingStatuses = getEnumValues<ShipmentTrackingStatus>(ShipmentTrackingStatus);
