import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    contractService,
    itemCacheKey,
    itemsService,
    useHofySubsidiaries,
    VariantDto,
} from '@hofy/api-admin';
import { ItemGrade, Ownership } from '@hofy/api-shared';
import { Currency, DateString, isZero, Price, UUID, zeroDollars } from '@hofy/global';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

import { useHofyWarehouse } from '../warehouses/useHofyWarehouse';
import { useItemEstimatedValue } from './useItemEstimatedValue';

export interface CreateItemFormData {
    createSingle: boolean;
    itemCode: string;
    grade: ItemGrade;
    receivedOn: DateString;
    variant: VariantDto | null;
    serialNumber: string | null;
    warehouseId?: UUID;
    estimatedValue: Price;

    ownership: Ownership;
    organization: UUID | null;
    activationFee: Price | null;
    monthlyFee: Price | null;
}

interface CreateHofyItemFormValidatedData extends CreateItemFormData {
    variant: VariantDto;
    warehouseId: UUID;

    ownership: Ownership.Hofy;
    organizationId: null;
    hofySubsidiaryId: null;
    billingEntityId: null;
    activationFee: null;
    monthlyFee: null;
}

interface CreateOrganizationItemFormValidatedData extends CreateItemFormData {
    variant: VariantDto;
    warehouseId: UUID;

    ownership: Ownership.Organization;
    organization: UUID;
    activationFee: Price;
    monthlyFee: Price;
}

export interface CreateItemFormDataErrors {
    itemCode?: string;
    warehouseId?: string;
    receivedOn?: string;
    estimatedValue?: string;
    grade?: string;
    serialNumber?: string;

    organization?: string;
    activationFee?: string;
    monthlyFee?: string;
}

type CreateItemFormValidatedData = CreateHofyItemFormValidatedData | CreateOrganizationItemFormValidatedData;

export const useCreateItem = (onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const [warehouse] = useHofyWarehouse();
    const { hofySubsidiaries } = useHofySubsidiaries();

    const onCreateItem = (data: CreateItemFormValidatedData) => {
        const device = {
            itemCode: data.itemCode.trim(),
            variantId: data.variant.id,
            grade: data.grade,
            receivedOn: data.receivedOn,
            serialNumber: data.serialNumber,
            warehouseId: data.warehouseId,
            estimatedValue: data.estimatedValue,
            warehouseBinId: null,
        };
        if (data.ownership === Ownership.Hofy) {
            return createHofyItemMutation.mutate(device);
        } else {
            return createCustomerOwnedDeviceMutation.mutate({
                contract: {
                    organizationId: data.organization,
                    activationFee: data.activationFee,
                    monthlyFee: data.monthlyFee,
                },
                asset: device,
                byodOrderItemId: null,
            });
        }
    };

    const form = useForm<CreateItemFormData, CreateItemFormValidatedData, CreateItemFormDataErrors>({
        initial: {
            createSingle: true,
            itemCode: '',
            grade: ItemGrade.New,
            receivedOn: nowISODate(),
            variant: null,
            warehouseId: warehouse?.id,
            serialNumber: null,
            estimatedValue: zeroDollars,
            ownership: Ownership.Hofy,

            organization: null,
            activationFee: null,
            monthlyFee: null,
        },
        onSubmit: onCreateItem,
        validate: ({
            itemCode,
            warehouseId,
            receivedOn,
            grade,
            estimatedValue,
            ownership,
            organization,
            activationFee,
            monthlyFee,
        }) => ({
            itemCode: errorMap([isEmpty(itemCode), 'Item code is required']),
            warehouseId: errorMap([isEmpty(warehouseId), 'Warehouse is required']),
            receivedOn: errorMap([isEmpty(receivedOn), 'Received on date is required']),
            grade: errorMap([isEmpty(grade), 'Grade is required']),
            estimatedValue: errorMap([isZero(estimatedValue), 'Estimated value must not be zero']),

            organization: errorMap([
                ownership === Ownership.Organization && !organization,
                'Organization is required',
            ]),

            activationFee: errorMap([
                ownership === Ownership.Organization && !activationFee,
                'Activation fee is required',
            ]),
            monthlyFee: errorMap([
                ownership === Ownership.Organization && !monthlyFee,
                'Monthly fee is required',
            ]),
        }),
    });

    const { value } = useItemEstimatedValue(form.values.variant?.id, warehouse?.id);

    useEffect(() => {
        if (!warehouse) {
            return;
        }
        const warehouseSubsidiary = hofySubsidiaries.find(
            sub => sub.id === warehouse.hofyWarehouse.hofySubsidiaryId,
        );

        form.setValues({
            estimatedValue: {
                currency: warehouseSubsidiary?.currency ?? Currency.USD,
                amount: form.values.estimatedValue.amount,
            },
            warehouseId: warehouse.id,
        });
    }, [warehouse, hofySubsidiaries]);

    useEffect(() => {
        form.setValues({
            estimatedValue: value,
        });
    }, [value]);

    const saveAndClear = () => {
        form.setValues({
            createSingle: false,
        });
        form.submit();
    };

    const saveAndClose = () => {
        form.setValues({
            createSingle: true,
        });
        form.submit();
    };
    const createHofyItemMutation = useMutation({
        mutationFn: itemsService.createItem,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [itemCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Hofy item created',
            });
            form.setValues({
                itemCode: '',
                serialNumber: '',
            });
            if (form.values.createSingle) {
                onSuccess?.();
            }
        },
    });
    const createCustomerOwnedDeviceMutation = useMutation({
        mutationFn: contractService.createCustomerOwnedAsset,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [itemCacheKey],
            });
            showToast({
                type: 'positive',
                message: 'Customer asset created',
            });
            form.setValues({
                itemCode: '',
                serialNumber: '',
            });
            if (form.values.createSingle) {
                onSuccess?.();
            }
        },
    });

    return {
        form,
        isLoading: createHofyItemMutation.isPending || createCustomerOwnedDeviceMutation.isPending,
        saveAndClear,
        saveAndClose,
    };
};
