import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ShipmentType, shipmentTypeColors, useTrShipmentType } from '@hofy/api-shared';
import { colorDyes } from '@hofy/theme';
import { Badge, BoxProps } from '@hofy/ui';

import { Hoverable } from '../../utils/Hoverable';

interface ShipmentTypeCellProps extends BoxProps {
    publicId: string;
    type: ShipmentType;
    linkToOpenShipment: string;
}

export const ShipmentTypeCell: FC<ShipmentTypeCellProps> = ({
    publicId,
    type,
    linkToOpenShipment,
    ...boxProps
}) => {
    const trShipmentType = useTrShipmentType();
    const color = shipmentTypeColors[type];

    return (
        <Link to={linkToOpenShipment}>
            <Hoverable color={colorDyes[color].onMuted} {...boxProps}>
                <Badge
                    label={`${trShipmentType(type)} #${publicId}`}
                    color={color}
                    data-test-key='shipment-type'
                />
            </Hoverable>
        </Link>
    );
};
