import React, { FC, ReactNode } from 'react';

import { VisualType } from '@hofy/global';
import { Color, toColorDye } from '@hofy/theme';

import { visualTypeIcon } from '../../config/visualTypeIcon';
import { renderTextNode } from '../../helpers/React';
import { Box, OuterBoxProps, Paragraph3, SectionTitle3 } from '../base';
import { FeaturedIcon } from '../icon';

export interface AlertProps extends OuterBoxProps {
    description: ReactNode;
    type: VisualType;

    title?: ReactNode;
    icon?: Svg;
    children?: ReactNode;

    /** If true, the alert will be horizontal with the buttons on the right */
    horizontal?: boolean;
    /** If true, the alert will not have rounded corners, should be used as a banner on top of the page/section */
    banner?: boolean;
}

export const Alert: FC<AlertProps> = ({
    description,
    type,
    title,
    icon = visualTypeIcon[type],
    banner,
    children,
    horizontal,
    ...boxProps
}) => {
    const { surface, surfaceBorder } = toColorDye(type);
    const hasTitle = title !== undefined;

    const titleNode = renderTextNode(title, text => <SectionTitle3>{text}</SectionTitle3>);
    const descriptionNode = renderTextNode(description, text => (
        <Paragraph3 color={hasTitle ? Color.ContentSecondary : Color.ContentPrimary}>{text}</Paragraph3>
    ));

    return (
        <Box
            padding={16}
            direction={horizontal ? 'row' : 'column'}
            gap={16}
            bg={surface}
            border
            borderColor={surfaceBorder}
            rounded={banner ? undefined : 8}
            {...boxProps}
        >
            <Box row gap={16} flex={1} alignItems={horizontal ? 'flex-start' : 'center'}>
                <FeaturedIcon type={type} variant='vivid' shape='square' size={40} icon={icon} />
                <Box column gap={4} flex={1}>
                    {titleNode}
                    {descriptionNode}
                </Box>
            </Box>
            {children && (
                <Box row gap={16} justify='flex-end'>
                    {children}
                </Box>
            )}
        </Box>
    );
};
