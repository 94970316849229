import React, { FC } from 'react';

import { BYODOrderItemDto } from '@hofy/api-admin';
import { formatVariant, useTrProductCategory } from '@hofy/api-shared';
import { formatPriceWithCurrency } from '@hofy/global';
import { Color } from '@hofy/theme';
import { BaseTable, BaseTableColumnConfig, BaseTableHeader, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { ProductItem } from '../../../../components/domain/products/ProductItem';

interface BYODOrderItemsTableProps {
    byodItems: BYODOrderItemDto[];
}

export const BYODOrderItemsTable: FC<BYODOrderItemsTableProps> = ({ byodItems }) => {
    const trProductCategory = useTrProductCategory();

    return (
        <BaseTable
            data={byodItems}
            toKey={byodOrder => byodOrder.id}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            headerRenderer={BYODOrderItemsTableHeader}
            columns={[
                {
                    id: 'product',
                    header: 'Product',
                    width: 400,
                    renderer: byodOrderItem => (
                        <ProductItem
                            image={byodOrderItem.variant.image}
                            label={byodOrderItem.product.name}
                            subLabel={formatVariant(byodOrderItem.variant)}
                        />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    renderer: byodOrderItem => trProductCategory(byodOrderItem.product.category),
                },
                {
                    id: 'sku',
                    header: 'SKU',
                    renderer: byodOrderItem => byodOrderItem.variant.sku,
                },
                {
                    id: 'value',
                    header: 'Value',
                    renderer: byodOrderItem => formatPriceWithCurrency(byodOrderItem.unitPurchasePrice),
                },
                {
                    id: 'expected',
                    header: 'Expected Items',
                    renderer: byodOrderItem => byodOrderItem.expectedQuantity,
                },
                {
                    id: 'received',
                    header: 'Received Items',
                    renderer: byodOrderItem => (
                        <Paragraph3 gap={8}>
                            {getReceivedItemsIcon(
                                byodOrderItem.receivedQuantity,
                                byodOrderItem.expectedQuantity,
                            )}
                            {byodOrderItem.receivedQuantity}
                        </Paragraph3>
                    ),
                },
            ]}
        />
    );
};

const BYODOrderItemsTableHeader = (columns: BaseTableColumnConfig<BYODOrderItemDto>[]) => (
    <BaseTableHeader paddingHorizontal={40} columns={columns} bgColor={Color.BackgroundSurfaceNeutral} />
);

const getReceivedItemsIcon = (receivedQuantity: number, expectedQuantity: number) => {
    if (receivedQuantity === expectedQuantity) {
        return <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />;
    }

    if (receivedQuantity < expectedQuantity) {
        return <Icon svg={SvgIcon.ArrowDown} color={Color.ContentNegative} />;
    }

    return null;
};
