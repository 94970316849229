import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
    emptyTermAndConditionPayload,
    TermAndConditionDto,
    TermAndConditionPayload,
    termAndConditionsCacheKey,
    termAndConditionService,
} from '@hofy/api-admin';
import { errorMap, isEmpty } from '@hofy/helpers';
import { useForm, useToast } from '@hofy/ui';

export const useUpdateTermAndCondition = () => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const [currentRow, setCurrentRow] = useState<TermAndConditionDto | null>(null);
    const mutation = useMutation({
        mutationFn: (payload: TermAndConditionPayload) =>
            termAndConditionService.updateTermAndCondition(currentRow?.id!, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [termAndConditionsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Term and Condition successfully saved',
            });
            setCurrentRow(null);
        },
    });

    const form = useForm<TermAndConditionPayload>({
        initial: emptyTermAndConditionPayload,
        onSubmit: mutation.mutate,
        validate: ({ name }) => ({
            name: errorMap([isEmpty(name), "Can't be empty"]),
        }),
    });

    return {
        form,
        currentRow,
        setCurrentRow,
        isLoadingMutation: mutation.isPending,
        isMutationSuccess: mutation.isSuccess,
    };
};
