import { BYODOrderStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export interface BYODOrdersFilter {
    search: string;
    warehouses: UUID[];
    statuses: BYODOrderStatus[];
}

export const emptyBYODOrdersFilter: BYODOrdersFilter = {
    search: '',
    warehouses: [],
    statuses: [],
};
