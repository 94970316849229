import React, { FC } from 'react';

import { InvoiceEntryDetailsDto } from '@hofy/api-admin';
import {
    InvoiceEntryType,
    periodRequiredTypes,
    productCategoryRequiredTypes,
    useTrContractType,
} from '@hofy/api-shared';
import { FormRow } from '@hofy/common';
import { UUID } from '@hofy/global';
import { FormCategorySelect } from '@hofy/product';
import {
    Box,
    FormContainer,
    FormDateInput,
    FormDecimalInput,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    FormSelect,
    FormTextarea,
    LabeledSelect,
    Paragraph4,
} from '@hofy/ui';

import { AuditSection } from '../../../../components/domain/auditingInline/AuditSection';
import { FormBillingEntitySelect } from '../../../../components/domain/billingEntity/FormBillingEntitySelect';
import { FormHofySubsidiarySelect } from '../../../../components/domain/hofySubsidiaries/FormHofySubsidiarySelect';
import { SubContractRef } from '../../../../store/invoiceEntries/useCreateInvoiceEntry';
import { UseInvoiceEntryForm } from '../../../../store/invoiceEntries/useInvoiceEntryForm';
import { useTrInvoiceEntryType } from '../../../../store/invoiceEntries/useTrInvoiceEntryType';

interface InvoiceEntryFormProps {
    formState: UseInvoiceEntryForm;
    entryTypes: InvoiceEntryType[];
    organizationId: UUID | null;
    editable?: boolean;
    invoiceEntry?: InvoiceEntryDetailsDto;
    availableSubContracts?: SubContractRef[];
}

export const InvoiceEntryForm: FC<InvoiceEntryFormProps> = ({
    formState: { form, setDiscount, setUnitPrice, setPrice, changeBillingEntity, changeType },
    entryTypes,
    editable = true,
    organizationId,
    invoiceEntry,
    availableSubContracts,
}) => {
    const trEntryType = useTrInvoiceEntryType();
    const trContractType = useTrContractType();
    const canChangeSubsidiary = editable && !form.values.subContractId;

    const isCreateFlow = invoiceEntry === undefined;
    const typeRequiresPeriod = form.values.type !== null && periodRequiredTypes.includes(form.values.type);
    const typeRequiresProductCategory =
        form.values.type !== null && productCategoryRequiredTypes.includes(form.values.type);

    const selectedSubContract = availableSubContracts?.find(
        c => c.subContractId === form.values.subContractId,
    );
    return (
        <Box marginTop={10}>
            <FormContainer>
                {(availableSubContracts?.length ?? 0) > 0 && (
                    <LabeledSelect
                        label='Selected contract'
                        marginRight={20}
                        options={availableSubContracts!}
                        value={selectedSubContract}
                        toText={c => `${trContractType(c.contractType)} - ${c.subContractId}`}
                        onChange={opt => {
                            form.setValues({
                                subContractId: opt?.subContractId,
                                hofySubsidiaryId: opt?.hofySubsidiaryId,
                                billingEntityId: opt?.billingEntityId,
                            });
                        }}
                    />
                )}
                <FormSelect
                    api={form.fields.type}
                    label='Type'
                    marginRight={20}
                    options={entryTypes}
                    onChange={changeType}
                    toText={trEntryType}
                    disabled={!editable || !!invoiceEntry}
                />
                {isCreateFlow && typeRequiresProductCategory && (
                    <FormCategorySelect
                        label='Product category'
                        api={form.fields.productCategory}
                        nullable
                        isRequired
                    />
                )}
                <Box>
                    <FormTextarea
                        label='Description'
                        api={form.fields.description}
                        rows={5}
                        disabled={!editable}
                    />
                    {form.values.subContractId && (
                        <Paragraph4 marginTop={4}>Contract id suffix will be automatically added</Paragraph4>
                    )}
                </Box>
                <FormSection label='Invoice'>
                    <FormDateInput label='Date' api={form.fields.invoiceTime} disabled={!editable} />
                    {typeRequiresPeriod && (
                        <>
                            <FormDateInput
                                label='From'
                                api={form.fields.invoicePeriodFrom}
                                disabled={!editable}
                                nullable
                            />
                            <FormDateInput
                                label='To'
                                api={form.fields.invoicePeriodTo}
                                disabled={!editable}
                                nullable
                            />
                        </>
                    )}
                </FormSection>
                <FormSection label='Price'>
                    <FormRow>
                        <FormPriceInput
                            label='Unit Price'
                            api={form.fields.unitPrice}
                            onChange={setUnitPrice}
                            disabled={!editable}
                        />
                        <FormDecimalInput
                            label='Discount'
                            unit='%'
                            api={form.fields.discount}
                            onChange={setDiscount}
                            precision={2}
                            disabled={!editable}
                        />
                        <FormPriceInput
                            label='Price'
                            api={form.fields.price}
                            onChange={setPrice}
                            disabled={!editable}
                        />
                    </FormRow>
                    <FormRow>
                        <FormNumberInput label='Quantity' api={form.fields.quantity} disabled={!editable} />
                    </FormRow>
                </FormSection>
                <FormSection label='Billing'>
                    <FormRow>
                        <FormHofySubsidiarySelect
                            label='Hofy subsidiary'
                            api={form.fields.hofySubsidiaryId}
                            disabled={!canChangeSubsidiary}
                            nullable
                        />
                        <FormBillingEntitySelect
                            organizationId={organizationId ?? undefined}
                            onChange={(_, dto) => changeBillingEntity(dto)}
                            label='Billing entity'
                            api={form.fields.billingEntityId}
                            disabled={!canChangeSubsidiary}
                            nonOrgEntitiesOnly={organizationId === undefined}
                            nullable
                        />
                    </FormRow>
                </FormSection>
                {invoiceEntry && <AuditSection model={invoiceEntry} />}
            </FormContainer>
        </Box>
    );
};
