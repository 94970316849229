import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { Box, BoxProps } from '../../base';

interface ModalContentProps extends BoxProps {
    children?: ReactNode;
}

export const ModalContent: FC<PropsWithChildren<ModalContentProps>> = ({ children, ...boxProps }) => {
    return (
        <Box flex='auto' padding={24} overflow='auto' data-test-key='modal-content' {...boxProps}>
            {children}
        </Box>
    );
};
