import React, { FC } from 'react';

import { Link } from '@hofy/ui';

interface ShipmentTrackingLinkProps {
    link: string | null;
    trackingId: string | null;
}

export const ShipmentTrackingLink: FC<ShipmentTrackingLinkProps> = ({ link, trackingId }) => {
    if (!link || !trackingId) {
        return null;
    }

    return <Link to={link}>#{trackingId}</Link>;
};
