import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { adminService } from '../../admin/service/adminService';
import { adminsCacheKey } from '../../admin/store/cacheKey';

export const useAdminQuery = (id: UUID) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [adminsCacheKey, id],
        queryFn: () => adminService.getAdmin(id),
    });

    return {
        isLoading,
        isError,
        data: data,
    };
};
