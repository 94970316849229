import React, { FC, ReactNode } from 'react';

import { Box, DisableContextProvider, OuterBoxProps, Tooltip } from '@hofy/ui';

interface DisabledWithTooltipWrapperProps extends OuterBoxProps {
    disabled: boolean;
    tooltip: ReactNode;
    children?: ReactNode;
}

export const DisabledWithTooltipWrapper: FC<DisabledWithTooltipWrapperProps> = ({
    disabled,
    children,
    tooltip,
    ...boxProps
}) => {
    return (
        <Tooltip body={tooltip} enabled={disabled} asChild>
            <Box {...boxProps}>
                <DisableContextProvider disabled={disabled}>{children}</DisableContextProvider>
            </Box>
        </Tooltip>
    );
};
