import React, { FC } from 'react';

import { ShipmentDto } from '@hofy/api-admin';
import {
    ShipmentTrackingExceptionStatus,
    ShipmentTrackingProvider,
    useTrShipmentTrackingStatus,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Icon, Link, Paragraph3, Paragraph4, SvgIcon, Tooltip } from '@hofy/ui';

import AftershipSvg from '../../../../assets/Aftership.svg';
import ShippyProSvg from '../../../../assets/Shippypro-logo.svg';
import { ShipmentTrackingLink } from './ShipmentTrackingLink';

export const TrackingStatus: FC<{
    shipment: ShipmentDto;
    onClick(id: UUID): void;
}> = ({ shipment, onClick }) => {
    const trShipmentTrackingStatus = useTrShipmentTrackingStatus();
    if (!shipment.tracking?.lastUpdate?.status) {
        return <ShipmentTrackingLink link={shipment.trackingLink} trackingId={shipment.trackingId} />;
    }

    return (
        <Box row onClick={() => onClick(shipment.id)} gap={8} pointer>
            <Box column gap={8}>
                <TrackingExceptionStatusIcon status={shipment.trackingExceptionStatus} />
                <ShipmentIsLateStatusIcon isLate={shipment.isLate} />
                <ShipmentLinkIcon shipmentLink={shipment.trackingLink} />
            </Box>
            <Box column gap={4}>
                <Paragraph3 color={Color.ContentPrimary} bold>
                    {trShipmentTrackingStatus(shipment.tracking.lastUpdate.status)}
                </Paragraph3>
                <Paragraph4 color={Color.ContentTertiary} textClomp={2} overflow='hidden'>
                    {shipment.tracking.lastUpdate.message}
                </Paragraph4>
                <Paragraph4 color={Color.ContentTertiary} italic>
                    {formatDateTime(shipment.tracking.lastUpdate.updatedAt)}
                </Paragraph4>
            </Box>
        </Box>
    );
};
const TrackingExceptionStatusIcon: FC<{
    status: ShipmentTrackingExceptionStatus | null;
}> = ({ status }) => {
    const { icon, color, tooltip } = trackingExceptionIconDetails(status);
    return (
        <Tooltip placement='bottom' body={tooltip} enabled>
            <Icon svg={icon} color={color} />
        </Tooltip>
    );
};
const ShipmentLinkIcon: FC<{
    shipmentLink: string | null;
}> = ({ shipmentLink }) => {
    const icon = () => (
        <Icon
            svg={SvgIcon.LinkExternal}
            color={shipmentLink ? Color.NonContextualBlueOnMuted : Color.NonContextualGreyMuted}
        />
    );
    return (
        <Tooltip
            placement='bottom'
            body={shipmentLink ? 'Shipment tracking link' : 'No tracking link'}
            enabled
        >
            {shipmentLink && (
                <Link inline to={shipmentLink}>
                    {icon()}
                </Link>
            )}
            {!shipmentLink && icon()}
        </Tooltip>
    );
};
const ShipmentIsLateStatusIcon: FC<{
    isLate: boolean;
}> = ({ isLate }) => {
    return (
        <Tooltip placement='bottom' body={isLate ? 'Shipment is late' : 'Shipment is on time'} enabled>
            <Icon
                svg={SvgIcon.Flag}
                color={isLate ? Color.BackgroundWarning : Color.NonContextualGreyMuted}
            />
        </Tooltip>
    );
};

interface TrackingExceptionIconDetails {
    icon: Svg;
    color: Color;
    tooltip: string;
}

export const trackingExceptionIconDetails = (
    status: ShipmentTrackingExceptionStatus | null,
): TrackingExceptionIconDetails => {
    if (status === null) {
        return {
            icon: SvgIcon.Message,
            color: Color.NonContextualGreyMuted,
            tooltip: 'No exceptions',
        };
    }
    if (status === ShipmentTrackingExceptionStatus.Raised) {
        return {
            icon: SvgIcon.MessageAlert,
            color: Color.NonContextualMagentaDefault,
            tooltip: 'Unresolved exception',
        };
    }
    return {
        icon: SvgIcon.MessageCheck,
        color: Color.NonContextualGreenDefault,
        tooltip: 'All exceptions have been cleared',
    };
};

export const providersIcons = {
    [ShipmentTrackingProvider.HofyAdmin]: SvgIcon.EyeOff,
    [ShipmentTrackingProvider.ShippyPro]: ShippyProSvg,
    [ShipmentTrackingProvider.Aftership]: AftershipSvg,
};
