import React, { FC, ReactNode, useState } from 'react';

import {
    Box,
    Button,
    LabeledInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Paragraph3,
    SvgIcon,
    TestKeyAware,
} from '@hofy/ui';

interface DangerousConfirmModalProps extends TestKeyAware {
    title: ReactNode;
    subTitle?: ReactNode;
    confirmText: string;
    confirmButtonLabel: string;
    cancelButtonLabel: string;
    inputLabel: string;

    width?: number;
    onClose(): void;
    onConfirm(): void;
    children?: ReactNode;
}

export const DangerousConfirmModal: FC<DangerousConfirmModalProps> = ({
    onClose,
    onConfirm,
    width = 450,
    title,
    subTitle,
    children,
    testKey,
    confirmButtonLabel,
    cancelButtonLabel,
    inputLabel,
    confirmText,
}) => {
    const [text, setText] = useState('');
    const actionDisabled = confirmText !== text;

    const handleConfirm = () => {
        onConfirm();
        onClose();
    };

    return (
        <Modal onClose={onClose} width={width} testKey={testKey}>
            <ModalHeader title={title} />
            <ModalContent>
                {!!subTitle && <Paragraph3 marginBottom={20}>{subTitle}</Paragraph3>}
                <LabeledInput label={inputLabel} isRequired value={text} onChange={setText} />
                {children}
            </ModalContent>
            <ModalFooter>
                <Box flex={1}>
                    <Button
                        type='secondary'
                        label={cancelButtonLabel}
                        leftIcon={SvgIcon.Cross}
                        onClick={onClose}
                        testKey='cancel'
                    />
                </Box>
                <Button
                    action='destructive'
                    label={confirmButtonLabel}
                    onClick={handleConfirm}
                    disabled={actionDisabled}
                    testKey='confirm'
                />
            </ModalFooter>
        </Modal>
    );
};
