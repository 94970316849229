import React, { FC } from 'react';

import { NumberValues } from '@hofy/theme';
import { Box, FormGridRow, Placeholder, SvgIllustration } from '@hofy/ui';

import { SupplierContact, SupplierContactCard } from './SupplierContactCard';

interface SupplierContactsTableProps {
    contacts: SupplierContact[];
    columns: number | 'auto-fill' | 'auto-fit';
    onEditContact(index: number): void;
    onDeleteContact(index: number): void;
    summarize?: boolean;
}

export const SupplierContactsTable: FC<SupplierContactsTableProps> = ({
    contacts,
    columns,
    onEditContact,
    onDeleteContact,
    summarize,
}) => {
    if (contacts.length === 0) {
        return (
            <Box paddingTop={100 as NumberValues}>
                <Placeholder
                    illustration={SvgIllustration.ContractSearch}
                    title='No contacts'
                    message='Add new contacts with the button above'
                />
            </Box>
        );
    }

    return (
        <FormGridRow columns={columns} maxColumnWidth={300}>
            {contacts.map((contact, index) => (
                <SupplierContactCard
                    key={`contact-${index}`}
                    contact={contact}
                    onEdit={() => onEditContact(index)}
                    onDelete={() => onDeleteContact(index)}
                    summarize={summarize}
                />
            ))}
        </FormGridRow>
    );
};
