import React, { FocusEvent, forwardRef, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Font, Icon, SvgIcon } from '@hofy/ui';

import { FlatInput } from './base/FlatInput';

interface ClearableInputProps extends BoxProps {
    value: string;
    onChangeText(v: string): void;
    clearable?: boolean;
    onClear?(): void;
    autoFocus?: boolean;
    isError?: boolean;
    placeholder?: string;
    prefix?: ReactNode;
    prefixWidth?: number;
    suffix?: ReactNode;
    testKey?: string;
    disabled?: boolean;
    bordered?: boolean;
    onBlur?(e: FocusEvent): void;
    onFocus?(e: FocusEvent): void;
}

/** @deprecated use `LabeledInput` or `FormInput` with `clearable` prop from `@hofy/ui` */
export const ClearableInput = forwardRef<HTMLInputElement, ClearableInputProps>(
    (
        {
            value = '',
            onChangeText = () => {},
            clearable = true,
            onClear,
            autoFocus,
            isError = false,
            placeholder,
            prefix,
            prefixWidth = 16,
            suffix,
            testKey,
            disabled = false,
            bordered = true,
            onFocus,
            onBlur,
            ...margins
        },
        ref,
    ) => {
        const [isFocused, setIsFocused] = useState(false);
        const clearVisible = clearable && !!value && !disabled;

        const clear = () => {
            onClear?.();
            onChangeText('');
        };

        const getRight = () => {
            if (clearVisible && suffix) {
                return 80;
            }
            if (clearVisible || suffix) {
                return 34;
            }
            return 10;
        };

        const getLeft = () => {
            if (prefix) {
                return 10 + prefixWidth + 6;
            }
            return 10;
        };

        return (
            <Box {...margins} row relative>
                <FlatInput
                    ref={ref}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    isError={isError}
                    bordered={bordered}
                    value={value ?? ''}
                    paddingLeft={getLeft()}
                    paddingRight={getRight()}
                    onChange={e => onChangeText(e.target.value)}
                    placeholder={placeholder}
                    onFocus={e => {
                        setIsFocused(true);
                        onFocus?.(e);
                    }}
                    onBlur={e => {
                        setIsFocused(false);
                        onBlur?.(e);
                    }}
                    data-test-key={`${testKey}-input`}
                />
                {clearVisible && (
                    <ClearIconBox
                        cursor={disabled ? 'initial' : 'pointer'}
                        onClick={disabled ? undefined : clear}
                        right={12}
                        data-test-key='input-clear-button'
                    >
                        <Icon
                            block
                            svg={SvgIcon.Cross}
                            color={isFocused ? Color.Neutral500 : Color.Neutral300}
                            marginLeft={8}
                        />
                    </ClearIconBox>
                )}

                {prefix && (
                    <Addon
                        width={prefixWidth}
                        color={isFocused ? Color.Neutral500 : Color.Neutral300}
                        left={10}
                    >
                        {prefix}
                    </Addon>
                )}

                {suffix && (
                    <Addon
                        color={isFocused ? Color.Neutral500 : Color.Neutral300}
                        right={clearable ? 30 : 4}
                        centered
                        fullHeight
                        width={50}
                    >
                        {suffix}
                    </Addon>
                )}
            </Box>
        );
    },
);

export const ClearIconBox = styled(Box)<{ right: number }>`
    position: absolute;
    top: 50%;
    right: ${p => p.right}px;
    transform: translateY(-50%);
`;

const Addon = styled(Font)<{ left?: number; right?: number; color: string }>`
    position: absolute;
    left: ${p => p.left && p.left + 'px'};
    right: ${p => p.right && p.right + 'px'};
    pointer-events: none;
    color: ${p => p.color};
    font-size: 16px;
    display: flex;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
`;

export const RightIcon = styled(Icon)<{ right: number }>`
    position: absolute;
    top: 50%;
    right: ${p => p.right}px;
    transform: translateY(-50%);
    pointer-events: none;
`;
