import React, { FC } from 'react';

import { useGoBack } from '@hofy/hooks';
import { Button, ButtonProps } from '@hofy/ui';

interface BackButtonBaseProps extends ButtonProps {
    defaultNavigation?: string;
}

type BackButtonProps = Omit<BackButtonBaseProps, 'onClick'>;

export const BackButton: FC<BackButtonProps> = props => {
    const { goBack } = useGoBack();
    const handleBack = () => {
        goBack(props.defaultNavigation);
    };
    return <Button type='ghost' negativeMargin onClick={handleBack} {...props} />;
};
