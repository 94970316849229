import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface PartnerIndicatorProps {
    partnerName: string;
}

export const PartnerIndicator: FC<PartnerIndicatorProps> = ({ partnerName }) => {
    return (
        <Indicator color={Color.AccentLightBlue}>
            <TooltipIcon
                icon={SvgIcon.Building}
                iconColor={Color.AccentLightBlue}
                body={`Partner (${partnerName})`}
                iconSize={16}
            />
        </Indicator>
    );
};
