import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { getSpacingValue, useTheme } from '@hofy/theme';

import { Box, BoxProps } from '../base';
import { useElScrollbarSize } from './hooks/useElScrollbarSize';
import { transparentScrollbarStyles } from './scrollbarTransparentStyles';

export const HiddenHorizontalScroll: FC<BoxProps> = ({
    children,
    padding = 0,
    paddingVertical = padding,
    paddingBottom = paddingVertical,
    paddingTop = paddingVertical,
    ...props
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const { scrollSize, contentSize } = useElScrollbarSize(ref, 'height');

    const theme = useTheme();
    const paddingTopPx = getSpacingValue(theme, paddingTop);
    const paddingBottomPx = getSpacingValue(theme, paddingBottom);

    let maxHeight = contentSize - paddingTopPx - paddingBottomPx;

    // If we have space to fit the scrollbar in right padding area
    // we can increase the max width to keep spacing consistent
    if (paddingBottomPx >= scrollSize) {
        maxHeight += scrollSize;
    }

    return (
        <ContainerBox
            ref={ref}
            padding={padding}
            paddingVertical={paddingVertical}
            paddingTop={paddingTop}
            style={{ paddingBottom: 0 }}
            {...props}
        >
            <Box style={{ height: maxHeight }}>{children}</Box>
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)`
    ${transparentScrollbarStyles}

    overflow-x: auto;

    @media (hover: hover) {
        overflow-x: hidden;

        &:hover {
            overflow-x: auto;
        }
    }
`;
