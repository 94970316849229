export const organizationSignUpPageI18n = {
    'org-sign-up-page.existing-user.title': 'User already exists',
    'org-sign-up-page.existing-user.subtitle':
        'Please choose a different email or try logging in using your existing credentials',
    'org-sign-up-page.existing-user.button': 'Go to sign in page',
    'org-sign-up-page.email-verification.title': 'Check your email',
    'org-sign-up-page.email-verification.message': 'We have sent you a link to create your Hofy account!',
    'org-sign-up-page.email-verification.wrong-email': 'Sent to the wrong email?',
    'org-sign-up-page.email-verification.back': 'Go back',

    'org-sign-up-page.email-password-form.continue': 'Continue',
    'org-sign-up-page.email-password-form.divider.sso': 'Sign up with',
    'org-sign-up-page.email-password-form.divider.email': 'Sign up with email',
    'org-sign-up-page.email-password-form.email': 'Work email',

    'org-sign-up-page.personal-details-form.title': 'Personal details',
    'org-sign-up-page.personal-details-form.first-name.label': 'First name',
    'org-sign-up-page.personal-details-form.first-name.placeholder': 'Your first name',
    'org-sign-up-page.personal-details-form.last-name.label': 'Last name',
    'org-sign-up-page.personal-details-form.last-name.placeholder': 'Your last name',
    'org-sign-up-page.personal-details-form.continue': 'Continue',
    'org-sign-up-page.personal-details-form.errors.first-name': 'First name required',
    'org-sign-up-page.personal-details-form.errors.last-name': 'Last name required',

    'org-sign-up-page.organization-details-form.title': 'Setup your organisation',
    'org-sign-up-page.organization-details-form.company-name.label': 'Company name',
    'org-sign-up-page.organization-details-form.company-size.label': 'Company size',
    'org-sign-up-page.organization-details-form.currency.label': 'Currency',
    'org-sign-up-page.organization-details-form.currency.placeholder': 'Select currency',
    'org-sign-up-page.organization-details-form.country.label': 'Billing country',
    'org-sign-up-page.organization-details-form.country.placeholder': 'Select billing country',
    'org-sign-up-page.organization-details-form.create-account': 'Create account',
    'org-sign-up-page.organization-details-form.errors.company-name': 'Company name required',
    'org-sign-up-page.organization-details-form.errors.company-size': 'Company size required',
    'org-sign-up-page.organization-details-form.errors.currency': 'Currency required',
    'org-sign-up-page.organization-details-form.errors.country': 'Billing country required',
    'org-sign-up-page.organization-details-form.errors.tac': 'Please accept our Terms & Conditions',
    'org-sign-up-page.organization-details-form.errors.token': 'Invalid or expired signup token',
};
