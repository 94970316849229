export enum EmailStatus {
    Created = 'created',
    Sent = 'sent',
    ErrorSent = 'sent_error',
    Processed = 'processed',
    Dropped = 'dropped',
    Deferred = 'deferred',
    Bounced = 'bounce',
    Delivered = 'delivered',
}
