import { range } from 'lodash';
import React, { FC } from 'react';

import { Skeleton } from '../loader';

export interface SummarySkeltonProps {
    height?: number;
    count?: number;
}

export const SummarySkeleton: FC<SummarySkeltonProps> = ({ height, count = 4 }) => {
    return (
        <>
            {range(count).map(i => (
                <Skeleton key={i} height={height} flex={1} />
            ))}
        </>
    );
};
