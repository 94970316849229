import { getEnumValues } from '@hofy/global';

export enum ProductBrand {
    Apple = 'apple',
    Dell = 'dell',
    Lenovo = 'lenovo',
    HP = 'hp',
    Acer = 'acer',
    Asus = 'asus',
    Microsoft = 'microsoft',
    Samsung = 'samsung',
    Other = 'other',
}

export const allProductBrands = getEnumValues<ProductBrand>(ProductBrand);
