import { AddressDto, SupplierRefDto, WarehouseDto } from '@hofy/api-shared';

export enum ShipmentPointType {
    Address,
    HofyWarehouse,
    Supplier,
}

export type ShipmentPoint =
    | {
          type: ShipmentPointType.Address;
          address: AddressDto;
      }
    | {
          type: ShipmentPointType.HofyWarehouse;
          warehouse: WarehouseDto | null;
      }
    | {
          type: ShipmentPointType.Supplier;
          supplier: SupplierRefDto | null;
      };
