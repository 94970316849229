import React, { FC } from 'react';

import { PurchaseOrderItemDto } from '@hofy/api-admin';
import { FormModal, FormNumberInput, ModalContent, ModalFooter, ModalHeader, SubmitButton } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { usePurchaseOrderItemUpdateQuantityForm } from '../../../../../store/purchaseOrders/usePurchaseOrderItemUpdateQuantityForm';

interface ItemDeliveryDateUpdateModalProps {
    purchaseOrderItem: PurchaseOrderItemDto;
    onClose(): void;
}

export const ItemQuantityUpdateModal: FC<ItemDeliveryDateUpdateModalProps> = ({
    purchaseOrderItem,
    onClose,
}) => {
    const purchaseOrderItemQuantityUpdateForm = usePurchaseOrderItemUpdateQuantityForm(
        purchaseOrderItem,
        onClose,
    );

    return (
        <FormModal
            onSubmit={purchaseOrderItemQuantityUpdateForm.form.submit}
            isLoading={purchaseOrderItemQuantityUpdateForm.isLoading}
            onClose={purchaseOrderItemQuantityUpdateForm.form.discard}
            width={600}
        >
            <ModalHeader
                title='Update item quantity'
                subtitle={`For purchase order item #${purchaseOrderItem.id}`}
            />
            <ModalContent>
                <FormNumberInput
                    label='Quantity'
                    api={purchaseOrderItemQuantityUpdateForm.form.fields.quantity}
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <SubmitButton label='Confirm' />
            </ModalFooter>
        </FormModal>
    );
};
