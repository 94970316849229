import { ColorDye, getEnumValues } from '@hofy/global';

export enum AddonType {
    Service = 'service',
    Warranty = 'warranty',
}

export const addonTypeColors: Record<AddonType, ColorDye> = {
    [AddonType.Service]: 'green',
    [AddonType.Warranty]: 'blue',
};

export const isStandardWarrantyAddon = (addonType: AddonType, isPremium: boolean): boolean => {
    return addonType === AddonType.Warranty && !isPremium;
};

export const allAddonTypes = getEnumValues<AddonType>(AddonType);
