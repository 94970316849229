import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { AdminsRouter } from './admins/AdminsRouter';
import { PeopleTab } from './PeopleTab';
import { UsersRouter } from './users/UsersRouter';

export const PeopleRouter: FC = () => {
    return (
        <Switch>
            <Route path={`${AdminNavLink.People}/${PeopleTab.Users}`} render={() => <UsersRouter />} />
            <Route path={`${AdminNavLink.People}/${PeopleTab.Admins}`} render={() => <AdminsRouter />} />
            <Redirect to={`${AdminNavLink.People}/${PeopleTab.Users}`} />
        </Switch>
    );
};
