import { ColorDye, getEnumValues } from '@hofy/global';

export enum InvoicePaymentStatus {
    Processing = 'processing',
    Succeeded = 'succeeded',
    Failed = 'failed',
}

export const invoicePaymentStatusColors: Record<InvoicePaymentStatus, ColorDye> = {
    [InvoicePaymentStatus.Processing]: 'orange',
    [InvoicePaymentStatus.Succeeded]: 'green',
    [InvoicePaymentStatus.Failed]: 'red',
};

export const allInvoicePaymentStatuses = getEnumValues<InvoicePaymentStatus>(InvoicePaymentStatus);
