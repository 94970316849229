/*
 * This file was generated automatically by the script.
 * Do not edit it manually!
 */
import AddTeamMember from './assets/AddTeamMember.svg?external';
import AssetClearance from './assets/AssetClearance.svg?external';
import AssetRepair from './assets/AssetRepair.svg?external';
import AssetSecurity from './assets/AssetSecurity.svg?external';
import AssetsSearch from './assets/AssetsSearch.svg?external';
import AssetSync from './assets/AssetSync.svg?external';
import ContractSearch from './assets/ContractSearch.svg?external';
import DeviceConfiguration from './assets/DeviceConfiguration.svg?external';
import Email from './assets/Email.svg?external';
import Error from './assets/Error.svg?external';
import FinanceSearch from './assets/FinanceSearch.svg?external';
import List from './assets/List.svg?external';
import Location from './assets/Location.svg?external';
import NotFound from './assets/NotFound.svg?external';
import OrderSearch from './assets/OrderSearch.svg?external';
import PackageSearch from './assets/PackageSearch.svg?external';
import Parcel from './assets/Parcel.svg?external';
import ParcelOk from './assets/ParcelOk.svg?external';
import ParcelSignature from './assets/ParcelSignature.svg?external';
import Requests from './assets/Requests.svg?external';
import Schedule from './assets/Schedule.svg?external';
import SendEquipment from './assets/SendEquipment.svg?external';
import Shipment from './assets/Shipment.svg?external';
import SmallBasket from './assets/SmallBasket.svg?external';
import SmallNotification from './assets/SmallNotification.svg?external';
import Storage from './assets/Storage.svg?external';
import UserSearch from './assets/UserSearch.svg?external';

const SvgIllustrationUrls = {
    AddTeamMember,
    AssetClearance,
    AssetRepair,
    AssetSecurity,
    AssetsSearch,
    AssetSync,
    ContractSearch,
    DeviceConfiguration,
    Email,
    Error,
    FinanceSearch,
    List,
    Location,
    NotFound,
    OrderSearch,
    PackageSearch,
    Parcel,
    ParcelOk,
    ParcelSignature,
    Requests,
    Schedule,
    SendEquipment,
    Shipment,
    Storage,
    UserSearch,
    SmallBasket,
    SmallNotification,
} as const;

enum SvgIllustrationFileBrand {
    _ = '',
}
export type SvgIllustration = SvgIllustrationFileBrand & string;

// eslint-disable-next-line no-redeclare
export const SvgIllustration = SvgIllustrationUrls as Record<
    keyof typeof SvgIllustrationUrls,
    SvgIllustration
>;
