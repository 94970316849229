import { useMutation, useQueryClient } from '@tanstack/react-query';

import { itemCacheKey, itemsService, UpdateItemPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useItemMutation = (itemId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (v: UpdateItemPayload) => itemsService.updateItem(itemId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [itemCacheKey] });
            onSuccess?.();
        },
    });
};
