import React, { FC } from 'react';

import { FilterApiSingle } from '@hofy/ui';

import { ListMultiBlockFilter } from './ListMultiBlockFilter';

interface BooleanBlockFilterProps {
    filter: FilterApiSingle<boolean | null>;
    title: string;
    labelForTrue?: string;
    labelForFalse?: string;
}

export const BooleanBlockFilter: FC<BooleanBlockFilterProps> = ({
    title,
    filter,
    labelForTrue,
    labelForFalse,
}) => {
    const list = [
        {
            selected: filter.value === true,
            action: (v: boolean | null) => filter.set(v),
            label: labelForTrue ?? 'Active',
        },
        {
            selected: filter.value === false,
            action: (v: boolean | null) => filter.set(v === true ? !v : v),
            label: labelForFalse ?? 'Inactive',
        },
    ];

    return (
        <ListMultiBlockFilter
            title={title}
            items={list}
            renderItem={item => item.label}
            selected={list.filter(item => item.selected)}
            onChange={(_items, changedItem) => {
                changedItem.action(changedItem.selected ? null : true);
            }}
        />
    );
};
