import { useMutation, useQueryClient } from '@tanstack/react-query';

import { organizationService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useDeleteOrganizationDiscount = (orgId: UUID, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: organizationService.deleteOrganizationDiscount,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, orgId] });
            showToast({
                type: 'positive',
                message: `Organization discount deleted`,
            });
            onSuccess?.();
        },
    });

    return {
        deleteDiscount: mutation.mutate,
    };
};
