import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import {
    isShipmentFromSupplierToUser,
    isShipmentFromUserToUser,
    isShipmentFromWarehouseToUser,
    ShipmentStatus,
} from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, FormContainer, FormDateRangeInput, FormSection } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useBackorderShipment } from '../../../store/shipments/useBackorderShipment';
import { SendNotificationForm } from '../components/SendNotificationForm';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';

interface BackorderShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const BackorderShipmentSlideout: FC<BackorderShipmentSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading } = useBackorderShipment(shipment, onClose);

    const pickRangeLabel =
        isShipmentFromWarehouseToUser(shipment) || isShipmentFromSupplierToUser(shipment)
            ? 'Items estimated back order until'
            : 'Estimated back order until';

    const notificationNotesTitle = !isShipmentFromUserToUser(shipment)
        ? 'Backorder notification notes'
        : 'Backorder notification notes for collection user';

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Mark as back order' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.Backorder}
                />
                <FormContainer marginBottom={30}>
                    <FormSection label='Back order details' flex={1}>
                        <FormDateRangeInput
                            label={pickRangeLabel}
                            api={form.fields.estimatedAvailability}
                            nullable
                        />
                    </FormSection>
                    <SendNotificationForm
                        form={form}
                        switchLabel='Send backorder notification'
                        textareaLabel={notificationNotesTitle}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton isLoading={isLoading} onClick={form.submit} label='Mark as back order' />
            </SlideoutFooter>
        </Slideout>
    );
};
