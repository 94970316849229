import { ColorDye, getEnumValues } from '@hofy/global';

export enum RepaymentStatus {
    Pending = 'pending',
    Created = 'created',
    CreationError = 'creation_error',
    FundingApproved = 'funding_approved',
    FundingRejected = 'funding_rejected',
    FundingCheckError = 'funding_check_error',
    Fulfilled = 'fulfilled',
    FulfillError = 'fulfill_error',
    Approved = 'approved',
    ApprovalError = 'approval_error',
    InstalmentMismatch = 'instalment_mismatch',
    CancellationPending = 'cancellation_pending',
    Cancelled = 'cancelled',
    CancellationError = 'cancellation_error',
    CancellationMismatch = 'cancellation_mismatch',
    FullyInvoiced = 'fully_invoiced',
}

export const repaymentStatusColors: Record<RepaymentStatus, ColorDye> = {
    [RepaymentStatus.Pending]: 'orange',
    [RepaymentStatus.FullyInvoiced]: 'blue',
    [RepaymentStatus.Cancelled]: 'blue',
    [RepaymentStatus.CancellationError]: 'red',
    [RepaymentStatus.Created]: 'orange',
    [RepaymentStatus.CreationError]: 'red',
    [RepaymentStatus.FundingApproved]: 'orange',
    [RepaymentStatus.FundingRejected]: 'red',
    [RepaymentStatus.FundingCheckError]: 'red',
    [RepaymentStatus.Fulfilled]: 'orange',
    [RepaymentStatus.FulfillError]: 'red',
    [RepaymentStatus.Approved]: 'green',
    [RepaymentStatus.InstalmentMismatch]: 'red',
    [RepaymentStatus.ApprovalError]: 'red',
    [RepaymentStatus.CancellationMismatch]: 'red',
    [RepaymentStatus.CancellationPending]: 'red',
};

export const allRealRepaymentStatuses = getEnumValues<RepaymentStatus>(RepaymentStatus).filter(
    t => t !== RepaymentStatus.Pending,
);
