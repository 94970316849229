import { useQuery } from '@tanstack/react-query';

import { authService } from '@hofy/api-admin';
import { EmailType } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

export const useGenerateSignupLinkQuery = (emailType: EmailType, userId: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: ['admin/users/generate-signup-link', userId],
        queryFn: () => authService.generateSignupLink(emailType, userId),
    });
    return { data, isLoading };
};
