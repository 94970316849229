import { groupBy } from 'lodash';
import React, { FC } from 'react';

import { defaultInvoiceGroups, InvoiceGroupPayload } from '@hofy/api-admin';
import { allInvoiceEntryTypes, InvoiceEntryType, SplitterType } from '@hofy/api-shared';
import { Currency } from '@hofy/global';
import { NumberValues } from '@hofy/theme';
import { BaseTable, Box, Modal, ModalContent, Paragraph2 } from '@hofy/ui';

import { useTrInvoiceEntryType } from '../../../store/invoiceEntries/useTrInvoiceEntryType';

interface ExampleInvoiceEntry {
    currency: Currency;
    domesticVat: boolean;
    type: InvoiceEntryType;
    contract?: string;
    user?: string;
    team?: string;
}

const exampleInvoiceEntries: ExampleInvoiceEntry[] = [
    {
        currency: Currency.GBP,
        domesticVat: true,
        type: InvoiceEntryType.Rental,
        contract: 'CON-001',
        user: 'User A',
        team: 'Product',
    },
    {
        currency: Currency.GBP,
        domesticVat: true,
        type: InvoiceEntryType.Rental,
        contract: 'CON-002',
        user: 'User A',
        team: 'Product',
    },
    {
        currency: Currency.GBP,
        domesticVat: true,
        type: InvoiceEntryType.LaptopConfig,
        contract: 'CON-002',
        user: 'User A',
        team: 'Product',
    },
    {
        currency: Currency.EUR,
        domesticVat: false,
        type: InvoiceEntryType.Rental,
        contract: 'CON-003',
        user: 'User B',
        team: 'Engineering',
    },
    {
        currency: Currency.EUR,
        domesticVat: false,
        type: InvoiceEntryType.Rental,
        contract: 'CON-004',
        user: 'User B',
        team: 'Engineering',
    },
    {
        currency: Currency.USD,
        domesticVat: false,
        type: InvoiceEntryType.Rental,
        contract: 'CON-005',
        user: 'User C',
        team: 'Engineering',
    },
    {
        currency: Currency.USD,
        domesticVat: false,
        type: InvoiceEntryType.Rental,
        contract: 'CON-006',
        user: 'User D',
        team: 'Sales',
    },
    ...allInvoiceEntryTypes.flatMap(type => [
        { currency: Currency.GBP, domesticVat: true, type },
        { currency: Currency.EUR, domesticVat: false, type },
        { currency: Currency.USD, domesticVat: false, type },
    ]),
];

interface InvoiceGroupSampleModalProps {
    customGroups: InvoiceGroupPayload[];

    onClose(): void;
}

export const InvoiceGroupSampleModal: FC<InvoiceGroupSampleModalProps> = ({ customGroups, onClose }) => {
    const getGroupingKey = (entry: ExampleInvoiceEntry): string => {
        return invoiceGroups
            .map(group => {
                switch (group.config.type) {
                    case SplitterType.Currency:
                        return `[currency=${entry.currency}]`;
                    case SplitterType.DomesticVat:
                        return entry.domesticVat ? '[vat=domestic]' : '[vat=international]';
                    case SplitterType.ByInvoiceEntryType:
                        return group.config.byInvoiceEntryType.types.includes(entry.type)
                            ? `[entryType=${group.config.byInvoiceEntryType.types.join('|')}]`
                            : '';
                    case SplitterType.User:
                        return entry.user ? `[user=${entry.user}]` : '';
                    case SplitterType.Team:
                        return entry.team ? `[team=${entry.team}]` : '';
                    case SplitterType.Contract:
                        return entry.contract ? `[contract=${entry.contract}]` : '';
                }
            })
            .join('');
    };

    const trInvoiceEntryType = useTrInvoiceEntryType();
    const invoiceGroups = [...defaultInvoiceGroups, ...customGroups];
    const groupedInvoices = groupBy<ExampleInvoiceEntry>(exampleInvoiceEntries, getGroupingKey);

    return (
        <Modal width={800} onClose={onClose}>
            <ModalContent>
                {Object.values(groupedInvoices).map((entries, index) => (
                    <Box key={index} column gap={8} margin={8}>
                        <Paragraph2 bold>Invoice #{index + 1}</Paragraph2>
                        <BaseTable
                            data={entries}
                            toKey={(_, index) => index}
                            height='auto'
                            marginHorizontal={-40 as NumberValues}
                            columns={[
                                {
                                    id: 'currency',
                                    header: 'Currency',
                                    renderer: entry => entry.currency,
                                },
                                {
                                    id: 'vat',
                                    header: 'VAT',
                                    renderer: entry => (entry.domesticVat ? 'Domestic' : 'International'),
                                },
                                {
                                    id: 'type',
                                    header: 'Entry type',
                                    renderer: entry => trInvoiceEntryType(entry.type),
                                },
                                {
                                    id: 'contract',
                                    header: 'Contract',
                                    renderer: entry => entry.contract ?? '--',
                                },
                                {
                                    id: 'user',
                                    header: 'User',
                                    renderer: entry => entry.user ?? '--',
                                },
                                {
                                    id: 'team',
                                    header: 'Team',
                                    renderer: entry => entry.team ?? '--',
                                },
                            ]}
                        />
                    </Box>
                ))}
            </ModalContent>
        </Modal>
    );
};
