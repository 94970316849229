export const organizationFeaturesUpdateI18n = {
    'organization-page.features-update.title': 'Update features',
    'organization-page.features-update.subtitle': 'This operation will update the organization features.',
    'organization-page.features-update.modal.features-section': 'Features',
    'organization-page.features-update.modal.welcome-packs': 'Welcome packs',
    'organization-page.features-update.modal.service-desk': 'Service Desk',
    'organization-page.features-update.modal.device-wiping': 'Skip device wiping prompt in collection survey',
    'organization-page.features-update.modal.store-and-reuse': 'Store and reuse managed by Hofy',
    'organization-page.features-update.modal.dse': 'DSE',
    'organization-page.features-update.modal.host-agent': 'Host agent',
    'organization-page.features-update.modal.device-config': 'Device Configuration',
    'organization-page.features-update.modal.reset-link': 'Custom device reset instructions link',
    'organization-page.features-update.modal.reset-link.helper-text':
        'The link will be displayed as part of the collection request device reset instructions',
    'organization-page.features-update.modal.confirm': 'Save',
    'organization-page.features-update.modal.cancel': 'Cancel',
};
