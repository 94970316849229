import React, { ReactNode } from 'react';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface EnumBlockFilterProps<T> {
    title: string;
    selected: T | null;
    onChange(type: T | null): void;
    items: T[];
    renderItem(type: T): ReactNode;
    icon?: Svg;
}

export const EnumBlockFilter = <T,>({
    title,
    selected,
    onChange,
    items,
    renderItem,
    icon,
}: EnumBlockFilterProps<T>) => {
    const handleChange = (item: T) => {
        onChange(item === selected ? null : item);
    };

    return (
        <BlockFilter title={title} icon={icon}>
            {items.map((item, index) => (
                <BlockFilterItem
                    key={`${item}-${index}`}
                    name={renderItem(item)}
                    selected={item === selected}
                    onClick={() => handleChange(item)}
                />
            ))}
        </BlockFilter>
    );
};
