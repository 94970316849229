import { DateTimeString, UUID } from '@hofy/global';

import { ActorDto } from './ActorDto';

export interface NoteDto {
    id: UUID;
    text: string;
    isExternal: boolean;
    source: NoteSource;
    createdAt: DateTimeString;
    createdBy: ActorDto;
}

export enum NoteSource {
    Assignments = 'assignments',
    Shipments = 'shipments',
    Items = 'items',
    Repairs = 'repairs',
    Inspections = 'inspections',
}

export interface PublicNoteDto {
    text: string;
    source: NoteSource;
    createdAt: DateTimeString;
}
