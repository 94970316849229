import { ColorDye, getEnumValues } from '@hofy/global';

export enum AddonTarget {
    Asset = 'asset',
    Organization = 'organization',
}

export const addonTargetColors: Record<AddonTarget, ColorDye> = {
    [AddonTarget.Asset]: 'green',
    [AddonTarget.Organization]: 'blue',
};

export const allAddonTargets = getEnumValues<AddonTarget>(AddonTarget);
