import React, { FC, useState } from 'react';

import { allUpdateOtdReasons, UpdateOtdReason } from '@hofy/api-shared';
import { DateString, UUID } from '@hofy/global';
import { ConfirmModal, LabeledDateInput, LabeledSelect } from '@hofy/ui';

import { useTrUpdateOtdReason } from '../../../store/assignments/useTrUpdateOtdReason';
import { useAdminAssignmentOtd } from '../../../store/shipments/useAdminAssignmentOtd';

interface UpdateOtdModalProps {
    assignmentId: UUID;
    assignmentOtd: DateString | null;
    onSuccess(): void;
}

export const UpdateOtdModal: FC<UpdateOtdModalProps> = ({ assignmentId, assignmentOtd, onSuccess }) => {
    const [otd, setOtd] = useState(assignmentOtd);
    const [reason, setReason] = useState<UpdateOtdReason>(UpdateOtdReason.CustomerRequest);
    const trUpdateOtdReason = useTrUpdateOtdReason();
    const { updateAssignmentOTD } = useAdminAssignmentOtd(assignmentId);

    return (
        <ConfirmModal
            keyPrefix='update-otd-modal'
            onClose={onSuccess}
            onConfirm={() => updateAssignmentOTD(otd, reason)}
            disabled={!otd}
        >
            <LabeledSelect
                label='Please provide a reason for the update'
                options={allUpdateOtdReasons}
                onChange={setReason}
                value={reason}
                toText={trUpdateOtdReason}
                marginBottom={20}
            />
            <LabeledDateInput
                label='Please select a new OTD date'
                value={otd}
                onChange={setOtd}
                nullable
                marginTop={5}
            />
        </ConfirmModal>
    );
};
