import React, { ElementRef, forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { TestKeyAware } from '../../../types';
import { OuterBoxProps, Pressable } from '../../base';
import { Icon, SvgIcon } from '../../icon';

export interface CheckboxProps extends OuterBoxProps, TestKeyAware {
    checked: CheckboxChecked;
    onChange?(v: boolean): void;
    onBlur?(): void;
    onFocus?(): void;
    disabled?: boolean;
    isError?: boolean;
    id?: string;
}

export type CheckboxChecked = boolean | 'mixed';
export const Checkbox = forwardRef<ElementRef<'div'>, CheckboxProps>(
    (
        { checked, onChange, onFocus, onBlur, disabled: switchDisabled, isError, id, testKey, ...rest },
        ref,
    ) => {
        const disabled = useIsDisabled(switchDisabled);

        const icon = checked === 'mixed' ? SvgIcon.Minus : SvgIcon.Check;

        return (
            <CheckboxBox
                ref={ref}
                onClick={() => {
                    if (!disabled) {
                        onChange?.(!checked);
                    }
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                checked={!!checked}
                disabled={disabled}
                inactive={disabled}
                isError={isError}
                rounded={4}
                rect={16}
                centered
                role='checkbox'
                aria-checked={checked}
                aria-labelledby={id}
                {...rest}
            >
                {checked && <Icon svg={icon} color={Color.InteractionInvertedNormal} size={12} />}
            </CheckboxBox>
        );
    },
);

const hover = css`
    &:hover {
        border-color: ${Color.InteractionDefaultHover};
    }
`;

const hoverChecked = css`
    &:hover {
        border-color: ${Color.InteractionDefaultHover};
        background-color: ${Color.InteractionDefaultHover};
    }
`;

const active = css`
    &:active {
        border-color: ${Color.InteractionDefaultActive};
    }
`;

const activeChecked = css`
    &:active {
        border-color: ${Color.InteractionDefaultActive};
        background-color: ${Color.InteractionDefaultActive};
    }
`;

const CheckboxBox = styled(Pressable)<{ checked: boolean; disabled?: boolean; isError?: boolean }>`
    border: 1px solid
        ${p =>
            p.isError
                ? Color.InteractionBorderAlert
                : p.checked
                ? Color.InteractionDefaultNormal
                : Color.InteractionNeutralNormal};
    background-color: ${p =>
        p.checked ? Color.InteractionDefaultNormal : Color.InteractionBackgroundFormField};
    ${p => (p.disabled ? undefined : p.checked ? hoverChecked : hover)}
    ${p => (p.disabled ? undefined : p.checked ? activeChecked : active)}
    transition: all 150ms ease-in-out;
`;
