import { isNil } from 'lodash';
import React, { FC } from 'react';

import { Color } from '@hofy/theme';

import { Paragraph3 } from '../base';
import { Labeled } from './Labeled';
import { LabeledTextProps } from './LabeledText';

export const LabeledLink: FC<LabeledTextProps> = ({ content, invertedColor, ...rest }) => {
    return (
        <Labeled
            content={
                <Paragraph3 color={Color.ContentPrimary} data-test-key='content'>
                    {isNil(content) || content === '' ? '--' : content}
                </Paragraph3>
            }
            {...rest}
        />
    );
};
