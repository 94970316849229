import { useRef } from 'react';
import { useBoolean, useClickAway } from 'react-use';

export const useBlockFilters = () => {
    const [showFilters, toggleShowFilters] = useBoolean(false);
    const filterElRef = useRef<HTMLDivElement>(null);
    useClickAway(filterElRef, e => {
        // don't close if clicked on dropdown content
        if ((e.target as HTMLElement).closest('[data-dropdown-content]')) {
            return;
        }
        // don't close if clicked filter button
        if ((e.target as HTMLElement).closest('#filter-button')) {
            return;
        }
        toggleShowFilters(false);
    });

    return {
        showFilters,
        toggleShowFilters,
        filterElRef,
    };
};
