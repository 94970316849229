import { Color, FontSize, NumberValues } from '@hofy/theme';

export type ButtonSize = 'small' | 'medium';
export type ButtonType = 'primary' | 'secondary' | 'ghost' | 'plain';
export type ButtonAction = 'default' | 'destructive';

interface ButtonSizeValues {
    px: NumberValues;
    py: NumberValues;
    height?: number;
    width?: number;
    fontSize: FontSize;
}

export const buttonSizes: Record<ButtonSize, ButtonSizeValues> = {
    small: { px: 12, py: 4, height: 32, fontSize: 14 },
    medium: { px: 16, py: 8, height: 40, fontSize: 14 },
};

export interface ButtonStateColors {
    normal: Color;
    hover: Color;
}

interface ActionColors {
    bg?: ButtonStateColors;
    text: ButtonStateColors;
    border?: ButtonStateColors;
}

const textBg: ButtonStateColors = {
    normal: Color.InteractionGhostNormal,
    hover: Color.InteractionGhostHover,
};

const interactionDefault: ButtonStateColors = {
    normal: Color.InteractionDefaultNormal,
    hover: Color.InteractionDefaultHover,
};

const interactionSubtleBorder: ButtonStateColors = {
    normal: Color.InteractionDefaultSubtleBorder,
    hover: Color.InteractionDefaultSubtleBorderHover,
};

const interactionInverted: ButtonStateColors = {
    normal: Color.InteractionInvertedNormal,
    hover: Color.InteractionInvertedHover,
};

const interactionDestructive: ButtonStateColors = {
    normal: Color.InteractionDestructiveNormal,
    hover: Color.InteractionDestructiveHover,
};

export const buttonColors: Record<ButtonType, Record<ButtonAction, ActionColors>> = {
    primary: {
        default: { text: interactionInverted, bg: interactionDefault },
        destructive: { text: interactionInverted, bg: interactionDestructive },
    },
    secondary: {
        default: { text: interactionDefault, border: interactionSubtleBorder },
        destructive: { text: interactionDestructive, border: interactionDestructive },
    },
    ghost: {
        default: { text: interactionDefault, bg: textBg },
        destructive: { text: interactionDestructive, bg: textBg },
    },
    plain: {
        default: { text: interactionDefault },
        destructive: { text: interactionDestructive },
    },
};
