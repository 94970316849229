import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { AsyncButton, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useDeletePurchaseOrder } from '../../../../../store/purchaseOrders/useDeletePurchaseOrder';

interface DiscardPurchaseOrderModalProps {
    purchaseOrderId: UUID;
    purchaseOrderReference: string;

    onClose(): void;
    onDraftDiscarded(): void;
}

export const DiscardPurchaseOrderModal: FC<DiscardPurchaseOrderModalProps> = ({
    purchaseOrderId,
    purchaseOrderReference,
    onClose,
    onDraftDiscarded,
}) => {
    const { deletePurchaseOrder, isLoading, isError } = useDeletePurchaseOrder(
        purchaseOrderId,
        onDraftDiscarded,
    );
    const onConfirm = () => {
        deletePurchaseOrder();
        onClose();
    };

    return (
        <Modal width={600} onClose={onClose}>
            <ModalHeader title='Discard draft purchase order' />
            <ModalContent>
                <Paragraph3>{`Are you sure you want to delete purchase order #${purchaseOrderReference}? This action cannot be undone.`}</Paragraph3>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <AsyncButton label='Confirm' isLoading={isLoading} isError={isError} onClick={onConfirm} />
            </ModalFooter>
        </Modal>
    );
};
