import { xor } from 'lodash';
import React, { FC } from 'react';

import { useAdminsRefsQuery, useHofyWarehousesQuery, useShipmentsQuery } from '@hofy/api-admin';
import {
    allShipmentStatuses,
    allShipmentTypes,
    ShipmentStatus,
    shipmentStatusColors,
    ShipmentType,
    unassignedUser,
    useTrShipmentStatus,
    useTrShipmentType,
} from '@hofy/api-shared';
import { ExportButton, Page } from '@hofy/common';
import { AnyRegion, Country, UUID } from '@hofy/global';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { FilterChip, FilterChipList, PageHeader, SearchInput, SvgIcon, useToast } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { ListMultiBlockFilter } from '../../components/design/blockFilters/ListMultiBlockFilter';
import { AdminsBlockFilter } from '../../components/domain/admin/AdminsBlockFilter';
import { WarehouseFilterRenderer } from '../../components/domain/filters/WarehouseFilterRenderer';
import { WarehouseBlockFilter } from '../../components/domain/warehouses/WarehouseBlockFilter';
import { ShipmentTab } from '../../store/shipments/types/ShipmentTab';
import { useAdminShipmentFilters } from '../../store/shipments/useAdminShipmentFilters';
import { useExportShipmentLabels } from '../../store/shipments/useExportShipmentLabels';
import { useShipmentsSelected } from '../../store/shipments/useShipmentsSelected';
import { AssignedUsersFilterChips } from '../assignmentsPage/components/filters/AssignedUsersFilterChips';
import { CountryBlockFilter } from '../assignmentsPage/components/filters/blocks/CountryBlockFilter';
import { RegionBlockFilter } from '../assignmentsPage/components/filters/blocks/RegionBlockFilter';
import { ShipmentTable } from './components/table/ShipmentTable';
import { ShipmentsPageTabs } from './ShipmentsPageTabs';

interface ShipmentsPageProps {
    tab: ShipmentTab;
    onChangeTab(tab: ShipmentTab): void;
    onOpenShipment(id: UUID): void;
    onOpenShipmentTrackingHistory(id: UUID): void;
    defaultShipmentStatus: ShipmentStatus[];
}

export const ShipmentsPage: FC<ShipmentsPageProps> = ({
    tab,
    onChangeTab,
    onOpenShipment,
    onOpenShipmentTrackingHistory,
    defaultShipmentStatus,
}) => {
    const {
        setShipmentStatus,
        shipmentStatus,
        setShipmentTypes,
        setRegion,
        setCountry,
        setWarehouse,
        filters,
        setAssignedUser,
        toggleUnassigned,
        toggleShouldUpdate,
        toggleShipmentTrackingProviders,
        toggleWithTrackingException,
        toggleWithoutTracking,
        setSearch,
        filterCount,
    } = useAdminShipmentFilters();
    const { shipments, shipmentsIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useShipmentsQuery({
            ...filters,
            shipToday: tab === ShipmentTab.ShipToday,
            shipmentTrackingProviders: filters.shipmentTrackingProviders,
            shipmentStatus: shipmentStatus.length > 0 ? shipmentStatus : defaultShipmentStatus,
        });
    const exportLabels = useExportShipmentLabels();
    const { data: allWarehouses } = useHofyWarehousesQuery();
    const { data: admins } = useAdminsRefsQuery();
    const { handlerAddShipmentId, shipmentSelectedIds, setShipmentSelectedIds } = useShipmentsSelected();
    const trType = useTrShipmentType();
    const trStatus = useTrShipmentStatus();
    const trRegion = useTrRegion();
    const trCountry = useTrCountry();

    const { showToast } = useToast();
    const exportShipments = () => {
        if (!shipmentSelectedIds || shipmentSelectedIds.length === 0) {
            showToast({
                type: 'warning',
                message: 'Select one or more shipments to export',
            });
        } else {
            exportLabels(shipmentSelectedIds);
        }
    };
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Page pageId='shipments'>
            <PageHeader
                title='Shipments'
                rightSlot={
                    <>
                        <SearchInput
                            value={filters.search}
                            onChange={setSearch}
                            placeholder='Search shipments'
                            autoFocus
                        />
                        <ExportButton onClick={exportShipments} />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                    </>
                }
                tabsSlot={<ShipmentsPageTabs tab={tab} onChange={onChangeTab} />}
            />

            <BlockFilterContainer show={showFilters} ref={filterElRef}>
                <AdminsBlockFilter
                    selected={filters.assignedUser}
                    onChange={setAssignedUser}
                    users={admins}
                />
                <EnumMultiBlockFilter<ShipmentType>
                    title='Shipment type'
                    selected={filters.shipmentTypes || []}
                    onChange={setShipmentTypes}
                    items={allShipmentTypes}
                    renderItem={trType}
                />
                <EnumMultiBlockFilter<ShipmentStatus>
                    title='Shipment status'
                    selected={filters.shipmentStatus || []}
                    onChange={setShipmentStatus}
                    items={allShipmentStatuses}
                    renderItem={trStatus}
                />
                <RegionBlockFilter selected={filters.region} onChange={setRegion} title='Delivery region' />
                <CountryBlockFilter
                    selected={filters.country}
                    onChange={setCountry}
                    title='Delivery country'
                />
                <WarehouseBlockFilter
                    warehouses={allWarehouses}
                    selected={filters.warehouse}
                    onChange={setWarehouse}
                />
                <Filters
                    isUnassigned={filters.assignedUser.includes(unassignedUser)}
                    toggleUnassigned={toggleUnassigned}
                    shouldUpdate={filters.shouldUpdate}
                    toggleShouldUpdate={toggleShouldUpdate}
                    shipmentTrackingProviders={filters.shipmentTrackingProviders.length > 0}
                    toggleShipmentTrackingProviders={toggleShipmentTrackingProviders}
                    withTrackingException={filters.withTrackingException}
                    toggleWithTrackingException={toggleWithTrackingException}
                    withoutTracking={filters.withoutTracking}
                    toggleWithoutTracking={toggleWithoutTracking}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <AssignedUsersFilterChips
                    allUsers={admins}
                    selectedUserIds={filters.assignedUser}
                    onChange={setAssignedUser}
                />
                {filters.shouldUpdate && <FilterChip label='Should update' onClear={toggleShouldUpdate} />}
                {filters.withTrackingException && (
                    <FilterChip label='With tracking exception' onClear={toggleWithTrackingException} />
                )}
                {filters.withoutTracking && (
                    <FilterChip label='Without tracking' onClear={toggleWithoutTracking} />
                )}
                {filters.shipmentTrackingProviders.length > 0 && (
                    <FilterChip label='With tracking' onClear={toggleShipmentTrackingProviders} />
                )}
                <FilterChipList<ShipmentType>
                    color='blue'
                    toKey={id => id}
                    selected={filters.shipmentTypes || []}
                    toLabel={trType}
                    onClear={cleared => setShipmentTypes(xor(filters.shipmentTypes, [cleared]))}
                />
                <FilterChipList<ShipmentStatus>
                    color={c => shipmentStatusColors[c]}
                    toKey={id => id}
                    selected={filters.shipmentStatus || []}
                    toLabel={trStatus}
                    onClear={cleared => setShipmentStatus(xor(filters.shipmentStatus, [cleared]))}
                />
                <FilterChipList<AnyRegion>
                    color='grape'
                    toKey={id => id}
                    selected={filters.region || []}
                    toLabel={trRegion}
                    onClear={cleared => setRegion(xor(filters.region, [cleared]))}
                />
                <FilterChipList<Country>
                    color='magenta'
                    toKey={id => id}
                    selected={filters.country || []}
                    toLabel={trCountry}
                    onClear={cleared => setCountry(xor(filters.country, [cleared]))}
                />
                <WarehouseFilterRenderer
                    warehouses={allWarehouses}
                    warehouseIds={filters.warehouse}
                    onChange={setWarehouse}
                />
            </BlockFilterChipContainer>
            <ShipmentTable
                tab={tab}
                shipmentSelectedIds={shipmentSelectedIds}
                setShipmentSelectedIds={setShipmentSelectedIds}
                shipments={shipments}
                onSelect={handlerAddShipmentId}
                onOpenShipment={onOpenShipment}
                onOpenShipmentTrackingHistory={onOpenShipmentTrackingHistory}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: shipmentsIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Page>
    );
};

interface FiltersProps {
    isUnassigned: boolean;
    toggleUnassigned(): void;
    shouldUpdate: boolean | null;
    toggleShouldUpdate(): void;
    shipmentTrackingProviders: boolean | null;
    toggleShipmentTrackingProviders(): void;
    withTrackingException: boolean | null;
    toggleWithTrackingException(): void;
    withoutTracking: boolean | null;
    toggleWithoutTracking(): void;
}

const Filters: FC<FiltersProps> = ({
    isUnassigned,
    toggleUnassigned,
    shouldUpdate,
    toggleShouldUpdate,
    shipmentTrackingProviders,
    toggleShipmentTrackingProviders,
    withTrackingException,
    toggleWithTrackingException,
    withoutTracking,
    toggleWithoutTracking,
}) => {
    const items = [
        {
            selected: isUnassigned,
            action: toggleUnassigned,
            label: 'Unassigned',
        },
        {
            selected: shouldUpdate,
            action: toggleShouldUpdate,
            label: 'Should update',
        },
        {
            selected: withoutTracking,
            action: toggleWithoutTracking,
            label: 'Without tracking',
        },
        {
            selected: shipmentTrackingProviders,
            action: toggleShipmentTrackingProviders,
            label: 'With tracking',
        },
        {
            selected: withTrackingException,
            action: toggleWithTrackingException,
            label: 'With tracking exception',
        },
    ];
    return (
        <ListMultiBlockFilter
            title='Filter'
            icon={SvgIcon.Filter}
            items={items}
            renderItem={item => item.label}
            selected={items.filter(v => v.selected)}
            onChange={(_items, changedItem) => {
                changedItem.action();
            }}
        />
    );
};
