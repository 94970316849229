import React, { createRef, FC, useEffect } from 'react';
import styled from 'styled-components';

import { allItemGrades } from '@hofy/api-shared';
import { FormDropdown, FormInput } from '@hofy/common';
import { nowDate, toISODate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, IconButton, LabeledDateInput, SvgIcon } from '@hofy/ui';

import { useTrItemGrade } from '../../../../../store/items/useTrItemGrade';
import { ReceivePurchaseOrderItemDetailFormData } from '../../../../../store/purchaseOrders/types/ReceivePurchaseOrderItemFormData';

interface ItemUnitDetailsProps {
    unit: ReceivePurchaseOrderItemDetailFormData;
    index: number;
    onUnitChange(index: number, data: Partial<ReceivePurchaseOrderItemDetailFormData>): void;
    deleteUnit(index: number): void;
}

export const ItemUnitDetails: FC<ItemUnitDetailsProps> = ({ unit, index, onUnitChange, deleteUnit }) => {
    const itemCodeRef = createRef<HTMLInputElement>();
    const trItemGrade = useTrItemGrade();

    useEffect(() => {
        if (unit.focused && itemCodeRef.current) {
            itemCodeRef.current.focus();
        }
    }, [unit.focused]);

    return (
        <Wrapper padding={20} row fullWidth justify='space-between'>
            <p>{index + 1}.</p>
            <FormInput
                label='Item code'
                onChangeText={itemCode =>
                    onUnitChange(index, {
                        itemCode,
                    })
                }
                value={unit.itemCode}
                autoFocus={index === 0}
                inputRef={itemCodeRef}
                isError={!unit.itemCode}
                isRequired
            />
            <FormInput
                label='Serial number'
                onChangeText={serialNumber =>
                    onUnitChange(index, {
                        serialNumber,
                    })
                }
                value={unit.serialNumber}
                nullable
            />
            <Box width={150}>
                <LabeledDateInput
                    label='Date received'
                    value={unit.receivedOn}
                    onChange={receivedOn =>
                        onUnitChange(index, {
                            receivedOn,
                        })
                    }
                    isError={!unit.receivedOn}
                    maxDate={toISODate(nowDate())}
                    isRequired
                />
            </Box>
            <FormDropdown
                items={allItemGrades}
                labelFormatter={trItemGrade}
                onChange={itemGrade =>
                    onUnitChange(index, {
                        itemGrade,
                    })
                }
                label='Condition'
                value={unit.itemGrade}
                width={150}
                isRequired
            />
            <IconButton icon={SvgIcon.Cross} onClick={() => deleteUnit(index)} />
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    background-color: ${Color.BackgroundSubtleNeutral};
`;
