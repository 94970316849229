import React, { FC } from 'react';

import { Country, isUSA, USState } from '@hofy/global';
import { FormFieldApi, FormInput, TestKeyAware } from '@hofy/ui';

import { useAddressI18n } from '../store/useAddressI18n';
import { USStateFormSelect } from './USStateFormSelect';

interface StateInputProps extends TestKeyAware {
    country: Country | null;
    api: FormFieldApi<USState | string | null>;
    isRequired?: boolean;
    disabled?: boolean;
}

export const StateInput: FC<StateInputProps> = ({ country, isRequired, disabled, api }) => {
    const { tr } = useAddressI18n();
    return country && isUSA(country) ? (
        <USStateFormSelect
            api={api as FormFieldApi<USState | null>}
            isRequired={isRequired}
            disabled={disabled}
        />
    ) : (
        <FormInput
            label={tr('address.state-province-region.label')}
            isRequired={isRequired}
            api={api}
            disabled={disabled}
            testKey='state-input'
            nullable
        />
    );
};
