import React, { forwardRef, ReactElement, Ref } from 'react';

import { Labeled } from '../../../labeled';
import { DateRangeInput, DateRangeInputProps } from './DateRangeInput';

interface LabeledDateRangeInputBaseProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledDateRangeInputProps = DateRangeInputProps & LabeledDateRangeInputBaseProps;

const LabeledDateRangeInputComponent = (
    {
        label,
        isRequired,
        errorMessage,
        helperText,

        value,
        onChange,
        onBlur,
        allowedRangeTypes,
        clearable,
        disabled,
        nullable,
        placeholder,
        yearMonthPicker,
        filterDate,
        minDate,
        maxDate,
        testKey,
        ...restLabeledProps
    }: LabeledDateRangeInputProps,
    ref: Ref<HTMLInputElement>,
) => {
    return (
        <Labeled
            as='label'
            label={label}
            isRequired={isRequired}
            content={
                <DateRangeInput
                    testKey={testKey}
                    ref={ref}
                    value={value!}
                    onChange={onChange}
                    onBlur={onBlur}
                    allowedRangeTypes={allowedRangeTypes}
                    disabled={disabled}
                    nullable={nullable}
                    placeholder={placeholder}
                    isError={!!errorMessage}
                    clearable={clearable}
                    yearMonthPicker={yearMonthPicker}
                    filterDate={filterDate}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            }
            errorMessage={errorMessage}
            helperText={helperText}
            {...restLabeledProps}
        />
    );
};

export const LabeledDateRangeInput = forwardRef(LabeledDateRangeInputComponent) as <HTMLInputElement>(
    props: LabeledDateRangeInputProps & { ref?: Ref<HTMLInputElement> },
) => ReactElement;
