import { compact } from 'lodash';
import React, { FC } from 'react';

import { PostAddress } from '@hofy/api-shared';
import { useStateLabel } from '@hofy/i18n';
import { Box } from '@hofy/ui';

interface AddressViewProps {
    address: PostAddress | null;
}

export const PostAddressView: FC<AddressViewProps> = ({ address }) => {
    const trState = useStateLabel();
    if (!address || isEmptyPostAddress(address)) {
        return <>--</>;
    }
    const { line1, line2, city, postCode } = address;
    const line3 = compact([city, trState(address)]).join(', ');
    return (
        <>
            {line1 && <Box>{line1}</Box>}
            {line2 && <Box>{line2}</Box>}
            {!!line3 && <Box>{line3}</Box>}
            {postCode && <Box>{postCode}</Box>}
        </>
    );
};

const isEmptyPostAddress = (profile: PostAddress) => {
    return !profile.line1 && !profile.line2 && !profile.city && !profile.state && !profile.postCode;
};
