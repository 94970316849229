import { Sort, SortDirectionType } from '@hofy/global';
import { useStringQueryParam } from '@hofy/router';

export enum SortOptions {
    ByStartDate = 'start_date',
    ByUpdated = 'updated',
    ByOTD = 'otd',
}

const sortingOptions: Sort<SortOptions>[] = [
    {
        sortBy: SortOptions.ByStartDate,
        sortDirection: 'ASC',
    },
    {
        sortBy: SortOptions.ByUpdated,
        sortDirection: 'DESC',
    },
    {
        sortBy: SortOptions.ByOTD,
        sortDirection: 'ASC',
    },
];

export const useAdminAssignmentsSorting = () => {
    const [sortBy, setSortBy] = useStringQueryParam<SortOptions>('sortBy', SortOptions.ByUpdated);
    const [sortDirection, setSortDirection] = useStringQueryParam<SortDirectionType>('sortDirection', 'DESC');

    const sort: Sort<SortOptions> =
        sortingOptions.find(s => s.sortBy === sortBy && s.sortDirection === sortDirection) ||
        sortingOptions[0];

    const handleSort = (sort: Sort<SortOptions>) => {
        setSortBy(sort.sortBy);
        setSortDirection(sort.sortDirection);
    };

    return {
        sort,
        handleSort,
        sortingOptions,
    };
};
