import React, { FC } from 'react';

import { OrganizationDetailsDto } from '@hofy/api-admin';
import {
    AsyncButton,
    FormCheckbox,
    FormContainer,
    FormPriceInput,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { useUpdateDataErasureSettings } from '../../../store/organizations/useUpdateDataErasureSettings';

interface DataErasureUpdateModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const DataErasureUpdateModal: FC<DataErasureUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateDataErasureSettings(organization, onClose);
    const { tr } = useAdminI18n();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.data-erasure-settings-update.allowed-modal.title')}
                subtitle={tr('organization-page.data-erasure-settings-update.allowed-modal.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormCheckbox
                        api={form.fields.enabled}
                        label={tr('organization-page.data-erasure-settings-update.allowed-modal.enabled')}
                    />
                    <FormPriceInput
                        label='Data erasure fee'
                        clearable
                        defaultCurrency={organization.currency}
                        api={form.fields.fee}
                        nullable
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton
                    label={tr('organization-page.data-erasure-settings-update.allowed-modal.cancel')}
                    onClick={onClose}
                />
                <AsyncButton
                    label={tr('organization-page.data-erasure-settings-update.allowed-modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
