import React, { createContext, FC, useContext, useMemo } from 'react';

import { AssignmentsPageTab } from '@hofy/api-admin';

interface AssignmentsTabContext {
    tab: AssignmentsPageTab;
}

const AssignmentsTabCtx = createContext<AssignmentsTabContext | null>(null);

export const AssignmentsPageTabContextProvider: FC<AssignmentsTabContext & { children: React.ReactNode }> = ({
    tab,
    children,
}) => {
    const value = useMemo(() => ({ tab }), [tab]);

    return <AssignmentsTabCtx.Provider value={value}>{children}</AssignmentsTabCtx.Provider>;
};

export const useAssignmentsPageTab = (): AssignmentsPageTab => {
    const ctx = useContext(AssignmentsTabCtx);

    if (ctx === null) {
        throw new Error('useAssignmentsPageTab should be called within AssignmentsPageTabContextProvider');
    }

    return ctx.tab;
};
