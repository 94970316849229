export const collectionRequestDisplayStatusI18n = {
    'collection-request-display-status.active': 'Active',
    'collection-request-display-status.escalated': 'Escalated',
    'collection-request-display-status.deferred': 'Deferred',
    'collection-request-display-status.completed': 'Completed',
    'collection-request-display-status.cancelled': 'Cancelled',
    'collection-request-display-status.de-escalated': 'De-escalated',
    'collection-request-display-status.de-deferred': 'De-deferred',
    'collection-request-display-status.viewed': 'Survey viewed',
    'collection-request-display-status.email-sent': 'Email sent',
    'collection-request-display-status.text-message-sent': 'Text message sent',
    'collection-request-display-status.text-message-delivered': 'Text message delivered',
};
