import { useMutation, useQueryClient } from '@tanstack/react-query';

import { exchangeRatesCacheKey, exchangeRatesService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useDeleteExchangeRate = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const deleteMutation = useMutation({
        mutationFn: exchangeRatesService.deleteExchangeRate,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [exchangeRatesCacheKey] });
            showToast({
                type: 'positive',
                message: 'Exchange rate deleted',
            });
            onSuccess?.();
        },
    });

    return {
        deleteExchangeRate: deleteMutation.mutate,
    };
};
