import { xor } from 'lodash';
import React, { FC } from 'react';

import { UpdateOrganizationFinancialSettingsPayload } from '@hofy/api-admin';
import {
    allowedPaymentSchemasForPaymentType,
    allPaymentSchemasWithoutDeposit,
    allPaymentTypes,
    PaymentSchema,
    PaymentType,
    RentalTerm,
    useTrPaymentType,
    useTrRentalTermFull,
} from '@hofy/api-shared';
import { FormRow, RadioGroup } from '@hofy/common';
import { Currency } from '@hofy/global';
import {
    Box,
    FormPriceInput,
    FormSection,
    LabeledCheckbox,
    Paragraph3,
    UseForm,
    WarnTooltipIcon,
} from '@hofy/ui';

import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { PaymentSchemaSection } from './PaymentSchemaSection';

interface PricingFormSectionProps {
    form: UseForm<UpdateOrganizationFinancialSettingsPayload>;
    isPaymentSchemaUpfront(schema: PaymentSchema): boolean;
    showSchemaWarning(schema: PaymentSchema): boolean;
    currency: Currency;
    availableRentalTerms: RentalTerm[];
}

export const PricingFormSection: FC<PricingFormSectionProps> = ({
    form,
    isPaymentSchemaUpfront,
    showSchemaWarning,
    currency,
    availableRentalTerms,
}) => {
    const { tr } = useAdminI18n();
    const trRentalTerm = useTrRentalTermFull();
    const isOnlyUpfrontSelected =
        form.values.allowedBillingFrequencies.length === 1 &&
        isPaymentSchemaUpfront(form.values.allowedBillingFrequencies[0]);

    const trPaymentType = useTrPaymentType();
    const isPaymentTypePrepaid = form.values.paymentType === PaymentType.Prepaid;

    const handlePaymentTypeChange = (paymentType: PaymentType) => {
        const allowedBillingFrequencies = form.values.allowedBillingFrequencies.filter(b => {
            const allowedBillingFrequenciesForPaymentType =
                allowedPaymentSchemasForPaymentType.get(paymentType);
            return allowedBillingFrequenciesForPaymentType?.includes(b);
        });
        form.setValues({
            paymentType,
            allowedBillingFrequencies,
        });
    };

    return (
        <>
            <FormSection label={tr('organization-page.financial-settings-update.modal.pricing-section')}>
                <FormRow>
                    <FormPriceInput
                        label={tr('organization-page.financial-settings-update.modal.config-fee')}
                        clearable
                        api={form.fields.laptopConfigFee}
                        defaultCurrency={currency}
                        nullable
                    />
                    <FormPriceInput
                        label={tr('organization-page.financial-settings-update.modal.mdm-fee')}
                        clearable
                        defaultCurrency={currency}
                        api={form.fields.mdmFee}
                        nullable
                    />
                    <FormPriceInput
                        label={
                            <TooltipLabel
                                title={tr('organization-page.financial-settings-update.modal.credit-limit')}
                                tooltip={tr(
                                    'organization-page.financial-settings-update.modal.credit-limit.tooltip',
                                )}
                            />
                        }
                        clearable
                        nullable
                        api={form.fields.creditLimit}
                        defaultCurrency={currency}
                    />
                </FormRow>
            </FormSection>
            <FormRow>
                <FormSection
                    label={
                        <TooltipLabel
                            title={tr(
                                'organization-page.financial-settings-update.modal.payment-type.tooltip.title',
                            )}
                            tooltip={tr(
                                'organization-page.financial-settings-update.modal.payment-type.tooltip',
                            )}
                        />
                    }
                >
                    <RadioGroup
                        spacing={20}
                        items={allPaymentTypes}
                        value={form.values.paymentType}
                        onChange={handlePaymentTypeChange}
                        labelFormatter={paymentType => <Paragraph3>{trPaymentType(paymentType)}</Paragraph3>}
                    />
                </FormSection>
                <FormSection
                    label={
                        <TooltipLabel
                            title={tr(
                                'organization-page.financial-settings-update.modal.billing-frequency-section',
                            )}
                            tooltip={tr(
                                'organization-page.financial-settings-update.modal.billing-frequency-section.tooltip',
                            )}
                        />
                    }
                >
                    {allPaymentSchemasWithoutDeposit.map(schema => (
                        <PaymentSchemaSection
                            key={schema}
                            schema={schema}
                            form={form}
                            isPaymentSchemaUpfront={isPaymentSchemaUpfront}
                            showSchemaWarning={showSchemaWarning}
                        />
                    ))}
                </FormSection>
                <FormSection
                    label={
                        <TooltipLabel
                            title={tr(
                                'organization-page.financial-settings-update.modal.allowed-rentals-section',
                            )}
                            tooltip={tr(
                                'organization-page.financial-settings-update.modal.allowed-rentals-section.tooltip',
                            )}
                        />
                    }
                >
                    {availableRentalTerms.map(rentalTerm => (
                        <Box key={rentalTerm} marginVertical={0}>
                            <Box>
                                <LabeledCheckbox
                                    checked={
                                        isOnlyUpfrontSelected ||
                                        form.values.allowedRentalTerms.includes(rentalTerm)
                                    }
                                    onChange={() =>
                                        form.setValues({
                                            allowedRentalTerms: xor(form.values.allowedRentalTerms, [
                                                rentalTerm,
                                            ]),
                                        })
                                    }
                                    disabled={
                                        isOnlyUpfrontSelected ||
                                        !availableRentalTerms.includes(rentalTerm) ||
                                        isPaymentTypePrepaid
                                    }
                                    label={trRentalTerm(rentalTerm)}
                                />
                            </Box>
                        </Box>
                    ))}
                </FormSection>
            </FormRow>
        </>
    );
};

interface TooltipLabelProps {
    title: string;
    tooltip: string;
}

const TooltipLabel: FC<TooltipLabelProps> = ({ title, tooltip }) => {
    return (
        <Box row gap={4}>
            {title}
            <WarnTooltipIcon body={tooltip} />
        </Box>
    );
};
