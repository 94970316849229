import { isObject, isString } from 'lodash';

import { FormFieldApi, FormFieldRecord } from './formTypes';

/**
 * Extend single form field api to handle array fields
 *
 * @example ```tsx
 *    const addressApi = useFormObjectField(form.fields.address);
 *
 *    <FormInput api={addressApi.street} />
 *    <FormInput api={addressApi.city} />
 * ```
 */
export const useFormObjectField = <T extends object>(fieldApi: FormFieldApi<T>) => {
    type FieldName = keyof T & string;
    const fieldNames = Object.keys(fieldApi.value) as FieldName[];

    return Object.fromEntries(
        fieldNames.map(fieldName => {
            const fieldValue = fieldApi.value[fieldName as FieldName];
            const fieldError = isObject(fieldApi.error) ? fieldApi.error[fieldName] : undefined;
            const fieldTouched = Array.isArray(fieldApi.touched)
                ? fieldApi.touched.includes(fieldName)
                : fieldApi.touched;
            const fieldShouldShowError = fieldApi.shouldShowError(fieldName) && isString(fieldError);

            const api: FormFieldApi<T[FieldName]> = {
                value: fieldValue,
                setValue: (value: T[FieldName]) => {
                    fieldApi.setValue({ ...fieldApi.value, [fieldName]: value });
                },
                touched: fieldTouched,
                setTouched: (was: boolean, subKey?: string) => {
                    fieldApi.setTouched(was, subKey || fieldName);
                },
                error: fieldError,
                errorMessage: fieldShouldShowError ? fieldError : undefined,
                shouldShowError: (subKey?: string) => fieldApi.shouldShowError(subKey || fieldName),
                ref: null, // TODO: implement refs for array fields
            };
            return [fieldName, api];
        }),
    ) as FormFieldRecord<T>;
};

/* eslint-disable react-hooks/rules-of-hooks */
export const useFormObjectOptionalField = <T extends object>(fieldApi: FormFieldApi<T | null>) => {
    if (fieldApi.value === null) {
        return null;
    }
    return useFormObjectField(fieldApi as FormFieldApi<T>);
};
