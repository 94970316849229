import React, { FC } from 'react';

import { SupplierStatus, supplierStatusColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrSupplierStatus } from '../../../../store/suppliers/useTrSupplierStatus';

interface SupplierStatusChipProps {
    status: SupplierStatus;
}

export const SupplierStatusChip: FC<SupplierStatusChipProps> = ({ status }) => {
    const tr = useTrSupplierStatus();
    return <Badge label={tr(status)} color={supplierStatusColor[status]} />;
};
