export enum InspectionError {
    InvalidInspectionStatus = 'invalid_inspection_status',
    InvalidDeviceCheckStatus = 'invalid_device_check_status',
    InvalidAssignedAdmin = 'invalid_assigned_admin',
    InvalidAssignedBin = 'invalid_assigned_bin',
    NoLongerAssignableInspection = 'no_longer_assignable_inspection',
    DeviceCheckNotRequired = 'device_check_not_required',
    ActivationLockRemovalNotRequired = 'activation_lock_removal_not_required',
    OrgMDMRemovalNotRequired = 'org_mdm_removal_not_required',
    DeviceCheckPendingInspectionCompletion = 'device_check_pending_inspection_completion',
    MissingGrade = 'missing_grade',
    MissingDamagedPartNote = 'missing_damaged_part_note',
    MissingMissingPartNote = 'missing_missing_part_note',
    MissingCleaning = 'missing_cleaning',
    MissingAccessoryReset = 'missing_accessory_reset',
    SerialNumberAlreadyExists = 'serial_number_already_exists',
}
