import { ColorDye, getEnumValues } from '@hofy/global';

export enum OrderStatus {
    Requested = 'requested',
    Declined = 'declined',
    Approved = 'approved',
    Cancelled = 'cancelled',
}

export const historicOrderStatuses = [OrderStatus.Approved, OrderStatus.Declined];

export const orderDecisionStatusColor: Record<OrderStatus, ColorDye> = {
    [OrderStatus.Requested]: 'grey',
    [OrderStatus.Declined]: 'red',
    [OrderStatus.Approved]: 'green',
    [OrderStatus.Cancelled]: 'grey',
};

export const allOrderStatuses = getEnumValues<OrderStatus>(OrderStatus);
