import { AddonDataDependenciesDto } from '@hofy/api-admin';
import { AddonTarget } from '@hofy/api-shared';
import { isBlank } from '@hofy/ui';

export enum AddonDataDependency {
    Durations = 'durations',
    PaymentSchemas = 'payment_schemas',
    ProductCategories = 'product_categories',
    AssetValue = 'asset_value',
    PlatformTiers = 'platform_tiers',
}

export const getAddonDataDependencyLabel = (() => {
    const addonDependencyLabels = {
        [AddonDataDependency.Durations]: 'Durations',
        [AddonDataDependency.PaymentSchemas]: 'Payment schemas',
        [AddonDataDependency.ProductCategories]: 'Product categories',
        [AddonDataDependency.AssetValue]: 'Asset value',
        [AddonDataDependency.PlatformTiers]: 'Platform tiers',
    };

    return (dependency: AddonDataDependency) => addonDependencyLabels[dependency];
})();

const addonDataDependencyKeys: Record<AddonDataDependency, keyof AddonDataDependenciesDto> = {
    [AddonDataDependency.Durations]: 'durations',
    [AddonDataDependency.PaymentSchemas]: 'paymentSchemas',
    [AddonDataDependency.ProductCategories]: 'productCategories',
    [AddonDataDependency.AssetValue]: 'assetValue',
    [AddonDataDependency.PlatformTiers]: 'platformTiers',
};

const AddonDataDependenciesByTarget: Record<AddonTarget, AddonDataDependency[]> = {
    [AddonTarget.Asset]: [
        AddonDataDependency.Durations,
        AddonDataDependency.PaymentSchemas,
        AddonDataDependency.ProductCategories,
        AddonDataDependency.AssetValue,
        AddonDataDependency.PlatformTiers,
    ],
    [AddonTarget.Organization]: [
        AddonDataDependency.Durations,
        AddonDataDependency.PaymentSchemas,
        AddonDataDependency.PlatformTiers,
    ],
};

export const getAddonDataDependenciesByTarget = (target: AddonTarget) =>
    AddonDataDependenciesByTarget[target];

export const getInitialAddonDataDependencies = (
    target: AddonTarget,
    addonData: AddonDataDependenciesDto[],
) => {
    return getAddonDataDependenciesByTarget(target).filter(dependency =>
        addonData.some(row => !isBlank(row[addonDataDependencyKeys[dependency]])),
    );
};

export const getSanitizedAddonDataDependencyValues = (
    target: AddonTarget,
    data: AddonDataDependenciesDto,
    dependencies: AddonDataDependency[],
) => {
    return Object.fromEntries(
        getAddonDataDependenciesByTarget(target).map(key => [
            addonDataDependencyKeys[key],
            dependencies.includes(key) ? data[addonDataDependencyKeys[key]] : null,
        ]),
    );
};
