import { useMutation, useQueryClient } from '@tanstack/react-query';

import { invoiceCacheKey, invoicesService, MonthQuery } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

export const useSendInvoicesByEmail = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: invoicesService.sendAllInvoicesByEmail,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceCacheKey] });
            showToast({
                type: 'positive',
                message: 'All invoices have been sent.',
            });
            onSuccess?.();
        },
    });

    const sendInvoices = (p: MonthQuery) => {
        mutation.mutate(p);
    };

    return {
        sendInvoices,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
