import React, { FC } from 'react';

import { Box, PageHeader, SearchInput } from '@hofy/ui';

import { BlockFilterButton } from '../../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterContainer } from '../../../components/design/blockFilters/BlockFilterContainer';
import { useBlockFilters } from '../../../components/design/blockFilters/hooks/useBlockFilters';
import { useAdminRevenueRecognitionFilters } from '../../../store/invoicing/revenueRecognition/useAdminRevenueRecognitionFilters';
import { useRevenueRecognitionQuery } from '../../../store/invoicing/revenueRecognition/useRevenueRecognitionQuery';
import { AccountingTabs } from '../AccountingTabs';
import { AdminAccountingTab } from '../AdminAccountingTab';
import { RevenueRecognitionDownloadReport } from './components/RevenueRecognitionDownloadReport';
import { RevenueRecognitionTable } from './components/RevenueRecognitionTable';
import { RevenueTimeRangeFilter } from './filters/RevenueTimeRangeFilter';
import { RevenueRecognitionMenu } from './RevenueRecognitionMenu';

export const RevenueRecognitionPage: FC = () => {
    const { filters, filterCount, setSearch, setDateRange } = useAdminRevenueRecognitionFilters();
    const { revenueRecognitionEntries, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } =
        useRevenueRecognitionQuery(filters);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();

    return (
        <Box column flex='auto'>
            <PageHeader
                title='Revenue recognition'
                rightSlot={
                    <>
                        <RevenueRecognitionDownloadReport filters={filters} />
                        <SearchInput value={filters.search} onChange={setSearch} placeholder='Reference…' />
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <RevenueRecognitionMenu />
                    </>
                }
                tabsSlot={<AccountingTabs tab={AdminAccountingTab.RevenueRecognition} />}
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <RevenueTimeRangeFilter date={filters.dateRange} onChange={setDateRange} />
            </BlockFilterContainer>
            <RevenueRecognitionTable
                entries={revenueRecognitionEntries}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Box>
    );
};
