import { useQuery } from '@tanstack/react-query';

import { ListHofyWarehousesFilter } from '../service/types/ListHofyWarehousesFilter';
import { HofyWarehouseDetailsDto } from '../service/types/WarehouseDetailsDto';
import { warehouseService } from '../service/warehouseService';
import { warehouseCacheKey } from './warehousesCacheKey';

const emptyWarehouses: HofyWarehouseDetailsDto[] = [];

export const useHofyWarehousesQuery = (filter?: ListHofyWarehousesFilter, enabled: boolean = true) => {
    const { isLoading, isError, data } = useQuery({
        queryKey: [warehouseCacheKey, filter],
        queryFn: () => warehouseService.listHofyWarehouses(filter),
        enabled,
    });

    return {
        isLoading,
        isError,
        data: data || emptyWarehouses,
        enabled,
    };
};
