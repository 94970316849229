import React, { FC, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import { useKeyPress } from 'react-use';
import styled from 'styled-components';

import { IconButton, Modal, ModalContent, ModalFooter, ModalHeader, SvgIcon } from '@hofy/ui';

import { Image } from './types/Image';

interface ImagePreviewModalProps {
    images: Image[];
    index: number;
    setIndex(v: number): void;
}

export const ImagePreviewModal: FC<ImagePreviewModalProps> = ({ images, index, setIndex }) => {
    const previous = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const next = () => {
        if (index < images.length - 1) {
            setIndex(index + 1);
        }
    };

    const [leftPressed] = useKeyPress('ArrowLeft');
    const [rightPressed] = useKeyPress('ArrowRight');
    useEffect(() => {
        if (leftPressed) {
            previous();
        }
    }, [leftPressed]);
    useEffect(() => {
        if (rightPressed) {
            next();
        }
    }, [rightPressed]);

    return (
        <Modal onClose={() => setIndex(-1)}>
            <ModalHeader title='' />

            <ModalContent>
                <Img src={images[index].url} />
            </ModalContent>

            <ModalFooter centered>
                <IconButton size={32} onClick={previous} icon={SvgIcon.ChevronLeft} disabled={index === 0} />
                <IconButton
                    size={32}
                    marginLeft={16}
                    onClick={next}
                    icon={SvgIcon.ChevronRight}
                    disabled={index === images.length - 1}
                />
            </ModalFooter>
        </Modal>
    );
};

const Img = styled.img`
    max-width: 800px;
    max-height: 600px;
    display: block;
`;
