import React, { FC } from 'react';

import { useBaseI18n } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface ExportButtonProps extends BoxProps {
    onClick(): void;
    disabled?: boolean;
}

export const ExportButton: FC<ExportButtonProps> = ({ onClick, disabled, ...boxProps }) => {
    const { tr } = useBaseI18n();
    const color = disabled ? Color.InteractionDisabledNormal : Color.InteractionNeutralNormal;
    const cursor = disabled ? 'not-allowed' : 'pointer';
    const action = disabled ? undefined : onClick;

    return (
        <Box row cursor={cursor} onClick={action} {...boxProps}>
            <Icon svg={SvgIcon.Download} size={16} color={color} marginRight={10} />
            <Paragraph3 color={color}>{tr('general.xlsx')}</Paragraph3>
        </Box>
    );
};
