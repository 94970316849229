import { AnimatePresence, motion, Target, TargetAndTransition } from 'framer-motion';
import React, { forwardRef, useEffect, useState } from 'react';
import { useInterval, usePrevious } from 'react-use';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box } from '../../base';
import { Icon, SvgIcon } from '../../icon';
import { Spinner, SpinnerSize } from '../../loader';
import { Button, ButtonProps, defaultSize } from './Button';
import { ButtonSize } from './buttonConfig';

type Status = 'success' | 'error';

export interface AsyncButtonProps extends ButtonProps {
    isLoading?: boolean;
    disableCheck?: boolean;
    isError?: boolean;
}

const CHECK_TIME = 2000;

export const AsyncButton = forwardRef<any, AsyncButtonProps>(
    ({ disableCheck, onClick, isLoading = false, size = defaultSize, isError, ...props }, ref) => {
        const [checkVisible, setCheckVisible] = useState(false);
        const previousLoading = usePrevious(isLoading);

        const checkDelay = !isLoading && checkVisible ? CHECK_TIME : null;
        const isClickActive = !checkVisible && !isLoading;
        const status = isError ? 'error' : 'success';

        const spinnerSize = spinnerSizes[size];
        const statusColor = statusColors[status];
        const [initial, animate] = statusAnimation[status];

        const checkOverlay = checkVisible ? (
            <>
                <OverlayBgBox
                    bg={statusColor}
                    rounded={8}
                    as={motion.div}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    style={{ zIndex: 1 }}
                />
                <Box
                    as={motion.div}
                    initial={initial}
                    animate={animate}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3, ease: 'easeOut' }}
                    style={{ zIndex: 2 }}
                >
                    <Icon svg={statusIcon[status]} size={24} color={Color.InteractionInvertedNormal} block />
                </Box>
            </>
        ) : null;

        useEffect(() => {
            if (isLoading && !previousLoading && !disableCheck) {
                setCheckVisible(true);
            }
        }, [isLoading, previousLoading, disableCheck]);

        useInterval(() => {
            setCheckVisible(false);
        }, checkDelay);

        return (
            <Button
                ref={ref}
                {...props}
                size={size}
                onClick={isClickActive ? onClick : undefined}
                overlay={
                    <AnimatePresence>
                        {isLoading ? <Spinner size={spinnerSize} color='currentColor' /> : checkOverlay}
                    </AnimatePresence>
                }
                hideLabel={!isClickActive}
            />
        );
    },
);

const spinnerSizes: Record<ButtonSize, SpinnerSize> = {
    small: 16,
    medium: 24,
};

const statusColors: Record<Status, Color> = {
    success: Color.BackgroundPositive,
    error: Color.BackgroundNegative,
};

const statusAnimation: Record<Status, [Target, TargetAndTransition]> = {
    success: [
        { opacity: 0, rotate: -90 },
        { opacity: 1, rotate: 0 },
    ],
    error: [{ opacity: 0 }, { opacity: 1, translateX: [0, 5, -5, 5, 0] }],
};

const statusIcon: Record<Status, Svg> = {
    success: SvgIcon.Check,
    error: SvgIcon.Cross,
};

const OverlayBgBox = styled(Box)`
    position: absolute;
    inset: 0;
`;
