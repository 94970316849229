import React, { FC } from 'react';

import { Badge, SvgIcon } from '@hofy/ui';

interface WarehouseChipProps {
    name: string;
}

export const WarehouseChip: FC<WarehouseChipProps> = ({ name }) => {
    return <Badge color='green' icon={SvgIcon.Home} label={name} />;
};
