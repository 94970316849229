import { ColorDye } from '@hofy/global';

export enum CollectionPackagingStatus {
    Required = 'required',
    UserHasPackaging = 'user_has_packaging',
    AssistanceRequested = 'assistance_requested',
    PackagingSentToUser = 'packaging_sent_to_user',
    UserReceivedPackaging = 'user_received_packaging',
}

export const collectionPackagingStatusColors: Record<CollectionPackagingStatus, ColorDye> = {
    [CollectionPackagingStatus.Required]: 'orange',
    [CollectionPackagingStatus.UserHasPackaging]: 'green',
    [CollectionPackagingStatus.AssistanceRequested]: 'red',
    [CollectionPackagingStatus.PackagingSentToUser]: 'green',
    [CollectionPackagingStatus.UserReceivedPackaging]: 'green',
};
