import { RefObject, useLayoutEffect, useRef, useState } from 'react';

const size = 100;
type MeasureSide = 'width' | 'height';

export const useElScrollbarSize = (ref: RefObject<HTMLDivElement>, measure: MeasureSide = 'width') => {
    const [scrollSize, setScrollSize] = useState(0);
    const [contentSize, setContentSize] = useState(0);
    const [refSize, setRefSize] = useState(() => {
        if (!ref.current) {
            return 0;
        }

        if (measure === 'width') {
            return ref.current.offsetWidth;
        }

        return ref.current.offsetHeight;
    });

    const observer = useRef(
        new ResizeObserver(entries => {
            const { [measure]: size } = entries[0].contentRect;
            setRefSize(size);
        }),
    );

    useLayoutEffect(() => {
        if (ref.current) {
            observer.current.observe(ref.current);
        }

        return () => {
            observer.current.unobserve(ref.current!);
        };
    }, [ref, observer]);

    useLayoutEffect(() => {
        if (!ref.current) {
            return;
        }

        const tmp = document.createElement('div');

        tmp.style.position = 'absolute';
        tmp.style.width = `${size}px`;
        tmp.style.height = `${size}px`;
        tmp.style.left = `-${size * 2}px`;
        tmp.style.top = `-${size * 2}px`;
        tmp.style.visibility = 'hidden';
        tmp.style.overflow = 'scroll';

        // Reset all styles that might affect the element width obtained from classes
        tmp.style.padding = '0';
        tmp.style.border = 'none';
        tmp.style.display = 'block';
        tmp.style.minWidth = '0';
        tmp.style.maxWidth = 'none';
        tmp.style.minHeight = '0';
        tmp.style.maxHeight = 'none';

        tmp.className = ref.current.className;

        const innerTmpEl = document.createElement('div');
        innerTmpEl.style.width = '100%';
        innerTmpEl.style.height = '100%';

        tmp.appendChild(innerTmpEl);

        document.body.appendChild(tmp);

        // Use boundingClientRect here because it's affected by browser zoom
        // In this way, we can get precise scrollbar width when user zooms in/out
        const { [measure]: innerSize } = innerTmpEl.getBoundingClientRect();
        const { [measure]: mainSize } = ref.current.getBoundingClientRect();

        const scrollbarWidth = size - innerSize;
        const contentSize = mainSize - scrollbarWidth;

        tmp.parentNode!.removeChild(tmp);

        setScrollSize(scrollbarWidth);
        setContentSize(contentSize);
    }, [refSize]);

    return { scrollSize, contentSize };
};
