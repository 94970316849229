import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { JobContext, JobDto } from './types/JobDto';
import { JobFilters } from './types/JobFilters';
import { JobsSummaryDto } from './types/JobsSummaryDto';

const baseUrl = '/api/admin/jobs';

class JobService {
    public getJobs = async (page: PageRequest, filters?: JobFilters): Promise<PageResponse<JobDto>> => {
        return restClient.getJson<PageResponse<JobDto>>(
            stringifyUrl({
                url: baseUrl,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                    statuses: filters?.statuses,
                    context: filters?.context,
                },
            }),
        );
    };

    public getJobsSummary = async (context: JobContext[]): Promise<JobsSummaryDto> => {
        return restClient.getJson<JobsSummaryDto>(
            stringifyUrl({
                url: `${baseUrl}/summary`,
                query: {
                    context,
                },
            }),
        );
    };

    public cancelJob = async (jobId: UUID): Promise<void> => {
        return restClient.post(`${baseUrl}/${jobId}/cancel`);
    };
}

export const jobService = new JobService();
