import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import { useStructMemo } from '@hofy/hooks';

import { ThemeProvider } from '../../theme/ThemeProvider';
import { RWDContext } from './RWDContext';

interface RWDProviderProps {
    enabled: boolean;
    children: ReactNode;
}

export const RWDProvider: FC<RWDProviderProps> = ({ enabled, children }) => {
    const value = useStructMemo({ enabled });
    const viewport = enabled ? 'width=device-width, initial-scale=1' : 'width=1440';

    return (
        <RWDContext.Provider value={value}>
            <ThemeProvider>
                <Helmet>
                    <meta name='viewport' content={viewport} />
                </Helmet>
                {children}
            </ThemeProvider>
        </RWDContext.Provider>
    );
};
