import { getEnumValues } from '@hofy/global';

export enum AddonItem {
    Clearance = 'clearance',
    DeviceConfig = 'device_config',
    GlobalWarranty = 'global_warranty',
    HardwareSupport = 'hardware_support',
    HubStoreAndReuse = 'hub_store_and_reuse',
    ITSeat = 'it_seat',
    Loaner = 'loaner',
    LocalStoreAndReuse = 'local_store_and_reuse',
    ManagedRepair = 'managed_repair',
    PlatformFeeOperations = 'platform_fee_operations',
    RepairLogistics = 'repair_logistics',
    Replacement = 'replacement',
    Transfer = 'transfer',
}

export const allAddonItems = getEnumValues<AddonItem>(AddonItem);
