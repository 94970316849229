import React, { FC } from 'react';

import { allShipmentClasses, ShipmentClass } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { Box, BoxProps, TestKeyAware } from '@hofy/ui';

import { useTrAdminShipmentClass } from '../../../store/shipments/useTrAdminShipmentClass';

interface ShipmentClassDropdownProps extends TestKeyAware, BoxProps {
    value: ShipmentClass | null;
    label?: string;
    onChange(o: ShipmentClass): void;
    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
}

export const ShipmentClassDropdown: FC<ShipmentClassDropdownProps> = ({
    value,
    label = '',
    onChange,
    isError,
    isRequired,
    testKey,
    disabled,
    ...boxProps
}) => {
    const trAdminShipmentClass = useTrAdminShipmentClass();
    return (
        <Box {...boxProps}>
            <FormDropdown
                items={allShipmentClasses}
                disabled={disabled}
                onChange={onChange}
                label={label}
                labelFormatter={i => trAdminShipmentClass(i)}
                value={value}
                isRequired={isRequired}
                isError={isError}
                testKey={testKey}
            />
        </Box>
    );
};
