import { useQuery } from '@tanstack/react-query';

import { AdminItemFilter, itemCacheKey, itemsService } from '@hofy/api-admin';
import { AnyProductCategory, goodConditionGrades, ItemStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { ItemsFiltersUi, toItemsFilters } from './useAdminItemFilters';

export const useLoanerItems = (warehouseId: UUID, category: AnyProductCategory) => {
    const filters: ItemsFiltersUi = {
        grade: goodConditionGrades,
        category,
        warehouseId: warehouseId,
        status: [ItemStatus.Active],
        filter: AdminItemFilter.UsableAsLoaner,
        organization: [],
        search: null,
        variant: [],
        redistributableIn: null,
        location: [],
        ownership: null,
        withAddon: [],
        withoutAddon: [],
    };

    const { data, isLoading } = useQuery({
        queryKey: [itemCacheKey, 'loaner', filters],

        queryFn: () => itemsService.listItems(toItemsFilters(filters), { page: 0, pageSize: 50 }),
    });

    return {
        items: data?.content || [],
        isLoading,
    };
};
