import { ColorDye, getEnumValues } from '@hofy/global';

export enum TeamColor {
    Red = 'red',
    Orange = 'orange',
    Yellow = 'yellow',
    Green = 'green',
    Teal = 'teal',
    LightBlue = 'light_blue',
    Blue = 'blue',
    Purple = 'purple',
    Pink = 'pink',
    Magenta = 'magenta',
    Grey = 'grey',
}

export const teamColorMapping: Record<TeamColor, ColorDye> = {
    [TeamColor.Red]: 'red',
    [TeamColor.Orange]: 'orange',
    [TeamColor.Yellow]: 'yellow',
    [TeamColor.Green]: 'green',
    [TeamColor.Teal]: 'teal',
    [TeamColor.LightBlue]: 'cyan',
    [TeamColor.Blue]: 'blue',
    [TeamColor.Purple]: 'purple',
    [TeamColor.Pink]: 'raspberry',
    [TeamColor.Magenta]: 'magenta',
    [TeamColor.Grey]: 'grey',
};
export const allTeamColorsOptions = getEnumValues<TeamColor>(TeamColor);
