import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Box, OuterBoxProps } from '@hofy/ui';

interface ProductsGridWrapperProps extends OuterBoxProps {
    minColumnWidth?: number;
    children?: ReactNode;
}

export const ProductsGridWrapper: FC<ProductsGridWrapperProps> = ({
    minColumnWidth = 175,
    children,
    ...rest
}) => {
    return (
        <ContainerBox {...rest} minColumnWidth={minColumnWidth} data-test-key='product-grid'>
            {children}
        </ContainerBox>
    );
};

const ContainerBox = styled(Box)<{ minColumnWidth: number }>`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(${p => p.minColumnWidth}px, 1fr));
    grid-gap: 32px;
    justify-content: start;
`;
