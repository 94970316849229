import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Link, Paragraph3 } from '@hofy/ui';

interface ShipmentTrackingCellProps extends BoxProps {
    trackingLink: string | null;
}

export const ShipmentTrackingCell: FC<ShipmentTrackingCellProps> = ({ trackingLink, ...boxProps }) => {
    return (
        <Box column gap={4} {...boxProps}>
            <Paragraph3 color={Color.Neutral300}>Tracking</Paragraph3>
            {trackingLink ? <Link to={trackingLink}>Tracking link</Link> : <Paragraph3>--</Paragraph3>}
        </Box>
    );
};
