import React, { FC } from 'react';

import { InspectionDto, useTrInspectionStatus } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDateTime } from '@hofy/helpers';
import { BaseTable, Box, Paragraph3, Placeholder, SvgIllustration, TextCell } from '@hofy/ui';

import { InspectionStatusChip } from '../inspectionsPage/components/InspectionStatusChip';

interface InspectionsTableProps {
    inspections: InspectionDto[];
    onOpenInspectionDetails(uuid: UUID): void;
}

export const InspectionsTable: FC<InspectionsTableProps> = ({ inspections, onOpenInspectionDetails }) => {
    const trInspectionStatus = useTrInspectionStatus();
    return (
        <BaseTable
            data={inspections}
            toKey={inspection => inspection.id}
            emptyContent={
                <Placeholder illustration={SvgIllustration.ContractSearch} title='No inspections' />
            }
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            columns={[
                {
                    id: 'publicId',
                    header: 'Public Id',
                    flexGrow: 0,
                    width: 120,
                    renderer: inspection => {
                        return (
                            <Paragraph3
                                onClick={() => onOpenInspectionDetails(inspection.id)}
                                pointer
                                textNoWrap
                                underline
                            >
                                {inspection.publicId}
                            </Paragraph3>
                        );
                    },
                },
                {
                    id: 'warehouse',
                    header: 'Warehouse',
                    flexGrow: 1,
                    renderer: inspection => (
                        <TextCell>{inspection.warehouse ? inspection.warehouse.name : `--`}</TextCell>
                    ),
                },
                {
                    id: 'status',
                    header: 'Status',
                    flexGrow: 1,
                    renderer: inspection => <InspectionStatusChip status={inspection.status} />,
                },
                {
                    id: 'history',
                    header: 'History',
                    flexGrow: 2,
                    renderer: inspection => (
                        <Box column gap={4}>
                            {inspection.statusUpdates.map(u => (
                                <Box row key={u.status} gap={10}>
                                    <Paragraph3 bold textNoWrap flex={1}>
                                        {trInspectionStatus(u.status)}
                                    </Paragraph3>
                                    <Paragraph3 textNoWrap flex={1}>
                                        {formatDateTime(u.updatedAt)}
                                    </Paragraph3>
                                </Box>
                            ))}
                        </Box>
                    ),
                },
            ]}
        />
    );
};
