import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, FontProps, OuterBoxProps, Paragraph3, Span, TestKeyAware } from '@hofy/ui';

interface FlagLabelWrapperProps extends FontProps {
    children: ReactNode;
    color?: Color;
}

export const FlagLabelWrapper: FC<FlagLabelWrapperProps> = ({
    children,
    color = Color.BackgroundNeutral,
    ...fontProps
}) => {
    return (
        <Paragraph3 color={color} weight='normal' {...fontProps}>
            {children}
        </Paragraph3>
    );
};

interface FlagLabelViewProps extends OuterBoxProps, TestKeyAware {
    children: ReactNode;
    label: string;
    textNoWrap?: boolean;
}

export const FlagLabelView = ({ children, label, textNoWrap, testKey, ...boxProps }: FlagLabelViewProps) => {
    return (
        <Box row gap={8} {...boxProps}>
            {children}
            <Span
                textNoWrap={textNoWrap}
                whiteSpace='nowrap'
                overflow='hidden'
                ellipsis
                data-test-key={testKey}
            >
                {label}
            </Span>
        </Box>
    );
};
