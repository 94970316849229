import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';

import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { Link } from '../../../../components/routing/Link';
import { AdminInvoicingTab } from '../../../../store/invoicing/types/AdminInvoicingTab';

interface InvoiceDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
    color?: Color;
}

const InvoiceDetailsLink: FC<InvoiceDetailsLinkProps> = ({ id, color, children }) => {
    return (
        <Link color={color} to={`${AdminNavLink.Invoicing}/${AdminInvoicingTab.Invoices}/${id}/details`}>
            {children}
        </Link>
    );
};

export const InvoiceDetailsLinkLabel: FC<{ id: UUID | null; children?: ReactNode }> = ({ id, children }) => {
    return id ? <InvoiceDetailsLink id={id}>{children}</InvoiceDetailsLink> : <>--</>;
};
