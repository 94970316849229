import React, { FC } from 'react';

import { UserAssignmentsByShipmentDto, UserShipmentDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SvgIcon } from '@hofy/ui';

import { ShipmentPointCard } from '../../../../../components/domain/shipments/ShipmentPointCard';
import { useShipmentPoints } from '../../../../../store/shipments/useShipmentPoints';

interface ShipmentPointsCellProps extends BoxProps {
    user: UserAssignmentsByShipmentDto;
    shipment: UserShipmentDto;
}

export const ShipmentPointsCell: FC<ShipmentPointsCellProps> = ({ user, shipment, ...boxProps }) => {
    const { shipmentFromPoint, shipmentToPoint } = useShipmentPoints(user, shipment);

    return (
        <Box row gap={24} {...boxProps}>
            <ShipmentPointCard point={shipmentFromPoint} />
            <Icon color={Color.Neutral500} svg={SvgIcon.ChevronRight} />
            <ShipmentPointCard point={shipmentToPoint} isDelivery />
        </Box>
    );
};
