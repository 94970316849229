import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Box, FeaturedIcon, Paragraph3, SvgIcon } from '@hofy/ui';

import { EmptyResult } from './EmptyResult';
import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface ParcelsResultProps {
    numberOfParcels: number | null;
}

export const ParcelsResult: FC<ParcelsResultProps> = ({ numberOfParcels }) => {
    const getText = () => {
        if (!numberOfParcels) {
            return <EmptyResult />;
        }

        const text = `${numberOfParcels} ${numberOfParcels > 1 ? 'parcels' : 'parcel'}`;
        return <Paragraph3 color={Color.ContentPrimary}>{text}</Paragraph3>;
    };

    return (
        <SurveyResultsSectionCardChip
            title='Number of parcels'
            status={SurveyResultsSectionCardChipStatus.Neutral}
        >
            <Box row gap={8}>
                <FeaturedIcon size={32} icon={SvgIcon.Box} />
                {getText()}
            </Box>
        </SurveyResultsSectionCardChip>
    );
};
