import React, { FC } from 'react';

import { CollectionShipmentLabelsStatus, collectionShipmentLabelsStatusColors } from '@hofy/api-shared';
import { IconItemWithTooltip } from '@hofy/common';
import { getColorFromColorDye } from '@hofy/theme';
import { SvgIcon } from '@hofy/ui';

interface CollectionShipmentLabelsStatusCellProps {
    shipmentLabelsStatus: CollectionShipmentLabelsStatus;
}

export const CollectionShipmentLabelsStatusCell: FC<CollectionShipmentLabelsStatusCellProps> = ({
    shipmentLabelsStatus,
}) => {
    const color = getColorFromColorDye(collectionShipmentLabelsStatusColors[shipmentLabelsStatus], 'main');
    return (
        <IconItemWithTooltip tooltip='Shipment labels' icon={SvgIcon.Printer} iconColor={color}>
            {collectionShipmentLabelsStatusLabels[shipmentLabelsStatus]}
        </IconItemWithTooltip>
    );
};

const collectionShipmentLabelsStatusLabels: Record<CollectionShipmentLabelsStatus, string> = {
    [CollectionShipmentLabelsStatus.Required]: 'Pending user response',
    [CollectionShipmentLabelsStatus.UserWillPrintLabels]: 'Can print labels',
    [CollectionShipmentLabelsStatus.AssistanceRequested]: 'Cannot print labels',
    [CollectionShipmentLabelsStatus.LabelsSentToUser]: 'Labels sent to user',
    [CollectionShipmentLabelsStatus.UserReceivedLabels]: 'User received labels',
};
