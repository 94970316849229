import React, { FC } from 'react';

import { FilterChip, FilterGroup } from '../../../components';
import { FilterApiSingle } from '../useFilters';

interface FilterPropsSinge {
    filter: FilterApiSingle<any>;
}

export const FilterGroupSinge: FC<FilterPropsSinge> = ({ filter }) => {
    const { value, clear, toLabel, isChanged } = filter;
    return (
        <FilterGroup title={filter.name}>
            <FilterChip label={toLabel(value)} onClear={isChanged() ? clear : undefined} />
        </FilterGroup>
    );
};
