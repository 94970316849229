import FlexSearch, { CreateOptions } from 'flexsearch';
import { useEffect, useRef, useState } from 'react';

interface ResultsOptions {
    limit?: number;
}

const indexOptions: CreateOptions = {
    encode: 'balance',
    tokenize: 'full',
    depth: 2,
    async: false,
};

export const useSearch = <T>(
    list: T[],
    toText: (item: T) => string | string[],
    query: string,
    { limit }: ResultsOptions = {},
): T[] => {
    const [results, setResults] = useState(list);
    const searchRef = useRef(FlexSearch.create<number>());

    const flattenText = (v: string | string[]) => (Array.isArray(v) ? v.join(' ') : v);

    const runSearch = () => {
        if (query.trim()) {
            Promise.resolve(searchRef.current.search({ query, limit })).then(indexes => {
                setResults(indexes.map((index: number) => list[index]));
            });
        } else if (list !== results) {
            setResults(list);
        }
    };

    useEffect(() => {
        searchRef.current.init(indexOptions);
        list.forEach((value, id) => {
            searchRef.current.add(id, flattenText(toText(value)));
        });
        runSearch();
        return () => {
            searchRef.current.destroy();
        };
    }, [list]);

    useEffect(runSearch, [query]);

    return query.trim() ? results : list;
};
