export const purchaseOrderErrorI18n = {
    'api-error.bill-quantity-not-valid.title': 'Bill quantity is not valid',
    'api-error.bill-quantity-not-valid.message': 'Cannot perform this operation',
    'api-error.invalid-purchase-order-status.title': 'Invalid purchase order status',
    'api-error.invalid-purchase-order-status.message': 'Cannot perform this operation',
    'api-error.purchase-order-not-updatable.title': 'Purchase order not updatable',
    'api-error.purchase-order-not-updatable.message': 'Cannot perform this operation',
    'api-error.invalid-purchase-order-payload.title': 'Invalid purchase order payload',
    'api-error.invalid-purchase-order-payload.message': 'Cannot perform this operation',
    'api-error.dropship-not-supported.title':
        'Drop shipped purchase orders should be updated by updating the shipment',
    'api-error.dropship-not-supported.message': 'Cannot perform this operation',
    'api-error.quantity-received-higher-than-expected.title':
        'Received quantity is higher than expected on po item',
    'api-error.quantity-received-higher-than-expected.message': 'Cannot perform this operation',
    'api-error.no-item-code.title': 'Item code must be provided for warehouse deliveries',
    'api-error.no-item-code.message': 'Cannot perform this operation',
    'api-error.purchase-order-not-approved.title': 'Purchase order not approved',
    'api-error.purchase-order-not-approved.message': 'Cannot perform this operation',
    'api-error.quantity-received-lower-than-expected.title':
        'Received quantity is lower than expected on po item',
    'api-error.quantity-received-lower-than-expected.message': 'Cannot perform this operation',
    'api-error.invalid-purchase-order-currency-payload.title': 'Invalid purchase order currency payload',
    'api-error.invalid-purchase-order-currency-payload.message': 'Cannot perform this operation',
};
