import { Palette } from './Palette';

/*
 * This file was generated automatically by the script.
 * Do not edit it manually!
 */

export const Color = {
    // Background
    BackgroundBrand: Palette.violet['0'], // rgb(99, 78, 202)
    BackgroundDefault: Palette.white['0'], // rgb(255, 255, 255)
    BackgroundDefaultDimmer: Palette.greyAlpha['+2'], // rgba(17, 38, 59, 0.40)
    BackgroundInformative: Palette.violet['0'], // rgb(99, 78, 202)
    BackgroundInverted: Palette.grey['-3'], // rgb(39, 46, 53)
    BackgroundNegative: Palette.red['0'], // rgb(197, 52, 52)
    BackgroundNeutral: Palette.grey['0'], // rgb(85, 95, 109)
    BackgroundPositive: Palette.green['0'], // rgb(29, 124, 77)
    BackgroundSubtleBrand: Palette.violet['+7'], // rgb(247, 245, 255)
    BackgroundSubtleNegative: Palette.red['+7'], // rgb(254, 245, 245)
    BackgroundSubtleNeutral: Palette.grey['+7'], // rgb(245, 247, 249)
    BackgroundSubtlePositive: Palette.green['+7'], // rgb(244, 251, 247)
    BackgroundSubtleWarning: Palette.orange['+7'], // rgb(255, 245, 235)
    BackgroundSurfaceBrand: Palette.violet['+8'], // rgb(251, 250, 255)
    BackgroundSurfaceInformative: Palette.violet['+8'], // rgb(251, 250, 255)
    BackgroundSurfaceNegative: Palette.red['+8'], // rgb(255, 250, 250)
    BackgroundSurfaceNeutral: Palette.grey['+8'], // rgb(251, 252, 253)
    BackgroundSurfacePositive: Palette.green['+8'], // rgb(251, 254, 252)
    BackgroundSurfaceWarning: Palette.orange['+8'], // rgb(255, 252, 250)
    BackgroundWarning: Palette.orange['0'], // rgb(245, 150, 56)

    // Content
    ContentBrand: Palette.violet['0'], // rgb(99, 78, 202)
    ContentDisabled: Palette.grey['+2'], // rgb(158, 168, 179)
    ContentInformative: Palette.violet['0'], // rgb(99, 78, 202)
    ContentInvertedDisabled: Palette.white['-6'], // rgba(255, 255, 255, 0.24)
    ContentInvertedPrimary: Palette.white['0'], // rgb(255, 255, 255)
    ContentInvertedSecondary: Palette.white['-4'], // rgba(255, 255, 255, 0.64)
    ContentInvertedTertiary: Palette.white['-5'], // rgba(255, 255, 255, 0.40)
    ContentNegative: Palette.red['0'], // rgb(197, 52, 52)
    ContentPositive: Palette.green['0'], // rgb(29, 124, 77)
    ContentPrimary: Palette.grey['-3'], // rgb(39, 46, 53)
    ContentSecondary: Palette.grey['0'], // rgb(85, 95, 109)
    ContentTertiary: Palette.grey['+1'], // rgb(126, 139, 153)
    ContentWarning: Palette.orange['-1'], // rgb(204, 110, 16)

    // Interaction
    InteractionBackgroundFormField: Palette.white['0'], // rgb(255, 255, 255)
    InteractionBackgroundInvertedModeless: Palette.grey['-3'], // rgb(39, 46, 53)
    InteractionBackgroundModal: Palette.white['0'], // rgb(255, 255, 255)
    InteractionBackgroundModeless: Palette.white['0'], // rgb(255, 255, 255)
    InteractionBackgroundSidepanel: Palette.white['0'], // rgb(255, 255, 255)
    InteractionBorderActive: Palette.violet['-1'], // rgb(87, 63, 207)
    InteractionBorderAlert: Palette.red['+1'], // rgb(242, 100, 100)
    InteractionBorderHover: Palette.violet['+1'], // rgb(145, 126, 241)
    InteractionBorderNeutralActive: Palette.grey['+1'], // rgb(126, 139, 153)
    InteractionBorderNeutralHover: Palette.grey['+2'], // rgb(158, 168, 179)
    InteractionBorderNeutralNormal: Palette.greyAlpha['+3'], // rgba(22, 48, 75, 0.20)
    InteractionBorderNeutralSelected: Palette.grey['+2'], // rgb(158, 168, 179)
    InteractionBorderNormal: Palette.violet['+2'], // rgb(171, 162, 251)
    InteractionBorderSelected: Palette.violet['0'], // rgb(99, 78, 202)
    InteractionDefaultActive: Palette.violet['-3'], // rgb(57, 40, 138)
    InteractionDefaultHover: Palette.violet['-2'], // rgb(78, 56, 188)
    InteractionDefaultNormal: Palette.violet['0'], // rgb(99, 78, 202)
    InteractionDefaultSelected: Palette.violet['-2'], // rgb(78, 56, 188)
    InteractionDefaultSubtleActive: Palette.violet['+3'], // rgb(220, 214, 255)
    InteractionDefaultSubtleBorder: Palette.grey['+5'], // rgb(233, 236, 239)
    InteractionDefaultSubtleBorderHover: Palette.violet['+1'], // rgb(145, 126, 241)
    InteractionDefaultSubtleHover: Palette.violet['+5'], // rgb(238, 235, 255)
    InteractionDefaultSubtleNormal: Palette.violet['+6'], // rgb(243, 240, 255)
    InteractionDefaultSubtleSelected: Palette.violet['+5'], // rgb(238, 235, 255)
    InteractionDestructiveActive: Palette.red['-3'], // rgb(111, 32, 32)
    InteractionDestructiveHover: Palette.red['-2'], // rgb(149, 45, 45)
    InteractionDestructiveNormal: Palette.red['0'], // rgb(197, 52, 52)
    InteractionDestructiveSelected: Palette.red['-2'], // rgb(149, 45, 45)
    InteractionDestructiveSubtleActive: Palette.red['+3'], // rgb(252, 207, 207)
    InteractionDestructiveSubtleHover: Palette.red['+5'], // rgb(254, 230, 230)
    InteractionDestructiveSubtleNormal: Palette.red['+6'], // rgb(255, 235, 235)
    InteractionDestructiveSubtleSelected: Palette.red['+5'], // rgb(254, 230, 230)
    InteractionDisabledActive: Palette.grey['0'], // rgb(85, 95, 109)
    InteractionDisabledHover: Palette.grey['+1'], // rgb(126, 139, 153)
    InteractionDisabledNormal: Palette.grey['+2'], // rgb(158, 168, 179)
    InteractionDisabledSubtleActive: Palette.grey['+3'], // rgb(207, 214, 221)
    InteractionDisabledSubtleHover: Palette.grey['+4'], // rgb(222, 227, 231)
    InteractionDisabledSubtleNormal: Palette.grey['+5'], // rgb(233, 236, 239)
    InteractionFocusAlert: Palette.red['+1'], // rgb(242, 100, 100)
    InteractionFocusDefault: Palette.violet['+1'], // rgb(145, 126, 241)
    InteractionGhostActive: Palette.violetAlpha['+5'], // rgba(43, 5, 255, 0.08)
    InteractionGhostDestructiveActive: Palette.red['+5'], // rgb(254, 230, 230)
    InteractionGhostDestructiveHover: Palette.red['+6'], // rgb(255, 235, 235)
    InteractionGhostDestructiveSelected: Palette.red['+6'], // rgb(255, 235, 235)
    InteractionGhostHover: Palette.violetAlpha['+6'], // rgba(55, 5, 255, 0.06)
    InteractionGhostInvertedActive: Palette.white['-7'], // rgba(255, 255, 255, 0.12)
    InteractionGhostInvertedHover: Palette.white['-8'], // rgba(255, 255, 255, 0.10)
    InteractionGhostInvertedSelected: Palette.white['-8'], // rgba(255, 255, 255, 0.10)
    InteractionGhostNormal: Palette.white['transparent'], // rgba(255, 255, 255, 0.00)
    InteractionGhostSelected: Palette.violetAlpha['+6'], // rgba(55, 5, 255, 0.06)
    InteractionInvertedActive: Palette.white['-2'], // rgba(255, 255, 255, 0.72)
    InteractionInvertedHover: Palette.white['-1'], // rgba(255, 255, 255, 0.82)
    InteractionInvertedNormal: Palette.white['0'], // rgb(255, 255, 255)
    InteractionInvertedSelected: Palette.white['-1'], // rgba(255, 255, 255, 0.82)
    InteractionNeutralActive: Palette.grey['-3'], // rgb(39, 46, 53)
    InteractionNeutralHover: Palette.grey['-2'], // rgb(58, 66, 74)
    InteractionNeutralNormal: Palette.grey['-1'], // rgb(74, 84, 94)
    InteractionNeutralSelected: Palette.grey['-2'], // rgb(58, 66, 74)
    InteractionNeutralSubtleActive: Palette.grey['+3'], // rgb(207, 214, 221)
    InteractionNeutralSubtleHover: Palette.grey['+7'], // rgb(245, 247, 249)
    InteractionNeutralSubtleNormal: Palette.grey['+6'], // rgb(240, 243, 245)
    InteractionNeutralSubtleSelected: Palette.grey['+5'], // rgb(233, 236, 239)
    InteractionSuccessActive: Palette.green['-3'], // rgb(14, 78, 48)
    InteractionSuccessHover: Palette.green['-2'], // rgb(25, 103, 66)
    InteractionSuccessNormal: Palette.green['0'], // rgb(29, 124, 77)
    InteractionSuccessSelected: Palette.green['-2'], // rgb(25, 103, 66)
    InteractionSuccessSubtleActive: Palette.green['+3'], // rgb(194, 235, 213)
    InteractionSuccessSubtleHover: Palette.green['+5'], // rgb(216, 248, 231)
    InteractionSuccessSubtleNormal: Palette.green['+6'], // rgb(230, 249, 239)
    InteractionSuccessSubtleSelected: Palette.green['+5'], // rgb(216, 248, 231)

    // Non-Contextual
    NonContextualAquamarineDefault: Palette.aquamarine['0'], // rgb(9, 124, 105)
    NonContextualAquamarineMuted: Palette.aquamarine['+4'], // rgb(197, 242, 236)
    NonContextualAquamarineOnMuted: Palette.aquamarine['-3'], // rgb(13, 74, 66)
    NonContextualAquamarineSubtle: Palette.aquamarine['+6'], // rgb(226, 248, 245)
    NonContextualAquamarineSurface: Palette.aquamarine['+8'], // rgb(251, 254, 254)
    NonContextualAquamarineSurfaceBorder: Palette.teal['+6'], // rgb(232, 247, 252)
    NonContextualBlueDefault: Palette.blue['0'], // rgb(48, 98, 212)
    NonContextualBlueMuted: Palette.blue['+4'], // rgb(215, 228, 255)
    NonContextualBlueOnMuted: Palette.blue['-3'], // rgb(17, 57, 151)
    NonContextualBlueSubtle: Palette.blue['+6'], // rgb(237, 242, 255)
    NonContextualBlueSurface: Palette.blue['+8'], // rgb(250, 251, 255)
    NonContextualBlueSurfaceBorder: Palette.blue['+6'], // rgb(237, 242, 255)
    NonContextualBorderBrand: Palette.violet['0'], // rgb(99, 78, 202)
    NonContextualBorderDivider: Palette.grey['+5'], // rgb(233, 236, 239)
    NonContextualBorderInformative: Palette.violet['0'], // rgb(99, 78, 202)
    NonContextualBorderInverted: Palette.white['0'], // rgb(255, 255, 255)
    NonContextualBorderNegative: Palette.red['0'], // rgb(197, 52, 52)
    NonContextualBorderNeutral: Palette.grey['0'], // rgb(85, 95, 109)
    NonContextualBorderPositive: Palette.green['0'], // rgb(29, 124, 77)
    NonContextualBorderSubtleBrand: Palette.violet['+3'], // rgb(220, 214, 255)
    NonContextualBorderSubtleInformative: Palette.violet['+3'], // rgb(220, 214, 255)
    NonContextualBorderSubtleNegative: Palette.red['+3'], // rgb(252, 207, 207)
    NonContextualBorderSubtleNeutral: Palette.grey['+3'], // rgb(207, 214, 221)
    NonContextualBorderSubtlePositive: Palette.green['+3'], // rgb(194, 235, 213)
    NonContextualBorderSubtleWarning: Palette.orange['+3'], // rgb(255, 212, 168)
    NonContextualBorderWarning: Palette.orange['0'], // rgb(245, 150, 56)
    NonContextualCyanDefault: Palette.cyan['0'], // rgb(8, 112, 186)
    NonContextualCyanMuted: Palette.cyan['+4'], // rgb(204, 231, 255)
    NonContextualCyanOnMuted: Palette.cyan['-3'], // rgb(12, 69, 110)
    NonContextualCyanSubtle: Palette.cyan['+6'], // rgb(235, 245, 255)
    NonContextualCyanSurface: Palette.cyan['+8'], // rgb(250, 253, 255)
    NonContextualCyanSurfaceBorder: Palette.cyan['+6'], // rgb(235, 245, 255)
    NonContextualEmeraldDefault: Palette.emerald['0'], // rgb(52, 116, 52)
    NonContextualEmeraldMuted: Palette.emerald['+4'], // rgb(207, 242, 207)
    NonContextualEmeraldOnMuted: 'rgb(14, 78, 47)',
    NonContextualEmeraldSubtle: Palette.emerald['+6'], // rgb(230, 249, 230)
    NonContextualEmeraldSurface: Palette.emerald['+8'], // rgb(251, 254, 251)
    NonContextualEmeraldSurfaceBorder: Palette.emerald['+6'], // rgb(230, 249, 230)
    NonContextualGrapeDefault: Palette.grape['0'], // rgb(128, 64, 191)
    NonContextualGrapeMuted: Palette.grape['+4'], // rgb(237, 219, 255)
    NonContextualGrapeOnMuted: Palette.grape['-3'], // rgb(76, 34, 119)
    NonContextualGrapeSubtle: Palette.grape['+6'], // rgb(246, 240, 255)
    NonContextualGrapeSurface: Palette.grape['+8'], // rgb(252, 250, 255)
    NonContextualGrapeSurfaceBorder: Palette.grape['+6'], // rgb(246, 240, 255)
    NonContextualGreenDefault: Palette.green['0'], // rgb(29, 124, 77)
    NonContextualGreenMuted: Palette.green['+4'], // rgb(198, 241, 218)
    NonContextualGreenOnMuted: Palette.green['-3'], // rgb(14, 78, 48)
    NonContextualGreenSubtle: Palette.green['+6'], // rgb(230, 249, 239)
    NonContextualGreenSurface: Palette.green['+8'], // rgb(251, 254, 252)
    NonContextualGreenSurfaceBorder: Palette.green['+6'], // rgb(230, 249, 239)
    NonContextualGreyDefault: Palette.grey['0'], // rgb(85, 95, 109)
    NonContextualGreyMuted: Palette.grey['+4'], // rgb(222, 227, 231)
    NonContextualGreyOnMuted: Palette.grey['-3'], // rgb(39, 46, 53)
    NonContextualGreySubtle: Palette.grey['+6'], // rgb(240, 243, 245)
    NonContextualGreySurface: Palette.grey['+8'], // rgb(251, 252, 253)
    NonContextualGreySurfaceBorder: Palette.grey['+6'], // rgb(240, 243, 245)
    NonContextualMagentaDefault: Palette.magenta['0'], // rgb(177, 47, 134)
    NonContextualMagentaMuted: Palette.magenta['+4'], // rgb(251, 213, 238)
    NonContextualMagentaOnMuted: Palette.magenta['-3'], // rgb(103, 30, 78)
    NonContextualMagentaSubtle: Palette.magenta['+6'], // rgb(253, 237, 248)
    NonContextualMagentaSurface: Palette.magenta['+8'], // rgb(255, 250, 253)
    NonContextualMagentaSurfaceBorder: Palette.magenta['+6'], // rgb(253, 237, 248)
    NonContextualOrangeDefault: Palette.orange['0'], // rgb(245, 150, 56)
    NonContextualOrangeMuted: Palette.orange['+4'], // rgb(252, 222, 192)
    NonContextualOrangeOnMuted: Palette.orange['-3'], // rgb(122, 69, 16)
    NonContextualOrangeSubtle: Palette.orange['+6'], // rgb(255, 240, 224)
    NonContextualOrangeSurface: Palette.orange['+8'], // rgb(255, 252, 250)
    NonContextualOrangeSurfaceBorder: Palette.orange['+6'], // rgb(255, 240, 224)
    NonContextualPurpleDefault: Palette.purple['0'], // rgb(153, 57, 172)
    NonContextualPurpleMuted: Palette.purple['+4'], // rgb(245, 213, 251)
    NonContextualPurpleOnMuted: Palette.purple['-3'], // rgb(91, 30, 103)
    NonContextualPurpleSubtle: Palette.purple['+6'], // rgb(250, 237, 253)
    NonContextualPurpleSurface: Palette.purple['+8'], // rgb(254, 250, 255)
    NonContextualPurpleSurfaceBorder: Palette.purple['+6'], // rgb(250, 237, 253)
    NonContextualRaspberryDefault: Palette.raspberry['0'], // rgb(192, 48, 96)
    NonContextualRaspberryMuted: Palette.raspberry['+4'], // rgb(251, 213, 226)
    NonContextualRaspberryOnMuted: Palette.raspberry['-3'], // rgb(103, 30, 54)
    NonContextualRaspberrySubtle: Palette.raspberry['+6'], // rgb(253, 237, 242)
    NonContextualRaspberrySurface: Palette.raspberry['+8'], // rgb(255, 250, 252)
    NonContextualRaspberrySurfaceBorder: Palette.raspberry['+6'], // rgb(253, 237, 242)
    NonContextualRedDefault: Palette.red['0'], // rgb(197, 52, 52)
    NonContextualRedMuted: Palette.red['+4'], // rgb(252, 217, 217)
    NonContextualRedOnMuted: Palette.red['-3'], // rgb(111, 32, 32)
    NonContextualRedSubtle: Palette.red['+6'], // rgb(255, 235, 235)
    NonContextualRedSurface: Palette.red['+8'], // rgb(255, 250, 250)
    NonContextualRedSurfaceBorder: Palette.red['+6'], // rgb(255, 235, 235)
    NonContextualTealDefault: Palette.teal['0'], // rgb(7, 117, 151)
    NonContextualTealMuted: Palette.teal['+4'], // rgb(197, 236, 247)
    NonContextualTealOnMuted: Palette.teal['-3'], // rgb(10, 73, 92)
    NonContextualTealSubtle: Palette.teal['+6'], // rgb(232, 247, 252)
    NonContextualTealSurface: Palette.teal['+8'], // rgb(250, 254, 255)
    NonContextualTealSurfaceBorder: Palette.teal['+6'], // rgb(232, 247, 252)
    NonContextualVioletDefault: Palette.violet['0'], // rgb(99, 78, 202)
    NonContextualVioletMuted: Palette.violet['+4'], // rgb(227, 224, 255)
    NonContextualVioletOnMuted: Palette.violet['-3'], // rgb(57, 40, 138)
    NonContextualVioletSubtle: Palette.violet['+6'], // rgb(243, 240, 255)
    NonContextualVioletSurface: Palette.violet['+8'], // rgb(251, 250, 255)
    NonContextualVioletSurfaceBorder: Palette.violet['+6'], // rgb(243, 240, 255)
    NonContextualYellowDefault: Palette.yellow['0'], // rgb(202, 138, 4)
    NonContextualYellowMuted: Palette.yellow['+4'], // rgb(253, 224, 71)
    NonContextualYellowOnMuted: Palette.yellow['-3'], // rgb(113, 63, 18)
    NonContextualYellowSubtle: Palette.yellow['+6'], // rgb(254, 249, 195)
    NonContextualYellowSurface: Palette.yellow['+8'], // rgb(255, 254, 243)
    NonContextualYellowSurfaceBorder: Palette.yellow['+6'], // rgb(254, 249, 195)

    // Old
    /** @deprecated */
    Neutral200: Palette.grey['+5'], // rgb(233, 236, 239)
    /** @deprecated */
    Neutral300: Palette.grey['+1'], // rgb(126, 139, 153)
    /** @deprecated */
    Neutral500: Palette.grey['0'], // rgb(85, 95, 109)
    /** @deprecated */
    Neutral700: Palette.grey['-3'], // rgb(39, 46, 53)
    /** @deprecated */
    Neutral900: Palette.grey['-3'], // rgb(39, 46, 53)
    /** @deprecated */
    FoundationLightPurple: Palette.violet['-1'], // rgb(87, 63, 207)
    /** @deprecated */
    FoundationPurple: Palette.violet['0'], // rgb(99, 78, 202)
    /** @deprecated */
    FoundationDarkPurple: Palette.violet['-4'], // rgb(31, 18, 100)
    /** @deprecated */
    FoundationPositive: Palette.green['+1'], // rgb(74, 165, 120)
    /** @deprecated */
    FoundationWarning: Palette.orange['0'], // rgb(245, 150, 56)
    /** @deprecated */
    FoundationNegative: Palette.red['0'], // rgb(197, 52, 52)
    /** @deprecated */
    AccentRed: Palette.red['0'], // rgb(197, 52, 52)
    /** @deprecated */
    AccentRedPastel: Palette.red['+1'], // rgb(242, 100, 100)
    /** @deprecated */
    AccentOrange: Palette.orange['0'], // rgb(245, 150, 56)
    /** @deprecated */
    AccentOrangePastel: Palette.orange['+1'], // rgb(246, 163, 81)
    /** @deprecated */
    AccentYellow: Palette.orange['+1'], // rgb(246, 163, 81)
    /** @deprecated */
    AccentYellowPastel: Palette.orange['+2'], // rgb(254, 184, 114)
    /** @deprecated */
    AccentGreen: Palette.green['+1'], // rgb(74, 165, 120)
    /** @deprecated */
    AccentGreenPastel: Palette.green['+2'], // rgb(117, 204, 158)
    /** @deprecated */
    AccentTeal: Palette.teal['+1'], // rgb(79, 158, 186)
    /** @deprecated */
    AccentLightBlue: Palette.cyan['-1'], // rgb(27, 103, 157)
    /** @deprecated */
    AccentLightBluePastel: Palette.cyan['+2'], // rgb(121, 188, 246)
    /** @deprecated */
    AccentBlue: Palette.blue['-1'], // rgb(39, 89, 205)
    /** @deprecated */
    AccentPurple: Palette.grape['-1'], // rgb(115, 57, 172)
    /** @deprecated */
    AccentPink: Palette.magenta['-1'], // rgb(149, 50, 116)
    /** @deprecated */
    AccentMagenta: Palette.magenta['0'], // rgb(177, 47, 134)
    /** @deprecated */
    AccentGreyPastel: Palette.grey['+2'], // rgb(158, 168, 179)
    /** @deprecated */
    BackgroundOverlay: Palette.greyAlpha['+3'], // rgba(22, 48, 75, 0.20)
    /** @deprecated */
    HighlightHover: Palette.grey['+8'], // rgb(251, 252, 253)
    /** @deprecated */
    HighlightActive: Palette.grey['+4'], // rgb(222, 227, 231)
    /** @deprecated */
    HighlightPositiveSelected: Palette.green['-1'], // rgb(30, 113, 74)
    /** @deprecated */
    HighlightNegativeHover: Palette.red['-1'], // rgb(161, 54, 54)
    /** @deprecated */
    Highlight: Palette.grey['+6'], // rgb(240, 243, 245)
    /** @deprecated */
    Purple: Palette.violet['-3'], // rgb(57, 40, 138)
    /** @deprecated */
    Radio: Palette.grey['+4'], // rgb(222, 227, 231)
    /** @deprecated */
    Info: Palette.grape['-1'], // rgb(115, 57, 172)
    /** @deprecated */
    Shadow: 'rgba(0, 0, 0, 0.20)',
    /** @deprecated */
    Transparent: Palette.white['transparent'], // rgba(255, 255, 255, 0.00)
} as const;

/* eslint-disable no-redeclare */
export type Color =
    | `rgb(${number}, ${number}, ${number})`
    | `rgba(${number}, ${number}, ${number}, ${number})`;
