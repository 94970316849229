import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { userService } from '../service/userService';
import { usersCacheKey } from './cacheKey';

const isUserNotFound = (error: any) => {
    return error.reason === 'Not Found';
};

export const useUserQuery = (id: UUID | null) => {
    const { data, isLoading } = useQuery({
        queryKey: [usersCacheKey, id],
        queryFn: () => userService.getUser(id!),
        enabled: id !== null,

        retry: (count, error) => {
            if (count > 3) {
                return false;
            }
            return !isUserNotFound(error);
        },
    });

    return {
        data,
        isLoading,
    };
};
