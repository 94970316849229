export const shipmentErrorI18n = {
    'api-error.assignment-contains-consumable-items.title': 'Cannot transfer consumable item assignment',
    'api-error.assignment-contains-consumable-items.message': 'Cannot perform this operation',
    'api-error.assignment-contains-items-not-invoiceable.title':
        'Cannot transfer items that are not invoicable',
    'api-error.assignment-contains-items-not-invoiceable.message': 'Cannot perform this operation',
    'api-error.shipment-contains-assignments-collected-for-store-and-reuse.title':
        'Cannot transfer assignments that are being collected for store and reuse',
    'api-error.shipment-contains-assignments-collected-for-store-and-reuse.message':
        'Cannot perform this operation',
    'api-error.not-matching-assignment-region-with-assignee-user.title':
        'Cannot transfer items that have subcontracts in a different region to the user',
    'api-error.not-matching-assignment-region-with-assignee-user.message': 'Cannot perform this operation',
    'api-error.no-purchase-order.title': 'No purchase order',
    'api-error.no-purchase-order.message': 'Cannot perform this operation',
    'api-error.items-not-assigned.title': 'No items assigned',
    'api-error.items-not-assigned.message': 'Cannot perform this operation',
    'api-error.items-not-scanned.title': 'Found unscanned items',
    'api-error.items-not-scanned.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-status.title': 'Invalid shipment status',
    'api-error.invalid-shipment-status.message': 'Cannot perform this operation',
    'api-error.address-country-changed.title': 'Invalid country changed',
    'api-error.address-country-changed.message': 'Cannot perform this operation',
    'api-error.invalid-number-of-boxes.title': 'Number of boxes',
    'api-error.invalid-number-of-boxes.message': 'Cannot perform this operation',
    'api-error.no-assignments-to-ship.title': 'No assignments to ship',
    'api-error.no-assignments-to-ship.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-type.title': 'Invalid assignment type',
    'api-error.invalid-assignment-type.message': 'Cannot perform this operation',
    'api-error.invalid-assignment-status.title': 'Invalid assignment status',
    'api-error.invalid-assignment-status.message': 'Cannot perform this operation',
    'api-error.invalid-selected-assignments.title': 'Invalid selected assignments',
    'api-error.invalid-selected-assignments.message': 'Cannot perform this operation',
    'api-error.assignments-belong-to-different-users.title': 'Assignments belong to different users',
    'api-error.assignments-belong-to-different-users.message': 'Cannot perform this operation',
    'api-error.missing-shipment-hofy-warehouse.title': 'Missing shipment hofy warehouse',
    'api-error.missing-shipment-hofy-warehouse.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-hofy-warehouse.title': 'Invalid shipment hofy warehouse',
    'api-error.invalid-shipment-hofy-warehouse.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-state.title':
        'Cannot create survey for shipments that are with courier completed or canceled',
    'api-error.invalid-shipment-state.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-type.title':
        'Can only create survey for shipments of type collection or transfer',
    'api-error.invalid-shipment-type.message': 'Cannot perform this operation',
    'api-error.missing-shipment-estimate-start-on.title': 'Missing shipment estimated arrival date',
    'api-error.missing-shipment-estimate-start-on.message': 'Cannot perform this operation',
    'api-error.assignment-is-on-hold.title': 'Assignment is on hold',
    'api-error.assignment-is-on-hold.message': 'Cannot perform this operation',
    'api-error.assignment-status-not-delivery-pending.title':
        'Assignment should be in delivery pending status',
    'api-error.assignment-status-not-delivery-pending.message': 'Cannot perform this operation',
    'api-error.items-in-different-organization-warehouses.title':
        'Items are in different organization warehouses',
    'api-error.items-in-different-organization-warehouses.message': 'Cannot perform this operation',
    'api-error.items-partially-at-warehouse.title': 'Not all items are in the warehouse',
    'api-error.items-partially-at-warehouse.message': 'Cannot perform this operation',
    'api-error.invalid-shipment-public-address.title': 'Invalid shipment public address',
    'api-error.invalid-shipment-public-address.message': 'Cannot perform this operation',
};
