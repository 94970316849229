import React, { FC, useState } from 'react';

import { firstImage, ProductDto } from '@hofy/api-admin';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, Box, Button, SvgIcon } from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { ProductOverview } from '../../../../components/domain/products/ProductOverview';
import { useCreateItem } from '../../../../store/items/useCreateItem';
import { AddItemForm } from './AddItemForm';
import { ProductSelectSlideout } from './ProductSelectSlideout';
import { VariantsList } from './VariantsList';

interface AddItemSlideoutProps {
    onClose(): void;
}

const productHasManyVariants = (product?: ProductDto) => !!product?.variants && product.variants.length > 1;

export const AddItemSlideout: FC<AddItemSlideoutProps> = ({ onClose }) => {
    const [product, setProduct] = useState<ProductDto>();
    const { form, isLoading, saveAndClear, saveAndClose } = useCreateItem(onClose);

    const handleProductSelect = (product: ProductDto) => {
        setProduct(product);
        const variant = productHasManyVariants(product) ? null : product.variants[0];
        form.setValues({ variant });
    };

    const handleVariantBack = () => {
        if (productHasManyVariants(product)) {
            form.setValues({ variant: null });
        } else {
            setProduct(undefined);
        }
    };

    const variantSelected = form.values.variant;
    const cancelButton = variantSelected ? (
        <Button
            type='ghost'
            negativeMargin
            onClick={handleVariantBack}
            label='Back'
            leftIcon={SvgIcon.ChevronLeft}
        />
    ) : (
        <CancelButton onClick={() => setProduct(undefined)} />
    );

    return (
        <>
            <ProductSelectSlideout onProductSelect={handleProductSelect} onCancel={onClose} />
            {product && (
                <Slideout width={600} onClose={onClose}>
                    <SlideoutHeader title={variantSelected ? 'Add Item' : 'Select variant'} />
                    <Box borderBottom paddingVertical={20} paddingHorizontal={60}>
                        <ProductOverview
                            imageSize={60}
                            product={product}
                            images={firstImage(product)}
                            showVariants
                        />
                    </Box>
                    <SlideoutContent>
                        {variantSelected ? (
                            <AddItemForm form={form} marginVertical={20} />
                        ) : (
                            <VariantsList
                                variants={product.variants}
                                onVariantClick={variant => form.setValues({ variant })}
                            />
                        )}
                    </SlideoutContent>
                    <SlideoutFooter fullWidth justify='space-between'>
                        {cancelButton}
                        <Box row gap={20}>
                            <Button
                                type='secondary'
                                label='Add second item'
                                onClick={saveAndClear}
                                disabled={!variantSelected}
                            />
                            <AsyncButton
                                label='Save'
                                onClick={saveAndClose}
                                isLoading={isLoading}
                                disabled={!variantSelected}
                            />
                        </Box>
                    </SlideoutFooter>
                </Slideout>
            )}
        </>
    );
};
