import React, { FC } from 'react';

import { emptyAdminUsersFilter, useUsersQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { UsersTable } from '../../peoplePage/users/UsersTable';

interface UsersTabProps {
    organizationId: UUID;
    onOpenUser(userId: UUID): void;
    search: string;
}

export const UsersTab: FC<UsersTabProps> = ({ organizationId, onOpenUser, search }) => {
    const { users, usersIsLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useUsersQuery({
        ...emptyAdminUsersFilter,
        search: search,
        organizations: [organizationId],
        roles: [],
        redistributableIn: null,
        includeOffboarded: true,
    });

    return (
        <UsersTable
            users={users}
            onOpenUser={onOpenUser}
            search={search}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: usersIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
        />
    );
};
