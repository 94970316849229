import React, { FC } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, Paragraph3 } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';

interface OrProps {
    text?: string;
}

export const Or: FC<OrProps> = ({ text }) => {
    const { tr } = useAuthI18n();
    return (
        <Box borderTop relative marginVertical={8}>
            <OrText color={Color.ContentTertiary}>{text || tr('auth.or')}</OrText>
        </Box>
    );
};

const OrText = styled(Paragraph3)`
    position: absolute;
    background: ${Color.BackgroundDefault};
    padding: 0 20px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
`;
