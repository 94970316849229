import { SsoType } from '@hofy/api-shared';
import { restClient } from '@hofy/rest';

export interface SsoConfigPayload {
    metadataUrl: string | null;
    enabled: boolean;
    ssoType: SsoType;
}

export const emptySsoConfigPayload: SsoConfigPayload = {
    enabled: false,
    ssoType: SsoType.Saml,
    metadataUrl: null,
};

class SsoService {
    public setSSOConfig = async (payload: SsoConfigPayload): Promise<void> => {
        return restClient.post('/api/auth/sso-configure', {
            enabled: payload.enabled,
            metadataUrl: payload.metadataUrl || null,
            ssoType: payload.ssoType,
        });
    };
}

export const ssoService = new SsoService();
