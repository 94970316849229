import React, { FC } from 'react';

import { Box, BoxProps } from '@hofy/ui';

export const Footer: FC<BoxProps> = ({ children, ...rest }) => {
    return (
        <Box row fullWidth justify='space-between' height={80} {...rest} paddingHorizontal={40} borderTop>
            {children}
        </Box>
    );
};
