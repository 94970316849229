import { useQuery } from '@tanstack/react-query';

import { suppliersService } from '../service/suppliersService';
import { suppliersCacheKey } from './cacheKey';

export const useSuppliersRefsQuery = () => {
    const { isLoading, data } = useQuery({
        queryKey: [suppliersCacheKey],
        queryFn: suppliersService.getSuppliersRefs,
    });
    return {
        isLoading,
        data,
    };
};
