import { xor } from 'lodash';
import React, { FC } from 'react';

import { BillingEntityDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { Paragraph3, SvgIcon } from '@hofy/ui';

import { SearchableBlockFilter } from '../../design/blockFilters/SearchableBlockFilter';

interface BillingEntityBlockFilterProps {
    selected?: UUID[];
    billingEntities: BillingEntityDto[];
    onChange(ids: UUID[]): void;
    search: string;
    onSearchChange(query: string): void;
}

export const BillingEntityBlockFilter: FC<BillingEntityBlockFilterProps> = ({
    selected,
    billingEntities,
    onChange,
    search,
    onSearchChange,
}) => {
    return (
        <SearchableBlockFilter
            title='Billing Entity'
            icon={SvgIcon.Building}
            items={billingEntities}
            isSelected={b => !!selected && selected.some(id => id === b.id)}
            onFilterClick={b => onChange(xor(selected, [b.id]))}
            renderItem={b => b.name}
            searchPlaceholder='Search billing entity'
            search={search}
            onSearch={onSearchChange}
            dropdownBottomSlot={
                <Paragraph3 padding={5} paddingLeft={16} color={Color.ContentTertiary}>
                    Search to find other entries
                </Paragraph3>
            }
        />
    );
};
