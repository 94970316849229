import { ColorDye, getEnumValues } from '@hofy/global';

import { AssignmentStatus } from './AssignmentStatus';

export enum ShipmentType {
    Delivery = 'delivery',
    Collection = 'collection',
    Transfer = 'transfer',
    Dropshipping = 'dropshipping',
}

export const shipmentTypeColors: Record<ShipmentType, ColorDye> = {
    [ShipmentType.Delivery]: 'blue',
    [ShipmentType.Collection]: 'grape',
    [ShipmentType.Transfer]: 'teal',
    [ShipmentType.Dropshipping]: 'blue',
};

export const shipmentTypeAssignmentStatus: Record<ShipmentType, AssignmentStatus[]> = {
    [ShipmentType.Delivery]: [AssignmentStatus.DeliveryPending],
    [ShipmentType.Collection]: [AssignmentStatus.CollectionPending],
    [ShipmentType.Transfer]: [
        AssignmentStatus.WithUser,
        AssignmentStatus.CollectionPending,
        AssignmentStatus.DeliveryPending,
    ],
    [ShipmentType.Dropshipping]: [AssignmentStatus.DeliveryPending],
};

export const allShipmentTypes = getEnumValues<ShipmentType>(ShipmentType);
