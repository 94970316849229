import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentService, CheckInItemPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { adminCheckInItemQueryKey } from './adminCheckInItemQueryKey';

export const useCheckInMutation = (assignmentId: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (v: CheckInItemPayload) => assignmentService.checkInItem(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [adminCheckInItemQueryKey] });
            onSuccess?.();
        },
    });
};
