import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';

import { emptyProductPayload, productCacheKey, productsService } from '@hofy/api-admin';
import { useToast } from '@hofy/ui';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { ProductDetailsTab } from './types/ProductDetailsTab';
import { useProductForm } from './useProductForm';

export const useCreateProduct = () => {
    const { showToast } = useToast();
    const history = useHistory();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: productsService.createProduct,
        onSuccess: id => {
            queryClient.invalidateQueries({ queryKey: [productCacheKey] });
            showToast({
                type: 'positive',
                message: 'Product created',
            });
            history.replace(`${AdminNavLink.Products}/${id}/${ProductDetailsTab.Details}`);
        },
    });

    const formState = useProductForm(mutation.mutate, emptyProductPayload);

    return {
        ...formState,
        isLoadingMutation: mutation.isPending,
    };
};
