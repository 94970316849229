import React, { forwardRef, ReactNode } from 'react';

import { SeparatedContainer } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, ExpandHeight, HiddenHorizontalScroll } from '@hofy/ui';

interface BlockFilterContainerProps {
    children?: ReactNode;
    show?: boolean;
}

export const BlockFilterContainer = forwardRef<HTMLDivElement, BlockFilterContainerProps>(
    ({ children, show }, ref) => {
        return (
            <Box bg={Color.BackgroundSubtleNeutral} ref={ref}>
                <ExpandHeight>
                    {show && (
                        <Box borderBottom>
                            <HiddenHorizontalScroll height={315} paddingVertical={12} paddingHorizontal={8}>
                                <SeparatedContainer
                                    orientation='horizontal'
                                    lined
                                    spacing={12}
                                    paddingHorizontal={20}
                                    alignItems='stretch'
                                    fullHeight
                                >
                                    {children}
                                </SeparatedContainer>
                            </HiddenHorizontalScroll>
                        </Box>
                    )}
                </ExpandHeight>
            </Box>
        );
    },
);
