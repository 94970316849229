import React, { FC } from 'react';

import { ShipmentNotificationPayload } from '@hofy/api-admin';
import { FormTextarea, Switch } from '@hofy/common';
import { UseForm } from '@hofy/ui';

interface SendNotificationFormProps {
    form: UseForm<ShipmentNotificationPayload>;
    switchLabel?: string;
    textareaLabel?: string;
}

export const SendNotificationForm: FC<SendNotificationFormProps> = ({
    form,
    switchLabel = 'Send notification',
    textareaLabel = 'Processed notification notes',
}) => {
    const { values } = form;

    return (
        <>
            <Switch
                label={switchLabel}
                checked={values.sendNotification}
                onChange={() => form.setValues({ sendNotification: !values.sendNotification })}
            />
            <FormTextarea
                label={textareaLabel}
                placeholder='Write notification notes...'
                value={values.deliveryNotes}
                rows={5}
                onChangeText={deliveryNotes => form.setValues({ deliveryNotes })}
            />
        </>
    );
};
