import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { AdminPurchaseOrdersFilter, usePurchaseOrders } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { AdminNavLink } from '../../../../../components/routing/AdminNavLink';
import { InventoryTab } from '../../../../../store/inventory/types/InventoryTab';
import { PurchaseOrderTab } from '../../../../../store/purchaseOrders/types/PurchaseOrderTab';
import { PurchaseOrderTable } from '../../../purchaseOrders/components/table/PurchaseOrderTable';

interface PurchaseOrdersTabProps {
    filters: AdminPurchaseOrdersFilter;
}

export const SupplierOrdersTab: FC<PurchaseOrdersTabProps> = ({ filters }) => {
    const history = useHistory();

    const { purchaseOrders, purchaseOrdersIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        usePurchaseOrders(filters);

    const onOpenPurchaseOrder = (orderId: UUID) =>
        history.push(
            `${AdminNavLink.Inventory}/${InventoryTab.PurchaseOrders}/${orderId}/${PurchaseOrderTab.Details}`,
        );

    return (
        <PurchaseOrderTable
            purchaseOrders={purchaseOrders}
            onOpenPurchaseOrder={onOpenPurchaseOrder}
            infinityScroll={{
                hasMore: hasNextPage,
                isLoading: purchaseOrdersIsLoading,
                isLoadingMore: isFetchingNextPage,
                loadMore: fetchNextPage,
            }}
        />
    );
};
