import React, { FC } from 'react';

import { PurchaseOrderItemDto } from '@hofy/api-admin';

import { PurchaseOrderItemsTable } from './components/PurchaseOrderItemsTable';

interface PurchaseOrderItemsTabProps {
    purchaseOrderItems: PurchaseOrderItemDto[];
}

export const PurchaseOrderItemsTab: FC<PurchaseOrderItemsTabProps> = ({ purchaseOrderItems }) => {
    return (
        <PurchaseOrderItemsTable
            entries={purchaseOrderItems}
            infinityScroll={{
                hasMore: false,
                isLoading: false,
                isLoadingMore: false,
                loadMore: () => {},
            }}
        />
    );
};
