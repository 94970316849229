import React, { FC } from 'react';
import styled from 'styled-components';

import { Icon, Span, SvgIcon, Tooltip } from '@hofy/ui';

import { PairsTableTooltipOverlay } from '../../../../components/design/tooltips/PairsTableTooltipOverlay';

interface PriceBreakdownHintTooltipProps {
    label: string;
}

export const PriceBreakdownHintTooltip: FC<PriceBreakdownHintTooltipProps> = ({ label }) => {
    return (
        <Tooltip
            placement='left'
            bodySlot={
                <PairsTableTooltipOverlay
                    items={[
                        ['Base price', 'The base price of the variant'],
                        ['Unit price', 'Base price * Country multiplier + Store Fee'],
                        ['Price', 'Unit price - Discounts'],
                    ]}
                />
            }
            maxWidth='auto'
            interactive
            asChild
        >
            <Span>
                {label}
                <AlignedIcon marginLeft={5} size={16} svg={SvgIcon.InfoCircle} />
            </Span>
        </Tooltip>
    );
};

const AlignedIcon = styled(Icon)`
    vertical-align: middle;
`;
