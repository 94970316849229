import { motion } from 'framer-motion';
import React, { FC, ReactNode, useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

import { Palette } from '@hofy/theme';

import { easeInOutTransition } from '../../../config/animations';
import { TestKeyAware } from '../../../types/index';
import { Box } from '../../base';
import { NavigationColors } from '../NavigationColors';
import { useNavigationContext } from '../NavigationContext';

interface NavigationSelectProps extends TestKeyAware {
    children: ReactNode;
    isOpen: boolean;
    setIsOpen(v: boolean): void;
    height: number;
    expandDirection?: 'up' | 'down';
}

export const NavigationSelect: FC<NavigationSelectProps> = ({
    testKey,
    expandDirection = 'up',
    height,
    children,
    isOpen,
    setIsOpen,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const { collapsed } = useNavigationContext();

    useClickAway(wrapperRef, () => {
        setIsOpen(false);
    });

    const backgroundColor =
        collapsed && isOpen ? NavigationColors.ProfileBackgroundOverlay : NavigationColors.ProfileBackground;

    return (
        <Box ref={wrapperRef} relative height={height} data-test-key={testKey}>
            <InnerBox
                expandDirection={expandDirection}
                as={motion.div}
                initial={{ width: 'auto', backgroundColor }}
                animate={{ width: collapsed && isOpen ? 220 : 'auto', backgroundColor }}
                transition={easeInOutTransition}
                border
                borderColor={Palette.white['-7']}
                rounded
                overflow='hidden'
            >
                {children}
            </InnerBox>
        </Box>
    );
};

const InnerBox = styled(Box)<{ expandDirection: 'up' | 'down' }>`
    position: absolute;
    bottom: ${p => (p.expandDirection === 'up' ? 0 : 'auto')};
    top: ${p => (p.expandDirection === 'down' ? 0 : 'auto')};
    left: 0;
    right: 0;
    z-index: 2;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
`;
