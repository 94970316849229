import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { PurchaseOrderItemDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, Icon, Paragraph3, Rotated, SvgIcon } from '@hofy/ui';

import { PurchaseOrderItemDescription, PurchaseOrderItemHeader } from './PurchaseOrderItemTypography';

interface PurchaseOrderItemBoxProps {
    item: PurchaseOrderItemDto;
    onItemToScanSelect(itemToScan: PurchaseOrderItemDto): void;
}

export const PurchaseOrderItemBox: FC<PurchaseOrderItemBoxProps> = ({ item, onItemToScanSelect }) => {
    const isLineAllowedToReceive = useMemo(() => {
        return item && item.receivedQuantity !== item.quantity;
    }, [item]);

    return (
        <Wrapper
            onClick={isLineAllowedToReceive ? () => onItemToScanSelect(item) : () => {}}
            pointer={isLineAllowedToReceive}
            marginVertical={10}
            paddingVertical={20}
            paddingHorizontal={20}
        >
            <Box fullWidth row justify='space-between'>
                <Box>
                    <PurchaseOrderItemHeader text={item.variant.product.name} />
                    <PurchaseOrderItemDescription text={`SKU: ${item.variant.sku}`} />
                    <PurchaseOrderItemDescription text={`Supplier code: ${item.supplierCode}`} />
                </Box>
                <Box row gap={16}>
                    <Box>
                        <PurchaseOrderItemHeader text={item.quantity} />
                        <PurchaseOrderItemDescription text='Ordered' />
                    </Box>
                    <Box>
                        <PurchaseOrderItemHeader
                            text={
                                !isLineAllowedToReceive ? (
                                    <Icon svg={SvgIcon.CheckCircle} color={Color.HighlightPositiveSelected} />
                                ) : (
                                    item.receivedQuantity
                                )
                            }
                        />
                        <PurchaseOrderItemDescription text='Received' />
                    </Box>
                    <Rotated angle={90}>
                        <Icon svg={SvgIcon.ChevronUp} />
                    </Rotated>
                </Box>
            </Box>
            <Box>
                <Paragraph3>Comments</Paragraph3>
                <Paragraph3>{item.notes || '--'}</Paragraph3>
            </Box>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    background-color: ${Color.BackgroundSubtleNeutral};
`;
