import React, { FC, ReactNode } from 'react';

import { Color, FontWeight, ThemeFontSize } from '@hofy/theme';
import { Paragraph3, TestKeyAware } from '@hofy/ui';

import { Checkbox } from './checkbox/Checkbox';

interface FormCheckboxProps extends TestKeyAware {
    checked: boolean;
    isError?: boolean | string;
    fontSize?: ThemeFontSize;
    fontWeight?: FontWeight;
    fontColor?: Color;
    disabled?: boolean;
    onChange(v: boolean): void;
    children?: ReactNode;
}

/** @deprecated use `LabeledCheckbox` or `FormCheckbox` from `@hofy/ui` instead */
export const FormCheckbox: FC<FormCheckboxProps> = ({
    isError,
    fontSize = 'paragraph3',
    fontWeight,
    fontColor,
    children,
    checked,
    onChange,
    disabled,
    testKey,
}) => {
    return (
        <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            isError={!!isError}
            testKey={testKey}
        >
            <Paragraph3
                size={fontSize}
                weight={fontWeight}
                color={isError ? Color.FoundationNegative : fontColor || Color.Neutral500}
            >
                {children}
            </Paragraph3>
        </Checkbox>
    );
};
