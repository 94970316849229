import { useQuery } from '@tanstack/react-query';

import { SupplierType } from '@hofy/api-shared';
import { Country } from '@hofy/global';

import { hofySubsidiariesService } from '../service/hofySubsidiariesService';
import { hofySubsidiaryCacheKey } from './hofySubsidiaryCacheKey';

export const useHofySubsidiaryQuery = (country?: Country, supplierType?: SupplierType) => {
    const { data, isLoading } = useQuery({
        queryKey: [hofySubsidiaryCacheKey, country, supplierType],

        queryFn: () => {
            if (supplierType && supplierType === SupplierType.NonCoreGeography) {
                return hofySubsidiariesService.getDefaultSubsidiary();
            }

            return hofySubsidiariesService.getHofySubsidiaryForCountry(country!);
        },

        enabled: !!country,
    });

    return {
        isLoading,
        data: data || null,
    };
};
