import styled from 'styled-components';

import { Box } from '@hofy/ui';

interface GridWrapperProps {
    columns: number;
    gap?: number;
}

export const GridWrapper = styled(Box)<GridWrapperProps>`
    display: grid;
    grid-gap: ${p => p.gap || 16}px;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
`;
