import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { RevenueRecognitionDetailDto } from './types/RevenueRecognitionDto';
import { RevenueRecognitionFilters } from './types/RevenueRecognitionFilters';

class RevenueRecognitionService {
    public listRevenueRecognition = async (
        filters: RevenueRecognitionFilters,
        page: PageRequest,
    ): Promise<PageResponse<RevenueRecognitionDetailDto>> => {
        return restClient.getJson<PageResponse<RevenueRecognitionDetailDto>>(
            stringifyUrl({
                url: '/api/admin/revenue-recognition',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public downloadContractReport = async (filters: RevenueRecognitionFilters): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: '/api/admin/revenue-recognition/report',
                    query: {
                        ...filters,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };
}

export const revenueRecognitionService = new RevenueRecognitionService();
