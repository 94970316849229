import { useQuery } from '@tanstack/react-query';

import { invoiceEntryService } from '../service/invoiceEntryService';
import { InvoiceEntryFilterQuery } from '../service/types/InvoiceEntryFilterQuery';
import { invoiceEntriesQueryKey } from './invoiceEntriesQueryKey';

export const useInvoiceEntriesWithTaxErrorsCount = (filter?: InvoiceEntryFilterQuery) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [invoiceEntriesQueryKey, filter],
        queryFn: () => invoiceEntryService.getInvoiceEntriesCountWithTaxErrors(filter),
    });

    return {
        count: data,
        isLoading,
        isError,
    };
};
