import React, { FC } from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { allInspectionsTabs, InspectionsTab } from '../../../store/inspections/inspectionsTab';

interface InspectionsPageTabsProps {
    tab: InspectionsTab;
    onChange(tab: InspectionsTab): void;
}

export const InspectionPageTabs: FC<InspectionsPageTabsProps> = ({ tab, onChange }) => {
    return (
        <Tabs active={tab} onChange={onChange}>
            {allInspectionsTabs.map(tab => (
                <Tab key={tab} id={tab} label={inspectionsPageTabLabels[tab]} />
            ))}
        </Tabs>
    );
};

const inspectionsPageTabLabels: Record<InspectionsTab, string> = {
    [InspectionsTab.Inspections]: 'Inspections',
    [InspectionsTab.DeviceChecks]: 'Device checks',
};
