import { createContext, useContext } from 'react';

import {
    hasAnyPermission,
    hasPermission,
    hasRole,
    isFinanceManagerOnly,
    isManager,
    Permission,
    Role,
    roleRequiredForPermission,
} from '@hofy/api-shared';
import { asArray } from '@hofy/helpers';

import { emptyPermissionState } from './PermissionState';

export const PermissionContext = createContext(emptyPermissionState);

export const usePermission = () => {
    const { roles, permissions, roleMapping } = useContext(PermissionContext);
    return {
        roles,
        isManager: isManager(roles),
        hasRole: (r: Role) => hasRole(roles, r),
        isFinanceManagerOnly: isFinanceManagerOnly(roles),
        managerPiiAccess: hasPermission(permissions, Permission.UsersPiiView),
        hasPermission: (p: Permission) => hasPermission(permissions, p),
        hasAnyPermission: (requiredPermissions: Permission[]) =>
            hasAnyPermission(permissions, requiredPermissions),
        roleRequiredForPermission: (v: Permission | Permission[]) =>
            roleRequiredForPermission(roleMapping, asArray(v), roles || []),
    };
};
