import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { assignmentsCacheKey } from '../../assignment/store/cacheKey';
import { shipmentService } from '../service/shipmentService';
import { ListShipmentsFilter } from '../service/types/ListShipmentsFilterPayload';

export const useShipmentsQuery = (filters: ListShipmentsFilter) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [assignmentsCacheKey, filters],
        queryFn: param =>
            shipmentService.listShipments(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const shipments = useMemo(() => data?.pages.flatMap(v => v.content) || [], [data]);

    return {
        shipments,
        shipmentsIsLoading: isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
