import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { repairsService } from '../service/repairsService';
import { RepairListFilters } from '../service/types/RepairListFilters';
import { RepairListSorting } from '../service/types/RepairListSorting';
import { repairsCacheKey } from './cacheKey';

export const useListRepairsQuery = (filters: RepairListFilters, sorting: RepairListSorting) => {
    const { data, isLoading, isError, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [repairsCacheKey, filters, sorting],
        queryFn: param =>
            repairsService.listRepairs(filters, sorting, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const repairs = useMemo(() => data?.pages.flatMap(v => v.content) || [], [data]);

    return {
        repairs,
        isLoading,
        isError,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
