export const productErrorI18n = {
    'api-error.product-needs-replacement.title': 'Product needs replacement',
    'api-error.product-needs-replacement.message': 'Cannot perform this operation',
    'api-error.duplicated-variant-sku.title': 'Duplicated variant sku',
    'api-error.duplicated-variant-sku.message': 'Cannot perform this operation',
    'api-error.operating-system-not-supported.title': 'Operating system is not supported',
    'api-error.operating-system-not-supported.message': 'Cannot perform this operation',
    'api-error.category-not-configurable.title': 'Category is not configurable',
    'api-error.category-not-configurable.message': 'Cannot perform this operation',
};
