import React from 'react';

import { SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../../components/design/blockFilters/BlockFilter';
import { BlockFilterItem } from '../../../../components/design/blockFilters/BlockFilterItem';

export const IsInvoicingBlockFilter = ({ isInvoiced, onChange }: IsInvoicingBlockFilterProps) => {
    return (
        <BlockFilter title='Invoicing' icon={SvgIcon.InfoCircle}>
            <BlockFilterItem
                name='Invoiced'
                selected={isInvoiced === true}
                onClick={() => (isInvoiced === true ? onChange(null) : onChange(true))}
            />
            <BlockFilterItem
                name='To be Invoiced'
                selected={isInvoiced === false}
                onClick={() => (isInvoiced === false ? onChange(null) : onChange(false))}
            />
        </BlockFilter>
    );
};

interface IsInvoicingBlockFilterProps {
    isInvoiced: boolean | null;
    onChange(v: boolean | null): void;
}
