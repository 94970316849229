import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

export const SubstitutionIndicator: FC = () => {
    const color = Color.AccentPurple;
    return (
        <Indicator color={color}>
            <TooltipIcon icon={SvgIcon.Replace} iconColor={color} body='Product substituted' iconSize={16} />
        </Indicator>
    );
};
