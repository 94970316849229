import { ColorDye } from '@hofy/global';

export enum AuditLogOperationType {
    Init = 'init',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

export const auditLogOperationTypeStatusColor: Record<AuditLogOperationType, ColorDye> = {
    [AuditLogOperationType.Init]: 'grey',
    [AuditLogOperationType.Create]: 'green',
    [AuditLogOperationType.Update]: 'blue',
    [AuditLogOperationType.Delete]: 'red',
};
