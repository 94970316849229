import React, { FC } from 'react';

import { HofySubsidiaryRef } from '@hofy/api-shared';
import { Slideout, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';

import { useCreateUpdateHofyWarehouse } from '../../../store/warehouses/useCreateUpdateHofyWarehouse';
import { WarehouseCreateUpdateForm } from './WarehouseCreateUpdateForm';

interface CreateUpdateWarehouseSlideoutProps {
    onClose(): void;
    hofySubsidiaries: HofySubsidiaryRef[];
    warehouseId?: UUID;
}

type WarehouseAction = 'create' | 'update';

export const CreateUpdateWarehouseSlideout: FC<CreateUpdateWarehouseSlideoutProps> = ({
    onClose,
    warehouseId,
    hofySubsidiaries,
}) => {
    const { form, isLoading: isLoadingMutation } = useCreateUpdateHofyWarehouse(warehouseId);

    const action: WarehouseAction = warehouseId ? 'update' : 'create';

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title={action === 'update' ? 'Update Warehouse' : 'Create Warehouse'} />
            <WarehouseCreateUpdateForm
                form={form}
                isLoadingMutation={isLoadingMutation}
                isUpdate={action === 'update'}
                hofySubsidiaries={hofySubsidiaries}
            />
        </Slideout>
    );
};
