import React, { JSX } from 'react';

import { AnyDateString } from '@hofy/global';
import { formatDate, formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, FeaturedIcon, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

interface StatusHistoryProps<T extends string> {
    dateTimeFormat?: boolean;
    allStatuses: T[];
    dateGetter(v: T): AnyDateString | AnyDateString[] | null;
    labelFormatter(v: T): string;
}

export const StatusHistory = <T extends string>({
    dateTimeFormat = false,
    allStatuses,
    dateGetter,
    labelFormatter,
}: StatusHistoryProps<T>) => {
    const dateFormatter = dateTimeFormat ? formatDateTime : formatDate;
    const statusBlocks = allStatuses
        .map(status => {
            const dates = dateGetter(status);
            const isArray = Array.isArray(dates);
            if (!dates || (isArray && dates.length === 0)) {
                return null;
            }

            if (isArray) {
                const formattedDates = dates.map(date => dateFormatter(date)).reverse();
                return <StatusStep key={status} status={labelFormatter(status)} dates={formattedDates} />;
            }

            return <StatusStep key={status} status={labelFormatter(status)} dates={[dateFormatter(dates)]} />;
        })
        .filter((block): block is JSX.Element => block !== null);

    return (
        <Box row alignItems='flex-start' wrap rowGap={8}>
            {statusBlocks.map((block, index) => (
                <Box key={block.key} row gap={12} alignItems='flex-start'>
                    <FeaturedIcon type='positive' icon={SvgIcon.Check} marginTop={12} />
                    {block}
                    {index !== statusBlocks.length - 1 && <StatusArrow />}
                </Box>
            ))}
        </Box>
    );
};

const StatusArrow = () => (
    <Icon marginHorizontal={16} marginTop={12} svg={SvgIcon.ChevronRight} color={Color.ContentTertiary} />
);

const StatusStep = ({ status, dates }: { status: string; dates: string[] }) => (
    <Box key={status}>
        <Paragraph3>{status}</Paragraph3>
        {dates.map(date => (
            <Paragraph3 key={date} color={Color.ContentTertiary}>
                {date}
            </Paragraph3>
        ))}
    </Box>
);
