import { Price } from '@hofy/global';

import { usePrice } from './usePrice';

export const useFormatPriceByContractType = () => {
    const { formatPrice, formatMonthlyPrice } = usePrice();

    const formatAnyPrice = (
        price: Price | null | undefined,
        contractType: 'rental' | 'purchase' | 'management',
        precision: number = 2,
    ): string => {
        if (contractType === 'purchase') {
            return formatPrice(price, precision);
        }
        return formatMonthlyPrice(price, precision);
    };

    return {
        formatAnyPrice,
    };
};
