import React, { FC } from 'react';

import { SalesOrderPaymentSessionDto } from '@hofy/api-admin';
import { useTrPaymentSessionStatus } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { LabeledText } from '@hofy/ui';

interface SalesOrderPaymentSessionInfoProps {
    paymentSession: SalesOrderPaymentSessionDto;
}

export const SalesOrderPaymentSessionInfo: FC<SalesOrderPaymentSessionInfoProps> = ({ paymentSession }) => {
    const { formatPrice } = usePrice();
    const trPaymentSessionStatus = useTrPaymentSessionStatus();
    const { status, expiresAt, amount } = paymentSession;

    return (
        <>
            <LabeledText label='Status' content={trPaymentSessionStatus(status)} />
            <LabeledText label='Expires at' content={formatDateTime(expiresAt)} />
            <LabeledText label='Amount' content={formatPrice(amount)} />
        </>
    );
};
