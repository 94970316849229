import { repaymentPlanService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

import { useSinglePlanMutation } from './useSinglePlanMutation';

export const useCancelRepaymentPlan = (planId: UUID) => {
    return useSinglePlanMutation(
        planId,
        repaymentPlanService.cancel,
        'Repayment plan set to cancellation pending',
    );
};
