import React, { FC } from 'react';

import { ProductDto, VariantDetailsDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    Alert,
    Box,
    Button,
    FormContainer,
    FormInput,
    FormModal,
    FormSection,
    IconButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
    SvgIcon,
    useFormArrayField,
} from '@hofy/ui';

import { useUpdateItemCode } from '../../../store/items/useUpdateItemCode';
import { ProductVariantDetails } from '../../../views/shipmentSlideout/components/ProductVariantDetails';
import { CancelButton } from '../../design/button/CancelButton';

interface AssignNewCodeModalProps {
    product: ProductDto;
    variant: VariantDetailsDto;
    itemId: UUID;
    onCancel(): void;
    onSuccess(code: string): void;
}

export const UpdateItemCodeModal: FC<AssignNewCodeModalProps> = ({
    product,
    variant,
    itemId,
    onCancel,
    onSuccess,
}) => {
    const { form, isLoading, isError } = useUpdateItemCode(itemId, onSuccess, onCancel);
    const codes = useFormArrayField(form.fields.codes, { code: '' });
    return (
        <FormModal
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
            width={600}
        >
            <ModalHeader title='Update item code' />
            <ModalContent>
                <Box column justify='center'>
                    <Box paddingBottom={20}>
                        <ProductVariantDetails product={product} variant={variant} />
                    </Box>
                    <Alert
                        description='Codes will be updated and deleted at the same time'
                        title=' Add new item code sticker to the item and enter or scan details'
                        type='informative'
                    />

                    <Box paddingVertical={20}>
                        <FormContainer>
                            <FormSection label='Codes'>
                                {codes.fields.map(v => (
                                    <Box row key={v.key}>
                                        <FormInput
                                            autoFocus
                                            isRequired
                                            label=''
                                            placeholder='Enter or scan item code'
                                            api={v.api.code}
                                            nullable
                                            flex={1}
                                        />
                                        <Box marginLeft={20}>
                                            <IconButton
                                                icon={SvgIcon.Trash}
                                                onClick={() => codes.remove(v.key)}
                                            />
                                        </Box>
                                    </Box>
                                ))}
                            </FormSection>
                            <Button
                                label='Add new item'
                                type='secondary'
                                leftIcon={SvgIcon.Add}
                                onClick={() => codes.add()}
                            />
                        </FormContainer>
                    </Box>
                </Box>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={form.discard} />
                <SubmitButton label='Update item code' />
            </ModalFooter>
        </FormModal>
    );
};
