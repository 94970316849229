import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { Link } from '../../../components/routing/Link';
import { AdminAccountingTab } from '../AdminAccountingTab';

interface JournalLinkProps {
    id: UUID;
    children?: ReactNode;
}

const JournalLink: FC<JournalLinkProps> = ({ id, children }) => {
    return (
        <Link to={`${AdminNavLink.Accounting}/${AdminAccountingTab.Journals}?search=${id}`}>{children}</Link>
    );
};

export const JournalLinkLabel: FC<{ id: UUID | null }> = ({ id }) => {
    return id ? <JournalLink id={id}>{`#${id}`}</JournalLink> : <>--</>;
};
