import { RentalStoreAndReuseLocation } from '@hofy/api-shared';

export const storeAndReuseLocationDefaultValue = (hasZone1Country: boolean, hasOriginRegion: boolean) => {
    if (hasZone1Country && hasOriginRegion) {
        return null;
    }
    if (hasOriginRegion && !hasZone1Country) {
        return RentalStoreAndReuseLocation.Local;
    }
    if (hasZone1Country && !hasOriginRegion) {
        return RentalStoreAndReuseLocation.Zone1;
    }
    return null;
};
