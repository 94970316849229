import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UUID } from '@hofy/global';

import { subscriptionService } from '../service/subscriptionService';
import { SubscriptionDto } from '../service/types/SubscriptionDto';
import { subscriptionCacheKey } from './cacheKey';

export const useSubscriptions = (organizationId: UUID, pageSize = 50) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [subscriptionCacheKey, organizationId],
        queryFn: param =>
            subscriptionService.listSubscriptions(organizationId, {
                page: param.pageParam,
                pageSize,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const subscriptions: SubscriptionDto[] = useMemo(() => {
        return data?.pages.flatMap(v => v.content) || [];
    }, [data]);

    return {
        subscriptions,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
