import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import {
    CreateCollectionRequestPayload,
    SendCollectionRequestSurveyPayload,
} from './types/CreateCollectionRequestPayload';
import { PackagingTrackingPayload } from './types/PackagingTrackingPayload';
import { ResolveCollectionRequestLabelsAndPackagingPayload } from './types/ResolveCollectionRequestLabelsAndPackagingPayload';

class CollectionRequestService {
    public sendCollectionRequestSurvey = async (
        shipmentId: UUID,
        payload: SendCollectionRequestSurveyPayload,
    ): Promise<void> => {
        return restClient
            .post(`/api/admin/shipments/${shipmentId}/collection-request/resend`, payload)
            .then();
    };

    public createCollectionRequestSurvey = (
        shipmentId: UUID,
        payload: CreateCollectionRequestPayload,
    ): Promise<void> => {
        return restClient.postJson(`/api/admin/shipments/${shipmentId}/collection-request`, payload).then();
    };

    public updatePackagingTrackingDataForCollectionRequest = (
        shipmentId: UUID,
        collectionRequestId: UUID,
        payload: PackagingTrackingPayload,
    ): Promise<void> => {
        return restClient
            .put(
                `/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/packaging-tracking-data`,
                payload,
            )
            .then();
    };

    public resolveCollectionRequestLabelsAndPackaging = async (
        shipmentId: UUID,
        collectionRequestId: UUID,
        payload: ResolveCollectionRequestLabelsAndPackagingPayload,
    ): Promise<void> => {
        return restClient
            .put(
                `/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/resolve-packaging`,
                payload,
            )
            .then();
    };

    public escalateCollectionRequest = async (shipmentId: UUID, collectionRequestId: UUID): Promise<void> =>
        restClient
            .post(`/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/escalate`)
            .then();

    public revertEscalateCollectionRequest = async (
        shipmentId: UUID,
        collectionRequestId: UUID,
    ): Promise<void> =>
        restClient
            .post(
                `/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/revert-escalation`,
            )
            .then();

    public deferCollectionRequest = async (shipmentId: UUID, collectionRequestId: UUID): Promise<void> =>
        restClient
            .post(`/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/defer`)
            .then();

    public undoDeferCollectionRequest = async (shipmentId: UUID, collectionRequestId: UUID): Promise<void> =>
        restClient
            .post(`/api/admin/shipments/${shipmentId}/collection-request/${collectionRequestId}/undo-defer`)
            .then();
}

export const collectionRequestService = new CollectionRequestService();
