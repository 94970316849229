export const countryI18n = {
    'country.AD': 'Andorra',
    'country.AE': 'United Arab Emirates',
    'country.AF': 'Afghanistan',
    'country.AG': 'Antigua and Barbuda',
    'country.AI': 'Anguilla',
    'country.AL': 'Albania',
    'country.AM': 'Armenia',
    'country.AN': 'Netherlands Antilles',
    'country.AO': 'Angola',
    'country.AQ': 'Antarctica',
    'country.AR': 'Argentina',
    'country.AS': 'American Samoa',
    'country.AT': 'Austria',
    'country.AU': 'Australia',
    'country.AW': 'Aruba',
    'country.AX': 'Aland Islands',
    'country.AZ': 'Azerbaijan',
    'country.BA': 'Bosnia and Herzegovina',
    'country.BB': 'Barbados',
    'country.BD': 'Bangladesh',
    'country.BE': 'Belgium',
    'country.BF': 'Burkina Faso',
    'country.BG': 'Bulgaria',
    'country.BH': 'Bahrain',
    'country.BI': 'Burundi',
    'country.BJ': 'Benin',
    'country.BL': 'Saint Barthélemy',
    'country.BM': 'Bermuda',
    'country.BN': 'Brunei',
    'country.BO': 'Bolivia',
    'country.BQ': 'BES Islands',
    'country.BR': 'Brazil',
    'country.BS': 'Bahamas',
    'country.BT': 'Bhutan',
    'country.BV': 'Bouvet Island',
    'country.BW': 'Botswana',
    'country.BY': 'Belarus',
    'country.BZ': 'Belize',
    'country.CA': 'Canada',
    'country.CC': 'Cocos (Keeling) Islands',
    'country.CD': 'DR Congo',
    'country.CF': 'Central African Republic',
    'country.CG': 'Republic of Congo',
    'country.CH': 'Switzerland',
    'country.CI': "Côte d'Ivoire",
    'country.CK': 'Cook Islands',
    'country.CL': 'Chile',
    'country.CM': 'Cameroon',
    'country.CN': 'China',
    'country.CO': 'Colombia',
    'country.CR': 'Costa Rica',
    'country.CU': 'Cuba',
    'country.CV': 'Cabo Verde',
    'country.CW': 'Curaçao',
    'country.CX': 'Christmas Island',
    'country.CY': 'Cyprus',
    'country.CZ': 'Czechia',
    'country.DE': 'Germany',
    'country.DJ': 'Djibouti',
    'country.DK': 'Denmark',
    'country.DM': 'Dominica',
    'country.DO': 'Dominican Republic',
    'country.DZ': 'Algeria',
    'country.EC': 'Ecuador',
    'country.EE': 'Estonia',
    'country.EG': 'Egypt',
    'country.EH': 'Western Sahara',
    'country.ER': 'Eritrea',
    'country.ES': 'Spain',
    'country.ET': 'Ethiopia',
    'country.FI': 'Finland',
    'country.FJ': 'Fiji',
    'country.FK': 'Falkland Islands',
    'country.FM': 'Micronesia',
    'country.FO': 'Faroe Islands',
    'country.FR': 'France',
    'country.GA': 'Gabon',
    'country.GB': 'United Kingdom',
    'country.GD': 'Grenada',
    'country.GE': 'Georgia',
    'country.GF': 'French Guiana',
    'country.GG': 'Guernsey',
    'country.GH': 'Ghana',
    'country.GI': 'Gibraltar',
    'country.GL': 'Greenland',
    'country.GM': 'Gambia',
    'country.GN': 'Guinea',
    'country.GP': 'Guadeloupe',
    'country.GQ': 'Equatorial Guinea',
    'country.GR': 'Greece',
    'country.GS': 'South Georgia and the South Sandwich Islands',
    'country.GT': 'Guatemala',
    'country.GU': 'Guam',
    'country.GW': 'Guinea-Bissau',
    'country.GY': 'Guyana',
    'country.HK': 'Hong Kong, SAR China',
    'country.HM': 'Heard Island and McDonald Islands',
    'country.HN': 'Honduras',
    'country.HR': 'Croatia',
    'country.HT': 'Haiti',
    'country.HU': 'Hungary',
    'country.IC': 'Canary Islands',
    'country.ID': 'Indonesia',
    'country.IE': 'Ireland',
    'country.IL': 'Israel',
    'country.IM': 'Isle of Man',
    'country.IN': 'India',
    'country.IO': 'British Indian Ocean Territory',
    'country.IQ': 'Iraq',
    'country.IR': 'Iran',
    'country.IS': 'Iceland',
    'country.IT': 'Italy',
    'country.JE': 'Jersey',
    'country.JM': 'Jamaica',
    'country.JO': 'Jordan',
    'country.JP': 'Japan',
    'country.KE': 'Kenya',
    'country.KG': 'Kyrgyzstan',
    'country.KH': 'Cambodia',
    'country.KI': 'Kiribati',
    'country.KM': 'Comoros',
    'country.KN': 'Saint Kitts and Nevis',
    'country.KP': 'North Korea',
    'country.KR': 'South Korea',
    'country.XK': 'Kosovo',
    'country.KW': 'Kuwait',
    'country.KY': 'Cayman Islands',
    'country.KZ': 'Kazakhstan',
    'country.LA': 'Laos',
    'country.LB': 'Lebanon',
    'country.LC': 'Saint Lucia',
    'country.LI': 'Liechtenstein',
    'country.LK': 'Sri Lanka',
    'country.LR': 'Liberia',
    'country.LS': 'Lesotho',
    'country.LT': 'Lithuania',
    'country.LU': 'Luxembourg',
    'country.LV': 'Latvia',
    'country.LY': 'Libya',
    'country.MA': 'Morocco',
    'country.MC': 'Monaco',
    'country.MD': 'Moldova',
    'country.ME': 'Montenegro',
    'country.MF': 'Saint Martin',
    'country.MG': 'Madagascar',
    'country.MH': 'Marshall Islands',
    'country.MK': 'North Macedonia',
    'country.ML': 'Mali',
    'country.MM': 'Myanmar',
    'country.MN': 'Mongolia',
    'country.MO': 'Macao, SAR China',
    'country.MP': 'Northern Mariana Islands',
    'country.MQ': 'Martinique',
    'country.MR': 'Mauritania',
    'country.MS': 'Montserrat',
    'country.MT': 'Malta',
    'country.MU': 'Mauritius',
    'country.MV': 'Maldives',
    'country.MW': 'Malawi',
    'country.MX': 'Mexico',
    'country.MY': 'Malaysia',
    'country.MZ': 'Mozambique',
    'country.NA': 'Namibia',
    'country.NC': 'New Caledonia',
    'country.NE': 'Niger',
    'country.NF': 'Norfolk Island',
    'country.NG': 'Nigeria',
    'country.NI': 'Nicaragua',
    'country.NL': 'Netherlands',
    'country.NO': 'Norway',
    'country.NP': 'Nepal',
    'country.NR': 'Nauru',
    'country.NU': 'Niue',
    'country.NZ': 'New Zealand',
    'country.OM': 'Oman',
    'country.PA': 'Panama',
    'country.PE': 'Peru',
    'country.PF': 'French Polynesia',
    'country.PG': 'Papua New Guinea',
    'country.PH': 'Philippines',
    'country.PK': 'Pakistan',
    'country.PL': 'Poland',
    'country.PM': 'Saint Pierre and Miquelon',
    'country.PN': 'Pitcairn Islands',
    'country.PR': 'Puerto Rico',
    'country.PS': 'State of Palestine',
    'country.PT': 'Portugal',
    'country.PW': 'Palau',
    'country.PY': 'Paraguay',
    'country.QA': 'Qatar',
    'country.RE': 'Réunion',
    'country.RO': 'Romania',
    'country.RS': 'Serbia',
    'country.RU': 'Russia',
    'country.RW': 'Rwanda',
    'country.SA': 'Saudi Arabia',
    'country.SB': 'Solomon Islands',
    'country.SC': 'Seychelles',
    'country.SD': 'Sudan',
    'country.SE': 'Sweden',
    'country.SG': 'Singapore',
    'country.SH': 'Saint Helena',
    'country.SI': 'Slovenia',
    'country.SJ': 'Svalbard and Jan Mayen',
    'country.SK': 'Slovakia',
    'country.SL': 'Sierra Leone',
    'country.SM': 'San Marino',
    'country.SN': 'Senegal',
    'country.SO': 'Somalia',
    'country.SR': 'Suriname',
    'country.SS': 'South Sudan',
    'country.ST': 'Sao Tome & Principe',
    'country.SV': 'El Salvador',
    'country.SX': 'Sint Maarten',
    'country.SY': 'Syria',
    'country.SZ': 'Eswatini',
    'country.TC': 'Turks and Caicos Islands',
    'country.TD': 'Chad',
    'country.TF': 'French Southern Territories',
    'country.TG': 'Togo',
    'country.TH': 'Thailand',
    'country.TJ': 'Tajikistan',
    'country.TK': 'Tokelau',
    'country.TL': 'Timor-Leste',
    'country.TM': 'Turkmenistan',
    'country.TN': 'Tunisia',
    'country.TO': 'Tonga',
    'country.TR': 'Turkey',
    'country.TT': 'Trinidad and Tobago',
    'country.TV': 'Tuvalu',
    'country.TW': 'Taiwan',
    'country.TZ': 'Tanzania',
    'country.UA': 'Ukraine',
    'country.UG': 'Uganda',
    'country.US': 'United States of America',
    'country.UY': 'Uruguay',
    'country.UZ': 'Uzbekistan',
    'country.VA': 'Vatican City',
    'country.VC': 'Saint Vincent and Grenadines',
    'country.VE': 'Venezuela',
    'country.VG': 'British Virgin Islands',
    'country.VI': 'United States Virgin Islands',
    'country.VN': 'Vietnam',
    'country.VU': 'Vanuatu',
    'country.WF': 'Wallis & Futuna',
    'country.WS': 'Samoa',
    'country.YE': 'Yemen',
    'country.YT': 'Mayotte',
    'country.ZA': 'South Africa',
    'country.ZM': 'Zambia',
    'country.ZW': 'Zimbabwe',
    'country.none': '--',

    'country.shortname.US': 'USA',
    'country.shortname.VI': 'Virgin Islands',
    'country.shortname.BA': 'Bosnia & Herz.',
    'country.shortname.CF': 'Central African',
    'country.shortname.DO': 'Dominican Rep.',
    'country.shortname.HK': 'Hong Kong',
    'country.shortname.MO': 'Macao',
    'country.shortname.MP': 'N. Mariana Is.',
    'country.shortname.CG': 'Rep. of Congo',
    'country.shortname.PM': 'St Pierre & Miq.',
    'country.shortname.VC': 'St Vinc. & Gren.',
    'country.shortname.TC': 'Turks & Caicos Is.',

    'country.abbreviation.US': 'USA',
    'country.abbreviation.GB': 'UK',
};
