import React, { FC } from 'react';

import { AdminInvoiceEntriesFilters, useInvoiceEntryDownloadReport } from '@hofy/api-admin';
import { ExportButton } from '@hofy/common';

interface InvoiceEntriesDownloadReportsProps {
    filters: AdminInvoiceEntriesFilters;
}

export const InvoiceEntriesDownloadReports: FC<InvoiceEntriesDownloadReportsProps> = ({ filters }) => {
    const { downloadContractReport } = useInvoiceEntryDownloadReport();
    return <ExportButton onClick={() => downloadContractReport(filters)} />;
};
