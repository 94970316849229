import React, { ElementRef, forwardRef } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { asNullable } from '../../../helpers/String';
import { TestKeyAware } from '../../../types';
import { OuterBoxProps } from '../../base';
import { FieldContainerBox } from '../shared/FieldContainer';

interface BaseTextareaProps extends OuterBoxProps, TestKeyAware {
    rows?: number;
    onBlur?(): void;
    onFocus?(): void;

    placeholder?: string;
    disabled?: boolean;
    trim?: boolean;
    isError?: boolean;
    maxLength?: number;
}

interface TextareaOnlyStringProps extends BaseTextareaProps {
    nullable?: false;
    value: string;
    onChange(value: string): void;
}

interface TextareaNullableStringProps extends BaseTextareaProps {
    nullable: true;
    value: string | null;
    onChange(v: string | null): void;
}

export type TextareaProps = TextareaOnlyStringProps | TextareaNullableStringProps;

export const Textarea = forwardRef<ElementRef<'textarea'>, TextareaProps>(
    (
        {
            value,
            nullable,
            rows = 4,
            onChange,
            onBlur,
            onFocus,
            placeholder,
            disabled: textareaDisabled,
            trim = true,
            isError,
            maxLength,
            ...rest
        },
        ref,
    ) => {
        const disabled = useIsDisabled(textareaDisabled);
        const borderColor = isError ? Color.InteractionBorderAlert : Color.InteractionBorderNeutralNormal;

        const change = (value: string) => {
            if (nullable) {
                onChange(asNullable(value));
            } else {
                onChange(value);
            }
        };

        const blur = (value: string) => {
            if (trim) {
                change(value.trim());
            }
            onBlur?.();
        };

        return (
            <StyledTextarea
                ref={ref}
                as='textarea'
                value={value || ''}
                rows={rows}
                onChange={e => change(e.target.value)}
                onBlur={e => blur(e.target.value)}
                onFocus={() => onFocus?.()}
                placeholder={placeholder}
                inactive={disabled}
                disabled={disabled}
                fullWidth
                paddingVertical={8}
                paddingHorizontal={16}
                rounded={8}
                border
                borderColor={borderColor}
                maxLength={maxLength}
                {...rest}
            />
        );
    },
);

const StyledTextarea = styled(FieldContainerBox)`
    color: ${Color.ContentPrimary};
    font-family: inherit;
    font-size: 14px;
    &::placeholder {
        color: ${Color.ContentTertiary};
    }
`;
