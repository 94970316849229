import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { Link } from '../../components/routing/Link';

interface ContractLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const ContractLink: FC<ContractLinkProps> = ({ id, children }) => {
    return <Link to={`/contracts/${id}/details`}>{children}</Link>;
};

export const ContractLinkLabel: FC<{ id: PublicRefDto | null }> = ({ id }) => {
    return id ? <ContractLink id={id.id}>{`${id.publicId}`}</ContractLink> : <>--</>;
};
