import {
    AdminOrganizationsFilters,
    emptyAdminOrganizationsFilters,
    useAdminsRefsQuery,
} from '@hofy/api-admin';
import {
    AdminRefDto,
    OrganizationStatus,
    PlatformTier,
    Role,
    useTrOrganizationStatus,
} from '@hofy/api-shared';
import { Currency } from '@hofy/global';
import { useTrCurrency } from '@hofy/i18n';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti, useFilters } from '@hofy/ui';

import { useAdminFilterDefintion } from '../admin/filters/useAdminFilterDefintion';
import { useBooleanFilterDefinition } from '../filters/useBooleanFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useTrPlatformTier } from './useTrPlatformTier';

interface UseOrganizationFiltersProps {
    defaultValues: Partial<AdminOrganizationsFilters>;
}

export interface OrganizationsFilterContext {
    salesAdmins: AdminRefDto[];
    accountManagerAdmins: AdminRefDto[];
}

export const useOrganizationFilters = (options?: UseOrganizationFiltersProps) => {
    const search = useSearchFilterDefinition();
    const deelIt = useBooleanFilterDefinition({
        name: 'Deel',
        toLabel: value => (value ? 'Yes' : 'No'),
    });
    const statuses: FilterDefinitionMulti<OrganizationStatus> = {
        type: 'multi',
        name: 'Status',
        query: queryStringArrayParser(),
        toLabel: useTrOrganizationStatus(),
    };
    const currencies: FilterDefinitionMulti<Currency> = {
        type: 'multi',
        name: 'Currency',
        query: queryStringArrayParser(),
        toLabel: useTrCurrency(),
    };
    const platformTiers: FilterDefinitionMulti<PlatformTier> = {
        type: 'multi',
        name: 'Platform tier',
        query: queryStringArrayParser(),
        toLabel: useTrPlatformTier(),
    };
    const { data: salesAdmins } = useAdminsRefsQuery(Role.SalesAdmin, true);
    const { data: accountManagerAdmins } = useAdminsRefsQuery(Role.SuccessAdmin, true);

    const salesReps = useAdminFilterDefintion('Sales Rep', salesAdmins);
    const accountManagers = useAdminFilterDefintion('Account Managers', accountManagerAdmins);

    return useFilters<AdminOrganizationsFilters, OrganizationsFilterContext>(
        {
            search,
            isDeel: deelIt,
            statuses,
            currencies,
            platformTiers,
            salesReps,
            accountManagers,
        },
        {
            ...emptyAdminOrganizationsFilters,
            ...options?.defaultValues,
        },
        {
            salesAdmins,
            accountManagerAdmins,
        },
    );
};
