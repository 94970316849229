export enum EventType {
    ProductAdded = 'product_added',
    ProductRemoved = 'product_removed',
    CountryMultiplierChanged = 'country_multiplier_changed',
    OneOff = 'one_off',
    OrderPreparedManager = 'order_prepared_manager',
    OrderDispatchedManager = 'order_dispatched_manager',
    OrderDeliveredManager = 'order_delivered_manager',
    OrderBackorderManager = 'order_backorder_manager',
    OrderStoreAndReuseDelivery = 'order_store_and_reuse_delivery',
    OrderStoreAndReuseCollected = 'order_store_and_reuse_collected',
    OrderStoreAndReuseAvailable = 'order_store_and_reuse_available',
    OrderConfirmed = 'order_confirmed',
    OrderRequest = 'order_request',
    OrderRequestAutoApprovalDeclined = 'order_request_auto_approval_declined',
    ContractAlmostExpiringDevice = 'contract_almost_expiring_device',
    ContractAlmostExpiringAccessory = 'contract_almost_expiring_accessory',
    ManageRefreshedDevice = 'manage_refreshed_device',
    HrIntegrationIssueReceived = 'hr_integration_issue_received',
    NewHireOrderReminder = 'new_hire_order_reminder',
    PrepaidOrderPaymentReminder = 'prepaid_order_payment_reminder',
    DeviceCollection = 'device_collection',
}

export const alwaysSentEvents = [EventType.OrderStoreAndReuseDelivery, EventType.OrderStoreAndReuseCollected];
