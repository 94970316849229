import { ParentProductCategory, ProductCategory, RentalTerm } from '@hofy/api-shared';
import { DateString, Percent } from '@hofy/global';

export interface OrganizationDiscountPayload {
    discountPercent: Percent;
    productCategory: ParentProductCategory | ProductCategory | null;
    rentalTerm: RentalTerm | null;
    precedence: number;
    validStartOn: DateString | null;
    validEndOn: DateString | null;
}

export const emptyOrganizationDiscountPayload: OrganizationDiscountPayload = {
    discountPercent: '0.00',
    productCategory: null,
    rentalTerm: null,
    precedence: 0,
    validStartOn: null,
    validEndOn: null,
};
