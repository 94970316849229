import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminJournalsFilters } from './types/AdminJournalsFilters';
import { JournalDto } from './types/JournalDto';

class JournalsService {
    public listJournals = async (
        filters: AdminJournalsFilters,
        page: PageRequest,
    ): Promise<PageResponse<JournalDto>> => {
        return restClient.getJson<PageResponse<JournalDto>>(
            stringifyUrl({
                url: '/api/admin/journals',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public syncToNetsuite = (journalId: UUID) => {
        return restClient.post(`/api/admin/journals/${journalId}/sync-to-netsuite`);
    };

    public syncAllToNetsuite = () => {
        return restClient.post('/api/admin/journals/sync-to-netsuite');
    };
}

export const journalsService = new JournalsService();
