import { useRef } from 'react';
import { useKey } from 'react-use';

interface UseMetaEnterOptions {
    onActivate(event: KeyboardEvent): void;
    requireMetaKey?: boolean;
}

export const useMetaEnter = ({ onActivate, requireMetaKey }: UseMetaEnterOptions) => {
    const ref = useRef<HTMLDivElement>(null);

    useKey(event => {
        if (!ref.current) {
            throw new Error(
                '[useMetaEnter] Element `ref.current` is `null`, did you forget to pass the `ref` to the `<Form /> or `<FormSection/>`?',
            );
        }

        if (event.key !== 'Enter') {
            return false;
        }

        if ((requireMetaKey && !event.metaKey) || (!requireMetaKey && event.metaKey)) {
            return false;
        }

        const insideForm = ref.current.contains(document.activeElement);

        if (!insideForm) {
            return false;
        }

        return true;
    }, onActivate);

    return ref;
};
