import { isNil } from 'lodash';
import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3, Paragraph4 } from '../base';
import { LabelError } from './LabelError';

export interface LabeledProps extends OuterBoxProps {
    label: ReactNode;
    labelSlot?: ReactNode;
    isRequired?: boolean;
    content?: ReactNode;
    helperText?: string;
    errorMessage?: string;
    invertedColor?: boolean;
    as?: any;
    labelGap?: 0 | 4 | 8;
    direction?: 'row' | 'column';
    htmlFor?: string;
}

export const Labeled: FC<LabeledProps> = ({
    label,
    labelSlot,
    isRequired,
    content,
    helperText,
    errorMessage,
    as,
    invertedColor,
    labelGap = 8,
    direction = 'column',
    htmlFor,
    ...boxProps
}) => {
    const colorSecondary = invertedColor ? Color.ContentInvertedSecondary : Color.ContentSecondary;
    const colorTertiary = invertedColor ? Color.ContentInvertedTertiary : Color.ContentTertiary;

    const errorNode = errorMessage && <LabelError message={errorMessage} />;

    const helperNode = helperText && (
        <Paragraph4 data-test-key='labeled-help-text' color={colorTertiary}>
            {helperText}
        </Paragraph4>
    );

    return (
        <Box
            as={as}
            direction={direction}
            gap={labelGap}
            htmlFor={htmlFor}
            justify={direction === 'row' ? 'space-between' : undefined}
            {...boxProps}
            data-test-key='labeled-root'
        >
            {!!label && (
                <Paragraph3 row color={colorSecondary} data-test-key='labeled-label'>
                    {label}
                    {isRequired && '*'}
                    {labelSlot}
                </Paragraph3>
            )}
            <Box data-test-key='labeled-content'>
                {isNil(content) || content === '' ? <Paragraph3>--</Paragraph3> : content}
            </Box>
            {errorNode || helperNode}
        </Box>
    );
};
