import React, { FC } from 'react';

import { firstImage, ProductDto, VariantDto } from '@hofy/api-admin';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Icon, SvgIcon } from '@hofy/ui';

import { ProductOverview } from '../ProductOverview';

interface ProductRowProps extends BoxProps {
    product: ProductDto;
    borderBottom?: boolean;
    selectedVariant?: VariantDto;
    selected?: boolean;
    onClick(): void;
}

export const ProductRow: FC<ProductRowProps> = ({
    product,
    selectedVariant,
    selected = false,
    borderBottom = false,
    onClick,
    paddingLeft = 'mainMarginHorizontal',
    ...boxProps
}) => {
    const variant = product.variants.find(v => v.id === selectedVariant?.id);
    return (
        <Box
            row
            pointer
            paddingVertical={10}
            borderBottom={borderBottom}
            onClick={onClick}
            paddingLeft={paddingLeft}
            bg={selected ? Color.BackgroundSubtlePositive : undefined}
            {...boxProps}
        >
            <Box flex={1}>
                <ProductOverview
                    inactive={!product.isActive}
                    imageSize={60}
                    product={product}
                    images={variant?.image || firstImage(product)}
                />
            </Box>
            {selected && (
                <Icon svg={SvgIcon.Check} size={24} color={Color.ContentPositive} marginRight={20} />
            )}
        </Box>
    );
};
