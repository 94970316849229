import React, { FC } from 'react';

import { InvoiceNotificationDto } from '@hofy/api-admin';
import { formatDateTime } from '@hofy/helpers';
import { BooleanBadge, Box, FormGridRow, FormSection, Labeled, LabeledText } from '@hofy/ui';

import { AttachmentCell } from '../../../../../components/domain/invoicing/AttachmentCell';
import { useDownloadInvoice } from '../../../../../store/invoices/useDownloadInvoice';

interface InvoiceCommunicationsSectionProps {
    notifications: InvoiceNotificationDto;
}

export const InvoiceCommunicationsSection: FC<InvoiceCommunicationsSectionProps> = ({ notifications }) => {
    const { download, isDownloadInvoiceLoading } = useDownloadInvoice();

    return (
        <FormSection label='Communication'>
            <Box row>
                <Box column flex={4} rowGap={24}>
                    <FormGridRow columns={3}>
                        <Labeled
                            label='Attachment'
                            content={
                                <AttachmentCell
                                    files={notifications.files}
                                    isDownloading={isDownloadInvoiceLoading}
                                    onDownload={download}
                                />
                            }
                        />
                        <Labeled
                            label='Email sent'
                            content={<BooleanBadge value={!!notifications.firstSentAt} offColor='grey' />}
                        />
                        <LabeledText label='Emailed at' content={formatDateTime(notifications.firstSentAt)} />
                        <Labeled
                            label='Payment reminder sent'
                            content={<BooleanBadge value={!!notifications.lastReminderAt} offColor='grey' />}
                        />
                        <LabeledText
                            label='Payment reminder at'
                            content={formatDateTime(notifications.lastReminderAt)}
                        />
                    </FormGridRow>
                </Box>
                <Box row flex={1} minWidth='200px' />
            </Box>
        </FormSection>
    );
};
