import React, { ElementRef, forwardRef, ReactNode, useId } from 'react';

import { useIsDisabled } from '../../../contexts';
import { ToggleLabel } from '../shared/ToggleLabel';
import { Switch, SwitchProps } from './Switch';

export interface LabeledSwitchProps extends SwitchProps {
    label: ReactNode;
    errorMessage?: string;
    helperText?: string;
    isRequired?: boolean;
}

export const LabeledSwitch = forwardRef<ElementRef<'div'>, LabeledSwitchProps>(
    (
        {
            label,
            errorMessage,
            helperText,
            isRequired,

            checked,
            onChange,
            onFocus,
            onBlur,
            disabled: switchDisabled,
            size = 'normal',
            ...rest
        },
        ref,
    ) => {
        const id = useId();
        const disabled = useIsDisabled(switchDisabled);

        return (
            <ToggleLabel
                label={label}
                errorMessage={errorMessage}
                helperText={helperText}
                disabled={disabled}
                isRequired={isRequired}
                id={id}
                {...rest}
                data-test-key='labeled-switch'
            >
                <Switch
                    ref={ref}
                    checked={checked}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    disabled={disabled}
                    size={size}
                    id={id}
                />
            </ToggleLabel>
        );
    },
);
