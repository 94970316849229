import React, { FC } from 'react';

import { ShipmentTrackingUpdateDto, useTrShipmentTrackingStatus } from '@hofy/api-shared';
import { formatDate, formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Divider, Paragraph3, Paragraph4, SectionTitle3 } from '@hofy/ui';

export const ShipmentTrackingUpdateTable: FC<{
    updates: ShipmentTrackingUpdateDto[];
}> = ({ updates }) => {
    return (
        <Box column gap={8}>
            <ShipmentTrackingUpdateHeaders />
            <Divider marginHorizontal={6} />
            {updates.map((update, index) => (
                <ShipmentTrackingUpdate key={index} trackingUpdate={update} />
            ))}
        </Box>
    );
};

const ShipmentTrackingUpdateHeaders: FC = () => (
    <Box row flex={1} gap={5}>
        <Box column width={150} gap={2}>
            <SectionTitle3>On time status</SectionTitle3>
            <Paragraph4 italic>Est. arrival date</Paragraph4>
        </Box>
        <Box column flex={2} gap={2}>
            <SectionTitle3>Message</SectionTitle3>
            <Paragraph4 italic>Tracking location</Paragraph4>
        </Box>
        <Box column flex={1} gap={2}>
            <SectionTitle3>Status</SectionTitle3>
            <Paragraph4 italic>Updated at</Paragraph4>
        </Box>
    </Box>
);

interface ShipmentTrackingUpdateProps {
    trackingUpdate: ShipmentTrackingUpdateDto;
}

const ShipmentTrackingUpdate: FC<ShipmentTrackingUpdateProps> = ({ trackingUpdate }) => {
    const trTrackingStatus = useTrShipmentTrackingStatus();
    return (
        <Box row flex={1} gap={5} marginVertical={5}>
            <Box width={150} column gap={2}>
                {trackingUpdate.isLate ? <Paragraph3>Late</Paragraph3> : <Paragraph3>On time</Paragraph3>}
                <Paragraph4 italic color={Color.ContentTertiary}>
                    {formatDate(trackingUpdate.estimatedDateOfArrival)}
                </Paragraph4>
            </Box>
            <Box flex={2} column gap={2}>
                <Paragraph3>{trackingUpdate.message}</Paragraph3>

                <Paragraph4 italic color={Color.ContentTertiary}>
                    {trackingUpdate.trackingLocation}
                </Paragraph4>
            </Box>
            <Box flex={1} column gap={2}>
                <Paragraph3>{trTrackingStatus(trackingUpdate.status)}</Paragraph3>
                <Paragraph4 italic color={Color.ContentTertiary}>
                    {formatDateTime(trackingUpdate.updatedAt)}
                </Paragraph4>
            </Box>
        </Box>
    );
};
