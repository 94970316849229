import { UUID } from '@hofy/global';

import { ActorType } from './ActorType';

// Based on Actor from modules/auth/actor.go
// eslint-disable-next-line hofy-lint/dto
export interface ActorDto {
    id: UUID;
    actorType: ActorType;
    name: string;
    userId: UUID | null;
    adminId: UUID | null;
}

export const formatActorName = (actor: ActorDto | null) => {
    if (actor === null) {
        return '--';
    }
    return actor.name;
};
