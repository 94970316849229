import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminPayoutsFilters } from './types/AdminPayoutsFilters';
import { PayoutDto } from './types/PayoutDto';
import { UploadTwoPayoutsPayload } from './types/UploadTwoPayoutsPayload';

class PayoutService {
    public listPayouts = (
        filters: AdminPayoutsFilters,
        page: PageRequest,
    ): Promise<PageResponse<PayoutDto>> => {
        return restClient.getJson(
            stringifyUrl({
                url: `/api/admin/payouts`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public uploadTwoPayouts = async (payload: UploadTwoPayoutsPayload): Promise<void> => {
        await restClient.post('/api/admin/payouts/two-upload', payload);
    };
}

export const payoutService = new PayoutService();
