export const shipmentTrackingErrorI18n = {
    'api-error.invalid-shipment-tracking-reference.title': 'Shipment tracking reference is required',
    'api-error.invalid-shipment-tracking-reference.message': 'Cannot perform this operation',
    'api-error.could-not-enable-shipment-tracking.title': 'Could not enable shipment tracking',
    'api-error.could-not-enable-shipment-tracking.message': 'Cannot perform this operation',
    'api-error.could-not-enable-shipment-tracking-on-aftership.title':
        'Aftership rejected enable shipment tracking',
    'api-error.could-not-enable-shipment-tracking-on-aftership.message': 'Cannot perform this operation',
    'api-error.shipment-tracking-already-enabled.title': 'Shipment tracking already enabled',
    'api-error.shipment-tracking-already-enabled.message': 'Cannot perform this operation',
};
