import React, { FC } from 'react';

import { SalesOrderRequestDto } from '@hofy/api-admin';
import { usePrice } from '@hofy/hooks';
import { FallbackCategoryImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import {
    Box,
    FallbackImage,
    InfoTooltipIcon,
    Labeled,
    LabeledText,
    Link,
    Paragraph3,
    Paragraph4,
    Tooltip,
} from '@hofy/ui';

import { AddressTooltipOverlay } from '../../../../components/domain/address/AddressTooltipOverlay';
import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { LabeledUserLink } from '../../../peoplePage/users/UserLink';

interface SalesOrderPaymentRequestProps {
    request: SalesOrderRequestDto;
}

export const SalesOrderRequestInfo: FC<SalesOrderPaymentRequestProps> = ({ request }) => {
    const { formatPrice } = usePrice();
    const { publicId, id, deliveryFee, expressDeliveryFee, deliveryAddress, user, items } = request;

    return (
        <>
            <Labeled
                label='Public id'
                content={<Link to={`${AdminNavLink.Orders}/${id}`}>{publicId}</Link>}
            />
            <LabeledText label='Delivery fee' content={formatPrice(deliveryFee)} />
            <LabeledText label='Express delivery fee' content={formatPrice(expressDeliveryFee)} />
            <Labeled
                label='Delivery address'
                content={
                    <Box row gap={8}>
                        <Paragraph3>Address</Paragraph3>
                        <InfoTooltipIcon
                            placement='top'
                            bodySlot={<AddressTooltipOverlay address={deliveryAddress} />}
                            maxWidth='auto'
                            interactive
                        />
                    </Box>
                }
            />
            <LabeledUserLink user={user} />
            <Labeled
                label='Items'
                content={
                    <Box row gap={8}>
                        {items.map(item => (
                            <Tooltip
                                key={item.id}
                                asChild
                                interactive
                                bodySlot={
                                    <Box column gap={8}>
                                        <Labeled
                                            invertedColor
                                            label='Name'
                                            content={
                                                <Paragraph4 color={Color.ContentInvertedPrimary}>
                                                    {item.product.name}
                                                </Paragraph4>
                                            }
                                        />
                                        {item.variant.style && (
                                            <Labeled
                                                invertedColor
                                                label='Style'
                                                content={
                                                    <Paragraph4 color={Color.ContentInvertedPrimary}>
                                                        {item.variant.style}
                                                    </Paragraph4>
                                                }
                                            />
                                        )}
                                        {item.variant.size && (
                                            <Labeled
                                                invertedColor
                                                label='Size'
                                                content={
                                                    <Paragraph4 color={Color.ContentInvertedPrimary}>
                                                        {item.variant.size}
                                                    </Paragraph4>
                                                }
                                            />
                                        )}
                                    </Box>
                                }
                            >
                                <Box row bg={Color.BackgroundDefault} rect={40} border>
                                    <FallbackImage
                                        fallback={<FallbackCategoryImage category={item.product.category} />}
                                        src={item.variant.image.url}
                                    />
                                </Box>
                            </Tooltip>
                        ))}
                    </Box>
                }
            />
        </>
    );
};
