import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { forwardRef, ReactElement, ReactNode, Ref, useMemo } from 'react';

import { useBaseI18n } from '@hofy/i18n';
import { Color } from '@hofy/theme';

import { renderTextNode } from '../../../helpers/React';
import { ReactNodeFunction, renderChildren } from '../../../types';
import { Box, OuterBoxProps, Paragraph3 } from '../../base';
import { useInteractiveListSearch } from '../../dropdown/hooks/useInteractiveListSearch';
import { DropdownContentScrollable } from '../../dropdown/shared/DropdownContentScrollable';
import { DropdownInteractiveListItem } from '../../dropdown/shared/DropdownInteractiveListItem';
import {
    InteractiveListBase,
    NormalInteractiveList,
    NullableInteractiveList,
} from '../../dropdown/types/InteractiveListTypes';
import { Spinner } from '../../loader';
import { Input } from '../input/Input';
import { SelectFieldContainer } from '../shared/FieldContainer';

interface SelectSearchPropsBaseProps<T> extends InteractiveListBase<T>, OuterBoxProps {
    fixedOptions?: NonNullable<T>[];
    optionsLengthToRender?: number;
    searchPlaceholder?: string;
    dropdownBottomSlot?: ReactNode | ReactNodeFunction<[{ closeDropdown(): void }]>;
    onFocus?(): void;
    onBlur?(): void;
    onSearchChange?(v: string): void;
    isLoadingSearch?: boolean;
    loadingSearchPlaceHolder?: string;
    placeholder?: string;
    isError?: boolean;
    id?: string;
}

export interface NormalSelectSearchProps<T> extends SelectSearchPropsBaseProps<T>, NormalInteractiveList<T> {}
export interface NullableSelectSearchProps<T>
    extends SelectSearchPropsBaseProps<T>,
        NullableInteractiveList<T> {}

type SelectSearchProps<T> = NormalSelectSearchProps<T> | NullableSelectSearchProps<T>;

const SelectSearchComponent = <T,>(
    {
        value,
        options,
        fixedOptions,
        optionsLengthToRender,
        searchPlaceholder,
        dropdownBottomSlot,
        contentMaxHeight,
        onChange,
        onSearchChange,
        isLoadingSearch,
        loadingSearchPlaceHolder,
        onFocus,
        onBlur,
        toText,
        toKey,
        toLabel,
        disabled: selectDisabled,
        placeholder,
        nullable,
        isError,
        id,
        ...rest
    }: SelectSearchProps<T>,
    ref: Ref<HTMLDivElement>,
) => {
    const { tr } = useBaseI18n();
    const allOptions = useMemo(() => {
        if (fixedOptions) {
            return [...fixedOptions, ...options];
        }
        return options;
    }, [fixedOptions, options]);

    const state = useInteractiveListSearch({
        options: allOptions,
        toText,
        toKey,
        toLabel,
        value,
        onChange,
        contentMaxHeight,
        disabled: selectDisabled,
        onOpenChange(isOpen: boolean) {
            if (!isOpen) {
                onBlur?.();
            }
        },
        nullable,
    });

    const {
        context,
        isOpen,
        setIsOpen,
        activeIndex,
        selectedIndex,
        refs,
        floatingStyles,
        listRef,
        disabled,
        inputProps,
        itemProps,
        floatingProps,
        referenceProps,
        getLabel,
        clear,

        search,
        handleSearch,
        searchResults,
        resultantPlacement,
    } = state;

    const renderItem = (item: NonNullable<T>, index: number): React.JSX.Element => (
        <DropdownInteractiveListItem
            key={toKey ? toKey(item) : index}
            ref={node => {
                listRef.current[index] = node;
            }}
            label={getLabel(item)}
            isActive={index === activeIndex}
            isSelected={index === selectedIndex}
            {...itemProps(item)}
        />
    );

    const selectedItemLabel = renderTextNode(getLabel(value), label => (
        <Paragraph3 textNoWrap overflow='hidden' ellipsis color={Color.ContentPrimary}>
            {label}
        </Paragraph3>
    ));
    const indexOffset = fixedOptions?.length ?? 0;

    const renderOptions = () => {
        let finalOptions = searchResults || options;
        if (optionsLengthToRender !== undefined) {
            finalOptions = finalOptions.slice(0, optionsLengthToRender);
        }
        return finalOptions.map((item, index) =>
            renderItem(item, searchResults ? index : index + indexOffset),
        );
    };

    return (
        <>
            <SelectFieldContainer
                labelledBy={id}
                isOpen={isOpen}
                isError={isError}
                hasValue={!!value}
                nullable={nullable}
                disabled={disabled}
                innerBoxProps={referenceProps({ onFocus })}
                innerBoxRef={ref}
                onClear={clear}
                setReference={refs.setReference}
                selectedItemLabel={selectedItemLabel}
                placeholder={placeholder}
                referenceProps={referenceProps()}
                {...rest}
            />

            <AnimatePresence>
                {isOpen && (
                    <FloatingPortal>
                        <FloatingFocusManager context={context} modal={false}>
                            <DropdownContentScrollable
                                ref={refs.setFloating}
                                style={floatingStyles}
                                floatingProps={floatingProps()}
                                placement={resultantPlacement}
                                topSlot={
                                    <Box column alignItems='stretch'>
                                        <Input
                                            value={search}
                                            onChange={value => {
                                                handleSearch(value);
                                                onSearchChange?.(value);
                                            }}
                                            placeholder={searchPlaceholder}
                                            inputRawProps={inputProps()}
                                            margin={8}
                                        />
                                        {!searchResults && fixedOptions && (
                                            <Box borderBottom column gap={4} padding={4}>
                                                {fixedOptions.map(renderItem)}
                                            </Box>
                                        )}
                                    </Box>
                                }
                                bottomSlot={
                                    dropdownBottomSlot && (
                                        <Box borderTop column gap={4} padding={4}>
                                            {renderChildren(dropdownBottomSlot, {
                                                closeDropdown: () => setIsOpen(false),
                                            })}
                                        </Box>
                                    )
                                }
                            >
                                {isLoadingSearch && (
                                    <Box row gap={10}>
                                        <Spinner size={16} />
                                        <Paragraph3>
                                            {loadingSearchPlaceHolder ??
                                                tr('general.placeholder-loading-search-results')}
                                        </Paragraph3>
                                    </Box>
                                )}
                                {renderOptions()}
                            </DropdownContentScrollable>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </AnimatePresence>
        </>
    );
};

export const SelectSearch = forwardRef(SelectSearchComponent) as <T>(
    props: SelectSearchProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
