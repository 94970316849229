export enum ItemError {
    CannotPurchaseNonMainItem = 'cannot_purchase_non_main_asset',
    ItemNotAssignedToWarehouse = 'item_is_not_assigned_to_warehouse',
    CreatingItemAtWarehouse = 'error_creating_item_received_at_warehouse',
    ItemHasNoCountry = 'item_has_no_country',
    ItemIsNotOrganizationOwned = 'item_does_not_belong_to_organization',
    ItemBelongsToAnotherOrganization = 'item_belongs_to_another_organization',
    ItemIsNotActive = 'item_is_not_active',
    ItemIsNotWithUser = 'item_is_not_with_user',
    ItemIsConsumable = 'item_is_consumable',
    InvalidItemStatusForRepair = 'item_has_invalid_status_for_being_repaired',
    DamagedItemWithUnresolvedRepairs = 'damaged_item_with_unresolved_repairs',
    InvalidStatusForLocation = 'invalid_status_for_location',
    InvalidStatusUpdateForCustomerOwnedAsset = 'invalid_status_update_for_customer_owned_asset',
    ItemCantBeUsedForLoanerOrStoreAndReuse = 'item_cant_be_used_for_loaner_or_store_and_reuse',
    NoWarehouseAssigned = 'no_warehouse_assigned_for_item',
    NoWarehouseAssignedForShipment = 'no_warehouse_assigned_for_item_shipment',
    NoUserAssigned = 'cannot_update_item_without_user_assigned',
    BinRequiredForCoreWarehouse = 'bin_required_for_core_warehouse',
    WarehouseNotFound = 'warehouse_not_found',
    ItemPriceZero = 'item_price_must_not_be_0',
    CreatingItemCode = 'error_creating_item_code',
    ExistingCode = 'item_code_already_exists',
    InvalidReportHeader = 'invalid_report_header',
    InvalidReportQty = 'invalid_report_qty',
    GetFixedAssets = 'error_retrieving_fixed_assets_for_item',
    NoActiveFixedAsset = 'there_are_no_active_fixed_assets',
    AddressRequiredForCollection = 'address_required_for_collection',
    DifferentUserAndContractRegion = 'different_user_and_contract_region',
    InvalidAssignedUser = 'invalid_assigned_user',
    InvalidStoreActionInvalidLocation = 'invalid_storage_location_for_store_action',
    MissingAssetAssignedUser = 'missing_asset_assigned_user',
    SuspendedOrganization = 'organization_is_suspended',
    TrialOrganization = 'organization_status_is_trial',
}
