import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { Avatar } from '../../avatar';
import { Box, BoxProps, Paragraph3, Pressable } from '../../base';
import { opacityTransition, opacityVariants } from '../components/fadeVariants';
import { ProfileChevron } from '../components/ProfileChevron';

interface NavigationProfileContentProps extends BoxProps, TestKeyAware {
    isOpen: boolean;
    onClick(): void;
    collapsed: boolean;
    name: string;
    subtitle: ReactNode;
    userId: UUID;
    pictureUrl: string | null;
}

export const NavigationProfileContent: FC<NavigationProfileContentProps> = ({
    isOpen,
    onClick,
    collapsed,
    name,
    subtitle,
    userId,
    pictureUrl,
    ...props
}) => {
    const compact = !isOpen && collapsed;

    return (
        <Pressable
            paddingVertical={8}
            paddingHorizontal={16}
            row
            gap={12}
            innerFocus
            fullWidth
            onClick={onClick}
            data-test-key='top-content'
            {...props}
        >
            <Avatar shrink={0} name={name} userId={userId} pictureUrl={pictureUrl} />
            {!compact && (
                <Box
                    column
                    flex='auto'
                    as={motion.div}
                    initial={false}
                    variants={opacityVariants}
                    transition={opacityTransition}
                >
                    <Paragraph3
                        bold
                        color={Color.ContentInvertedPrimary}
                        ellipsis
                        textNoWrap
                        overflow='hidden'
                        data-test-key='profile-user-name'
                    >
                        {name}
                    </Paragraph3>
                    <Paragraph3
                        color={Color.ContentInvertedSecondary}
                        ellipsis
                        textNoWrap
                        overflow='hidden'
                        data-test-key='profile-user-mode'
                    >
                        {subtitle}
                    </Paragraph3>
                </Box>
            )}
            <ProfileChevron isOpen={isOpen} collapsed={collapsed} compact={compact} />
        </Pressable>
    );
};
