import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useAssignmentDispose = (onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: assignmentService.disposeItem,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                title: 'Item marked as disposed',
                message: 'Item and assignments updated accordingly',
            });
            onSuccess();
        },
    });

    const dispose = (assignmentId: UUID) => {
        mutation.mutate(assignmentId);
    };

    return {
        dispose,
        isLoading: mutation.isPending,
    };
};
