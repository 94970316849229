import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { RentalTerm } from '../types/RentalTerm';

export const useTrRentalTermFull = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<RentalTerm>(RentalTerm, 'rental-term-full');
};

export const useTrRentalTerm = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<RentalTerm>(RentalTerm, 'rental-term');
};

export const useTrRentalTermShort = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<RentalTerm>(RentalTerm, 'rental-term-short');
};
