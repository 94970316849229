import React, { FC } from 'react';

import { AssignmentStatus, AssignmentStatusDatesDto } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Paragraph3 } from '@hofy/ui';

interface AssignmentDateCellProps {
    assignment: {
        statusDates: AssignmentStatusDatesDto;
    };
}

export const AssignmentDateCell: FC<AssignmentDateCellProps> = ({ assignment }) => {
    const dateSection = (label: string, status: AssignmentStatus) => {
        if (!assignment.statusDates[status]) {
            return null;
        }

        return <AssignmentDateCellDetails label={label} dateString={assignment.statusDates[status]!} />;
    };

    return (
        <Box direction='column' rowGap={5} justify='space-between'>
            {dateSection('Delivered', AssignmentStatus.WithUser)}
            {dateSection('Collected', AssignmentStatus.CollectionWithCourier)}
            {dateSection('Ended', AssignmentStatus.Collected)}
        </Box>
    );
};

interface AssignmentDateCellDetailsProps {
    label: string;
    dateString: DateString;
}

const AssignmentDateCellDetails: FC<AssignmentDateCellDetailsProps> = ({ label, dateString }) => {
    return (
        <Box column gap={2}>
            <Paragraph3 bold color={Color.Neutral700}>
                {label}:
            </Paragraph3>
            <Paragraph3>{formatDate(dateString)}</Paragraph3>
        </Box>
    );
};
