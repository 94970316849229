import React, { forwardRef, MouseEvent } from 'react';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../contexts';
import { MarginBoxProps, Pressable } from '../base';
import { Icon } from './Icon';

export interface IconButtonProps extends MarginBoxProps {
    size?: number;
    color?: Color;
    icon: Svg;
    disabled?: boolean;
    onClick?(e: MouseEvent<HTMLDivElement>): void;
    testKey?: string;
}

export const IconButton = forwardRef<any, IconButtonProps>(
    (
        {
            size = 16,
            color = Color.ContentSecondary,
            icon,
            onClick,
            disabled: iconButtonDisabled = false,
            testKey,
            ...boxProps
        },
        ref,
    ) => {
        const disabled = useIsDisabled(iconButtonDisabled);

        return (
            <Pressable
                ref={ref}
                centered
                {...boxProps}
                onClick={disabled ? undefined : onClick}
                cursor={disabled ? 'default' : 'pointer'}
                inactive={disabled}
                activeEffect
                rounded={32}
                data-test-key={testKey ? testKey : 'icon'}
            >
                <Icon svg={icon} size={size} block color={color} />
            </Pressable>
        );
    },
);
