import styled, { css } from 'styled-components';

import { Color, fontFamily } from '@hofy/theme';

export const FlatInput = styled.input<{
    paddingLeft?: number;
    paddingRight?: number;
    disabled?: boolean;
    isError?: boolean;
    bordered?: boolean;
}>`
    height: 40px;
    line-height: 40px;
    font-family: ${fontFamily};
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    padding-left: ${p => p.paddingLeft || 0}px;
    padding-right: ${p => p.paddingRight || 0}px;
    color: ${p => (p.disabled ? Color.Neutral300 : Color.Neutral500)};

    :-webkit-autofill,
    :-webkit-autofill::first-line,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        font-family: ${fontFamily} !important;
        font-weight: normal !important;
        font-size: 14px !important;
        color: ${p => (p.disabled ? Color.Neutral300 : Color.Neutral500)} !important;
    }

    ::placeholder {
        letter-spacing: 0.4px;
        color: ${Color.Neutral300};
    }

    ${p =>
        !p.isError &&
        p.bordered &&
        css`
            &:hover {
                border-color: ${Color.InteractionBorderHover};
            }
        `}

    ${p =>
        p.bordered &&
        css`
            border: 1px solid
                ${p.isError ? Color.InteractionBorderAlert : Color.InteractionBorderNeutralNormal};
            border-radius: 8px;
            transition: all 0.3s ease-in-out;
            :focus {
                outline: none;
                border-color: ${Color.InteractionFocusDefault};
                box-shadow: 0 0 0 3px ${Color.InteractionFocusDefault};
            }
        `}

    :disabled {
        background: ${Color.HighlightHover};
        border: none;
        :hover {
            box-shadow: none;
        }
    }
`;

export const FlatTextarea = styled.textarea<{
    height?: number | string;
    paddingLeft?: number;
    paddingRight?: number;
    paddingTop?: number;
    paddingBottom?: number;
    disabled?: boolean;
}>`
    font-family: ${fontFamily};
    font-weight: normal;
    font-size: 14px;
    height: ${({ height }) => (typeof height === 'number' ? `${height}px` : height)};
    width: 100%;
    padding-left: ${p => p.paddingLeft || 0}px;
    padding-bottom: ${p => p.paddingBottom || 0}px;
    padding-top: ${p => p.paddingTop || 0}px;
    padding-right: ${p => p.paddingRight || 0}px;
    color: ${p => (p.disabled ? Color.Neutral300 : Color.Neutral500)};
    border-radius: 8px;

    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    ::placeholder {
        color: ${Color.Neutral300};
    }
    :disabled {
        background: ${Color.HighlightHover};
        border: none;
        :hover {
            box-shadow: none;
        }
    }
`;

FlatInput.defaultProps = {
    bordered: true,
};
