import React, { FC } from 'react';

import { TwoCreditDecision, twoCreditDecisionColors } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrTwoCreditDecision } from '../../../../store/two/useTrTwoCreditDecision';

interface TwoCreditDecisionProps {
    creditDecision: TwoCreditDecision;
}

export const TwoCreditDecisionChip: FC<TwoCreditDecisionProps> = ({ creditDecision }) => {
    const trStatus = useTrTwoCreditDecision();
    return <Badge color={twoCreditDecisionColors[creditDecision]} label={trStatus(creditDecision)} />;
};
