import React, { FC } from 'react';

import { Alert, Box, Form, FormInput, SubmitButton } from '@hofy/ui';

import { useSeedOrganization } from '../../../../store/settings/useSeedOrganization';

export const SeedOrganization: FC = () => {
    const { form, isLoading } = useSeedOrganization();
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='Creates active organization with 3 subsidiaries and single manager account'
                />
                <FormInput label='Organization name' api={form.fields.organization} />
                <FormInput label='First name' api={form.fields.firstName} />
                <FormInput label='Last name' api={form.fields.lastName} />
                <FormInput label='Manager email' api={form.fields.email} />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
