import React, { FC } from 'react';

import { AssignmentConfigurationDto } from '@hofy/api-admin';
import { OperatingSystem, useTrOperatingSystem } from '@hofy/api-shared';
import { FormCheckbox, PasswordInput } from '@hofy/common';
import { formatDateTime } from '@hofy/helpers';
import { Box, FormGridRow, FormSection, LabeledText } from '@hofy/ui';

import { UpdateAssignmentConfigurationState } from '../../../store/assignments/useUpdateAssignmentConfiguration';
import { LabeledUserLink } from '../../peoplePage/users/UserLink';

interface ConfigurationDetailsProps {
    assignmentConfiguration: AssignmentConfigurationDto;
    operatingSystem: OperatingSystem | null;
    form: UpdateAssignmentConfigurationState['form'];
    isReadOnly: boolean;
}

export const ConfigurationDetails: FC<ConfigurationDetailsProps> = ({
    assignmentConfiguration,
    operatingSystem,
    form,
    isReadOnly,
}) => {
    const trOperatingSystem = useTrOperatingSystem();

    const isChecklistCompleted = !!form.values.checklistCompleted;
    const configPrefix = operatingSystem ? `${trOperatingSystem(operatingSystem)} ` : '';

    return (
        <Box direction='column' paddingHorizontal={40} paddingVertical={20} gap={40}>
            <FormSection label={`${configPrefix}configuration`}>
                <FormGridRow columns={2}>
                    <PasswordInput
                        label='Username'
                        value={form.values.userName || ''}
                        isError={form.errors.userName}
                        onChangeText={configuredUserName =>
                            form.setValues({
                                userName: configuredUserName,
                            })
                        }
                        isReadOnly={isReadOnly}
                    />
                    <PasswordInput
                        label='Password'
                        value={form.values.password || ''}
                        isError={form.errors.password}
                        onChangeText={configuredPassword =>
                            form.setValues({
                                password: configuredPassword,
                            })
                        }
                        isReadOnly={isReadOnly}
                    />
                </FormGridRow>
                <FormGridRow columns={1}>
                    <PasswordInput
                        label='Recovery key'
                        value={form.values.recoveryKey || ''}
                        isError={form.errors.recoveryKey}
                        onChangeText={configuredRecoveryKey =>
                            form.setValues({
                                recoveryKey: configuredRecoveryKey,
                            })
                        }
                        isReadOnly={isReadOnly}
                    />
                </FormGridRow>
                <FormGridRow columns={1}>
                    <FormCheckbox
                        checked={isChecklistCompleted}
                        onChange={isCompleted =>
                            form.setValues({
                                checklistCompleted: isCompleted,
                            })
                        }
                        disabled={isReadOnly}
                    >
                        Checklist completed
                    </FormCheckbox>
                </FormGridRow>
            </FormSection>
            <FormSection label='Auditing'>
                <FormGridRow columns={2}>
                    <LabeledUserLink label='Configured by' user={assignmentConfiguration.configuredBy} />
                    <LabeledText
                        label='Configured at'
                        content={formatDateTime(assignmentConfiguration.configuredAt)}
                    />
                </FormGridRow>
            </FormSection>
        </Box>
    );
};
