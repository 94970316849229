import { Multiplier, Percent, percentToNumber, Price, priceToNumber } from '@hofy/global';

export const usePriceManipulation = () => {
    const toFinite = (v: number) => (isFinite(v) ? (Math.floor(v * 100 + 0.5) / 100).toFixed(2) : '0.00');

    const discountedPrice = (price: Price, discount: Percent | null): Price => {
        if (!discount) {
            return price;
        }

        // Using 3 digits after decimal because we're already dealing with price in cents
        // then $4.12345 = 412.345 cents so 5 digits after decimal in dollars is the same as 3 digits after decimal for cents

        const amount = priceToNumber(price);
        const discountAmount = amount * (percentToNumber(discount) / 100);

        return {
            currency: price.currency,
            amount: toFinite(amount - discountAmount),
        };
    };

    const preDiscountedPrice = (price: Price, discount: Percent | null): Price => {
        if (!discount) {
            return price;
        }
        const amount = priceToNumber(price);
        const div = 1 - percentToNumber(discount) / 100;
        return {
            currency: price.currency,
            amount: toFinite(amount / div),
        };
    };

    const multipliedPrice = (price: Price, multiplier: Multiplier | null): Price => {
        if (!multiplier) {
            return price;
        }
        const amount = priceToNumber(price);
        return {
            currency: price.currency,
            amount: toFinite(amount * parseFloat(multiplier)),
        };
    };

    const preMultipliedPrice = (price: Price, multiplier: Multiplier | null): Price => {
        if (!multiplier) {
            return price;
        }
        const amount = priceToNumber(price);
        return {
            currency: price.currency,
            amount: toFinite(amount / parseFloat(multiplier)),
        };
    };

    const sumDiscounts = (discount1: Percent, discount2: Percent): Percent => {
        const n1 = (100 - percentToNumber(discount1)) / 100;
        const n2 = (100 - percentToNumber(discount2)) / 100;

        return toFinite((1 - n1 * n2) * 100);
    };
    return {
        discountedPrice,
        preDiscountedPrice,
        multipliedPrice,
        preMultipliedPrice,
        sumDiscounts,
    };
};
