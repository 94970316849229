import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { MarginBoxProps, Paragraph3 } from '../../base/index';

interface LabelDividerProps extends MarginBoxProps {
    children?: ReactNode;
}

export const LabelDivider: FC<LabelDividerProps> = ({ children, ...props }) => {
    return (
        <Label data-test-key='labeled-label' dividerColor={Color.NonContextualBorderDivider} {...props}>
            {children}
        </Label>
    );
};

const Label = styled(Paragraph3)<{ dividerColor: Color }>`
    overflow: hidden;

    &:after {
        content: '';
        background-color: ${p => p.dividerColor};
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 100%;
        left: 20px;
        margin-right: -50%;
    }
`;
