import React, { FC } from 'react';

import { AddressDto } from '@hofy/api-shared';
import { OuterBoxProps } from '@hofy/ui';

import { useAddressTooltipItems } from '../../../store/addresses/useAddressTooltipItems';
import { PairsTableTooltipOverlay } from '../../design/tooltips/PairsTableTooltipOverlay';

interface AddressTooltipOverlayProps extends OuterBoxProps {
    address: AddressDto;
}

export const AddressTooltipOverlay: FC<AddressTooltipOverlayProps> = ({ address, ...rest }) => {
    const tooltipItems = useAddressTooltipItems(address);

    return <PairsTableTooltipOverlay items={tooltipItems} {...rest} />;
};
