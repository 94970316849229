import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, collectionRequestService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

import { shipmentCollectionRequestQueryKey } from '../shipments/useShipmentCollectionRequestQuery';

export const useDeferCollectionRequest = (shipmentId: UUID, collectionRequestId: UUID) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    return useMutation({
        mutationFn: () => collectionRequestService.deferCollectionRequest(shipmentId, collectionRequestId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [shipmentCollectionRequestQueryKey, shipmentId] });
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Shipment has been successfully deferred.',
            });
        },
    });
};
