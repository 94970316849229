import React, { FC, useState } from 'react';

import { UUID } from '@hofy/global';
import { Button, ConfirmModal, Modals, SvgIcon } from '@hofy/ui';

import { useEscalateCollectionRequest } from '../../../store/collectionRequests/useEscalateCollectionRequest';
import { useRevertEscalateCollectionRequest } from '../../../store/collectionRequests/useRevertEscalateCollectionRequest';

enum CollectionRequestModals {
    EscalateModal = 'escalate_modal',
    DeEscalateModal = 'de_escalate_modal',
}

interface EscalateCollectionSurveyButtonProps {
    collectionRequestId: UUID;
    escalated: boolean;
    shipmentId: UUID;
}

export const EscalateCollectionSurveyButton: FC<EscalateCollectionSurveyButtonProps> = ({
    collectionRequestId,
    escalated,
    shipmentId,
}) => {
    const [showModal, setShowModal] = useState<CollectionRequestModals | null>(null);
    const { mutate: escalate, isLoading: isEscalating } = useEscalateCollectionRequest(
        shipmentId,
        collectionRequestId,
    );
    const { mutate: revertEscalation, isLoading: isReverting } = useRevertEscalateCollectionRequest(
        shipmentId,
        collectionRequestId,
    );
    return (
        <>
            {escalated ? (
                <Button
                    type='secondary'
                    leftIcon={SvgIcon.ArrowDown}
                    label='De-escalate'
                    onClick={() => setShowModal(CollectionRequestModals.DeEscalateModal)}
                />
            ) : (
                <Button
                    type='secondary'
                    leftIcon={SvgIcon.ArrowUp}
                    label='Escalate'
                    onClick={() => setShowModal(CollectionRequestModals.EscalateModal)}
                />
            )}
            <Modals>
                {showModal === CollectionRequestModals.EscalateModal && (
                    <ConfirmModal
                        keyPrefix='escalate-collection-survey-modal'
                        onClose={() => {
                            setShowModal(null);
                        }}
                        isLoading={isEscalating}
                        onConfirm={escalate}
                    />
                )}
                {showModal === CollectionRequestModals.DeEscalateModal && (
                    <ConfirmModal
                        keyPrefix='de-escalate-collection-survey-modal'
                        onClose={() => {
                            setShowModal(null);
                        }}
                        isLoading={isReverting}
                        onConfirm={revertEscalation}
                    />
                )}
            </Modals>
        </>
    );
};
