import React, { FC } from 'react';

import { PaymentProvider, paymentProviderColor } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrPaymentProvider } from '../../../../../store/payments/useTrPaymentProvider';

interface PaymentProviderChipProps {
    provider: PaymentProvider;
}

export const PaymentProviderChip: FC<PaymentProviderChipProps> = ({ provider }) => {
    const tr = useTrPaymentProvider();
    return <Badge label={tr(provider)} color={paymentProviderColor[provider]} />;
};
