import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { ApiKeyDto, useApiKeys } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { pathUuid } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { usePermission } from '@hofy/permission';
import { UUIDRoute } from '@hofy/router';
import { Box, Button, SvgIcon } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { ApiKeysPage } from './ApiKeysPage';
import { CreateApiKeySlideout } from './CreateApiKeySlideout';
import { UpdateApiKeySlideout } from './UpdateApiKeySlideout';

interface ApiKeysRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const ApiKeysRouter: FC<ApiKeysRouterProps> = ({ tab, tabs, onChangeTab }) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const { hasPermission } = usePermission();
    const { apiKeys, isKeysLoading } = useApiKeys();

    const handleAddApiKey = () => {
        history.push(`${AdminNavLink.ApiKeys}/add${history.location.search}`);
    };

    const handleEditApiKey = (key: ApiKeyDto) => {
        history.push(`${AdminNavLink.ApiKeys}/${key.id}/edit${history.location.search}`, key);
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tabs={tabs}
                tab={tab}
                onChangeTab={onChangeTab}
                titleContent={
                    <Button
                        leftIcon={SvgIcon.Add}
                        label='Add API key'
                        disabled={!hasPermission(Permission.AdminApiKeyCreate)}
                        onClick={handleAddApiKey}
                    />
                }
            />
            <ApiKeysPage onEdit={handleEditApiKey} items={apiKeys} isLoading={isKeysLoading} />
            <Route path={`${AdminNavLink.ApiKeys}/add`}>
                <CreateApiKeySlideout keyId={0} onClose={goBack} />
            </Route>
            <KeyRoute path={`${AdminNavLink.ApiKeys}/:keyId(${pathUuid})/edit`}>
                {({ keyId }) => <UpdateApiKeySlideout keyId={keyId} onClose={goBack} />}
            </KeyRoute>
        </Box>
    );
};

const KeyRoute = UUIDRoute('keyId', Route);
