import React, { FC, ReactNode } from 'react';

import { getBYODOrderLink } from '../../../components/routing/adminLinks';
import { Link } from '../../../components/routing/Link';

interface BYODOrdersLinkProps {
    publicId: string;
    children?: ReactNode;
}

export const BYODOrdersLink: FC<BYODOrdersLinkProps> = ({ publicId, children }) => {
    return <Link to={getBYODOrderLink(publicId)}>{children}</Link>;
};
