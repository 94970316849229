import React, { FC } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { OrderDetailsPage } from './orderDetails/OrderDetailsPage';
import { OrdersPage } from './OrdersPage';

export const OrdersRouter: FC = () => {
    const history = useHistory();

    const handleOpenOrder = (id: UUID) => {
        history.push(`${AdminNavLink.Orders}/${id}`);
    };

    return (
        <Switch>
            <OrderDetailsRoute path={`${AdminNavLink.Orders}/:orderId(${pathUuid})`}>
                {({ orderId }) => <OrderDetailsPage orderId={orderId} />}
            </OrderDetailsRoute>
            <Route path={AdminNavLink.Orders}>
                <OrdersPage onOpenOrder={handleOpenOrder} />
            </Route>
        </Switch>
    );
};

const OrderDetailsRoute = UUIDRoute('orderId', Route);
