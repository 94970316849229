import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box } from '@hofy/ui';

interface PageProps {
    children: ReactNode;
    pageId?: string;
}

export const Page: FC<PageProps> = ({ children, pageId }) => {
    return (
        <Box column flex='auto' relative bg={Color.BackgroundDefault} data-test-key={`page:${pageId}`}>
            {children}
        </Box>
    );
};
