import { fromPairs } from 'lodash';
import { useMemo } from 'react';

import { ProductDto, useProductsQuery, VariantDto } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

interface VariantDetails {
    id: UUID;
    imageUrl: string;
    productName: string;
}

export type VariantDetailsMap = Record<UUID, VariantDetails>;

export const useVariantDetailsMap = () => {
    const { data: products, isLoading } = useProductsQuery();
    const mapVariantDetails = (product: ProductDto, variant: VariantDto): [UUID, VariantDetails] => [
        variant.id,
        {
            id: variant.id,
            productName: product.name,
            imageUrl: variant.image.url,
        },
    ];
    const variantDetailsMap: VariantDetailsMap = useMemo(() => {
        return fromPairs(
            products.flatMap(product => product.variants.map(variant => mapVariantDetails(product, variant))),
        );
    }, [products]);
    return { variantDetailsMap, isLoading };
};
