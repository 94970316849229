import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
    assignmentsCacheKey,
    CompleteShipmentPayload,
    ShipmentDetailsDto,
    shipmentService,
} from '@hofy/api-admin';
import { errorMap, isEmpty, nowISODate } from '@hofy/helpers';
import { useForm } from '@hofy/ui';

export const useCompleteDeliveryShipment = (shipment: ShipmentDetailsDto, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const mutation = useMutation<void, unknown, CompleteShipmentPayload>({
        mutationFn: payload => shipmentService.completeShipment(shipment.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [assignmentsCacheKey],
            });
            onSuccess?.();
        },
    });

    const form = useForm<CompleteShipmentPayload>({
        initial: initialCompleteShipmentPayload(shipment),
        onSubmit: mutation.mutate,
        validate: ({ completedOn }) => ({
            completedOn: errorMap([
                isEmpty(completedOn),
                'Completed date must be set if status is Completed',
            ]),
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};

const initialCompleteShipmentPayload = (shipment: ShipmentDetailsDto): CompleteShipmentPayload => {
    return {
        completedOn: nowISODate(),
        sendNotification: true,
        deliveryNotes: shipment.deliveryNotes,
        items: null,
    };
};
