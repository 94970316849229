import { getEnumValues } from '@hofy/global';

export enum AdminAssignmentSlideoutTabs {
    Details = 'details',
    Shipments = 'shipments',
    Notes = 'notes',
}

export const allAdminAssignmentSlideoutTabs =
    getEnumValues<AdminAssignmentSlideoutTabs>(AdminAssignmentSlideoutTabs);
