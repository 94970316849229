import React, { FC } from 'react';
import styled from 'styled-components';

import {
    AddonConfigInvalidReason,
    AddonConfigInvalidReasonDto,
    AddonConfigValidForOrgDto,
    useTrAddonConfigInvalidReason,
} from '@hofy/api-admin';
import { useTrOrganizationStatus } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Badge, BooleanBadge, Box, Paragraph3, SvgIcon, Tooltip } from '@hofy/ui';

import { useTrPlatformTier } from '../../../store/organizations/useTrPlatformTier';

interface ValidForOrganizationAddonConfigBadgeProps {
    addonName: string;
    active: AddonConfigValidForOrgDto;
}

export const ValidForOrganizationAddonConfigBadge: FC<ValidForOrganizationAddonConfigBadgeProps> = ({
    addonName,
    active,
}) => {
    const trAddonConfigInvalidReason = useTrAddonConfigInvalidReason();

    if (active.state) {
        return <BooleanBadge value />;
    }

    if (active.invalidReason === AddonConfigInvalidReason.InactiveAddon) {
        return <BooleanBadge value={false} />;
    }

    return (
        <Tooltip
            placement='top'
            maxWidth='auto'
            bodySlot={<InvalidBadgeTooltipBody addonName={addonName} invalidState={active} />}
        >
            <Badge
                color='red'
                icon={SvgIcon.InfoCircle}
                label={trAddonConfigInvalidReason(active.invalidReason)}
            />
        </Tooltip>
    );
};

interface InvalidBadgeTooltipBodyProps {
    addonName: string;
    invalidState: AddonConfigInvalidReasonDto;
}

const InvalidBadgeTooltipBody: FC<InvalidBadgeTooltipBodyProps> = ({ addonName, invalidState }) => {
    const trPlatformTier = useTrPlatformTier();
    const trOrganizationStatus = useTrOrganizationStatus();
    switch (invalidState.invalidReason) {
        case AddonConfigInvalidReason.InvalidOrganizationStatus:
            return (
                <Paragraph3 color={Color.ContentInvertedPrimary}>
                    {addonName} is not applicable for {trOrganizationStatus(invalidState.organizationStatus)}{' '}
                    organizations
                </Paragraph3>
            );
        case AddonConfigInvalidReason.InvalidTier:
            return (
                <Box>
                    <Paragraph3 color={Color.ContentInvertedPrimary}>
                        {addonName} is only applicable on the following tiers:
                    </Paragraph3>
                    <Box marginLeft={15}>
                        <ul>
                            {invalidState.validTiers.map(tier => (
                                <LiCircled as='li' key={tier} color={Color.ContentInvertedPrimary}>
                                    {trPlatformTier(tier)}
                                </LiCircled>
                            ))}
                        </ul>
                    </Box>
                </Box>
            );
        default:
            return null;
    }
};

const LiCircled = styled(Paragraph3)`
    list-style-type: circle;
`;
