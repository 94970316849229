import { xor } from 'lodash';
import React, { FC } from 'react';

import { allJobContexts, JobContext, jobContextColors, useJobs } from '@hofy/api-admin';
import { Page } from '@hofy/common';
import { Box, FilterChipList, PageHeader, SvgIcon } from '@hofy/ui';

import { BlockFilterButton } from '../../components/design/blockFilters/BlockFilterButton';
import { BlockFilterChipContainer } from '../../components/design/blockFilters/BlockFilterChipContainer';
import { BlockFilterContainer } from '../../components/design/blockFilters/BlockFilterContainer';
import { EnumMultiBlockFilter } from '../../components/design/blockFilters/EnumMultiBlockFilter';
import { useBlockFilters } from '../../components/design/blockFilters/hooks/useBlockFilters';
import { useJobFilters } from '../../store/jobs/useJobFilters';
import { useTrJobContext } from '../../store/jobs/useTrJobContext';
import { JournalsPageMenu } from '../accountingPage/journals/JournalsPageMenu';
import { JobsTable } from './JobsTable';

export const JobsPage: FC = () => {
    const { filters, filterCount, setContext } = useJobFilters();
    const { isLoading, hasNextPage, isFetchingNextPage, fetchNextPage, items } = useJobs(filters);
    const { showFilters, toggleShowFilters, filterElRef } = useBlockFilters();
    const trJobContext = useTrJobContext();

    return (
        <Page>
            <PageHeader
                title='Jobs'
                rightSlot={
                    <>
                        <BlockFilterButton
                            onClick={toggleShowFilters}
                            isOpened={showFilters}
                            count={filterCount}
                        />
                        <JournalsPageMenu />
                    </>
                }
            />
            <BlockFilterContainer ref={filterElRef} show={showFilters}>
                <EnumMultiBlockFilter<JobContext>
                    title='Job context'
                    icon={SvgIcon.Circle}
                    selected={filters.context}
                    onChange={setContext}
                    items={allJobContexts}
                    renderItem={context => trJobContext(context)}
                />
            </BlockFilterContainer>
            <BlockFilterChipContainer show={filterCount > 0}>
                <FilterChipList
                    toKey={context => context}
                    selected={filters.context}
                    toLabel={trJobContext}
                    onClear={value => setContext(xor(filters.context, [value]))}
                    color={context => jobContextColors[context]}
                />
            </BlockFilterChipContainer>
            <Box relative flex='auto' row alignItems='stretch'>
                <JobsTable
                    jobs={items}
                    infinityScroll={{
                        hasMore: hasNextPage,
                        isLoading: isLoading,
                        isLoadingMore: isFetchingNextPage,
                        loadMore: fetchNextPage,
                    }}
                />
            </Box>
        </Page>
    );
};
