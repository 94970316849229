import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { FormFieldApi, Optional, TestKeyAware } from '@hofy/ui';

import { LabeledUserProps, LabeledUserSelect } from './LabeledUserSelect';

type FormUserSelectOptionalProps = Optional<LabeledUserProps, 'onChange'>;
type FormUserSelectOmittedProps = Omit<FormUserSelectOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalUserSelectProps extends FormUserSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID | null, UUID>;
    nullable?: false;
}

interface FormNullableUserSelectProps extends FormUserSelectOmittedProps, TestKeyAware {
    api: FormFieldApi<UUID | null>;
    nullable: true;
}

type FormUserSelectProps = FormNormalUserSelectProps | FormNullableUserSelectProps;

export const FormUserSelect: FC<FormUserSelectProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledUserSelect
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={(value: UUID | null) => {
                api.setValue(value!);
                onChange?.(value!);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
