import React, { FC, PropsWithChildren } from 'react';

import { ConfirmationContextProvider } from './confirmation';
import { ToastsContextProvider } from './toast';

export const HofyUiModuleContexts: FC<PropsWithChildren> = ({ children }) => {
    return (
        <ConfirmationContextProvider>
            <ToastsContextProvider>{children}</ToastsContextProvider>
        </ConfirmationContextProvider>
    );
};
