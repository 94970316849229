export const asNullable = (value: string | null): string | null => {
    if (!value?.trim()) {
        return null;
    }
    return value;
};

export const isBlank = (value: string | null) => {
    return !value?.trim();
};
