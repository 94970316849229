import React, { MouseEvent } from 'react';
import styled from 'styled-components';

import { Box, TextCell } from '@hofy/ui';

export interface AttachmentFile<T> {
    id: T;
    filename: string;
}

interface AttachmentCellProps<T> {
    files: AttachmentFile<T>[];
    isDownloading: boolean;
    onDownload(fileId: T): void;
}

export const AttachmentCell = <T extends number | string>({
    files,
    onDownload,
    isDownloading,
}: AttachmentCellProps<T>) => {
    const handleDownload = (e: MouseEvent<HTMLDivElement>, f: AttachmentFile<T>) => {
        e.stopPropagation();
        onDownload(f.id as T);
    };
    if (!files.length) {
        return <TextCell>--</TextCell>;
    }
    return (
        <TextCell>
            {files.map(f => (
                <Box key={f.id} row>
                    <LinkWrapper key={f.id} onClick={e => !isDownloading && handleDownload(e, f)}>
                        {f.filename}
                    </LinkWrapper>
                </Box>
            ))}
        </TextCell>
    );
};

const LinkWrapper = styled(Box)`
    cursor: pointer;

    :hover {
        text-decoration: underline;
    }
`;
