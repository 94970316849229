export const assetActivityI18n = {
    'asset-activity.created-at-warehouse.description': 'Asset created at',

    'asset-activity.created-with-user.description': 'Asset created for',
    'asset-activity.created-with-user.reason.loaner': 'due to issuance of loaner device for',
    'asset-activity.created-with-user.reason.replacement': 'due to replacement of',

    'asset-activity.pending-delivery.description': 'Asset pending delivery to',
    'asset-activity.pending-collection.description': 'Asset pending collection from',
    'asset-activity.pending-transfer.description': 'Asset pending transfer from',

    'asset-activity.collected.description': 'Asset collected from',
    'asset-activity.delivered.description': 'Asset delivered from',
    'asset-activity.transferred.description': 'Asset transferred from',

    'asset-activity.archived.description': 'Asset archived due to the following reason: {reason}',

    'asset-activity.common.to': 'to',
    'asset-activity.common.via': 'via',
    'asset-activity.common.part-of': 'part of',

    'asset-activity.pending-repair.description': 'Asset repair requested',
    'asset-activity.replaced.description': 'Asset replaced by a new one',
    'asset-activity.repaired.description': 'Asset repaired successfully',
};
