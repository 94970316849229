import React, { createContext, FC, ReactNode, useContext, useMemo } from 'react';
import { useToggle } from 'react-use';

import { useIsDisabled } from '../../contexts';
import { Box } from '../base';
import { Dropdown, Placement } from '../dropdown';
import { Button } from '../form';
import { SvgIcon } from '../icon';
import { ActionPressable } from './inner/ActionPressable';
import { ActionTileContent } from './inner/ActionTileContent';

export interface ActionDropdownProps {
    label: string;
    children?: ReactNode;
    placement?: Placement;
    contentWidth?: number;
    disabled?: boolean;
}

export const ActionDropdown: FC<ActionDropdownProps> = ({
    label,
    children,
    disabled,
    placement = 'bottom-end',
    contentWidth = 360,
}) => {
    const [open, toggle] = useToggle(false);

    const contextValue = useMemo(() => ({ close: () => toggle(false) }), []);

    return (
        <Dropdown
            open={open}
            onDismiss={() => toggle(false)}
            trigger={
                <Button
                    disabled={disabled}
                    label={label}
                    rightIcon={SvgIcon.DotsVertical}
                    onClick={() => toggle(!open)}
                />
            }
            placement={placement}
            asChild
        >
            <ActionContext.Provider value={contextValue}>
                <Box column padding={4} maxWidth={contentWidth}>
                    {children}
                </Box>
            </ActionContext.Provider>
        </Dropdown>
    );
};

interface ActionDropdownItemProps {
    icon?: Svg;
    title: ReactNode;
    description?: ReactNode;
    onClick?(): void;
    disabled?: boolean;
}

/**
 * ActionDropdownItem component is used to display an action tile for a smaller list of actions like in a dropdown.
 */
export const ActionDropdownItem: FC<ActionDropdownItemProps> = ({
    icon,
    title,
    description,
    onClick,
    disabled: localDisabled,
}) => {
    const disabled = useIsDisabled(localDisabled);
    const { close } = useContext(ActionContext);

    return (
        <ActionPressable
            onClick={() => {
                if (disabled) {
                    return;
                }
                close();
                onClick?.();
            }}
            padding={16}
            rounded={8}
            inactive={disabled}
            fullWidth
        >
            <ActionTileContent icon={icon} title={title} description={description} disabled={disabled} />
        </ActionPressable>
    );
};

const ActionContext = createContext({ close: () => {} });
