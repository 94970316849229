import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/features/array';
import 'core-js/features/dom-collections/for-each';
import 'core-js/features/iterator';
import 'core-js/features/math/sign';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/promise';
import 'core-js/features/set';
import 'core-js/features/string';

import { authProvider } from '@hofy/api-admin';
import { initSentry } from '@hofy/external-services';
import { registerAuthRequestInterceptor, restClient } from '@hofy/rest';

import { startApp } from './app/App';

restClient.addRequestInterceptor(registerAuthRequestInterceptor(authProvider));

initSentry();
startApp();
