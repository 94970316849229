import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Box, MarginBoxProps } from '../../base';

interface FormGridRowProps extends MarginBoxProps {
    columns: number | 'auto-fill' | 'auto-fit';
    maxColumnWidth?: number;
    children?: ReactNode;
}

export const FormGridRow: FC<FormGridRowProps> = ({
    children,
    columns,
    maxColumnWidth = 150,
    ...margins
}) => {
    return (
        <Wrapper columns={columns} maxColumnWidth={maxColumnWidth} {...margins}>
            {children}
        </Wrapper>
    );
};

export const FormGridItem = styled.div<{ columns: number }>`
    grid-column: span ${p => p.columns};
`;

const Wrapper = styled(Box)<{ columns: number | 'auto-fill' | 'auto-fit'; maxColumnWidth: number }>`
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 20px;
    grid-template-columns: repeat(
        ${p => p.columns},
        ${p => (typeof p.columns === 'number' ? '1fr' : `minmax(${p.maxColumnWidth}px, 1fr)`)}
    );
`;

export const FormGridLayout = styled(Box)<{ columns: number }>`
    display: grid;
    grid-column-gap: 40px;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
`;
