import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    BetaFeaturePayload,
    betaFeaturesCacheKey,
    betaFeatureService,
    useBetaFeaturesQuery,
} from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useForm, useToast } from '@hofy/ui';

export const useUpdateBetaFeature = (featureId: UUID, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const { data: features, isLoading: isLoadingFeatures } = useBetaFeaturesQuery();

    const mutation = useMutation({
        mutationFn: (payload: BetaFeaturePayload) => betaFeatureService.updateFeatures(featureId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [betaFeaturesCacheKey] });
            showToast({
                type: 'positive',
                message: 'Beta Feature successfully saved',
            });
            onSuccess();
        },
    });

    const form = useForm<BetaFeaturePayload>({
        initial: {
            globallyEnabled: false,
            active: false,
            organizationIds: [],
        },
        onSubmit: mutation.mutate,
    });

    useEffect(() => {
        const betaFeature = features.find(i => i.id === featureId);
        if (!betaFeature) {
            return;
        }
        form.setValues({
            globallyEnabled: betaFeature.globallyEnabled,
            active: betaFeature.active,
            organizationIds: betaFeature.organizations.map(v => v.id),
        });
    }, [features]);

    return {
        form,
        isLoadingMutation: mutation.isPending,
        isMutationSuccess: mutation.isSuccess,
        isLoadingFeatures,
    };
};
