import React from 'react';
import { useLocation } from 'react-router-dom';

import { useIssuesCount } from '@hofy/api-admin';
import { Permission } from '@hofy/api-shared';
import { APP_CONFIG, isLocal } from '@hofy/config';
import { ReactQueryDevtools } from '@hofy/devtools';
import { usePermission } from '@hofy/permission';
import { Navigation, NavigationItem, SvgIcon } from '@hofy/ui';

import { adminLinkPermissions, AdminNavLink } from '../../components/routing/AdminNavLink';
import { inventoryTabPermissions } from '../../store/inventory/types/InventoryTab';
import { AdminNavigationProfile } from './AdminNavigationProfile';

interface AdminNavigationProps {
    onSignOut(): void;
    onBackToHofy(): void;
}

export const AdminNavigation = ({ onSignOut, onBackToHofy }: AdminNavigationProps) => {
    const location = useLocation();
    const isActive = (v: string) => location.pathname.startsWith(v);
    const { hasPermission, hasAnyPermission } = usePermission();

    const openIssuesCount = useIssuesCount();
    const isInventoryVisible = Object.values(inventoryTabPermissions).some(hasPermission);

    return (
        <Navigation
            collapsible
            isCollapsed
            bottomSlot={<AdminNavigationProfile onSignOut={onSignOut} onBackToHofy={onBackToHofy} />}
            env={APP_CONFIG.env}
            envSlot={isLocal() && <ReactQueryDevtools warningThreshold={30} />}
        >
            <NavigationItem
                icon={SvgIcon.Box}
                link={`${AdminNavLink.Assignments}/delivery`}
                label='Assignments'
                isActive={isActive(AdminNavLink.Assignments)}
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Assignments])}
            />
            <NavigationItem
                icon={SvgIcon.Truck}
                link={`${AdminNavLink.Shipments}/pending`}
                label='Shipments'
                isActive={isActive(AdminNavLink.Shipments)}
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Shipments])}
            />
            <NavigationItem
                icon={SvgIcon.Search}
                link={AdminNavLink.Inspections}
                label='Inspections'
                isActive={isActive(AdminNavLink.Inspections)}
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Inspections])}
            />
            <NavigationItem
                icon={SvgIcon.Desk}
                link={AdminNavLink.Products}
                label='Products'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Products])}
            />
            <NavigationItem
                icon={SvgIcon.Chair}
                link={AdminNavLink.Inventory}
                label='Inventory'
                isVisible={isInventoryVisible}
            />
            <NavigationItem
                icon={SvgIcon.Laptop}
                link={AdminNavLink.Items}
                label='Items'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Items])}
            />
            <NavigationItem
                icon={SvgIcon.Tool}
                link={AdminNavLink.Repairs}
                label='Repairs'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Repairs])}
            />
            <NavigationItem
                icon={SvgIcon.Building}
                link={AdminNavLink.Organizations}
                label='Organizations'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Organizations])}
            />
            <NavigationItem
                icon={SvgIcon.Users}
                link={AdminNavLink.People}
                label='Users'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.People])}
            />
            <NavigationItem
                icon={SvgIcon.Message}
                link={AdminNavLink.Orders}
                label='Orders'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Orders])}
            />
            <NavigationItem
                icon={SvgIcon.CreditCard}
                link={AdminNavLink.Contracts}
                label='Contracts'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Contracts])}
            />
            <NavigationItem
                icon={SvgIcon.DollarSign}
                link={AdminNavLink.Invoicing}
                label='Invoicing'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Invoicing])}
            />
            <NavigationItem
                icon={SvgIcon.BarChart02}
                link={AdminNavLink.Accounting}
                label='Accounting'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Accounting])}
            />
            <NavigationItem
                icon={SvgIcon.AlertTriangle}
                link={`${AdminNavLink.Issues}/open`}
                label='Issues'
                isActive={isActive(AdminNavLink.Issues)}
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Issues])}
                notifications={openIssuesCount}
            />
            <NavigationItem
                icon={SvgIcon.Settings}
                link={AdminNavLink.Settings}
                label='Settings'
                isVisible={hasPermission(Permission.AdminOrganizationView)}
            />
            <NavigationItem
                icon={SvgIcon.Bell}
                link={AdminNavLink.Notifications}
                label='Notifications'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Notifications])}
            />
            <NavigationItem
                icon={SvgIcon.Clock}
                link={AdminNavLink.ActivityLogs}
                label='Activity logs'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.ActivityLogs])}
            />
            <NavigationItem
                icon={SvgIcon.Shield}
                link={AdminNavLink.AuditLogs}
                label='Audit logs'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.AuditLogs])}
            />
            <NavigationItem
                icon={SvgIcon.Server}
                link={AdminNavLink.Jobs}
                label='Jobs'
                isVisible={hasAnyPermission(adminLinkPermissions[AdminNavLink.Jobs])}
            />
        </Navigation>
    );
};
