import { useContext } from 'react';

import { RWDContext } from './RWDContext';
import { RwdConfig, useRWDConfig } from './useRWDConfig';

const noRwdConfig: RwdConfig = {
    isPhone: false,
    isTablet: false,
    isLaptop: false,
    isMobile: false,

    isDesktop: true,
    isLarge: true,
};

export const useRWD = (): RwdConfig => {
    const config = useRWDConfig();
    const { enabled } = useContext(RWDContext);

    return enabled ? config : noRwdConfig;
};
