import { getEnumValues } from '@hofy/global';

export enum ItemDocumentType {
    DataErasure = 'data_erasure',
    Warranty = 'warranty',
    Agreement = 'agreement',
    Invoice = 'invoice',
    Other = 'other',
}

export const allItemDocumentTypes = getEnumValues<ItemDocumentType>(ItemDocumentType);

export const allNonComputerItemDocumentTypes = getEnumValues<ItemDocumentType>(ItemDocumentType).filter(
    value => value !== ItemDocumentType.DataErasure,
);
