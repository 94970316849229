import React, { FC, useState } from 'react';

import { AssignmentWithProductDto, is3rdPartyHofyWarehouse, ShipmentDetailsDto } from '@hofy/api-admin';
import {
    AssignmentCollectionReason,
    formatVariant,
    isDefectiveStatus,
    isShipmentToHofyWarehouse,
    ShipmentStatus,
} from '@hofy/api-shared';
import { FormInput, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { Color } from '@hofy/theme';
import {
    AsyncButton,
    Box,
    Button,
    FormContainer,
    FormDateInput,
    FormSection,
    Paragraph3,
    Span,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ProductItem } from '../../../components/domain/products/ProductItem';
import { useCompleteCollectionShipment } from '../../../store/shipments/useCompleteCollectionShipment';
import { SendNotificationForm } from '../components/SendNotificationForm';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';
import { useFastCodeScan } from '../hooks/useFastCodeScan';
import { CollectionItemCheckConditionSlideout } from './CollectionItemCheckConditionSlideout';
import { CollectionItemCheckInSlideout } from './CollectionItemCheckInSlideout';

interface CollectionCompleteSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const CollectionCompleteSlideout: FC<CollectionCompleteSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading, disabledCompletion } = useCompleteCollectionShipment(shipment, onClose);

    const [isCheckInSlideoutOpen, setIsCheckInSlideoutOpen] = useState(false);
    const [openedAssignment, setOpenedAssignment] = useState<AssignmentWithProductDto>();

    const [fastScanCode, setFastScanCode] = useState('');

    const fastScan = useFastCodeScan(shipment.assignments, fastScanCode, {
        findCode: async query => {
            return (
                shipment.assignments.find(({ item }) => item?.itemCodes.some(({ code }) => code === query)) ||
                null
            );
        },
        orderFound: setOpenedAssignment,
    });

    const renderItemActions = (order: AssignmentWithProductDto) => {
        if (!order.item) {
            return null;
        }

        const handleOnClick = () => {
            setOpenedAssignment(order);
            setIsCheckInSlideoutOpen(true);
        };

        const label = order.item.warehouseBin ? 'Recheck item' : 'Check in item';
        return <Button type='secondary' onClick={handleOnClick} label={label} />;
    };

    const closeOrder = () => {
        setIsCheckInSlideoutOpen(false);
        setOpenedAssignment(undefined);
        setFastScanCode('');
    };

    const openSlideout = () => {
        if (openedAssignment?.item && isCheckInSlideoutOpen) {
            return getCorrectCheckInSlideout(shipment, openedAssignment, fastScanCode, closeOrder);
        }
        return null;
    };

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Mark as complete' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.Completed}
                />
                <FormContainer marginBottom={30}>
                    <FormSection label='Courier details' flex={1}>
                        <FormDateInput label='Completion date' api={form.fields.completedOn} />
                    </FormSection>
                    <FormSection label='Item details' flex={1}>
                        {shipment.assignments.map(order => (
                            <Box key={order.id} row justify='space-between'>
                                <ProductItem
                                    image={order.variant.image?.url}
                                    label={order.product.name}
                                    subLabel={<SubLabel assignment={order} />}
                                />
                                {order.collectionReason !== AssignmentCollectionReason.Disposal &&
                                    renderItemActions(order)}
                            </Box>
                        ))}
                    </FormSection>
                    {!openedAssignment?.item && (
                        <FormSection label='Shipment orders scan' marginTop={25}>
                            <FormInput
                                autoFocus
                                label='Item code or #Id'
                                placeholder='Enter or scan a item code'
                                onChangeText={setFastScanCode}
                                value={fastScanCode}
                                isError={!!fastScan.errorMessage}
                                errorMessage={fastScan.errorMessage}
                            />
                        </FormSection>
                    )}
                    <FormSection label='External' flex={1} marginTop={25}>
                        <SendNotificationForm
                            form={form}
                            switchLabel='Send notification'
                            textareaLabel='Notification notes'
                        />
                    </FormSection>
                </FormContainer>
                {openSlideout()}
            </SlideoutContent>

            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    isLoading={isLoading}
                    disabled={disabledCompletion}
                    onClick={form.submit}
                    label='Mark as completed'
                />
            </SlideoutFooter>
        </Slideout>
    );
};

interface SubLabelProps {
    assignment: AssignmentWithProductDto;
}

const SubLabel: FC<SubLabelProps> = ({ assignment }) => {
    return (
        <>
            <Paragraph3 color={Color.Neutral300}>{formatVariant(assignment.variant)}</Paragraph3>
            {assignment.item?.warehouse && (
                <Paragraph3>
                    Item code{' '}
                    <Span color={Color.ContentPositive}>
                        {(assignment.item?.itemCodes || []).map(i => i.code).join(', ')}
                    </Span>{' '}
                    checked
                </Paragraph3>
            )}
            {assignment.item && isDefectiveStatus(assignment.item.status) && (
                <Paragraph3 color={Color.AccentOrangePastel}>*Marked as damaged or defective</Paragraph3>
            )}
            {assignment.item?.warehouseBin?.identifier && (
                <Paragraph3>
                    Warehouse bin{' '}
                    <Span color={Color.ContentPositive}>{assignment.item.warehouseBin.identifier}</Span>
                </Paragraph3>
            )}
        </>
    );
};

const getCorrectCheckInSlideout = (
    shipment: ShipmentDetailsDto,
    openedAssignment: AssignmentWithProductDto,
    fastScanCode: string,
    onSlideoutClose: () => void,
) => {
    if (!isShipmentToHofyWarehouse(shipment)) {
        return null;
    }

    if (is3rdPartyHofyWarehouse(shipment.toWarehouse)) {
        return (
            <CollectionItemCheckConditionSlideout
                shipment={shipment}
                assignmentId={openedAssignment.id}
                product={openedAssignment.product}
                variant={openedAssignment.variant}
                item={openedAssignment.item!}
                onClose={onSlideoutClose}
            />
        );
    }

    return (
        <CollectionItemCheckInSlideout
            shipment={shipment}
            product={openedAssignment.product}
            variant={openedAssignment.variant}
            assignmentId={openedAssignment.id}
            item={openedAssignment.item!}
            onClose={onSlideoutClose}
            onSuccess={onSlideoutClose}
            scannedCode={fastScanCode}
        />
    );
};
