import { UUID, zeroUuid } from '@hofy/global';

export interface SeedRandomUsersPayload {
    organizationId: UUID;
    baseEmail: string;
    count: number;
}

export const emptySeedRandomUsersPayload: SeedRandomUsersPayload = {
    organizationId: zeroUuid,
    baseEmail: '',
    count: 10,
};
