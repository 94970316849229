export const teamColorI18n = {
    'team-color.red': 'Red',
    'team-color.orange': 'Orange',
    'team-color.yellow': 'Yellow',
    'team-color.green': 'Green',
    'team-color.teal': 'Teal',
    'team-color.light-blue': 'Light blue',
    'team-color.blue': 'Blue',
    'team-color.purple': 'Purple',
    'team-color.pink': 'Pink',
    'team-color.magenta': 'Magenta',
    'team-color.grey': 'Grey',
};
