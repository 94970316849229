import { useMutation, useQueryClient } from '@tanstack/react-query';

import { OrganizationDetailsDto, organizationService, UpdateOrganizationMdmPayload } from '@hofy/api-admin';
import { useForm, useToast } from '@hofy/ui';

import { organizationsCacheKey } from './organizationsCacheKey';

export const useUpdateOrganizationMdm = (organization: OrganizationDetailsDto, onSuccess?: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationMdmPayload) =>
            organizationService.updateOrganizationMdm(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                message: `Organization ${organization.name} MDM successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationMdmPayload>({
        initial: {
            mdmCount: organization.mdmCount,
            mdmEnabled: organization.mdmEnabled,
        },
        onSubmit: mutation.mutate,
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
