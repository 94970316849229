import React, { FC } from 'react';

import { ShipmentDetailsDto } from '@hofy/api-admin';
import { isShipmentFromUserToUser, ShipmentStatus } from '@hofy/api-shared';
import { Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { AsyncButton, FormContainer, FormDateInput } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useShipShipment } from '../../../store/shipments/useShipShipment';
import { SendNotificationForm } from '../components/SendNotificationForm';
import { ShipmentCourierForm } from '../components/ShipmentCourierForm';
import { ShipmentStatusTransitionPane } from '../components/ShipmentStatusTransitionPane';

interface ShipShipmentSlideoutProps {
    shipment: ShipmentDetailsDto;
    onClose(): void;
}

export const ShipShipmentSlideout: FC<ShipShipmentSlideoutProps> = ({ onClose, shipment }) => {
    const { form, isLoading, isError } = useShipShipment(shipment, onClose);

    const notificationNotesTitle = !isShipmentFromUserToUser(shipment)
        ? 'Shipped notification notes'
        : 'Shipped notification notes for delivery user';
    return (
        <Slideout width={600} onClose={onClose} slideoutId='ship-shipment'>
            <SlideoutHeader title='Ship shipment' />
            <SlideoutContent>
                <ShipmentStatusTransitionPane
                    marginVertical={30}
                    shipment={shipment}
                    toStatus={ShipmentStatus.WithCourier}
                />
                <FormContainer marginBottom={30}>
                    <ShipmentCourierForm form={form} />
                    <FormDateInput label='Courier pick up date' api={form.fields.withCourierOn} />
                    <SendNotificationForm
                        form={form}
                        switchLabel='Send shipped notification'
                        textareaLabel={notificationNotesTitle}
                    />
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Mark as shipped'
                    onClick={form.submit}
                    isLoading={isLoading}
                    isError={isError}
                />
            </SlideoutFooter>
        </Slideout>
    );
};
