import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { byodOrderService } from '../service/byodOrderService';
import { byodOrdersCacheKey } from './cacheKey';

export const useBYODOrderQuery = (id: UUID) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [byodOrdersCacheKey, 'byod-order', id],

        queryFn: () => byodOrderService.getBYODOrder(id),
    });

    return {
        data,
        isLoading,
        isError,
    };
};
