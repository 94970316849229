import React, { FC } from 'react';

import { AssignmentStatus, isDeliveryPending } from '@hofy/api-shared';
import { DateString, DateTimeString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3 } from '@hofy/ui';

interface AssignmentDateCellProps extends BoxProps {
    status: AssignmentStatus;
    onTimeDeliveryDate: DateString | null;
    withUserOn: DateString | null;
    collectionPendingAt?: DateTimeString | null;
}

export const AssignmentDateCell: FC<AssignmentDateCellProps> = ({
    status,
    onTimeDeliveryDate,
    withUserOn,
    collectionPendingAt,
    ...boxProps
}) => {
    const title = titleFromStatus(status);
    const renderDate = dateFromAssignment(status, onTimeDeliveryDate, withUserOn, collectionPendingAt);
    return (
        <Box column {...boxProps}>
            <Paragraph3 color={Color.Neutral300}>{title}</Paragraph3>
            <Paragraph3>{renderDate} </Paragraph3>
        </Box>
    );
};

const titleFromStatus = (status: AssignmentStatus) => {
    if (status === AssignmentStatus.WithUser) {
        return 'Delivered';
    }
    if (isDeliveryPending(status)) {
        return 'OTD';
    }
    return 'Requested at';
};

const dateFromAssignment = (
    status: AssignmentStatus,
    onTimeDeliveryDate: DateString | null,
    deliveredOn: DateString | null,
    collectionPendingAt?: DateTimeString | null,
) => {
    if (status === AssignmentStatus.WithUser) {
        return formatDate(deliveredOn);
    }
    if (isDeliveryPending(status)) {
        return formatDate(onTimeDeliveryDate);
    }
    return formatDate(collectionPendingAt || null);
};
