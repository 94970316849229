import { difference, sortBy } from 'lodash';
import React, { FC, useEffect } from 'react';

import { useShipmentQuery } from '@hofy/api-admin';
import { isShipmentFromSupplierToUser } from '@hofy/api-shared';
import { allCurrencies, coreCurrencies, Currency } from '@hofy/global';
import { CopyToClipboardStatus, useCopyToClipboard } from '@hofy/hooks';
import { useTrCurrency } from '@hofy/i18n';
import {
    FormDateInput,
    FormInput,
    FormSegment,
    FormSelectSearch,
    FormTextarea,
    IconButton,
    SvgIcon,
    TextCell,
    UseForm,
    useToast,
} from '@hofy/ui';

import { FormHofyWarehouseSelect } from '../../../../../components/domain/warehouses/FormHofyWarehouseSelect';
import { PurchaseOrderHeaderFormData } from '../../../../../store/purchaseOrders/usePurchaseOrderHeaderForm';
import { PurchaseOrderFormSupplierDropdown } from './PurchaseOrderFormSupplierDropdown';

interface PurchaseOrderHeaderFormProps {
    headerForm: UseForm<PurchaseOrderHeaderFormData>;
}

export const PurchaseOrderHeaderForm: FC<PurchaseOrderHeaderFormProps> = ({ headerForm }) => {
    const { copyStatus, copyToClipboard } = useCopyToClipboard(
        headerForm.values.purchaseOrderReference ?? '',
    );
    const { showToast } = useToast();
    const trCurrency = useTrCurrency();
    const { data: shipment } = useShipmentQuery(headerForm.values.shipmentId);

    useEffect(() => {
        if (copyStatus === CopyToClipboardStatus.Copied) {
            showToast({
                message: 'Reference copied to clipboard',
                type: 'informative',
            });
        }
    }, [copyStatus]);

    return (
        <FormSegment column gap={20}>
            {shipment && isShipmentFromSupplierToUser(shipment) && (
                <TextCell bold>
                    Shipment going to user: {`${shipment.toUser.firstName} ${shipment.toUser.lastName}`}
                </TextCell>
            )}
            <FormInput
                api={headerForm.fields.purchaseOrderReference}
                label='Purchase order reference'
                rightSlot={<IconButton icon={SvgIcon.Copy} onClick={copyToClipboard} />}
                rightSlotInteractive
                disabled
                nullable
            />
            <FormInput
                api={headerForm.fields.purchaseOrderSupplierReference}
                label='Supplier reference'
                nullable
            />
            <PurchaseOrderFormSupplierDropdown headerForm={headerForm} />
            <FormSelectSearch
                api={headerForm.fields.currency}
                label='Currency'
                options={sortBy(difference(allCurrencies, coreCurrencies), c => trCurrency(c))}
                fixedOptions={sortBy(coreCurrencies, c => trCurrency(c))}
                toText={trCurrency}
                onSearchChange={(c: Currency) => [trCurrency(c).toLowerCase(), trCurrency(c).toUpperCase()]}
                isRequired
            />
            {!headerForm.values.isDropship && (
                <FormHofyWarehouseSelect
                    label='Warehouse'
                    api={headerForm.fields.toWarehouseId}
                    isRequired
                    nullable
                />
            )}
            <FormDateInput label='Order date' api={headerForm.fields.orderedOn} isRequired />
            <FormDateInput
                label='Delivery date'
                api={headerForm.fields.estimatedReceiveOn}
                isRequired
                nullable
            />
            <FormTextarea label='Notes' api={headerForm.fields.notes} nullable rows={5} />
        </FormSegment>
    );
};
