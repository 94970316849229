import { getEnumValues, VisualType } from '@hofy/global';
import { SvgIcon } from '@hofy/ui';

import { CollectionRequestItemPropertyStatus } from './CollectionRequestItemPropertyStatus';

// shared properties between all types of collection request items (laptops, furniture, accessories, etc.)
export enum CollectionRequestItemPropertyType {
    Item = 'item',
    ManufacturerBox = 'manufacturer_box',
    ShippingBox = 'shipping_box',
    ChargingBlock = 'charging_block',
    ChargingCable = 'charging_cable',
    ProvidedSerialNumber = 'provided_serial_number',
}

export const allCollectionRequestItemPropertyTypes = getEnumValues<CollectionRequestItemPropertyType>(
    CollectionRequestItemPropertyType,
);

type CollectionRequestItemPropertyTypeText = Partial<Record<CollectionRequestItemPropertyType, string>>;

const itemPropertyConfirmedText: CollectionRequestItemPropertyTypeText = {
    [CollectionRequestItemPropertyType.Item]: 'Item confirmed',
    [CollectionRequestItemPropertyType.ManufacturerBox]: 'Original box',
    [CollectionRequestItemPropertyType.ShippingBox]: 'Shipping box',
    [CollectionRequestItemPropertyType.ChargingBlock]: 'Charging block',
    [CollectionRequestItemPropertyType.ChargingCable]: 'Charging cable',
    [CollectionRequestItemPropertyType.ProvidedSerialNumber]: 'Serial number provided',
};

const itemPropertyMissingText: CollectionRequestItemPropertyTypeText = {
    [CollectionRequestItemPropertyType.Item]: 'Missing item',
    [CollectionRequestItemPropertyType.ManufacturerBox]: 'Missing original box',
    [CollectionRequestItemPropertyType.ShippingBox]: 'Missing shipping box',
    [CollectionRequestItemPropertyType.ChargingBlock]: 'Missing charging block',
    [CollectionRequestItemPropertyType.ChargingCable]: 'Missing charging cable',
    [CollectionRequestItemPropertyType.ProvidedSerialNumber]: 'Serial number not provided',
};

const itemPropertyResolvedText: CollectionRequestItemPropertyTypeText = {
    [CollectionRequestItemPropertyType.Item]: 'Item found',
    [CollectionRequestItemPropertyType.ManufacturerBox]: 'Original box received',
    [CollectionRequestItemPropertyType.ShippingBox]: 'Shipping box received',
};

const itemPropertySentText: CollectionRequestItemPropertyTypeText = {
    [CollectionRequestItemPropertyType.Item]: 'Item sent',
    [CollectionRequestItemPropertyType.ManufacturerBox]: 'Original box sent',
    [CollectionRequestItemPropertyType.ShippingBox]: 'Shipping box sent',
};

const itemPropertyResponseNotReceivedText: CollectionRequestItemPropertyTypeText = {
    [CollectionRequestItemPropertyType.ChargingBlock]: 'Charging block status unknown',
    [CollectionRequestItemPropertyType.ChargingCable]: 'Charging cable status unknown',
};

const itemPropertyTextAndAppearance: Record<
    CollectionRequestItemPropertyStatus,
    [CollectionRequestItemPropertyTypeText, VisualType, Svg]
> = {
    [CollectionRequestItemPropertyStatus.Confirmed]: [itemPropertyConfirmedText, 'positive', SvgIcon.Check],
    [CollectionRequestItemPropertyStatus.Missing]: [itemPropertyMissingText, 'negative', SvgIcon.AlertCircle],
    [CollectionRequestItemPropertyStatus.Resolved]: [itemPropertyResolvedText, 'positive', SvgIcon.Check],
    [CollectionRequestItemPropertyStatus.Sent]: [itemPropertySentText, 'warning', SvgIcon.AlertCircle],
    [CollectionRequestItemPropertyStatus.ResponseNotReceived]: [
        itemPropertyResponseNotReceivedText,
        'neutral',
        SvgIcon.AlertCircle,
    ],
};

type AllCollectionRequestItemPropertiesTextAndAppearance = [string, VisualType, Svg];

export const getItemPropertyTextAndAppearance = (
    propertyType: CollectionRequestItemPropertyType,
    propertyStatus: CollectionRequestItemPropertyStatus,
): AllCollectionRequestItemPropertiesTextAndAppearance => {
    const [text, visualType, icon] = itemPropertyTextAndAppearance[propertyStatus];
    return [text[propertyType] ?? 'Unknown', visualType, icon];
};
