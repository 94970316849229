import React, { FC } from 'react';

import { allShipmentStatuses, ShipmentStatus, useTrShipmentStatus } from '@hofy/api-shared';
import { SvgIcon } from '@hofy/ui';

import { ListMultiBlockFilter } from '../../../../../components/design/blockFilters/ListMultiBlockFilter';

interface ShipmentStatusBlockFilterProps {
    selected: ShipmentStatus[];
    onChange(status: ShipmentStatus[]): void;
}

export const ShipmentStatusBlockFilter: FC<ShipmentStatusBlockFilterProps> = ({ selected, onChange }) => {
    const trShipmentStatus = useTrShipmentStatus();

    return (
        <ListMultiBlockFilter
            title='Shipment status'
            icon={SvgIcon.Truck}
            selected={selected}
            onChange={onChange}
            items={allShipmentStatuses}
            renderItem={type => trShipmentStatus(type)}
        />
    );
};
