import React, { FC } from 'react';

import { AssignmentStatus } from '@hofy/api-shared';
import { DateString } from '@hofy/global';
import { formatDate, isDateInFuture } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Badge, Icon, SvgIcon, Tooltip } from '@hofy/ui';

interface ScheduledCollectionBadgeProps {
    assignmentStatus: AssignmentStatus;
    notBefore: DateString | null;
}

export const ScheduledCollectionBadge: FC<ScheduledCollectionBadgeProps> = ({
    assignmentStatus,
    notBefore,
}) => {
    if (!notBefore || !isDateInFuture(notBefore) || assignmentStatus !== AssignmentStatus.CollectionPending) {
        return null;
    }

    return (
        <Tooltip body={`Do not contact before ${formatDate(notBefore)}`} asChild>
            <Badge
                leftSlot={<Icon color={Color.ContentInformative} svg={SvgIcon.Clock} size={16} />}
                color='emerald'
                label='Scheduled'
            />
        </Tooltip>
    );
};
