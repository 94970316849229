import React, { createRef, FC, FocusEvent, KeyboardEvent, ReactNode, RefObject } from 'react';

import { asNullable } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Font, MarginBoxProps, Paragraph3, TestKeyAware } from '@hofy/ui';

import { FlatInput } from './base/FlatInput';

interface FormInputBaseProps extends MarginBoxProps, TestKeyAware {
    label?: string;

    placeholder?: string;
    name?: string;
    unit?: string;
    inputRef?: RefObject<HTMLInputElement>;
    type?: string;
    paddingRight?: number;
    disabled?: boolean;
    bordered?: boolean;
    flex?: number | 'auto';
    isError?: boolean | string;
    isRequired?: boolean;
    errorMessage?: ReactNode;
    autoFocus?: boolean;
    children?: ReactNode;
    onBlur?(e: FocusEvent): void;
    onFocus?(e: FocusEvent): void;
    onKeyDown?(e: KeyboardEvent): void;
}

interface FormInputOnlyStringProps extends FormInputBaseProps {
    nullable?: false;
    value: string;
    onChangeText(v: string): void;
}

export interface FormInputNullableStringProps extends FormInputBaseProps {
    nullable: true;
    value: string | null;
    onChangeText(v: string | null): void;
}

export type FormInputProps = FormInputOnlyStringProps | FormInputNullableStringProps;

/** @deprecated use `LabeledInput` or `FormInput` from `@hofy/ui */
export const FormInput: FC<FormInputProps> = ({
    value,
    onChangeText,
    nullable,
    isError,
    errorMessage,
    autoFocus,
    placeholder,
    label,
    name,
    isRequired,
    testKey,
    unit,
    inputRef,
    type = 'text',
    paddingRight = 16,
    disabled,
    bordered = true,
    children,
    flex,
    onBlur,
    onFocus,
    onKeyDown,
    ...margins
}) => {
    const finalRef = inputRef || createRef<HTMLInputElement>();

    const actualIsError = typeof isError === 'string' || isError === true;
    const errorReason = errorMessage || (typeof isError === 'string' ? isError : undefined);
    const showError = actualIsError && errorReason;

    const handleContainerClick = () => {
        finalRef.current?.focus();
    };

    const handleChange = (value: string) => {
        if (nullable) {
            onChangeText(asNullable(value));
        } else {
            onChangeText(value);
        }
    };

    return (
        <Box flex={flex} {...margins}>
            <label role='label' data-test-key={testKey}>
                {label && (
                    <Paragraph3 marginBottom={8}>
                        {label}
                        {isRequired && '*'}
                    </Paragraph3>
                )}
                <Box row onClick={handleContainerClick}>
                    <FlatInput
                        isError={actualIsError}
                        ref={finalRef}
                        value={value || ''}
                        type={type}
                        name={name}
                        onBlur={onBlur}
                        onChange={e => handleChange(e.target.value)}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        disabled={disabled}
                        bordered={bordered}
                        paddingLeft={16}
                        paddingRight={paddingRight}
                        autoFocus={autoFocus}
                    />
                    {unit && (
                        <Font
                            fullHeight
                            centered
                            color={Color.Neutral500}
                            weight='normal'
                            paddingLeft={8}
                            paddingRight={8}
                            size={14}
                        >
                            {unit}
                        </Font>
                    )}
                    {children}
                </Box>
                {showError && (
                    <Paragraph3 data-test-key='error' color={Color.HighlightNegativeHover} marginTop={8}>
                        {errorReason}
                    </Paragraph3>
                )}
            </label>
        </Box>
    );
};
