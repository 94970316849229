import { identity, xorBy } from 'lodash';
import React, { ReactNode } from 'react';

import { BoxProps } from '@hofy/ui';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface ListMultiBlockFilterProps<T, I> extends BoxProps {
    title: ReactNode;
    selected: T[];
    onChange(list: T[], changedItem: T): void;
    items: T[];
    renderItem(item: T): ReactNode;
    icon?: Svg;
    compareIteratee?(item: T): I;
}

export const ListMultiBlockFilter = <T, I>({
    title,
    selected,
    onChange,
    items,
    renderItem,
    icon,
    compareIteratee = identity,
    ...boxProps
}: ListMultiBlockFilterProps<T, I>) => {
    const handleChange = (item: T) => {
        onChange(xorBy(selected, [item], compareIteratee), item);
    };

    const isSelected = (item: T) => {
        return selected.some(s => compareIteratee(s) === compareIteratee(item));
    };

    return (
        <BlockFilter title={title} icon={icon} {...boxProps}>
            {items.map((item, index) => (
                <BlockFilterItem
                    key={`${compareIteratee(item)}-${index}`}
                    name={renderItem(item)}
                    selected={isSelected(item)}
                    onClick={() => handleChange(item)}
                />
            ))}
        </BlockFilter>
    );
};
