import React from 'react';

import { AdminAssignmentFilters } from '@hofy/api-admin';
import { FilterApiRecord, LabeledDateInput, LabeledDateRangeInput, SvgIcon } from '@hofy/ui';

import { BlockFilter } from '../../../../../components/design/blockFilters/BlockFilter';

interface AssignmentsDateFilterProps {
    filterApi: FilterApiRecord<AdminAssignmentFilters>;
    filterValues: AdminAssignmentFilters;
}

export const AssignmentsDateFilter = ({ filterApi, filterValues }: AssignmentsDateFilterProps) => {
    return (
        <BlockFilter title='Date' icon={SvgIcon.Calendar} marginLeft={12} column gap={8}>
            <LabeledDateInput
                label='OTD before'
                value={filterValues.otdDate}
                onChange={filterApi.otdDate.set}
                nullable
            />
            <LabeledDateRangeInput
                label='Ship by'
                value={filterValues.shipDateRange}
                onChange={filterApi.shipDateRange.set}
                nullable
            />
            <LabeledDateInput
                label='Est. Delivery before'
                value={filterValues.deliveryDate}
                onChange={filterApi.deliveryDate.set}
                nullable
            />
        </BlockFilter>
    );
};
