import { getEnumValues } from '@hofy/global';

export enum OrganizationSize {
    Micro = 'micro',
    Small = 'small',
    Medium = 'medium',
    MediumLarge = 'medium_large',
    Large = 'large',
    Enterprise = 'enterprise',
}

export const allOrganizationSizes = getEnumValues<OrganizationSize>(OrganizationSize);
