import React, { FC, ReactNode, useMemo } from 'react';

import { FormDropdown } from '@hofy/common';
import { allCountries, Country, sortCountries } from '@hofy/global';
import { useTrCountrySearch } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { TestKeyAware } from '@hofy/ui';

import { CountryLabel } from './CountryLabel';

interface CountryFormDropdownProps extends TestKeyAware {
    value?: Country | null;
    label: string;
    onChange(o: Country): void;
    emptyContent?: ReactNode;
    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
    options?: Country[];
}

export const CountryFormDropdown: FC<CountryFormDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired = false,
    testKey,
    disabled,
    options,
}) => {
    const getCountryNames = useTrCountrySearch();
    const items = useMemo(() => sortCountries(options || allCountries), [options]);

    return (
        <FormDropdown
            testKey={testKey}
            items={items}
            labelFormatter={c => (
                <CountryLabel iconSize={16} color={Color.Neutral500} country={c} textNoWrap marginRight={4} />
            )}
            onChange={onChange}
            label={label}
            value={value}
            toSearchLabel={getCountryNames}
            searchable
            emptyContent={emptyContent}
            isError={isError}
            isRequired={isRequired}
            disabled={disabled}
        />
    );
};
