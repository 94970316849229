import React, { FC, ReactNode } from 'react';

import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3, SvgIcon } from '@hofy/ui';

interface PartOfSalesOrderModalProps {
    onClose(): void;
    title: ReactNode;
    content: ReactNode;
}

export const PartOfSalesOrderModal: FC<PartOfSalesOrderModalProps> = ({ onClose, title, content }) => {
    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={title} />
            <ModalContent>
                <Paragraph3>{content}</Paragraph3>
            </ModalContent>
            <ModalFooter>
                <Button type='secondary' label='Close' onClick={onClose} leftIcon={SvgIcon.Cross} />
            </ModalFooter>
        </Modal>
    );
};
