import { Country, isUSA, isValidUSAState, USState } from '@hofy/global';

import { useBaseI18n } from '../useBaseI18n';

export const useTrState = () => {
    const { trParts } = useBaseI18n();
    return (v: USState) => {
        return isValidUSAState(v) ? trParts('state', v) : '';
    };
};
export const useStateLabel = () => {
    const trState = useTrState();
    return (v: { country: Country; state: USState | string | null } | null) =>
        isUSA(v?.country) && isValidUSAState(v?.state) ? trState(v?.state as USState) : v?.state;
};
