import React, { FC, ReactNode } from 'react';

import { Color } from '@hofy/theme';
import { Box, FontProps, Icon, OuterBoxProps, Paragraph3 } from '@hofy/ui';

export interface IconItemProps extends OuterBoxProps {
    icon: Svg;
    iconColor?: Color;
    children?: ReactNode;
}

export const IconItem: FC<IconItemProps & FontProps> = ({
    icon,
    iconColor = Color.ContentSecondary,
    children,
    ...rest
}) => {
    return (
        <Paragraph3 row padding={4} lineHeight='large' {...rest}>
            <Icon svg={icon} color={iconColor} size={16} marginRight={4} />
            {children}
        </Paragraph3>
    );
};

export const IconItemBox: FC<IconItemProps> = ({ icon, iconColor, children, ...rest }) => {
    return (
        <Box row marginRight={8} {...rest}>
            <Icon svg={icon} size={16} marginRight={4} color={iconColor} />
            {children}
        </Box>
    );
};
