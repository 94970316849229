import React, { FC } from 'react';

import { AssetArchivedEvent, useTrItemStatus } from '@hofy/api-shared';
import { Paragraph3 } from '@hofy/ui';

import { useActivityLogI18n } from '../../store/useActivityLogI18n';

export const AssetArchivedDescription: FC<AssetArchivedEvent> = event => {
    const { tr } = useActivityLogI18n();

    const trItemStatus = useTrItemStatus();

    return (
        <Paragraph3>
            {tr('asset-activity.archived.description', { reason: trItemStatus(event.archived.status) })}
        </Paragraph3>
    );
};
