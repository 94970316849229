import { FloatingPortal } from '@floating-ui/react';
import { AnimatePresence } from 'framer-motion';
import React, { FC, PropsWithChildren } from 'react';

export const Modals: FC<PropsWithChildren> = ({ children }) => {
    return (
        <FloatingPortal>
            <AnimatePresence>{children}</AnimatePresence>
        </FloatingPortal>
    );
};
