import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { UUIDRoute } from '@hofy/router';

import { getAdminLink } from '../../../components/routing/adminLinks';
import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { PeopleTab } from '../PeopleTab';
import { AdminDetailsPage } from './AdminDetailsPage';
import { AdminsPage } from './AdminsPage';
import { CreateAdminSlideout } from './createAdminSlideout/CreateAdminSlideout';
import { UpdateAdminSlideout } from './updateAdminSlideout/UpdateAdminSlideout';

export const AdminsRouter: FC = () => {
    const history = useHistory();
    const handleOpenAdmin = (id: UUID) => history.push(getAdminLink(id));
    const handleEditAdmin = (id: UUID) => history.push(`${getAdminLink(id)}/edit`);
    const handleCreateAdmin = () => history.push(`${AdminNavLink.People}/${PeopleTab.Admins}/new`);
    return (
        <>
            <DetailsRoute path={`${AdminNavLink.People}/${PeopleTab.Admins}/:adminId(${pathUuid})`}>
                {({ adminId }) => <AdminDetailsPage onEdit={handleEditAdmin} adminId={adminId} />}
            </DetailsRoute>
            <DetailsRoute
                path={`${AdminNavLink.People}/${PeopleTab.Admins}/:adminId(${pathUuid})/edit`}
                exact
            >
                {({ adminId }) => <UpdateAdminSlideout onClose={() => history.goBack()} adminId={adminId} />}
            </DetailsRoute>
            <Route path={`${AdminNavLink.People}/${PeopleTab.Admins}/new`} exact>
                <CreateAdminSlideout onClose={() => history.goBack()} />
            </Route>
            <Route path={`${AdminNavLink.People}/${PeopleTab.Admins}`}>
                <AdminsPage onCreate={handleCreateAdmin} onOpenAdmin={handleOpenAdmin} />
            </Route>
        </>
    );
};

const DetailsRoute = UUIDRoute('adminId', Route);
