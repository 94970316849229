import { BillingEntityContactDto } from '@hofy/api-shared';
import { Currency, PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AdminBillingEntityFilters } from './types/AdminBillingEntityFilters';
import { BillingEntityDetailsDto, BillingEntityDto } from './types/BillingEntityDto';
import { BillingEntityPayload } from './types/BillingEntityPayload';
import { ForkBillingEntityPayload } from './types/ForkBillingEntityPayload';

class BillingEntityService {
    public listBillingEntities = async (
        filters: AdminBillingEntityFilters,
        page: PageRequest,
    ): Promise<PageResponse<BillingEntityDto>> => {
        return restClient.getJson<PageResponse<BillingEntityDto>>(
            stringifyUrl({
                url: `/api/admin/billing-entities`,
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public getBillingEntity = async (billingEntityId: UUID): Promise<BillingEntityDetailsDto> => {
        return restClient.getJson<BillingEntityDetailsDto>(`/api/admin/billing-entities/${billingEntityId}`);
    };

    public updateBillingEntityCurrency = async (billingEntityId: UUID, currency: Currency): Promise<void> => {
        return restClient.patchJson(`/api/admin/billing-entities/${billingEntityId}/currency`, {
            currency: currency,
        });
    };

    public forkBillingEntity = async (
        billingEntityId: UUID,
        payload: ForkBillingEntityPayload,
    ): Promise<number> => {
        return restClient
            .postJson<{
                id: number;
            }>(`/api/admin/billing-entities/${billingEntityId}/fork`, payload)
            .then(r => r.id);
    };

    public accountingSync = async (billingEntityId: UUID) => {
        return restClient.post(`/api/admin/billing-entities/${billingEntityId}/accounting-sync`);
    };

    public updateCredit = async (billingEntityId: UUID) => {
        return restClient.post(`/api/admin/billing-entities/${billingEntityId}/update-credit`);
    };

    public syncToNetsuite = async () => {
        return restClient.post(`/api/admin/billing-entities/sync-to-netsuite`);
    };

    public createUpdateBillingEntity = async (payload: BillingEntityPayload): Promise<UUID> => {
        return restClient
            .postJson<{
                id: UUID;
            }>(`/api/admin/billing-entities`, payload)
            .then(r => r.id);
    };

    public getBillingEntityInvoiceContacts = async (
        billingEntityId: UUID,
    ): Promise<BillingEntityContactDto[]> => {
        return restClient.getJson<BillingEntityContactDto[]>(
            `/api/admin/billing-entities/${billingEntityId}/invoice-contacts`,
        );
    };
}

export const billingEntitiesService = new BillingEntityService();
