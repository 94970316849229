import React, { FC } from 'react';

import { Alert, Box, Form, FormNumberInput, SubmitButton } from '@hofy/ui';

import { useSeedWarehouseItems } from '../../../../store/settings/useSeedWarehouseItems';

export const SeedRandomWarehouseItems: FC = () => {
    const { form, isLoading } = useSeedWarehouseItems();
    return (
        <Box>
            <Form column gap={20} onSubmit={form.submit} isLoading={isLoading}>
                <Alert
                    type='informative'
                    description='For each warehouse creates number of items with code in format ${SKU}-${3 letters of warehouse name}-${index}'
                />
                <FormNumberInput label='Index from' api={form.fields.indexFrom} />
                <FormNumberInput label='Index to' api={form.fields.indexTo} />
                <SubmitButton label='Generate' />
            </Form>
        </Box>
    );
};
