import React from 'react';

import { Box } from '@hofy/ui';

import { FilterEntry } from './FilterEntry';

interface FilterOptionData<T> {
    value: T;
    display: string;
    isGreyOut?: boolean;
}

interface SelectFilterProps<T> {
    borderBottom?: boolean;
    title: string;
    options: FilterOptionData<T>[];

    selected?: T;
    categoryValue: T;
    onChange(selected?: T): void;
    testPrefix?: string;
}

export const SelectFilter = <T extends any>(props: SelectFilterProps<T>) => {
    const { title, options, selected, onChange, borderBottom, categoryValue, testPrefix } = props;
    const renderOption = (o: FilterOptionData<T>) => {
        return (
            <FilterEntry
                testKey={testPrefix === undefined ? undefined : `${testPrefix}--filter--${o.value}`}
                key={o.display}
                isActive={selected === o.value}
                isGreyedOut={o.isGreyOut}
                onChange={v => (v ? onChange(o.value) : onChange())}
                marginBottom={4}
                marginLeft={10}
                type='secondary'
            >
                {o.display}
            </FilterEntry>
        );
    };

    return (
        <Box borderBottom={!!borderBottom}>
            <FilterEntry
                testKey={testPrefix === undefined ? undefined : `${testPrefix}--filter--${categoryValue}`}
                isActive={selected === categoryValue}
                onChange={v => (v ? onChange(categoryValue) : onChange())}
                marginBottom={4}
                type='primary'
            >
                {title}
            </FilterEntry>
            <Box>{options.map(renderOption)}</Box>
        </Box>
    );
};
