import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, OuterBoxProps, Paragraph3 } from '@hofy/ui';

interface PairsTableTooltipProps extends OuterBoxProps {
    items: [string, string][];
}

export const PairsTableTooltipOverlay: FC<PairsTableTooltipProps> = ({ items, ...rest }) => (
    <Wrapper columnGap={12} rowGap={4} {...rest}>
        {items.map(([key, value]) => (
            <Fragment key={key}>
                <KeyColumn textNoWrap color={Color.ContentInvertedSecondary}>
                    {key}
                </KeyColumn>
                <Paragraph3 textNoWrap color={Color.ContentInvertedPrimary}>
                    {value}
                </Paragraph3>
            </Fragment>
        ))}
    </Wrapper>
);

const Wrapper = styled(Box)`
    display: grid;
    grid-template-columns: auto auto;
`;

const KeyColumn = styled(Paragraph3)`
    justify-self: end;
`;
