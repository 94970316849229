import React, { FC } from 'react';

import { useItems } from '@hofy/api-admin';
import {
    allFilterableItemStatuses,
    ItemLocation,
    ItemStatus,
    Ownership,
    ParentProductCategory,
    productCategoriesHierarchy,
    ProductCategory,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useFilters } from '@hofy/ui';

import { DevicesTable } from './DevicesTable';
import {
    useCategoryFilterDefinition,
    useItemStatusFilterDefinition,
    useLocationFilterDefinition,
} from './filters';
import { useAddonFilterDefinition } from './filters/useAddonFilterDefinition';
import { useOwnershipFilterDefinition } from './filters/useOwnershipFilterDefinition';

interface OrganizationDevicesTabProps {
    search: string;
    organizationId: UUID;
    selectedItemsIds: UUID[];
    onEntryClick(id: UUID): void;
    setSelectedItemsIds(ids: UUID[]): void;
}

interface OrganizationDevicesFilters {
    category: ProductCategory[];
    location: ItemLocation[];
    status: ItemStatus[];
    ownership: Ownership | null;
    withAddon: UUID[];
    withoutAddon: UUID[];
}

export const OrganizationDevicesTab: FC<OrganizationDevicesTabProps> = ({
    search,
    organizationId,
    selectedItemsIds,
    setSelectedItemsIds,
    onEntryClick,
}) => {
    const filters = useFilters<OrganizationDevicesFilters>(
        {
            category: useCategoryFilterDefinition(productCategoriesHierarchy[ParentProductCategory.Devices]),
            location: useLocationFilterDefinition(),
            status: useItemStatusFilterDefinition(allFilterableItemStatuses),
            ownership: useOwnershipFilterDefinition(),
            withAddon: useAddonFilterDefinition(),
            withoutAddon: useAddonFilterDefinition(),
        },
        {
            category: productCategoriesHierarchy[ParentProductCategory.Devices],
            location: [],
            status: [],
            ownership: null,
            withAddon: [],
            withoutAddon: [],
        },
    );

    const { items, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useItems({
        search,
        organization: [organizationId],
        category: filters.filterValues.category,
        status: filters.filterValues.status,
        location: filters.filterValues.location,
        ownership: filters.filterValues.ownership,
        withAddon: filters.filterValues.withAddon,
        withoutAddon: filters.filterValues.withoutAddon,
        redistributableIn: null,
        variant: [],
        warehouseId: null,
        grade: [],
        filter: null,
    });

    return (
        <DevicesTable
            items={items}
            infinityScroll={{
                isLoading: isLoading,
                isLoadingMore: isFetchingNextPage,
                hasMore: hasNextPage,
                loadMore: fetchNextPage,
            }}
            filters={filters}
            onEntryClick={onEntryClick}
            selectedItemsIds={selectedItemsIds}
            setSelectedItemsIds={setSelectedItemsIds}
        />
    );
};
