import { ReactNode } from 'react';
import styled from 'styled-components';

const filteredProps = [
    'size',
    'width',
    'height',
    'cursor',
    'pointer',
    'wrap',
    'onResize',
    'overflow',
    'color',
    'spacing',
    'orientation',
    'offset',
];

const forwardProps = [
    // framer-motion props when using as={motion.div} prop
    'layout',
    'layoutId',
    'initial',
    'animate',
    'exit',
    'transition',
    'variants',
];

export interface BaseBoxProps {
    className?: string;
    children?: ReactNode;
}

export const BaseBox = styled.div.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
        forwardProps.includes(prop) || (!filteredProps.includes(prop) && defaultValidatorFn(prop)),
})<BaseBoxProps>({});
