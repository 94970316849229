import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { Pressable } from '../../base';
import { FeaturedIcon } from '../../icon';

export const StyledFeaturedIcon = styled(FeaturedIcon)``;

export const ActionPressable = styled(Pressable)`
    ${p =>
        !p.inactive &&
        css`
            &:hover {
                background-color: ${Color.NonContextualVioletSurface};
                border-color: ${Color.NonContextualVioletSurfaceBorder};
                ${StyledFeaturedIcon} {
                    background-color: ${Color.NonContextualVioletMuted};
                }
            }
        `}
`;
