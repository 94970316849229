import { ShipmentStatus } from '@hofy/api-shared';
import { DateString } from '@hofy/global';

interface HookProps {
    status: ShipmentStatus;

    createdOn: DateString | null;
    bookedOn: DateString | null;
    shippedOn: DateString | null;
    completedOn: DateString | null;
    backorderOn: DateString | null;
    cancelledOn: DateString | null;
}

export const useShipmentDate = ({
    status,
    createdOn,
    bookedOn,
    shippedOn,
    completedOn,
    backorderOn,
    cancelledOn,
}: HookProps): DateString | null => {
    const shipmentDate: Record<ShipmentStatus, DateString | null> = {
        [ShipmentStatus.Created]: createdOn,
        [ShipmentStatus.Booked]: bookedOn,
        [ShipmentStatus.WithCourier]: shippedOn,
        [ShipmentStatus.Completed]: completedOn,
        [ShipmentStatus.Backorder]: backorderOn,
        [ShipmentStatus.Canceled]: cancelledOn,
    };

    return shipmentDate[status];
};
