import { OperatingSystem, useTrOperatingSystem } from '@hofy/api-shared';

interface ProductTooltipInfo {
    size: string | null;
    style: string | null;
    os: OperatingSystem | null;
    serial: string | null;
    mpc: string | null;
    sku: string | null;
}

export const useProductTooltipItems = ({
    size,
    style,
    os,
    serial,
    mpc,
    sku,
}: ProductTooltipInfo): [string, string][] => {
    const trOs = useTrOperatingSystem();

    return [
        ['Variant', [style, size, os ? trOs(os) : null].filter(v => v).join('/') || null],
        ['Serial', serial],
        ['MPC', mpc],
        ['SKU', sku],
    ].filter((item): item is [string, string] => !!item[1]);
};
