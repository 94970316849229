import React, { FC } from 'react';

import { DateRangeStrings, DateString } from '@hofy/global';
import { formatDate, formatDateRange } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, Paragraph4 } from '@hofy/ui';

interface ShipmentDateCellProps extends BoxProps {
    title: string;
    date: DateString | null;
}

export const ShipmentDateCell: FC<ShipmentDateCellProps> = ({ title, date, ...boxProps }) => {
    return (
        <Box column gap={4} {...boxProps}>
            <Paragraph4 color={Color.ContentTertiary}>{title}</Paragraph4>
            <Paragraph3>{formatDate(date)}</Paragraph3>
        </Box>
    );
};

interface ShipmentDateRangeCellProps extends BoxProps {
    title: string;
    dateRange: DateRangeStrings | null;
}

export const ShipmentDateRangeCell: FC<ShipmentDateRangeCellProps> = ({ title, dateRange, ...boxProps }) => {
    return (
        <Box column gap={4} {...boxProps}>
            <Paragraph4 color={Color.ContentTertiary}>{title}</Paragraph4>
            <Paragraph3>{formatDateRange(dateRange)}</Paragraph3>
        </Box>
    );
};
