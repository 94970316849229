import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { formatMonth, now } from '@hofy/helpers';

import { FontProps, Paragraph3 } from '../../base';

interface MonthLabelProps extends FontProps {
    date: DateTime;
}

export const MonthLabel: FC<MonthLabelProps> = ({ date, ...props }) => {
    return (
        <Paragraph3 whiteSpace='nowrap' bold={date > now()} {...props}>
            {formatMonth(date)}
        </Paragraph3>
    );
};
