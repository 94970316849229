import { noop } from 'lodash';

import { AddonPoolTokenMultiplierDto, AddonPoolTokenQuantityDto } from '@hofy/api-admin';
import { AddonTarget } from '@hofy/api-shared';
import { useForm } from '@hofy/ui';

import { getInitialAddonDataDependencies } from './types/AddonDataDependency';
import { AddonPoolForm } from './types/AddonPoolForm';

interface UseAddonPoolFormsOptions {
    addonTarget: AddonTarget;
    quantities: AddonPoolTokenQuantityDto[];
    multipliers: AddonPoolTokenMultiplierDto[];
}

export const useAddonPoolForm = ({ addonTarget, quantities, multipliers }: UseAddonPoolFormsOptions) => {
    return useForm<AddonPoolForm>({
        initial: {
            quantityDependencies: getInitialAddonDataDependencies(addonTarget, quantities),
            multiplierDependencies: getInitialAddonDataDependencies(addonTarget, multipliers),
            quantities: quantities,
            multipliers: multipliers,
        },
        onSubmit: noop,
    });
};
