import React, { FC, useState } from 'react';

import { allAuditLogsTabFilters, AuditLogsTabFilter } from '@hofy/api-admin';
import {
    BackButton,
    ComponentLoader,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutTabbedHeader,
} from '@hofy/common';
import { UUID } from '@hofy/global';
import { SvgIcon, Tab, Tabs } from '@hofy/ui';

import { useAuditLog } from '../../store/auditLogs/useAuditLog';
import { AuditLogsDetailsSlideoutBaseData } from './AuditLogDetailSlideoutBaseData';
import { AuditLogsDetailsSlideoutFields } from './AuditLogDetailSlideoutFields';

interface AuditLogsDetailsSlideoutProps {
    auditId: UUID;
    onClose(): void;
}

export const AuditLogsDetailsSlideout: FC<AuditLogsDetailsSlideoutProps> = ({ onClose, auditId }) => {
    const { auditLog, changeRecord } = useAuditLog(auditId);
    const [tabName, setTabName] = useState<AuditLogsTabFilter>(AuditLogsTabFilter.Data);

    const content = () => {
        if (!auditLog) {
            return <ComponentLoader />;
        }

        switch (tabName) {
            case AuditLogsTabFilter.Data:
                return <AuditLogsDetailsSlideoutBaseData auditLog={auditLog} />;
            case AuditLogsTabFilter.Fields:
                return <AuditLogsDetailsSlideoutFields changedData={changeRecord} />;
        }
    };

    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutTabbedHeader
                title={`Audit log #${auditLog?.id}`}
                tabsSlot={
                    <Tabs active={tabName} onChange={tab => setTabName(tab)}>
                        {allAuditLogsTabFilters.map(item => (
                            <Tab key={item} id={item} label={item} />
                        ))}
                    </Tabs>
                }
            />
            <SlideoutContent paddingVertical={30}>{content()}</SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
            </SlideoutFooter>
        </Slideout>
    );
};
