import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';

import { easeInOutTransition } from '../../config/animations';
import { Box, BoxProps } from '../base';

export interface RotatedProps extends BoxProps {
    angle: number;
    children: ReactNode;
}

export const Rotated: FC<RotatedProps> = ({ angle, children, ...props }) => {
    return (
        <Box as={motion.div} animate={{ rotate: angle }} transition={easeInOutTransition} {...props}>
            {children}
        </Box>
    );
};
