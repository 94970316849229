import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3, Paragraph4 } from '../../base';
import { LabelError } from '../../labeled/index';

interface ToggleLabelProps extends OuterBoxProps {
    label?: ReactNode;
    errorMessage?: string;
    helperText?: string;
    disabled?: boolean;
    isRequired?: boolean;
    onClick?(): void;
    children?: ReactNode;
    id?: string;
}

export const ToggleLabel: FC<ToggleLabelProps> = ({
    label,
    errorMessage,
    helperText,
    disabled,
    isRequired,
    children,
    onClick,
    id,
    ...rest
}) => {
    const getLabel = () => {
        if (typeof label === 'string') {
            return (
                <Paragraph3 color={Color.ContentPrimary} data-test-key='labeled-label'>
                    {label}
                    {isRequired && '*'}
                </Paragraph3>
            );
        }
        return label;
    };

    const errorNode = errorMessage && (
        <ErrorGrid>
            <LabelError message={errorMessage} />
        </ErrorGrid>
    );
    const helperNode = helperText && <HelperText color={Color.ContentTertiary}>{helperText}</HelperText>;

    return (
        <GridBox {...rest} inactive={disabled}>
            {children}
            <Box id={id} onClick={disabled ? undefined : onClick} pointer={onClick && !disabled}>
                {getLabel()}
            </Box>
            {errorNode || helperNode}
        </GridBox>
    );
};

const GridBox = styled(Box)`
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: 0 8px;
    grid-row-gap: 6px;
    justify-content: start;
    align-items: center;
`;

const HelperText = styled(Paragraph4)`
    grid-column-start: 2;
    grid-row-start: 2;
`;

const ErrorGrid = styled.div`
    grid-row-start: 2;
    grid-column: span 2;
`;
