import React, { FC, memo } from 'react';

import { PayoutDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import {
    IdColumn,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Placeholder,
    SvgIllustration,
    TextCell,
} from '@hofy/ui';

import { useTrPayoutProvider } from '../../../store/payouts/useTrPayoutProvider';
import { JournalLinkLabel } from '../journals/JournalLink';

interface PayoutsTableProps {
    payouts: PayoutDto[];
    infinityScroll: InfiniteScrollConfig;
}

const PayoutsTableComponent: FC<PayoutsTableProps> = ({ payouts, infinityScroll }) => {
    const { formatPrice } = usePrice();
    const trPayoutProvider = useTrPayoutProvider();
    return (
        <InfinityScrollTable
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No payouts'
                    message='No payouts for selected criteria'
                />
            }
            flex='auto'
            data={payouts}
            toKey={payout => payout.id}
            infinityScroll={infinityScroll}
            columns={[
                IdColumn<PayoutDto>(),
                {
                    id: 'externalId',
                    header: 'External Id',
                    flexGrow: 2,
                    renderer: payout => (
                        <Paragraph3 alignContent='baseline' wordBreak>
                            {payout.externalId}
                        </Paragraph3>
                    ),
                },
                {
                    id: 'arrivedOn',
                    header: 'Date',
                    flexGrow: 1,
                    renderer: payout => formatDate(payout.arrivedOn),
                },
                {
                    id: 'subsidiary',
                    header: 'Subsidiary',
                    flexGrow: 1,
                    renderer: payout => payout.hofySubsidiary.name,
                },
                {
                    id: 'amount',
                    header: 'Amount',
                    flexGrow: 1,
                    renderer: payout => <Paragraph3>{formatPrice(payout.amount)}</Paragraph3>,
                },
                {
                    id: 'provider',
                    header: 'Provider',
                    renderer: payout => trPayoutProvider(payout.provider),
                },
                {
                    id: 'journalId',
                    header: 'Journal',
                    flexGrow: 0,
                    width: 120,
                    renderer: payout => (
                        <TextCell>
                            <JournalLinkLabel id={payout.journalId} />
                        </TextCell>
                    ),
                },
            ]}
        />
    );
};

export const PayoutsTable = memo(PayoutsTableComponent);
