import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { pathUuid, UUID } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { EnumRoute, UUIDRoute } from '@hofy/router';

import { CreateRepairForItemSlideoutRouter } from '../repairsPage/slideouts/createRepair/CreateRepairForItemSlideoutRouter';
import { AdminAssignmentSlideoutTabs } from './AdminAssignmentSlideoutTabs';
import { AssignmentSlideoutContent } from './AssignmentSlideoutContent';
import { ConfigureProductSlideout } from './configureProductSlideout/ConfigureProductSlideout';
import { DataErasureSlideout } from './dataErasureSlideout/DataErasureSlideout';
import { CreateConsumableSlideout } from './variantPickerSlideout/CreateConsumableSlideout';
import {
    PickCustomProductSlideout,
    SubstituteProductSlideout,
} from './variantPickerSlideout/VariantPickerSlideout';

interface OrderSlideoutRouterProps {
    base: string;
    shipmentsBase: string;
    onCloseBase: string;
}

export const AssignmentSlideoutRouter: FC<OrderSlideoutRouterProps> = ({
    base,
    shipmentsBase,
    onCloseBase,
}) => {
    const history = useHistory();

    const handleTabChange = (assignmentId: UUID, tab: AdminAssignmentSlideoutTabs) => {
        history.replace(`${base}/${assignmentId}/${tab}${history.location.search}`);
    };

    const handleSubstituteProduct = (assignmentId: UUID) => {
        history.push(`${base}/${assignmentId}/details/substitute${history.location.search}`);
    };

    const handleConfigureProduct = (assignmentId: UUID) => {
        history.push(`${base}/${assignmentId}/details/configure${history.location.search}`);
    };

    const handleEraseDevice = (assignmentId: UUID) => {
        history.push(`${base}/${assignmentId}/details/data-erasure${history.location.search}`);
    };

    const handlePickCustomProduct = (assignmentId: UUID) => {
        history.push(`${base}/${assignmentId}/details/custom-product${history.location.search}`);
    };

    const handleShipmentClick = (shipmentId: UUID) => {
        history.push(`${shipmentsBase}/${shipmentId}/details${history.location.search}`);
    };

    const handleCreateRepair = (repairId: UUID) => {
        history.push(`${base}/create-repair/${repairId}${history.location.search}`);
    };

    const handleCreateConsumable = (assignmentId: UUID) => {
        history.push(`${base}/${assignmentId}/details/create-consumable${history.location.search}`);
    };

    return (
        <>
            <AssignmentSlideoutRoute path={`${base}/:assignmentId(${pathUuid})`}>
                {({ assignmentId }) => (
                    <Switch>
                        <AssignmentSlideoutTabRoute path={`${base}/:assignmentId(${pathUuid})/:activeTab`}>
                            {({ activeTab }) => (
                                <AssignmentSlideout
                                    base={base}
                                    onCloseBase={onCloseBase}
                                    assignmentId={assignmentId}
                                    activeTab={activeTab}
                                    handleTabChange={handleTabChange}
                                    onSubstituteProduct={() => handleSubstituteProduct(assignmentId)}
                                    onConfigureProduct={() => handleConfigureProduct(assignmentId)}
                                    onEraseDevice={() => handleEraseDevice(assignmentId)}
                                    onPickCustomProduct={() => handlePickCustomProduct(assignmentId)}
                                    onShipmentClick={handleShipmentClick}
                                    onCreateRepair={handleCreateRepair}
                                    onCreateConsumable={() => handleCreateConsumable(assignmentId)}
                                />
                            )}
                        </AssignmentSlideoutTabRoute>
                        <Redirect
                            to={`${base}/${assignmentId}/${AdminAssignmentSlideoutTabs.Details}${history.location.search}`}
                        />
                    </Switch>
                )}
            </AssignmentSlideoutRoute>
            <CreateRepairForItemSlideoutRouter
                base={`${base}/create-repair`}
                onClose={() => history.push(onCloseBase)}
            />
        </>
    );
};

interface AssignmentSlideoutProps {
    base: string;
    onCloseBase: string;
    assignmentId: UUID;
    activeTab: AdminAssignmentSlideoutTabs;
    onSubstituteProduct(): void;
    onConfigureProduct(): void;
    onEraseDevice(): void;
    onPickCustomProduct(): void;
    handleTabChange(assignmentId: UUID, tab: AdminAssignmentSlideoutTabs): void;
    onShipmentClick(shipmentId: UUID): void;
    onCreateRepair(itemId: UUID): void;
    onCreateConsumable(): void;
}

const AssignmentSlideout: FC<AssignmentSlideoutProps> = ({
    base,
    onCloseBase,
    onSubstituteProduct,
    onConfigureProduct,
    onEraseDevice,
    onPickCustomProduct,
    assignmentId,
    activeTab,
    handleTabChange,
    onShipmentClick,
    onCreateRepair,
    onCreateConsumable,
}) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const historyPush = (path: string) => {
        history.push(`${path}${history.location.search}`);
    };

    const handleGoBack = () => {
        goBack(`${base}/${assignmentId}/${activeTab}`);
    };

    return (
        <>
            <AssignmentSlideoutContent
                assignmentId={assignmentId}
                activeTab={activeTab}
                onTabChange={tabName => handleTabChange(assignmentId, tabName)}
                onClose={() => historyPush(onCloseBase)}
                onSubstituteProduct={onSubstituteProduct}
                onConfigureProduct={onConfigureProduct}
                onEraseDevice={onEraseDevice}
                onPickCustomProduct={onPickCustomProduct}
                onShipmentClick={onShipmentClick}
                onCreateRepair={onCreateRepair}
                onCreateConsumable={onCreateConsumable}
            />
            <Route path={`${base}/:assignmentId(${pathUuid})/:tabName/substitute`} exact>
                <SubstituteProductSlideout assignmentId={assignmentId} onClose={handleGoBack} />
            </Route>
            <Route path={`${base}/:assignmentId(${pathUuid})/:tabName/configure`} exact>
                <ConfigureProductSlideout
                    basePath={`${base}/${assignmentId}/${activeTab}`}
                    assignmentId={assignmentId}
                    onClose={handleGoBack}
                />
            </Route>
            <Route path={`${base}/:assignmentId(${pathUuid})/:tabName/data-erasure`} exact>
                <DataErasureSlideout assignmentId={assignmentId} onClose={handleGoBack} />
            </Route>
            <Route path={`${base}/:assignmentId(${pathUuid})/:tabName/custom-product`} exact>
                <PickCustomProductSlideout onClose={handleGoBack} assignmentId={assignmentId} />
            </Route>
            <Route path={`${base}/:assignmentId(${pathUuid})/:tabName/create-consumable`} exact>
                <CreateConsumableSlideout onClose={handleGoBack} assignmentId={assignmentId} />
            </Route>
        </>
    );
};

const AssignmentSlideoutRoute = UUIDRoute('assignmentId', Route);
const AssignmentSlideoutTabRoute = EnumRoute<AdminAssignmentSlideoutTabs>(
    'activeTab',
    AdminAssignmentSlideoutTabs,
    Route,
);
