import React, { FC } from 'react';

import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, Tooltip } from '@hofy/ui';

interface OffboardedBadgeProps {
    offboardedOn: DateString | null;
    lastWorkingDayOn: DateString | null;
}

export const OffboardedBadge: FC<OffboardedBadgeProps> = ({ offboardedOn, lastWorkingDayOn }) => {
    if (!offboardedOn) {
        return null;
    }

    return (
        <Tooltip
            body={`Offboarded on ${formatDate(offboardedOn)}\nLast working day on ${formatDate(
                lastWorkingDayOn,
            )}`}
            asChild
        >
            <Badge color='red' label={`OFFBOARDED - ${formatDate(lastWorkingDayOn)}`} dot />
        </Tooltip>
    );
};
