import React, { FC } from 'react';

import { useActivityLogs } from '@hofy/api-admin';
import { Page } from '@hofy/common';
import { FilterSearchInput, PageHeader } from '@hofy/ui';

import { useActivityLogsFilters } from '../../store/activityLogs/useActivityLogsFilters';
import { ActivityLogTable } from './components/ActivityLogTable';

export const ActivityLogsPage: FC = () => {
    const { filters, filterValues } = useActivityLogsFilters();

    const { activityLogs, activityLogsIsLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useActivityLogs(filterValues);

    return (
        <Page>
            <PageHeader
                title='Activity Logs'
                rightSlot={<FilterSearchInput filter={filters.search} autoFocus />}
            />

            <ActivityLogTable
                activityLogs={activityLogs}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: activityLogsIsLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />
        </Page>
    );
};
