import { allOwnerships, Ownership, useTrOwnership } from '@hofy/api-shared';
import { queryOptionalStringParser } from '@hofy/router';
import { FilterDefinitionSingle } from '@hofy/ui';

export const useOwnershipFilterDefinition = (): FilterDefinitionSingle<Ownership | null> => {
    const trOwnership = useTrOwnership();

    return {
        type: 'single',
        name: 'Ownership',
        query: queryOptionalStringParser<Ownership>(),
        toLabel: trOwnership,
        allValues: allOwnerships,
    };
};
