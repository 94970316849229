import { stringifyUrl } from '@hofy/global';
import { downloadFile, nowISODate } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { ExportDto } from './types/ExportDto';
import { ExportField } from './types/ExportField';

class ExportImportService {
    public export = async (fields: ExportField[]): Promise<void> => {
        await restClient
            .getJson(
                stringifyUrl({
                    url: '/api/admin/export',
                    query: {
                        field: fields,
                    },
                }),
            )
            .then(blob =>
                downloadFile(
                    new Blob([JSON.stringify(blob)], { type: 'application/json' }),
                    `hofy-master-data-export-${nowISODate()}.json`,
                ),
            );
    };

    public import = async (data: ExportDto): Promise<void> => {
        await restClient.post('/api/admin/import', data);
    };
}

export const exportImportService = new ExportImportService();
