import React, { FC } from 'react';
import styled from 'styled-components';

import { SpecificationDto } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, SectionTitle1 } from '@hofy/ui';

import { useProductI18n } from '../store/useProductI18n';

interface ProductSpecificationsProps {
    specifications: SpecificationDto[];
}

export const ProductSpecifications: FC<ProductSpecificationsProps> = ({ specifications }) => {
    const { tr } = useProductI18n();

    return (
        <>
            {specifications.length > 0 && (
                <Box gap={24} column marginTop={24}>
                    <SectionTitle1>{tr('product.specifications')}</SectionTitle1>
                    <Box column>
                        {specifications.map((specification, index) => (
                            <SpecificationRow alignItems='flex-start' row key={index} paddingVertical={6}>
                                <Paragraph3 bold color={Color.ContentPrimary}>
                                    {specification.name}
                                </Paragraph3>
                                <Paragraph3>{specification.value}</Paragraph3>
                            </SpecificationRow>
                        ))}
                    </Box>
                </Box>
            )}
        </>
    );
};

const SpecificationRow = styled(Box)`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;
