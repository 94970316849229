export const dateI18n = {
    'date.today': 'today',
    'date.tomorrow': 'tomorrow',
};

export const monthI18n = {
    'month.empty': '0 months',
    'month.single': '{value} month',
    'month.plural': '{value} months',
};
