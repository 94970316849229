import { HardwarePolicyDto } from './HardwarePolicyDto';
import { ItPolicyRefDto } from './ItPolicyRefDto';

export interface ItPolicyDto extends ItPolicyRefDto {
    note: string;
    hardwarePolicy: HardwarePolicyDto;
}

export const isPolicyNameUsed = (teams: ItPolicyDto[] | undefined = [], name: string | undefined = '') => {
    const newName = name.toLowerCase();
    return teams.some(team => team.name.toLowerCase() === newName);
};
