import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';
import { Box, LabeledInput, Modals, Paragraph3, SectionTitle3 } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';
import { SecretModal } from './SecretModal';

interface QRCodeStepProps {
    code: string;
    qrCodeUrl: string;
    secret: string;
    verificationError: boolean;
    setCode(v: string): void;
}

export const QRCodeStep: FC<QRCodeStepProps> = ({ code, setCode, qrCodeUrl, secret, verificationError }) => {
    const { trStyled, tr } = useAuthI18n();
    const [secretInfoOpen, setSecretInfoOpen] = useState(false);

    return (
        <Box>
            <SectionTitle3>{tr('two-factor-slideout.scan-qr.title')}</SectionTitle3>
            <Paragraph3
                marginTop={4}
                cursor='pointer'
                onClick={() => setSecretInfoOpen(true)}
                data-test-key='code-link'
            >
                {trStyled('two-factor-slideout.scan-qr.subtitle', { color: Color.ContentBrand })}
            </Paragraph3>

            <Box paddingTop={20} paddingBottom={20}>
                <QrImg src={qrCodeUrl} />
            </Box>

            <SectionTitle3>{tr('two-factor-slideout.code.title')}</SectionTitle3>
            <Paragraph3 marginTop={4}>{tr('two-factor-slideout.code.subtitle')}</Paragraph3>

            <Box marginTop={30}>
                <LabeledInput
                    label={tr('two-factor-slideout.authentication-code.label')}
                    value={code}
                    onChange={c => setCode(c)}
                    testKey='auth-code-input'
                    errorMessage={
                        verificationError ? tr('two-factor-slideout.authentication-code.error') : undefined
                    }
                />
            </Box>
            <Modals>
                {secretInfoOpen && <SecretModal onClose={() => setSecretInfoOpen(false)} secret={secret} />}
            </Modals>
        </Box>
    );
};

const QrImg = styled.img`
    border-radius: 8px;
    padding: 5px;
    border: 1px solid ${Color.NonContextualBorderDivider};
`;
