import { FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import React, { forwardRef, HTMLProps } from 'react';
import styled from 'styled-components';

import { ZIndex } from '@hofy/theme';

import { TooltipState } from './hooks/useTooltip';

interface TooltipContentProps extends HTMLProps<HTMLDivElement> {
    state: TooltipState;
    interactive: boolean;
}

export const TooltipContent = forwardRef<HTMLDivElement, TooltipContentProps>(
    ({ state, interactive, children, ...props }, propRef) => {
        const ref = useMergeRefs([state.data.refs.setFloating, propRef]);

        const { isMounted, styles } = useTransitionStyles(state.data.context, {
            duration: 250,
            initial: { opacity: 0 },
        });

        if (!isMounted) {
            return null;
        }

        return (
            <FloatingPortal>
                <Container
                    ref={ref}
                    style={{
                        ...state.data.floatingStyles,
                        ...props.style,
                        ...styles,
                    }}
                    interactive={interactive}
                    {...state.interactions.getFloatingProps(props)}
                >
                    {children}
                </Container>
            </FloatingPortal>
        );
    },
);

const Container = styled.div<{ interactive: boolean }>`
    pointer-events: ${({ interactive }) => (interactive ? 'auto' : 'none')};
    z-index: ${ZIndex.Tooltip};
`;
