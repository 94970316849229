export interface SeedLocalOrganizationPayload {
    organization: string;
    firstName: string;
    lastName: string;
    email: string;
}

export const emptySeedLocalOrganizationPayload: SeedLocalOrganizationPayload = {
    organization: '',
    firstName: '',
    lastName: '',
    email: '',
};
