import React, { FC } from 'react';

import { ActorDto, ActorType } from '@hofy/api-shared';
import { Box, Icon, SvgIcon } from '@hofy/ui';

import { AdminDetailsLink } from '../../peoplePage/admins/AdminLink';
import { UserDetailsLink } from '../../peoplePage/users/UserLink';

interface ActorLabelProps {
    actor: ActorDto | null;
}

export const ActorLabel: FC<ActorLabelProps> = ({ actor }) => {
    const content = () => {
        if (!actor) {
            return '--';
        }
        switch (actor.actorType) {
            case ActorType.Api:
                return (
                    <>
                        <Icon size={12} svg={SvgIcon.Monitor} marginRight={4} />
                        {actor.name}
                    </>
                );
            case ActorType.Admin:
                return (
                    <AdminDetailsLink
                        admin={{
                            id: actor.adminId!,
                            firstName: actor.name,
                            lastName: '',
                        }}
                    />
                );
            case ActorType.User:
                return (
                    <UserDetailsLink
                        user={{
                            id: actor.userId!,
                            firstName: actor.name,
                            lastName: '',
                        }}
                    />
                );
        }
    };

    return <Box row>{content()}</Box>;
};
