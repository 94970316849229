import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { Color } from '@hofy/theme';

import { useIsDisabled } from '../../../contexts';
import { TestKeyAware } from '../../../types';
import { OuterBoxProps, Pressable } from '../../base';

export interface RadioProps extends OuterBoxProps, TestKeyAware {
    checked: boolean;
    onChange?(v: boolean): void;
    disabled?: boolean;
    isError?: boolean;
    id?: string;
}

/**
 * Radio without `onChange` is just a visual component, useful when you want to put it inside a `Pressable` component
 **/
export const Radio: FC<RadioProps> = ({
    checked,
    onChange,
    disabled: radioDisabled,
    isError,
    id,
    testKey,
    ...rest
}) => {
    const disabled = useIsDisabled(radioDisabled);

    return (
        <RadioBox
            as={onChange ? 'button' : 'span'}
            role={onChange ? 'switch' : undefined}
            onClick={() => {
                if (!disabled) {
                    onChange?.(!checked);
                }
            }}
            checked={!!checked}
            inactive={disabled}
            isError={isError}
            rounded={16}
            rect={16}
            centered
            aria-checked={checked}
            aria-labelledby={id}
            data-test-key={testKey}
            {...rest}
        />
    );
};

const hover = css`
    &:hover {
        border-color: ${Color.InteractionDefaultHover};
    }
`;

const hoverChecked = css`
    &:hover {
        border-color: ${Color.InteractionDefaultHover};
        background-color: ${Color.InteractionDefaultHover};
    }
`;

const active = css`
    &:active {
        border-color: ${Color.InteractionDefaultActive};
    }
`;

const activeChecked = css`
    &:active {
        border-color: ${Color.InteractionDefaultActive};
        background-color: ${Color.InteractionDefaultActive};
    }
`;

const RadioBox = styled(Pressable)<{ checked: boolean; isError?: boolean }>`
    border: 1px solid
        ${p =>
            p.isError
                ? Color.InteractionBorderAlert
                : p.checked
                ? Color.InteractionDefaultNormal
                : Color.InteractionNeutralNormal};
    background-color: ${p =>
        p.checked ? Color.InteractionDefaultNormal : Color.InteractionBackgroundFormField};
    ${p => (p.inactive ? undefined : p.checked ? hoverChecked : hover)}
    ${p => (p.inactive ? undefined : p.checked ? activeChecked : active)}
    transition: all 150ms ease-in-out;

    &::after {
        content: '';
        background-color: ${Color.InteractionBackgroundFormField};
        border-radius: 50%;
        width: 6px;
        height: 6px;
    }
`;
