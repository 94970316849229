import { useQuery } from '@tanstack/react-query';

import { accountingService } from '../service/accountingService';
import { netsuiteConfigurationCacheKey } from './cacheKey';

export const useNetsuiteConfiguration = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [netsuiteConfigurationCacheKey],
        queryFn: accountingService.getNetsuiteConfiguration,
    });

    return {
        netsuiteConfiguration: data,
        isLoading: isLoading,
        isError: isError,
    };
};
