import React, { FC } from 'react';

import { CollectionShipmentLabelsStatus } from '@hofy/api-shared';
import { ColorDye } from '@hofy/global';
import { Badge, Box } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface ShippingLabelsResultProps {
    labelStatus: CollectionShipmentLabelsStatus | null;
}

export const ShippingLabelsResult: FC<ShippingLabelsResultProps> = ({ labelStatus }) => {
    const status = labelStatus || CollectionShipmentLabelsStatus.Required;

    const color: Record<CollectionShipmentLabelsStatus, ColorDye> = {
        [CollectionShipmentLabelsStatus.Required]: 'red',
        [CollectionShipmentLabelsStatus.UserWillPrintLabels]: 'green',
        [CollectionShipmentLabelsStatus.AssistanceRequested]: 'red',
        [CollectionShipmentLabelsStatus.LabelsSentToUser]: 'orange',
        [CollectionShipmentLabelsStatus.UserReceivedLabels]: 'green',
    };

    const text: Record<CollectionShipmentLabelsStatus, string> = {
        [CollectionShipmentLabelsStatus.Required]: 'No',
        [CollectionShipmentLabelsStatus.UserWillPrintLabels]: 'Yes',
        [CollectionShipmentLabelsStatus.AssistanceRequested]: 'No',
        [CollectionShipmentLabelsStatus.LabelsSentToUser]: 'Sent',
        [CollectionShipmentLabelsStatus.UserReceivedLabels]: 'Received',
    };

    const chipStatus: Record<CollectionShipmentLabelsStatus, SurveyResultsSectionCardChipStatus> = {
        [CollectionShipmentLabelsStatus.Required]: SurveyResultsSectionCardChipStatus.Negative,
        [CollectionShipmentLabelsStatus.UserWillPrintLabels]: SurveyResultsSectionCardChipStatus.Neutral,
        [CollectionShipmentLabelsStatus.AssistanceRequested]: SurveyResultsSectionCardChipStatus.Negative,
        [CollectionShipmentLabelsStatus.LabelsSentToUser]: SurveyResultsSectionCardChipStatus.Warning,
        [CollectionShipmentLabelsStatus.UserReceivedLabels]: SurveyResultsSectionCardChipStatus.Neutral,
    };

    return (
        <SurveyResultsSectionCardChip title='Shipping labels' status={chipStatus[status]}>
            <Box row>
                <Badge color={color[status]} label={text[status]} />
            </Box>
        </SurveyResultsSectionCardChip>
    );
};
