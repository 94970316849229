import React from 'react';

import { Tab, Tabs } from '@hofy/ui';

import { allShipmentTabs, ShipmentTab } from '../../store/shipments/types/ShipmentTab';
import { useTrAdminShipmentFilter } from '../../store/shipments/useTrAdminShipmentFilter';

interface ShipmentsPageTabsProps {
    tab: ShipmentTab;
    onChange(tab: ShipmentTab): void;
}

export const ShipmentsPageTabs = ({ tab, onChange }: ShipmentsPageTabsProps) => {
    const trTab = useTrAdminShipmentFilter();

    return (
        <Tabs active={tab} onChange={onChange} animationId='shipments-tabs'>
            {allShipmentTabs.map(tab => (
                <Tab key={tab} id={tab} label={trTab(tab)} />
            ))}
        </Tabs>
    );
};
