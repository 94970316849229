import { uniqueId } from 'lodash';
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useList, useTimeoutFn } from 'react-use';

import { useStructMemo } from '@hofy/hooks';

import { ToastList } from './ToastList';
import { ToastItem, ToastItemWithoutId, ToastsContext, ToastsContextType } from './ToastsContext';

interface ToastsContextProps {
    children?: ReactNode;
}

export const ToastsContextProvider: FC<ToastsContextProps> = ({ children }) => {
    const [toasts, { push, removeAt, sort }] = useList<ToastItem>();
    const invertedToasts = useMemo(() => [...toasts].reverse(), [toasts]);

    const [, cancel, reset] = useTimeoutFn(() => {
        removeAt(-1);
    }, 3000);

    useEffect(() => {
        if (toasts.length > 0) {
            reset();
        }
    }, [toasts]);

    const showToast = useCallback(
        (toast: ToastItemWithoutId) => {
            if (toast.id) {
                hideToast(toast.id);
            }
            const key = uniqueId('toast-');
            const { id = key } = toast;
            push({ ...toast, id, key });
        },
        [toasts],
    );

    const hideToast = useCallback(
        (id: string) => {
            const index = toasts.findIndex(item => item.id === id);
            if (index >= 0) {
                removeAt(index);
            }
        },
        [toasts],
    );

    const dismiss = useCallback(
        (id: string) => {
            sort(a => (a.id === id ? -1 : 0));
        },
        [toasts],
    );

    const value = useStructMemo<ToastsContextType>({
        toasts: invertedToasts,
        showToast,
        hideToast,
    });

    return (
        <ToastsContext.Provider value={value}>
            <ToastList onPause={cancel} onResume={reset} onDismiss={dismiss} />
            {children}
        </ToastsContext.Provider>
    );
};
