export interface CreateWarehouseBinPayload {
    identifier: string;
}

export interface CreateWarehouseBinFormData {
    identifier: string | null;
}

export const initialCreateWarehouseBinFormData: CreateWarehouseBinFormData = {
    identifier: null,
};
