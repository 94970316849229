import { AddonQuoteEnrolmentPayload, AddonQuotePayload } from '@hofy/api-admin';
import { PlatformTier } from '@hofy/api-shared';
import { numberToPercent } from '@hofy/global';
import { isGreaterThan, isPricePositive, isRequired, useForm, validateArrayField, validator } from '@hofy/ui';

export const useAddonQuoteForm = () => {
    return useForm<AddonQuotePayload>({
        initial: {
            platformTier: PlatformTier.OrgBasedLite,
            customDiscount: numberToPercent(0),
            enrolments: [],
        },
        validate: validator<AddonQuotePayload>({
            platformTier: isRequired('Platform tier cannot be empty'),
            enrolments: validateArrayField<AddonQuotePayload, 'enrolments'>({
                selfRules: isRequired('At least one enrolment is required'),
                fieldsValidator: validator<AddonQuoteEnrolmentPayload>({
                    enrolmentCount: isGreaterThan(0, 'Enrolment count must be greater than 0'),
                    assetValue: isPricePositive('Asset value must be greater than 0'),
                    duration: isGreaterThan(0, 'Enrolment duration must be greater than 0'),
                }),
            }),
        }),
    });
};
