import { getEnumValues } from '@hofy/global';

export enum RentalTerm {
    Rental6Months = '6_months_rental',
    Rental12Months = '12_months_rental',
    Rental24Months = '24_months_rental',
    Rental36Months = '36_months_rental',
}

export const rentalLength: Record<RentalTerm, number> = {
    [RentalTerm.Rental6Months]: 6,
    [RentalTerm.Rental12Months]: 12,
    [RentalTerm.Rental24Months]: 24,
    [RentalTerm.Rental36Months]: 36,
};

export const rentalTermByLength: Record<number, RentalTerm> = {
    6: RentalTerm.Rental6Months,
    12: RentalTerm.Rental12Months,
    24: RentalTerm.Rental24Months,
    36: RentalTerm.Rental36Months,
};

// purchase removed from options but left in enum for backward compatibility
export const allRentalTerms = getEnumValues<RentalTerm>(RentalTerm);

export const unbundledRentalTerms = getEnumValues<RentalTerm>(RentalTerm).filter(
    r => r !== RentalTerm.Rental6Months,
);

export const getAvailableRentalTerms = (unbundled?: boolean): RentalTerm[] => {
    return unbundled ? unbundledRentalTerms : allRentalTerms;
};
