export const invoiceStatusI18n = {
    'invoice-status.draft': 'Draft',
    'invoice-status.open': 'Open',
    'invoice-status.overdue': 'Overdue',
    'invoice-status.processing-payment': 'Processing payment',
    'invoice-status.paid': 'Paid',
    'invoice-status.voided': 'Voided',

    'credit-note-status.draft': 'Draft',
    'credit-note-status.open': 'Open',
    'credit-note-status.paid': 'Applied',
    'credit-note-status.voided': 'Voided',
};
