import { PageRequest, PageResponse, stringifyUrl, UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { AuditLogDetailsDto, AuditLogDto } from './types/AuditLogDto';
import { AuditLogsFilters } from './types/AuditLogsFilters';

class AuditLogService {
    public getAuditableTables = async (): Promise<string[]> => {
        return restClient.getJson<string[]>(`/api/admin/audit-logs/tables`);
    };

    public auditLogsItems = async (
        { ...filters }: AuditLogsFilters,
        page: PageRequest,
    ): Promise<PageResponse<AuditLogDto>> => {
        return restClient.getJson<PageResponse<AuditLogDto>>(
            stringifyUrl({
                url: `/api/admin/audit-logs`,
                query: {
                    ...filters,
                    page: page.page,
                    pageSize: page.pageSize,
                },
            }),
        );
    };

    public getAuditLog = async (auditId: UUID): Promise<AuditLogDetailsDto> => {
        return restClient.getJson<AuditLogDetailsDto>(`/api/admin/audit-logs/${auditId}`);
    };
}

export const auditLogService = new AuditLogService();
