import {
    AdminAssignmentFilters,
    emptyAdminAssignmentFilters,
    HofyWarehouseDetailsDto,
    useAdminsRefsQuery,
    useHofyWarehousesQuery,
} from '@hofy/api-admin';
import {
    AdminRefDto,
    CollectionSurveyStatus,
    OrderType,
    Ownership,
    Role,
    ShipmentStatus,
    ShipmentType,
    useTrOwnership,
    useTrShipmentStatus,
    useTrShipmentType,
} from '@hofy/api-shared';
import { AnyRegion, Country, UUID } from '@hofy/global';
import { useTrCountry, useTrRegion } from '@hofy/i18n';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti, useFilters } from '@hofy/ui';

import { useAdminFilterDefintion } from '../admin/filters/useAdminFilterDefintion';
import { useTrCollectionSurveyStatus } from '../collectionRequests/useTrCollectionSurveyStatus';
import { useBooleanFilterDefinition } from '../filters/useBooleanFilterDefinition';
import { useDateFilterDefinition } from '../filters/useDateFilterDefinition';
import { useDateRangeFilterDefinition } from '../filters/useDateRangeFilterDefinition';
import { useMultiEnumFilterDefinition } from '../filters/useMultiEnumFilterDefinition';
import { useSearchFilterDefinition } from '../filters/useSearchFilterDefinition';
import { useSingleEnumFilterDefinition } from '../filters/useSingleEnumFilterDefinition';
import { useTrOrderType } from './useTrOrderType';

interface UseAdminAssignmentsFiltersOptions {
    defaultValues?: Partial<AdminAssignmentFilters>;
}

export interface AssignmentFiltersContext {
    warehousesList: HofyWarehouseDetailsDto[];
    assignedAdmins: AdminRefDto[];
}

export const useAdminAssignmentsFilters = ({ defaultValues }: UseAdminAssignmentsFiltersOptions) => {
    const { data: warehousesList } = useHofyWarehousesQuery();
    const { data: assignedAdmins } = useAdminsRefsQuery(Role.FulfillmentAdmin, true);

    const search = useSearchFilterDefinition();
    const allShipments = useBooleanFilterDefinition({ name: 'All shipments' });
    const configRequired = useBooleanFilterDefinition({ name: 'Config required' });
    const containingLaptop = useBooleanFilterDefinition({ name: 'Containing laptop' });
    const isConsumable = useBooleanFilterDefinition({ name: 'Consumables' });
    const isDisposal = useBooleanFilterDefinition({ name: 'Disposals' });
    const isFutureScheduled = useBooleanFilterDefinition({ showInActive: false });
    const isLoaner = useBooleanFilterDefinition({ name: 'Loaners' });
    const isOnHold = useBooleanFilterDefinition({ name: 'On hold' });
    const isPartner = useBooleanFilterDefinition({ name: 'Partners' });
    const isPending = useBooleanFilterDefinition({ showInActive: false });
    const isScanned = useBooleanFilterDefinition({ name: 'Scanned' });
    const isScheduled = useBooleanFilterDefinition({ name: 'Scheduled' });
    const isStoreAndReuse = useBooleanFilterDefinition({ name: 'Store & Reuse' });
    const isStorageLocation = useBooleanFilterDefinition({ name: 'Storage Location' });
    const isUnscanned = useBooleanFilterDefinition({ name: 'Unscanned' });
    const newJoiners = useBooleanFilterDefinition({ name: 'New joiners' });

    const ownership = useSingleEnumFilterDefinition({
        name: 'Ownership',
        statusEnum: Ownership,
        toLabel: useTrOwnership(),
    });
    const orderType = useSingleEnumFilterDefinition({
        name: 'Order type',
        statusEnum: OrderType,
        toLabel: useTrOrderType(),
    });
    const shipmentType = useSingleEnumFilterDefinition({
        name: 'Shipment type',
        statusEnum: ShipmentType,
        toLabel: useTrShipmentType(),
    });
    const shipmentStatus = useMultiEnumFilterDefinition({
        name: 'Shipment status',
        statusEnum: ShipmentStatus,
        toLabel: useTrShipmentStatus(),
    });
    const collectionSurveyStatus = useMultiEnumFilterDefinition({
        name: 'Collection survey status',
        statusEnum: CollectionSurveyStatus,
        toLabel: useTrCollectionSurveyStatus(),
    });

    const otdDate = useDateFilterDefinition({ name: 'OTD date' });
    const deliveryDate = useDateFilterDefinition({ name: 'Delivery date' });
    const shipDateRange = useDateRangeFilterDefinition({ name: 'Ship date range' });

    const warehouses: FilterDefinitionMulti<UUID> = {
        type: 'multi',
        name: 'Warehouses',
        query: queryStringArrayParser(),
        toLabel: id => warehousesList.find(warehouse => warehouse.id === id)?.name ?? '--',
    };
    const assignedUsers = useAdminFilterDefintion('Assigned users', assignedAdmins);

    const region: FilterDefinitionMulti<AnyRegion> = {
        type: 'multi',
        name: 'Region',
        query: queryStringArrayParser(),
        toLabel: useTrRegion(),
    };
    const countries: FilterDefinitionMulti<Country> = {
        type: 'multi',
        name: 'Country',
        query: queryStringArrayParser(),
        toLabel: useTrCountry(),
    };

    return useFilters<AdminAssignmentFilters, AssignmentFiltersContext>(
        {
            allShipments,
            assignedUsers,
            collectionSurveyStatus,
            configRequired,
            containingLaptopOnly: containingLaptop,
            countries,
            deliveryDate,
            futureScheduled: isFutureScheduled,
            isConsumable,
            isDisposal,
            isLoaner,
            isOnHold,
            isPartner,
            isPending,
            isStoreAndReuse,
            isStorageLocation,
            newJoiners,
            orderType,
            otdDate,
            ownership,
            region,
            scanned: isScanned,
            scheduledOnly: isScheduled,
            search,
            shipDateRange,
            shipmentStatus,
            shipmentType,
            unscanned: isUnscanned,
            warehouses,
        },
        {
            ...emptyAdminAssignmentFilters,
            ...defaultValues,
        },
        {
            warehousesList,
            assignedAdmins,
        },
    );
};
