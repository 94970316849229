import { ColorDye } from '@hofy/global';

export enum FixedAssetStatus {
    Depreciating = 'depreciating',
    Depreciated = 'depreciated',
    Disposed = 'disposed',
}

export const fixedAssetStatusColors: Record<FixedAssetStatus, ColorDye> = {
    [FixedAssetStatus.Disposed]: 'red',
    [FixedAssetStatus.Depreciated]: 'green',
    [FixedAssetStatus.Depreciating]: 'teal',
};
