import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { UUID } from '@hofy/global';

import { getRepairsLink } from '../../components/routing/adminLinks';
import { useRepairsPageTab } from './RepairsTabContext';

export const useRepairsLinks = () => {
    const { location } = useHistory();

    const tab = useRepairsPageTab();
    const base = getRepairsLink(tab);

    const link = useCallback((path: string) => `${path}${location.search}`, [location.search]);

    // repairs navigation
    const createNewRepairLink = useMemo(() => link(`${base}/create`), [link, base]);

    const getOpenRepairProgressLink = useCallback(
        (id: UUID) => link(`${base}/repair/${id}/progress`),
        [link, base],
    );
    const getOpenRepairDetailsLink = useCallback(
        (id: UUID) => link(`${base}/repair/${id}/details`),
        [link, base],
    );

    // items navigation
    const getOpenItemLink = useCallback((id: UUID) => link(`${base}/item/${id}`), [link, base]);

    return {
        createNewRepairLink,
        getOpenRepairProgressLink,
        getOpenRepairDetailsLink,
        getOpenItemLink,
    };
};
