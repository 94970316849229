import React, { FC } from 'react';

import { RepaymentPlanType, repaymentTypeColors, useTrRepaymentPlanType } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface RepaymentTypeChipProps {
    type: RepaymentPlanType;
}

export const RepaymentTypeBadge: FC<RepaymentTypeChipProps> = ({ type }) => {
    const tr = useTrRepaymentPlanType();

    if (!type) {
        return null;
    }

    const color = repaymentTypeColors[type];
    return <Badge color={color} label={tr(type)} />;
};
