import React, { FC } from 'react';

import { FormFieldApi } from '../../../hooks';
import { Optional } from '../../../types/Utils';
import { LabeledTextarea, LabeledTextareaProps } from './LabeledTextarea';

type FormTextareaOptionalProps = Optional<LabeledTextareaProps, 'onChange'>;
type FormTextareaOmittedProps = Omit<FormTextareaOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalTextareaProps extends FormTextareaOmittedProps {
    api: FormFieldApi<string>;
    nullable?: false;
}

interface FormNullableTextareaProps extends FormTextareaOmittedProps {
    api: FormFieldApi<string | null>;
    nullable: true;
}

export type FormTextareaProps = FormNormalTextareaProps | FormNullableTextareaProps;

export const FormTextarea: FC<FormTextareaProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledTextarea
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
