import { ColorDye, getEnumValues } from '@hofy/global';

export enum InvoicingEntityFilter {
    Hofy = 'hofy',
    Two = 'two',
}

export const invoicingEntityFilterColors: Record<InvoicingEntityFilter, ColorDye> = {
    [InvoicingEntityFilter.Hofy]: 'purple',
    [InvoicingEntityFilter.Two]: 'teal',
};

export const allInvoicingEntityFilters = getEnumValues<InvoicingEntityFilter>(InvoicingEntityFilter);

export const invoicingEntityFilterToIsTwo = (filter: InvoicingEntityFilter | null): boolean | null => {
    if (filter === null) {
        return null;
    }

    switch (filter) {
        case InvoicingEntityFilter.Hofy:
            return false;
        case InvoicingEntityFilter.Two:
            return true;
    }
};
