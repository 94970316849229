import React, { FC } from 'react';

import { useOneOffEvents } from '@hofy/api-admin';
import { Page } from '@hofy/common';
import { Box, Button, PageHeader, PageSkeleton, Placeholder, SvgIllustration } from '@hofy/ui';

import { EventContent } from './EventContent';

interface NotificationsPageProps {
    onSend(): void;
}

export const NotificationsPage: FC<NotificationsPageProps> = ({ onSend }) => {
    const { events, isLoading } = useOneOffEvents();

    if (isLoading) {
        return <PageSkeleton />;
    }

    return (
        <Page>
            <PageHeader title='One-off notifications' rightSlot={<Button label='Send' onClick={onSend} />} />
            {events?.length ? (
                <Box flex='auto' column paddingHorizontal={40} paddingVertical={24} overflow='auto'>
                    <Box column gap={20} maxWidth={800}>
                        {events.map(event => (
                            <EventContent key={event.id} event={event} />
                        ))}
                    </Box>
                </Box>
            ) : (
                <Placeholder
                    illustration={SvgIllustration.UserSearch}
                    title='No one-off messages'
                    message='No one-off messages send yet'
                />
            )}
        </Page>
    );
};
