export const contractTypeI18n = {
    'contract-type.rental': 'Rental',
    'contract-type.purchase': 'Purchase',
    'contract-type.management': 'Management',

    'rental-contract-type.rental': 'Rental',
    'rental-contract-type.rolling': 'Extended',

    'management-contract-type.addon': 'Service package',
    'management-contract-type.store-and-reuse': 'Store and reuse',
};
