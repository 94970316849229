import React, { FC, ReactNode } from 'react';

import { Box, ExpandHeight } from '../../components';

interface ActiveFiltersLayoutProps {
    show: boolean;
    children?: ReactNode;
}

export const ActiveFiltersLayout: FC<ActiveFiltersLayoutProps> = ({ show, children }) => {
    return (
        <ExpandHeight>
            {show && (
                <Box
                    paddingHorizontal='mainMarginHorizontal'
                    paddingVertical={16}
                    marginHorizontal={-12}
                    borderBottom
                >
                    {children}
                </Box>
            )}
        </ExpandHeight>
    );
};
