import React, { FC } from 'react';

import { UUID } from '@hofy/global';
import { Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3 } from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useClosePurchaseOrder } from '../../../../../store/purchaseOrders/useClosePurchaseOrder';

interface ClosePurchaseOrderModalProps {
    purchaseOrderId: UUID;
    purchaseOrderReference: string;
    onClose(): void;
}

export const ClosePurchaseOrderModal: FC<ClosePurchaseOrderModalProps> = ({
    purchaseOrderId,
    purchaseOrderReference,
    onClose,
}) => {
    const { mutate } = useClosePurchaseOrder(purchaseOrderId);
    const submit = () => {
        mutate();
        onClose();
    };

    return (
        <Modal width={600} onClose={onClose}>
            <ModalHeader title='Close purchase order' />
            <ModalContent>
                <Paragraph3>{`Are you sure you want to close purchase order #${purchaseOrderReference}?`}</Paragraph3>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Close' onClick={onClose} />
                <Button label='Confirm' onClick={submit} />
            </ModalFooter>
        </Modal>
    );
};
