import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';

import { AdminNavLink } from '../../../../components/routing/AdminNavLink';
import { Link } from '../../../../components/routing/Link';
import { AdminInvoicingTab } from '../../../../store/invoicing/types/AdminInvoicingTab';

interface InvoiceEntryDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
    color?: Color;
}

const InvoiceEntryDetailsLink: FC<InvoiceEntryDetailsLinkProps> = ({ id, color, children }) => {
    return (
        <Link color={color} to={`${AdminNavLink.Invoicing}/${AdminInvoicingTab.InvoiceEntries}/${id}`}>
            {children}
        </Link>
    );
};

export const InvoiceEntryDetailsLinkLabel: FC<{ id: UUID }> = ({ id }) => {
    return <InvoiceEntryDetailsLink id={id}>{`#${id}`}</InvoiceEntryDetailsLink>;
};
