import React, { FC, ReactNode } from 'react';

import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';

import { Link } from '../../../components/routing/Link';
import { InvoiceDetailTabs } from '../../../store/invoices/types/InvoiceDetailTabs';

interface InvoiceDetailsLinkProps {
    id: UUID;
    children?: ReactNode;
    color?: Color;
    tab: InvoiceDetailTabs;
}

export const InvoiceDetailsLink: FC<InvoiceDetailsLinkProps> = ({ id, color, children, tab }) => {
    return (
        <Link color={color} to={`/invoicing/invoices/${id}/${tab}`}>
            {children}
        </Link>
    );
};
