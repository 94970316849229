import React, { FC, memo, useState } from 'react';

import { firstImage, ProductDto } from '@hofy/api-admin';
import {
    allParentProductCategories,
    AnyProductCategory,
    productCategoriesHierarchy,
    useTrProductCategory,
} from '@hofy/api-shared';
import { RadioFilter, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { useStructMemo } from '@hofy/hooks';
import { ProductCategoryTree } from '@hofy/product';
import { NumberValues } from '@hofy/theme';
import {
    BaseTable,
    Box,
    HiddenScroll,
    Placeholder,
    SearchInput,
    SectionTitle2,
    SvgIllustration,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';
import { ProductOverview } from '../../../../components/domain/products/ProductOverview';
import { ProductFilters } from '../../../../store/products/types/ProductFilters';
import { allProductStatuses, ProductStatus } from '../../../../store/products/types/ProductStatus';
import { useProducts } from '../../../../store/products/useProducts';
import { useTrProductStatus } from '../../../../store/products/useTrProductStatus';
import { ActiveProductChip } from './ActiveProductChip';

interface ProductSelectSlideoutProps {
    onProductSelect(product: ProductDto): void;
    onCancel(): void;
}

export const ProductSelectSlideout: FC<ProductSelectSlideoutProps> = ({ onCancel, onProductSelect }) => {
    // We are using local filters in this slideout to not interfere with the `useProductFilters` URL state
    const [search, setSearch] = useState('');
    const [category, setCategory] = useState<AnyProductCategory | null>(null);
    const [productStatus, setProductStatus] = useState<ProductStatus>(ProductStatus.Active);

    const filters = useStructMemo({
        search,
        category,
        productStatus,
    }) satisfies Partial<ProductFilters>;

    const { data, isLoading } = useProducts(filters);

    const trProductStatus = useTrProductStatus();

    return (
        <Slideout width={1300} onClose={onCancel}>
            <SlideoutHeader title='Add item' justify='space-between'>
                <SearchInput value={filters.search} onChange={setSearch} placeholder='Search products…' />
            </SlideoutHeader>
            <SlideoutContent paddingRight={0}>
                <Box row alignItems='stretch' fullHeight marginLeft={-12 as NumberValues}>
                    <Box column width={270}>
                        <HiddenScroll flex='auto' paddingVertical={24} paddingRight={24} borderRight>
                            <SectionTitle2 paddingBottom={24} paddingLeft={10}>
                                Category
                            </SectionTitle2>
                            <ProductCategoryTree
                                category={filters.category}
                                onChange={setCategory}
                                parentProductCategories={allParentProductCategories}
                                hierarchy={productCategoriesHierarchy}
                            />
                        </HiddenScroll>
                        <Box borderTop borderRight paddingVertical={24}>
                            <RadioFilter
                                title='Product status'
                                options={allProductStatuses}
                                selected={filters.productStatus}
                                onChange={status => setProductStatus(status)}
                                optionToText={trProductStatus}
                            />
                        </Box>
                    </Box>
                    <ProductTable isLoading={isLoading} products={data} onOpenProduct={onProductSelect} />
                </Box>
            </SlideoutContent>
            <SlideoutFooter>
                <CancelButton onClick={onCancel} />
            </SlideoutFooter>
        </Slideout>
    );
};

interface ProductTableProps {
    isLoading: boolean;
    products: ProductDto[];
    onOpenProduct(product: ProductDto): void;
}

const ProductTable = memo<ProductTableProps>(({ products, onOpenProduct, isLoading }) => {
    const trProductCategory = useTrProductCategory();

    return (
        <BaseTable
            data={products}
            toKey={product => product.id}
            onRowClick={onOpenProduct}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Storage}
                    title='No products '
                    message='No products for selected criteria'
                />
            }
            isLoading={isLoading}
            minWidth={700}
            columns={[
                {
                    id: 'product',
                    header: 'Product',
                    flexGrow: 1,
                    renderer: product => (
                        <ProductOverview product={product} images={firstImage(product)} showVariants />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => trProductCategory(product.category),
                },
                {
                    id: 'variants',
                    header: 'Variants',
                    width: 120,
                    flexGrow: 0,
                    renderer: product => `${product.variants.length} variants`,
                },
                {
                    id: 'status',
                    header: 'Status',
                    width: 100,
                    flexGrow: 0,
                    renderer: product => <ActiveProductChip value={product.isActive} />,
                },
            ]}
        />
    );
});
