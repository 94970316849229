export const emailStatusI18n = {
    'email-status.created': 'Created',
    'email-status.sent': 'Sent',
    'email-status.sent-error': 'Error',
    'email-status.processed': 'Processed',
    'email-status.dropped': 'Dropped',
    'email-status.deferred': 'Deferred',
    'email-status.bounced': 'Bounced',
    'email-status.delivered': 'Delivered',
};
