import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { jobService } from '../service/jobService';
import { JobDto } from '../service/types/JobDto';
import { JobFilters } from '../service/types/JobFilters';
import { jobsCacheKey } from './jobsCacheKey';

export const useJobs = (filters?: JobFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [jobsCacheKey, filters],
        queryFn: param =>
            jobService.getJobs(
                {
                    page: param.pageParam,
                    pageSize: 50,
                },
                filters,
            ),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const items: JobDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        items,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
