import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { useKey } from 'react-use';
import styled from 'styled-components';

import { Color, ZIndex } from '@hofy/theme';
import { Box, BoxProps, TestKeyAware } from '@hofy/ui';

interface SlideoutProps extends BoxProps, TestKeyAware {
    onClose(): void;
    autoClosable?: boolean;
    slideoutId?: string; // todo: make required and remove testKey
}

export const Slideout: FC<SlideoutProps> = ({
    autoClosable = true,
    onClose,
    children,
    width,
    testKey,
    slideoutId,
    ...boxProps
}) => {
    useKey('Escape', autoClosable ? onClose : undefined);

    return (
        <SlideoutContainer column alignItems='flex-end' data-test-key={`slideout:${slideoutId}`}>
            <CoverAllBox
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={transition}
                bg={Color.BackgroundOverlay}
                onClick={() => autoClosable && onClose?.()}
            />
            <Box
                width={width}
                maxFullWidth
                fullHeight
                relative
                bg={Color.BackgroundDefault}
                as={motion.div}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
                transition={transition}
            >
                <Box fullHeight column {...boxProps} data-test-key={testKey}>
                    {children}
                </Box>
            </Box>
        </SlideoutContainer>
    );
};

const transition = { duration: 0.3 };

const SlideoutContainer = styled(Box)`
    z-index: ${ZIndex.PopupOverlay};
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;

const CoverAllBox = styled(Box)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
