import React, { FC } from 'react';
import styled from 'styled-components';

import { SvgImage } from '../icon';
import logoLarge from './assets/logo-light.svg';
import logoSmall from './assets/logo-small-light.svg';
import logoSmallColor from './assets/logo-small.svg';
import logoLargeColor from './assets/logo.svg';

export interface LogoProps {
    small?: boolean;
    color?: boolean;
    height?: number;
}

export const Logo: FC<LogoProps> = ({ small, height = 34, color }) => {
    const logo = small ? logoSmall : logoLarge;
    const logoColor = small ? logoSmallColor : logoLargeColor;

    return <LogoIcon height={height} svg={color ? logoColor : logo} />;
};

const LogoIcon = styled(SvgImage)<{ height: number }>`
    height: ${p => p.height}px;
`;
