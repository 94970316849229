import { getEnumValues } from '@hofy/global';

export enum RepairSlideoutTab {
    Details = 'details',
    Progress = 'progress',
    Notes = 'notes',
}

export const repairSlideoutTabLabels: Record<RepairSlideoutTab, string> = {
    [RepairSlideoutTab.Details]: 'Details',
    [RepairSlideoutTab.Progress]: 'Progress',
    [RepairSlideoutTab.Notes]: 'Notes',
};

export const allRepairSlideoutTabs = getEnumValues<RepairSlideoutTab>(RepairSlideoutTab);
