import React, { FC } from 'react';

import { ShipmentType, shipmentTypeColors, useTrShipmentType } from '@hofy/api-shared';
import { Badge, OuterBoxProps, SvgIcon } from '@hofy/ui';

interface ShipmentTypeChipProps extends OuterBoxProps {
    type: ShipmentType;
}

export const ShipmentTypeChip: FC<ShipmentTypeChipProps> = ({ type, ...rest }) => {
    const trShipmentType = useTrShipmentType();

    if (!type) {
        return null;
    }

    const color = shipmentTypeColors[type];

    const getIcon = () => {
        switch (type) {
            case ShipmentType.Collection:
                return SvgIcon.Box;
            case ShipmentType.Delivery:
            case ShipmentType.Transfer:
            case ShipmentType.Dropshipping:
                return SvgIcon.Truck;
        }
    };

    return <Badge color={color} icon={getIcon()} label={trShipmentType(type)} {...rest} />;
};
