import React, { FC } from 'react';
import styled from 'styled-components';

import { Box, MarginBoxProps } from '../base';

export interface FlagProps extends MarginBoxProps {
    src: string;
    size?: number;
}

export const Flag: FC<FlagProps> = ({ size = 20, src, ...boxProps }) => {
    return <FlagBox as='img' loading='lazy' src={src} width={size} height={size} {...boxProps} />;
};

const FlagBox = styled(Box)`
    border-radius: 50%;
    display: inline-block;
`;
