import React, { FC } from 'react';

import { useBaseI18n } from '@hofy/i18n';
import { Color } from '@hofy/theme';
import { AsyncButton, Box, Button, Paragraph3, SvgIcon } from '@hofy/ui';

interface WizardFooterProps {
    onCancel(): void;
    onSubmit(): void;
    onChangeStep(v: number): void;
    submitLabel: string;
    cancelLabel: string;
    prevLabel: string;
    nextLabel: string;
    step: number;
    finalStep: number;
    primaryTestKey?: string;
    submitTestKey?: string;
    isSubmitLoading?: boolean;
}

export const WizardFooter: FC<WizardFooterProps> = ({
    cancelLabel,
    step,
    finalStep,
    onCancel,
    onSubmit,
    onChangeStep,
    prevLabel,
    submitLabel,
    nextLabel,
    primaryTestKey,
    submitTestKey,
    isSubmitLoading = false,
}) => {
    const { tr } = useBaseI18n();
    return (
        <>
            {step === 0 && (
                <Button
                    type='ghost'
                    negativeMargin
                    label={cancelLabel}
                    leftIcon={SvgIcon.Cross}
                    onClick={onCancel}
                />
            )}
            {step > 0 && (
                <Button
                    type='ghost'
                    negativeMargin
                    label={prevLabel}
                    leftIcon={SvgIcon.ChevronLeft}
                    onClick={() => onChangeStep(step - 1)}
                />
            )}
            <Box flex={1} />
            <Paragraph3 color={Color.Neutral300} marginRight={20}>
                {tr('general.step', { from: step + 1, to: finalStep })}
            </Paragraph3>
            {step !== finalStep - 1 && (
                <Button
                    data-test-key={primaryTestKey}
                    label={nextLabel}
                    onClick={() => onChangeStep(step + 1)}
                />
            )}
            {step === finalStep - 1 && (
                <AsyncButton
                    isLoading={isSubmitLoading}
                    label={submitLabel}
                    onClick={onSubmit}
                    testKey={submitTestKey}
                    disableCheck
                />
            )}
        </>
    );
};

interface WizardSimpleFooterProps<T> {
    onCancel(): void;
    onSubmit(): void;
    onChangeStep(v: T): void;
    submitLabel: string;
    cancelLabel: string;
    prevLabel: string;
    nextLabel: string;
    step: T;
    steps: T[];
    primaryTestKey?: string;
    submitTestKey?: string;
    isSubmitLoading?: boolean;
}

export const WizardSimpleFooter = <T extends string>({
    steps,
    step,
    onChangeStep,
    onCancel,
    onSubmit,
    ...rest
}: WizardSimpleFooterProps<T>) => {
    const currentStep = steps.indexOf(step);

    return (
        <WizardFooter
            step={currentStep}
            finalStep={steps.length}
            onCancel={onCancel}
            onSubmit={onSubmit}
            onChangeStep={v => onChangeStep(steps[v])}
            {...rest}
        />
    );
};
