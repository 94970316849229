import { DependencyList, useEffect } from 'react';

import { ConfirmationModalItem, useConfirmationContext } from './ConfirmationContext';

export const useConfirmation = (
    callback: () => void,
    modalItem: ConfirmationModalItem,
    deps: DependencyList = [],
) => {
    const context = useConfirmationContext();

    useEffect(() => {
        context?.addModal(modalItem);
        return () => {
            context?.removeModal(modalItem.id);
        };
    }, [modalItem.id, ...deps]);

    return () => {
        context?.openModal(modalItem.id, callback);
    };
};
