import React, { FC, ReactNode } from 'react';

import { Box, BoxProps, Heading3, renderTextNode } from '@hofy/ui';

import { SlideoutContentContainer } from './SlideoutContentContainer';

interface SlideoutTabbedHeaderProps extends BoxProps {
    title?: ReactNode;
    tabsSlot: ReactNode;
}

export const SlideoutTabbedHeader: FC<SlideoutTabbedHeaderProps> = ({
    title,
    tabsSlot,
    children,
    ...boxProps
}) => {
    return (
        <SlideoutContentContainer borderBottom shrink={0} minHeight={80}>
            <Box row paddingVertical={16} {...boxProps}>
                {renderTextNode(title, text => (
                    <Heading3>{text}</Heading3>
                ))}
                {children}
            </Box>
            {tabsSlot}
        </SlideoutContentContainer>
    );
};
