export const adminOrderFilterI18n = {
    'admin-order-filter.new-joiner': 'New joiners',
    'admin-order-filter.dropshipping': 'Dropshipping',
    'admin-order-filter.pending': 'All pending',
    'admin-order-filter.all': 'All orders',
};

export const adminOrderSortOptionI18n = {
    'admin-order-sort-option.by-start-date': 'User start date',
    'admin-order-sort-option.by-updated': 'Updated date',
    'admin-order-sort-option.by-otd': 'OTD date',
};
