import { ColorDye } from '@hofy/global';

import { ZendeskStatusDto } from './ZendeskStatusDto';

export enum TicketStatus {
    New = 'new',
    Open = 'open',
    Pending = 'pending',
    Solved = 'solved',
    Closed = 'closed',
}

export interface ZendeskTicketStatusDto extends ZendeskStatusDto {
    ticketStatus: TicketStatus | null;
}

export const zendeskTicketStatusColors: Record<TicketStatus, ColorDye> = {
    [TicketStatus.New]: 'teal',
    [TicketStatus.Open]: 'blue',
    [TicketStatus.Pending]: 'orange',
    [TicketStatus.Solved]: 'green',
    [TicketStatus.Closed]: 'grey',
};

export const zendeskTicketStatusLabel: Record<TicketStatus, string> = {
    [TicketStatus.New]: 'New',
    [TicketStatus.Open]: 'Open',
    [TicketStatus.Pending]: 'Pending',
    [TicketStatus.Solved]: 'Solved',
    [TicketStatus.Closed]: 'Closed',
};
