import React, { FC } from 'react';

import { ShipmentStatus, shipmentStatusColors, useTrShipmentStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface ShipmentChipProps {
    status: ShipmentStatus;
}

export const ShipmentStatusChip: FC<ShipmentChipProps> = ({ status }) => {
    const trShipmentStatus = useTrShipmentStatus();

    if (!status) {
        return null;
    }

    const color = shipmentStatusColors[status];

    return <Badge color={color} label={trShipmentStatus(status)} data-test-key='shipment-status' />;
};
