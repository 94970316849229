import React, { FC } from 'react';

import { TotalValuationDto } from '@hofy/api-admin';
import { HofySubsidiaryRef } from '@hofy/api-shared';
import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Paragraph3, Strong, Summary } from '@hofy/ui';

interface ValuationTotalCardProps {
    totals: TotalValuationDto[];
    hofySubsidiary: HofySubsidiaryRef;
}

export const ValuationTotalCard: FC<ValuationTotalCardProps> = ({ totals, hofySubsidiary }) => {
    return (
        <Summary title={hofySubsidiary.name}>
            <Paragraph3>
                Total cost
                <Total prices={totals.map(total => total.initialCost)} />
            </Paragraph3>
            <Paragraph3>
                Total value
                <Total prices={totals.map(total => total.currentValue)} />
            </Paragraph3>
        </Summary>
    );
};

interface TotalProps {
    prices: Price[];
}

const Total: FC<TotalProps> = ({ prices }) => {
    const { localeFormatPrice } = usePrice();

    return (
        <>
            {prices.map((price, i) => (
                <Strong key={i} bold color={Color.ContentPrimary}>
                    {' '}
                    {localeFormatPrice(price)}
                </Strong>
            ))}
        </>
    );
};
