import React, { FC } from 'react';

import { AddressField } from '@hofy/address';
import { AutoUpdateContractPayload } from '@hofy/api-admin';
import {
    allPaymentSchemasWithoutDeposit,
    allRentalTerms,
    useTrPaymentSchema,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { Alert, Box, FormContainer, FormSection, LabeledSelect, UseForm, useFormObjectField } from '@hofy/ui';

interface AutoContractFormProps {
    form: UseForm<AutoUpdateContractPayload>;
}

export const AutoRentalContractForm: FC<AutoContractFormProps> = ({ form }) => {
    const trRentalTerm = useTrRentalTerm();
    const trPaymentSchema = useTrPaymentSchema();
    const address = useFormObjectField(form.fields.billingToAddress);
    return (
        <Box marginTop={10}>
            <FormContainer>
                <FormSection label='Base details'>
                    <Alert
                        type='warning'
                        title='This contract will be updated with newest pricing and current organization settings'
                        description='Billing entity and currency will be determined by the billing address country. This method of updating will not preserve existing prices and discounts'
                    />
                    <LabeledSelect
                        label='Rental term'
                        onChange={rentalTerm =>
                            form.setValues({
                                rentalTerm,
                            })
                        }
                        value={form.values.rentalTerm}
                        toText={trRentalTerm}
                        options={allRentalTerms}
                        isRequired
                    />
                    <LabeledSelect
                        label='Payment schema'
                        onChange={paymentSchema =>
                            form.setValues({
                                paymentSchema,
                            })
                        }
                        value={form.values.paymentSchema}
                        toText={trPaymentSchema}
                        options={allPaymentSchemasWithoutDeposit}
                        isRequired
                    />
                </FormSection>
                <AddressField api={address} label='Billing address' />
            </FormContainer>
        </Box>
    );
};
