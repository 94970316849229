import React from 'react';
import { useHistory } from 'react-router-dom';

import { Tab, Tabs } from '@hofy/ui';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { useTrPeopleTab } from '../../store/users/useTrPeopleTab';
import { PeopleTab } from './PeopleTab';

interface PeopleTabsProps {
    active: PeopleTab;
}

export const PeopleTabs = ({ active }: PeopleTabsProps) => {
    const trAdminPeople = useTrPeopleTab();
    const history = useHistory();
    const onChange = (tab: PeopleTab) => {
        switch (tab) {
            case PeopleTab.Admins:
                return history.push(`${AdminNavLink.People}/${PeopleTab.Admins}`);
            case PeopleTab.Users:
                return history.push(`${AdminNavLink.People}/${PeopleTab.Users}`);
        }
    };
    return (
        <Tabs active={active} onChange={onChange} animationId='inventory'>
            {[PeopleTab.Users, PeopleTab.Admins].map(tab => (
                <Tab key={tab} id={tab} label={trAdminPeople(tab)} />
            ))}
        </Tabs>
    );
};
