import React, { FC } from 'react';

import { RentalTerm, useTrRentalTerm } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { Color } from '@hofy/theme';
import { MarginBoxProps, Paragraph3, TestKeyAware } from '@hofy/ui';

interface RentalTermDropdownProps extends TestKeyAware, MarginBoxProps {
    value?: RentalTerm;
    label?: string;
    bg?: Color;
    onChange(o: RentalTerm): void;
    disabled?: boolean;
    width?: string | number | 'auto';
    allowedRentalTerms: RentalTerm[];
    availableRentalTerms: RentalTerm[];
}

export const RentalTermFormDropdown: FC<RentalTermDropdownProps> = ({
    value,
    onChange,
    label,
    bg,
    disabled,
    width = 250,
    testKey,
    allowedRentalTerms,
    availableRentalTerms,
    ...margins
}) => {
    const tr = useTrRentalTerm();

    const rentalTermOptions = availableRentalTerms.map(term => ({
        title: tr(term),
        value: term,
        disabled: !allowedRentalTerms.includes(term),
    }));

    return (
        <FormDropdown
            testKey={testKey}
            items={rentalTermOptions}
            labelFormatter={item => (
                <Paragraph3
                    textNoWrap
                    overflow='hidden'
                    ellipsis
                    color={item.disabled ? Color.Neutral300 : Color.Neutral700}
                >
                    {item.title}
                </Paragraph3>
            )}
            onChange={selected => {
                selected && !selected.disabled && onChange(selected.value);
            }}
            label={label}
            bg={bg}
            value={rentalTermOptions.find(item => item.value === value)}
            width={width}
            disabled={disabled}
            {...margins}
        />
    );
};
