import { compact, Dictionary, groupBy } from 'lodash';
import { useMemo } from 'react';

import { useStateEffect } from '@hofy/hooks';

interface LikeAVariant {
    style: string | null;
    size: string | null;
}

export interface ProductVariantsState<T extends LikeAVariant> {
    size: string;
    style: string;
    variant?: T;
    setStyle(v: string | null): void;
    setSize(v: string | null): void;
    styles: string[];
    sizes: string[];
}

/** Retrieves variants with a style matching `selectedStyle`.
 * If `selectedStyle` is out-of-date due to the variant list changing, a style is selected from `allStyles`.
 */
const variantsFromStyle = <T extends LikeAVariant>(
    variantsByStyle: Dictionary<T[]>,
    selectedStyle: string,
    allStyles: string[],
) => {
    if (selectedStyle in variantsByStyle) {
        return variantsByStyle[selectedStyle];
    }

    const newStyle = allStyles[0];
    if (newStyle in variantsByStyle) {
        // Variants changed; use new default
        return variantsByStyle[newStyle];
    }

    // styleless product
    return [];
};

/** Generates a state of styles and sizes based on the variants present in the product.
 *
 * Sizes and styles are the two identifiers that specify a variant of a product. Individually they
 * will be duplicated across variants, but no two variants should have the same size and style.
 *
 * In the case a product doesn't have one of a style or a size, the product's variants will have those properties set to null.
 *
 * @returns All non-empty sizes and styles, the selected style and size, the variant matching the style and size,
 *          and a means of setting the style and size, which in turn update which variant is selected.
 */
export const useProductVariants = <T extends LikeAVariant>(
    variants: T[],
    initialVariant?: T,
): ProductVariantsState<T> => {
    // Group the variants of the product under matching styles
    const variantsByStyle = useMemo(() => groupBy(variants, variant => variant.style || ''), [variants]);
    const styles = useMemo(
        () => Object.keys(variantsByStyle).filter(style => style !== ''),
        [variantsByStyle],
    );
    const [style, setStyle] = useStateEffect(() => initialVariant?.style || '', [styles]);

    const styleVariants = variantsFromStyle(variantsByStyle, style, styles);

    const sizes = useMemo(() => compact(styleVariants.map(variant => variant.size)), [style]);
    const [size, setSize] = useStateEffect(
        () => (initialVariant?.size && sizes.includes(initialVariant.size) ? initialVariant.size : ''),
        [sizes],
    );
    const handleChangeSize = (size: string | null) => setSize(size || '');

    // Get first matching variant by size and style
    const variant = getVariant(styles, sizes, style, size, styleVariants);

    return {
        sizes,
        styles,
        variant,
        style,
        size,
        setStyle: style => setStyle(style || ''),
        setSize: handleChangeSize,
    };
};

const getVariant = <T extends LikeAVariant>(
    styles: string[],
    sizes: string[],
    style: string,
    size: string,
    styleVariants: T[],
): T | undefined => {
    // Only one variant with not styles and sizes
    if (styles.length === 0 && sizes.length === 0) {
        return styleVariants[0];
    }

    // Only one variant with style, still need to select style
    if (style && sizes.length === 0) {
        return styleVariants[0];
    }

    return styleVariants.find(v => v.size === size);
};
