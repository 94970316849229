import { motion, useAnimation } from 'framer-motion';
import React, { FC, ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Box } from '../base';
import { Counter } from './Counter';

type Placement = 'top' | 'bottom';

export interface AlertCounterProps {
    count: number;
    placement?: Placement;
    children?: ReactNode;
}

export const AlertCounter: FC<AlertCounterProps> = ({ count, placement = 'top', children }) => {
    const controls = useAnimation();
    const prevCount = useRef<number>(count);

    useEffect(() => {
        if (count !== prevCount.current) {
            controls.start({
                opacity: 1,
                scale: [1, 1.8, 1],
            });
        }
        prevCount.current = count;
    }, [count, controls]);

    return (
        <Box relative inline>
            {children}
            {count !== 0 && (
                <CounterBox
                    as={motion.div}
                    animate={controls}
                    initial={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.5 }}
                    placement={placement}
                    transition={{ duration: 0.3 }}
                >
                    <Counter count={count} color='grey' />
                </CounterBox>
            )}
        </Box>
    );
};

const CounterBox = styled(Box)<{ placement: Placement }>`
    position: absolute;
    right: -8px;
    ${p => p.placement === 'top' && 'top: -4px;'}
    ${p => p.placement === 'bottom' && 'bottom: -4px;'}
`;
