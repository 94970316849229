import React, { FC } from 'react';

import {
    isShipmentLocationHofyWarehouse,
    isShipmentLocationOrgWarehouse,
    isShipmentLocationSupplier,
    isShipmentLocationUser,
    ShipmentLocation,
    SupplierRefDto,
    UserRefDto,
    WarehouseRefDto,
} from '@hofy/api-shared';
import { HofyWarehouseLocationCard, OrgWarehouseLocationCard, UserLocationCard } from '@hofy/ui-domain';

import { NewJoinerLabel } from '../../../../components/domain/user/NewJoinerLabel';
import { getUserLink } from '../../../../components/routing/adminLinks';
import { SupplierLocationCard } from './SupplierLocationCard';

interface ShipmentLocationCellProps {
    location: ShipmentLocation<UserRefDto, WarehouseRefDto, SupplierRefDto>;
}

export const ShipmentLocationCell: FC<ShipmentLocationCellProps> = ({ location }) => {
    if (isShipmentLocationUser(location)) {
        return (
            <UserLocationCard
                user={location.user}
                label='Team member'
                country={location.address?.country}
                link={getUserLink(location.user.id)}
            >
                {location.user.newJoinerStartsOn && (
                    <NewJoinerLabel startDate={location.user.newJoinerStartsOn} />
                )}
            </UserLocationCard>
        );
    }

    if (isShipmentLocationHofyWarehouse(location)) {
        return (
            <HofyWarehouseLocationCard
                label='Hofy warehouse'
                name={location.warehouse.name}
                country={location.address?.country}
            />
        );
    }

    if (isShipmentLocationOrgWarehouse(location)) {
        return (
            <OrgWarehouseLocationCard
                label='Storage location'
                name={location.warehouse.name}
                country={location.address?.country}
            />
        );
    }

    if (isShipmentLocationSupplier(location)) {
        return (
            <SupplierLocationCard
                label='Supplier'
                name={location.supplier?.name ?? '--'}
                country={location.address?.country}
            />
        );
    }

    return null;
};
