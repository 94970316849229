import { DateString } from '@hofy/global';
import { formatDate, isDateToday, isDateTomorrow } from '@hofy/helpers';
import { useBaseI18n } from '@hofy/i18n';

export const useReadableDate = () => {
    const { tr } = useBaseI18n();

    const formatReadableDate = (date: DateString): string => {
        if (isDateToday(date)) {
            return tr('date.today');
        } else if (isDateTomorrow(date)) {
            return tr('date.tomorrow');
        } else {
            return formatDate(date);
        }
    };

    return {
        formatReadableDate,
    };
};
