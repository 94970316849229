import React, { FC } from 'react';
import styled from 'styled-components';

interface FileUploadProps {
    accept: string;
    disabled?: boolean;
    onChange(v: FileList): void;
}

export const InvisibleFileInput: FC<FileUploadProps> = ({ onChange, disabled, accept }) => {
    return (
        <Input
            type='file'
            accept={accept}
            disabled={disabled}
            onChange={event => {
                if (event.target.files) {
                    onChange(event.target.files);
                    (event.target as any).value = null;
                }
            }}
        />
    );
};

const Input = styled.input`
    cursor: pointer;
    opacity: 0;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;
