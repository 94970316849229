import React, { FC } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import { Permission } from '@hofy/api-shared';
import { Page } from '@hofy/common';
import { getEnumValues } from '@hofy/global';
import { usePermission } from '@hofy/permission';
import { EnumRoute } from '@hofy/router';

import { AdminNavLink } from '../../components/routing/AdminNavLink';
import { AdminSettingsTab, allSettingsTabs } from '../../store/settings/types/AdminSettingsTab';
import { AddonsRouter } from './addons/AddonsRouter';
import { ApiKeysRouter } from './apiKeys/ApiKeysRouter';
import { BetaFeaturesRouter } from './betaFeatures/BetaFeaturesRouter';
import { CountryMultipliersTab } from './countryMultipliers/CountryMultipliersTab';
import { DataPage } from './data/DataPage';
import { ExchangeRatesRouter } from './exchangeRates/ExchangeRatesRouter';
import { FulfilmentCountrySettingsRouter } from './fulfilmentCountrySettings/FulfilmentCountrySettingsRouter';
import { NetsuiteRouter } from './netsuite/NetsuiteRouter';
import { SubsidiariesTab } from './subsidiaries/SubsidiariesTab';
import { TermsAndConditionsTab } from './termsAndConditions/TermsAndConditionsTab';
import { WarehouseRouter } from './warehouses/WarehouseRouter';

const allSettingsTabsParams = allSettingsTabs.join('|');

export const SettingsRouter: FC = () => {
    const history = useHistory();

    const settingsPath = AdminNavLink.Settings;

    const handleTabChange = (tab: AdminSettingsTab) => {
        history.push(`${settingsPath}/${tab}`);
    };

    const { hasPermission } = usePermission();

    const allSettingsTabs: AdminSettingsTab[] = getEnumValues(AdminSettingsTab);

    const allTabs: AdminSettingsTab[] = hasPermission(Permission.AdminApiKeyView)
        ? allSettingsTabs
        : allSettingsTabs.filter(tab => tab !== AdminSettingsTab.ApiKeys);

    const content = (tab: AdminSettingsTab) => {
        switch (tab) {
            case AdminSettingsTab.TermsAndConditions:
                return <TermsAndConditionsTab tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.CountryMultipliers:
                return <CountryMultipliersTab tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.ApiKeys:
                return <ApiKeysRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.ExchangeRates:
                return <ExchangeRatesRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.BetaFeatures:
                return <BetaFeaturesRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.Warehouses:
                return <WarehouseRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.FulfilmentCountrySettings:
                return (
                    <FulfilmentCountrySettingsRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />
                );
            case AdminSettingsTab.Subsidiaries:
                return <SubsidiariesTab tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.Data:
                return <DataPage tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.Addons:
                return <AddonsRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
            case AdminSettingsTab.Netsuite:
                return <NetsuiteRouter tab={tab} tabs={allTabs} onChangeTab={handleTabChange} />;
        }
    };

    return (
        <Page>
            <Switch>
                <SettingsTabRoute path={`${settingsPath}/:tab(${allSettingsTabsParams})`}>
                    {({ tab }) => content(tab)}
                </SettingsTabRoute>
                <Redirect to={`${settingsPath}/${allSettingsTabs[0]}`} from={settingsPath} />
            </Switch>
        </Page>
    );
};

const SettingsTabRoute = EnumRoute<AdminSettingsTab>('tab', AdminSettingsTab, Route);
