export const contractExtendOptionI18n = {
    'contract-extend-option.default': 'Default',
    'contract-extend-option.not': 'Not extend',
    'contract-extend-option.months-24': '24 months',
    'contract-extend-option.months-36': '36 months',
    'contract-extend-option.months-48': '48 months',
};

export const contractExtendOptionDurationI18n = {
    'contract-extend-option-duration.default': 'Default',
    'contract-extend-option-duration.not': 'Not extend',
    'contract-extend-option-duration.months-24': 'End contracts at 24 months',
    'contract-extend-option-duration.months-36': 'End contracts at 36 months',
    'contract-extend-option-duration.months-48': 'End contracts at 48 months',
};

export const contractExtendOptionExplanationI18n = {
    'contract-extend-option-explanation.default': 'Default',
    'contract-extend-option-explanation.not': 'Not extend',
    'contract-extend-option-explanation.months-24': '24 months in total',
    'contract-extend-option-explanation.months-36': '36 months in total',
    'contract-extend-option-explanation.months-48': '48 months in total',
};
