import { StoreAndReuseStatus } from '@hofy/api-shared';
import { getEnumValues } from '@hofy/global';

export enum StoreAndReuseStatusFilter {
    CollectingForStoreAndReuse = 'collecting_for_store_and_reuse',
    ReadyToReuse = 'ready_to_reuse',
    Reused = 'reused',
    IsStoreAndReuse = 'is_store_and_reuse',
}

export const allStoreAndReuseStatusFilters =
    getEnumValues<StoreAndReuseStatusFilter>(StoreAndReuseStatusFilter);

export const isStoreAndReuseChildFilter = (storeAndReuseStatus: StoreAndReuseStatusFilter[] | undefined) => {
    return storeAndReuseStatus?.some(s => s === StoreAndReuseStatusFilter.IsStoreAndReuse) ? true : null;
};

export const storeAndReuseStatusToPayload = (
    storeAndReuseStatus: StoreAndReuseStatusFilter[] | undefined,
): StoreAndReuseStatus[] => {
    return (
        storeAndReuseStatus
            ?.filter(s => s !== StoreAndReuseStatusFilter.IsStoreAndReuse)
            .map(s => {
                switch (s) {
                    case StoreAndReuseStatusFilter.CollectingForStoreAndReuse:
                        return StoreAndReuseStatus.Enabled;
                    case StoreAndReuseStatusFilter.ReadyToReuse:
                        return StoreAndReuseStatus.Ready;
                    case StoreAndReuseStatusFilter.Reused:
                        return StoreAndReuseStatus.Reused;
                }
                return StoreAndReuseStatus.Enabled;
            }) || []
    );
};
