import React, { FC } from 'react';

import { PaymentSchema, RentalTerm } from '@hofy/api-shared';
import { FormDropdown } from '@hofy/common';
import { Color } from '@hofy/theme';
import { MarginBoxProps, Paragraph3 } from '@hofy/ui';

import { useCoreI18n } from '../../../i18n/useCoreI18n';

interface PaymentSchemaFormDropdownProps extends MarginBoxProps {
    value: PaymentSchema;
    onChange(paymentSchema: PaymentSchema): void;
    rentalTerm: RentalTerm | null;
    allowedBillingFrequencies: PaymentSchema[];
    disabled?: boolean;
    addonsEnabled: boolean;
    width?: string | number | 'auto';
}

export const PaymentSchemaFormDropdown: FC<PaymentSchemaFormDropdownProps> = ({
    value,
    onChange,
    allowedBillingFrequencies,
    rentalTerm,
    disabled = false,
    addonsEnabled = false,
    width = 250,
    ...margins
}) => {
    const { tr } = useCoreI18n();

    const paymentSchemaOptions = [
        {
            title: tr('contract-settings.dropdown.billing-frequency.monthly'),
            value: PaymentSchema.Monthly,
            disabled: !allowedBillingFrequencies.includes(PaymentSchema.Monthly),
        },
        {
            title: tr('contract-settings.dropdown.billing-frequency.annual'),
            value: PaymentSchema.Annual,
            disabled:
                rentalTerm === RentalTerm.Rental6Months ||
                rentalTerm === RentalTerm.Rental12Months ||
                !allowedBillingFrequencies.includes(PaymentSchema.Annual),
        },
        {
            title: tr('contract-settings.dropdown.billing-frequency.upfront'),
            value: PaymentSchema.Upfront,
            disabled: !allowedBillingFrequencies.includes(PaymentSchema.Upfront),
        },
    ];

    return (
        <FormDropdown
            items={paymentSchemaOptions}
            value={paymentSchemaOptions.find(item => item.value === value)}
            onChange={selected => value && selected && !selected.disabled && onChange(selected.value)}
            labelFormatter={item => (
                <Paragraph3
                    textNoWrap
                    overflow='hidden'
                    ellipsis
                    color={item.disabled ? Color.Neutral300 : Color.Neutral700}
                >
                    {item.title}
                </Paragraph3>
            )}
            label={
                addonsEnabled
                    ? tr('contract-settings.dropdown.device-billing-frequency')
                    : tr('contract-settings.dropdown.billing-frequency')
            }
            width={width}
            disabled={disabled}
            {...margins}
        />
    );
};
