import { AddonConstraintDto } from '@hofy/api-admin';
import { AddonTarget } from '@hofy/api-shared';
import { isBlank } from '@hofy/ui';

export enum AddonConstraint {
    ContractType = 'contractType',
    RentalTerm = 'rentalTerm',
    Durations = 'durations',
    PaymentSchemas = 'paymentSchemas',
    ProductParentCategories = 'productParentCategories',
    ProductCategories = 'productCategories',
    ProductBrands = 'productCategoryBrands',
    AssetAgeMax = 'assetAgeMax',
    PlatformTiers = 'platformTiers',
}

export const getAddonConstraintLabel = (() => {
    const addonConstraintLabels = {
        [AddonConstraint.ContractType]: 'Contract type',
        [AddonConstraint.RentalTerm]: 'Rental term',
        [AddonConstraint.Durations]: 'Durations',
        [AddonConstraint.PaymentSchemas]: 'Payment schemas',
        [AddonConstraint.ProductParentCategories]: 'Product parent categories',
        [AddonConstraint.ProductCategories]: 'Product categories',
        [AddonConstraint.ProductBrands]: 'Product brands',
        [AddonConstraint.AssetAgeMax]: 'Asset max age',
        [AddonConstraint.PlatformTiers]: 'Platform tiers',
    };

    return (constraint: AddonConstraint) => addonConstraintLabels[constraint];
})();

const addonConstraintKeys: Record<AddonConstraint, keyof AddonConstraintDto> = {
    [AddonConstraint.ContractType]: 'contractType',
    [AddonConstraint.RentalTerm]: 'rentalTerm',
    [AddonConstraint.Durations]: 'durations',
    [AddonConstraint.PaymentSchemas]: 'paymentSchemas',
    [AddonConstraint.ProductParentCategories]: 'productParentCategories',
    [AddonConstraint.ProductCategories]: 'productCategories',
    [AddonConstraint.ProductBrands]: 'productBrands',
    [AddonConstraint.AssetAgeMax]: 'assetAgeMax',
    [AddonConstraint.PlatformTiers]: 'platformTiers',
};

const addonConstraintsByTarget: Record<AddonTarget, AddonConstraint[]> = {
    [AddonTarget.Asset]: [
        AddonConstraint.ContractType,
        AddonConstraint.RentalTerm,
        AddonConstraint.Durations,
        AddonConstraint.PaymentSchemas,
        AddonConstraint.ProductParentCategories,
        AddonConstraint.ProductCategories,
        AddonConstraint.ProductBrands,
        AddonConstraint.AssetAgeMax,
        AddonConstraint.PlatformTiers,
    ],
    [AddonTarget.Organization]: [
        AddonConstraint.Durations,
        AddonConstraint.PaymentSchemas,
        AddonConstraint.PlatformTiers,
    ],
};

export const getAddonConstraintsByTarget = (target: AddonTarget) => {
    return addonConstraintsByTarget[target];
};

export const getInitialAddonConstraints = (target: AddonTarget, addonData: AddonConstraintDto[]) => {
    return getAddonConstraintsByTarget(target).filter(dependency =>
        addonData.some(row => !isBlank(row[addonConstraintKeys[dependency]])),
    );
};

export const getSanitizedAddonConstraintValues = (
    target: AddonTarget,
    data: AddonConstraintDto,
    dependencies: AddonConstraint[],
) => {
    return Object.fromEntries(
        getAddonConstraintsByTarget(target).map(key => [
            addonConstraintKeys[key],
            dependencies.includes(key) ? data[addonConstraintKeys[key]] : null,
        ]),
    );
};
