import { useMutation } from '@tanstack/react-query';

import { itemsService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useItemCode = (code: string, onItemFound: (id: UUID) => void) => {
    const mutation = useMutation({
        mutationFn: (code: string) => itemsService.getItemCode(code),
        onSuccess: data => onItemFound(data.itemId),
    });

    return {
        isLoading: mutation.isPending,
        isError: mutation.isError,
        findItem: () => mutation.mutate(code),
    };
};
