import React, { FC, ReactNode } from 'react';

import { allRoles, Role, useTrRole } from '@hofy/api-shared';
import { MultiSelectDropdown } from '@hofy/common';
import { BoxProps, TestKeyAware } from '@hofy/ui';

interface RoleMultiFormDropdownProps extends BoxProps, TestKeyAware {
    value: Role[];
    label: string;
    onChange(o: Role[]): void;
    emptyContent?: ReactNode;
    isError?: boolean | string;
    isRequired?: boolean;
    disabled?: boolean;
}

export const RoleMultiFormDropdown: FC<RoleMultiFormDropdownProps> = ({
    value,
    label,
    onChange,
    emptyContent = false,
    isError,
    isRequired = false,
    testKey,
    disabled = false,
    ...boxProps
}) => {
    const trRole = useTrRole();
    return (
        <MultiSelectDropdown
            value={value}
            items={allRoles}
            label={label}
            labelFormatter={trRole}
            onChange={onChange}
            isError={isError}
            isRequired={isRequired}
            emptyContent={emptyContent}
            toSearchLabel={role => trRole(role)}
            clearable
            disabled={disabled}
            testKey={testKey}
            {...boxProps}
        />
    );
};
