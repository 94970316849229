import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import {
    RevenueRecognitionDetailDto,
    RevenueRecognitionFilters,
    revenueRecognitionService,
} from '@hofy/api-admin';

import { revenueRecognitionQueryKey } from './revenueRecognitionQueryKey';

export const useRevenueRecognitionQuery = (filters: RevenueRecognitionFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [revenueRecognitionQueryKey, filters],
        queryFn: param =>
            revenueRecognitionService.listRevenueRecognition(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const revenueRecognitionEntries: RevenueRecognitionDetailDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        revenueRecognitionEntries,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};
