import { useHofyWarehousesQuery } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useWarehousesFilterDefinition = (filterName: string): FilterDefinitionMulti<UUID> => {
    const { data: warehouses } = useHofyWarehousesQuery();
    const warehouseIdLabel = (id: UUID) => {
        const warehouse = warehouses.find(warehouse => warehouse.id === id);
        return warehouse!.name;
    };
    const warehouseIds = warehouses.map(warehouse => warehouse.id);

    return {
        type: 'multi',
        name: filterName,
        query: queryStringArrayParser<UUID>(),
        allValues: warehouseIds,
        toLabel: warehouseIdLabel,
    };
};
