import { AdminBillingEntityFilters } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useStructMemo } from '@hofy/hooks';
import { useStringQueryParam } from '@hofy/router';

export const useAdminBillingEntityFilters = () => {
    const [search, setSearch] = useStringQueryParam('search', '');
    const [organizationId, setOrganizationId] = useStringQueryParam<UUID>('organizationId');

    const filters = useStructMemo<AdminBillingEntityFilters>({
        search,
        organizationIds: organizationId ? [organizationId] : [],
    });

    return {
        filters,
        setSearch,
        setOrganizationId,
    };
};
