import React, { ReactNode } from 'react';
import { Route } from 'react-router-dom';

import { isPossibleStatusTransition, ShipmentStatus } from '@hofy/api-shared';

interface ShipmentStatusRoute {
    from: ShipmentStatus;
    to: ShipmentStatus;
    path?: string;
    exact?: boolean;
    children?: ReactNode;
}

export const TransitionStatusRoute = ({ from, to, ...restProps }: ShipmentStatusRoute) => {
    return isPossibleStatusTransition(from, to) ? <Route {...restProps} /> : null;
};
