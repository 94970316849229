export const twoFactortI18n = {
    'two-factor-slideout.title': 'Two-factor authentication',
    'two-factor-slideout.cancel': 'Cancel',
    'two-factor-slideout.continue': 'Continue',
    'two-factor-slideout.scan-qr.title': 'Scan the QR code with your authentication app on your phone.',
    'two-factor-slideout.scan-qr.subtitle': 'Can’t scan the QR code? manually enter *this code* instead.',
    'two-factor-slideout.code.title': 'Enter the 6-digit code from your authenticator app',
    'two-factor-slideout.code.subtitle':
        'After adding the authentication to your authenticator app, you will be displayed with a 6-digit authentication code that refreshes every 30 seconds.',
    'two-factor-slideout.authentication-code.label': 'Authentication code',
    'two-factor-slideout.authentication-code.error': 'Authentication code not valid',
    'two-factor-slideout.save': 'Set 2FA',
    'two-factor-slideout.save-codes.title': 'Save your recover codes',
    'two-factor-slideout.save-codes.subtitle':
        'If you lose access to your phone, you can use your recovery codes to regain access to your Hofy account. Keep these stored somewhere safe.',
    'two-factor-slideout.download-codes.label': 'Download recovery codes',

    'two-factor-slideout.secret-model.title': 'Two-factor secret',
    'two-factor-slideout.secret-model.ok': 'Close',

    'two-factor-page.title': 'Two-factor authentication',
    'two-factor-page.subtitle':
        'Your company has enabled two-factor security for all accounts. Please follow the instructions below to continue.',
};
