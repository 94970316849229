import React, { FC } from 'react';

import { TaxStatus, taxStatusColors } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

import { useTrTaxStatus } from '../../../../store/invoiceEntries/useTrTaxStatus';

interface TaxStatusChipProps {
    status: TaxStatus;
}

export const TaxStatusChip: FC<TaxStatusChipProps> = ({ status }) => {
    const tr = useTrTaxStatus();
    const color = taxStatusColors[status];
    return <Badge color={color} label={tr(status)} />;
};
