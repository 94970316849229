import React, { FC } from 'react';

import { FixedAssetStatus, fixedAssetStatusColors } from '@hofy/api-admin';
import { DateString } from '@hofy/global';
import { formatDate } from '@hofy/helpers';
import { Badge, MarginBoxProps, Tooltip } from '@hofy/ui';

import { useTrFixedAssetStatus } from '../../../store/fixedAssets/useTrFixedAssetStatus';

interface FixedAssetStatusChipProps extends MarginBoxProps {
    status: FixedAssetStatus;
    date?: DateString;
}

export const FixedAssetStatusChip: FC<FixedAssetStatusChipProps> = ({ status, date, ...boxProps }) => {
    const trFixedAssetStatus = useTrFixedAssetStatus();

    if (!status) {
        return null;
    }

    const color = fixedAssetStatusColors[status];

    return (
        <Tooltip body={date && formatDate(date)}>
            <Badge color={color} label={trFixedAssetStatus(status)} {...boxProps} />
        </Tooltip>
    );
};
