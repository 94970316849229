export const billingEntityCurrencyUpdateI18n = {
    'billing-entity-page.currency-update-modal.currency-field': 'Currency',
    'billing-entity-page.currency-update-modal.header': 'Update currency',
    'billing-entity-page.currency-update-modal.confirm': 'Update',

    'billing-entity-page.fork-modal.title': 'Billing entity will be forked',
    'billing-entity-page.fork-modal.description':
        'Because the billing entity already has invoice entries that were created on the current currency, ' +
        'updating the currency will result in a new billing entity being created with the selected currency. ' +
        'All active rental contracts will be moved to the new billing entity.',
    'billing-entity-page.fork-modal.confirm': 'Fork billing entity',
    'billing-entity-page.fork-modal.name-field': 'New billing entity name',
    'billing-entity-page.fork-modal.validation.empty-name': 'Name must not be empty',
    'billing-entity-page.fork-modal.validation.same-name': 'Name must not be the same',
    'billing-entity-page.fork-modal.validation.same-currency': 'Currency must not be the same',
};
