import { ItPolicyDto, TeamColor } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

interface ItPoliciesResponse {
    itPolicies: ItPolicyDto[];
}

export interface ItPolicyPayload {
    name: string;
    color: TeamColor;
}

class ItPolicyService {
    public getItPolicies = async (organizationId: UUID): Promise<ItPolicyDto[]> => {
        const response = await restClient.getJson<ItPoliciesResponse>(
            `/api/admin/it-policies/organizations/${organizationId}`,
        );

        return response.itPolicies;
    };

    public createItPolicy = async (organizationId: UUID, payload: ItPolicyPayload): Promise<{ id: UUID }> => {
        return restClient.postJson<{ id: UUID }>(`/api/admin/it-policies/organizations/${organizationId}`, {
            name: payload.name,
            color: payload.color,
        });
    };
}

export const itPolicyService = new ItPolicyService();
