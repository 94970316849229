export enum CollectionRequestPackagingStatus {
    AssistanceRequested = 'assistance_requested',
    Sent = 'sent',
    Received = 'received',
    Resolved = 'resolved',
}

export const collectionRequestPackagingStatusLabels: Record<CollectionRequestPackagingStatus, string> = {
    [CollectionRequestPackagingStatus.AssistanceRequested]: 'Assistance requested',
    [CollectionRequestPackagingStatus.Sent]: 'Sent',
    [CollectionRequestPackagingStatus.Received]: 'Received',
    [CollectionRequestPackagingStatus.Resolved]: 'Resolved',
};
