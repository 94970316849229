import { useEffect } from 'react';

export const useTableInitialLoad = (
    ref: React.RefObject<HTMLDivElement>,
    loadNextPage: () => void,
    isLoading: boolean,
    isLoadingMore: boolean,
    hasNextPage: boolean,
) => {
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        if (
            !isLoading &&
            !isLoadingMore &&
            ref.current.scrollTop === 0 &&
            ref.current.scrollHeight === ref.current.clientHeight &&
            hasNextPage
        ) {
            loadNextPage();
        }
    }, [ref.current, isLoading, isLoadingMore, hasNextPage]);

    return {
        ref,
    };
};
