import React, { Children, FC, Fragment, ReactNode } from 'react';

import { Box, BoxProps } from '../Box';

export const SeparatedBox: FC<BoxProps> = ({ children, ...props }) => {
    const childArray = Children.toArray(children);
    return (
        <Box {...props}>
            {childArray.map((t: Exclude<ReactNode, boolean | null | undefined>, index: number) => (
                <Fragment key={index}>
                    {index !== 0 && <Divider column={props.column} row={props.row} />}
                    {t}
                </Fragment>
            ))}
        </Box>
    );
};

interface DividerProps {
    column?: boolean;
    row?: boolean;
}

const Divider: FC<DividerProps> = ({ column, row }) => {
    if (row) {
        return <Box borderLeft alignSelf='stretch' />;
    }
    if (column) {
        return <Box borderTop />;
    }

    return null;
};
