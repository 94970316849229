import React, { FC, useState } from 'react';

import { ProductDto } from '@hofy/api-admin';
import { Box, Button, Modal, ModalContent, ModalFooter, ModalHeader, Paragraph3, Span } from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ProductPicker } from '../../../components/domain/products/productPicker/ProductPicker';

interface ReplaceProductModalProps {
    product: ProductDto;
    onReplace(v: ProductDto): void;
    onForceReplacement(): void;
    sendNotificationConfig: boolean;
    onCancel(): void;
}

export const ReplaceProductModal: FC<ReplaceProductModalProps> = ({
    onForceReplacement,
    onReplace,
    onCancel,
    sendNotificationConfig,
    product,
}) => {
    const [selectedProduct, setSelectedProduct] = useState<ProductDto>();

    return (
        <Modal onClose={onCancel} width={800}>
            <ModalHeader
                title={`Deactivating ${product.name}`}
                subtitle='You are deactivating a product which is currently used in user budgets. Please select a replacement'
                borderBottom
            />
            <ModalContent>
                <ProductPicker
                    onPick={setSelectedProduct}
                    selected={selectedProduct}
                    listProductsOptions={{
                        categories: [product.category],
                        excludedProductIds: [product.id],
                        isActiveForNewOrders: true,
                    }}
                />
            </ModalContent>
            <ModalFooter borderTop>
                <CancelButton onClick={onCancel} />
                <Box flex={1} row justify='center'>
                    <Paragraph3>
                        Sending notification is
                        <Span bold>{sendNotificationConfig ? ' enabled' : ' disabled'}</Span>
                    </Paragraph3>
                </Box>
                <Button
                    action='destructive'
                    label='Force deactivation'
                    onClick={onForceReplacement}
                    marginRight={20}
                />
                <Button
                    disabled={!selectedProduct}
                    label='Replace'
                    onClick={() => onReplace(selectedProduct!)}
                />
            </ModalFooter>
        </Modal>
    );
};
