import React, { forwardRef, ReactElement, Ref } from 'react';

import { Labeled } from '../../../labeled';
import { DateInput, DateInputProps } from './DateInput';

interface LabeledDateInputBaseProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledDateInputProps = DateInputProps & LabeledDateInputBaseProps;

const LabeledDateInputComponent = (
    {
        label,
        isRequired,
        errorMessage,
        helperText,

        value,
        onChange,
        onBlur,
        clearable,
        disabled,
        nullable,
        placeholder,
        filterDate,
        minDate,
        maxDate,
        testKey,
        ...restLabeledProps
    }: LabeledDateInputProps,
    ref: Ref<HTMLInputElement>,
) => {
    return (
        <Labeled
            as='label'
            label={label}
            isRequired={isRequired}
            content={
                <DateInput
                    testKey={testKey}
                    ref={ref}
                    value={value!}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={disabled}
                    nullable={nullable}
                    placeholder={placeholder}
                    isError={!!errorMessage}
                    clearable={clearable}
                    filterDate={filterDate}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            }
            errorMessage={errorMessage}
            helperText={helperText}
            {...restLabeledProps}
        />
    );
};

export const LabeledDateInput = forwardRef(LabeledDateInputComponent) as <HTMLInputElement>(
    props: LabeledDateInputProps & { ref?: Ref<HTMLInputElement> },
) => ReactElement;
