import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { ContractExtendOption } from '../types/ContractExtendOption';

export const useTrContractExtendOption = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractExtendOption>(ContractExtendOption, 'contract-extend-option');
};

export const useTrContractExtendOptionDuration = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractExtendOption>(ContractExtendOption, 'contract-extend-option-duration');
};

export const useTrContractExtendOptionExplanation = () => {
    const { trEnum } = useSharedApiI18n();
    return trEnum<ContractExtendOption>(ContractExtendOption, 'contract-extend-option-explanation');
};
