import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { WarehouseDetailsUnionDto } from '@hofy/api-admin';
import {
    AssignmentCollectionReason,
    AssignmentException,
    AssignmentStatus,
    AssignmentType,
    isCollectionPending,
    isOrganizationWarehouse,
    RentalStoreAndReuseLocation,
    StoreAndReuseDto,
} from '@hofy/api-shared';
import { Country, DateString, UUID } from '@hofy/global';
import { Box, BoxProps, Paragraph3 } from '@hofy/ui';

import { AssignmentCollectionReasonBadge } from '../../../../../components/domain/assignments/badge/AssignmentCollectionReasonBadge';
import { AssignmentCompletionReasonBadge } from '../../../../../components/domain/assignments/badge/AssignmentCompletionReasonBadge';
import { AssignmentTypeBadge } from '../../../../../components/domain/assignments/badge/AssignmentTypeBadge';
import { ConsumableBadge } from '../../../../../components/domain/assignments/badge/ConsumableBadge';
import { InPersonTransferBadge } from '../../../../../components/domain/assignments/badge/InPersonTransferBadge';
import { OnHoldBadge } from '../../../../../components/domain/assignments/badge/OnHoldBadge';
import { OrganizationWarehouseBadge } from '../../../../../components/domain/assignments/badge/OrganizationWarehouseBadge';
import { RefurbishedBadge } from '../../../../../components/domain/assignments/badge/RefurbishedBadge';
import { ScheduledCollectionBadge } from '../../../../../components/domain/assignments/badge/ScheduledCollectionBadge';
import { StoreAndReuseBadge } from '../../../../../components/domain/assignments/badge/StoreAndReuseBadge';

interface AssignmentInfoCellProps extends BoxProps {
    id: UUID;
    publicId: string;
    status: AssignmentStatus;
    type: AssignmentType;
    collectionReason: AssignmentCollectionReason | null;
    exception: AssignmentException | null;
    isStoreAndReuseDelivery: boolean;
    isStoreAndReuseCollection: boolean;
    isRefurbishedProduct: boolean;
    isOnHold: boolean;
    isConsumable: boolean;
    linkToAssignment: string;
    isDelivery: boolean;
    storeAndReuse: StoreAndReuseDto | null;
    itemWarehouse: WarehouseDetailsUnionDto | null;
    collectionToOrganizationWarehouse: WarehouseDetailsUnionDto | null;
    country?: Country;
    collectionShipmentId: UUID | null;
    deliveryShipmentId: UUID | null;
    collectionNotBefore: DateString | null;
}

export const AssignmentInfoCell: FC<AssignmentInfoCellProps> = ({
    id,
    publicId,
    status,
    collectionReason,
    exception,
    type,
    isStoreAndReuseDelivery,
    isStoreAndReuseCollection,
    isRefurbishedProduct,
    isOnHold,
    isConsumable,
    linkToAssignment,
    isDelivery,
    storeAndReuse,
    itemWarehouse,
    collectionToOrganizationWarehouse,
    country,
    collectionShipmentId,
    deliveryShipmentId,
    collectionNotBefore,
    ...boxProps
}) => {
    const needsCollectionReason =
        collectionReason && (isCollectionPending(status) || status === AssignmentStatus.Collected);

    return (
        <Box column alignItems='flex-start' gap={4} {...boxProps}>
            <LinkToAssignment linkToAssignment={linkToAssignment} publicId={publicId} />
            <AssignmentTypeBadge type={type} />
            {isRefurbishedProduct && <RefurbishedBadge />}
            {isDelivery && isStoreAndReuseDelivery && (
                <StoreAndReuseBadge
                    type='delivery'
                    country={storeAndReuse?.country ?? country ?? null}
                    isLocalRegion={storeAndReuse?.deliverFromLocation === RentalStoreAndReuseLocation.Local}
                />
            )}
            <OrganizationWarehouseBadge
                assignmentStatus={status}
                isItemAtWarehouse={!!itemWarehouse && isOrganizationWarehouse(itemWarehouse)}
                atWarehouseAddress={itemWarehouse?.address ?? null}
                isItemCollectionToWarehouse={!!collectionToOrganizationWarehouse}
                toWarehouseAddress={collectionToOrganizationWarehouse?.address ?? null}
            />
            {isOnHold && <OnHoldBadge />}
            {isConsumable && <ConsumableBadge />}
            {!isDelivery && isStoreAndReuseCollection && (
                <StoreAndReuseBadge
                    type='collection'
                    country={storeAndReuse?.country ?? country ?? null}
                    isLocalRegion={storeAndReuse?.collectToLocation === RentalStoreAndReuseLocation.Local}
                />
            )}
            {!isDelivery && needsCollectionReason && (
                <AssignmentCollectionReasonBadge reason={collectionReason} />
            )}
            {exception && <AssignmentCompletionReasonBadge reason={exception} />}
            <InPersonTransferBadge
                assignmentStatus={status}
                exception={exception}
                hasCollection={!!collectionShipmentId}
                hasDelivery={!!deliveryShipmentId}
            />
            <ScheduledCollectionBadge assignmentStatus={status} notBefore={collectionNotBefore} />
        </Box>
    );
};

interface LinkToAssignmentProps {
    linkToAssignment: string;
    publicId: string;
}

const LinkToAssignment: FC<LinkToAssignmentProps> = ({ linkToAssignment, publicId }) => {
    return (
        <Link to={linkToAssignment} onClick={e => e.stopPropagation()}>
            <Paragraph3>{publicId}</Paragraph3>
        </Link>
    );
};
