import { useMutation, useQueryClient } from '@tanstack/react-query';

import { repaymentPlansCacheKey, repaymentPlanService, TriggerInvoicingPayload } from '@hofy/api-admin';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useTriggerTwoInvoicing = (billingEntityId: UUID, onSuccess: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();

    const mutation = useMutation({
        mutationFn: (payload: TriggerInvoicingPayload) =>
            repaymentPlanService.triggerInvoicing(billingEntityId, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [repaymentPlansCacheKey] });
            showToast({
                type: 'positive',
                message: 'Two invoicing triggered',
            });
            onSuccess();
        },
    });

    const triggerInvoicing = (payload: TriggerInvoicingPayload) => {
        mutation.mutate(payload);
    };

    return {
        triggerInvoicing,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
