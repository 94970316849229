import React, { FC, useState } from 'react';

import { ExchangeRateDto, useExchangeRatesQuery } from '@hofy/api-admin';
import { Permission, Role } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { isDateInFuture } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import { Badge, BaseTable, ConfirmModal, IconButton, Modals, SvgIcon } from '@hofy/ui';

import { useDeleteExchangeRate } from '../../../store/exchangeRates/useDeleteExchangeRate';

interface ExchangeRatesPageProps {}

export const ExchangeRatesPage: FC<ExchangeRatesPageProps> = () => {
    const { data = [], isLoading } = useExchangeRatesQuery();
    const { deleteExchangeRate } = useDeleteExchangeRate();
    const [deleteExchangeRateId, setDeleteExchangeRateId] = useState<UUID | null>(null);
    const { hasPermission, hasRole } = usePermission();
    const canDelete = hasPermission(Permission.AdminExchangeRateDelete) && hasRole(Role.SuperAdmin);

    return (
        <>
            <BaseTable
                isLoading={isLoading}
                data={data}
                toKey={item => item.id}
                flex='auto'
                columns={[
                    {
                        id: 'applyDate',
                        header: 'Apply date',
                        flexGrow: 1,
                        renderer: item => item.applyOn,
                    },
                    {
                        id: 'from',
                        header: 'From',
                        flexGrow: 1,
                        renderer: item => item.fromCurrency,
                    },
                    {
                        id: 'to',
                        header: 'To',
                        flexGrow: 1,
                        renderer: item => item.toCurrency,
                    },
                    {
                        id: 'rate',
                        header: 'Rate',
                        flexGrow: 1,
                        renderer: item => item.rate,
                    },
                    {
                        id: 'backRate',
                        header: 'Back rate',
                        flexGrow: 1,
                        renderer: item => item.backRate,
                    },
                    {
                        id: 'actions',
                        flexGrow: 0,
                        width: 100,
                        renderer: item => (
                            <ActionExchangeRateCell
                                exchangeRate={item}
                                canDelete={canDelete}
                                onDelete={() => setDeleteExchangeRateId(item.id)}
                            />
                        ),
                    },
                ]}
            />
            <Modals>
                {!!deleteExchangeRateId && (
                    <ConfirmModal
                        keyPrefix='delete-exchange-rate-modal'
                        onClose={() => setDeleteExchangeRateId(null)}
                        onConfirm={() => deleteExchangeRate(deleteExchangeRateId)}
                    />
                )}
            </Modals>
        </>
    );
};

interface ActionExchangeRateCellProps {
    exchangeRate: ExchangeRateDto;
    onDelete(): void;
    canDelete: boolean;
}

const ActionExchangeRateCell: FC<ActionExchangeRateCellProps> = ({ onDelete, exchangeRate, canDelete }) => {
    if (isDateInFuture(exchangeRate.applyOn) && canDelete) {
        return <IconButton icon={SvgIcon.Trash} onClick={onDelete} />;
    }
    if (exchangeRate.isActive) {
        return <Badge color='green' label='Active' />;
    }
    return null;
};
