import { BillingEntityDto, InvoiceEntryPayload } from '@hofy/api-admin';
import { ContractType, InvoiceEntryType, periodRequiredTypes } from '@hofy/api-shared';
import { Currency, DateString, Percent, Price, toCurrency } from '@hofy/global';
import { endOfMonth, startOfMonth } from '@hofy/helpers';
import {
    isDecimalGreaterThan,
    isDecimalLessThan,
    isGreaterThan,
    isPriceNegativeIf,
    isPricePositiveIf,
    isRequired,
    isRequiredIf,
    useForm,
    validator,
} from '@hofy/ui';

import { usePriceManipulation } from './usePriceManipulation';

export const useInvoiceEntryForm = (
    initial: InvoiceEntryPayload,
    onSubmit: (v: InvoiceEntryPayload) => void,
) => {
    const { preDiscountedPrice, discountedPrice } = usePriceManipulation();

    const form = useForm<InvoiceEntryPayload>({
        initial,
        onSubmit,
        validate: validator<InvoiceEntryPayload>({
            type: isRequired('Type is required'),
            description: isRequired('Description is required'),
            invoicePeriodFrom: isRequiredIf(
                v => !!v.type && periodRequiredTypes.includes(v.type),
                'Period from is required',
            ),
            invoicePeriodTo: isRequiredIf(
                v => !!v.type && periodRequiredTypes.includes(v.type),
                'Period to is required',
            ),
            invoiceTime: isRequired('Invoice time is required'),
            billingEntityId: isRequired('Billing entity is required'),
            hofySubsidiaryId: isRequired('Hofy subsidiary is required'),
            quantity: isGreaterThan(0, 'Quantity must be positive'),
            price: [
                isPricePositiveIf(v => v.type !== InvoiceEntryType.CreditNote, 'Price must be positive'),
                isPriceNegativeIf(v => v.type === InvoiceEntryType.CreditNote, 'Price must be negative'),
            ],
            unitPrice: [
                isPricePositiveIf(v => v.type !== InvoiceEntryType.CreditNote, 'Unit price must be positive'),
                isPriceNegativeIf(v => v.type === InvoiceEntryType.CreditNote, 'Unit price must be negative'),
            ],
            discount: [
                isDecimalLessThan('100.00', 'Discount must lower then 100'),
                isDecimalGreaterThan('-100.00', 'Discount must greater then -100'),
            ],
        }),
    });

    const setUnitPrice = (value: Price) => {
        const unitPrice = value;
        const price = discountedPrice(unitPrice, form.values.discount);
        form.setValues({
            unitPrice,
            price,
        });
    };

    const setDiscount = (value: Percent) => {
        form.setValues({
            discount: value,
            price: discountedPrice(form.values.unitPrice, value),
        });
    };

    const setPrice = (value: Price) => {
        const price = value;
        const unitPrice = preDiscountedPrice(price, form.values.discount);
        form.setValues({
            price,
            unitPrice,
        });
    };

    const changeBillingEntity = (billingEntity: BillingEntityDto | null) => {
        const newCurrency = billingEntity?.currency || Currency.USD;
        form.setValues({
            billingEntityId: billingEntity?.id || null,
            price: toCurrency(form.values.price, newCurrency),
            unitPrice: toCurrency(form.values.unitPrice, newCurrency),
        });
    };
    const changeType = (type: InvoiceEntryType) => {
        if (periodRequiredTypes.includes(type)) {
            form.setValues({
                type,
                invoicePeriodTo: endOfMonth().toISODate() as DateString,
                invoicePeriodFrom: startOfMonth().toISODate() as DateString,
            });
        } else {
            form.setValues({
                type,
                invoicePeriodTo: null,
                invoicePeriodFrom: null,
            });
        }
    };

    const changeContractType = (contractType: ContractType) => {
        form.setValues({
            contractType: contractType,
        });
    };
    return {
        form,
        setUnitPrice,
        setDiscount,
        setPrice,
        changeBillingEntity,
        changeType,
        changeContractType,
    };
};

export type UseInvoiceEntryForm = ReturnType<typeof useInvoiceEntryForm>;
