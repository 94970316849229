import { uniq } from 'lodash';
import React, { FC } from 'react';

import { ShipmentCollectionRequestDto } from '@hofy/api-admin';
import { Alert } from '@hofy/ui';

import { DifferentCountryWarning } from './DifferentCountryWarning';

interface WarningsSectionProps {
    collectionRequest: ShipmentCollectionRequestDto;
}

export const WarningsSection: FC<WarningsSectionProps> = ({ collectionRequest }) => {
    const { collectionCountry, countries } = handleCollectionRequest(collectionRequest);

    const isDifferentCountry = collectionCountry && !countries.includes(collectionCountry);
    if (!collectionRequest.formSubmittedByUser || !isDifferentCountry) {
        return <Alert type='positive' description='No warnings' />;
    }

    return (
        <DifferentCountryWarning
            key='different-country-action'
            collectionCountry={collectionCountry}
            countries={countries}
        />
    );
};

const handleCollectionRequest = (collectionRequest: ShipmentCollectionRequestDto) => {
    const activeItems = collectionRequest.itemsToCollect.filter(item => !item.disabled);

    const collectionCountry = collectionRequest.userConfirmedAddress?.country;
    const countries = uniq(activeItems.map(item => item.country));

    return { collectionCountry, countries };
};
