import { DateTime } from 'luxon';
import React, { FC, useState } from 'react';

import { formatMonth } from '@hofy/helpers';
import {
    AsyncButton,
    FormContainer,
    LabeledSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
    MonthLabel,
} from '@hofy/ui';

import { CancelButton } from '../../../../components/design/button/CancelButton';

interface SelectorModelProps {
    title: string;
    selectorLabel: string;
    selectorOptions: DateTime[];
    selectorInitialValue: DateTime;
    submitLabel: string;
    isLoading: boolean;
    isError: boolean;
    onSubmit(selected: DateTime): void;
    onClose(): void;
}

export const MonthSelectorModal: FC<SelectorModelProps> = ({
    title,
    selectorLabel,
    selectorOptions,
    selectorInitialValue,
    submitLabel,
    isLoading,
    isError,
    onSubmit,
    onClose,
}) => {
    const [month, setMonth] = useState(selectorInitialValue);
    const submit = () => {
        onSubmit(month);
    };

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title={title} />
            <ModalContent>
                <FormContainer>
                    <LabeledSelect
                        label={selectorLabel}
                        options={selectorOptions}
                        value={month}
                        onChange={date => setMonth(date)}
                        toText={formatMonth}
                        toLabel={date => <MonthLabel date={date} />}
                    />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label={submitLabel}
                    disableCheck
                    onClick={submit}
                    isLoading={isLoading}
                    isError={isError}
                />
            </ModalFooter>
        </Modal>
    );
};
