import React, { FC } from 'react';

import { ItemEventDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import { LabeledText, Modal, ModalContent, ModalHeader } from '@hofy/ui';

interface ManualFixeDetailsModalProps {
    itemEvent: ItemEventDto;
    onClose(): void;
}

export const ManualFixDetailsModal: FC<ManualFixeDetailsModalProps> = ({ itemEvent, onClose }) => {
    return (
        <Modal onClose={onClose} width={600}>
            <ModalHeader title='Manual fix details' />
            <ModalContent>
                <LabeledText
                    marginVertical={12}
                    labelGap={4}
                    label='Fixed at'
                    content={formatDate(itemEvent.manuallyFixedAt)}
                />
                <LabeledText
                    marginVertical={12}
                    labelGap={4}
                    label='Reason'
                    content={itemEvent.errorReason}
                />
                <LabeledText
                    marginVertical={12}
                    labelGap={4}
                    label='Notes'
                    content={itemEvent.manualFixNotes}
                />
            </ModalContent>
        </Modal>
    );
};
