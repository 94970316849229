import React, { Key, ReactNode, useEffect } from 'react';

import { Sort } from '@hofy/global';
import { NumberValues } from '@hofy/theme';

import { TestKeyAware } from '../../../types';
import { BoxProps } from '../../base';
import { BaseTable } from '../BaseTable';
import { SortFilterDataTableColumnConfig } from '../types/SortFilterDataTableTypes';
import { TableRowRendererProps } from '../types/TableRowRendererProps';
import { useTableFilter } from './hooks/useTableFilters';
import { useTableSelection } from './hooks/useTableSelection';
import { useTableSort } from './hooks/useTableSort';
import { SortFilterDataTableHeader } from './SortFilterDataTableHeader';

interface SortFilterDataTableProps<T> extends BoxProps, TestKeyAware {
    data: T[];
    columns: SortFilterDataTableColumnConfig<T>[];
    isLoading?: boolean;
    emptyContent?: ReactNode;
    onSelect?(v: T[]): void;
    selected?: T[];
    toKey(v: T, index: number): Key;
    onRowClick?(v: T, index: number): void;
    defaultSort?: Sort<any>;
    setDataCount?(p: number): void;
    minWidth?: string | number;
    width?: string | number;
    flex?: number | 'auto';
    bodyPaddingTop?: NumberValues;
    bodyPaddingBottom?: NumberValues;
    rowVerticalPadding?: NumberValues;
    rowHorizontalPadding?: NumberValues;
    headerHorizontalPadding?: NumberValues;
    rowRenderer?(item: TableRowRendererProps<T>): ReactNode;
}

export const SortFilterDataTable = <T extends any>({
    data,
    columns: initialColumns,
    onRowClick,
    defaultSort,
    selected = [],
    onSelect,
    isLoading,
    emptyContent,
    toKey,
    setDataCount,
    minWidth,
    width,
    bodyPaddingTop,
    bodyPaddingBottom,
    flex,
    rowVerticalPadding = 20,
    rowHorizontalPadding = 20,
    headerHorizontalPadding = 40,
    rowRenderer,
    ...boxProps
}: SortFilterDataTableProps<T>) => {
    const { data: sortedData, sort, handleSort } = useTableSort(data, initialColumns, defaultSort);
    const { data: effectiveData, filterState, setFilter } = useTableFilter(sortedData, initialColumns);
    const { columns, toggleRow } = useTableSelection(initialColumns, effectiveData, selected, onSelect);

    useEffect(() => {
        if (setDataCount) {
            setDataCount(effectiveData.length);
        }
    }, [effectiveData.length]);

    return (
        <BaseTable
            data={effectiveData}
            minWidth={minWidth}
            width={width}
            columns={columns}
            flex={flex}
            rowVerticalPadding={rowVerticalPadding}
            rowHorizontalPadding={rowHorizontalPadding}
            headerHorizontalPadding={headerHorizontalPadding}
            headerRenderer={() => (
                <SortFilterDataTableHeader
                    minWidth={minWidth}
                    columns={columns}
                    sort={sort}
                    handleSort={handleSort}
                    setFilter={setFilter}
                    filterState={filterState}
                    horizontalPadding={headerHorizontalPadding}
                />
            )}
            isLoading={isLoading}
            emptyContent={emptyContent}
            toKey={toKey}
            onRowClick={(onSelect && toggleRow) || onRowClick}
            bodyPaddingBottom={bodyPaddingBottom}
            bodyPaddingTop={bodyPaddingTop}
            rowRenderer={rowRenderer}
            {...boxProps}
        />
    );
};
