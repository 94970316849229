import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';

import { Link } from '../../routing/Link';

interface ShipmentLinkProps {
    shipment: PublicRefDto;
    children?: ReactNode;
}

export const ShipmentLink: FC<ShipmentLinkProps> = ({ shipment, children }) => {
    return <Link to={`/assignments/all/shipment/${shipment.id}/details`}>{children}</Link>;
};
export const ShipmentLinkLabel: FC<{ shipment: PublicRefDto | null }> = ({ shipment }) => {
    return shipment ? <ShipmentLink shipment={shipment}>{shipment.publicId}</ShipmentLink> : <>--</>;
};
