import { useWindowSize } from 'react-use';

export interface RwdConfig {
    isPhone: boolean;
    isTablet: boolean;
    isLaptop: boolean;
    isMobile: boolean;

    isDesktop: boolean;
    isLarge: boolean;
}

const BREAK_POINTS = {
    phone: 425,
    tablet: 1024,
    desktop: 1440,
};

export const useRWDConfig = (): RwdConfig => {
    const { width } = useWindowSize();

    const isPhone = width < BREAK_POINTS.phone;
    const isTablet = width >= BREAK_POINTS.phone && width < BREAK_POINTS.tablet;
    const isLaptop = width >= BREAK_POINTS.tablet && width < BREAK_POINTS.desktop;
    const isDesktop = width >= BREAK_POINTS.desktop;

    const isMobile = isPhone || isTablet;
    const isLarge = isLaptop || isDesktop;

    return {
        isPhone,
        isTablet,
        /** Same as: `isPhone || isTablet` */
        isMobile,

        isLaptop,
        isDesktop,
        /** Same as: `isLaptop || isDesktop` */
        isLarge,
    };
};
