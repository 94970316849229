import React, { FC } from 'react';

import { Box, Icon, SvgIcon } from '@hofy/ui';

import { ClearableInput } from '../input/ClearableInput';

interface DropdownSearchInputProps {
    search: string;
    setSearch(value: string): void;
    autoFocus?: boolean;
    placeholder?: string;
}

export const DropdownSearchInput: FC<DropdownSearchInputProps> = ({
    search,
    setSearch,
    autoFocus,
    placeholder = 'Search',
}) => {
    return (
        <Box row height={50} borderBottom>
            <ClearableInput
                autoFocus={autoFocus}
                value={search}
                onChangeText={setSearch}
                placeholder={placeholder}
                prefix={<Icon size={16} block svg={SvgIcon.Search} />}
                bordered={false}
                fullWidth
            />
        </Box>
    );
};
