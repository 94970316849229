import { useMemo } from 'react';

import { ProductDto, useProductsQuery } from '@hofy/api-admin';
import { isProductOfCategory, StockCondition } from '@hofy/api-shared';
import { AnyRegion, someBelongToRegion } from '@hofy/global';
import { useSearch } from '@hofy/hooks';

import { availabilityAll } from './types/Availability';
import { emptyProductFilters, ProductFilters } from './types/ProductFilters';
import { ProductStatus } from './types/ProductStatus';

export const useProducts = (userFilters: Partial<ProductFilters>) => {
    const { data, isLoading } = useProductsQuery();

    const finalProducts = useMemo(() => {
        const filters: ProductFilters = {
            ...emptyProductFilters,
            ...userFilters,
        };
        return data.filter(product => productPredicate(product, filters));
    }, [userFilters, data]);

    const searchString = userFilters.search?.toLowerCase() || '';

    const fuzzyResults = useSearch(finalProducts, ({ name, brand }) => [name, brand], searchString);

    const skuResults = useMemo(
        () =>
            finalProducts?.filter(({ variants }) =>
                variants.some(v => v.sku.toLowerCase().includes(searchString)),
            ),
        [finalProducts, searchString],
    );

    return {
        data: skuResults.length === 0 || !searchString ? fuzzyResults : skuResults,
        isLoading,
    };
};

const productPredicate = (
    product: ProductDto,
    { productStatus, category, country, availability, stockCondition }: ProductFilters,
) => {
    if (!isProductOfCategory(product, category)) {
        return false;
    }

    const checkVariantStatus = (variantStatus: boolean) => {
        if (productStatus === ProductStatus.All) {
            return true;
        }
        if (productStatus === ProductStatus.Inactive && !variantStatus) {
            return true;
        }
        if (productStatus === ProductStatus.Active && variantStatus) {
            return true;
        }
        return false;
    };

    if (
        (stockCondition === StockCondition.New && product.isRefurbished) ||
        (stockCondition === StockCondition.Refurbished && !product.isRefurbished)
    ) {
        return false;
    }

    if (
        (productStatus === ProductStatus.Active && !product.isActive) ||
        (productStatus === ProductStatus.Inactive && product.isActive)
    ) {
        return false;
    }

    if (country) {
        for (let variant of product.variants) {
            if (checkVariantStatus(variant.isActive) && variant.availability.includes(country)) {
                return true;
            }
        }
        return false;
    }

    if (availability !== availabilityAll) {
        for (let variant of product.variants) {
            if (
                checkVariantStatus(variant.isActive) &&
                someBelongToRegion(availability as AnyRegion, variant.availability)
            ) {
                return true;
            }
        }
        return false;
    }

    return true;
};
