import { ProductDto } from '@hofy/api-shared';

import { CountryMultiplierDto } from '../../../country/service/types/CountryMultiplierDto';
import { ExchangeRateDto } from '../../../exchangeRate/service/types/ExchangeRateDto';

export interface ExportDto {
    countryMultipliers: CountryMultiplierDto[];
    products: ProductDto[];
    exchangeRates: ExchangeRateDto[];
}

export const emptyExportDto: ExportDto = {
    countryMultipliers: [],
    products: [],
    exchangeRates: [],
};
