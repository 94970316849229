import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { AdminRefDto, formatAdminName } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

export const useAdminFilterDefintion = (
    filterName: string,
    admins: AdminRefDto[],
): FilterDefinitionMulti<UUID> => {
    const nameMap = useMemo(() => keyBy(admins, v => v.id), [admins]);
    const toLabel = (id: UUID) => {
        return nameMap[id] ? formatAdminName(nameMap[id]) : '';
    };

    return {
        type: 'multi',
        name: filterName,
        query: queryStringArrayParser<UUID>(),
        allValues: admins.map(a => a.id),
        toLabel: toLabel,
    };
};
