import React, { FC } from 'react';

import { RepaymentPlanRefDto, RepaymentStatus } from '@hofy/api-shared';

import { RepaymentStatusBadge } from './RepaymentStatusBadge';

interface RepaymentStatusLabelProps {
    repayment: RepaymentPlanRefDto | null;
}

export const RepaymentStatusLabel: FC<RepaymentStatusLabelProps> = ({ repayment }) => {
    const status = repayment?.status ?? RepaymentStatus.Pending;
    return <RepaymentStatusBadge status={status} />;
};
