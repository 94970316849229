import React, { FC } from 'react';

import { AddressType, emptyAddressPayload } from '@hofy/api-shared';
import {
    BackButton,
    CenteredBox,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton, Box, LabeledCheckbox, Placeholder, SvgIcon, SvgIllustration } from '@hofy/ui';

import { useInvoiceEntryTaxAddressForm } from '../../../../store/invoiceEntries/useInvoiceEntryTaxAddressForm';
import { InvoiceEntryTaxAddressForm } from '../components/InvoiceEntryTaxAddressForm';

interface UpdateInvoiceEntryTaxAddressSlideoutProps {
    entryId: UUID;
    basePath: string;
    onClose(): void;
}

export const UpdateInvoiceEntryTaxAddressSlideout: FC<UpdateInvoiceEntryTaxAddressSlideoutProps> = ({
    entryId,
    basePath,
    onClose,
}) => {
    const { form, isLoadingMutation, isError } = useInvoiceEntryTaxAddressForm(entryId, onClose);

    if (isError) {
        return (
            <CenteredBox>
                <Placeholder
                    illustration={SvgIllustration.FinanceSearch}
                    title='No such invoice entry'
                    message='We could not find an invoice entry with provided identifier'
                />
            </CenteredBox>
        );
    }
    const toggleFrom = () => {
        if (!form.values.fromAddress) {
            form.setValues({ fromAddress: emptyAddressPayload(AddressType.Billing) });
        } else {
            form.setValues({ fromAddress: null });
        }
    };
    return (
        <Slideout width={900} onClose={onClose}>
            <SlideoutHeader title='Update tax address'>
                <Box flex={1} row justify='flex-end'>
                    <LabeledCheckbox
                        label='Include from address'
                        checked={!!form.values.fromAddress}
                        onChange={toggleFrom}
                    />
                </Box>
            </SlideoutHeader>
            <SlideoutContent paddingBottom={30}>
                <InvoiceEntryTaxAddressForm form={form} />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} defaultNavigation={basePath} />
                <AsyncButton isLoading={isLoadingMutation} label='Save' onClick={form.submit} />
            </SlideoutFooter>
        </Slideout>
    );
};
