import React, { FC, useState } from 'react';

import { BillingEntityContactDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import {
    Alert,
    AsyncButton,
    CheckboxGroup,
    Labeled,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../../../components/design/button/CancelButton';
import { useSendEmailByInvoice } from '../../../../../store/invoices/useSendEmailByInvoice';

interface SendInvoiceModalProps {
    invoiceId: UUID;
    contacts: BillingEntityContactDto[];
    emailSent: boolean;
    onClose(): void;
}

export const SendInvoiceModal: FC<SendInvoiceModalProps> = ({ invoiceId, contacts, emailSent, onClose }) => {
    const {
        sendInvoice,
        isLoading: isSendInvoiceLoading,
        isError,
    } = useSendEmailByInvoice(invoiceId, onClose);
    const [selectedContacts, setSelectedContacts] = useState<BillingEntityContactDto[]>(contacts);

    const onSubmit = () => {
        sendInvoice(selectedContacts.map(c => c.id));
    };

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader title='Send invoice email' />
            <ModalContent column gap={8}>
                {emailSent && (
                    <Alert
                        type='informative'
                        title='An email for this invoice has already been sent.'
                        description='Are you sure you want to send the invoice again?'
                    />
                )}
                <Labeled
                    label='Send to:'
                    content={
                        <CheckboxGroup<BillingEntityContactDto>
                            options={contacts}
                            toKey={c => c.id}
                            toLabel={c => c.email}
                            onChange={contacts => setSelectedContacts(contacts)}
                            value={selectedContacts}
                        />
                    }
                />
            </ModalContent>
            <ModalFooter>
                <CancelButton onClick={onClose} />
                <AsyncButton
                    label='Send invoice'
                    disableCheck
                    onClick={onSubmit}
                    isLoading={isSendInvoiceLoading}
                    isError={isError}
                    disabled={selectedContacts.length === 0}
                />
            </ModalFooter>
        </Modal>
    );
};
