import React, { FC } from 'react';

import {
    BackButton,
    FormDropdown,
    FormInput,
    FormRow,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { coreCurrencies, Currency, decimalToNumber } from '@hofy/global';
import { getTomorrowISODate } from '@hofy/helpers';
import { useTrCurrency } from '@hofy/i18n';
import { AsyncButton, FormContainer, FormDateInput, SvgIcon } from '@hofy/ui';

import { useCreateExchangeRates } from '../../../store/exchangeRates/useCreateExchangeRate';

interface ApiKeysSlideoutProps {
    onClose(): void;
}

const getRate = (rate: string) => (1 / decimalToNumber(rate || '1')).toFixed(6);

export const CreateExchangeRatesSlideout: FC<ApiKeysSlideoutProps> = ({ onClose }) => {
    const { form, isLoading } = useCreateExchangeRates(onClose);
    const tr = useTrCurrency();

    const handleOnRateChange = (rate: string) => {
        form.setValues({
            backRate: getRate(rate),
            rate,
        });
    };

    const handleOnBackRateChange = (backRate: string) => {
        form.setValues({
            backRate,
            rate: getRate(backRate),
        });
    };
    const handleOnCurrencyChange = ({
        fromCurrency,
        toCurrency,
    }: {
        fromCurrency: Currency;
        toCurrency: Currency;
    }) => {
        form.setValues({
            fromCurrency,
            toCurrency,
        });
    };

    const { fromCurrency, toCurrency } = form.values;
    return (
        <Slideout width={800} onClose={onClose}>
            <SlideoutHeader title='Create exchange rate' />
            <SlideoutContent>
                <FormContainer marginTop={40} marginBottom={40}>
                    <FormDateInput
                        label='Apply on date'
                        api={form.fields.applyOn}
                        minDate={getTomorrowISODate()}
                    />
                    <FormRow>
                        <FormDropdown
                            items={formatCurrencyCombinations()}
                            labelFormatter={o => {
                                if (o.fromCurrency && o.toCurrency) {
                                    return `${tr(o.fromCurrency)} - ${tr(o.toCurrency)}`;
                                }
                                return null;
                            }}
                            onChange={handleOnCurrencyChange}
                            label='Currency'
                            value={{
                                fromCurrency,
                                toCurrency,
                            }}
                            isError={form.errors.fromCurrency || form.errors.toCurrency}
                        />
                    </FormRow>
                    <FormRow>
                        <FormInput
                            label='Rate'
                            onChangeText={handleOnRateChange}
                            value={form.values.rate}
                            isError={form.errors.rate}
                            nullable
                        />
                        <FormInput
                            label='Back rate'
                            onChangeText={handleOnBackRateChange}
                            value={form.values.backRate}
                            isError={form.errors.backRate}
                            nullable
                        />
                    </FormRow>
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton isLoading={isLoading} label='Create' onClick={form.submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};

const formatCurrencyCombinations = () => {
    return coreCurrencies.flatMap((fromCurrency, i) =>
        coreCurrencies.slice(i + 1).map(toCurrency => ({
            fromCurrency,
            toCurrency,
        })),
    );
};
