import React, { FC, PropsWithChildren } from 'react';
import { Redirect } from 'react-router-dom';

import { useAuth } from '@hofy/api-admin';
import { AuthNavLink, ForbiddenPage, isAuthLink, useRedirectLocation } from '@hofy/auth';

import { SignInPage } from './SignInPage';

export const AuthGate: FC<PropsWithChildren> = ({ children }) => {
    const { redirectLocation, pathname } = useRedirectLocation();
    const auth = useAuth();

    if (!isAuthLink(pathname) && auth.authError) {
        return <ForbiddenPage reason={auth.authError} onSignOut={auth.signOut} />;
    }

    const isSignedOutInApp = !auth.isAuthenticated && !isAuthLink(pathname);

    if (isSignedOutInApp) {
        return (
            <Redirect
                to={{
                    pathname: AuthNavLink.SignIn,
                    state: { redirect: redirectLocation },
                }}
            />
        );
    }

    if (!auth.isAuthenticated) {
        return <SignInPage />;
    }

    return <>{children}</>;
};
