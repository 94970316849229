import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AssignInspectionAdminPayload, inspectionService, inspectionsQueryKey } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

export const useUpdateInspectionAssignee = (uuid: UUID, onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const mutation = useMutation<void, unknown, AssignInspectionAdminPayload>({
        mutationFn: payload => inspectionService.assignInspectionAdmin(uuid, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [inspectionsQueryKey] });
            onSuccess?.();
        },
    });

    return {
        assignInspection: (assignedToAdminId: UUID | null) => mutation.mutate({ assignedToAdminId }),
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
