import { useFulfilmentCountrySettingsStoreAndReuseEnabledQuery } from '@hofy/api-admin';
import { Country } from '@hofy/global';

export const useStoreAndReuseCountryQuery = () => {
    const { data: countrySettings, isLoading } = useFulfilmentCountrySettingsStoreAndReuseEnabledQuery();

    const zone1Countries = countrySettings.filter(c => c.isZone1).map(c => c.country);
    const localCollectionCountries = countrySettings
        .filter(c => c.isCollectToLocalEnabled)
        .map(c => c.country);
    const hubCollectionCountries = countrySettings.filter(c => c.isCollectToHubEnabled).map(c => c.country);

    const isStoreAndReuseCollectionToHubEnabled = (country: Country) =>
        hubCollectionCountries.includes(country);

    const isStoreAndReuseCollectionToLocalPartnerEnabled = (country: Country) =>
        localCollectionCountries.includes(country);

    const isStoreAndReuseCollectionEnabled = (country: Country) =>
        isStoreAndReuseCollectionToHubEnabled(country) ||
        isStoreAndReuseCollectionToLocalPartnerEnabled(country);

    const isCountryInZone1 = (country: Country) => zone1Countries.includes(country);

    const isStoreAndReuseDeliveryEnabled = (country: Country) => {
        return isCountryInZone1(country) || isStoreAndReuseCollectionToLocalPartnerEnabled(country);
    };

    return {
        isStoreAndReuseCollectionEnabled,
        isStoreAndReuseCollectionToHubEnabled,
        isStoreAndReuseCollectionToLocalPartnerEnabled,
        isStoreAndReuseDeliveryEnabled,
        isLoading,
    };
};
