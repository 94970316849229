import { ColorDye } from '@hofy/global';

import { Color } from './Color';

interface ColorDyeDef {
    main: Color;
    surface: Color;
    surfaceBorder: Color;
    muted: Color;
    onMuted: Color;
    subtle: Color;
}

export const colorDyes = {
    grey: {
        main: Color.NonContextualGreyDefault,
        surface: Color.NonContextualGreySurface,
        surfaceBorder: Color.NonContextualGreySurfaceBorder,
        muted: Color.NonContextualGreyMuted,
        onMuted: Color.NonContextualGreyOnMuted,
        subtle: Color.NonContextualGreySubtle,
    },
    aquamarine: {
        main: Color.NonContextualAquamarineDefault,
        surface: Color.NonContextualAquamarineSurface,
        surfaceBorder: Color.NonContextualAquamarineSurfaceBorder,
        muted: Color.NonContextualAquamarineMuted,
        onMuted: Color.NonContextualAquamarineOnMuted,
        subtle: Color.NonContextualAquamarineSubtle,
    },
    blue: {
        main: Color.NonContextualBlueDefault,
        surface: Color.NonContextualBlueSurface,
        surfaceBorder: Color.NonContextualBlueSurfaceBorder,
        muted: Color.NonContextualBlueMuted,
        onMuted: Color.NonContextualBlueOnMuted,
        subtle: Color.NonContextualBlueSubtle,
    },
    cyan: {
        main: Color.NonContextualCyanDefault,
        surface: Color.NonContextualCyanSurface,
        surfaceBorder: Color.NonContextualCyanSurfaceBorder,
        muted: Color.NonContextualCyanMuted,
        onMuted: Color.NonContextualCyanOnMuted,
        subtle: Color.NonContextualCyanSubtle,
    },
    emerald: {
        main: Color.NonContextualEmeraldDefault,
        surface: Color.NonContextualEmeraldSurface,
        surfaceBorder: Color.NonContextualEmeraldSurfaceBorder,
        muted: Color.NonContextualEmeraldMuted,
        onMuted: Color.NonContextualEmeraldOnMuted,
        subtle: Color.NonContextualEmeraldSubtle,
    },
    grape: {
        main: Color.NonContextualGrapeDefault,
        surface: Color.NonContextualGrapeSurface,
        surfaceBorder: Color.NonContextualGrapeSurfaceBorder,
        muted: Color.NonContextualGrapeMuted,
        onMuted: Color.NonContextualGrapeOnMuted,
        subtle: Color.NonContextualGrapeSubtle,
    },
    green: {
        main: Color.NonContextualGreenDefault,
        surface: Color.NonContextualGreenSurface,
        surfaceBorder: Color.NonContextualGreenSurfaceBorder,
        muted: Color.NonContextualGreenMuted,
        onMuted: Color.NonContextualGreenOnMuted,
        subtle: Color.NonContextualGreenSubtle,
    },
    magenta: {
        main: Color.NonContextualMagentaDefault,
        surface: Color.NonContextualMagentaSurface,
        surfaceBorder: Color.NonContextualMagentaSurfaceBorder,
        muted: Color.NonContextualMagentaMuted,
        onMuted: Color.NonContextualMagentaOnMuted,
        subtle: Color.NonContextualMagentaSubtle,
    },
    orange: {
        main: Color.NonContextualOrangeDefault,
        surface: Color.NonContextualOrangeSurface,
        surfaceBorder: Color.NonContextualOrangeSurfaceBorder,
        muted: Color.NonContextualOrangeMuted,
        onMuted: Color.NonContextualOrangeOnMuted,
        subtle: Color.NonContextualOrangeSubtle,
    },
    purple: {
        main: Color.NonContextualPurpleDefault,
        surface: Color.NonContextualPurpleSurface,
        surfaceBorder: Color.NonContextualPurpleSurfaceBorder,
        muted: Color.NonContextualPurpleMuted,
        onMuted: Color.NonContextualPurpleOnMuted,
        subtle: Color.NonContextualPurpleSubtle,
    },
    raspberry: {
        main: Color.NonContextualRaspberryDefault,
        surface: Color.NonContextualRaspberrySurface,
        surfaceBorder: Color.NonContextualRaspberrySurfaceBorder,
        muted: Color.NonContextualRaspberryMuted,
        onMuted: Color.NonContextualRaspberryOnMuted,
        subtle: Color.NonContextualRaspberrySubtle,
    },
    red: {
        main: Color.NonContextualRedDefault,
        surface: Color.NonContextualRedSurface,
        surfaceBorder: Color.NonContextualRedSurfaceBorder,
        muted: Color.NonContextualRedMuted,
        onMuted: Color.NonContextualRedOnMuted,
        subtle: Color.NonContextualRedSubtle,
    },
    teal: {
        main: Color.NonContextualTealDefault,
        surface: Color.NonContextualTealSurface,
        surfaceBorder: Color.NonContextualTealSurfaceBorder,
        muted: Color.NonContextualTealMuted,
        onMuted: Color.NonContextualTealOnMuted,
        subtle: Color.NonContextualTealSubtle,
    },
    violet: {
        main: Color.NonContextualVioletDefault,
        surface: Color.NonContextualVioletSurface,
        surfaceBorder: Color.NonContextualVioletSurfaceBorder,
        muted: Color.NonContextualVioletMuted,
        onMuted: Color.NonContextualVioletOnMuted,
        subtle: Color.NonContextualVioletSubtle,
    },
    yellow: {
        main: Color.NonContextualYellowDefault,
        surface: Color.NonContextualYellowSurface,
        surfaceBorder: Color.NonContextualYellowSurfaceBorder,
        muted: Color.NonContextualYellowMuted,
        onMuted: Color.NonContextualYellowOnMuted,
        subtle: Color.NonContextualYellowSubtle,
    },
} satisfies Record<ColorDye, ColorDyeDef>;

export const getColorFromColorDye = (color: ColorDye, type: keyof ColorDyeDef): Color =>
    colorDyes[color][type];

export const isColorDye = (color: string): color is ColorDye => color in colorDyes;
