import { getEnumValues } from '@hofy/global';

export enum CustomerOwnedStoreAndReuseLocation {
    LocalHub = 'local_hub',
    HofyHub = 'hofy_hub',
}

export const customerOwnedAllStoreAndReuseLocations = getEnumValues<CustomerOwnedStoreAndReuseLocation>(
    CustomerOwnedStoreAndReuseLocation,
);

export const customerOwnedStoreAndReuseLocationLabels = {
    [CustomerOwnedStoreAndReuseLocation.LocalHub]: 'Local partner',
    [CustomerOwnedStoreAndReuseLocation.HofyHub]: 'Hofy Hub',
};
