import React, { FC } from 'react';

import { getInspectionStatusColorDye, InspectionStatus, useTrInspectionStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface InspectionStatusChipProps {
    prefix?: string;
    status: InspectionStatus;
}

export const InspectionStatusChip: FC<InspectionStatusChipProps> = ({ prefix = '', status }) => {
    const trInspectionStatus = useTrInspectionStatus();

    return (
        <Badge label={`${prefix}${trInspectionStatus(status)}`} color={getInspectionStatusColorDye(status)} />
    );
};
