export const itemStatusI18n = {
    'item-status.active': 'Active',
    'item-status.being-repaired': 'Being repaired',
    'item-status.supplier-return': 'Supplier return',
    'item-status.stolen': 'Stolen',
    'item-status.lost-in-transit': 'Lost in transit',
    'item-status.waiting-for-clearance': 'Waiting for clearance',
    'item-status.cleared': 'Cleared',
    'item-status.binned': 'Binned',
    'item-status.left-with-user': 'Left with user',
    'item-status.shrinkage': 'Shrinkage',
    'item-status.damaged-by-user': 'Damaged with user',
    'item-status.damaged-in-transit': 'Damaged in transit',
    'item-status.damaged-at-warehouse': 'Damaged at warehouse',
    'item-status.broken-by-user': 'Broken by user',
    'item-status.broken-in-transit': 'Broken in transit',
    'item-status.broken-at-warehouse': 'Broken at warehouse',
    'item-status.added-by-mistake': 'Added by mistake',
    'item-status.returned-to-hofy': 'Returned to Hofy',
};
