import { DateTime } from 'luxon';
import React, { FC } from 'react';

import { IconItemWithTooltip } from '@hofy/common';
import { DateTimeString } from '@hofy/global';
import { diffDays, formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { SvgIcon } from '@hofy/ui';

interface CollectionRequestStatusProps {
    formFirstSentAt: DateTimeString | null;
    userSubmittedFormAt: DateTimeString | null;
    isUserUnresponsive: boolean;
}

export const CollectionRequestStatusCell: FC<CollectionRequestStatusProps> = ({
    formFirstSentAt,
    userSubmittedFormAt,
    isUserUnresponsive,
}) => {
    const tooltip = 'Collection survey';
    const icon = SvgIcon.Clipboard;

    if (userSubmittedFormAt) {
        return (
            <IconItemWithTooltip
                tooltip={formatDateTime(userSubmittedFormAt)}
                icon={icon}
                iconColor={Color.FoundationPositive}
            >
                Survey completed
            </IconItemWithTooltip>
        );
    }

    if (!formFirstSentAt) {
        return (
            <IconItemWithTooltip tooltip={tooltip} icon={icon} iconColor={Color.AccentRed}>
                Not sent yet
            </IconItemWithTooltip>
        );
    }

    if (isUserUnresponsive) {
        return (
            <IconItemWithTooltip tooltip={tooltip} icon={icon} iconColor={Color.FoundationNegative}>
                User unresponsive{getElapsedDaysLabelSinceTime(formFirstSentAt)}
            </IconItemWithTooltip>
        );
    }

    return (
        <IconItemWithTooltip tooltip={tooltip} icon={icon} iconColor={Color.AccentYellow}>
            Pending user response{getElapsedDaysLabelSinceTime(formFirstSentAt)}
        </IconItemWithTooltip>
    );
};

const getElapsedDaysLabelSinceTime = (time: string) => {
    const daysSinceFormWasSent = diffDays(DateTime.fromISO(time), DateTime.now());

    if (daysSinceFormWasSent < 1) {
        return ' (sent today)';
    }

    if (daysSinceFormWasSent < 2) {
        return ' (since yesterday)';
    }

    return ` (${daysSinceFormWasSent} days)`;
};
