import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noop } from 'lodash';

import { notesService } from '@hofy/api-admin';
import { NoteDto } from '@hofy/api-shared';

import { notesQueryKey } from './notesQueryKey';

export const useDeleteNote = (note: NoteDto, onSuccess = noop) => {
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: () => notesService.deleteNote(note.id),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [notesQueryKey] });
            onSuccess();
        },
    });

    return {
        deleteNote: () => mutation.mutate(),
        isSubmitting: mutation.isPending,
    };
};
