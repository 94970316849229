import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { usePermission } from '@hofy/permission';
import { Box, SearchInput } from '@hofy/ui';

import { useCountryMultipliers } from '../../../store/countryMultipliers/useCountryMultipliers';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { CountryMultipliersTable } from './CountryMultipliersTable';

interface CountryMultipliersTabProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const CountryMultipliersTab: FC<CountryMultipliersTabProps> = ({ tab, tabs, onChangeTab }) => {
    const { hasPermission } = usePermission();
    const canEdit = hasPermission(Permission.AdminCountryMultipliersUpdate);

    const { countryMultipliers, isLoading, search, setSearch } = useCountryMultipliers();

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tab={tab}
                tabs={tabs}
                onChangeTab={onChangeTab}
                titleContent={<SearchInput value={search} onChange={setSearch} />}
            />
            <CountryMultipliersTable
                countryMultipliers={countryMultipliers}
                canEdit={canEdit}
                isLoading={isLoading}
            />
        </Box>
    );
};
