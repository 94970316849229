import React, { FC, ReactNode } from 'react';

import { childrenProps } from '../../helpers';
import { Box, Paragraph4 } from '../base';
import { Tooltip } from '../tooltip';
import { FilterChip } from './FilterChip';

export interface FilterGroupProps {
    title: string;
    children?: ReactNode;
    limit?: number;
    onClearAfter?(index: number): void;
}

export const FilterGroup: FC<FilterGroupProps> = ({ title, children, limit = Infinity, onClearAfter }) => {
    const filters = childrenProps(children, FilterChip);
    const visibleFilters = filters.slice(0, limit);
    const hiddenFilters = filters.slice(limit);

    return (
        <Box
            inline
            row
            gap={8}
            paddingLeft={12}
            paddingRight={4}
            paddingVertical={4}
            border
            borderStyle='dashed'
            rounded={8}
        >
            <Paragraph4 borderRight paddingRight={8}>
                {title}
            </Paragraph4>
            {visibleFilters.map((filter, index) => (
                <FilterChip key={index} {...filter} />
            ))}
            {hiddenFilters.length > 0 && onClearAfter && (
                <Tooltip body={hiddenFilters.map(filter => filter.label).join('\n')} asChild>
                    <FilterChip
                        label={`+${hiddenFilters.length}`}
                        onClear={() => onClearAfter(visibleFilters.length)}
                    />
                </Tooltip>
            )}
        </Box>
    );
};
