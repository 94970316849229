import React, { FC } from 'react';

import { ProductPayload } from '@hofy/api-admin';
import {
    Box,
    Button,
    FormFieldRecord,
    FormInput,
    IconButton,
    SvgIcon,
    UseForm,
    useFormArrayField,
} from '@hofy/ui';

interface ProductSpecificationsProps {
    form: UseForm<ProductPayload>;
}

interface ProductSpecification {
    name: string;
    value: string;
}

export const ProductSpecifications: FC<ProductSpecificationsProps> = ({
    form,
}: ProductSpecificationsProps) => {
    const specifications = useFormArrayField(form.fields.specifications, { name: '', value: '' });

    return (
        <Box fullWidth>
            <Box gap={20} column>
                {specifications.fields.map((specification, index) => (
                    <ProductSpecificationEntry
                        key={specification.key}
                        index={index}
                        api={specification.api}
                        onRemove={() => specifications.remove(specification.key)}
                    />
                ))}
                <Button
                    type='secondary'
                    label='Add'
                    leftIcon={SvgIcon.Add}
                    onClick={() => specifications.add()}
                />
            </Box>
        </Box>
    );
};

interface ProductSpecificationEntryProps {
    onRemove(): void;
    api: FormFieldRecord<ProductSpecification>;
    index: number;
}

const ProductSpecificationEntry: FC<ProductSpecificationEntryProps> = ({
    api,
    onRemove,
    index,
}: ProductSpecificationEntryProps) => {
    return (
        <Box gap={20} row>
            <FormInput flex={1} label={index === 0 ? 'Name' : ''} api={api.name} isRequired />
            <FormInput flex={1} label={index === 0 ? 'Value' : ''} api={api.value} isRequired />
            <IconButton onClick={onRemove} icon={SvgIcon.Cross} marginTop={index === 0 ? 24 : 0} />
        </Box>
    );
};
