import { getEnumValues } from '@hofy/global';

export enum AssignmentShipmentIssue {
    LostInTransit = 'lost_in_transit',
    DamagedInTransit = 'damaged_in_transit',
    WrongItemSend = 'wrong_item_send',
    Other = 'other',
}

export const allShipmentIssues = getEnumValues<AssignmentShipmentIssue>(AssignmentShipmentIssue);
