import React, { FC } from 'react';

import { Badge, MarginBoxProps, SvgIcon } from '@hofy/ui';

interface PurchaseOrderTypeChipProps extends MarginBoxProps {
    isDropship: boolean;
}

export const PurchaseOrderTypeChip: FC<PurchaseOrderTypeChipProps> = ({ isDropship, ...rest }) => {
    const color = isDropship ? 'teal' : 'green';
    const icon = isDropship ? SvgIcon.Truck : SvgIcon.Box;
    const label = isDropship ? 'Dropship' : 'Warehouse';

    return <Badge color={color} icon={icon} label={label} {...rest} />;
};
