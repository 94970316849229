import React, { FC } from 'react';

import { RepaymentStatus, repaymentStatusColors, useTrRepaymentStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface RepaymentStatusChipProps {
    status?: RepaymentStatus;
}

export const RepaymentStatusBadge: FC<RepaymentStatusChipProps> = ({ status }) => {
    const trStatus = useTrRepaymentStatus();

    if (!status) {
        return null;
    }

    const color = repaymentStatusColors[status];
    return <Badge color={color} label={trStatus(status)} />;
};
