import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';

import { useFulfilmentCountrySettingsQuery } from '@hofy/api-admin';
import { Country } from '@hofy/global';
import { useGoBack } from '@hofy/hooks';
import { EnumRoute } from '@hofy/router';
import { ActiveFilters, ActiveFiltersLayout, Box, FilterSearchInput } from '@hofy/ui';

import { AdminNavLink } from '../../../components/routing/AdminNavLink';
import { useFulfilmentCountrySettingFilters } from '../../../store/fulfilmentCountrySettings/useFulfilmentCountrySettingFilters';
import { AdminSettingsTab } from '../../../store/settings/types/AdminSettingsTab';
import { SettingsHeader } from '../SettingsHeader';
import { FulfilmentCountrySettingTable } from './FulfilmentCountrySettingsTable';
import { UpdateFulfilmentCountrySettingSlideout } from './UpdateFulfilmentCountrySettingSlideout';

interface FulfilmentCountrySettingRouterProps {
    tab: AdminSettingsTab;
    tabs: AdminSettingsTab[];
    onChangeTab(tab: AdminSettingsTab): void;
}

export const FulfilmentCountrySettingsRouter: FC<FulfilmentCountrySettingRouterProps> = ({
    tab,
    tabs,
    onChangeTab,
}) => {
    const history = useHistory();
    const { goBack } = useGoBack();

    const { filters, filterValues, filterCount, clearFilters } = useFulfilmentCountrySettingFilters();
    const {
        data: settings,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    } = useFulfilmentCountrySettingsQuery(filterValues);

    const onUpdateClick = (country: Country) => {
        history.push(
            `${AdminNavLink.Settings}/${AdminSettingsTab.FulfilmentCountrySettings}/${country}/update${history.location.search}`,
        );
    };

    return (
        <Box column flex='auto'>
            <SettingsHeader
                tab={tab}
                tabs={tabs}
                onChangeTab={onChangeTab}
                titleContent={<FilterSearchInput filter={filters.search} autoFocus />}
            />

            <ActiveFiltersLayout show={filterCount > 0}>
                <ActiveFilters filters={filters} onClearAll={clearFilters} />
            </ActiveFiltersLayout>

            <FulfilmentCountrySettingTable
                settings={settings}
                filters={filters}
                onUpdateClick={onUpdateClick}
                infinityScroll={{
                    hasMore: hasNextPage,
                    isLoading: isLoading,
                    isLoadingMore: isFetchingNextPage,
                    loadMore: fetchNextPage,
                }}
            />

            <UpdateFulfilmentCountrySettingRoute
                path={`${AdminNavLink.Settings}/${AdminSettingsTab.FulfilmentCountrySettings}/:country/update`}
            >
                {({ country }) => {
                    const setting = settings.find(setting => setting.country === country);
                    return (
                        setting && (
                            <UpdateFulfilmentCountrySettingSlideout setting={setting} onClose={goBack} />
                        )
                    );
                }}
            </UpdateFulfilmentCountrySettingRoute>
        </Box>
    );
};

const UpdateFulfilmentCountrySettingRoute = EnumRoute('country', Country, Route);
