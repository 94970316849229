import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { SvgIcon, TooltipIcon } from '@hofy/ui';

import { Indicator } from '../../../design/indicator/Indicator';

interface CollectionMissingItemIndicatorProps {
    reason: string | null;
}

export const CollectionMissingItemIndicator: FC<CollectionMissingItemIndicatorProps> = ({ reason }) => {
    const color = Color.ContentNegative;
    const chipBody = reason ? `Missing item: ${reason}` : 'Missing item';
    return (
        <Indicator color={color}>
            <TooltipIcon icon={SvgIcon.Cross} iconColor={color} body={chipBody} iconSize={16} />
        </Indicator>
    );
};
