import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { ColorDye } from '@hofy/global';
import { Color, colorDyes } from '@hofy/theme';

import { Box, Paragraph4, Pressable } from '../base';
import { Icon, SvgIcon } from '../icon';

export interface FilterChipProps {
    label: string;
    /** @deprecated don't use colors, use `FilterGroup` instead */
    color?: ColorDye;
    onClear?(): void;
}

export const FilterChip = forwardRef<HTMLDivElement, FilterChipProps>(
    ({ label, color = 'grey', onClear }, ref) => {
        const { subtle, onMuted } = colorDyes[color];

        const Wrapper = onClear ? HoverPressable : Box;

        return (
            <Wrapper
                ref={ref}
                onClick={onClear}
                row
                gap={6}
                bg={subtle}
                rounded={8}
                paddingLeft={12}
                paddingRight={onClear ? 2 : 12}
                paddingVertical={2}
                inline
            >
                <Paragraph4 color={onMuted} bold>
                    {label}
                </Paragraph4>
                {onClear && (
                    <CloseBox color={Color.ContentTertiary} rect={18} centered>
                        <Icon svg={SvgIcon.Cross} size={14} color='currentColor' />
                    </CloseBox>
                )}
            </Wrapper>
        );
    },
);

const CloseBox = styled(Box)`
    border-radius: 6px;
`;

const HoverPressable = styled(Pressable)`
    &:hover {
        background-color: ${Color.NonContextualVioletSubtle};
        ${Paragraph4} {
            color: ${Color.NonContextualVioletOnMuted};
        }
        ${CloseBox} {
            color: ${Color.InteractionDefaultNormal};
            background-color: ${Color.NonContextualVioletMuted};
        }
    }
`;
