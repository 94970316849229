import { restClient } from '@hofy/rest';

import { SeedDemoOrganizationPayload } from './types/SeedDemoOrganizationPayload';
import { SeedLocalOrganizationPayload } from './types/SeedLocalOrganizationPayload';
import { SeedRandomHofyAssetsPayload } from './types/SeedRandomHofyAssetsPayload';
import { SeedRandomNonHofyAssetsPayload } from './types/SeedRandomNonHofyAssetsPayload';
import { SeedRandomOrdersPayload } from './types/SeedRandomRequestsPayload';
import { SeedRandomUsersPayload } from './types/SeedRandomUsersPayload';
import { SeedWarehouseItemsPayload } from './types/SeedWarehouseItemsPayload';

class DataSeedService {
    public seedRandomUsers = async (payload: SeedRandomUsersPayload): Promise<void> => {
        return restClient.post(`/api/seed/users`, payload);
    };
    public seedRandomOrders = async (payload: SeedRandomOrdersPayload): Promise<void> => {
        return restClient.post(`/api/seed/orders`, payload);
    };
    public seedWarehouseItems = async (payload: SeedWarehouseItemsPayload): Promise<void> => {
        return restClient.post(`/api/seed/items`, payload);
    };
    public seedLocalOrganization = async (payload: SeedLocalOrganizationPayload): Promise<void> => {
        return restClient.post(`/api/seed/organization`, payload);
    };
    public seedDemoOrganization = async (payload: SeedDemoOrganizationPayload): Promise<void> => {
        return restClient.post(`/api/seed/demo-organization`, payload);
    };
    public seedRandomHofyAssets = async (payload: SeedRandomHofyAssetsPayload): Promise<void> => {
        return restClient.post(`/api/seed/hofy-assets`, payload);
    };
    public seedRandomNonHofyAssets = async (payload: SeedRandomNonHofyAssetsPayload): Promise<void> => {
        return restClient.post(`/api/seed/non-hofy-assets`, payload);
    };
}

export const dataSeedService = new DataSeedService();
