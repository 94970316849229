import { keyBy, uniq } from 'lodash';
import React, { FC } from 'react';

import {
    BackButton,
    Checkbox,
    Slideout,
    SlideoutContent,
    SlideoutFooter,
    SlideoutHeader,
} from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton, Box, FilterChip, FormContainer, Paragraph3, SvgIcon } from '@hofy/ui';

import { LabeledOrganizationSelect } from '../../../components/domain/organizations/LabeledOrganizationSelect';
import { useUpdateBetaFeature } from '../../../store/betaFeature/useUpdateBetaFeature';
import { useOrganizationsRefsQuery } from '../../../store/organizations/useOrganizationsQuery';

interface BetaFeaturesSlideoutProps {
    featureId: UUID;
    onClose(): void;
}

export const UpdateBetaFeaturesSlideout: FC<BetaFeaturesSlideoutProps> = ({ onClose, featureId }) => {
    const {
        form: { submit, values, setValues },
        isLoadingMutation,
    } = useUpdateBetaFeature(featureId, onClose);

    const onOrganizationChange = (item: UUID) => {
        setValues({
            organizationIds: uniq([...values.organizationIds, item]),
        });
    };
    const onRemoveOrganisation = (id: UUID) => {
        const organizationIds = values.organizationIds.filter(item => item !== id);
        setValues({
            organizationIds,
        });
    };
    const { data, isLoading } = useOrganizationsRefsQuery(values.organizationIds, '');
    const nameMap = keyBy(data, v => v.id);

    return (
        <Slideout width={600} onClose={onClose}>
            <SlideoutHeader title='Edit beta feature' />
            <SlideoutContent>
                <FormContainer marginVertical={40}>
                    <Checkbox
                        checked={values.active}
                        onChange={active =>
                            setValues({
                                active,
                            })
                        }
                    >
                        <Paragraph3>Feature is active</Paragraph3>
                    </Checkbox>
                    <Checkbox
                        checked={values.globallyEnabled}
                        disabled={!values.active}
                        onChange={globallyEnabled =>
                            setValues({
                                globallyEnabled,
                            })
                        }
                    >
                        <Paragraph3>Enable feature for all organizations</Paragraph3>
                    </Checkbox>
                    <LabeledOrganizationSelect
                        label='Specify Organization'
                        placeholder='Select organization'
                        disabled={!values.active || values.globallyEnabled || isLoading}
                        onChange={onOrganizationChange}
                        nullable
                        value={null}
                    />
                    <Box rowGap={5} columnGap={5} wrap>
                        {!isLoading &&
                            values.organizationIds.map(org => (
                                <FilterChip
                                    key={org}
                                    label={nameMap[org]?.name}
                                    color='purple'
                                    onClear={() => onRemoveOrganisation(org)}
                                />
                            ))}
                    </Box>
                </FormContainer>
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton isLoading={isLoadingMutation} label='Save' onClick={submit} disableCheck />
            </SlideoutFooter>
        </Slideout>
    );
};
