import React, { FC } from 'react';

import { BYODOrderItemDto, useBYODOrderQuery } from '@hofy/api-admin';
import { ComponentLoader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, ErrorStatePlaceholder, FormSection } from '@hofy/ui';

import { BYODOrderDetails } from './BYODOrderDetails';
import { BYODOrderItemBox } from './BYODOrderItemBox';

interface BYODOrderItemsScanSelectProps {
    byodOrderId: UUID;
    setByodOrderItem(byodOrderItem: BYODOrderItemDto): void;
}

export const BYODOrderItemsScanSelect: FC<BYODOrderItemsScanSelectProps> = ({
    byodOrderId,
    setByodOrderItem,
}) => {
    const { data: byodOrder, isLoading, isError } = useBYODOrderQuery(byodOrderId);

    if (isLoading) {
        return <ComponentLoader />;
    }

    if (isError || !byodOrder) {
        return <ErrorStatePlaceholder />;
    }
    const byodItemRows = byodOrder.items
        .sort((a, b) => b.receivedQuantity - a.receivedQuantity)
        .map(item => <BYODOrderItemBox key={item.id} item={item} setByodOrderItem={setByodOrderItem} />);

    return (
        <Box marginVertical={16}>
            <FormSection label='Details' marginVertical={16}>
                <BYODOrderDetails byodOrder={byodOrder} />
            </FormSection>
            <FormSection label='Products'>{byodItemRows}</FormSection>
        </Box>
    );
};
