import { UUID } from '@hofy/global';
import { restClient } from '@hofy/rest';

import { ApiKeyDto } from './types/ApiKeyDto';
import { CreateApiKeyPayload } from './types/CreateApiKeyPayload';
import { UpdateApiKeyPayload } from './types/UpdateApiKeyPayload';

interface CreateApiKeyResponse {
    value: string;
}

class ApiKeyService {
    public getApiKeys = async (): Promise<ApiKeyDto[]> => {
        return restClient.getJson<ApiKeyDto[]>(`/api/admin/auth/api-keys`);
    };

    public createApiKey = async (payload: CreateApiKeyPayload): Promise<CreateApiKeyResponse> => {
        return restClient.postJson<CreateApiKeyResponse>(`/api/admin/auth/api-keys`, payload);
    };

    public revokeApiKey = async (id: UUID): Promise<void> => {
        return restClient.post(`/api/admin/auth/api-keys/${id}/revoke`);
    };

    public updateApiKey = async (id: UUID, payload: UpdateApiKeyPayload): Promise<void> => {
        return restClient.put(`/api/admin/auth/api-keys/${id}/update`, payload);
    };
}

export const apiKeyService = new ApiKeyService();
