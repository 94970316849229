import { motion } from 'framer-motion';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Box, OuterBoxProps } from '../base';
import { Stepper } from '../progress/Stepper';

export interface CarouselProps extends Omit<OuterBoxProps, 'height'> {
    children: ReactNode[];
    step: number;
    onChange(step: number): void;
    height: number;
    width: number;
    hideStepper?: boolean;
}

export const Carousel: FC<CarouselProps> = ({
    children,
    step,
    onChange,
    height,
    width,
    hideStepper,
    ...boxProps
}) => {
    return (
        <Box gap={16} column height={height} {...boxProps}>
            <Box row overflow='hidden' relative flex='auto'>
                <Slider animate={{ x: `-${step * 100}%` }} transition={{ duration: 0.4, ease: 'easeInOut' }}>
                    <Box row alignItems='stretch' fullHeight width={width * children.length}>
                        {children.map((itemNode, index) => (
                            <Box width={width} fullHeight key={index}>
                                {itemNode}
                            </Box>
                        ))}
                    </Box>
                </Slider>
            </Box>
            {!hideStepper && (
                <Box centered row>
                    <Stepper currentIndex={step} onChange={onChange} steps={children.length} />
                </Box>
            )}
        </Box>
    );
};

const Slider = styled(motion.div)`
    position: absolute;
    inset: 0;
`;
