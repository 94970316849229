import React, { forwardRef, ReactNode } from 'react';

import { Color } from '@hofy/theme';

import { Box, OuterBoxProps, Paragraph3, Pressable } from '../../base';
import { Chevron } from '../../shared';

interface DropdownTriggerLabelProps extends OuterBoxProps {
    label: ReactNode;
    isOpen: boolean;
    disabled: boolean;
}

export const DropdownTriggerLabel = forwardRef<any, DropdownTriggerLabelProps>(
    ({ label, isOpen, disabled, ...rest }, ref) => {
        const fontColor = disabled ? Color.ContentTertiary : Color.ContentPrimary;

        return (
            <Pressable ref={ref} row inactive={disabled} {...rest}>
                {typeof label === 'string' ? <Paragraph3 color={fontColor}>{label}</Paragraph3> : label}
                <Box marginLeft={8}>
                    <Chevron isOpen={isOpen} color={fontColor} />
                </Box>
            </Pressable>
        );
    },
);
