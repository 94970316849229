import { useQuery } from '@tanstack/react-query';

import { itemsService } from '@hofy/api-admin';
import { UUID } from '@hofy/global';

const itemAllowedStatusesQuery = 'admin/allowed-statuses';

export const useItemAllowedStatusesQuery = (itemId: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [itemAllowedStatusesQuery, itemId],
        queryFn: () => itemsService.getItemAllowedStatuses(itemId),
    });

    return {
        statuses: data || [],
        isLoading,
    };
};
