import { useDisableContext } from './DisableContext';

/**
 * This hook is for every component that needs to know if it is disabled.
 * The rule here is that the component `disabled` prop will override the `DisableContext` value.
 * So you enable singe component inside a `disabled=true` wrapper if you want to.
 */

export const useIsDisabled = (componentDisabled?: boolean): boolean => {
    const { disabled } = useDisableContext();
    return componentDisabled || disabled;
};
