import React, { FC } from 'react';

import { InvoiceStatus, invoiceStatusColors, useTrInvoiceStatus } from '@hofy/api-shared';
import { Badge, BadgeProps } from '@hofy/ui';

interface InvoiceStatusChipProps extends BadgeProps {
    status: InvoiceStatus;
    isCreditNote: boolean;
}

export const InvoiceStatusChip: FC<InvoiceStatusChipProps> = ({ status, isCreditNote, ...rest }) => {
    const trStatus = useTrInvoiceStatus(isCreditNote);
    const color = invoiceStatusColors[status];
    return <Badge color={color} label={trStatus(status)} {...rest} />;
};
