import { xor } from 'lodash';
import React, { ReactNode, useState } from 'react';
import { useBoolean } from 'react-use';

import { useSearch } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Icon, IconButton, SearchInput, SectionTitle3, SvgIcon } from '@hofy/ui';

import { BlockFilter } from './BlockFilter';
import { BlockFilterItem } from './BlockFilterItem';

interface SearchableEnumMultiBlockFilterProps<T> {
    title: string;
    icon?: Svg;
    selected: T[];
    onChange(type?: T[]): void;
    items: T[];
    renderItem(type: T): ReactNode;
    searchPlaceholder: string;
    toText(type: T): string;
}

export const SearchableEnumMultiBlockFilter = <T,>({
    title,
    icon = SvgIcon.Filter,
    selected,
    onChange,
    items,
    renderItem,
    searchPlaceholder,
    toText,
}: SearchableEnumMultiBlockFilterProps<T>) => {
    const [searchQuery, setSearchQuery] = useState('');
    const results = useSearch(items, toText, searchQuery);
    const [showSearch, toggleShowSearch] = useBoolean(false);
    const renderTitle = () => {
        if (showSearch || !!searchQuery) {
            return (
                <Box marginHorizontal={-12}>
                    <SearchInput
                        value={searchQuery}
                        onChange={setSearchQuery}
                        onFocus={() => toggleShowSearch(true)}
                        onBlur={() => toggleShowSearch(false)}
                        placeholder={searchPlaceholder}
                        delay={100}
                        fullWidth
                        autoFocus
                    />
                </Box>
            );
        }
        return (
            <>
                <Icon svg={icon} size={16} marginRight={10} />
                <SectionTitle3 flex={1}>{title}</SectionTitle3>
                <IconButton
                    icon={SvgIcon.Search}
                    onClick={() => toggleShowSearch()}
                    color={Color.ContentSecondary}
                />
            </>
        );
    };
    return (
        <BlockFilter title={renderTitle()} icon={icon}>
            {results.map((item, index) => (
                <BlockFilterItem
                    key={`${item}-${index}`}
                    name={renderItem(item)}
                    selected={selected.includes(item)}
                    onClick={() => onChange(xor(selected, [item]))}
                />
            ))}
        </BlockFilter>
    );
};
