import React, { FC } from 'react';

import { PurchaseOrderItemDto, usePurchaseOrderQuery } from '@hofy/api-admin';
import { CenteredBox, ComponentLoader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Box, Placeholder, SvgIllustration } from '@hofy/ui';

import { PurchaseOrderDetails } from './PurchaseOrderDetails';
import { PurchaseOrderItemBox } from './PurchaseOrderItemBox';

interface ItemsScanSelectProps {
    purchaseOrderId: UUID;
    onItemToScanSelect(itemToScan: PurchaseOrderItemDto): void;
}

export const ItemsScanSelect: FC<ItemsScanSelectProps> = ({ purchaseOrderId, onItemToScanSelect }) => {
    const { data: purchaseOrder, isLoading: purchaseOrderIsLoading } = usePurchaseOrderQuery(purchaseOrderId);

    if (purchaseOrderIsLoading) {
        return <ComponentLoader />;
    }

    if (!purchaseOrder) {
        return (
            <CenteredBox>
                <Placeholder
                    illustration={SvgIllustration.Error}
                    title='Failed to fetch PO items'
                    message={`We could not find the items for PO #${purchaseOrderId}`}
                />
            </CenteredBox>
        );
    }

    return (
        <Box marginVertical={8}>
            <PurchaseOrderDetails purchaseOrder={purchaseOrder} />
            {[...purchaseOrder.purchaseOrderItems]
                .sort((a, b) => b.receivedQuantity - a.receivedQuantity)
                .map(item => (
                    <PurchaseOrderItemBox key={item.id} item={item} onItemToScanSelect={onItemToScanSelect} />
                ))}
        </Box>
    );
};
