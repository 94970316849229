export enum BetaFeatureName {
    ItemRefresh = 'item_refresh',
    Slack = 'slack',
    AssetProperties = 'asset_properties',
    ZendeskChat = 'zendesk_chat',
    OrganizationSignup = 'organization_signup',
    PublicApi = 'public_api',
    FleetServices = 'fleet_services',
    AutomaticShipmentTracking = 'automatic_shipment_tracking',
    AutomaticShipmentStatusUpdate = 'automatic_shipment_status_update',
    AssetAnomalies = 'asset_anomalies',
}
