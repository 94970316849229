import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { ShipmentDto } from '@hofy/api-admin';
import {
    getShipmentFromLocation,
    getShipmentToLocation,
    isShipmentFromUserToUser,
    Permission,
    ShipmentClass,
    useTrShipmentStatus,
    useTrShipmentTrackingProvider,
} from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { formatDate, formatDateRange } from '@hofy/helpers';
import { usePermission } from '@hofy/permission';
import { Color } from '@hofy/theme';
import {
    BaseTableColumnConfig,
    Box,
    Checkbox,
    Dot,
    Icon,
    InfiniteScrollConfig,
    InfinityScrollTable,
    Paragraph3,
    Paragraph4,
    Placeholder,
    SvgIcon,
    SvgIllustration,
    Tooltip,
} from '@hofy/ui';

import { ShipmentAdminSelector } from '../../../../components/domain/shipments/ShipmentAdminSelector';
import { ShipmentStatusChip } from '../../../../components/domain/shipments/ShipmentStatusChip';
import { ShipmentTypeChip } from '../../../../components/domain/shipments/ShipmentTypeChip';
import { useAdminI18n } from '../../../../i18n/useAdminI18n';
import { ShipmentTab } from '../../../../store/shipments/types/ShipmentTab';
import { ShipmentActionButtons } from '../../../shipmentSlideout/components/ShipmentStatusPane';
import { ShipmentAction } from '../../../shipmentSlideout/ShipmentAction';
import { ShipmentCheckBox } from './ShipmentCheckBox';
import { ShipmentLocationCell } from './ShipmentLocationCell';
import { ShipmentTrackingLink } from './ShipmentTrackingLink';
import { providersIcons, TrackingStatus } from './ShipmentTrackingStatus';

interface ShipmentTableProps {
    tab: ShipmentTab;
    shipments: ShipmentDto[];
    onSelect(id: UUID): void;
    onOpenShipment(id: UUID): void;
    onOpenShipmentTrackingHistory(id: UUID): void;
    infinityScroll: InfiniteScrollConfig;
    shipmentSelectedIds: UUID[];
    setShipmentSelectedIds(id: UUID[]): void;
}

export const ShipmentTable: FC<ShipmentTableProps> = ({
    tab,
    shipments,
    onSelect,
    onOpenShipment,
    onOpenShipmentTrackingHistory,
    infinityScroll,
    shipmentSelectedIds,
    setShipmentSelectedIds,
}) => {
    const { hasPermission } = usePermission();
    const canChangeShipment = hasPermission(Permission.AdminShipmentsUpdate);
    const { trPlural } = useAdminI18n();
    const trShipmentStatus = useTrShipmentStatus();
    const trShipmentTrackerProvider = useTrShipmentTrackingProvider();
    const allShipmentsSelected = shipmentSelectedIds.length === shipments.length;
    const shipmentsPartialSelected = shipmentSelectedIds.length > 0 && !allShipmentsSelected;

    const handleHeaderSelection = () => {
        if (allShipmentsSelected) {
            setShipmentSelectedIds([]);
        } else {
            setShipmentSelectedIds(shipments.map(shipment => shipment.id));
        }
    };
    const handleAction = useHandleShipmentAction(tab);
    type FilterableColumn = BaseTableColumnConfig<ShipmentDto> & {
        disabled?: boolean;
    };
    const columns: FilterableColumn[] = [
        {
            id: 'select',
            header: (
                <Checkbox
                    checked={shipmentsPartialSelected ? 'mixed' : !!shipmentSelectedIds.length}
                    onChange={handleHeaderSelection}
                >
                    &nbsp;
                </Checkbox>
            ),
            width: 40,
            flexGrow: 0,
            renderer: shipment => (
                <ShipmentCheckBox
                    onSelect={() => onSelect(shipment.id)}
                    isSelected={shipmentSelectedIds.includes(shipment.id)}
                />
            ),
        },
        {
            id: 'shipment',
            header: 'Id',
            flexGrow: 1,
            renderer: shipment => (
                <Box onClick={() => onOpenShipment(shipment.id)} pointer>
                    <Paragraph3>{shipment.publicId}</Paragraph3>
                </Box>
            ),
        },
        {
            id: 'from',
            header: 'From',
            flexGrow: 4,
            renderer: s => <ShipmentLocationCell location={getShipmentFromLocation(s)} />,
        },
        {
            id: 'to',
            header: 'To',
            flexGrow: 4,
            renderer: s => <ShipmentLocationCell location={getShipmentToLocation(s)} />,
        },
        {
            id: 'status_for_tracking',
            header: 'Status',
            disabled: tab !== ShipmentTab.RealTimeTracking,
            flexGrow: 1,
            renderer: shipment => (
                <Box column gap={4}>
                    <ShipmentStatusChip status={shipment.status} />
                    {shipment.shipmentClass === ShipmentClass.Standard ? (
                        <Box row gap={4}>
                            <Icon svg={SvgIcon.Zap} color={Color.NonContextualBorderWarning} />
                            <Paragraph4 color={Color.ContentTertiary} italic>
                                Express
                            </Paragraph4>
                        </Box>
                    ) : (
                        <Box row gap={4}>
                            <Dot size={6} color={Color.ContentTertiary} />
                            <Paragraph4 color={Color.ContentTertiary} italic>
                                Standard
                            </Paragraph4>
                        </Box>
                    )}
                </Box>
            ),
        },
        {
            id: 'status',
            header: 'Status',
            disabled: tab === ShipmentTab.RealTimeTracking,
            flexGrow: 1,
            renderer: shipment => <ShipmentStatusChip status={shipment.status} />,
        },
        {
            id: 'type',
            header: 'Type',
            flexGrow: 1,
            disabled: tab === ShipmentTab.RealTimeTracking,
            renderer: shipment => <ShipmentTypeChip type={shipment.adminType} />,
        },
        {
            id: 'type_for_tracking',
            header: 'Type',
            flexGrow: 1,
            disabled: tab !== ShipmentTab.RealTimeTracking,
            renderer: shipment => (
                <Box column gap={4}>
                    <ShipmentTypeChip type={shipment.adminType} />
                    {(shipment.latestDeliveryOn || shipment.estimateOn) && (
                        <Paragraph4 color={Color.ContentTertiary} italic>
                            {shipment.latestDeliveryOn
                                ? `OTD: ${formatDate(shipment.latestDeliveryOn)}`
                                : `ETA: ${formatDateRange(shipment.estimateOn)} `}
                        </Paragraph4>
                    )}
                </Box>
            ),
        },
        {
            id: 'trackingLink',
            header: 'Tracking #',
            flexGrow: 1,
            disabled: tab === ShipmentTab.RealTimeTracking,
            renderer: shipment => (
                <ShipmentTrackingLink link={shipment.trackingLink} trackingId={shipment.trackingId} />
            ),
        },
        {
            id: 'TrackingInSync',
            header: 'Tracking',
            width: 150,
            disabled: tab !== ShipmentTab.RealTimeTracking,
            renderer: shipment => {
                if (!shipment.tracking) {
                    return null;
                }

                const linkToProcessShipment = () => handleAction(shipment.id, ShipmentAction.Book);
                const linkToBackorderShipment = () => handleAction(shipment.id, ShipmentAction.Backorder);
                const linkToShipShipment = () => handleAction(shipment.id, ShipmentAction.Ship);
                const linkToCompleteShipment = () => handleAction(shipment.id, ShipmentAction.Complete);

                return (
                    <Box column gap={4}>
                        <ShipmentActionButtons
                            status={shipment.status}
                            onBook={linkToProcessShipment}
                            onShip={linkToShipShipment}
                            onComplete={linkToCompleteShipment}
                            onBackorder={linkToBackorderShipment}
                            canTransitionShipment={canChangeShipment}
                            disabled={!shipment.shouldUpdateStatus}
                            buttonType={shipment.shouldUpdateStatus ? 'primary' : 'secondary'}
                            disableBackorder
                        />
                        {shipment.expectedStatus && (
                            <Paragraph4 color={Color.BackgroundNeutral}>
                                Expected: {trShipmentStatus(shipment.expectedStatus)}
                            </Paragraph4>
                        )}
                    </Box>
                );
            },
        },
        {
            id: 'trackingStatus',
            header: 'Last status',
            flexGrow: 3,
            disabled: tab !== ShipmentTab.RealTimeTracking,
            renderer: shipment => (
                <TrackingStatus shipment={shipment} onClick={onOpenShipmentTrackingHistory} />
            ),
        },
        {
            id: 'trackingProvider',
            header: '',
            width: 26,
            disabled: tab !== ShipmentTab.RealTimeTracking,
            renderer: shipment => (
                <Box width={24}>
                    {shipment.tracking ? (
                        <Tooltip
                            placement='bottom'
                            body={trShipmentTrackerProvider(shipment.tracking.provider)}
                            enabled
                        >
                            <Icon size={24} svg={providersIcons[shipment.tracking.provider]} />
                        </Tooltip>
                    ) : (
                        <Tooltip placement='bottom' body='No tracking' enabled>
                            <Icon size={16} color={Color.BackgroundDefaultDimmer} svg={SvgIcon.Cross} />
                        </Tooltip>
                    )}
                </Box>
            ),
        },
        {
            id: 'orders',
            header: 'Orders',
            flexGrow: 1,
            disabled: tab === ShipmentTab.RealTimeTracking,
            renderer: shipment =>
                trPlural(
                    'admin-shipment-page.table.orders-count',
                    isShipmentFromUserToUser(shipment)
                        ? shipment.assignments.length / 2
                        : shipment.assignments.length,
                ),
        },
        {
            id: 'assignedUser',
            header: 'Assigned',
            flexGrow: 1,
            disabled: tab === ShipmentTab.RealTimeTracking,
            renderer: shipment => (
                <ShipmentAdminSelector shipmentId={shipment.id} user={shipment.assignedUser} />
            ),
        },
    ];
    return (
        <InfinityScrollTable
            data={shipments}
            toKey={shipment => shipment.id}
            infinityScroll={infinityScroll}
            emptyContent={
                <Placeholder
                    illustration={SvgIllustration.Shipment}
                    title='No shipments'
                    message='No shipments for selected criteria'
                />
            }
            columns={columns.filter(c => !c.disabled)}
        />
    );
};

const useHandleShipmentAction = (tab: ShipmentTab) => {
    const history = useHistory();
    const { location } = history;

    const historyPush = (path: string, query?: string) => {
        const search = location.search;
        if (search && query) {
            return history.push(`${path}${search}&${query}`);
        }
        if (query) {
            return history.push(`${path}?${query}`);
        }
        if (search) {
            return history.push(`${path}?${search}`);
        }
        history.push(path);
    };
    return (shipmentId: UUID, action: ShipmentAction) => {
        historyPush(`/shipments/${tab}/shipment/${shipmentId}/details/${action}`);
    };
};
