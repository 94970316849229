import React, { FC } from 'react';
import styled from 'styled-components';

import { Titled } from '@hofy/common';
import { Color } from '@hofy/theme';
import { Box, Button, SectionTitle3 } from '@hofy/ui';

import { useAuthI18n } from '../../i18n/useAuthI18n';

interface DownloadCodesStepProps {
    codes: string[];
    onDownload(): void;
}

export const DownloadCodesStep: FC<DownloadCodesStepProps> = ({ codes, onDownload }) => {
    const { tr } = useAuthI18n();

    return (
        <Box>
            <Titled
                heading={tr('two-factor-slideout.save-codes.title')}
                text={tr('two-factor-slideout.save-codes.subtitle')}
            />
            <CodeContainer>
                {codes.map(c => (
                    <SectionTitle3 key={c} color={Color.Neutral500}>
                        {c}
                    </SectionTitle3>
                ))}
            </CodeContainer>
            <Box row marginTop={20}>
                <Button
                    type='secondary'
                    label={tr('two-factor-slideout.download-codes.label')}
                    onClick={onDownload}
                    data-test-key='two-factor-slideout--download-codes-button'
                />
            </Box>
        </Box>
    );
};

const CodeContainer = styled.div`
    border-radius: 8px;
    padding: 20px 40px;
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    border: 1px solid ${Color.Neutral200};
    grid-row-gap: 10px;
`;
