import { ColorDye, VisualType } from '@hofy/global';

import { Color } from '../colors/Color';
import { colorDyes } from '../colors/ColorDye';

const colorDyeMap: Record<VisualType, ColorDye> = {
    informative: 'violet',
    positive: 'green',
    warning: 'orange',
    negative: 'red',
    neutral: 'grey',
};

export const toColorDye = (type: VisualType) => colorDyes[colorDyeMap[type]];

const contentColorMap: Record<VisualType, Color> = {
    informative: Color.ContentInformative,
    positive: Color.ContentPositive,
    warning: Color.ContentWarning,
    negative: Color.ContentNegative,
    neutral: Color.ContentPrimary,
};

export const toContentColor = (type: VisualType) => contentColorMap[type];
