import React, { FC, ReactNode } from 'react';

import { PublicRefDto } from '@hofy/api-shared';
import { UUID } from '@hofy/global';

import { Link } from '../../../components/routing/Link';

interface OrderDetailsLinkProps {
    order: PublicRefDto;
}

export const OrderDetailsLink: FC<OrderDetailsLinkProps> = ({ order }) => {
    return <OrderLink id={order.id}>{order.publicId}</OrderLink>;
};

interface OrderLinkProps {
    id: UUID;
    children?: ReactNode;
}

export const OrderLink: FC<OrderLinkProps> = ({ id, children }) => {
    return <Link to={`/orders/${id}`}>{children}</Link>;
};
