import { useMutation, useQueryClient } from '@tanstack/react-query';

import { assignmentsCacheKey, assignmentService, UpdateCollectionReasonPayload } from '@hofy/api-admin';
import { AssignmentCollectionReason } from '@hofy/api-shared';
import { UUID } from '@hofy/global';
import { useToast } from '@hofy/ui';

export const useUpdateCollectionReason = (assignmentId: UUID, onSuccess: () => void) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (v: UpdateCollectionReasonPayload) =>
            assignmentService.updateCollectionReason(assignmentId, v),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [assignmentsCacheKey] });
            showToast({
                type: 'positive',
                message: 'Collection updated',
            });
            onSuccess();
        },
    });

    const collect = (collectionReason: AssignmentCollectionReason | null, collectionNotes: string | null) => {
        if (collectionReason !== AssignmentCollectionReason.Other) {
            collectionNotes = null;
        } else {
            collectionNotes = `Collection Notes: ${collectionNotes}`;
        }
        mutation.mutate({ collectionReason: collectionReason || null, collectionNotes });
    };

    return {
        collect,
    };
};
