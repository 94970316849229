import React, { FC } from 'react';

import { InvoicePaymentStatus, invoicePaymentStatusColors } from '@hofy/api-admin';
import { Badge } from '@hofy/ui';

import { useTrInvoicePaymentStatus } from '../../../store/invoices/useTrInvoicePaymentStatus';

interface InvoicePaymentStatusChipProps {
    status: InvoicePaymentStatus | null;
}

export const InvoicePaymentStatusChip: FC<InvoicePaymentStatusChipProps> = ({ status }) => {
    const trInvoicePaymentStatus = useTrInvoicePaymentStatus();
    if (!status) {
        return <Badge color='grey' label='None' />;
    }
    return <Badge color={invoicePaymentStatusColors[status]} label={trInvoicePaymentStatus(status)} />;
};
