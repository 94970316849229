import React, { FC } from 'react';

import { AssignmentStatus, assignmentStatusColor, useTrAssignmentStatus } from '@hofy/api-shared';
import { Badge } from '@hofy/ui';

interface AssignmentStatusBadgeProps {
    status: AssignmentStatus;
}

export const AssignmentStatusBadge: FC<AssignmentStatusBadgeProps> = ({ status }) => {
    const trAssignmentStatus = useTrAssignmentStatus();
    return <Badge label={trAssignmentStatus(status)} color={assignmentStatusColor[status]} />;
};
