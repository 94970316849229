import React, { FC } from 'react';

import { RentalTerm } from '@hofy/api-shared';
import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, Paragraph4, SectionTitle3 } from '@hofy/ui';

interface PriceLabelProps {
    price: Price;
    rentalTerm?: RentalTerm;
}

export const RentalTermPriceLabel: FC<PriceLabelProps> = ({ price, rentalTerm }) => {
    const { formatMonthlyPrice, formatPrice } = usePrice();
    return (
        <Box>
            <SectionTitle3>{rentalTerm ? formatMonthlyPrice(price) : formatPrice(price)}</SectionTitle3>
            <Paragraph4 color={Color.ContentTertiary}>
                Invoice may include additional charges and discounts
            </Paragraph4>
        </Box>
    );
};
