import React, { FC } from 'react';
import styled from 'styled-components';

import { OneOffEventDto } from '@hofy/api-admin';
import { useTrRole } from '@hofy/api-shared';
import { formatDateTime } from '@hofy/helpers';
import { Color } from '@hofy/theme';
import { Box, Paragraph3, Span } from '@hofy/ui';

interface EventProps {
    event: OneOffEventDto;
}

export const EventContent: FC<EventProps> = ({
    event: { createdBy, createdAt, payload, organization, roles },
}) => {
    const trRole = useTrRole();
    const formatTarget = () => {
        return (
            <>
                <Span color={Color.Neutral900}>{organization?.name || 'Global'}</Span>{' '}
                {!!roles.length && <>( {roles.map(role => trRole(role)).join(', ')} )</>}
            </>
        );
    };

    return (
        <Box>
            <Paragraph3 marginBottom={12} color={Color.Neutral700}>
                {createdBy && (
                    <>
                        <Span color={Color.Neutral900}>{createdBy.name}</Span>
                        {' | '}
                    </>
                )}
                <>
                    {formatTarget()}
                    {' | '}
                </>
                {formatDateTime(createdAt)}
            </Paragraph3>
            <Wrapper>
                <Paragraph3 color={Color.Neutral900} paddingBottom={8}>
                    {payload.title}
                </Paragraph3>
                <Paragraph3 color={Color.Neutral700}>{payload.message}</Paragraph3>
            </Wrapper>
        </Box>
    );
};

const IconWrapper = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
`;

const Wrapper = styled(Box)`
    background: ${Color.Highlight};
    border-radius: 8px;
    padding: 12px;
    position: relative;

    ::before {
        content: '';
        background: ${Color.Highlight};
        width: 16px;
        height: 16px;
        position: absolute;
        top: -8px;
        left: 8px;
        transform: scaleX(0.8) rotate(45deg);
    }

    ${IconWrapper} {
        display: none;
    }

    :hover ${IconWrapper} {
        display: block;
    }
`;
