import { activityLogEventKindI18n } from './activityLogEventKindI18n';
import { assetActivityI18n } from './assetActivityl18n';
import { collectionActivityI18n } from './collectionActivityl18n';

export const activityLogI18n = {
    ...activityLogEventKindI18n,
    ...assetActivityI18n,
    ...collectionActivityI18n,
};

export type ActivityLogI18nKey = keyof typeof activityLogI18n;
