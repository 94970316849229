import { ColorDye, getEnumValues } from '@hofy/global';

export enum OrderItemStatus {
    Placed = 'placed',
    AwaitingPayment = 'awaiting_payment',
    Processing = 'processing',
    ReadyToShip = 'ready_to_ship',
    Shipped = 'shipped',
    Completed = 'completed',
    Cancelled = 'cancelled',
}

export const orderItemStatusColors: Record<OrderItemStatus, ColorDye> = {
    [OrderItemStatus.Placed]: 'grey',
    [OrderItemStatus.AwaitingPayment]: 'grey',
    [OrderItemStatus.Processing]: 'grey',
    [OrderItemStatus.ReadyToShip]: 'grey',
    [OrderItemStatus.Shipped]: 'grey',
    [OrderItemStatus.Completed]: 'green',
    [OrderItemStatus.Cancelled]: 'grey',
};

const allOrderItemStatuses = getEnumValues<OrderItemStatus>(OrderItemStatus);
export const orderItemStatusesWithoutPlaced = allOrderItemStatuses.filter(
    status => status !== OrderItemStatus.Placed,
);
