import React, { FC, useMemo } from 'react';

import { useHofyWarehousesQuery, useShipmentQuery } from '@hofy/api-admin';
import { UseForm } from '@hofy/ui';

import { SupplierDropdown } from '../../../../../components/domain/supplier/SupplierDropdown';
import { PurchaseOrderHeaderFormData } from '../../../../../store/purchaseOrders/usePurchaseOrderHeaderForm';

interface PurchaseOrderFormSupplierDropdownProps {
    headerForm: UseForm<PurchaseOrderHeaderFormData>;
}

export const PurchaseOrderFormSupplierDropdown: FC<PurchaseOrderFormSupplierDropdownProps> = ({
    headerForm,
}) => {
    const { data: shipment } = useShipmentQuery(headerForm.values.shipmentId);
    const { data: allWarehouses } = useHofyWarehousesQuery();

    const shipmentCountry = useMemo(() => {
        if (headerForm.values.isDropship) {
            return shipment?.toAddress?.country;
        }
        const warehouse = allWarehouses.find(w => w.id === headerForm.values.toWarehouseId);
        return warehouse?.address?.country;
    }, [headerForm.values.isDropship, shipment, allWarehouses]);

    return (
        <SupplierDropdown
            value={headerForm.values.supplierId}
            label='Supplier'
            onChange={supplier =>
                headerForm.setValues({
                    supplierId: supplier.id,
                    currency: supplier.currency,
                })
            }
            isRequired
            isError={headerForm.errors.supplierId}
            filterSuppliers={supplier =>
                !headerForm.values.isDropship
                    ? true
                    : !!shipmentCountry && supplier.countries.includes(shipmentCountry)
            }
        />
    );
};
