import React, { FC, forwardRef, ReactNode } from 'react';
import styled from 'styled-components';

import { Color } from '@hofy/theme';

import { Font, MarginBoxProps, Pressable } from '../../base';
import { Icon } from '../../icon';
import largeBellSvg from './assets/large-bell.svg';

interface NavigationNotificationBellProps extends MarginBoxProps {
    count: number;
}

export const NavigationNotificationBell = forwardRef<any, NavigationNotificationBellProps>(
    ({ count, ...rest }, ref) => {
        return (
            <Pressable ref={ref} height={24} width={24} centered relative {...rest} activeEffect>
                <Icon svg={largeBellSvg} color={Color.ContentInvertedPrimary} size={24} />
                {count > 0 && <CircleIndicator>{count >= 10 ? '9+' : count}</CircleIndicator>}
            </Pressable>
        );
    },
);

interface CircleIndicatorProps {
    children: ReactNode;
}

export const CircleIndicator: FC<CircleIndicatorProps> = ({ children }) => {
    return (
        <IndicatorBox>
            <Font size={8} color={Color.BackgroundDefault}>
                {children}
            </Font>
        </IndicatorBox>
    );
};

const IndicatorBox = styled.div`
    background: ${Color.NonContextualRedDefault};
    border-radius: 16px;
    position: absolute;
    top: 0;
    right: 2px;
    padding: 0 4px;
    transform: translateX(50%);
`;
