import React, { FC } from 'react';

import { AuditLogOperationType, auditLogOperationTypeStatusColor } from '@hofy/api-admin';
import { Badge } from '@hofy/ui';

import { useTrAuditLogOperationType } from '../../../store/auditLogs/useTrAuditLogOperationType';

interface AuditLogOperationTypeProps {
    operationType: AuditLogOperationType;
}

export const AuditLogOperationStatusChip: FC<AuditLogOperationTypeProps> = ({ operationType }) => {
    const tr = useTrAuditLogOperationType();
    return (
        <Badge
            label={tr(operationType)}
            color={operationType ? auditLogOperationTypeStatusColor[operationType] : 'grey'}
        />
    );
};
