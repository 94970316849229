export enum ShipmentAction {
    Schedule = 'schedule',
    Book = 'book',
    Ship = 'ship',
    Complete = 'complete',
    Backorder = 'backorder',
    Edit = 'edit',
    Cancel = 'cancel',
    CreatePurchaseOrder = 'create-purchase-order',
    CreateCollectionRequestSurvey = 'create-collection-request-survey',
}
