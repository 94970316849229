import React, { FC } from 'react';

import { ContractDetailsDtoWithRental, RentalSubContractDto } from '@hofy/api-admin';
import { formatDate } from '@hofy/helpers';
import {
    Alert,
    FormContainer,
    FormDateInput,
    FormModal,
    FormPriceInput,
    ModalContent,
    ModalFooter,
    ModalHeader,
    SubmitButton,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useRolloverContract } from '../../../store/contracts/useRolloverContract';

interface RolloverContractModalProps {
    contract: ContractDetailsDtoWithRental;
    mainSubContract: RentalSubContractDto;
    onClose(): void;
}

export const RolloverContractModal: FC<RolloverContractModalProps> = ({
    contract,
    mainSubContract,
    onClose,
}) => {
    const { form, isLoading, isError } = useRolloverContract(
        {
            id: contract.id,
            rolloverPrice: contract.rentalContract.rolloverPrice,
            estimatedEndOfContract: mainSubContract.estimatedEndOfContract,
        },
        onClose,
    );

    return (
        <FormModal
            width={450}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title={`Rollover contract #${contract.id}`} />
            <ModalContent>
                <FormContainer>
                    <Alert
                        type='positive'
                        title={`A new rolling contract will be created starting from ${formatDate(
                            form.values.rolloverDate,
                        )} and added as a subcontract`}
                        description='The currently active subcontract will be terminated'
                        marginVertical={16}
                    />
                    <FormDateInput label='Rollover date' api={form.fields.rolloverDate} />
                    <FormPriceInput label='Rollover price' api={form.fields.rolloverPrice} />
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={form.discard} />
                <SubmitButton label='Rollover' />
            </ModalFooter>
        </FormModal>
    );
};
