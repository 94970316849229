import React, { FC } from 'react';

import { RevenueRecognitionFilters } from '@hofy/api-admin';
import { ExportButton } from '@hofy/common';

import { useRevenueRecognitionReport } from '../../../../store/invoicing/revenueRecognition/useRevenueRecognitionReport';

interface RevenueRecognitionDownloadProps {
    filters: RevenueRecognitionFilters;
}

export const RevenueRecognitionDownloadReport: FC<RevenueRecognitionDownloadProps> = ({ filters }) => {
    const { downloadContractReport } = useRevenueRecognitionReport();
    return <ExportButton onClick={() => downloadContractReport(filters)} />;
};
