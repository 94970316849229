import { FileRef, PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { StockLevelsDto } from './types/StockLevelsDto';
import { StockLevelsFilters } from './types/StockLevelsFilters';
import { UploadMinAvailability } from './types/UploadMinAvailability';

class StockLevelsService {
    public getStockLevels = async (
        filters: StockLevelsFilters,
        page: PageRequest,
    ): Promise<PageResponse<StockLevelsDto>> => {
        return restClient.getJson<PageResponse<StockLevelsDto>>(
            stringifyUrl({
                url: `/api/admin/stock-levels`,
                query: {
                    page: page.page,
                    pageSize: page.pageSize,
                    search: filters.search,
                    category: filters.category,
                    activeVariants: filters.activeVariants,
                    warehouses: filters.warehouses,
                    filterZeroStock: filters.filterZeroStock,
                },
            }),
        );
    };

    public exportStockLevels = async (filters: StockLevelsFilters): Promise<void> => {
        await restClient
            .downloadFile(
                stringifyUrl({
                    url: `/api/admin/stock-levels/report`,
                    query: {
                        search: filters.search,
                        category: filters.category,
                        activeVariants: filters.activeVariants,
                        warehouses: filters.warehouses,
                        filterZeroStock: filters.filterZeroStock,
                    },
                }),
            )
            .then(downloadFileFromResponse);
    };

    public uploadMinAvailability = async (file: FileRef): Promise<void> => {
        const payload: UploadMinAvailability = {
            file,
        };
        await restClient.post(`/api/admin/stock-levels/report`, payload);
    };
}

export const stockLevelsService = new StockLevelsService();
