import { useCountryMultipliersQuery } from '@hofy/api-admin';
import { useSearch } from '@hofy/hooks';
import { useTrCountry } from '@hofy/i18n';
import { useStringQueryParam } from '@hofy/router';

export const useCountryMultipliers = () => {
    const trCountry = useTrCountry();

    const { data, isLoading } = useCountryMultipliersQuery();
    const [search, setSearch] = useStringQueryParam('search', '');

    const countryMultipliers = useSearch(
        data || [],
        item => [item.country, trCountry(item.country), item.multiplier],
        search,
    );

    return {
        countryMultipliers,
        isLoading,
        search,
        setSearch,
    };
};
