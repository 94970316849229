import React, { FC } from 'react';

import { Color } from '@hofy/theme';
import { Labeled, Paragraph3 } from '@hofy/ui';

import {
    SurveyResultsSectionCardChip,
    SurveyResultsSectionCardChipStatus,
} from './SurveyResultsSectionCardChip';

interface PhoneNumberResultProps {
    collectionPhoneNumber: string | null;
    userPhoneNumber: string | null;
}

export const PhoneNumberResult: FC<PhoneNumberResultProps> = ({ collectionPhoneNumber, userPhoneNumber }) => {
    if (collectionPhoneNumber === userPhoneNumber) {
        return (
            <SurveyResultsSectionCardChip
                title='Phone number'
                status={SurveyResultsSectionCardChipStatus.Neutral}
            >
                <Paragraph3 color={Color.ContentPrimary}>{collectionPhoneNumber}</Paragraph3>
            </SurveyResultsSectionCardChip>
        );
    }

    return (
        <SurveyResultsSectionCardChip
            title='Different phone number'
            status={SurveyResultsSectionCardChipStatus.Warning}
        >
            <Labeled
                label='Collection phone number'
                content={<Paragraph3 color={Color.ContentPrimary}>{collectionPhoneNumber}</Paragraph3>}
            />
            <Labeled
                label='User phone number'
                content={<Paragraph3 color={Color.ContentPrimary}>{userPhoneNumber}</Paragraph3>}
            />
        </SurveyResultsSectionCardChip>
    );
};
