import React, { FC } from 'react';
import styled from 'styled-components';

import { Box, Paragraph3, TextCell } from '@hofy/ui';

interface PurchaseOrderQuantityCellProps {
    orderedQuantity: number;
    receivedQuantity: number;
    billedQuantity: number;
    approvedQuantity: number;
}

export const PurchaseOrderQuantityCell: FC<PurchaseOrderQuantityCellProps> = ({
    orderedQuantity,
    receivedQuantity,
    billedQuantity,
    approvedQuantity,
}) => {
    return (
        <Box overflow='hidden'>
            <Box row>
                <QuantityLabel marginRight={4}>Ordered:</QuantityLabel>
                <QuantityCell>{orderedQuantity}</QuantityCell>
            </Box>
            <Box row>
                <QuantityLabel marginRight={4}>Approved:</QuantityLabel>
                <QuantityCell>{approvedQuantity}</QuantityCell>
            </Box>
            <Box row>
                <QuantityLabel marginRight={4}>Received:</QuantityLabel>
                <QuantityCell>{receivedQuantity}</QuantityCell>
            </Box>
            <Box row>
                <QuantityLabel marginRight={4}>Billed:</QuantityLabel>
                <QuantityCell>{billedQuantity}</QuantityCell>
            </Box>
        </Box>
    );
};

const QuantityCell = styled(TextCell)`
    font-weight: bold;
`;

const QuantityLabel = styled(Paragraph3)`
    min-width: 64px;
    text-align: right;
`;
