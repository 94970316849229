import React, { FC, FocusEvent, ReactNode, useEffect, useRef, useState } from 'react';

import { useInputCursor } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, MarginBoxProps, Paragraph3, TestKeyAware } from '@hofy/ui';

import { ClearableInput } from './ClearableInput';

interface FormFractionInputProps extends MarginBoxProps, TestKeyAware {
    value: string | null;
    onChange(v: string): void;
    isError?: boolean | string;
    errorMessage?: ReactNode;
    isRequired?: boolean;
    label?: string;
    name?: string;
    unit?: string;
    disabled?: boolean;
    clearable?: boolean;
    flex?: number | 'auto';
    onFocus?(e: FocusEvent): void;
    onBlur?(e: FocusEvent): void;
}

const emptyValue = '--';

/** @deprecated use `LabeledInput` or `FormInput` with `rightSlot` prop from `@hofy/ui` */
export const FormFractionInput: FC<FormFractionInputProps> = ({
    value,
    onChange,
    onFocus,
    onBlur,
    disabled = false,
    flex,
    clearable = false,
    label,
    isError,
    errorMessage,
    unit,
    ...rest
}) => {
    const ref = useRef<HTMLInputElement>(null);
    useInputCursor(ref, [0]);
    const [isFocused, setIsFocused] = useState(false);

    const formatValue = (v: string | null) => {
        if (v === null) {
            return emptyValue;
        }
        return `${v}${unit ?? ''}`;
    };

    const [localValue, setLocalValue] = useState(() => formatValue(value));

    useEffect(() => {
        if (value !== null && !isFocused) {
            setLocalValue(formatValue(value));
        }
    }, [value]);

    const handleChange = (v: string | null) => {
        setLocalValue(v || '');
    };

    const handleBlur = (e: FocusEvent) => {
        const amountNumber = parseFloat(localValue);
        if (!Number.isNaN(amountNumber)) {
            onChange(amountNumber.toFixed(2));
        } else if (clearable === true) {
            onChange(null as any);
        } else {
            setLocalValue(formatValue(value));
        }
        onBlur?.(e);
        setIsFocused(false);
    };

    const handleFocus = (e: FocusEvent) => {
        if (localValue === emptyValue) {
            setLocalValue('');
        }
        onFocus?.(e);
        setIsFocused(true);
    };

    const actualIsError = typeof isError === 'string' || isError === true;
    const errorReason = errorMessage || (typeof isError === 'string' ? isError : undefined);
    const showError = actualIsError && errorReason;

    return (
        <Box>
            {label && <Paragraph3 marginBottom={5}>{label}</Paragraph3>}
            <ClearableInput
                ref={ref}
                value={localValue}
                flex={flex}
                onChangeText={v => handleChange(v || null)}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                clearable={clearable && localValue !== emptyValue}
                isError={actualIsError}
                {...rest}
            />
            {showError && (
                <Paragraph3 color={Color.HighlightNegativeHover} marginTop={5}>
                    {errorReason}
                </Paragraph3>
            )}
        </Box>
    );
};
