import { AddressDto } from './AddressDto';
import { ShipmentLocationType } from './ShipmentLocationType';

interface AddressAware {
    address: AddressDto | null;
}

export interface ShipmentLocationUser<U> extends AddressAware {
    type: ShipmentLocationType.User;
    user: U;
}

interface ShipmentLocationHofyWarehouse<W> extends AddressAware {
    type: ShipmentLocationType.HofyWarehouse;
    warehouse: W;
}

interface ShipmentLocationOrgWarehouse<W> extends AddressAware {
    type: ShipmentLocationType.OrganizationWarehouse;
    warehouse: W;
}

interface ShipmentLocationSupplier<S> extends AddressAware {
    type: ShipmentLocationType.Supplier;
    // TODO HOF-9116 - Remove null once supplier are specified when creating dropships
    supplier: S | null;
}

export type ShipmentLocation<U, W, S> =
    | ShipmentLocationUser<U>
    | ShipmentLocationHofyWarehouse<W>
    | ShipmentLocationOrgWarehouse<W>
    | ShipmentLocationSupplier<S>;

export const isShipmentLocationUser = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationUser<U> => {
    return l.type === ShipmentLocationType.User;
};

export const isShipmentLocationHofyWarehouse = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationHofyWarehouse<W> => {
    return l.type === ShipmentLocationType.HofyWarehouse;
};

export const isShipmentLocationOrgWarehouse = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationOrgWarehouse<W> => {
    return l.type === ShipmentLocationType.OrganizationWarehouse;
};

export const isShipmentLocationSupplier = <U, W, S>(
    l: ShipmentLocation<U, W, S>,
): l is ShipmentLocationSupplier<S> => {
    return l.type === ShipmentLocationType.Supplier;
};

type Prefix = 'from' | 'to';
type WithPrefix<P extends Prefix, L> = {
    [k in keyof L as `${P}${Capitalize<string & k>}`]: L[k];
};

type ShipmentLocationWithPrefix<P extends Prefix, U, W, S> =
    | WithPrefix<P, ShipmentLocationUser<U>>
    | WithPrefix<P, ShipmentLocationHofyWarehouse<W>>
    | WithPrefix<P, ShipmentLocationOrgWarehouse<W>>
    | WithPrefix<P, ShipmentLocationSupplier<S>>;

type ShipmentLocationFrom<U, W, S> = ShipmentLocationWithPrefix<'from', U, W, S>;
type ShipmentLocationTo<U, W, S> = ShipmentLocationWithPrefix<'to', U, W, S>;

type ShipmentLocationFromUser<U> = WithPrefix<'from', ShipmentLocationUser<U>>;
type ShipmentLocationToUser<U> = WithPrefix<'to', ShipmentLocationUser<U>>;
type ShipmentLocationFromHofyWarehouse<W> = WithPrefix<'from', ShipmentLocationHofyWarehouse<W>>;
type ShipmentLocationToHofyWarehouse<W> = WithPrefix<'to', ShipmentLocationHofyWarehouse<W>>;
type ShipmentLocationFromOrgWarehouse<W> = WithPrefix<'from', ShipmentLocationOrgWarehouse<W>>;
type ShipmentLocationToOrgWarehouse<W> = WithPrefix<'to', ShipmentLocationOrgWarehouse<W>>;
type ShipmentLocationFromSupplier<S> = WithPrefix<'from', ShipmentLocationSupplier<S>>;
type ShipmentLocationToSupplier<S> = WithPrefix<'to', ShipmentLocationSupplier<S>>;

export type ShipmentLocationAware<U = unknown, W = unknown, S = unknown> = ShipmentLocationFrom<U, W, S> &
    ShipmentLocationTo<U, W, S>;

export const isShipmentFromUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ShipmentLocationType.User;
};

export const isShipmentToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToUser<U> => {
    return s?.toType === ShipmentLocationType.User;
};

export const isShipmentFromOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromOrgWarehouse<W> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ShipmentLocationType.OrganizationWarehouse;
};

export const isShipmentToOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToOrgWarehouse<W> => {
    return s?.toType === ShipmentLocationType.OrganizationWarehouse;
};

export const isShipmentFromHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromHofyWarehouse<W> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ShipmentLocationType.HofyWarehouse;
};

export const isShipmentToHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToHofyWarehouse<W> => {
    return s?.toType === ShipmentLocationType.HofyWarehouse;
};

export const isShipmentFromSupplier = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromSupplier<S> & ShipmentLocationTo<U, W, S> => {
    return s?.fromType === ShipmentLocationType.Supplier;
};

export const isShipmentToSupplier = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> & ShipmentLocationToSupplier<S> => {
    return s?.toType === ShipmentLocationType.Supplier;
};

export const isShipmentFromWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is (ShipmentLocationFromHofyWarehouse<W> | ShipmentLocationFromOrgWarehouse<W>) &
    ShipmentLocationTo<U, W, S> => {
    return isShipmentFromHofyWarehouse(s) || isShipmentFromOrganizationWarehouse(s);
};

export const isShipmentToWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFrom<U, W, S> &
    (ShipmentLocationToHofyWarehouse<W> | ShipmentLocationToOrgWarehouse<W>) => {
    return isShipmentToHofyWarehouse(s) || isShipmentToOrganizationWarehouse(s);
};

export const isShipmentFromUserToUser = <U, W, S>(
    shipment: ShipmentLocationAware<U, W, S> | null | undefined,
): shipment is ShipmentLocationFromUser<U> & ShipmentLocationToUser<U> => {
    return isShipmentFromUser(shipment) && isShipmentToUser(shipment);
};

export const isShipmentFromUserToHofyWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationToHofyWarehouse<W> => {
    return isShipmentFromUser(s) && isShipmentToHofyWarehouse(s);
};

export const isShipmentFromHofyWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromHofyWarehouse<W> & ShipmentLocationToUser<U> => {
    return isShipmentFromHofyWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromUserToOrganizationWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> & ShipmentLocationToOrgWarehouse<W> => {
    return isShipmentFromUser(s) && isShipmentToOrganizationWarehouse(s);
};

export const isShipmentFromOrganizationWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromOrgWarehouse<W> & ShipmentLocationToUser<U> => {
    return isShipmentFromOrganizationWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromUserToWarehouse = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromUser<U> &
    (ShipmentLocationToHofyWarehouse<W> | ShipmentLocationToOrgWarehouse<W>) => {
    return isShipmentFromUser(s) && isShipmentToWarehouse(s);
};

export const isShipmentFromWarehouseToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is (ShipmentLocationFromHofyWarehouse<W> | ShipmentLocationFromOrgWarehouse<W>) &
    ShipmentLocationToUser<U> => {
    return isShipmentFromWarehouse(s) && isShipmentToUser(s);
};

export const isShipmentFromSupplierToUser = <U, W, S>(
    s: ShipmentLocationAware<U, W, S> | null | undefined,
): s is ShipmentLocationFromSupplier<S> & ShipmentLocationToUser<U> => {
    return isShipmentFromSupplier(s) && isShipmentToUser(s);
};

export const getShipmentFromLocation = <U = unknown, W = unknown, S = unknown>(
    shipment: ShipmentLocationAware<U, W, S>,
): ShipmentLocation<U, W, S> => {
    return {
        type: shipment.fromType,
        address: shipment.fromAddress,
        ...(isShipmentFromUser(shipment) && {
            user: shipment.fromUser,
        }),
        ...(isShipmentFromWarehouse(shipment) && {
            warehouse: shipment.fromWarehouse,
        }),
        ...(isShipmentFromSupplier(shipment) && {
            supplier: shipment.fromSupplier,
        }),
    } as ShipmentLocation<U, W, S>;
};

export const getShipmentToLocation = <U = unknown, W = unknown, S = unknown>(
    shipment: ShipmentLocationAware<U, W, S>,
): ShipmentLocation<U, W, S> => {
    return {
        type: shipment.toType,
        address: shipment.toAddress,
        ...(isShipmentToUser(shipment) && {
            user: shipment.toUser,
        }),
        ...(isShipmentToWarehouse(shipment) && {
            warehouse: shipment.toWarehouse,
        }),
        ...(isShipmentToSupplier(shipment) && {
            supplier: shipment.toSupplier,
        }),
    } as ShipmentLocation<U, W, S>;
};
