import { useQuery } from '@tanstack/react-query';

import { UUID } from '@hofy/global';

import { auditLogService } from '../service/auditLogService';
import { cacheKey } from './cacheKey';

export const useAuditLogQuery = (id: UUID) => {
    const { data, isLoading } = useQuery({
        queryKey: [cacheKey, id],
        queryFn: () => auditLogService.getAuditLog(id),
    });

    return {
        data,
        isLoading,
    };
};
