import React, { FC, ReactElement } from 'react';
import { useToggle } from 'react-use';
import styled from 'styled-components';

import { UUID } from '@hofy/global';

import { Box, Pressable } from '../base';
import { Dropdown } from '../dropdown';
import { FramedImage } from './FramedImage';

export interface ImageDropdownImage {
    url: string;
    id: UUID;
}

interface ImageDropdownProps {
    images: ImageDropdownImage[];
    selected: UUID | null;
    imageSize?: number;
    dropdownImageSize?: number;
    fallback: string | ReactElement;
    isError?: boolean;
    onChange(index: UUID): void;
}

export const ImageDropdown: FC<ImageDropdownProps> = ({
    imageSize = 50,
    dropdownImageSize = 80,
    fallback,
    images,
    isError,
    selected,
    onChange,
}) => {
    const [open, toggleOpen] = useToggle(false);

    const handleChange = (image: UUID) => {
        onChange(image);
        toggleOpen(false);
    };
    const selectedImage = selected ? images.find(v => v.id === selected) : null;

    if (images.length === 0) {
        return (
            <FramedImage size={imageSize} fallback={fallback} src={selectedImage?.url} isError={isError} />
        );
    }

    return (
        <Dropdown
            open={open}
            onDismiss={() => toggleOpen(false)}
            trigger={
                <Pressable onClick={toggleOpen}>
                    <FramedImage
                        size={imageSize}
                        fallback={fallback}
                        src={selectedImage?.url}
                        isError={isError}
                    />
                </Pressable>
            }
            asChild
            placement='bottom-start'
        >
            <Content columns={Math.min(4, images.length)}>
                {images.map((image, index) => (
                    <Box key={index} pointer onClick={() => handleChange(image.id)}>
                        <FramedImage size={dropdownImageSize} fallback={fallback} src={image.url} />
                    </Box>
                ))}
            </Content>
        </Dropdown>
    );
};

const Content = styled(Box)<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
    grid-gap: 8px;
    padding: 8px;
`;
