import React from 'react';
import { useMeasure } from 'react-use';

import { Box } from '../base';
import { Image, ImageCarousel, ImageCarouselProps } from './ImageCarousel';

export const RWDImageCarousel = <T extends Image>(props: ImageCarouselProps<T>) => {
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    return (
        <Box ref={ref} overflow='hidden' fullWidth>
            {width > 0 && <ImageCarousel {...props} size={width} />}
        </Box>
    );
};
