import { ColorDye, getEnumValues } from '@hofy/global';

export enum ItemLocation {
    WithUser = 'with_user',
    AtWarehouse = 'at_warehouse',
    WithCourier = 'with_courier',
    WriteOff = 'write_off',
}

export const allItemLocations = getEnumValues<ItemLocation>(ItemLocation);

export const allAssetItemLocations = getEnumValues<ItemLocation>(ItemLocation).filter(
    location => location !== ItemLocation.WriteOff,
);

export const itemLocationColor: Record<ItemLocation, ColorDye> = {
    [ItemLocation.WithUser]: 'green',
    [ItemLocation.AtWarehouse]: 'grey',
    [ItemLocation.WithCourier]: 'blue',
    [ItemLocation.WriteOff]: 'grey',
};
