import React, { FC } from 'react';

import { ItemStatus, itemStatusColor, useTrItemStatus } from '@hofy/api-shared';
import { Badge, OuterBoxProps } from '@hofy/ui';

interface ItemStatusChipProps extends OuterBoxProps {
    status: ItemStatus;
}

export const ItemStatusChip: FC<ItemStatusChipProps> = ({ status, ...rest }) => {
    const trItemStatus = useTrItemStatus();
    const color = itemStatusColor[status];

    return <Badge label={trItemStatus(status)} color={color} {...rest} />;
};
