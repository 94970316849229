import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import { UUID } from '@hofy/global';
import { useImageLoad } from '@hofy/hooks';
import { Color } from '@hofy/theme';

import { BoxProps, Font } from '../base';
import { getAvatarColor } from './AvatarColor';

export interface AvatarProps extends BoxProps {
    name: string;
    /** The user uuid to generate a color */
    userId: UUID;
    /** The url of an image to show on the avatar */
    pictureUrl?: string | null;
    /** The size of the avatar */
    blockSize?: number;
    radius?: number;
}

export const Avatar: FC<AvatarProps> = ({ name, userId, pictureUrl, blockSize = 30, radius, ...rest }) => {
    const letter = name.substring(0, 1).toUpperCase();
    const fontSize = blockSize / 1.85; // ~16 for 30 blockSize
    const ref = useRef<HTMLImageElement>(null);
    const { isError } = useImageLoad(ref, pictureUrl || null);
    const hasPicture = pictureUrl && !isError;
    const background = hasPicture ? undefined : getAvatarColor(userId);
    return (
        <WrapperFont
            centered
            bold
            color={Color.BackgroundDefault}
            width={blockSize}
            height={blockSize}
            overflow='hidden'
            style={{ background, fontSize }}
            radius={radius}
            {...rest}
        >
            {hasPicture ? (
                <Image
                    referrerPolicy='no-referrer'
                    ref={ref}
                    src={pictureUrl}
                    blockSize={blockSize}
                    radius={radius}
                    alt='user-logo'
                />
            ) : (
                letter
            )}
        </WrapperFont>
    );
};

const WrapperFont = styled(Font)<{ radius?: number }>`
    border-radius: ${props => props.radius ?? 50}%;
`;

const Image = styled.img<{ blockSize: number; radius?: number }>`
    border-radius: ${props => props.radius ?? 50}%;
    width: ${props => props.blockSize}px;
    height: ${props => props.blockSize}px;
`;
