import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { Color, NumberValues } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, TestKeyAware } from '@hofy/ui';

import { CheckableContent } from './checkbox/CheckableContent';

interface SwitchProps extends BoxProps, TestKeyAware {
    checked: boolean;
    onChange?(v: boolean): void;
    label?: ReactNode;
    disabled?: boolean;
    color?: Color;
}

/** @deprecated use `Switch` from `@hofy/ui` instead */
export const Switch: FC<SwitchProps> = ({
    children,
    checked,
    onChange,
    label,
    testKey,
    disabled = false,
    color = Color.FoundationPositive,
    ...boxProps
}) => {
    const getLabel = () => {
        if (!label) {
            return null;
        }
        if (typeof label === 'string') {
            return <Paragraph3 color={disabled ? Color.Neutral300 : Color.Neutral700}>{label}</Paragraph3>;
        }
        return label;
    };

    return (
        <Box row {...boxProps}>
            <SwitchContainer
                data-test-key={testKey}
                disabled={disabled}
                marginRight={label || children ? 15 : 0}
                color={color}
            >
                <CheckableContent type='checkbox' checked={checked} disabled={disabled} onChange={onChange}>
                    {children}
                </CheckableContent>
            </SwitchContainer>
            {getLabel()}
        </Box>
    );
};

const SwitchContainer = styled.div<{ disabled: boolean; marginRight: NumberValues; color: Color }>`
    > input {
        display: none;
    }

    > label {
        min-height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding-left: 28px;
        margin-right: ${p => p.marginRight}px;
        line-height: 24px;

        &::before,
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            transform-origin: 50% 50%;
            content: '';
        }

        &::before {
            width: 28px;
            height: 18px;
            border-radius: 20px;
            background: ${p => (p.disabled ? Color.BackgroundDefault : Color.HighlightActive)};
            border: ${p => (p.disabled ? `1px solid ${Color.Neutral200}` : 'none')};
            transform: translate(0, -50%);
        }

        &::after {
            transition: transform 200ms ease;
            width: 11.25px;
            height: 11.25px;
            border-radius: 5px;
            background-color: ${p => (p.disabled ? Color.Neutral200 : Color.BackgroundDefault)};
            transform: translate(0, -50%);
            left: 3px;
        }
    }

    > input:checked + label::before {
        background-color: ${p => p.color};
        border: ${p => p.disabled && 'none'};
        opacity: ${p => (p.disabled ? 0.3 : 1)};
    }

    > input:checked + label::after {
        transform: translate(11px, -50%);
        background-color: ${p => p.disabled && Color.BackgroundDefault};
    }
`;
