import React, { FC } from 'react';

import { ContractExtendOption, useTrContractExtendOption } from '@hofy/api-shared';
import { Color } from '@hofy/theme';
import { Badge, Paragraph4, Span } from '@hofy/ui';

interface ContractExtendOptionBadgeProps {
    contractExtendOption: ContractExtendOption;
    organizationExtendOption: ContractExtendOption;
}

export const ContractExtendOptionBadge: FC<ContractExtendOptionBadgeProps> = ({
    contractExtendOption,
    organizationExtendOption,
}) => {
    const trExtendOption = useTrContractExtendOption();

    const getLabel = () => {
        if (contractExtendOption === ContractExtendOption.Default) {
            return (
                <Paragraph4>
                    {trExtendOption(contractExtendOption)}:{' '}
                    <Span color={Color.ContentPrimary}>{trExtendOption(organizationExtendOption)}</Span>
                </Paragraph4>
            );
        }

        return <Paragraph4 color={Color.ContentPrimary}>{trExtendOption(contractExtendOption)}</Paragraph4>;
    };

    return <Badge leftSlot={getLabel()} />;
};
