import React, { FC } from 'react';

import { DateRangeStrings } from '@hofy/global';

import { FormFieldApi } from '../../../../hooks';
import { TestKeyAware } from '../../../../types';
import { Optional } from '../../../../types/Utils';
import { LabeledDateRangeInput, LabeledDateRangeInputProps } from './LabeledDateRangeInput';

type FormDateRangeInputOptionalProps = Optional<LabeledDateRangeInputProps, 'onChange'>;
type FormDateRangeInputOmittedProps = Omit<FormDateRangeInputOptionalProps, 'errorMessage' | 'value'>;

interface FormNormalDateRangeInputProps extends FormDateRangeInputOmittedProps, TestKeyAware {
    api: FormFieldApi<DateRangeStrings>;
    nullable?: false;
}

interface FormNullableDateRangeInputProps extends FormDateRangeInputOmittedProps, TestKeyAware {
    api: FormFieldApi<DateRangeStrings | null>;
    nullable: true;
}

type FormDateRangeInputProps = FormNormalDateRangeInputProps | FormNullableDateRangeInputProps;

export const FormDateRangeInput: FC<FormDateRangeInputProps> = ({ api, onChange, onBlur, ...inputProps }) => {
    return (
        <LabeledDateRangeInput
            {...inputProps}
            ref={api.ref}
            value={api.value!}
            onChange={value => {
                api.setValue(value);
                onChange?.(value);
            }}
            errorMessage={api.errorMessage}
            onBlur={() => {
                //FIXME: commented out becuase of blur event when date is selected and focus is lost from input, validation is showing error
                // api.setTouched(true);
                onBlur?.();
            }}
        />
    );
};
