import { JobContext, JobFilters, JobStatus } from '@hofy/api-admin';
import { countObjectValues } from '@hofy/helpers';
import { useStructMemo } from '@hofy/hooks';
import { useArrayQueryParam } from '@hofy/router';

export const useJobFilters = () => {
    const [statuses, setStatuses] = useArrayQueryParam<JobStatus>('status');
    const [context, setContext] = useArrayQueryParam<JobContext>('context');

    const filters = useStructMemo<JobFilters>({
        statuses,
        context,
    });

    const filterCount = countObjectValues(filters);

    return {
        filters,
        filterCount,
        setStatuses,
        setContext,
    };
};
