import React, { FC } from 'react';

import { RentalContractType, useTrRentalContractType } from '@hofy/api-shared';
import { Box, Paragraph3 } from '@hofy/ui';

import { useAdminI18n } from '../../../i18n/useAdminI18n';

interface RentalContractTypeLabelProps {
    rentalType: RentalContractType;
    rentalLength?: number | null;
}

export const RentalContractTypeLabel: FC<RentalContractTypeLabelProps> = ({ rentalType, rentalLength }) => {
    const { trPlural } = useAdminI18n();
    const trRentalContractType = useTrRentalContractType();

    return (
        <Box>
            <Paragraph3>{trRentalContractType(rentalType)}</Paragraph3>
            {rentalLength !== null ? <Paragraph3>{trPlural('month', rentalLength)}</Paragraph3> : null}
        </Box>
    );
};
