import { useBaseI18n } from '@hofy/i18n';

import { ToastItemWithoutId, useToastsContext } from './ToastsContext';

export const useToast = () => {
    const context = useToastsContext();
    const { tr } = useBaseI18n();

    const showToast = (toast: ToastItemWithoutId) => {
        context?.showToast(toast);
    };

    const showErrorToast = (message?: string) => {
        showToast({
            title: tr('ui.error.title'),
            message: message || tr('ui.error.message'),
            type: 'negative',
        });
    };

    return {
        showToast,
        showErrorToast,
    };
};
