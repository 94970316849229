import { useMemo } from 'react';

import { ImageDto } from '@hofy/api-shared';
import { useStateEffect } from '@hofy/hooks';

export interface Variant {
    image?: ImageDto;
}
export const useProductImages = <T extends Variant = Variant>(productImages: ImageDto[], variant?: T) => {
    const [image, setImage] = useStateEffect<ImageDto | undefined>(() => variant?.image, [variant]);
    const images = useMemo(() => productImages.filter(image => image.isActive), [productImages]);

    return {
        image,
        setImage,
        images,
    };
};
