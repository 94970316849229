export const downloadFile = (blob: Blob, filename: string) => {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    const anyNavigator = window.navigator as any;

    if (window.navigator && anyNavigator.msSaveOrOpenBlob) {
        anyNavigator.msSaveOrOpenBlob(blob, filename);
        return;
    }
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    (link as any).style = 'display: none';
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(data);
    }, 100);
};

export const downloadFileFromResponse = ([blob, filename]: [Blob, string]) => {
    downloadFile(blob, filename);
};
