export const durationI18n = {
    'duration.year.single': '{value} year',
    'duration.year.plural': '{value} years',
    'duration.month.single': '{value} month',
    'duration.month.plural': '{value} months',
    'duration.day.single': '{value} day',
    'duration.day.plural': '{value} days',
    'duration.hour.single': '{value} hour',
    'duration.hour.plural': '{value} hours',
    'duration.minute.single': '{value} minute',
    'duration.minute.plural': '{value} minutes',
    'duration.second.single': '{value} second',
    'duration.second.plural': '{value} seconds',
};
