import { chunk } from 'lodash';
import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import { RepaymentPlanDto } from '@hofy/api-admin';
import { useTrCancellationReason } from '@hofy/api-shared';
import { formatDate } from '@hofy/helpers';
import { usePrice } from '@hofy/hooks';
import { Color } from '@hofy/theme';
import { Box, FormGridRow, FormSection, Labeled, LabeledText, Paragraph3 } from '@hofy/ui';

import { BillingEntityLink } from '../../billingEntities/BillingEntityLink';
import { RepaymentTypeBadge } from '../components/RepaymentTypeBadge';
import { TwoRefDetails } from '../components/TwoRefDetails';

interface RepaymentDetailsTabProps {
    plan: RepaymentPlanDto;
}

export const RepaymentDetailsTab: FC<RepaymentDetailsTabProps> = ({ plan }) => {
    const { formatPrice } = usePrice();
    const trReason = useTrCancellationReason();
    return (
        <Box overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Details'>
                    <FormGridRow columns={2}>
                        <LabeledText label='Id' content={plan.id} />
                        <LabeledText label='Public Id' content={plan.publicId} />
                        <LabeledText label='Two Id' content={plan.twoId} />
                        <Labeled label='Type' content={<RepaymentTypeBadge type={plan.type} />} />
                    </FormGridRow>
                    <FormGridRow columns={2}>
                        <LabeledText label='Invoice from' content={plan.hofySubsidiary.name} />
                        <LabeledText
                            label='Invoice to'
                            content={<BillingEntityLink billingEntity={plan.billingEntity} />}
                        />
                        <LabeledText label='Grouping key' content={plan.invoiceGroupingKey || '--'} />
                        {!!plan.cancelOn && (
                            <LabeledText label='Cancel on' content={formatDate(plan.cancelOn)} />
                        )}
                    </FormGridRow>
                </FormSection>
                <FormSection label='Financial' marginTop={30}>
                    <FormGridRow columns={4}>
                        <LabeledText label='Initial amount' content={formatPrice(plan.initialAmount)} />
                        <LabeledText label='Invoiced amount' content={formatPrice(plan.invoicedAmount)} />
                        <LabeledText label='Final amount' content={formatPrice(plan.finalAmount)} />
                        {plan.cancellationReason && (
                            <LabeledText
                                label='Cancellation reason'
                                content={trReason(plan.cancellationReason)}
                            />
                        )}
                    </FormGridRow>
                </FormSection>
                <FormSection label='Syncing' marginTop={30}>
                    <TwoRefDetails plan={plan} />
                </FormSection>
            </Box>
            <Box flex={1} marginRight={20}>
                {!!plan.commissionCurve.length && (
                    <FormSection label='Commission' marginTop={30}>
                        <CurveChart commissionCurve={plan.commissionCurve} />
                    </FormSection>
                )}
            </Box>
        </Box>
    );
};

interface CurveChartProps {
    commissionCurve: string[];
}

const CurveChart: FC<CurveChartProps> = ({ commissionCurve }) => {
    const data = commissionCurve.map((c, i) => ({
        value: c,
        month: i + 1,
    }));

    const chunks = chunk(data, 12);

    return (
        <table>
            <tbody>
                {chunks.map((ch, index) => (
                    <Fragment key={index}>
                        <tr>
                            {ch.map(v => (
                                <Th key={v.month} as='td' bold>
                                    {v.month}
                                </Th>
                            ))}
                        </tr>
                        <tr>
                            {ch.map(v => (
                                <Td as='td' key={v.month}>
                                    {v.value}
                                </Td>
                            ))}
                        </tr>
                    </Fragment>
                ))}
            </tbody>
        </table>
    );
};

const Td = styled(Paragraph3)`
    border: 1px solid ${Color.ContentSecondary};
    padding: 4px;
`;
const Th = styled(Paragraph3)`
    background: ${Color.BackgroundSurfaceNeutral};
    border: 1px solid ${Color.ContentSecondary};
    padding: 4px;
`;
