import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { stockLevelsService } from '@hofy/api-admin';
import { FileRef } from '@hofy/common';
import { useToast } from '@hofy/ui';

export const useUploadMinAvailabilityLevels = (onSuccess?: () => void) => {
    const { showToast } = useToast();
    const [file, setFile] = useState<FileRef | null>(null);

    const mutation = useMutation({
        mutationFn: stockLevelsService.uploadMinAvailability,
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Minimum availability updated.',
            });
            setFile(null);
            onSuccess?.();
        },
    });

    const upload = (file: FileRef | null) => {
        setFile(file);
        if (file) {
            mutation.mutate(file);
        }
    };

    return { file, upload, isLoading: mutation.isPending };
};
