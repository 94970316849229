import React, { forwardRef, ReactElement, Ref } from 'react';

import { Labeled } from '../../labeled';
import { NormalSelectSearchProps, NullableSelectSearchProps, SelectSearch } from './SelectSearch';

interface BaseLabeledSelectSearchProps {
    label: string;
    isRequired?: boolean;
    errorMessage?: string;
    helperText?: string;
}

export type LabeledSelectSearchOnlyStringProps<T> = Omit<NormalSelectSearchProps<T>, 'isError'> &
    BaseLabeledSelectSearchProps;
export type LabeledSelectSearchNullableStringProps<T> = Omit<NullableSelectSearchProps<T>, 'isError'> &
    BaseLabeledSelectSearchProps;

export type LabeledSelectSearchProps<T> =
    | LabeledSelectSearchOnlyStringProps<T>
    | LabeledSelectSearchNullableStringProps<T>;

const LabeledSelectSearchComponent = <T,>(
    {
        label,
        isRequired,
        errorMessage,
        helperText,

        options,
        fixedOptions,
        optionsLengthToRender,
        value,
        onChange,
        onSearchChange,
        isLoadingSearch,
        loadingSearchPlaceHolder,
        onBlur,
        onFocus,
        toText,
        toKey,
        toLabel,
        searchPlaceholder,
        dropdownBottomSlot,
        contentMaxHeight,
        disabled,
        nullable,
        placeholder,
        ...rest
    }: LabeledSelectSearchProps<T>,
    ref: Ref<HTMLDivElement>,
) => {
    return (
        <Labeled
            as='label'
            label={label}
            isRequired={isRequired}
            content={
                <SelectSearch
                    ref={ref}
                    options={options}
                    fixedOptions={fixedOptions}
                    optionsLengthToRender={optionsLengthToRender}
                    value={value}
                    onChange={onChange}
                    onSearchChange={onSearchChange}
                    isLoadingSearch={isLoadingSearch}
                    loadingSearchPlaceHolder={loadingSearchPlaceHolder}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    toText={toText}
                    toKey={toKey}
                    toLabel={toLabel}
                    searchPlaceholder={searchPlaceholder}
                    disabled={disabled}
                    nullable={nullable}
                    placeholder={placeholder}
                    dropdownBottomSlot={dropdownBottomSlot}
                    isError={!!errorMessage}
                    contentMaxHeight={contentMaxHeight}
                />
            }
            errorMessage={errorMessage}
            helperText={helperText}
            {...rest}
        />
    );
};

export const LabeledSelectSearch = forwardRef(LabeledSelectSearchComponent) as <T>(
    props: LabeledSelectSearchProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;
